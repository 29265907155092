
import react,{ Component } from 'react';
// data chart's elements for category series:
import { IgrNumericYAxis } from 'igniteui-react-charts';
import { IgrCategoryXAxis } from 'igniteui-react-charts';
import { IgrColumnSeries } from 'igniteui-react-charts';
// data chart's modules:
import { IgrDataChart } from 'igniteui-react-charts';
import { IgrDataChartCoreModule } from 'igniteui-react-charts';
import { IgrDataChartCategoryModule } from 'igniteui-react-charts';
import { IgrDataChartInteractivityModule } from 'igniteui-react-charts';
// legend's modules:
import { IgrLegend } from 'igniteui-react-charts';
import { IgrLegendModule } from 'igniteui-react-charts';

IgrDataChartCoreModule.register();
IgrDataChartCategoryModule.register();
IgrDataChartInteractivityModule.register();
IgrLegendModule.register();


export default class OperationChart extends Component {
    constructor(props){
        super(props)
        this.onChartRef = this.onChartRef.bind(this);
        this.onLegendRef = this.onLegendRef.bind(this);

        this.state = {
            data: []
        }
    }

    onChartRef(chart) {
        if (!chart) { return; }

        this.chart = chart;
        if (this.legend) {
            this.chart.legend = this.legend;
        }
        //this.chart.dataSource = this.data
    }

    onLegendRef(legend) {
        if (!legend) { return; }

        this.legend = legend;
        if (this.chart) {
            this.chart.legend = this.legend;
        }
    }

    initSampleData = () => {
        let sampleData = [
            { Country: "Canada", Coal: 400, Oil: 100, Gas: 175, Nuclear: 225, Hydro: 350 },
            { Country: "China", Coal: 925, Oil: 200, Gas: 350, Nuclear: 400, Hydro: 625 },
            { Country: "Russia", Coal: 550, Oil: 200, Gas: 250, Nuclear: 475, Hydro: 425 },
            { Country: "Australia", Coal: 450, Oil: 100, Gas: 150, Nuclear: 175, Hydro: 350 },
            { Country: "United States", Coal: 800, Oil: 250, Gas: 475, Nuclear: 575, Hydro: 750 },
            { Country: "France", Coal: 375, Oil: 150, Gas: 350, Nuclear: 275, Hydro: 325 }
        ];
        this.setState({data: sampleData})
    }

    componentDidMount(){
        this.initSampleData();
    }
    render(){
        const {data} = this.state;
        console.log(data);
        return( <div className="igContainer" style={{height: "Calc(100% - 5px)"}} >

        <div className="igLegend">
            <IgrLegend ref={this.onLegendRef} orientation="Horizontal" />
        </div>
        <div className="igComponent" style={{height: "100%"}} >
            <IgrDataChart
                dataSource={data} 
                ref={this.onChartRef}
                width="100%" height="100%"
                chartTitle="Energy Use Per Country"
                subtitle="Results over a two year period"
                isHorizontalZoomEnabled={true}
                isVerticalZoomEnabled={true} >

                <IgrCategoryXAxis name="xAxis" label="Country" />
                <IgrNumericYAxis name="yAxis" minimumValue={0} />

                <IgrColumnSeries name="series1" title="Coal" xAxisName="xAxis"
                                 yAxisName="yAxis" valueMemberPath="Coal"/>
                <IgrColumnSeries name="series2" title="Hydro" xAxisName="xAxis"
                                 yAxisName="yAxis" valueMemberPath="Hydro"/>
                <IgrColumnSeries name="series3" title="Nuclear" xAxisName="xAxis"
                                 yAxisName="yAxis" valueMemberPath="Nuclear"/>
                <IgrColumnSeries name="series4" title="Gas" xAxisName="xAxis"
                                 yAxisName="yAxis" valueMemberPath="Gas"/>
                <IgrColumnSeries name="series5" title="Oil" xAxisName="xAxis"
                                 yAxisName="yAxis" valueMemberPath="Oil"/>
            </IgrDataChart>

        </div>
    </div>)
    }

}