import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';

const WOOnHoldModal = (props) => {
  const {
    outline,
    color,
    className,
    formName,
    message,
    hidden,
    disabled,
    buttonLabel,
    style,
    isOpen,
    toggle,
    commentPrompMsg,
    value,
    onClickYes,
    onClosed,
    onChangeTest,
  } = props;

  const [inputComment, setInputComment] = useState(value);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setInputComment(value);
  }, [value]);

  const toggleYes = () => {
    if (inputComment.trim() === "") {
      setIsValid(false);
    } else {
      onClickYes(inputComment);
      setInputComment("");
      toggle();
    }
  };

  const handleClosed = () => {
    if (onClosed !== undefined) {
      onClosed();
    }
    setIsValid(true);
    setInputComment("");
  };

  const handleTextChange = (e) => {
    setInputComment(e.target.value);
    setIsValid(e.target.value.trim() !== "");
    if (onChangeTest) {
      onChangeTest(e);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} onClosed={handleClosed}>
        <ModalHeader toggle={toggle}>{formName}</ModalHeader>
        <ModalBody>
          {message}
          <FormGroup>
            <Label for="inputComment">{commentPrompMsg}</Label>
            <Input
              invalid={!isValid}
              id="inputComment"
              name="text"
              type="textarea"
              value={inputComment}
              onChange={handleTextChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleYes}>OK</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WOOnHoldModal;
