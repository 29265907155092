// This comment put in by Hau Nguyen on 06/06/2021

import React, { Component } from 'react';
import { apiURL, apiHeader } from '../resources/apiURL';
// import MenuIcon from '../resources/menu.png';
import clearfilter from '../components/img/clearfilter.png'
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import Select from 'react-select';

import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';

import { Col, Row, Button, FormGroup, Label, CustomInput } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
// import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
// import { IgrDateTimeColumn } from 'igniteui-react-grids';
// import { IgrColumnGroupDescription } from 'igniteui-react-grids';
// import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn, IgrColumn } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrTemplateCellInfo } from 'igniteui-react-grids';
// import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import SafetyObservationDetail from './SafetyObservationDetail'
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
import Revise from '../resources/revise.png'
import Report from '../resources/report.png'
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();
IgrDataGridToolbarModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class SafetyObservation extends Component {
    static displayName = SafetyObservation.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        // this.onGridfilterExpressionsChanged = this.onGridfilterExpressionsChanged.bind(this);
        // this.onToolbarRef = this.onToolbarRef.bind(this);

        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        const urlPara = this.getURLParameters(props.parameters)
        this.searchInput = React.createRef();

        this.state = {
            safetyObs: [],
            safetyObsDefault: [],
            isLoaded: false,
            // startDate: (urlPara.startDate === null ? getFirstDayofMonth().toISOString() : urlPara.startDate), 
            // endDate: (urlPara.endDate === null ? this.addDays(Date(), 1).toISOString() : urlPara.endDate),
            startDate: urlPara.startDate,
            endDate: urlPara.endDate,
            modalOpen: true,
            safetyObservationKey: -1,
            selSafeObs: null,
            windowHeight: window.innerHeight,
            modal: false,
            safetyType: urlPara.safetyType,
            isQHSEAdmin: false,
            isQHSEApprover: false,
            isMVAUser: false,
            // fromParamStartDate: urlPara.startDate,
            // fromParamEndDate: urlPara.endDate,
            modalReport: false,
            filterType: "",
            searchText: '',
            truckList: [],
            isTruckListLoaded: false

        }
    }


    onFilter = (e) => {


        this.setState({

            filterType: e.target.value
        }, () => {

        })

    }


    onTextChange = (fieldName, fieldValue, e) => {
        const { safetyObsDefault } = this.state;

        if (fieldName === "searchText") {
            let searchText = fieldValue.toLowerCase();
            if (this.state.isTyping) {
                this.searchInput.current.focus();
                // this.setState({ isTyping: false });
            }
            if (searchText !== this.state.searchText) {
                this.setState({ searchText: searchText });

            }


            // Apply filter based on filterType
            let filteredData = safetyObsDefault.filter(item => {
                // If a specific filterType is selected, filter by it
                // Then apply search text filter
                return this.isItemMatchingSearchText(item, searchText);
            });


            //   this.setState({ facInvCollector: filteredData, searchText, facilityInvCollectorKey: null });
            this.setState({ safetyObs: filteredData, searchText });

        }
    };

    isItemMatchingSearchText = (item, searchText) => {
        const { currentView } = this.state
        // Define columns to search based on the current view
        let columnsToSearch =
            ["safetyObservationNo", "statusDesc", "qhseReportTypeDesc", "hazardDesc", "solutionDesc", "createdByName", "managerName", "locationName"];


        return columnsToSearch.some(column => {
            if (item[column] && item[column].toString().toLowerCase().includes(searchText)) {
                return true;
            }
            return false;
        });
    };


    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport })
    }

    createReportParamter = () => {
        const { safetyObservationKey } = this.state
        // const reportPara = [{
        //     "name": "InvoiceKey",
        //     "value": invoiceKey  
        // }]
        const reportPara = [{
            "name": "SafetyObservationKey",
            "value": safetyObservationKey
        }]

        // console.log(reportPara)
        return reportPara

    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    getURLParameters = (inputPara) => {
        var paraMeters = {
            startDate: null,
            endDate: null,
            facilityKeys: null,
            safetyType: null,
            safetyObs: null
        }


        if (!this.props.location === 'undefined' || inputPara !== 'undefined') {
            var query
            if (!this.props.location === 'undefined') {
                query = new URLSearchParams(this.props.location.search);

            } else {
                query = new URLSearchParams(inputPara);
            }


            paraMeters = {
                startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
                endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
                safetyType: query.get('SafetyType'),
                facilityKeys: query.get('Facility')
            }

        }

        // console.log("inputPara")
        // console.log(this.props.location)

        // if (this.props.location != undefined)
        // {
        //     const para= this.props.location.search

        // console.log("parainputPara")
        // console.log(para)

        //     if (this.props.location.search !== 'undefined')
        //     {   
        //         const key = para.substring(1,para.length)
        //         if (!isNaN(key)){
        //             console.log("key")
        //             console.log(key)
        //             paraMeters={
        //                 startDate: null ,
        //                 endDate:null,
        //                 safetyType:null,
        //                 facilityKeys:null,
        //                 safetyObs:key
        //             }                      
        //         }
        //     }    
        // }



        // console.log(paraMeters)
        return paraMeters
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.safetyObs;

        // if (this.toolbar !== null){
        //     this.toolbar.targetGrid = this.grid

        // }

    }

    // onToolbarRef(toolbar) {
    //     this.toolbar = toolbar;
    //     if (this.toolbar) {
    //         this.toolbar.targetGrid = this.grid;
    //     }
    // }




    toggle = () => {
        // console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })

    }
    onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // console.log(dataItem)

            this.setState({ safetyObservationKey: dataItem.safetyObservationKey })
            this.setState({ selSafeObs: dataItem })
            //this.toggle()
            //this.onVolRefresh()    
        }
        else {
            this.setState({ safetyObservationKey: -1 })
        }

    }

    // onGridfilterExpressionsChanged(s, e) {
    //     const { safetyObservationKey } = this.state
    //     const searchText = window.$searchText.toLowerCase()
    //     if (safetyObservationKey > 0 && searchText.length === 0) {
    //         this.setState({ safetyObservationKey: -1 })
    //     }


    // }

    getIsQHSEadmin = () => {

        let myURI = apiURL + 'SafetyObservation/IsQHSEAdmin/' + this.props.user.account.userName;

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isQHSEAdmin: (json.isQHSEAdmin != 0)
                })
            });
    }

    getIsQHSEApprover = () => {

        let myURI = apiURL + 'SafetyObservation/IsQHSEApprover/' + this.props.user.account.userName;

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isQHSEApprover: (json.isQHSEApprover != 0)
                })
            });
    }

    getIsMVAUser = () => {

        let myURI = apiURL + 'SafetyObservation/IsMVAUser/' + this.props.user.account.userName;

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isMVAUser: (json.isMVAUser != 0)
                })
            });
    }


    getSafetyObsData = () => {
        //console.log(this.state.startDate);
        const { safetyType,
            filterType, startDate, endDate } = this.state
        // console.log("safetyType");
        // console.log(safetyType);
        // console.log("--getSafetyObsData--");
        // console.log(this.state.startDate)
        // console.log(this.state.endDate)









        let startD = (startDate === null ? getFirstDayofMonth().toISOString() : startDate)
        let endD = (endDate === null ? this.addDays(Date(), 1).toISOString() : endDate)
        let strfilterType = filterType

        console.log("dates...");
        console.log(filterType);
        console.log(strfilterType);



        if (filterType != 'defaultview' && filterType && (!startDate || !endDate)) {

            console.log("clearing dates...");
            startD = getFirstDayofMonth().toISOString()
            endD = this.addDays(Date(), 1).toISOString()
            strfilterType = "defaultview"
            this.setState({
                filterType: 'defaultview',
                startDate: null,
                endDate: null


            });



        }

        // if (filterType != 'defaultview' && filterType)
        // {
        //     console.log("not def");
        //     console.log(filterType);

        //     this.setState({
        //         startDate: startD,
        //         endDate: endD,

        //     });
        // } else
        // {
        //     this.setState({
        //         startDate: null,
        //         endDate: null,

        //     });

        // }

        // let startD = this.state.startDate === null ? '2019-01-01T06:00:00.000Z' : this.state.startDate.toString()
        // let endD = this.state.endDate === null ? '2099-01-01T06:00:00.000Z' : this.state.endDate.toString()


        let myURI = apiURL + 'SafetyObservation/startdate/' +
            startD.toString().substring(0, 10) +
            '/enddate/' + endD.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName
            + '/filterType/' + (strfilterType ? strfilterType : 'defaultview');




        fetch(myURI)
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.text();
            })
            .then(text => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    console.error("Parsing error:", e);
                    throw new Error("Failed to parse JSON");
                }
            })
            .then(json => {
                const transformedJson = json
                    // .filter(rec => ((safetyType === null ? true : (rec.safetyTypeKey === safetyType))) &&
                    //     ((fromParamStartDate === null && fromParamEndDate === null) ? true :
                    //         (rec.createdDatetime >= fromParamStartDate && rec.createdDatetime <= fromParamEndDate)))
                    .map(rec => ({
                        ...rec,
                        createdDatetime: rec.createdDatetime ? new Date(rec.createdDatetime) : null,
                        submittedDatetime: rec.submittedDatetime ? new Date(rec.submittedDatetime) : null,
                        approvedDate: rec.approvedDate ? new Date(rec.approvedDate) : null



                    }))
                    .sort((a, b) => ((a.statusKey === 5 ? 3 : a.statusKey) > (b.statusKey === 5 ? 3 : b.statusKey)) ? 1 : -1);

                this.setState({
                    isLoaded: true,
                    safetyObs: transformedJson,
                    safetyObsDefault: transformedJson,
                    safetyObservationKey: -1,
                    searchText: ''
                });

            })
            .catch(error => {
                console.error("Fetch error:", error);
            });
        if (this.searchInput.current) {
            // console.log("Ref found, current element:", this.searchInput.current);
            this.searchInput.current.value = '';
        } else {
            // console.log("Ref not found");
        }





        // console.log(myURI)
        // console.log(safetyType)
    }

    getTruckList = () => {
        let myURL = apiURL + 'ItemMaster/FacilityInventoryCollectorFleet';
        fetch(myURL, apiHeader('GET'), { mode: 'no-cors' })
            .then(res => res.json())
            .then(data => {

                const tempList = []
                data.map(e => {
                    var temp = { value: "", label: "" }
                    temp.value = e.itemDesc
                    temp.label = e.itemDesc
                    tempList.push(temp)
                })

                this.setState({
                    truckList: tempList,
                    isTruckListLoaded: true
                });
            });
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getTruckList()
        this.getIsQHSEadmin()
        this.getIsQHSEApprover()
        this.getIsMVAUser()
        this.getSafetyObsData()


    }


    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
        // console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue

        });

    }

    getSafetyObsHeaderColor = (selSafeObs) => {
        // console.log("selSafeObs")
        // console.log(selSafeObs)


        if (selSafeObs != null) {

            const statusKey = selSafeObs.statusKey
            // console.log("statusKey")
            // console.log(statusKey)

            if (statusKey === 4) {
                return "modal-header submit"
            } else if (statusKey === 6) {
                return "modal-header approve"
            } else if (statusKey === 3) {
                return "modal-header reject"
            } else if (statusKey === 5) {
                return "modal-header reject"
            } else {
                return "modal-header primary"
            }

        } else { return "" }
    }

    resetFilter = () => {
        const { safetyObsDefault } = this.state;

        for (var i = 0; i < this.grid.actualColumns.count; i++) {
            this.grid.actualColumns.item(i).filter = null;

        }
        this.grid.selectedItems.clear();


        this.grid.filterExpressions.clear();

        if (this.searchInput.current) {
            // console.log("Ref found, current element:", this.searchInput.current);
            this.searchInput.current.value = '';
        } else {
            // console.log("Ref not found");
        }

        this.setState({ searchText: '', safetyObs: safetyObsDefault });
    }

    render() {
        var { isLoaded, safetyObs, windowHeight, isTruckListLoaded } = this.state;
        const { truckList, safetyObservationKey, selSafeObs, isQHSEAdmin, isQHSEApprover, isMVAUser, filterType, filterTypeList } = this.state;
        const gridHeight = "75vh"
        // const searchText = window.$searchText.toLowerCase()

        // console.log("isQHSEAdmin")
        // console.log(isQHSEAdmin)
        //if (isMVAUser) return <h1> yes </h1> 

        if (!isTruckListLoaded) {
            return (<div>
                <h3>Loading Asset Inventory.....</h3>
            </div>)
        } else if (!isLoaded) {
            return (<div>
                <h3>Loading safety observation.....</h3>
            </div>)
        } else
            return (<div>
                <FormGroup>
                    <Row form>
                        <h2> Safety Observation | </h2>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.getSafetyObsData}>
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>
                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>{' '}





                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.toggle}
                            disabled={safetyObservationKey < 0}
                        >
                            <img
                                src={Revise}
                                alt="details"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            View Details
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.toggleReport}
                            disabled={safetyObservationKey < 0}
                        >
                            <img
                                src={Report}
                                alt="Report"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            View Report
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.newObservation}>
                            <img
                                src={Plus}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            New Observation
                        </Button>{' '}


                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>



                {/* <FormGroup row>

                    <Col md={1}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                            Start Date
                        </Label>

                    </Col>
                    <Col md={2}>
                        <DatePicker value={this.state.startDate}
                            onChange={(v, f) => this.handleChange(v, f)} />
                    </Col>
                    <Col md={1}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                            End Date
                        </Label>

                    </Col>
                    <Col md={2}>
                        <DatePicker value={this.state.endDate}
                            onChange={(v, f) => this.handleEndDateChange(v, f)} />
                    </Col>
                  
                    <Col md={2}>
                        <Button outline
                            style={{ margin: "0 0 20px 20px", padding: "0 0 0 0" }}
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.resetFilter}
                        >
                            <img src={MenuIcon}
                                alt="Reset"
                                style={{ width: "30px", margin: "0 0 0 0" }} />
                            Reset Filter
                        </Button>

                    </Col>
                </FormGroup> */}


                {/* <FormGroup>
                    <Row >
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)} />
                        </Col>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />

                        </Col>
                        <Col md={2}>
                        <Button outline
                            style={{ margin: "0 0 20px 20px", padding: "0 0 0 0" }}
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.resetFilter}
                        >
                            <img src={MenuIcon}
                                alt="Reset"
                                style={{ width: "30px", margin: "0 0 0 0" }} />
                            Reset Filter
                        </Button>

                    </Col>

                    </Row>
                </FormGroup> */}

                <FormGroup>
                    {/* First row for labels */}
                    <Row form>
                        <Col md={2}><Label>Filter type</Label></Col>
                        <Col md={2}><Label>From Date</Label></Col>
                        <Col md={2}><Label>To Date</Label></Col>
                        <Col md={1} />
                        <Col md={5}></Col>






                    </Row>

                    {/* Second row for form elements */}
                    <Row form>
                        <Col md={2}>
                            <CustomInput
                                type="select"
                                id="test"
                                label="test"
                                value={filterType}
                                onChange={this.onFilter}
                            >
                                <option value={""}>Please Select</option>
                                <option value={"created"}>Created Date</option>
                                <option value={"submitted"}>Submitted Date</option>
                                <option value={"approved"}>Approved Date</option>
                            </CustomInput>
                        </Col>

                        <Col md={2}>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)} />
                        </Col>

                        <Col md={2}>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />
                        </Col>
                        <Col md={1}></Col>
                        <Col md={2}>

                            <Button outline color="secondary"
                                className="btn-no-border float-right"
                                style={{ float: 'right' }}
                                // style={{ float: 'right', marginRight: '-5px' }} 
                                onClick={this.resetFilter}

                            >
                                <img
                                    src={clearfilter}
                                    alt="clear"
                                    style={{ width: "25px", height: "25px", margin: "0 0 0 10" }} />
                                {"Clear Filter"}
                            </Button>
                        </Col>
                        <Col md={3}>
                            <Input
                                // className="my-custom-reactstrap-input"
                                innerRef={this.searchInput}
                                placeholder="Begin search..."
                                onChange={(e) => this.onTextChange("searchText", e.target.value, e)}
                            />
                        </Col>




                    </Row>
                </FormGroup>





                <FormGroup>
                    {/* <IgrDataGridToolbar
                            ref={this.onToolbarRef}
                            toolbarTitle="helelel"
                            columnChooser="true"
                        /> */}
                    <IgrDataGrid
                        ref={this.onGridRef}
                        cellTextStyle="14px Calibri"
                        headerTextStyle="14px Calibri"
                        rowHeight={"25"}
                        height={gridHeight}
                        dataSource={safetyObs}
                        // dataSource={searchText.length > 0 ? safetyObs.filter(rec => ((rec.safetyObservationNo || '').toLowerCase().includes(searchText)) ||
                        //     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.qhseReportTypeDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.hazardDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.solutionDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.createdByName || '').toLowerCase().includes(searchText) ||
                        //     (rec.managerName || '').toLowerCase().includes(searchText) ||
                        //     (rec.locationName || '').toLowerCase().includes(searchText))
                        //     : safetyObs


                        // }
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                    // filterExpressionsChanged={this.onGridfilterExpressionsChanged}
                    //cellBackground={"#ffbfbf"}
                    >


                        <IgrTemplateColumn field="basin" headerText="Basin " width="*>120"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="safetyObservationNo" headerText="Safety Observation No" width="*>190"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="statusDesc" headerText="Status"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="submittedDatetime" headerText="Submitted Date"
                            width="*>170" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="approvedDate" headerText="Approved Date"
                            width="*>170" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="qhseReportTypeDesc" headerText="Observation Type"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="hazardDesc" headerText="Observation Description"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="solutionDesc" headerText="Solution"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating} />


                        <IgrTemplateColumn field="createdDatetime" headerText="Created Date"
                            width="*>160"
                            dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />



                        <IgrTemplateColumn field="createdByName" headerText="CreatedBy"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="managerName" headerText="CreatedBy Manager"
                            width="*>190"
                            cellUpdating={this.onStatusCellUpdating} />

                        {/* <IgrTemplateColumn field="submittedDatetime" headerText="Submitted Date" 
                            width="*>160" 
                            dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />  */}
                        <IgrTemplateColumn field="submittedByName" headerText="SubmittedFor"
                            width="*>160"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="submittedByManager" headerText="SubmittedFor Manager"
                            width="*>190"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="locationName" headerText="Location Name"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="facilityName" headerText="Facility Name"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="estSpillAmt" headerText="Est Spill Amt"
                            width="*>170"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="truckNo" headerText="Truck No"
                            width="*>170"
                            cellUpdating={this.onStatusCellUpdating} />


                        <IgrNumericColumn field="safetyObservationKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>

                <div>
                    <Modal isOpen={this.state.modal}
                        toggle={() => this.toggle()}
                        size="xl"
                        centered>
                        <ModalHeader className={this.getSafetyObsHeaderColor(selSafeObs)}
                            toggle={() => this.toggle()}> Safety Observation Details</ModalHeader>
                        <ModalBody>
                            <SafetyObservationDetail
                                SafetyObservationKey={safetyObservationKey}
                                user={this.props.user}
                                toggle={this.toggle}
                                isQHSEAdmin={isQHSEAdmin}
                                isQHSEApprover={isQHSEApprover}
                                isMVAUser={isMVAUser}
                                truckList={truckList}
                                refreshData={this.getSafetyObsData}>

                            </SafetyObservationDetail>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalReport}
                        toggle={() => this.toggleReport()}
                        //style={{ margin: 0, flex: 1 }}
                        //onClosed={() => this.getSupplierReq()}
                        size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                        //contentClassName="full-screen-modal"
                        centered
                    >
                        <ModalHeader toggle={() => this.toggleReport()}> Safety Observation Report </ModalHeader>
                        <ModalBody>
                            <PowerBIReportViewer
                                reportName={"SafetyObservation"} //{"R9Invoice"}
                                reportParameters={this.createReportParamter()}
                                //invoiceKey={invoiceKey}                            
                                toggle={this.toggleReport}
                            //refreshData={this.getInvovice}
                            >

                            </PowerBIReportViewer>
                        </ModalBody>
                    </Modal>
                </div>

            </div>)
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.statusKey === 4) {
                content.style.background = "#b6a065";
            } else if (item.statusKey === 6) {
                content.style.background = "#61a066";
            } else if (item.statusKey === 3 || item.statusKey === 5) {
                content.style.background = "#ad5f55";
            } else {
                content.style.background = "transparent";
            }
        }

        switch (s.field) {
            case 'statusDesc':
                link.textContent = item.statusDesc;
                break;
            case 'safetyObservationNo':
                link.textContent = item.safetyObservationNo;
                break;
            case 'createdDatetime':
                // var date = new Date(item.createdDatetime);                
                // link.textContent = date.toLocaleDateString();


                const dateObj = new Date(item.createdDatetime);
                const month2 = dateObj.getMonth() + 1; // getMonth() returns 0-11
                const date2 = dateObj.getDate();
                const year2 = dateObj.getFullYear();

                // Formatting to MM.DD.YYYY
                link.textContent = `${month2.toString().padStart(2, '0')}/${date2.toString().padStart(2, '0')}/${year2}`;



                break;
            case 'submittedDatetime':
                // var date = new Date(item.submittedDatetime);                
                // link.textContent = date.toLocaleDateString();
                const dateObj3 = new Date(item.submittedDatetime);
                const month3 = dateObj3.getMonth() + 1; // getMonth() returns 0-11
                const date3 = dateObj3.getDate();
                const year3 = dateObj3.getFullYear();

                // Formatting to MM.DD.YYYY
                link.textContent = `${month3.toString().padStart(2, '0')}/${date3.toString().padStart(2, '0')}/${year3}`;
                break;

            case 'approvedDate':

                if (item.approvedDate && !isNaN(new Date(item.approvedDate).getTime())) {
                    const dateObjad = new Date(item.approvedDate);
                    const monthad = dateObjad.getMonth() + 1; // getMonth() returns 0-11
                    const datead = dateObjad.getDate();
                    const yearad = dateObjad.getFullYear();

                    // Formatting to MM/DD/YYYY
                    link.textContent = `${monthad.toString().padStart(2, '0')}/${datead.toString().padStart(2, '0')}/${yearad}`;
                } else {
                    // Handle null or invalid date
                    link.textContent = ''; // or any placeholder text like 'N/A'
                }
                break;

            case 'qhseReportTypeDesc':
                link.textContent = item.qhseReportTypeDesc;
                break;
            case 'basin':
                link.textContent = item.basin;

                break;
            case 'hazardDesc':
                link.textContent = item.hazardDesc;
                break;
            case 'solutionDesc':
                link.textContent = item.solutionDesc;
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'managerName':
                link.textContent = item.managerName;
                break;
            case 'submittedByName':
                link.textContent = item.submittedByName;
                break;
            case 'submittedByManager':
                link.textContent = item.submittedByManager;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            case 'estSpillAmt':
                link.textContent = item.estSpillAmt;
                break;
            case 'truckNo':
                link.textContent = item.truckNo
                break;
            default:

        }

    }

    newObservation = () => {
        this.setState({ safetyObservationKey: -1 })
        //this.setState({selSafeObs: dataItem})
        this.toggle()
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        // console.log(actCols.item(0) )

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "SafetyObservation");
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.reportDate).toLocaleDateString("es-PA");
    }


}