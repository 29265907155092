import React, { Component } from 'react';

import { Alert, Form, Button } from 'reactstrap';

import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';


import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

export default class AFEWDBGDetails extends Component {
    static displayName = AFEWDBGDetails.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);

        this.state = {
            afeWDBGDetails: [],
            isDataLoaded: false,
            afeNo: props.afeNo,
            dept: props.dept,
            scid: props.scid,
            showAlert: false,
            alertMsg: '',
            isFormReadOnly: props.isFormReadOnly
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.afeWDBGDetails;
        this.onLoad()
    }

    getWDBGDetails = () => {
        const { afeNo, dept, scid } = this.state

        var myURI = apiURL + 'AFE/Workday/BG/' + afeNo


        if (dept !== '') {
            myURI = myURI + '/Dept/' + dept
        } else if (scid !== '') {
            myURI = myURI + '/SC/' + scid
        }

        console.log("myURI")
        console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    afeWDBGDetails: json
                })
            });
    }

    componentDidMount() {

        window.addEventListener('load', this.onLoad);
        this.getWDBGDetails()


    }

    onLoad() {
        if (this.grid !== undefined) {
            this.grid.summaryDescriptions.clear()

            const creditSum = new IgrColumnSummaryDescription();
            creditSum.field = "budget_Credit";
            creditSum.operand = SummaryOperand.Sum;
            creditSum.formatOverride = new Intl.NumberFormat('en-EN', {
                style: 'currency', currency: 'USD',
                minimumFractionDigits: 0, maximumFractionDigits: 2
            });
            this.grid.summaryDescriptions.add(creditSum);


            const debitSum = new IgrColumnSummaryDescription();
            debitSum.field = "budget_Debit";
            debitSum.operand = SummaryOperand.Sum;
            debitSum.formatOverride = new Intl.NumberFormat('en-EN', {
                style: 'currency', currency: 'USD',
                minimumFractionDigits: 0, maximumFractionDigits: 2
            });
            this.grid.summaryDescriptions.add(debitSum);

        }
    }

    okClick = () => {
        this.props.toggleModalWDBDDetails()
    }

    render() {
        const { afeWDBGDetails, isDataLoaded, isFormReadOnly,
            showAlert, alertMsg } = this.state

        if (!isDataLoaded) {
            return (<div>Loading BugetData Data .......</div>)
        } else
            return (<div>
                <Form>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="350px"
                        width='100%'
                        dataSource={afeWDBGDetails}
                        isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        groupSummaryDisplayMode={"RowTop"}
                        groupHeaderDisplayMode="combined"
                        summaryScope="Both"
                        editMode={0}
                    // editMode={(isFormReadOnly ? 0 : 1)}
                    // selectedKeysChanged={this.onGridRowSelected}
                    //cellValueChanging={this.onCellValueChanging}
                    >
                        <IgrTemplateColumn
                            field="dept"
                            headerText="Department"
                            width="120"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="plan"
                            headerText="Plan"
                            width="*>250"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="spend_Category"
                            headerText="Spend Category"
                            width="*>250"
                            cellUpdating={this.onCellUpdating}
                        />
{/* 
                        <IgrTemplateColumn
                            field="cost_Center"
                            headerText="Cost Center"
                            width="*>150"
                            hidden={true}
                            cellUpdating={this.onCellUpdating}
                        /> */}

                        <IgrNumericColumn
                            field="budget_Credit"
                            headerText="Credit"
                            showGroupingSeparator="true"
                            positivePrefix="$"
                            maxFractionDigits={0}
                            width="160"
                        //cellUpdating={this.onCellUpdating}

                        />

                        <IgrNumericColumn
                            field="budget_Debit"
                            headerText="Debit"
                            showGroupingSeparator="true"
                            positivePrefix="$"
                            maxFractionDigits={0}
                            width="160"
                        //cellUpdating={this.onCellUpdating}
                        />
                        <IgrNumericColumn field="wdProjectsBudgetsLineKey" isHidden />
                        <IgrNumericColumn field="project_ID" isHidden />
                    </IgrDataGrid>


                    <div>
                        <Alert color="danger" isOpen={showAlert}
                            //toggle={() => this.toggleAlert()}
                            style={{ marginTop: "5px", marginBottom: "10px" }}
                        >
                            {alertMsg}
                        </Alert>
                    </div>


                    <ColoredLine color="grey" />

                    <div>
                        <Form>

                            <Button
                                color="primary"
                                className='float-right'
                                disabled={showAlert}
                                onClick={() => this.okClick()}
                                style={{ marginTop: "5px", marginRight: "20px" }}
                            >
                                OK
                            </Button>
                            {' '}
                        </Form>
                    </div>
                </Form>
            </div>)
    }

    onCellUpdating(s, e) {

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;

        if (content.childElementCount === 0) {
            if (s.field === "plan" ) {
                link = document.createElement("a");
                let cell = document.createElement("div");

                link.style.fontFamily = "Verdana";
                link.style.fontSize = "13px";
                link.style.color = "#4286f4";
                content.style.width = "100%";
                link.style.padding = "8px 0px 0px 0px";
            } else {
                link = document.createElement("label");
                let cell = document.createElement("div");

                link.setAttribute("class", "form-control selectBorderLess");
                //link.style.fontFamily = "Verdana";
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";

                // content.style.margin = "0px -20px -10px -12px";
                link.style.padding = "8px 0px 0px 0px";
                // //content.style.width = "100%";
            }
            content.appendChild(link);
        } else {
            link = content.children[0]
        }

        if (link != undefined) {

            switch (s.field) {
                case 'dept':
                    
                    link.textContent = item.dept;
                    link.setAttribute("class", "form-control selectBorderLess");
                    break;
                case 'plan':
                    if (item.widBudgetWDURL!==''){
                        link.href = item.widBudgetWDURL
                        link.target = "_blank"
                        link.textContent = item.plan;                        
                    }
                    link.setAttribute("class", "text-md-left form-control selectBorderLess");
                    break;
                case 'spend_Category':
                    link.textContent = item.spend_Category;
                    link.setAttribute("class", "form-control selectBorderLess");
                    break;
                default:
            }

        }


    }
}