// // // // //HAS ACCESS TO EVERYTHING

// export const accountInfo = {
//     account: {
//         name: "Ryan Steward",
//         userName: "Ryan.Steward@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Joshua LaMarca",
//         userName: "Joshua.LaMarca@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Hau Nguyen",
//         userName: "Hau.Nguyen@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "J K",
//         userName: "Jake.Langford@h2obridge.com"
//     }
// }

//MOC Approvers
//         name: "Brandon Nguyen",
//         userName: "Brandon.Nguyen@h2obridge.com"
//     }
// }

// MOC Approvers
// export const accountInfo = {
//     account: {
//         name: "Dan Sewell",
//         userName: "Dan.Sewell@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {

//         name: "Jimmy Nixon",
//         userName: "Jimmy.Nixon@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Adam Few",
//         userName: "Adam.Few@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Ray Montgomery",
//         userName: "Ray.Montgomery@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Stephen Anson",
//         userName: "Stephen.Anson@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Ryan Glover",
//         userName: "Ryan.Glover@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Joey Philippi",
//         userName: "Joey.Philippi@h2obridge.com"
//     }
// }



// export const accountInfo = {
//     account: {
//         name: "J M",
//         userName: "Jamil.Memon@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "A F",
//         userName: "Amanda.free@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "R G",
//         userName: "stephen.anson@h2obridge.com"
//     }
// }


// export const accountInfo = {
//     account: {
//         name: "M K",
//         userName: "Michal.Kociszewski@mothdesign.co"
//     }
// }

// export const accountInfo = {
//         account: {
//             name: "S B",
//             userName: "shabana.bingham@h2obridge.com"
//         }
//     }

// export const accountInfo = {
//     account: {
//         name: "Jack Getman",
//         userName: "Jack.Getman@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Chase Davis",
//         userName: "Chase.Davis@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "Aaron Whitman",
//         userName: "Aaron.whitman@h2obridge.com"
//     }
// }


// export const accountInfo = {
//     account: {
//         name: "Amanda Free",
//         userName: "Amanda.Free@h2obridge.com"
//     }
// }




// export const accountInfo = {
//     account: {
//         name: "Zoe LAbbate",
//         userName: "Zoe.LAbbate@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "S H",
//         userName: "Sam.Howard@h2obridge.com"
//     }
// }
// export const accountInfo = {
//     account: {
//         name: "L C",
//         userName: "Loc.Cao@h2obridge.com"
//     }
// }

//VP-CAO

// export const accountInfo = {
//     account: {
//         name: "B S",
//         userName: "Brad.Stauffer@h2obridge.com"
//     }
// }


// export const accountInfo = {
//     account: {
//         name: "J W",
//         userName: "Jason.Williams@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "H M",
//         userName: "hayden.mcentire@h2obridge.com"
//     }
// }

//VP Delaware

// export const accountInfo = {
//     account: {
//         name: "R G",
//         userName: "russ.ginanni@h2obridge.com"
//     }
// }



//VP Land,SrVP Operation

// export const accountInfo = {
//     account: {
//         name: "W L",
//         userName: "William.Lane@h2obridge.com"
//     }
// }


//Top Level C Boss

// export const accountInfo = {
//     account: {
//         name: "Chris Morris",
//         userName: "Chris.Morris@h2obridge.com"
//     }
// }


//AFE Manager

// export const accountInfo = {
//     account: {
//         name: "S M",
//         userName: "Scott.Mcneely@h2obridge.com"
//     }
// }

// Supply Chain 

// export const accountInfo = {
//     account: {
//         name: "Dragan Nicetin",
//         userName: "dragan.nicetin@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "A M",
//         userName: "Abraham.Mendez@h2obridge.com"
//     }
// }



// export const accountInfo = {
//     account: {
//         name: "Jessica High",
//         userName: "Jessica.High@h2obridge.com"
//     }
// }


// export const accountInfo = {
//     account: {
//         name: "Desert Kiosk",
//         userName: "DesertKiosk@desertenv.com"
//     }
// }



//VP-CAO


// export const accountInfo = {
//     account: {
//         name: "M C",
//         userName: "marshall.counts@h2obridge.com"
//     }
// }


//AFE Manager/Users





// export const accountInfo = {
//     account: {
//         name: "C L",
//         userName: "Charles.Lame@h2obridge.com"
//     }
// }



// export const accountInfo = {
//     account: {
//         name: "S B",
//         userName: "Shabana.bingham@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "B B",
//         userName: "Brensen.Bains@h2obridge.com"
//     }
// }


// export const accountInfo = {
//     account: {
//         name: "B G",
//         userName: "Blake.garrett@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "D P",
//         userName: "Dennis.Paul@h2obridge.com"
//     }
// }




// export const accountInfo = {
//     account: {
//         name: "M R",
//         userName: "Michael.Reitz@h2obridge.com"
//     }
// }





// export const accountInfo = {
//     account: {
//         name: "J L",
//         userName: "Jason.Long@h2obridge.com"
//     }
// }

// export const accountInfo = {
//     account: {
//         name: "W D",
//         userName: "Wes.dillards@h2obridge.com"
//     }
// }
