import React, { useState } from 'react';
import { Button, Modal, ModalHeader, FormGroup, ModalBody, ModalFooter, Label, Input } from 'reactstrap';

const ConfirmWithMsgModal = (props) => {

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        buttonLabel,
        id,
        style,
        disabled,
        commentPrompMsg        
    } = props

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [inputComment, setComment] = useState(props.inputComment)
    const [isValid, setIsValid] = useState(true)
    const [isCommentRequired, setIsCommentRequired] = useState((props.isCommentRequired === undefined ? true : props.isCommentRequired))

    const toggleYes = () => {
        console.log(inputComment)
        if (inputComment === "" || inputComment === undefined) {
            setIsValid(false)
        } else {
            setModal(!modal)
            props.onClickYes(inputComment);
            setComment("")
        }

    }

    const onClosed = () => {

        if (props.onClosed !== undefined) {
            props.onClosed();
        }

        setIsValid(true)
        setComment("")
    }

    const onTextChange = (fieldName, fieldValue, e) => {
        console.log("props")
        console.log(fieldName + '=' + fieldValue)

        switch (fieldName) {
            case "comment":
                setComment(fieldValue)
                if (isCommentRequired) { setIsValid(fieldValue !== "") } 
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <Button outline={outline}
                style={style}
                color={color}
                className={className}
                hidden={hidden}
                disabled={disabled}
                onClick={toggle}
            // style={{ margin: "0 5px 0 5px" }}
            >
                {image}
                {buttonLabel}

            </Button>

            <Modal isOpen={modal}
                toggle={toggle}
                onClosed={onClosed} >
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>
                    {message}
                    <FormGroup style={{ marginBottom: "-10px" }}>
                        <Label for="inputComment">
                            {commentPrompMsg}
                        </Label>
                        <Input invalid={!isValid}
                            id="inputComment"
                            name="text"
                            type="textarea"
                            value={inputComment}
                            defaultValue={inputComment}
                            rows={3}
                            onChange={(e) => onTextChange("comment", e.target.value, e)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleYes}>OK</Button>
                    <Button color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default ConfirmWithMsgModal;


/* usage sample code */

/*
   confirmWithComment = (rntCmnt) => {
        console.log("comment return: ")
        console.log(rntCmnt)
    }

    
                        <ConfirmWithMsgModal outline={false}
                                  color="success"
                                  className="float-right"
                                  buttonLabel="confirm with Msg"
                                  hidden ={false}
                                  formName="Confirm remove"
                                  id={-1}
                                  message="Are you sure you want to remove selected from EIB upload?"
                                  commentPrompMsg="Please Enter Delete Comment:"
                                  inputComment="Hau is right"
                                  onClickYes={this.confirmWithComment}                     
                    /> 
*/