import React, { Component} from 'react';
import {Col, Row, Button, FormGroup, Label} from 'reactstrap';
import {apiURL, apiHeader} from '../../resources/apiURL';

import '../../custom.css';

import RefreshIcon from '../../resources/refresh.svg';
import ExcelIcon from '../../resources/excel.svg';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ExcelUtility } from '../../operation/ExcelUtility';
import { ColoredLine, getFirstDayofMonth} from '../../resources/utility';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");
export default class WOListView extends Component{
    constructor (props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.updateGridHeight = this.updateGridHeight.bind(this);
        this.state = {
            woListView: [],
                isLoaded: false,
                // windowHeight:window.innerHeight,
                gridHeight: window.innerHeight * 0.3,
                windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
                startDate: getFirstDayofMonth().toISOString(),
                endDate: new Date().toISOString(),
                
        }
    }

    updateGridHeight = () => {
        const availableHeight = window.innerHeight * 0.65; // Calculate 60% of the window height
        this.setState({ gridHeight: availableHeight });
    }

    
    getListView= () =>{

        let myURI = apiURL + 'Fiix/WorkOrderListView/StartDate/' +
        this.state.startDate.toString().substring(0, 10) +
        '/enddate/' + this.state.endDate.toString().substring(0, 10) 

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                woListView: json
            })
        })
    }

    handleChange(value) {
        this.setState({
            startDate: value,
        });

    }

    handleEndDateChange(value) {
        this.setState({
            endDate: value,
        });

    }


    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.chkPointListView;
    }


    
    handleResize = (e) => {
   
        if (document.getElementById('mainDiv') !== null){
            this.setState({ windowHeight: window.innerHeight,
                windowWidth: document.getElementById('mainDiv').offsetWidth });

        }
    };

    componentDidMount(){
        // window.addEventListener("resize", this.handleResize);
        window.addEventListener('resize', this.updateGridHeight);
        this.getListView()
        this.updateGridHeight()
    }


    componentWillUnmount() {
        // window.addEventListener("resize", this.handleResize);
        window.removeEventListener('resize', this.updateGridHeight);
    } 


    render(){
        const {isLoaded, woListView, windowHeight, gridHeight} = this.state
        // const gridHeight=(windowHeight * 0.65) + "px"
         const searchText = window.$searchText.toLowerCase()
        if(!isLoaded){
            <h3>Loading Work Order List...... </h3>
        }
        return(<div>
             <FormGroup>
                    <Row form>
                        {/* <h2> Work Order List View |</h2> */}
                        <Col sm={12}>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                 onClick={this.getListView}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{ width: "30px", margin: "0 5px 0 0" }} />
                                    Refresh
                                </Button>

                         <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}
                                >
                            <img 
                            src={ExcelIcon}
                            alt="excel"
                            style={{ width: "50px", margin: "0 0 0 0" }}/>
                            Export To Excel
                        </Button>{' '} 

                        </Col>
                            
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>
                <FormGroup>
                    <Row form>

                    <Col md={2}>
                    <Row form>
                        <Col md={12}>
                            <Label>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)} />
                        </Col>
                        </Row>
                    </Col>
                    <Col md={2}>
                    <Row form>
                    <Col md={12}>
                    
                            <Label>
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />

                        </Col>
                     </Row>
                     </Col>
                    </Row>
                </FormGroup>

                <div>
                <FormGroup>
                <Row form style={{ zIndex: 0, position: "relative" }}>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        // height={gridHeight}
                        height={`${gridHeight}px`}
                        width={"100%"}
                        dataSource={woListView.filter(rec => ((rec.woNumber || '').toLowerCase().includes(searchText)) ||
                                                (rec.qrCodeValue || '').toLowerCase().includes(searchText) ||
                                                (rec.basin || '').toLowerCase().includes(searchText) ||
                                                (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                (rec.description || '').toLowerCase().includes(searchText) ||
                                                (rec.workInstructions || '').toLowerCase().includes(searchText) ||
                                                (rec.assignedToUserName || '').toLowerCase().includes(searchText) ||  
                                                (rec.priority || '').toLowerCase().includes(searchText) ||  
                                                (rec.maintenanceType || '').toLowerCase().includes(searchText) ||  
                                                (rec.fiixWOStatus || '').toLowerCase().includes(searchText) ||                                                                 
                                                (rec.createdByName || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        isColumnOptionsEnabled="true"
                        cellTextStyle="14px Calibri"
                        headerTextStyle="14px Calibri"
                        rowHeight={"25"}
                        editMode={0}
                        >         


                        <IgrTextColumn field="woNumber" headerText="WO Number" width="150"  isEditable = "false" />  
                        <IgrTextColumn field="qrCodeValue" headerText="QR Code" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="basin" headerText="Basin" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="150"  isEditable = "false" />  
                        <IgrTextColumn field="description" headerText="Description" width="150"  isEditable = "false" />  
                        <IgrTextColumn field="workInstructions" headerText="Work Instructions" width="170"  isEditable = "false" />    
                        <IgrTextColumn field="createdByName" headerText="Created By" width="150"  isEditable = "false" />        
                        <IgrTextColumn field="assignedToUserName" headerText="Assigned To" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="priority" headerText="Priority" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="maintenanceType" headerText="Maintenance Type" width="200"  isEditable = "false" />
                        <IgrTextColumn field="dblEstimatedTotalCost" headerText="Estimated Total Cost" width="200"  isEditable = "false" />        
                        <IgrTextColumn field="fiixWOStatus" headerText="Status" width="150"  isEditable = "false" />    
                        <IgrTemplateColumn field="availStartDate" headerText="Available Start Date" width="200"  isEditable = "false" 
                        cellUpdating={this.onStatusCellUpdating}/>
                        <IgrTemplateColumn field="availEndDate" headerText="Available End Date" width="200"  isEditable = "false" 
                        cellUpdating={this.onStatusCellUpdating}/>      
                        <IgrTemplateColumn field="dtmDateCreated" headerText="Date Created" width="150"  isEditable = "false" 
                        cellUpdating={this.onStatusCellUpdating}/>  
                        <IgrTemplateColumn field="modifiedDateTime" headerText="Date Modified" width="150"  isEditable = "false"
                          cellUpdating={this.onStatusCellUpdating} 
                          />   
                        <IgrTemplateColumn field="fiixWODateCompleted" headerText="Date Completed" width="200"  isEditable = "false" 
                        cellUpdating={this.onStatusCellUpdating}
                        /> 
                



                    </IgrDataGrid>
                    </Row>
                </FormGroup>
                </div>
      




        </div>)
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";

            content.appendChild(link);

        }
        else{
            link = content.children[0];
        }
    

        switch (s.field) {
            case 'availStartDate':
                if (item.availStartDate) {
                    var date = new Date(item.availStartDate);
                    link.textContent = date.toLocaleDateString();
                } else {
                    link.textContent = '';
                }
                
            
                break;
                case 'availEndDate':
                    if (item.availEndDate) {
                        var date = new Date(item.availEndDate);
                        link.textContent = date.toLocaleDateString();
                    } else {
                        link.textContent = '';
                    }
                    break;
           
            case 'dtmDateCreated':
                if (item.dtmDateCreated) {
                    var date = new Date(item.dtmDateCreated);
                    link.textContent = date.toLocaleDateString();
                } else {
                    link.textContent = '';
                }
            
                break;
                
             case 'modifiedDateTime':
                if (item.modifiedDateTime) {
                    var date = new Date(item.modifiedDateTime);
                    link.textContent = date.toLocaleDateString();
                } else {
                    link.textContent = '';
                }
                break;
            
            case 'fiixWODateCompleted':
                if (item.fiixWODateCompleted) {
                    var date = new Date(item.fiixWODateCompleted);
                    link.textContent = date.toLocaleDateString();  
                } else {
                    link.textContent = '';
                }
                break;  

            default:

        }

    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        
        let gridDts = this.grid.dataSource 
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText 
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                      
            }
        }

        ExcelUtility.save(wb, "WorkOrderListView");
    }
}