import React, { Component } from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputGroup, InputGroupAddon, InputGroupText, Input, CustomInput } from 'reactstrap';
import Select from 'react-select';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import MenuIcon from '../../resources/menu.png';
import Filter from '../../resources/filter.png';
import { FilterFactory } from 'igniteui-react-core';
import { IgcDockManagerComponent, IgcContentPane } from '@infragistics/igniteui-dockmanager';
import { IgcDockManagerPaneType, IgcSplitPaneOrientation } from '@infragistics/igniteui-dockmanager';
import { defineCustomElements } from '@infragistics/igniteui-dockmanager/loader';
import AssetMapView from './AssetMapView';
import AssetMapViewSimple from './AssetMapViewSimple';
import AssetDetails, { getValueList, getValueListData } from './AssetDetails';
import AssetMapListView from './AssetMapListView';

defineCustomElements();


export default class AssetMap extends Component {


    constructor(props) {
        super(props)

        this.state = {
            companyID: "WBR",
            assetCatID: "OP",
            user: props.user,
            windowHeight: "87vh",
            assetViewList: 'all',
            userSecurity: [],
            isUserSecurityLoaded: false,
            isUserAllowAccess: false,
            showAssetLabel: false,
            assetTypeList: [],
            isAssetTypeListLoaded: false,
            basin: [],
            isBasinLoaded: [],
            subSystem: [],
            selAssetType: [],
            selBasin: [],
            selSubSystem: [],
            selAssetStatus: [],
            isSubSystemLoaded: [],
            showPipeline: true,
            showSubsystem:false,
            reloadForm: 0,
            assetFullList: [],
            assetCoor: [],
            assetHierarchy: [],
            selAssetHierarchy: [],
            assetStatus: [],
            assetStatusList: [],
            assetSubType: [],
            assetSubTypeList: [],
            modalAssetMapListView: false,
            basinKMLFile:[],
            subSystemKMLFile:[]
        }
    }

    mapListApplyFiler = (assetList) => {

        console.log("assetList")
        console.log(assetList)

        if (this.state.isAssetCoorLoaded) {
            this.getFitlerAsset(this.state.assetFullList,
                                this.state.selAssetType,
                                this.state.selBasin,
                                this.state.selSubSystem,
                                this.state.selAssetHierarchy,
                                assetList)
        }
    }

    toggleModalAssetMapListView = () => {

        this.getFitlerAsset(this.state.assetFullList,
                            this.state.selAssetType,
                            this.state.selBasin,
                            this.state.selSubSystem,
                            this.state.selAssetHierarchy).then(data => {
                                this.setState({
                                    modalAssetMapListView: !this.state.modalAssetMapListView,
                                })
                            })

    }

    onAssetRadioChanged = (e) => {
        //console.log("Facilitytype: " + e.target.value)
        this.setState({ assetViewList: e.target.value })
        // if (e.target.value === "my"){
        //    this.getMyFacilityData();
        // }else {
        //     this.getAssignFacility();
        // }
    }
    setUserSecurity = (appSecurity) => {
        if (appSecurity.length > 0) {
            const isUserAllowAccess = (appSecurity[0].accessLevel1 > 0)
            const isReadOnly = (appSecurity[0].accessLevel2 !== 0)

            this.setState({
                isAccessReadonly: isReadOnly,
                isUserAllowAccess: isUserAllowAccess
            })

            this.getBasinList()
            this.getSubSystemList()
            this.getAssetTypeList()
            //this.getAssetHierarchy()
            this.getAssetSubType()
            this.getAssetStatus()
            this.getAssetCoordinate()
            this.getBasinKMLFile()
            this.getSubSystemKMLFile()
        } else {

            this.setState({ isUserAllowAccess: false })
        }
    }

    getBasinKMLFile = () => {
      
        let myURI = apiURL + 'Asset/' + this.state.companyID  + '/' + this.state.assetCatID + '/basinkmlfile'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
               this.setState({basinKMLFile:json})

            });
    }

  


    
    getSubSystemKMLFile = () => {
        let myURI = apiURL + 'Asset/' + this.state.companyID  + '/' + this.state.assetCatID + '/subsystemkmlfile'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.createSubSystemKmlList(json).then(data => {
                    this.setState({
                        subSystemKMLFile: data
                    })
                })

            });
    }

    createSubSystemKmlList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.url
                e.label = e.kml_file_desc;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }

    getBasinList = () => {
        getValueListData(this.state.companyID, "basin").then(data => {
            this.setState({
                isBasinLoaded: true,
                basin: data
            })
        })
    }

    getSubSystemList = () => {
        getValueListData(this.state.companyID, "Area").then(data => {
            this.setState({
                isSubSystemLoaded: true,
                subSystem: data
            })
        })
    }

    getAssetTypeList = () => {
        var myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/assetType'
        myURI = myURI + '/ByAssetLevel/1'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log('getAssetHierarchy')
                //console.log(json)


                this.createAssetTypeList(json).then(data => {
                    this.setState({
                        assetTypeList: data,
                        isAssetTypeListLoaded: true
                    })
                })
            });
    }

    createAssetTypeList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.asset_type_id
                e.label = e.asset_type_desc;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }
    getAssetType = () => {
        const { assetTypeID } = this.state

        console.log('getAssetType')

        if (assetTypeID !== "") {
            let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID
                + '/AssetType/ByAssetType/' + this.state.assetTypeID

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    //console.log(json)
                    this.setState({
                        isAssetTypeLoaded: true,
                        assetType: json,
                        formHeaderText: (json.length > 0 ? json[0].asset_type_desc : "")
                    })

                });
        } else {
            this.setState({
                isAssetTypeLoaded: true,
                assetType: [],
                formHeaderText: "All Assets"
            })

        }
    }

    getUserSecurity = () => {

        var tempID = "ALL"

        let myURI = apiURL + 'Asset/' + this.state.companyID + '/AppSecurity/' + this.state.user.account.userName + '/' + tempID

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log('user security')
                //console.log(json)
                this.setUserSecurity(json)
                this.setState({
                    isUserSecurityLoaded: true,
                    userSecurity: json
                })

            });

    }

    onValueChange = (fieldName, fieldValue, e) => {

        console.log('fieldName')
        console.log(e)

        switch (fieldName) {
            case "showAssetLabel":
                this.setState({ showAssetLabel: !this.state.showAssetLabel })
                break;
            case "showPipeline":
                this.setState({ showPipeline: !this.state.showPipeline })
                break;
            case "showSubsystem":
                this.setState({ showSubsystem: !this.state.showSubsystem })
                
                break;
            case "asset_type":
                //this.getFitlerAsset(fullAssetTable, e, this.state.selBasin, this.state.selSubSystem)

                var assetSubTypeList = []
                if (e !== null & e !== undefined) {
                    e.map(a => {
                        this.state.assetSubType.filter(rec => rec.asset_id_list.includes(a.asset_type_id)).map(b => {
                            let tempRec = assetSubTypeList.filter(c => c.asset_subtype_id === b.asset_subtype_id)
                            if (tempRec.length === 0) {
                                assetSubTypeList.push(b)
                            }
                        })

                    })
                }
                console.log(assetSubTypeList)
                this.setState({
                    selAssetType: e,
                    assetSubTypeList: assetSubTypeList
                })
                break;
            case "sub_system":
                //this.getFitlerAsset(fullAssetTable, e.selAssetType, this.state.selBasin, e)
                this.setState({ selSubSystem: e })
                break;
            case "asset_hierarchy":
                this.setState({ selAssetHierarchy: e })
                break;
            case "basin":
                //this.getFitlerAsset(fullAssetTable, e.selAssetType, e, this.state.selSubSystem, this.state.searchText)
                this.setState({ selBasin: e })

                break;
            case "asset_status":
                this.setState({ selAssetStatus: e })
                break;
            case "assetSearch":
                let searchText = fieldValue.toLowerCase()
                this.grid.filterExpressions.clear()
                if (searchText === "") {
                    return
                }

                this.filterFactory = new FilterFactory()
                const column = this.filterFactory.property("asset_name").toLower();
                let filter = column.contains(searchText)

                this.grid.filterExpressions.add(filter);
                this.setState({ searchText: searchText })
                break;

            default:

                break;
        }
    }

    getAssetSubType = () => {
        const { assetCatID, companyID } = this.state
        let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/AssetSubtype'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log(json)
                this.createAssetSubTypeList(json).then(data => {
                    this.setState({
                        assetSubTypeList: data,
                        assetSubType: data
                    })
                })
            })
    }

    createAssetSubTypeList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.asset_subtype_id
                e.label = e.asset_subtype_desc;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }

    getAssetStatus = () => {
        const { assetCatID, companyID } = this.state
        let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/AssetStatus'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log(json)
                this.createAssetStatusList(json).then(data => {
                    this.setState({ assetStatusList: data })
                })
            })
    }

    createAssetStatusList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.asset_status_id
                e.label = e.asset_status_desc;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }


    getAssetHierarchy = () => {
        const { assetCatID, companyID } = this.state
        let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/assetHierarchy'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log(json)

                this.createAssetHirarchyList(json).then(data => {
                    this.setState({ assetHierarchy: data })
                })
            })

    }

    createAssetHirarchyList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.link_asset_type_id
                e.label = e.link_asset_type_desc;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }

    getAssetCoordinate = () => {
        const { assetCatID, selAssetType, selBasin, selSubSystem } = this.state

        console.log('getAssetCoordinate------')
        console.log('assetTypeID -  ' + assetCatID)

        let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/Coordinate'

        console.log("myURI")
        console.log(myURI)
        console.log(this.state.assetCatID)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log(json)
                this.setState({
                    isAssetCoorLoaded: true,
                    assetFullList: json,
                })

            })

    }

    getFitlerAsset = (e, m, b, s, subType, selAsset) => {

        return new Promise((res, rej) => {
            const { user } = this.props

            const assetTypes = m.map(e => e.asset_type_id).join(",")
            const assetSubType = subType.map(e => e.asset_subtype_id).join(',')
            const basins = b.map(e => e.label).join(",")
            const sub_system = s.map(e => e.label).join(",")


            // const userName = user.account.userName.toLowerCase()

            var returnAssets = []

            if (basins.length > 0) {
                const tempList = e.filter(a => a.basin_name !== '').filter(b => basins.includes((b.basin_name)))
                if (assetTypes.length === 0 && assetSubType.length === 0) {
                    returnAssets = tempList
                }

                e = tempList
            }


            if (assetTypes.length > 0) {

                const tempList = e.filter(a => assetTypes.includes(a.asset_type_id))
                returnAssets = tempList
            }


            if (assetSubType.length > 0) {
                var tempList = e.filter(a => assetSubType.includes(a.asset_type_id))
                if (assetTypes.length > 0) {
                    tempList = tempList.filter(a => assetTypes.includes(a.parent_asset_type_id))
                }

                returnAssets = returnAssets.concat(tempList)
            }


            if (selAsset !== undefined){
                
                selAsset.map(e => {
                    const templist = returnAssets.filter( a=> a.parent_assetkey === e.assetkey)
                    selAsset = selAsset.concat(templist)
                })

                // console.log("tempList")
                // console.log(tempList)

                returnAssets = selAsset
            }

            // if (status.length > 0) {
            //     tempAFE = tempAFE.filter(e => status.includes(e.afeStatusDesc))
            // }

            console.log("returnAssets")
            console.log(returnAssets)
            this.setState({
                assetCoor: returnAssets,
                reloadForm: Math.random()
            })

            return res(true)

        })


    }



    applyFilter = () => {
        if (this.state.isAssetCoorLoaded) {
            this.getFitlerAsset(this.state.assetFullList,
                this.state.selAssetType,
                this.state.selBasin,
                this.state.selSubSystem,
                this.state.selAssetHierarchy)
        }
    }

    render() {
        const { windowHeight, isUserSecurityLoaded, isUserAllowAccess, assetSubTypeList,
            assetTypeList, selAssetType, assetCoor, assetHierarchy, selAssetHierarchy,
            selBasin, basin, subSystem, selSubSystem, assetStatusList, selAssetStatus,
            isBasinLoaded, isSubSystemLoaded, isAssetTypeListLoaded,showSubsystem } = this.state

        {

            return (<div style={{ height: windowHeight }}>
                {/* <FormGroup>
                <Row form>
                    <h3> Asset Map | </h3>
                </Row>
            </FormGroup> */}
                <igc-dockmanager id="dockManager">

                    <div className="dockManagerContent"
                        slot="mapFilterPane"
                        id="mapFilterContainer"
                        style={{ paddingRight: "0px" }}>


                        <FormGroup>

                            <div>
                                <CustomInput type="radio"
                                    id="allAsset"
                                    name='asset'
                                    label='All Asset'
                                    value='all'
                                    checked={this.state.assetViewList == 'all'}
                                    onChange={this.onAssetRadioChanged}
                                    inline />
                                <CustomInput type="radio" id="myAsset"
                                    name='asset' label='My Asset'
                                    value='my'
                                    checked={this.state.assetViewList == 'my'}
                                    onChange={this.onAssetRadioChanged}
                                    inline />

                            </div>

                        </FormGroup>
                        <FormGroup>
                            <div>
                                <Row form>
                                    <Col md="12">
                                        <CustomInput className="float-left"
                                            type="switch"
                                            id="showAssetLabel"
                                            name="showAssetLabel"
                                            label="Show Asset Label"
                                            checked={this.state.showAssetLabel}
                                            onClick={(e) => this.onValueChange("showAssetLabel", 1, e)} />
                                    </Col>                                 
                                </Row>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div>
                                <Row form>                           
                                    <Col md="6">
                                        <CustomInput className="float-left"
                                            type="switch"
                                            id="showPipeline"
                                            name="showPipeline"
                                            label="Pipeline"
                                            checked={this.state.showPipeline}
                                            onClick={(e) => this.onValueChange("showPipeline", 1, e)} />
                                    </Col>
                                    <Col md="6">
                                        <CustomInput className="float-left"
                                            type="switch"
                                            id="showSubsystem"
                                            name="showSubsystem"
                                            label="Subsystem"
                                            checked={this.state.showSubsystem}
                                            onClick={(e) => this.onValueChange("showSubsystem", 1, e)} />
                                    </Col>
                                </Row>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label for="typeKey">Asset Type</Label>
                            <Select
                                options={assetTypeList}
                                value={selAssetType}
                                defaultValue={selAssetType}
                                onChange={(e) => this.onValueChange("asset_type", e, e)}
                                isClearable={true}
                                //width="200px"
                                isMulti
                            >
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label for="typeKey">SubType</Label>
                            <Select
                                options={assetSubTypeList}
                                value={selAssetHierarchy}
                                defaultValue={selAssetHierarchy}
                                onChange={(e) => this.onValueChange("asset_hierarchy", e, e)}
                                isClearable={true}
                                //width="200px"
                                isMulti
                            >
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label for="typeKey">Status</Label>
                            <Select
                                options={assetStatusList}
                                value={selAssetStatus}
                                defaultValue={selAssetStatus}
                                onChange={(e) => this.onValueChange("asset_status", e, e)}
                                isClearable={true}
                                //width="200px"
                                isMulti
                            >
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label for="typeKey">Basin</Label>
                            <Select
                                options={basin}
                                value={selBasin}
                                defaultValue={selBasin}
                                onChange={(e) => this.onValueChange("basin", e, e)}
                                isClearable={true}
                                //width="200px"
                                isMulti
                            >
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label for="typeKey">Sub System</Label>
                            <Select
                                options={subSystem}
                                value={selSubSystem}
                                defaultValue={selSubSystem}
                                onChange={(e) => this.onValueChange("sub_system", e, e)}
                                isClearable={true}
                                //width="200px"
                                isMulti
                            >
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Button outline
                                style={{ margin: "0", padding: "0 0 0 0" }}
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.toggleModalAssetMapListView}>
                                <img src={MenuIcon}
                                    alt="Reset"
                                    style={{ width: "20px", margin: "0 0 0 0" }} />
                                Asset List
                            </Button>


                            <Button outline
                                style={{ margin: "0", padding: "0 0 0 0" }}
                                color="secondary"

                                className="btn-no-border float-right"
                                onClick={this.applyFilter}>
                                <img src={Filter}
                                    alt="Reset"
                                    style={{ width: "20px", margin: "0 0 0 0" }} />
                                Apply Filter
                            </Button>


                        </FormGroup>
                    </div>


                    <div className="dockManagerContent"
                        slot="mapPane"
                        id="mapContainer">
                        <AssetMapViewSimple
                            key={this.state.reloadForm}
                            companyID={this.state.companyID}
                            user={this.props.user}
                            showPipeline={this.state.showPipeline}
                            showLabel={this.state.showAssetLabel}
                            showSubsystem={this.state.showSubsystem}
                            basinKMLFile={this.state.basinKMLFile}
                            subSystemKMLFile = {this.state.subSystemKMLFile}
                            assetCoor={assetCoor}
                        >

                        </AssetMapViewSimple>
                    </div>


                </igc-dockmanager>


                <div>
                    <Modal isOpen={this.state.modalAssetMapListView}
                        className="alway-on-top"
                        toggle={() => this.toggleModalAdd()}
                        size="xl"
                        //onClosed={() => this.getRiser()}
                        backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleModalAssetMapListView()}>
                            {"Map View"}
                        </ModalHeader>
                        <ModalBody>

                            <AssetMapListView
                                toggle={this.toggleModalAssetMapListView}
                                user={this.props.user}
                                assetData={assetCoor}
                                mapListApplyFiler = {this.mapListApplyFiler}
                            >

                            </AssetMapListView>

                        </ModalBody>

                    </Modal>
                </div>
            </div>
            )
        }
    }

    createDockPanel = () => {
        window.addEventListener("resize", this.handleResize);

        var { dayCounts, selectedFacility } = this.state;
        console.log('daycount:' + dayCounts)
        const { isLoaded } = this.state;

        const mapFilterPane = this.createContentPane('mapFilterPane', 'Map Filter', 100)

        const mapPane = this.createContentPane('mapPane', 'Waterbridge Assets', 200)
        var dockManager = document.getElementById("dockManager");

        if (dockManager !== null) {


            dockManager.layout = {
                rootPane: {
                    type: IgcDockManagerPaneType.splitPane,
                    orientation: IgcSplitPaneOrientation.horizontal,
                    panes: [
                        {
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.vertical,
                            size: 60,
                            panes: [mapFilterPane]
                        },
                        {
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.vertical,
                            size: 300,
                            panes: [{
                                type: IgcDockManagerPaneType.splitPane,
                                orientation: IgcSplitPaneOrientation.vertical,
                                size: 100,
                                panes: [mapPane]
                            },
                            ]
                        }
                    ]
                }
            };
        }
    }

    componentDidMount() {

        //window.addEventListener("resize", this.handleResize);
        this.getUserSecurity()
        this.createDockPanel()
        // this.getAssetCoordinate()

    }

    createContentPane(contentID, paneHeader, size) {
        const pane = {
            size: size,
            header: paneHeader,
            type: IgcDockManagerPaneType.contentPane,
            contentId: contentID
        };
        return pane;
    }

    createSplitPane(orientation, contentPanes, size) {
        const pane = {
            type: IgcDockManagerPaneType.splitPane,
            orientation: orientation,
            panes: contentPanes,
            size: size
        };
        return pane;
    }
    createTabPane(orientation, contentPanes, size) {
        const pane = {
            type: IgcDockManagerPaneType.documentHost,
            size: size,
            rootPane: {
                type: IgcDockManagerPaneType.splitPane,
                orientation: orientation,
                panes: [
                    {
                        type: IgcDockManagerPaneType.tabGroupPane,
                        panes: contentPanes
                    }
                ]
            }
        };
        return pane;
    }
}