//Created by BN 1/19/22


import React, { Component } from 'react';

import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, 
        FormFeedback, InputGroup } from 'reactstrap';
import { apiURL, apiHeader,apiPUTHeader,apiPOSTHeader,apiDELETEHeader } from '../resources/apiURL';

import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import ImageViewer from '../resources/ImageViewer';

import ConfirmModal from '../operation/ConfirmModal'
// import  './format.css'


var DatePicker = require("reactstrap-date-picker");


export default class ItemTypeDetails extends Component {
    static displayName = ItemTypeDetails.name
    

    constructor(props){
        super(props)

        this.state={
            inventoryPropertyMapKey: props.itemTypeLinkKey,
            itemTypeDetails : props.itemTypeDetails,
            isRequired : props.isRequired,
            isHasValueList: props.isHasValueList,
            refreshData: props.refreshData,
            propsDataType: [],
            isDataTypeLoaded: false,
            invalidDataItem:{
                itemField: true,
                itemFieldDesc: true,
                dataType: true,
            }
        }
    }

    closeForm=(refreshData2)=>{
        if(refreshData2){
           
            this.props.refreshData2()
        }
        this.props.toggle()
        
    }

    saveData=() =>{
        const {itemTypeDetails,inventoryPropertyMapKey} = this.state 

        if(this.validateData()){
           

            
        if (inventoryPropertyMapKey<0) {            
            this.createItemTypePropsCreate()
        }else{
            this.updateItemTypeProps()
            
        }
        // this.setState({isHasValueList: false,
        //                 isRequired: false })


        this.closeForm(true);
    
    }
      
     }

     createItemTypePropsCreate = () =>{

        const {grpDetails} = this.state   

        const itemTypePropsCreateDTO= this.createItemTypePropsCreateDTO()


          let myURI = apiURL + 'ItemMaster/ItemPropertyMap'
        
          fetch(myURI , apiPOSTHeader(itemTypePropsCreateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){

                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
             

            })
            .catch(error =>{

            })

         
            this.closeForm(true);

     }

    

     updateItemTypeProps = () => {
        const {requiredKey} = this.state 
        const itemTypePropsUpdateDTO= this.createItemTypePropsUpdateDTO()

        let myURI = apiURL + 'ItemMaster/ItemPropertyMap'

          fetch(myURI , apiPUTHeader(itemTypePropsUpdateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){

                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
            })
            .catch(error =>{

            })

            this.closeForm(true);
    }

    createItemTypePropsCreateDTO=()=>{
        const {itemTypeDetails} = this.state 
        const {user} = this.props
        const itemTypePropsCreateDTO = {
            
            "itemPropertyMapKey": itemTypeDetails.itemPropertyMapKey,
            "itemTypeKey":itemTypeDetails.itemTypeKey,
            "itemField": itemTypeDetails.itemField,
            "dataType": itemTypeDetails.dataType,
            "defaultValue": itemTypeDetails.defaultValue,
            "isRequired": itemTypeDetails.isRequired,
            "hasValueList": itemTypeDetails.hasValueList,
            "valueList": itemTypeDetails.valueList,
            "itemFieldDesc": itemTypeDetails.itemFieldDesc,
            "createdBy": getUserADAccount(user.account.userName)
             //USER GROUP KEY OUTPUT????

         }
         return  itemTypePropsCreateDTO
    }

    

    createItemTypePropsUpdateDTO=()=>{
        const {itemTypeDetails, inventoryPropertyMapKey} = this.state
        var {isHasValueList} = this.state
        const {user} = this.props
        const itemTypePropsUpdateDTO={
            "itemPropertyMapKey": inventoryPropertyMapKey,
            "itemField": itemTypeDetails.itemField,
            "dataType": itemTypeDetails.dataType,
            "defaultValue": itemTypeDetails.defaultValue,
            "isRequired": itemTypeDetails.isRequired,
            "hasValueList": itemTypeDetails.hasValueList,
            "valueList": isHasValueList == false ? "" : itemTypeDetails.valueList,
            "itemFieldDesc": itemTypeDetails.itemFieldDesc,
            "modifiedBy": getUserADAccount(user.account.userName)
        }

        return itemTypePropsUpdateDTO
        
    }

    validateData=()=>{
        const{itemTypeDetails, invalidDataItem} = this.state
        var bVal = true 
        var valItem = invalidDataItem

        if(itemTypeDetails.itemField == ""){
            bVal = false
            valItem.itemField= false
        }


        if(itemTypeDetails.itemFieldDesc == ""){
            bVal = false
            valItem.itemFieldDesc= false
        }

        if(itemTypeDetails.dataType == ""){
            bVal = false
            valItem.dataType= false
        }

        this.setState({invalidDataItem:valItem})
        
        return bVal
    }

    onTextChange=(fieldName,fieldValue,e)=>{

        const {itemTypeDetails, invalidDataItem, isHasValueList}=this.state;

        
        switch(fieldName){
            case "itemField":
                itemTypeDetails.itemField=fieldValue
                invalidDataItem.itemField=(fieldValue != "")
                break;

             case "dataType":
                itemTypeDetails.dataType=fieldValue
                invalidDataItem.dataType=(fieldValue != "")
                break;
           
            case "defaultValue":
                itemTypeDetails.defaultValue=fieldValue
                    // invalidDataItem.itemDesc=(fieldValue != "")
                    break;
            case "isRequired":
                    itemTypeDetails.isRequired=fieldValue
                    this.setState({isRequired: (fieldValue!=0)})
                    // invalidDataItem.itemDesc=(fieldValue != "")
                     break;
            case "hasValueList":
                itemTypeDetails.hasValueList=fieldValue
                this.setState({isHasValueList: (fieldValue!=0)})
        

                // invalidDataItem.itemDesc=(fieldValue != "")
                break; 
                case "valueList":
                    itemTypeDetails.valueList=fieldValue
                   
                    // invalidDataItem.itemDesc=(fieldValue != "")
                    break;
                    
             case "itemFieldDesc":
                    itemTypeDetails.itemFieldDesc=fieldValue
                    
                    invalidDataItem.itemFieldDesc=(fieldValue != "")
                    break;
            default:

        }

   
        
        this.setState({itemTypeDetails:itemTypeDetails,
                       invalidDataItem:invalidDataItem
                       
  
                })
    }

    getPropsDataType = ()=> {
            
        let myURI = apiURL + 'ItemMaster/PropsDataType' 
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isDataTypeLoaded: true,
                propsDataType: json
            })
        })

        }

        componentDidMount(){
            this.getPropsDataType()
        }

    

    render(){
        const {itemTypeDetails, propsDataType, isDataTypeLoaded, invalidDataItem, inventoryPropertyMapKey} = this.state

        var {isRequired, isHasValueList} = this.state
    if (!isDataTypeLoaded){
        return (<div>
            <h3>Loading Data Type .....</h3>
        </div>)
    }else  {   
        return(<div>

            <Form>
            <Row form>


            <Col md ={6}>
                    <FormGroup>
                        <Label for="itemField">Item Field</Label>
                        <Input 
                        type="text"
                        name="itemField"
                        id="itemField"
                        defaultValue= {itemTypeDetails.itemField}
                        invalid={!invalidDataItem.itemField}
                        // value={itemTypeDetails.itemField}
                        onChange={(e) => this.onTextChange("itemField",e.target.value,e)}
                        // placeHolder=""
                        >
                        </Input>
                        <FormFeedback tooltip > Please enter Item Field</FormFeedback>
                    </FormGroup>

                </Col>

            <Col md ={6}>
                        <FormGroup>
                            <Label hidden={inventoryPropertyMapKey<0 ? true: false} for="valueList">Prop #</Label>
                            <Input 
                            type="text"
                            name="defaultValue"
                            id="defaultValue"
                            disabled
                            hidden={inventoryPropertyMapKey<0 ? true: false}
                            defaultValue={itemTypeDetails.propField}
                            // value={itemTypeDetails.defaultValue}
                            onChange={(e) => this.onTextChange("defaultValue",e.target.value,e)}
                            placeHolder=""
                            >
                            </Input>
                        
                        </FormGroup>

                    </Col>

               


                </Row>


                <Row form>

                <Col md ={6}>
                    <FormGroup>
                        <Label for="dataType">Data Type</Label>
                        <CustomInput 
                        type="select"
                        name="dataType"
                        id="dataType"
                        invalid={!invalidDataItem.dataType}
                        // value={itemTypeDetails.dataType}
                        defaultValue={itemTypeDetails.dataType}
                        placeHolder=""
                        onChange={(e) => this.onTextChange("dataType",e.target.value,e)}
                        >
                       
                        <option value={null}></option> 
                        {propsDataType.map(v => <option value={v.propsDataTypeID}>{v.propsDataTypeDesc}</option>)} 
                        </CustomInput>
                        <FormFeedback tooltip > Please select a Data Type</FormFeedback>
                    </FormGroup>

                </Col>

                
                <Col md ={6}>
                    <FormGroup>
                        <Label for="itemField">Item Field Description</Label>
                        <Input 
                        type="text"
                        name="itemFieldDescription"
                        id="itemFieldDescription"
                       
                        defaultValue={itemTypeDetails.itemFieldDesc}
                        invalid={!invalidDataItem.itemFieldDesc}
                        // value={itemTypeDetails.itemFieldDesc}
                        placeHolder=""
                        onChange={(e) => this.onTextChange("itemFieldDesc",e.target.value,e)}
                        >
                    
                        </Input>
                        <FormFeedback tooltip > Please enter Item Field Description</FormFeedback>
                    </FormGroup>

                </Col>
    

               

              
              
                </Row>

                <Row form>

                <Col md={6}>
                      <FormGroup>
                                    <Row form>
                                     <Col md={4}>
                                        <Label></Label>
                                    </Col>
                                    </Row>

                                     <Row form>
                                     <Col md={4}>
                                        <Label>Has Value?</Label>
                                    </Col>
                                    <Col md={4}>
                                        <CustomInput type="checkbox" 
                                        id="hasValueYes" 
                                        label="Yes"
                                        checked = {isHasValueList} 
                                        inline 
                                        // defaultValue={itemTypeDetails.valueList}
                                        onChange = {(e) => this.onTextChange("hasValueList",1,e)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <CustomInput type="checkbox"
                                        id="hasValueNo" 
                                        label="No" inline
                                        checked = {!isHasValueList}  
                                        // defaultValue={itemTypeDetails.valueList}
                                        onChange = {(e) => this.onTextChange("hasValueList",0,e)}
                                        
                                        />
                                    </Col>
                                </Row>


                                     
                                

                         </FormGroup>

                         
                    </Col>

                

                  



                    <Col md ={6}>
                        <FormGroup>
                            <Label for="valueList">Value List</Label>
                            <Input 
                            type="text"
                            name="valueList"
                            id="valueList"
                            disabled={!isHasValueList}
                            defaultValue={itemTypeDetails.valueList}
                            value={isHasValueList === false ? "" : itemTypeDetails.valueList}
                            onChange={(e) => this.onTextChange("valueList", e.target.value,e)}
                            placeHolder=""
                            >
                            </Input>
                        
                        </FormGroup>
                        <Label
                        style={{fontWeight: "bold"}}
                        hidden={!isHasValueList}>Note: Enter "/" between each value.</Label>
                    </Col>



                    </Row>

                    <Row form>
                        <Col md={6}>

                        <FormGroup>
                                        <Row form>
                                        <Col md={4}>
                                            <Label></Label>
                                        </Col>
                                        </Row>

                                        <Row form>
                                        <Col md={4}>
                                            <Label>Is Required?</Label>
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" 
                                            id="inServiceYes" 
                                            label="Yes"
                                            checked = {isRequired} 
                                            inline 
                                        
                                            onChange = {(e) => this.onTextChange("isRequired",1,e)}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                            id="inServiceNo" 
                                            label="No" inline
                                            checked = {!isRequired}  
                                            onChange = {(e) => this.onTextChange("isRequired",0,e)}
                                            
                                            />
                                        </Col>
                                    </Row>


                            </FormGroup>
                        </Col>


                        
                <Col md ={6}>
                        <FormGroup>
                            <Label for="valueList">Default Value</Label>
                            <Input 
                            type="text"
                            name="defaultValue"
                            id="defaultValue"
                            defaultValue={itemTypeDetails.defaultValue}
                            // value={itemTypeDetails.defaultValue}
                            onChange={(e) => this.onTextChange("defaultValue",e.target.value,e)}
                            placeHolder=""
                            >
                            </Input>
                        
                        </FormGroup>

                    </Col>
                    </Row>






                    <Row>
                    <Col md={3}>

                  
                     </Col>                    
           

                     <Col sm={8}>
                     <Button color="primary" className="float-right" 
                             onClick={() => this.closeForm(true)}
                             >Cancel</Button>
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> this.saveData()}
                             >Save</Button>
                     </Col>
                     </Row>



                
            </Form>

        </div>)
    }
  }
}