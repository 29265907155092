import React, { Component } from 'react';

import { Form, FormFeedback,Col, Row, Button, FormGroup, Label, Input, CustomInput, InputGroupAddon, InputGroup } from 'reactstrap';
import { apiURL, apiDELETEHeader,apiHeader,apiPUTHeader,apiPOSTHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import { USDCurrencyFormat, NumberFormat } from '../resources/utility'
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount ,getRandomInt} from '../resources/utility'
import ConfirmModal from './ConfirmModal'
import ImageViewer from '../resources/ImageViewer';
import TrashBin from '../resources/trash.svg'


var DatePicker = require("reactstrap-date-picker");


export default class CostDetail extends Component {
    static displayName = CostDetail.name;

    constructor(props) {
        super(props)

        // console.log("facilityDailyOpCostKey")
        // console.log(props.FacilityDailyOpCostKey)


        this.state = {
            costType: [],
            isTypeLoaded: false,
            costCode: [],
            isCodeLoaded: false,
            facilityDailyOpCostKey: props.FacilityDailyOpCostKey,
            facOpCost: [],
            isFacOpCostLoaded: false,
            isReadOnly:false,
            svrStartDate:null,
            svrEndDate:null,
            facility:[],
            offLineLinkKey: (getRandomInt(10000) * -1),
            validDataItem:{
                svrStartDate:true,
                costCodeKey:true,
                costTypeKey:true,
                facilityKey:true,
                extCurCost:true
            }
        }
    }

    closeForm = () => {
        this.props.toggle()
    }

    saveData = () => {
        const {facilityDailyOpCostKey} = this.state

        if (this.validateData()){

        
        if (facilityDailyOpCostKey > 0) {
            this.updateCost()
        }else{
            this.addCost()
        }
        
        this.props.refreshData()
        this.closeForm()
        }
    }

    validateData = () => {
        const {validDataItem, facOpCost} = this.state
        var bVal = true
        var valItem = validDataItem

        if (facOpCost[0].facilityKey === null || facOpCost[0].facilityKey==0){
            valItem.facilityKey = false
            bVal=false
        }

        if (facOpCost[0].svrStartDate === null || facOpCost[0].svrStartDate==""){
            valItem.svrStartDate = false
            bVal=false
        }
        if (facOpCost[0].costCodeKey === null || facOpCost[0].costCodeKey==0){
            valItem.costCodeKey = false
            bVal=false
        }

        if (facOpCost[0].costTypeKey === null || facOpCost[0].costTypeKey==0){
            valItem.costTypeKey = false
            bVal=false
        }
        if (facOpCost[0].extCurCost === null || facOpCost[0].extCurCost<=0){
            valItem.extCurCost = false
            bVal=false
        }
        this.setState({validDataItem,valItem})
        return bVal
    }

    addCost = () => {
        const costCreateDTO=this.createCostCreateDTO()
        // console.log("costCreateDTO")
        // console.log(costCreateDTO)

        let myURI = apiURL + 'FacilityDailyCost'
          fetch(myURI , apiPOSTHeader(costCreateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
              console.log("No error here: " + rspData)
            })
            .catch(error =>{
              console.log("POST Error: " + error);
            })

    }
    updateCost = () =>{
        const costUpdateDTO=this.createCostUpdateDTO()
        console.log(costUpdateDTO)

        let myURI = apiURL + 'FacilityDailyCost'
          fetch(myURI , apiPUTHeader(costUpdateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
              console.log("No error here: " + rspData)
            })
            .catch(error =>{
              console.log("PUT Error: " + error);
            })

    }

    createCostCreateDTO = () => {
        const {facOpCost,offLineLinkKey} = this.state
        const {user} = this.props

        const costCreateDTO = {
        "facilityKey": facOpCost[0].facilityKey,
        "reportDate": facOpCost[0].svrStartDate,
        "svrStartDate": facOpCost[0].svrStartDate,
        "svrEndDate": facOpCost[0].svrEndDate,
        "supplierKey": facOpCost[0].supplierKey,
        "costCodeKey": facOpCost[0].costCodeKey,
        "costTypeKey": facOpCost[0].costTypeKey,
        "costDescription": facOpCost[0].costDescription,
        "curCost": facOpCost[0].extCurCost,
        "stdCost": facOpCost[0].extCurCost,
        "qty": facOpCost[0].qty,
        "unitKey": facOpCost[0].unitKey,
        "comments": facOpCost[0].comments,
        "createdBy": getUserADAccount(user.account.userName),
        "latitude": facOpCost[0].latitude,
        "longtitude": facOpCost[0].longtitude,      
        "offLineLinkKey": offLineLinkKey, 
        "facilityEventKey": facOpCost[0].facilityEventKey,
        "supplierName": facOpCost[0].supplierName 
        }

        return costCreateDTO;
    }

    createCostUpdateDTO = () => {
        const {facOpCost} = this.state
        const {user} = this.props

        const costUpdateDTO = {
        "facilityDailyOpCostKey": facOpCost[0].facilityDailyOpCostKey,
        "facilityKey": facOpCost[0].facilityKey,
        "reportDate": facOpCost[0].svrStartDate,
        "svrStartDate": facOpCost[0].svrStartDate,
        "svrEndDate": facOpCost[0].svrEndDate,
        "supplierKey": facOpCost[0].supplierKey,
        "costCodeKey": facOpCost[0].costCodeKey,
        "costTypeKey": facOpCost[0].costTypeKey,
        "costDescription": facOpCost[0].costDescription,
        "curCost": facOpCost[0].extCurCost,
        "stdCost": facOpCost[0].extCurCost,
        "qty": facOpCost[0].qty,
        "unitKey": facOpCost[0].unitKey,
        "comments": facOpCost[0].comments,
        "modifiedBy": getUserADAccount(user.account.userName),
        "latitude": facOpCost[0].latitude,
        "longtitude": facOpCost[0].longtitude,
        "logLatitude": null,
        "logLongtitude": null,
        "facilityEventKey": facOpCost[0].facilityEventKey,
        "supplierName": facOpCost[0].supplierName 
        }

        return costUpdateDTO;
    }

    getCostType = () => {
        let myURI = apiURL + 'FacilityDailyCost/type'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isTypeLoaded: true,
                    costType: json
                })
            });
    }

    getCostCode = () => {
        let myURI = apiURL + 'FacilityDailyCost/Code'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isCodeLoaded: true,
                    costCode: json
                })
            });
    }

    getFacility = () => {
        const {user}=this.props;

        let myURI = apiURL + 'FacilityDetails/AzureAD/' + user.account.userName

        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                facility: json
            })
        })      
    }

    getFacilityDailyOpCost = () => {
        const { facilityDailyOpCostKey } = this.state;

        if (facilityDailyOpCostKey > 0){
            let myURI = apiURL + 'FacilityDailyCost/' + facilityDailyOpCostKey
            console.log("facilityDailyOpCostKey")
            console.log(facilityDailyOpCostKey)

            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    this.setState({
                        isFacOpCostLoaded: true,
                        facOpCost: json,
                        svrStartDate:new Date(json[0].svrStartDate).toISOString(),
                        svrEndDate:new Date(json[0].svrEndDate).toISOString()
                    })
                });
        }else{
            const newCost = this.createNewCostRecord()
            this.setState({isFacOpCostLoaded:true,
                         facOpCost: newCost,
                         svrStartDate:new Date().toISOString(),
                        svrEndDate:new Date().toISOString()})
        }
    }

    createNewCostRecord = () => {
        
        const {user} = this.props

        const newCostRecord = ({
        "facilityDailyOpCostKey": -1,
        "facilityKey": 0,
        "reportDate": new Date().toISOString(),
        "svrStartDate": new Date().toISOString(),
        "svrEndDate": new Date().toISOString(),
        "createdDatetime:": new Date().toISOString(),
        "supplierKey": 0,
        "costCodeKey": 0,
        "costTypeKey": 0,
        "costDescription": "",
        "curCost": 0,
        "stdCost": 0,
        "extCurCost":0,
        "qty": 1,
        "unitKey": null,
        "comments": "",
        "modifiedBy": "",
        "createdByName" : user.account.name,
        "latitude": 0,
        "longtitude": 0,
        "logLatitude": null,
        "logLongtitude": null,
        "facilityEventKey": null,
        "supplierName": "" 
        })
        var newCost = [newCostRecord]
        return newCost;
    }


    setCostDescription=(costKey) =>{
        const {costCode}=this.state;
        
        const costRec = costCode.filter(f => (f.costCodeKey == costKey))
        console.log("costRec")
        console.log(costRec)

        if (costRec.length>0){
            return costRec[0].costDesc
        }else{
            return ""
        }

    }

    onTextChange=(fieldName,fieldValue,e)=>{
        console.log("props")
        console.log(fieldName + '=' + fieldValue)
        const {facOpCost,validDataItem}=this.state;
        // if (fieldName==="ticketNo"){
            
        // }       
        
        switch(fieldName){
            case "svrStartDate":
               
                facOpCost[0].svrStartDate = fieldValue
                validDataItem.svrStartDate = true

                this.setState({svrStartDate:fieldValue})
               
                break;
            case "svrEndDate":                
                facOpCost[0].svrEndDate = fieldValue
                this.setState({svrEndDate:fieldValue})
               break;
            case "comments":
                facOpCost[0].comments=fieldValue
                break;
            case "costCode1":
                facOpCost[0].costDescription=this.setCostDescription(fieldValue)  
                validDataItem.costCodeKey = true              
                facOpCost[0].costCodeKey=fieldValue
            break;
            case "costDesc":
                facOpCost[0].costDescription=this.setCostDescription(fieldValue)
                validDataItem.costCodeKey = true
                facOpCost[0].costCodeKey=fieldValue
                break;

            case "costDescription":
                facOpCost[0].costDescription=fieldValue
                break;
                
            case "costTypeKey":
                facOpCost[0].costTypeKey = fieldValue
                validDataItem.costTypeKey = true
                break;
            case "facilityName":
                facOpCost[0].facilityName = fieldValue
                break;
            case "extCurCost":
                facOpCost[0].extCurCost = fieldValue
                if (fieldValue > 0){
                    validDataItem.extCurCost = true
                }
                
                break;
            case "supplierName":
                facOpCost[0].supplierName = fieldValue
                break;   
            case "facilityKey":
                facOpCost[0].facilityKey = fieldValue       
                validDataItem.facilityKey = true
            default:

        }
        
        this.setState({facOpCost:facOpCost,validDataItem:validDataItem})
    }

    deleteCost=()=>{
        
        const deleteCostDTO = this.createDeleteCostDTO()
        console.log(deleteCostDTO)

        let myURI = apiURL + 'FacilityDailyCost'
        fetch(myURI , apiDELETEHeader(deleteCostDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
              console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);
              
            }
    
            console.log("No error here: " + rspData)
          })
          .catch(error =>{
            console.log("DELETE Error: " + error);
          })

          
            this.props.refreshData()
          this.closeForm()

    }

    createDeleteCostDTO = () => {
        
        const {user} = this.props
        const {facilityDailyOpCostKey} = this.state
        const delCostRecord = ({
        "facilityDailyOpCostKey": facilityDailyOpCostKey,       
        "deletedBy" : user.account.name,
        "logLatitude": null,
        "logLongtitude": null
        })
        
        return delCostRecord;
    }

    componentDidMount() {
        this.getFacility()
        this.getCostType()
        this.getCostCode()
        this.getFacilityDailyOpCost()
    }
    render() {
        const { costType, costCode, isFacOpCostLoaded, offLineLinkKey,
                facOpCost,isReadOnly,facilityDailyOpCostKey,
                svrStartDate,svrEndDate,facility,validDataItem } = this.state;
        console.log(facOpCost)

        if (!isFacOpCostLoaded) {
            return (<div>
                <h3>Loading facility cost details.....</h3>
            </div>)
        } else if (facOpCost.length <= 0) {
            return (<div>
                <h3>Cost Details Not Found.....</h3>
            </div>)
        } else
            return (<div>
                <Form>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="costType">Cost Type</Label>
                                <CustomInput invalid={!validDataItem.costTypeKey}
                                    type="select"
                                    id="costType"
                                    name="costType"
                                    defaultValue={facOpCost[0].costTypeKey}                                    
                                    disabled={isReadOnly}
                                    onChange={(e) => this.onTextChange("costTypeKey",e.target.value,e)}
                                >
                                    <option value={0}></option>
                                    {costType.map(v => <option value={v.costTypeKey}>{v.costTypeDesc}</option>)}
                                </CustomInput>
                                
                                <FormFeedback tooltip> Please select cost type</FormFeedback>
                                
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="createdBy">Created By</Label>
                                <Input disabled type="text" name="createdBy" id="createdBy" placeholder="" value={facOpCost[0].createdByName} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="startDate">Service Start Date</Label>
                                <DatePicker invalid={!validDataItem.svrStartDate} id="startDate" value={svrStartDate}
                                    disabled={isReadOnly}
                                    onChange={(v, f) => this.onTextChange("svrStartDate",v, f)} />
                                    <FormFeedback tooltip>Please select service start date</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="createdDate">Created Date</Label>
                                {/* <Input type="text" name="createdDate" id="createdDAte"
                                    disabled placeholder="" 
                                    value={new Date(facOpCost[0].createdDatetime).toLocaleDateString()} /> */}

                                    <DatePicker id="createdDAte" 
                                    value={(facilityDailyOpCostKey<0?svrStartDate : facOpCost[0].createdDatetime)}
                                    disabled/>
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="endDate">Service End Date</Label>
                                <DatePicker id="endDate" 
                                value={svrEndDate}
                                onChange={(v, f) => this.onTextChange("svrEndDate",v, f)} 
                                disabled={isReadOnly}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="facilityName">Facility Name</Label>


                                {/* <Input disabled type="text" name="facilityName" id="facilityName"
                                    placeholder="" 
                                    defaultValue={facOpCost[0].facilityName} /> */}

                                <CustomInput invalid={!validDataItem.facilityKey}
                                     type="select"
                                    id="facilityName"
                                    name="facilityName"
                                    defaultValue={facOpCost[0].facilityKey}
                                    value={facOpCost[0].facilityKey}                                    
                                    disabled={isReadOnly}
                                    onChange={(e) => this.onTextChange("facilityKey",e.target.value,e)}
                                >
                                    <option value={0}></option>
                                    {facility.map(v => <option value={v.facilityKey}>{v.facilityName}</option>)}
                                </CustomInput>
                                <FormFeedback tooltip> Please select facility</FormFeedback>
                                

                            </FormGroup>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="amount">Amount</Label>
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Input invalid={!validDataItem.extCurCost} type="number" name="amount" id="amount"
                                    onChange={(e) => this.onTextChange("extCurCost",e.target.value,e)}
                                    disabled={isReadOnly}
                                    defaultValue={facOpCost[0].extCurCost} 
                                    // defaultValue={USDCurrencyFormat(facOpCost[0].extCurCost)} 
                                    />
                                    <FormFeedback tooltip> {"Amount must be > 0"}</FormFeedback>
                                </InputGroup>
                                
                                
                                
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="supplier">Supplier</Label>
                               
                                <Input type="text" name="supplier" id="supplier"
                                    onChange={(e) => this.onTextChange("supplierName",e.target.value,e)}
                                    disabled={isReadOnly} defaultValue={facOpCost[0].supplierName} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={9}>
                            <FormGroup>
                                <Label for="costDesc">Cost Desc</Label>

                                <CustomInput invalid={!validDataItem.costCodeKey} type="select"
                                    id="costDesc"
                                    name="costDesc"
                                    defaultValue={facOpCost[0].costCodeKey}
                                    value={facOpCost[0].costCodeKey}                                    
                                    disabled={isReadOnly}
                                    onChange={(e) => this.onTextChange("costDesc",e.target.value,e)}
                                >
                                    <option value={0}></option>
                                    {costCode.map(v => <option value={v.costCodeKey}>{v.costDesc}</option>)}
                                </CustomInput>

                                <FormFeedback tooltip> Please select cost desc</FormFeedback>
                                

                                {/* <Input type="text" name="costDesc" id="costDesc" placeholder="Cost Code Description" /> */}
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="costCode">Cost Code</Label>
                                <CustomInput invalid={!validDataItem.costCodeKey} type="select"
                                    id="costCode"
                                    name="costCode"
                                    defaultValue={facOpCost[0].costCodeKey}
                                    value={facOpCost[0].costCodeKey}
                                    onChange={(e) => this.onTextChange("costCode1",e.target.value,e)}
                                    disabled={isReadOnly}
                                >
                                    <option value={0}></option>
                                    {costCode.map(v => <option value={v.costCodeKey}>{v.costCode1}</option>)}
                                </CustomInput>
                                
                                <FormFeedback tooltip> Please select costcode</FormFeedback>
                                
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="description">Description</Label>
                        <Input type="text" name="description" id="description"
                             onChange={(e) => this.onTextChange("costDescription",e.target.value,e)}
                            disabled={isReadOnly} 
                            defaultValue={facOpCost[0].costDescription}
                            value={facOpCost[0].costDescription} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="comments">Comments</Label>
                        <Input type="textarea" name="comments" id="comments" 
                         onChange={(e) => this.onTextChange("comments",e.target.value,e)}
                        disabled={isReadOnly} defaultValue={facOpCost[0].comments} />
                    </FormGroup>

                    <FormGroup>
                    <Row>
                    
                    <Col md={3}>
                            
                     <ConfirmModal outline={true}
                                  color="danger"
                                  className="float-left"
                                  buttonLabel="Delete"
                                  hidden = {(facilityDailyOpCostKey<=-1)}
                                  formName="Confirm"
                                  id={facilityDailyOpCostKey}
                                  message="Are you sure you want to delete selected cost?"
                                  onClickYes={(e) => this.deleteCost()}
                                  image={<img
                                    src={TrashBin}
                                    id={facilityDailyOpCostKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }}/>}>
                        </ConfirmModal>         

                    </Col>


                    <Col md={4}>

                       <ImageViewer buttonLabel="Documents" 
                                        buttonColor="primary" 
                                        formName="Cost Documents"  
                                        hasDoc={1}
                                        //hidden = {(facilityDailyOpCostKey<=-1)}
                                        className={"costDetail-modal-style"}
                                        linkTableName={"topFacilityDailyOpCost"}
                                        linkPrimaryKey={(facilityDailyOpCostKey<0 ? offLineLinkKey:facilityDailyOpCostKey)}
                                        inspAPIURL={apiURL + 'FacilityDailyCost/Documents/' + (facilityDailyOpCostKey<0 ? offLineLinkKey:facilityDailyOpCostKey)} />

                            
                     </Col>

                     <Col sm={5}>                    
                     <Button color="primary" className="float-right" 
                             onClick={() => this.closeForm()}>Cancel</Button>
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> this.saveData()}>OK</Button>
                     </Col>
                     </Row>
                    </FormGroup>

                </Form>
            </div>)
    }
}


