import React, { Component } from 'react';

import {
    Form, Col, Row, Button, FormGroup, Label, Input, CustomInput
    
} from 'reactstrap';


import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader} from '../resources/apiURL';

import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'


export default class WarehouseDetail extends Component {
    static displayName = WarehouseDetail.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            WHDetails: [],
            location: [],
            isLocLoaded: false,
            warehouseKey: props.warehouseKey,
            validateDataItem: {
                // warehouseId: true,
                warehouseName: true,
                locationKey: true
            }
            
        }

    }

    validateData = ()=>{
        const {validateDataItem, WHDetails} = this.state
        var bVal = true 
        var valItem = validateDataItem
        
        if (WHDetails[0].locationKey === "" || WHDetails[0].locationKey === null){
            bVal = false
            valItem.locationKey= false
        }

        if (WHDetails[0].warehouseName === "" || WHDetails[0].warehouseName === null){
            bVal = false
            valItem.warehouseName= false
        }
        this.setState({validateDataItem:valItem})
        
        return bVal
    }
        
    closeForm = (refreshData) => {
        if (refreshData) {
            this.props.refreshData();
        }

        this.props.toggle()
    }

    getWBRLocation = () => {
        let myURI = apiURL + 'SupplyChain/WBRlocation'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isLocLoaded: true,
                    location: json
                })
            });
    }

    saveData = () => {
        const { warehouseKey } = this.state
        if(this.validateData() ){   
        if (warehouseKey >= 0) {
            this.updateWH()
        } else {
            this.addWH()
        }

        this.closeForm()
        }
    }

    updateWH = () => {
        const updateWHDTO = this.createWHUpdateDTO()
        let myURI = apiURL + 'SupplyChain/WH'
        fetch(myURI, apiPUTHeader(updateWHDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })

    }

    updateWHStatus = (StatusKey) => {
        const { WHDetails } = this.state
        WHDetails[0].statusKey=StatusKey
        this.setState({WHDetails:WHDetails})
        this.updateWH()
        this.closeForm()
    }

    createWHUpdateDTO = () => {
        const { WHDetails } = this.state
        const { user } = this.props
        const whUpdateDTO = {

            "warehouseKey": WHDetails[0].warehouseKey,
            "locationKey": WHDetails[0].locationKey,
            "warehouseID": WHDetails[0].warehouseID,
            "warehouseName": WHDetails[0].warehouseName,
            "statusKey": WHDetails[0].statusKey,
            "addressName": WHDetails[0].addressName,
            "addressLine": WHDetails[0].addressLine,
            "city": WHDetails[0].city,
            "stateID": WHDetails[0].stateID,
            "zipCode": WHDetails[0].zipCode,
            "contactName": WHDetails[0].contactName,
            "contactEmail": WHDetails[0].contactEMail,
            "contactPhone": WHDetails[0].contactPhone,
            "latitude": WHDetails[0].latitude,
            "longtitude": WHDetails[0].longtitude,
            "comments": WHDetails[0].comments,
            "modifiedBy": getUserADAccount(user.account.userName)
        }
        return whUpdateDTO;
    }

    addWH = () => {
        const createWHDTO = this.createWHCreateDTO()
        let myURI = apiURL + 'SupplyChain/WH'
        fetch(myURI, apiPOSTHeader(createWHDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })

    }

    createWHCreateDTO = () => {
        const { WHDetails } = this.state
        const { user } = this.props
        const whDataDTO = {
         
            "locationKey": WHDetails[0].locationKey,
            "warehouseID": WHDetails[0].warehouseID,
            "warehouseName": WHDetails[0].warehouseName,
            "statusKey": WHDetails[0].statusKey,
            "addressName": WHDetails[0].addressName,
            "addressLine": WHDetails[0].addressLine,
            "city": WHDetails[0].city,
            "stateID": WHDetails[0].stateID,
            "zipCode": WHDetails[0].zipCode,
            "contactName": WHDetails[0].contactName,
            "contactEmail": WHDetails[0].contactEMail,
            "contactPhone": WHDetails[0].contactPhone,
            "latitude": WHDetails[0].latitude,
            "longtitude": WHDetails[0].longtitude,
            "comments": WHDetails[0].comments,
            "createdBy": getUserADAccount(user.account.userName)
        }
        return whDataDTO;
    }

    getWHDetails = () => {
        const { warehouseKey } = this.state
        if (warehouseKey >= 0) {
            let myURI = apiURL + 'SupplyChain/WH/' + warehouseKey

            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    this.setState({
                        isLoaded: true,
                        WHDetails: json
                    })
                });
        } else {
            const WHDetails = this.createNewWarehouse()
            this.setState({
                WHDetails: WHDetails,
                isLoaded: true
            })
        }
    }


    createNewWarehouse = () => {
        const { user } = this.props

        const WHData = {
            "locationID": "PEC",
            "locationName": "Southern Delaware",
            "warehouseID": "XXXXXX",
            "warehouseName": "",
            "statusDesc": "Active",
            "addressName": null,
            "addressLine": null,
            "city": null,
            "stateID": "TX",
            "zipCode": null,
            "contactName": null,
            "contactEMail": null,
            "contactPhone": null,
            "latitude": null,
            "longtitude": null,
            "comments": "",
            "createdDatetime": new Date().toISOString(),
            "createdBy": user.account.name,
            "warehouseKey": -1,
            "locationKey": 4,
            "statusKey": 1
        }
        return [WHData]
    }

    onTextChange = (fieldName, fieldValue, e) => {
        console.log("props")
        console.log(fieldName + '=' + fieldValue)
        const { WHDetails, validateDataItem } = this.state;


        switch (fieldName) {
            case "locationKey":
                WHDetails[0].locationKey = fieldValue
                validateDataItem.locationKey = (fieldValue !== "")
                break;
            case "warehouseID":
                WHDetails[0].warehouseID = fieldValue
                // validateDataItem.warehouseID = true
                break;
            case "warehouseName":
                WHDetails[0].warehouseName = fieldValue
                validateDataItem.warehouseName =  (fieldValue !== "" || fieldValue != null )
                break;
            case "comments":
                WHDetails[0].comments = fieldValue

                break;
            case "contactName":
                WHDetails[0].contactName = fieldValue

                break;
            case "contactPhone":
                WHDetails[0].contactPhone = fieldValue

                break;
            case "contactEmail":
                WHDetails[0].contactEMail = fieldValue
    
                break;
            case "addressName":
                WHDetails[0].addressName = fieldValue

                break
            case "addressLine":
                WHDetails[0].addressLine = fieldValue

                break
            
            case "city":
                WHDetails[0].city = fieldValue

                break
            case "stateID":
                WHDetails[0].stateID = fieldValue

                break
            case "zipCode":
                WHDetails[0].zipCode = fieldValue

                break
            default:

        }

        this.setState({ WHDetails: WHDetails, validateDataItem: validateDataItem })
    }

    componentDidMount() {
        this.getWBRLocation()
        this.getWHDetails()
    }

    render() {
        const { isLoaded, WHDetails, warehouseKey,
            isLocLoaded, location, validateDataItem } = this.state

        if (!isLocLoaded) {
            return (<div>Loading Waterbridge location.....</div>)
        } else
            if (!isLoaded) {
                return (<div>Loading Warehouse details.....</div>)
            } else
                return (<div>
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="warehouseID">Warehouse/Yard ID</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="warehouseID" id="warehouseID"
                                        placeholder="" value={WHDetails[0].warehouseID}
                                        disabled
                                        onChange={(e) => this.onTextChange("warehouseID", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="location" > Location </Label>

                                    <CustomInput type="select"
                                    className={!validateDataItem.locationKey ? "form-error" : ""}
                                    // invalid={!validateDataItem.locationKey}
                                        //disabled={isReadOnly}
                                        id="location"
                                        name="location"
                                        defaultValue={WHDetails[0].locationKey}
                                        value={WHDetails[0].locationKey}
                                        onChange={(e) => this.onTextChange("locationKey", e.target.value, e)}
                                    >
                                        <option value=""></option>
                                        {location.map(v => <option value={v.locationKey}>{v.locationName}</option>)}

                                    </CustomInput>
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="warehouseName">Warehouse/Yard Name</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="warehouseName" id="warehouseName"
                                        invalid={!validateDataItem.warehouseName}
                                        placeholder="" value={WHDetails[0].warehouseName}
                                        onChange={(e) => this.onTextChange("warehouseName", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="comments">Comment</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="textarea" name="comments" id="comments"
                                        placeholder="" value={WHDetails[0].comments}
                                        onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="contactName">Contact Name</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="contactName" id="contactName" placeholder=""
                                        value={WHDetails[0].contactName}
                                        onChange={(e) => this.onTextChange("contactName", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="contactEmail">Email Address</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="contactEmail" id="contactEmail" placeholder=""
                                        value={WHDetails[0].contactEMail}
                                        onChange={(e) => this.onTextChange("contactEmail", e.target.value, e)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="phoneNo">Phone No.</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="phoneNo" id="phoneNo" placeholder=""
                                        value={WHDetails[0].contactPhone}
                                        onChange={(e) => this.onTextChange("contactPhone", e.target.value, e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="addressName">Address Name</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="addressName" id="addressName"
                                        placeholder="" value={WHDetails[0].addressName}
                                        onChange={(e) => this.onTextChange("addressName", e.target.value, e)}
                                    />
                                    <Input style={{ margin: "5px 0 0 0" }} type="text" name="addressLine" id="addressLine" placeholder=""
                                        value={WHDetails[0].addressLine}
                                        onChange={(e) => this.onTextChange("addressLine", e.target.value, e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="city">City</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="city" id="city"
                                        placeholder="" value={WHDetails[0].city}
                                        onChange={(e) => this.onTextChange("city", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label for="state">State</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="state" id="state"
                                        placeholder="" value={WHDetails[0].stateID}
                                        onChange={(e) => this.onTextChange("stateID", e.target.value, e)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="zipCode">ZipCode</Label>
                                    <Input style={{ margin: "-5px 0 0 0" }} type="text" name="zipCode" id="zipCode"
                                        placeholder="" value={WHDetails[0].zipCode}
                                        onChange={(e) => this.onTextChange("zipCode", e.target.value, e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Row>
                                <Col sm={12}>
                                    <Button color="warning" className="float-right"
                                        onClick={() => this.closeForm(false)}>Close</Button>


                                    <Button color="primary" className="float-right"
                                        style={{ margin: "0px 10px 0px 0px" }}
                                        // hidden={this.setIsReadOnly(3)}
                                        onClick={() => this.saveData()}

                                    >Save
                                    </Button>


                                    <ConfirmModal outline={true}
                                        color={(WHDetails[0].statusKey!==0?"danger":"primary") }
                                        className="float-left"
                                        buttonLabel={(WHDetails[0].statusKey!==0?"Deactivate":"Activate") }
                                        //hidden ={(!((statusKey==0 && safetyObservationKey > 0) || ((isQHSEAdmin|| isQHSEApprover) && statusKey!=6 &&  statusKey!=4 && safetyObservationKey > 0))) }
                                        hidden = {(warehouseKey<0)}
                                        formName="Confirm"
                                        id={warehouseKey}
                                        message={"Are you sure you want to " + (WHDetails[0].statusKey!==0?"deactivate":"activate") + " this warehouse?"}
                                        onClickYes={(e) => this.updateWHStatus((WHDetails[0].statusKey!==0? 0 :1))}
                                        image={<img
                                            src={TrashBin}
                                            id={warehouseKey}
                                            alt="Edit"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                    </ConfirmModal>


                                </Col>


                            </Row>
                        </FormGroup>

                    </Form>
                </div>)
    }

}