import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'

import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt,USDCurrencyFormat } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import LineCostForcast from './LineCostForcast'

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


// const AFEBudget = React.forwardRef((props, ref) => {
class AFEBudget extends Component {
    static displayName = AFEBudget.name;

    constructor(props) {
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onCellValueChanging = this.onCellValueChanging.bind(this);
        this.onItemCellUpdating = this.onItemCellUpdating.bind(this);
        this.saveAFELine = this.saveAFELine.bind(this);
        this.isGridReadonly = this.isGridReadonly.bind(this);

        this.state = {
            afeData: props.afeData,
            costType: props.costType,
            isGeneral: (props.costType.afeLineTypeKey === 0),
            isFacility: (props.costType.afeLineTypeKey === 2),
            isDC: (props.costType.afeLineTypeKey === 1),
            isPipeLine: (props.costType.afeLineTypeKey === 3),
            uomList: props.uomList,
            afeLines: [],           
            isLoaded: false,
            costCodeList: [],
            selAFELine: [],
            isDefLineCreate: false,
            isCostCodeLoaded: false,
            isFormReadOnly: props.isFormReadOnly,
            afeLineKey: -1,
            searchText: '',
            modalMsg: false,
            modalLineAdd: false,
            modalCostForcast: false,
            msgHeader: "",
            msgBody: "",
            siteTransfer: 0,
            afesRevSupBuget: props.afesRevSupBuget,
            user: props.user
        }
    }

    setFromOutside(msg) {
        console.log("msg")
        console.log(msg)
        /* this is call from the parent to tell the child to save data
        comment out since we have to update data on on demand HN-03/23/2022 */

        ///this.saveAFELine()
    }

    onGridRowSelected(s, e) {
        //console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({ selAFELine: dataItem })
            // this.toggle()
            //this.onVolRefresh()    
        }
    }

    toggleModalLineAdd = () => {
        const selAFELine = this.createNewAFELineDTO()
        ///this.saveAFELine()
        this.setState({
            modalLineAdd: !this.state.modalLineAdd,
            selAFELine: selAFELine
        })
    }

    toggleModalCostForcast = () => {
        this.setState({ modalCostForcast: !this.state.modalCostForcast })
    }

    toggleSiteTransfer(value){
        this.props.handleCallBackSiteTransfer(value);
    }

    afeLineAdd = (closeForm) => {
        const { selAFELine } = this.state

        let myURI = apiURL + 'AFE/Line'
        fetch(myURI, apiPOSTHeader(selAFELine))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.getAFELine()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })       
        
        if (closeForm) {
            this.toggleModalLineAdd()
        } else {            
            const selAFELine = this.createNewAFELineDTO()
            this.setState({ selAFELine: selAFELine })
        }
        
    }
    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.afeLines;
    }

    createEmptyAFELine = () => {
        const selAFELine = this.createNewAFELineDTO()
        this.setState({
            selAFELine: selAFELine,
            isDefLineCreate: true
        })
    }

    getAFELine = () => {
        const { afeData, costType, isFormReadOnly } = this.state
        let myURI = apiURL + 'AFE/Line/' + afeData[0].afeHeaderKey + '/CostType/' + costType.afeLineTypeKey

        console.log("getAFELine");
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeLines: (isFormReadOnly ? json.filter(a => a.unitPrice + a.unitPrice2 > 0) : json),
                    afeLinesFilter: (isFormReadOnly ? json.filter(a => a.unitPrice + a.unitPrice2 > 0) : json),
                    isLoaded: true
                })    
                this.props.lineItemUpdate(json)            
            });
    }

    getGeneralCostCode = () => {
        const { isGeneral, costType } = this.state

        //if (isGeneral) {
        let myURI = apiURL + 'AFE/costcode/Type/' + costType.afeLineTypeKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    costCodeList: this.createCostCodeList(json),
                    isCostCodeLoaded: true
                })
            });
        //}
    }

    createCostCodeList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeCostCodeKey
            e.label = e.costCodeLongDesc
            tempList.push(e)
        })

        return tempList
    }

    getSelCostCode = (afeCostCodeKey) => {
        const { costCodeList } = this.state
        return costCodeList.filter(e => e.afeCostCodeKey === afeCostCodeKey);
    }

    getSelUOM = (uomKey) => {
        const { uomList } = this.state
        // console.log("uomList")
        // console.log(uomKey)
        return uomList.filter(e => uomList.uomKey === uomKey)
    }

    deleteAFELine = () => {
        const deleteAFELineDTO = this.createLineDeleteDTO()

        let myURI = apiURL + 'AFE/Line'
        fetch(myURI, apiDELETEHeader(deleteAFELineDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.getAFELine()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    createLineDeleteDTO = () => {
        const { selAFELine } = this.state
        const { user } = this.props
        const afeLineDTO = []

        const deleteDTO = {
            "afeLineKey": selAFELine.afeLineKey,
            "deletedBy": getUserADAccount(user.account.userName)
        }

        return deleteDTO

    }


    saveAFELine = () => {
        const { costType } = this.state
        //console.log("I was called to save the line: " + costType.afeLineTypeKey)
        const updateAFEDTO = this.createLineUpdateDTO()

        let myURI = apiURL + 'AFE/Line'
        fetch(myURI, apiPUTHeader(updateAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                //this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    createNewAFELineDTO = () => {
        const { user } = this.props
        const { afeData, costType } = this.state
        const itemDTO = {
            "afeHeaderKey": afeData[0].afeHeaderKey,
            "assetTypeKey": null,
            "lineID": "0",
            "itemID": "",
            "itemName": "",
            "itemDesc": "",
            "uomKey": null,
            "costTypeKey": costType.afeLineTypeKey,
            "qty": 1,
            "unitPrice": 0.0,
            "unitPrice2": 0.0,
            "lineComments": "",
            "createdBy": getUserADAccount(user.account.userName),
            "afeCostCodeKey": 0,
            "lineTotal": 0.0,
            "afeLineKey": -1
        }
        return itemDTO
    }

    createLineUpdateDTO = () => {
        const { afeLines } = this.state
        const { user } = this.props
        const afeLineDTO = []

        afeLines.map(e => {
            if (e.isUpdated !== undefined) {
                if (e.isUpdated !== 0) {
                    const itemDTO = {
                        "afeLineKey": e.afeLineKey,
                        "lineID": e.lineID,
                        "itemID": e.itemID,
                        "itemName": e.itemName,
                        "itemDesc": e.itemDesc,
                        "uomKey": e.uomKey,
                        "costTypeKey": e.costTypeKey,
                        "qty": e.qty,
                        "unitPrice": e.unitPrice,
                        "unitPrice2": e.unitPrice2,
                        "lineComments": e.lineComments,
                        "modifiedBy": getUserADAccount(user.account.userName),
                        "assetTypeKey": e.assetTypeKey
                    }
                    afeLineDTO.push(itemDTO)
                }
            }

            // console.log("afeLines")
            // console.log(e.isUpdated)

        })

        // console.log("afeLines")
        // console.log(afeLines)

        return afeLineDTO

    }

    onTextChange = (fieldName, fieldValue, e) => {
        var { searchText} = this.state
        //console.log(fieldValue)
        //this.setState({gridSelectionMode:"None"})
        
        

        switch (fieldName) {
            case "searchBox":
                searchText = (fieldValue || "").toLowerCase()     
                if (this.grid.activeCell!==null ){
                    this.grid.activeCell=null
                }
                      
                break;
            default:
                break;
        }

        this.setState({ searchText: searchText})
    }

    onLineTextChange = (fieldName, fieldValue, e) => {
        var { selAFELine } = this.state

        console.log("selAFELine")
        console.log(selAFELine)

        switch (fieldName) {
            case "uomKey":
                if (e !== null){
                    selAFELine.uomKey = e.value
                }
                
                break;
            case "afeCostCodeKey":
                selAFELine.afeCostCodeKey = e.value
                selAFELine.itemID = e.costCodeID
                selAFELine.itemName = e.costCodeDesc
                selAFELine.itemDesc = e.comment
                break;
            case "qty":
                selAFELine.qty = fieldValue                
                selAFELine.lineTotal = this.calLineTotal( Number(selAFELine.unitPrice)
                                                        ,Number(fieldValue)
                                                        ,Number(selAFELine.unitPrice2))
                break;
            case "unitPrice":
                selAFELine.unitPrice = fieldValue
                selAFELine.lineTotal = this.calLineTotal( Number(fieldValue)
                                                        ,Number(selAFELine.qty)
                                                        ,Number(selAFELine.unitPrice2))
                
                //Number(fieldValue) * Number(selAFELine.qty)
                break;
            case "unitPrice2":
                selAFELine.unitPrice2 = fieldValue
                selAFELine.lineTotal = this.calLineTotal( Number(selAFELine.unitPrice)
                                                        ,Number(1)
                                                        ,Number(fieldValue))

                //Number(fieldValue) * Number(selAFELine.qty)
                break;
            case "lineComments":
                selAFELine.lineComments = fieldValue
                break;
            case "itemDesc":
                    selAFELine.itemDesc = fieldValue
                    break;
            default:
                break;
        }

        
        this.setState({ selAFELine: selAFELine })
    }

    onSiteTransferChang = (fieldName, fieldValue, e) => {
        let value = +e.value.replace(/\D/g, '');
        this.props.handleCallBackSiteTransfer(+value);  
    }

    calLineTotal = (unitPrice, qty, unitPrice2) => {
        const {isDC} = this.state

        return (isDC ? (unitPrice===null?0:unitPrice) + unitPrice2 : unitPrice * qty)
    }

    onCellValueChanging(s, e) {
        // if (this.state.showAlert) {
        //     this.setState({
        //         alertMessage: "",
        //         showAlert: false
        //     })
        // }
        // console.log("e")
        // console.log(e)
        // e.item.itemID = e.item.itemID
        this.updateLineValue(e.item.afeLineKey, e.column.field, e.newValue)
    }

    updateLineValue = (key, fieldName, value) => {
        const { afeLines, isDC } = this.state
        var temp = []
        afeLines.map(e => {
            if (e.afeLineKey === key) {
                e.isUpdated = 1
                switch (fieldName) {
                    case "qty":
                        e.qty = value
                        e.extLineAmt = (isDC ? e.extLineAmt : value * e.unitPrice)
                        break
                    case "unitPrice":
                        e.unitPrice = value
                        e.extLineAmt = (isDC ? (e.unitPrice2 + value) : (e.qty * e.unitPrice))
                        break;
                    case "unitPrice2":
                        e.unitPrice2 = value
                        e.extLineAmt = (isDC ? (e.unitPrice + value) : e.extLineAmt)
                        break;
                    case "lineComments":
                        e.lineComments = value
                        break
                    case "itemDesc":
                        e.itemDesc = value
                        break;
                    case "uomKey":
                        e.uomKey = value
                        break;
                    default:
                        break
                }
                temp.push(e)
            } else {
                temp.push(e)
            }
        })
      
        this.props.lineItemUpdate(afeLines.filter(e => e.extLineAmt>0))
        this.setState({ afeLines: afeLines })
        this.saveAFELine()
    }

    componentDidMount() {
        //console.log("Call me!!!!")
        //this.props.saveLineData(this.saveAFELine)
        this.createEmptyAFELine()
        this.getGeneralCostCode()
        this.getAFELine()
    }

    isGridReadonly = () => {
        const {isFormReadOnly,afeData,user} = this.state;
    
        if (afeData === undefined || afeData.length <= 0 ){
            return true;
        }
        else if (afeData[0].statusKey === 0 || afeData[0].statusKey === 10 || afeData[0].statusKey === 1) { //If Draft or Revision Or Rejected then is editable.  
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { afeHeaderKey, afeLines, isLoaded, afeLineKey,
            isFormReadOnly, isGeneral, searchText, modalCostForcast,
            isCostCodeLoaded, costCodeList, uomList, selAFELine, isDefLineCreate,
            isFacility, isDC, isPipeLine, msgHeader, msgBody,afeData,afesRevSupBuget } = this.state

           
            //afeLines = afeLines.filter(x => x.afeHeaderKey == afeLines[0].afeHeaderKey)

        if (!isDefLineCreate) {
            return (<div>Loading Default AFE Line.......</div>)
        } else if (!isLoaded) {
            return (<div>Loading AFE Line.......</div>)
        } else
            return (<div>

                <Row form style={{ marginTop: "5px", marginBottom: "2px" }}>
                    <Col sm={6}>
                        {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                        <Button color="primary" outline className="float-left"
                            disabled={isFormReadOnly}
                            hidden={isFormReadOnly}
                            style={{ height: "40px" }}
                            //hidden={this.setIsReadOnly(statusKey)}
                            onClick={() => this.toggleModalLineAdd()}
                        >New Line</Button>

                        <Button color="success" outline className="float-left"
                            style={{ height: "40px", marginBottom: "5px", marginLeft: "10px" }}
                            //hidden={this.setIsReadOnly(statusKey)}
                            onClick={() => this.toggleModalCostForcast()}
                        >Line Item Spend Forecast</Button>
                        <ConfirmModal outline={true}
                                disabled={isFormReadOnly}
                                hidden={isFormReadOnly}
                                color="danger"
                                className="float-left"
                                buttonLabel="Delete"
                                //hidden={(!((statusKey == 0 && safetyObservationKey > 0) || ((isQHSEAdmin || isQHSEApprover) && statusKey != 6 && statusKey != 4 && safetyObservationKey > 0)))}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message="Are you sure you want to delete selected receiver?"
                                onClickYes={(e) => this.deleteAFELine()}
                                image={<img
                                    src={TrashBin}
                                    id={afeHeaderKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                        </ConfirmModal>
                        
                    </Col>
                    <Col sm={6} className="float-right">
                        <Row form style={{ verticalAlign: "middle"  }}>
                            <Col sm={6} >
                                <Label className="float-right" for="siteToSite">Site to Site Transfer $</Label>    
                            </Col>
                            <Col sm={6}> 
                                 <NumberFormat value={afeData[0].siteTransfer}
                                            onValueChange={(e) => this.onSiteTransferChang('siteTransfer', e.target, e)}
                                            className='form-control text-md-right'
                                            fixedDecimalScale={true}
                                            decimalScale={0} 
                                            disabled={isFormReadOnly}
                                            thousandSeparator={true}
                                            prefix={'$'} />
                            </Col>
                        </Row>
                    </Col>
                     
                </Row>
                <Row form style={{ marginTop: "5px", marginBottom: "2px" }}>
                    <Col sm={12}> 
                        <Input type="text" placeholder='Type here to search .....'
                                        autoFocus
                                        onChange={(e) => this.onTextChange("searchBox", e.target.value, e)} 
                                        className="float-left"
                            />
                    </Col>
                </Row>        

                <ColoredLine color="grey" hidden={!isGeneral} />

                <Row form style={{ zIndex: 0, position: 'relative' }}>
                    {/* <FormGroup> */}

                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="450px"
                        width='100%'
                        //autoFocus={true}
                        //dataSource={afeLines}
                        dataSource={afeLines.filter(rec => ((rec.itemID || '').toLowerCase().includes(searchText)) ||
                            (rec.itemName || '').toLowerCase().includes(searchText) ||
                            (rec.itemComments || '').toLowerCase().includes(searchText) || 
                            (rec.itemDesc || '').toLowerCase().includes(searchText))}
                        isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        headerClickAction="none"
                        selectionMode={"SingleRow"}
                        editModeClickAction="DoubleClick"
                        //editMode={(isFormReadOnly ? 0 : 1)}
                        editMode={this.isGridReadonly() ? 0 : 1} 
                        editOnKeyPress ={"false"}
                        selectedKeysChanged={this.onGridRowSelected}
                        cellValueChanging={this.onCellValueChanging}
                    >

                   
                        <IgrTemplateColumn
                            field="itemID"
                            headerText="Item ID"
                            width="95"
                            cellUpdating={this.onCellUpdating} 
                        />

                        <IgrTemplateColumn
                            field="itemName"
                            headerText="Item Name"
                            width="300"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTextColumn //IgrTemplateColumn
                            field="itemDesc"
                            headerText="Item Desc"
                            width="*>300"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrNumericColumn
                            isHidden={isDC}
                            field="qty"
                            headerText="Qty"
                            width="100"
                            showGroupingSeparator="true"
                        //cellUpdating={this.onCellUpdating}

                        />
                        <IgrTemplateColumn
                            isHidden={isDC}
                            field="uomDesc"
                            headerText="UOM"
                            width="100"
                            cellUpdating={this.onItemCellUpdating}
                            //isEditable={(e) => this.rowEditable} 
                        />
                        <IgrNumericColumn
                            field="unitPrice"
                            headerText={isDC ? "Drilling Cost" : "Unit Price"}
                            width="*>120"
                            positivePrefix="$"
                            showGroupingSeparator="true"
                        />

                        <IgrNumericColumn
                            isHidden={!isDC}
                            field="unitPrice2"
                            headerText="Completion Cost"
                            positivePrefix="$"
                            width="*>120"
                            showGroupingSeparator="true" 
                        />


                        <IgrTemplateColumn
                            field="extLineAmt"
                            headerText="Total Cost"
                            width="120"
                            //positivePrefix="$"
                            cellUpdating={this.onCellUpdating}
                        />


                        <IgrTextColumn
                            field="lineComments"
                            headerText="Line Comment"
                            width="*>280"  
                        />
                        {/* <IgrTextColumn field="codeDefinitionKey" headerText="Item Number"
                                                        /> */}

                        <IgrNumericColumn field="afeLineKey" isHidden />
                    </IgrDataGrid>

                    {/* </FormGroup> */}
                </Row>

                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalCostForcast}
                        toggle={() => this.toggleModalCostForcast()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleModalCostForcast()}>
                            {"Cost Line Forecast -  Line Total: "}
                            <NumberFormat value={selAFELine.extLineAmt}
                                fixedDecimalScale={true}
                                decimalScale={0}
                                displayType={'text'}
                                // disabled={true}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </ModalHeader>
                        <ModalBody>
                            <LineCostForcast afeLineKey={selAFELine.afeLineKey}
                                selAFELine={selAFELine}
                                user={this.props.user}
                                isFormReadOnly={isFormReadOnly}
                                toggleModalCostForcast={this.toggleModalCostForcast}></LineCostForcast>
                        </ModalBody>

                    </Modal>
                </div>


                <div>
                    <Modal isOpen={this.state.modalLineAdd}
                        toggle={() => this.toggleModalLineAdd()}
                        size="md">
                        <ModalHeader toggle={() => this.toggleModalLineAdd()}>
                            {" Budget Line Add"}

                        </ModalHeader>
                        <ModalBody>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="itemName" > Item Name </Label>

                                        <Select options={costCodeList}
                                            name='itemName'
                                            id='itemName'
                                            defaultValue={this.getSelCostCode(selAFELine.afeCostCodeKey)}
                                            value={this.getSelCostCode(selAFELine.afeCostCodeKey)}
                                            onChange={(e) => this.onLineTextChange("afeCostCodeKey", e, e)}
                                            isClearable={true}></Select>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="itemDesc" > Item Description </Label>

                                        <Input type="text" name="itemDesc"
                                            //rows={3}
                                            //disabled={this.isFormReadOnly()}
                                            id="itemDesc" placeholder="Item Description"
                                            value={selAFELine.itemDesc}
                                            defaultValue={selAFELine.itemDesc}
                                            onChange={(e) => this.onLineTextChange("itemDesc", e.target.value, e)}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row form>
                                <Col md={4}>
                                    <FormGroup hidden={!isDC}>
                                        <Label for="unitPrice2">Completion Cost</Label>
                            
                                        <NumberFormat value={selAFELine.unitPrice2}
                                            onValueChange={(e) => this.onLineTextChange("unitPrice2", e.value, e)}
                                            className='form-control text-md-right'
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            //displayType={'text'} 
                                            //disabled={isFormReadOnly}
                                            thousandSeparator={true}
                                            prefix={'$'} />

                                    </FormGroup>
                                    <FormGroup hidden={isDC}>
                                        <Label for="qty">Qty</Label>
                                          <NumberFormat value={selAFELine.qty}
                                            onValueChange={(e) => this.onLineTextChange("qty", e.value, e)}
                                            className='form-control text-md-right'
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                          
                                            thousandSeparator={true}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="unitPrice">{(isDC ? "Drilling Cost":"Unit Price")}</Label>
                                 
                                        <NumberFormat value={selAFELine.unitPrice}
                                            onValueChange={(e) => this.onLineTextChange("unitPrice", e.value, e)}
                                            className='form-control text-md-right'
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            //displayType={'text'} 
                                            //disabled={isFormReadOnly}
                                            thousandSeparator={true}
                                            prefix={'$'} />


                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="uom">UOM</Label>
                                        <Select options={uomList}
                                            name='uom'
                                            id='uom'
                                            defaultValue={this.getSelUOM(selAFELine.uomKey)}
                                            //value={this.getSelUOM(selAFELine.uomKey)}
                                            onChange={(e) => this.onLineTextChange("uomKey", e, e)}
                                            isClearable={true}></Select>
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="lineComments">Comments</Label>

                                        <Input type="textarea" name="lineComments"
                                            rows={3}
                                            //disabled={this.isFormReadOnly()}
                                            id="lineComments" placeholder="Line Comment"
                                            value={selAFELine.lineComments}
                                            defaultValue={selAFELine.lineComments}
                                            onChange={(e) => this.onLineTextChange("lineComments", e.target.value, e)}
                                        />


                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row form>

                                <Col md={4}>
                                </Col>
                                <Col md={4}>
                                    <Label for="lineTotal" className='float-right' style={{ marginTop: "10px" }}>Line Total</Label>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>

                                        <NumberFormat value={selAFELine.lineTotal}
                                            //onValueChange={(e) => this.onLineTextChange("unitPrice", e.value, e)}
                                            className='form-control text-md-right'
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            //displayType={'text'} 
                                            disabled={true}
                                            thousandSeparator={true}
                                            prefix={'$'} />


                                    </FormGroup>
                                </Col>
                            </Row>
                            <ColoredLine color="grey" />
                            <FormGroup>
                                <Row style={{ margin: "15px 0 0 0" }}>
                                    <Col sm={12}>
                                        <Button outline
                                            color="warning" className="float-right"
                                            onClick={() => this.toggleModalLineAdd()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button outline  disabled={this.isLineAddButtonDisable()}
                                            color="primary" className="float-right"
                                            style={{ margin: "0px 10px 0px 0px" }}
                                            onClick={() => this.afeLineAdd(true)}
                                        >

                                            {"Add & Close"}
                                        </Button>

                                        <Button outline  disabled={this.isLineAddButtonDisable()}
                                            color="primary" className="float-left"
                                            onClick={() => this.afeLineAdd(false)}
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                </Row>

                            </FormGroup>
                        </ModalBody>
                    </Modal>
                </div>


            </div>)
    }

    isLineAddButtonDisable = () =>{
        const {selAFELine,isDC} = this.state

        if (isDC){
            return (selAFELine.afeCostCodeKey<=0 || (selAFELine.unitPrice <=0 && selAFELine.unitPrice2 <=0))
            
        }else
        {
            return (selAFELine.afeCostCodeKey<=0 || selAFELine.unitPrice <=0 || selAFELine.qtyOrdered<=0)
        }
        
    }

    lineItemChange = (key, value) => {    
        this.updateLineValue(key, 'uomKey', value)
    }

    onItemCellUpdating(s, e) {
        //if (e !== undefined){

        var bCreateSel = false
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";
        let select;

        if (content.childElementCount === 0) {
            bCreateSel = true
        } else {
            const temp = content.children[0]
            // console.log("temp.id")
            // console.log(temp.id)
            // console.log(item.afeLineKey)

            if (Number(temp.id) !== Number(item.afeLineKey)) {
                bCreateSel = true
                temp.remove()
                //console.log("remove")

            }

        }


        if (content.childElementCount === 0) {
           // console.log("removed")
            const { uomList, isFormReadOnly } = this.state
            if (s.field === "uomDesc") {
                select = document.createElement("select");
                select.setAttribute("id", item.afeLineKey)

                // select.addEventListener("change", () => {
                //     this.lineItemChange(item.afeLineKey, select.value)
                // })


                select.style.margin = "0px 0px 0px -10px";
                select.style.width = "125%";
                select.style.fontSize = "13px";

                //select.setAttribute("height", "70%");                
                // const itemMS = itemMaster.filter(e => (e.spendCategory || '').includes(item.spendCategoryID))

                //First Empty option
                var option = document.createElement("option");
                option.setAttribute("value", -1);
                option.text = "";
                select.appendChild(option);

                uomList.map(e => {
                    var option = document.createElement("option");
                    option.setAttribute("value", e.uomKey);
                    option.text = e.uomDesc;
                    select.appendChild(option);
                })

                select.value = (item.uomKey === null ? -1 : item.uomKey)
                // if (isFormReadOnly) {
                //     select.setAttribute("class", "selectBorderLess");
                //     select.disabled = isFormReadOnly
                // } else {
                //     select.setAttribute("class", "form-control selectBorderLess");
                // }

                if (this.isGridReadonly()){
                    select.setAttribute("class", "selectBorderLess");
                    select.disabled = true;
                }
                else {
                    select.setAttribute("class", "form-control selectBorderLess");
                }
                  
                content.appendChild(select);

                document.getElementById(item.afeLineKey).addEventListener("change", () => {
                    this.lineItemChange(item.afeLineKey, select.value)
                })

            }


        }
        else {
            //select = content.children[0];
            // const temp = content.children[0]
            // console.log("temp.id")
            // console.log(temp.id)
            // console.log(item.afeLineKey)
            // if (temp !== undefined && select!==undefined) {
            //     select.value = temp.value
            //     select.text = temp.text
            // }else{
            //     if (temp !== undefined && select!==undefined) 
            //     {
            //     select.value = null
            //     select.text = ""}
            // }
        }
        //}

    }


    onCellUpdating (s, e) {

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            link.setAttribute("class", "form-control selectBorderLess");
            //link.style.fontFamily = "Verdana";
            link.style.fontSize = "13px";
            link.style.verticalAlign = "center";

            // content.style.margin = "0px -20px -10px -12px";
            link.style.padding = "8px 0px 0px 0px";
            // //content.style.width = "100%";

            content.appendChild(link);
        } else {
            link = content.children[0]
        }


        if (link !== undefined) {


            switch (s.field) {
                case 'itemID':
                    link.textContent = item.itemID;
                    break;
                case 'itemDesc':
                    link.textContent = item.itemDesc;
                    break;
                case 'itemName':
                    link.textContent = item.itemName;
                    break;
                case 'extLineAmt':
                    link.textContent = USDCurrencyFormat(item.extLineAmt,0);

                    link.setAttribute("class", "text-md-right form-control selectBorderLess");

                    break;
                case 'qtyOrdered':
                    //var date = new Date(item.qtyOrdered);
                    //link.textContent = date.toLocaleDateString();
                    link.textContent = item.qtyOrdered;
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");
                    break;
                case 'uomDesc':
                    link.textContent = item.uomDesc;
                    break;
                case 'solutionDesc':
                    link.textContent = item.solutionDesc;
                    break;
                case 'createdByName':
                    link.textContent = item.createdByName;
                    break;
                case 'managerName':
                    link.textContent = item.managerName;
                    break;
                case 'locationName':
                    link.textContent = item.locationName;
                    break;
                case 'facilityName':
                    link.textContent = item.facilityName;
                    break;
                default:
            }
        }
    }

    rowEditable = () =>{
        //console.log('Row Editing' + s);
        return true;
    }

}
//     return <AFEBudget />
//  })

export default AFEBudget