import React, { Component} from 'react';
import { apiURL, apiHeader, apiPUTHeader} from '../../resources/apiURL';
import { Form} from 'reactstrap';

import Excel from '../../resources/excel.svg'
import '../../custom.css';
import { ExcelUtility } from '../../operation/ExcelUtility';
import { getUserADAccount} from '../../resources/utility'
import ConfirmModal from '../../operation/ConfirmModal'
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input} from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { ColoredLine} from '../../resources/utility';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();



export default class MtlMove extends Component {
    static displayName = MtlMove.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            windowHeight: window.innerHeight,
            materialMovementKey: props.materialMovementKey,
            mtlMoveHdr: props.mtlMoveHdr,
            statusKey: props.statusKey,
            mtlMoveTran: [],
            isLoaded: false,
            isDataChange: false,
            modalMsg: false,
            msgHeader: "",
            msgBody: ""
        }
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.mtlMoveTran;

    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { mtlMoveHdr } = this.state

        // console.log(fieldName + ' ------ ' + fieldValue)
        // console.log(e)

        switch (fieldName) {
            case "comments":
                mtlMoveHdr.comments = fieldValue
                break;
            default:
                break;
        }

        this.setState({ mtlMoveHdr: mtlMoveHdr })

    }
    getMtlMovementDetails = () => {
        const { materialMovementKey } = this.state

        let myURI = apiURL + 'ItemMaster/mtlmovement/' + materialMovementKey + '/Details'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isLoaded: true,
                    mtlMoveTran: json
                })
            });
    }

    closeForm = () => {
        this.props.toggle()

    }

    saveClose = () => {
        this.saveData()
        // this.closeForm()
    }

    submitMM = (e) => {
        this.saveData(2)
    }
    approveMM = (comment) => {
        this.saveData(6)
    }

    rejectMM = (comment) => {
        this.saveData(1)
    }

    saveData = (statusKey = -1) => {
        const mmHdrUpdateDTO = this.mmHeaderUpdateDTO(statusKey)
        console.log("mmHdrUpdateDTO")
        console.log(mmHdrUpdateDTO)
        let myURI = apiURL + 'ItemMaster/MTLMovement'

        fetch(myURI, apiPUTHeader(mmHdrUpdateDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })
    }

    mmHeaderUpdateDTO = (statusKey) => {
        const { user } = this.props
        const { mtlMoveHdr } = this.state
        const mmHdrUpdateDTO = {
            "MaterialMovementKey": mtlMoveHdr.materialMovementKey,
            "StatusKey": (statusKey < 0 ? mtlMoveHdr.statusKey : statusKey),
            "ModifiedBy": getUserADAccount(user.account.userName),
            "ExportedToWDDatetime": mtlMoveHdr.exportedToWDDatetime,
            "Comments": mtlMoveHdr.comments,
            "FiscalYrMonth": mtlMoveHdr.fiscalYrMonth
        }

        return mmHdrUpdateDTO
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getMtlMovementDetails()
    }
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    isFormReadOnly = () => {
        return false;
    }

    render() {
        const { mtlMoveTran, isLoaded, windowHeight, msgHeader, msgBody, statusKey,
            isDataChange, materialMovementKey, mtlMoveHdr } = this.state
        const gridHeight = (windowHeight * 0.70) + "px"

        console.log("statusKey")
        console.log(statusKey)

        if (!isLoaded) {
            return (<div>Loading Transaction details.....</div>)
        }
        return (<div>

            <Form onSubmit={e => e.preventDefault()}>
                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                    <Col sm={4}>

                        <Button color="secondary" className="float-left"
                            hidden={isDataChange}
                            onClick={() => this.saveClose()}>Close</Button>



                        <ConfirmModal
                            color="secondary"
                            buttonLabel={"Close"}
                            className="float-left"
                            hidden={(!isDataChange)} //{(this.isFormReadOnly() && !isDataChange)}
                            formName="Confirm"
                            id={materialMovementKey}
                            message={"Do you want to save your changes ?"}
                            onClickYes={(e) => this.saveData(true)}
                            onClickNo={this.closeForm}
                        >
                        </ConfirmModal>


                        <Button color="primary" className="float-left"
                            style={{ marginLeft: "5px" }}
                            //ref={ref}
                            hidden={this.isFormReadOnly() && !isDataChange}
                            onClick={() => this.saveData()}>Edit</Button>

                        <ConfirmModal
                            color="success"
                            buttonLabel={"Submit"}
                            className="float-left"
                            style={{ marginLeft: "5px" }}
                            hidden={(statusKey !== 0 && statusKey !== 1)} //{(this.isFormReadOnly() && !isDataChange)}
                            formName="Confirm"
                            id={materialMovementKey}
                            message={"Are you sure you want to submit this material movement ?"}
                            onClickYes={(e) => this.submitMM()}
                        //onClickNo={this.closeForm}
                        >
                        </ConfirmModal>

                        <ConfirmModal
                            color="success"
                            buttonLabel="Approve"
                            style={{ marginLeft: "5px" }}
                            hidden={statusKey !== 2}
                            formName="Confirm"
                            id={materialMovementKey}
                            message={"Are you sure you want to approve this material movement ?"}
                            //commentPrompMsg="Please Enter Approval Comment:"
                            onClickYes={(e) => this.approveMM()}>
                        </ConfirmModal>

                        {/* <ConfirmWithMsgModal
                            color="success"
                            buttonLabel="Approve"
                            style={{ marginLeft: "5px" }}
                            hidden={true}
                            formName="Confirm"
                            id={materialMovementKey}
                            message=""
                            commentPrompMsg="Please Enter Approval Comment:"
                            onClickYes={(e) => this.approveMM(e)}>
                        </ConfirmWithMsgModal> */}


                    </Col>
                    <Col sm={3}>
                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>
                    </Col>
                    <Col sm={5}>


                        <Button color="primary" className="float-right"
                            style={{ marginLeft: "5px" }}
                            //ref={ref}
                            hidden={true} //{this.isFormReadOnly() && !isDataChange}
                            onClick={() => this.saveData()}>View Approval</Button>

                        <ConfirmModal outline={true}
                            style={{ marginRight: "5px" }}
                            color="danger"
                            buttonLabel={"Reject"}
                            className="float-right"
                            hidden={statusKey !== 2}
                            formName="Confirm"
                            id={materialMovementKey}
                            materialMovementKey={materialMovementKey}
                            message={"Are you sure you want to reject this material movement ?"}
                            //commentPrompMsg="Please Enter Reject Comment:"
                            onClickYes={(e) => this.rejectMM()}>
                        </ConfirmModal>

                        {/* <ConfirmWithMsgModal outline={true}
                            style={{ marginRight: "5px" }}
                            color="danger"
                            buttonLabel={"Reject"}
                            className="float-right"
                            //hidden={!(isAFEManager && statusKey === 2)}
                            formName="Confirm"
                            id={materialMovementKey}
                            materialMovementKey={materialMovementKey}
                            message=""
                            commentPrompMsg="Please Enter Reject Comment:"
                            onClickYes={(e) => this.rejectMM(e)}>
                        </ConfirmWithMsgModal> */}

                    </Col>
                </Row>
                <ColoredLine color="grey" />

                <FormGroup>
                    <Row form>


                        <Col md={12}>
                            <Label for="area">Comment:</Label>
                            <Input type="textarea" name="mmComment"
                                rows={2}
                                //className={(!validateDataItem.afeDescription ? "form-error" : "")}
                                disabled={this.isFormReadOnly()}
                                id="comments" placeholder="Comments"
                                value={mtlMoveHdr.comments}
                                defaultValue={mtlMoveHdr.comments}
                                onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <IgrDataGrid
                    ref={this.onGridRef}
                    height={gridHeight}
                    width={"100%"}
                    dataSource={mtlMoveTran}
                    isColumnOptionsEnabled="true"
                    autoGenerateColumns="false"
                    selectionMode="SingleRow"
                    isGroupCollapsable="true"
                    isGroupExpandedDefault="true"
                    isHeaderSeparatorVisible="false"
                    groupHeaderDisplayMode="split"
                    editMode={0}

                // selectedKeysChanged={this.onGridRowSelected}
                >
                    <IgrTextColumn field="companyCode"
                        headerText="Company Code" width="*>150" />

                    <IgrTextColumn field="mmNo" isHidden
                        headerText="MMNo" width="*>110" />
                    <IgrTextColumn field="mtlRequestNo"
                        headerText="Mtl RequestNo" width="*>150" />
                    <IgrTextColumn field="fiscalYrMonth" isHidden
                        headerText="Yr Month" width="*>130" />
                    <IgrTextColumn field="transDateTime"
                        headerText="Transaction Date" width="*>230" />
                    <IgrTextColumn field="partcode"
                        headerText="partcode" width="*>130" />

                    <IgrTextColumn field="partName"
                        headerText="partName" width="*>230" />

                    <IgrNumericColumn field="issueQty"
                        showGroupingSeparator="true"
                        headerText="issueQty" width="*>130" />

                    <IgrNumericColumn field="unitCost"
                        positivePrefix="$"
                        showGroupingSeparator="true"
                        headerText="unitCost" width="*>130" />

                    <IgrNumericColumn field="extAmount"
                        positivePrefix="$"
                        negativePrefix="$"
                        showGroupingSeparator="true"
                        headerText="ExtAmount" width="*>130" />

                    <IgrTextColumn field="fromCompanyCode"
                        headerText="FromCompanyCode" width="*>130" />

                    <IgrTextColumn field="woNo"
                        headerText="woNo" width="*>130" />

                    <IgrTextColumn field="fromAFE"
                        headerText="fromAFE" width="*>130" />

                    <IgrTextColumn field="fromGL"
                        headerText="fromGL" width="*>130" />

                    <IgrTextColumn field="fromSC"
                        headerText="fromSC" width="*>130" />

                    <IgrTextColumn field="fromSiteID"
                        headerText="fromSiteID" width="*>130" />

                    <IgrTextColumn field="toCompanyCode"
                        headerText="toCompanyCode" width="*>130" />

                    <IgrTextColumn field="toGL"
                        headerText="toGL" width="*>130" />

                    <IgrTextColumn field="toSC"
                        headerText="toSC" width="*>130" />

                    <IgrTextColumn field="toSiteID"
                        headerText="toSiteID" width="*>130" />

                    <IgrTextColumn field="toAFE"
                        headerText="toAFE" width="*>130" />




                    <IgrTextColumn field="createdDatetime"
                        headerText="Created Datetime" width="*>230" />
                    <IgrTextColumn field="modifiedByName"
                        headerText="Modified By" width="*>230" />

                    <IgrTextColumn field="lineComments"
                        headerText="lineComments" width="*>550" />
                    <IgrNumericColumn field="materialMovementKey" isHidden />
                    <IgrNumericColumn field="materialMovementDetailsKey" isHidden />
                    <IgrNumericColumn field="transactionKey" isHidden />
                    <IgrNumericColumn field="mtlRequestKey" isHidden />

                </IgrDataGrid>
            </Form>

            <div>
                <Modal isOpen={this.state.modalMsg}
                    toggle={() => this.toggleMsg()}
                    size="lg">
                    <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                    </ModalHeader>
                    <ModalBody>
                        {msgBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.toggleMsg()}
                        >
                            OK
                        </Button>
                        {' '}

                    </ModalFooter>
                </Modal>
            </div>

        </div>)
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "MaterialMovementDetails");
    }
}