

import React,{Component} from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {apiURL} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import CostDetail from './CostDetail'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class MeterVolume extends Component{
    static displayName = MeterVolume.name;

    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        //this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        const urlPara=this.getURLParameters(props.parameters)

        this.state = {
            meterVol:[],
            isLoaded:false,
            startDate: (urlPara.startDate === null? getFirstDayofMonth().toISOString() : urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            endDate: (urlPara.endDate === null ? new Date().toISOString():urlPara.endDate),
            modalOpen: true,
            estMeterVolumeFinalKey:0,
            windowHeight:window.innerHeight,
            modal:false,
            volType: urlPara.volType,
            facilityKeys: urlPara.facilityKeys
        }
    }

    getURLParameters = (inputPara) => {
        var paraMeters={
            startDate: null ,
            endDate:null ,
            facilityKeys: null ,
            volType:null}

            
        if ( !this.props.location==='undefined' || inputPara!=='undefined'){   
            var query
            if (!this.props.location==='undefined')
            {
                 query = new URLSearchParams(this.props.location.search);
            
            }else{
                 query = new URLSearchParams(inputPara);            
            }
            paraMeters={
                startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
                endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
                facilityKeys:query.get('Facility') ,
                volType:query.get('volType')
        }
        
        }
     
        return paraMeters
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.meterVol;
      
    }
    getMeterVolumes=() =>{
        //console.log(this.state.startDate);
        const {facilityKeys, volType} = this.state
         console.log("facilityKeys");
         console.log(facilityKeys);

        let myURI = apiURL + 'FacilityDetails/FinalVol/startdate/' + 
                              this.state.startDate.toString().substring(0, 10) + 
                              '/enddate/' + this.state.endDate.toString().substring(0, 10) 
                              //+ '/Employee/' + this.props.user.account.userName;
        console.log(myURI);

        // fetch(myURI , { modes: 'no-cors' })
        // .then(res => res.json())
        // .then(json => {
        //     this.setState({
        //         isLoaded: true,
        //         meterVol: json                        
        //     })
        // });

        fetch(myURI , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {


                this.setState({
                    isLoaded: true,
                    meterVol: (facilityKeys === null ? json:
                        json.filter(facList => (facilityKeys.includes(facList.facilityKey)))
                        ).filter(rec => (rec.finalVolume > 0) && (volType===null ? true : 
                                ((volType || '').toLowerCase()==="other" ? (((rec.flowType || '').toLowerCase() !== 'inlet') && ((rec.flowType || '').toLowerCase() !== 'outlet')) : 
                                 (rec.flowType || '').toLowerCase().includes(volType.toLowerCase()))))                        
                })
            });
       

    }
    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getMeterVolumes()
    }
    handleChange(value,formattedValue){
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue

        });
       
    }

    render(){
        var {isLoaded,meterVol, windowHeight} = this.state;
        const gridHeight=(windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()
        
        console.log("Meter Volumes")
        if (!isLoaded) {
            return(<div>
                <h3>Loading meters volumes.....</h3>
            </div>)
        }else
        return(
            <div>
                <FormGroup>
                    <Row form>
                        <h2> Meters Volumes |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getMeterVolumes}>
                                    <img
                                    src={MenuIcon}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>

                <FormGroup>
                <Row >
                        <Col md={3}>
                            <Label style={{margin: "0 0 0 0", padding:"0"}}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate} 
                                        onChange={(v,f) => this.handleChange(v,f)} /> 
                        </Col>
                        <Col md={3}>
                            <Label style={{margin: "0 0 0 0", padding:"0"}} >
                                End Date                                
                            </Label>
                            <DatePicker value={this.state.endDate} 
                                        onChange={(v,f) => this.handleEndDateChange(v,f)} /> 
                          
                        </Col>

                    </Row>
                </FormGroup>

                
                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}
                        dataSource={meterVol}
                        //width="100%"
                        // dataSource={meterVol.filter( rec => ((rec.flowType || '').toLowerCase().includes(searchText)) ||
                        //                                     (rec.facilityName || '').toLowerCase().includes(searchText) ||
                        //                                     (rec.estMeterName || '').toLowerCase().includes(searchText) ||
                        //                                     (rec.operatorName || '').toLowerCase().includes(searchText) ||
                        //                                     rec.locationName.toLowerCase().includes(searchText) ||
                        //                                     (rec.subSystem || '').toLowerCase().includes(searchText) ||                                                            
                        //                                     (rec.comments || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}>                      
                        
                        
                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>190" />
                        <IgrTextColumn field="estMeterName" headerText="Meter Name" width="*>140" />
                        
                        <IgrTemplateColumn field="reportDate" headerText="Report Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}/>
                        <IgrNumericColumn field="finalVolume" headerText="Volume" width="*>140"/>
                        <IgrTextColumn field="VolID" headerText="Volume Source" width="*>130" />
                    
                        <IgrTextColumn field="flowType" headerText="Flow Type" width="*>150" />
                       
                        <IgrTextColumn field="flowDesc" headerText="Flow Description" width="*>180" />
                       
                        <IgrTextColumn field="operatorName" headerText="Operator" width="*>210" />
                                         
                        <IgrTextColumn field="comments" headerText="Comments" width="*>450"/>
                        <IgrTextColumn field="locationName" headerText="Basin" width="*>150" />
                        <IgrTextColumn field="subSystem" headerText="Sub System" width="*>150" />
                        
                        
                        <IgrNumericColumn field="facilityDailyOpCostKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>
            </div>
        );
    }
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.reportDate).toLocaleDateString("es-PA");
    }
    
    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "MeterVolumes");
    }

        
}