

import React, { useState, useEffect } from 'react';

export const USDCurrencyFormat = (value, fractionDigits = 2) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
    }).format(parseFloat(value));


export const NumberFormat = (value) =>
    new Intl.NumberFormat().format(value);

export const NumFormat = (value) =>
    new Intl.NumberFormat().format(value);


// export const NumFormat = (value) =>
// new Intl.NumberFormat().format(value);

export const IsDate2 = (myDate) => {
    return myDate.constructor.toString().indexOf("Date") > -1;
  }

export const IsDate = (date) => {
    return (date !== null && new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export const FormatDate = (value) => {

    if (IsDate(value)) {
        const months = {
            0: '01',
            1: '02',
            2: '03',
            3: '04',
            4: '05',
            5: '06',
            6: '07',
            7: '08',
            8: '09',
            9: '10',
            10: '11',
            11: '12',
        }
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const d = new Date(value)
        const year = d.getFullYear()
        const date = (d.getDate() < 10 ? '0' : '') + d.getDate()
        const monthIndex = d.getMonth()
        const monthName = months[d.getMonth()]
        const dayName = days[d.getDay()] // Thu

        const formatted = `${monthName}/${date}/${year}`

        return formatted.toString()
    } else {
        return ""
    }

}

export const FormatDateUTC = (value) => {

    if (IsDate(value)) {
        const months = {
            0: '01',
            1: '02',
            2: '03',
            3: '04',
            4: '05',
            5: '06',
            6: '07',
            7: '08',
            8: '09',
            9: '10',
            10: '11',
            11: '12',
        }
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const d = new Date(value)
        const year = d.getFullYear()
        const date = (d.getDate() < 10 ? '0' : '') + d.getDate()
        const monthIndex = d.getMonth()
        const monthName = months[d.getMonth()]
        const dayName = days[d.getDay()] // Thu

        const formatted = `${year}-${monthName}-${date}T00:00:00`

        return formatted.toString()
    } else {
        return ""
    }

}

export const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            padding: "0",
            margin: "0"
        }}
    />

);

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
}

export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

export const getFirstDayofMonth = () => {
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    return (new Date(year, month, 1));
};

export const getFirstDayOfJanuaryYearsAgo = (intYears) => {
    let d = new Date();
    let year = d.getFullYear() - intYears; 
    let month = 0; 
    return new Date(year, month, 1);
};


export const getUserADAccount = (emailAddress) => {
    let returnaddress = emailAddress.replace("@h2obridge.com", "")
    returnaddress = returnaddress.replace("@desertenv.com", "")
    return returnaddress;
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}



export const bitwise = (e, value) => {

    const temp = Number(value)
    return ((Number(e) & temp))

}
