import React, { Component, useEffect, useReducer, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, UncontrolledTooltip } from 'reactstrap';

import PowerBIReportViewer from '../../resources/PowerBIReportViewer'

import classnames from 'classnames';

import MenuIcon from '../../resources/menu.png';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Excel from '../../resources/excel.svg'
import Revise from '../../resources/revise.png'
import '../../custom.css';
import { ExcelUtility } from '../../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import ImageViewer from '../../resources/ImageViewer';
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import ConfirmModal from '../../operation/ConfirmModal'
import ConfirmWithMsgModal from '../../resources/ConfirmWithMsgModal'

import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import { useSyncExternalStore } from 'react';

import InvoiceDetails from './InvoiceDetails'
import R9ScadaTickets from './R9ScadaTickets';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


var DatePicker = require("reactstrap-date-picker");


export default class InvoiceHeader extends Component {
    static displayName = InvoiceHeader.name

    constructor(props) {
        super(props);
        document.title = "Invoice"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.onStatusDateUpdating = this.onStatusDateUpdating.bind(this);
        // this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            invHeader: [],
            isLoaded: false,
            invoiceBatchKey: props.invoiceBatchKey,
            invoiceKey: -1,
            selInvoice: [],
            invStatusKey: -1,
            modal: false,
            modalReport: false,
            modalR9Ticket: false,
            user: props.user,
            windowHeight: window.innerHeight,
            statusKey: -1,
            custList: [],
            isCustListLoaded: false,
            paymentTermList: [],
            isPMTLoaded: false,
            uomList: [],
            isUOMLoaded: false,
            itemList: [],
            isItemListLoaded: false,
            startDate: null,
            endDate: null,
            invSearchText: '',
            dropdownOpen: false,
            confirmType: '',
            modalConfirm: false,
            confirmMsg: ''
        }

    }

    toggleConfirm = (type) => {
        var msg = ''
        if (type === 'R9') {
            msg = 'Are you sure you want to run R9 Invoice Service?'
        } else if (type === 'PInv') {
            msg = 'Are you sure you want to print selected invoices?'
        } else if (type === 'EIB') {
            msg = 'Are you sure you want to create EIB for selected invoices?'
        } else if (type === "DEL") {
            msg = 'Are you sure you want to delete selected invoice(s)?'
        }

        this.setState({
            modalConfirm: !this.state.modalConfirm,
            confirmType: type,
            confirmMsg: msg
        })

    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    onTextChange = (fieldName, fieldValue, e) => {

        switch (fieldName) {
            case "invSearch":
                this.setState({ invSearchText: fieldValue })
                break;
            case "startDate":
                this.setState({ startDate: fieldValue })
                break;
            case "endDate":
                this.setState({ endDate: fieldValue })
                break;
            default:
                break;
        }
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport })
    }

    toggleR9Ticket = () => {
        this.setState({ modalR9Ticket: !this.state.modalR9Ticket })
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)

            this.setState({
                invoiceKey: dataItem.invoiceKey,
                selInvoice: dataItem,
                invStatusKey: dataItem.invStatusKey
            })
            // this.setState({selSafeObs: dataItem})
            // this.toggle()
            //this.onVolRefresh()    
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.invBatch;
    }


    getInvovice = () => {
        const { invoiceBatchKey } = this.state
        // let myURI = apiURL + 'SafetyObservation/startdate/' +
        //     this.state.startDate.toString().substring(0, 10) +
        //     '/enddate/' + this.state.endDate.toString().substring(0, 10)
        //     + '/AzureAD/' + this.props.user.account.userName;
        // console.log(myURI);
        var myURI = ''
        if (this.props.invoiceBatchKey > 0) {
            myURI = apiURL + 'ar/Invoice/ByBatch/' + this.props.invoiceBatchKey;
        } else if (this.state.startDate !== null && this.state.endDate !== null) {
            myURI = apiURL + 'ar/Invoice/startdate/' +
                this.state.startDate.toString().substring(0, 10) +
                '/enddate/' + this.state.endDate.toString().substring(0, 10)
                + '/AzureAD/' + this.props.user.account.userName;
        }
        else {
            myURI = apiURL + 'ar/Invoice/AzureAD/' + this.props.user.account.userName;


        }
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    invHeader: json
                })
            });

    }


    getCustomer = () => {

        let myURI = apiURL + 'ar/Customers'
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isCustListLoaded: true,
                    custList: this.createCustList(json)
                })
            });

    }

    getItem = () => {
        let myURI = apiURL + 'ar/ItemMaster'
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isItemListLoaded: true,
                    itemList: this.createItemList(json)
                })
            });
    }

    createItemList = (data) => {
        var tempList = []
        data.map(e => {
            e.value = e.itemID
            e.label = e.itemDescription
            tempList.push(e)
        })

        return tempList
    }

    getUOM = () => {
        let myURI = apiURL + 'ar/uom'
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isUOMLoaded: true,
                    uomList: this.createUOMList(json)
                })
            });
    }

    createUOMList = (data) => {
        var tempList = []
        data.map(e => {
            e.value = e.uomid
            e.label = e.uomDesc
            tempList.push(e)
        })

        return tempList
    }

    getPaymentTerms = () => {

        let myURI = apiURL + 'ar/PaymentTerms'
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isPMTLoaded: true,
                    paymentTermList: this.createPaymentTermList(json)
                })
            });

    }
    createPaymentTermList = (data) => {
        var tempList = []

        data.map(e => {
            e.value = e.paymentTermID
            e.label = e.paymentTermDesc
            tempList.push(e)
        })

        return tempList
    }


    createCustList = (data) => {
        var tempList = []

        data.map(e => {
            e.value = e.customer_ID
            e.label = e.customer
            tempList.push(e)
        })

        return tempList
    }

    viewInvDetails = () => {
        this.toggle()
    }


    componentDidMount() {
        this.getItem()
        this.getUOM()
        this.getCustomer()
        this.getPaymentTerms()
        this.getInvovice()
    }


    runR9InvService() {
        let myURI = apiURL + 'ar/R9InvoiceService/startDate/-1/endDate/-1'
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => {
                this.getInvovice()
            }).then(e => {
                if (this.state.modalConfirm) {
                    this.setState({ modalConfirm: false })
                }
            });
    }


    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);

        }
        else {
            link = content.children[0];
            //console.log(item)
            if (item.errorMsg !== '') {
                content.style.backgroundColor = "#f8858b";
            } else if (item.invStatusKey === 4) {
                content.style.background = "#b6a065";
            }
            else if (item.invStatusKey === 8 || item.invStatusKey === 6) {
                content.style.background = "#b8f2b1";
            }
            else if (item.invStatusKey === 2 || item.statusKey === 5) {
                content.style.background = "#ad5f55";
            } else {
                content.style.background = "transparent";
            }

        }


        switch (s.field) {
            case 'invoiceNo':
                link.textContent = item.invoiceNo;
                break;
            case 'errorMsg':
                link.textContent = item.errorMsg;
                break;
            case 'batchTypeDesc':
                link.textContent = item.batchTypeDesc;
                break;
            default:

        }

    }

    createReportParamter = () => {
        const { invoiceKey } = this.state
        const reportPara = [{
            "name": "InvoiceKey",
            "value": invoiceKey
        }]
        // console.log(reportPara)
        return reportPara
    }


    render() {
        const { invHeader, isLoaded, windowHeight, paymentTermList,
            isPMTLoaded, modalConfirm, confirmType, confirmMsg,
            selInvoice, invoiceKey, custList, uomList, itemList, invSearchText } = this.state

        const searchText = invSearchText.toLowerCase()

        return (<div>
            <Form onSubmit={e => e.preventDefault}>
                <FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={8}>
                                <Label><h3> Reclamation Invoices |</h3></Label>

                                {/*                         
                            <Button outline color="secondary"
                                className="btn-no-border"
                                onClick={this.saveWorkbook}>
                                <img
                                    src={Excel}
                                    alt="excel"
                                    style={{ width: "50px", margin: "0 0 0 0" }} />
                                Export To Excel
                            </Button>{' '} */}
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={(e) => this.getInvovice()}
                                >
                                    <img
                                        src={Refresh}
                                        alt="refresh"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    Refresh
                                </Button>{' '}

                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    hidden={true}
                                    onClick={(e) => this.newSupReq()}
                                >
                                    <img
                                        src={Plus}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    New Invoice
                                </Button>{' '}

                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.viewInvDetails}
                                //disabled={supplierRequestKey < 0}
                                >
                                    <img
                                        src={Revise}
                                        alt="details"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Details
                                </Button>{' '}

                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.toggleReport}
                                //disabled={supplierRequestKey < 0}
                                >
                                    <img
                                        src={Revise}
                                        alt="details"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Report
                                </Button>{' '}

                                <Button outline={true} color="secondary"
                                    //className="btn-no-border"
                                    onClick={this.toggleR9Ticket}
                                //disabled={supplierRequestKey < 0}
                                >
                                    R9 Tickets
                                </Button>{' '}

                            </Col>

                            <Col sm={4}>
                                <ConfirmModal outline
                                    color="danger"
                                    className="float-right"
                                    buttonLabel="Delete"
                                    hidden={true} //{!(statusKey === 0 && afeHeaderKey > 0)}
                                    formName="Confirm"
                                    //disabled={supplierRequestKey < 0 || statusKey > 0}
                                    message="Are you sure you want to delete selected Supplier Request?"
                                    onClickYes={(e) => this.deleteSupplierReq()}
                                    image={<img
                                        src={TrashBin}
                                        //id={afeHeaderKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>

                                <ConfirmModal outline
                                    color="primary"
                                    className="float-right"
                                    buttonLabel="Run R9 Invoice Service"
                                    hidden={true} //{!(statusKey === 0 && afeHeaderKey > 0)}
                                    formName="Confirm"
                                    //disabled={supplierRequestKey < 0 || statusKey > 0}
                                    message="Are you sure you want to run sync service?"
                                    onClickYes={(e) => this.runR9InvService()}
                                // image={<img
                                //     src={TrashBin}
                                //     //id={afeHeaderKey}
                                //     alt="Edit"
                                //     style={{ width: "20px", margin: "0 5px 0 0" }} />}
                                >
                                </ConfirmModal>


                                <Dropdown isOpen={this.state.dropdownOpen} nav={false}
                                    direction="left"
                                    className="no-border float-right"
                                    color='primary'
                                    outline={true}
                                    //hidden={!isAFEAdmin}
                                    toggle={this.toggleDropdown}>
                                    <DropdownToggle caret>
                                        Action
                                    </DropdownToggle>
                                    <DropdownMenu end>

                                        <DropdownItem onClick={() => this.toggleConfirm("R9")}>
                                            Run R9 Invoice Service
                                        </DropdownItem>
                                        <DropdownItem onClick={() => this.toggleConfirm("PInv")}>
                                            Print Selected Invoice
                                        </DropdownItem>
                                        <DropdownItem onClick={() => this.toggleConfirm("EIB")}>
                                            Create EIB
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => this.toggleConfirm("DEL")}>
                                            Delete Selected Invoice
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>


                            </Col>
                        </Row>
                        <ColoredLine color="grey" />
                    </FormGroup>

                    <FormGroup>

                        <Row >
                            <Col md={"auto"}>
                                <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                    Start Date
                                </Label>
                                <DatePicker value={this.state.startDate}
                                    onChange={(v, f) => this.onTextChange("startDate", v, f)} />
                            </Col>
                            <Col md={"auto"}>
                                <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                    End Date
                                </Label>
                                <DatePicker value={this.state.endDate}
                                    onChange={(v, f) => this.onTextChange("endDate", v, f)} />

                            </Col>
                            <Col>

                                <Label for="typeKey" style={{ margin: "0 0 0 0", padding: "0" }}> </Label>
                                <Input width="75%" className='floatRight'
                                    placeholder="Type here to search......."
                                    onChange={(e) => this.onTextChange("invSearch", e.target.value, e)}
                                >

                                </Input>


                            </Col>
                        </Row>

                    </FormGroup>
                </FormGroup>

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={"70vh"}
                        //dataSource={invHeader}
                        dataSource={invHeader.filter(rec => ((rec.batchTypeDesc || '').toLowerCase().includes(searchText)) ||
                            (rec.invoiceNo || '').toLowerCase().includes(searchText) ||
                            (rec.custID || '').toLowerCase().includes(searchText) ||
                            (rec.custName || '').toLowerCase().includes(searchText) ||
                            (rec.invoiceTypeDesc || '').toLowerCase().includes(searchText) ||
                            (rec.arInvStatusDesc || '').toLowerCase().includes(searchText) ||
                            (rec.invoiceDate || '').toLowerCase().includes(searchText) ||
                            (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                            (rec.createdByName || '').toLowerCase().includes(searchText) ||
                            (rec.comments || '').toLowerCase().includes(searchText) ||
                            (rec.errorMsg || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"
                        selectionMode="MultipleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                    //cellBackground={"#ffbfbf"}
                    >

                        <IgrTemplateColumn field="batchTypeDesc" headerText="Group By" width="*>180"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="invoiceNo" headerText="Invoice No" width="*>180"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTextColumn field="custID" headerText="Customer ID"
                            width="*>180"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="custName" headerText="Customer Name"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating} />


                        <IgrTextColumn field="invoiceTypeDesc" headerText="Invoice Type"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="arInvStatusDesc" headerText="Status"
                            width="*>140" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="invoiceDate" headerText="Invoice Date"
                            width="*>150"
                            dateTimeFormat={"dateShort"}
                            cellUpdating={this.onStatusDateUpdating} />

                        <IgrTemplateColumn field="dueDate" headerText="Due Date"
                            width="*>150"
                            cellUpdating={this.onStatusDateUpdating} />
                        <IgrNumericColumn field="totalInvAmnt"
                            headerText="Invoice Amount"
                            positivePrefix="$"
                            maxFractionDigits={0}
                            showGroupingSeparator="true"
                            width="*>150"
                            cellUpdating={this.onStatusDateUpdating} />
                        <IgrNumericColumn field="lineCount" headerText="No of Line"
                            width="*>120"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="billToAddress" headerText="Bill To Address"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="createdByName" headerText="Requested By"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="createdDatetime" headerText="Created Date"
                            width="*>150"
                            cellUpdating={this.onStatusDateUpdating} />
                        <IgrTemplateColumn field="errorMsg" headerText="Comments"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrNumericColumn field="invoiceKey" isHidden />
                        <IgrNumericColumn field="invoiceTypeKey" isHidden />
                        <IgrNumericColumn field="invStatusKey" isHidden />
                        <IgrNumericColumn field="errorCode" isHidden />

                    </IgrDataGrid>
                </FormGroup>

            </Form>


            <div>
                <Modal isOpen={this.state.modal}
                    toggle={() => this.toggle()}
                    //onClosed={() => this.getSupplierReq()}
                    size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    centered>
                    <ModalHeader toggle={() => this.toggle()}> Invoice Details </ModalHeader>
                    <ModalBody>
                        <InvoiceDetails
                            invoiceKey={invoiceKey}
                            selInvoice={selInvoice}
                            custList={custList}
                            paymentTermList={paymentTermList}
                            uomList={uomList}
                            itemList={itemList}
                            user={this.props.user}
                            toggle={this.toggle}
                            refreshData={this.getInvovice}
                        >

                        </InvoiceDetails>
                    </ModalBody>
                </Modal>
            </div>

            <div>
                <Modal isOpen={this.state.modalReport}
                    toggle={() => this.toggleReport()}
                    //style={{ margin: 0, flex: 1 }}
                    //onClosed={() => this.getSupplierReq()}
                    size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    //contentClassName="full-screen-modal"
                    centered
                >
                    <ModalHeader toggle={() => this.toggleReport()}> Invoice Report </ModalHeader>
                    <ModalBody>
                        <PowerBIReportViewer
                            reportName={"R9Invoice"}
                            reportParameters={this.createReportParamter()}
                            invoiceKey={invoiceKey}
                            toggle={this.toggleReport}
                            refreshData={this.getInvovice}
                        >

                        </PowerBIReportViewer>
                    </ModalBody>
                </Modal>
            </div>


            <div>
                <Modal isOpen={this.state.modalR9Ticket}
                    toggle={() => this.toggleR9Ticket()}
                    contentClassName="full-screen-modal"
                    style={{ margin: 0, flex: 1 }}
                    backdrop={"static"}
                    //onClosed={() => this.getSupplierReq()}
                    //size={"lg"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    centered
                >
                    <ModalHeader toggle={() => this.toggleR9Ticket()}> R9 Tickets </ModalHeader>
                    <ModalBody>
                        <R9ScadaTickets
                            user={this.props.user}>

                        </R9ScadaTickets>
                    </ModalBody>
                </Modal>
            </div>

            <div>
                <Modal isOpen={this.state.modalConfirm}
                    toggle={() => this.toggleConfirm('')}
                    backdrop={"static"}
                    //onClosed={() => this.getSupplierReq()}
                    size={"md"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                >
                    <ModalHeader toggle={() => this.toggleConfirm('')}> Confirm </ModalHeader>
                    <ModalBody>
                        {confirmMsg}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => (confirmType === "R9" ? this.runR9InvService() : this.toggleConfirm(''))}>Yes</Button>
                        <Button color="secondary" onClick={() => this.toggleConfirm('')}>No</Button>
                    </ModalFooter>
                </Modal>
            </div>



        </div>)
    }


    onStatusDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);

        }
        else {
            link = content.children[0];
            // console.log(item)
            // if (item.errorMsg !== '') {
            //     content.style.backgroundColor = "#f8858b";
            // } else if (item.invStatusKey === 4) {
            //     content.style.background = "#b6a065";
            // }
            // else if (item.invStatusKey === 8) {
            //     content.style.background = "#b8f2b1";
            // }
            // else if (item.invStatusKey === 2 || item.statusKey === 5) {
            //     content.style.background = "#ad5f55";
            // } else {
            //     content.style.background = "transparent";
            // }

        }


        switch (s.field) {
            case 'invoiceDate':
                var date = new Date(item.invoiceDate);
                link.textContent = date.toLocaleDateString();

                break;

            case 'createdDatetime':
                var date = new Date(item.createdDatetime);
                link.textContent = date.toLocaleDateString();

                break;

            case 'dueDate':
                var date = new Date(item.dueDate);
                link.textContent = date.toLocaleDateString();

                break;

            default:

        }

    }

}