import React,{Component} from 'react';
import {Col,CustomInput, Row, Button, FormGroup, Label} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';

import {apiURL, apiHeader} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import FacilityMeter from './FacilityMeter';

import { ColoredLine, getFirstDayofMonth,useWindowDimensions } from '../resources/utility';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
// //IgrInputModule.register();
//IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class Facility extends Component {
    
    constructor(props){
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.state={
            facility:[],
            isLoaded:false,
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            facilityKey:-1,
            facilityName:"",
            windowHeight:window.innerHeight,
            windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
            activeTab:'1',
            modal:false,
            hideAssociateDetails:((typeof(props.HideAssociateDetails) ==='undefined' || props.HideAssociateDetails===null) ? false : props.HideAssociateDetails),
            hideToolBar:((typeof(props.HideToolBar) ==='undefined' || props.HideToolBar===null) ? false : props.HideToolBar),
            facilityKeys:[],
            user:this.props.user,
            showAllFacility:false
        }
    }

    modalToggle = () =>{
        this.setState({modal: !this.state.modal})
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    onLoadStatusChange = (value,e) =>{
        this.getFacilityData(!this.state.showAllFacility)
        this.setState({showAllFacility:!this.state.showAllFacility})
       
    }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.facility;
    }

    selectedFacilityChanged = (facilityKeys) => {
        if ((typeof(this.props.SelectedFacilityChanged) !=='undefined' && this.props.SelectedFacilityChanged!==null)){
            this.props.SelectedFacilityChanged(facilityKeys)
        }
                    
    } 

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            //console.log(dataItem)
            
            if (!this.state.hideAssociateDetails){
                this.setState({facilityKey: dataItem.facilityKey,
                    facilityName: dataItem.facilityName})       
            }

            const tempList=[]
            let clickedRows = e.currentKeys
            
            for (let i = 0; i < e.currentKeys.count; i++){
               // console.log(e.currentKeys.item(i).value[0] )
                tempList.push(e.currentKeys.item(i).value[0].facilityKey); 
            }
            
            this.selectedFacilityChanged(tempList)
      
        } 
    }
    
    freshFacilityData = () =>{
        var {showAllFacility} = this.state
        this.getFacilityData(showAllFacility)
    }
    
    getFacilityData = (showAllFacility=false) =>{
        const {user}=this.state;
        let myURI
        if (showAllFacility)
        {
            myURI = apiURL + 'FacilityDetails/AzureAD/' + user.account.userName
        }
        else{
            myURI = apiURL + 'FacilityDetails/MyFacility/AzureAD/' + user.account.userName
        }
        //let 
        //let 

        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                facility: json
            })
        })

        var temp=[];
        this.state.facility.forEach((item,i) => {
            temp.push(item.facilityKey);
        })

        this.setState({
            facilityKeys:temp
        })


    }

    handleResize = (e) => {
        // console.log("document.getElementById('mainDiv')")
        // console.log(document.getElementById('mainDiv'))
        
        if (document.getElementById('mainDiv') !== null){
            this.setState({ windowHeight: window.innerHeight,
                windowWidth: document.getElementById('mainDiv').offsetWidth });

        }
    };

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getFacilityData();
        this.selectedFacilityChanged(this.state.facilityKeys)
    }


    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    render(){
        var {facility,isLoaded,windowHeight,windowWidth, activeTab,showAllFacility,
            facilityKey,facilityName,hideAssociateDetails,hideToolBar,
            facilityKeys} = this.state;
        const gridHeight=(windowHeight * (hideAssociateDetails ? 0.8: 0.4)) + "px"
        var searchText = window.$searchText.toLowerCase();
        
        //const { height, width } = useWindowDimensions();
        console.log("selected FAcilities: " + facilityKeys)
        console.log(facilityKey)

        if (!isLoaded) {
            return(<div>
                <h3>Loading facility data</h3>
            </div>)
        }else
        return(
            <div id="mainDiv">                 
                <FormGroup hidden={hideToolBar}>                   
                    <Row form >
                        <h3> Facility |</h3>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.freshFacilityData}>
                                    <img
                                    src={MenuIcon}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                        <FormGroup  style={{margin:"10px 0 0 100px"}} >
                        <h6>
                        <CustomInput className="float-right" 
                                    type="switch" 
                                    id="accept" 
                                    name="accept" 
                                    label="Show All Facilities"                                   
                                    checked={showAllFacility} 
                                    onClick={(e) => this.onLoadStatusChange(1,e)} />
                                    </h6>
                                    </FormGroup>
                    </Row>

                    <ColoredLine color="grey" />                       
                </FormGroup>
                
                
                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}
                        //width="Calc(100% - 5px)"
                        //width="100%"
                        dataSource={facility.filter(rec => ((rec.createdBy || '').toLowerCase().includes(searchText)) ||
                                                (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                (rec.facilityID || '').toLowerCase().includes(searchText) ||
                                                (rec.areaManageBy || '').toLowerCase().includes(searchText) ||
                                                (rec.areaName || '').toLowerCase().includes(searchText) ||
                                                (rec.inspComments || '').toLowerCase().includes(searchText) ||                                                            
                                                (rec.facilityComments || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                                   
                        selectionMode="MultipleRow"
                        selectedKeysChanged={this.onGridRowSelected}>         

                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>190" />
                        <IgrTextColumn field="facilityID" headerText="Facility ID" width="*>140"
                            isHidden={(windowWidth < 450 ? true : false)} />                        
                        
                        <IgrTextColumn field="locationName" headerText="Basin" width="*>140" 
                                       isHidden={(windowWidth < 450 ? true : false)} />

                        <IgrTextColumn field="areaName" headerText="Area" width="*>130" 
                                        />
                        
                        <IgrTextColumn field="subAreaName" headerText="Sub Area" width="*>130" 
                                        /> 
                        
                        <IgrTextColumn field="subSystem" headerText="Sub System" width="*>130" 
                                        />                                                           
                        <IgrNumericColumn field="permitCap" headerText="Permit Cap" width="*>100"
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        <IgrNumericColumn field="operationCap" headerText="Operation Cap" width="*>130"
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        <IgrNumericColumn field="royaltyCost" headerText="Royalty" width="*>130"
                                        isHidden={(windowWidth < 450 ? true : false)}/>                    
                        <IgrTextColumn field="facilityTypeDesc" headerText="Facility Type" width="*>120" 
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        <IgrNumericColumn field="meterCount" headerText="Meter Count" width="*>130"
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        <IgrNumericColumn field="tankCount" headerText="Tank Count" width="*>130"
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        <IgrNumericColumn field="lat" headerText="Latitude" width="*>130"
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        <IgrNumericColumn field="long" headerText="Longtitude" width="*>130"
                                        isHidden={(windowWidth < 450 ? true : false)}/>

                        <IgrTemplateColumn field="acquiredDate" headerText="Acquired Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}
                                            isHidden={(windowWidth < 450 ? true : false)}/>
                        <IgrTextColumn field="areaManageBy" headerText="Area Manage By" width="*>150" 
                                            isHidden={(windowWidth < 450 ? true : false)}/>                                   
  
                        {/* <IgrTextColumn field="costDescription" headerText="Cost Desc" width="*>210" />
                        */}
                        <IgrTextColumn field="createdBy" headerText="Created By" width="*>150"
                                        isHidden={(windowWidth < 450 ? true : false)} />                                   
                        
                        <IgrTextColumn field="modifiedBy" headerText="Modified By" width="*>150" 
                                        isHidden={(windowWidth < 450 ? true : false)}/>                                   
                        
                        <IgrTextColumn field="inspComments" headerText="Inspection Note" width="*>450"
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        
                        <IgrTextColumn field="facilityComments" headerText="Facility Comments" width="*>450"
                                        isHidden={(windowWidth < 450 ? true : false)}/>
                        
                        
                        <IgrNumericColumn field="facilityDailyOpCostKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>

                <FormGroup hidden={hideAssociateDetails}> 
                <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '1'})}
                                      onClick={() => {this.toggle('1')}}>
                                <h5>Meters</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem hidden>
                            <NavLink className={classnames({active: activeTab === '2'})}
                                      onClick={() => {this.toggle('2')}}>
                                <h5>Tanks</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem hidden>
                            <NavLink className={classnames({active: activeTab === '3'})}
                                      onClick={() => {this.toggle('3')}}>
                                <h5>Inspections</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem hidden>
                            <NavLink className={classnames({active: activeTab === '4'})}
                                      onClick={() => {this.toggle('4')}}>
                                <h5>Oil Tickets</h5>
                            </NavLink>
                        </NavItem>                        
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">                                                        
                            <FacilityMeter FacilityKey={facilityKey}
                                           FacilityName={facilityName}
                                           GridHeightRatio={0.30}
                                           GridFilter={false}
                                           HideHeader={true}
                                           user={this.props.user}>


                            </FacilityMeter>
                        </TabPane>

                        <TabPane tabId="2">
                            {/* <TankVolume VoldHeaderLabel=""/>                                 */}
                            <h3> Facility Tank List</h3>
                        </TabPane>

                        <TabPane tabId="3">
                            {/* <TankVolume VoldHeaderLabel=""/>                                 */}
                            <h3> Facility Inspections</h3>
                        </TabPane>
                        

                        <TabPane tabId="4">
                            {/* <TankVolume VoldHeaderLabel=""/>                                 */}
                            <h3> Facility Oil Ticket</h3>
                        </TabPane>
                        
                    </TabContent>
                </FormGroup>
                
                <div>
                <Modal isOpen={this.state.modal} toggle={() => this.modalToggle()} className="{className}">
                    <ModalHeader toggle={() => this.toggle()}> Facility Detals </ModalHeader>
                    <ModalBody>
                    Facility Details here
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.modalToggle()}>OK</Button>
                        <Button color="primary" onClick={() => this.modalToggle()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                </div>

            </div>
        )


    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "FacilityLists");
    }

}