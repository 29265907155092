
import React, { Component } from 'react';

import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import TrashBin from '../resources/trash.svg'
import { USDCurrencyFormat, getRandomInt } from '../resources/utility'
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'
//import DocumentBrowser from '../resources/DocumentBrowser';
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop';
import DocumentUploader from '../resources/DocumentUploader';
import ConfirmModal from '../operation/ConfirmModal'
import SafetyObservationCreateIncident from './safetyIncident/SafetyObservationCreateIncident'
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
import Select from 'react-select';

var DatePicker = require("reactstrap-date-picker");

export default class SafetyObservationDetail extends Component {
    static displayName = SafetyObservationDetail.name;

    constructor(props) {
        super(props)
        const urlPara = this.getURLParameters(props.location)

        this.state = {
            isReadOnly: false,
            isSubmitHidden: false,
            observationType: [],
            isObservationTypeLoaded: false,
            submitBy: [],
            isSubmitByLoaded: false,
            location: [],
            isLocationLoaded: false,
            observation: [],
            isObservationLoaded: false,
            safetyObservationKey: (urlPara.safetyObsKey === null ? props.SafetyObservationKey : urlPara.safetyObsKey),
            submittedDatetime: null,
            activeTab: '1',
            modal: false,
            isQHSEAdmin: props.isQHSEAdmin,
            isQHSEApproverforThisSO: false,
            isMVAUser: props.isMVAUser,
            statusKey: 0,
            ddTypes: [],
            ddMvadescription: [],
            modalReport: false,
            preventablehidden: true,
            estspillhidden: true,
            offLineLinkSafetyObservationKey: (getRandomInt(10000) * -1),
            truckList: props.truckList,
            validateDataItem: {
                safetyTypeKey: true,
                locationKey: true,
                hazardAndRiskValue: true,
                riskInvolvePeopleValue: true,
                hazardDesc: true,
                solutionDesc: true,
                mvadescription: true,
                estSpillAmt: true
            }

        }
    }

    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport })
    }

    createReportParamter = () => {
        const { safetyObservationKey } = this.state
        // const reportPara = [{
        //     "name": "InvoiceKey",
        //     "value": invoiceKey  
        // }]
        const reportPara = [{
            "name": "SafetyObservationKey",
            "value": safetyObservationKey
        }]

        //console.log(reportPara)
        return reportPara

    }

    getURLParameters = (inputPara) => {

        var paraMeters = {
            safetyObsKey: null
        }
        var query
        if (inputPara !== undefined) {
            query = new URLSearchParams(inputPara.search);
            // console.log("query")
            // console.log(query.get('key') )

            paraMeters = {
                safetyObsKey: query.get('key')
            }

            // console.log("props.user")
            // console.log(this.props.user)
        }

        return paraMeters

    }

    getIsQHSEadmin = () => {

        let myURI = apiURL + 'SafetyObservation/IsQHSEAdmin/' + this.props.user.account.userName;

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isQHSEAdmin: (json.isQHSEAdmin != 0)
                })
            });
    }

    getMvadescription = () => {

        let myURI = apiURL + 'SafetyIncident/getCodeDefinition/Mvadescription';

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    ddMvadescription: (json)
                })

            });


    }





    getIsQHSEApproverforThisSO = () => {
        const { safetyObservationKey } = this.state

        let myURI = apiURL + 'SafetyObservation/IsQHSEApproverforThisSO/' + this.props.user.account.userName + '/' + safetyObservationKey;

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isQHSEApproverforThisSO: (json.isQHSEApprover != 0)
                })
            });
    }

    getIsMVAUser = () => {


        let myURI = apiURL + 'SafetyObservation/IsMVAUser/' + this.props.user.account.userName;


        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isMVAUser: (json.isMVAUser != 0)
                })
            });
    }


    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    toggle = () => {
        // console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })

    }

    updateQHSE = (boolApproving = false) => {
        const obsData = this.SafetyObservationForUpdateDto()
        // console.log(obsData)

        let myURI = apiURL + 'SafetyObservation'

        fetch(myURI, apiPUTHeader(obsData))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    //   console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                // console.log("No error here: " + rspData)
            })
            .catch(error => {
                // console.log("PUT Error: " + error);
            })

        if (boolApproving) {
            this.getSefetyObservation()
            // don't cloase the form
        } else {
            this.closeForm(true)
        }

    }

    saveData = (boolApproving = false) => {
        const { safetyObservationKey } = this.state

        if (safetyObservationKey < 0) {
            if (this.validataData()) {
                this.addQHSE()
            }
        } else {
            if (this.validataData()) {
                this.updateQHSE(boolApproving)
            }

        }
    }

    rejectData = () => {
        const { observation } = this.state
        const { user } = this.props

        var obsData = observation
        obsData[0].statusKey = 5
        obsData[0].reviewedBy = getUserADAccount(user.account.userName)

        this.setState({ observation: obsData })
        this.saveData()
    }

    submitData = () => {

        if (this.validataData()) {
            // console.log("insubmit")   
            const { observation } = this.state
            const { user } = this.props

            var obsData = observation
            // if (obsData[0].statusKey=)
            //obsData[0].submittedDatetime = new Date().toISOString()

            obsData[0].statusKey = 4
            //obsData[0].submittedBy=getUserADAccount(user.account.userName)


            this.setState({ observation: obsData })
            this.saveData()
        }
    }

    validataData = () => {
        const { validateDataItem, observation, preventablehidden, estspillhidden } = this.state
        var bVal = true
        var valItem = validateDataItem
        if (observation[0].safetyTypeKey === null || observation[0].safetyTypeKey === 0) {
            valItem.safetyTypeKey = false
            bVal = false
        }
        if (observation[0].locationKey === null || observation[0].locationKey === 0) {
            valItem.locationKey = false
            bVal = false
        }

        if (observation[0].hazardAndRiskValue === null || observation[0].hazardAndRiskValue === 0) {
            valItem.hazardAndRiskValue = false
            bVal = false
        }

        if (observation[0].riskInvolvePeopleValue === null || observation[0].riskInvolvePeopleValue === 0) {
            valItem.riskInvolvePeopleValue = false
            bVal = false
        }

        if (observation[0].hazardDesc === null || observation[0].hazardDesc === "") {
            valItem.hazardDesc = false
            bVal = false
        }

        if (observation[0].solutionDesc === null || observation[0].solutionDesc === "") {
            valItem.solutionDesc = false
            bVal = false
        }

        if (observation[0].mvadescription === "" && !preventablehidden) {
            valItem.mvadescription = false
            bVal = false
        }

        if ((observation[0].estSpillAmt === "" || observation[0].estSpillAmt === null) && !estspillhidden) {
            valItem.estSpillAmt = false
            bVal = false
        }

        this.setState({ validateDataItem: valItem })
        return bVal
    }

    approveData = () => {
        const { observation } = this.state
        const { user } = this.props

        var obsData = observation
        obsData[0].statusKey = 6
        obsData[0].reviewedBy = getUserADAccount(user.account.userName)
        obsData[0].reviewedDateTime = new Date().toISOString()

        this.setState({ observation: obsData })

        let isApproving = true;
        this.saveData(isApproving);
    }


    deleteData = () => {
        const obsData = this.SafetyObservationForDeleteDto()
        // console.log(obsData)

        let myURI = apiURL + 'SafetyObservation'

        fetch(myURI, apiDELETEHeader(obsData))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    //   console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                // console.log("No error here: " + rspData)
            })
            .catch(error => {
                // console.log("DELETE Error: " + error);
            })

        this.closeForm(true)
    }

    addQHSE = () => {
        const obsData = this.SafetyObservationForCreateDto()
        // console.log(obsData)

        let myURI = apiURL + 'SafetyObservation'

        fetch(myURI, apiPOSTHeader(obsData))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    //   console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                // console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })

        this.closeForm(true)
    }

    SafetyObservationForDeleteDto = () => {
        const { observation } = this.state
        const { user } = this.props

        const safeObsForDeleteDTO = {
            "safetyObservationKey": observation[0].safetyObservationKey,
            "deletedBy": getUserADAccount(user.account.userName), /*4=Submitted, 6=Reviewed*/
            "latitude": 0,
            "longtitude": 0
        }

        return safeObsForDeleteDTO;
    }

    SafetyObservationForUpdateDto = () => {
        const { observation } = this.state
        const { user } = this.props

        const safeObsForUpdateDTO = {
            "safetyObservationKey": observation[0].safetyObservationKey,
            "statusKey": observation[0].statusKey, /*4=Submitted, 6=Reviewed*/
            "safetyTypeKey": observation[0].safetyTypeKey,
            "locationKey": observation[0].locationKey,
            "facilityKey": observation[0].facilityKey,
            "hazardDesc": observation[0].hazardDesc,
            "solutionDesc": observation[0].solutionDesc,
            "modifiedBy": getUserADAccount(user.account.userName),
            "reviewedBy": observation[0].reviewedBy,
            "latitude": observation[0].latitude,
            "longtitude": observation[0].longtitude,
            "hazardAndRiskValue": observation[0].hazardAndRiskValue,
            "hazardAndRiskOtherCmnt": observation[0].hazardAndRiskOtherCmnt,
            "riskInvolvePeopleValue": observation[0].riskInvolvePeopleValue,
            "riskInvolvePeopleOtherCmnt": observation[0].riskInvolvePeopleOtherCmnt,
            "contractor": observation[0].contractor,
            "altLocationName": observation[0].altLocationName,
            "reportedTo": observation[0].reportedTo,
            "submittedBy": observation[0].submittedBy,
            "submittedDatetime": observation[0].submittedDatetime,
            "reviewComments": observation[0].reviewComments,
            "privateComments": observation[0].privateComments,
            "logLatitude": 0,
            "logLongtitude": 0,
            "preventable": 0,
            "mvadescription": observation[0].mvadescription,
            "estSpillAmt": observation[0].estSpillAmt,
            "truckNo": observation[0].truckNo
        }

        return safeObsForUpdateDTO;
    }



    SafetyObservationForCreateDto = () => {
        const { observation } = this.state
        const { user } = this.props

        const safeObsForCreateDTO = {
            "statusKey": observation[0].statusKey, /*4=Submitted, 6=Reviewed*/
            "safetyTypeKey": observation[0].safetyTypeKey,
            "locationKey": observation[0].locationKey,
            "facilityKey": observation[0].facilityKey,
            "hazardDesc": observation[0].hazardDesc,
            "solutionDesc": observation[0].solutionDesc,
            "createdBy": getUserADAccount(user.account.userName),
            "latitude": observation[0].latitude,
            "longtitude": observation[0].longtitude,
            "hazardAndRiskValue": observation[0].hazardAndRiskValue,
            "hazardAndRiskOtherCmnt": observation[0].hazardAndRiskOtherCmnt,
            "riskInvolvePeopleValue": observation[0].riskInvolvePeopleValue,
            "riskInvolvePeopleOtherCmnt": observation[0].riskInvolvePeopleOtherCmnt,
            "contractor": observation[0].contractor,
            "altLocationName": observation[0].altLocationName,
            "reportedTo": observation[0].reportedTo,
            "offLineLinkSafetyObservationKey": observation[0].offLineLinkSafetyObservationKey,
            "submittedBy": observation[0].submittedBy,
            "submittedDatetime": observation[0].submittedDatetime, // (observation[0].statusKey == 4 ? new Date().toISOString() :"")
            "preventable": 0,
            "mvadescription": observation[0].mvadescription,
            "estSpillAmt": observation[0].estSpillAmt,
            "truckNo": observation[0].truckNo

        }

        return safeObsForCreateDTO;
        // "submittedBy":(observation[0].statusKey === 4 ? getUserADAccount(user.account.userName):""),

    }

    closeForm = (refreshData) => {
        if (refreshData) {
            this.props.refreshData();
        }

        this.props.toggle()
    }

    addSafetyObsDoc = () => {
        this.toggle();
    }

    getSubmitBy = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyObservation/SubmitBy/List/' + user.account.userName

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isSubmitByLoaded: true,
                    submitBy: json
                })
            });
    }


    getSefetyType = () => {
        let myURI = apiURL + 'SafetyObservation/type'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isObservationTypeLoaded: true,
                    observationType: json
                })
            });
    }

    getSefetySubmitLocation = () => {
        let myURI = apiURL + 'SafetyObservation/SubmitLocations'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isLocationLoaded: true,
                    location: json
                })
            });
    }


    getSefetyObservation = () => {
        const { safetyObservationKey, isQHSEAdmin } = this.state
        // console.log(safetyObservationKey)

        if (safetyObservationKey > 0) {
            let myURI = apiURL + 'SafetyObservation/' + safetyObservationKey

            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    this.setState({
                        isObservationLoaded: true,
                        observation: json
                    })

                    if (json.length > 0) {
                        this.setState({
                            isContractor: (json[0].contractor === 0 ? false : true),
                            // isPreventable:(json[0].preventable ),
                            preventablehidden: (json[0].safetyTypeKey === 6 ? false : true),
                            estspillhidden: (json[0].safetyTypeKey === 3 ? false : true),
                            submittedDatetime: new Date(json[0].submittedDatetime).toISOString(),
                            statusKey: json[0].statusKey,
                            isReadOnly: this.setIsReadOnly(json[0].statusKey)
                        })
                    }
                });
        } else {
            const safetyObsData = this.createSafetyObsData()
            this.setState({
                isObservationLoaded: true,
                observation: safetyObsData,
                isContractor: false,
                // isPreventable:NULL,
                submittedDatetime: new Date().toISOString(),
                statusKey: 0,
                isReadOnly: false
            })
        }

    }

    createSafetyObsData = () => {
        const { user } = this.props
        const { offLineLinkSafetyObservationKey } = this.state

        const safetyData = {
            "contractor": 0,
            "createdBy": "",
            "createdByName": user.account.name,
            "createdDatetime": new Date().toISOString(),
            "facilityID": "",
            "facilityKey": null,
            "facilityName": "",
            "hazardAndRiskOtherCmnt": "",
            "hazardAndRiskValue": 0,
            "hazardDesc": "",
            "latitude": 0,
            "locationID": "",
            "locationKey": 0,
            "locationName": "",
            "longtitude": 0,
            "modifiedBy": "",
            "modifiedDateTime": null,
            "offLineLinkSafetyObservationKey": offLineLinkSafetyObservationKey,
            "privateComments": "",
            "qhseReportTypeDesc": "",
            "qhseReportTypeID": "",
            "reportedTo": "",
            "reviewComments": "",
            "reviewdByName": " ",
            "reviewedBy": "",
            "reviewedByEmail": null,
            "reviewedDateTime": null,
            "riskInvolvePeopleOtherCmnt": "",
            "riskInvolvePeopleValue": 0,
            "safetyObservationKey": 0,
            "safetyObservationNo": "HSEXXXXXXX",
            "safetyTypeKey": 0,
            "solutionDesc": "",
            "statusDesc": "New",
            "statusKey": 0,
            "submittedBy": getUserADAccount(user.account.userName),
            "submittedByKey": null,
            "submittedByName": "",
            "submittedDatetime": "",
            "sumittedByEmail": null,
            "mvadescription": "",
            "estSpillAmt": "",
            "truckNo":""
        }

        return [safetyData]
    }



    setIsReadOnly = (statusKey) => {
        //Incomplete	0
        // Submitted	4
        // Rejected	5
        // Approved	6
        const { isQHSEAdmin, isQHSEApproverforThisSO, isMVAUser, observation } = this.state
        //return (statusKey==6 || (!isQHSEAdmin && statusKey==4))

        //Submit hidden
        //observation[0].qhseReportTypeDesc=='Motor Vehicle Accident'
        let bSubmitHidden = true
        bSubmitHidden = !(statusKey === 0 || statusKey === 5)
        if (!bSubmitHidden && (observation[0].safetyTypeKey === 6) && !(isQHSEAdmin || isQHSEApproverforThisSO) &&
            (this.props.user.account.userName.toLowerCase() !== observation[0].sumittedByEmail.toLowerCase() ||
                this.props.user.account.userName.toLowerCase() !== observation[0].createdbyEmail.toLowerCase() ||
                this.props.user.account.userName.toLowerCase() !== observation[0].submittedManagerAzureADLvl1.toLowerCase()
            )
        ) {
            bSubmitHidden = true
        }

        this.setState({ isSubmitHidden: bSubmitHidden })
        //end of Submit hidden



        let bReadonly = true

        bReadonly = (statusKey === 6 || (!(isQHSEAdmin || isQHSEApproverforThisSO) && statusKey === 4))
        if (!bReadonly && isMVAUser && (observation[0].safetyTypeKey === 6) && !(isQHSEAdmin || isQHSEApproverforThisSO) && (this.props.user.account.userName.toLowerCase() !== observation[0].sumittedByEmail.toLowerCase())) {
            bReadonly = true
        }


        return bReadonly

        // return (statusKey===6 || (!(isQHSEAdmin|| isQHSEApprover) && statusKey===4))

    }

    harzardAndRiskValue = (value, checkValue) => {
        return (value & checkValue) === checkValue
    }

    componentDidMount() {
        this.getIsQHSEadmin()
        this.getMvadescription()
        this.getIsQHSEApproverforThisSO()
        this.getIsMVAUser()
        this.getddTypes()
        this.getSubmitBy()
        this.getSefetySubmitLocation()
        this.getSefetyType()
        this.getSefetyObservation()
    }

    getddTypes = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetSafIncType'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    //isddTypesLoaded: true,
                    //ddTypes: json
                    ddTypes: json.filter(rec => (rec.typeID !== "JSA"))
                })
            });
    }

    openSI = (safIncHeaderKey) => {

        const url = "/#/SafetyIncidentHeader?safIncHeaderKey=" + safIncHeaderKey;
        window.open(url, '_blank')

    }

    newIncident = (ddkey, desc) => {
        const { safetyObservationKey } = this.state
        const itemDTO = this.creatHeaderforInsertDTO(ddkey, desc, safetyObservationKey);
        this.saveData();

        // console.log(itemDTO);

        const putReq = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }


        let myURI = apiURL + 'SafetyIncident/insertSafIncfromObservation'



        fetch(myURI, putReq)
            .then(response => response.json())
            .then(data => {
                // this.getIsSafIncAdmin();
                this.getSefetyObservation()
                // this.openHeaderForm(data[0].safIncHeaderKey);
                const url = "/#/SafetyIncidentHeader?safIncHeaderKey=" + data[0].safIncHeaderKey;
                window.open(url, '_blank')

            });


        //   .then(async response => {
        //     const rspData = response.text();
        //     console.log("dddddddddddd");
        //     console.log(response.body);
        //     if (!response.ok) {
        //       console.log("Error return but you dont see")
        //       const error = (rspData && rspData.message) || response.status;
        //       return Promise.reject(error);

        //     }
        //     // this.openHeaderForm(response.data[0].safIncHeaderKey)
        //     console.log("No error here: " + rspData)
        //     // this.getSafetyIncData();
        //     // this.openHeaderForm();


        //   })
        //  .catch(error => {
        //     console.log("POST Error: " + error);
        //   })


    }

    creatHeaderforInsertDTO = (ddkey, desc, safetyObservationKey) => {

        const { user } = this.props
        // if (!ddtyp) { ddtyp = 0 }
        // if (!desc) { desc = "" }


        const dataForInsertDTO = {
            "CreatedByAD": getUserADAccount(user.account.userName),
            "Descript": desc,
            "SafIncType": ddkey,
            "safetyObservationKey": safetyObservationKey
        }

        return dataForInsertDTO;
    }

    getSelTruck = (truckNo) => {
        const { truckList } = this.state

        if (truckList=== undefined || truckList === null || truckList.length <= 0){
            let myURL = apiURL + 'ItemMaster/FacilityInventoryCollectorFleet';
            fetch(myURL, apiHeader('GET'), { mode: 'no-cors' })
                .then(res => res.json())
                .then(data => {
    
                    const tempList = []
                    data.map(e => {
                        var temp ={value:"",label:""}
                        temp.value = e.itemDesc
                        temp.label = e.itemDesc
                        tempList.push(temp)
                    })
            
                    this.setState({
                        truckList:tempList
                    });

                    return tempList.filter(e => e.value === truckNo);
                });
        } else if (truckNo !== null && truckNo !== "") {
            return truckList.filter(e => e.value === truckNo);
        }
        return ""
    }

    onTextChange = (fieldName, fieldValue, e) => {
        console.log("props")
        console.log(fieldName + '=' + fieldValue)
        console.log(e)
        console.log(e.label)
        const { observation, validateDataItem } = this.state;
        let { preventablehidden, estspillhidden } = this.state;
        switch (fieldName) {
            case "truckNo":
                observation[0].truckNo = e.label
                break;
            case "safetyTypeKey":
                observation[0].safetyTypeKey = fieldValue
                preventablehidden = (fieldValue === '6' ? false : true)
                estspillhidden = (fieldValue === '3' ? false : true)

                validateDataItem.safetyTypeKey = true
                break;
            case "submittedBy":
                observation[0].submittedBy = fieldValue
                break;
            case "locationKey":
                observation[0].locationKey = fieldValue
                validateDataItem.locationKey = true
                break;
            case "submittedDatetime":
                observation[0].submittedDatetime = fieldValue
                this.setState({ submittedDatetime: fieldValue })
                break;
            case "reportedTo":
                observation[0].reportedTo = fieldValue
                break;
            case "contractor":
                observation[0].contractor = fieldValue
                this.setState({ isContractor: (fieldValue != 0) })
                break;
            case "mvadescription":
                observation[0].mvadescription = fieldValue

                if (fieldValue != "") { validateDataItem.mvadescription = true }
                // if (observation[0].preventable===fieldValue){
                //     observation[0].preventable=null
                // }
                // else
                //     observation[0].preventable=fieldValue
                break;
            case "estSpillAmt":
                observation[0].estSpillAmt = fieldValue

                if (fieldValue != "") { validateDataItem.estSpillAmt = true }
                // if (observation[0].preventable===fieldValue){
                //     observation[0].preventable=null
                // }
                // else
                //     observation[0].preventable=fieldValue
                break;
            case "hazardAndRiskValue":
                if (this.harzardAndRiskValue(observation[0].hazardAndRiskValue, fieldValue)) {
                    observation[0].hazardAndRiskValue = observation[0].hazardAndRiskValue - fieldValue
                } else {
                    observation[0].hazardAndRiskValue = observation[0].hazardAndRiskValue + fieldValue
                }
                validateDataItem.hazardAndRiskValue = true
                break;
            case "riskInvolvePeopleValue":
                if (this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, fieldValue)) {
                    observation[0].riskInvolvePeopleValue = observation[0].riskInvolvePeopleValue - fieldValue
                } else {
                    observation[0].riskInvolvePeopleValue = observation[0].riskInvolvePeopleValue + fieldValue
                }
                validateDataItem.riskInvolvePeopleValue = true
                break;
            case "riskInvolvePeopleOtherCmnt":
                observation[0].riskInvolvePeopleOtherCmnt = fieldValue
                if (fieldValue != "") {
                    if (!this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 1024)) {
                        observation[0].riskInvolvePeopleValue = observation[0].riskInvolvePeopleValue + 1024
                    }
                } else {
                    if (this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 1024)) {
                        observation[0].riskInvolvePeopleValue = observation[0].riskInvolvePeopleValue - 1024
                    }
                }

                break;
            case "hazardAndRiskOtherCmnt":
                observation[0].hazardAndRiskOtherCmnt = fieldValue
                if (fieldValue != "") {
                    if (!this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 4096)) {
                        observation[0].hazardAndRiskValue = observation[0].hazardAndRiskValue + 4096
                    }
                } else {
                    if (this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 4096)) {
                        observation[0].hazardAndRiskValue = observation[0].hazardAndRiskValue - 4096
                    }
                }

                break;
            case "hazardDesc":
                observation[0].hazardDesc = fieldValue
                validateDataItem.hazardDesc = true
                break;
            case "solutionDesc":
                observation[0].solutionDesc = fieldValue
                validateDataItem.solutionDesc = true
                break;
            case "reviewComments":
                observation[0].reviewComments = fieldValue
                break;
            default:

        }

        this.setState({ observation: observation, validateDataItem: validateDataItem, preventablehidden: preventablehidden, estspillhidden: estspillhidden })
    }



    render() {
        const { observationType,
            isObservationTypeLoaded,
            offLineLinkSafetyObservationKey,
            submitBy, isSubmitByLoaded,
            location, isLocationLoaded,
            observation,
            submittedDatetime,
            safetyObservationKey,
            isObservationLoaded,
            activeTab,
            isReadOnly,
            statusKey,
            isQHSEAdmin,
            isQHSEApproverforThisSO,
            isSubmitHidden,
            ddTypes,
            validateDataItem,
            preventablehidden,
            estspillhidden,
            ddMvadescription,
            truckList
        } = this.state;
        var { isContractor
            // , isPreventable
        } = this.state;
        console.log("---->observation<-----")
        console.log(observation)
        //if (isMVAUser) return <h1> yes </h1> 

        if (!isSubmitByLoaded) {
            return (<div>
                <h3>Loading Submit By User List.....</h3>
            </div>)
        }
        if (!isObservationTypeLoaded) {
            return (<div>
                <h3>Loading Safety Observation Type.....</h3>
            </div>)
        } else if (!isLocationLoaded) {
            return (<div>
                <h3>Loading Safety Observation Location.....</h3>
            </div>)
        } else if (!isObservationLoaded) {
            return (<div>
                <h3>Loading Safety Observation details.....</h3>
            </div>)
        } else if (observation.length <= 0) {
            return (<div>
                <h3>Safety Observation Detail not found.....</h3>
            </div>)
        } else
            return (<div>
                <Form>
                    <FormGroup row>
                        <Col md={12}>

                            <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="obsNo">Observation No</Label>
                                        </Col>
                                        <Col md={4}>
                                            <Input disabled type="text" name="obsNo"
                                                id="obsNo" placeholder=""
                                                value={observation[0].safetyObservationNo}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input disabled type="text" name="obsStatus"
                                                id="obsStatus" placeholder=""
                                                value={observation[0].statusDesc}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="createdBy">Created By</Label>
                                        </Col>
                                        <Col md={4}>
                                            <Input disabled type="text" name="createdBy"
                                                id="createdBy" placeholder=""
                                                value={observation[0].createdByName}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input disabled type="text" name="createdDate"
                                                id="createdDAte" placeholder=""
                                                value={new Date(observation[0].createdDatetime).toLocaleDateString()}
                                            />
                                        </Col>

                                    </Row>
                                </Col>

                            </FormGroup>

                            <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="obsType">Observation Type</Label>
                                        </Col>

                                        <Col md={8}>
                                            <CustomInput invalid={!validateDataItem.safetyTypeKey} type="select"
                                                disabled={isReadOnly}
                                                id="obsType"
                                                name="obsType"
                                                defaultValue={observation[0].safetyTypeKey}
                                                value={observation[0].safetyTypeKey}
                                                onChange={(e) => this.onTextChange("safetyTypeKey", e.target.value, e)}
                                            >
                                                <option value=""></option>
                                                {observationType.map(v => <option value={v.qhseReportTypeKey}>{v.qhseReportTypeDesc}</option>)}

                                            </CustomInput>
                                            <FormFeedback tooltip>Please select observation type</FormFeedback>
                                        </Col>

                                    </Row>
                                </Col>


                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="submitedBy">Submitted For</Label>
                                        </Col>
                                        <Col md={8}>
                                            {isReadOnly ? (
                                                <Input type="text" name="reportTo"
                                                    id="reportTo" placeholder=""
                                                    value={observation[0].submittedByName}
                                                    defaultValue={observation[0].submittedByName}
                                                    disabled={true}
                                                // onChange = {(e) => this.onTextChange("reportedTo",e.target.value,e)}
                                                />
                                            ) : (

                                                <CustomInput
                                                    type="select"
                                                    id="submitedBy"
                                                    name="submitedBy"
                                                    defaultValue={observation[0].submittedBy.toLowerCase()}
                                                    value={observation[0].submittedBy.toLowerCase()}
                                                    disabled={false}        //{isReadOnly || (safetyObservationKey<0)}
                                                    onChange={(e) => this.onTextChange("submittedBy", e.target.value, e)}
                                                >
                                                    <option value=""></option>

                                                    {submitBy.map(v => <option value={v.adAccount.toLowerCase()}>{v.empName}</option>)}
                                                </CustomInput>

                                            )}
                                        </Col>

                                    </Row>
                                </Col>

                            </FormGroup>


                            <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="location">Location</Label>
                                        </Col>

                                        <Col md={8}>
                                            <CustomInput invalid={!validateDataItem.locationKey}
                                                type="select"
                                                id="location"
                                                name="location"
                                                value={observation[0].locationKey}
                                                defaultValue={observation[0].locationKey}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("locationKey", e.target.value, e)}
                                            >
                                                <option value=""></option>
                                                {location.map(v => <option value={v.locationKey}>{v.locationName}</option>)}

                                            </CustomInput>
                                            <FormFeedback tooltip>Please select location</FormFeedback>
                                        </Col>

                                    </Row>
                                </Col>


                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="submitedDate">Report Date</Label>
                                        </Col>
                                        <Col md={8}>
                                            <DatePicker id="submitedDate"
                                                disabled={isReadOnly}
                                                // showTimeSelect
                                                // timeFormat="HH:mm"
                                                // timeIntervals={15}
                                                //dateFormat="LLL"
                                                onChange={(v, f) => this.onTextChange("submittedDatetime", v, f)}
                                                value={submittedDatetime} />

                                        </Col>

                                    </Row>
                                </Col>

                            </FormGroup>

                            <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="reportTo">Report To</Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="text" name="reportTo"
                                                id="reportTo" placeholder=""
                                                value={observation[0].reportedTo}
                                                defaultValue={observation[0].reportedTo}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("reportedTo", e.target.value, e)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="approvedBy">{(statusKey === 5 ? "Rejected By" : "Approved By")}</Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="approvedBy"
                                                id="approvedBy" placeholder=""
                                                value={observation[0].reviewdByName}
                                            />
                                        </Col>

                                    </Row>
                                </Col>

                            </FormGroup>

                            <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label>Contractor</Label>
                                        </Col>
                                        <Col md={1}>
                                            <CustomInput type="checkbox"
                                                id="contratorYes"
                                                label={<span style={{ marginLeft: '-6px' }}>Yes</span>}
                                                // label="Yes"
                                                checked={isContractor}
                                                inline
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("contractor", 1, e)}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <CustomInput type="checkbox"
                                                id="contratorNo"
                                                label={<span style={{ marginLeft: '-6px' }}>No</span>}
                                                // label="No" 
                                                inline
                                                checked={!isContractor}
                                                onChange={(e) => this.onTextChange("contractor", 0, e)}
                                                disabled={isReadOnly} />
                                        </Col>


                                    </Row>
                                </Col>

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="approvedDate">{(statusKey === 5 ? "Rejected Date" : "Approved Date")}</Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="approvedDate"
                                                id="approvedDate" placeholder=""
                                                value={(observation[0].reviewedDateTime === null ? "" : new Date(observation[0].reviewedDateTime).toLocaleDateString())}
                                            />
                                        </Col>

                                    </Row>
                                </Col>

                            </FormGroup>

                            {!preventablehidden && (


                                <div>
                                    <FormGroup row style={{position: 'relative', zIndex: "200" }}> 
                                        <Col md={6}>
                                            <Row form>
                                                <Col md={4}>
                                                    <Label for="mvaDescription">MVA Description</Label>
                                                </Col>
                                                <Col md={8}>
                                                    <CustomInput
                                                        invalid={!validateDataItem.mvadescription}
                                                        type="select"
                                                        id="mvaDescription"
                                                        name="mvaDescription"
                                                        value={observation[0].mvadescription}
                                                        defaultValue={observation[0].mvadescription}
                                                        disabled={isReadOnly}
                                                        onChange={(e) => this.onTextChange("mvadescription", e.target.value, e)}
                                                    >
                                                        <option value="" disabled>Select MVA Description</option>
                                                        {/* <option value=""></option> */}
                                                        <option value="Preventable">Preventable</option>
                                                        <option value="Non-Preventable">Non-Preventable</option>
                                                        <option value="Property Damage">Property Damage</option>

                                                    </CustomInput>
                                                    <FormFeedback tooltip>Please select MVA Description</FormFeedback>
                                                </Col>

                                            </Row>
                                        </Col>

                                        <Col md={6}>
                                            <Row form>
                                                <Col md={4}>
                                                    <Label for="mvaTruckNo">Truck No</Label>
                                                </Col>
                                                <Col md={8}>
                                                    <Select
                                                        options={truckList}
                                                        isClearable={true} // Optional: allows clearing the selection
                                                        isDisabled={isReadOnly}
                                                        value={this.getSelTruck(observation[0].truckNo)}                                                        
                                                        onChange={(e) => this.onTextChange('truckNo', e, e)}
                                                    />                                                    
                                                </Col>

                                            </Row>
                                        </Col>

                                    </FormGroup>

                                    <div>
                                        <FormGroup row>
                                            {ddMvadescription.map((desc, index) => (
                                                desc.codeDefDesc2 ?
                                                    <Col md={4} key={desc.codeDefinitionKey} className="d-flex">
                                                        <Card className="flex-fill my-1" style={{ margin: '10px' }} color="light">
                                                            <CardBody style={{ padding: '4px', fontSize: '0.8rem' }}>
                                                                {/* <CardTitle tag="h6">{desc.codeDefDesc}</CardTitle> */}
                                                                <CardText dangerouslySetInnerHTML={{ __html: desc.codeDefDesc2 }} />
                                                            </CardBody>
                                                        </Card>
                                                    </Col> : null
                                            ))}
                                        </FormGroup>
                                    </div>



                                    {/* <FormGroup row className="d-flex flex-row">
      <Col md={4} className="d-flex">
        <Card className="flex-fill my-2"  color="light">
          <CardBody>
            <CardTitle tag="h5">Preventable MVAs include</CardTitle>
            <CardText>
              <ul>
                <li>Accidents where vehicle is in motion and employee’s vehicle hits something</li>
              </ul>
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col md={4} className="d-flex">
        <Card className="flex-fill my-2" color="light">
          <CardBody>
            <CardTitle tag="h5">Non-Preventable MVAs include</CardTitle>
            <CardText>
              <ul>
                <li>Accidents where vehicle is in motion and employee’s vehicle is struck by someone/something else</li>
              </ul>
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col md={4} className="d-flex">
        <Card className="flex-fill my-2"  color="light">
          <CardBody>
            <CardTitle tag="h5">Property Damage includes</CardTitle>
            <CardText>
              <ul>
                <li>Animal strikes of any kind</li>
                <li>Damage of any kind if the vehicle is not in motion</li>
                <li>Tire blowouts, windshield damage, even if the vehicle is in motion</li>
              </ul>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </FormGroup> */}
                                </div>
                            )}


                            {!estspillhidden && (

                                <FormGroup row>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="estSpillAmt">Est Spill Amount in bbls</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="estSpillAmt"
                                                    invalid={!validateDataItem.estSpillAmt}
                                                    id="estSpillAmt" placeholder=""
                                                    value={observation[0].estSpillAmt}
                                                    defaultValue={observation[0].estSpillAmt}
                                                    disabled={isReadOnly}
                                                    onChange={(e) => this.onTextChange("estSpillAmt", e.target.value, e)}
                                                />
                                                <FormFeedback tooltip>Please enter Est Spill Amount</FormFeedback>
                                            </Col>

                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>

                                            </Col>
                                            <Col md={8}>

                                            </Col>

                                        </Row>
                                    </Col>

                                </FormGroup>


                            )}



                            {/* 
Public Enum eHazard As Integer
        Mechanical = 1
        Fire = 2
        Electrical = 4
        Vehicle = 8
        Noise = 16
        Chemical = 32
        SlipTripFall = 64
        Drops = 128
        Pressure = 256
        Temperature = 512
        H2S = 1024
        SpillLeak = 2048
        Other = 4096
    End Enum

    Public Enum ePeople As Integer
        BodyPosition = 1
        ToolUse = 2
        Distracted = 4
        WorkingPace = 8
        PPE = 16
        Communication = 32
        SprainStrain = 64
        InTheLineofFire = 128
        DoesNotApply = 256
        Fatigue = 512
        Other = 1024
    End Enum */}

                            <FormGroup row>
                                <Col md={6}>
                                    <Card>
                                        <CardHeader style={{ padding: "0", margin: "0" }}>
                                            <Input invalid={!validateDataItem.hazardAndRiskValue}
                                                disabled
                                                className="bg-transparent"
                                                value="HAZARD AND RISK (Check all that apply)"
                                                style={{ border: "0px", padding: "0", margin: "0" }}>
                                            </Input>
                                            <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback>

                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={5}>
                                                    <CustomInput type="checkbox"
                                                        id="mechanical"
                                                        label="Mechanical"
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 1)}
                                                        inline
                                                        disabled={isReadOnly}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 1, e)} />
                                                </Col>
                                                <Col md={3}>
                                                    <CustomInput type="checkbox" id="fire" label="Fire" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 2)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 2, e)}
                                                    />

                                                </Col>
                                                <Col md={4}>
                                                    <CustomInput type="checkbox" id="Electrical" label="Electrical" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 4)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 4, e)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <CustomInput type="checkbox" id="vehicle" label="VehicleEquip" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 8)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 8, e)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <CustomInput type="checkbox" id="noise" label="Noise" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 16)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 16, e)} />
                                                </Col>
                                                <Col md={4}>
                                                    <CustomInput type="checkbox" id="chemical" label="Chemical" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 32)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 32, e)} />
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={5}>
                                                    <CustomInput type="checkbox" id="slip" label="Slip, Trip, Fall" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 64)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 64, e)} />
                                                </Col>
                                                <Col md={3}>
                                                    <CustomInput type="checkbox" id="drops" label="Drops" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 128)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 128, e)} />
                                                </Col>
                                                <Col md={4}>
                                                    <CustomInput type="checkbox" id="pressure" label="Pressure" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 256)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 256, e)} />
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={5}>
                                                    <CustomInput type="checkbox" id="temperature" label="Temperature" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 512)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 512, e)} />
                                                </Col>

                                                <Col md={3}>
                                                    <CustomInput type="checkbox" id="h2s" label="H2S" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 1024)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 1024, e)} />
                                                </Col>
                                                <Col md={4}>
                                                    <CustomInput type="checkbox" id="spill" label="Spill/Leak" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 2048)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 2048, e)} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3}>
                                                    <CustomInput type="checkbox" id="hazOther" label="Other" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].hazardAndRiskValue, 4096)}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskValue", 4096, e)} />

                                                </Col>
                                                <Col md={9}>
                                                    <Input type="text" name="hazOtherText"
                                                        disabled={isReadOnly}
                                                        id="hazOtherText" placeholder=""
                                                        defaultValue={observation[0].hazardAndRiskOtherCmnt}
                                                        value={observation[0].hazardAndRiskOtherCmnt}
                                                        onChange={(e) => this.onTextChange("hazardAndRiskOtherCmnt", e.target.value, e)} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                </Col>

                                <Col md={6}>
                                    <Card>
                                        <CardHeader style={{ position: 'relative', padding: "0", margin: "0", zIndex:"0"}}>

                                            <Input invalid={!validateDataItem.riskInvolvePeopleValue}
                                                disabled
                                                className="bg-transparent"
                                                value="DID THE RISK INVOLVE PEOPLE?"
                                                style={{ border: "0px", padding: "0", margin: "0" }}>
                                            </Input>
                                            <FormFeedback tooltip>Please select one "Did the risk involve people"</FormFeedback>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="bodyPosition" label="Body Position" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 1)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 1, e)} />
                                                </Col>
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="toolUse" label="Tool Use" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 2)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 2, e)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="distracted" label="Distracted" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 4)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 4, e)} />
                                                </Col>
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="workingPace" label="Working Pace" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 8)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 8, e)} />
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="ppe" label="PPE" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 16)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 16, e)} />
                                                </Col>
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="communication" label="Communication" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 32)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 32, e)} />
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="sprainStrain" label="Sprain Strain" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 64)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 64, e)} />
                                                </Col>

                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="lineOfFire" label="In The line of fire" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 128)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 128, e)} />
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="doesNotApply" label="Does not apply" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 256)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 256, e)} />
                                                </Col>

                                                <Col md={6}>
                                                    <CustomInput type="checkbox" id="fatigue" label="Fatigue" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 512)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 512, e)} />
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3}>
                                                    <CustomInput type="checkbox" id="riskOther" label="Other" inline
                                                        disabled={isReadOnly}
                                                        checked={this.harzardAndRiskValue(observation[0].riskInvolvePeopleValue, 1024)}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleValue", 1024, e)} />
                                                </Col>
                                                <Col md={9}>
                                                    <Input type="text" name="riskOtherText"
                                                        disabled={isReadOnly}
                                                        id="riskOtherText" placeholder=""
                                                        defaultValue={observation[0].riskInvolvePeopleOtherCmnt}
                                                        value={observation[0].riskInvolvePeopleOtherCmnt}
                                                        onChange={(e) => this.onTextChange("riskInvolvePeopleOtherCmnt", e.target.value, e)} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </FormGroup>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >
                                        Details Comments
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { this.toggleTab('2'); }}
                                    >
                                        Documents
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col md={4}>
                                            <Label for="youRObserve">WHAT DID YOU OBSERVE?</Label>
                                            <Input invalid={!validateDataItem.hazardDesc}
                                                type="textarea" name="youRObserve" rows={5}
                                                id="yourObserve"
                                                value={observation[0].hazardDesc}
                                                defaultValue={observation[0].hazardDesc}
                                                onChange={(e) => this.onTextChange("hazardDesc", e.target.value, e)}
                                                disabled={isReadOnly} />
                                            <FormFeedback>Please enter what you did you observe</FormFeedback>
                                        </Col>
                                        <Col md={4}>
                                            <Label for="yourAction">WHAT DID YOU DO TO CONTROL THE RISK ?</Label>
                                            <Input invalid={!validateDataItem.solutionDesc}
                                                type="textarea" name="yourAction" rows={5}
                                                id="yourAction"
                                                value={observation[0].solutionDesc}
                                                defaultValue={observation[0].solutionDesc}
                                                onChange={(e) => this.onTextChange("solutionDesc", e.target.value, e)}
                                                disabled={isReadOnly} />
                                            <FormFeedback>Please enter what you did you do to control the risk</FormFeedback>
                                        </Col>
                                        <Col md={4}>
                                            <Label for="comment">{(statusKey === 5 ? "REJECTED COMMENT" : "APPROVER COMMENT")}</Label>
                                            <Input type="textarea" name="comment" rows={5}
                                                id="comment"
                                                value={observation[0].reviewComments}
                                                defaultValue={observation[0].reviewComments}
                                                onChange={(e) => this.onTextChange("reviewComments", e.target.value, e)}
                                                disabled={(isReadOnly ? true : !(isQHSEAdmin || isQHSEApproverforThisSO))} />
                                        </Col>
                                    </Row>
                                </TabPane>


                                <TabPane tabId="2">
                                    <Row>
                                        <Col md={12}>
                                            <DocumentBrowserDragDrop buttonLabel="View Image"
                                                buttonColor="primary"
                                                formName="Safety Observation Images"
                                                linkTableName={"thsSafetyObservation"}
                                                linkPrimaryKey={(safetyObservationKey < 0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}
                                                apiURL={apiURL + 'SafetyObservation/Documents/' + (safetyObservationKey < 0 ? offLineLinkSafetyObservationKey : safetyObservationKey)} />

                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>

                        </div>
                    </FormGroup>



                    <FormGroup>
                        <Row>

                            <Col sm={12}>




                                <Button color="warning" className="float-right"
                                    onClick={() => this.closeForm(false)}>Close</Button>


                                <Button color="primary" className="float-right"
                                    style={{ margin: "0px 10px 0px 0px" }}
                                    hidden={isReadOnly}
                                    //hidden={this.setIsReadOnly(statusKey)}
                                    onClick={() => this.saveData()}>Save</Button>

                                {/* <Button color="success"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                             hidden={!(statusKey==0 || statusKey==5)}
                             onClick={()=> this.saveData()}>Submit</Button> */}

                                {/* <Button color="success"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                             hidden={!(statusKey==4 && isQHSEAdmin)}
                             onClick={()=> this.saveData()}>Approve</Button> */}

                                {/* <Button color="danger"className="float-left"
                             style={{margin:"0px 10px 0px 0px"}}
                             hidden={!(statusKey==4 && isQHSEAdmin)}
                             onClick={()=> this.saveData()}>Reject</Button> */}

                                {/* <Button color="danger"className="float-left"
                             style={{margin:"0px 10px 0px 0px"}}
                             hidden={!(statusKey==0 && safetyObservationKey > 0)}
                             onClick={()=> this.deleteData()}>Delete</Button> */}


                                <ConfirmModal outline={false}
                                    color="success"
                                    className="float-right"
                                    buttonLabel="Submit"
                                    //   hidden ={!(statusKey===0 || statusKey===5)}
                                    hidden={isSubmitHidden}
                                    formName="Confirm"
                                    id={safetyObservationKey}
                                    message="Are you sure you want to submit selected safety observation?"
                                    onClickYes={(e) => this.submitData()}
                                    style={{ marginRight: "10px" }}
                                />

                                <ConfirmModal outline={false}
                                    color="success"
                                    className="float-right"
                                    buttonLabel="Approve"
                                    hidden={!(statusKey === 4 && (isQHSEAdmin || isQHSEApproverforThisSO))}
                                    formName="Confirm"
                                    id={safetyObservationKey}
                                    message="Are you sure you want to approve selected safety observation?"
                                    onClickYes={(e) => this.approveData()}
                                    style={{ marginRight: "10px" }}
                                />


                                <ConfirmModal outline={true}
                                    color="danger"
                                    className="float-left"
                                    buttonLabel="Reject"
                                    hidden={!(statusKey === 4 && (isQHSEAdmin || isQHSEApproverforThisSO) && safetyObservationKey > 0)}
                                    formName="Confirm"
                                    id={safetyObservationKey}
                                    message="Are you sure you want to reject selected safety observation?"
                                    onClickYes={(e) => this.rejectData()}
                                />

                                <ConfirmModal outline={true}
                                    color="danger"
                                    className="float-left"
                                    buttonLabel="Delete"
                                    hidden={(!((statusKey === 0 && safetyObservationKey > 0) || ((isQHSEAdmin || isQHSEApproverforThisSO) && statusKey != 6 && statusKey != 4 && safetyObservationKey > 0)))}
                                    formName="Confirm"
                                    id={safetyObservationKey}
                                    message="Are you sure you want to delete selected safety observation?"
                                    onClickYes={(e) => this.deleteData()}
                                    image={<img
                                        src={TrashBin}
                                        id={safetyObservationKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>



                                {/* create si safety incident */}
                                <div>
                                    <SafetyObservationCreateIncident
                                        id="btncreatesi"
                                        hidden={observation[0].safIncHeaderKey ? true : false || !isQHSEAdmin}
                                        ddropdown={ddTypes}
                                        // isSafIncAdmin={isSafIncAdmin}
                                        disabled={statusKey === 0}
                                        //isSafIncAdmin={true}
                                        onClickOK={(ddkey, desc) => this.newIncident(ddkey, desc)}
                                    />

                                    <UncontrolledTooltip placement="top" target="btncreatesi">
                                        Create Safety Incident from this Observation
                                    </UncontrolledTooltip>

                                </div>

                                <Button color="secondary"
                                    className="float-right"
                                    style={{ margin: "0px 10px 0px 0px" }}
                                    disabled={statusKey === 0}
                                    onClick={this.toggleReport}
                                //disabled={supplierRequestKey < 0}
                                >

                                    View Report
                                </Button>

                                <div>
                                    <Button className="float-right"
                                        id="btnopensi"
                                        style={{ margin: "0px 10px 0px 0px" }}
                                        hidden={observation[0].safIncHeaderKey ? false : true || !isQHSEAdmin}
                                        onClick={() => this.openSI(observation[0].safIncHeaderKey)}
                                    >Open {observation[0].safIncID} </Button>

                                    <UncontrolledTooltip placement="top" target="btnopensi">
                                        Open Safety Incident created from this Observation
                                    </UncontrolledTooltip>
                                </div>


                            </Col>


                        </Row>
                    </FormGroup>

                    <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            //style={{ margin: 0, flex: 1 }}
                            //onClosed={() => this.getSupplierReq()}
                            size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                            //contentClassName="full-screen-modal"
                            centered
                        >
                            <ModalHeader toggle={() => this.toggleReport()}> Safety Observation Report </ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName={"SafetyObservation"} //{"R9Invoice"}
                                    reportParameters={this.createReportParamter()}
                                    //invoiceKey={invoiceKey}                            
                                    toggle={this.toggleReport}
                                //refreshData={this.getInvovice}
                                >

                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div>

                    {/* <div>
                <Modal isOpen={this.state.modal} toggle={() => this.toggle()} 
                  contentClassName="safetyObsDetail-modal-style">
                    <ModalHeader toggle={() => this.toggle()}> Safety Observation Document Upload</ModalHeader>
                    <ModalBody>
                    <DocumentUploader formName="Safety Observation Images"                                    
                                      toggle = {this.toggle}
                                      linkTableName={"thsSafetyObservation"}
                                      linkPrimaryKey={safetyObservationKey} />

                    </ModalBody>                   
                </Modal>
                </div> */}

                </Form>
            </div>)
    }
}