import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import RevisionPng from '../resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'; 
import FileManager from '../resources/FileManager'; 
import NumberFormat from 'react-number-format';
 

import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../resources/utility';
import PowerBIReportViewer from '../resources/PowerBIReportViewer';
import ConstructionReportingChangeOrders from './ConstructionReportingChangeOrders';
import { UndoExecuteReason } from 'igniteui-react-spreadsheet';

var DatePicker = require("reactstrap-date-picker");
  
class DailyPipeLineReport extends React.Component {
    static displayName =  'Alex testing' //AFEDetail.name

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            constructionReportKey: props.constructionReportKey,
            constructionReportPipelineKey: props.constructionReportPipelineKey,
            constructionReports: props.constructionReports,
            isSecurityAdminLogin: props.isSecurityAdminLogin,
            dailyPipeLineReportDTO: null,
            dailyReports: [],
            statusKey: null,
            closeoutStatusKey: -1,
            alertMessage: "",
            showAlert: false,
            showSaveAlert: false,
            isFormReadOnly: false,
            isApprover: false,
            isAFEManager: false,
            isAFEAdmin: false,
            showCostTracking: false,
            isLoaded: false,
            constructionReportDTO: props.constructionReportDTO,
            empList: props.empList,
            isEmpListLoaded: false,
            budgetClassList: props.budgetClassList,
            isBudgetClass: false,
            afeTypeList: props.afeTypeList,
            isAFETypeLoaded: false,
            costTypeList: props.costTypeList,
            isCostTypeLoaded: false,
            facilityList: props.facilityList,
            isFacilityLoaded: false,
            budgetList: props.budgetList,
            dupAFEList: props.dupAFEList,
            isBudgetLoaded: false,
            isUserAdmin: false,
            isSecurityLoaded: false,
            uomList: props.uomList,
            linkAFEList: props.linkAFEList,
            basinList: props.basinList,
            countyList: props.countyList,
            projectStatusList: props.projectStatusList,
            areaList: props.areaList,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            alertMessage: "",
            alertColor: "danger",
            isForceBudgeLineUpdate: false,
            budgetLineRefreshKey: 0,
            activeTab: '1',
            hasDCCost: false,
            isDataChange: false,
            //isApprovalRevision:false,
            yesNoList: [{
                "label": "Yes",
                "value": 1
            }, {
                "label": "No",
                "value": 0
            }],
            validateDataItem: {
                reportDate: true,
                workSummary: true,
                issues: true,
                inspectorName: true,
                expectedCompletionDate: true ,
                rowClearedCompletedToday: true,
                trenchDugCompletedToday: true,
                pipeStrungCompletedToday: true,
                loweredInCompletedToday: true,
                backFilledCompletedToday: true,
                rowCleanUpCompletedToday: true,
                casedBoreCompletedToday: true,
                uncasedBoreCompletedToday: true,
                rockEncounteredCompletedToday: true,
                fusionWeldsCompletedToday: true,
                tieInsCompletedToday: true,
                lineCrossingsCompletedToday: true,
                lineCrossingsCompletedToday: true,
                fenceGapsCompletedToday: true,
                hydrovacsCompletedToday: true,
                matsCompletedToday: true,
                pipeSupportsCompletedToday: true,
                valveSitesCompletedToday: true,
                testStationsCompletedToday: true,
                pipePaddingCompletedToday: true,
                inspectorName: true,
                integrityTestingComplete: true,
                issue: true,
                casedBorePercent: true, 
            },
            siteTransfer: null ,
            afesRevSupBuget: [],
            reimbursable: [{label: '',value: -1}, {label: 'No',value: 0},{label:'Yes', value: 1}],
            modalReport: false,
            reportName: "",
            dropdownOpenManage: false,
            edited: false,
        }

        console.log('test');
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const { dailyPipeLineReportDTO, validateDataItem,constructionReportDTO } = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }

 

            if (!IsDate(dailyPipeLineReportDTO.reportDate)) {
                validateDataItem.reportDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " A Report Date was not provided."
            }

            let objReportDate = this.isReportDateExistOnDifferentDailyReport(dailyPipeLineReportDTO.reportDate);
            if (objReportDate){ 
                validateDataItem.reportDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + ' Report Date ' + dailyPipeLineReportDTO.reportDate + ' is being used by another daily report.'
            }

            if (constructionReportDTO != undefined && constructionReportDTO != null 
                 && IsDate(constructionReportDTO.startDate)  && dailyPipeLineReportDTO.reportDate < constructionReportDTO.startDate){
                    validateDataItem.reportDate = false
                    returnVal.ok = false
                    if (returnVal.message !== "") {
                        returnVal.message = returnVal.message + ","
                    }
                    returnVal.message = returnVal.message + " Report Date cannot be before AFE" + "'" + "s start date.";
            }
            
            if (!IsDate(dailyPipeLineReportDTO.expectedCompletionDate)) {
                validateDataItem.expectedCompletionDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " A ExpectedCompletion Date was not provided."
            }

            if (dailyPipeLineReportDTO.workSummary === null || dailyPipeLineReportDTO.workSummary.trim() === '') {
                validateDataItem.workSummary = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Work  Summary is required."
            }
 
            if (dailyPipeLineReportDTO.rowClearedCompletedToday === null || dailyPipeLineReportDTO.rowClearedCompletedToday < 0) {
                validateDataItem.rowClearedCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Row Cleared Completed needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.trenchDugCompletedToday === null || dailyPipeLineReportDTO.trenchDugCompletedToday < 0) {
                validateDataItem.trenchDugCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Trench Dug Completed needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.pipeStrungCompletedToday === null || dailyPipeLineReportDTO.pipeStrungCompletedToday < 0) {
                validateDataItem.pipeStrungCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Pipe Strung Completed needs to be greater than -1%."
            }
  
            if (dailyPipeLineReportDTO.loweredInCompletedToday === null || dailyPipeLineReportDTO.loweredInCompletedToday < 0) {
                validateDataItem.loweredInCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Lowered InCompleted Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.backFilledCompletedToday === null || dailyPipeLineReportDTO.backFilledCompletedToday < 0) {
                validateDataItem.backFilledCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Back Filled Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.rowCleanUpCompletedToday === null || dailyPipeLineReportDTO.rowCleanUpCompletedToday < 0) {
                validateDataItem.rowCleanUpCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Row Clean Up Completed Today needs to be greater than -1%."
            }

            // if ((dailyPipeLineReportDTO.casedBoreLength === undefined || dailyPipeLineReportDTO.casedBoreLength === null || dailyPipeLineReportDTO.casedBoreLength <= 0) && Math.round(dailyPipeLineReportDTO.casedBoreCompletedToday)  > 0){
            //     validateDataItem.casedBoreCompletedToday = false;
            //     returnVal.ok = false;
            //     if (returnVal.message !== "") {
            //         returnVal.message = returnVal.message + ","
            //     }
            //     returnVal.message = returnVal.message + " No Case Bore Length was provided in Construction Report.  It is required in order to have a Cased Bore Completed Today greater than 0.."
            // }

            if (dailyPipeLineReportDTO.casedBoreCompletedToday === null || dailyPipeLineReportDTO.casedBoreCompletedToday < 0) {
                validateDataItem.casedBoreCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Cased Bore Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.uncasedBoreCompletedToday === null || dailyPipeLineReportDTO.uncasedBoreCompletedToday < 0) {
                validateDataItem.uncasedBoreCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Uncased Bore Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.rockEncounteredCompletedToday === null || dailyPipeLineReportDTO.rockEncounteredCompletedToday < 0) {
                validateDataItem.rockEncounteredCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Rock Encountered Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.fusionWeldsCompletedToday === null || dailyPipeLineReportDTO.fusionWeldsCompletedToday < 0) {
                validateDataItem.fusionWeldsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Fusion Welds Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.tieInsCompletedToday === null || dailyPipeLineReportDTO.tieInsCompletedToday < 0) {
                validateDataItem.tieInsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " TieIns Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.lineCrossingsCompletedToday === null || dailyPipeLineReportDTO.lineCrossingsCompletedToday < 0) {
                validateDataItem.lineCrossingsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Line Crossings Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.fenceGapsCompletedToday === null || dailyPipeLineReportDTO.fenceGapsCompletedToday < 0) {
                validateDataItem.fenceGapsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Fence Gaps Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.hydrovacsCompletedToday === null || dailyPipeLineReportDTO.hydrovacsCompletedToday < 0) {
                validateDataItem.hydrovacsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Hydrovacs Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.matsCompletedToday === null || dailyPipeLineReportDTO.matsCompletedToday < 0) {
                validateDataItem.matsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Mats Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.pipeSupportsCompletedToday === null || dailyPipeLineReportDTO.pipeSupportsCompletedToday < 0) {
                validateDataItem.pipeSupportsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Pipe Supports Completed Today needs to be greater than -1%."
            }    

            if (dailyPipeLineReportDTO.valveSitesCompletedToday === null || dailyPipeLineReportDTO.valveSitesCompletedToday < 0) {
                validateDataItem.valveSitesCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Valve Sites Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.testStationsCompletedToday === null || dailyPipeLineReportDTO.testStationsCompletedToday < 0) {
                validateDataItem.testStationsCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Test Stations Completed Today needs to be greater than -1%."
            }

            if (dailyPipeLineReportDTO.pipePaddingCompletedToday === null || dailyPipeLineReportDTO.pipePaddingCompletedToday < 0) {
                validateDataItem.pipePaddingCompletedToday = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Pipe Padding Completed Today needs to be greater than -1%."
            }
 
            if (dailyPipeLineReportDTO.issues === null) {
                validateDataItem.issues = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Issues needs to be selected."
            }

            if (dailyPipeLineReportDTO.integrityTestingComplete === null) {
                validateDataItem.integrityTestingComplete = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Integrity Testing Complete needs to be selected."
            }

            if (dailyPipeLineReportDTO.inspectorName === null || dailyPipeLineReportDTO.inspectorName.trim() === '') {
                validateDataItem.inspectorName = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Inspectors Name needs to be filled out."
            }
 

            returnVal.validateDataItem = validateDataItem
            if (returnVal.message !== "") {
                returnVal.message = "Please enter missing data: " + returnVal.message
            }
            resolve(returnVal)
        })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleAlert = () => {

        this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggleSaveAlsert = () => {
        this.setState({ showSaveAlert: !this.state.showSaveAlert },
            () => { window.setTimeout(() => { this.setState({ showSaveAlert: false }) }, 3000) })
    }

    resetValidDataItem = () => {
        // const { validDataItem } = this.state

        // validDataItem.toLocationKey = true
        // validDataItem.invoiceDate = true
        // validDataItem.invoiceNo = true

        // this.setState({ validDataItem: validDataItem })
    }

    closeForm = () => {
        this.setState({isLoaded: false});
        this.props.toggle(this.state.edited);
    }

    saveData = (statusKey) => {
        const { constructionReportPipelineKey } = this.state
        let {validateDataItem,showAlert} = this.state;
         

        this.setState({validateDataItem: validateDataItem,showAlert: false, alertMessage: '' });

        try {
            this.dataValidation().then((data) => {
                if (data.ok) { 
                    if (constructionReportPipelineKey > 0) {
                        this.updateReport(statusKey)
                    } else {
                        this.addReport(statusKey)
                    }

                    this.toggleSaveAlsert()
                } else {
                    this.setState({
                        alertMessage: data.message,
                        validateDataItem: data.validateDataItem,
                        showAlert: true
                    })
                }
            })
        } catch (error) {

        }


    }

    addReport = (statusKey) => {
      
        let {dailyPipeLineReportDTO} = this.state;
    
            let myURI = apiURL + 'ConstructionReporting/ConstructionProject/PipeLineDailyReport/Add/' + dailyPipeLineReportDTO.expectedCompletionDate
            dailyPipeLineReportDTO.statusKey = statusKey;
    
            this.setState({isLoaded: false});

            fetch(myURI, apiPOSTHeader(dailyPipeLineReportDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;
    
                        rspData.then(t => {
                            this.setState({
                                msgBody: t,
                                msgHeader: "Error",
                                modalMsg: !this.state.modalMsg,
                                isLoaded: true,
                            })
                        })
    
                        return Promise.reject(error)
                    } else{
                        this.setState({edited: true});
                        return response.json();
                    }
                        
                }).then(json => {
                    //this.setState({dailyPipeLineReportDTO: json,constructionReportPipelineKey: json.constructionReportPipelineKey})   
                    this.closeForm();
                }).catch(error => {
                    console.log("PUT error: " + error)
                })
    
    }

    updateReport = (statusKey) => {
        let {dailyPipeLineReportDTO,user} = this.state;

        dailyPipeLineReportDTO.statusKey = statusKey;
        dailyPipeLineReportDTO.modifiedBy = user.account.userName;

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/PipeLineDailyReport/Edit/'+ dailyPipeLineReportDTO.expectedCompletionDate
        this.setState({isLoaded: false});
        
        fetch(myURI, apiPOSTHeader(dailyPipeLineReportDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg,
                            isLoaded: true,
                        })
                    })

                    return Promise.reject(error)
                } else{
                    //await this.loadReportData(dailyPipeLineReportDTO.constructionReportPipelineKey)
                    this.setState({edited: true});
                    this.closeForm();
                    return;
                }
                return response.json()
            }).then(json => {
                // this.setState({ 
                //                 dailyPipeLineReportDTO: json, 
                //                 //isLoaded: false,
                //                 constructionReportPipelineKey: json.constructionReportPipelineKey 
                //             });  
                //this.componentDidMount(); 
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }  

    onTextChange = (fieldName, fieldValue, e) => {
        let { constructionReportDTO,dailyPipeLineReportDTO, validateDataItem ,alertMessage,showAlert} = this.state
        const { user } = this.props
      

        console.log(fieldName + ' ------ ' + fieldValue)
        console.log(e)
        showAlert = false;

        switch (fieldName) {
            case "reportDate": 
                let objReportDate = this.isReportDateExistOnDifferentDailyReport(fieldValue);
                if (objReportDate){
                    alertMessage = 'Report Date ' + fieldValue.toString().substr(0,10) + ' is being used by another daily report.';
                    showAlert = true 
                }
                else{
                    validateDataItem.reportDate = true;
                    dailyPipeLineReportDTO.reportDate = fieldValue;
                }

                break;
            case "expectedCompletionDate":
                validateDataItem.expectedCompletionDate = true;
                dailyPipeLineReportDTO.expectedCompletionDate = fieldValue;
                break;    
            case "workSummary":
                validateDataItem.workSummary = true;
                dailyPipeLineReportDTO.workSummary = fieldValue;
                showAlert = false;
                break;
            case 'rowClearedCompletedToday':
                validateDataItem.rowClearedCompletedToday = true;  
                dailyPipeLineReportDTO.rowClearedCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'trenchDugCompletedToday':
                validateDataItem.trenchDugCompletedToday = true;
                dailyPipeLineReportDTO.trenchDugCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'pipeStrungCompletedToday':
                validateDataItem.pipeStrungCompletedToday = true;
                dailyPipeLineReportDTO.pipeStrungCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'loweredInCompletedToday':
                validateDataItem.loweredInCompletedToday = true;
                dailyPipeLineReportDTO.loweredInCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'backFilledCompletedToday':
                validateDataItem.backFilledCompletedToday = true;
                dailyPipeLineReportDTO.backFilledCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'rowCleanUpCompletedToday':
                validateDataItem.rowCleanUpCompletedToday = true;
                dailyPipeLineReportDTO.rowCleanUpCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'casedBoreCompletedToday':

                if ((dailyPipeLineReportDTO.casedBoreLength === undefined || dailyPipeLineReportDTO.casedBoreLength === null || dailyPipeLineReportDTO.casedBoreLength <= 0) && Math.round(fieldValue) > 0) {
                    validateDataItem.casedBoreCompletedToday = false; 
                    dailyPipeLineReportDTO.casedBoreCompletedToday = 0;
                    alertMessage = "No Case Bore Length was provided in Construction Report.  It is required in order to have a Cased Bore Completed Today greater than 0."
                    showAlert = true
                    break; 
                }
                else {
                    validateDataItem.casedBoreCompletedToday = true;
                    dailyPipeLineReportDTO.casedBoreCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                    break;
                }
            case 'uncasedBoreCompletedToday':
                validateDataItem.uncasedBoreCompletedToday = true;
                dailyPipeLineReportDTO.uncasedBoreCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'rockEncounteredCompletedToday':
                validateDataItem.rockEncounteredCompletedToday = true;
                dailyPipeLineReportDTO.rockEncounteredCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'fusionWeldsCompletedToday':
                validateDataItem.fusionWeldsCompletedToday = true;
                dailyPipeLineReportDTO.fusionWeldsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'tieInsCompletedToday':
                validateDataItem.tieInsCompletedToday = true;
                dailyPipeLineReportDTO.tieInsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'lineCrossingsCompletedToday':
                validateDataItem.lineCrossingsCompletedToday = true;
                dailyPipeLineReportDTO.lineCrossingsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'fenceGapsCompletedToday':
                validateDataItem.fenceGapsCompletedToday = true;
                dailyPipeLineReportDTO.fenceGapsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'hydrovacsCompletedToday':
                validateDataItem.hydrovacsCompletedToday = true;
                dailyPipeLineReportDTO.hydrovacsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'matsCompletedToday':
                validateDataItem.matsCompletedToday = true;
                dailyPipeLineReportDTO.matsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'pipeSupportsCompletedToday':
                validateDataItem.pipeSupportsCompletedToday = true;
                dailyPipeLineReportDTO.pipeSupportsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;    
            case 'valveSitesCompletedToday':
                validateDataItem.valveSitesCompletedToday = true;
                dailyPipeLineReportDTO.valveSitesCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;  
            case 'testStationsCompletedToday':
                validateDataItem.testStationsCompletedToday = true;
                dailyPipeLineReportDTO.testStationsCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'pipePaddingCompletedToday':
                validateDataItem.pipePaddingCompletedToday = true;
                dailyPipeLineReportDTO.pipePaddingCompletedToday = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                break;
            case 'issues':
                validateDataItem.issues = true;
                dailyPipeLineReportDTO.issues = fieldValue ? 1 : 0;
                break;
            case 'integrityTestingComplete':
                validateDataItem.integrityTestingComplete = true;
                dailyPipeLineReportDTO.integrityTestingComplete = fieldValue ? 1 : 0;
                break;
            case 'inspectorName':
                validateDataItem.inspectorName = true;
                dailyPipeLineReportDTO.inspectorName = fieldValue;
                break;     
            default:
                break;  
        }
 
        this.setState({ 
            dailyPipeLineReportDTO: dailyPipeLineReportDTO,
            validateDataItem: validateDataItem,
            alertMessage: alertMessage,
            showAlert: showAlert 
        }) 
    }

    isReportDateExistOnDifferentDailyReport(reportDate){
        const {constructionReports,constructionReportPipelineKey} = this.state;

        if(reportDate === undefined || reportDate === null ||  reportDate == '' ){
            return false;
        }

        reportDate = reportDate.substr(0,10);

        if (constructionReports !== undefined && constructionReports !== null){
            let obj = constructionReports.filter(x => x.constructionReportPipelineKey !== constructionReportPipelineKey &&
                                                      x.reportDate.substr(0,10) == reportDate);
            if (obj != null && obj.length > 0){
                return true;
            }
        }
        return false;
    }

    onSiteToSiteChange = (fieldValue) => {
        const { afeData  } = this.state
        afeData[0].siteTransfer = fieldValue;
        this.setState({ afeData: afeData })
    }

    getAFEDetailsAPI = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }

    getAFERevSup = async() => {
        const { afeHeaderKey } = this.state
        
        if (afeHeaderKey === undefined || afeHeaderKey === null || afeHeaderKey <= 1){
            return; 
        }
        
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey + '/RevisionSupplement';

        console.log(myURI);

        await fetch (myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afesRevSupBuget: json 
                }) 
            });

        console.log('getAFERevSup');    
    }

    setIsShowCostTracking = (statusKey) => {
        return !(statusKey === 0 || statusKey === 1 || statusKey === 2 || statusKey === 3)
    }

    setIsApprovalRevision = (statusKey, approver, isApprovalRevision, closeoutStatusKey) => {
        if (this.isAFEApprover(statusKey, approver, closeoutStatusKey) && isApprovalRevision !== 0) {
            this.setState({
                showAlert: true,
                alertColor: "info",
                alertMessage: "This AFE have been revised and resubmitted for your approval. Please view change log for update history."
            })
        }
    }

    isAFEApprover = (statusKey, approver, closeOutStatusKey) => {
        const { user } = this.props
        if (approver !== "" && (statusKey === 3 || closeOutStatusKey === 3)) {
            return approver.includes(user.account.userName.toLowerCase())
        } else {
            return false
        }
    }

    getConstructionReportDetails = async(constructionReportKey) => {
        //const { afeHeaderKey } = this.state
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({ 
                    constructionReportDTO: json, 
                }) 
            });
    }



    setIsFormReadonly = (statusKey, afeManagerAzureAD) => {
        const { user } = this.props
        const { isAFEAdmin } = this.state


        if (statusKey === 1 || statusKey === 10) { //Rejected //10=Revise
            return !((afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase()) || isAFEAdmin)
        } if (statusKey === 6 || statusKey === 5) {
            return true
        } else if (statusKey === 3) {
            return true
        } else if (statusKey === 8) { // 8 - Closed
            return true
        } else if (statusKey === 11) {// 11=Project Started
            return true
        } else if (statusKey === 2) {
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } if (statusKey === 4) { //Approved 
            //return true
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } else {
            return false
        }

    }

    createConstructionReport = () => {
        const { user } = this.props;

        var date = new Date();
        let todaysDate = date.toLocaleDateString();

        const constructionReportDTO = {
            "constructionReportKey": 0,
            "afeNo": null,
            // "afeName": "",
            "constructionReportStatusDesc": "Initiated",
            "statusKey": 1,
            "afeHeaderKey": 0,
            "projectName": "",
            "createdBy": user.account.name,
            "createdDate": todaysDate, 
            "modifiedBy": user.account.name,
            "modifiedDate": todaysDate,  
            "projectManager": "",
            "projectType": null,
            "requiredEndDate": null,
            "pipeSize": null,
            "uncasedBoreLength": null,
            "projectLength": null,
            "casedBoreLength": null,
            "lat": null,
            "long": null,
            "comments": "", 
        }

        return [constructionReportDTO]
    }

    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }


    async componentDidMount() { 
        const { constructionReportKey,constructionReportPipelineKey } = this.state;  
        await this.loadReportData(constructionReportPipelineKey);
        this.setState({  isLoaded: true });
    }
  
    async loadReportData(constructionReportPipelineKey){
        let {validateDataItem} = this.state;
        validateDataItem.reportDate = true; 
        validateDataItem.workSummary = true;
        validateDataItem.issues = true;
        validateDataItem.inspectorName = true; 
        this.setState({validateDataItem: validateDataItem});

        const {constructionReportKey,user,constructionReportDTO} = this.state;
        
        if (constructionReportPipelineKey == undefined || constructionReportPipelineKey == null || constructionReportPipelineKey <=0 ){

            await this.getAllDailyReports();
            const {dailyReports} = this.state;
            let objDailyReport = [];

            if(dailyReports.length > 0){
                objDailyReport = dailyReports[0];
            }
 
            var date = new Date();
            let todaysDate = this.formatDate(date);
 
            let dailyPipeLineReportDTO = {
                constructionReportPipelineKey: 0,
                constructionReportKey: constructionReportKey,
                reportDate: todaysDate,
                workSummary: null,
                rowClearedCompletedToday: 0,
                rowClearedTotalCompleted: objDailyReport != null && objDailyReport.rowClearedTotalCompleted != null ? objDailyReport.rowClearedTotalCompleted : 0,
                rowClearedPercent: objDailyReport != null && objDailyReport.rowClearedPercent != null ? objDailyReport.rowClearedPercent : 0,
                trenchDugCompletedToday: 0,
                trenchDugTotalCompleted: objDailyReport != null && objDailyReport.trenchDugTotalCompleted != null ? objDailyReport.trenchDugTotalCompleted : 0,
                trenchDugPercent: objDailyReport != null && objDailyReport.trenchDugPercent != null ? objDailyReport.trenchDugPercent : 0,
                pipeStrungCompletedToday: 0,
                pipeStrungTotalCompleted: objDailyReport != null && objDailyReport.pipeStrungTotalCompleted != null ? objDailyReport.pipeStrungTotalCompleted : 0,
                pipeStrungPercent: objDailyReport != null && objDailyReport.pipeStrungPercent != null ? objDailyReport.pipeStrungPercent : 0,
                loweredInCompletedToday: 0,
                loweredInTotalCompleted: objDailyReport != null && objDailyReport.loweredInTotalCompleted != null ? objDailyReport.loweredInTotalCompleted : 0,
                loweredInPercent: objDailyReport != null && objDailyReport.loweredInPercent != null ? objDailyReport.loweredInPercent : 0,
                backFilledCompletedToday: 0,
                backFilledTotalCompleted: objDailyReport != null && objDailyReport.backFilledTotalCompleted != null ? objDailyReport.backFilledTotalCompleted : 0,
                backFilledPercent: objDailyReport != null && objDailyReport.backFilledPercent != null ? objDailyReport.backFilledPercent : 0,
                rowCleanUpCompletedToday: 0,
                rowCleanUpTotalCompleted: objDailyReport != null && objDailyReport.rowCleanUpTotalCompleted != null ? objDailyReport.rowCleanUpTotalCompleted : 0,
                rowCleanUpPercent: objDailyReport != null && objDailyReport.rowCleanUpPercent != null ? objDailyReport.rowCleanUpPercent : 0,
                casedBoreCompletedToday: 0,
                casedBoreTotalCompleted: objDailyReport != null && objDailyReport.casedBoreTotalCompleted != null ? objDailyReport.casedBoreTotalCompleted : 0,
                casedBorePercent: objDailyReport != null && objDailyReport.casedBorePercent != null ? objDailyReport.casedBorePercent : 0,
                uncasedBoreCompletedToday: 0,
                uncasedBoreTotalCompleted: objDailyReport != null && objDailyReport.uncasedBoreTotalCompleted != null ? objDailyReport.uncasedBoreTotalCompleted : 0,
                uncasedBorePercent: objDailyReport != null && objDailyReport.uncasedBorePercent != null ? objDailyReport.uncasedBorePercent : 0,
                rockEncounteredCompletedToday:  0,
                rockEncounteredTotalCompleted: objDailyReport != null && objDailyReport.rockEncounteredTotalCompleted != null ? objDailyReport.rockEncounteredTotalCompleted : 0,
                rockEncounteredPercent: objDailyReport != null && objDailyReport.rockEncounteredPercent != null ? objDailyReport.rockEncounteredPercent : 0,
                fusionWeldsCompletedToday: 0,
                fusionWeldsTotalCompleted: objDailyReport != null && objDailyReport.fusionWeldsTotalCompleted != null ? objDailyReport.fusionWeldsTotalCompleted : 0,
                fusionWeldsPercent: objDailyReport != null && objDailyReport.fusionWeldsPercent != null ? objDailyReport.fusionWeldsPercent : 0,
                tieInsCompletedToday: 0,
                tieInsTotalCompleted: objDailyReport != null && objDailyReport.tieInsTotalCompleted != null ? objDailyReport.tieInsTotalCompleted : 0,
                tieInsTotalPercent: objDailyReport != null && objDailyReport.tieInsTotalPercent != null ? objDailyReport.tieInsTotalPercent : 0,
                lineCrossingsCompletedToday: 0,
                lineCrossingsTotalCompleted: objDailyReport != null && objDailyReport.lineCrossingsTotalCompleted != null ? objDailyReport.lineCrossingsTotalCompleted : 0,
                lineCrossingsPercent: objDailyReport != null && objDailyReport.lineCrossingsPercent != null ? objDailyReport.lineCrossingsPercent : 0,
                fenceGapsCompletedToday: 0,
                fenceGapsTotalCompleted: objDailyReport != null && objDailyReport.fenceGapsTotalCompleted != null ? objDailyReport.fenceGapsTotalCompleted : 0,
                fenceGapsPercent: objDailyReport != null && objDailyReport.fenceGapsPercent != null ? objDailyReport.fenceGapsPercent : 0,
                hydrovacsCompletedToday: 0,
                hydrovacsTotalCompleted: objDailyReport != null && objDailyReport.hydrovacsTotalCompleted != null ? objDailyReport.hydrovacsTotalCompleted : 0,
                hydrovacsPercent: objDailyReport != null && objDailyReport.hydrovacsPercent != null ? objDailyReport.hydrovacsPercent : 0,
                matsCompletedToday: 0,
                matsTotalCompleted: objDailyReport != null && objDailyReport.matsTotalCompleted != null ? objDailyReport.matsTotalCompleted : 0,
                matsTotalPercent: objDailyReport != null && objDailyReport.matsTotalPercent != null ? objDailyReport.matsTotalPercent : 0,
                pipeSupportsCompletedToday: 0,
                pipeSupportsTotalCompleted: objDailyReport != null && objDailyReport.pipeSupportsTotalCompleted != null ? objDailyReport.pipeSupportsTotalCompleted : 0,
                pipeSupportsPercent: objDailyReport != null && objDailyReport.pipeSupportsPercent != null ? objDailyReport.pipeSupportsPercent : 0,
                valveSitesCompletedToday: 0,
                valveSitesTotalCompleted: objDailyReport != null && objDailyReport.valveSitesTotalCompleted != null ? objDailyReport.valveSitesTotalCompleted : 0,
                valveSitesPercent: objDailyReport != null && objDailyReport.valveSitesPercent != null ? objDailyReport.valveSitesPercent : 0,
                testStationsCompletedToday: 0,
                testStationsTotalCompleted: objDailyReport != null && objDailyReport.testStationsTotalCompleted != null ? objDailyReport.testStationsTotalCompleted : 0,
                testStationsPercent: objDailyReport != null && objDailyReport.testStationsPercent != null ? objDailyReport.testStationsPercent : 0,
                pipePaddingCompletedToday: 0,
                pipePaddingTotalCompleted: objDailyReport != null && objDailyReport.pipePaddingTotalCompleted != null ? objDailyReport.pipePaddingTotalCompleted : 0,
                pipePaddingPercent: objDailyReport != null && objDailyReport.pipePaddingPercent != null ? objDailyReport.pipePaddingPercent : 0,
                issues: null,
                accident: null,
                integrityTestingComplete: null,
                inspectorName: user.account.name,
                createdBy: user.account.userName,
                createdByKey: 0,
                createdDate: todaysDate,
                statusKey: 0,
                status: "Draft",
                afeNo: constructionReportDTO.afeNo,
                afeHeaderKey: constructionReportDTO.afeHeaderKey,
                afeDescription: '',
                projectName: constructionReportDTO.projectName,
                startDate: constructionReportDTO.startDate,
                endDate: constructionReportDTO.estComplete,
                expectedCompletionDate: constructionReportDTO.expectedCompletionDate,
                locationKey: constructionReportDTO.locationKey,
                basin: constructionReportDTO.basin,
                pipelineSize: constructionReportDTO.pipeLineSize,
                projectLength: constructionReportDTO.projectLength,
                casedBoreLength: constructionReportDTO.casedBoreLength,
                unCasedBoreLength: constructionReportDTO.unCasedBoreLength,
                afeStatusKey: 0,
                afeStatusDesc: '',
                accident: 0,
                blob_id: "-" + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString() + date.getMilliseconds().toString()
            }

            this.setState({dailyPipeLineReportDTO: dailyPipeLineReportDTO})
        }
        else{
            //Call API;
            await this.getReportData();
        }
    }

    async getReportData(){
        
        const { constructionReportKey,constructionReportPipelineKey} = this.state;

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/' + constructionReportKey + '/DailyReport/' + constructionReportPipelineKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
               
                if(json.casedBoreLength === undefined || json.casedBoreLength === null || json.casedBoreLength <= 0 ){
                    json.casedBoreCompletedToday = 0;
                }

                if(json.unCasedBoreLength === undefined || json.unCasedBoreLength === null || json.unCasedBoreLength <= 0 ){
                    json.uncasedBoreCompletedToday = 0;
                }
                 
                this.setState({ 
                    dailyPipeLineReportDTO: json, 
                }) 
            });
    }
  
    async getAllDailyReports(){
        
        const { constructionReportKey} = this.state;

        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey + '/DailyReports'; 
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
               
                if(json.casedBoreLength === undefined || json.casedBoreLength === null || json.casedBoreLength <= 0 ){
                    json.casedBoreCompletedToday = 0;
                }

                if(json.unCasedBoreLength === undefined || json.unCasedBoreLength === null || json.unCasedBoreLength <= 0 ){
                    json.uncasedBoreCompletedToday = 0;
                }
                 
                this.setState({ 
                    dailyReports: json, 
                }) 
            });
    }

    isFormReadOnly = () => {
        const {  constructionReportDTO} = this.state

        if (constructionReportDTO !== undefined){
            return constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4 || constructionReportDTO.statusKey === 5 ? true : false;
        }
        return false;
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKey} = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKey }]; 
        return reportPara
    }
    
    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }

    async getAFEReport(reportName){
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }

    formatDate = (value) => {

        if (IsDate(value)) {
            const months = {
                0: '01',
                1: '02',
                2: '03',
                3: '04',
                4: '05',
                5: '06',
                6: '07',
                7: '08',
                8: '09',
                9: '10',
                10: '11',
                11: '12',
            }
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            const d = new Date(value)
            const year = d.getFullYear()
            const date = (d.getDate() < 10 ? '0' : '') + d.getDate()
            const monthIndex = d.getMonth()
            const monthName = months[d.getMonth()]
            const dayName = days[d.getDay()] // Thu
    
            //const formatted = `${monthName}/${date}/${year}`
            const formatted = `${year}-${monthName}-${date}`
            return formatted.toString()
        } else {
            return ""
        }
    
    }

    issues = (fieldName) => {
        let {dailyPipeLineReportDTO} = this.state;
        let result = false;

        if (dailyPipeLineReportDTO.issues == null){
            result = false;
        }
        else if (dailyPipeLineReportDTO.issues && fieldName == 'YES'){
            result = true;
        }
        else if (dailyPipeLineReportDTO.issues == false && fieldName == 'YES'){
            result = false;
        }
        else if (dailyPipeLineReportDTO.issues && fieldName == 'NO'){
            result = false;
        }
        else if (dailyPipeLineReportDTO.issues == false && fieldName == 'NO'){
            result = true;
        }

        return result; 
    }

    integrityTestingCompleted = (fieldName) => {
        let {dailyPipeLineReportDTO} = this.state;
        let result = false;

        if (dailyPipeLineReportDTO.integrityTestingComplete == null){
            result = false;
        }
        else if (dailyPipeLineReportDTO.integrityTestingComplete && fieldName == 'YES'){
            result = true;
        }
        else if (dailyPipeLineReportDTO.integrityTestingComplete == false && fieldName == 'YES'){
            result = false;
        }
        else if (dailyPipeLineReportDTO.integrityTestingComplete && fieldName == 'NO'){
            result = false;
        }
        else if (dailyPipeLineReportDTO.integrityTestingComplete == false && fieldName == 'NO'){
            result = true;
        }

        return result; 
    }

    render() {
        const { constructionReportDTO,dailyPipeLineReportDTO, constructionReportKey, alertMessage, 
            isSecurityAdminLogin, showAlert,isLoaded, showSaveAlert , alertColor,  
             validateDataItem , isDataChange ,afesRevSupBuget } = this.state
        const { user } = this.props

         

        let objAFEsRevSupBuget = 0;
        let objAFEsRevSupBugetDetails = '' 
        if (afesRevSupBuget != undefined && afesRevSupBuget !== null && afesRevSupBuget.length > 0){ 
            for (let i = 0; i < afesRevSupBuget.length; i++) {
                let index = afesRevSupBuget[i].afeNo.indexOf('-') + 1;
                let objLength = afesRevSupBuget[i].afeNo.length - index;
                objAFEsRevSupBuget  =  objAFEsRevSupBuget + afesRevSupBuget[i].afeTotalAmt;
                objAFEsRevSupBugetDetails += (i == 0 ? ' ' : ' + ')   + afesRevSupBuget[i].afeNo.substr(index, objLength) + ': ' +  USDCurrencyFormat(afesRevSupBuget[i].afeTotalAmt,0) ;
            }
            console.log(objAFEsRevSupBuget); 
        } 
 
        let index = 0;
 

        if (!isLoaded) {
            return (<div>Loading Construction Report Data .....</div>)
        } else
            return (<div>

                <Form onSubmit={e => e.preventDefault()}>
                    <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <Col sm={5}>

                            <Button color="secondary" className="float-left"
                                hidden={isDataChange}
                                onClick={() => this.closeForm()}>Close</Button>  
                            <Button color="success" className="float-left"
                                style={{ marginLeft: "5px" }}
                                hidden={!isSecurityAdminLogin && this.isFormReadOnly()}
                                onClick={() => this.saveData(2)}>{dailyPipeLineReportDTO.constructionReportPipelineKey > 0 ? 'Save' : 'Submit'}</Button>     
                        </Col> 
                        <Col sm={1}>  
                        </Col>  
                    </Row>
                    <ColoredLine color="grey" />
                    <Row form>
                        <Alert color="success" isOpen={showSaveAlert}
                            toggle={this.toggleSaveAlsert}
                            style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                            {"Data Save Successfuly !!!!"}
                        </Alert>
                        <Alert color={alertColor} toggle={() => this.toggleAlert()}
                            isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {alertMessage}
                        </Alert >
                    </Row>
                    <Accordion allowMultipleExpanded={true}
                        allowZeroExpanded={true}
                        preExpanded={(['general','workSummary'])}>

                        <AccordionItem uuid='general'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>General Information</strong>
                                    {/* <strong style={{ textAlign: 'right', paddingLeft: '45rem' }}>Status - {dailyPipeLineReportDTO.status}</strong>    */}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeNo">AFE No</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    disabled type="text" 
                                                    name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={dailyPipeLineReportDTO.afeNo}
                                                />
                                            </Col> 
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="createdBy">Project Name</Label>
                                            </Col>
                                            <Col md={8}>
                                                 <Input disabled type="text" name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={dailyPipeLineReportDTO.projectName}
                                                />
                                            </Col>

                                        </Row>
                                    </Col> 
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Start Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={FormatDate(dailyPipeLineReportDTO.startDate)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">End Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="endDate"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={FormatDate(dailyPipeLineReportDTO.endDate)}
                                                /> 
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Pipe Size (in.)</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" 
                                                    name="afeName" 
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={dailyPipeLineReportDTO.pipelineSize}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Project Length (ft.)</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" 
                                                    name="afeName" 
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={dailyPipeLineReportDTO.projectLength}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Case Bore Length (ft.)</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="afeName" 
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={dailyPipeLineReportDTO.casedBoreLength}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Uncased Bore Length (ft.)</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="afeName" 
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={dailyPipeLineReportDTO.unCasedBoreLength}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Report Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <DatePicker 
                                                    value={dailyPipeLineReportDTO.reportDate}
                                                    className={(!validateDataItem.reportDate ? "form-error" : "")}
                                                    onChange={(v, f) => this.onTextChange("reportDate", v, f)}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                    <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Basin</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={dailyPipeLineReportDTO.basin}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Expected Completion Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <DatePicker 
                                                    value={dailyPipeLineReportDTO.expectedCompletionDate}
                                                    className={(!validateDataItem.expectedCompletionDate ? "form-error" : "")}
                                                    onChange={(v, f) => this.onTextChange("expectedCompletionDate", v, f)}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={12}> 
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeSponsor">Work Summary</Label>
                                            </Col>
                                            <Col md={10}>
                                                <Input type="textarea" name="afeDescription"
                                                    rows={8}
                                                    className={(!validateDataItem.workSummary ? "form-error" : "")}
                                                    id="summary" placeholder=""
                                                    value={dailyPipeLineReportDTO.workSummary} 
                                                    onChange={(e) => this.onTextChange("workSummary", e.target.value, e)}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>  
                                </Row> 
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem uuid='workSummary'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>Details</strong>  
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Row form style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Col md={12}>
                                        <Row form>
                                            <Col md={2}>
                                                 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "8px"}}>
                                                <Label for="afeNo"><strong>Completed Today (ft.)</strong></Label>
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "16px"}}>
                                                <Label for="afeNo"><strong>Total Completed (ft.)</strong></Label> 
                                            </Col>
                                            <Col md={2} style={{textAlign: "center"}}> 
                                                <Label  for="afeNo"><strong>%</strong></Label>  
                                            </Col> 
                                        </Row>
                                    </Col> 
                                    <Col md={12} >
                                        <Row form  style={{ marginBottom: "5px" }}>
                                            <Col md={2}>
                                                <Label for="afeNo">ROW Cleared</Label>
                                            </Col>
                                            <Col md={2} >
                                                <NumberFormat 
                                                    className={(!validateDataItem.rowClearedCompletedToday ? "form-error" : "")}
                                                    thousandSeparator={true}
                                                    value={dailyPipeLineReportDTO.rowClearedCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("rowClearedCompletedToday", e.value, e)} 
                                                    style={{height: "37px"}}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                 /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.rowClearedTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                    //className={(!validateDataItem.wbOwnedPct ? "form-error form-control text-md-right" : "form-control text-md-right")}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    //displayType={'text'} 
                                                    //disabled={(this.isFormReadOnly() || statusKey === 4) && !( (isAFEAdmin || isAFEManager) && (statusKey!==5 && statusKey !==8) )}
                                                    thousandSeparator={true}
                                                    style={{height: "37px"}}
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                            disabled={true} 
                                                            value={dailyPipeLineReportDTO.rowClearedPercent}
                                                            onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                            //className={(!validateDataItem.wbOwnedPct ? "form-error form-control text-md-right" : "form-control text-md-right")}
                                                            fixedDecimalScale={true}
                                                            //decimalScale={2}
                                                            //displayType={'text'} 
                                                            //disabled={(this.isFormReadOnly() || statusKey === 4) && !( (isAFEAdmin || isAFEManager) && (statusKey!==5 && statusKey !==8) )}
                                                            thousandSeparator={true}
                                                            suffix={'%'} 
                                                            style={{height: "37px"}}
                                                            />  
                                            </Col> 
                                        </Row> 
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Trench Dug</Label>
                                            </Col>
                                            <Col md={2} >
                                                <NumberFormat
                                                        className={(!validateDataItem.trenchDugCompletedToday ? "form-error" : "")} 
                                                        value={dailyPipeLineReportDTO.trenchDugCompletedToday}
                                                        onValueChange={(e) => this.onTextChange("trenchDugCompletedToday", e.value, e)}
                                                        thousandSeparator={true}
                                                        disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.trenchDugTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.trenchDugPercent}
                                                    onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Pipe Strung</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.pipeStrungCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.pipeStrungCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("pipeStrungCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.pipeStrungTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("pipeStrungCompletedToday", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true} 
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat 
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.pipeStrungPercent}
                                                    onValueChange={(e) => this.onTextChange("pipeStrungCompletedToday", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Lowered In</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.loweredInCompletedToday ? "form-error" : "")} 
                                                    value={dailyPipeLineReportDTO.loweredInCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("loweredInCompletedToday", e.value, e)}
                                                    thousandSeparator={true} 
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.loweredInTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("loweredInTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat 
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.loweredInPercent}
                                                    onValueChange={(e) => this.onTextChange("loweredInPercent", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Back Filled</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.backFilledCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.backFilledCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("backFilledCompletedToday", e.value, e)}
                                                    thousandSeparator={true} 
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat 
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.backFilledTotalCompleted}
                                                    //onValueChange={(e) => this.onTextChange("backFilledTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat 
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.backFilledPercent}
                                                    //onValueChange={(e) => this.onTextChange("backFilledPercent", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">ROW Clean-up</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.rowCleanUpCompletedToday ? "form-error" : "")}   
                                                    value={dailyPipeLineReportDTO.rowCleanUpCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("rowCleanUpCompletedToday", e.value, e)}
                                                    thousandSeparator={true} 
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.rowCleanUpTotalCompleted}
                                                    //onValueChange={(e) => this.onTextChange("rowCleanUpTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat 
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.rowCleanUpPercent}
                                                    //onValueChange={(e) => this.onTextChange("rowCleanUpPercent", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Cased Bore</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.casedBoreCompletedToday ? "form-error" : "")}  
                                                    //className={"form-error"} 
                                                    value={dailyPipeLineReportDTO.casedBoreLength === undefined || dailyPipeLineReportDTO.casedBoreLength === null || dailyPipeLineReportDTO.casedBoreLength <= 0 ? 0 :
                                                        dailyPipeLineReportDTO.casedBoreCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("casedBoreCompletedToday", e.value, e)}
                                                    thousandSeparator={true} 
                                                    disabled={(this.isFormReadOnly() && !isSecurityAdminLogin) || 
                                                              (dailyPipeLineReportDTO.casedBoreLength === undefined || dailyPipeLineReportDTO.casedBoreLength === null || dailyPipeLineReportDTO.casedBoreLength <= 0)}
                                                    invalid={!validateDataItem.casedBoreCompletedToday}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.casedBoreTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("casedBoreTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true} 
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat 
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.casedBorePercent}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Uncased Bore</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.uncasedBoreCompletedToday ? "form-error" : "")}
                                                    value={dailyPipeLineReportDTO.uncasedBoreCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("uncasedBoreCompletedToday", e.value, e)}
                                                    thousandSeparator={true} 
                                                    //disabled={(this.isFormReadOnly() && !isSecurityAdminLogin)}
                                                    disabled={(this.isFormReadOnly() && !isSecurityAdminLogin) || 
                                                        (dailyPipeLineReportDTO.unCasedBoreLength === undefined || dailyPipeLineReportDTO.unCasedBoreLength === null || dailyPipeLineReportDTO.unCasedBoreLength <= 0)}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.uncasedBoreTotalCompleted}
                                                    //onValueChange={(e) => this.onTextChange("uncasedBoreTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col>
                                            <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat 
                                                    disabled={true}  
                                                    value={dailyPipeLineReportDTO.uncasedBorePercent}
                                                    //onValueChange={(e) => this.onTextChange("uncasedBorePercent", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col> 
                                        </Row>
                                        
                                    </Col>
                                     
                                </Row> 
                                   
                                <Row form style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Col md={12}>
                                        <Row form>
                                            <Col md={2}> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <Label for="afeNo"><strong>Completed Today</strong></Label>
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "20px"}}>
                                                <Label for="afeNo"><strong>Total Completed</strong></Label> 
                                            </Col> 
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                    <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Rock Encountered</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.rockEncounteredCompletedToday ? "form-error" : "")} 
                                                    value={dailyPipeLineReportDTO.rockEncounteredCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("rockEncounteredCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}  
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.rockEncounteredTotalCompleted}
                                                    //onValueChange={(e) => this.onTextChange("rockEncounteredTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col>
                                            {/* <Col md={1} style={{paddingLeft: "22px", paddingRight:"14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.rockEncounteredPercent}
                                                    //onValueChange={(e) => this.onTextChange("rockEncounteredPercent", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    //decimalScale={2}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                />  
                                            </Col>  */}
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Fusion Welds</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.fusionWeldsCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.fusionWeldsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("fusionWeldsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.fusionWeldsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("fusionWeldsCompletedToday", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col> 
                                        </Row> 
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Tie-Ins</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.tieInsCompletedToday ? "form-error" : "")}   
                                                    value={dailyPipeLineReportDTO.tieInsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("tieInsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}  
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.tieInsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("tieInsCompletedToday", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true} 
                                                />   
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Line Crossings</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.lineCrossingsCompletedToday ? "form-error" : "")}    
                                                    value={dailyPipeLineReportDTO.lineCrossingsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("lineCrossingsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.lineCrossingsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("lineCrossingsCompletedToday", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true} 
                                                />   
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Fence Gaps</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.fenceGapsCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.fenceGapsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("fenceGapsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.fenceGapsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("fenceGapsTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Hydrovacs</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.hydrovacsCompletedToday ? "form-error" : "")} 
                                                    value={dailyPipeLineReportDTO.hydrovacsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("hydrovacsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin} 
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.hydrovacsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("hydrovacsTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Mats</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.matsCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.matsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("matsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.matsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("matsTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Pipe Supports</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.pipeSupportsCompletedToday ? "form-error" : "")}   
                                                    value={dailyPipeLineReportDTO.pipeSupportsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("pipeSupportsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.pipeSupportsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("pipeSupportsTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Valve Sites</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.valveSitesCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.valveSitesCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("valveSitesCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.valveSitesTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("valveSitesTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}  
                                                />   
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo"># Of Test Stations</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.testStationsCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.testStationsCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("testStationsCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.testStationsTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("testStationsTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col> 
                                        </Row>
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeNo">Pipe Padding</Label>
                                            </Col>
                                            <Col md={2}>
                                                <NumberFormat
                                                    className={(!validateDataItem.pipePaddingCompletedToday ? "form-error" : "")}  
                                                    value={dailyPipeLineReportDTO.pipePaddingCompletedToday}
                                                    onValueChange={(e) => this.onTextChange("pipePaddingCompletedToday", e.value, e)}
                                                    thousandSeparator={true}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                /> 
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "14px"}}>
                                                <NumberFormat
                                                    disabled={true}   
                                                    value={dailyPipeLineReportDTO.pipePaddingTotalCompleted}
                                                    onValueChange={(e) => this.onTextChange("pipePaddingTotalCompleted", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />   
                                            </Col> 
                                        </Row>
                                    </Col>
                                     
                                </Row>  

                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={12}>
                                                <Label for="afeSponsor">Issues: If yes, describe in work summary above</Label>
                                            </Col> 
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row form> 
                                            <Col md={3}> 
                                                <CustomInput
                                                    className={(!validateDataItem.issues ? "form-error" : "")}  
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                    type="checkbox"
                                                    id={`q${index + 1}Yes`}
                                                    label={"Yes"}
                                                    checked={this.issues('YES')}
                                                    onChange={(e) => this.onTextChange("issues", 
                                                                                        true,
                                                                                        e)
                                                    }
                                                /> 
                                            </Col>
                                            <Col md={3}> 
                                                <CustomInput
                                                    className={(!validateDataItem.issues ? "form-error" : "")}  
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                    type="checkbox"
                                                    id={`q${index + 1}No`}
                                                    label={"No"} 
                                                    checked={this.issues('NO')}
                                                    onChange={(e) => this.onTextChange("issues", 
                                                                                        false,
                                                                                        e)
                                                    }
                                                />
                                            </Col>
                                             
                                        </Row>
                                    </Col>  
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={12}>
                                                <Label for="afeSponsor">Integrity Testing Completed</Label>
                                            </Col> 
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row form> 
                                            <Col md={3}>
                                                <CustomInput
                                                    className={(!validateDataItem.integrityTestingComplete ? "form-error" : "")}  
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                    type="checkbox"
                                                    id={`q${index + 1}Yes2`}
                                                    label={"Yes"}
                                                    checked={this.integrityTestingCompleted('YES')}
                                                    onChange={(e) => this.onTextChange("integrityTestingComplete", true, e)
                                                    }
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <CustomInput
                                                    className={(!validateDataItem.integrityTestingComplete ? "form-error" : "")} 
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                    type="checkbox"
                                                    id={`q${index + 1}No2`}
                                                    label={"No"}
                                                    checked={this.integrityTestingCompleted('NO')}
                                                    onChange={(e) => this.onTextChange("integrityTestingComplete", false, e)
                                                    }
                                                /> 
                                            </Col>
                                             
                                        </Row>
                                    </Col>  
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={12}>
                                                <Label for="afeSponsor"><strong>Inspectors Name</strong></Label>
                                            </Col> 
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row form> 
                                            <Col md={6}>
                                                <Input
                                                    className={(!validateDataItem.inspectorName ? "form-error" : "")}  
                                                    type="text" 
                                                    name="createdBy"
                                                    id="createdBy" 
                                                    placeholder={"Enter Inspectors Name"}
                                                    value={dailyPipeLineReportDTO.inspectorName}
                                                    onChange={(e) => this.onTextChange("inspectorName", e.target.value, e)}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin} 
                                                />
                                            </Col> 
                                        </Row>
                                    </Col>  
                                </Row> 
                            </AccordionItemPanel>
                        </AccordionItem>
                        {/* <AccordionItem hidden={constructionReportKey == undefined || constructionReportKey == null || constructionReportKey <=0 || constructionReportDTO.statusKey <= 0 ||
                                                dailyPipeLineReportDTO.constructionReportPipelineKey == undefined || dailyPipeLineReportDTO.constructionReportPipelineKey <= 0}> */}
                        <AccordionItem hidden={constructionReportKey == undefined || constructionReportKey == null ||  
                                                dailyPipeLineReportDTO == undefined || dailyPipeLineReportDTO === null}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Attachments"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel> 
                                    <FileManager
                                        assetkey={dailyPipeLineReportDTO.constructionReportPipelineKey > 0 ? dailyPipeLineReportDTO.constructionReportPipelineKey : dailyPipeLineReportDTO.blob_id}
                                        controlid={getRandomInt}
                                        folderPath={'constructionReport/' + constructionReportDTO.blob_id + '/DailyReportsDocuments/' + dailyPipeLineReportDTO.blob_id}
                                        linkTableName={'topConstructionReportPipeline'}
                                        blobContainter={constructionReportDTO.blob_id}
                                        >
                                    </FileManager>
                                </AccordionItemPanel>
                        </AccordionItem> 
                        <AccordionItem hidden={constructionReportKey == undefined || constructionReportKey == null || constructionReportKey <=0 || constructionReportDTO.statusKey <= 0 ||
                                                dailyPipeLineReportDTO.constructionReportPipelineKey == undefined || dailyPipeLineReportDTO.constructionReportPipelineKey <= 0}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Change Orders "}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ConstructionReportingChangeOrders
                                        constructionReportKey={dailyPipeLineReportDTO.constructionReportKey}
                                        projectType={dailyPipeLineReportDTO.projectType}
                                        user={this.props.user}
                                        constructionReportDTO={constructionReportDTO}
                                        reportKey={dailyPipeLineReportDTO.constructionReportPipelineKey}
                                        dailyReportKey={this.props.constructionReportPipelineKey}
                                        reportDate={dailyPipeLineReportDTO.reportDate}
                                        isSecurityAdminLogin={isSecurityAdminLogin} 
                                    >  
                                    </ConstructionReportingChangeOrders> 
                                </AccordionItemPanel>
                        </AccordionItem>  
                    </Accordion>
                     
                     
                </Form>

            </div>)
    }

}
 

export default DailyPipeLineReport
