import React, { Component } from 'react';
import { apiURL, apiHeader, apiDELETEHeader } from '../resources/apiURL';
import { Form } from 'reactstrap';
import moment from "moment";
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { getUserADAccount } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Input } from 'reactstrap';


import Select from 'react-select';


import { IgrDatePickerModule } from 'igniteui-react-inputs';


import { ColoredLine } from '../resources/utility';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Refresh from '../resources/refresh.svg'
import Remove from '../resources/remove.png'
import TrashBin from '../resources/trash.svg'
import Revise from '../resources/revise.png'
import floppy from '../resources/floppy.png'
import { is } from 'type-is';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn, IgrDateTimeColumn } from 'igniteui-react-grids';

import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop';



var DatePicker = require("reactstrap-date-picker");


IgrDatePickerModule.register();



export default class JibLineDet extends Component {
    static displayName = JibLineDet.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            windowHeight: window.innerHeight,
            user: props.user,
            isJIBUser: props.isJIBUser,
            isJIBEdit: props.isJIBEdit,
            isJIBWorkday: props.isJIBWorkday,
            eibSubmitSupplierInvKey: props.eibSubmitSupplierInvKey,
            isLineEditable: props.isLineEditable,
            isSubline: false,
            jibLinedata: [],
            isLoaded: false,

            jibLineHeaddata: [],
            isHeadLoaded: false,

            apiError: '',
            showBadAlert: false,
            showGoodAlert: false,

            showFailedAlert: false,
            showWarningAlert: false,

            modalDetails: false,

            ddCompany: [],
            isddCompanyLoaded: false,

            ddSite: [],
            isddSiteLoaded: false,

            ddCostcenter: [],
            isddCostcenterLoaded: false,

            ddsSpendcategory: [],
            isddSpendcategoryLoaded: false,

            ddSupplier: [],
            isddSupplierLoaded: false,

            ddProject: [],
            isddProjectLoaded: false,

            jibLogdata: [],
            isjibLogdataLoaded: false,


            validateDataItem: {
                company: true,
                site: true,
                project: true,
                costcenter: true,
                spendcategory: true,
                quantity: true,
                unitCost: true,
                extendedAmount: true,
                controlAmount: true


            }



        }
    }





    validateDataLocal = () => {


        const { validateDataItem, jibLinedata } = this.state
        var bVal = true
        var valItem = validateDataItem

        const controlAmount = jibLinedata[0].controlAmount;
        if (controlAmount != null && controlAmount != '' && isNaN(parseFloat(controlAmount))) {
            valItem.controlAmount = false;
            bVal = false;
        } else {
            valItem.controlAmount = true;
        }



        const extendedAmount = jibLinedata[0].extendedAmount;
        if (extendedAmount != null && extendedAmount != '' && isNaN(parseFloat(extendedAmount))) {
            valItem.extendedAmount = false;
            bVal = false;
        } else {
            valItem.extendedAmount = true;
        }


        const quantity = jibLinedata[0].quantity;
        if (quantity != null && quantity != '' && isNaN(parseFloat(quantity))) {
            valItem.quantity = false;
            bVal = false;
        } else {
            valItem.quantity = true;
        }


        const unitCost = jibLinedata[0].unitCost;
        if (unitCost != null && unitCost != '' && isNaN(parseFloat(unitCost))) {
            valItem.unitCost = false;
            bVal = false;
        } else {
            valItem.unitCost = true;
        }


        this.setState({ validateDataItem: valItem })
        return bVal

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.jibLogdata;

    }

    getLogData = () => {
        const { eibSubmitSupplierInvKey } = this.state

        let myURI = apiURL + 'AR/vapJIBLog/tapEIBSubmitSupplierInv/primarykey/' + eibSubmitSupplierInvKey

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isjibLogdataLoaded: true,
                    jibLogdata: json
                })
            });
    }


    // validateBoxes = (errorMsg) => {

    //     const { validateDataItem} = this.state
    //     var bVal = true
    //     var valItem = validateDataItem

    //     if (errorMsg.includes('site')) {
    //         // Perform actions if "site" is found in errorMsg
    //         console.log('The keyword "site" is found in the error message.');
    //         // You can set bVal to false or perform other logic here
    //         bVal = false;
    //     }


    //     if (data[0].iunderstand === null || data[0].iunderstand === 0) {
    //         valItem.iunderstand = false
    //         bVal = false
    //     }

    //     if (!this.validateJSAJobTaskForSubmit()) {
    //         bVal = false
    //     }

    //     if (data[0].addtlEquipment === null || data[0].addtlEquipment === 0) {
    //         valItem.addtlEquipment = false
    //         bVal = false
    //     }

    //     if (data[0].hazEnergyControl === null || data[0].hazEnergyControl === 0) {
    //         valItem.hazEnergyControl = false
    //         bVal = false
    //     }







    //     this.setState({ validateDataItem: valItem })
    //     return bVal

    // }

    //company ---------------------------------------------------

    getSelddCompany = (v) => {
        const { ddCompany } = this.state
        if (v !== null && v !== "") {
            return ddCompany.filter(e => e.companyID === v);
        }
        return ""
    }


    getCompany = () => {
        let myURI = apiURL + 'AR/WDCompany'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    ddCompany: this.createCompanyList(json),
                    isddCompanyLoaded: true
                })
            });
    }


    // getCompany = () => {
    //     let myURI = apiURL + 'AR/WDEIBCompany';

    //     console.log(myURI);

    //     fetch(myURI) 
    //         .then(res => res.json()) // Parse the response as JSON
    //         .then(json => {
    //             // Filter the JSON data
    //             const filteredData = json.filter(a => {
    //                 // Check if CompanyID is not in the list of ParentCompanyID where ParentCompanyID is not null
    //                 return !json.some(f => f.parentCompanyID !== null && f.parentCompanyID === a.companyID);
    //             });

    //             this.setState({
    //                 ddCompany: this.createCompanyList(filteredData),
    //                 isddCompanyLoaded: true
    //             });
    //         })
    //         .catch(error => console.error('Error fetching company data:', error));
    // }


    createCompanyList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.companyID
            e.label = e.companyDesc
            tempList.push(e)
        })

        return tempList
    }

    //end company ------------------------------------------

    //project ---------------------------------------------------

    getSelddProject = (v) => {
        const { ddProject } = this.state
        if (v !== null && v !== "") {
            return ddProject.filter(e => e.project_ID === v);
        }
        return ""
    }


    getProject = () => {
        const { jibLinedata } = this.state;
        //if (!isSubline) {
        //let myURI = apiURL + 'AR/vWDProjectsByCompanyID/LE012'
        let myURI; // = `${apiURL}AR/vWDProjectsByCompanyID/${jibLinedata[0].company}`;
        if (jibLinedata[0].company) {
            myURI = `${apiURL}AR/vWDProjectsByCompanyIDActive/${jibLinedata[0].company}`;
        } else {
            myURI = `${apiURL}AR/vWDProjectsByCompanyIDActive/all`;
        }

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    ddProject: this.createProjectList(json),
                    isddProjectLoaded: true
                })
            });
    }


    createProjectList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.project_ID
            e.label = e.projectDesc
            tempList.push(e)
        })

        return tempList
    }

    //end project ------------------------------------------

    //sites ---------------------------------------------------
    getSelddSite = (v) => {
        const { ddSite } = this.state
        if (v !== null && v !== "") {
            return ddSite.filter(e => e.siteID === v);
        }
        return ""
    }


    getSite = () => {
        let myURI = apiURL + 'AR/vwdSite'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    ddSite: this.createSiteList(json),
                    isddSiteLoaded: true
                })
            });
    }



    createSiteList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.siteID
            e.label = e.siteDesc
            tempList.push(e)
        })

        return tempList
    }


    //end sites -----------------------------------------------


    //cost centers ---------------------------------------------------
    getSelddCostcenter = (v) => {
        const { ddCostcenter } = this.state
        if (v !== null && v !== "") {
            return ddCostcenter.filter(e => e.costCenterID === v);
        }
        return ""
    }


    getCostCenter = () => {
        let myURI = apiURL + 'AR/vwdCostCenters'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    ddCostcenter: this.createCostcenterList(json),
                    isddCostcenterLoaded: true
                })
            });
    }



    createCostcenterList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.costCenterID
            e.label = e.costCenterDesc
            tempList.push(e)
        })

        return tempList
    }


    //end cost centers -----------------------------------------------


    //Spend category ---------------------------------------------------
    getSelddSpendcategory = (v) => {
        const { ddsSpendcategory } = this.state
        if (v !== null && v !== "") {
            return ddsSpendcategory.filter(e => e.spendCategoryID === v);
        }
        return ""
    }


    getSpendcategory = () => {
        let myURI = apiURL + 'AR/vwdSpendCategory'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    ddsSpendcategory: this.createSpendcategoryList(json),
                    isddSpendcategoryLoaded: true
                })
            });
    }



    createSpendcategoryList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.spendCategoryID
            e.label = e.spendCategoryDesc
            tempList.push(e)
        })

        return tempList
    }


    //end Spend category -----------------------------------------------

    //Supplier ---------------------------------------------------
    getSelddSupplier = (v) => {
        const { ddSupplier } = this.state
        if (v !== null && v !== "") {
            return ddSupplier.filter(e => e.supplier_ID === v);
        }
        return ""
    }


    getSupplier = () => {
        let myURI = apiURL + 'AR/vwdSuppliersSmall'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    ddSupplier: this.createSupplierList(json),
                    isddSupplierLoaded: true
                })
            });
    }



    createSupplierList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.supplier_ID
            e.label = e.supplier_Name_ID
            tempList.push(e)
        })

        return tempList
    }


    //end Supplier ------------------------------------------------

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "JIBLog");
    }

    onDismissAlert = () => {
        this.setState({ showBadAlert: false, showGoodAlert: false });
    }

    onDismissFailedAlert = () => {
        this.setState({ showFailedAlert: false });
    }


    getDetails = () => {
        const { eibSubmitSupplierInvKey } = this.state

        let myURI = apiURL + 'AR/GetJIBLineDetails/' + eibSubmitSupplierInvKey;

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                const isSubline = !json[0].company;
                const isFailed = json[0].status === "Failed" || json[0].status === "Success(warning)"
                
                const isWarning = json[0].status === "Success(warning)"

                // console.log('json:', json);
                // console.log('isSubline:', isSubline);
                // console.log('isFailed:', isFailed);
                // console.log('isWarning:', isWarning);

                this.setState({
                    isLoaded: true,
                    jibLinedata: json,
                    isSubline: isSubline,
                    showFailedAlert: isFailed,
                    showWarningAlert: isWarning
                    
                }, () => {
                    this.getHeadDetails();
                    this.validateData();
                    this.getCompany();
                    this.getSite();
                    this.getCostCenter();
                    this.getSpendcategory();
                    this.getSupplier();
                    this.getProject();
                    this.getLogData();
                });
            });
    }

    getHeadDetails = () => {
        const { eibSubmitSupplierInvKey } = this.state

        let myURI = apiURL + 'AR/GetJIBLineHeadDetails/' + eibSubmitSupplierInvKey;

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isHeadLoaded: true,
                    jibLineHeaddata: json
                });
            });
    }



    closeForm = () => {
        this.props.toggle()

    }

    refresh = () => {

        this.getDetails();
    }


    createDataForSaveDTO = () => {
        const { jibLinedata, user, eibSubmitSupplierInvKey } = this.state
        const datatoval = {
            "EIBSubmitSupplierInvKey": eibSubmitSupplierInvKey,
            "Status": jibLinedata[0].status,
            "Submit": jibLinedata[0].submit,
            "LockedInWorkday": jibLinedata[0].lockedInWorkday,
            "InvoiceAccountingDate": jibLinedata[0].invoiceAccountingDate,
            "Company": jibLinedata[0].company,
            "Currency": jibLinedata[0].currency,
            "Supplier": jibLinedata[0].supplier,
            "InvoiceDate": jibLinedata[0].invoiceDate,
            "InvoiceReceivedDate": jibLinedata[0].invoiceReceivedDate,
            "ControlAmount": jibLinedata[0].controlAmount,
            "SuppliersInvoiceNumber": jibLinedata[0].suppliersInvoiceNumber,
            "Memo": jibLinedata[0].memo,
            "IntercompanyAffiliate": jibLinedata[0].intercompanyAffiliate,
            "SpendCategory": jibLinedata[0].spendCategory,
            "Quantity": jibLinedata[0].quantity,
            "UnitCost": jibLinedata[0].unitCost,
            "ExtendedAmount": jibLinedata[0].extendedAmount,
            "Memo2": jibLinedata[0].memo2,
            "Project": jibLinedata[0].project,
            "CostCenter": jibLinedata[0].costCenter,
            "Site": jibLinedata[0].site,
            "ServiceMonth": jibLinedata[0].serviceMonth,
            "ServiceYear": jibLinedata[0].serviceYear,
            "ModifiedBy": getUserADAccount(user.account.userName),
            "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return datatoval
    }

    saveData = () => {
        this.validateData()
            .then(() => {
                //const { jibLinedata } = this.state;
                const saveDataDTO = this.createDataForSaveDTO()
                this.setState({
                    showBadAlert: false,
                    showGoodAlert: false
                });

                const postReq = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(saveDataDTO)
                };

                let myURI = apiURL + 'AR/JIBLineSave';
                fetch(myURI, postReq)
                    .then(async response => {
                        if (!response.ok) {
                            const rspData = await response.text();
                            const error = rspData || response.status;
                            this.setState({
                                apiError: rspData,
                                showBadAlert: true,
                            });
                            return Promise.reject(error);
                        }
                        // Save was successful
                        // this.setState({
                        //     showGoodAlert: true
                        // });
                        this.closeForm();
                    })
                    .catch(error => {
                        console.log("Save Error: " + error);
                    });
            })
            .catch(error => {
                console.log("Validation failed, saveData will not proceed: " + error);
            });
    }


    validateData = () => {
        return new Promise((resolve, reject) => {
            const { jibLinedata } = this.state;

            this.setState({
                showBadAlert: false,
                showGoodAlert: false
            });

            const postReq = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(jibLinedata[0])
            };

            let myURI = apiURL + 'AR/JIBLineValidate';
            fetch(myURI, postReq)
                .then(async response => {
                    if (!response.ok) {
                        const rspData = await response.text();
                        const error = rspData || response.status;
                        this.setState({
                            apiError: rspData,
                            showBadAlert: true,
                        });
                        return reject(error);
                    }
                    resolve(); // Validation successful
                })
                .catch(error => {
                    console.log("Validation Error: " + error);
                    reject(error);
                });
        });
    }




    saveDataOLD = () => {
        //const itemDTO = this.createDataForValidateDTO();

        //const { jibLinedata } = this.state
        const saveDataDTO = this.createDataForSaveDTO()
        this.setState({
            showBadAlert: false,
            showGoodAlert: false
        });

        const postReq = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(saveDataDTO)
        }

        let myURI = apiURL + 'AR/JIBLineSave'
        fetch(myURI, postReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = await response.text();
                    const error = rspData || response.status;
                    this.setState({
                        apiError: rspData,
                        showBadAlert: true,
                    });

                    return Promise.reject(error);
                }
                this.closeForm();

                //  this.getData()


                // this.setState({
                //     showGoodAlert: true
                // });


            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }


    validateDataOLD = () => {
        //const itemDTO = this.createDataForValidateDTO();

        const { jibLinedata } = this.state

        this.setState({
            showBadAlert: false,
            showGoodAlert: false
        });

        const postReq = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(jibLinedata[0])
        }

        let myURI = apiURL + 'AR/JIBLineValidate'
        fetch(myURI, postReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = await response.text();
                    const error = rspData || response.status;
                    this.setState({
                        apiError: rspData,
                        showBadAlert: true,
                    });

                    return Promise.reject(error);
                }

                //  this.getData()


                // this.setState({
                //     showGoodAlert: true
                // });


            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }


    onTextChange = (fieldName, fieldValue, e) => {
        this.setState({
            showBadAlert: false,
            showGoodAlert: false
        });
        const { jibLinedata, validateDataItem } = this.state
        switch (fieldName) {


            case "controlAmount":



                if (/^-?\d+\.?\d*$/.test(fieldValue) || fieldValue === '') {
                    jibLinedata[0].controlAmount = fieldValue;

                    this.setState({ jibLinedata: jibLinedata }, this.validateDataLocal);
                } else {
                    jibLinedata[0].controlAmount = ''
                    this.setState({ jibLinedata: jibLinedata });
                }

                break;


            case "company":

                jibLinedata[0].company = fieldValue.value
                jibLinedata[0].project = null;

                this.setState({ jibLinedata: jibLinedata }, () => {

                    this.getProject();
                });

                break;



            case "costCenter":

                jibLinedata[0].costCenter = fieldValue.value
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;





            case "currency":

                jibLinedata[0].currency = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "extendedAmount":
                if (/^-?\d+\.?\d*$/.test(fieldValue) || fieldValue === '') {
                    jibLinedata[0].extendedAmount = fieldValue;

                    this.setState({ jibLinedata: jibLinedata }, this.validateDataLocal);
                } else {
                    jibLinedata[0].extendedAmount = ''
                    this.setState({ jibLinedata: jibLinedata });
                }

                break;

            case "intercompanyAffiliate":

                jibLinedata[0].intercompanyAffiliate = fieldValue
                this.setState({ jibLinedata: jibLinedata })

                //this.validateData();
                break;

            case "invoiceAccountingDate":

                jibLinedata[0].invoiceAccountingDate = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;


            case "invoiceDate":

                jibLinedata[0].invoiceDate = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "invoiceReceivedDate":

                jibLinedata[0].invoiceReceivedDate = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "lockedInWorkday":

                jibLinedata[0].lockedInWorkday = fieldValue
                this.setState({ jibLinedata: jibLinedata })

                //this.validateData();
                break;

            case "memo":

                jibLinedata[0].memo = fieldValue
                this.setState({ jibLinedata: jibLinedata })

                //this.validateData();
                break;

            case "memo2":

                jibLinedata[0].memo2 = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;


            case "project":
                jibLinedata[0].project = fieldValue ? fieldValue.value : null;
                if (fieldValue) {
                    jibLinedata[0].site = fieldValue.siteID;
                    jibLinedata[0].costCenter = fieldValue.costCenterID;

                }
                this.setState({ jibLinedata: jibLinedata })
                break;

            case "quantity":

                if (/^-?\d+\.?\d*$/.test(fieldValue) || fieldValue === '') {
                    jibLinedata[0].quantity = fieldValue;

                    this.setState({ jibLinedata: jibLinedata }, this.validateDataLocal);
                } else {
                    jibLinedata[0].quantity = ''
                    this.setState({ jibLinedata: jibLinedata });
                }




                break;


            case "serviceMonth":

                jibLinedata[0].serviceMonth = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "serviceYear":

                jibLinedata[0].serviceYear = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "site":

                jibLinedata[0].site = fieldValue.value
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "spendCategory":

                jibLinedata[0].spendCategory = fieldValue.value
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;


            case "submit":

                jibLinedata[0].submit = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                console.log("subm")
                console.log(jibLinedata[0])
                //this.validateData();
                break;

            case "supplier":

                jibLinedata[0].supplier = fieldValue.value
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "suppliersInvoiceNumber":

                jibLinedata[0].suppliersInvoiceNumber = fieldValue
                this.setState({ jibLinedata: jibLinedata })
                // this.validateData();
                break;

            case "unitCost":


                if (/^-?\d+\.?\d*$/.test(fieldValue) || fieldValue === '' || fieldValue === null) {
                    jibLinedata[0].unitCost = fieldValue;

                    this.setState({ jibLinedata: jibLinedata }, this.validateDataLocal);
                } else {
                    jibLinedata[0].unitCost = ''
                    this.setState({ jibLinedata: jibLinedata });
                }



                break;

            default:
                break;
        }



    }


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getDetails()


    }
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }






    formatAnyDate = (date) => {
        if (!date || new Date(date).getTime() <= 0) {
            return ''; // Return an empty string if date is null or invalid
        }
        return new Date(date).toLocaleString(); // Format the date as a local string
    };

    render() {
        const { jibLinedata, isLoaded, windowHeight,
            apiError, showBadAlert, showGoodAlert, eibSubmitSupplierInvKey, isddCompanyLoaded, ddCompany, isddSiteLoaded, ddSite } = this.state

        const { isddCostcenterLoaded, ddCostcenter, isddSpendcategoryLoaded, ddsSpendcategory, ddSupplier, isddSupplierLoaded, ddProject, isddProjectLoaded } = this.state;
        const { isLineEditable, jibLogdata, isjibLogdataLoaded, isSubline, showFailedAlert, showWarningAlert, validateDataItem } = this.state;
        const { isHeadLoaded, jibLineHeaddata } = this.state;
        const { isJIBUser, isJIBEdit, isJIBWorkday } = this.state;

        //const gridHeight = (windowHeight * 0.70) + "px"
        const gridHeight = "50vh"
        //const gridHeight = "160px"



        // const createdDateTime = new Date(selJibNo.createdDateTime);
        // const formattedDate = createdDateTime.toISOString().split('T')[0];
        //const status = selJibNo ? selJibNo.status : 'Unknown Status';
        const isreadOnly = isJIBUser;



        if (!isLoaded) {
            return (<div>Loading details.....</div>)
        }
        if (!isddCompanyLoaded || !isddSiteLoaded || !isddCostcenterLoaded || !isddSpendcategoryLoaded || !isddSupplierLoaded || !isddProjectLoaded || !isjibLogdataLoaded || !isHeadLoaded) {
            return (<div>Loading.....</div>)
        }



        // const isSpecialStatus = jibHeaderdata[0].status && (jibHeaderdata[0].status === 'Failed' || jibHeaderdata[0].status.toLowerCase().startsWith('eib ready'));
        // const isLineFailed = selEibLine && (selEibLine.status === 'Failed');


        return (
            <div>

                {/* <div>

<p>Is JIB User: {isJIBUser ? 'Yes' : 'No'}</p>
<p>Is JIB Edit: {isJIBEdit ? 'Yes' : 'No'}</p>
<p>Is JIB Workday: {isJIBWorkday ? 'Yes' : 'No'}</p>
</div> */}

                <Form onSubmit={e => e.preventDefault()}>
                    <Row form style={{ marginTop: "-10px", marginBottom: "10px" }}>
                        <Col sm={2}>

                            {/* <Button outline
                            color="secondary"
                            className="btn-no-border"
                            style={{ position: 'relative', paddingRight: '35px' }}
                            onClick={() => this.closeForm()}>
                                <img
                                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                                alt="Close"
                                style={{ width: "30px", margin: "0 5px 0 0" }}

                            />
                                Close
                            </Button> */}



                            {/* <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.refresh}
                            disabled={false}
                        >
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }}

                            />
                            Refresh
                        </Button> */}

                            {/* <Button color="primary" outline 
                                    className="float-left"
                                     style={{  margin: "0 5px 0 0" }}
                                onClick={() => this.closeForm()}
                            >Close</Button> */}
                            <Button outline color="secondary"
                                //className="btn-no-border float-left"
                                onClick={(e) => this.closeForm()}
                               // hidden={!isreadOnly}
                            >

                                Close
                            </Button>{' '}
                            <Button outline color="secondary"
                                //className="btn-no-border float-left"
                                onClick={(e) => this.saveDataOLD()}
                                hidden={!isLineEditable || isreadOnly}
                            >
                                <img
                                    src={floppy}
                                    alt="save"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />
                                Save and Close
                            </Button>{' '}

                            {/* <Button outline color="secondary"
                            //className="btn-no-border float-left"
                            onClick={(e) => this.validateData()}
                            hidden={false}
                        >
                            <img
                                src={floppy}
                                alt="save"
                                style={{ width: "20px", margin: "0 5px 0 0" }} />
                            Validate
                        </Button>{' '} */}


                            {/* <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.toggleDetails}
                            disabled={eibSubmitSupplierInvKey < 0 ||  isLoading || !isLineFailed}
                        >
                            <img
                                src={Revise}
                                alt="details"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Edit Line Details 
                        </Button>{' '} */}

                            {/* <Button outline color="danger"
                            className="btn-no-border"
                            onClick={this.deleteHeader}
                            disabled={jibHeaderKey < 0 ||  isLoading || !isSpecialStatus}
                        >
                            <img
                                src={TrashBin}
                                alt="delete"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Delete
                        </Button>{' '} */}







                        </Col>


                        <Col>
                            <FormGroup style={{ marginTop: "-2px", marginBottom: "-20px", padding: "0px" }}>

                                <row form>
                                    <div>

                                        {showFailedAlert &&
                                            <Alert 
                                            color={showWarningAlert ? "warning" : "danger"} 
                                            isOpen={showFailedAlert} 
                                            toggle={this.onDismissFailedAlert}>
                                                {jibLinedata[0].comments}
                                            </Alert>
                                        }



                                    </div>
                                    <div>

                                        {showBadAlert &&
                                            <Alert color="danger" isOpen={showBadAlert} toggle={this.onDismissAlert}>
                                                {apiError}
                                            </Alert>
                                        }
                                        {showGoodAlert &&
                                            <Alert color="success" isOpen={showGoodAlert} toggle={this.onDismissAlert}>
                                                validated ok
                                                {/* : {JSON.stringify(apiResponse)} */}
                                            </Alert>
                                        }


                                    </div>

                                </row>
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* <row form>
                    <div>

                        {showFailedAlert &&
                            <Alert color="danger" isOpen={showFailedAlert} toggle={this.onDismissFailedAlert}>
                                {jibLinedata[0].comments}
                            </Alert>
                        }
                        


                    </div>
                    <div>

                        {showBadAlert &&
                            <Alert color="danger" isOpen={showBadAlert} toggle={this.onDismissAlert}>
                                {apiError}
                            </Alert>
                        }
                        {showGoodAlert &&
                            <Alert color="success" isOpen={showGoodAlert} toggle={this.onDismissAlert}>
                                validated ok                               
                            </Alert>
                        }


                    </div>

                </row> */}


                    <ColoredLine color="grey" />



                    <FormGroup className="mt-4" >
                        <Row>

                            <Col md={3}>
                                <Label for="sheetName">Excel Sheet</Label>
                                <Input type="text" name="sheetName"
                                    id="sheetName"
                                    disabled={true}
                                    value={jibLinedata[0].sheetName}

                                />

                            </Col>


                            <Col md={3}>
                                <Label for="originalOrder">Excel Row No</Label>
                                <Input type="text" name="originalOrder"
                                    id="originalOrder"
                                    disabled={true}
                                    value={jibLinedata[0].originalOrder}

                                />



                            </Col>

                            <Col md={3}>
                                <Label for="sheetToPdf">Excel Sheet To Pdf</Label>
                                <Input type="text" name="sheetToPdf"
                                    id="sheetToPdf"
                                    disabled={true}
                                    value={isSubline ? jibLineHeaddata[0].sheetToPdf : jibLinedata[0].sheetToPdf}

                                />



                            </Col>
                            <Col md={3}>
                                <Label for="supplier_Invoice_Reference_ID">Supplier_Invoice_Reference_ID</Label>
                                <Input type="text" name="supplier_Invoice_Reference_ID"
                                    id="supplier_Invoice_Reference_ID"
                                    disabled={true}
                                    value={isSubline ? jibLineHeaddata[0].supplier_Invoice_Reference_ID : jibLinedata[0].supplier_Invoice_Reference_ID}


                                />

                            </Col>


                        </Row>

                        <Row>


                            <Col md={12}>
                                <Label for="comments">Comments</Label>
                                <Input type="textarea"
                                    rows={1}
                                    name="comments"
                                    id="comments"
                                    disabled={true}
                                    //value={isSubline ? jibLineHeaddata[0].comments : jibLinedata[0].comments}
                                    //value={(isSubline ? jibLineHeaddata[0].comments : jibLinedata[0].comments).replace(/\r?\n|\r/g, ' ')}
                                    value={(isSubline ? jibLineHeaddata[0].comments : jibLinedata[0].comments)?.replace(/\r?\n|\r/g, ' ') ?? ''}



                                />

                            </Col>



                        </Row>

                        <Row >

                            <Col md={3}>
                                <Label for="spreadsheetKey">Spreadsheet Key</Label>

                                <Input type="text" name="spreadsheetKey"
                                    id="spreadsheetKey"
                                    disabled={true}
                                    value={jibLinedata[0].spreadsheetKey}

                                />


                            </Col>

                            <Col md={3}>
                                <Label for="rowID">Row ID</Label>

                                <Input type="rowID" name="rowID"
                                    id="rowID"
                                    disabled={true}
                                    value={jibLinedata[0].rowID}

                                />



                            </Col>

                            <Col md={3}>
                                <Label for="submit">Submit</Label>
                                <Input type="text" name="submit"
                                    id="submit"
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                    onChange={(event) => this.onTextChange("submit", event.target.value, event)}

                                    value={isSubline ? jibLineHeaddata[0].submit : jibLinedata[0].submit}


                                />

                            </Col>

                            <Col md={3}>
                                <Label for="lockedInWorkday">Locked In Workday</Label>
                                <Input type="text" name="lockedInWorkday"
                                    id="lockedInWorkday"
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                    onChange={(event) => this.onTextChange("lockedInWorkday", event.target.value, event)}
                                    value={isSubline ? jibLineHeaddata[0].lockedInWorkday : jibLinedata[0].lockedInWorkday}



                                />

                            </Col>





                        </Row>





                        <Row >

                            <Col md={3}>
                                <Label for="company">Company</Label>

                                {/* <Input type="text" name="company"
    id="company"
    disabled={false}
    value={jibLinedata[0].company}

/> */}
                                <Select options={ddCompany}
                                    isDisabled={!isLineEditable || isSubline || isreadOnly}
                                    name={"ddCompany"}
                                    className="alway-in-between-more-more"
                                    //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                    //value={this.getSelddCompany(jibLinedata[0].company)}
                                    //value={isSubline?jibLineHeaddata[0].company:jibLinedata[0].company}
                                    value={this.getSelddCompany(isSubline ? jibLineHeaddata[0].company : jibLinedata[0].company)}
                                    //value={this.getSelddCompany(jibLineHeaddata[0].company)}

                                    //value={this.getSelddCompany(isSubline?jibLineHeaddata[0].company:jibLinedata[0].company)}



                                    onChange={(e) => this.onTextChange("company", e, e)}
                                    isClearable={false}

                                >
                                </Select>


                            </Col>




                            <Col md={3}>
                                <Label for="currency">Currency</Label>
                                <Input type="text" name="currency"
                                    id="currency"
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                    onChange={(event) => this.onTextChange("currency", event.target.value, event)}
                                    value={isSubline ? jibLineHeaddata[0].currency : jibLinedata[0].currency}


                                />

                            </Col>

                            <Col md={3}>
                                <Label for="supplier">Supplier</Label>
                                {/* <Input type="text" name="supplier"
                                id="supplier"
                                disabled={false}
                                onChange={(event) => this.onTextChange("supplier", event.target.value, event)}
                                value={jibLinedata[0].supplier}

                            /> */}

                                <Select options={ddSupplier}
                                    isDisabled={!isLineEditable || isSubline || isreadOnly}
                                    name={"ddSupplier"}
                                    //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                    //value={this.getSelddSupplier(jibLinedata[0].supplier)}
                                    value={this.getSelddSupplier(isSubline ? jibLineHeaddata[0].supplier : jibLinedata[0].supplier)}

                                    onChange={(e) => this.onTextChange("supplier", e, e)}
                                    isClearable={false}

                                >
                                </Select>

                            </Col>

                            <Col md={3}>
                                <Label for="invoiceDate">Invoice Date</Label>

                                <DatePicker

                                    value={isSubline ? jibLineHeaddata[0].invoiceDate : jibLinedata[0].invoiceDate}

                                    //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                                    onChange={(v, f) => this.onTextChange("invoiceDate", v, f)}
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                />


                            </Col>

                        </Row>




                        <Row >

                            <Col md={3}>


                                <Label for="project">Project</Label>
                                {/* <Input type="text" name="project"
                                id="project"
                                disabled={false}
                                onChange={(event) => this.onTextChange("project", event.target.value, event)}
                                value={jibLinedata[0].project}
                            /> */}

                                <Select options={ddProject}
                                    isDisabled={!isLineEditable || isreadOnly}
                                    name={"ddProject"}
                                    className="alway-in-between-more"
                                    //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                    value={this.getSelddProject(jibLinedata[0].project)}
                                    defaultValue={this.getSelddProject(jibLinedata[0].project)}
                                    onChange={(e) => this.onTextChange("project", e, e)}
                                    isClearable={true}

                                >
                                </Select>

                            </Col>

                            <Col md={3}>
                                <Label for="controlAmount">Control Amount</Label>
                                <Input type="text" name="controlAmount"
                                    id="controlAmount"
                                    disabled
                                    // disabled={!isLineEditable || isSubline}
                                    // onChange={(event) => this.onTextChange("controlAmount", event.target.value, event)}

                                    value={isSubline ? jibLineHeaddata[0].controlAmount : jibLinedata[0].controlAmount}


                                />


                            </Col>




                            <Col md={3}>
                                <Label for="suppliersInvoiceNumber">Suppliers Invoice Number</Label>
                                <Input type="text" name="suppliersInvoiceNumber"
                                    id="suppliersInvoiceNumber"
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                    onChange={(event) => this.onTextChange("suppliersInvoiceNumber", event.target.value, event)}
                                    value={isSubline ? jibLineHeaddata[0].suppliersInvoiceNumber : jibLinedata[0].suppliersInvoiceNumber}



                                />

                            </Col>

                            <Col md={3}>
                                <Label for="invoiceAccountingDate">Invoice Accounting Date</Label>

                                <DatePicker

                                    value={isSubline ? jibLineHeaddata[0].invoiceAccountingDate : jibLinedata[0].invoiceAccountingDate}

                                    //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                                    onChange={(v, f) => this.onTextChange("invoiceAccountingDate", v, f)}
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                />


                            </Col>

                        </Row>







                        <Row >

                            <Col md={3}>
                                <Label for="site">Site</Label>
                                {/* <Input type="site" name="site"
                                id="site"
                                disabled={false}
                                value={jibLinedata[0].site}

                            /> */}


                                <Select options={ddSite}
                                    isDisabled={!isLineEditable || isreadOnly}
                                    name={"ddSite"}
                                    className="alway-in-between3"
                                    //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                    //className={("form-error")}
                                    value={this.getSelddSite(jibLinedata[0].site)}
                                    defaultValue={this.getSelddSite(jibLinedata[0].site)}
                                    onChange={(e) => this.onTextChange("site", e, e)}
                                    isClearable={false}

                                >
                                </Select>

                            </Col>


                            <Col md={3}>
                                <Label for="quantity">Quantity</Label>
                                <Input type="text" name="quantity"
                                    id="quantity"
                                    disabled={!isLineEditable || isreadOnly}
                                    className={(!validateDataItem.quantity ? "form-error" : "")}
                                    onChange={(event) => this.onTextChange("quantity", event.target.value, event)}

                                    value={jibLinedata[0].quantity}

                                />



                            </Col>


                            <Col md={3}>
                                <Label for="memo">Memo</Label>
                                <Input type="textarea"
                                    rows={1}
                                    name="memo"
                                    id="memo"
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                    onChange={(event) => this.onTextChange("memo", event.target.value, event)}


                                    value={isSubline ? jibLineHeaddata[0].memo : jibLinedata[0].memo}


                                />

                            </Col>

                            <Col md={3}>
                                <Label for="invoiceReceivedDate">Invoice Received Date</Label>
                                <DatePicker

                                    value={isSubline ? jibLineHeaddata[0].invoiceReceivedDate : jibLinedata[0].invoiceReceivedDate}
                                    //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                                    onChange={(v, f) => this.onTextChange("invoiceReceivedDate", v, f)}
                                    disabled={!isLineEditable || isSubline || isreadOnly}
                                />

                            </Col>



                        </Row>




                        <Row >

                            <Col md={3}>
                                <Label for="costCenter">Cost Center</Label>

                                {/* <Input type="costCenter" name="costCenter"
                                id="costCenter"
                                disabled={false}
                                value={jibLinedata[0].costCenter}

                            /> */}

                                <Select options={ddCostcenter}
                                    isDisabled={!isLineEditable || isreadOnly}
                                    name={"ddCostcenter"}
                                    className="alway-in-between2"
                                    //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                    value={this.getSelddCostcenter(jibLinedata[0].costCenter)}
                                    defaultValue={this.getSelddCostcenter(jibLinedata[0].costCenter)}
                                    onChange={(e) => this.onTextChange("costCenter", e, e)}
                                    isClearable={false}

                                >
                                </Select>


                            </Col>


                            <Col md={3}>
                                <Label for="unitCost">Unit Cost</Label>

                                <Input type="unitCost" name="unitCost"
                                    id="unitCost"
                                    disabled={!isLineEditable || isreadOnly}
                                    onChange={(event) => this.onTextChange("unitCost", event.target.value, event)}
                                    className={(!validateDataItem.unitCost ? "form-error" : "")}
                                    value={jibLinedata[0].unitCost}

                                />


                            </Col>



                            <Col md={3}>

                                <Label for="intercompanyAffiliate">Intercompany Affiliate</Label>
                                <Input type="intercompanyAffiliate" name="intercompanyAffiliate"
                                    id="intercompanyAffiliate"
                                    disabled={!isLineEditable || isreadOnly}
                                    onChange={(event) => this.onTextChange("intercompanyAffiliate", event.target.value, event)}
                                    value={jibLinedata[0].intercompanyAffiliate}

                                />


                            </Col>

                            <Col md={3}>
                                <Label for="serviceMonth">Service Month</Label>
                                <Input type="text" name="serviceMonth"
                                    id="serviceMonth"
                                    disabled={!isLineEditable || isreadOnly}
                                    onChange={(event) => this.onTextChange("serviceMonth", event.target.value, event)}

                                    value={jibLinedata[0].serviceMonth}

                                />

                            </Col>


                        </Row>



                        <Row >

                            <Col md={3}>
                                <Label for="spendCategory">Spend Category</Label>
                                {/* <Input type="text" name="spendCategory"
                                id="spendCategory"
                                disabled={false}
                                value={jibLinedata[0].spendCategory}

                            /> */}

                                <Select options={ddsSpendcategory}
                                    isDisabled={!isLineEditable || isreadOnly}
                                    name={"ddsSpendcategory"}
                                    className="alway-in-between"
                                    //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                    value={this.getSelddSpendcategory(jibLinedata[0].spendCategory)}
                                    defaultValue={this.getSelddSpendcategory(jibLinedata[0].spendCategory)}
                                    onChange={(e) => this.onTextChange("spendCategory", e, e)}
                                    isClearable={false}

                                >
                                </Select>

                            </Col>

                            <Col md={3}>
                                <Label for="extendedAmount">Extended Amount</Label>
                                <Input type="extendedAmount" name="extendedAmount"
                                    id="extendedAmount"
                                    disabled={!isLineEditable || isreadOnly}
                                    className={(!validateDataItem.extendedAmount ? "form-error" : "")}
                                    onChange={(event) => this.onTextChange("extendedAmount", event.target.value, event)}

                                    value={jibLinedata[0].extendedAmount}

                                />

                            </Col>


                            <Col md={3}>
                                <Label for="memo2">Memo2</Label>
                                <Input type="textarea"
                                    rows={1}
                                    name="memo2"
                                    id="memo2"
                                    disabled={!isLineEditable || isreadOnly}
                                    onChange={(event) => this.onTextChange("memo2", event.target.value, event)}

                                    value={jibLinedata[0].memo2}

                                />

                            </Col>



                            <Col md={3}>
                                <Label for="serviceYear">Service Year</Label>
                                <Input type="text" name="serviceYear"
                                    id="serviceYear"
                                    disabled={!isLineEditable || isreadOnly}
                                    onChange={(event) => this.onTextChange("serviceYear", event.target.value, event)}

                                    value={jibLinedata[0].serviceYear}

                                />

                            </Col>






                        </Row>

                        <Row hidden>

                            <Col md={3}>
                                <Label for="intercompanyAffiliate2">Intercompany Affiliate2</Label>

                                <Input type="intercompanyAffiliate2" name="intercompanyAffiliate2"
                                    id="intercompanyAffiliate2"
                                    disabled={!isLineEditable || isreadOnly}
                                    value={jibLinedata[0].intercompanyAffiliate2}

                                />


                            </Col>

                            <Col md={3}>
                                <Label for="rowID2">rowID2</Label>
                                <Input type="rowID2" name="rowID2"
                                    id="rowID2"
                                    disabled={!isLineEditable || isreadOnly}
                                    value={jibLinedata[0].rowID2}

                                />

                            </Col>

                        </Row>

                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="font-weight-bold">Attached files</Label>
                            </Col>




                        </Row>
                        <Row>
                            <Col>
                                <Col md={12}>
                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                        buttonColor="primary"
                                        formName="JIB line Documents"
                                        linkTableName={"tapEIBSubmitSupplierInv"}
                                        linkPrimaryKey={eibSubmitSupplierInvKey}
                                        apiURL={apiURL + 'AR/JIBDocuments/' + eibSubmitSupplierInvKey}
                                    />

                                </Col>
                            </Col>

                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col>
                                <Label className="font-weight-bold">JIB Change log</Label>
                            </Col>


                            <Col className="text-right">
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.saveWorkbook}
                                    disabled={jibLogdata.length === 0}
                                >

                                    <img
                                        src={Excel}
                                        alt="excel"
                                        style={{ width: "44px", height: "22px", margin: "0 0 0 0" }}
                                    />
                                    Export Log To Excel
                                </Button>
                            </Col>

                        </Row>
                    </FormGroup>


                    <FormGroup>

                        <IgrDataGrid
                            ref={this.onGridRef}
                            // className="alway-on-bottom"
                            cellTextStyle="14px Calibri"
                            headerTextStyle="14px Calibri"
                            rowHeight={"25"}
                            height={gridHeight}
                            dataSource={jibLogdata}

                            // cellClicked={this.onCellClicked}

                            autoGenerateColumns="false"
                            selectionMode="SingleRow"

                        // selectedKeysChanged={this.onGridRowSelected}
                        //width={"100%"}
                        // isColumnOptionsEnabled="true"
                        // onCellDataBound={this.onCellDataBound}

                        //useAccessibility="true"

                        // isHeaderSeparatorVisible="true"
                        // editMode={0}


                        >

                            <IgrTextColumn field="createdBy"
                                headerText="CreatedBy" width="300" />
                            <IgrTextColumn field="columnName"
                                headerText="ColumnName" width="300" />

                            <IgrTextColumn field="oldValue"
                                headerText="OldValue" width="300" />

                            <IgrTextColumn field="newValue"
                                headerText="NewValue" width="300" />

                            <IgrTextColumn field="createdDatetime"
                                headerText="Created" width=">*300"

                            />


                            <IgrNumericColumn field="PrimaryKey" isHidden />




                        </IgrDataGrid>

                    </FormGroup>




                </Form>



            </div>)
    }


}