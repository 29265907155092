import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import MenuIcon from '../../resources/menu.png';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Revise from '../../resources/revise.png'
import Excel from '../../resources/excel.svg'
import '../../custom.css';
import { ExcelUtility } from '../../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt, addDays } from '../../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { ST_TrueFalseBlank, Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import ConfirmModal from '../../operation/ConfirmModal'
import ConfirmWithMsgModal from '../../resources/ConfirmWithMsgModal'

import { EaseOfMovementIndicator } from 'igniteui-react-charts';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


var DatePicker = require("reactstrap-date-picker");

export default class R9ScadaTickets extends Component {
    static displayName = R9ScadaTickets.name;

    constructor(props) {
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        //this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            modal: false,
            r9Tickets: [],
            isLoaded: false,
            startDate: getFirstDayofMonth().toISOString(),
            endDate: addDays(Date(), 1).toISOString(),
            windowHeight: window.innerHeight,

        }
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            //console.log(dataItem.invoiceBatchkey)

            // this.setState({
            //     invoiceBatchkey: dataItem.invoiceBatchkey,
            //     selInvBatch: dataItem,
            //     batchStatusKey: dataItem.batchStatusKey
            // })

        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.r9Tickets;
    }


    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
        // console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue
        });

    }


    getR9Tickets = () => {
        const { startDate, endDate } = this.state
        let myURI = apiURL + 'AR/R9Tickets/startdate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName;
        console.log(myURI);


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    r9Tickets: json
                })
            });

    }

    componentDidMount() {
        this.getR9Tickets()
    }

    render() {
        const { isLoaded, r9Tickets } = this.state

        if (!isLoaded) {
            return (<div>
                <h3>Loading R9 Ticket Data.....</h3>
            </div>)
        }

        return (<div>
            {/* <h2> R9 Tickets |</h2> */}
            <FormGroup>
                <Row >
                    <Col md={"auto"}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                            Start Date
                        </Label>
                        <DatePicker value={this.state.startDate}
                            onChange={(v, f) => this.handleChange(v, f)} />
                    </Col>
                    <Col md={"auto"}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                            End Date
                        </Label>
                        <DatePicker value={this.state.endDate}
                            onChange={(v, f) => this.handleEndDateChange(v, f)} />

                    </Col>

                    <Col>
                        <Row style={{ marginTop: "20px" }}>
                            <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getR9Tickets}>
                                <img
                                    src={Refresh}
                                    alt="Refresh"
                                    style={{ width: "30px", margin: "0 5px 0 0" }} />
                                Refresh
                            </Button>
                            <Button outline color="secondary"
                                className="btn-no-border"
                                onClick={this.saveWorkbook}>
                                <img
                                    src={Excel}
                                    alt="excel"
                                    style={{ width: "50px", margin: "0 0 0 0" }} />
                                Export To Excel
                            </Button>{' '}
                        </Row>
                    </Col>

                </Row>
            </FormGroup>
            <FormGroup>
                <IgrDataGrid
                    form style={{ zIndex: 0, position: 'relative' }}
                    ref={this.onGridRef}
                    height={"78vh"}
                    dataSource={r9Tickets}
                    // dataSource={searchText.length>0 ? safetyObs.filter( rec =>  ((rec.safetyObservationNo || '').toLowerCase().includes(searchText)) ||
                    //                                     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.qhseReportTypeDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.hazardDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.solutionDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.createdByName || '').toLowerCase().includes(searchText) ||       
                    //                                     (rec.managerName || '').toLowerCase().includes(searchText) ||                                                      
                    //                                     (rec.locationName || '').toLowerCase().includes(searchText))
                    //                                     :safetyObs


                    //                                 }
                    autoGenerateColumns="false"
                    selectionMode="SingleRow"
                //selectedKeysChanged={this.onGridRowSelected}
                // reactsToFilterChanges="true"
                //filterExpressionsChanged={this.onGridfilterExpressionsChanged}
                //cellBackground={"#ffbfbf"}
                >


                    <IgrTextColumn field="invoiceNo" headerText="Invoice No " width="*>190"
                        cellUpdating={this.onStatusCellUpdating}
                    />
                    <IgrTextColumn field="billLoadTypeDesc" headerText="Bill Load Type" width="*>120"
                        cellUpdating={this.onStatusCellUpdating}
                    />
                    <IgrTextColumn field="washoutTypeDesc" headerText="Washout Type"
                        width="*>140"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="facility" headerText="Facility"
                        width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="ticketID" headerText="Ticket ID"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="startDateText" headerText="Start Date"
                        width="*>200"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="endDateText" headerText="End Date"
                        width="*>200"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="serviceCompany" headerText="Service Company"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="driver" headerText="Driver"
                        width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="truckNo" headerText="Truck No"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="svrTicketNo" headerText="Service Ticket No"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="sourceOp1" headerText="Opearator 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="sourceOp1No" headerText="Opearator No 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="sourceName1" headerText="Source Name 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="source1No" headerText="Source No 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="well1" headerText="Well 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="api1" headerText="API 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="wasteType1" headerText="Waste Type 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="sourceTank1" headerText="Source Tank 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="grossTotal1" headerText="Gross Total 1"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="bsw" headerText="BSW"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="netOil" headerText="Net Oil"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="rcrAexcempt" headerText="RCRA Exmpt"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="microroentgen" headerText="Microroentgen"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="sourceOp2" headerText="Opearator 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="sourceOp2No" headerText="Opearator No 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="sourceName2" headerText="Source Name 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="source2No" headerText="Source No 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="well2" headerText="Well 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="api2" headerText="API 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="wasteType2" headerText="Waste Type 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="sourceTank2" headerText="Source Tank 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="grossTotal2" headerText="Gross Total 2"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="sourceOp3" headerText="Opearator 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="sourceOp3No" headerText="Opearator No 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="sourceName3" headerText="Source Name 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="source3No" headerText="Source No 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="well3" headerText="Well 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="api3" headerText="API 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="wasteType3" headerText="Waste Type 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="sourceTank3" headerText="Source Tank 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="grossTotal3" headerText="Gross Total 3"
                        width="*>180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrNumericColumn field="rs_id" isHidden />
                </IgrDataGrid>
            </FormGroup>
        </div>)
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems


        if (gridDts.length > 0) {


            const headers = Object.keys(gridDts[0]);
            headers.pop();
            const wb = new Workbook(WorkbookFormat.Excel2007);
            const ws = wb.worksheets().add("Sheet1");

            for (let i = 0; i < headers.length; i++) {
                for (let j = 0; j < actCols.count; j++) {
                    if (headers[i] == actCols.item(j).field) {
                        ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                        if ((actCols.item(j).headerText || "") === "") {
                            ws.rows(0).cells(j).value = actCols.item(j).field
                        }
                    }
                }

            }

            for (let i = 0; i < gridDts.length; i++) {
                const dataRow = gridDts[i];
                const xlRow = ws.rows(i + 1);
                for (let j = 0; j < headers.length; j++) {
                    for (let k = 0; k < actCols.count; k++) {

                        if (headers[j] == actCols.item(k).field) {
                            xlRow.setCellValue(k, dataRow[headers[j]]);
                        }

                    }

                    //xlRow.setCellValue(j, dataRow[headers[j]]);                
                }
            }

            ExcelUtility.save(wb, "R9Tickets");
        }
    }

}