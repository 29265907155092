//Modified by BN232208 
import React, { Component, useState } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  Card,
  CardBody,
  CardText,
  CardHeader,
  CardFooter,
  CardDeck,
  CardColumns,
  UncontrolledTooltip,
} from "reactstrap";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormFeedback,
  Alert,
} from "reactstrap";
import "../../custom.css";
import {
  apiURL,
  apiPOSTHeader,
  apiPUTHeader,
  apiDELETEHeader,
} from "../../resources/apiURL";
import ConfirmModal from "../../operation/ConfirmModal";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Plus from "../../resources/plus.svg";
import Refresh from "../../resources/refresh.svg";
import MenuIcon from "../../resources/menu.png";
import Trash from "../../resources/trash.svg";
import TrashBin from "../../resources/trash.svg";
import Select from "react-select";
import Email from "../../resources/email.png";
import { getUserADAccount, getRandomInt } from "../../resources/utility";
import classnames from "classnames";
import DocumentBrowser from "../../resources/DocumentBrowser";
import NumberFormat from "react-number-format";

import { ColoredLine, getFirstDayofMonth } from "../../resources/utility";

import { IgrDataGrid } from "igniteui-react-grids";
import {
  SummaryOperand,
  SummaryCalculator,
  DefaultSummaryResult,
  IDataSource,
  ISummaryResult,
} from "igniteui-react-core";
import { IgrDataGridModule } from "igniteui-react-grids";
import { IgrTextColumn } from "igniteui-react-grids";
import { IgrNumericColumn } from "igniteui-react-grids";
import { IgrGridColumnOptionsModule } from "igniteui-react-grids";
import { IgrDateTimeColumn } from "igniteui-react-grids";
import { IgrColumnSummaryDescription } from "igniteui-react-grids";

import { IgrTemplateColumn } from "igniteui-react-grids";
import { getLCP } from "web-vitals";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class MOCTask extends Component {
  static displayName = MOCTask.name;

  constructor(props) {
    super(props);
    this.onGridRef = this.onGridRef.bind(this);
    this.onCellUpdating = this.onCellUpdating.bind(this);
    this.onGridRowSelected = this.onGridRowSelected.bind(this);
    //   this.rowDragStart= this.onRowDragStart(this);
    //  this.rowDragEnd= this.onRowDragEnd(this);
    //   this.rowDrop= this.onRowDrop(this);

    this.state = {
      user: props.user,
      readOnly: props.readOnly,
      mocKey: props.mocKey,
      mocComment: [],
      isWOCommentLoaded: false,
      modalComment: false,
      cmtDate: new Date().toISOString(),
      mocCommentKey: -1,
      comments: "",
      isMOCApprover: props.isMOCApprover,
      isApproverCreatedBy: false,
      mocCommentStatusList: props.mocCommentStatusList,
      statusKey: -1,
      mocCommentStatusDesc: "",
      mocUserList: props.mocUserList,
      assignedTo: "",
      employeeKey: null,
      mocStatusKey: props.mocStatusKey,
      mocData: props.mocData,
      isWorkInProgress: false,
      selectedRowIndex: null,
      position: 0,
      // fiixWOId: props.fiixWOId
      validDataItem: {
        assignedTo: true,
        comments: true,
      },
    };
  }

  onGridRef(grid) {
    if (!grid) {
      return;
    }
    this.grid = grid;
    this.grid.dataSource = this.state.mocComment;
  }

  onRowDragStart = (e) => {
    e.effectAllowed = "move";
    const draggedRecord = this.state.mocComment[e.dragIndex];
    // Set the dragged record as the selected record
    this.setState({
      selectedRowIndex: e.dragIndex,
    });
  };

  onRowDragEnd = (e) => {
    const data = e.dragData.data;
    // Clear the selected record when dragging ends
    this.setState({
      selectedRowIndex: null,
    });
  };

  onRowDrop = (e) => {
    const { mocComment } = this.state;
    const draggedRecord = mocComment[e.dragIndex];
    const targetRecord = mocComment[e.dropIndex];
    // Reorder the records in the data source
    mocComment.splice(e.dragIndex, 1);
    mocComment.splice(e.dropIndex, 0, draggedRecord);
    this.setState({
      mocComment: mocComment,
    });
  };

  dataValidation = () => {
    return new Promise((resolve, reject) => {
      const { assignedTo, comments, validDataItem } = this.state;
      const returnVal = { ok: true, message: "", headerValidation: [] };

      if (assignedTo === "" || assignedTo === null) {
        validDataItem.assignedTo = false;
        returnVal.ok = false;
        returnVal.message = "Please enter missing data in red";
      }

      if (comments.trim() === "" || comments === null) {
        validDataItem.comments = false;
        returnVal.ok = false;
        returnVal.message = "Please enter missing data in red";
      }

      returnVal.headerValidation = validDataItem;

      resolve(returnVal);
    });
  };

  toggleOK = async () => {
    try {
      await this.saveMOCComment(); // Wait for saveMOCComment() to finish

      // Uncomment this section if you want to use it.
      // this.setState({
      //   modal: !this.state.modal,
      //   comments: ""
      // });

      await Promise.all([
        this.props.refreshData(),
        this.props.refreshDataMOCKey((updatedMOCData) => {
          // Callback function to receive the updated mocData

          const { mocData } = this.props; // Access mocData from props, which should be updated now

          if (mocData[0].statusKey === 6) {
            this.closeForm(); // Will run only after saveMOCComment() and setState are done
            this.props.handleShowAlert(
              mocData[0].mocNumber +
                " has been completed (All task(s) have been completed and/or canceled)."
            );
          }
        }),
      ]);
    } catch (error) {
      // Handle any errors that occurred during saveMOCComment() or refreshData() or refreshDataMOCKey()
      console.error("Error while saving MOC comment:", error);
    }
  };

  createMOCCommentLineForUpdateDTO = () => {
    const {
      mocCommentKey,
      comments,
      user,
      statusKey,
      assignedTo,
      employeeKey,
    } = this.state;
    const opsCmtDTO = {
      mocCommentKey: mocCommentKey,
      statusKey: statusKey,
      comments: comments,
      modifiedBy: getUserADAccount(user.account.userName),
      assignedTo: assignedTo,
      employeeKey: employeeKey,
    };
    return opsCmtDTO;
  };

  updateData = () => {
    const uptDTO = this.createMOCCommentLineForUpdateDTO();
    let myURI = apiURL + "MOC/Task";

    fetch(myURI, apiPUTHeader(uptDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          //return Promise.reject(error);
          return false;
        }
        this.refreshComment();
      })
      .catch((error) => {
        return false;
      });

    return true;
  };
  //   createMOCCommentForCreateDTO = () => {
  //     const { mocKey, cmtDate, comments, user } = this.state;

  //     const afeCmtDTO = {
  //       commentTypeKey: 7, //MOC Task
  //       compObjectKey: mocKey, //selectedValue.key,
  //       facilityKey: null,
  //       commentDate: cmtDate,
  //       comments: comments,
  //       createdBy: getUserADAccount(user.account.userName),
  //       // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
  //       // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
  //     };
  //     return afeCmtDTO;
  //   };

  createMOCCommentLineForCreateDTO = () => {
    const {
      mocKey,
      cmtDate,
      comments,
      user,
      statusKey,
      assignedTo,
      employeeKey,
    } = this.state;

    const mocCommentLineCreateDTO = {
      MOCKey: mocKey,
      StatusKey: statusKey,
      Comments: comments,
      CreatedBy: getUserADAccount(user.account.userName),
      AssignedTo: assignedTo,
      EmployeeKey: employeeKey,
    };
    return mocCommentLineCreateDTO;
  };

  saveMOCComment = () => {
    const { mocCommentKey } = this.state;
    this.dataValidation().then((data) => {
      if (data.ok) {
        if (mocCommentKey > 0) {
          if (this.updateData()) {
            this.setState({ mocCommentKey: -1, modal: !this.state.modal });
          }
        } else {
          const addDTO = this.createMOCCommentLineForCreateDTO();
          let myURI = apiURL + "MOC/Task";
          fetch(myURI, apiPOSTHeader(addDTO))
            .then(async (response) => {
              const rspData = response.text();
              if (!response.ok) {
                const error = (rspData && rspData.message) || response.status;
                //return Promise.reject(error);
                return false;
              }
              this.refreshComment();
              this.setState({
                modal: !this.state.modal,
                comments: "",
              });
            })
            .catch((error) => {
              return false;
            });
        }
      } else {
        this.setState({
          alertMessage: data.message,
          validDataItem: data.headerValidation,
          showAlert: true,
          // mocData: mocData
        });
      }
    });
  };

  saveTaskNoRefresh = () => {
    const { mocCommentKey } = this.state;
    this.dataValidation().then((data) => {
      if (data.ok) {
        const addDTO = this.createMOCCommentLineForCreateDTO();
        let myURI = apiURL + "MOC/Task";
        fetch(myURI, apiPOSTHeader(addDTO))
          .then(async (response) => {
            const rspData = response.text();
            if (!response.ok) {
              const error = (rspData && rspData.message) || response.status;
              //return Promise.reject(error);
              return false;
            }
            this.refreshComment();
          })
          .catch((error) => {
            return false;
          });
      }
    });
  };

  // saveAutoTask=()=>{
  //   const {user, mocUserList} = this.props
  //   let userInfo = mocUserList.filter(a=> a.emailAddress.toLowerCase() === getUserADAccount(user.account.userName).toLowerCase())

  //   this.setState({
  //     assginedTo: getUserADAccount(user.account.userName).toLowerCase().replace(".", " "),
  //     employeeKey: userInfo[0].employeeKey
  //   },()=>{
  //     this.saveMOCComment();
  //   })
  // }

  saveAutoTask = () => {
    const { user, mocUserList } = this.props;
    const adAccount = getUserADAccount(user.account.userName);
    let userInfo = mocUserList.filter(
      (item) => item.adAccount.toLowerCase() === adAccount.toLowerCase()
    );
    // console.log(adAccount)

    // console.log(userInfo)
    //   MOCKey: mocKey,
    // StatusKey: statusKey,
    // Comments: comments,
    // CreatedBy: getUserADAccount(user.account.userName),
    // AssignedTo: assignedTo,
    // EmployeeKey: employeeKey,

    this.setState(
      {
        mocCommentKey: -1,
        assignedTo: adAccount.toLowerCase().replace(".", " "),
        employeeKey: userInfo[0].employeeKey,
        comments: "N/A",
        statusKey: 2,
      },
      () => {
        this.saveTaskNoRefresh();
      }
    );
  };

  // saveAutoTask = () => {
  //   const { user, mocUserList } = this.props;
  //   const userEmailAddress = getUserADAccount(user.account.userName);

  //   let userInfo = mocUserList.filter((item) => item.emailAddress === userEmailAddress);

  //   this.setState({
  //     assginedTo: userEmailAddress.toLowerCase().replace(".", " "),
  //     employeeKey: userInfo.length > 0 ? userInfo.employeeKey : null
  //   }, () => {
  //     this.saveMOCComment();
  //   });
  // }

  mocMOCTask = () => {
    const { mocCommentKey } = this.state;
    this.dataValidation().then((data) => {
      if (data.ok) {
        if (mocCommentKey > 0) {
          if (this.updateData()) {
            this.setState({ mocCommentKey: -1, modal: !this.state.modal });
          }
        } else {
          const addDTO = this.createMOCCommentLineForCreateDTO();
          let myURI = apiURL + "MOC/Task";
          fetch(myURI, apiPOSTHeader(addDTO))
            .then(async (response) => {
              const rspData = response.text();
              if (!response.ok) {
                const error = (rspData && rspData.message) || response.status;
                //return Promise.reject(error);
                return false;
              }
              this.refreshComment();
              this.setState({
                modal: !this.state.modal,
                comments: "",
              });
            })
            .catch((error) => {
              return false;
            });
        }
      } else {
        this.setState({
          alertMessage: data.message,
          validDataItem: data.headerValidation,
          showAlert: true,
          // mocData: mocData
        });
      }
    });
  };

  deleteMOCComment = () => {
    const meterValActionDeleteDTO = this.createMOCCommentDeleteLineDTO();

    let myURI = apiURL + "moc/Task";
    fetch(myURI, apiDELETEHeader(meterValActionDeleteDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          return Promise.reject(error);
        }
        this.refreshComment();
      })
      .catch((error) => {});
  };

  createMOCCommentDeleteLineDTO = () => {
    const { mocCommentKey, user } = this.state;

    const meterValActionDeleteDTO = {
      mocCommentKey: mocCommentKey,
      deletedBy: getUserADAccount(user.account.userName),
    };
    return meterValActionDeleteDTO;
  };

  onTextChange = (fieldName, fieldValue, e) => {
    const { comments, validDataItem } = this.state;
    validDataItem.comments = true;
    this.setState({ comments: fieldValue });
  };

  //   onTextChange2 = (fieldName, fieldValue, e) => {
  //     const {mocCommentStatusDesc, statusKey} = this.state
  //     switch (fieldName) {
  //         case "status":
  //             statusKey = e === null ? "" : e.value;
  //             mocCommentStatusDesc = e === null ? "" : e.mocCommentStatusDesc;

  //             break;

  //     }

  //     this.setState({ statusKey: statusKey, mocCommentStatusDesc: mocCommentStatusDesc});

  //   }

  onTextChange2 = (fieldName, fieldValue, e) => {
    const { user } = this.props;
    let {
      mocCommentStatusDesc,
      statusKey,
      assignedTo,
      employeeKey,
      validDataItem,
    } = this.state;
    switch (fieldName) {
      case "status":
        statusKey = e === null ? "" : e.value;
        mocCommentStatusDesc = e === null ? "" : e.mocCommentStatusDesc;
        break;
      case "assignedTo":
        assignedTo = e === null ? "" : e.empName;
        employeeKey = e === null ? "" : e.value;
        validDataItem.assignedTo = true;
        // console.log(e.empName)
        break;

      // Add more cases for other field names if needed
    }

    // Update the state with the modified values
    this.setState({
      mocCommentStatusDesc,
      statusKey,
      assignedTo,
      employeeKey,
      validDataItem,
    });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  newComment = () => {
    const { mocComment } = this.state;
    const { user } = this.props;
    const fullName = getUserADAccount(user.account.userName);
    const modifiedFullName = fullName
      .toLowerCase()
      .replace(".", " ")
      .replace(/\b\w/g, (c) => c.toUpperCase());

    // console.log(modifiedFullName);
    this.setState({
      comments: "",
      mocCommentKey: -1,
      statusKey: 4,
      mocCommentStatusDesc: "Initiated",
      assignedTo: modifiedFullName,
      employeeKey: null,
      //   mocComment: mocComment
    });
    this.toggle();
  };

  newTaskMoveUp = () => {
    const uptDTO = this.createMoveTaskForUpdateDTO();
    let myURI = apiURL + "MOC/MoveTaskUp";

    fetch(myURI, apiPUTHeader(uptDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          //return Promise.reject(error);
          return false;
        }
        this.refreshComment();
      })
      .catch((error) => {
        return false;
      });

    this.setState({ mocCommentKey: -1 });

    return true;
  };

  newTaskMoveDown = () => {
    const uptDTO = this.createMoveTaskForUpdateDTO();
    let myURI = apiURL + "MOC/MoveTaskDown";

    fetch(myURI, apiPUTHeader(uptDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          //return Promise.reject(error);
          return false;
        }
        this.refreshComment();
      })
      .catch((error) => {
        return false;
      });
    this.setState({ mocCommentKey: -1 });

    return true;
  };

  createMoveTaskForUpdateDTO = () => {
    const { mocCommentKey, mocKey } = this.state;
    const moveUpDTO = {
      mocKey: mocKey,
      mocCommentKey: mocCommentKey,
    };
    return moveUpDTO;
  };

  onGridRowSelected(s, e) {
    const { user } = this.state;
    //const content = e.content;
    if (e.addedKeys.count > 0) {
      let clickedRow = e.addedKeys.item(0);
      let dataItem = clickedRow.value[0];
      this.setState({
        mocCommentKey: dataItem.mocCommentKey,
        comments: dataItem.comments,
        statusKey: dataItem.statusKey,
        mocCommentStatusDesc: dataItem.mocCommentStatusDesc,
        assignedTo: dataItem.assignedTo,
        employeeKey: dataItem.employeeKey,
        // cmtDate: dataItem.commentDate,
        // mocCommentKey: dataItem.mocCommentKey,
      });
      if (dataItem.createdBy !== null || dataItem.createdBy !== "") {
        this.isApproverTaskCreator(dataItem.createdBy.toLowerCase());
      }
    }
  }

  isApproverTaskCreator = (createdBy) => {
    const { user, isApproverCreatedBy, isMOCApprover } = this.state;

    if (createdBy !== null && createdBy !== "") {
      // console.log(createdBy)
      // console.log((getUserADAccount(user.account.userName).toLowerCase()).replace(".", " "))
      // console.log(createdBy === (getUserADAccount(user.account.userName).toLowerCase()).replace(".", " "))
      if (createdBy === getUserADAccount(user.account.userName).toLowerCase()) {
        this.setState({ isApproverCreatedBy: true });
      } else {
        this.setState({ isApproverCreatedBy: false });
      }
    }
    // else{
    //     this.setState({isApproverCreatedBy: false})
    // }
  };

  editComment = (key, date, text) => {
    this.toggle();
  };

  refreshComment = () => {
    // this.getMOCComment();
    this.getMOCCommentLine();
    this.setState({ mocCommentKey: -1 });
  };

  //   getMOCComment = () => {
  //     const { mocKey } = this.state;
  //     let myURI = apiURL + "MOC/Task/" + mocKey;

  //     fetch(myURI, { modes: "no-cors" })
  //       .then((res) => res.json())
  //       .then((json) => {
  //         this.setState({
  //           mocComment: json,
  //           isLoaded: true,
  //         });
  //       });
  //   };

  getMOCCommentLine = () => {
    const { mocKey, mocCommentKey } = this.state;
    const { mocComment } = this.state;
    let myURI = apiURL + "MOC/Task/" + mocKey;
    fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          mocComment: json,
          isLoaded: true,
        });
      });
  };

  // getMOCCommentLine = () => {
  //   const { mocKey } = this.state;
  //   let myURI = apiURL + "MOC/Task/" + mocKey;

  //   return fetch(myURI, { modes: "no-cors" })
  //     .then((res) => res.json())
  //     .then((json) => {
  //       const isWorkInProgress = json.some(item => item.statusKey === 1);

  //       this.setState({
  //         mocComment: json,
  //         isLoaded: true,
  //         isWorkInProgress: isWorkInProgress
  //       });
  //     });
  // };

  //   createComment = () => {
  //     const { user } = this.props;
  //     const newWORecord = {
  //       mocCommentKey: -1,
  //       statusKey: 0,
  //       comments: "",
  //       createdBy: user.account.name
  //     };
  //     return [newWORecord];
  //   };

  handleChange = (v, f) => {
    this.setState({
      cmtDate: v,
    });
  };

  componentDidMount() {
    // this.getMOCComment();
    this.getMOCCommentLine();
  }

  onCellUpdating(s, e) {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
    let link;
    if (content.childElementCount === 0) {
      link = document.createElement("label");
      let cell = document.createElement("div");

      content.style.fontFamily = "Verdana";
      content.style.fontSize = "13px";

      content.style.margin = "0px -20px -10px -12px";
      content.style.padding = "0px 10px 10px 10px";
      //content.style.width = "100%";

      content.appendChild(link);
    } else {
      link = content.children[0];

      if (item.statusKey === 3) {
        //content.style.background = "#f0f5b0";
        content.style.background = "#fc4e42";
      } else if (item.statusKey === 2) {
        content.style.background = "#b8f2b1";
      } else if (item.statusKey === 1) {
        content.style.background = "#f0f5b0";
      } else {
        content.style.background = "transparent";
      }
    }

    switch (s.field) {
      case "mocCommentLineNo":
        link.textContent = item.mocCommentLineNo;
        break;
      case "createdDatetime":
        //   var date = new Date(item.createdDatetime);
        //   link.textContent = date.toLocaleDateString() + " " + date.toLocaleTimeString();
        var date = new Date(item.createdDatetime);
        var localOffset = date.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
        var localDate = new Date(date.getTime() - localOffset).toLocaleString();
        link.textContent = localDate;
        break;

      case "modifiedDatetime":
        if (item.modifiedDatetime !== null) {
          // var date2 = new Date(item.modifiedDatetime);
          // link.textContent = date2.toLocaleDateString() + " " + date2.toLocaleTimeString();

          var date2 = new Date(item.modifiedDatetime);
          var localOffset = date2.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
          var localDate2 = new Date(
            date2.getTime() - localOffset
          ).toLocaleString();
          link.textContent = localDate2;
        }
        break;

      case "mocCommentStatusDesc":
        link.textContent = item.mocCommentStatusDesc;
        break;

      case "createdBy":
        link.textContent = item.createdBy.replace(".", " ");
        break;

      case "modifiedBy":
        if (item.modifiedBy !== null) {
          link.textContent = item.modifiedBy.replace(".", " ");
        }
        break;

      case "assignedTo":
        if (item.assignedTo !== null) {
          link.textContent = item.assignedTo;
        }
        break;

      default:
        break;
    }
  }

  getSelMOCCommentStatus = (value) => {
    const { mocCommentStatusList } = this.state;

    if (value !== null && value !== "") {
      return mocCommentStatusList.filter(
        (e) => e.mocCommentStatusDesc === value
      );
    }
    return "";
  };

  getSelMOCUser = (value) => {
    const { mocUserList } = this.state;

    if (value !== null && value !== "") {
      return mocUserList.filter(
        (e) => e.empName.toLowerCase() === value.toLowerCase()
      );
    }
    return "";
  };

  sendRemiderEmail = () => {
    const newAFEEmailDTO = this.emailMOCDTO();

    let myURI = apiURL + "MOC/ResendMOCTaskEmail";
    fetch(myURI, apiPUTHeader(newAFEEmailDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {});
  };

  emailMOCDTO = () => {
    const { mocKey, selLineActionKey, employeeKey } = this.state;
    const newMOCEmailDTO = {
      mocKey: mocKey,
      employeeKey: employeeKey,
      // "ActionLineKeys": null
    };
    return newMOCEmailDTO;
  };

  filterStatusList = (mocCommentStatusList) => {
    return mocCommentStatusList.filter(
      (status) =>
        status.mocCommentStatusDesc === "Initiated" ||
        status.mocCommentStatusDesc === "Completed"
    );
  };

  filterStatusList2 = (mocCommentStatusList) => {
    return mocCommentStatusList.filter(
      (status) =>
        status.mocCommentStatusDesc === "Canceled" ||
        status.mocCommentStatusDesc === "Completed"
      // ||
      // status.mocCommentStatusDesc === "Work in Progress"
    );
  };

  filterStatusList3 = (mocCommentStatusList) => {
    return mocCommentStatusList.filter(
      (status) =>
        status.mocCommentStatusDesc === "Initiated" ||
        status.mocCommentStatusDesc === "Canceled" ||
        status.mocCommentStatusDesc === "Completed"
    );
  };

  // mocData[0].approvedUser.includes(getUserADAccount(user.account.userName).toLowerCase() + "@h2obridge.com")

  // closeForm = () => {
  //   this.props.refreshData()
  //   this.props.toggle();
  // };
  closeForm = async () => {
    try {
      await this.props.refreshData(); // Wait for refreshData() to finish
      this.props.toggle(); // Only call toggle() after refreshData() finishes successfully
    } catch (error) {
      // Handle any errors that occurred during refreshData()
      console.error("Error while refreshing data:", error);
    }
  };

  render() {
    const {
      user,
      mocComment,
      isLoaded,
      isFormReadOnly,
      mocCommentKey,
      comments,
      isMOCApprover,
      isApproverCreatedBy,
      mocCommentStatusList,
      mocData,
      mocCommentStatusDesc,
      mocUserList,
      assignedTo,
      mocStatusKey,
      validDataItem,
    } = this.state;

    const filteredStatusList = this.filterStatusList(mocCommentStatusList);
    const filteredStatusList2 = this.filterStatusList2(mocCommentStatusList);
    const filteredStatusList3 = this.filterStatusList3(mocCommentStatusList);
    if (!isLoaded) {
      return <div>Loading Comment ......</div>;
    } else
      return (
        <div>
          <Row
            form
            style={{
              marginTop: "-15px",
              marginBottom: "5px",
              alignItems: "flex-start",
            }}
          >
            <Button
              color="primary"
              outline
              className="float-left"
              style={{ height: "35px" }}
              // disabled={
              //   (!isMOCApprover ||
              //   (mocData.length > 0 &&
              //   mocData[0].approvedUser &&
              //   mocData[0].approvedUser.includes(
              //     getUserADAccount(user.account.userName).toLowerCase() +
              //       "@h2obridge.com"
              //   )))
              // }
              // hidden={console.log(
              //   mocData[0].approvedUser.includes(
              //     getUserADAccount(user.account.userName).toLowerCase() +
              //       "@h2obridge.com"
              //   )
              // )}

              // hidden={console.log(

              //     getUserADAccount(user.account.userName).toLowerCase() +
              //       "@h2obridge.com"

              // )}

              //   hidden={console.log(

              //     isMOCApprover

              // )}

              disabled={
                mocStatusKey === 6 ||
                mocStatusKey === 5 ||
                (mocData[0].length > 0 &&
                  mocData[0].approvedUser.includes(
                    getUserADAccount(user.account.userName).toLowerCase() +
                      "@h2obridge.com"
                  ) === true)
                  ? true
                  : isMOCApprover
                  ? false
                  : true
              }
              // disabled={!isMOCApprover}
              onClick={() => this.newComment()}
            >
              Add
            </Button>

            <Button
              color="primary"
              outline
              className="float-left"
              style={{ height: "35px", marginLeft: "10px" }}
              disabled={
                mocCommentKey < 0 ||
                !isMOCApprover ||
                !isApproverCreatedBy ||
                mocStatusKey === 6 ||
                mocStatusKey === 5
              }
              onClick={() => this.toggle()}
            >
              Edit
            </Button>

            <ConfirmModal
              outline={true}
              color="success"
              buttonLabel="Send Reminder Email"
              style={{ height: "35px", marginLeft: "10px" }}
              formName="ReminderEmail"
              disabled={
                mocCommentKey < 0 || mocStatusKey === 6 || mocStatusKey === 5
              }
              message={"Are you sure you want to send reminder email?"}
              onClickYes={(e) => this.sendRemiderEmail()}
              hidden
              image={
                <img
                  src={Email}
                  // id={afeHeaderKey}
                  alt="Edit"
                  style={{ width: "20px", margin: "0 5px 0 0" }}
                />
              }
            ></ConfirmModal>

            <ConfirmModal
              outline={true}
              color="warning"
              buttonLabel="No Task"
              style={{ height: "35px", marginLeft: "10px" }}
              formName="No Task Submission"
              // disabled={ mocStatusKey === 6 || mocStatusKey === 9}
              disabled={
                mocStatusKey === 6 ||
                mocStatusKey === 5 ||
                (mocData[0].length > 0 &&
                  mocData[0].approvedUser.includes(
                    getUserADAccount(user.account.userName).toLowerCase() +
                      "@h2obridge.com"
                  ) === true)
                  ? true
                  : isMOCApprover
                  ? false
                  : true
              }
              message={"Are you sure you want to add no task?"}
              onClickYes={(e) => this.saveAutoTask()}

            ></ConfirmModal>

            <Button
              color="primary"
              outline
              className="float-left"
              style={{ height: "35px", marginLeft: "10px" }}
              // disabled={ mocStatusKey === 6 || mocStatusKey === 5 || mocData[0].length > 0 && mocData[0].approvedUser.includes(
              //       getUserADAccount(user.account.userName).toLowerCase() +
              //         "@h2obridge.com") === true ? true : isMOCApprover ? false : true

              //       }
              // disabled={!isMOCApprover}
              disabled={mocCommentKey == -1}
              onClick={this.newTaskMoveUp}
            >
              Move Up
            </Button>

            <Button
              color="primary"
              outline
              className="float-left"
              style={{ height: "35px", marginLeft: "10px" }}
              // disabled={ mocStatusKey === 6 || mocStatusKey === 5 || mocData[0].length > 0 && mocData[0].approvedUser.includes(
              //       getUserADAccount(user.account.userName).toLowerCase() +
              //         "@h2obridge.com") === true ? true : isMOCApprover ? false : true

              //       }
              disabled={mocCommentKey == -1}
              onClick={this.newTaskMoveDown}
            >
              Move Down
            </Button>

            <div style={{ marginLeft: "auto" }}>
              <ConfirmModal
                outline={true}
                color="danger"
                className="float-right"
                buttonLabel="Delete"
                disabled={
                  mocCommentKey < 0 ||
                  !isMOCApprover ||
                  !isApproverCreatedBy ||
                  (mocData.length > 0 &&
                    mocData[0].approvedUser &&
                    mocData[0].approvedUser.includes(
                      getUserADAccount(user.account.userName).toLowerCase() +
                        "@h2obridge.com"
                    ))
                }
                formName="Delete"
                message="Are you sure you want to delete selected moc task?"
                onClickYes={(e) => this.deleteMOCComment()}
                image={
                  <img
                    src={TrashBin}
                    alt="Delete"
                    style={{ width: "20px", marginLeft: "-10px" }}
                  />
                }
              ></ConfirmModal>
            </div>
          </Row>

          <ColoredLine color="grey" />

          <FormGroup>
            <IgrDataGrid
              ref={this.onGridRef}
              // height={(afeComment.length > 10 ? "300px" : "100%")}
              width="100%"
              dataSource={mocComment}
              autoGenerateColumns="false"
              selectionMode="SingleRow"
              editModeClickAction="SingleClick"
              editMode={0}
              selectedKeysChanged={this.onGridRowSelected}
              //           rowDraggable={true} // Enable row dragging
              // rowDragStart={this.onRowDragStart} // Event handler for drag start
              // rowDragEnd={this.onRowDragEnd} // Event handler for drag end
              //  rowDrop={this.onRowDrop} // Event handler for row drop
            >
              <IgrTextColumn
                field="orderNo"
                headerText="Order #"
                width="*>110"
              />
              <IgrTemplateColumn
                field="mocCommentLineNo"
                headerText="Task #"
                width="100"
                cellUpdating={this.onCellUpdating}
              />
              <IgrTemplateColumn
                field="createdDatetime"
                headerText="Created Date"
                width="180"
                cellUpdating={this.onCellUpdating}
              />

              <IgrTemplateColumn
                field="modifiedDatetime"
                headerText="Modified Date"
                width="180"
                cellUpdating={this.onCellUpdating}
              />

              <IgrTemplateColumn
                field="mocCommentStatusDesc"
                headerText="Status"
                width="150"
                cellUpdating={this.onCellUpdating}
              />

              <IgrTemplateColumn
                field="createdBy"
                headerText="Created By"
                width="130"
                cellUpdating={this.onCellUpdating}
              />

              <IgrTemplateColumn
                field="modifiedBy"
                headerText="Modified By"
                width="135"
                cellUpdating={this.onCellUpdating}
              />

              <IgrTemplateColumn
                field="assignedTo"
                headerText="Assigned To"
                width="140"
                cellUpdating={this.onCellUpdating}
              />
              <IgrTextColumn
                field="comments"
                headerText="Comments"
                width="*>300"
              />
            </IgrDataGrid>
          </FormGroup>

          <div>
            <Modal
              isOpen={this.state.modal}
              toggle={() => this.toggle()}
              onClosed={() => this.refreshComment()}
              //contentClassName="oilTicketDetail-modal-style"
              size="lg"
            >
              <ModalHeader toggle={() => this.toggle()}> New Task </ModalHeader>
              <ModalBody>
                <FormGroup row>
                  <Label for="commentDate" sm={2}>
                    Date
                  </Label>
                  <Col sm={10}>
                    <DatePicker
                      value={this.state.cmtDate}
                      id="commentDate"
                      onChange={(v, f) => this.handleChange(v, f)}
                      disabled={true}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="exampleText2" sm={2}>
                    Status
                  </Label>
                  <Col sm={4}>
                    {/* <Select
                          options={mocStatusKey === 4 ?  filteredStatusList2 : mocData[0].approvedUser.includes(getUserADAccount(user.account.userName).toLowerCase() + "@h2obridge.com") ? filteredStatusList3  : mocStatusKey === 8 || mocStatusKey === 3 ? filteredStatusList : mocStatusKey === 4 ? filteredStatusList2 : mocCommentStatusList}

                          //  isDisabled={mocCommentKey < 0}
                          isDisabled={(mocStatusKey === 0 || mocStatusKey === 8 || mocStatusKey === 3) && !mocData[0].approvedUser.includes(getUserADAccount(user.account.userName).toLowerCase() + "@h2obridge.com")}
                          value={this.getSelMOCCommentStatus(mocCommentStatusDesc)}
                          onChange={(e) => this.onTextChange2("status", e, e)}
                          placeholder={""}
                          isClearable={true}
                        ></Select> */}

                    <Select
                      options={
                        mocStatusKey === 4
                          ? filteredStatusList2
                          : mocData[0].approvedUser &&
                            mocData[0].approvedUser.includes(
                              getUserADAccount(
                                user.account.userName
                              ).toLowerCase() + "@h2obridge.com"
                            )
                          ? filteredStatusList3
                          : mocStatusKey === 8 || mocStatusKey === 3
                          ? filteredStatusList
                          : mocStatusKey === 4
                          ? filteredStatusList2
                          : mocCommentStatusList
                      }
                      // isDisabled={console.log(

                      //   mocData[0].approvedUser && mocData[0].approvedUser.includes(
                      //   getUserADAccount(
                      //     user.account.userName
                      //   ).toLowerCase() + "@h2obridge.com"
                      // )
                      // )}
                      // isFormReadOnly={console.log(mocStatusKey)}
                      // isDisabled={
                      //   (mocStatusKey === 0 ||
                      //     mocStatusKey === 8 ||
                      //     mocStatusKey === 3) &&
                      //   (!mocData[0].approvedUser ||
                      //     !mocData[0].approvedUser.includes(
                      //       getUserADAccount(
                      //         user.account.userName
                      //       ).toLowerCase() + "@h2obridge.com"
                      //     ))
                      // }
                      // isDisabled={
                      //   // (mocStatusKey === 0 ||
                      //   //   mocStatusKey === 8 ||
                      //   //   mocStatusKey === 3) &&
                      //   // (!(mocData.length > 0 && mocData[0].approvedUser) ||
                      //     (
                      //       mocData.length > 0 &&
                      //       (mocData[0].approvedUser.includes(
                      //         getUserADAccount(
                      //           user.account.userName
                      //         ).toLowerCase() + "@h2obridge.com"
                      //       ))
                      //     )
                      //     // console.log(mocData[0].approvedUser)

                      // }

                      value={this.getSelMOCCommentStatus(mocCommentStatusDesc)}
                      onChange={(e) => this.onTextChange2("status", e, e)}
                      placeholder={""}
                      isClearable={true}
                    ></Select>
                  </Col>

                  <Label for="exampleText2" sm={2}>
                    Assigned To
                  </Label>

                  <Col sm={4}>
                    <Select
                      options={mocUserList}
                      //   isDisabled={this.statusFieldReadOnly()}
                      className={
                        !validDataItem.assignedTo
                          ? "alway-on-top-more-more form-error"
                          : "alway-on-top-more-more"
                      }
                      // className={console.log(validDataItem.assignedTo)}
                      value={this.getSelMOCUser(assignedTo)}
                      onChange={(e) => this.onTextChange2("assignedTo", e, e)}
                      placeholder={""}
                      isClearable={true}
                    ></Select>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="exampleText" sm={2}>
                    Description
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="textarea"
                      name="text"
                      id="exampleText"
                      rows="6"
                      className={
                        !validDataItem.comments
                          ? "form-error"
                          : "alway-on-top-more"
                      }
                      defaultValue={comments}
                      value={comments}
                      // invalid={comments === ""}
                      onChange={(e) =>
                        this.onTextChange("comments", e.target.value, e)
                      }
                    />
                    <FormFeedback tooltip>
                      {""}
                      Comment can not be blank
                    </FormFeedback>
                  </Col>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.toggleOK()}>
                  OK
                </Button>
                <Button color="primary" onClick={() => this.toggle()}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
  }
}
