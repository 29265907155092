import React, { Component } from 'react';
import { Container,CustomInput, Col, Row, Button, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText, Form } from 'reactstrap';
import { IgrMultiColumnComboBoxModule } from 'igniteui-react-grids';
import { IgrMultiColumnComboBox, SortMode } from 'igniteui-react-grids';
import TankVolume from './TankVolume';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { apiURL } from '../resources/apiURL';


import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { ExcelUtility } from './ExcelUtility';
import MenuIcon from '../resources/menu.png';
IgrMultiColumnComboBoxModule.register();
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();



export default class TankList extends Component {

    constructor(props) {
        super(props);
        
        this.onSortChanged = () => {
            if (this.pager) {
                this.pager.applySorts(this.grid.sortDescriptions);
            }
        };
        this.onGroupChanged = () => {
            if (this.pager) {
                this.pager.applyGroups(this.grid.groupDescriptions);
            }
        };
        this.onFilterChanged = () => {
            if (this.pager) {
                this.pager.applyFilters(this.grid.filterExpressions);
            }
        };
        
        this.onFacilityComboRef = this.onFacilityComboRef.bind(this);
        this.onBasinComboRef = this.onBasinComboRef.bind(this);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state={
            tankList:[],
            isLoaded:false,           
            selectedTankKey: -10,
            selectedTankName: "",
            refreshTankVol:false,
            windowHeight:window.innerHeight,
            modal:false,
            showAllFacility:false
        }
    }

    onLoadStatusChange = (value,e) =>{
        this.getTankList(!this.state.showAllFacility)
        this.setState({showAllFacility:!this.state.showAllFacility})
       
    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
                
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            console.log(dataItem)
            
            this.setState({selectedTankKey: dataItem.tankKey,
                            selectedTankName: dataItem.tankName})       
            this.toggle()
            //this.onVolRefresh()    
        } 
    }

    onFacilityComboRef(multiColumnComboBox) {
        if (!multiColumnComboBox) {
            return;
        }
        this.onFacilityComboRef = multiColumnComboBox;
        //this.multiColumnComboBox.dataSource = this.countryData;
        //this.multiColumnComboBox.textField = "country";
        this.onFacilityComboRef.placeholder = "Choose a Facility";
        this.onFacilityComboRef.sortMode = SortMode.SortByOneColumnOnly;
    }

    onBasinComboRef(multiColumnComboBox) {
        if (!multiColumnComboBox) {
            return;
        }
        this.onBasinComboRef = multiColumnComboBox;
        //this.multiColumnComboBox.dataSource = this.countryData;
        //this.multiColumnComboBox.textField = "country";
        this.onBasinComboRef.placeholder = "Choose a Basin";
        this.onBasinComboRef.sortMode = SortMode.SortByOneColumnOnly;
    }

  

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.tankList;
      
    }

    freshTankData = () => {
        const {showAllFacility} = this.state
        this.getTankList (showAllFacility)
    }
    
    getTankList = (showAllFacility=false) => {
        let myURI
        if (showAllFacility){
            myURI = apiURL + 'Tanks/AzureAD/' +  this.props.user.account.userName;
       
        }else{
            myURI = apiURL + 'Tanks/MyFacility/AzureAD/' +  this.props.user.account.userName;
        }
       
        fetch(myURI, { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
            this.setState({
                tankList: json,
                isLoaded: true
            })
            // if (!isNaN(this.state.tankKey)) {
            //     console.log("TankKey: " + this.state.tankKey)
            //     this.setState({
            //         tankVol: json.filter(vols => vols.tankKey == this.state.tankKey),
            //         isLoad: true
            //     })
            // } else {
            //     this.setState({
            //         tankVol: json,
            //         isLoad: true
            //     })
            // }
        });
    }

    onVolRefresh(){
        console.log('tank vold refresh')
        this.setState({
            refreshTankVol:!this.state.refreshTankVol
        })
    }

    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getTankList();
    }

    render() {
        const {tankList,isLoaded,selectedTankKey,showAllFacility,
            selectedTankName,windowHeight} = this.state;
        console.log("Selected TankKey: " + selectedTankKey);
        const gridHeight=(windowHeight * 0.25) + "px"
        const searchText = window.$searchText.toLowerCase()

        if (!isLoaded){
            return(<div>
                <h3> Loading Facility Tank .....</h3>
            </div>)
        }else
        return (
            
            <div>
                <FormGroup>
                    <Row>
                    <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.freshTankData}>
                            <img src={MenuIcon}
                                 alt="Refresh"
                                 style={{width: "30px", margin:"0 0 0 0"}}/>
                            Refresh
                        </Button>

                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.saveWorkbook}>
                            <img src={MenuIcon}
                                 alt="excel"
                                 style={{width:"30px", margin:"0 0 0 0"}}/>
                            Export to Excel
                        </Button>

                        <FormGroup  style={{margin:"10px 0 0 100px"}} >
                        <h6>
                        <CustomInput className="float-right" 
                                    type="switch" 
                                    id="accept" 
                                    name="accept" 
                                    label="Show All Facilities"                                   
                                    checked={showAllFacility} 
                                    onClick={(e) => this.onLoadStatusChange(1,e)} />
                                    </h6>
                                    </FormGroup>
                    </Row>
                </FormGroup>

                
                <IgrDataGrid
                    ref={this.onGridRef}
                    height="300px" //"calc(100% - 100 px)"
                    width="100%"
                    dataSource={tankList.filter(rec => ((rec.facilityID || '').toLowerCase().includes(searchText)) ||
                                                (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                (rec.tankID || '').toLowerCase().includes(searchText) ||
                                                (rec.tankName || '').toLowerCase().includes(searchText) ||
                                                (rec.tankTypeDesc || '').toLowerCase().includes(searchText) ||
                                                (rec.qrCodeValue || '').toLowerCase().includes(searchText) ||      
                                                (rec.tankSerialNo || '').toLowerCase().includes(searchText) ||                                                
                                                (rec.areaName || '').toLowerCase().includes(searchText) ||
                                                (rec.companyName || '').toLowerCase().includes(searchText) ||                                                            
                                                (rec.comments || '').toLowerCase().includes(searchText) )}
                    autoGenerateColumns="false"
                    sortDescriptionsChanged={this.onSortChanged}
                    groupDescriptionsChanged={this.onGroupChanged}
                    filterExpressionsChanged={this.onFilterChanged}
                    selectedKeysChanged={this.onGridRowSelected}                    
                    defaultColumnMinWidth="100"
                    summaryScope="Root"
                    isColumnOptionsEnabled="true"
                    columnMovingSeparatorWidth="2"
                    selectionMode="SingleRow">
                    <IgrTextColumn field="facilityID" headerText="Facility ID" width="*>100" />
                    <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>150" />
                    <IgrTextColumn field="tankID" headerText="Tank ID" width="*>120" />
                    <IgrTextColumn field="tankName" headerText="Tank Name" width="*>190" />
                    <IgrTextColumn field="tankTypeDesc" headerText="Tank Type" width="*>100" />                    
                    <IgrNumericColumn field="tankHeight" headerText="Height" width="*>90" />
                    <IgrNumericColumn field="tankWidth" headerText="Width" width="*>90" />
                    <IgrNumericColumn field="tankSize" headerText="Size" width="*>90" />
                    <IgrDateTimeColumn field="createdDate" headerText="Created Date" width="*>120" />
                    <IgrNumericColumn field="qrCodeValue" headerText="QRCode" width="*>60" />
                    <IgrTextColumn field="tankSerialNo" headerText="Serial No" width="*>120" />                    
                    <IgrTextColumn field="comments" headerText="Comments" width="*>250" />
                    <IgrTextColumn field="tankKey" isHidden="true" />
                    
                </IgrDataGrid>
                {/* <h1>Selected Tank Key {selectedTankKey}</h1> */}
                <FormGroup>
                    <TankVolume  VoldHeaderLabel={selectedTankName + " Volumes"}
                                 TankKey={selectedTankKey} 
                                 StartDate={getFirstDayofMonth().toISOString()} 
                                 EndDate={new Date().toISOString()}
                                 GridFilter={false}
                                 hideAllFacilitySwitch={true}
                                 user={this.props.user}></TankVolume>
                </FormGroup>
            </div>
        );
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "TankList");
    }
}
