
import React, { Component, ReactDOM } from 'react';

import { Form, Card, CardHeader, CardBody, CustomInput, Container, Col, Row, Button, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup } from 'reactstrap';
import { apiURL, apiHeader } from '../../resources/apiURL';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

// import MenuIcon from '../resources/menu.png';
import '../../custom.css';
import classnames from 'classnames';
// import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
// import FetchData from '../../resources/FetchData';
//import InspectionItem from './InspectionItem';
// import { CT_NonVisualGroupDrawingShapeProps } from 'igniteui-react-excel';
// import { IFunctionFilterExpression_$type, TemplateSectionHeaderCellInfoDescription } from 'igniteui-react-core';
import { getUserADAccount } from '../../resources/utility'
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
// import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrGridCellValueChangingEventArgs } from 'igniteui-react-grids';
// import { IgrGridRowEditEndedEventArgs } from 'igniteui-react-grids';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


// import iconplus from '../resources/plus.svg'
import icontrash from '../../resources/trash.svg'
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import Select from 'react-select';
//var moment = require('moment-business-days');

var DatePicker = require("reactstrap-date-picker");

//import ConfirmModal from './ConfirmModal'




export default class FormFirstReport extends Component {
  static displayName = FormFirstReport.name;


  constructor(props) {
    super(props);
    this.onGridRef = this.onGridRef.bind(this);
    // this.saveData = this.saveData.bind(this);
    // this.getAlert=this.getAlert.bind(this);


    // //const urlPara = this.getURLParameters(props.location)
    console.log("loc search_____________first report")


    // console.log(this.props.location.search)

    this.state = {
      childValue: '',
      data: [],
      hdrData: [],
      isLoaded: false,
      isHdrLoaded: false,
      tblWitness: [],
      // facilityInspLineItemForUpdateDTO:[],
      // facilityComments:'',
      // inspComments:'',
      reloadForm: false,
      activeTab: '1',
      safIncHeaderKey: 0,
      safIncLineKey: props.safIncLineKey,
      ddEmployees: [],
      isddEmployeesLoaded: false,
      txtnewWitnessName: '',
      txtnewPosition: '',
      // user:props.user
      isReadOnly: props.isReadOnly,

      modalMsg: false,
      msgHeader: "",
      msgBody: "",
    }



  }



  toggleMsg = () => {
    this.setState({ modalMsg: !this.state.modalMsg })
}

  // getURLParameters = (inputPara) => {
  //   var paraMeters = {
  //     safIncLineKey: null
  //   }




  //   var query
  //   // query = new URLSearchParams(this.props.location.search);
  //   console.log("query")
  //   // console.log(query.get('safIncLineKey'))

  //   paraMeters = {
  //     safIncLineKey: query.get('safIncLineKey')
  //   }
  //   return paraMeters
  // }


  // isAllowEdit = (hdrData) => {
  //   if (hdrData.statusKey === 6){
  //     return false
  //   }else{return true}
  // }







  // reOpen = () => {
  //   const {hdrData} = this.state;
  //   hdrData[0].statusKey = 4
  //   //this.saveData()
  //   this.setState({reloadForm:!this.state.reloadForm})
  // }

  createEmpList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.employeeKey
      e.label = e.empName
      tempList.push(e)
    })

    return tempList
  }


  getSelEmp = (v) => {
    const { ddEmployees } = this.state
    if (v !== null && v !== "" && !isNaN(v)) {
      return ddEmployees.filter(e => e.employeeKey === Number(v));
    }
    return ""

  }

  bMultiField = (value, checkValue) => {
    return (value & checkValue) === checkValue
  }

  intBusinessDays = () => {
    const {  data } = this.state

  //   moment.updateLocale('us', {
  //     workingWeekdays: [1, 2, 3 ]
  //  });

if (data[0].returntoWorkDateTime != null && data[0].incDateTime != null)
{
 // var diff = moment('05-08-2017', 'MM-DD-YYYY').businessDiff(moment('05-15-2017','MM-DD-YYYY'),false);
// var datea = moment(data[0].ReturntoWorkDateTime, 'MM-DD-YYYY');
var datea= moment(data[0].returntoWorkDateTime).format('MM-DD-YYYY')
var dateb= moment(data[0].incDateTime).format('MM-DD-YYYY')

//console.log(moment('4-12-2022', 'DD-MM-YYYY').isBusinessDay())


// console.log(datea);
// console.log(dateb);

var diff2 = moment(datea, 'MM-DD-YYYY').diff(moment(dateb,'MM-DD-YYYY'), 'days')-1;
if (diff2<0) {diff2=0}
// if (moment(data[0].incDateTime).isAfter(data[0].returntoWorkDateTime))
// {
//   diff2=0;
// }

// console.log(diff2);

data[0].incOutofOfficeDays=diff2
  return diff2
 
}
else{data[0].incOutofOfficeDays=0}

  }

  intBusinessDaysRestriction = () => {
    const {  data } = this.state

  //   moment.updateLocale('us', {
  //     workingWeekdays: [1, 2, 3 ]
  //  });

if (data[0].returntoWorkDateWithNoRestrictions != null && data[0].incDateTime != null)
{
 // var diff = moment('05-08-2017', 'MM-DD-YYYY').businessDiff(moment('05-15-2017','MM-DD-YYYY'),false);
// var datea = moment(data[0].ReturntoWorkDateTime, 'MM-DD-YYYY');
var datea= moment(data[0].returntoWorkDateWithNoRestrictions).format('MM-DD-YYYY')
var dateb= moment(data[0].incDateTime).format('MM-DD-YYYY')

//console.log(moment('4-12-2022', 'DD-MM-YYYY').isBusinessDay())


// console.log(datea);
// console.log(dateb);

var diff2 = moment(datea, 'MM-DD-YYYY').diff(moment(dateb,'MM-DD-YYYY'), 'days')-1;
if (diff2<0) {diff2=0}
// if (moment(data[0].incDateTime).isAfter(data[0].returntoWorkDateTime))
// {
//   diff2=0;
// }

// console.log(diff2);

data[0].incJobRestrictionDays=diff2
  return diff2
 
}
else{data[0].incJobRestrictionDays=0}

  }


  getInspHdrData = (intKey) => {
    const { isLoaded } = this.state

    console.log("safIncHeaderKey")
    console.log(intKey)

    let myURI = apiURL + 'SafetyIncident/SafIncHeaderKey/' + intKey
    fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        this.setState({
          hdrData: json,
          isHdrLoaded: true
        });
        console.log(this.state.hdrData);
      });

  }

  getInpsData = () => {
    const { safIncLineKey, isLoaded } = this.state
    let myURI = apiURL + 'SafetyIncident/FormFirstReportKey/' + safIncLineKey

    fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        // if (json.length > 0) {
        //   this.getInspHdrData(json[0].safIncHeaderKey)
        //   this.getWitness()
        // }

        this.setState({
          data: json,
          isLoaded: true,

          //safIncHeaderKey:json[0].safIncHeaderKey
          // inspComments:json[0].inspComments,
          // facInspComments:json[0].facInspComments
        }
        );


        console.log("---------")
        console.log(this.state.data)

        this.getInspHdrData(json[0].safIncHeaderKey)
        this.getWitness()

      });
  }


  getddEmployees = () => {
    const { user } = this.props

    //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

    let myURI = apiURL + 'SafetyObservation/SubmitBy'

    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isddEmployeesLoaded: true,
          ddEmployees: json
        })
      });
  }



  getWitness = () => {
    //console.log(this.state.startDate);
    const { facilityKeys, safetyType,
      fromParamStartDate, fromParamEndDate, data } = this.state
    // console.log("safetyType");
    // console.log(safetyType);

    // let myURI = apiURL + 'SafetyObservation/startdate/' + 
    //                       this.state.startDate.toString().substring(0, 10) + 
    //                       '/enddate/' + this.state.endDate.toString().substring(0, 10) 
    //                       + '/AzureAD/' + this.props.user.account.userName;

    if (data.length > 0) {
      let myURI = apiURL + 'SafetyIncident/FormFirstWitnessKey/' + data[0].formFirstReportKey
      // + this.state.startDate.toString().substring(0, 10) + '/enddate/' + this.state.endDate.toString().substring(0, 10) ;
      // let myURI = apiURL + 'SafetyIncident/GetSafIncHeader';                              
      console.log(myURI);

      fetch(myURI, { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
          this.setState({
            //isLoaded: true,
            // safetyInc:  json.filter(rec => ((safetyType===null ? true : 
            //                                (rec.safetyTypeKey == safetyType))) &&
            //                                ((fromParamStartDate===null && fromParamStartDate===null) ? true : 
            //                                 (rec.createdDatetime >= fromParamStartDate && rec.createdDatetime <= fromParamEndDate)))
            //                 .sort((a, b) => ((a.statusKey==5 ? 3 : a.statusKey) > (b.statusKey==5 ? 3 : b.statusKey)) ? 1:-1)                   
            // safetyInc:  json.filter(rec => ((this.state.startDate===null && this.state.endDate===null) ? true : 
            //                                 (rec.createdDatetime >= this.state.startDate && rec.createdDatetime <= this.state.endDate)))
            //safetyInc:  json.filter(rec => (rec.SafIncHeaderKey >= 2))                
            tblWitness: json
          })
        });
    }

  }

  onGridRef(grid) {
    if (!grid) {
      return;
    }
    this.grid = grid;
    this.grid.dataSource = this.state.tblWitness;

    //this.grid.cells[0]
    console.log(this.grid.cellBackground)

  }



  componentDidMount() {
    this.props.saveFirst(this.saveData);
    this.getInpsData()
    this.getddEmployees()
    //this.getInspHdrData()

  }


  onStatusCellUpdating(s, e) {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
    // content.style.backgroundColor = "#4286f4";

    // console.log("item.statusKey")
    // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
    let link;
    if (content.childElementCount === 0) {
      link = document.createElement("label");
      let cell = document.createElement("div");

      content.style.fontFamily = "Verdana";
      content.style.fontSize = "13px";

      content.style.margin = "0px -20px -10px -12px";
      content.style.padding = "0px 10px 10px 10px";
      //content.style.width = "100%";

      content.appendChild(link);
    }
    else {
      link = content.children[0];

      if (item.lineStatusKey === 4) {
        content.style.background = "#b6a065";
      } else if (item.lineStatusKey === 6) {
        content.style.background = "#61a066";
      } else if (item.lineStatusKey === 3 || item.lineStatusKey === 5) {
        content.style.background = "#ad5f55";
      } else {
        content.style.background = "transparent";
      }
    }


    switch (s.field) {
      case 'name':
        link.textContent = item.name;
        break;
      case 'position':
        link.textContent = item.position;
        break;


      default:

    }

  }



  onDeleteCellUpdating = (s, e) => {
    const content = e.content;
    if (content.childElementCount === 0) {
      const button = document.createElement("input");
      // button.innerText = "Delete";
      button.setAttribute('type', 'image');
      button.src = icontrash;
      button.title = "Delete witness info";
      button.width = "12";
      button.height = "12";

      // button.innerHTML = "<img height=12px width=11px src='" + icontrash + "'>";


      button.addEventListener("click", this.onDeleteRowClick);
      // button.addEventListener("click", if(window.confirm('Delete the item?')){this.onDeleteRowClick});

      // button.addEventListener('click', function (e) {
      //   if (window.confirm('Do you confirm?')) {
      //     this.onDeleteRowClick;
      //   } else {
      //     e.preventDefault();
      //   }

      // });


      content.appendChild(button);

      // const img = document.createElement('IMG');
      // img.setAttribute('src', icontrash);
      // // img.setAttribute('class', 'mark');
      // img.addEventListener("click", this.onDeleteRowClick);
      // content.appendChild(img);

    }

    const button = content.children[0];
    // button.disabled = e.cellInfo.isDeleted;
    // button.id = e.cellInfo.dataRow.toString();
    button.id = e.cellInfo.rowItem.formFirstWitnessKey.toString();

  }


  onDeleteRowClick = (e) => {
    const button = e.srcElement;
    const wkey = parseInt(button.id);


    if (window.confirm('Delete line?')) {
      this.deleteWitness(wkey);
      e.preventDefault();
    } else {
      e.preventDefault();
    }

    // if(window.confirm('Delete the item?')) {this.deleteWitness(wkey)};
    // this.deleteWitness(wkey);
    // const rowItem = this.grid.actualDataSource.getItemAtIndex(viewIndex);
    // this.grid.removeItem(rowItem);
    // this.setState({ });

  }



  onTextChange = (fieldName, fieldValue, e) => {
    console.log("props")
    console.log(fieldName + '=' + fieldValue)

    const { data } = this.state;
    var { txtnewPosition, txtnewWitnessName } = this.state;
    // /cbincEquipmentDamage
    //incClassif


    switch (fieldName) {



      case "other":
        data[0].other = fieldValue
        break;

      case "incDateTime":
        // data[0].incDateTime = moment(fieldValue).format();
        data[0].incDateTime = fieldValue;
        this.intBusinessDays();
        this.intBusinessDaysRestriction();
        break;

      case "comments":
        data[0].comments = fieldValue
        break;


      case "incidentType":
        if (this.bMultiField(data[0].incidentType, fieldValue)) {
          data[0].incidentType = data[0].incidentType - fieldValue
        } else {
          data[0].incidentType = data[0].incidentType + fieldValue
        }
        //validateDataItem.riskInvolvePeopleValue=true
        break;


      case "incClassif":
        if (this.bMultiField(data[0].incClassif, fieldValue)) {
          data[0].incClassif = data[0].incClassif - fieldValue
        } else {
          data[0].incClassif = data[0].incClassif + fieldValue
        }
        //validateDataItem.riskInvolvePeopleValue=true
        break;

        case "returntoWorkDateTime":
          data[0].returntoWorkDateTime = fieldValue
          this.intBusinessDays();
          break;
        case "incOutofOfficeDays":
          data[0].incOutofOfficeDays = fieldValue
        break;

        case "returntoWorkDateWithNoRestrictions":
          data[0].returntoWorkDateWithNoRestrictions = fieldValue
          this.intBusinessDaysRestriction();
          break;
        case "incJobRestrictionDays":
          data[0].incJobRestrictionDays = fieldValue
        break;

      //osha
      case "oshaContacted":
        if (fieldValue === data[0].oshaContacted) {
          data[0].oshaContacted = null
        }
        else { data[0].oshaContacted = fieldValue }

        //validateDataItem.riskInvolvePeopleValue=true
        break;


      case "oshaDateTime":
        // data[0].oshaDateTime = moment(fieldValue).format();
        data[0].oshaDateTime = fieldValue;
        break;
      case "oshaByWhom":
        data[0].oshaByWhom = fieldValue
        break;
      case "oshaRep":
        data[0].oshaRep = fieldValue
        break;


      case "empEquipInfoName":
        data[0].empEquipInfoName = fieldValue
        break;
      case "empEquipInfoPosition":
        data[0].empEquipInfoPosition = fieldValue
        break;
      case "empEquipInfoSSN":
        data[0].empEquipInfoSSN = fieldValue
        break;
      case "empEquipInfoEmpID":
        data[0].empEquipInfoEmpID = fieldValue
        break;
      case "empEquipInfoHireDate":
        data[0].empEquipInfoHireDate = fieldValue
        break;
      case "empEquipInfoAddress":
        data[0].empEquipInfoAddress = fieldValue
        break;
      case "empEquipInfoCity":
        data[0].empEquipInfoCity = fieldValue
        break;
      case "empEquipInfoState":
        data[0].empEquipInfoState = fieldValue
        break;
      case "empEquipInfoZIP":
        data[0].empEquipInfoZIP = fieldValue
        break;
      case "empEquipInfoPhone":
        data[0].empEquipInfoPhone = fieldValue
        break;
      case "empEquipInfoDOB":
        data[0].empEquipInfoDOB = fieldValue
        break;
      case "empEquipInfoMaritalStatus":
        data[0].empEquipInfoMaritalStatus = fieldValue
        break;

      case "opLeaseIdWellOperator":
        data[0].opLeaseIdWellOperator = fieldValue
        break;

      case "opLeaseIdLeaseName":
        data[0].opLeaseIdLeaseName = fieldValue
        break;

      case "incInfoLocation":
        data[0].incInfoLocation = fieldValue
        break;

      case "incInfoEquipInvolved":
        data[0].incInfoEquipInvolved = fieldValue
        break;

      case "incInfoInjuryType":
        data[0].incInfoInjuryType = fieldValue
        break;

      case "incInfoAffectedBodyPart":
        data[0].incInfoAffectedBodyPart = fieldValue
        break;

      case "incInfoDescription":
        data[0].incInfoDescription = fieldValue
        break;

      case "medicalTreatmentY":
        if (data[0].medicalTreatment > 0) {
          data[0].medicalTreatment = null
        }
        else {
          data[0].medicalTreatment = 1

        }
        //validateDataItem.riskInvolvePeopleValue=true
        break;

      case "medicalTreatment":
        if (fieldValue === data[0].medicalTreatment) {
          data[0].medicalTreatment = null
        }
        else { data[0].medicalTreatment = fieldValue }

        //validateDataItem.riskInvolvePeopleValue=true
        break;


      case "medicalDrugScreen":
        if (fieldValue === data[0].medicalDrugScreen) {
          data[0].medicalDrugScreen = null
        }
        else { data[0].medicalDrugScreen = fieldValue }

        //validateDataItem.riskInvolvePeopleValue=true
        break;


      case "medicalBonafide":
        if (fieldValue === data[0].medicalBonafide) {
          data[0].medicalBonafide = null
        }
        else { data[0].medicalBonafide = fieldValue }

        //validateDataItem.riskInvolvePeopleValue=true
        break;






      case "medicalPhysician":
        data[0].medicalPhysician = fieldValue
        break;

      case "medicalFacility":
        data[0].medicalFacility = fieldValue
        break;

      //x-rays located in XraysRadioChanged



      case "newWitnessName":
        txtnewWitnessName = fieldValue

        break;

      case "newPosition":
        txtnewPosition = fieldValue
        break;


      case "otherInfoPersonCompleting":
        // data[0].otherInfoPersonCompleting = fieldValue
        if (fieldValue != null) {

          data[0].otherInfoPersonCompleting = fieldValue.value
        } else {
          data[0].otherInfoPersonCompleting = null

        }

        break;

      case "otherInfoSupervisorKey":
        if (fieldValue != null) {

          data[0].otherInfoSupervisorKey = fieldValue.value
        } else {
          data[0].otherInfoSupervisorKey = null

        }



        break;





      default:

    }

    this.setState({ data: data, txtnewPosition: txtnewPosition, txtnewWitnessName: txtnewWitnessName })
  }

  XraysRadioChanged = (e) => {
    const { data } = this.state;
    // console.log("Facilitytype: " + e.target.value)
    data[0].medicalXRays = parseInt(e.target.value)
    //const {selectedValue} = this.state
    //selectedValue.faciltyViewList=e.target.value
    // var rr
    // rr=e.target.value

    // this.setState({faciltyViewList:e.target.value,
    //               selectedValue:selectedValue})
    // if (e.target.value === "my"){
    //    this.getMyFacilityData();
    // }else {
    //     this.getAssignFacility();
    // }
    this.setState({ data: data })
  }

  createDataForUpdateDTO = () => {
    const { data } = this.state
    const { user } = this.props

    const dataForUpdateDTO = {
      "FormFirstReportKey": data[0].formFirstReportKey,
      //"ModifiedByKey": 580, /*4=Submitted, 6=Reviewed*/
      // "StatusKey": data[0].lineStatusKey,
      "Other": data[0].other,
      "Comments": data[0].comments,
      "ModifiedByAD": getUserADAccount(user.account.userName),
      "IncDateTime": data[0].incDateTime ? moment(data[0].incDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
      "IncidentType": data[0].incidentType,
      "IncClassif": data[0].incClassif,
      "OSHAContacted": data[0].oshaContacted,
      "OSHADateTime": data[0].oshaDateTime ? moment(data[0].oshaDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
      "OSHAByWhom": data[0].oshaByWhom,
      "OSHARep": data[0].oshaRep,
      "EmpEquipInfoName": data[0].empEquipInfoName,
      "EmpEquipInfoPosition": data[0].empEquipInfoPosition,
      "EmpEquipInfoSSN": data[0].empEquipInfoSSN,
      "EmpEquipInfoEmpID": data[0].empEquipInfoEmpID,
      "EmpEquipInfoHireDate": data[0].empEquipInfoHireDate,
      "EmpEquipInfoAddress": data[0].empEquipInfoAddress,
      "EmpEquipInfoCity": data[0].empEquipInfoCity,
      "EmpEquipInfoState": data[0].empEquipInfoState,
      "EmpEquipInfoZIP": data[0].empEquipInfoZIP,
      "EmpEquipInfoPhone": data[0].empEquipInfoPhone,
      "EmpEquipInfoDOB": data[0].empEquipInfoDOB,
      "EmpEquipInfoMaritalStatus": data[0].empEquipInfoMaritalStatus,
      "OpLeaseIdWellOperator": data[0].opLeaseIdWellOperator,
      "OpLeaseIdLeaseName": data[0].opLeaseIdLeaseName,
      "IncInfoLocation": data[0].incInfoLocation,
      "IncInfoEquipInvolved": data[0].incInfoEquipInvolved,
      "IncInfoInjuryType": data[0].incInfoInjuryType,
      "IncInfoAffectedBodyPart": data[0].incInfoAffectedBodyPart,
      "IncInfoDescription": data[0].incInfoDescription,
      "MedicalTreatment": data[0].medicalTreatment,
      "MedicalDrugScreen": data[0].medicalDrugScreen,
      "MedicalBonafide": data[0].medicalBonafide,
      "MedicalPhysician": data[0].medicalPhysician,
      "MedicalFacility": data[0].medicalFacility,
      "MedicalXRays": data[0].medicalXRays,
      "OtherInfoSupervisorKey": data[0].otherInfoSupervisorKey,
      "OtherInfoPersonCompleting": data[0].otherInfoPersonCompleting,
      "ReturntoWorkDateTime": this.bMultiField(data[0].incClassif, 64) ?  
                    data[0].returntoWorkDateTime ? moment(data[0].returntoWorkDateTime).format("YYYY-MM-DD HH:mm:ss") : null
                  : null,
      "IncOutofOfficeDays": this.bMultiField(data[0].incClassif, 64) ? data[0].incOutofOfficeDays: 0,
      "ReturntoWorkDateWithNoRestrictions": this.bMultiField(data[0].incClassif, 2048) ?  
      data[0].returntoWorkDateWithNoRestrictions ? moment(data[0].returntoWorkDateWithNoRestrictions).format("YYYY-MM-DD HH:mm:ss") : null
    : null,
"IncJobRestrictionDays": this.bMultiField(data[0].incClassif, 2048) ? data[0].incJobRestrictionDays: 0
  

    }

    return dataForUpdateDTO;
  }


  saveData = () => {
    // alert("first Report");
    const itemDTO = this.createDataForUpdateDTO();
    console.log(itemDTO);

    const putReq = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(itemDTO)
    }

    let myURI = apiURL + 'SafetyIncident/updateSafIncFirstForm'
    fetch(myURI, putReq)
    .then(async response => {
      if (!response.ok) {
          const rspData = response.text();
          //console.log("Error return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          rspData.then(t => {
              this.setState({
                  msgBody: t,
                  msgHeader: "Error",
                  modalMsg: !this.state.modalMsg

              })
          })
          return Promise.reject(error);
      }

        console.log("No error here: " )

        this.getInpsData()
      })
      .catch(error => {
        console.log("PUT Error: " + error);
      })


  }



  createWitnessforInsertDTO = () => {
    const { data, txtnewWitnessName, txtnewPosition } = this.state
    const { user } = this.props

    const dataForInsertDTO = {
      "FormFirstReportKey": data[0].formFirstReportKey,
      "Name": txtnewWitnessName,
      "Position": txtnewPosition


    }

    return dataForInsertDTO;
  }



  createWitnessforDeleteDTO = (wkey) => {
    const { data, txtnewWitnessName, txtnewPosition } = this.state
    const { user } = this.props

    const dataForInsertDTO = {
      "FormFirstWitnessKey": wkey,
      "DeletedBy": getUserADAccount(user.account.userName),
      "FormFirstReportKey": data[0].formFirstReportKey


    }

    return dataForInsertDTO;
  }


  insertWitness = () => {
    const itemDTO = this.createWitnessforInsertDTO();
    console.log(itemDTO);

    const putReq = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(itemDTO)
    }

    let myURI = apiURL + 'SafetyIncident/insertSafIncFirstFormWitness'
    fetch(myURI, putReq)
      .then(async response => {
        const rspData = response.text();
        if (!response.ok) {
          console.log("Error return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          return Promise.reject(error);

        }

        console.log("No error here: " + rspData)
        this.setState({ txtnewPosition: '', txtnewWitnessName: '' })
        this.getWitness()
      })
      .catch(error => {
        console.log("POST Error: " + error);
      })


  }


  deleteWitness = (wkey) => {
    const itemDTO = this.createWitnessforDeleteDTO(wkey);
    console.log(itemDTO);

    const putReq = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(itemDTO)
    }

    let myURI = apiURL + 'SafetyIncident/deleteSafIncFirstFormWitness'

      // fetch(myURI, putReq)
      // .then(response => response.json())
      // .then(data => {
      //     this.getSafetyIncData();

      //     this.openHeaderForm(data[0].safIncHeaderKey);

      // })
      ;


    fetch(myURI, putReq)
      .then(async response => {
        const rspData = response.text();
        if (!response.ok) {
          console.log("Error return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          return Promise.reject(error);

        }

        console.log("No error here: " + rspData)
        this.getWitness()
      })
      .catch(error => {
        console.log("POST Error: " + error);
      })


  }

  toggleTab = tab => {
    const { activeTab } = this.state

    if (activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }



  render() {

    // var {isLoaded, data, isHdrLoaded, hdrData} = this.state
    var { isLoaded, isHdrLoaded,
      data, hdrData,
      ddEmployees, isddEmployeesLoaded,
      tblWitness,
      txtnewWitnessName, txtnewPosition, activeTab, isReadOnly, safIncLineKey, msgHeader, msgBody
    } = this.state

    if (!isLoaded || !isHdrLoaded) return <h1> loading... </h1>

    if (!isddEmployeesLoaded) return <h1> loading Employees... </h1>


    //if (!isLoaded) return <h1> loading </h1> 

    return (
      <div>
        {/* <Form>
                <FormGroup>
                  <Row form>
                    <h3> First Report </h3>
                  </Row>
                  <h5>
                  <label>{hdrData[0].safIncID}</label>  <span>&nbsp;&nbsp;</span>
                  <label>| Line: {data[0].sLineNo} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| Status: {data[0].lineStatusDesc} </label>  <span>&nbsp;&nbsp;</span>                    
                    <label>| By: {data[0].createdByAD} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| Created: {new Date(data[0].createdDateTime).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                   
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup>

            </Form> */}
        <Form>
          <FormGroup row>
            {/* <Col md={2}>
              <h3> First Report </h3>
            </Col> */}
            <Col md={2}>

              {/* <Button color="primary"
                // className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}
                disabled={isReadOnly}
                //  hidden={this.setIsReadOnly(statusKey)}
                onClick={() => this.saveData()}>Save</Button> */}




            </Col>

          </FormGroup>

          <FormGroup row>

            <Col md={12}>
              {/* <FormGroup row>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="safIncID">Incident ID</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="safIncID"
                        id="safIncID" placeholder=""
                        value={hdrData[0].safIncID}
                      />
                    </Col>

                  </Row>
                </Col>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="lineStatusDesc">Status</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="lineStatusDesc"
                        id="lineStatusDesc" placeholder=""
                        value={data[0].lineStatusDesc}
                      />
                    </Col>
                  </Row>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="locationKey">Location</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="locationKey"
                        id="locationKey" placeholder=""
                        value={data[0].locationKey}
                      />
                    </Col>

                  </Row>
                </Col>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="createdByAD">Created By</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="createdByAD"
                        id="createdByAD" placeholder=""
                        value={data[0].createdByAD}
                      />
                    </Col>
                  </Row>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="other">Other</Label>
                    </Col>
                    <Col md={8}>
                      <Input type="text" name="other"
                        id="other" placeholder=""
                        value={data[0].other}
                        onChange={(e) => this.onTextChange("other", e.target.value, e)}
                      />
                    </Col>

                  </Row>
                </Col>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="createdDateTime">Created DateTime</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="createdDateTime"
                        id="createdDateTime" placeholder=""
                        value={new Date(data[0].createdDateTime).toLocaleDateString()}
                      />

                    </Col>
                  </Row>
                </Col>
              </FormGroup> */}

              <FormGroup row>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="incDateTime">Incident Datetime</Label>
                    </Col>
                    <Col md={8}>

                      {/* <DatePicker id="incDateTime"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("incDateTime", v, f)}
                            value={data[0].incDateTime} /> */}
                      <Datetime
                        inputProps={{
                          placeholder: "MM/DD/YYYY hh:mm",
                          // disabled: false
                          disabled: isReadOnly
                        }}
                        // value={moment(data[0].incDateTime).format("MM/DD/YYYY hh:mm")}
                        value={moment(data[0].incDateTime)}

                        onChange={(v, f) => this.onTextChange("incDateTime", v, f)}
                      />

                    </Col>

                  </Row>
                </Col>
                {/* <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="modifiedByAD">Modified By</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="modifiedByAD"
                        id="modifiedByAD" placeholder=""
                        value={data[0].modifiedByAD}
                      
                      />
                    </Col>
                  </Row>
                </Col> */}
              </FormGroup>


              {/* <FormGroup row>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>

                    </Col>
                    <Col md={8}>

                    </Col>

                  </Row>
                </Col>
                <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="modifiedDateTime">Modified Datetime</Label>
                    </Col>
                    <Col md={8}>

                      <Datetime
                        inputProps={{
                          placeholder: "MM//DD/YYYY hh:mm",
                          disabled: true
                        }}
                        id="modifiedDateTime"
                        value={moment(data[0].modifiedDateTime)}


                      />


                    </Col>
                  </Row>
                </Col>
              </FormGroup> */}



              <FormGroup>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { this.toggleTab('1'); }}
                      >
                        Comments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { this.toggleTab('2'); }}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { this.toggleTab('3'); }}
                      >
                        Additional Details
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col md={12}>
                          {/* <Label for="youRObserve">WHAT DID YOU OBSERVE?</Label>       */}
                          <Input
                            // invalid={!validateDataItem.hazardDesc}
                            type="textarea" name="comments" rows={5}
                            id="comments"
                            value={data[0].comments}
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                          // defaultValue = {observation[0].hazardDesc}                               
                          // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                          // disabled={isReadOnly} 

                          />


                        </Col>


                      </Row>
                    </TabPane>


                    <TabPane tabId="2">
                      <Row>
                        <Col md={12}>
                          {/* <DocumentBrowser  buttonLabel="View Image" 
                                        buttonColor="primary" 
                                        formName="Safety Observation Images"     
                                        linkTableName={"thsSafetyObservation"}
                                        linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                        apiURL={apiURL + 'SafetyObservation/Documents/' + (safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey) } /> */}
                          <DocumentBrowserDragDrop buttonLabel="View Image"
                            buttonColor="primary"
                            formName="First Report Images"
                            linkTableName={"thseSafetyIncident_FirstReport"}
                            // linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                            linkPrimaryKey={safIncLineKey}
                            apiURL={apiURL + 'SafetyIncident/Documents/' + safIncLineKey + '/bucket/thseSafetyIncident_FirstReport'}

                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col md={12}>
                          details tbd
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>

                </div>
              </FormGroup>

              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="INCIDENT TYPE (Check all that apply)"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody >
                      {/* 1 row */}
                      <Row >
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbincEquipmentDamage"
                            disabled={isReadOnly}
                            label="Equipment Damage"
                            checked={this.bMultiField(data[0].incidentType, 1)}
                            onChange={(e) => this.onTextChange("incidentType", 1, e)}

                            inline



                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincExposure" label="Exposure" inline
                            checked={this.bMultiField(data[0].incidentType, 2)}
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incidentType", 2, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincNearHit" label="Near Hit" inline
                            checked={this.bMultiField(data[0].incidentType, 4)}
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incidentType", 4, e)}
                          />
                        </Col>

                      </Row>
                      {/* 2 row */}
                      <Row>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbincToxicRelease"
                            label="Toxic Release"
                            checked={this.bMultiField(data[0].incidentType, 8)}
                            disabled={isReadOnly}
                            inline

                            onChange={(e) => this.onTextChange("incidentType", 8, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincExplosion" label="Explosion" inline

                            checked={this.bMultiField(data[0].incidentType, 16)}
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incidentType", 16, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincInjury" label="Injury" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incidentType, 32)}
                            onChange={(e) => this.onTextChange("incidentType", 32, e)}
                          />
                        </Col>

                      </Row>
                      {/* 3 row */}

                      <Row>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbincVehicleIncident"
                            label="Vehicle Incident"
                            checked={this.bMultiField(data[0].incidentType, 64)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incidentType", 64, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincSpillLeak" label="Spill/Leak" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incidentType, 128)}
                            onChange={(e) => this.onTextChange("incidentType", 128, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincFire" label="Fire" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incidentType, 256)}
                            onChange={(e) => this.onTextChange("incidentType", 256, e)}
                          />
                        </Col>

                      </Row>
                      {/* 4 row */}
                      <Row>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbincViolentAct"
                            label="Violent Act"
                            checked={this.bMultiField(data[0].incidentType, 512)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incidentType", 512, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincIllness" label="Illness" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incidentType, 1024)}
                            onChange={(e) => this.onTextChange("incidentType", 1024, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbincOther" label="Other" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incidentType, 2048)}
                            onChange={(e) => this.onTextChange("incidentType", 2048, e)}
                          />
                        </Col>

                      </Row>


                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>



              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="INCIDENT Classification (Check all that apply)"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row hidden>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbclaPersonal"
                            label="Personal"
                            checked={this.bMultiField(data[0].incClassif, 1)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incClassif", 1, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbclaNoMedical" label="No Medical/Report Only" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 2)}
                            onChange={(e) => this.onTextChange("incClassif", 2, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbclaFirstAid" label="1st Aid" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 4)}
                            onChange={(e) => this.onTextChange("incClassif", 4, e)}
                          />
                        </Col>

                      </Row>
                      {/* 2 row */}
                      <Row hidden>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbclaAlleged"
                            label="Alleged"
                            checked={this.bMultiField(data[0].incClassif, 8)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incClassif", 8, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbclaRecordable" label="Recordable NLTI" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 16)}
                            onChange={(e) => this.onTextChange("incClassif", 16, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbclaReduced" label="Reduced Work Case (RWC)" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 32)}
                            onChange={(e) => this.onTextChange("incClassif", 32, e)}
                          />
                        </Col>

                      </Row>
                      {/* 3 row */}

                      <Row hidden>
                        <Col md={4}>
                         
                          <CustomInput type="checkbox" id="cbclaFatality" label="Fatality" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 128)}
                            onChange={(e) => this.onTextChange("incClassif", 128, e)}
                          />
                        </Col>
                        <Col md={4}>
                        

                        </Col>

                        <Col  md={4}>
                        
                          </Col>

                      </Row>

                      <Row>
                        <Col md={4}>
                         
                          <CustomInput type="checkbox" id="cbclaNonRecordableFirstAid" label="Non-Recordable First Aid" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 256)}
                            onChange={(e) => this.onTextChange("incClassif", 256, e)}
                          />
                        </Col>
                        <Col md={4}>
                        <CustomInput type="checkbox" id="cbclaNonRecordableOther" label="Non-Recordable Other" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 512)}
                            onChange={(e) => this.onTextChange("incClassif", 512, e)}
                          />
                        

                        </Col>

                        <Col  md={4}>
                        <CustomInput type="checkbox" id="cbclaRecordableMedical" label="Recordable Medical" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 1024)}
                            onChange={(e) => this.onTextChange("incClassif", 1024, e)}
                          />
                        
                          </Col>




                      </Row>



                      <Row>
                        <Col md={4}>
                        <CustomInput type="checkbox"
                            id="cbclaLostTime"
                            label="Recordable Lost Time"
                            checked={this.bMultiField(data[0].incClassif, 64)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incClassif", 64, e)}

                          />
                         
                        </Col>
                        <Col md={4}>
                        <Label 
                       
                        for="returntoWorkDateTime"
                        >Return to Work Date</Label>


                        
                          <DatePicker id="returntoWorkDateTime"
                           //hidden={this.bMultiField(data[0].incClassif, 64)}
                           //hidden={!(empDetails.employeeKey>0)}
                           // = {true}
                           //isHidden
                          
                          //  disabled={isReadOnly}
                           disabled={!this.bMultiField(data[0].incClassif, 64) || isReadOnly}
                           //value={this.bMultiField(data[0].incClassif, 64) ? data[0].ReturntoWorkDateTime : ""}
                           
                           value={this.bMultiField(data[0].incClassif, 64) ? data[0].returntoWorkDateTime : ""}
                            onChange={(v, f) => this.onTextChange("returntoWorkDateTime", v, f)}
                          />

                        </Col>

                        <Col  md={4}>
                        <Label 
                       
                       for="incOutofOfficeDays"
                       >Out of Office Days (including weekends)</Label>
                           <Input type="text"
                            name="incOutofOfficeDays"
                            id="incOutofOfficeDays"
                            value={this.bMultiField(data[0].incClassif, 64) ? data[0].incOutofOfficeDays : ""}
                            disabled={!this.bMultiField(data[0].incClassif, 64) || isReadOnly}
                            onChange={(e) => this.onTextChange("incOutofOfficeDays", e.target.value, e)}

                          />
                       
                          </Col>




                      </Row>

                      <Row>
                        <Col md={4}>
                        <CustomInput type="checkbox"
                            id="cbclaRecordableJobRestriction"
                            label="Recordable Job Restriction"
                            checked={this.bMultiField(data[0].incClassif, 2048)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("incClassif", 2048, e)}

                          />
                         
                        </Col>
                        <Col md={4}>
                        <Label 
                       
                        for="returntoWorkDateWithNoRestrictions"
                        >Return to Work Date With No Restrictions</Label>


                        
                          <DatePicker id="returntoWorkDateWithNoRestrictions"
                           //hidden={this.bMultiField(data[0].incClassif, 64)}
                           //hidden={!(empDetails.employeeKey>0)}
                           // = {true}
                           //isHidden
                          
                          //  disabled={isReadOnly}
                           disabled={!this.bMultiField(data[0].incClassif, 2048) || isReadOnly}
                           //value={this.bMultiField(data[0].incClassif, 64) ? data[0].ReturntoWorkDateTime : ""}
                           
                           value={this.bMultiField(data[0].incClassif, 2048) ? data[0].returntoWorkDateWithNoRestrictions : ""}
                            onChange={(v, f) => this.onTextChange("returntoWorkDateWithNoRestrictions", v, f)}
                          />

                        </Col>

                        <Col  md={4}>
                        <Label 
                       
                       for="incJobRestrictionDays"
                       >Job Restriction Days</Label>
                           <Input type="text"
                            name="incJobRestrictionDays"
                            id="incJobRestrictionDays"
                            value={this.bMultiField(data[0].incClassif, 2048) ? data[0].incJobRestrictionDays : ""}
                            disabled={!this.bMultiField(data[0].incClassif, 2048) || isReadOnly}
                            onChange={(e) => this.onTextChange("incJobRestrictionDays", e.target.value, e)}

                          />
                       
                          </Col>




                      </Row>

                      <Row>
                        <Col md={4}>
                         
                          <CustomInput type="checkbox" id="cbclaRecordableFatality" label="Recordable Fatality" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 4096)}
                            onChange={(e) => this.onTextChange("incClassif", 4096, e)}
                          />
                        </Col>
                        <Col md={4}>
                        

                        </Col>

                        <Col  md={4}>
                        
                          </Col>

                      </Row>



                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>


              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                     c
                        disabled
                        className="bg-transparent"
                        value="OSHA"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>


                      <Row>
                        {/* <Col md={2}>
                          <CustomInput type="checkbox" id="cbclaOsha" label="OSHA Contacted" inline
                            // disabled={isReadOnly}
                            checked={this.bMultiField(data[0].incClassif, 256)}
                            onChange={(e) => this.onTextChange("incClassif", 256, e)}
                          />

                        </Col> */}
                        <Col md={4}>
                          <Row form>
                            <Col md={4}>
                              <Label>OSHA Contacted</Label>
                            </Col>
                            <Col md={2}>
                              <CustomInput
                                type="checkbox"
                                
                                id="oshaContactedY"
                                label="Yes"
                                checked={data[0].oshaContacted === 1}
                                inline
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("oshaContacted", 1, e)}
                              />
                            </Col>
                            <Col md={2}>
                              <CustomInput type="checkbox"
                                id="oshaContactedN"
                                label="No" inline
                                checked={data[0].oshaContacted === 0}
                                onChange={(e) => this.onTextChange("oshaContacted", 0, e)}
                                disabled={isReadOnly}

                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* 3 row */}

                      <Row>
                        <Col md={4}>
                          <Label for="oshaDateTime">OSHA Contacted Datetime</Label>
                          {/* <DatePicker id="oshaDate"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("oSHADateTime", v, f)}
                            value={data[0].oSHADateTime} /> */}
                          <Datetime
                            inputProps={{
                              placeholder: "MM/DD/YYYY hh:mm",
                              //disabled: false
                              disabled: data[0].oshaContacted === 1 ? isReadOnly : true
                            }}
                            // value={data[0].oshaDateTime === null ? null : new Date(data[0].oshaDateTime)}
                            value={moment(data[0].oshaDateTime)}


                            onChange={(v, f) => this.onTextChange("oshaDateTime", v, f)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="oshaByWhom">OSHA Contacted By Whom</Label>
                          <Input type="text"
                            name="oshabywhom"
                            id="oshabywhom"
                            value={data[0].oshaContacted === 1 ? data[0].oshaByWhom : ""}
                            disabled={data[0].oshaContacted === 1 ? isReadOnly : true}
                            onChange={(e) => this.onTextChange("oshaByWhom", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="oshaRep">OSHA Rep</Label>
                          <Input type="text"
                            name="oshaRep"
                            id="oshaRep"
                            value={data[0].oshaContacted === 1 ? data[0].oshaRep : ""}
                            disabled={data[0].oshaContacted === 1 ? isReadOnly : true}
                            onChange={(e) => this.onTextChange("oshaRep", e.target.value, e)}

                          />
                        </Col>



                      </Row>


                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>



              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Employee (IE) or Equipment Information"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>
                        <Col md={4}>
                          <Label for="empEquipInfoName">IE's Name or Equipment Involved</Label>
                          <Input type="text" name="empEquipInfoName"
                            id="empEquipInfoName"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoName}
                            onChange={(e) => this.onTextChange("empEquipInfoName", e.target.value, e)}
                          />


                        </Col>
                        <Col md={4}>
                          <Label for="empEquipInfoPosition">IE's Position</Label>
                          <Input type="text" name="empEquipInfoPosition"
                            id="empEquipInfoPosition"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoPosition}
                            onChange={(e) => this.onTextChange("empEquipInfoPosition", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="empEquipInfoSSN">SSN</Label>
                          <Input type="password" name="empEquipInfoSSN"
                            id="empEquipInfoSSN"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoSSN}
                            // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                            onChange={(e) => this.onTextChange("empEquipInfoSSN", e.target.value, e)}

                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label for="empEquipInfoEmpID">Employee ID#</Label>
                          <Input type="text" name="empEquipInfoEmpID"
                            id="empEquipInfoEmpID"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoEmpID}
                            onChange={(e) => this.onTextChange("empEquipInfoEmpID", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="empEquipInfoHireDate">Hire Date</Label>
                          <DatePicker id="empEquipInfoHireDate"
                            disabled={isReadOnly}
                            //onChange={(v, f) => this.onTextChange("submittedDatetime",v, f)} 
                            value={data[0].empEquipInfoHireDate}
                            onChange={(v, f) => this.onTextChange("empEquipInfoHireDate", v, f)}
                          />
                        </Col>

                        <Col md={4}>
                          <Label for="empEquipInfoAddress">Address</Label>
                          <Input type="text" name="empEquipInfoAddress"
                            id="empEquipInfoAddress"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoAddress}
                            onChange={(e) => this.onTextChange("empEquipInfoAddress", e.target.value, e)}
                          />
                        </Col>
                      </Row>


                      <Row>
                        <Col md={4}>
                          <Label for="empEquipInfoCity">City</Label>
                          <Input type="text" name="empEquipInfoCity"
                            id="empEquipInfoCity"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoCity}
                            onChange={(e) => this.onTextChange("empEquipInfoCity", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="empEquipInfoState">State</Label>
                          <Input type="text" name="empEquipInfoState"
                            id="empEquipInfoState"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoState}
                            onChange={(e) => this.onTextChange("empEquipInfoState", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="empEquipInfoZIP">ZIP</Label>
                          <Input type="text" name="empEquipInfoZIP"
                            id="empEquipInfoZIP"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoZIP}
                            onChange={(e) => this.onTextChange("empEquipInfoZIP", e.target.value, e)}
                          />
                        </Col>



                      </Row>

                      <Row>

                        <Col md={4}>
                          <Label for="empEquipInfoPhone">Phone</Label>
                          <Input type="text" name="empEquipInfoPhone"
                            id="empEquipInfoPhone"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoPhone}
                            onChange={(e) => this.onTextChange("empEquipInfoPhone", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="empEquipInfoDOB">DOB</Label>
                          <DatePicker id="empEquipInfoDOB"
                            value={data[0].empEquipInfoDOB}
                            disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("empEquipInfoDOB", v, f)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="empEquipInfoMaritalStatus">Marital Status</Label>
                          <Input type="text" name="empEquipInfoMaritalStatus"
                            id="empEquipInfoMaritalStatus"
                            disabled={isReadOnly}
                            value={data[0].empEquipInfoMaritalStatus}
                            onChange={(e) => this.onTextChange("empEquipInfoMaritalStatus", e.target.value, e)}
                          />
                        </Col>




                      </Row>



                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>

              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Operator/Lease Information"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>
                        <Col md={6}>
                          <Label for="opLeaseIdWellOperator">Well Operator</Label>
                          <Input type="text" name="opLeaseIdWellOperator"
                            id="opLeaseIdWellOperator"
                            disabled={isReadOnly}
                            value={data[0].opLeaseIdWellOperator}
                            onChange={(e) => this.onTextChange("opLeaseIdWellOperator", e.target.value, e)}
                          />


                        </Col>
                        <Col md={6}>
                          <Label for="opLeaseIdLeaseName">Lease Name and Well#</Label>
                          <Input type="text" name="opLeaseIdLeaseName"
                            id="opLeaseIdLeaseName"
                            disabled={isReadOnly}
                            value={data[0].opLeaseIdLeaseName}
                            onChange={(e) => this.onTextChange("opLeaseIdLeaseName", e.target.value, e)}
                          />
                        </Col>


                      </Row>



                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>

              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Incident Information"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>
                        <Col md={6}>
                          <Label for="incInfoLocation">Incident Location</Label>
                          <Input type="text" name="incInfoLocation"
                            id="incInfoLocation"
                            disabled={isReadOnly}
                            value={data[0].incInfoLocation}
                            onChange={(e) => this.onTextChange("incInfoLocation", e.target.value, e)}
                          />


                        </Col>
                        <Col md={6}>
                          <Label for="incInfoEquipInvolved">Equipment Involved</Label>
                          <Input type="text" name="incInfoEquipInvolved"
                            id="incInfoEquipInvolved"
                            disabled={isReadOnly}
                            value={data[0].incInfoEquipInvolved}
                            onChange={(e) => this.onTextChange("incInfoEquipInvolved", e.target.value, e)}
                          />
                        </Col>


                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label for="incInfoInjuryType">Injury Type (i.e. Sprain)</Label>
                          <Input type="text" name="incInfoInjuryType"
                            id="incInfoInjuryType"
                            disabled={isReadOnly}
                            value={data[0].incInfoInjuryType}
                            onChange={(e) => this.onTextChange("incInfoInjuryType", e.target.value, e)}
                          />


                        </Col>

                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label for="incInfoAffectedBodyPart">Affected Body Part (i.e. Right wrist, left shoulder, etc)</Label>
                          <Input type="text" name="incInfoAffectedBodyPart"
                            id="incInfoAffectedBodyPart"
                            disabled={isReadOnly}
                            value={data[0].incInfoAffectedBodyPart}
                            onChange={(e) => this.onTextChange("incInfoAffectedBodyPart", e.target.value, e)}
                          />


                        </Col>

                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label for="incInfoDescription">Description of Incident</Label>
                          <Input
                            // invalid={!validateDataItem.hazardDesc}
                            type="textarea" name="incInfoDescription" rows={5}
                            id="incInfoDescription"
                            disabled={isReadOnly}
                            value={data[0].incInfoDescription}
                            onChange={(e) => this.onTextChange("incInfoDescription", e.target.value, e)}
                          // defaultValue = {observation[0].hazardDesc}                               
                          // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                          // disabled={isReadOnly} 

                          />
                          {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                        </Col>
                      </Row>


                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>

              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Medical Notes"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>
                        {/* <Col md={4}>
                          <CustomInput type="checkbox" id="medicalTreatment" label="Medical Treatment" inline
                            // disabled={isReadOnly}
                            checked={this.bMultiField(data[0].medicalTreatment, 1)}
                            onChange={(e) => this.onTextChange("medicalTreatment", 1, e)}
                          />
                        </Col> */}
                        <Col md={4}>
                          <Row form>
                            <Col md={4}>
                              <Label>Medical Treatment</Label>
                            </Col>
                            <Col md={2}>
                              <CustomInput type="checkbox"
                                id="medicalTreatmentYes"
                                label="Yes"
                                checked={data[0].medicalTreatment === 1}
                                inline
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("medicalTreatment", 1, e)}
                              />
                            </Col>
                            <Col md={2}>
                              <CustomInput type="checkbox"
                                id="medicalTreatmentNo"
                                label="No" inline
                                checked={data[0].medicalTreatment === 0}
                                onChange={(e) => this.onTextChange("medicalTreatment", 0, e)}
                                disabled={isReadOnly}

                              />
                            </Col>
                          </Row>
                        </Col>


                        <Col md={4}>
                          {/* <CustomInput type="checkbox" id="medicalDrugScreen" label="Drug Screen" inline
                            // disabled={isReadOnly}
                            checked={this.bMultiField(data[0].medicalDrugScreen, 1)}
                            onChange={(e) => this.onTextChange("medicalDrugScreen", 1, e)}
                          /> */}
                          <Row form>
                            <Col md={4}>
                              <Label>Drug Screen</Label>
                            </Col>
                            <Col md={2}>
                              <CustomInput type="checkbox"
                                id="medicalDrugScreenYes"
                                label="Yes"
                                checked={data[0].medicalDrugScreen === 1}
                                inline
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("medicalDrugScreen", 1, e)}
                              />
                            </Col>
                            <Col md={2}>
                              <CustomInput type="checkbox"
                                id="medicalDrugScreenNo"
                                label="No" inline
                                checked={data[0].medicalDrugScreen === 0}
                                onChange={(e) => this.onTextChange("medicalDrugScreen", 0, e)}
                                disabled={isReadOnly}

                              />
                            </Col>
                          </Row>

                        </Col>
                        <Col md={4}>
                          {/* <CustomInput type="checkbox" id="medicalBonafide" label="Bonafide Offer" inline
                            // disabled={isReadOnly}
                            checked={this.bMultiField(data[0].medicalBonafide, 1)}
                            onChange={(e) => this.onTextChange("medicalBonafide", 1, e)}
                          /> */}

                          <Row form>
                            <Col md={4}>
                              <Label>Bonafide Offer</Label>
                            </Col>
                            <Col md={2}>
                              <CustomInput type="checkbox"
                                id="medicalBonafideYes"
                                label="Yes"
                                checked={data[0].medicalBonafide === 1}
                                inline
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("medicalBonafide", 1, e)}
                              />
                            </Col>
                            <Col md={2}>
                              <CustomInput type="checkbox"
                                id="medicalBonafideNo"
                                label="No" inline
                                checked={data[0].medicalBonafide === 0}
                                onChange={(e) => this.onTextChange("medicalBonafide", 0, e)}
                                disabled={isReadOnly}

                              />
                            </Col>
                          </Row>





                        </Col>



                      </Row>
                      <Row>

                        <Col md={4}>
                          <Label for="medicalPhysician">Treating Physician</Label>
                          <Input type="text" name="medicalPhysician"
                            id="medicalPhysician"
                            disabled={isReadOnly}
                            value={data[0].medicalPhysician}
                            onChange={(e) => this.onTextChange("medicalPhysician", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="medicalFacility">Treatment Facility</Label>
                          <Input type="text" name="medicalFacility"
                            id="medicalFacility"
                            disabled={isReadOnly}
                            value={data[0].medicalFacility}
                            onChange={(e) => this.onTextChange("medicalFacility", e.target.value, e)}
                          />
                        </Col>

                        <Col md={4}>
                          <Label for="medicalXRays">X-Rays</Label>
                          <div>
                            <CustomInput type="radio"
                              id="medicalXRaysna"
                              disabled={isReadOnly}
                              name='medicalXRays'
                              label='N/A'
                              value='0'
                              checked={data[0].medicalXRays === 0}
                              onChange={this.XraysRadioChanged}
                              inline />
                            <CustomInput type="radio"
                              id="medicalXRaysPositive"
                              disabled={isReadOnly}
                              name='medicalXRays'
                              label='Positive'
                              value='1'
                              checked={data[0].medicalXRays === 1}
                              onChange={this.XraysRadioChanged}
                              inline />
                            <CustomInput type="radio"
                              id="medicalXRaysNegative"
                              disabled={isReadOnly}
                              name='medicalXRays'
                              label='Negative'
                              value='-1'
                              checked={data[0].medicalXRays === -1}
                              onChange={this.XraysRadioChanged}
                              inline />  </div>


                        </Col>







                      </Row>
                      <Row>
                        <Col md={12}>


                        </Col>

                      </Row>
                      <Row>
                        <Col md={12}>

                        </Col>
                      </Row>


                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>



              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Incident Witness Information"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>

                        <Col md={4}>
                          <Label for="newWitnessName">Witness Name</Label>
                          <Input type="text" name="newWitnessName"
                            id="newWitnessName"
                            disabled={isReadOnly}
                            value={txtnewWitnessName}
                            onChange={(e) => this.onTextChange("newWitnessName", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="newPosition">Position</Label>
                          <Input type="text" name="newPosition"
                            id="newPosition"
                            disabled={isReadOnly}
                            value={txtnewPosition}
                            onChange={(e) => this.onTextChange("newPosition", e.target.value, e)}
                          />
                        </Col>
                        <Col md={4}>
                          <Label for="addnewwitness">&nbsp;&nbsp;</Label>
                          <div>
                            <Button
                              color="primary"
                              className="btn btn-sm active text-decoration-none"
                              id="addnewwitness"
                              disabled={!txtnewWitnessName || !txtnewPosition || isReadOnly}
                              onClick={this.insertWitness}>
                              {/* <img
                                    src={iconplus}
                                    alt="Add"
                                    style={{width: "30px", margin:"0 0 0 0"}} /> */}
                              Add new Witness
                            </Button>
                          </div>
                        </Col>




                      </Row>


                      <span>&nbsp;&nbsp;</span>


                      <Row>

                        <IgrDataGrid
                          ref={this.onGridRef}
                          // height={gridHeight}             
                          height="100%"
                          width="100%"
                          dataSource={tblWitness}
                          // dataSource={safetyInc.filter( rec =>  ((rec.safetyObservationNo || '').toLowerCase().includes(searchText)) ||
                          //                                     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                          //                                     (rec.qhseReportTypeDesc || '').toLowerCase().includes(searchText) ||
                          //                                     (rec.hazardDesc || '').toLowerCase().includes(searchText) ||
                          //                                     (rec.solutionDesc || '').toLowerCase().includes(searchText) ||
                          //                                     (rec.createdByName || '').toLowerCase().includes(searchText) ||       
                          //                                     (rec.managerName || '').toLowerCase().includes(searchText) ||                                                      
                          //                                     (rec.locationName || '').toLowerCase().includes(searchText))}
                          autoGenerateColumns="false"
                          defaultColumnMinWidth="100"
                          selectionMode="SingleRow"
                        // selectedKeysChanged={this.onGridRowSelected}
                        //cellBackground={"#ffbfbf"}
                        >


                          <IgrTemplateColumn field="name" headerText="Witness Name" width="*>120"
                            cellUpdating={this.onStatusCellUpdating}
                          />
                          <IgrTemplateColumn field="position" headerText="Position"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating}

                          />
                          <IgrTemplateColumn isColumnOptionsEnabled="false" field="DeleteColumn" isHidden={isReadOnly} headerText="" width="140" cellUpdating={this.onDeleteCellUpdating} />

                          {/* <IgrImageColumn field="deleterow" headerText="wdw" contentOpacity="1"  horizontalAlignment="center" cellUpdating={this.onStatusCellUpdating}  width="*>110"/> */}



                          <IgrNumericColumn field="formFirstWitnessKey" isHidden />
                          <IgrNumericColumn field="formFirstReportKey" isHidden />

                        </IgrDataGrid>





                      </Row>





                    </CardBody>


                  </Card>
                </Col>

              </FormGroup>


              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Other Information"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>

                        <Col md={6}   className="alway-in-between">
                          <Label for="otherInfoSupervisorKey">Supervisor</Label>
                                                 

                          <Select options={this.createEmpList(ddEmployees)}
                            //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                            // styles={customStyles}
            
                            isDisabled={isReadOnly}
                            id="otherInfoSupervisorKey"
                            name="otherInfoSupervisorKey"
                            value={this.getSelEmp(data[0].otherInfoSupervisorKey)}
                            // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                            onChange={(e) => this.onTextChange("otherInfoSupervisorKey", e, e)}
                            isClearable={true}

                          >
                          </Select>

                        </Col>



                        <Col md={6}  className="alway-in-between">
                          <Label for="otherInfoPersonCompleting">Person Completing Form</Label>
                          {/* <Input type="number" name="otherInfoPersonCompleting"
                            id="otherInfoPersonCompleting"
                            value={data[0].otherInfoPersonCompleting}
                            onChange={(e) => this.onTextChange("otherInfoPersonCompleting", e.target.value, e)}
                          /> */}

                          {/* <CustomInput
                            type="select"
                            id="otherInfoPersonCompleting"
                            name="otherInfoPersonCompleting"
                            //defaulValue={observation[0].submittedBy}
                            value={data[0].otherInfoPersonCompleting}
                            disabled={isReadOnly}
                            //disabled={true}        //{isReadOnly || (safetyObservationKey<0)}
                            onChange={(e) => this.onTextChange("otherInfoPersonCompleting", e.target.value, e)}
                          >
                            <option value=""></option>

                            {ddEmployees.map(v => <option value={v.employeeKey}>{v.empName}</option>)}
                          </CustomInput> */}

                          <Select options={this.createEmpList(ddEmployees)}
                            //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                            isDisabled={isReadOnly}
                            id="otherInfoPersonCompleting"
                            name="otherInfoPersonCompleting"
                            // className={(!validateDataItem.afeManagerKey ? "form-error" : "")}
                            value={this.getSelEmp(data[0].otherInfoPersonCompleting)}
                            // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                            onChange={(e) => this.onTextChange("otherInfoPersonCompleting", e, e)}
                            isClearable={true}

                          >
                          </Select>



                        </Col>


                      </Row>





                    </CardBody>


                  </Card>
                </Col>

              </FormGroup>





















            </Col>



          </FormGroup>

        </Form>

        <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>



      </div>

    );



    //       }
    //     }
    //   </FetchData>
    // );
  }
}