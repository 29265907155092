import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';

import MenuIcon from '../../resources/menu.png';
import PlusIcon from '../../resources/plus.svg'
import RefreshIcon from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import ExcelIcon from '../../resources/excel.svg'
import GlobeBW from '../../resources/globebw.svg'
import EditIcon from '../../resources/revise.png';
import Filter from '../../resources/filter.png'
import Action from '../../resources/action.png'
import * as XLSX from "xlsx";
import '../../custom.css';
import { ExcelUtility } from '../ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt, bitwise, FormatDate, IsDate } from '../../resources/utility'

import { Col, Row, Button, FormGroup, Label, Form } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText, InputGroup, InputGroupAddon } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn, IgrComboBoxColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";
import { FilterExpression } from 'igniteui-react-core';
import { FilterFactory } from 'igniteui-react-core';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import ConfirmModal from '../ConfirmModal'
import ConfirmWithMsgModal from '../../resources/ConfirmWithMsgModal'

import AssetDetails, { getValueList, getValueListData } from './AssetDetails';
import { AssetDetailsSections } from './AssetDetailsSections';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';

import { CellRegionMapSingle } from 'igniteui-react-spreadsheet';
import AssetMapView from './AssetMapView';
import AssetMapViewSimple from './AssetMapViewSimple'
import AssetMap from './AssetMap'
import AssetFilter from './AssetFilter'
import { isDate } from 'moment';
import { UndoRedoCommands } from '@syncfusion/ej2-react-richtexteditor';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';

import LossOfUtilityPowerProcedureDetails from '../UtilityPowerProcedure/LossOfUtilityPowerProcedureDetails'
//import { truncateSync } from 'fs-extra';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();
IgrDataGridToolbarModule.register();


var DatePicker = require("reactstrap-date-picker");

const DropdownFilterItems = (props) => {
    const { userFilter } = props

    const onDropDownItemClick = (e) => {
        props.onFilterClick(e)
    }

    if (userFilter === undefined || userFilter === null) {
        return ''
    }

    return (userFilter.map(e => {
        return (<DropdownItem onClick={() => onDropDownItemClick(e)}>
            {e.filter_name}
        </DropdownItem>)
    }))
}

export class Asset extends Component {
    static displayName = Asset.name;

    constructor(props) {
        super(props)
        document.title = "WaterBridge Portal - Asset Manager"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onToolbarRef = this.onToolbarRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onCellDataBound = this.onCellDataBound.bind(this)
        this.onCellClicked = this.onCellClicked.bind(this)
        this.onCellValueChanging = this.onCellValueChanging.bind(this)
        this.onDateRangeCellUpdating = this.onDateRangeCellUpdating.bind(this)
        this.searchInput = React.createRef();

        this.state = {
            companyID: props.companyID,
            assetTypeID: props.assetTypeID,
            assetCatID: (props.assetCatID === undefined ? "OP" : props.assetCatID),
            parentAssetKey: (props.parentAssetKey === undefined ? -1 : props.parentAssetKey),
            parentsAssetKey: (props.parentsAssetKey === undefined ? "" : props.parentsAssetKey),
            isSubFormMode: (props.parentAssetKey === undefined ? false :
                (props.parentAssetKey > 0)),

            showFilterBar: (props.assetTypeID === ""),
            windowHeight: window.innerHeight,
            assetDetail: [],
            modal: false,
            modalAdd: false,
            modalDateRange: false,
            assetKey: -1,
            selAsset: {
                asset_type_id: "",
                company_id: "",
                asset_name: "",
                asset_id: ""
            },
            selAssetTypeID: "",
            formHeaderText: "",
            assetType: [],
            isAssetTypeLoaded: false,
            assetTable: [],
            fullAssetTable: [],
            isLoaded: false,
            assetConfigList: [],
            assetConfig: [],
            isAssetConfigLoaded: false,
            valueListData: [],
            isValueListLoaded: false,
            assetHierarchy: [],
            isAssetHierarchyLoaded: false,
            isGridLoaded: false,
            user: props.user,
            userSecurity: [],
            assetTypeList: [],
            isAssetTypeListLoaded: false,
            basin: [],
            isBasinLoaded: [],
            subSystem: [],
            selAssetType: [],
            selBasin: [],
            selSubSystem: [],
            isSubSystemLoaded: [],
            isUserSecurityLoaded: false,
            isUserAllowAccess: false,
            isAccessReadonly: true,
            modalMapView: false,
            modalAssetFilter: false,
            searchText: '',
            isAssetFilterLoaded: false,
            assetFilter: [],
            filterValueList: [],
            dropdownOpen: false,
            startDate: (getFirstDayofMonth().toISOString()), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            endDate: (new Date().toISOString()),
            filterResult: '',
            modalWMassUpload: false,
            modalMsg: '',
            msgHeader: "",
            msgBody: "",
            isTyping: false,
            userFilter: [],
            isUserFilterLoaded: false,
            filterName: "Filter",
            selFilter: null,
            selectedMetaValue: '',
            modallpp: false,
            divGridid: (props.divGridid === undefined ? "uigrid" : props.divGridid),
            contextMenuid: (props.contextMenuid === undefined ? "contextmenu" : props.contextMenuid),
            editableFieldType: '',
            editableFieldName: ''

        }

    }


    componentDidUpdate() {
        const { isGridLoaded } = this.state
        if (!isGridLoaded) {
            this.loadGridLayout()

        }


    }
    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }
    toggleMassUpload = () => {
        this.setState({ modalWMassUpload: !this.state.modalWMassUpload })
    }

    printAssetDlg = () => {

    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    toggleModalAssetFilterOK = (filterInput, reloadFilter, clearFlter, filterName) => {
        if ((reloadFilter !== undefined) && reloadFilter) {
            this.getUserFilter()
        }
        if ((clearFlter !== undefined) && clearFlter) {
            this.clearAllFilter()
        }

        if ((filterName !== undefined && filterName !== null) && (filterName !== '')) {
            this.setState({ filterName: filterName })
        }

        this.setState({
            filterResult: filterInput,
            modalAssetFilter: !this.state.modalAssetFilter,
        })

        if (filterInput === '') {
            filterInput = 'clear'
        }
        this.getAssetTable(filterInput)
    }


    toggleModalAssetFilter = () => {
        this.setState({
            modalAssetFilter: !this.state.modalAssetFilter,

        })
    }
    toggleModalMapView = () => {
        this.setState({
            assetKey: -1, /**reset assetKey to -1 so it will show all asset in the grid */
            modalMapView: !this.state.modalMapView,

        })
    }

    toggleModalAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,

        })
    }

    toggleModalDateRange = () => {
        this.setState({
            modalDateRange: !this.state.modalDateRange,

        })
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,

        })
    }

    toggleLPPModal = () => {
        this.setState({
            modallpp: !this.state.modallpp,

        })
    }

    toggleEdit = () => {
        const { selAssetTypeID, assetHierarchy } = this.state
        let haveHierarchy = assetHierarchy.filter(a => a.asset_type_id === selAssetTypeID)

        //** added by HN - special form for LPP asset type */
        if (selAssetTypeID === "LPP") {
            this.toggleLPPModal()
        } else if (haveHierarchy.length > 0) {
            this.toggleModal()
        } else {
            this.toggleModalAdd()
        }

    }

    clearAllFilter = () => {
        // console.log("clearfilter Call")
        this.grid.filterExpressions.clear()
        this.setState({
            searchText: '',
            filterName: "Filter",
            selFilter: null
        })
        this.getAssetTable('clear')
    }

    onFilterSelect = (selFilter) => {
        // console.log("selFilter")
        // console.log(selFilter)
        if (!(selFilter === undefined)) {

            let filterInput = selFilter.filter_expression_sql
            this.setState({
                filterName: selFilter.filter_name,
                filterResult: filterInput,
                selFilter: selFilter
            })

            if (filterInput === '') {
                filterInput = 'clear'
            }
            this.getAssetTable(filterInput)

        } else {
            this.setState({ filterName: "Filter" })

        }


    }

    addAsset = () => {

        let selAsset = {
            asset_type_id: this.state.assetTypeID,
            company_id: this.state.companyID,
            asset_name: "New",
            asset_id: "XXXXXX"
        }

        this.setState({
            assetKey: (getRandomInt(100000) * -1),
            modalAdd: !this.state.modalAdd,
            selAsset: selAsset,

        })

    }

    onToolbarRef(toolbar) {
        this.toolbar = toolbar;
        if (this.toolbar) {
            this.toolbar.targetGrid = this.grid;
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.assetTable;

        if (this.toolbar !== null) {
            this.toolbar.targetGrid = this.grid

        }
        this.grid.actualColumns.toArray().forEach((column) => {
            column.dataBound = this.onCellDataBound;
        });

    }

    filterUpdate = (e) => {
        // // // console.log("filterUpdate")
        // // // console.log(e)
        this.setState({ filterResult: e })
        //this.setState({ filterInput: e })
    }

    saveGridLayout = () => {
        const { companyID, assetTypeID } = this.state
        if (this.grid !== undefined) {
            //this.state({saveLayout:this.grid.saveLayout()})
            const { user } = this.state
            this.grid.filterExpressions.clear()


            const addLayoutDTO = {
                "applicationKey": 21,
                "userName": user.account.userName,
                "layoutName": "Asset_" + companyID + '_' + assetTypeID + '_' + user.account.userName,
                "layoutData": this.grid.saveLayout(),
                "createdBy": user.account.userName
            }
            let myURI = apiURL + 'AppLayout/AddLayout'
            fetch(myURI, apiPOSTHeader(addLayoutDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;

                        return Promise.reject(error)
                    } else {
                        //// // console.log("i am here ")
                        //this.closeForm(true)
                    }
                }).catch(error => {
                    // console.log("PUT error: " + error)
                })


        }
    }

    loadGridLayout = () => {
        const { companyID, assetTypeID } = this.state



        if (this.grid !== undefined) {
            console.log('---->loading grid layout------')
            console.log(this.grid.dataSource)
            this.grid.filterExpressions.clear()

            const { user } = this.state
            var myURI = apiURL + 'AppLayout/Grid/21/' + user.account.userName + '/'
            myURI = myURI + "Asset_" + companyID + '_' + assetTypeID + '_' + user.account.userName
            //// console.log(myURI)
            //myURI = apiURL + 'WOTracker/WO'

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    console.log("loadGridLayout")
                    console.log(json)
                    if (json.length > 0) {
                        console.log("json: " + assetTypeID)
                        try {
                            this.grid.loadLayout(json[0].layoutData)
                            this.setState({ isGridLoaded: true })
                        } catch (e) {
                            console.log("Error in loading grid layout :" + e)
                        }
                    }
                })



        }
    }


    onCellDataBound(s, e) {
        // console.log("onCellDataBound")
        // console.log(e)
        //console.log(s)
        if (e.cellInfo !== undefined && e.cellInfo !== null &&
            e.cellInfo.rowItem !== undefined && e.cellInfo.rowItem !== null) {

            // console.log("e.cellInfo.rowItem.wtc_manager_color_code")
            // console.log(e.cellInfo.rowItem.wtc_manager_color_code)

            var wtc_manager_color_code = e.cellInfo.rowItem.wtc_manager_color_code


            if ((wtc_manager_color_code !== undefined && wtc_manager_color_code !== null)) {
                // console.log("s.field")
                // console.log(s.field)
                if (wtc_manager_color_code.includes(s.field)) {
                    wtc_manager_color_code.split(',').map(f => {
                        if (f.includes(s.field)) {
                            const field_color = f.split(":")
                            // console.log("----->found it ->>>s.field")
                            // console.log(s.field)

                            if (field_color.length === 2) {
                                if (field_color[0] === s.field) {
                                    if (field_color[1] !== null) {
                                        if (field_color[1] !== "") {
                                            e.cellInfo.background = field_color[1]
                                        } else {
                                            e.cellInfo.background = "White"
                                        }

                                        // console.log("----->found it ->>>e.cellInfo.background")
                                        // console.log(field_color[1])

                                    }

                                }
                            }

                        }
                    }
                    )

                }
            }


        }
    }
    onCellValueChanging(s, e) {
        // console.log("in onCellValueChanging")
        // console.log("s", s)
        // console.log("e", e)
        // console.log("select Asset")
        // console.log(this.state.selAsset)

        let meta_id = e.column.field
        let meta_value = e.newValue

        // console.log("this.state.assetConfigList")
        // console.log(this.state.assetConfigList)

        let config_meta = this.getAssetConfigMeta(meta_id)

        if (config_meta !== undefined && config_meta !== null) {

            this.updateAssetMeta(meta_value,
                config_meta)
        }



    }

    onDateRangeCellUpdating(s, e) {
        console.log("s", s)
        console.log("e", e)

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;



    }

    getAssetConfigMeta = meta_id => {
        const { assetConfigList, assetTypeID } = this.state
        var config_meta = null
        console.log("assetTypeID")
        console.log(assetTypeID)
        assetConfigList.map(a => {
            if (a.asset_type_id === assetTypeID) {
                a.assetConfig.map(b => {
                    b.asset_config.map(c => {
                        c.meta_datas.map(d => {
                            if (d.meta_id === meta_id) {
                                console.log("Found it")
                                config_meta = d
                                return
                            }
                        })

                        if (config_meta !== null) {
                            return
                        }
                    })

                    if (config_meta !== null) {
                        return
                    }
                })
                if (config_meta !== null) {
                    return
                }
            }
        })


        return config_meta
    }

    updateAssetMeta = (meta_value,
        config_meta,
        bRefresh) => {
        const { assetKey, user } = this.state
        let data_type = config_meta.data_type
        var meta_text = ''
        var meta_datas = []
        if (data_type === "value_list") {
            config_meta.value_list_data.map(a => {
                if (a.field_label === meta_value) {
                    meta_text = meta_value
                    meta_value = a.field_value

                    return
                }
            })
        }

        const meta_data = {
            "asset_meta_key": -1,
            "meta_id": config_meta.meta_id,
            "meta_value": meta_value,
            "meta_text": meta_text,
            "meta_name": config_meta.meta_name,
            "asset_config_meta_key": config_meta.asset_config_meta_key,
            "assetkey": assetKey,
            "created_by": getUserADAccount(user.account.userName)
        }

        console.log("update_meta_data")
        console.log(meta_data)

        meta_datas.push(meta_data)

        this.updateAssetMetaAPI(meta_datas, bRefresh)
    }

    updateAssetMetaAPI = (meta_datas, bRefresh) => {
        let myURI = apiURL + 'Asset/MetaAdd'

        console.log(meta_datas)

        fetch(myURI, apiPOSTHeader(meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        // setMsgBody(t)
                        // setMsgHeader("Error")
                        // setModalMsg(!modalMsg)   
                        console.log("Error in response: " + t)

                    })

                    return Promise.reject(error)
                } else
                    return response
            }).then(json => {

                console.log("Success")

                if (bRefresh !== undefined && bRefresh !== null) {
                    if (bRefresh) {
                        this.getAssetTable()
                    }
                }
                // if (action === "") {
                //     props.refreshData();
                //     props.toggle();
                // } else {
                //     spasset_action(action)
                // }

            }).catch(error => {
                // setMsgBody("PUT error: " + error)
                // setMsgHeader("Error")
                // setModalMsg(!modalMsg)
                console.log("Error: " + error)
            })
    }


    onCellClicked(s, e) {
        const { isAccessReadonly } = this.state
        // console.log("in OnCellClick")
        // console.log("s", s)
        // console.log("e", e)
        if (e.isDoubleClick) {
            // this.toggleEdit()
            // console.log("in OnCellClick")
            // console.log("s", s)
            // console.log("e", e)

            var bPopUp = true

            if (!isAccessReadonly) {
                let column_id = s.activeCell.columnUniqueKey
                let config_meta = this.getAssetConfigMeta(column_id)


                if (config_meta !== undefined && config_meta !== null) {
                    if (config_meta.meta_options !== undefined && config_meta.meta_options !== null) {
                        // console.log("config_meta.meta_options & 32")
                        // console.log(config_meta.meta_options & 32)
                        // console.log(config_meta)
                        if (column_id === 'wtc_projectname' || (Number(config_meta.meta_options) & 32) !== 32) {
                            bPopUp = false
                            this.toggleEdit()
                        } else if (config_meta.data_type === "daterange") {
                            //this.toggleModalDateRange()
                            bPopUp = false
                            this.setState({
                                selectedMetaValue: e.cellInfo.textValue,
                                modalDateRange: !this.state.modalDateRange,
                                editableFieldType: config_meta.data_type,
                                editableFieldName: config_meta.meta_name
                            })
                        } else if (column_id === 'wtc_creticalpath') {
                            //this.toggleModalDateRange()
                            bPopUp = false
                            this.setState({
                                selectedMetaValue: e.cellInfo.textValue,
                                modalDateRange: !this.state.modalDateRange,
                                editableFieldType: config_meta.data_type,
                                editableFieldName: config_meta.meta_name
                            })
                        } else if ((Number(config_meta.meta_options) & 32) === 32) {
                            bPopUp = false
                        }
                    }

                }
            }

            if (bPopUp) {
                this.toggleEdit()
            }
        } else if (e.button === 2) /**2 = right click, 0 and */ {
            // if (e.addedKeys.count > 0) {
            //let clickedRow = e.cellInfo.rowItem
            console.log("e.cellInfo.rowItem")
            console.log(e.cellInfo.rowItem)

            let dataItem = e.cellInfo.rowItem

            this.setState({
                selAsset: dataItem,
                assetKey: dataItem.assetkey,
                selAssetTypeID: dataItem.asset_type_id,
                isTyping: false

            }, () => {
                this.loadAssetConfig(dataItem.asset_type_id)
            })
            //}
        }
    }

    updateEditMetaData = () => {

        let activeCell = this.grid.activeCell

        if (activeCell !== undefined && activeCell !== null) {
            let meta_id = activeCell.columnUniqueKey
            let meta_value = this.state.selectedMetaValue

            // console.log("this.state.assetConfigList")
            // console.log(this.state.assetConfigList)

            let config_meta = this.getAssetConfigMeta(meta_id)

            if (config_meta !== undefined && config_meta !== null) {

                this.updateAssetMeta(meta_value,
                    config_meta,
                    true)

                this.toggleModalDateRange()
            }
        }
    }

    onDateRangeClose = (v, f) => {
        console.log(v)
        // console.log(f)

        if (!v.cancel) {
            // console.log("this.grid.activeCell")
            // console.log(this.grid.activeCell)
            // console.log(this.grid)
            //this.grid.activeCell.value = v.date  
            this.setState({ modalDateRange: !this.state.modalDateRange })

            let activeCell = this.grid.activeCell

            if (activeCell !== undefined && activeCell !== null) {
                let meta_id = activeCell.columnUniqueKey
                let meta_value = v.date

                // console.log("this.state.assetConfigList")
                // console.log(this.state.assetConfigList)

                let config_meta = this.getAssetConfigMeta(meta_id)

                if (config_meta !== undefined && config_meta !== null) {

                    this.updateAssetMeta(meta_value,
                        config_meta,
                        true)
                }
            }


        }
    }



    onGridRowSelected(s, e) {

        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]

            console.log("dataItem")
            console.log(dataItem)

            this.setState({
                selAsset: dataItem,
                assetKey: dataItem.assetkey,
                selAssetTypeID: dataItem.asset_type_id,
                isTyping: false

            }, () => {
                this.loadAssetConfig(dataItem.asset_type_id)
            })
        }
    }


    getFormFilterData = (key) => {

        var myURI = apiURL + 'querybuilder/filter/byfilterkey/' + key

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // // console.log('Form filter data')
                // // console.log(json)
                this.setState({
                    isAssetFilterLoaded: true,
                    assetFilter: json
                })

                if (json.length > 0) {
                    if ((json[0].value_list_id + '') !== '') {
                        this.getFilterValueList(json[0].value_list_id)
                    }

                }

            });
    }



    getFilterValueList = (view_name) => {
        // // console.log('getFilterValueList')
        var myURI = apiURL + 'querybuilder/ValueList/' + view_name

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //// // console.log(json)        
                this.setState({
                    filterValueList: json
                })
            });
    }


    getUserFilter = () => {
        // // console.log('getFilterValueList')
        var myURI = apiURL + 'Asset/' + this.state.companyID + '/AssetFilterByuser/' + this.state.assetTypeID + '/' + this.state.user.account.userName

        // console.log('getFilterValueList')
        // console.log(myURI)


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //// // console.log(json)        
                this.createUserFilterList(json).then(data => {
                    this.setState({
                        isUserFilterLoaded: true,
                        userFilter: data
                    })

                })
            });
    }

    createUserFilterList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.filter_name
                e.label = e.filter_name;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }


    getAssetStatus = () => {
        const { assetTypeID } = this.state

        // // console.log('getAssetType')
        var myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/AssetStatus'
        if (assetTypeID !== "" && assetTypeID !== 'ALL') {

            myURI = myURI + '/ByAssetType/' + this.state.assetTypeID

        }

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //// // console.log(json)        
                this.createAssetStatusList(json).then(data => {
                    this.setState({
                        isAssetStatusLoaded: true,
                        assetStatus: data
                    })

                })

            });
    }

    createAssetStatusList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.asset_status_id
                e.label = e.asset_status_desc;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }

    getAssetType = () => {
        const { assetTypeID } = this.state

        // // console.log('getAssetType')

        if (assetTypeID !== "" && assetTypeID !== 'ALL') {
            let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID
                + '/AssetType/ByAssetType/' + this.state.assetTypeID

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    // // console.log("assettype")
                    // // console.log(json)

                    this.setState({
                        isAssetTypeLoaded: true,
                        assetType: json,
                        formHeaderText: (json.length > 0 ? json[0].asset_type_desc : "")
                    })

                    if (json.length > 0) {
                        if (!isNaN(json[0].filter_key)) {
                            // // console.log('get filter data')
                            this.getFormFilterData(json[0].filter_key)
                        }
                    }


                });
        } else {
            this.setState({
                isAssetTypeLoaded: true,
                assetType: [],
                formHeaderText: "All Assets"
            })

        }
    }

    setUserSecurity = (appSecurity) => {
        if (appSecurity.length > 0) {
            const isUserAllowAccess = (appSecurity[0].accessLevel1 > 0)
            const isReadOnly = (appSecurity[0].accessLevel2 !== 0)

            this.setState({
                isAccessReadonly: isReadOnly,
                isUserAllowAccess: isUserAllowAccess
            })

            this.getAssetType()
            this.getAssetConfig()
            this.getAssetTable()
            this.getAssetHierarchy()
            this.getBasinList()
            this.getSubSystemList()
            this.getAssetTypeList()
            this.getAssetStatus()
        } else {

            this.setState({ isUserAllowAccess: false })
        }
    }

    getBasinList = () => {
        getValueListData(this.state.companyID, "basin").then(data => {
            this.setState({
                isBasinLoaded: true,
                basin: data
            })
        })
    }

    getSubSystemList = () => {
        getValueListData(this.state.companyID, "Area").then(data => {
            this.setState({
                isSubSystemLoaded: true,
                subSystem: data
            })
        })
    }

    getAssetTypeList = () => {
        var myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/assetType'
        myURI = myURI + '/ByAssetLevel/1'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //// // console.log('getAssetHierarchy')
                //// // console.log(json)


                this.createAssetTypeList(json).then(data => {
                    this.setState({
                        assetTypeList: data,
                        isAssetTypeListLoaded: true
                    })
                })
            });
    }

    createAssetTypeList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.asset_type_id
                e.label = e.asset_type_desc;
                tempList.push(e)
            })
            resolve(tempList)
        })

    }


    getAssetHierarchy = () => {
        const { assetTypeID } = this.state


        var myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/assetHierarchy'

        if (assetTypeID !== "" && assetTypeID !== 'ALL') {
            myURI = myURI + '/ByAssetType/' + assetTypeID
        }

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //// // console.log('getAssetHierarchy')
                //// // console.log(json)
                this.setState({
                    assetHierarchy: json,
                    isAssetHierarchyLoaded: true
                })

            });

    }

    getUserSecurity = () => {
        const { assetTypeID } = this.state
        var tempID = assetTypeID
        if (assetTypeID === "") {
            tempID = "ALL"
        }
        let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/AppSecurity/' + this.state.user.account.userName + '/' + tempID

        console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                console.log('------>user security')
                console.log(json)

                this.setUserSecurity(json)
                this.setState({
                    isUserSecurityLoaded: true,
                    userSecurity: json
                })

            });

    }

    updateAssetConfigList = (asset_type_id, data) => {
        const { assetConfigList } = this.state

        let assetConfig = assetConfigList.filter(a => a.asset_type_id === asset_type_id)
        if (assetConfig.length <= 0) {
            assetConfigList.push({
                "asset_type_id": asset_type_id,
                "assetConfig": data
            })
        }
        // // console.log("--->assetConfigList <---")
        // // console.log(assetConfigList)
        this.setState({ assetConfigList: assetConfigList })
    }


    loadAssetConfig = (asset_type_id) => {
        const { assetConfigList } = this.state

        // // console.log("-->assetConfigList in loadAssetConfig  " + asset_type_id)
        // // console.log(assetConfigList)

        if (asset_type_id !== "") {
            let assetConfig = assetConfigList.filter(a => a.asset_type_id === asset_type_id)
            if (assetConfig.length === 0) {
                this.getAssetConfig2(asset_type_id, this.state.companyID).then(data => {
                    assetConfigList.push({
                        "asset_type_id": asset_type_id,
                        "assetConfig": data
                    })
                    this.setState({ assetConfigList: assetConfigList })
                    // // console.log("----Load new Asset config -----")

                    return assetConfigList
                })
            }
            // // console.log("----Asset config found 1111-----")
            // // console.log(assetConfig)

        } else {
            // // console.log("----Asset config found SHOULD NOT BE HERE-----")
        }
    }

    getAssetConfig2 = (assetTypeID, companyID) => {
        const { isAccessReadonly } = this.state
        // // console.log('getAssetConfig2')
        return new Promise((res, rej) => {
            if (assetTypeID !== "" && assetTypeID !== 'ALL') {
                let myURI = apiURL + 'Asset/' + companyID + '/' + this.state.assetCatID + '/1/AssetConfig/ByAssetType/' + assetTypeID
                fetch(myURI, { modes: 'no-cors' })
                    .then(res => res.json())
                    .then(json => {
                        //// // console.log(json)
                        getValueList(companyID, json, isAccessReadonly).then(data => {
                            // // console.log('getAssetConfig ---> COMPLETE <----')
                            res(data)
                        })

                    });
            }
        }
        )

    }


    getAssetConfig = () => {
        const { assetTypeID, isAccessReadonly } = this.state
        //// // console.log('getAssetConfig')

        if (assetTypeID !== "" && assetTypeID !== 'ALL') {

            //let myURI = apiURL + 'Asset/' + this.state.companyID + '/AssetConfig/ByAssetType/' + assetTypeID
            let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/1/AssetConfig/ByAssetType/' + assetTypeID

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    //// // console.log(json)
                    getValueList(this.state.companyID, json, isAccessReadonly).then(data => {
                        this.setState({
                            isAssetConfigLoaded: true,
                            assetConfig: data,
                        })

                        this.updateAssetConfigList(assetTypeID, data)
                        //// // console.log('getAssetConfig ---> COMPLETE <----')

                    })

                });
        } else {
            this.setState({
                isAssetConfigLoaded: true,
                //assetConfig: null,
            })
        }

    }


    getValueList = (data) => {
        const { isAccessReadonly } = this.state

        return new Promise((res, rej) => {
            var tempDataList = []
            data.map(e => {
                e.asset_config.map(b => {
                    let tempList = b.meta_datas.filter(d => d.data_type === 'value_list')
                    if (tempList.length > 0) {

                        tempList.map(a => {
                            this.getValueListData(a.value_list_id).then(data => {
                                a.value_list_data = data
                                a.parent_meta_value = -1
                            })
                        })
                        tempDataList.push(tempList)
                    }

                    if (isAccessReadonly) {
                        b.meta_datas.map(g => {
                            g.is_readonly = 1
                        })
                    }
                })
            })

            res(data)
        })

    }

    getValueListData = (value_list_id) => {
        return new Promise((res, rej) => {

            let myURI = apiURL + 'Asset/' + this.state.companyID + '/ValueList/ByName/' + value_list_id
            // // // console.log("myURI")
            // // // console.log(myURI)
            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    res(json[0].value_list_data)
                });
        })
    }

    convertValueList = (v) => {
        return new Promise((resolve, reject) => {
            const tempList = []
            v.map(e => {
                e.value = e.field_value
                e.label = e.field_label;
                tempList.push(e)
            })
            // // // console.log("tempList")
            // // // console.log(tempList)
            resolve(tempList)
        })

    }


    getAssetTable = (inputFilter = '') => {
        const { parentAssetKey, parentsAssetKey, companyID, assetTypeID } = this.state
        let myURI = apiURL + 'Asset/' + companyID + '/' + this.state.assetCatID


        if (inputFilter === '') {
            inputFilter = this.state.filterResult
        } else if (inputFilter === 'clear') {
            inputFilter = ''
        }


        if (assetTypeID !== '' && assetTypeID !== 'ALL') {
            myURI = myURI + '/TableFormat/ByAssetType/' + assetTypeID

            /**Added by HN 08/12/2024
             * child data can be load from more than one parents
             */
            if (parentsAssetKey !== "") {
                myURI = myURI + '/ParentAsset/' + parentsAssetKey
            } else if (parentAssetKey > 0) {
                myURI = myURI + '/ParentAsset/' + parentAssetKey
            }


        } else {
            myURI = apiURL + 'Asset/' + companyID + '/' + this.state.assetCatID + '/jsonFormat'
        }

        if (inputFilter !== undefined && inputFilter !== null) {
            if (inputFilter !== '') {
                // filterResult = filterResult.replace('/','~')
                // // // console.log("inputFilter")
                // // // console.log(inputFilter)
                myURI = myURI + '/' + inputFilter.replaceAll('/', '~')
            }
        }


        // // // // console.log("------>myURI")
        // // // // console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                let assetTemp = json.map(e => {
                    return {
                        ...e,
                        created_at: new Date(e.created_at),
                        collect_date: new Date(e.collect_date),
                        wtc_popdate: ((e.wtc_popdate === null || e.wtc_popdate === '') ? '' : new Date(e.wtc_popdate)),
                        pct_jobstart: ((e.pct_jobstart === null || e.pct_jobstart === '') ? '' : new Date(e.pct_jobstart)),
                        pct_jobend: ((e.pct_jobend === null || e.pct_jobend === '') ? '' : new Date(e.pct_jobend)),
                        pnd_wqt_collect_date: ((e.pnd_wqt_collect_date === null || e.pnd_wqt_collect_date === '') ? '' : new Date(e.pnd_wqt_collect_date)),
                        pnd_cinv_collect_date: ((e.pnd_cinv_collect_date === null || e.pnd_cinv_collect_date === '') ? '' : new Date(e.pnd_cinv_collect_date)),
                        pnd_job_start_date: ((e.pnd_job_start_date === null || e.pnd_job_start_date === '') ? '' : new Date(e.pnd_job_start_date)),
                        pnd_job_end_date: ((e.pnd_job_end_date === null || e.pnd_job_end_date === '') ? '' : new Date(e.pnd_job_end_date)),
                        modified_at: ((e.modified_at === null || e.modified_at === '') ? '' : new Date(e.modified_at)),
                        wtc_land_req_date: ((e.wtc_land_req_date === null || e.wtc_land_req_date === '') ? '' : new Date(e.wtc_land_req_date))


                    }
                })


                this.setState({
                    isLoaded: true,
                    fullAssetTable: assetTemp, //json,
                    filterResult: inputFilter,
                    //searchText: ""
                })

                this.getDefaultAssetList(assetTemp)

                // // // // console.log('getAssetTable ---> COMPLETE <----')
            });


    }

    getDefaultAssetList = (e) => {
        this.getFitlerAsset(e, this.state.selAssetType, this.state.selBasin, this.state.selSubSystem, this.state.searchText)
    }

    getFitlerAsset = (e, m, b, s, t) => {
        //const {fullAFEData} = this.state
        const { user } = this.props

        var tempAFE = e

        if (m !== undefined) {
            const assetTypes = m.map(e => e.asset_type_id).join(",")
            if (assetTypes.length > 0) {
                tempAFE = tempAFE.filter(e => assetTypes.includes(e.asset_type_id) && ((e.asset_type_id || '') !== ''))
            }

        }

        if (b !== undefined) {
            const basins = b.map(e => e.label).join(",")
            if (basins.length > 0) {
                tempAFE = tempAFE.filter(e => basins.includes(e.basin_name) && ((e.basin_name || '') !== ''))
            }

        }

        if (s !== undefined) {
            const sub_system = s.map(e => e.label).join(",")
            if (sub_system.length > 0) {
                tempAFE = tempAFE.filter(e => sub_system.includes(e.subsystem_name) && ((e.subsystem_name || '') !== ''))
            }
        }

        // // // // console.log("I am here first")
        // // // // console.log(tempAFE)

        this.setState({ assetTable: tempAFE })

        /**Added by HN 08/12/2024
        * child data can be load from more than one parents
        */
        if (this.props.parentsAssetKey !== undefined &&
            this.props.parentsAssetKey !== null && this.props.parentsAssetKey !== "") {
            this.loadGridLayout()
        } else if (this.props.parentAssetKey !== undefined &&
            this.props.parentAssetKey !== null && this.props.parentAssetKey > 0) {
            this.loadGridLayout()
        }




    }

    gridColumnHeader = (id, name, config_meta) => {
        const { isAccessReadonly } = this.state
        // console.log("--->config_meta")
        // console.log(config_meta)

        var is_readonly = true

        /* only allow inline edit if meta_option has 32 */
        let meta_options = config_meta.meta_options

        if ((meta_options & 32) === 32) {
            is_readonly = isAccessReadonly
            if (!is_readonly) {
                is_readonly = (config_meta.is_readonly === null || config_meta.is_readonly === 0 ? false : true)
            }
        }


        let data_type = config_meta.data_type

        if (data_type === "decimal" || data_type === "number" || data_type === "currency") {
            return (<IgrNumericColumn field={id}
                key={id}
                headerText={name}
                maxFractionDigits={2}
                showGroupingSeparator="true"
                isEditable={!is_readonly}
                width="*>200" />)
        } else if (data_type === "date") {
            return (<IgrDateTimeColumn field={id}
                key={id}
                headerText={name}
                dateTimeFormat={"DateShort"}
                width="*>200" />)
        }
        else if (data_type === "datetime") {
            return (<IgrDateTimeColumn field={id}
                key={id}
                headerText={name}
                dateTimeFormat={"DateTimeShort"}
                width="*>200" />)
        } if (data_type === "value_list" && !is_readonly) {
            // console.log("--->config_meta")
            // console.log(config_meta)

            return (<IgrComboBoxColumn field={id}
                key={id}
                headerText={name}
                textField="field_label"
                valueField="field_label"
                dataSource={config_meta.value_list_data}
                width="*>200" />)
        } if (data_type === "daterange" && !is_readonly) {
            return (<IgrTextColumn field={id}
                key={id}
                headerText={name}
                //cellUpdating={this.onDateRangeCellUpdating}
                width="*>200"
                isEditable={false} />)
        }
        else
            return (<IgrTextColumn field={id}
                key={id}
                headerText={name}
                isEditable={!is_readonly}
                width="*>200" />)
    }

    GridColumnHeaders = (config) => {
        // console.log("GridColumnHeaders")
        // console.log(config)
        var columnList = []
        if (config !== undefined) {
            if (config.asset_config !== null && config.asset_config !== undefined) {
                config.asset_config
                    .sort((m, n) => m.meta_order > n.meta_order ? 1 : -1)
                    .map(a => {
                        a.meta_datas.map(b => {

                            if (!b.meta_id.includes('blank')) {
                                columnList.push(this.gridColumnHeader(b.meta_id,
                                    (b.meta_name === "" ? b.meta_id : b.meta_name),
                                    b))
                            }
                        })
                    })
            }
        }
        // // // // console.log("-------->columnList")
        // // // // console.log(columnList)
        return columnList

    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { fullAssetTable } = this.state
        // console.log(fieldName + ' ------ ' + fieldValue)
        // console.log(e)

        switch (fieldName) {
            case "asset_type":
                this.getFitlerAsset(fullAssetTable, e, this.state.selBasin, this.state.selSubSystem)
                this.setState({ selAssetType: e })
                break;
            case "sub_system":
                this.getFitlerAsset(fullAssetTable, e.selAssetType, this.state.selBasin, e)
                this.setState({ selSubSystem: e })
                break;
            case "basin":
                this.getFitlerAsset(fullAssetTable, e.selAssetType, e, this.state.selSubSystem, this.state.searchText)
                this.setState({ selBasin: e })

                break;
            case "editMetaValue":
                this.setState({ selectedMetaValue: fieldValue })
                break;
            case "assetSearch":
                let searchText = fieldValue.toLowerCase()
                this.grid.filterExpressions.clear()
                if (searchText === "") {
                    this.setState({
                        searchText: searchText,
                        isTyping: true
                    })
                    return
                }

                this.filterFactory = new FilterFactory()
                //const column = this.filterFactory.property("asset_name").toLower();
                const column = this.filterFactory.property("search_tag").toLower();

                // console.log("column")
                // console.log(column)

                let filter = column.contains(searchText)

                this.grid.filterExpressions.add(filter);
                this.setState({
                    searchText: searchText,
                    isTyping: true
                })
                break;
            default:
                break;
        }
    }

    filePathset = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        // // // console.log(file);
        this.setState({ file });

        // // // console.log(this.state.file);
    }

    readFile = () => {
        var f = this.state.file;
        var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            //const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            //// // // console.log("Data>>>" + data);// shows that excel data is read
            //// // // console.log(this.convertToJson(data)); // shows data in json format 
            // will have issue with ','

            /* Update state */
            const datajson = XLSX.utils.sheet_to_json(ws);
            //// // console.log(JSON.stringify(datajson));// shows that excel data is read

            this.assetMassUpdateData(JSON.stringify(datajson), name)
        };

        reader.readAsBinaryString(f);
    }

    assetMassUpdateData = (data, file_name) => {
        const { companyID, assetTypeID } = this.state
        //this.state({saveLayout:this.grid.saveLayout()})
        const { user } = this.state


        const addLayoutDTO = {
            "company_id": this.state.companyID,
            "asset_type_id": this.state.assetTypeID,
            "asset_category_id": this.state.assetCatID,
            "json_data": data,
            "update_by": getUserADAccount(user.account.userName),
            "file_name": file_name
        }

        let myURI = apiURL + 'Asset/MassUpdate'
        fetch(myURI, apiPOSTHeader(addLayoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error)
                } else {
                    return response.json()
                }
            }).then(json => {
                // // // console.log("Complete data update ")
                // // // console.log(json)
                //this.toggleMassUpload()

                this.setState({
                    msgBody: json[0].action_type + ' at ' + json[0].action_at + ' ; ' +
                        json[0].field_value + ' - ' +
                        json[0].comments,
                    msgHeader: "Complete",
                    modalMsg: !this.state.modalMsg,
                    modalWMassUpload: false
                })


            }).catch(error => {
                // // // console.log("PUT error: " + error)
            })


    }

    contextMenuItems = () => {
        let menuItems = [
            {
                iconCss: 'e-icons e-medium e-web-layout',
                text: 'View on Map',
                id: "view_on_map"
            },
            {
                iconCss: 'e-icons e-paragraph-2',
                text: 'View Details',
                id: "view_details"
            },
            {
                separator: true
            },
            {
                iconCss: 'e-icons e-copy',
                text: 'Copy',
                id: 'copy'
            }

            // ,
            // {
            //     iconCss: 'e-cm-icons e-paste',
            //     text: 'Paste',
            //     id: 'paste'
            // },
            // {
            //     text: 'Refresh',
            //     id: 'refresh'
            // },

            // {
            //     separator: true
            // },
            // {
            //     text: 'Display Settings'
            // },
            // {
            //     text: 'Personalize'
            // }
        ];
        return menuItems
    }

    mnuItemCreated = () => {
        //  this.cMenu.enableItems(['Display Settings'], false);
    }
        ;
    mnuItemBeforeOpen = () => {
        //  this.cMenu.enableItems(['Medium icons'], false);
    }

    mnuItemSelected = (args) => {
        console.log(args.item)

        switch (args.item.id) {
            case "copy":
                document.execCommand('copy');
                break;
            case "view_details":
                this.toggleEdit()
                break;
            case "view_on_map":
                this.setState({
                    assetKey: this.state.selAsset.assetkey, /**reset assetKey to -1 so it will show all asset in the grid */
                    modalMapView: !this.state.modalMapView,

                })
                break;
            case "paste":
                document.execCommand('paste');
                break;
            default:
                break;
        }
    }

    convertToJson = (csv) => {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        //return result; //JavaScript object        
        return JSON.stringify(result); //JSON
    }

    setFocusToSearchInput = () => {
        console.log("here")
        if (this.state.isTyping) {
            console.log("inside here")
            console.log(this.searchInput.current)
            if (this.searchInput.current) {
                this.searchInput.current.focus();
            }
        }
    };

    handleClickOutside = (event) => {
        // Ensure that this.searchInput.current is not null
        if (this.searchInput.current && !this.searchInput.current.contains(event.target)) {
            // Clicked outside the search input, so set isTyping to false
            this.setState({ isTyping: false });
        }
    };



    componentWillUnmount() {
        //window.addEventListener("resize", this.handleResize);
        document.removeEventListener('click', this.handleClickOutside);
    }
    refreshData = () => {
        //  console.log("REfresh Data")
        this.saveGridLayout()
        this.getAssetTable()
    }
    componentDidMount() {
        //window.addEventListener("resize", this.handleResize);
        document.addEventListener('click', this.handleClickOutside);
        this.getUserFilter()
        this.getUserSecurity()
    }

    render() {
        const { assetTable, selAsset, assetKey, windowHeight, assetConfig, valueListData, isUserSecurityLoaded
            , isAssetConfigLoaded, isAssetTypeLoaded, formHeaderText, isValueListLoaded, isAssetHierarchyLoaded, assetHierarchy
            , isAccessReadonly, isUserAllowAccess, isSubFormMode
            , parentAssetKey, parentsAssetKey
            , showFilterBar, selBasin, selSubSystem, selAssetType
            , basin, isBasinLoaded, subSystem, searchText, isSubSystemLoaded, assetTypeList, isAssetTypeListLoaded
            , assetStatus, isAssetStatusLoaded, assetType, filterResult, filterInput
            , isAssetFilterLoaded, assetFilter, filterValueList, filterName } = this.state
        const gridHeight = (isSubFormMode ? '40vh' : (showFilterBar ? '65vh' : '75vh'))  //(windowHeight * 0.8) + "px"
        var { isLoaded } = this.state

        // console.log("-------------------------------------->assetType")
        // console.log(assetType)
        // // // // console.log("bitwise(assetType[0].form_options,1) === 1")
        // // // // console.log((assetType[0] !== undefined && assetType[0] !== null ? 
        //             bitwise(assetType[0].form_options,1) === 1 : false))



        if (!isUserSecurityLoaded) {
            return (<div>
                <h3>Loading application security.....</h3>
            </div>)
        } else if (!isUserAllowAccess) {
            return (<div>
                <h4>{"You are not authorize to access " + formHeaderText + '. Please contact Asset Manager Admin for access then try again.'}  </h4>
            </div>)
        } else if (!isAssetStatusLoaded) {
            return (<div>
                <h3>Loading Asset Status.....</h3>
            </div>)
        } else if (!isLoaded) {
            return (<div>
                <h3>Loading asset data.....</h3>
            </div>)
        } else if (!isAssetTypeLoaded && !isAssetFilterLoaded) {
            return (<div>
                <h3>Loading asset type data.....</h3>
            </div>)
        } else if (!isAssetConfigLoaded) {
            return (<div>
                <h3>Loading asset config data.....</h3>
            </div>)
        } else if (!isAssetHierarchyLoaded) {
            return (<div>
                <h3>Loading asset hierarchy data.....</h3>
            </div>)
        } else if (!isBasinLoaded) {
            return (<div>
                <h3>Loading asset basin data.....</h3>
            </div>)
        } else if (!isSubSystemLoaded) {
            return (<div>
                <h3>Loading sub system data.....</h3>
            </div>)
        } else if (!isAssetTypeListLoaded) {
            return (<div>
                <h3>Loading asset type list data.....</h3>
            </div>)
        } else
            return (<div>
                <Form onSubmit={e => e.preventDefault()}>
                    <FormGroup>
                        <Row form style={{ marginTop: "-15px" }}>
                            <Col md={8}>
                                <Row form>
                                    <h3> <Label hidden={isSubFormMode} style={{ marginLeft: "-5px" }}>
                                        {formHeaderText + ' |'}
                                    </Label> </h3>

                                    <Button outline
                                        color="secondary"
                                        className="btn-no-border"
                                        onClick={this.refreshData}
                                    >
                                        <img
                                            src={RefreshIcon}
                                            alt="Refresh"
                                            style={{ width: "25px", margin: "0 0 0 0" }} />
                                        Refresh
                                    </Button>


                                    <Button outline color="secondary"
                                        className="btn-no-border"
                                        onClick={this.addAsset}
                                        hidden={isAccessReadonly || showFilterBar}
                                    // onClick={this.addItemTypeModal}
                                    >
                                        <img
                                            src={PlusIcon}
                                            alt="Add"
                                            style={{ width: "22px", height: "22px", marginRight: "5px" }} />
                                        Add
                                    </Button>{' '}

                                    <Button outline color="secondary"
                                        className="btn-no-border"
                                        onClick={this.toggleEdit}
                                        disabled={(assetKey <= 0)}
                                    >
                                        <img
                                            src={EditIcon}
                                            alt="Edit"
                                            style={{ width: "25px", height: "25px", margin: "0 0 0 150" }} />
                                        {(isAccessReadonly ? "View" : "Edit")}
                                    </Button>{' '}


                                    <Button outline color="secondary"
                                        className="btn-no-border"
                                        onClick={this.saveWorkbook}
                                    >
                                        <img
                                            src={ExcelIcon}
                                            alt="excel"
                                            style={{ width: "30px", height: "30px", margin: "0 0 0 0" }} />
                                        Export To Excel
                                    </Button>{' '}

                                    <Button outline color="secondary"
                                        className="btn-no-border float-right"
                                        style={{ float: 'right' }}
                                        onClick={this.toggleModalMapView}
                                        hidden={!((this.state.assetTypeID === "") ||
                                            ((assetType[0] !== undefined && assetType[0] !== null ?
                                                bitwise(assetType[0].form_options, 2) === 2 : false)))}
                                    >
                                        <img
                                            src={GlobeBW}
                                            alt="Edit"
                                            style={{ width: "25px", height: "25px", margin: "0 0 0 150" }} />
                                        {"Map View"}
                                    </Button>{' '}


                                </Row>
                            </Col>
                            <Col md={4}>

                                <Row form style={{ marginTop: "5px" }} className='float-right'>
                                    <InputGroup>
                                        <InputGroupAddon >
                                            {/* <Button outline color="secondary"
                                                className="btn-no-border float-right"
                                                style={{ float: 'right' }}
                                                onClick={this.toggleModalAssetFilter}
                                                hidden={!((this.state.assetTypeID === "") ||
                                                    ((assetType[0] !== undefined && assetType[0] !== null ?
                                                        bitwise(assetType[0].form_options, 1) === 1 : false)))}
                                            >
                                                <img
                                                    src={Filter}
                                                    alt="Edit"
                                                    style={{ width: "25px", height: "25px", margin: "0 0 0 150" }} />
                                                {"Filter"}
                                            </Button>{' '} */}

                                            <UncontrolledDropdown group>
                                                <Button outline={true} color="secondary"
                                                    className="btn-no-border float-right"
                                                    style={{ float: 'right' }}
                                                    onClick={this.toggleModalAssetFilter}
                                                    hidden={!((this.state.assetTypeID === "") ||
                                                        ((assetType[0] !== undefined && assetType[0] !== null ?
                                                            bitwise(assetType[0].form_options, 1) === 1 : false)))}
                                                >
                                                    <img
                                                        src={Filter}
                                                        alt="Edit"
                                                        style={{ width: "25px", height: "25px", margin: "0 0 0 150" }} />
                                                    {filterName}
                                                </Button>{' '}
                                                <DropdownToggle
                                                    caret
                                                    outline={true} color="secondary"
                                                    className="btn-no-border float-right"
                                                />
                                                <DropdownMenu>
                                                    <DropdownItem header>
                                                        Default
                                                    </DropdownItem>
                                                    <DropdownFilterItems userFilter={(this.state.isUserFilterLoaded ?
                                                        this.state.userFilter.filter(a => a.user_id === null) :
                                                        null)}
                                                        onFilterClick={this.onFilterSelect} />
                                                    {/* <DropdownItem disabled>
                                                        Action (disabled)
                                                    </DropdownItem> */}
                                                    <DropdownItem divider />
                                                    <DropdownItem header>
                                                        My Filter
                                                    </DropdownItem>
                                                    <DropdownFilterItems userFilter={(this.state.isUserFilterLoaded ?
                                                        this.state.userFilter.filter(a => a.user_id !== null) :
                                                        null)}
                                                        onFilterClick={this.onFilterSelect} />
                                                    <DropdownItem divider />
                                                    <DropdownItem onClick={this.clearAllFilter}>
                                                        Clear Filter
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </InputGroupAddon>

                                        {/* <Input width="100%"
                                            className="float-right"
                                            id={"searchText"}
                                            ref={this.searchInput}
                                            placeholder="Type here to search......."
                                            onBlur={this.setFocusToSearchInput}
                                            onChange={(e) => this.onTextChange("assetSearch", e.target.value, e)}
                                        >

                                        </Input> */}


                                        {/* <Row  style={{ marginLeft: "5px", marginRight: "5px" }}> */}
                                        <input //width="100%"
                                            className="float-right"
                                            ref={this.searchInput}
                                            placeholder="Type here to search......."
                                            onBlur={this.setFocusToSearchInput}
                                            onChange={(e) => this.onTextChange("assetSearch", e.target.value, e)}
                                            value={this.state.searchText}
                                        >

                                        </input>
                                        {/* </Row> */}

                                        <InputGroupAddon >
                                            <Dropdown isOpen={this.state.dropdownOpen} nav={false}
                                                direction="left"
                                                className="no-border float-right"
                                                color='primary'
                                                outline={true}
                                                hidden={isAccessReadonly || isSubFormMode}
                                                toggle={this.toggleDropdown}>
                                                <DropdownToggle outline className="no-border float-right" caret>
                                                    <img
                                                        src={Action}
                                                        alt="Edit"
                                                        style={{ width: "25px", height: "25px", marginRight: "10px" }} />
                                                    {"Action"}
                                                </DropdownToggle>
                                                <DropdownMenu end>
                                                    <DropdownItem onClick={this.toggleMassUpload}>
                                                        Template Upload
                                                    </DropdownItem>
                                                    <DropdownItem onClick={this.printAssetDlg}>
                                                        Print
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>

                                        </InputGroupAddon>
                                    </InputGroup>
                                </Row>
                            </Col>
                        </Row>
                        <ColoredLine color="grey" />
                    </FormGroup>

                    {/* <FormGroup>
                        <Row>
                            <Col md={3}>
                                <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                    Start Date
                                </Label>
                                <DatePicker value={this.state.startDate}
                                    onChange={(v, f) => this.handleChange(v, f)} />
                            </Col>
                            <Col md={3}>
                                <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                    End Date
                                </Label>
                                <DatePicker value={this.state.endDate} className="alway-on-top"
                                    onChange={(v, f) => this.handleEndDateChange(v, f)} />

                            </Col>

                        </Row>
                    </FormGroup> */}
                    <FormGroup>
                        <Row form hidden={!showFilterBar} style={{ marginTop: "-5px" }}>
                            <Col md={4}>
                                <Row form>
                                    <Col md={12}>
                                        <Label for="typeKey">Asset Type</Label>
                                        <Select
                                            options={assetTypeList}
                                            value={selAssetType}
                                            defaultValue={selAssetType}
                                            onChange={(e) => this.onTextChange("asset_type", e, e)}
                                            isClearable={true}
                                            //width="200px"
                                            isMulti
                                        >
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <Row form>
                                    <Col md={12}>
                                        <Label for="typeKey">Basin</Label>
                                        <Select
                                            options={basin}
                                            value={selBasin}
                                            defaultValue={selBasin}
                                            onChange={(e) => this.onTextChange("basin", e, e)}
                                            isClearable={true}
                                            // width="200px"
                                            isMulti
                                        >
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <Col md={12}>
                                    <Label for="typeKey">Sub System</Label>

                                    <Select
                                        options={subSystem}
                                        value={selSubSystem}
                                        defaultValue={selSubSystem}
                                        onChange={(e) => this.onTextChange("sub_system", e, e)}
                                        isClearable={true}
                                        isMulti

                                    >
                                    </Select>
                                </Col>
                            </Col>
                            <Col md={2}>

                                {/* <Label for="typeKey"> </Label>
                                <Input width="75%" className='floatRight'
                                    placeholder="Type here to search......."
                                    onChange={(e) => this.onTextChange("assetSearch", e.target.value, e)}
                                >

                                </Input> */}


                            </Col>

                        </Row>
                    </FormGroup>
                    <div>
                        <ContextMenuComponent id={this.state.contextMenuid}
                            ref={(scope) => this.cMenu = scope}
                            target={'#' + this.state.divGridid}
                            items={this.contextMenuItems()}
                            created={this.mnuItemCreated}
                            beforeOpen={this.mnuItemBeforeOpen}
                            select={this.mnuItemSelected}
                        />
                        <div id={this.state.divGridid}>
                            <FormGroup >
                                {/* <Row form style={{ zIndex: 0, position: 'relative' }}> */}
                                <Row form>
                                    <IgrDataGridToolbar
                                        width="100%"
                                        ref={this.onToolbarRef}
                                        toolbarTitle={filterResult}
                                        columnChooser="true"
                                        columnPinning="true"

                                    />
                                    <IgrDataGrid

                                        ref={this.onGridRef}

                                        dataSource={assetTable}
                                        isGroupExpandedDefault={false}
                                        width="100%"
                                        height={gridHeight}
                                        selectionMode="SingleRow"
                                        isColumnOptionsEnabled="true"
                                        isGroupCollapsable="true"
                                        // filterUIType="FilterRow"
                                        useAccessibility="true"
                                        selectedKeysChanged={this.onGridRowSelected}
                                        onCellDataBound={this.onCellDataBound}
                                        groupHeaderDisplayMode={"split"}
                                        scrollable={true}
                                        //editMode={1}
                                        autoGenerateColumns="false"
                                        defaultColumnWidth="120"
                                        groupSummaryDisplayMode={"RowTop"}
                                        summaryScope={"Both"}
                                        rowHeight={"30"}
                                        cellTextStyle="14px Arial"
                                        headerTextStyle="14px Arial"
                                        cellClicked={this.onCellClicked}
                                        cellValueChanging={this.onCellValueChanging}


                                    //shouldComponentUpdate={this.state.shouldComponentUpdate}

                                    >
                                        {/* <IgrTextColumn field="product_line_name" headerText="Product Line" width="*>200" />

                                <IgrTextColumn field="basin_name" headerText="Basin" width="*>200" />
                                <IgrTextColumn field="subsystem_name" headerText="Sub System" width="*>200" /> */}

                                        <IgrTextColumn field="asset_name" headerText="Asset name" width="*>200" isEditable={false} />
                                        <IgrTextColumn field="asset_id" headerText="ID" width="*>200" isEditable={false} />

                                        {this.GridColumnHeaders(assetConfig[0])}

                                        <IgrTextColumn field="asset_type_desc" headerText="Asset Type" width="*>200" isEditable={false} />
                                        <IgrTextColumn field="parent_asset_name" headerText="Parent Asset name" width="*>200" isEditable={false} />
                                        <IgrTextColumn field="parent_asset_id" headerText="Parent Asset ID" width="*>200" isEditable={false} />
                                        <IgrTextColumn field="parent_asset_type_id" headerText="Parent Asset Type" width="*>200" isEditable={false} />

                                        <IgrTextColumn field="status_id" headerText="Status" width="*>200" isEditable={false} />
                                        <IgrDateTimeColumn field="created_at"
                                            headerText="Created At"
                                            width="*>200"
                                            dateTimeFormat="DateTimeShort"
                                            isEditable={false}
                                        />
                                        <IgrTextColumn field="created_by" headerText="Created By" width="*>200" isEditable={false} />
                                        <IgrDateTimeColumn field="modified_at"
                                            headerText="Modified At"
                                            width="*>200"
                                            dateTimeFormat="DateTimeShort"
                                            isEditable={false} />
                                        <IgrTextColumn field="modified_by"
                                            headerText="Modified By"
                                            width="*>200" isEditable={false} />


                                        <IgrTextColumn field="assetkey" isHidden={true} isEditable={false}

                                        />

                                        <IgrTextColumn field="asset_type_key" isHidden={true} isEditable={false}

                                        />
                                        <IgrTextColumn field="parent_assetkey" isHidden={true} isEditable={false} />


                                    </IgrDataGrid>
                                </Row>
                            </FormGroup>
                        </div>

                    </div>
                    <Modal isOpen={this.state.modalDateRange}
                        className="alway-on-top"
                        toggle={() => this.toggleModalDateRange()}
                        size="md"
                        //onClosed={() => this.getRiser()}
                        backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleModalDateRange()}>
                            {(this.state.editableFieldType === "daterange" ? "Select Date Range" : this.state.editableFieldName)}
                        </ModalHeader>
                        <ModalBody>


                            <div hidden={this.state.editableFieldType !== "daterange"}>
                                <DateRangePickerComponent
                                    value={this.state.selectedMetaValue}
                                    close={this.onDateRangeClose}
                                    delayUpdate={true}
                                />
                            </div>

                            <div hidden={this.state.editableFieldType !== "textarea" && this.state.editableFieldType !== "text"} >
                                <Input type="textarea"
                                    name={"textedit"}
                                    id={"textedit"}
                                    //className='e-input'
                                    rows={3}
                                    style={{ backgroundColor: "transparent", color: 'grey' }}
                                    //value={assetDetails[0].asset_id}
                                    defaultValue={this.state.selectedMetaValue}
                                    onChange={(e) => this.onTextChange("editMetaValue", e.target.value, e)}
                                //placeholder={""} 
                                />
                            </div>

                        </ModalBody>
                        <ModalFooter hidden={this.state.editableFieldType !== "textarea" &&
                            this.state.editableFieldType !== "text"}>
                            <Button outline color="secondary"
                                className="float-right"
                                onClick={this.updateEditMetaData}
                                style={{ marginBottom: "5px" }}>

                                OK
                            </Button>{' '}
                            <Button outline color="danger"
                                className="float-right"
                                onClick={this.toggleModalDateRange}
                                style={{ marginBottom: "5px" }}>

                                Cancel
                            </Button>{' '}
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.modallpp}
                        toggle={() => this.toggleLPPModal()}
                        className="alway-on-top"
                        contentClassName="full-screen-modal"
                        style={{ margin: 0, flex: 1 }}
                        backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleLPPModal()}>
                            {(assetKey < 0 ? 'New ' + formHeaderText : 'Loss Utility Power Procedure - ' + selAsset.asset_name + ' Details')}
                        </ModalHeader>
                        <ModalBody>


                            <LossOfUtilityPowerProcedureDetails
                                assetTypeID={selAsset.asset_type_id}
                                updateAssetConfigList={this.updateAssetConfigList}
                                assetConfigList={this.state.assetConfigList}
                                companyID={selAsset.company_id}
                                assetKey={assetKey}
                                toggle={this.toggleLPPModal}
                                user={this.props.user}
                                refreshData={this.getAssetTable}
                                isReadOnly={isAccessReadonly}
                                parentAssetKey={parentAssetKey}
                                parentsAssetKey={parentsAssetKey}
                                assetStatus={assetStatus}
                                assetType={assetType}
                                assetCatID={this.state.assetCatID}
                            >
                            </LossOfUtilityPowerProcedureDetails>

                        </ModalBody>

                    </Modal>

                    <Modal isOpen={this.state.modal}
                        className="alway-on-top"
                        toggle={() => this.toggleModal()}
                        size="xl"
                    //onClosed={() => this.getRiser()}
                    //backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleModal()}>
                            {(assetKey < 0 ? 'New ' + formHeaderText : selAsset.asset_id + ' - ' + selAsset.asset_name + ' Details')}
                        </ModalHeader>
                        <ModalBody>


                            <AssetDetailsSections
                                assetTypeID={selAsset.asset_type_id}
                                updateAssetConfigList={this.updateAssetConfigList}
                                assetConfigList={this.state.assetConfigList}
                                companyID={selAsset.company_id}
                                assetKey={selAsset.assetkey} /**assetKey */
                                toggle={this.toggleModal}
                                user={this.props.user}
                                refreshData={this.getAssetTable}
                                isReadOnly={isAccessReadonly}
                                parentAssetKey={parentAssetKey}
                                parentsAssetKey={parentsAssetKey}
                                hierachy_parentkeys={((selAsset.hierachy_parentkeys === undefined || selAsset.hierachy_parentkeys === null) ? '' : selAsset.hierachy_parentkeys)}
                                assetStatus={assetStatus}
                                assetType={assetType}
                                assetCatID={this.state.assetCatID}
                            >
                            </AssetDetailsSections>

                        </ModalBody>

                    </Modal>


                    <Modal isOpen={this.state.modalAdd}
                        className="alway-on-top"
                        toggle={() => this.toggleModalAdd()}
                        size="xl"
                        //onClosed={() => this.getRiser()}
                        backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleModalAdd()}>
                            {(assetKey < 0 ? 'New ' + formHeaderText : selAsset.asset_id + ' - ' + selAsset.asset_name + ' Details')}
                        </ModalHeader>
                        <ModalBody>

                            <AssetDetails
                                assetTypeID={selAsset.asset_type_id}
                                updateAssetConfigList={this.updateAssetConfigList}
                                assetConfigList={this.state.assetConfigList}
                                companyID={selAsset.company_id}
                                assetKey={assetKey}
                                toggle={this.toggleModalAdd}
                                user={this.props.user}
                                refreshData={this.getAssetTable}
                                getValueList={this.getValueList}
                                parentAssetKey={parentAssetKey}
                                parentsAssetKey={parentsAssetKey}
                                isReadOnly={isAccessReadonly}
                                assetStatus={assetStatus}
                                assetType={assetType}
                                assetCatID={this.state.assetCatID}
                            >

                            </AssetDetails>

                        </ModalBody>

                    </Modal>



                    <Modal isOpen={this.state.modalAssetFilter}
                        className="alway-on-top"
                        size="xl"
                        toggle={() => this.toggleModalAssetFilter()}
                        // style={{ margin: 0, flex: 1 }}
                        backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleModalAssetFilter()}>
                            {this.state.filterName}
                        </ModalHeader>
                        <ModalBody>

                            <AssetFilter
                                dsSource={filterValueList}
                                assetFilter={assetFilter}
                                filterResult={filterResult}
                                filterUpdate={this.filterUpdate}
                                companyID={this.state.companyID}
                                toggle={this.toggleModalAssetFilter}
                                toggleOK={this.toggleModalAssetFilterOK}
                                user={this.props.user}
                                userFilter={this.state.userFilter}
                                filterName={this.state.filterName}
                                selFilter={this.state.selFilter}
                                asset_type_key={(assetType[0] !== undefined ? assetType[0].asset_type_key : -1)}
                            >

                            </AssetFilter>

                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.modalMapView}
                        className="alway-on-top"
                        contentClassName="full-screen-modal"
                        toggle={() => this.toggleModalMapView()}
                        style={{ margin: 0, flex: 1 }}
                        backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleModalMapView()}>
                            {"Map View"}
                        </ModalHeader>
                        <ModalBody>

                            <AssetMapView
                                companyID={this.state.companyID}
                                assetCatID={this.state.assetCatID}
                                toggle={this.toggleModalMapView}
                                user={this.props.user}
                                assetTypeList={assetTypeList}
                                basin={subSystem}
                                subSystem={subSystem}
                                selAssetType={selAssetType}
                                selBasin={selBasin}
                                selSubSystem={selSubSystem}
                                assetTypeID={this.state.assetTypeID}
                                assetKey={assetKey}
                                parentAssetKey={parentAssetKey}
                                parentsAssetKey={parentsAssetKey}
                                loadAssetAddonFile={1}
                            // kmzFiles={[{
                            //     fileName: "kmxxx.kmx",
                            //     url: "https://wbrstorageaccountprod.blob.core.windows.net/files/tasset/WTC/5232/MOONLIGHT%20A%20EXTENSION%20-%20IFC%20-%2020240506.kmz"
                            // }]}
                            >


                            </AssetMapView>

                            {/* <AssetMapViewSimple
                                            toggle={this.toggleModalMapView}
                                            user={this.props.user}
                                            //key={Math.random()}
                                            companyID={this.state.companyID}
                                            showPipeline={false}
                                            showLabel={true}
                                            showSubsystem={false}
                                            basinKMLFile={'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRNDBRoutes.kml'}
                                            subSystemKMLFile={null}
                                            assetCoor={[{
                                                asset_name: "Hellow",
                                                lat: 29.740700,
                                                lng: -95.463600
                                            }]}
                                        >

                                        </AssetMapViewSimple> */}

                        </ModalBody>

                    </Modal>


                    <div>
                        <Modal isOpen={this.state.modalWMassUpload} toggle={() => this.toggleMassUpload()}
                            //contentClassName="eventDetail-modal-style"
                            //onClosed={() => this.getAppCheckPoint(-1)}
                            size="md"
                        >
                            <ModalHeader toggle={() => this.toggleMassUpload()}>  Mass Update </ModalHeader>
                            <ModalBody>
                                <div>
                                    <Row form>
                                        <input
                                            type="file"
                                            id="file"
                                            ref="fileUploader"
                                            onChange={this.filePathset.bind(this)}
                                        />

                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button outline color="secondary"
                                    className="float-right"
                                    onClick={this.readFile}
                                    style={{ marginBottom: "5px" }}>

                                    OK
                                </Button>{' '}
                                <Button outline color="danger"
                                    className="float-right"
                                    onClick={this.toggleMassUpload}
                                    style={{ marginBottom: "5px" }}>

                                    Cancel
                                </Button>{' '}
                            </ModalFooter>

                        </Modal>
                    </div>

                    <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{this.state.msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {this.state.msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>

                </Form>
            </div>)
    }

    getFieldDataType = (field_name) => {
        const { assetConfig } = this.state
        var data_type = ''
        if (assetConfig !== undefined) {
            assetConfig.map(e => {
                e.asset_config.map(f => {
                    f.meta_datas.map(g => {
                        if (g.meta_id === field_name) {
                            // // // console.log(g.data_type)
                            data_type = g.data_type
                            return
                        }
                    })

                    if (data_type !== '') {
                        return
                    }
                })
                if (data_type !== '') {
                    return
                }
            })
        }

        //// // // console.log("HERERE")
        return data_type
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        const dataTypeList = []
        var data_type = ''

        let gridDts = this.grid.dataSource // this.state.inspItems


        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");
        var f = 0

        for (let j = 0; j < actCols.count; j++) {
            if (!actCols.item(j).isHidden) {


                for (let i = 0; i < headers.length; i++) {

                    if (headers[i] === actCols.item(j).field) {
                        // // // // console.log("headers[i],actCols.item(j).field")
                        // // // // console.log(headers[i], actCols.item(j).field, j, f)

                        ws.rows(0).cells(f).value = actCols.item(j).headerText //headers[i];
                        if ((actCols.item(j).headerText || "") === "") {
                            ws.rows(0).cells(f).value = actCols.item(j).field
                        }
                        f++
                    }
                }
            }

        }


        for (let i = 0; i < this.grid.actualDataSource.actualCount; i++) {
            const dataRow = this.grid.actualDataSource.getItemAtIndex(i)
            const xlRow = ws.rows(i + 1);
            f = 0
            for (let k = 0; k < actCols.count; k++) {
                console.log("actCols.item(k)")
                console.log(actCols.item(k))
                if (!actCols.item(k).isHidden) {

                    for (let j = 0; j < headers.length; j++) {
                        if (headers[j] === actCols.item(k).field) {
                            data_type = ''

                            let dataType = dataTypeList.filter(e => e.meta_id === headers[j])
                            if (dataType.length === 0) {
                                let temp = {
                                    meta_id: headers[j],
                                    data_type: this.getFieldDataType(headers[j])
                                }
                                dataTypeList.push(temp)
                                data_type = temp.data_type
                            } else {
                                data_type = dataType[0].data_type
                            }

                            if ((data_type === 'date' ||
                                headers[j] === "created_at" ||
                                headers[j] === "rrc_h5_due" ||
                                headers[j] === "aprv_ate" ||
                                headers[j] === "last_h5" ||
                                headers[j] === "h10_due" ||
                                headers[j] === "modified_at")
                                && dataRow[headers[j]] !== null) {
                                // // // // console.log("header[j]")
                                //// // // console.log(headers[j])
                                // // // console.log(dataRow[headers[j]])
                                // // // console.log(FormatDate(dataRow[headers[j]]))

                                xlRow.setCellValue(f, FormatDate(dataRow[headers[j]]));
                            } else {
                                xlRow.setCellValue(f, dataRow[headers[j]]);
                            }
                            f++
                        }

                    }
                }
            }
        }


        ExcelUtility.save(wb, "Asset_" + this.state.assetTypeID);
    }


}