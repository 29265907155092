import React,{Component, useState } from 'react';
import { Col, Input,Row, Button, FormGroup, Label, FormFeedback } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import { apiURL, apiHeader,apiPUTHeader,apiPOSTHeader,apiDELETEHeader } from '../resources/apiURL';
import { Card, CardBody, CardText,CardHeader, CardFooter,CardDeck,CardColumns,UncontrolledTooltip } from 'reactstrap';

import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refreshGreen.svg'
import Trash from '../resources/trash.svg'
import GlobeBW from '../resources/globebw.svg' //HN-11/18/21
import '../custom.css';
import '../customDiv.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth,getUserADAccount } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

const CardSummary = (props) => {
    const opsInspCmt = props.opsInspCmt
    const linkDataLevel = props.linkDataLevel
    const user = props.user

    const style = { width: "100%",padding:"0px 0px 2px 10px" };

    const hdrStyle = { height: "35px", padding:"5px 5px 0px 20px"};
    
    const hdrStyleInspComplete= { height: "35px", padding:"5px 5px 0px 20px" ,backgroundColor: '#90EE90', borderColor: '#333'};
    

    const CommentLineBreak = (props) =>{     
        
        if (props.comments === null){
            return(<div></div>)
        }else
        return(<div>

        {props.comments.split("{/n}").map( i=> 
            <div>{i}</div>
            // i.split("\n").map(k=> 
            // <p>{k}</p> )
            )

        }

        </div>
        )
    }

    const getAtText = (v) => {
       // console.log("InspCmt")        
       // console.log(v.facilityInspectionHdrKey)
       // console.log(isNaN(v.facilityInspectionHdrKey))

        if (linkDataLevel==3){
            var returnText = " - At: " + v.facilityName 
            if (v.facilityInspectionHdrKey !== undefined && v.facilityInspectionHdrKey !== null){
                if (v.needReviewCount>0){
                    returnText = returnText + ' (' + v.needReviewCount + " need review)"
                }
            }
            return returnText
        }else if (linkDataLevel==2){
            return " - At: " + v.subAreaName
        }else if (linkDataLevel==1){
            return " - At: " + v.areaName
        } else {return ""}
    }

    const refreshComment = () =>{
        props.refreshComment()
    }
    const createOperationCommentForDeleteDTO = () => {
        const operationCommentKey = opsInspCmt.operationCommentKey
        const opsCmtDTO= {
            "operationCommentKey":operationCommentKey,
            "deletedBy":getUserADAccount(user.account.userName),
        }
        return opsCmtDTO
    }

    const deleteData = () =>{
        const delDTO = createOperationCommentForDeleteDTO()
        let myURI = apiURL + 'FacilityInspLineItem/OpsComment'

        fetch(myURI , apiDELETEHeader(delDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
              console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              //return Promise.reject(error);
              return false
            }
    
            console.log("No error here: " + rspData)                
          })
          .catch(error =>{
            console.log("PUT Error: " + error);
            return false
          })
        
        return true
    }


  const editComment = () =>{
        props.editComment(opsInspCmt.operationCommentKey,
                          opsInspCmt.commentDate,
                          opsInspCmt.comments)
    }

    const openFacInsp = () => {
        const url = "/#/facility-insp-dtl?inspHdrKey=" + opsInspCmt.facilityInspectionHdrKey;
        window.open(url,'_blank')
    }
  
    const openInspInGooleMap = () => {
        const url = "https://www.google.com/maps/search/?api=1&query=" + opsInspCmt.latitude + "," + opsInspCmt.longtitude
        window.open(url,'_blank')
    }

    const getInspCoor = () => {
        return opsInspCmt.latitude + "," + opsInspCmt.longtitude        
    }
    
    const getHdrStyle =(v)=>{
        if (v.statusKey!==undefined && v.statusKey !== null){
            if (v.statusKey==6){
                return hdrStyleInspComplete
            }else return hdrStyle
        }else{
            return hdrStyle
        }
    }

    return(<div>         
         <CardHeader tag="h5" style={getHdrStyle(opsInspCmt)} color="success" > 
         <Row>
         <Col sm={10}> 
                {new Date(opsInspCmt.commentDate).toLocaleString() +  
                                " - By: " + opsInspCmt.createdBy + 
                                getAtText(opsInspCmt)} 

                                <Button outline color="secondary" 
                                className="float-right btn-no-border" 
                                onClick={(e) => editComment()}
                                hidden={!(opsInspCmt.facilityInspectionHdrKey===null || (opsInspCmt.facilityInspectionHdrKey===undefined))}
                                style={{padding:"0 0 5px 0"}}
                                // style={{margin:"-15px 0 0 0"}}
                                >
                                    <img 
                                    src={MenuIcon}
                                    alt="new"
                                    style={{width:"20px", margin:"0 5px 3px 0"}}/>
                                    Edit
                                </Button>{' '}   
                    </Col>
                    <Col sm={2}>   
                    <Button className="float-right" 
                                        outline
                                        color="link"
                                        style={{padding:"0 0 8px 0"}}
                                        id="googleMap"
                                        hidden={isNaN(opsInspCmt.facilityInspectionHdrKey)} // linkDataLevel!=3}
                                        onClick={(e) => openInspInGooleMap()}> 
                                  <img 
                                    src={GlobeBW}
                                    alt="Map"
                                    style={{width:"20px", margin:"0 5px 3px 10px"}}/>
                                    
                    </Button>
                    <Button className="float-right" 
                                        outline
                                        color="link"
                                        style={{padding:"0 0 8px 0"}}
                                        hidden={isNaN(opsInspCmt.facilityInspectionHdrKey)} // linkDataLevel!=3}
                                        onClick={(e) => openFacInsp()}> 
                                    {opsInspCmt.inspectionNo}
                                </Button>
                   
                                
                                <ConfirmModal outline={true}
                                //   style={{margin:"-15px 0 0 0"}}
                                 style={{padding:"0 0 10px 0"}}
                                  color="danger"
                                  className="float-right btn-no-border"
                                  buttonLabel=""                                  
                                  formName="Confirm"
                                  hidden={!(opsInspCmt.facilityInspectionHdrKey===null || (opsInspCmt.facilityInspectionHdrKey===undefined))}  //{!(linkDataLevel<3) && !isNaN(opsInspCmt.facilityInspectionHdrKey)}                                  
                                  message="Are you sure you want to delete selected comment record?"
                                  onClickYes={(e) => deleteData()}
                                  onClosed={() => refreshComment()} 
                                  image={<img
                                    src={Trash}                                   
                                    alt="Edit"
                                    style={{width:"15px", margin:"0 0 15px 0"}}/>}>
                                </ConfirmModal>  
                                </Col>
                    
                </Row>
            </CardHeader>
            
                <Card style={style} body className="text-left">                
                    <CardText>  <CommentLineBreak comments={opsInspCmt.comments} /> 
                    </CardText>
                    {/* <Input type="textarea"
                        defaultValue={opsInspCmt.comments}
                        style={{width:"100%",margin:"0px 0px 0px 0px"}}>
                    </Input> */}                                        
         </Card>
    </div>)
}

const InspectionCommentCard=(props)=>{
    const opsInspCmts = props.opsInspCmts
    const linkDataLevel = props.linkDataLevel
    const style = { width: "100%", padding:"0 0 10px 0" };
    return<Row>
        {opsInspCmts.map( v=>
            <Card style={style}>
                <CardSummary opsInspCmt={v} 
                             linkDataLevel={linkDataLevel}
                             refreshComment={props.refreshComment}
                             editComment={props.editComment}
                             user={props.user} />
            </Card>
        )
        }
    </Row>
    
}


export default class InspectionDashBoardComment extends Component {
    static displayName = InspectionDashBoardComment.name;

    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
       // this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state ={
            user:props.user,
            inspComment:[],
            isLoaded:false,
            forceReload:false,
            selectedValue:props.selectedValue,
            startDate:(props.selectedValue.startDate===undefined ? props.startDate : props.selectedValue.startDate),
            endDate: (props.selectedValue.endDate===undefined ? props.endDate : props.selectedValue.endDate),
            cmtDate:(props.selectedValue.endDate===undefined ? props.endDate : props.selectedValue.endDate),
            linkDataLevel:(props.linkDataLevel === undefined ? 0 : props.linkDataLevel),
            modal:false,
            comments:" ",
            operationCommentKey:-1
        }

    }

    handleChange=(v,f) => {
        this.setState({
            cmtDate:v
        })
    }

    toggle = () => {  
       // console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
    }

    validateData=()=>{
        var {comments}=this.state
        comments = comments.trim()
        if (comments!=""){
            return true
        }else{
            this.setState({comments:comments})
        }
    }

    
    // onGridRowSelected(s, e) {
    //     console.log(" On CLick")
    //     //const content = e.content;
    //     if (e.addedKeys.count > 0) {
    //         let clickedRow = e.addedKeys.item(0) 
    //         let dataItem = clickedRow.value[0] 
    //         console.log(dataItem)       
    //         this.setState({operationCommentKey: dataItem.operationCommentKey})       
    //     } 
    // }

    createOperationCommentForDeleteDTO = () => {
        const {operationCommentKey,user} = this.state
        const opsCmtDTO= {
            "operationCommentKey":operationCommentKey,
            "deletedBy":getUserADAccount(user.account.userName),
        }
        return opsCmtDTO
    }

    deleteData = () =>{
        const delDTO = this.createOperationCommentForDeleteDTO()
        let myURI = apiURL + 'FacilityInspLineItem/OpsComment'

        fetch(myURI , apiDELETEHeader(delDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
              console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              //return Promise.reject(error);
              return false
            }
    
            console.log("No error here: " + rspData)                
          })
          .catch(error =>{
            console.log("PUT Error: " + error);
            return false
          })
        
        return true
    }

    createOperationCommentForUpdateDTO = () => {
        const {operationCommentKey,comments,user} = this.state
        const opsCmtDTO= {
            "operationCommentKey":operationCommentKey,
            "comments":comments,
            "modifiedBy":getUserADAccount(user.account.userName),
            "headerComment":null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    updateData = () =>{
        const uptDTO = this.createOperationCommentForUpdateDTO()
        let myURI = apiURL + 'FacilityInspLineItem/OpsComment'

        //console.log("uptDTO")
        //console.log(uptDTO)
        fetch(myURI , apiPUTHeader(uptDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
              console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              //return Promise.reject(error);
              return false
            }
    
            console.log("No error here: " + rspData)                
          })
          .catch(error =>{
            console.log("PUT Error: " + error);
            return false
          })
        
        return true
    }


    createOperationCommentForCreateDTO = () => {
        const {selectedValue,cmtDate,comments,user} = this.state
        var key = selectedValue.key
        var faciliyKey = null
        if (selectedValue.key === null && selectedValue.level==2){
            key = selectedValue.areaKey
        }else if (selectedValue.level == 3)  {
            faciliyKey = selectedValue.key
            key = null
        }

        const opsCmtDTO= {
            "commentTypeKey":1,
            "compObjectKey":key, //selectedValue.key,
            "facilityKey":faciliyKey,
            "commentDate":cmtDate,
            "comments":comments,
            "createdBy":getUserADAccount(user.account.userName),
            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    saveInspComment = () =>{
        const {operationCommentKey}= this.state
        if (this.validateData()){        
      
            if (operationCommentKey>0){
                if (this.updateData()){
                    this.setState({operationCommentKey:-1})
                }
            }else{
            const addDTO = this.createOperationCommentForCreateDTO()
            let myURI = apiURL + 'FacilityInspLineItem/OpsComment'
            fetch(myURI , apiPOSTHeader(addDTO) )
              .then(async response => {
                const rspData = response.text();
                if (!response.ok){
                  console.log("Error return but you dont see")
                  const error = (rspData && rspData.message) || response.status;
                  //return Promise.reject(error);
                  return false
                }
        
                console.log("No error here: " + rspData)                
              })
              .catch(error =>{
                console.log("PUT Error: " + error);
                return false
              })
            }
            return true
        }

        return false
    }

    toggleOK = () => {
        if (this.saveInspComment()){       
            this.setState({modal: !this.state.modal,
                           comments: " "})      
                  
        }
    }

    refreshComment = () =>{
        this.getInspComment()
    }
    

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.inspComment;
      
    }

    
    getInspComment = () =>{
        const {selectedValue,linkDataLevel,user} = this.state
        // console.log("-----selectedValue------")
        // console.log(selectedValue)
        // console.log("linkDataLevel")
        // console.log(linkDataLevel)
        

        if (selectedValue !== undefined){

        let myURI=""
        switch (selectedValue.level){
            case 0: //Location Selected
                if (linkDataLevel==1){
                    myURI = apiURL + 'FacilityInspLineItem/OpsComment/Area/Loc/'+ selectedValue.key + '/startdate/' +
                    selectedValue.startDate.toString().substring(0,10) + 
                    '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                }else  if (linkDataLevel==2){
                    myURI = apiURL + 'FacilityInspLineItem/OpsComment/SubArea/Loc/'+ selectedValue.key + '/startdate/' +
                    selectedValue.startDate.toString().substring(0,10) + 
                    '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                }else if (linkDataLevel==3){
                    myURI = apiURL + 'FacilityInspLineItem/OpsComment/Facility/Loc/'+ selectedValue.key + '/startdate/' +
                    selectedValue.startDate.toString().substring(0,10) + 
                    '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                    if (selectedValue.faciltyViewList==='my'){
                        myURI = myURI + '/Employee/' + user.account.userName 
                    }
                }else{
                    myURI = apiURL + 'FacilityInspLineItem/OpsComment/Location/'+ selectedValue.key + '/startdate/' +
                    selectedValue.startDate.toString().substring(0,10) + 
                    '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                }
               
                break;
            case 1: // Area Selected

                if (linkDataLevel==2){
                    myURI = apiURL + 'FacilityInspLineItem/OpsComment/SubArea/Area/'+ selectedValue.key + '/startdate/' +
                    selectedValue.startDate.toString().substring(0,10) + 
                    '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                }else if (linkDataLevel==3){
                    myURI = apiURL + 'FacilityInspLineItem/OpsComment/Facility/ARea/'+ selectedValue.key + '/startdate/' +
                    selectedValue.startDate.toString().substring(0,10) + 
                    '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                    if (selectedValue.faciltyViewList==='my'){
                        myURI = myURI + '/Employee/' + user.account.userName 
                    }
                }else{
                    myURI = apiURL + 'FacilityInspLineItem/OpsComment/Area/'+ selectedValue.key + '/startdate/' +
                    selectedValue.startDate.toString().substring(0,10) + 
                    '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                }

                // myURI = apiURL + 'FacilityInspLineItem/OpsComment/Area/'+ selectedValue.key + '/startdate/' +
                // this.state.startDate.toString().substring(0,10) + 
                // '/enddate/' + this.state.endDate.toString().substring(0,10);
                
                break;
            case 2: //Subarea selected
               // console.log("-----Sub Area selected Value------")
               // console.log(selectedValue)
                
                if (selectedValue.key===null)
                {
                   // console.log("SubArea not found !!!!")
                    if (linkDataLevel==3){
                        myURI = apiURL + 'FacilityInspLineItem/OpsComment/Facility/Area/'+ selectedValue.areaKey + '/startdate/' +
                        selectedValue.startDate.toString().substring(0,10) + 
                        '/enddate/' + selectedValue.endDate.toString().substring(0,10) 
                        if (selectedValue.faciltyViewList==='my'){
                            myURI = myURI + '/Employee/' + user.account.userName 
                        }
                    }else{
                        myURI = apiURL + 'FacilityInspLineItem/OpsComment/Area/'+ selectedValue.areaKey + '/startdate/' +
                        selectedValue.startDate.toString().substring(0,10) + 
                        '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                    }  
                    break
                }
                else{
                    if (linkDataLevel==3){
                        myURI = apiURL + 'FacilityInspLineItem/OpsComment/Facility/SubArea/'+ selectedValue.key + '/startdate/' +
                        selectedValue.startDate.toString().substring(0,10) + 
                        '/enddate/' + selectedValue.endDate.toString().substring(0,10) 
                        if (selectedValue.faciltyViewList==='my'){
                            myURI = myURI + '/Employee/' + user.account.userName 
                        }
                    }else{
                        myURI = apiURL + 'FacilityInspLineItem/OpsComment/SubArea/'+ selectedValue.key + '/startdate/' +
                        selectedValue.startDate.toString().substring(0,10) + 
                        '/enddate/' + selectedValue.endDate.toString().substring(0,10);
                    }  
                }
                break;
            case 3: //FacilitySelected
                myURI = apiURL + 'FacilityInspLineItem/OpsComment/Facility/'+ selectedValue.key + '/startdate/' +
                selectedValue.startDate.toString().substring(0,10) + 
                '/enddate/' + selectedValue.endDate.toString().substring(0,10) 
                    
                break;
            default:
                break;
        }

        
        //this.setState({curSelectedValue:myval})
        selectedValue.reloadData = false
        if (myURI != ""){
            fetch(myURI, {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=> {
                this.setState({
                    inspComment:json.sort((a, b) => a.commentDate > b.commentDate ? -1:1),
                    selectedValue:selectedValue                    
                    })
            })

            //console.log("myURI")
            //console.log(myURI)
        }

        }
    }

  

    onTextChange=(fieldName,fieldValue,e)=>{
        this.setState({comments:fieldValue});    
    }
    newComment = () => {
        this.toggle()
    }

    editComment = (key,date,text) => {
        this.setState({comments:text,
                        cmtDate:date,
                        operationCommentKey:key})
        this.toggle()
    }

    componentDidMount(){
        this.getInspComment();
    }

    componentWillReceiveProps(props) {
       // console.log("PropReceived")
        this.getInspComment()
    }
    render(){
        const {inspComment,linkDataLevel,comments,operationCommentKey,user}=this.state
        const searchText = window.$searchText.toLowerCase()
        
        return(<div>
            <FormGroup hidden={linkDataLevel!==0}>
                    <Row form style={{margin:"-8px 0 0 0"}}>
                        {/* <Col md={8}> */}
                            
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                hidden
                                // style={{margin:"-15px 0 0 0"}}
                                onClick={this.refreshComment}>
                                    <img
                                    src={Refresh}
                                    alt="Refresh"
                                    style={{width: "18px", margin:"0 5px 0 0"}} />
                                    Refresh
                                </Button>               
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.newComment}
                                // style={{margin:"-15px 0 0 0"}}
                                >
                            <img 
                            src={Plus}
                            alt="new"
                            style={{width:"15px", margin:"0 5px 0 0"}}/>
                            New
                        </Button>{' '}                       

                        {/* </Col> */}
                    </Row>
                    <ColoredLine color="grey"/>      
                </FormGroup>

                <FormGroup>
                    <InspectionCommentCard opsInspCmts={inspComment}
                                           linkDataLevel={linkDataLevel}
                                           refreshComment={this.refreshComment}
                                           editComment={this.editComment}
                                           user={user}/>        
                </FormGroup>

                <div>
                <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                        onClosed={() => this.refreshComment()} 
                       //contentClassName="oilTicketDetail-modal-style"
                       size='lg'
                       >
                    <ModalHeader toggle={() => this.toggle()}
                                 > New Comments </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="commentDate" sm={2}>Date</Label>
                            <Col sm={10}>
                            <DatePicker value={this.state.cmtDate} id="commentDate"
                            onChange={(v, f) => this.handleChange(v, f)} disabled={operationCommentKey>0} />
                            </Col>
                        </FormGroup>
                 
                        <FormGroup row>
                            <Label for="exampleText" sm={2}>Text Area</Label>
                            <Col sm={10}>
                            <Input type="textarea" 
                                    name="text" 
                                    id="exampleText" 
                                    rows="6"
                                    defaultValue={comments}
                                    value = {comments}
                                    invalid={comments==""} 
                            onChange={(e) => this.onTextChange("comments",e.target.value,e)}/>
                            <FormFeedback tooltip> Comment can not be blank</FormFeedback>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                        <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                    </ModalFooter>
                </Modal>
                </div>

        </div>)
    }
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.commentDate).toLocaleDateString("es-PA");
    }
}