import React, { Component } from 'react';
import Select from 'react-select';

import {
    Form, Col, Row, Button, FormGroup, Label, Input, CustomInput,
    FormFeedback, InputGroup, InputGroupAddon
} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert  } from 'reactstrap';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import NumberFormat from 'react-number-format';
import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'
import DocumentBrowser from '../resources/DocumentBrowser';
import ItemSupplierLink from './SubForms/ItemSupplierLink';
import ItemTransDetail from './SubForms/ItemTransDetail';
import ItemInvDetails from './SubForms/ItemInvDetails'
import BasinDetails from './SubForms/BasinDetails'
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop'
var DatePicker = require("reactstrap-date-picker");

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

export default class ItemDetail extends Component {
    static displayName = ItemDetail.name;

    constructor(props) {
        super(props)
 
        this.state = {
            conCodeBool:false,
            isItemDetailListLoaded:false,
            itemDetailList: [],
            conCodeID: [],
            itemIDKey: props.itemIDKey,
            modalMsg:false,
            alertMessage:"",
            showAlert:false,
            isTransactionLoaded: false,
            transaction: [],
            isOnHandByLocLoaded: false,
            onHandByLoc: [],
            isLoaded: false,
            itemDetail: [],
            isUOMLoaded: false,
            uomList: [],
            isItemTypeLoaded: false,
            itemType: [],
            isItemCatLoaded: false,
            itemCat: [],
            isConCodeLoaded: false,
            conCodeList: [],
            isSCLoaded: false,
            scList: [],
            isSupLoaded: false,
            supList: [],
            offLineLinkKey: -1,
            activeTab: '7',
            createdBy: props.createdBy,
            itemMasterKey: props.itemMasterKey,
            duplicateBool: props.duplicateBool,
            setStateOfParent:props.setStateOfParent,
            setStateOfParent2: props.setStateOfParent2,
            itemStatus: props.itemStatus,
            isReadOnly: false,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            validDataItem: {
                itemNumber: true,
                itemDesc: true,
                categoryKey: true,
                spendCategory:true

            }
            // invalidDataItem: {
            //     itemDesc: true,
            //     categoryKey: true,
            //     spendCategory:true
            // }
        }
    }
    toggleAlert = () => {
        this.setState({ showAlert: !this.state.showAlert })
    }

    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const returnVal = { "ok": true, "message": "", "headerValidation": [] }
            const { itemDetail, validDataItem } = this.state


            // if (rcvrDetail[0].toLocationKey === null) {
            //     validDataItem.toLocationKey = false
            //     returnVal.ok = false
            //     returnVal.message = "Please enter missing data in red"
            // }

            if ((itemDetail[0].itemDesc || "") === "") {
                validDataItem.itemDesc = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data in red"
            }

            if ((itemDetail[0].categoryKey || "") == 0) {
                validDataItem.categoryKey = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data"
            }
            if ((itemDetail[0].spendCategory || "") === "") {
                validDataItem.spendCategory = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data"
            }


            returnVal.headerValidation = validDataItem
            //Header Validation
           

            resolve(returnVal)
        })
    }


    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }


    setIsReadOnly = (statusKey) => {
        // const {isQHSEAdmin,isQHSEApprover}= this.state
        // //return (statusKey==6 || (!isQHSEAdmin && statusKey==4))

        // return (statusKey==6 || (!(isQHSEAdmin|| isQHSEApprover) && statusKey==4))

    }

    saveData = () => {
        const { itemMasterKey, duplicateBool } = this.state
        this.dataValidation().then((data) => {


    if (data.ok) {
        if (duplicateBool){
           console.log("Duplicate hit")
       
            this.duplicateItem()
                }
            else {
                    this.setState({
                        alertMessage: data.message,
                        validDataItem: data.headerValidation,
                        // toggleMsg: true
                        showAlert:  true
                    })
                }    
     
        }

        if(data.ok){
             if (itemMasterKey > 0 && !duplicateBool) {
                 console.log("This also hit tho")
                this.updateItem()
            }
            else {
                this.setState({
                    alertMessage: data.message,
                    validDataItem: data.headerValidation,
                    // toggleMsg: true
                    showAlert:  true
                })
            }   
            
        }

       if(data.ok) {
           if(itemMasterKey < 0){
            this.addItem()
            console.log("This hit bro")
           }
           else {
            this.setState({
                alertMessage: data.message,
                validDataItem: data.headerValidation,
                // toggleMsg: true
                showAlert:  true
            })
        }   

        }
    })

        this.props.refreshData()
        // this.closeForm(true)
    }

    updateItem = () => {
        const updateItemDTO = this.createItemMasterUpdateDTO()

        let myURI = apiURL + 'ItemMaster'
        fetch(myURI, apiPUTHeader(updateItemDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    this.closeForm(true)
                    return Promise.reject(error);

                }
                this.closeForm(true)
                // console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })

    }
    duplicateItem = () => {
        const createItemDuplicateDTO = this.createItemMasterDuplicateCreateDTO()

        console.log("createItemDTO")
        console.log(createItemDuplicateDTO)
        let myURI = apiURL + 'ItemMaster'
        fetch(myURI, apiPOSTHeader(createItemDuplicateDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    // this.closeForm(true)
                    return Promise.reject(error)
                } else
                this.closeForm(true)
                    return response.json()
                    
            }).catch(error => {
                console.log("POST error: " + error)
            })
            this.props.setStateOfParent(false)

        // const createItemDTO = this.createItemMasterCreateDTO()

        // let myURI = apiURL + 'ItemMaster'
        // fetch(myURI, apiPOSTHeader(createItemDTO))
        //     .then(async response => {


        //         const rspData = response.text();
        //         if (!response.ok) {
        //             console.log("Error return but you dont see")
        //             const error = (rspData && rspData.message) || response.status;
        //             return Promise.reject(error);

        //         }

        //         console.log("No error here: " + rspData)
        //     })
        //     .catch(error => {
        //         console.log("PUT Error: " + error);
        //     })
    }

    addItem = () => {
        const createItemDTO = this.createItemMasterCreateDTO()

        // console.log("createItemDTO")
        // console.log(createItemDTO)
        let myURI = apiURL + 'ItemMaster'
        fetch(myURI, apiPOSTHeader(createItemDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    this.closeForm(true)
                    return Promise.reject(error)
                } else
                this.closeForm(true)
                    return response.json()
                    
            }).catch(error => {
                console.log("POST error: " + error)
            })

        // const createItemDTO = this.createItemMasterCreateDTO()

        // let myURI = apiURL + 'ItemMaster'
        // fetch(myURI, apiPOSTHeader(createItemDTO))
        //     .then(async response => {


        //         const rspData = response.text();
        //         if (!response.ok) {
        //             console.log("Error return but you dont see")
        //             const error = (rspData && rspData.message) || response.status;
        //             return Promise.reject(error);

        //         }

        //         console.log("No error here: " + rspData)
        //     })
        //     .catch(error => {
        //         console.log("PUT Error: " + error);
        //     })
    }

    createItemMasterUpdateDTO = () => {
        const { itemDetail } = this.state
        const { user } = this.props
        const itemUpdateDTO = {
            "itemMasterKey": itemDetail[0].itemMasterKey,
            "itemNumber": itemDetail[0].itemNumber,
            "itemStatus": itemDetail[0].itemStatus,
            "itemTypeKey": itemDetail[0].itemTypeKey,
            "categoryKey": itemDetail[0].categoryKey,
            "inventoryCode": itemDetail[0].inventoryCode,
            "itemDesc": itemDetail[0].itemDesc,
            "stdPurchCost": itemDetail[0].stdPurchCost,
            "qtyOnHand": itemDetail[0].qtyOnHand,
            "purchUOMKey": itemDetail[0].purchUOMKey,
            "stockUOMKey": itemDetail[0].stockUOMKey,
            "barcode": itemDetail[0].barcode,
            "supplierKey": itemDetail[0].supplierKey,
            "modifiedBy": getUserADAccount(user.account.userName),
            "itemPropertyKey": itemDetail[0].itemPropertyKey,
            "conditionCode": itemDetail[0].conditionCode,
            "spendCategory": itemDetail[0].spendCategory,
            "reorderQty": itemDetail[0].reorderQty,
            "minQty": itemDetail[0].minQty
        }
        console.log(itemUpdateDTO)
        return itemUpdateDTO;
    }

    createItemMasterCreateDTO = () => {
        const { itemDetail } = this.state
        const { user } = this.props
        const itemUpdateDTO = {
            "companyID": itemDetail[0].companyID,
            "itemNumber": itemDetail[0].itemNumber,
            "itemStatus": itemDetail[0].itemStatus,
            "itemTypeKey": itemDetail[0].itemTypeKey,
            "categoryKey": itemDetail[0].categoryKey,
            "inventoryCode": itemDetail[0].inventoryCode,
            "itemDesc": itemDetail[0].itemDesc,
            "stdPurchCost": itemDetail[0].stdPurchCost,
            "qtyOnHand": itemDetail[0].qtyOnHand,
            "purchUOMKey": itemDetail[0].purchUOMKey,
            "stockUOMKey": itemDetail[0].stockUOMKey,
            "barcode": itemDetail[0].barcode,
            "supplierKey": itemDetail[0].supplierKey,
            "createdBy": getUserADAccount(user.account.userName),
            "itemPropertyKey": itemDetail[0].itemPropertyKey,
            "conditionCode": itemDetail[0].conditionCode,
            "spendCategory": itemDetail[0].spendCategory,
            "reorderQty": itemDetail[0].reorderQty,
            "minQty": itemDetail[0].minQty,
            "conditionItemMasterKey": itemDetail[0].conditionItemMasterKey,
            "conditionPct": itemDetail[0].conditionPct
        }
        console.log(itemUpdateDTO)
        return itemUpdateDTO;
    }
    createItemMasterDuplicateCreateDTO = () => {
        const { itemDetail, duplicateBool } = this.state
        const { user } = this.props
        const itemUpdateDTO = {
            "companyID": itemDetail[0].companyID,
            "itemNumber": "XXXXXX",
            "itemStatus": itemDetail[0].itemStatus,
            "itemTypeKey": itemDetail[0].itemTypeKey,
            "categoryKey": itemDetail[0].categoryKey,
            "inventoryCode": itemDetail[0].inventoryCode,
            "itemDesc": itemDetail[0].itemDesc,
            "stdPurchCost": itemDetail[0].stdPurchCost,
            "qtyOnHand":  itemDetail[0].qtyOnHand,
            "purchUOMKey": itemDetail[0].purchUOMKey,
            "stockUOMKey": itemDetail[0].stockUOMKey,
            "barcode": itemDetail[0].barcode,
            "supplierKey": itemDetail[0].supplierKey,
            "createdBy": getUserADAccount(user.account.userName),
            "itemPropertyKey": itemDetail[0].itemPropertyKey,
            "conditionCode": itemDetail[0].conditionCode,
            "spendCategory": itemDetail[0].spendCategory,
            "reorderQty": itemDetail[0].reorderQty,
            "minQty": itemDetail[0].minQty
        }
        return itemUpdateDTO;
    }


    onOpenItemMasterDuplicateCreateDTO = () => {
        const {duplicateBool, itemDetail} = this.state
        const { user } = this.props
        const itemUpdateDTO = {
            "itemID": "XXXXX",
            "itemNumber": "XXXXXXXX",
            "itemStatus": "Active", 
            "itemTypeDesc": null,
            "inventoryCode": null,
            "itemDesc": itemDetail[0].itemDesc,
            "stdPurchCost": 0.0,
            "qtyOnHand": 0.0,
            "allocatedQty": 0.0,
            "availableQty": 0.0,
            "purchUOMDesc": "Piece",
            "stockUOMDesc": "Piece",
            "barcode": null,
            "supplierKey": itemDetail[0].supplierKey,
            "createdBy": user.account.name,
            "createdByName": user.account.name,
            "createdDatetime": new Date().toISOString(),
            "modifiedby": null,
            "modifiedbyName": null,
            "modifiedDateTime": null,
            "itemMasterKey": -1,
            "itemPropertyKey": itemDetail[0].itemPropertyKey,
            "itemTypeKey": itemDetail[0].itemTypeKey,
            "categoryKey": itemDetail[0].categoryKey,
            "purchUOMKey": itemDetail[0].purchUOMKey,
            "stockUOMKey": itemDetail[0].stockUOMKey,
            "companyID": "WBR",
            "conditionCode": "",
            "spendCategory": itemDetail[0].spendCategory,
            "reorderQty": 0,
            "minQty": 0 
        }
        console.log(itemUpdateDTO)
        return [itemUpdateDTO];
    }

    getSCString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.spendCategoryID
        })

        return grpKey;
    }

    getSelConCode = (value) =>{
        return value.itemConditionCodeID
    }

    
    getSelCategoryKey = (value) =>{
        // console.log(value.itemCategoryKey)
        return value.itemCategoryKey
    }

    // getSelCategoryKey = (value) => {
    //     var grpKey = ''
    //     value.map(e => {
    //         if (grpKey !== "") {
    //             grpKey = grpKey + ','
    //         }

    //         grpKey = grpKey + e.itemCategoryKey
    //     })

    //     return grpKey;
    // }
    getSelConItemMaster = (value) =>{
        // console.log("xxx" + value.itemMasterKey)
        return value.itemMasterKey
    }

    createItemDetailList = (value) =>{
        var tempList = []
        value.map(e => {
            e.value = e.itemMasterKey
            e.label = e.longItemNumber
            tempList.push(e)
        })
        // console.log(tempList)
        return tempList
    }



    closeForm = (refreshData) => {
        if (refreshData) {
            this.props.refreshData();
            this.props.setStateOfParent(false)
        }

        this.props.toggle()
        this.props.setStateOfParent(false)
    }

    getItemType = () => {
        let myURI = apiURL + 'ItemMaster/ItemType'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isItemTypeLoaded: true,
                    itemType: json
                })
            });
    }

    getConditionCode = () => {
        let myURI = apiURL + 'ItemMaster/ConditionCode'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isConCodeLoaded: true,
                    conCodeList: this.createConCodeList(json),
                    conCodeID: json
                })
            });
    }

    getItemCat = () => {
        let myURI = apiURL + 'ItemMaster/ItemCat'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isItemCatLoaded: true,
                    itemCat: this.createItemCatList(json)
                })
            });
    }

    getUOM = () => {
        let myURI = apiURL + 'ItemMaster/ItemUOM'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isUOMLoaded: true,
                    uomList: json
                })
            });
    }

    getSC = () => {
        let myURI = apiURL + 'ItemMaster/SC'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isSCLoaded: true,
                    scList: this.createSCList(json)
                })
            });
    }

    createSCList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.spendCategoryID
            e.label = e.spendCategoryID
            tempList.push(e)
        })

        return tempList
    }

    getSupplier = () => {
        let myURI = apiURL + 'ItemMaster/Supplier/ActiveOnly'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isSupLoaded: true,
                    supList: this.createSupList(json)
                })
            });
    }

 

    createSupList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.supplier_ID
            e.label = e.alternate_Name
            tempList.push(e)
        })

        return tempList
    }

    testFunction=()=>{
        const{duplicateBool}= this.state
        if (duplicateBool ) {
                console.log("This hit 2")
              const itemDetailDuplicate = this.onOpenItemMasterDuplicateCreateDTO(
                  this.setState({
                    isLoaded: true,
                    itemDetail: itemDetailDuplicate
                  })
              )
            }
    }

    getItemDetail = () => {
        const { itemMasterKey, duplicateBool, itemDetail } = this.state
     

         if (itemMasterKey > 0) {
            console.log("This hit 1")
            let myURI = apiURL + 'ItemMaster/byKey/' + itemMasterKey

            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    this.setState({
                        isLoaded: true,
                        // itemDetail: json,
                        // itemDetailList: this.createItemDetailList(json)
                        itemDetail: json
                        
                    },()=>{
                     if (duplicateBool ) {
                         console.log("Duplication -1 hit")
                        const itemDetailDuplicate = this.onOpenItemMasterDuplicateCreateDTO(itemDetail) 
                        this.setState({
                        isLoaded: true,
                        itemDetail: itemDetailDuplicate
                             })
                         }

                    })
                    
                   
                })
               
               
        }
        
        //  if (duplicateBool ) {
        //     console.log("This hit 2")
        //   const itemDetailDuplicate = this.onOpenItemMasterDuplicateCreateDTO(itemDetail)
        //       this.setState({
        //         isLoaded: true,
        //         itemDetail: itemDetailDuplicate
        //       })
          
        // }
        else{
            // let myURI = apiURL + 'ItemMaster/ByCompany/WBR'

            // fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            //     .then(res => res.json())
            //     .then(json => {

            //         this.setState({
            //             itemDetailList: this.createItemDetailList(json)            
            //         })            
            //     })
            console.log("This hit 3")
            const itemDetail = this.createItemMaster()
            this.setState({
                isLoaded: true,
                itemDetail: itemDetail
            })
        }
       
    }
    getItemDetailList=()=>{
        let myURI = apiURL + 'ItemMaster/ByCompany/WBR'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
    
                this.setState({
                    isItemDetailListLoaded: true,
                    itemDetailList: this.createItemDetailList(json)            
                })            
            })
    }


    // createItemMasterDuplicateCreateDTO = () => {
    //     const { itemDetail } = this.state
    //     const { user } = this.props
    //     const itemUpdateDTO = {
    //         "companyID": itemDetail[0].companyID,
    //         "itemNumber": "XXXXXX",
    //         "itemStatus": itemDetail[0].itemStatus,
    //         "itemTypeKey": itemDetail[0].itemTypeKey,
    //         "categoryKey": itemDetail[0].categoryKey,
    //         "inventoryCode": itemDetail[0].inventoryCode,
    //         "itemDesc": itemDetail[0].itemDesc,
    //         "stdPurchCost": itemDetail[0].stdPurchCost,
    //         "qtyOnHand": itemDetail[0].qtyOnHand,
    //         "purchUOMKey": itemDetail[0].purchUOMKey,
    //         "stockUOMKey": itemDetail[0].stockUOMKey,
    //         "barcode": itemDetail[0].barcode,
    //         "supplierKey": itemDetail[0].supplierKey,
    //         "createdBy": getUserADAccount(user.account.userName),
    //         "itemPropertyKey": itemDetail[0].itemPropertyKey,
    //         "conditionCode": itemDetail[0].conditionCode,
    //         "spendCategory": itemDetail[0].spendCategory,
    //         "reorderQty": itemDetail[0].reorderQty,
    //         "minQty": itemDetail[0].minQty
    //     }
    //     return [itemUpdateDTO]
    // }
    

    createItemMaster = () => {
        const { user } = this.props
        const { offLineLinkKey } = this.state

        const itemData = {
            "itemID": "XXXXX",
            "itemNumber": "XXXXXXXX",
            "itemStatus": "Active",
            "itemTypeDesc": null,
            "inventoryCode": null,
            "itemDesc": "",
            "stdPurchCost": 0.0,
            "qtyOnHand": 0.0,
            "allocatedQty": 0.0, 
            "availableQty": 0.0,
            "purchUOMDesc": "Piece",
            "stockUOMDesc": "Piece",
            "barcode": null,
            "supplierKey": null,
            "createdBy": user.account.name,
            "createdByName": user.account.name,
            "createdDatetime": new Date().toISOString(),
            "modifiedby": null,
            "modifiedbyName": null,
            "modifiedDateTime": null,
            "itemMasterKey": -1,
            "itemPropertyKey": null,
            "itemTypeKey": null,
            "categoryKey": null,
            "purchUOMKey": 1,
            "stockUOMKey": 1,
            "companyID": "WBR",
            "conditionCode": "",
            "spendCategory": "",
            "reorderQty": 0,
            "minQty": 0,
            "conditionItemMasterKey": null,
            "conditionPct": null,
        }



        return [itemData]
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    componentDidMount() {
        
        this.getSupplier()
        this.getSC()
        this.getConditionCode()
        this.getUOM()
        this.getItemType()
        this.getItemCat()
        this.getItemDetail()
        this.getItemDetailList()
       
    }

    getConCodeID = (v)=>{
        const {conCodeID} = this.state
        var test
        if (v !== null && v !== ""){
        test =  conCodeID.filter(e => e.itemConditionCodeKey === v)
        console.log(v)
        // console.log(test[0].itemConditionCodeDesc)
            return Number(test[0].itemConditionCodePct)
            // return (test[0].itemConditionCodeDesc)
        }
        else{
            return null
        }

    }

    getConItemIDItemCat=(e)=>{
        const {itemCat} = this.state
        // console.log(e)
        if(e !== null || e !== ""){
            var test =    itemCat.filter(a => a.itemCategoryKey === e)
            return test[0].itemCategoryKey
        }
        // else{
        //     return null
        // }

    }


    getConItemIDSC=(e)=>{
        const {scList} = this.state
        var grpKey = ''
        if(e === null || e === ""){
           return null
        }
        else{
            // console.log(scList)
         var test =  scList.filter(a=> e.includes(a.spendCategoryID))
            console.log(scList.filter(a=> e.includes(a.spendCategoryID)))
              test.map(test => {
                if (grpKey !== "") {
                    grpKey = grpKey + ','
                }
    
                grpKey = grpKey + test.spendCategoryID
            })
          
            return grpKey;
        
        }
            // var grpKey = ''
            // e.map(e => {
            //     if (grpKey !== "") {
            //         grpKey = grpKey + ','
            //     }
    
            //     grpKey = grpKey + e.spendCategoryID
            // })
    
            // return grpKey;
        

    }

    // getSCString = (value) => {
    //     var grpKey = ''
    //     value.map(e => {
    //         if (grpKey !== "") {
    //             grpKey = grpKey + ','
    //         }

    //         grpKey = grpKey + e.spendCategoryID
    //     })

    //     return grpKey;
    // }



    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        console.log(fieldName + '=' + fieldValue )
        const { itemDetail, itemCat, validDataItem, itemMasterKey } = this.state;

        switch (fieldName) {
            case "itemNumber":
   
                itemDetail[0].itemNumber = fieldValue
                validDataItem.itemNumber = true

                break;
            case "categoryKey":
                // itemDetail[0].categoryKey = fieldValue
                // console.log(e.value)
                // itemDetail[0].categoryKey = this.getSelCategoryKey(e)
                itemDetail[0].categoryKey = (e === null ? null : this.getSelCategoryKey(e))
                validDataItem.categoryKey = true
                if (itemDetail[0].itemMasterKey < 0) {
                    itemDetail[0].itemNumber = 'XXXXXXXX'
               
                }

                break;
            case "inventoryCode":
            
                itemDetail[0].inventoryCode = fieldValue
                break;
            case "conditionCode":
                // console.log(e.value)
                if(e !== null){
                    this.setState({conCodeBool: true})
                }
                else{
                    this.setState({conCodeBool: false})
                    itemDetail[0].conditionItemMasterKey = null
                }
                // console.log(e.value)
                itemDetail[0].conditionCode = (e === null ? "" : this.getSelConCode(e))
               itemDetail[0].conditionPct = (e === null ? "" :  this.getConCodeID(e.value))
               
                break;
            case "conditionItemMasterKey":
                itemDetail[0].conditionItemMasterKey = (e === null ? null : this.getSelConItemMaster(e))
             
                if (e === null || e === ""){
                   
                    itemDetail[0].categoryKey = null
                    itemDetail[0].spendCategory = null
                }
                else{
                    itemDetail[0].categoryKey = this.getConItemIDItemCat(e.categoryKey)
                    itemDetail[0].spendCategory =  this.getConItemIDSC(e.spendCategory)
                    // this.getConItemIDSC(e.spendCategory)
                    console.log(e)
                }
                break;
            case "itemTypeKey":
                itemDetail[0].itemTypeKey = fieldValue
                break;
            case "purchUOMKey":
                itemDetail[0].purchUOMKey = fieldValue
                break;

            case "qtyOnHand":
                itemDetail[0].qtyOnHand = fieldValue
                break;

            case "stockUOMKey":
                itemDetail[0].stockUOMKey = fieldValue
                break;
            case "itemDesc":
                itemDetail[0].itemDesc = fieldValue
                validDataItem.itemDesc = true
                // console.log(itemMasterKey)
          
                break;
            // case "conditionCode":
            //     this.getConCodeID(e.value)
            //     itemDetail[0].conditionCode = fieldValue
            //     break;
            case "spendCategory":
                console.log(e)
                itemDetail[0].spendCategory = this.getSCString(e)
                validDataItem.spendCategory = true
                break;
            case "reorderQty":
                itemDetail[0].reorderQty = fieldValue
                break;
            case "minQty":
                itemDetail[0].minQty = fieldValue
                break;
            case "stdPurchCost":
                itemDetail[0].stdPurchCost = fieldValue
                // this.props.setStateOfParent(false)
                break;

            case "supplierKey":
                itemDetail[0].supplierKey = (e === null ? null : e.value)
                break;
            default:

        }

        this.setState({ itemDetail: itemDetail, validDataItem: validDataItem })
    }

    createConCodeList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.itemConditionCodeKey
            e.label = e.itemConditionCodeID
            tempList.push(e)
        })

        return tempList
    }


    createItemCatList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.itemCategoryKey
            e.label = e.itemCategoryDesc
            tempList.push(e)
        })

        return tempList
    }

    getSelSC = (value) => {
        const { scList } = this.state
        
        if (value !== null && value !== "") {
            return scList.filter(e => value.includes(e.spendCategoryID))
           
        }
        else{
            return ""
        }
   
    }

    
    getSelCategory = (value) => {
        const { itemCat } = this.state

        if (value !== null && value !== "") {
            // console.log(value)
            return itemCat.filter(e => value === e.itemCategoryKey)
        }
        return ""
    }

    
    getConCode = (value) => {
        const { conCodeList } = this.state

        if (value !== null && value !== "") {
            // console.log("xxx" + value)
            // console.log(conCodeList.filter(e => value === e.itemConditionCodeID))
            return conCodeList.filter(e => value === e.itemConditionCodeID)
        }
        return ""
    }

    getConItemMasterKey = (value) => {
        const { itemDetailList } = this.state

        if (value !== null && value !== "") {
            // console.log("xxx" + value)
            // console.log( itemDetailList.filter(e => value === e.itemMasterKey))
            return itemDetailList.filter(e => value === e.itemMasterKey)
        }
        return ""
    }

    getSelSup = (value) => {
        const { supList } = this.state
        return supList.filter(e => value === e.wdSupplierKey)

    }
    
    deactivateData = () => {
        const { itemDetail, itemStatus } = this.state
        if (itemDetail[0].itemStatus == "Active"){
            itemDetail[0].itemStatus = "Inactive"
            this.setState({ itemDetail: itemDetail })
            this.updateItem()
        }
        else{
            itemDetail[0].itemStatus = "Active"
            this.setState({ itemDetail: itemDetail })
            this.updateItem()
        }
 
        // this.closeForm()
    }

    getConditionCodeID = () =>{

    }

 
    render() {
        const { itemDetail, isLoaded, activeTab, isReadOnly, msgBody, msgHeader,
            isUOMLoaded, uomList, isItemTypeLoaded, itemType,
            isItemCatLoaded, itemCat, isConCodeLoaded, itemMasterKey,
            conCodeList, isSCLoaded, scList, isSupLoaded, supList,
             duplicateBool, validDataItem, alertMessage, showAlert,
            itemStatus, itemIDKey, conCodeID, itemDetailList, isItemDetailListLoaded, conCodeBool} = this.state
      
        if (!isSupLoaded) {
            return (<div>
                <h3>Loading supplier list......</h3>
            </div>)
        }
        else if (!isItemDetailListLoaded) {
            return (<div>
                <h3>Loading Item Detail List.......</h3>
            </div>)
        } 
        else if (!isSCLoaded) {
            return (<div>
                <h3>Loading Item Spend Category.......</h3>
            </div>)
        } else if (!isConCodeLoaded) {
            return (<div>
                <h3>Loading Item Condition Code.......</h3>
            </div>)
        } else if (!isItemTypeLoaded) {
            return (<div>
                <h3>Loading Item Master Type.......</h3>
            </div>)
        } else if (!isItemCatLoaded) {
            return (<div>
                <h3>Loading Item Master Category.......</h3>
            </div>)
        } else if (!isUOMLoaded) {
            return (<div>
                <h3>Loading Item Master UOM.......</h3>
            </div>)
        } else if (!isLoaded) {
            return (<div>
                <h3>Loading Item Master Data.......</h3>
            </div>)
        }
        return (
        
        <div>
            <Form>

            <Col sm={6}>
                                <Alert color="danger" toggle={() => this.toggleAlert()}
                                    isOpen={showAlert} style={{ marginBottom: "-5px" }}>
                                    {alertMessage}
                                </Alert >
            </Col>
            <Col></Col>
                <Row form>
                
                          
                    <Col md={4}>
                        <FormGroup>
                            <Label for="itemNo">Item Number</Label>
                            <Input type="text" name="itemNo" id="itemNo"
                                placeholder="" 
                                // disabled={duplicateBool}
                                disabled
                                value={duplicateBool ? "XXXXXX" : itemDetail[0].itemNumber}
                                onChange={(e) => this.onTextChange("itemNumber", e.target.value, e)}
                            />

                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="status">Status</Label>
                            <Input disabled type="text" name="status" id="status" placeholder="" 
                            value={itemDetail[0].itemStatus} />
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="qtyOnHand">Quantity On Hand</Label>
                            <NumberFormat
                             className={"form-control text-md-left"}
                             disabled
                             value={itemDetail[0].qtyOnHand}
                             fixedDecimalScale={true}
                             decimalScale={2}
                             thousandSeparator={true}
                        
                        // prefix={'$'} 
                        />
                        </FormGroup>
                      

                    </Col>
                </Row>
                <Row form>
                
                          
                <Col md={2}>
                    <FormGroup>
                        <Label for="itemNo">Conditional Code</Label>
                        <Select options={conCodeList}
                                isDisabled={itemMasterKey > 0}
                            // className={(!validDataItem.spendCategory? "form-error" : "")}
                                value={this.getConCode(itemDetail[0].conditionCode)}
                                // defaultValue={this.getSelSC(itemDetail[0].spendCategory)}
                                onChange={(e) => this.onTextChange("conditionCode", e, e)}
                                isClearable={true}>

                                </Select>

                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for="itemNo">Percent</Label>
                        <Input type="text" name="itemNo" id="itemNo"
                            placeholder="" 
                            // disabled={duplicateBool}
                            disabled
                            value={itemDetail[0].conditionPct}
                            // onChange={(e) => this.onTextChange("itemNumber", e.target.value, e)}
                        />

                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <Label for="status">Conditional Item Number</Label>
                        <Select 
                        className={"alway-on-top"}
                            options={itemDetailList}
                            isDisabled={(itemDetail[0].conditionCode !== "" || conCodeBool == true ? false : true) || (itemMasterKey > 0 ? true: false)}
                          // className={(!validDataItem.spendCategory? "form-error" : "")}
                              value={this.getConItemMasterKey(itemDetail[0].conditionItemMasterKey)}
                            //   defaultValue={this.getConItemMasterKey(itemDetail[0].conditionItemMasterKey)}
                              onChange={(e) => this.onTextChange("conditionItemMasterKey", e, e)}
                              isClearable={true}>

                              </Select>
                    </FormGroup>
                </Col>

                {/* <Col md={4}>
                    <FormGroup>
                        <Label for="qtyOnHand">Quantity On Hand</Label>
                        <NumberFormat
                         className={"form-control text-md-left"}
                         disabled
                         value={itemDetail[0].qtyOnHand}
                         fixedDecimalScale={true}
                         decimalScale={2}
                         thousandSeparator={true}
                    
                    // prefix={'$'} 
                    />
                    </FormGroup>
                  

                </Col> */}
                           <Col md={4}>


                        <FormGroup>
                            <Label for="allocatedQty">Allocated Quantity</Label>
                            {/* <Input type="text" name="allocatedQty" id="allocatedQty"
                                placeholder="" value={itemDetail[0].allocatedQty}
                                disabled
                                onChange={(e) => this.onTextChange("allocatedQty", e.target.value, e)}
                            /> */}
                            <NumberFormat
                             className={"form-control text-md-left"}
                             disabled
                             value={itemDetail[0].allocatedQty}
                             fixedDecimalScale={true}
                             decimalScale={2}
                             thousandSeparator={true}
                        
                        // prefix={'$'} 
                        />  
                        </FormGroup>



                    </Col>
            </Row>

                <Row form>

                    <Col md={8}>
                        <FormGroup>
                            <Label for="itemDesc">Item Description</Label>
                            <Input type="textarea" name="itemDesc" id="itemDesc"
                            className={(!validDataItem.itemDesc? "form-error" : "")}
                                onChange={(e) => this.onTextChange("itemDesc", e.target.value, e)}
                                disabled={isReadOnly} defaultValue={itemDetail[0].itemDesc}
                                // invalid={!invalidDataItem.itemDesc}
                                
                                />
        
                                
                        </FormGroup>

                    </Col>

                    

                    <Col md={4}>
                        <FormGroup>
                            <Label for="availableQty">Available Quantity</Label>
                            {/* <Input type="text" name="availableQty" id="availableQty"
                                placeholder="" value={itemDetail[0].availableQty}
                                disabled
                                onChange={(e) => this.onTextChange("availableQty", e.target.value, e)}
                            /> */}
                           <NumberFormat
                             className={"form-control text-md-left"}
                             disabled
                             value={itemDetail[0].availableQty}
                             fixedDecimalScale={true}
                             decimalScale={2}
                             thousandSeparator={true}
                        
                        // prefix={'$'} 
                        />  

                        </FormGroup>

                    </Col>

                </Row>



                <Row form>
                    <Col md={4}>
                        <FormGroup>

                            <Label for="itemCat">Item Category</Label>
                            {/* <CustomInput type="select"
                                name="itemCat"
                                id="itemCat"
                                placeholder=""
                                defaultValue={itemDetail[0].categoryKey}
                                className={(!validDataItem.categoryKey ? "form-error" : "")}
                                onChange={(e) => this.onTextChange("categoryKey", e.target.value, e)}
                            >
                                <option value={0}></option>
                                {itemCat.map(v => <option value={v.itemCategoryKey}>{v.itemCategoryDesc}</option>)}

            

                            </CustomInput> */}

                            <Select options={itemCat}
                            //  invalid={!invalidDataItem.spendCategory}
                            // className={(!validDataItem.spendCategory? "alway-in-between form-error" : "alway-in-between")}
                                // isMulti
                                value={this.getSelCategory(itemDetail[0].categoryKey)}
                                // defaultValue={this.getSelCategory(itemDetail[0].categoryKey)}
                                onChange={(e) => this.onTextChange("categoryKey", e, e)}
                                isClearable={true}></Select>
                                  <FormGroup>
                                      
                        {/* <Label for="itemNo">Conditional Code</Label>
                        <Select options={conCodeList}
                                isDisabled={itemMasterKey > 0}
                            // className={(!validDataItem.spendCategory? "form-error" : "")}
                                value={this.getConCode(itemDetail[0].conditionCode)}
                                // defaultValue={this.getSelSC(itemDetail[0].spendCategory)}
                                onChange={(e) => this.onTextChange("conditionCode", e, e)}
                                isClearable={true}>

                                </Select> */}

                    </FormGroup>



                        </FormGroup>
                    </Col>

                    <Col md={4}>

                        <FormGroup>
                            <Label for="spendCategory">Spend Category</Label>

                            <Select options={scList}
                            //  invalid={!invalidDataItem.spendCategory}
                            className={(!validDataItem.spendCategory? "alway-in-between form-error" : "alway-in-between")}
                                isMulti
                                value={this.getSelSC(itemDetail[0].spendCategory)}
                                defaultValue={this.getSelSC(itemDetail[0].spendCategory)}
                                onChange={(e) => this.onTextChange("spendCategory", e, e)}
                                isClearable={true}></Select>
                        </FormGroup>
                    </Col>



                </Row>

                {/* <Row form>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="spendCategory">Spend Category</Label>

                            <Select options={scList}
                                isMulti
                                value={this.getSelSC(itemDetail[0].spendCategory)}
                                defaultValue={this.getSelSC(itemDetail[0].spendCategory)}
                                onChange={(e) => this.onTextChange("spendCategory", e, e)}
                                isClearable={true}></Select>
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <Label for="itemType">Item Type</Label>
                            <CustomInput type="select"
                                name="itemType"
                                id="itemType"
                                placeholder=""
                                defaultValue={itemDetail[0].itemTypeKey}
                                onChange={(e) => this.onTextChange("itemTypeKey", e.target.value, e)}
                            >
                                <option value={0}></option>
                                {itemType.map(v => <option value={v.itemTypeKey}>{v.itemTypeDesc}</option>)}
                            </CustomInput>

                        </FormGroup>
                    </Col>


                    <Col md={4}>


                        <Label for="supplier">Supplier</Label>

                        <Select options={supList}
                            value={this.getSelSup(itemDetail[0].supplierKey)}
                            defaultValue={this.getSelSup(itemDetail[0].supplierKey)}
                            onChange={(e) => this.onTextChange("supplierKey", e, e)}
                            isClearable={true}></Select>


                    </Col>

                </Row> */}



                <Row form>
                  

                    <Col md={4}>
                        <FormGroup>
                            <Label hidden for="stockUOM">Stock UOM</Label>

                            <CustomInput type="select"
                            hidden
                                name="stockUOM"
                                id="stockUOM"
                                onChange={(e) => this.onTextChange("stockUOMKey", e.target.value, e)}
                                disabled={isReadOnly}
                                defaultValue={itemDetail[0].stockUOMKey} >
                                <option value={0}></option>
                                {uomList.map(v => <option value={v.uomKey}>{v.uomDesc}</option>)}
                            </CustomInput>


                        </FormGroup>
                    </Col>


                    <Col md={4}>
                        <FormGroup>
                            <Label hidden for="unitCost">Unit Cost</Label>
                            <Input type="text" name="unitCost" id="unitCost"
                            hidden
                                placeholder="" 
                                value={itemDetail[0].stdPurchCost}
                                onChange={(e) => this.onTextChange("stdPurchCost", e.target.value, e)}
                            />
                        </FormGroup>


                    </Col>

                </Row>



                <FormGroup hidden={duplicateBool} >
                    <div >

                        <Nav tabs hidden={itemMasterKey < 0 }>
                            
                        <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '7' })}
                                    onClick={() => { this.toggleTab('7'); }}
                                >
                                   Basin
                                </NavLink>
                            </NavItem>


                            <NavItem >
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }}
                                >
                                    Stock
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '4' })}
                                    onClick={() => { this.toggleTab('4'); }}
                                >
                                    Transaction
                                </NavLink>
                            </NavItem>


                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}
                                >
                                    Suppliers
                                </NavLink>
                            </NavItem>

                            
                        
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { this.toggleTab('3'); }}
                                >
                                    Documents
                                </NavLink>
                            </NavItem>



                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '6' })}
                                    onClick={() => { this.toggleTab('6'); }}
                                >
                                    Additional Details
                                </NavLink>
                            </NavItem>


                            <NavItem>
                                <NavLink
                                    hidden
                                    className={classnames({ active: activeTab === '5' })}
                                    onClick={() => { this.toggleTab('5'); }}
                                >
                                    Cycle Count
                                </NavLink>
                            </NavItem>



                        </Nav>
                        <TabContent activeTab={activeTab} hidden={itemMasterKey < 0}>
                            <TabPane tabId="1">
                               <ItemInvDetails
                                itemMasterKey = {this.props.itemMasterKey}
                               >

                               </ItemInvDetails>
                            </TabPane>



                            <TabPane tabId="3">
                                <Row style={{ height: "250px" }} >
                                    <Col md={12}>
                                      
                                        {/* <DocumentBrowser buttonLabel="View Image"
                                            buttonColor="primary"
                                            formName="Item Master Document"
                                            linkTableName={"tpmItemMaster"}
                                            linkPrimaryKey={itemMasterKey}
                                            height={"250px"}
                                            apiURL={apiURL + 'itemMaster/Documents/' + itemMasterKey} /> */}

                                <DocumentBrowserDragDrop buttonLabel="View Image"
                                    //    disabled={isFormReadOnly}
                                       buttonColor="primary"
                                       formName="Item Master Document"
                                       linkTableName={"tpmItemMaster"}
                                       linkPrimaryKey={itemMasterKey}
                                       height={"250px"}
                                       // allowDelete={isAFEManager || isAFEAdmin}
                                       apiURL={apiURL + 'itemMaster/Documents/' + itemMasterKey} />

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <ItemTransDetail
                                
                                itemMasterKey = {this.props.itemMasterKey}
                                >
                                </ItemTransDetail>
                            </TabPane>
                            <TabPane tabId="2">
                                <ItemSupplierLink
                                    user={this.props.user}
                                    itemMasterKey={itemMasterKey}
                                    isFormReadOnly={false}
                                    supList={supList}
                                    uomList={uomList}>

                                </ItemSupplierLink>
                            </TabPane>

                            <TabPane tabId="7">
                                <BasinDetails
                                    user={this.props.user}
                                    itemMasterKey={itemMasterKey}
                                    isFormReadOnly={false}
                                    itemIDKey={itemIDKey}
                                    
                                    >
                                   
                                </BasinDetails>
                            </TabPane>

                            <TabPane tabId="5">
                                <div>Cycle Count</div>
                            </TabPane>

                            <TabPane tabId="6">
                                <Row style={{ height: "250px" }} >
                                    <div>Additional Details</div>
                                </Row>
                            </TabPane>

                        </TabContent>

                    </div>
                </FormGroup>



            </Form>


            <FormGroup>
                <Row>
                    <Col sm={12}>
                        <Button color="warning" className="float-right"
                            onClick={() => this.closeForm(false)}>Close</Button>


                        <Button color="primary" className="float-right"
                            style={{ margin: "0px 10px 0px 0px" }}
                            hidden={this.setIsReadOnly(3)}
                            onClick={() => this.saveData()}>{duplicateBool ? "Duplicate" : "Save"}</Button>

                                             
                    <ConfirmModal outline={true}
                                  color={(itemDetail[0].itemStatus == "Inactive" ? "primary" : "danger")}
                                // color={"primary"}
                                //   className={console.log(itemStatus != 'Inactive')}
                                //   className="float-left"
                                // className={console.log(itemStatus)}
                                  buttonLabel={(itemDetail[0].itemStatus == "Inactive" ? "Activate" : "Deactivate")}
                                  hidden={duplicateBool || itemMasterKey < 0}
                                  formName="Confirm"
                                //   id={safetyObservationKey}
                                  message="Are you sure you want to deactivate selected item number?"
                                  onClickYes={(e) => this.deactivateData()}
                                  image={<img
                                    src={TrashBin}
                                    // id={safetyObservationKey}
                                    alt="deactivate"
                                    style={{ width: "20px", margin: "0 5px 0 0" }}/>}>
                        </ConfirmModal>    


                    </Col>


                </Row>
            </FormGroup>
            {/* <div>
                <Modal isOpen={this.state.modalMsg}
                    toggle={() => this.toggleMsg()}
                    size="lg">
                    <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                    </ModalHeader>
                    <ModalBody>
                        {msgBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.toggleMsg()}
                        >
                            OK
                        </Button>
                        {' '}

                    </ModalFooter>
                </Modal>
            </div> */}

        </div>)
    }
}