
import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { FormatDate,IsDate } from '../../resources/utility';

export default class AFECloseoutSummary extends Component {
    static displayName = AFECloseoutSummary.name;

    constructor(props) {
        super(props)

        this.state = {
            afeData: props.afeData,
            isFormReadOnly: props.isFormReadOnly
        }
    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData } = this.state
        switch (fieldName) {
            case "projectedAmount":
                afeData[0].projectedAmount = fieldValue
                break;
            case "projectedAmntComments":
                afeData[0].projectedAmntComments = fieldValue
                break;
            default:
                break;
        }

        this.setState({ afeData: afeData })
        this.props.onTextChange(fieldName, fieldValue, e)
    }

    render() {
        const { afeData } = this.state

        return (<div>
            <Form>
                <h5>Projected Amount</h5>
                <Row form style={{ marginTop: "5px" }}>
                    {/*<Col md={2}>
                        <Label invalid for="budget">Budget</Label>

                         <NumberFormat value={afeData[0].afeTotalAmt}
                            //onValueChange={(e) => this.onTextChange("afeTotalAmt", e.value, e)}
                            className={("form-control text-md-right")}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType={'text'}
                            disabled={true}
                            thousandSeparator={true}
                        />

                    </Col>

                    <Col md={2}>
                        <Label invalid for="invoice">Invoice</Label>

                        <NumberFormat value={afeData[0].invAmount}
                            onValueChange={(e) => this.onTextChange("lat", e.value, e)}
                            className={("form-control text-md-right")}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType={'text'}
                            disabled={true}
                            thousandSeparator={true}
                        />



                    </Col>

                    <Col md={2}>

                        <Label invalid for="actualvsBudget">Actual vs Budget</Label>

                        <NumberFormat value={afeData[0].actualvsBudget}
                            className={("form-control text-md-right")}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType={'text'}
                            disabled={true}
                            thousandSeparator={true}
                        />

                    </Col> */}
                    <Col md={2}>

                        <Label invalid for="projectedAmount">Projected</Label>

                        <NumberFormat value={afeData[0].projectedAmount}
                            className={("form-control text-md-right")}
                            fixedDecimalScale={true}
                            decimalScale={0}
                            displayType={''}
                            // displayType={(isFormReadOnly ? 'text' : '')}
                            onValueChange={(e) => this.onTextChange("projectedAmount", e.value, e)}
                            prefix={'$'}
                            thousandSeparator={true}
                        />

                    </Col>
                    <Col md={6}>
                        <Label invalid for="comments">Projected Comments</Label>

                        <Input type="text" placeholder='Type comments here .....'
                            value={afeData[0].projectedAmntComments}
                            onChange={(e) => this.onTextChange("projectedAmntComments", e.target.value, e)} />

                    </Col>

                    <Col md={2}>
                        <Label invalid for="ProjectedAmntUpdatedDate">Last Updated </Label>
                        <Input type="text" name="field"
                            id="projectedAmntUpdatedDate" placeholder=""
                            disabled={true}
                            value={FormatDate(afeData[0].projectedAmntUpdatedDate)}
                        />
                    </Col>

                    <Col md={2}>
                        <Label invalid for="ProjectedAmntUpdatedBy">By</Label>
                        <Input type="text" name="field"
                            id="projectedAmntUpdatedBy" placeholder=""
                            disabled={true}
                            value={afeData[0].projectedAmntUpdatedBy}
                        />

                    </Col>

                </Row>
            </Form>


        </div>)
    }
}