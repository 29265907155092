import React,{Component, ReactDOM} from 'react';
import { Col, Row, Button, FormGroup, Label,CustomInput } from 'reactstrap';
import {apiURL} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
//import reactDom from 'react-dom';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class FacilityInspection extends Component {
    static displayName = FacilityInspection.name;

    constructor(props){
        super(props);

        this.onSortChanged = () => {
            if (this.pager) {
                this.pager.applySorts(this.grid.sortDescriptions);
            }
        };
        this.onGroupChanged = () => {
            if (this.pager) {
                this.pager.applyGroups(this.grid.groupDescriptions);
            }
        };
        
        this.onFilterChanged = () => {
            if (this.pager) {
                this.pager.applyFilters(this.grid.filterExpressions);
            }
        };

        this.onPageChanged = (pageNumber, data) => {
            this.grid.dataSource = data;
            this.grid.flush();
            this.grid.scrollToRowByIndex(0);
        };
        
        this.onGridRef = this.onGridRef.bind(this);
        this.onPagerRef = this.onPagerRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onInspNoCellUpdating  = this.onInspNoCellUpdating.bind(this);
        this.onReportDateUpdating = this.onReportDateUpdating.bind(this);
        this.onSearchTextChange  = this.props.onSearchTextChange;

        const urlPara=this.getURLParameters(props.parameters)
        
        // console.log("urlPara")
        // console.log(urlPara)

        this.state = {
            inspItems:[],
            isLoaded:false,
            startDate: (urlPara.startDate === null? getFirstDayofMonth().toISOString():urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            endDate: (urlPara.endDate === null ? new Date().toISOString() : urlPara.endDate),
            windowHeight:window.innerHeight,
            modalOpen: true,
            inspType:urlPara.inspType,
            facilityKeys:urlPara.facilityKeys,
            showAllFacility:false
        }
    }

    getURLParameters = (inputPara) => {
        var paraMeters={
            startDate: null ,
            endDate:null ,
            facilityKeys: null ,
            costType:null}
       
            if ( !this.props.location==='undefined' || inputPara!=='undefined')
            {
                var query
                if (!this.props.location==='undefined')
                {
                    query = new URLSearchParams(this.props.location.search);
                
                }else{
                    query = new URLSearchParams(inputPara);            
                }
                
                paraMeters={
                    startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
                    endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
                    facilityKeys:query.get('Facility') ,
                    inspType:query.get('InspType')
                }
            }
        
        
        return paraMeters
    }

    onLoadStatusChange = (value,e) =>{
        this.getInspData(!this.state.showAllFacility)
        this.setState({showAllFacility:!this.state.showAllFacility})
       
    }

    convertStringToDate=(dateString) => {
        var dateParts = dateString.split("/");

            // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        
        return dateObject;
    }

    onSearchTextChange = (v,e) => {
        console.log("Text change in ins:" + v)
    }

    handleModalOpen=()=> {        
        this.setState((prevState) => {
            console.log("click me")
           return{
              modalOpen: !prevState.modalOpen
           }
        })
     }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.inspItems;
      
    }
    onPagerRef(pager) {
        if (!pager) {
            return;
        }
        this.pager = pager;
        if (this.grid) {
            if (this.grid.sortDescriptions.count > 0) {
                this.onSortChanged();
            }
            if (this.grid.groupDescriptions.count > 0) {
                this.onGroupChanged();
            }
            if (this.grid.filterExpressions.size() > 0) {
                this.onFilterChanged();
            }
        }
    }

    getInspData = (showAllFacility=false) => {
        const {facilityKeys,inspType} = this.state

        if (inspType==='Missing'){
            let myURI = apiURL + 'FacilityInspLineItem/missing/startdate/' + 
            this.state.startDate.toString().substring(0, 10) + 
            '/enddate/' + this.state.endDate.toString().substring(0, 10) 
            console.log(myURI);

            fetch(myURI , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
            this.setState({
            isLoaded: true,
            inspItems: (facilityKeys === null ? json: 
                        json.filter(facList => (facilityKeys.includes(facList.facilityKey)))
                        )                 
            })
            });
        }else if (facilityKeys === null) {
            let myURI

            if (showAllFacility){
                myURI = apiURL + 'FacilityInspLineItem/header/startdate/' + 
                this.state.startDate.toString().substring(0, 10) + 
                '/enddate/' + this.state.endDate.toString().substring(0, 10) 
                + '/Employee/' + this.props.user.account.userName;
            }else{
                myURI = apiURL + 'FacilityInspLineItem/header/startdate/' + 
                this.state.startDate.toString().substring(0, 10) + 
                '/enddate/' + this.state.endDate.toString().substring(0, 10) 
                + '/Employee/' + this.props.user.account.userName;
            }
            

            console.log(myURI);

            fetch(myURI , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
            this.setState({
            isLoaded: true,
            inspItems: (facilityKeys === null ? json: 
                        json.filter(facList => (facilityKeys.includes(facList.facilityKey)))
                        ).filter(rec => (inspType==="NeedReview" ? rec.needReviewCount > 0 : true))                   
            })
            });      
            
            
        }
        else{
        let myURI = apiURL + 'FacilityInspLineItem/header/startdate/' + 
                              this.state.startDate.toString().substring(0, 10) + 
                              '/enddate/' + this.state.endDate.toString().substring(0, 10) 
                              //+ '/Employee/' + this.props.user.account.userName;

        console.log(myURI);

        fetch(myURI , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    inspItems: (facilityKeys === null ? json: 
                                json.filter(facList => (facilityKeys.includes(facList.facilityKey)))
                                ).filter(rec => (inspType==="NeedReview" ? rec.needReviewCount > 0 : true))                   
                })
            });
        }     
    }
    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 
    
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
         this.getInspData() 
    }
    

    handleChange(value,formattedValue){
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue

        });
        //console.log(value)
    }

    render() {
        var { isLoaded, inspItems,windowHeight,facilityKeys,showAllFacility } = this.state;     
        const gridHeight=(windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()

        // const query = new URLSearchParams(this.props.location.search);
        // console.log(query.get('startDate'))
         console.log("Key:" + facilityKeys)
        
        if (!isLoaded) {
            return(<div>
                <h3>Loading facility inspection ....</h3>
            </div>)
        }else
        return (
            <div>
                <FormGroup>
                    <Row form>
                        <h2>Facility Inspection |</h2>
                        <Button outline 
                                color="secondary"
                                className="btn-no-border" 
                                onClick={this.getInspData} >
                            <img 
                            src={MenuIcon}
                            alt="refresh"
                            style={{width: "30px", margin:"0 0 0 0"}} />
                            Refresh
                        </Button>{' '}

                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}
                        <FormGroup  style={{margin:"10px 0 0 100px"}} >
                        <h6>
                        <CustomInput className="float-right" 
                                    type="switch" 
                                    id="accept" 
                                    hidden={true} //{!(facilityKeys===null)}
                                    name="accept" 
                                    label="Show All Facilities"                                   
                                    checked={showAllFacility} 
                                    onClick={(e) => this.onLoadStatusChange(1,e)} />
                                    </h6>
                                    </FormGroup>
                        
                    </Row>
                    <ColoredLine color="grey" />        
                
                </FormGroup>

                <div className="alway-on-top">
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <Label style={{margin: "0 0 0 0", padding:"0"}}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate} 
                                        onChange={(v,f) => this.handleChange(v,f)} /> 
                        </Col>
                        <Col md={3}>
                            <Label style={{margin: "0 0 0 0", padding:"0"}} >
                                End Date                                
                            </Label>
                            <DatePicker value={this.state.endDate} className="alway-on-top"
                                        onChange={(v,f) => this.handleEndDateChange(v,f)} /> 
                          
                        </Col>

                    </Row>
                <br />
                <Row> 
                <Col md={12}>
                                               
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}
                        width="100%"
                        dataSource={inspItems.filter(rec => (rec.createByName.toLowerCase().includes(searchText)) ||
                                                            rec.facilityName.toLowerCase().includes(searchText) ||
                                                            rec.inspectionNo.toLowerCase().includes(searchText) ||
                                                            rec.inspComments.toLowerCase().includes(searchText) ||
                                                            rec.areaName.toLowerCase().includes(searchText) ||
                                                            (rec.comments || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                
                        editMode={0} /*0=None, 1=Cell, 2=cellBatch, 3=Row*/
                        //sortDescriptionsChanged={this.onSortChanged}
                        //groupDescriptionsChanged={this.onGroupChanged}
                        //filterExpressionsChanged={this.onFilterChanged}
                        //cellClicked={this.onCellClicked}
                        //defaultColumnMinWidth="80"
                       // summaryScope="Root"
                        //isColumnOptionsEnabled="true"
                        //columnMovingSeparatorWidth="2"
                        selectionMode="SingleRow">
                        <IgrTemplateColumn field="inspectionNo" headerText="Inspection No" width="*>120" 
                            cellUpdating={this.onInspNoCellUpdating}/>
                        <IgrTextColumn field="facilityInspStatusDesc" headerText="Status" width="*>90" />
                        <IgrTextColumn field="areaName" headerText="Sub System" width="*>150" />
                        <IgrTextColumn field="locationName" headerText="Basin" width="*>160" />
                        
                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>150" />
                        <IgrTextColumn field="createByName" headerText="Created By" width="*>150" />
                        <IgrTemplateColumn field="reportDate" headerText="Insp Date" width="*>170"                                           
                                            cellUpdating={this.onReportDateUpdating}/>
                       
                        <IgrTextColumn field="needReviewCount" headerText="Need Review" width="*>150" />  
                        <IgrTextColumn field="inspComments" headerText="Inspection Daily Comments" width="*>450"/>                                         
                        <IgrTextColumn field="facInspComments" headerText="Facility Notes" width="*>450"/>                        
                         
                        <IgrNumericColumn field="facilityInspectionHdrKey" isHidden/>
                    </IgrDataGrid>
                     {/* <DataGridPager
                        ref={this.onPagerRef}
                        dataSource={inspItems}
                        pageSize={50}
                        pagedChanged={this.onPageChanged} /> 
                         */}
                </Col>    
                </Row>
                </FormGroup>   
                </div>
            </div>
        );
    }

  
    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "FacilityInsp");
    }

    
    
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.reportDate).toLocaleDateString("es-PA");
    }
 
    onInspNoCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

            // content.style.verticalAlign = "center";
            // content.style.marginTop = "15px";
            // content.style.lineHeight = "normal";
            // content.style.display = "inline-block";
            // content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";
            content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        link.href = "/#/facility-insp-dtl?inspHdrKey=" + item.facilityInspectionHdrKey;
        link.target = "_blank"
        link.textContent = item.inspectionNo;
    }
 
    

}