
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InputGroupAddon, Col, Row, Button, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText } from 'reactstrap';
import ImageViewer from '../resources/ImageViewer';
import InspItemState from './InspItemState';
import EventDetailModal from './EventDetailModal';
import { apiURL } from '../resources/apiURL';
import '../custom.css';


const showItemText = (itemText, 
                     itemValue,
                     itemFieldId,
                     inspLineDetails, 
                     allowUpdate,
                     onTextChange) => {

    if (itemText) {
        if (allowUpdate){
            return <div style={{margin: "5px 0 0 0"}}>
            <InputGroup>                
            <InputGroupAddon addonType="prepend">               
                <Label style={{margin:"5px 5px 0 0"}}>{itemText} : </Label>
            </InputGroupAddon>
            <Input type="text" defaultValue={itemValue} onChange={(e) => onTextChange(inspLineDetails.facilityInspLineItemKey,
                                                                                      itemFieldId,
                                                                                      e.target.value,
                                                                                      e)}></Input>
           </InputGroup>
           </div>
        }else {
              return (<ListGroupItemText>
            {itemText} : {itemValue}
            </ListGroupItemText>);
        }
      
    } else {
        return "";
    }

}

const hasDoc = (docCount) => {

}

function inspStateClick(inspLines,id){
    console.log("click click " + id);
}


const InspectionItem = (props) => {
    // console.log("this is a test" + props)
    
    const sortInsp = props.data.sort((a, b) => a.inspHeaderOrder - b.inspHeaderOrder);
    var hdrOrder = [];
    var i = 0;
    console.log(sortInsp);
    //console.log(sortInsp);

    hdrOrder[0] = sortInsp[0];
    
    const isAllowEdit = props.isAllowEdit;

    sortInsp.map(function (item) {

        if (hdrOrder[i].inspHeaderOrder !== item.inspHeaderOrder) {

            i = i + 1;
            hdrOrder[i] = item;

        }
    }
    )
        
    const onHandleInpsLineCustValueChange  = (key, custValueLable, value) => {        
       
        props.onHandleInpsLineCustValueChange (key,custValueLable, value)
    }

    const onHandleInspState = (key, value) => {
        console.log("Insp value: " + value)
        props.onHandleInpsLineCustValueChange (key,"inspLineValue", value)
        //props.onHandleInspState(key,value);        
    }

    return (
        <div>
            {hdrOrder.map(function (item) {
                return <ListGroup style={{margin:"10px 0 0 0"}}>
                    <ListGroupItem disabled>
                        <h4>{item.inspHeaderDesc}</h4>
                        {/* {console.log(item.inspHeaderDesc)} */}
                    </ListGroupItem>
                    {sortInsp.map(function (i) {
                        if (item.inspHeaderOrder === i.inspHeaderOrder) {
                            return <ListGroupItem >
                                <InputGroup>
                                    <InspItemState InspLine={i} onHandleInspState={onHandleInspState}/>                                  
                                    <Input disabled value={i.inspLineItemDesc} />                                  
                                    <EventDetailModal buttonLabel="Event" 
                                                      buttonColor="success" 
                                                      formName="Event Detailxs" 
                                                      facilityEventKey={i.facilityEventKey} />                               
                                    <ImageViewer  buttonLabel="Image" 
                                                      buttonColor="success" 
                                                      formName="Inspection Documents"  
                                                      hasDoc={i.docCount > 0 ? 1: 0}
                                                      inspAPIURL={apiURL + 'FacilityInspLineItem/Documents/' + i.facilityInspLineItemKey} />
                                </InputGroup>

                                {showItemText("Comment", i.comments,"comments",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel1, i.customValue1,"customValue1",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel2, i.customValue2,"customValue2",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel3, i.customValue3,"customValue3",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel4, i.customValue4,"customValue4",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel5, i.customValue5,"customValue5",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel6, i.customValue6,"customValue6",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel7, i.customValue7,"customValue7",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel8, i.customValue8,"customValue8",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel9, i.customValue9,"customValue9",i,isAllowEdit,onHandleInpsLineCustValueChange)}
                                {showItemText(i.customValueLabel10, i.customValue10,"customValue10",i,isAllowEdit,onHandleInpsLineCustValueChange)}

                            </ListGroupItem>
                        }
                    })

                    }
                </ListGroup>

            }
            )
            }
        </div>


    );

}

export default InspectionItem;


// return (
//     <div>
//         {hdrOrder.map(function (item) {
//             return <ListGroup>
//                 <ListGroupItem disabled tag="a">
//                     {item.inspHeaderDesc}
//                 </ListGroupItem>
//                 {sortInsp.map(function (i) {
//                     if (item.inspHeaderOrder === i.inspHeaderOrder) {
//                         return <ListGroupItem >
//                             <InputGroup>                                 
//                                 <Button color={inspState(i.inspLineValue)} style={{ margin: "0 5px 0 0" }} >O</Button>
//                                 <Input disabled value={i.inspLineItemDesc} />
//                                 <Button color="success" style={{ margin: "0 5px 0 5px" }} >Event</Button>
//                                 <Button color="success" style={{ margin: "0 5px 0 0" }} >Image</Button>
//                             </InputGroup>
//                         </ListGroupItem>
//                     }
//                 })

//                 }
//             </ListGroup>

//         }
//         )
//         }
//     </div>


// );