//Created By Brandon Nguyen 1/10/22


import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InputGroupAddon, Form,CustomInput, Col, Row, Button, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText,  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ImageViewer from '../resources/ImageViewer';

import EventDetailModal from './EventDetailModal';
import { apiURL } from '../resources/apiURL';

import '../custom.css';
import {useState} from "react";


const showItemText=(headers, isTypeKey, onTextChange, 
    // invTempDetails, itemTypeKey,
     prop1, prop2,  prop3, prop4, prop5, prop6, prop7, prop8, prop9, prop10, prop11, prop12, prop13, prop14, prop15, prop16, prop17, prop18, prop19, prop20, prop21, prop22, prop23, prop24, prop25, prop26, prop27, prop28, prop29, prop30, prop31, prop32, prop33, prop34, prop35, prop36, prop37, prop38, prop39, prop40, 
     itemFieldId, itemFieldId2, itemFieldId3, itemFieldId4, itemFieldId5, itemFieldId6, itemFieldId7, itemFieldId8, itemFieldId9, itemFieldId10, itemFieldId11, itemFieldId12, itemFieldId13, itemFieldId14, itemFieldId15, itemFieldId16, itemFieldId17, itemFieldId18, itemFieldId19, itemFieldId20, itemFieldId21, itemFieldId22, itemFieldId23, itemFieldId24, itemFieldId25, itemFieldId26, itemFieldId27, itemFieldId28, itemFieldId29, itemFieldId30, itemFieldId31, itemFieldId32, itemFieldId33, itemFieldId34, itemFieldId35, itemFieldId36, itemFieldId37, itemFieldId38, itemFieldId39, itemFieldId40,  
     isProp1Title, isProp2Title, isProp3Title,  isProp4Title,  isProp5Title,  isProp6Title,  isProp7Title,  isProp8Title,  isProp9Title,  isProp10Title,  isProp11Title,  isProp12Title, isProp13Title, isProp14Title, isProp15Title, isProp16Title, isProp17Title, isProp18Title, isProp19Title, isProp20Title, isProp21Title, isProp22Title, isProp23Title, isProp24Title, isProp25Title, isProp26Title, isProp27Title, isProp28Title, isProp29Title, isProp30Title, isProp31Title, isProp32Title, isProp33Title, isProp34Title, isProp35Title, isProp36Title, isProp37Title, isProp38Title, isProp39Title, isProp40Title,   
    hasValueList, data,
    dateType, numberType,
    valueList,
    isReadOnly,
    isFleetSupervisors,
    invalidDataItem
   
     )=>{

         if(isTypeKey){

        return  <div style={{margin: "0px 0 0 0"}}>
        <Form Group>   
            
            <Row Form>
                 
                <Col sm={6}>  
                <FormGroup>                         
                 <Label  width="100px" style={{margin:"0px 0px 0 0"}} >{headers}  </Label> 
                 </FormGroup>   
              
                </Col>
        
            <Col sm={6}>                 
                <FormGroup> 

                <Input  style={{margin:"0px 0 0 0"}}
                        type={(dateType ? "date" : numberType ? "number" : "text")}
                        hidden={!hasValueList == 1}
                        disabled={isFleetSupervisors === true ? true: isReadOnly}
                        defaultValue = {isProp1Title? prop1 : isProp2Title? prop2 :  isProp3Title ? prop3 : isProp4Title ? prop4 : isProp5Title ? prop5 : isProp6Title ? prop6 :isProp7Title ? prop7 : isProp8Title ? prop8 : isProp9Title ? prop9 : isProp10Title ? prop10 : isProp11Title ? prop11 : isProp12Title ? prop12 : isProp13Title ? prop13 : isProp14Title ? prop14 : isProp15Title ? prop15 : isProp16Title ? prop16 : isProp17Title ? prop17 : isProp18Title ? prop18 :isProp19Title ? prop19 : isProp20Title ? prop20 : isProp21Title ? prop21 : isProp22Title ? prop22 : isProp23Title ? prop23 : isProp24Title ? prop24 : isProp25Title ? prop25 : isProp26Title ? prop26 : isProp27Title ? prop27 : isProp28Title ? prop28 : isProp29Title ? prop29 : isProp30Title ? prop30 : isProp31Title ? prop31 :  isProp32Title ? prop32 :  isProp33Title ? prop33 : isProp34Title ? prop34 : isProp35Title ? prop35 : isProp36Title ? prop36 : isProp37Title ? prop37 : isProp38Title ? prop38 : isProp39Title ? prop39 : isProp40Title ? prop40 :  null}
                        invalid={isProp24Title ? invalidDataItem : false}
                        onChange={(e)=> onTextChange(isProp1Title ? itemFieldId: isProp2Title ?  itemFieldId2 :  isProp3Title ? itemFieldId3 : isProp4Title ? itemFieldId4 : isProp5Title ? itemFieldId5 : isProp6Title ? itemFieldId6 :isProp7Title ? itemFieldId7 : isProp8Title ? itemFieldId8 : isProp9Title ? itemFieldId9 : isProp10Title ? itemFieldId10 : isProp11Title ? itemFieldId11: isProp12Title ? itemFieldId12 : isProp13Title ? itemFieldId13 : isProp14Title ? itemFieldId14 : isProp15Title ? itemFieldId15 :  isProp16Title ? itemFieldId16 :  isProp17Title ? itemFieldId17 :  isProp18Title ? itemFieldId18 :  isProp19Title ? itemFieldId19 :  isProp20Title ? itemFieldId20 : isProp21Title ? itemFieldId21 :  isProp22Title ? itemFieldId22 :  isProp23Title ? itemFieldId23 :  isProp24Title ? itemFieldId24 :  isProp25Title ? itemFieldId25 :  isProp26Title ? itemFieldId26 :  isProp27Title ? itemFieldId27 :  isProp28Title ? itemFieldId28 :  isProp29Title ? itemFieldId29 :  isProp30Title ? itemFieldId30 : isProp31Title ? itemFieldId31 : isProp32Title ? itemFieldId32 :  isProp33Title ? itemFieldId33 :  isProp34Title ? itemFieldId34 :  isProp35Title ? itemFieldId35 :  isProp36Title ? itemFieldId36 :  isProp37Title ? itemFieldId37 :  isProp38Title ? itemFieldId38 :  isProp39Title ? itemFieldId39 :  isProp40Title ? itemFieldId40 :               
                              null , e.target.value, e)}

                       
                            >                                                                         
                </Input>
               
                <CustomInput
                    hidden={hasValueList}
                    type = "select"
                    disabled={isFleetSupervisors === true ? true: isReadOnly == 1}
                    defaultValue = { isProp1Title? prop1 : isProp2Title? prop2 :  isProp3Title ? prop3 : isProp4Title ? prop4 : isProp5Title ? prop5 : isProp6Title ? prop6 :isProp7Title ? prop7 : isProp8Title ? prop8 : isProp9Title ? prop9 : isProp10Title ? prop10 : isProp11Title ? prop11 : isProp12Title ? prop12 : isProp13Title ? prop13 : isProp14Title ? prop14 : isProp15Title ? prop15 : isProp16Title ? prop16 : isProp17Title ? prop17 : isProp18Title ? prop18 :isProp19Title ? prop19 : isProp20Title ? prop20 : isProp21Title ? prop21 : isProp22Title ? prop22 : isProp23Title ? prop23 : isProp24Title ? prop24 : isProp25Title ? prop25 : isProp26Title ? prop26 : isProp27Title ? prop27 : isProp28Title ? prop28 : isProp29Title ? prop29 : isProp30Title ? prop30 : isProp31Title ? prop31 :  isProp32Title ? prop32 :  isProp33Title ? prop33 : isProp34Title ? prop34 : isProp35Title ? prop35 : isProp36Title ? prop36 : isProp37Title ? prop37 : isProp38Title ? prop38 : isProp39Title ? prop39 : isProp40Title ? prop40 :  null }
                    onChange={(e)=> onTextChange(isProp1Title ? itemFieldId: isProp2Title ?  itemFieldId2 :  
                        isProp3Title ? itemFieldId3 : isProp4Title ? itemFieldId4 : isProp5Title ? itemFieldId5 : isProp6Title ? itemFieldId6 :isProp7Title ? itemFieldId7 : isProp8Title ? itemFieldId8 : isProp9Title ? itemFieldId9 : isProp10Title ? itemFieldId10 : isProp11Title ? itemFieldId11: isProp12Title ? itemFieldId12 : isProp13Title ? itemFieldId13 : isProp14Title ? itemFieldId14 : isProp15Title ? itemFieldId15 :  isProp16Title ? itemFieldId16 :  isProp17Title ? itemFieldId17 :  isProp18Title ? itemFieldId18 :  isProp19Title ? itemFieldId19 :  isProp20Title ? itemFieldId20 : isProp21Title ? itemFieldId21 :  isProp22Title ? itemFieldId22 :  isProp23Title ? itemFieldId23 :  isProp24Title ? itemFieldId24 :  isProp25Title ? itemFieldId25 :  isProp26Title ? itemFieldId26 :  isProp27Title ? itemFieldId27 :  isProp28Title ? itemFieldId28 :  isProp29Title ? itemFieldId29 :  isProp30Title ? itemFieldId30 : isProp31Title ? itemFieldId31 : isProp32Title ? itemFieldId32 :  isProp33Title ? itemFieldId33 :  isProp34Title ? itemFieldId34 :  isProp35Title ? itemFieldId35 :  isProp36Title ? itemFieldId36 :  isProp37Title ? itemFieldId37 :  isProp38Title ? itemFieldId38 :  isProp39Title ? itemFieldId39 :  isProp40Title ? itemFieldId40 :               
                         null , e.target.value, e)}
                >
                    <option value={-100}></option> 
                    {(valueList && typeof valueList === 'string' ? valueList.split("/").map(v => <option className="defaultColor" value={v}>{v}</option>) : "")}
                </CustomInput>


                
                </FormGroup>  
            </Col>
        
        
        </Row>
                
        </Form>
       
        </div>
      
   
             
             
    }

    

    else {
        return ""
    }
    
}





const InventoryItem=(props)=>{
    const sortInventory = props.data.sort((a, b) => a.sortOrder- b.sortOrder );
    const sortPropsInput = props.facInvCollector.sort((a, b) => a.sortOrder- b.sortOrder );
    var hdrOrder = [];
    var hdrOrder2=[]
    var i = 0;

    hdrOrder[0] = sortInventory[0];
    // hdrOrder2[0] = sortPropsInput[0]
    
  const  facilityInvCollectorKey = props.facilityInvCollectorKey

    // const itemTypeKeyDetails = props.itemTypeKeyDetails

    // const onHandleInvTempValueChange  = (key, valueChange, value) => {        
    //     props.onHandleInvTempValueChange(key,valueChange, value)
    // }

 

    const onTextChangeTest=(fieldName,fieldValue,e)=>{
        props.onTextChangeTest(fieldName,fieldValue,e)
    }

    const data = props.data


      // Sort the sortInventory array based on itemField property alphabetically
      sortInventory.sort((a, b) => {
        if (a.itemField < b.itemField) return -1;
        if (a.itemField > b.itemField) return 1;
        return 0;
    });


    return <ListGroup style={{margin:"5px 0 0 0"}}  >
      <div class = "column2">
             {/* {hdrOrder.map(function (item) { */}
                    
                {/* return <ListGroup style={{margin:"5px 0 0 0"}}> */}
                {/* <ListGroupItem disabled>
                    {showItemText(i.itemField)}                                                                            
                </ListGroupItem> */}
                 
                {sortInventory.map(function (i) {


                        if(facilityInvCollectorKey > 0){

                        return <div>
                           
                            {showItemText( (i.itemField), (i.itemTypeKey),   onTextChangeTest , 
                             sortPropsInput[0].prop1, sortPropsInput[0].prop2, sortPropsInput[0].prop3, sortPropsInput[0].prop4, sortPropsInput[0].prop5, sortPropsInput[0].prop6, sortPropsInput[0].prop7,sortPropsInput[0].prop8, sortPropsInput[0].prop9, sortPropsInput[0].prop10,  sortPropsInput[0].prop11,  sortPropsInput[0].prop12, sortPropsInput[0].prop13, sortPropsInput[0].prop14, sortPropsInput[0].prop15, sortPropsInput[0].prop16, sortPropsInput[0].prop17, sortPropsInput[0].prop18, sortPropsInput[0].prop19, sortPropsInput[0].prop20, sortPropsInput[0].prop21,  sortPropsInput[0].prop22,  sortPropsInput[0].prop23,  sortPropsInput[0].prop24,  sortPropsInput[0].prop25,  sortPropsInput[0].prop26,  sortPropsInput[0].prop27,  sortPropsInput[0].prop28,  sortPropsInput[0].prop29,  sortPropsInput[0].prop30, sortPropsInput[0].prop31, sortPropsInput[0].prop32, sortPropsInput[0].prop33, sortPropsInput[0].prop34, sortPropsInput[0].prop35, sortPropsInput[0].prop36, sortPropsInput[0].prop37, sortPropsInput[0].prop38, sortPropsInput[0].prop39, sortPropsInput[0].prop40,  
                            "prop1", "prop2", "prop3", "prop4", "prop5", "prop6", "prop7", "prop8", "prop9", "prop10", "prop11", "prop12", "prop13", "prop14", "prop15","prop16", "prop17", "prop18", "prop19", "prop20", "prop21", "prop22", "prop23", "prop24", "prop25", "prop26", "prop27", "prop28", "prop29", "prop30", "prop31", "prop32", "prop33", "prop34", "prop35", "prop36", "prop37", "prop38", "prop39", "prop40",
                            (i.propField=== "Prop1"), (i.propField === "Prop2"), (i.propField  === "Prop3"), (i.propField  === "Prop4"), (i.propField  === "Prop5"), (i.propField  === "Prop6"), (i.propField  === "Prop7"), (i.propField  === "Prop8"), (i.propField  === "Prop9"), i.propField  === "Prop10", i.propField  === "Prop11",  (i.propField  === "Prop12"), (i.propField  === "Prop13"),  (i.propField  === "Prop14"), (i.propField  === "Prop15"), (i.propField  === "Prop16"), (i.propField  === "Prop17"), (i.propField  === "Prop18"), (i.propField  === "Prop19"), (i.propField  === "Prop20"), (i.propField  === "Prop21"),  (i.propField  === "Prop22"),  (i.propField  === "Prop23"),  (i.propField  === "Prop24"),  (i.propField  === "Prop25"),  (i.propField  === "Prop26"),  (i.propField  === "Prop27"),  (i.propField  === "Prop28"),  (i.propField  === "Prop29"),  (i.propField  === "Prop30"), (i.propField  === "Prop31"),  (i.propField  === "Prop32"),  (i.propField  === "Prop33"),  (i.propField  === "Prop34"),  (i.propField  === "Prop35"),  (i.propField  === "Prop36"),  (i.propField  === "Prop37"),  (i.propField  === "Prop38"),  (i.propField  === "Prop39"),  (i.propField  === "Prop40"),   
                              (i.hasValueList == 0) , data,
                              (i.dataType == "Date"), (i.dataType == "Decimal"),
                               (i.valueList),
                               (i.isReadOnly),
                                props.isFleetSupervisors,
                                props.invalidDataItem
                                )}
                          
                        </div>
                        }
                        else {

                            return <div>
                                {showItemText((i.itemField), (i.itemTypeKey),   onTextChangeTest , 
                                "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
                                "prop1", "prop2", "prop3", "prop4", "prop5", "prop6", "prop7", "prop8", "prop9", "prop10", "prop11", "prop12", "prop13", "prop14", "prop15","prop16", "prop17", "prop18", "prop19", "prop20", "prop21", "prop22", "prop23", "prop24", "prop25", "prop26", "prop27", "prop28", "prop29", "prop30", "prop31", "prop32", "prop33", "prop34", "prop35", "prop36", "prop37", "prop38", "prop39", "prop40",
                                (i.propField=== "Prop1"), (i.propField === "Prop2"), (i.propField  === "Prop3"), (i.propField  === "Prop4"), (i.propField  === "Prop5"), (i.propField  === "Prop6"), (i.propField  === "Prop7"), (i.propField  === "Prop8"), (i.propField  === "Prop9"), i.propField  === "Prop10", i.propField  === "Prop11",  (i.propField  === "Prop12"), (i.propField  === "Prop13"),  (i.propField  === "Prop14"), (i.propField  === "Prop15"), (i.propField  === "Prop16"), (i.propField  === "Prop17"), (i.propField  === "Prop18"), (i.propField  === "Prop19"), (i.propField  === "Prop20"), (i.propField  === "Prop21"),  (i.propField  === "Prop22"),  (i.propField  === "Prop23"),  (i.propField  === "Prop24"),  (i.propField  === "Prop25"),  (i.propField  === "Prop26"),  (i.propField  === "Prop27"),  (i.propField  === "Prop28"),  (i.propField  === "Prop29"),  (i.propField  === "Prop30"), (i.propField  === "Prop31"),  (i.propField  === "Prop32"),  (i.propField  === "Prop33"),  (i.propField  === "Prop34"),  (i.propField  === "Prop35"),  (i.propField  === "Prop36"),  (i.propField  === "Prop37"),  (i.propField  === "Prop38"),  (i.propField  === "Prop39"),  (i.propField  === "Prop40"),   
                                (i.hasValueList == 0) , data,
                                (i.dataType == "Date"), (i.dataType == "Decimal"),
                                (i.valueList),
                                (i.isReadOnly),
                                props.isFleetSupervisors,
                                props.invalidDataItem
                                )}
                            </div>
                        }
                       
                
                })
                

                }
 
                {/* </ListGroup>


           } 
        )
    } */}

         

      </div>
    
    </ListGroup>

}



export default InventoryItem;