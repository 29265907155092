
import {MsalAuthProvider, LoginType} from 'react-aad-msal';

// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: '9d0a2d18-9dd4-4543-a2b9-cfd8ede1e8b2'
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
}


// Authentication Parameters
const authenticationParameters = {
    scopes: ['https://graph.microsoft.com/.default']
}

// Options
const options = {
    loginType: LoginType.Redirect
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)
