import React, { Component, useEffect } from 'react';
import Select from 'react-select';

import {
    Form, Col, Row, Button, FormGroup, Label, Input, CustomInput,
    FormFeedback, InputGroup, InputGroupAddon
} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert  } from 'reactstrap';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import NumberFormat from 'react-number-format';
import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'
import DocumentBrowser from '../resources/DocumentBrowser';
import ItemSupplierLink from './SubForms/ItemSupplierLink';
import ItemTransDetail from './SubForms/ItemTransDetail';
import ItemInvDetails from './SubForms/ItemInvDetails'
import BasinDetails from './SubForms/BasinDetails'
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop'
var DatePicker = require("reactstrap-date-picker");

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



export default class MaterialMove extends Component {
    static displayName = MaterialMove.name;

    constructor(props){
        super(props)

        this.state={      
            moveData:[],
            itemDataList: [],
            isItemDataLoaded:false,
            invLocList: [],
            isWHLoaded:false,
            isInvLocLoaded: false,
            itemMasterKey: props.itemMasterKey,
            itemMasterData: props.itemMasterData
        }
    }

    // getWHData=() =>{
    //     let myURI = apiURL + 'SupplyChain/WH'

    //     console.log(myURI);

    //     fetch(myURI , { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 isWHLoaded: true,
    //                 WHData:  json
    //             })
    //         });
            

    // }


    getItemMaster = ()=>{
        const {itemMasterData} = this.state

        let myURI = apiURL + 'SupplyChain/MaterialRequestItem'
        

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
            //    console.log(json[0].locationKey)
        //   setItemDataArray(createSCList2(json))
        this.setState({
            isItemDataLoaded: true,
            itemDataList: this.createItemDataList(json.filter(a=> a.itemMasterKey === itemMasterData.itemMasterKey))
        })
        
                // setItemData(createItemDataList(selBasinKey !== -1 ? json.filter(a=> a.locationKey === selBasinKey) : json.filter(a=> a.locationKey === locationKey)))
                
        
            });

    }

    getInventoryLoc = () => {
        // const { selBasinKey } = this.state

        let myURI = apiURL + 'SupplyChain/InvLoc'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                    this.setState({
                        isInvLocLoaded: true,
                        invLocList: this.createInvLocList(json)
                    })
                
                    // setInvLocList(createInvLocList(json.filter(e => e.locationKey === selBasinKey)))
                    // setIsInvLocLoaded(true) 
                   
                
            });

    }
    
    createItemDataList(value) {
        var tempList = []
        value.map(e => {
            e.value = e.onHandByLocKey
            e.label = e.itemNumberWHSLoc
            tempList.push(e)
        })
        
        return tempList
    }

    createInvLocList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.invLocationKey
            e.label = e.warehouseInvLocation
            tempList.push(e)
        })

        return tempList
    }
    componentDidMount = () =>{
        // this.getMaterialRequestDetails()
        // this.getWHData()
        this.getItemMaster()
        this.getInventoryLoc()
      
    }

    moveItem= () =>{
    
        const itemMoveDTO= this.createItemMoveDTO()
    
    
          let myURI = apiURL + 'ItemMaster/Move'
        
          fetch(myURI , apiPOSTHeader(itemMoveDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
       
                const error = (rspData && rspData.message) || response.status;
                rspData.then(t => {
                    // setMsgBody(t)
                    // setMsgHeader("Error")
                    // setModalMsg(!modalMsg)
                })
               
                return Promise.reject(error);
               
              }
            //   props.setStateOfParentValidation(false)
      
            // props.resetLineDataOnAdd(true)
     
       
            // refreshData(true)
         
            })
            .catch(error =>{
                // refreshData(true)
              
    
    
            })
        
    
    }

    
    createItemMoveDTO=()=>{
    const {itemMasterKey, moveData, itemMasterData} = this.state 
    const { user } = this.props
    const receiverLineCreateDTO = {

       "ItemMasterKey": itemMasterKey,
       "TransBy": getUserADAccount(user.account.userName),
       "TransQty": moveData.transQty === undefined ? 0.00 : moveData.transQty,
       "UnitCost": moveData.unitCost === undefined ? itemMasterData.stdPurchCost : moveData.unitCost,
       "FromInvLocationKey": moveData.fromInvLocationKey,
       "ToInvLocationKey": moveData.toInvLocationKey,
       "TransMemo": moveData.transMemo,
    
     }
     console.log(receiverLineCreateDTO + "xxx")
     return receiverLineCreateDTO
}

  getSelInvLocKey = (value) => {

    return value.invLocationKey
}

 getSelToLocKey = (value) => {
    return value.invLocationKey
}

onTextChange = (fieldName, fieldValue, e) => { 
    const {moveData} = this.state

    var temp = null
    console.log(fieldName + '=' + fieldValue )


    switch (fieldName) {
     
        case "fromInvLocationKey":
         console.log( e.value)
         moveData.fromInvLocationKey = (e === null ? null : this.getSelInvLocKey(e))
            // moveData[0].fromInvLocationKey = fieldValue
            
       break;

       case "toInvLocationKey": 

       moveData.toInvLocationKey = (e === null ? null : this.getSelToLocKey(e))
    //    validDataItem.spendCategoryID = true
        //    setSpendCategoryIDVal(fieldValue != null)
        break;


         case "transQty": 

         moveData.transQty =  Number(fieldValue)
      //    validDataItem.spendCategoryID = true
          //    setSpendCategoryIDVal(fieldValue != null)
          break;

          case "unitCost": 

         moveData.unitCost =  Number(fieldValue)
      //    validDataItem.spendCategoryID = true
          //    setSpendCategoryIDVal(fieldValue != null)
          break;

          case "transMemo": 

          moveData.transMemo =  fieldValue
       //    validDataItem.spendCategoryID = true
           //    setSpendCategoryIDVal(fieldValue != null)
           break;

         



        default:

    }

    this.setState({moveData: moveData})

}
getSelFromInvLoc = (v) => {
    const {itemDataList, moveData} = this.state
    if (v !== null && v !== "") {
        console.log(v)
            // console.log(itemDataList.filter(e => e.itemMasterKey === v))
        return itemDataList.filter(e => e.invLocationKey === v)
        
        
    }
    return ""
}

getSelFromInvLoc2 = (v) => {
    const {itemDataList, moveData, itemMasterData} = this.state
    if (v !== null && v !== "") {
        if(itemMasterData.qtyOnHand > 0){
            var test = itemDataList.filter(e => e.itemMasterKey === v)
            // console.log(test)
            console.log(v)
            moveData.fromInvLocationKey = test[0].invLocationKey
            // console.log(test[0].invLocationKey)
            // this.setState({moveData : moveData})
            return itemDataList.filter(e => e.itemMasterKey === v)
        }
        else{
            return null
        }
  
    }
  
     
        


    
}

    render(){

        const {itemMasterKey,isWHLoaded, isInvLocLoaded, invLocList, itemDataList, isItemDataLoaded, itemMasterData, moveData} = this.state
        if (!isInvLocLoaded) {
            return (<div>
                <h3>Loading warehouses.....</h3>
            </div>)
        }
        if (!isItemDataLoaded) {
            return (<div>
                <h3>Loading location.....</h3>
            </div>)
        } else
        return(<div>
          <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                From Inv Location
            </Label>
            <Col sm={9}>
                <Select 
                    options={itemDataList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    value={itemDataList.filter(a=> a.itemMasterKey === itemMasterData.itemMasterKey).length > 1 ? this.getSelFromInvLoc(moveData.fromInvLocationKey) :  this.getSelFromInvLoc2(itemMasterData.itemMasterKey)}
                    // value={itemDataList.filter(a=> a.itemMasterKey === itemMasterData.itemMasterKey).length > 1)}
                    onChange={(e) => this.onTextChange("fromInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                To Inv Location
            </Label>
            <Col sm={9}>
                <Select 
                    options={invLocList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    onChange={(e) => this.onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>
        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                Trans Qty
            </Label>
            <Col sm={9}>
                <NumberFormat 
                    // options={basinList}
                    // value={0.00}
                    defaultValue={0.00}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    className={"form-control text-md-right"}
                    // className={!requestQtyVal ? "form-error form-control text-md-right" : "form-control text-md-right"}
                    onValueChange={(e) => this.onTextChange("transQty", e.value, e)}
                    // isClearable={true}
                    >

                </NumberFormat>
            </Col>
            
        </FormGroup>
        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                Unit Cost
            </Label>
            <Col sm={9}>
            <NumberFormat 
                    // options={basinList}
                    // defaultValue={0.00}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    className={"form-control text-md-right"}
                    defaultValue={itemMasterData.stdPurchCost}
                    // className={!requestQtyVal ? "form-error form-control text-md-right" : "form-control text-md-right"}
                    onValueChange={(e) => this.onTextChange("unitCost", e.value, e)}
                    // isClearable={true}
                    >

                </NumberFormat>
            </Col>
            
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                Memo
            </Label>
            <Col sm={9}>
            <Input type="textarea" name="lineComment"
                        rows={4}
                        // value={lineData.lineComment}
                        // defaultValue={lineData.lineComment}
                        //disabled={isFormReadOnly}
                        id="transMemo" 
                       
                        onChange={(e) => this.onTextChange("transMemo", e.target.value, e)}
                    />
            </Col>
            
        </FormGroup>

        <Form style={{ marginTop: "5px" }}>
            <Row>
                <Col md={12}>
                    <div className="float-right">
                        <Button color="warning" className="float-right"
                            onClick={() => this.props.toggle()}
                            >Close</Button>
                              <Button color="primary" className="float-right"
                            //   hidden = {mtlRequestLineKey < 0}
                            style={{ marginRight: "10px" }}
                            onClick={() => this.moveItem()}
                            >Move</Button>

                        
                          
                    </div>
                </Col>
            </Row>
        </Form>

        
        </div>)
    }
}