
import React, { Component,useState } from 'react';
import { FormGroup,Row,Col } from 'reactstrap';
import {Card, Button,  CardText, CardLink,
        CardHeader, CardBody,CardDeck,CardGroup
        } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { apiURL,apiDELETEHeader } from '../resources/apiURL';
import PlusSymbol128 from '../resources/PlusSymbol128.png'
import TrashBin from '../resources/trash.svg'
import DocumentUploader from '../resources/DocumentUploader';
import ConfirmModal from '../operation/ConfirmModal'
import FilePreviewer from 'react-file-previewer';

function DocumentCardList(props){
    const docList = props.docList

    // console.log("docList")
    // console.log(docList)
    const [modalPreview, setModalPreview] = useState(false)
    const [docData,setDocData] = useState(null)

    const addNewDoc=()=>{
        props.toggle();
        //this.setState({})
    }
    
    const deleteDoc=(e)=>{
        //e.preventDefault();
        // console.log("deleteDoc")        
        // console.log(e)

        const hdrDELDto = {
            "docFileName": "",
            "docFileDesc": "",
            "documentKey": e
        }

        // console.log(hdrDELDto)

        let myURI = apiURL + 'Documents'
        fetch(myURI , apiDELETEHeader(hdrDELDto) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
              
              console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);
              
            }
            
            props.refreshDocList();
            console.log("No error here: " + rspData)
          })
          .catch(error =>{
            console.log("POST Error: " + error);
          })

         
         
    }

    const downloadAtt=(e,v)=>{
        e.preventDefault();
        // console.log("download")        
        // console.log(e.target.id)

            
        let myURI = apiURL + 'Documents/Base64/' + e.target.id                
            fetch(myURI)
                .then(res => res.json())
                .then((data) => {                 
                    //const base64 = 'data:image/png;base64,' + data[0].docFileData
                    //const base64 = 'data:video/mp4;base64,' + data[0].docFileData
                    const base64 = data[0].docFileType + data[0].docFileData                    
                    //console.log(data)
                    //triggerBase64Download(base64, 'my_download_name.jpg')
                   
                if(data[0].allowPreview !== 0){
                    previewFileAttachment(data)
                }else{
                    downloadPDF(base64, data[0].docFileName)
                } 
        })
                       
    }

    
    const previewFileAttachment = (docData) => {
        setDocData(docData)
        setModalPreview(true)
    }

    const downloadPDF=(base64,fileName)=> {
       // console.log(base64)
        //const linkSource = 'data:application/pdf;base64;' + {base64};
        const downloadLink = document.createElement("a");
        //const fileName = fileName; //"vct_illustration.mp4";
    
        downloadLink.href = base64;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return<CardGroup>
        {docList.map(v => 
            <Card>   
                <CardHeader> 
                    <Row>
                    <Col sm={8}>
                        <CardLink href="" id={v.documentKey} onClick={(e,v)=>{downloadAtt(e,v)}}>{v.docFileName}</CardLink>
                    </Col>
                    <Col sm={4}>
                 
                    <ConfirmModal outline={true}
                                  color="secondary"
                                  className="btn-no-border float-right"
                                  buttonLabel=""
                                  hidden = {false}
                                  formName="Confirm"
                                 
                                  id={v.documentKey}
                                  message="Are you sure you want to delete this attachment?"
                                  onClickYes={(e) => deleteDoc(v.documentKey)}
                                  image={<img
                                    src={TrashBin}
                                    id={v.documentKey}
                                    alt="Edit"
                                    style={{ width: "30px", margin: "0 0 0 0" }}/>}>

                    </ConfirmModal>


                    </Col>
                    </Row>
                </CardHeader>
                <CardBody>         
               
                <CardText tag="h7">{(v.docFileName===v.docFileDesc? "" : v.docFileDesc)}</CardText>                      
                
                <div>
                        <Modal isOpen={modalPreview} toggle={() => setModalPreview(!modalPreview)}
                            //contentClassName="safetyObsDetail-modal-style"
                            size="xl"
                        >
                            <ModalHeader toggle={() => setModalPreview(!modalPreview)}>{"Preview"}</ModalHeader>
                            <ModalBody>
                                <FilePreviewer
                                    //hideControls={true}
                                    file={{
                                        data: (docData === undefined || docData === null ? null : docData[0].docFileData),
                                        mimeType: (docData === undefined || docData === null ? null : docData[0].docFileTypeShort), //"image/jpg", // "application/pdf" ,//(docData===undefined || docData===null ? null: docData[0].docFileType),
                                        name: (docData === undefined || docData === null ? null : docData[0].docFileName)// for download
                                    }}
                                />

                            </ModalBody>
                        </Modal>
                    </div>
                </CardBody>
            </Card>
        )}   

       <Button outline
           
               color="secondary"
               className="btn-no-border"
               onClick={() => addNewDoc()}>
                   <img
                   src={PlusSymbol128}
                   alt="Refresh"
                   style={{width: "70%", margin:"0 0 0 0"}} />
                   
               </Button>
        </CardGroup>   
    
}


export default class DocumentBrowser extends Component {
    static displayName = DocumentBrowser.name;

    constructor(props){
        super(props);
        this.state={
            apiURL:props.apiURL,
            docList:[],
            isDocListLoaded:false,
            modal:false,
            linkTableName:props.linkTableName,
            linkPrimaryKey:props.linkPrimaryKey,
            disabled:(props.disabled===undefined?false:props.disabled)
        }
    }

    toggle = () => {
        //console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
                
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.docList;
    }

    getDocList = () => {
        const {apiURL} = this.state

        fetch(apiURL)
        .then(res => res.json())
        .then((json) => {
                this.setState({
                    isDocListLoaded:true,
                    docList:json
                })
            })
    }

    addNewDoc = () => {

    }

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getDocList()
    }
    
    refreshForm = () => {
        //console.log("setState")
        this.getDocList()
    }

    render(){
        const {docList,isDocListLoaded,linkTableName,linkPrimaryKey,disabled} = this.state

        if (!isDocListLoaded){
            return(<div>
                <h3>Loading Document List</h3>
                </div>)
        }else
        return (
            <div>                
                <FormGroup>
                    <Row form>
                        <DocumentCardList docList={docList}
                                          toggle={this.toggle}
                                          disabled={disabled}
                                          refreshDocList={this.refreshForm}/>
                    </Row>
                </FormGroup>

                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()} 
                        //contentClassName="safetyObsDetail-modal-style"
                        size="xl"
                        >
                        <ModalHeader toggle={() => this.toggle()}> { this.props.formName }</ModalHeader>
                        <ModalBody>
                        <DocumentUploader   formName="Safety Observation Images"                                    
                                            toggle = {this.toggle}
                                            linkTableName={linkTableName}
                                            linkPrimaryKey={linkPrimaryKey}
                                            refreshDocList={this.refreshForm} />

                        </ModalBody>                   
                    </Modal>
                </div>

            </div>


        )

    }  

}

// const DocumentBrowser = (props) => {
//     const {
//         buttonLabel,
//         className,
//         formName,
//         buttonColor,
//         hasDoc,
//         inspAPIURL
//     } = props;

//     const [modal, setModal] = useState(false);
//     const toggle = () => setModal(!modal);

//     const [isLoading, setIsLoading] = useState(false);
//     var [imgList, setImageList] = useState([]);

//     const openModelForm = () => {

//         var imgs = [];

//         //if (modal) {
//         { console.log(inspAPIURL) }
        
//         setIsLoading(true);
//         setModal(!modal);

//         if (!modal) {
//             fetch(inspAPIURL)
//                 .then(res => res.json())
//                 .then((data) => {
//                     data.map(function (doc) {
//                         console.log(doc.documentKey);
//                         let myURI = apiURL + 'Documents/Base64/' + doc.documentKey
//                         //console.log(myURI)
//                         fetch(myURI)
//                             .then(res => res.json())
//                             .then((data) => {
                                
//                                 const image = {
//                                     src: 'data:image/jpeg;base64,' + data[0].docFileData,
//                                     altText: doc.docFileName,
//                                     caption: '',
//                                     header: doc.docFileName,
//                                     key: doc.documentKey
//                                 }

//                                 imgs.push(image)
                             
//                             })
//                     })

//                     setImageList(imgs);
//                    // 
//                 });
//                 //console.log(imgs);

//                 //setImageList(imgs);
//                 setIsLoading(false);
//         }

       
//     }
//     const [base64Image, setBase64Image] = useState('...');

//     const rowClickHandle = (docKey) => {
//         console.log("Row click click - " + docKey)
//         let myURI = apiURL + 'Documents/Base64/' + docKey
//         console.log(myURI)
//         fetch(myURI)
//             .then(res => res.json())
//             .then((data) => {
//                 console.log(data[0].docFileData);
//                 setBase64Image(data[0].docFileData)
//             })



//     }

//     return (

//         <div>
//             <Button outline={hasDoc > 0 ? false : true}
//                 color={buttonColor}
//                 onClick={openModelForm}>
//                 {buttonLabel}
//             </Button>

//             <Modal isOpen={modal} toggle={toggle} className={className}>
//                 <ModalHeader toggle={toggle}>{formName}</ModalHeader>
//                 <ModalBody>

//                     <IsLoading isLoading={isLoading} hasDoc={hasDoc}/>
//                     <ImageViewer list={imgList} />
                   
                    
//                 </ModalBody>

//                 <ModalFooter>
//                     <Button color="primary" onClick={toggle}>OK</Button>
//                     {/* <Button color="primary" onClick={toggle}>Cancel</Button> */}
//                 </ModalFooter>
//             </Modal>
//         </div>






//     );
// }

// export default DocumentBrowser;

