import React, { Component } from 'react';
import WaveAsset from './WaveAsset';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h4>Hello, {this.props.user.account.name}</h4>        
        {/* <ul>
          <li><a href='https://www.h2obridge.com/' target="_blank">WaterBridge Website</a></li>
          <li><a href='https://h20bridge.sharepoint.com/SitePages/Home.aspx' target="_blank">Sharepoint</a></li>
          <li><a href='https://wd5.myworkday.com/waterbridge/d/home.htmld' target="_blank"> Workday</a> </li>
          
        </ul> */}
        <WaveAsset />
        
        {/* <p>To help you get started, we have also set up:</p>
        <ul>
          <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
          <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
          <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
        </ul>
        <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
       */}
      </div>
    );
  }
}
