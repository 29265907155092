import logo from './refresh.svg';
import '../printcustom.css'

const PrintComponent = ({children}) => {
    const printAction = () => {
        window.print()
    }
    return (<>
          <button className={"print-preview-button"} onClick={printAction}>{"Print Preview"}</button>
        <table className="print-component">
            <thead>
                <tr>
                <th>
                <img src={logo} height={"40px"} width={"40px"} alt="logo" />
                <div>
                {"Page Header"}
                </div>
                </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>
                    {children}
                </td>
                </tr>
            </tbody>
            <tfoot className="table-footer">
                <tr>
                <td>
                {"Page footer"}
                </td>
                </tr>
            </tfoot>
        </table>
        </>
    )
}

export default PrintComponent