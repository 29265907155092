import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import RevisionPng from '../resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal';
import AFERejectModal from './AFE/AFERejectModal'
import AFEManualEdits from './AFE/AFEManualEdits';

import NumberFormat from 'react-number-format';

import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../resources/utility';

import AFEBudget from './AFE/AFEBudget';
import AFEApproval from './AFE/AFEApproval';
import AFEAttachment from './AFE/AFEAttachment';
import AFEComment from './AFE/AFEComment';
import AFEFieldReport from './AFE/AFEFieldReport';
import AFECostSummary from './AFE/AFECostSummary';
import AFEFixAssets, { AFEFixAssetsPopUp } from './AFE/AFEFixAssets'
import AFECloseout, { AFECloseOutPopUp } from './AFE/AFECloseout'
import AFECostTracking from './AFE/AFECostTracking'
import AFEAdditionalDetails from './AFE/AFEAdditionalDetails'
import AFEExportCostDetails from './AFE/AFEExportCostDetails'
import AFEListSelector from './AFE/AFEListSelector'
import RecordLogs from './AFE/RecordLogs';

import OperationChart from './OperationChart'
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
import CustomConfirmModal from '../resources/CustomConfirmModal';

var DatePicker = require("reactstrap-date-picker");

const CostSummary = (props) => {
    const { afeData, isFormReadOnly } = props

    const onTextChange = (fieldName, fieldValue, e) => {

        switch (fieldName) {
            case "intangibleContigencyRate":
                afeData[0].intangibleContigencyRate = e.value
                afeData[0].intangibleContigencyAmt = afeData[0].intangibleAmt * (e.value / 100.0)
                afeData[0].intangibleTotalAmt = afeData[0].intangibleAmt + afeData[0].intangibleContigencyAmt
                props.onTextChange(fieldName, fieldValue, e)
                break;
            case "tangibleContigencyRate":
                afeData[0].tangibleContigencyRate = e.value
                afeData[0].tangibleContigencyAmt = afeData[0].tangibleAmt * (e.value / 100.0)
                afeData[0].tangibleTotalAmt = afeData[0].tangibleAmt + afeData[0].tangibleContigencyAmt
                props.onTextChange(fieldName, fieldValue, e)
                break;
            case "intangibleTaxRate":
                afeData[0].intangibleTaxRate = e.value
                afeData[0].intangibleTaxAmt = afeData[0].intangibleAmt * (e.value / 100.0)
                afeData[0].intangibleTotalAmt = afeData[0].intangibleAmt + afeData[0].intangibleTaxAmt + afeData[0].intangibleContigencyAmt
                props.onTextChange(fieldName, fieldValue, e)

                break;
            case "tangibleTaxRate":
                afeData[0].tangibleTaxRate = e.value
                afeData[0].tangibleTaxAmt = afeData[0].tangibleAmt * (e.value / 100.0)
                afeData[0].tangibleTotalAmt = afeData[0].tangibleAmt + afeData[0].tangibleTaxAmt + afeData[0].tangibleContigencyAmt
                props.onTextChange(fieldName, fieldValue, e)
                break;
            default:
                break;
        }
    }


    return (<div>
        <CardGroup width="100%">
            <Card>
                <CardHeader>
                    Intangible
                    <NumberFormat value={afeData[0].intangibleAmt}
                        className='float-right'
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'} />
                </CardHeader>
                <CardBody>

                    <Row form>
                        <Col md={4}>
                            <Label for="afeNo">Sales Tax</Label>
                        </Col>
                        <Col md={4}>
                            <NumberFormat value={afeData[0].intangibleTaxRate}
                                className={(isFormReadOnly ? 'float-right' : 'form-control text-md-right')}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={(isFormReadOnly ? 'text' : '')}
                                onValueChange={(e) => onTextChange("intangibleTaxRate", e.value, e)}
                                thousandSeparator={true}
                                suffix={'%'}
                            />
                        </Col>
                        <Col md={4}>
                            <NumberFormat value={afeData[0].intangibleTaxAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                    <Row form style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        <Col md={4} style={{ marginTop: "5px" }}>
                            <Label for="afeNo">Contingency</Label>
                        </Col>
                        <Col md={4}>
                            <NumberFormat value={afeData[0].intangibleContigencyRate}
                                className={(isFormReadOnly ? 'float-right' : 'form-control text-md-right')}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={(isFormReadOnly ? 'text' : '')}
                                onValueChange={(e) => onTextChange("intangibleContigencyRate", e.value, e)}
                                thousandSeparator={true}
                                suffix={'%'} />
                        </Col>
                        <Col md={4} style={{ marginTop: "5px" }}>
                            <NumberFormat value={afeData[0].intangibleContigencyAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter style={{ height: "30px" }}>
                    <Row form style={{ marginTop: "-10px" }}>
                        <Col md={6}>
                            <Label for="intSubtotal">Sub Total</Label>
                        </Col>
                        <Col md={6}>
                            <NumberFormat value={afeData[0].intangibleTotalAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                </CardFooter>
            </Card>

            <Card>
                <CardHeader>
                    Tangible
                    <NumberFormat value={afeData[0].tangibleAmt}
                        className='float-right'
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'} />
                </CardHeader>
                <CardBody>

                    <Row form>
                        <Col md={4}>
                            <Label for="afeNo">Sales Tax</Label>
                        </Col>
                        <Col md={4}>
                            <NumberFormat value={afeData[0].tangibleTaxRate}
                                className={(isFormReadOnly ? 'float-right' : 'form-control text-md-right')}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={(isFormReadOnly ? 'text' : '')}
                                thousandSeparator={true}
                                onValueChange={(e) => onTextChange("tangibleTaxRate", e.value, e)}
                                suffix={'%'} />
                        </Col>
                        <Col md={4}>
                            <NumberFormat value={afeData[0].tangibleTaxAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>

                    <Row form style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        <Col md={4} style={{ marginTop: "5px" }}>
                            <Label for="afeNo">Contingency</Label>
                        </Col>
                        <Col md={4}>
                            <   NumberFormat value={afeData[0].tangibleContigencyRate}
                                className={(isFormReadOnly ? 'float-right' : 'form-control text-md-right')}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                onValueChange={(e) => onTextChange("tangibleContigencyRate", e.value, e)}
                                displayType={(isFormReadOnly ? 'text' : '')}
                                thousandSeparator={true}
                                suffix={'%'} />
                        </Col>
                        <Col md={4} style={{ marginTop: "5px" }}>
                            <NumberFormat value={afeData[0].tangibleContigencyAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter style={{ height: "30px" }}>
                    <Row form style={{ marginTop: "-10px" }}>
                        <Col md={6}>
                            <Label for="intSubtotal">Sub Total</Label>
                        </Col>
                        <Col md={6}>
                            <NumberFormat value={afeData[0].tangibleTotalAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                </CardFooter>
            </Card>

            <Card>
                <CardHeader>
                    Total
                    <NumberFormat value={afeData[0].tangibleAmt + afeData[0].intangibleAmt}
                        className='float-right'
                        fixedDecimalScale={true}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'} />
                </CardHeader>
                <CardBody>

                    <Row form>
                        <Col md={4}>
                            <Label for="afeNo">Sales Tax</Label>
                        </Col>
                        <Col md={2}>
                            {/* <NumberFormat value={0.0}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} /> */}
                        </Col>
                        <Col md={6}>
                            <NumberFormat value={afeData[0].tangibleTaxAmt + afeData[0].intangibleTaxAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                    <Row form style={{ marginTop: "5px", marginBottom: "-10px" }}>
                        <Col md={4} style={{ marginTop: "5px" }}>
                            <Label for="afeNo">Contingency</Label>
                        </Col>
                        <Col md={2}>
                            {/* <NumberFormat value={0.0}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} /> */}
                        </Col>
                        <Col md={6} style={{ marginTop: "5px" }}>
                            <NumberFormat value={afeData[0].tangibleContigencyAmt + afeData[0].intangibleContigencyAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter style={{ height: "30px" }}>
                    <Row form style={{ marginTop: "-10px" }}>
                        <Col md={6}>
                            <Label for="intSubtotal">Total</Label>
                        </Col>
                        <Col md={6}>
                            <NumberFormat value={afeData[0].tangibleAmt +
                                afeData[0].intangibleAmt +
                                afeData[0].tangibleTaxAmt +
                                afeData[0].intangibleTaxAmt +
                                afeData[0].tangibleContigencyAmt +
                                afeData[0].intangibleContigencyAmt}
                                className='float-right'
                                fixedDecimalScale={true}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'} />
                        </Col>
                    </Row>
                </CardFooter>
            </Card>



        </CardGroup>
    </div>)
}

// const BugetSection = React.forwardRef((props, ref) => {

const BugetSection = ((props) => {
    const { costTypeList, uomList, user, budgetLineRefreshKey,
        isFormReadOnly, isForceUpdate, afesRevSupBuget } = props

    const [afeData, setAFEData] = useState(props.afeData)
    const [activeTab, setActiveTab] = useState("1")
    const [costLineUpdated, setCostLineUpdated] = useState(false)
    const afeCostType = afeData[0].afeCostType
    const selCostType = props.costTypeList.filter(a => afeCostType.includes(a.afeLineTypeKey))

    const [refresh, setRefresh] = useState(false)
    const [revSupAFEs, setRevSupAFEs] = useState([]);

    // useImperativeHandle(
    //     ref,
    //     () => ({
    //         saveLineData() {
    //             childCompRef()
    //         }
    //     }),
    // )

    // const childCompRef = () => {

    //     selCostType.map(a => {
    //         if (childsRef[a.afeLineTypeKey].current !== undefined) {
    //             childsRef[a.afeLineTypeKey].current.setFromOutside("I am your father --" + a.afeLineTypeKey)
    //         }
    //     })
    // }
    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const costLineUpdate = (v) => {

        console.log("costLineUpdate")
        // console.log(v)
        var tempData = afeData,
            resetPL = false,
            resetFacility = false,
            resetDC = false,
            resetGen = false,
            resetAcct = false

        // console.log("tempData")
        // console.log(afeData)

        v.map(e => {
            if (e.costTypeKey === 0) {
                if (!resetGen) {
                    resetGen = true
                    tempData[0].totalGeneralAmt = 0
                }
                tempData[0].totalGeneralAmt = tempData[0].totalGeneralAmt + e.extLineAmt
            } else if (e.costTypeKey === 1) {
                if (!resetDC) {
                    resetDC = true
                    tempData[0].totalDCAmount = 0
                }
                tempData[0].totalDCAmount = tempData[0].totalDCAmount + e.extLineAmt
            } else if (e.costTypeKey === 2) {

                if (!resetFacility) {
                    resetFacility = true
                    tempData[0].totalFacilityAmt = 0
                }
                tempData[0].totalFacilityAmt = tempData[0].totalFacilityAmt + e.extLineAmt

            } else if (e.costTypeKey === 3) {
                //console.log("here")
                if (!resetPL) {
                    resetPL = true
                    tempData[0].totalPLAmt = 0
                }
                tempData[0].totalPLAmt = tempData[0].totalPLAmt + e.extLineAmt
            } else if (e.costTypeKey === 4) {
                //console.log("here")
                if (!resetAcct) {
                    resetAcct = true
                    tempData[0].totalAcctAmt = 0
                }
                tempData[0].totalAcctAmt = tempData[0].totalAcctAmt + e.extLineAmt
            }
        })

        //console.log(tempData)

        setAFEData(tempData)
        setRefresh(!refresh)

    }

    const updateAFESiteTransfer = (v) => {
        let value = +v;
        props.handleCallBackSiteTransfer(value);
    }

    const getBudgetSummary=()=>{
        let ojbBudgetSummary = ''
        if(afesRevSupBuget !== undefined && afesRevSupBuget !== null && afesRevSupBuget !== '' && afesRevSupBuget.includes("R")){
            ojbBudgetSummary = "Budget Summary (" +
            afesRevSupBuget.trim() + ")";
        }
        else{
            ojbBudgetSummary = "Budget Summary (" +
            USDCurrencyFormat(afeData[0].intangibleTotalAmt + afeData[0].tangibleTotalAmt, 0) 
            + (afesRevSupBuget !== undefined && afesRevSupBuget !== null && afesRevSupBuget !== '' ? ' + ' + afesRevSupBuget : '')
            + ")";
        }
     
        return ojbBudgetSummary  
    }

    const getTotalCostToWaterBridge = () => {
        let intangibleTotalAmt = afeData[0].intangibleTotalAmt !== undefined && afeData[0].intangibleTotalAmt !== null ? afeData[0].intangibleTotalAmt : 0;
        let tangibleTotalAmt = afeData[0].tangibleTotalAmt !== undefined && afeData[0].tangibleTotalAmt !== null ? afeData[0].tangibleTotalAmt : 0;
        let objBudget = intangibleTotalAmt + tangibleTotalAmt
        let siteTransfer = afeData[0].siteTransfer !== undefined && afeData[0].siteTransfer !== null ? afeData[0].siteTransfer : 0;
        let result = USDCurrencyFormat(objBudget - siteTransfer, 0);

        let objTotalCostToWaterBridge = 'Total Cost To WaterBridge: ' + result;
        return objTotalCostToWaterBridge;
    }


    // const childsRef = [useRef(), useRef(), useRef(), useRef(), useRef()]

    if (selCostType.length > 0) {
        console.log("selCostType AFE Data -----------------")
        console.log(selCostType)

        // let ojbBudgetSummary = "Budget Summary (" +
        //                      USDCurrencyFormat(afeData[0].intangibleTotalAmt + afeData[0].tangibleTotalAmt - (afeData[0].siteTransfer !== undefined && afeData[0].siteTransfer !== null ? afeData[0].siteTransfer : 0), 0) 
        //                      + ")"

        let objBudgetSummary = getBudgetSummary();
        let objTotalCostToWaterBridge = getTotalCostToWaterBridge();

        return (
            // selCostType.map(a =>
            < AccordionItem >
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {/* <strong>{"Budget Summary (" + USDCurrencyFormat(afeData[0].intangibleTotalAmt +
                            afeData[0].tangibleTotalAmt - (afeData[0].siteTransfer !== undefined && afeData[0].siteTransfer !== null ? afeData[0].siteTransfer : 0), 0) + ")"} </strong>
                          */}
                        {/* <strong >{objTotalCostToWaterBridge}</strong> */}

                        <strong>{objBudgetSummary}</strong>
                        <strong style={{ textAlign: 'right', paddingLeft: '10rem' }}>{objTotalCostToWaterBridge}</strong>
                    </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                    {/* 
                        <AFEBudget costType={a}
                            afeData={afeData}
                            uomList={uomList}
                            user={user}
                            isFormReadOnly={isFormReadOnly}
                            ref={childsRef[a.afeLineTypeKey]}
                            //key ={Math.random()}
                            lineItemUpdate={costLineUpdate}
                            //key={afeData[0].afeHeaderKey}
                            //key={(isForceUpdate ? Math.random() : afeData[0].afeHeaderKey)}
                            key={(isForceUpdate ? budgetLineRefreshKey : afeData[0].afeHeaderKey)}
                        //key={Math.random()}

                        >
                        </AFEBudget> */}



                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggleTab('1'); }}
                            >
                                Summary
                            </NavLink>
                        </NavItem>

                        {selCostType.map(a => {
                            return (
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === (a.afeLineTypeKey + 2).toString() })}
                                        onClick={() => { toggleTab((a.afeLineTypeKey + 2).toString()); }}
                                    >
                                        {a.afeLineTypeDesc} (
                                        {(a.afeLineTypeKey === 0 ? USDCurrencyFormat(afeData[0].totalGeneralAmt, 0) :
                                            a.afeLineTypeKey === 1 ? USDCurrencyFormat(afeData[0].totalDCAmount, 0) :
                                                a.afeLineTypeKey === 2 ? USDCurrencyFormat(afeData[0].totalFacilityAmt, 0) :
                                                    a.afeLineTypeKey === 3 ? USDCurrencyFormat(afeData[0].totalPLAmt, 0) :
                                                        a.afeLineTypeKey === 4 ? USDCurrencyFormat(afeData[0].totalAcctAmt, 0) : 0)
                                            + (afesRevSupBuget !== '' ? ' + ' + afesRevSupBuget : '')
                                        }
                                        )
                                    </NavLink>
                                </NavItem>)
                        }
                        )}

                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <AFEExportCostDetails
                                afeHeaderKey={afeData[0].afeHeaderKey}>

                            </AFEExportCostDetails>
                            <ColoredLine color="grey" />
                            <Row form>

                                <AFECostSummary afeHeaderKey={afeData[0].afeHeaderKey}
                                    user={user}
                                    key={Math.random()}>
                                </AFECostSummary>
                            </Row>
                            <ColoredLine color="grey" />
                            <Row form style={{ marginTop: "5px" }}>
                                <Col md={12}>
                                    <CostSummary
                                        afeData={afeData}
                                        onTextChange={props.onTextChange}
                                        isFormReadOnly={isFormReadOnly} // || statusKey >= 4} 
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        {selCostType.map(a => {
                            return (
                                <TabPane tabId={(a.afeLineTypeKey + 2).toString()}>
                                    <Row form>
                                        <Col md={12}>
                                            <AFEBudget costType={a}
                                                afeData={afeData}
                                                uomList={uomList}
                                                user={user}
                                                isFormReadOnly={isFormReadOnly}
                                                //ref={childsRef[a.afeLineTypeKey]}
                                                //key ={Math.random()}
                                                lineItemUpdate={costLineUpdate}
                                                //key={afeData[0].afeHeaderKey}
                                                //key={(isForceUpdate ? Math.random() : afeData[0].afeHeaderKey)}
                                                key={(isForceUpdate ? budgetLineRefreshKey : afeData[0].afeHeaderKey)}
                                                handleCallBackSiteTransfer={(e) => updateAFESiteTransfer(e)}
                                                afesRevSupBuget={afesRevSupBuget}

                                            >
                                            </AFEBudget>
                                        </Col>
                                    </Row>
                                </TabPane>
                            )
                        }
                        )}
                    </TabContent>


                </AccordionItemPanel>
            </AccordionItem>

        )
        //     )
        // } 

    }
    else {
        return (<div>
            No budget Line
        </div>)
    }
}
)

// const AFEDetail = React.forwardRef((props, ref) => {
class AFEDetail extends React.Component {
    static displayName = AFEDetail.name

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            afeHeaderKey: props.afeHeaderKey,
            statusKey: null,
            closeoutStatusKey: -1,
            alertMessage: "",
            showAlert: false,
            showSaveAlert: false,
            isFormReadOnly: false,
            isApprover: false,
            isAFEManager: false,
            isAFEAdmin: false,
            showCostTracking: false,
            isLoaded: false,
            afeData: [],
            empList: props.empList,
            isEmpListLoaded: false,
            budgetClassList: props.budgetClassList,
            isBudgetClass: false,
            afeTypeList: props.afeTypeList,
            isAFETypeLoaded: false,
            costTypeList: props.costTypeList,
            isCostTypeLoaded: false,
            facilityList: props.facilityList,
            isFacilityLoaded: false,
            budgetList: props.budgetList,
            dupAFEList: props.dupAFEList,
            isBudgetLoaded: false,
            isUserAdmin: false,
            isSecurityLoaded: false,
            uomList: props.uomList,
            linkAFEList: props.linkAFEList,
            basinList: props.basinList,
            countyList: props.countyList,
            projectStatusList: props.projectStatusList,
            areaList: props.areaList,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            alertMessage: "",
            alertColor: "danger",
            isForceBudgeLineUpdate: false,
            budgetLineRefreshKey: 0,
            activeTab: '1',
            hasDCCost: false,
            isDataChange: false,
            //isApprovalRevision:false,
            yesNoList: [{
                "label": "Yes",
                "value": 1
            }, {
                "label": "No",
                "value": 0
            }],
            validateDataItem: {
                afeName: true,
                afeManagerKey: true,
                afeSponsor: true,
                budgetClassKey: true,
                budgetID: true,
                typeKey: true,
                afeCostType: true,
                locationKey: true,
                county: true,
                linkAFE: true,
                estStartDate: true,
                estComplete: true,
                afeDescription: true,
                wbOwnedPct: true,
                area: true
            },
            siteTransfer: null,
            afesRevSupBuget: [],
            reimbursable: [{ label: '', value: -1 }, { label: 'No', value: 0 }, { label: 'Yes', value: 1 }],
            modalReport: false,
            reportName: "",
            dropdownOpenManage: false,
            prefillAFEData: props.prefillAFEData,
            manualEdits: false,
            isCustomConfirmModalOpen: false,
        }

        //this.childRef = createRef()
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const { afeData, validateDataItem } = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }



            if (afeData[0].afeName === null || afeData[0].afeName === "") {
                validateDataItem.afeName = false
                returnVal.ok = false
                returnVal.message = returnVal.message + " Project Name"
            }


            if (afeData[0].afeManagerKey === null || isNaN(afeData[0].afeManagerKey)) {
                validateDataItem.afeManagerKey = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Project Manager"
            }


            if (afeData[0].afeSponsor === null || afeData[0].afeSponsor === "") {
                validateDataItem.afeSponsor = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Project Sponsor"
            }

            if (afeData[0].budgetClassKey === null || isNaN(afeData[0].budgetClassKey)) {
                validateDataItem.budgetClassKey = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Budget Classification"
            } else {
                if (Number(afeData[0].budgetClassKey) === 1) {
                    if (afeData[0].budgetID === null || afeData[0].budgetID === "") {
                        validateDataItem.budgetID = false
                        returnVal.ok = false
                        if (returnVal.message !== "") {
                            returnVal.message = returnVal.message + ","
                        }
                        returnVal.message = returnVal.message + " Budget ID"
                    }
                }
            }

            validateDataItem.linkAFE = true
            if (afeData[0].typeKey === null || isNaN(afeData[0].typeKey)) {

                validateDataItem.typeKey = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Project Type"
            } else {
                if ((afeData[0].typeKey === 1 || afeData[0].typeKey === 2) && afeData[0].linkAFE === "") {
                    validateDataItem.linkAFE = false
                    returnVal.ok = false
                    if (returnVal.message !== "") {
                        returnVal.message = returnVal.message + ","
                    }
                    returnVal.message = returnVal.message + " Associate AFE"
                }
            }

            /* Added by Hau Nguyen
            allow user to enter basic informaton and save without submit
            */
            console.log("afeData")
            console.log(afeData)
            if (afeData[0].statusKey === 0) {
                returnVal.validateDataItem = validateDataItem
                if (returnVal.message !== "") {
                    returnVal.message = "Please enter missing data: " + returnVal.message
                }
                resolve(returnVal)
                return
            }

            // console.log("hererererere----------------------------------")



            if (afeData[0].afeCostType === null || afeData[0].afeCostType === "") {
                validateDataItem.afeCostType = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Cost Type"
            }

            if (afeData[0].locationKey === null || isNaN(afeData[0].locationKey)) {
                validateDataItem.locationKey = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Entity"
            }


            if (afeData[0].afeDescription === null || afeData[0].afeDescription === "") {
                validateDataItem.afeDescription = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = "Project Scope"
            }



            if (afeData[0].county === null || afeData[0].county === "") {
                validateDataItem.county = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " County"
            }


            if (afeData[0].wbOwnedPct === null || afeData[0].wbOwnedPct === "") {
                validateDataItem.wbOwnedPct = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Working Interest"
            }


            if (afeData[0].areaKey === null || isNaN(afeData[0].areaKey)) {

                validateDataItem.area = false
                returnVal.ok = false
                returnVal.message = returnVal.message + " Area"
            } else if (afeData[0].areaKey <= 0) {
                validateDataItem.area = false
                returnVal.ok = false
                returnVal.message = returnVal.message + " Area"
            }


            if (afeData[0].estStartDate === null || afeData[0].estStartDate === "") {
                validateDataItem.estStartDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Est Start Date"
            }

            if (afeData[0].estComplete === null || afeData[0].estComplete === "") {
                validateDataItem.estComplete = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Est End Date"
            }

            if (IsDate(afeData[0].estStartDate) && IsDate(afeData[0].estComplete)) {
                const estStartDate = new Date(afeData[0].estStartDate),
                    estComplete = new Date(afeData[0].estComplete)

                //console.log("IsDate: " + IsDate(afeData[0].estStartDate))
                validateDataItem.estComplete = true
                validateDataItem.estStartDate = true
                if (estStartDate > estComplete) {
                    validateDataItem.estComplete = false
                    validateDataItem.estStartDate = false
                    returnVal.ok = false
                    if (returnVal.message !== "") {
                        returnVal.message = returnVal.message + ","
                    }
                    returnVal.message = returnVal.message + " Est Start Date can not be greater Est End Date"
                }

            }

            returnVal.validateDataItem = validateDataItem
            if (returnVal.message !== "") {
                returnVal.message = "Please enter missing data: " + returnVal.message
            }
            resolve(returnVal)
        })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleAlert = () => {

        this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggleSaveAlsert = () => {
        this.setState({ showSaveAlert: !this.state.showSaveAlert },
            () => { window.setTimeout(() => { this.setState({ showSaveAlert: false }) }, 3000) })
    }

    resetValidDataItem = () => {
        // const { validDataItem } = this.state

        // validDataItem.toLocationKey = true
        // validDataItem.invoiceDate = true
        // validDataItem.invoiceNo = true

        // this.setState({ validDataItem: validDataItem })
    }

    closeForm = () => {
        this.props.toggle()
    }

    saveData = (bCloseForm = false) => {
        const { afeHeaderKey } = this.state

        try {
            this.dataValidation().then((data) => {
                if (data.ok) {

                    //this.childRef.current.saveLineData()

                    if (afeHeaderKey > 0) {
                        this.updateAFE(bCloseForm)
                    } else {
                        this.addAFE()
                    }

                    this.toggleSaveAlsert()
                } else {
                    this.setState({
                        alertMessage: data.message,
                        validateDataItem: data.validateDataItem,
                        showAlert: true
                    })
                }
            })
        } catch (error) {

        }


    }

    manualEdits =()=>{
        this.setState({manualEdits: !this.state.manualEdits});
    }
 
    updateAFE = (bCloseForm = false) => {
        const updateAFEDTO = this.updateAFEDTO()
        //console.log(updateAFEDTO)
        let myURI = apiURL + 'AFE/Header'

        fetch(myURI, apiPUTHeader(updateAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.setState({
                    afeHeaderKey: json[0].afeHeaderKey,
                    isDataChange: false
                })

                /**added by HN 01/21/2024 - update water connect
                   * after AFE is added
                   */
                if (this.props.WaterConnectLink !== undefined) {
                    this.props.WaterConnectLink(json[0])
                }

                if (bCloseForm) {
                    this.closeForm()
                } else { this.getAFEDetails() }
            }).catch(error => {
                console.log(error)
            })
    }

    updateAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeUpdateDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "AFENo": afeData[0].afeNo,
            "AFEName": afeData[0].afeName,
            "StatusKey": afeData[0].statusKey,
            "AFEManagerKey": afeData[0].afeManagerKey,
            "AFESponsor": afeData[0].afeSponsor,
            "AFEDescription": afeData[0].afeDescription,
            "Comments": afeData[0].comments,
            "TimingChange": afeData[0].timingChange,
            "TypeKey": afeData[0].typeKey,
            "BudgetClasskey": afeData[0].budgetClassKey,
            "EstStartDate": afeData[0].estStartDate,
            "EstComplete": afeData[0].estComplete,
            "BudgetID": afeData[0].budgetID,
            "WBOwnedPct": afeData[0].wbOwnedPct,
            "WDAFENo": afeData[0].wdAFENo,
            "FacilityKey": afeData[0].facilityKey,
            "WellName": afeData[0].wellName,
            "Lease": afeData[0].lease,
            "APINumber": afeData[0].apiNumber,
            "County": afeData[0].county,
            "Basin": afeData[0].basin,
            "State": afeData[0].state,
            "SurfaceLegal": afeData[0].surfaceLegal,
            "BottomHoleLegal": afeData[0].bottomHoleLegal,
            "Area": afeData[0].area,
            "ModifiedBy": getUserADAccount(user.account.userName),
            "AFECostType": afeData[0].afeCostType,
            "LinkAFE": afeData[0].linkAFE,
            "LocationKey": afeData[0].locationKey,
            "intangibleContigencyRate": afeData[0].intangibleContigencyRate / 100.00,
            "tangibleContigencyRate": afeData[0].tangibleContigencyRate / 100.00,
            "ProjectHierachy": afeData[0].projectHierachy,
            "CostCenterID": afeData[0].costCenterID,
            "CompanyID": afeData[0].companyID,
            "SiteID": afeData[0].siteID,
            "FixAssetsEnteredBy": afeData[0].fixAssetsEnteredBy,
            "FixAssetsModifiedDate": afeData[0].fixAssetsModifiedDate,
            "ActualStartDate": afeData[0].actualStartDate,
            "actualEndDate": afeData[0].actualEndDate,
            "lat": afeData[0].lat,
            "long": afeData[0].long,
            "estStartDate2": afeData[0].estStartDate2,
            "estEndDate2": afeData[0].estEndDate2,
            "projectStatusKey": afeData[0].projectStatusKey,
            "areaKey": afeData[0].areaKey,
            "tangibleTaxRate": afeData[0].tangibleTaxRate / 100.00,
            "intangibleTaxRate": afeData[0].intangibleTaxRate / 100.00,
            "closeoutStatusKey": afeData[0].closeoutStatusKey,
            "projectedAmount": afeData[0].projectedAmount,
            "projectedAmntComments": afeData[0].projectedAmntComments,
            "inServiceDate": afeData[0].inServiceDate,
            "SiteTransfer": afeData[0].siteTransfer,
            "ReimbursableProject": afeData[0].reimbursableProject
        }

        return afeUpdateDTO;
    }

    deleteAFE = () => {
        const deleteAFEDTO = this.deleteAFEDTO()
        let myURI = apiURL + 'AFE/Header'
        fetch(myURI, apiDELETEHeader(deleteAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    this.props.toggle()
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    deleteAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeDeleteDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "DeletedBy": getUserADAccount(user.account.userName)
        }

        return afeDeleteDTO
    }

    dupAFEDTO = (afeNo) => {
        const { user } = this.props
        const { afeData } = this.state
        const afeDeleteDTO = {
            "DupAFENo": afeNo,
            "RequestBy": getUserADAccount(user.account.userName)
        }

        return afeDeleteDTO
    }


    dupAFE = (afeNo) => {
        const newDupAFEDTO = this.dupAFEDTO(afeNo)
        //console.log(newAFEDTO)

        let myURI = apiURL + 'AFE/Header/Duplicate'
        fetch(myURI, apiPOSTHeader(newDupAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                if (json[0].afeHeaderKey > 0) {
                    console.log("New AFE Return")
                    console.log(json)


                    this.setState({
                        isLoaded: false,
                        afeHeaderKey: json[0].afeHeaderKey,
                        isDataChange: false
                    })
                    this.getAFEDetails()
                }
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    addAFE = () => {
        const newAFEDTO = this.createAFEDTO()
        //console.log(newAFEDTO)

        let myURI = apiURL + 'AFE/Header'
        fetch(myURI, apiPOSTHeader(newAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                if (json[0].afeHeaderKey > 0) {
                    console.log("New AFE Return")
                    console.log(json)


                    this.setState({
                        isLoaded: false,
                        afeHeaderKey: json[0].afeHeaderKey,
                        isDataChange: false
                    })

                    this.getAFEDetails()

                    /**added by HN 01/21/2024 - update water connect
                     * after AFE is added
                     */
                    if (this.props.WaterConnectLink !== undefined) {
                        this.props.WaterConnectLink(json[0])
                    }
                }
            }).catch(error => {
                console.log("PUT error: " + error)
            })

    }

    createAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state

        const afeCreateDTO = {
            "AFENo": afeData[0].afeNo,
            "AFEName": afeData[0].afeName,
            "AFEManagerKey": afeData[0].afeManagerKey,
            "AFESponsor": afeData[0].afeSponsor,
            "AFEDescription": afeData[0].afeDescription,
            "Comments": afeData[0].comments,
            "TimingChange": afeData[0].timingChange,
            "TypeKey": afeData[0].typeKey,
            "BudgetClasskey": afeData[0].budgetClassKey,
            "EstStartDate": afeData[0].estStartDate,
            "EstComplete": afeData[0].estComplete,
            "BudgetID": afeData[0].budgetID,
            "WBOwnedPct": afeData[0].wbOwnedPct,
            "FacilityKey": afeData[0].facilityKey,
            "WellName": afeData[0].wellName,
            "Lease": afeData[0].lease,
            "APINumber": afeData[0].apiNumber,
            "County": afeData[0].county,
            "Basin": afeData[0].basin,
            "State": afeData[0].state,
            "SurfaceLegal": afeData[0].surfaceLegal,
            "BottomHoleLegal": afeData[0].bottomHoleLegal,
            "Area": afeData[0].area,
            "CreatedBy": getUserADAccount(user.account.userName),
            "AFECostType": afeData[0].afeCostType,
            "LinkAFE": afeData[0].linkAFE,
            "LocationKey": afeData[0].locationKey,
            "AreaKey": afeData[0].areaKey,
            "SiteTransfer": afeData[0].siteTransfer,
            "ReimbursableProject": afeData[0].reimbursableProject
        }

        return afeCreateDTO;
    }

    reviseAFE = (statusKey) => {
        const { afeData } = this.state
        afeData[0].statusKey = statusKey
        this.setState({ afeData: afeData })
        this.saveData(true)
    }


    reviseCloseoutAFE = (statusKey) => {
        const { afeData } = this.state
        afeData[0].closeoutStatusKey = statusKey
        this.setState({ afeData: afeData })
        this.saveData(true)
    }

    issueAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 4
        this.setState({ afeData: afeData })
        //this.updateAFE()
        this.saveData(true)
    }


    completeAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 6
        this.setState({ afeData: afeData })
        //this.updateAFE()
        this.saveData(true)
    }



    startedAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 11
        this.setState({ afeData: afeData })
        console.log("here here")
        //this.updateAFE()
        this.saveData(true)
    }


    startAFE = () => {
        const { afeData } = this.state
        if (afeData[0].actualStartDate === null) {
            this.setState({
                alertMessage: "Missing Actual Start Date. Please enter Actual Start Date in Additional Detail.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].statusKey = 11 //Project Start
            this.setState({ afeData: afeData })
            //this.updateAFE()
            this.saveData(true)
        }
    }

    completeAFE = () => {
        const { afeData } = this.state
        if (afeData[0].actualEndDate === null) {
            this.setState({
                alertMessage: "Missing Actual End Date. Please enter Actual End Date in Additional Detail.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].statusKey = 6 //Project Start
            this.setState({ afeData: afeData })
            //this.updateAFE()
            this.saveData(true)
        }
    }

    approveAFE = (comment) => {
        this.approvalAFE(6, comment, null)
    }

    sendBackAFE = (comment) => {
        this.approvalAFE(10, comment, null)
    }

    duplidateFromAFE = (afeNo) => {
        this.dupAFE(afeNo)
    }

    fixAssetApproveAFE = (comment) => {
        this.saveData(false)
        this.approvalAFE(6, comment, null)
    }

    approveCloseoutAFE = (comment) => {
        this.approvalAFE(6, comment, null)
    }

    closeOutAFE = (e) => {
        const { afeData } = this.state

        if (afeData[0].actualStartDate === null || afeData[0].actualEndDate === null) {
            var sErr = ""
            if (afeData[0].actualStartDate === null) {
                sErr = "Actual Start Date"
            }
            if (afeData[0].actualEndDate === null) {
                if (sErr !== '') { sErr = sErr + ', ' }
                sErr = sErr + "Actual End Date"
            }
            this.setState({
                alertMessage: "Missing " + sErr + ". Please enter missing date in Additional Details.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].closeoutStatusKey = 0
            this.setState({ afeData: afeData },
                this.saveData(false))
        }

    }

    submitCloseoutValidation = () => {
        const { afeHeaderKey } = this.state
        var bPass = true
        let myURI = apiURL + 'AFE/closeout/Validate/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    if (json[0].hardStop != 0) {
                        bPass = false
                        this.setState({
                            msgBody: json[0].validationErrorMsg,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    }
                }

                if (bPass) {
                    this.submitCloseoutAFE()
                }
            });
    }




    submitCloseoutAFE = () => {

        const closeoutDTO = this.closeoutAFEDTO()
        console.log(closeoutDTO)
        let myURI = apiURL + 'AFE/Header/Closeout'

        fetch(myURI, apiPUTHeader(closeoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })

    }

    closeOutRequested = () => {
        const {afeData} = this.state;
        
        if (afeData[0].actualvsBudget > 115){
            let objDifference = afeData[0].invAmount - afeData[0].afeTotalAmt

            if (objDifference > 50000){
                this.setState({isCustomConfirmModalOpen: true});
                return;
            }
            else{
                console.log("Set to Closeout Request Status1!!")
                this.closeOutRequestedUpdate();
            }
        }
        else{
            console.log("Set to Closeout Request Status2!!")
            this.closeOutRequestedUpdate();
        }

    }

    closeOutRequestedUpdate =()=>{
        const closeoutDTO = this.closeoutAFEDTO()
        console.log(closeoutDTO)
        let myURI = apiURL + 'AFE/Header/CloseOutRequested'

        fetch(myURI, apiPUTHeader(closeoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })
    }


    toggleCustomConfirmModal= () => {
        this.setState({isCustomConfirmModalOpen: false});
    }

    closeoutAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const closeoutAFEDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "CloseoutBy": getUserADAccount(user.account.userName)
        }

        return closeoutAFEDTO
    }


    rejectAFE = (comment, key) => {
        // console.log("reject Comment")
        // console.log(key)
        this.approvalAFE(0, comment, key)

    }

    rejectCloseoutAFE = (comment, key) => {
        this.approvalAFE(0, comment, key)
    }


    approvalAFE = (statusKey, comment, key) => {
        const approvalFEDTO = this.approvalAFEDTO(statusKey, comment, key)
        console.log(approvalFEDTO)
        let myURI = apiURL + 'AFE/Approval'

        fetch(myURI, apiPUTHeader(approvalFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })
    }

    approvalAFEDTO = (statusKey, comment, actionLineKey) => {
        const { user } = this.props
        const { afeData } = this.state
        const afeApprovalDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "ApprovalUser": getUserADAccount(user.account.userName),
            "ApprovalStatusKey": statusKey,
            "ApprovalComment": comment,
            "ActionLineKey": actionLineKey
        }

        return afeApprovalDTO
    }

    getSelEmp = (v) => {
        const { empList } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return empList.filter(e => e.employeeKey === Number(v));
        }
        return ""

    }


    getSelLinkAFE = (v) => {
        const { linkAFEList } = this.state
        if (v !== null && v !== "") {
            return linkAFEList.filter(e => e.project_ID === v);
        }
        return ""

    }



    getEmpKey = () => {
        const { empList } = this.state
        const { user } = this.props
        const adAccount = getUserADAccount(user.account.userName)

        // const selEmp = empList.filter(e => (e.adAccount || '').toLowerCase() === adAccount.toLowerCase());
        // if (selEmp.length > 0) {
        //     return selEmp[0].employeeKey
        // }
        // return -1
        return this.getEmpKeyByAD(adAccount)
    }


    getEmpKeyByAD = (adAccount) => {
        const { empList } = this.state
        const { user } = this.props
        if (adAccount === null || adAccount === "") {
            adAccount = getUserADAccount(user.account.userName)
        }

        const selEmp = empList.filter(e => (e.adAccount || '').toLowerCase() === adAccount.toLowerCase());
        if (selEmp.length > 0) {
            return selEmp[0].employeeKey
        }
        return -1
    }



    getSelFacility = (v) => {
        const { facilityList } = this.state
        if (v !== null && v !== "") {
            return facilityList.filter(e => e.facilityKey === v);
        }
        return ""
    }



    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            return budgetList.filter(e => e.afeBudgetDesc === v);
        }
        return ""
    }


    getSelBudgetClass = (v) => {
        const { budgetClassList } = this.state
        if (v !== null && v !== "") {
            return budgetClassList.filter(e => e.afeBudgetClassKey === v);
        }
        return ""
    }


    getSelAFECostType = (value) => {
        const { costTypeList } = this.state

        if (value !== null && value !== "") {
            return costTypeList.filter(e => value.includes(e.afeLineTypeKey))
        }
        return ""
    }

    getSelArea = (v) => {
        const { areaList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return areaList.filter(e => e.areaKey === v)
        }
        return ""
    }

    getReimbursable = (v) => {
        const { reimbursable } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return reimbursable.filter(e => e.value === v)
        }
        return ""
    }

    getSelCounty = (v) => {
        const { countyList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && v !== "") {
            // console.log("v")
            // console.log(v)
            return countyList.filter(e => e.countyDesc === v)
        }
        return ""
    }


    getSelBasin = (v) => {
        const { basinList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            // console.log("v")
            // console.log(v)
            return basinList.filter(e => e.locationKey === Number(v))
        }
        return ""
    }



    getAFETypeCostString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.afeLineTypeKey
        })

        return grpKey;
    }



    getSelAFEType = (v) => {
        const { afeTypeList } = this.state
        if (v !== null && v !== "") {
            return afeTypeList.filter(e => e.afeTypeKey === v);
        }
        return ""
    }

    getSelYesNo = (v) => {
        const { yesNoList } = this.state
        if (v !== null && v !== "") {
            return yesNoList.filter(e => e.value === v);
        }
        return ""
    }


    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData, validateDataItem } = this.state
        const { user } = this.props
        var showAlert = false
        var isForceBudgeLineUpdate = false
        var budgetLineRefreshKey = 0

        console.log(fieldName + ' ------ ' + fieldValue)
        console.log(fieldValue)

        switch (fieldName) {
            case "field":
                afeData[0].field = fieldValue
                break;
            case "bottomHoleLegal":
                afeData[0].bottomHoleLegal = fieldValue
                break;
            case "surfaceLegal":
                afeData[0].surfaceLegal = fieldValue
                break;
            case "apiNumber":
                afeData[0].apiNumber = fieldValue
                break;
            case "wellName":
                afeData[0].wellName = fieldValue
                break;
            case "lease":
                afeData[0].lease = fieldValue
                break;
            case "estStartDate":
                afeData[0].estStartDate = fieldValue
                validateDataItem.estStartDate = true
                validateDataItem.estComplete = true
                break;
            case "actualStartDate":

                afeData[0].actualStartDate = fieldValue
                break;
            case "actualEndDate":
                afeData[0].actualEndDate = fieldValue
                break;

            case "estComplete":
                afeData[0].estComplete = fieldValue
                validateDataItem.estComplete = true
                validateDataItem.estStartDate = true
                break;
            case "afeName":
                afeData[0].afeName = fieldValue
                validateDataItem.afeName = true
                showAlert = false
                break;
            case "afeSponsor":
                afeData[0].afeSponsor = null
                if (e !== null) {
                    afeData[0].afeSponsor = e.employeeKey
                    validateDataItem.afeSponsor = true
                    showAlert = false
                }
                break;
            case "budgetID":
                afeData[0].budgetID = null
                afeData[0].afeBudgetAmount = 0.0
                if (e !== null && e !== undefined) {
                    afeData[0].budgetID = e.value
                    afeData[0].afeBudgetAmount = e.afeBudgetAmount
                    validateDataItem.budgetID = true
                    showAlert = false
                }
                break;
            case "county":
                afeData[0].county = null
                afeData[0].state = null
                if (e !== null && e !== undefined) {
                    afeData[0].county = e.value
                    afeData[0].state = e.stateID
                    validateDataItem.county = true
                    showAlert = false
                }
                break;
            case "basin":
                afeData[0].basin = fieldValue

                break;
            case "state":
                afeData[0].state = fieldValue
                break;
            case "areaKey":
                afeData[0].areaKey = -1

                if (e !== null && e !== undefined) {
                    afeData[0].areaKey = e.value
                    validateDataItem.area = true
                    showAlert = false
                }
                break;
            case "wbOwnedPct":
                afeData[0].wbOwnedPct = fieldValue
                afeData[0].nonOperatedWIPct = 100.0 - Number(fieldValue)
                validateDataItem.wbOwnedPct = true
                showAlert = false
                break;
            case "afeDescription":
                afeData[0].afeDescription = fieldValue
                validateDataItem.afeDescription = true
                showAlert = false
                break;
            case "afeManagerKey":
                afeData[0].afeManagerKey = null
                if (e !== null && e !== undefined) {
                    afeData[0].afeManagerKey = e.employeeKey
                    validateDataItem.afeManagerKey = true
                    showAlert = false
                }

                break;
            case "budgetClassKey":
                afeData[0].budgetClassKey = e.afeBudgetClassKey
                validateDataItem.budgetClassKey = true
                validateDataItem.budgetID = true
                showAlert = false
                if (e.afeBudgetClassKey !== 1) {
                    afeData[0].budgetID = null
                }
                break;
            case "typeKey":

                afeData[0].typeKey = e.afeTypeKey
                validateDataItem.typeKey = true
                validateDataItem.linkAFE = true

                if (e.afeTypeKey === 0) {
                    afeData[0].linkAFE = ""
                }

                showAlert = false
                break;
            case "timingChange":
                afeData[0].timingChange = e.value
                break;
            case "afeCostType":
                afeData[0].afeCostType = this.getAFETypeCostString(e)
                validateDataItem.afeCostType = true
                isForceBudgeLineUpdate = true
                budgetLineRefreshKey = Math.random()
                showAlert = false
                break;
            case "linkAFE":
                afeData[0].linkAFE = ""
                if (e !== null || e != undefined) {
                    afeData[0].linkAFE = e.value
                    validateDataItem.linkAFE = true
                }
                showAlert = false
                break;
            case "locationKey":
                afeData[0].areaKey = -1
                afeData[0].locationKey = e.value
                validateDataItem.locationKey = true



                /*Comment out per Haydent request 03/30/3022
                default to null and force user to enter value */
                // if (afeData[0].locationKey === 51) {
                //     afeData[0].wbOwnedPct = 50.00
                // } else {
                //     afeData[0].wbOwnedPct = 100
                // }

                showAlert = false
                break;
            case "intangibleContigencyRate":
                afeData[0].intangibleContigencyRate = e.value

                break;
            case "tangibleContigencyRate":
                afeData[0].tangibleContigencyRate = e.value
                break;
            case "intangibleTaxRate":
                afeData[0].intangibleTaxRate = e.value
                break;
            case "tangibleTaxRate":
                afeData[0].tangibleTaxRate = e.value
                break;
            case "companyID":
                afeData[0].companyID = ""
                //afeData[0].companyName=""
                if (e !== null && e !== undefined) {
                    if (afeData[0].companyID !== e.value) {
                        afeData[0].fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    }
                    afeData[0].companyID = e.value
                    afeData[0].companyName = e.label

                    showAlert = false
                }
                break;
            case "projectHierachy":
                afeData[0].projectHierachy = ""

                if (e !== null && e !== undefined) {
                    // if (afeData[0].projectHierachy !== e.value) {
                    //     afeData[0].fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    // }

                    afeData[0].projectHierachy = e.value
                    showAlert = false

                }

                break;
            case "siteID":
                afeData[0].siteID = ""
                //afeData[0].siteName = ""

                if (e !== null && e !== undefined) {
                    if (afeData[0].siteID !== e.value) {
                        afeData[0].fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    }
                    afeData[0].siteID = e.value
                    afeData[0].siteName = e.label
                    showAlert = false

                }
                break;
            case "costCenterID":
                afeData[0].costCenterID = ""
                // afeData[0].costCenterName = ""
                if (e !== null && e !== undefined) {
                    if (afeData[0].costCenterID !== e.value) {
                        afeData[0].fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    }

                    afeData[0].costCenterID = e.value
                    afeData[0].costCenterName = e.label

                    showAlert = false
                }
                break;
            case "facilityKey":

                //afeData[0].basin = ""
                afeData[0].area = ""
                if (e !== null && e !== undefined) {
                    afeData[0].facilityKey = e.value
                    if (!isNaN(e.value)) {
                        afeData[0].basin = e.locationName
                        afeData[0].area = e.areaName
                    }
                } else {
                    afeData[0].facilityKey = null
                }
            case "estStartDate2":
                afeData[0].estStartDate2 = fieldValue
                break;
            case "estEndDate2":
                afeData[0].estEndDate2 = fieldValue
                break;
            case "lat":
                afeData[0].lat = fieldValue
                break;
            case "long":
                afeData[0].long = fieldValue
                break;
            case "projectStatus":
                afeData[0].projectStatusKey = null
                if (e !== null) {
                    afeData[0].projectStatusKey = e.value
                }
                break;
            case "projectedAmount":
                afeData[0].projectedAmount = fieldValue
                break;
            case "projectedAmntComments":
                afeData[0].projectedAmntComments = fieldValue
                break;
            case "siteTransfer":
                afeData[0].siteTransfer = fieldValue;
                break;
            case 'reimbursable':
                afeData[0].reimbursableProject = fieldValue.value < 0 ? null : fieldValue.value;
                break;
            default:
                break;
        }

        this.setState({
            afeData: afeData,
            validateDataItem: validateDataItem,
            isForceBudgeLineUpdate: isForceBudgeLineUpdate,
            budgetLineRefreshKey: budgetLineRefreshKey,
            showAlert: showAlert,
            isDataChange: true
        })
    }

    onSiteToSiteChange = (fieldValue) => {
        const { afeData } = this.state
        afeData[0].siteTransfer = fieldValue;
        this.setState({ afeData: afeData })
    }

    getAFEDetailsAPI = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }

    getAFERevSup = async () => {
        const { afeHeaderKey } = this.state

        if (afeHeaderKey === undefined || afeHeaderKey === null || afeHeaderKey <= 1) {
            return;
        }

        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey + '/RevisionSupplement';

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afesRevSupBuget: json
                })
            });

        console.log('getAFERevSup');
    }

    setIsShowCostTracking = (statusKey) => {
        return !(statusKey === 0 || statusKey === 1 || statusKey === 2 || statusKey === 3)
    }

    setIsApprovalRevision = (statusKey, approver, isApprovalRevision, closeoutStatusKey) => {
        if (this.isAFEApprover(statusKey, approver, closeoutStatusKey) && isApprovalRevision !== 0) {
            this.setState({
                showAlert: true,
                alertColor: "info",
                alertMessage: "This AFE have been revised and resubmitted for your approval. Please view change log for update history."
            })
        }
    }

    isAFEApprover = (statusKey, approver, closeOutStatusKey) => {
        const { user } = this.props
        if (approver !== "" && (statusKey === 3 || closeOutStatusKey === 3)) {
            return approver.includes(user.account.userName.toLowerCase())
        } else {
            return false
        }
    }

    getAFEDetails = () => {
        const { afeHeaderKey } = this.state


        if (afeHeaderKey > 0) {
            console.log("get new API Details")
            this.getAFEDetailsAPI()
        } else {
            const afeData = this.createAFE()
            this.setState({
                isLoaded: true,
                afeData: afeData
            })
            this.props.setFormHeader("New AFE", "InComplete", "", -1, "")
        }
    }



    setIsFormReadonly = (statusKey, afeManagerAzureAD) => {
        const { user } = this.props
        const { isAFEAdmin } = this.state


        if (statusKey === 1 || statusKey === 10) { //Rejected //10=Revise
            return !((afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase()) || isAFEAdmin)
        } if (statusKey === 6 || statusKey === 5) {
            return true
        } else if (statusKey === 3) {
            return true
        } else if (statusKey === 8) { // 8 - Closed
            return true
        } else if (statusKey === 11) {// 11=Project Started
            return true
        } else if (statusKey === 2) {
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } if (statusKey === 4) { //Approved 
            //return true
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } else {
            return false
        }

    }

    createAFE = () => {
        const { user, prefillAFEData } = this.props
        const afeData = {
            "afeNo": "XXXXXX",
            "afeName": "",
            "afeStatusDesc": "Initiated",
            "afeManagerName": user.account.name,
            "afeSignedDate": "",
            "comments": "",
            "timingChange": 0,
            "typeKey": null,
            "afeTypeDesc": "",
            "afeDescription": "",
            "estStartDate": null,
            "estComplete": null,
            "budgetClassDesc": "",
            "budgetID": "",
            "wbOwnedPct": null,
            "NonOperatedWIPct": 0,
            "wdAFENo": "",
            "facilityKey": null,
            "wellName": "",
            "lease": "",
            "apiNumber": "",
            "county": "",
            "basin": "",
            "state": "",
            "surfaceLegal": "",
            "bottomHoleLegal": "",
            "area": "",
            "intangibleCost": 0,
            "tangibleCost": 0,
            "partnerCost": 0,
            "createdBy": user.account.name,
            "createdByName": user.account.name,
            "modifiedByName": null,
            "createdDatetime": new Date().toISOString(),
            "modifiedBy": null,
            "modifiedDateTime": null,
            "afeHeaderKey": -1,
            "budgetClassKey": null,
            "statusKey": 0,
            "afeManagerKey": this.getEmpKey(),
            "afeSponsor": "",
            "afeCostType": "",
            "linkAFE": "",
            "locationKey": null,
            "intangibleContigencyRate": 0.0,
            "tangibleContigencyRate": 0.0,
            "intangibleTaxRate": 0.0,
            "tangibleTaxRate": 0.0,
            "isFixAssetApprove": 0,
            "areaKey": null,
            "afeBudgetAmount": 0.0

        }

        if (prefillAFEData != undefined && prefillAFEData != null) {
            console.log("prefillAFEData")
            console.log(prefillAFEData)
            afeData.afeName = prefillAFEData.afeName
            afeData.afeManagerKey = this.getEmpKeyByAD(prefillAFEData.afeManagerName)
            afeData.basin = prefillAFEData.basin
            afeData.locationKey = prefillAFEData.locationKey
            afeData.area = prefillAFEData.area
            afeData.areaKey = prefillAFEData.areaKey
            afeData.typeKey = prefillAFEData.typeKey
            afeData.afeSponsor = this.getEmpKeyByAD(prefillAFEData.afeSponsor)
            afeData.estStartDate = prefillAFEData.estStartDate
            afeData.estComplete = prefillAFEData.estComplete
        }

        return [afeData]
    }

    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }


    async componentDidMount() {
        await this.getAFERevSup();
        this.getUserSecurity()
        this.getAFEDetails()
    }

    isFormReadOnly = () => {
        const { isFormReadOnly, isAFEAdmin, isApprover } = this.state
        return isFormReadOnly
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKey } = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKey }];
        return reportPara
    }

    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }

    async getAFEReport(reportName) {
        this.setState({ modalReport: !this.state.modalReport, reportName: reportName })
    }

    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport })
    }

    toggleManualEdits =() =>{
        this.setState({manualEdits: !this.state.manualEdits,isLoaded: false});
        this.getAFEDetails();
        this.setState({isLoaded: true});
    }

    toggleManualEditsCloseForm = () =>{
        this.setState({isLoaded: false,manualEdits: !this.state.manualEdits});
        this.getAFEDetails();
        this.setState({isLoaded: true});
    }
    // toggleManualCancel=()=>{
    //     this.setState({manualEdits: !this.state.manualEdits});
    // }

    render() {
        const { afeHeaderKey, alertMessage, empList, isEmpListLoaded, basinList, showCostTracking,
            showAlert, isFormReadOnly, afeData, isLoaded, isForceBudgeLineUpdate, budgetLineRefreshKey,
            yesNoList, budgetClassList, isApprover, statusKey, countyList, closeoutStatusKey, showSaveAlert,
            afeTypeList, msgHeader, msgBody, uomList, isAFEAdmin, isAFEManager, isSecurityLoaded,
            costTypeList, facilityList, isFacilityLoaded, linkAFEList, alertColor, areaList, projectStatusList,
            budgetList, validateDataItem, activeTab, hasDCCost, isDataChange, dupAFEList, afesRevSupBuget, reimbursable,
            reportName,manualEdits,isCustomConfirmModalOpen } = this.state
        const { user } = this.props
 
        let objAFEsRevSupBuget = 0;
        let objAFEsRevSupBugetDetails = ''
        if (afesRevSupBuget != undefined && afesRevSupBuget !== null && afesRevSupBuget.length > 0) {
            for (let i = 0; i < afesRevSupBuget.length; i++) {
                let index = afesRevSupBuget[i].afeNo.indexOf('-') + 1;
                let objLength = afesRevSupBuget[i].afeNo.length - index;
                objAFEsRevSupBuget = objAFEsRevSupBuget + afesRevSupBuget[i].afeTotalAmt;
                objAFEsRevSupBugetDetails += (i == 0 ? ' ' : ' + ') + afesRevSupBuget[i].afeNo.substr(index, objLength) + ': ' + USDCurrencyFormat(afesRevSupBuget[i].afeTotalAmt, 0);
            }
            console.log(objAFEsRevSupBuget);
        }


        if (!isSecurityLoaded) {
            return (<div>Loading Security data .....</div>)
        } else if (!isLoaded) {
            return (<div>Loading AFE data .....</div>)
        } else
            return (<div>

                <Form onSubmit={e => e.preventDefault()}>
                    <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <Col sm={4}>

                            <Button color="secondary" className="float-left"
                                hidden={isDataChange}
                                onClick={() => this.closeForm()}>Close</Button>

                            <ConfirmModal
                                color="secondary"
                                buttonLabel={"Close"}
                                className="float-left"
                                hidden={(!isDataChange)} //{(this.isFormReadOnly() && !isDataChange)}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message={"Do you want to save your changes ?"}
                                onClickYes={(e) => this.saveData(true)}
                                onClickNo={this.closeForm}
                            //onClosed={this.closeForm}
                            >
                            </ConfirmModal>


                            <Button color="primary" className="float-left"
                                style={{ marginLeft: "5px" }}
                                //ref={ref}
                                hidden={this.isFormReadOnly() && !isDataChange}
                                onClick={() => this.saveData()}>Save</Button>

                            <ConfirmModal
                                color="success"
                                style={{ marginLeft: "5px" }}

                                // buttonLabel={(statusKey === 4 ? "Start Project" :
                                //     (statusKey === 11 ? "Complete Project" :
                                //         (statusKey === 6 ? "Close AFE" : "Submit")))
                                buttonLabel={(statusKey === 11 || statusKey === 4 ? "Complete Project" :
                                    (statusKey === 6 ? "Close AFE" : "Submit"))
                                }

                                //hidden={this.isFormReadOnly() || afeHeaderKey < 0}
                                //Remove statusKey ===4 by HN 07/25/2022 -> user open different form to close project
                                hidden={afeHeaderKey < 0 || !(((statusKey === 1 || statusKey === 2
                                    || statusKey === 10) && isAFEManager) || statusKey === 0)}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message={"Are you sure you want to " +
                                    (statusKey === 11 || statusKey === 4 ? "Complete" :
                                        (statusKey === 6 ? "Close" :
                                            "Submit")) + " selected AFE?"}
                                onClickYes={(e) => (statusKey === 11 || statusKey === 4 ? this.completeAFE() :
                                    (statusKey == 6 ? this.reviseAFE(8) :
                                        this.issueAFE()))}>
                            </ConfirmModal>

                            {/* This to set project complete
                            Added by HN 07/25/2022  */}
                            <AFECloseOutPopUp
                                color="success"
                                buttonLabel={(statusKey === 4 ? "Project Started" : "Complete Project")}
                                className="float-right"
                                style={{ marginLeft: "5px" }}
                                hidden={!(((statusKey === 4 || statusKey === 11) && (isAFEAdmin || isAFEManager)))}
                                //hidden={true}
                                formName={(statusKey === 4 ? "Project Started" : "Complete Project")}
                                formMode={(statusKey === 4 ? "3" : "1")}/* 1=Actual Start Date Confirm, 3 = only confirm Start Date */
                                id={afeHeaderKey}
                                afeHeaderKey={afeHeaderKey}
                                user={user}
                                message={(statusKey === 4 ? "Please confirm Actual Start date before start this project." :
                                    "Please confirm Actual Start and Actual End date before complete this project.")}
                                afeData={afeData}
                                onTextChange={this.onTextChange}
                                commentPrompMsg="Please confirm actual start and end date."
                                onClickYes={(e) => (statusKey === 4 ? this.startedAFE() : this.completeAFE())}>
                            </AFECloseOutPopUp>


                            <ConfirmWithMsgModal
                                color="success"
                                buttonLabel={(closeoutStatusKey === 3 ? "Approve Closeout" : "Approve")}
                                style={{ marginLeft: "5px" }}
                                hidden={!(isApprover &&
                                    (afeData[0].isFixAssetApprove === 0 || closeoutStatusKey === 3))}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message=""
                                inputComment="Approved."
                                isCommentRequired={false}
                                commentPrompMsg="Please Enter Approval Comment:"
                                onClickYes={(e) => (closeoutStatusKey === 3 ? this.approveCloseoutAFE(e) : this.approveAFE(e))}>
                            </ConfirmWithMsgModal>


                            <AFEFixAssetsPopUp
                                color="success"
                                buttonLabel={"Approve"}
                                style={{ marginLeft: "5px" }}
                                hidden={!(afeData[0].isFixAssetApprove !== 0 && statusKey === 3 &&
                                    isApprover)}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message=""
                                afeData={afeData}
                                onTextChange={this.onTextChange}
                                commentPrompMsg="Please Enter Approval Comment And Fixed Asset Information:"
                                onClickYes={(e) => this.fixAssetApproveAFE(e)}>
                            </AFEFixAssetsPopUp>

                            <AFECloseOutPopUp
                                color="success"
                                buttonLabel={("Closeout AFE")}
                                className="float-right"
                                style={{ marginLeft: "5px" }}
                                //hidden={!((statusKey === 6 && (isAFEAdmin || isAFEManager)) && (closeoutStatusKey < 0 || closeoutStatusKey === 0 || closeoutStatusKey === 1 || closeoutStatusKey === 10))}
                                hidden={!((statusKey === 6 && (isAFEAdmin || isAFEManager)) && (closeoutStatusKey === 0 || closeoutStatusKey === 1 || closeoutStatusKey === 10 || closeoutStatusKey == 15))}
                                formName="Closeout AFE"
                                formMode="2"
                                id={Math.random()}
                                afeHeaderKey={afeHeaderKey}
                                user={user}
                                message={""}
                                afeData={afeData}
                                onTextChange={this.onTextChange}
                                commentPrompMsg="Please complete checklist."
                                onClickYes={(e) => this.submitCloseoutAFE()}>
                            </AFECloseOutPopUp>
 
                            <Button outline
                                //color="success"
                                hidden={!(isAFEAdmin && statusKey === 6 && closeoutStatusKey < 0)}
                                onClick={(e) => this.closeOutRequested()} 
                                style={{ margin: "0 5px 0 5px" , color:"black",borderColor:"#FFDDAF", backgroundColor:"#FFDDAF"}} > 
                                {"Closeout Requested"} 
                            </Button> 
                            <Modal isOpen={isCustomConfirmModalOpen} toggle={this.toggleCustomConfirmModal} onClosed={this.toggleCustomConfirmModal}>
                                <ModalHeader toggle={this.toggleCustomConfirmModal}>{afeData[0].afeNo}</ModalHeader>
                                <ModalBody>{"This AFE requires a supplement before closure."}</ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={(e) => this.toggleCustomConfirmModal()}>Ok</Button>
                                </ModalFooter>
                            </Modal>

                        </Col>

                        {/* <ConfirmModal
                                color="success"
                                style={{ marginLeft: "5px" }}

                                buttonLabel={(statusKey === 4 ? "Start Project" :
                                    (statusKey === 11 ? "Complete Project" :
                                        (statusKey === 6 ? "Close AFE" : "Submit")))}
                                // buttonLabel={("Submit Closeout")}

                                //hidden={this.isFormReadOnly() || afeHeaderKey < 0}
                                hidden={!(statusKey === 6 && (closeoutStatusKey === 0 || closeoutStatusKey === 1) && (isAFEAdmin || isAFEManager))}
                                //hidden={true}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message={"Are you sure you want to submit selected AFE for closeout? "}
                                onClickYes={(e) => this.submitCloseoutAFE()}>
                            </ConfirmModal> */}

                        {/* section below are for Closeout approval */}
                        <Col sm={3}>

                            <Alert color="success" isOpen={showSaveAlert}
                                //toggle={this.toggleSaveAlsert}
                                style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                                {"Data Save Successfuly !!!!"}
                            </Alert>






                        </Col>

                        <Col sm={5}>


                            <ConfirmModal outline={true}
                                color="danger"
                                className="float-right"
                                buttonLabel="Delete"
                                //hidden={this.isFormReadOnly()}
                                hidden={!(statusKey === 0 && afeHeaderKey > 0)}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message="Are you sure you want to delete selected AFE?"
                                onClickYes={(e) => this.deleteAFE()}
                                image={<img
                                    src={TrashBin}
                                    id={afeHeaderKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                            </ConfirmModal>

                            <AFERejectModal
                                color="danger"
                                buttonLabel={("Reject") + (closeoutStatusKey === 3 ? " Closeout" : '')}
                                className="float-right"
                                hidden={(!isApprover || (statusKey !== 3 && closeoutStatusKey !== 3))}
                                formName="Confirm"
                                id={afeHeaderKey}
                                afeHeaderKey={afeHeaderKey}
                                message=""
                                commentPrompMsg="Please Enter Reject Comment:"
                                onClickYes={(e, key) => (closeoutStatusKey === 3 ? this.rejectCloseoutAFE(e, key) : this.rejectAFE(e, key))}>
                            </AFERejectModal>


                            <ConfirmModal outline={true}
                                color="danger"
                                className="float-right"
                                buttonLabel={((statusKey === 3) ? "Revise" :
                                    (closeoutStatusKey > 0 ? (closeoutStatusKey === 3 ? "Revise Closeout" : "Cancel Closeout") : "Cancel"))}
                                //hidden={this.isFormReadOnly()} statusKey === 2 
                                hidden={!((statusKey === 2 || statusKey === 1 || statusKey === 3 || statusKey === 4 || statusKey === 10
                                    || statusKey === 6) && (isAFEAdmin || isAFEManager) && !isApprover)}
                                formName="Confirm"
                                id={afeHeaderKey}
                                message={"Are you sure you want to " + (statusKey === 3 || closeoutStatusKey === 3 ? "revise" : "cancel") +
                                    (closeoutStatusKey >= 3 ? " closeout" : "") +
                                    " this AFE?"}
                                onClickYes={(e) => (closeoutStatusKey >= 3 ? this.reviseCloseoutAFE((closeoutStatusKey === 3 ? 10 : 5)) : this.reviseAFE((statusKey === 3 ? 10 : 5)))}
                                image={<img
                                    src={(statusKey === 3 || closeoutStatusKey === 3 ? RevisionPng : TrashBin)}
                                    id={afeHeaderKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                            </ConfirmModal>

                            <ConfirmWithMsgModal outline={true}
                                style={{ marginRight: "5px" }}
                                color="danger"
                                buttonLabel={"Send Back to Initiator"}
                                className="float-right"
                                hidden={!(isAFEManager && statusKey === 2)}
                                formName="Confirm"
                                id={afeHeaderKey}
                                afeHeaderKey={afeHeaderKey}
                                message=""
                                commentPrompMsg="Please Enter Send Back Comment:"
                                onClickYes={(e) => this.sendBackAFE(e)}>
                            </ConfirmWithMsgModal>

                            <AFEListSelector outline={true}
                                style={{ marginRight: "5px" }}
                                color="success"
                                buttonLabel={"Copy from AFE"}
                                className="float-right"
                                hidden={(statusKey === 0 || afeHeaderKey > 0)}
                                formName="Confirm"
                                id={afeHeaderKey}
                                afeHeaderKey={afeHeaderKey}
                                message=""
                                user={user}
                                dupAFEList={dupAFEList}
                                onClickYes={(e) => this.duplidateFromAFE(e)}>
                            </AFEListSelector>

                            <Button color="primary" className="float-right"
                                style={{ marginRight: "5px" }} 
                                hidden={!isAFEAdmin}
                                onClick={() => this.manualEdits()}>Manual Edits</Button>   
                        </Col>
                    </Row>
                    <ColoredLine color="grey" />
                    <Row form>
                        <Alert color={alertColor} toggle={() => this.toggleAlert()}
                            isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {alertMessage}
                        </Alert >
                    </Row>
                    <Accordion allowMultipleExpanded={true}
                        allowZeroExpanded={true}
                        preExpanded={(afeHeaderKey > 0 ? (showCostTracking ? ['costTracking'] : ['general']) : ['general'])}>

                        <AccordionItem uuid='general'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>General Information</strong>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeNo">AFE No</Label>
                                            </Col>
                                            <Col md={4}>
                                                <Input disabled type="text" name="afeNo"
                                                    id="afeNo" placeholder=""
                                                    value={afeData[0].afeNo}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Input disabled type="text" name="afeStatusDesc"
                                                    id="afeStatusDesc" placeholder=""
                                                    value={afeData[0].afeStatusDesc}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="createdBy">Initiated By</Label>
                                            </Col>
                                            <Col md={4}>
                                                <Input disabled type="text" name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={afeData[0].createdByName}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <Input disabled type="text" name="createdDatetime"
                                                    id="createdDatetime" placeholder=""
                                                    value={FormatDate(afeData[0].createdDatetime)}
                                                />
                                            </Col>

                                        </Row>
                                    </Col>

                                </Row>

                                <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeName">Project Name</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    className={(!validateDataItem.afeName ? "form-error" : "")}
                                                    id="afeName" placeholder=""
                                                    disabled={this.isFormReadOnly() || statusKey === 4}
                                                    onChange={(e) => this.onTextChange("afeName", e.target.value, e)}
                                                    value={afeData[0].afeName}
                                                />
                                            </Col>

                                        </Row>
                                    </Col>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="typeKey">AFE Type</Label>
                                            </Col>

                                            <Col md={8}>
                                                <Select options={afeTypeList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || statusKey === 4}
                                                    className={(!validateDataItem.typeKey ? "form-error" : "")}
                                                    value={this.getSelAFEType(afeData[0].typeKey)}
                                                    defaultValue={this.getSelAFEType(afeData[0].typeKey)}
                                                    onChange={(e) => this.onTextChange("typeKey", e, e)}
                                                //isClearable={true}

                                                >
                                                </Select>
                                            </Col>

                                        </Row>


                                    </Col>

                                </Row>

                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Project Sponsor </Label>
                                            </Col>
                                            <Col md={8}>
                                                {/* <Col md={8}> */}

                                                <Select options={empList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || statusKey === 4}
                                                    className={(!validateDataItem.afeSponsor ? "form-error" : "")}
                                                    value={this.getSelEmp(afeData[0].afeSponsor)}
                                                    defaultValue={this.getSelEmp(afeData[0].afeSponsor)}
                                                    onChange={(e) => this.onTextChange("afeSponsor", e, e)}
                                                    isClearable={true}

                                                >
                                                </Select>


                                                {/* <Input type="text" name="afeSponsor"
                                        id="afeSponsor" placeholder=""
                                        value={afeData[0].afeSponsor}
                                        onChange={(e) => this.onTextChange("afeSponsor", e.target.value, e)}
                                    /> */}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="typeKey">Associate AFE</Label>
                                            </Col>

                                            <Col md={8}>
                                                <Select options={linkAFEList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || (afeData[0].typeKey === 0) || statusKey === 4}
                                                    className={(!validateDataItem.linkAFE ? "form-error" : "")}
                                                    value={this.getSelLinkAFE(afeData[0].linkAFE)}
                                                    defaultValue={this.getSelLinkAFE(afeData[0].linkAFE)}
                                                    onChange={(e) => this.onTextChange("linkAFE", e, e)}
                                                    isClearable={true}

                                                >
                                                </Select>
                                            </Col>

                                        </Row>

                                    </Col>


                                </Row>


                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="afeManager">Project Manager</Label>

                                            </Col>
                                            <Col md={8}>

                                                <Select options={empList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || statusKey === 4}
                                                    className={(!validateDataItem.afeManagerKey ? "form-error" : "")}
                                                    value={this.getSelEmp(afeData[0].afeManagerKey)}
                                                    defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                                    onChange={(e) => this.onTextChange("afeManagerKey", e, e)}
                                                    isClearable={true}

                                                >
                                                </Select>
                                            </Col>
                                        </Row>

                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSignedDate">Accepted Date</Label>
                                            </Col>

                                            <Col md={8}>
                                                <Input disabled type="text" name="afeSignedDate"
                                                    id="afeSignedDate" placeholder="AFE SignedDate"
                                                    value={FormatDate(afeData[0].afeSignedDate)}
                                                />
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>


                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="budgetClass">Budget Classification</Label>

                                            </Col>
                                            <Col md={8}>

                                                <Select options={budgetClassList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || statusKey === 4}
                                                    className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                                    value={this.getSelBudgetClass(afeData[0].budgetClassKey)}
                                                    defaultValue={this.getSelBudgetClass(afeData[0].budgetClassKey)}
                                                    onChange={(e) => this.onTextChange("budgetClassKey", e, e)}
                                                //isClearable={true}

                                                >
                                                </Select>
                                            </Col>
                                        </Row>


                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="budgetID">Budget ID</Label>

                                            </Col>

                                            <Col md={8}>
                                                <Select options={budgetList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || afeData[0].budgetClassKey !== 1 || statusKey === 4}
                                                    className={(!validateDataItem.budgetID ? "form-error" : "")}
                                                    value={this.getSelBudget(afeData[0].budgetID)}
                                                    defaultValue={this.getSelBudget(afeData[0].budgetID)}
                                                    onChange={(e) => this.onTextChange("budgetID", e, e)}
                                                    isClearable={true}

                                                >
                                                </Select>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>



                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="typeKey">Project Cost Type</Label>
                                            </Col>

                                            <Col md={8}>
                                                <Select options={costTypeList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || statusKey === 4}
                                                    className={(!validateDataItem.afeCostType ? "form-error" : "")}
                                                    value={this.getSelAFECostType(afeData[0].afeCostType)}
                                                    defaultValue={this.getSelAFECostType(afeData[0].afeCostType)}
                                                    onChange={(e) => this.onTextChange("afeCostType", e, e)}
                                                    isClearable={true}
                                                    isMulti

                                                >
                                                </Select>
                                            </Col>

                                        </Row>
                                    </Col>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="estStartDate">Fiscal Budget ID Amt</Label>

                                            </Col>
                                            <Col md={8}>

                                                <NumberFormat value={afeData[0].afeBudgetAmount}

                                                    // onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                    className={("float-right")}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    displayType={'text'}
                                                    //disabled={this.isFormReadOnly() || statusKey === 4}
                                                    thousandSeparator={true}
                                                    prefix="$"
                                                />
                                            </Col>

                                        </Row>




                                    </Col>
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="basin">Entity</Label>
                                            </Col>

                                            <Col md={8}>
                                                <Select options={basinList}
                                                    //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                    isDisabled={this.isFormReadOnly() || statusKey === 4}
                                                    className={(!validateDataItem.locationKey ? "form-error" : "")}
                                                    value={this.getSelBasin(afeData[0].locationKey)}
                                                    defaultValue={this.getSelBasin(afeData[0].locationKey)}
                                                    onChange={(e) => this.onTextChange("locationKey", e, e)}
                                                //isClearable={true}

                                                >
                                                </Select>


                                            </Col>

                                        </Row>

                                    </Col>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="estStartDate">Start Date</Label>

                                            </Col>
                                            <Col md={8}>

                                                <DatePicker value={afeData[0].estStartDate}
                                                    className={(!validateDataItem.estStartDate ? "form-error" : "")}
                                                    onChange={(v, f) => this.onTextChange("estStartDate", v, f)}
                                                    disabled={this.isFormReadOnly() || statusKey === 4}
                                                />
                                            </Col>

                                        </Row>



                                    </Col>
                                </Row>


                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="county">County</Label>

                                            </Col>
                                            <Col md={8}>

                                                <Select options={countyList}
                                                    isDisabled={this.isFormReadOnly() || statusKey === 4}
                                                    className={(!validateDataItem.county ? "form-error" : "")}
                                                    value={this.getSelCounty(afeData[0].county)}
                                                    defaultValue={this.getSelCounty(afeData[0].county)}
                                                    onChange={(e) => this.onTextChange("county", e, e)}
                                                    isClearable={true}

                                                >
                                                </Select>

                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="estComplete">End Date</Label>
                                            </Col>

                                            <Col md={8}>
                                                <DatePicker value={afeData[0].estComplete}
                                                    className={(!validateDataItem.estComplete ? "form-error" : "")}
                                                    onChange={(v, f) => this.onTextChange("estComplete", v, f)}
                                                    disabled={this.isFormReadOnly() || statusKey === 4}
                                                />

                                            </Col>

                                        </Row>

                                    </Col>
                                </Row>

                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="area">Area</Label>

                                            </Col>
                                            <Col md={8}>

                                                <Select options={areaList.filter(e => e.locationKey === afeData[0].locationKey)}
                                                    isDisabled={(this.isFormReadOnly() || statusKey === 4) && !((isAFEAdmin || isAFEManager) && (statusKey !== 5 && statusKey !== 8))}
                                                    className={(!validateDataItem.area ? "form-error" : "")}
                                                    value={this.getSelArea(afeData[0].areaKey)}
                                                    defaultValue={this.getSelArea(afeData[0].areaKey)}
                                                    onChange={(e) => this.onTextChange("areaKey", e, e)}
                                                    isClearable={true}

                                                >
                                                </Select>

                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="wbOwnedPct">WI%</Label>

                                            </Col>
                                            <Col md={8}>
                                                <NumberFormat value={afeData[0].wbOwnedPct}
                                                    onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                    className={(!validateDataItem.wbOwnedPct ? "form-error form-control text-md-right" : "form-control text-md-right")}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    //displayType={'text'} 
                                                    disabled={(this.isFormReadOnly() || statusKey === 4) && !((isAFEAdmin || isAFEManager) && (statusKey !== 5 && statusKey !== 8))}
                                                    thousandSeparator={true}
                                                    suffix={'%'} />

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row form style={{ marginBottom: "5px" }}>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>


                                            </Col>
                                            <Col md={8}>



                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="wbOwnedPct">Reimbursable</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Select options={reimbursable}
                                                    isDisabled={(this.isFormReadOnly() || statusKey === 4) && !((isAFEAdmin || isAFEManager) && (statusKey !== 5 && statusKey !== 8))}
                                                    value={this.getReimbursable(afeData[0].reimbursableProject)}
                                                    defaultValue={this.getReimbursable(afeData[0].reimbursableProject)}
                                                    onChange={(e) => this.onTextChange("reimbursable", e, e)}
                                                    isClearable={true} >
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                            Project Scope
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink hidden={(afeHeaderKey < 0 || !hasDCCost)}
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggleTab('2'); }}
                                        >
                                            {"Drilling & Completion Information"}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink hidden={(afeHeaderKey < 0 || statusKey === 0)}
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { this.toggleTab('3'); }}
                                        >
                                            {"Fixed Assets"}
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink hidden={(afeHeaderKey < 0)}//{(afeHeaderKey < 0 || statusKey === 0)}
                                            className={classnames({ active: activeTab === '4' })}
                                            onClick={() => { this.toggleTab('4'); }}
                                        >
                                            {"Additional Details"}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row form>
                                            <Col md={12}>
                                                <Input type="textarea" name="afeDescription"
                                                    rows={8}
                                                    className={(!validateDataItem.afeDescription ? "form-error" : "")}
                                                    disabled={this.isFormReadOnly()}
                                                    id="afeDescription" placeholder="Project Scope"
                                                    value={afeData[0].afeDescription}
                                                    defaultValue={afeData[0].afeDescription}
                                                    onChange={(e) => this.onTextChange("afeDescription", e.target.value, e)}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <AFEFixAssets
                                            afeData={afeData}
                                            isFormReadOnly={!(isApprover || isAFEAdmin || isAFEManager) ||
                                                statusKey === 5 || statusKey === 6 || statusKey === 8}
                                            //isFormReadOnly={false}

                                            onTextChange={this.onTextChange}>

                                        </AFEFixAssets>
                                    </TabPane>

                                    <TabPane tabId="4">
                                        <AFEAdditionalDetails
                                            afeData={afeData}
                                            projectStatusList={projectStatusList}
                                            isFormReadOnly={(!(isApprover || isAFEAdmin || isAFEManager) ||
                                                (statusKey === 5 || statusKey === 6 || statusKey === 8))}
                                            onTextChange={this.onTextChange}
                                            closeoutStatusKey={closeoutStatusKey}
                                            statusKey={statusKey}>

                                        </AFEAdditionalDetails>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                            <Col md={6}>
                                                <Row form>
                                                    <Col md={4}>
                                                        <Label for="wellName">Well Name</Label>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Input type="text" name="wellName"
                                                            //  className={(!validateDataItem.wellName ? "form-error" : "")}
                                                            id="wellName" placeholder=""
                                                            disabled={this.isFormReadOnly()}
                                                            onChange={(e) => this.onTextChange("wellName", e.target.value, e)}
                                                            value={afeData[0].wellName}
                                                        />
                                                    </Col>

                                                </Row>
                                            </Col>

                                            <Col md={6}>

                                                <Row form>
                                                    <Col md={4}>
                                                        <Label for="lease">Lease</Label>
                                                    </Col>

                                                    <Col md={8}>
                                                        <Input type="text" name="lease"
                                                            //  className={(!validateDataItem.wellName ? "form-error" : "")}
                                                            id="lease" placeholder=""
                                                            disabled={this.isFormReadOnly()}
                                                            onChange={(e) => this.onTextChange("lease", e.target.value, e)}
                                                            value={afeData[0].lease}
                                                        />
                                                    </Col>

                                                </Row>

                                            </Col>

                                        </Row>

                                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                            <Col md={6}>

                                                <Row form>
                                                    <Col md={4}>
                                                        <Label for="apiNumber">API Number</Label>
                                                    </Col>

                                                    <Col md={8}>
                                                        <Input type="text" name="apiNumber"
                                                            //  className={(!validateDataItem.wellName ? "form-error" : "")}
                                                            id="apiNumber" placeholder=""
                                                            disabled={this.isFormReadOnly()}
                                                            onChange={(e) => this.onTextChange("apiNumber", e.target.value, e)}
                                                            value={afeData[0].apiNumber}
                                                        />
                                                    </Col>

                                                </Row>
                                            </Col>

                                            <Col md={6}>
                                                <Row form>
                                                    <Col md={4}>
                                                        <Label for="field">Field</Label>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Input type="text" name="field"
                                                            //  className={(!validateDataItem.wellName ? "form-error" : "")}
                                                            id="field" placeholder=""
                                                            disabled={this.isFormReadOnly()}
                                                            onChange={(e) => this.onTextChange("area", e.target.value, e)}
                                                            value={afeData[0].area}
                                                        />
                                                    </Col>

                                                </Row>


                                            </Col>

                                        </Row>

                                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                            <Col md={6}>

                                                <Row form>
                                                    <Col md={4}>
                                                        <Label for="surfaceLegal">Surface Legal</Label>
                                                    </Col>

                                                    <Col md={8}>
                                                        <Input type="textarea" name="surfaceLegal" rows={4}
                                                            //  className={(!validateDataItem.wellName ? "form-error" : "")}
                                                            id="surfaceLegal" placeholder=""
                                                            disabled={this.isFormReadOnly()}
                                                            onChange={(e) => this.onTextChange("surfaceLegal", e.target.value, e)}
                                                            value={afeData[0].surfaceLegal}
                                                        />
                                                    </Col>

                                                </Row>
                                            </Col>

                                            <Col md={6}>
                                                <Row form>
                                                    <Col md={4}>
                                                        <Label for="bottomLegal">Bottom Legal</Label>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Input type="textarea" name="field" rows={4}
                                                            //  className={(!validateDataItem.wellName ? "form-error" : "")}
                                                            id="bottomLegal" placeholder=""
                                                            disabled={this.isFormReadOnly()}
                                                            onChange={(e) => this.onTextChange("bottomHoleLegal", e.target.value, e)}
                                                            value={afeData[0].bottomHoleLegal}
                                                        />
                                                    </Col>

                                                </Row>


                                            </Col>

                                        </Row>

                                    </TabPane>
                                </TabContent>

                            </AccordionItemPanel>
                        </AccordionItem>

                        <div hidden={afeHeaderKey < 0}>

                            <AccordionItem hidden={!showCostTracking}
                                uuid='costTracking'>
                                <AccordionItemHeading>
                                    <AccordionItemButton style={{
                                        background:
                                            ((afeData[0].actualvsBudget <= 104.44 ? "#b8f2b1" :
                                                (afeData[0].actualvsBudget >= 114.44 ? "#f8858b" :
                                                    (afeData[0].actualvsBudget > 104.44 && afeData[0].actualvsBudget < 114.44) ? "#ffaf7a" :
                                                        "#transparent")))
                                    }} >
                                        <strong>
                                            {
                                                // "Cost Tracking: Actual vs Budget: " + afeData[0].actualvsBudget + "% - " +
                                                // "Budget: " + USDCurrencyFormat(afeData[0].afeTotalAmt + objAFEsRevSupBuget, 0) + " - " +
                                                // "Actuals: " + USDCurrencyFormat(afeData[0].invAmount, 0) 
                                                // + "     test: " + objAFEsRevSupBugetDetails  

                                                "Cost Tracking: Actual vs Budget: " + afeData[0].actualvsBudget + "% - " +
                                                "Budget: " +
                                                (objAFEsRevSupBugetDetails.includes('R') ? USDCurrencyFormat(objAFEsRevSupBuget, 0) + " - " 
                                                                                        : USDCurrencyFormat(afeData[0].afeTotalAmt + objAFEsRevSupBuget, 0) + " - ")   + 
                                                "Actuals: " + USDCurrencyFormat(afeData[0].invAmount, 0) 
                                                    
                                            }
                                        </strong>

                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <AFECostTracking afeHeaderKey={afeHeaderKey}
                                        user={user}
                                        key={afeData[0].afeHeaderKey}
                                        afeData={afeData}
                                        isAFEAdmin={isAFEAdmin}
                                        isAFEManager={isAFEManager}
                                        onTextChange={this.onTextChange}>

                                    </AFECostTracking>

                                </AccordionItemPanel>
                            </AccordionItem>


                            <AccordionItem hidden={afeData[0].closeoutStatusKey < 0}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Closeout Details"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <AFECloseout afeHeaderKey={afeHeaderKey}
                                        user={user}
                                        key={afeData[0].afeHeaderKey}
                                        isAFEAdmin={isAFEAdmin}
                                        isAFEManager={isAFEManager}>

                                    </AFECloseout>

                                </AccordionItemPanel>
                            </AccordionItem>

                            <BugetSection costTypeList={costTypeList}
                                afeData={afeData}
                                uomList={uomList}
                                user={user}
                                //ref={this.childRef}
                                isForceUpdate={isForceBudgeLineUpdate}
                                budgetLineRefreshKey={budgetLineRefreshKey}
                                isFormReadOnly={this.isFormReadOnly()}
                                key={afeData[0].afeTotalAmt}
                                onTextChange={this.onTextChange}
                                handleCallBackSiteTransfer={(e) => this.onSiteToSiteChange(e)}
                                afesRevSupBuget={objAFEsRevSupBugetDetails}
                            >
                            </BugetSection>


                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Attachments (" + afeData[0].docCount + ")"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <AFEAttachment afeHeaderKey={afeHeaderKey}
                                        user={user}
                                        key={afeData[0].afeHeaderKey}
                                        isAFEAdmin={isAFEAdmin}
                                        isAFEManager={isAFEManager}
                                        closeoutStatusKey={closeoutStatusKey}
                                        statusKey={statusKey}>
                                    </AFEAttachment>
                                </AccordionItemPanel>
                            </AccordionItem>


                                {/* <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <strong>{"Cost Summary (" + USDCurrencyFormat(afeData[0].afeTotalAmt, 0) + ")"} </strong>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <AFEExportCostDetails
                                            afeHeaderKey={afeHeaderKey}>

                                        </AFEExportCostDetails>
                                        <ColoredLine color="grey" />
                                        <AFECostSummary afeHeaderKey={afeHeaderKey}
                                            user={user}
                                            key={Math.random()}>

                                        </AFECostSummary>
                                        <ColoredLine color="grey" />
                                        <Row form style={{ marginTop: "5px" }}>
                                            <Col md={12}>
                                                <CostSummary
                                                    afeData={afeData}
                                                    onTextChange={this.onTextChange}
                                                    isFormReadOnly={this.isFormReadOnly() || statusKey >= 4} />
                                            </Col>
                                        </Row>
                                    </AccordionItemPanel>
                                </AccordionItem> */}

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>Approval</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <AFEApproval afeHeaderKey={afeHeaderKey}
                                        user={user}
                                        empList={empList}
                                        isAFEManager={isAFEManager}
                                        isAFEAdmin={isAFEAdmin}
                                        isFormReadOnly={isFormReadOnly}
                                        key={Math.random()} //{afeData[0].afeHeaderKey}// 
                                        afeStatusKey={statusKey}
                                        closeoutStatusKey={closeoutStatusKey}
                                        waitingOnApprovers={afeData[0].waitingOnApprovers}>

                                    </AFEApproval>

                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Comments (" + afeData[0].commentCount + ")"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <AFEComment
                                        afeHeaderKey={afeHeaderKey}
                                        afeData={afeData}
                                        user={user}
                                        isAFEAdmin={isAFEAdmin}
                                        isAFEManager={isAFEManager}
                                        key={afeHeaderKey}>

                                    </AFEComment>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"AFE Logs"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>

                                    <RecordLogs
                                        primaryKey={afeHeaderKey}
                                        closeVisable={false}
                                        isDataChange={!isDataChange}
                                        >

                                    </RecordLogs>
                                </AccordionItemPanel>
                            </AccordionItem>        
                        </div>
                    </Accordion>
                    <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            size={"xl"}
                            centered
                        >
                            <ModalHeader toggle={() => this.toggleReport()}> {reportName == 'AFECloseout' ? 'AFE Closeout' : "Approval Document"} </ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName={reportName}
                                    reportParameters={this.createReportParamter()}

                                    toggle={this.toggleReport}

                                >

                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={manualEdits}
                            toggle={() => this.toggleManualEdits()}
                            size={"xl"}
                            centered
                        >
                            <ModalHeader toggle={() => this.toggleManualEdits()}> {'Manual Edits'} </ModalHeader>
                            <ModalBody> 
                                 <AFEManualEdits
                                    afeDTO={afeData[0]}
                                    afeTypeList={afeTypeList}
                                    empList={empList}
                                    linkAFEList={linkAFEList}
                                    budgetClassList={budgetClassList}
                                    budgetList={budgetList}
                                    costTypeList={costTypeList}
                                    basinList={basinList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    cancelSave={()=>this.toggleManualEdits()}
                                    user={user}
                                    closeForm={()=>this.toggleManualEditsCloseForm()}
                                 >

                                 </AFEManualEdits>
                            </ModalBody>
                        </Modal>
                    </div>
                </Form>

            </div>)
    }

}
// return <AFEDetail props={props}/>
// })

export default AFEDetail
