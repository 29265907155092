
import React, { Component, useState } from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import {
    Card, Button, CardText, CardLink,
    CardHeader, CardBody, CardDeck, CardGroup
} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { apiURL, apiDELETEHeader, apiPOSTHeader } from '../resources/apiURL';
import PlusSymbol128 from '../resources/PlusSymbol128.png'
import TrashBin from '../resources/trash.svg'
import DocumentUploader from '../resources/DocumentUploader';
import ConfirmModal from '../operation/ConfirmModal'
import { FileUploader } from "react-drag-drop-files";
import FilePreviewer from 'react-file-previewer';



function PreviewFile(props) {

    const {
        documentKey,
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        buttonLabel,
        id,
        style,
        disabled
    } = props

    const [modal, setModal] = useState(false);
    const [docData, setDocData] = useState(null)

    const toggle = () => {
        setModal(!modal);
        //downloadAtt()
    }

    const toggleOpen = () => {
        downloadAtt()
    }


    const onClosed = () => {

        if (props.onClosed !== undefined) {
            props.onClosed();
        }
    }

    const downloadAtt = () => {

        let myURI = apiURL + 'Documents/Base64/' + documentKey
        fetch(myURI)
            .then(res => res.json())
            .then((data) => {
                console.log("data")
                console.log(data)
                setDocData(data)
                setModal(!modal);
            })

    }

    return (
        <div>
            <Button outline={outline}
                style={style}
                color={color}
                className={className}
                hidden={hidden}
                disabled={disabled}             
                onClick={toggleOpen} //style={{ margin: "0 5px 0 5px" }} 
                >
                {image}
                {buttonLabel}
            </Button>


            <Modal isOpen={modal}
                size="lg"
                toggle={toggle}
                onClosed={onClosed} >
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>

                    <FilePreviewer
                        file={{
                            data: (docData === undefined || docData === null ? null : docData[0].docFileData),
                            mimeType: (docData === undefined || docData === null ? null : docData[0].docFileTypeShort), //"image/jpg", // "application/pdf" ,//(docData===undefined || docData===null ? null: docData[0].docFileType),
                            name: (docData === undefined || docData === null ? null : docData[0].docFileName)// for download
                        }}
                    />

                </ModalBody>
            </Modal>
        </div>
    )
}



function DocumentCardList(props) {
    let fileReader;
    var selectedFile = null
    const allowDelete=props.allowDelete

    const [linkPrimaryKey] = useState(props.linkPrimaryKey);
    const [linkTableName] = useState(props.linkTableName);

    const docList = props.docList
    const [directUpload] = useState((props.directUpload !== null && props.directUpload !== undefined ?
        props.directUpload : false))

    const [modalPreview, setModalPreview] = useState(false)
    const [docData,setDocData] = useState(null)

    const addNewDoc = () => {
        props.toggle();
        //this.setState({})
    }

    const handleChange = (file) => {
        // console.log("file")
        // console.log(file)
        if (directUpload) {
            selectedFile = file
            handleSubmission(file)
        } else {
            props.toggle(file);
        }

    }
    const handleSubmission = (file) => {
        // console.log("selectedFile")
        // console.log(selectedFile)
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsDataURL(file)

    };

    const handleFileRead = (e) => {
        const content = fileReader.result;

        // console.log("fileReader.name")
        // console.log(e)

        const hdrDTO = documentForCreateDto(content)


        let myURI = apiURL + 'Documents/Base64'
        fetch(myURI, apiPOSTHeader(hdrDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }
                props.refreshDocList();
                //closeForm();

                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })


    }
    const documentForCreateDto = (data) => {

        // console.log("selectedFile.name.data")
        // console.log(data)

        const docCreateDTO = {
            "DocFileName": selectedFile.name,
            "DocFileDesc": "",
            "DocFileDataBase64": data,
            "LinkTableName": linkTableName,
            "LinkPrimaryKey": linkPrimaryKey
        }
        return docCreateDTO;
    }


    const deleteDoc = (e) => {
        //e.preventDefault();
        // console.log("deleteDoc")
        // console.log(e)

        const hdrDELDto = {
            "docFileName": "",
            "docFileDesc": "",
            "documentKey": e
        }

        // console.log(hdrDELDto)

        let myURI = apiURL + 'Documents'
        fetch(myURI, apiDELETEHeader(hdrDELDto))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {

                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                props.refreshDocList();
                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })



    }

    const downloadAtt = (e, v) => {
        e.preventDefault();
        // console.log("download")        
        // console.log(e.target.id)


        let myURI = apiURL + 'Documents/Base64/' + e.target.id
        fetch(myURI)
            .then(res => res.json())
            .then((data) => {
                const base64 = data[0].docFileType + data[0].docFileData

                if(data[0].allowPreview !== 0){
                    previewFileAttachment(data)
                }else{
                    downloadPDF(base64, data[0].docFileName)
                }                
            })
    }

    const previewFileAttachment = (docData) => {
        setDocData(docData)
        setModalPreview(true)
    }


    const downloadPDF = (base64, fileName) => {
        //console.log(base64)
        //const linkSource = 'data:application/pdf;base64;' + {base64};
        const downloadLink = document.createElement("a");
        //const fileName = fileName; //"vct_illustration.mp4";

        downloadLink.href = base64;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return <CardGroup>
        {docList.map(v =>
            <Card>
                <CardHeader style={{maxWidth:"250px", maxHeight:"100px"}}>
                    <Row>
                        <Col sm={8}>
                            <CardLink href="" id={v.documentKey} onClick={(e, v) => { downloadAtt(e, v) }}>{v.docFileName}</CardLink>
                        </Col>
                        <Col sm={4}>

                            <ConfirmModal outline={true}
                                color="secondary"
                                className="btn-no-border float-right"
                                buttonLabel=""
                                hidden={!allowDelete}
                                formName="Confirm"
                                id={v.documentKey}
                                message="Are you sure you want to delete this attachment?"
                                onClickYes={(e) => deleteDoc(v.documentKey)}
                                image={<img
                                    src={TrashBin}
                                    id={v.documentKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 0 0 0" }} />}>

                            </ConfirmModal>


                        </Col>
                    </Row>
                    <div>
                        <Modal isOpen={modalPreview} toggle={() => setModalPreview(!modalPreview)}
                            //contentClassName="safetyObsDetail-modal-style"
                             size="xl"
                        >
                            <ModalHeader toggle={() => setModalPreview(!modalPreview)}>{"Preview"}</ModalHeader>
                            <ModalBody>
                                <FilePreviewer
                                    //hideControls={true}
                                    file={{
                                        data: (docData === undefined || docData === null ? null : docData[0].docFileData),
                                        mimeType: (docData === undefined || docData === null ? null : docData[0].docFileTypeShort), //"image/jpg", // "application/pdf" ,//(docData===undefined || docData===null ? null: docData[0].docFileType),
                                        name: (docData === undefined || docData === null ? null : docData[0].docFileName)// for download
                                    }}
                                />

                            </ModalBody>
                        </Modal>
                    </div>
                </CardHeader>
                {/* <CardBody>

                    <CardText tag="h7">{(v.docFileName === v.docFileDesc ? "" : v.docFileDesc)}</CardText>

                    
                </CardBody> */}
            </Card>


        )}

     

        <FileUploader handleChange={handleChange}
            name="file">
        </FileUploader>
    </CardGroup>

}


export default class DocumentBrowserDragDrop extends Component {
    static displayName = DocumentBrowserDragDrop.name;

    constructor(props) {
        super(props);
        this.state = {
            apiURL: props.apiURL,
            docList: [],
            isDocListLoaded: false,
            modal: false,
            modalPreview: false,
            linkTableName: props.linkTableName,
            linkPrimaryKey: props.linkPrimaryKey,
            disabled: (props.disabled === undefined ? false : props.disabled),
            selectedFile: null,        
            allowDelete:(props.allowDelete === undefined ? true : props.allowDelete)
        }
    }

    toggle = () => {
        //console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })

    }


    toggleModalPreview = () => {
        this.setState({ modalPreview: !this.state.modalPreview })
    }

    toggleFileUploader = (file) => {
        // console.log("file.name")
        // console.log(file.name)
        this.setState({
            selectedFile: file,
            modal: !this.state.modal
        })

    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.docList;
    }

    getDocList = () => {
        const { apiURL } = this.state

        fetch(apiURL)
            .then(res => res.json())
            .then((json) => {
                this.setState({
                    isDocListLoaded: true,
                    docList: json
                })
            })
    }

    addNewDoc = () => {

    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getDocList()
    }

    refreshForm = () => {
        // console.log("setState")
        this.getDocList()
    }

    render() {
        const { docList, isDocListLoaded, linkTableName,allowDelete,
            selectedFile, linkPrimaryKey, disabled } = this.state

        if (!isDocListLoaded) {
            return (<div>
                <h3>Loading Document List</h3>
            </div>)
        } else
            return (
                <div>
                    <FormGroup>
                        <Row form>
                            <DocumentCardList docList={docList}
                                toggle={this.toggleFileUploader}
                                disabled={disabled}
                                refreshDocList={this.refreshForm}
                                linkTableName={linkTableName}
                                linkPrimaryKey={linkPrimaryKey}
                                directUpload={true}
                                allowDelete={allowDelete} />
                        </Row>
                    </FormGroup>

                    <div>
                        <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                            //contentClassName="safetyObsDetail-modal-style"
                            size="xl"
                        >
                            <ModalHeader toggle={() => this.toggle()}> {this.props.formName}</ModalHeader>
                            <ModalBody>
                                <DocumentUploader formName="AFE Document Uploader"
                                    toggle={this.toggle}
                                    selectedFile={selectedFile}
                                    linkTableName={linkTableName}
                                    linkPrimaryKey={linkPrimaryKey}
                                    refreshDocList={this.refreshForm}
                                />

                            </ModalBody>
                        </Modal>
                    </div>

                    {/* <div>
                        <Modal isOpen={this.state.modalPreview} toggle={() => this.toggle()}
                            //contentClassName="safetyObsDetail-modal-style"
                            size="xl"
                        >
                            <ModalHeader toggle={() => this.toggleModalPreview()}> {this.props.formName}</ModalHeader>
                            <ModalBody>
                            <FilePreviewer 
                                            file={{
                                                data: "<base64 string>",
                                                mimeType: 'application/pdf',
                                                name: 'sample.pdf' // for download
                                            }}
                                        />

                            </ModalBody>
                        </Modal>
                    </div> */}
                </div>


            )

    }

}
