

import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, Card, CardBody, CardHeader } from 'reactstrap';
// import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import { Color, ComponentRenderer, LinkedListNode$1 } from 'igniteui-react-core';
//import '../../custom.css';
import '../custom.css';
import { v4 as uuidv4 } from 'uuid';

// import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../../resources/utility';
import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



export default class 
ConstructionReportingCompleteChk extends Component {
    static displayName = ConstructionReportingCompleteChk.name;
    constructor(props) {
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onCellValueChanging = this.onCellValueChanging.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onCellStyleKeyRequested = this.onCellStyleKeyRequested.bind(this); 
        this.getCellColor = this.getCellColor.bind(this);

        this.state = {
            closeoutChk: [],
            closeoutChkFilter: [],
            isDataLoaded: false,
            afeHeaderKey: props.afeHeaderKey,
            showAlert: false,
            alertMsg: '',
            alertColor: "danger",
            isFormReadOnly: props.isFormReadOnly,
            constructionReportCompleteDTO: null,
            user: props.user,
            constructionReportDTO: props.constructionReportDTO,
            isDisabledComments: false,
            validateDataItem: {
                completionChecklist: true,
                finalReportForThisProject: true,
                extraMaterialsReturnedToInventory: true,
                tempEquipmentReleased: true,
                changeOrdersReviewedWithProjectManager: true,
                rowCleanupAndReseedingCompleted: true,
                hydrotestPressureSheetFile: true,
                reasonWhyNotHundredPercent: true,
            },
            constructionReportCloseoutLinesKey: 0, 
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.closeoutChkFilter;
        //this.onLoad()
    }


    componentDidMount() {
        //this.getCloseoutSC()

        //let constructionReportCompleteDTO = {lineDescription}
        this.getQuestions();
        this.setState({isDataLoaded: true});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.closeoutChkFilter !== this.state.closeoutChkFilter) {
          this.removeEventListeners();
          //this.addEventListeners();
        }
      }

    getCloseoutSC = () => {
        const { afeHeaderKey } = this.state

        let myURI = apiURL + 'AFE/closeout/CHKL/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    closeoutChk: json
                })
            });
    }

    getDetails = async(constructionReportKey) => {
         
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    constructionReportDTO: json, 
                }) 
            });
    }

    async getQuestions(){
        const {constructionReportDTO} = this.state;
        // let temp = [
        //             { lineNo: 1,lineDescription: 'Is this the final report for this project?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey: 1,isDisabled: false},
        //             // {lineNo: 2,lineDescription: 'What prevented this project from being 100% completed?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey: 2,isDisabled: true},
        //             { lineNo: 3,lineDescription: 'Extra materials returned to inventory?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey: 3,isDisabled: false},
        //             { lineNo: 4,lineDescription: 'Temp equipment released?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey: 4,isDisabled: false},
        //             { lineNo: 5,lineDescription: 'All change orders reviewed with project manager?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey:5,isDisabled: false},
        //             { lineNo: 6,lineDescription: 'ROW cleanup and reseeding completed?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey: 6,isDisabled: false},
        //             { lineNo: 7,lineDescription: 'Hydrotest pressure sheet file?/PSSR complete?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey:7,isDisabled: false}
        //         ]
        let temp = [];

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/CloseOutQuestions/' + constructionReportDTO.constructionReportKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => { 
                temp = json 
            });

        temp = temp.map(item => ({...item,
                                isDisabled: item.lineNo == 2 ? true : false
        }));

        let question2 = null; 
        
        if (constructionReportDTO.statusKey != 3){
            question2 = temp.filter(x => x.lineNo == 2)[0];
            temp = temp.filter(x => x.lineNo != 2 ) 
        }
        
    this.setState({closeoutChk: temp,closeoutChkFilter: temp,question2: question2});

    }

    saveData = async()=>{
        const { constructionReportDTO } = this.state
 
            this.dataValidation().then((data) => {
                if (data.ok) {
  
                    if (constructionReportDTO.constructionReportKey > 0) { 
                        //this.updateRecord();
                        this.returnDTO() 
                    }  
  
                } else {
                    this.setState({
                        alertMessage: data.message,
                        validateDataItem: data.validateDataItem,
                        showAlert: true
                    })
                }
            }) 
    }

    returnDTO=()=>{
        
        // let obj  = this.state.closeoutChkFilter.filter( x => x.lineNo == 2);
        
        // if (obj != null || obj.length <= 0){
        //     this.addNewRow();
        // }

        const {closeoutChkFilter} = this.state;
        this.props.savedData(closeoutChkFilter);
    }

    cancel=()=>{
        const {closeoutChk} = this.state;
        this.props.cancelSave();
    }
    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const { constructionReportDTO, validateDataItem ,constructionReports,closeoutChkFilter} = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }

 
            let obj = closeoutChkFilter.filter( x => x.lineNo != 2 && ( x.miscValue1 == null || (x.miscValue1 != 'Yes' && x.miscValue1 != 'No')) ); 
            if (closeoutChkFilter[0].miscValue1 === 'Yes' && constructionReportDTO.totalCompletion < 100 && 
                (closeoutChkFilter[1].comments === null || closeoutChkFilter[1].comments === '' ) ){
              
            //validateDataItem.finalReportForThisProject = false 
            returnVal.ok = false
            returnVal.message = "Comment is required for question 2 because the project is not 100% complete."
            //alertMessage = "Comment is required for question 2.";
            }
            else if (closeoutChkFilter[0].miscValue1 === 'No' && constructionReportDTO.totalCompletion < 100){
              
            //validateDataItem.finalReportForThisProject = false 
            returnVal.ok = false
            returnVal.message = "Please submit final report before completing this project."
            //alertMessage = "Comment is required for question 2.";
            } 
            else if (obj.length > 0) {
                //validateDataItem.extraMaterialsReturnedToInventory = false
                returnVal.ok = false
                returnVal.message = returnVal.message + "All questions require a Yes or No answer." 
                //alertMessage = "All question require a Yes or No answer.";
            }
            
            resolve(returnVal)
        })
    }
 


    toggleAlert = () => { 
        this.setState({ showAlert: !this.state.showAlert  });
    }

    handleChange = (id, field, value) => {
        this.setState(prevState => ({
            closeoutChkFilter: prevState.closeoutChkFilter.map(row =>
            row.id === id ? { ...row, [field]: value } : row
          )
        }));
      };

      onGridRowSelected(s, e) {
        //  console.log(" On CLick")
        const content = e.content;
        
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                constructionReportCloseoutLinesKey: dataItem.constructionReportCloseoutLinesKey
            }) 
        } 
        
    }

    onCellStyleKeyRequested = (s, e) => {
        if (e.column.field === "comments") {
          e.styleKey = "customOutlineCell";
          
        }
      }
      
      renderCommentCell = (props) => {
         
        const rowData = props.dataContext;
        const lineNo = rowData ? rowData.rowItem.lineNo : '';
        //const constructionReportCloseoutLinesKey = rowData ? rowData.rowItem.constructionReportCloseoutLinesKey : 0;
        const comments = rowData ? rowData.rowItem.comments : '';
  
        const handleChange = (event) => {
            const newValue = event.target.value;
            const {constructionReportCloseoutLinesKey} = this.state 
            //this.closeoutChkChange (constructionReportCloseoutLinesKey, newValue)
            this.updateCloseoutLine(constructionReportCloseoutLinesKey, "comments", newValue, "")
            //this.setState({isDataLoaded: true});
          };
        
         

        const inputStyle = { 
            borderColor: (lineNo == 2 ? 'red' : ''),//this.getCellColor(lineNo),
            height:"100%",
            width:"100%", 
          };

        return (  
            <input
                type="text"
                value={comments} 
                onChange={handleChange} 
                style={inputStyle}
            />  
        );
      };

    getCellColor(lineNo){
        const {closeoutChkFilter,constructionReportCloseoutLinesKey} = this.state;
 
        if (lineNo !== undefined && lineNo !== null && lineNo == 2){
            return "red";
        }
        else {
            return "";
        }

    }  

    render() {
        const {constructionReportDTO, isDataLoaded,closeoutChkFilter, isFormReadOnly,alertMessage,showAlert ,alertColor} = this.state
 
 
        if (!isDataLoaded) {
            return (<div>Loading Screen .......</div>)
        } 
        else{
              
            return (<div>
                <Form>

                    <Row form>  
                            <Alert color={alertColor} toggle={() => this.toggleAlert()}
                                isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                                {alertMessage}
                            </Alert >
                    </Row>

                    <Row form>
                        <Col sm={12}>
                            <h5>Construction Supervisor Completion Checklist (Answer Yes or No)</h5> 
                             { (constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4 || constructionReportDTO.statusKey === 5 ? false : true)  && 
                                (
                                    <IgrDataGrid
                                        ref={this.onGridRef}
                                        height="100%"
                                        width='100%'
                                        dataSource={closeoutChkFilter}
                                        isColumnOptionsEnabled="false"
                                        autoGenerateColumns="false"
                                        selectionMode="SingleRow"
                                        editModeClickAction="SingleClick"
                                        groupSummaryDisplayMode={"RowTop"}
                                        groupHeaderDisplayMode="combined"
                                        summaryScope="Both"
                                        editMode={0} 
                                        cellValueChanging={this.onCellValueChanging} 
                                        selectedKeysChanged={this.onGridRowSelected} 
                                    >
                                    <IgrTextColumn
                                        field="lineNo"
                                        headerText="lineNo"
                                        width="*>250"
                                        isHidden={true}
                                    />

                                    <IgrTemplateColumn
                                        field="lineDescription"
                                        headerText="Check List"
                                        width="500"
                                        cellUpdating={this.onCellUpdating}
                                    />

                                    <IgrTemplateColumn
                                        field="miscValue1"
                                        headerText=""
                                        width="150"
                                        cellUpdating={this.onCellUpdating} 
                                    />
  
                                    <IgrTemplateColumn
                                        field="comments"
                                        headerText="Comments"
                                        width="*>250" 
                                        template={this.renderCommentCell}  
                                    />
 
                                    <IgrNumericColumn field="constructionReportKey" isHidden={true}/>
                                    <IgrNumericColumn field="constructionReportCloseoutLinesKey" isHidden={true} />
                                    <IgrTextColumn field="isDisabled" isHidden={true}/>
                                    </IgrDataGrid>
                                )    
                             }
                             { (constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4 || constructionReportDTO.statusKey === 5 ? true : false)  && 
                                (
                                    <IgrDataGrid
                                        ref={this.onGridRef}
                                        height="100%"
                                        width='100%'
                                        dataSource={closeoutChkFilter}
                                        isColumnOptionsEnabled="false"
                                        autoGenerateColumns="false"
                                        selectionMode="SingleRow"
                                        editModeClickAction="SingleClick"
                                        groupSummaryDisplayMode={"RowTop"}
                                        groupHeaderDisplayMode="combined"
                                        summaryScope="Both"
                                        editMode={0} 
                                        cellValueChanging={this.onCellValueChanging} 
                                        selectedKeysChanged={this.onGridRowSelected} 
                                    >
                                    <IgrTextColumn
                                        field="lineNo"
                                        headerText="lineNo"
                                        width="*>250"
                                        isHidden={true}
                                    />

                                    <IgrTemplateColumn
                                        field="lineDescription"
                                        headerText="Check List"
                                        width="500"
                                        cellUpdating={this.onCellUpdating}
                                    />

                                    <IgrTextColumn
                                        field="miscValue1"
                                        headerText=""
                                        width="150" 
                                    />
  
                                    <IgrTextColumn
                                        field="comments"
                                        headerText="Comments"
                                        width="*>250"
                                    /> 
                                    <IgrNumericColumn field="constructionReportKey" isHidden={true}/>
                                    <IgrNumericColumn field="constructionReportCloseoutLinesKey" isHidden={true} />
                                    <IgrTextColumn field="isDisabled" isHidden={true}/>
                                    </IgrDataGrid>
                                )    
                             }   

                        </Col> 
                    </Row> 
                    <Row form style={{ marginTop: "40px", marginBottom: "5px" }} >
                        <Col sm={5}> 
                           
                        </Col> 
                        <Col sm={7} className="float-right">  
                            <Button color="danger"
                                className="float-right"
                                style={{ marginRight: "5px" }} 
                                //ref={ref}
                                hidden={ constructionReportDTO.statusKey == 3 || constructionReportDTO.statusKey == 4 || constructionReportDTO.statusKey == 5}
                                onClick={() => this.cancel()}
                                >Cancel</Button>
                            <Button color="primary" 
                                className="float-right"
                                style={{ marginRight: "5px" }} 
                                onClick={() => this.saveData()}
                                hidden={ constructionReportDTO.statusKey == 3 || constructionReportDTO.statusKey == 4 || constructionReportDTO.statusKey == 5}>
                                Save
                                </Button>
                        </Col>
                    </Row>
                </Form>

            </div >)
        }

            
    }

    onCellClick = (s, e) => {
        const item = e.cellInfo.dataRow;
        if (item.isDisabled) {
          e.preventDefault();
          e.stopPropagation();
        }
      };

    // onCellUpdating = (s, e) => {
    //     const item = e.cellInfo.dataRow;
    //     if (item.isDisabled) {
    //       e.cellInfo.element.classList.add('disabled-row');
    //     } else {
    //       e.cellInfo.element.classList.remove('disabled-row');
    //     }
    // }

    onCellValueChanging(s, e) {

        this.updateCloseoutLine(e.item.constructionReportCloseoutLinesKey, e.column.field, e.newValue, e)
        //this.setState({isDataLoaded: true});
    }

    updateCloseoutLine = (key, fieldName, value, e) => {
        const { user } = this.props
        let {closeoutChkFilter,constructionReportDTO} = this.state
    
        let tempCloseoutChkFilter = [];
          
        tempCloseoutChkFilter = closeoutChkFilter.map((item) =>
            item.constructionReportCloseoutLinesKey===key ? 
                                    { ...item, 
                                        // id: key,
                                        lineNo: item.lineNo,
                                        constructionReportCloseoutLinesKey: item.constructionReportCloseoutLinesKey,
                                        miscValue1: (fieldName === "miscValue1" ? value : item.miscValue1),
                                        comments: (fieldName === "comments" ? value : item.comments), 
                                        modifiedBy: getUserADAccount(user.account.userName),  
                                        isDisabled: (fieldName === "isDisabled" ? value : item.isDisabled)
                                    } : item
        );  
        
        this.clearCloseoutChkFilter();
         
        if (tempCloseoutChkFilter.filter(x => x.lineNo == 1 && x.miscValue1 == 'Yes' && constructionReportDTO.totalCompletion < 100 ).length > 0){
            if (tempCloseoutChkFilter.filter(x => x.lineNo == 2).length <= 0){
                tempCloseoutChkFilter = this.addNewRow(tempCloseoutChkFilter);
            }
        }
        else{
            tempCloseoutChkFilter = this.removeRow(tempCloseoutChkFilter);
        }

        //this.state.closeoutChkFilter = [];
        this.setState({closeoutChkFilter: [],isDataLoaded: false});
        this.setState({closeoutChkFilter: tempCloseoutChkFilter,isDisabledComments: false ,isDataLoaded: true}); 
    }
    
    addNewRow(tempArray){
        let {question2} = this.state;
        //{lineNo: 2,lineDescription: 'What prevented this project from being 100% completed?',miscValue1: null,comments: null,constructionReportKey: 0,constructionReportCloseoutLinesKey: 2,isDisabled: true},
        // const newData = {
        //                 //id: uuidv4()
        //                 lineNo: 2
        //                 ,lineDescription: 'What prevented this project from being 100% completed?'
        //                 ,miscValue1: null
        //                 ,comments: null
        //                 ,constructionReportKey: 0
        //                 ,constructionReportCloseoutLinesKey: 2
        //                 ,isDisabled: true};
        
        //question2.comments = null;
        
        let temp = tempArray;   
         
        temp = [...temp, question2];
        

        temp = temp.sort((a, b) => {
                if (a['lineNo'] < b['lineNo']) {
                return  -1;
                }
                if (a['lineNo'] > b['lineNo']) {
                return 1;
                }
                return 0;
        });

        //this.setState({closeoutChkFilter: tempArray});  
        return temp;
    }

    removeRow(tempArray){
        tempArray = tempArray.filter(x => x.lineNo != 2)
        //this.setState({closeoutChkFilter: tempArray});
        return tempArray;
    }

    clearCloseoutChkFilter=()=>{
        this.setState({closeoutChkFilter: [],alertMessage: '',showAlert: false});
    }

    onCellUpdating(s, e) {

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        let select;
 

        if (content.childElementCount === 0) {

            if (s.field === "miscValue1") {
                
                if(item.lineNo !== 2){
                    select = document.createElement("select");

                    // select.addEventListener("change", () => {
                    //     this.closeoutChkChange(item.constructionReportCloseoutLinesKey, select.value) 
                    // })
      
                    select.onchange = (event) => {
                        //this.handleChange(dataItem.id, 'status', event.target.value);
                        this.closeoutChkChange(item.constructionReportCloseoutLinesKey, event.target.value) 
                      };
    
                    var option = document.createElement("option");
                    option.setAttribute("value", -1);
                    option.text = "";
                    select.appendChild(option);
    
                    var opYes = document.createElement("option");
                    opYes.setAttribute("Yes", -1);
                    opYes.text = "Yes";
                    opYes.style.fontSize = "13px";
                    opYes.style.fontFamily = "Verdana";
                    select.appendChild(opYes);
    
                    var opNo = document.createElement("option");
                    opNo.setAttribute("No", -1);
                    opNo.text = "No";
                    opNo.style.fontFamily = "Verdana";
                    opNo.style.fontSize = "13px";
                    select.appendChild(opNo);
                    
                    select.style.padding = "0px 0px 0px 0px";
                    select.style.margin = "0px 0px 0px 0px";
                    select.style.width = "105%";
                    select.style.fontFamily = "Verdana";
                    select.style.fontSize = "13px";
                    //select.style.verticalAlign = "center";
    
                    select.setAttribute("class", "text-md-center form-control selectBorderLess");
                   
                    if (item.constructionReportCloseoutLinesKey === 2){
                        console.log('item.constructionReportCloseoutLinesKey: ' + item.constructionReportCloseoutLinesKey)
                    }
                    
                     
                    if (item.isDisabled) {
                       //select.style.background = "#F3B5A2";
                    //    select.style.background = "#f0f0f0";
                    //    select.style.color = "#a0a0a0"; 
                       select.setAttribute("class", "selectBorderLess"); 
                       select.disabled = true;
                    }
                    else{
                        select.style.background = "";
                    }
    
    
                    if (item.lineNo !== 2){ 
                        content.appendChild(select);
                    }
                    else{
                        //content.style.background = "#F3B5A2"; 
                        // content.style.background = "red";
                        // content.style.color = "white"
    
                    }
                }
                
            } 
            else {

                link = document.createElement("label");
                let cell = document.createElement("div");
 
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center"; 
                link.style.padding = "8px 0px 0px 0px";
                 

                if (item.isDisabled) {  
                    content.style.background = "#f0f0f0";
                    content.style.color = "#a0a0a0";
                    
                    if (s.field == 'lineDescription' ){ 
                        content.style.color = "white" 
                    }
                      
                }
                else{ 
                    content.style.background = ""  
                    s.isEditable = true;
                    cell.contentEditable = true;
                    cell.disabled = false; 
                }
 
                content.appendChild(link); 
            }
        } else {
            link = content.children[0]

            // if (item.miscValue1 === "No" && s.field === "miscValue1") {
            //     link.style.background = "#F3B5A2";
            // }
          
            

            if (item.isDisabled) {
                 
                //link.disabled = true; 
                link.style.background = "#f0f0f0";
                link.style.color = "#a0a0a0";
                
                if (s.field == 'lineDescription' ){
                    //link.style.background = "#F3B5A2";
                    //link.style.background = "blue"; 
                    link.style.color = "#495057";//"black"
                    link.style.background = "";
                }
                else if (s.field == 'comments' && item.lineNo === 2){
                    //link.style.background = "#F3B5A2";
                    // link.style
                    // link.style.color = "white"
                    //link.style.color = "red"
                    //link.style.ColoredLine = 'red'; 
                    link.style.padding = '100px';
                    //link.style.background = "blue";
                    content.style.border = '2px solid red';
                } 

                // let cell = document.createElement("div");
                // if (s.field == 'comments'){
                    
                //     cell.style.border = '2px solid red'; // Change color and width as needed
                //     cell.style.padding = '5px'; 
                //     cell.textContent = item.comments;
                // }
                

                //s.isEditable = false;
                //link.isEditable = false;
                //content.disabled = false;
                //content.appendChild(cell);
            }
             else{ 
                link.style.background = "";
                link.style.color = "#495057" 
                //link.disabled = false; 
                //s.isEditable = true;
                //link.isEditable = true;
                //content.disabled = false;
                content.style.border =""
            }

        }


        if (link !== undefined) {
            switch (s.field) {
                case 'lineDescription':
                    link.textContent = item.lineDescription;
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");

                    break;
                case 'miscValue1':
                    link.value = item.lineNo ===  2 ? null :  item.miscValue1;
                    //link.disabled = false;
                    break;
                case 'comments':
                    link.textContent = item.comments;
                    link.textContent = content.innerText;
                    //cell.textContent = item.comments;
                   // link.setAttribute("class", "text-md-right form-control selectBorderLess");
                   //content.style.background = 'linear-gradient(to right, #28A745 ' + 50 + '%, white ' + 50 + '%)';
                   
                    break;
                default:
            }
        }
    }
     
    closeoutChkChange = (key, value) =>{
        const {closeoutChkFilter,constructionReportCloseoutLinesKey} = this.state

        key = constructionReportCloseoutLinesKey;
        closeoutChkFilter.map(k=>{
            if (k.constructionReportCloseoutLinesKey===key){
                k.miscValue1 = value
                return
            }
        })

        this.updateCloseoutLine(key, 'miscValue1', value ,null)
        //this.setState({isDataLoaded: true})
    }

    removeEventListeners() {
    //     this.selectRefs.forEach((selectRef, index) => {
    //       if (selectRef) {
    //         selectRef.removeEventListener('change', this.handleChange.bind(this, index));
    //       }
    //     });
       }
} 