import React, { Component } from 'react';
import Select from 'react-select';
import {
    Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, Card, CardTitle, CardBody,
    ModalBody, ModalFooter, Modal,ModalHeader
} from 'reactstrap';


import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';

export default class AFEApprovalAdd extends Component {
    static displayName = AFEApprovalAdd.name;


    constructor(props) {
        super(props)

        this.state = {
            afeHeaderKey: props.afeHeaderKey,
            empList: props.empList,
            actionLineKey: props.selLineActionKey,
            addPosition: props.addPosition,
            selectedEmpList: [],
            actionKey: 0,
            comment: "",
            msgBody: "",
            msgHeader: "",
            modalMsg: false
        }
    }

    closeForm = (refreshData) => {
        if (refreshData) {
            this.props.refreshData();
        }

        this.props.toggleModalAppAdd()
    }

    saveData = () => {
        const { selectedEmpList } = this.state
        var apprList = []
        selectedEmpList.map(e => {
            var tempList = this.createApprovalLineAdd()
            tempList.EmployeeKey = e.employeeKey

            apprList.push(tempList)
        })

        console.log("apprList")
        console.log(apprList)
        let myURI = apiURL + 'AFE/ApprovalEmail'

        fetch(myURI, apiPOSTHeader(apprList))
            .then(async response => {              
                if (!response.ok) {
                    //console.log("Error return but you dont see")
                    const rspData = response.text();
                    const error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                //console.log("No error here: " + rspData)
                this.closeForm(true)
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    createApprovalLineAdd = () => {
        const { afeHeaderKey, actionKey, comment, actionLineKey, addPosition } = this.state
        const { user } = this.props
        const appData = {
            "AFEHeaderKey": afeHeaderKey,
            "EmployeeKey": 0,
            "ActionKey": actionKey, /*0= no action (use for email notification), 1 = Approval, 2= maybe investigation*/
            "Comment": comment,
            "CreatedByAD": user.account.name,
            "ActionLineKey": actionLineKey,
            "AddPosition": addPosition
        }

        return appData

    }

    onTextChange = (fieldName, fieldValue, e) => {
        console.log("props")
        console.log(fieldValue)
        var selectedEmpList = []

        switch (fieldName) {
            case "empList":
                this.setState({ selectedEmpList: e })
                break;
            case "email":
                this.setState({ actionKey: 0 })
                break;
            case "approval":
                this.setState({ actionKey: 1 })
                break;
            case "comment":
                this.setState({ comment: fieldValue })
            default:
                break;
        }
    }


    render() {
        const { empList, selectedEmpList, 
            actionKey, comment,msgHeader,msgBody } = this.state

        return (<div>
            <Form>
                <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="empList" > Please select user(s) </Label>

                            <Select options={empList}
                                isMulti
                                name='empList'
                                id='empList'
                                defaultValue={selectedEmpList}
                                value={selectedEmpList}
                                onChange={(e) => this.onTextChange("empList", e, e)}
                                isClearable={true}></Select>
                        </FormGroup>
                    </Col>
                </Row>

                <Row form style={{ zIndex: 0, position: 'relative' }}>
                    <Col md={4}>
                        <FormGroup check inline>
                            <CustomInput
                                type="checkbox" name="approval" id="approval"
                                placeholder=""
                                checked={actionKey === 1}
                                onChange={(e) => this.onTextChange("approval", e.target.checked, e)}
                            />
                            <Label check for="approval">Approval </Label>
                        </FormGroup>
                    </Col>

                    <Col md={6}>

                        <FormGroup check inline>
                            <CustomInput
                                type="checkbox" name="email" id="email"
                                placeholder=""
                                checked={actionKey === 0}
                                onChange={(e) => this.onTextChange("email", e.target.checked, e)}
                            />
                            <Label check for="email">Email Notifcation</Label>
                        </FormGroup>

                    </Col>
                </Row>
                <Row form style={{ marginTop: "10px" }}>
                    <Col md={12}>
                        <FormGroup>
                            <Label check for="comment">Comment</Label>

                            <Input
                                type="textarea" name="comment" id="comment"
                                placeholder="" rows={4}
                                value={comment}
                                //checked={invLocDetails[0].physcialLoc !== 0}
                                onChange={(e) => this.onTextChange("comment", e.target.value, e)}
                            />

                        </FormGroup>
                    </Col>


                </Row>

                <Row style={{ margin: "15px 0 0 0" }}>
                    <Col sm={12}>
                        <Button color="warning" className="float-right"
                            onClick={() => this.closeForm(false)}>Close</Button>


                        <Button color="primary" className="float-right"
                            style={{ margin: "0px 20px 0px 0px" }}
                            // hidden={this.setIsReadOnly(3)}
                            onClick={() => this.saveData()}

                        >Save
                        </Button>


                    </Col>


                </Row>

                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>
            </Form>
        </div >)
    }
}