import { DetailsView, NavigationPane, Toolbar, Inject } from '@syncfusion/ej2-react-filemanager';
import { apiSyncFusionAzureFileManagerURL, } from './apiURL';
import { FormGroup, Button,Row,Col } from 'reactstrap';
import { apiURL,apiPOSTHeader } from '../resources/apiURL';

import { FileManagerComponent } from '@syncfusion/ej2-react-filemanager';
import { useState } from 'react';

const onCreated = (args) => {
    // console.log("File Manager has been created successfully");
}


/**Note by HN - 06/26/2023
 * HN modified SyncFusion API to auto create folder if the folder is not already exists
 * This will set user at the root and prevent user from travel to other folders.
 * file containtner default to "files" blob name
 * folderPath = folder within Blob conainters
 * when syncfusion create folder it will add an text file call About.txt this file will 
 * prevent the folder from disappear.
 * "rootFolder" will prevent user from going to another folder 
 * 
 */
const FileManager = (props) => {
    const { blobName, folderPath, controlid, assetkey, linkTableName ,blobContainter} = props

    const [refreshForm, setRefreshForm] = useState(false);

    //let hostUrl = "https://ej2-aspcore-service.azurewebsites.net/";

    let hostUrl = apiSyncFusionAzureFileManagerURL;

    const onSuccess = (args) => {
        
        // console.log(args)
        if (args.action === "Upload"){
            // console.log("On Success File Manager - Upload " + folderPath )
            //// console.log(assetkey)
            // console.log(args.result.file.name,args.result.file.type)

            documentAdd(args.result.file.name,folderPath,
                (linkTableName === undefined || linkTableName === null || linkTableName === '' ? 'tasset' :  linkTableName),
                (blobContainter === undefined || blobContainter === null || blobContainter === '' ? '' :  blobContainter)
                )

        }else if (args.action === "delete"){
            // console.log("On Success File Delete - Upload " + folderPath )
            // console.log(assetkey)
            args.result.files.map(e => {
                // console.log(e.name,e.filterPath,e.type)
                documentDelete(e.name)
            })
        }
    }

    const documentAdd=(fileName,folderPath,linkTableName,blobContainter)=>{

        const hdrAddDTO = {
            "docFileName": fileName,
            "docFileDesc": fileName,
            "docFileData":folderPath,
            // "LinkTableName":"tasset",
            "LinkTableName":linkTableName === undefined || linkTableName === null || linkTableName === '' ? "tasset" : linkTableName,
            "linkPrimaryKey":assetkey,
            "blobContainter": blobContainter
        }

        // console.log(hdrAddDTO)

        let myURI = apiURL + 'Documents'
        fetch(myURI , apiPOSTHeader(hdrAddDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){              
              // console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);              
            }            
            //props.refreshDocList();
            // console.log("No error here: " + rspData)
          })
          .catch(error =>{
            // console.log("POST Error: " + error);
          })

         
         
    }

    const documentDelete=(fileName)=>{

        // console.log("File Delete: " + fileName)

        let myURI = apiURL + 'Documents/Delete/tasset/' + assetkey + '/' + fileName
        fetch(myURI , apiPOSTHeader() )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){              
              // console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);              
            }            
            //props.refreshDocList();
            // console.log("No error here: " + rspData)
          })
          .catch(error =>{
            // console.log("POST Error: " + error);
          })

         
         
    }

    return (
        <div>
            <FormGroup>
                <Row form style={{ marginBottom: "10px"}}>
                    <Col md="12">
                        <Button color="secondary"
                            outline
                            onClick={() => setRefreshForm(!refreshForm)}>
                            Refresh
                        </Button>
                    </Col>
                </Row>

                <FileManagerComponent id={controlid}
                    key={(refreshForm ? 1 : 0)}
                    ajaxSettings={{
                        url: hostUrl + "api/AzureProvider/AzureFileOperations?rootFolder=" + folderPath,
                        uploadUrl: hostUrl + 'api/AzureProvider/AzureUpload?rootFolder=' + folderPath,
                        downloadUrl: hostUrl + 'api/AzureProvider/AzureDownload?rootFolder=' + folderPath + '&',
                        getImageUrl: hostUrl + 'api/AzureProvider/AzureGetImage?rootFolder=' + folderPath

                    }} toolbarSettings={{ items: ['NewFolder', 'Upload', 'Delete', 'Download', 'Rename', 'SortBy', 'Selection', 'View', 'Details'] }}
                    created={onCreated.bind(this)}
                    success={onSuccess.bind(this)}>

                    <Inject services={[NavigationPane, DetailsView, Toolbar]} />
                </FileManagerComponent>
            </FormGroup>
        </div>
    );

}


export default FileManager