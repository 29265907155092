import React, { Component, useEffect, useState } from 'react';
import { apiURL } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashIcon from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import CopyIcon from '../resources/copyicon.png'
import TransferIcon from '../resources/transfer.png'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import {apiDELETEHeader, apiPOSTHeader, apiHeader} from '../resources/apiURL';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import ConfirmModal from '../operation/ConfirmModal'
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ItemDetail from './ItemDetail'
import MaterialMove from './MaterialMove'
import BulkMove from './BulkMove'
import moment from "moment";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");

const NewBulkMove = (props) =>{
    const {} = props

    const [basinList, setBasinList] = useState([])
    const [isBasinLoaded, setIsBasinLoaded] = useState(false)
    const [WHList, setWHList] = useState([])
    const [isWHLoaded, setIsWHLoaded] = useState(false)

    const [selFromBasin, setSelFromBasin] = useState(-1)
    const [selFromWarehouse, setSelFromWarehouse] = useState(-1)

    const onTextChange = (fieldName, fieldValue, e) =>{
        console.log(fieldName + '=' + fieldValue )
        switch (fieldName) {

            case "fromInvLocationKey":
                if(e !== null){
                    setSelFromBasin(e.value)
                }
                else{
                    setSelFromBasin(null)
                }

        

            // break;


            // default:
        }
    }

   const getBasin = () =>{
                    
        let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                
                    setBasinList(createBasinList(json))
                    setIsBasinLoaded(true)
              
            });
    }

    
  const getWHList = () => {
        let myURI = apiURL + 'SupplyChain/WH'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

              
                    setIsWHLoaded(true) 
                    setWHList(createWHList(json))
             
            });
    }

    const createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }

    const createWHList = (value) => {
        var tempList = []
        value.map(item => {               
            item.value=item.warehouseKey
            item.label=item.locationWarehouse
            tempList.push(item)         
        })
        // console.log("tempList")
        // console.log(tempList)
        return tempList;
    }

    const saveData = () => {
        console.log(selFromBasin)
        props.onClickYes(selFromBasin)
    }



    useEffect(()=>{

      getBasin()
      getWHList()
    },[props.itemMasterKey])




    if(!isBasinLoaded,!isWHLoaded){
        return(<div>
            <h4> Loading Item Data ....</h4>
        </div>)
    }else
    return(<div>

          <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                From Basin
            </Label>
            <Col sm={9}>
                <Select 
                    className="alway-on-top-more"
                    options={basinList}
                    // value={itemDataList.filter(a=> a.itemMasterKey === itemMasterData.itemMasterKey).length > 1 ? this.getSelFromInvLoc(moveData.fromInvLocationKey) :  this.getSelFromInvLoc2(itemMasterData.itemMasterKey)}
          
                    // onChange={(e) => this.onTextChange("fromInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                   From Warehouse
            </Label>
            <Col sm={9}>
                <Select 
                    className="alway-on-top"
                    options={WHList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    // onChange={(e) => this.onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                   To Basin
            </Label>
            <Col sm={9} className="alway-in-between-more">
                <Select 
                    
                    options={basinList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    // onChange={(e) => this.onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                   To Warehouse
            </Label>
            <Col sm={9}>
                <Select 
                className="alway-in-between"
                    options={WHList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    // onChange={(e) => this.onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        <div className="float-right">
            <Button color="secondary" className="float-right"
                onClick={() => props.toggle()}>Cancel</Button>
            <Button color="primary" className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}
                onClick={() => saveData()}
        
                >OK</Button>
        </div>

    </div>)
}

export default class ItemMaster extends Component {
    static displayName = ItemMaster.name;

    // static savedLayout =  string; 
    static grid = IgrDataGrid

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        // this.onLoadLayoutClicked = this.onLoadLayoutClicked.bind(this);
        // this.onSaveLayoutClicked = this.onSaveLayoutClicked.bind(this);

        this.state = {
            modalBulkSelector:false,
            mtlRequestKey:null,
            itemIDKey:-1,
            // itemMasterKey: null,
            modalBulkMove:false,
            bulkMoveBool:false,
            modalMove:false,
            moveBool:false,
            duplicateBool: false,
            isItemDetailLoaded: false,
            itemDetail:[],
            itemMasterData: [],
            msgBody: "",
            msgHeader: "",
            modalMsg: false,
            isItemLoaded: false,
            itemData: [],
            windowHeight: window.innerHeight,
            itemMasterKey: -1,
            itemID: "",
            itemStatus: "",
            modal: false,
        }
    }

    //  onLoadLayoutClicked =()=>{
    //     this.grid.loadLayout(this.savedLayout)
    // }

    // onSaveLayoutClicked =()=>{
    //     this.grid.saveLayout()
    //    this.savedLayout = this.grid.saveLayout()
    // }

    


    toggle = () => {
        // console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })

    }
    
    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.itemData;

    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            //console.log(dataItem)

            this.setState({
                itemMasterKey: dataItem.itemMasterKey,
                itemID: dataItem.itemNumber,
                itemStatus: dataItem.itemStatus,
                itemMasterData: dataItem,
                itemIDKey: dataItem.itemID
                // modal: !this.state.modal
            })
            console.log(dataItem)
            console.log(dataItem.itemMasterKey)
            //this.toggle()
            //this.onVolRefresh()    
        }
    }
    getItemMaster = () => {
        let myURI = apiURL + 'ItemMaster/byCompany/WBR'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    itemData: json
                })
            });


    }

    newItem = () => {
        this.setState({
            itemMasterKey: -1,
            itemID: "XXXXXX",
            itemStatus: "New"
        })
        this.toggle()
    }

    
    deleteData = () => {
        
        const dataForDeleteDTO = this.createDeleteData()

        let myURI = apiURL + 'ItemMaster'

        fetch(myURI, apiDELETEHeader(dataForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                } else {
                }
            
                this.setState({itemMasterKey: -1})
                this.getItemMaster()
            })
            .catch(error => {
          
                // this.getReceiverLine()
                this.getItemMaster()

            })

    }

    createDeleteData = () => {
        const { itemMasterData } = this.state
        const { user } = this.props

        const lineDataForDeleteDTO = {
            "ItemMasterKey": itemMasterData.itemMasterKey,
            "DeletedBy": getUserADAccount(user.account.userName),
        }

        return lineDataForDeleteDTO;
    }

    // duplicateItem = () => {
    //     const createItemDTO = this.createItemMasterDuplicateDTO()

    //     console.log("createItemDTO")
    //     console.log(createItemDTO)
    //     let myURI = apiURL + 'ItemMaster'
    //     fetch(myURI, apiPOSTHeader(createItemDTO))
    //         .then(async response => {
    //             if (!response.ok) {
    //                 const rspData = response.text();
    //                 var error = (rspData && rspData.message) || response.status;

    //                 rspData.then(t => {
    //                     this.setState({
    //                         msgBody: t,
    //                         msgHeader: "Error",
    //                         modalMsg: !this.state.modalMsg
    //                     })
    //                 })
    //                 this.getItemMaster()
    //                 // this.closeForm(true)
    //                 return Promise.reject(error)
    //             } else
    //             // this.closeForm(true)
    //             this.getItemMaster()
    //                 return response.json()
                    
    //         }).catch(error => {
    //             this.getItemMaster()
    //             console.log("PUT error: " + error)
    //         })

    // }

    // createItemMasterDuplicateDTO = () => {
    //     const { itemDetail, itemMasterData } = this.state
    //     const { user } = this.props
    //     const itemUpdateDTO = {
    //         "companyID": itemMasterData.companyID,
    //         "itemNumber": "XXXXXX",
    //         "itemStatus": itemMasterData.itemStatus,
    //         "itemTypeKey": itemMasterData.itemTypeKey,
    //         "categoryKey": itemMasterData.categoryKey,
    //         "inventoryCode": itemMasterData.inventoryCode,
    //         "itemDesc": itemMasterData.itemDesc,
    //         "stdPurchCost": itemMasterData.stdPurchCost,
    //         "qtyOnHand": itemMasterData.qtyOnHand,
    //         "purchUOMKey": itemMasterData.purchUOMKey,
    //         "stockUOMKey": itemMasterData.stockUOMKey,
    //         "barcode": itemMasterData.barcode,
    //         "supplierKey": itemMasterData.supplierKey,
    //         "createdBy": getUserADAccount(user.account.userName),
    //         "itemPropertyKey": itemMasterData.itemPropertyKey,
    //         "conditionCode": itemMasterData.conditionCode,
    //         "spendCategory": itemMasterData.spendCategory,
    //         "reorderQty": itemMasterData.reorderQty,
    //         "minQty": itemMasterData.minQty
    //     }
    //     return itemUpdateDTO;
    // }

    // getItemDetail = () => {
    //     const { itemMasterKey } = this.state

    //     if (itemMasterKey > 0) {
    //         let myURI = apiURL + 'ItemMaster/byKey/' + itemMasterKey

    //         fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
    //             .then(res => res.json())
    //             .then(json => {

    //                 this.setState({
    //                     isItemDetailLoaded: true,
    //                     itemDetail: json
    //                 })
    //             });
    //     } 
    //     else{
    //         console.log("")
    //     }
    //     // else {
    //     //     const itemDetail = this.createItemMaster()
    //     //     this.setState({
    //     //         isItemDetailLoaded: true,
    //     //         itemDetail: itemDetail
    //     //     })
    //     // }
    // }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getItemMaster()
        // this.getItemDetail()
    }
    duplicateToggle=()=>{
        this.setState({ 
            duplicateBool: true,
            modal: !this.state.modal,
         
        })
    }
    moveToggle=()=>{
        this.setState({ 
            moveBool: true,
            modalMove: !this.state.modalMove,
         
        })
    }


    createNewReceiver = (basin, afe2, site, WoNo, selSite,  scHeaderBool2) => {
        const newMtlReqDTO = this.createMtlReqDTO(basin, afe2, site, WoNo, selSite,  scHeaderBool2)
        const {mtlRequestKey} = this.state
        let myURI = apiURL + 'SupplyChain/MaterialRequest'
        fetch(myURI, apiPOSTHeader(newMtlReqDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    
                    var error = (rspData && rspData.message) || response.status;
    
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    });
                    return Promise.reject(error);
                } else

                    return response.json();

            })
            .then(json => {
                           
                if (json[0].mtlRequestKey > 0) {
                    this.setState({ 
                                    mtlRequestKey: json[0].mtlRequestKey,
                                    // selBasinKey: basin, 
                                    // selAFEKey: afe2,
                                    // createdBy: json[0].createdBy
                                
                                },()=>{console.log(json[0].mtlRequestKey)})
                    // this.toggleMatReqDetails()
                }

            })
            .catch(error => {

            })
    }

    createMtlReqDTO = (basin, afe2, site, WoNo, selSite,  scHeaderBool2) => {
        const { user } = this.props
        const rcvrCreateDTO = {
            "MtlRequestNo": "XXXXXX",
            "RequestTypeKey": 3,
            "StatusKey": 0,
            "FacilityKey": null,
            "ProjectID": null,
            "WONumber": "",
            "FromLocationKey": null,
            "ToLocationKey": null,
            "RequestedBy": "",
            "RequestDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            "CreatedBy": getUserADAccount(user.account.userName),
            "LocationKey": null,      
            "SiteID": null,
            "AFENo": ""
        
          
        }

        console.log(rcvrCreateDTO)
        return rcvrCreateDTO;

    }

    newMtlReq=(basin)=>{
        this.createNewReceiver(basin)
        // this.bulkMoveToggle()
        this.toggleBulkSelector()
    }

    bulkMoveToggle=()=>{

        
        this.setState({ 
            bulkMoveBool: true,
            modalBulkMove: !this.state.modalBulkMove,
         
        })
    }

    setStateOfParent = (newTitle) =>{

        this.setState({duplicateBool: newTitle}, ()=>{

        })
        
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleBulkSelector=()=>{
        this.setState({modalBulkSelector: !this.state.modalBulkSelector})
    }

    newSelector = () => {
        this.toggleBulkSelector()

    }
    render() {
        var { isLoaded, itemData, windowHeight, itemID} = this.state;
        const gridHeight = (windowHeight * 0.8) + "px"
        const searchText = window.$searchText.toLowerCase()
        const {msgBody, msgHeader, itemMasterData, isItemDetailLoaded, 
            duplicateBool, itemMasterKey , itemStatus, itemIDKey, mtlRequestKey } = this.state
        if (!isLoaded) {
            return (<div>
                <h3>Loading item Master.....</h3>
            </div>)
        } else
            return (<div>
                <FormGroup>
                    <Row form>
                        <h2> Item Master |</h2>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.getItemMaster}>
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>
                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.newItem}>
                            <img
                                src={Plus}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            New Item
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            disabled={itemMasterKey < 0}
                            onClick={this.toggle}>
                            <img
                                src={MenuIcon}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Edit
                        </Button>{' '}

                        
                        <Button outline color="secondary"
                            className="btn-no-border"
                            disabled={itemMasterKey < 0}
                            onClick={this.duplicateToggle}>
                            <img
                                src={CopyIcon}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Duplicate
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            disabled={itemMasterKey < 0 || itemMasterData.qtyOnHand <= 0}
                            onClick={this.moveToggle}>
                            <img
                                src={TransferIcon}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Move
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            // disabled={itemMasterKey < 0}

                            // onClick={this.newMtlReq}
                            onClick={this.newSelector}
                            >
                            <img
                                src={TransferIcon}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                           Bulk Move
                        </Button>{' '}
                <Col>
                        <ConfirmModal 
                        // outline={true}
                        
                                    color="danger"
                                    // className="btn-no-border:focus"
                                    className="float-right"
                                    // className="btn-no-border"
                                    disabled={itemMasterKey < 0}
                                    buttonLabel="Delete"
                                    // hidden={isFormReadOnly}                         
                                    formName="Delete"
                                    id={itemMasterKey}
                                    message="Are you sure you want to delete selected item?"
                                    onClickYes={(e) => this.deleteData()}
                                    image={<img
                                        
                                        src={TrashIcon}
                                        id={itemMasterKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>
                                </Col>

                    </Row>
              
                     
                           
                                      
                    
                    <ColoredLine color="grey" />
                    
                </FormGroup>

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef !== null}
                        height={gridHeight}
                        //dataSource={safetyObs}
                        dataSource={itemData.filter(rec => ((rec.itemID || '').toLowerCase().includes(searchText)) ||
                            (rec.itemNumber || '').toLowerCase().includes(searchText) ||
                            (rec.itemStatus || '').toLowerCase().includes(searchText) ||
                            (rec.itemDesc || '').toLowerCase().includes(searchText) ||
                            (rec.itemTypeDesc || '').toLowerCase().includes(searchText) ||
                            (rec.inventoryCode || '').toLowerCase().includes(searchText) ||
                            (rec.createdByName || '').toLowerCase().includes(searchText) ||
                            (rec.spendCategory || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editMode={0}
                        // isGroupByAreaVisible="true"
                        // isGroupCollapsable="true"
                        selectedKeysChanged={this.onGridRowSelected}
                        filterUIType="FilterRow"
                        isColumnOptionsEnabled="true"
                        
                    //cellBackground={"#ffbfbf"}
                    >

                        <IgrTextColumn field="itemNumber" headerText="Item Number"
                            width="*>180" />

                        {/* <IgrTextColumn field="itemID" 
                            headerText="Item ID" width="*>120"

                        /> */}
                        <IgrTextColumn field="itemStatus" headerText="Status"
                            width="*>120"
                        />
                        <IgrTextColumn field="itemDesc" headerText="Item Description"
                            width="*>250" />

                        <IgrNumericColumn field="qtyOnHand"
                            
                            headerText="On Hand Qty"
                            showGroupingSeparator="true"
                            width="*>180"
                            minFractionDigits={2}
                            />
                            


                        <IgrNumericColumn field="allocatedQty"
                            headerText="Allocated Qty"
                            showGroupingSeparator="true"
                            minFractionDigits={2}
                            width="*>180" />    

                         <IgrNumericColumn field="reorderQty"
                            headerText="Reorder Qty"
                            showGroupingSeparator="true"
                            minFractionDigits={2}
                            width="*>180" />     
                            
{/*                             
                        <IgrNumericColumn field="stdPurchCost"
                            headerText="Purchased Cost"
                            positivePrefix="$"
                            showGroupingSeparator="true"
                            minFractionDigits={2}
                            width="*>180" /> */}
                        <IgrTextColumn field="spendCategory" headerText="Spend Category"
                            width="*>250" />

                        <IgrTextColumn field="itemCategoryDesc" headerText="Item Category"
                            width="*>180" />

                        {/* <IgrTextColumn field="conditionCode" headerText="Condition Code"
                            width="*>150" /> */}

                        <IgrTextColumn field="stockUOMDesc" headerText="Stock UOM"
                            width="*>150" />

                        <IgrTextColumn field="purchUOMDesc" headerText="Purchase UOM"
                            width="*>180" />

                        <IgrTextColumn field="createdByName" headerText="Created By"
                            width="*>150" />
                        <IgrTemplateColumn field="createdDatetime" headerText="Created Date"
                            width="*>150" 
                            // dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />


                        <IgrNumericColumn field="itemMasterKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>


                <div>
                    <Modal isOpen={this.state.modal}
                        toggle={() => this.toggle()}
                        onClosed={()=> this.setState({duplicateBool:false})}
                        size="xl"
                        centered>
                        <ModalHeader toggle={() => this.toggle()}>{duplicateBool == true ? "Item Details: " + "XXXXXX" + " - " + "Duplicate" : itemMasterKey < 0 ? "New Item" : "Item Details: " + itemID + " - " + itemStatus} </ModalHeader>
                        <ModalBody>
                            <ItemDetail
                                itemMasterKey={itemMasterKey}
                                user={this.props.user}
                                toggle={this.toggle}
                                refreshData={this.getItemMaster}
                                duplicateBool = {duplicateBool}
                                setStateOfParent={this.setStateOfParent}
                                setStateOfParent2={this.setStateOfParent2}
                                itemStatus={itemStatus}
                                itemIDKey= {itemIDKey}
                                >

                            </ItemDetail>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalMove}
                        toggle={() => this.moveToggle()}
                        onClosed={()=> this.setState({moveToggle:false})}
                        size="lg"
                        centered>
                        <ModalHeader toggle={() => this.moveToggle()}>{"Move"} </ModalHeader>
                        <ModalBody>
                            <MaterialMove
                              itemMasterKey = {itemMasterKey}
                              itemMasterData = {itemMasterData}
                              toggle={this.moveToggle}
                              user={this.props.user}
                        
                                >

                            </MaterialMove>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalBulkSelector}
                        toggle={() => this.toggleBulkSelector()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleBulkSelector()}>New Bulk Move
                        </ModalHeader>
                        <ModalBody>
                            <NewBulkMove 
                                toggle={this.toggleBulkSelector}
                            //    setStateOfParent={this.setStateOfParent}
                              itemMasterKey= {itemMasterKey}
                                // basinList={basinList}
                                // afeList={afeList}         
                                // siteList={siteList}   
                                user={this.props.user}
                                onClickYes={this.newMtlReq}
                                >
                            </NewBulkMove>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalBulkMove}
                        toggle={() => this.bulkMoveToggle()}
                        onClosed={()=> this.setState({bulkMoveToggle:false})}
                        size="xl"
                        centered>
                        <ModalHeader toggle={() => this.bulkMoveToggle()}>{"Bulk Move"} </ModalHeader>
                        <ModalBody>
                            <BulkMove
                              itemMasterKey = {itemMasterKey}
                              itemMasterData = {itemMasterData}
                              mtlRequestKey = {mtlRequestKey}
                              toggle={this.bulkMoveToggle}
                              user={this.props.user}
                           
                                >

                            </BulkMove>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader} 
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>

            </div>)
    }

    setStateOfParent2=(newTitle)=>{
        this.setState({duplicateBool: newTitle})
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "ItemMaster");
    }
    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");
            
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";          
            
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
        
            content.appendChild(link);
        
        }
        else {
            link = content.children[0];
       
           
            if (item.statusKey==4){
                content.style.background = "#61a066";
            }
            
            else if (item.statusKey==5){
                content.style.background = "#ad5f55";
            }
            else if (item.statusKey==2){
                content.style.background = "#05e1fa";
            }
            else {
                content.style.background = "transparent";
            }
        
        }
       

        switch(s.field){
            case 'createdDatetime':
                var date = new Date(item.createdDatetime);                
                    link.textContent = date.toLocaleDateString();
                break;
         

            default:

        }        
        
    }
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        // link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
        // link.textContent = moment(item.createdDatetime).format('MM-DD-YYYY')         
        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
    }


}
