
import React, {Component} from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import MenuIcon from '../resources/menu.png';

import SWIMSSummary from './SubForms/SWIMSSummary'

export default class SWIMSDashboard extends Component {
    constructor(props){
        super(props)

    }

    render(){
        return(<div>
            <FormGroup>
                <Row form>
                    <h3> S.W.I.M.S Dashboard</h3>
                    <Button outline
                        style={{margin: "5px 0 20px 20px", padding:"0" }}
                        color="secondary"
                        className="btn-no-border"
                        //onClick={this.getOpDashboard}
                        >
                        <img src={MenuIcon}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                            Refresh
                    </Button>
                </Row>

                <ColoredLine color="grey"/>
            </FormGroup>
          <SWIMSSummary user={this.props.user}/>

        </div>)
    }
}