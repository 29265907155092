
import React, { Component, useState, useEffect } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, Breadcrumb, CustomInput } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert, InputGroupAddon } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPUTHeader, apiDELETEHeader, apiHeader, apiPOSTHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import MenuIcon from '../resources/menu.png'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ContainerState, ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
// import { copySync } from 'fs-extra';
import moment from "moment";
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop'
import ReactToPrint from 'react-to-print';
import { getByTestId } from '@testing-library/react';
import ConfirmMeterValModal from '../operation/ConfirmMeterValModal';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

const MaterialRequestLineCRUD = (props)=>{
    const {lineData, mtlReqLine, mtlRequestLineKey, mtlRequestKey, user, 
        selBasinKey, locationKey, spendCategoryID, itemMasterKey, scFilterOnLoad, 
        isSecurityAdminLogin, createdBy, materialRequestData, reviseBool} = props

    const [itemData, setItemData] = useState([])
    const [itemDataMain, setItemDataMain] = useState([])
    const [itemDefault, setItemDataDefault] = useState([])
    const [unitCost, setUnitCost] = useState([])
    const [unitCostStandard, setUnitCostStandard] = useState(null)
    const [unitCostBool, setUnitCostBool] = useState(false)

    const [rtnCostMain, setRtnCostMain] = useState(null)

    const [isItemDataLoaded, setIsItemDataLoaded]= useState(false)
    const [scList, setSCList] = useState([])
    const [scListMain, setSCListMain] = useState([])
    const [scListDefault, setSCListDefault]= useState([])
    const [scLoaded,setSCLoaded] = useState(false)
    const [itemUOMList, setItemUOMList] = useState([])
    const [itemUOMListLoaded, setItemUOMListLoaded] = useState(false)

    const[itemNoVal, setItemNoVal]= useState(true)
    const[itemMasterKeyVal, setItemMasterKeyVal] = useState(true)
    const[spendCategoryIDVal, setSpendCategoryIDVal] = useState(true)
    const[requestQtyVal, setRequestQtyVal] = useState(true)
    const[requestUOMVal, setRequestUOMVal] = useState(true)
    const[issueQtyVal, setIssueQtyVal] = useState(true)
    const[issueUOMVal, setIssueUOMVal] = useState(true)
    const[unitCostVal, setUnitCostVal] = useState(true)
    const[toInvLocKeyVal, setToInvLocKeyVal] = useState(true)
    const [msgBody, setMsgBody] = useState("")
    const [msgHeader, setMsgHeader] = useState("")
    const [modalMsg, setModalMsg] = useState(false)


    
    const [invLocList,setInvLocList] = useState([])
    const [isInvLocLoaded, setIsInvLocLoaded] = useState(false)

    const getItemMaster = ()=>{
        let myURI = apiURL + 'ItemMaster/ItemBasinDetails'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            setIsItemDataLoaded(true)

                setItemData(createItemDataList(selBasinKey !== -1 ? json.filter(a=> a.locationKey === selBasinKey) : json.filter(a=> a.locationKey === locationKey)))

                // setUnitCostStandard(lineData.itemMasterKey !== undefined && lineData.fromInvLocKey !== undefined ? (json.filter(e => e.locationKey=== lineData.fromInvLocKey && e.itemMasterKey === lineData.itemMasterKey))[0].stdPurchCost : null)

                setRtnCostMain(json)
                // itemData.filter(e => e.locationKey=== lineData.fromInvLocKey && e.itemMasterKey === lineData.itemMasterKey )
            });

    }

    // const getItemMaster = ()=>{
    //     let myURI = apiURL + 'ItemMaster/ItemBasinDetails'


    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
               
    //     //   setItemDataArray(createSCList2(json))
                
    //             setItemData(createItemDataList(selBasinKey !== -1 ? json.filter(a=> a.locationKey === selBasinKey) : json.filter(a=> a.locationKey === locationKey)))
    //             // setItemDataMain(createItemDataList(json.filter(a=> a.locationKey === selBasinKey)))
    //             // setItemDataDefault(createItemDataList(json.filter(a=> a.locationKey === selBasinKey)))
    //             setIsItemDataLoaded(true)
    //             // console.log(json)
    //         //    console.log(json.filter(a=> a.spendCategory.includes(lineData.spendCategoryID)))
    //         });
            
         


    // }

   

    const createInvLocList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.invLocationKey
            e.label = e.warehouseInvLocation
            tempList.push(e)
        })

        return tempList
    }

    const getInventoryLoc = () => {
        // const { selBasinKey } = this.state

        let myURI = apiURL + 'SupplyChain/InvLoc'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                
                
                    setInvLocList(createInvLocList(json.filter(e => e.locationKey === selBasinKey)))
                    setIsInvLocLoaded(true) 
                   
                
            });

    }

    const getSC = () => {
        // console.log(test())
        let myURI = apiURL + 'ItemMaster/SC'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log( scFilterOnLoad)
                // console.log(json)
                // console.log(spendCategoryID)testing2
            //    console.log(test())

                console.log(scFilterOnLoad)
                setSCList(createSCList(mtlRequestLineKey > 0 ? json.filter(a=> scFilterOnLoad.includes(a.spendCategoryID)) : json))
                // setSCList(createSCList(json))
                setSCListMain(createSCList(json))
                setSCListDefault(createSCList(json))
                setSCLoaded(true)
            });
 
    }

    
    const getItemUOM = () => {
        // const { selBasinKey } = this.state

        let myURI = apiURL + 'ItemMaster/ItemUOM'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                    setItemUOMList(createItemUOMList(json))
                    setItemUOMListLoaded(true)
            });
     
    }
 

    const getSelItemNumber = (value) => {
        // console.log(value.itemNumberWHSLoc)
        return value.itemNumberWHSLoc
    }

    const  getSelInvLocKey = (value) => {
        // console.log("fromInvLocKey: "+ value.locationKey)
        return value.locationKey
    }

    const  getSelAFE = (value) => {
        // console.log(value.invLocAFE)
        return value.invLocAFE
    }
    const getSelItemMasterKey= (value) => {
        // console.log(value.itemMasterKey)
        return value.itemMasterKey
    }

    const getSelSCKey = (value) =>{
        return value.spendCategoryID
    }
    
    const getSelUOMKey = (value) => {
        return value.uomKey
    }
    const getSelToLocKey = (value) => {
        return value.invLocationKey
    }



    // function createItemDataList(value) {
    //     var tempList = []
    //     value.map(e => {
    //         e.value = e.onHandByLocKey
    //         e.label = e.itemNumberWHSLoc
    //         tempList.push(e)
    //     })
        
    //     return tempList
    // }

    function createItemDataList(value) {
        var tempList = []
        value.map(e => {
            e.value = e.itemMasterKey
            e.label = e.longItemNumber
            tempList.push(e)
        })
        
        return tempList
    }


    const createSCList = (value) =>{
        var tempList=[]
        value.map(e=> {
            e.value = e.spendCategoryID

            e.label = e.spendCategoryDesc
        
            tempList.push(e)
        })

        return tempList

    }

    const createItemUOMList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.uomKey
            e.label = e.uomDesc
            tempList.push(e)
        })
    
        return tempList
    }
    const getSelInvLoc = (value) => {
        // const { invLocation } = this.state
        if (value !== null && value !== "") {
            return invLocList.filter(e => e.invLocationKey === value)
            
        }
        return null
    }


    
    const getSelItemNo= (v, f) => {

        // if (v !== null && v !== "" && f !== null && f !== "") {

        //     return itemData.filter(e => e.locationKey=== v && e.itemMasterKey === f )

        // }
        // return null
        return itemData.filter(e => e.locationKey=== lineData.fromInvLocKey && e.itemMasterKey === lineData.itemMasterKey )
    }
     
    // const getSelUnitCost= (v, f) => {

    //     if (v !== null && v !== "" && f !== null && f !== "") {
    //         console.log("fromInvLocKey: " + v)
    //         console.log("itemMasterKey: " + f)
    //         const tester =  itemData.filter(e => e.locationKey=== v && e.itemMasterKey === f )
    //          console.log("xxx" + tester)
    //        return itemData.filter(e => e.locationKey=== v && e.itemMasterKey === f )
                
    //     //    console.log(test)
    
    //     // console.log(test[0].itemMasterKey)
    //         // return test[0].stdPurchCost
        
           
    //     }
    //     return null
    // }
    const getSelUnitCost= (v, f) => {
        var test
        if(lineData.itemMasterKey !== null || lineData.locationKey !== null){
            const tester =  itemData.filter(e => e.locationKey=== lineData.fromInvLocKey && e.itemMasterKey === lineData.itemMasterKey )
       
            // test = itemData.filter(e => e.itemMasterKey === lineData.itemMasterKey )
            //    console.log(test[0].stdPurchCost)
            //   return   test[0].stdPurchCost
        }
        
        
                
       
    }

    
    const getSelSC = (value) => {

        if (value !== null && value !== "") {
            // console.log(scList.filter(e => e.spendCategoryID === value))
           return scList.filter(e => e.spendCategoryID === value)
           
       }
       return null
   }

   const getSelUOM = (value) => {

    if (value !== null && value !== "") {
        return itemUOMList.filter(e => e.uomKey === value)
        
    }
    return null
}

const refreshDataOnSubmit = (refreshData) => {
    // if (bFreshData) this.props.refreshOilTicket();
    if(refreshData){
      props.refreshData()
        // props.calInvtotal()
    }
    props.toggle()

 }

 const refreshData=(refreshData)=>{
    if(refreshData){
        props.refreshData()
    }
 }

const createReceiverLineAndClose = () =>{
    var cont = true;

    if(lineData.itemMasterKey == null){
        setItemMasterKeyVal(false)
        cont = false;
    }
    if(lineData.toInvLocKey == null){
        setToInvLocKeyVal(false)
        cont = false;
    }
    if(lineData.unitCost <= 0.00)
    {
        setUnitCostVal(false)
        cont = false;
    }
    if(lineData.spendCategoryID == null){
        setSpendCategoryIDVal(false)
        cont = false;
    }
    if(lineData.requestQty === null || lineData.requestQty === 0.00 ){
        setRequestQtyVal(false)
        cont = false;
    }
    if(lineData.requestUOMKey == null){
        setRequestUOMVal(false)
        cont = false;
    }
    if (lineData.issueQty === 0.00 && isSecurityAdminLogin){
       console.log("Validation went through")
    }
        else if((lineData.issueQty === null || lineData.issueQty === 0.00)){
            setIssueQtyVal(false)
            cont = false;
        }
    if (lineData.issueUOMKey == null && isSecurityAdminLogin){
        console.log("Validation went through")
        }   
        else if(lineData.issueUOMKey == null){
            setIssueUOMVal(false)
            cont = false;
        }

    if(cont){
        const receiverLineCreateDTO= createReceiverLineCreateDTO()

          
    
              let myURI = apiURL + 'SupplyChain/MaterialRequestLine'
            
              fetch(myURI , apiPOSTHeader(receiverLineCreateDTO) )
                .then(async response => {
                  const rspData = response.text();
                  if (!response.ok){
           
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        setMsgBody(t)
                        setMsgHeader("Error")
                        setModalMsg(!modalMsg)
                    })
                    return Promise.reject(error);
                   
                  }
                
                  props.setStateOfParentValidation(false)
                  refreshDataOnSubmit(true)
               
         
                //   closeForm(true)
                
             
                })
                .catch(error =>{
            
                    // refreshDataOnSubmit(true)

        
                })

    }

            
  
 }

 const createReceiverLine= () =>{
    var cont = true;

    if(lineData.itemMasterKey == null){
        setItemMasterKeyVal(false)
        cont = false;
    }
    if(lineData.toInvLocKey == null){
        setToInvLocKeyVal(false)
        cont = false;
    }
    if(lineData.unitCost <= 0.00){
        setUnitCostVal(false)
        cont = false;
    }
    if(lineData.spendCategoryID == null){
        setSpendCategoryIDVal(false)
        cont = false;
    }
    if(lineData.requestQty === null || lineData.requestQty === 0.00 ){
        setRequestQtyVal(false)
        cont = false;
    }
    if(lineData.requestUOMKey == null){
        setRequestUOMVal(false)
        cont = false;
    }
    // if(lineData.issueQty == null || lineData.issueQty == 0.00){
    //     setIssueQtyVal(false)
    //     cont = false;
    // }
    // if(lineData.issueUOMKey == null){
    //     setIssueUOMVal(false)
    //     cont = false;
    // }

    if (lineData.issueQty === 0.00 && isSecurityAdminLogin){
        console.log("Validation went through")
     }
         else if((lineData.issueQty === null || lineData.issueQty === 0.00)){
             setIssueQtyVal(false)
             cont = false;
         }
     if (lineData.issueUOMKey == null && isSecurityAdminLogin){
         console.log("Validation went through")
         }   
         else if(lineData.issueUOMKey == null){
             setIssueUOMVal(false)
             cont = false;
         }

    if(cont){

    const receiverLineCreateDTO= createReceiverLineCreateDTO()

  

      let myURI = apiURL + 'SupplyChain/MaterialRequestLine'
    
      fetch(myURI , apiPOSTHeader(receiverLineCreateDTO) )
        .then(async response => {
          const rspData = response.text();
          if (!response.ok){
   
            const error = (rspData && rspData.message) || response.status;
            rspData.then(t => {
                setMsgBody(t)
                setMsgHeader("Error")
                setModalMsg(!modalMsg)
            })
            return Promise.reject(error);
           
          }
          props.setStateOfParentValidation(false)
        //   refreshDataOnSubmit(true)
        props.resetLineDataOnAdd(true)
        setUnitCostVal(true)
        //   closeForm(true)
        refreshData(true)
     
        })
        .catch(error =>{
            refreshData(true)
            // refreshDataOnSubmit(true)


        })
    }

}


 const updateLineDetail = () => {
    var cont = true;

    if(lineData.itemMasterKey == null){
        setItemMasterKeyVal(false)
        cont = false;
    }
    if(lineData.toInvLocKey == null){
        setToInvLocKeyVal(false)
        cont = false;
    }
    if(lineData.unitCost <= 0.00){
        setUnitCostVal(false)
        cont = false;
    }
    if(lineData.spendCategoryID == null){
        setSpendCategoryIDVal(false)
        cont = false;
    }
    if (lineData.requestQty === 0.00 && isSecurityAdminLogin === false && reviseBool){
        console.log("Validation went through")
     }
    else if(lineData.requestQty === null || lineData.requestQty === 0.00 ){
        console.log("this hit instead")
        setRequestQtyVal(false)
        cont = false;
    }
    if(lineData.requestUOMKey == null){
        setRequestUOMVal(false)
        cont = false;
    }
    if (lineData.issueQty === 0.00 && isSecurityAdminLogin === false && reviseBool){
       console.log("Validation went through")
    }
        else if((lineData.issueQty === null || lineData.issueQty === 0.00)){
            setIssueQtyVal(false)
            cont = false;
        }
    if (lineData.issueUOMKey === null && isSecurityAdminLogin === false && reviseBool){
        console.log("Validation went through")
        }   
        else if(lineData.issueUOMKey == null){
            setIssueUOMVal(false)
            cont = false;
        }

    if(cont){
   
      
    const createupdateLineDetailDTO = updateLineDetailDTO()

    let myURI = apiURL + 'SupplyChain/MaterialRequestLine'
    fetch(myURI, apiPUTHeader(createupdateLineDetailDTO))
        .then(async response => {
            const rspData = response.text();
            if (!response.ok) {

                const error = (rspData && rspData.message) || response.status;
                rspData.then(t => {
                    setMsgBody(t)
                    setMsgHeader("Error")
                    setModalMsg(!modalMsg)
                })
                return Promise.reject(error);

            }
           
            props.setStateOfParentValidation(false)
            refreshDataOnSubmit(true)
        
            //this.props.refreshData()
        })
        .catch(error => {
         

            // closeForm(true)
        })
    }

}

 const createReceiverLineCreateDTO=()=>{
    // const {grpDetails} = this.state 
  
    const receiverLineCreateDTO = {

       "MtlRequestKey": mtlRequestKey,
       "ItemMasterKey": lineData.itemMasterKey,
       "ItemNumber":  lineData.itemNumber,
       "ItemDescription": lineData.itemNumber,
       "RequestQty": lineData.requestQty,
       "RequestUOMKey": lineData.requestUOMKey,
       "IssueQty": lineData.issueQty,
       "IssueUOMKey": lineData.issueUOMKey,
       "FromInvLocKey": lineData.fromInvLocKey,
       "ToInvLocKey": lineData.toInvLocKey,
       "FromAFE": lineData.fromAFE,
       "ToAFE": "",
       "LineComment": lineData.lineComment,
       "CreatedBy": getUserADAccount(user.account.userName),
       "SpendCategoryID": lineData.spendCategoryID,
       "RtnUnitCost": lineData.unitCost
     }
     console.log(receiverLineCreateDTO + "xxx")
     return receiverLineCreateDTO
}

const  updateLineDetailDTO=()=>{
    // const {grpDetails} = this.state 
    // const {user} = this.props
    const mtlReqLineDTO = []
    mtlReqLine.map(e => {
        // if (e.isUpdated !== 0) {
            const itemDTO = {
                "MtlRequestLineKey": e.mtlRequestLineKey,
                "ItemMasterKey": e.itemMasterKey,
                "ItemNumber": e.itemNumber,
                "ItemDescription": e.itemDescription,
                "RequestQty": e.requestQty,
                "RequestUOMKey": e.requestUOMKey,
                "IssueQty": e.issueQty,
                "IssueUOMKey": e.issueUOMKey,
                "LineComment": e.lineComment,
                "FromInvLocKey": e.fromInvLocKey,
                "ToInvLocKey": e.toInvLocKey,
                "FromAFE": e.fromAFE,
                "ToAFE": "",
                // "FromInvLocKey": e.fromInvLocKey,
                // "ToInvLocKey": e.toInvLocKey,
                // "FromAFE": e.fromAFE,
                // "ToAFE": e.toAfe,
                "SpendCategoryID": e.spendCategoryID,
                "RtnUnitCost": e.unitCost,
                "ModifiedBy": getUserADAccount(user.account.userName)
            }
            
            mtlReqLineDTO.push(itemDTO)
        // }
       

    })
    console.log(mtlReqLineDTO)
    return mtlReqLineDTO
}

const getUnitCost=(e)=>{
    if (e !== null || e !== "")
    {
        // console.log(itemData.filter(a => e == a.itemMasterKey))
      var unitCost =  itemData.filter(a => e === a.itemMasterKey)
      setUnitCost(unitCost[0].stdPurchCost)
      return unitCost[0].stdPurchCost
    }
    else
    setUnitCost(5)
    return ""
}




    const onTextChange = (fieldName, fieldValue, e) => {
       
        console.log(fieldName + "=" +  fieldValue )
     
        switch (fieldName) {

            case "itemNumber": 
            
           
                if (e !== null) {

                    var scListFilter = scListMain.filter(a=> e.spendCategory.includes(a.spendCategoryID))       
                    setSCList(scListFilter)

                

                    console.log(e.value)
                    console.log(e.locationKey)

                    if(rtnCostMain.filter(a => e.value === a.itemMasterKey && e.locationKey === a.locationKey))
                    {
                        var unitCostFilter = rtnCostMain.filter(a => e.value === a.itemMasterKey && e.locationKey === a.locationKey)
                        lineData.unitCost = unitCostFilter[0].stdPurchCost
                    }
                  

                    // lineData.itemNumber = (e === null ? null : getSelItemNumber(e))

                    lineData.itemMasterKey =  (e === null ? null :  getSelItemMasterKey(e))

                    lineData.fromInvLocKey = (e === null ? null : getSelInvLocKey(e)) 

                    lineData.fromAFE = (e === null ? null : getSelAFE(e))
                    // setUnitCostBool(true)
                    // setItemNoVal(fieldValue != null )
                    // setItemMasterKeyVal(fieldValue != null)
                    setItemNoVal(fieldValue != null )
                    setItemMasterKeyVal(fieldValue != null)
                }
               
                else {
                    lineData.longItemNumber = null
                    lineData.itemMasterKey = null
                    lineData.unitCost = 0.00

                    setUnitCostVal(fieldValue !== 0.00)
                    setItemNoVal(fieldValue != null )
                    setItemMasterKeyVal(fieldValue != null)
                    // setSCList("")

                //     setItemNoVal(fieldValue != "")
                //     setItemMasterKeyVal(fieldValue != null)
                //    setSpendCategory("")
                }

                break;

            case "unitCost":
                lineData.unitCost = Number(fieldValue)
                setUnitCostVal(fieldValue !== 0.00)
                // setIssueUOMVal(fieldValue != 0.00)
                // setUnitPriceVal(Number(fieldValue) != 0.00 )
            break;

                    
            case "spendCategoryID": 

            lineData.spendCategoryID = (e === null ? null : getSelSCKey(e))
                setSpendCategoryIDVal(fieldValue != null)
             break;


             
             case "requestQty": 

             lineData.requestQty = Number(fieldValue)
             if(isSecurityAdminLogin){
                 console.log("")
             }
            else if(mtlRequestLineKey < 0){
                lineData.issueQty = Number(fieldValue)
            }
            setRequestQtyVal(fieldValue != null)
            
         //    setReceivedQtyVal( Number(fieldValue) != 0.00  )

             break;

             
            case "requestUOMKey": 

            lineData.requestUOMKey = (e === null ? null : getSelUOMKey(e))
            if(isSecurityAdminLogin){
                console.log("")
            }
            else if(mtlRequestLineKey < 0){
                lineData.issueUOMKey = (e === null ? null : getSelUOMKey(e))
            }

            // setUOMVal(fieldValue != null)
            setRequestUOMVal(fieldValue != null)
            setIssueUOMVal(fieldValue != null)
             break;

             
             case "issueQty": 

             lineData.issueQty = Number(fieldValue)
         //    setReceivedQtyVal( Number(fieldValue) != 0.00  )
            setIssueQtyVal(fieldValue != null)
             break;

             case "issueUOMKey": 

             lineData.issueUOMKey = (e === null ? null : getSelUOMKey(e))
            setIssueUOMVal(fieldValue != null)
             // setUOMVal(fieldValue != null)
 
              break;

              case "toInvLocKey": 


            //   if (e !== null){

               
                    lineData.toInvLocKey = (e === null ? null : getSelToLocKey(e))
                    setToInvLocKeyVal(fieldValue != null)
            //   }
            //   else{
            //       lineData.toInvLocKey = null
            //       setToLocationKeyVal(fieldValue != null)
            //     //   setAFE(afeDefault)
            //   }

          
            // setToLocationKeyVal(fieldValue != null)
           
           break;


              case "lineComment": 
                   
              lineData.lineComment = fieldValue

               break;
           default:


            }

      props.setStateOfParent(lineData)
 

    }
    const closeForm2=(refreshData)=>{

        if(refreshData){
            props.refreshData()
       
          }
           props.toggle()
    }
    const toggleMsg = () => {
        setModalMsg(!modalMsg) 
    }

    useEffect(()=>{

        getItemMaster()
        getSC()
        getItemUOM()
        getInventoryLoc()
        // test()

    },[props.mtlRequestKey])

    if(!isItemDataLoaded, !scLoaded, !itemUOMListLoaded, !isInvLocLoaded){
        return(<div>
            <h4> loading Item Data ....</h4>
        </div>)
    }else
    
    return(<div>
        <Form>
        <Row form>
                <Col md={6}>
                    <Label for="itemNumber">
                        Item Number
                    </Label>
                    <Select 
                    className={!itemMasterKeyVal || !itemNoVal ? "form-error" : ""}

                        // defaultValue={getSelItemNo(lineData.itemMasterKey)}
                        value={getSelItemNo(lineData.fromInvLocKey, lineData.itemMasterKey)}
                        options={itemData}
                        onChange={(e) => onTextChange("itemNumber", e, e)}
                        isClearable={true}>
               
                    </Select>
                </Col>
                <Col md={6}>
                    <Label
                        for="spendCategory"

                    >
                        Spend Category
                    </Label>

                    <Select
                        options={scList}
                        isClearable={true}
                        
                          className={!spendCategoryIDVal ? "form-error" : ""}
                            defaultValue={getSelSC(lineData.spendCategoryID)}            
                            value={getSelSC(lineData.spendCategoryID)}
                            onChange={(e) => onTextChange("spendCategoryID", e, e)}
                            />
                </Col>
            </Row>
        </Form>
        <Form>
            <Row form>  

                <Col md={6}>
                    <Label>
                       Return Qty
                    </Label>
                   
                    <NumberFormat
                        defaultValue = {lineData.requestQty}
                        value={lineData.requestQty}         
                         className={!requestQtyVal ? "form-error form-control text-md-right" : "form-control text-md-right"}
                        fixedDecimalScale={true}
                        decimalScale={2}
                
                        onValueChange={(e) => onTextChange("requestQty", e.value, e)}
                        thousandSeparator={true}
                        
                        // prefix={'$'} 
                        />
                </Col>
                
            <Col md={6}>
                    <Label
                        for="requestUOMKey"

                    >
                        Return UOM
                    </Label>

                    <Select
                        options={itemUOMList}
                        // isDisabled={  (lineData.createdByName !== getUserADAccount(user.account.userName)) && !isSecurityAdminLogin}


                        // isDisabled={mtlRequestLineKey < 0 && !isSecurityAdminLogin ? false : (materialRequestData[0].createdBy !== getUserADAccount(user.account.userName)) && !isSecurityAdminLogin}
                        isClearable={true}
                        className={!requestUOMVal ? "form-error" : ""}
                        placeholder={""}
                        //   className={!spendCategoryIDVal ? "form-error" : ""}
                        // defaultValue={getSelUOM(lineData.requestUOMKey)}
                        value={getSelUOM(lineData.requestUOMKey)}
                      
                        //     // placeholder=""
                            onChange={(e) => onTextChange("requestUOMKey", e, e)}
                            />
                </Col>
                
            </Row>
        </Form>

        <Form>
            <Row form>
                
                <Col md={6}>
                    <Label>
                        Accept Qty
                    </Label>

                    <NumberFormat
                        // defaultValue = {lineData.issueQty}
                        value={lineData.issueQty}
                        disabled={isSecurityAdminLogin}
                        // defaultValue={lineData.requestQty}
                        // // invalid={unitPriceVal}
                        className={!issueQtyVal ? "form-error form-control text-md-right" : "form-control text-md-right"}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        //displayType={'text'} 
                        //disabled={isFormReadOnly}
                        onValueChange={(e) => onTextChange("issueQty", e.value, e)}
                        thousandSeparator={true}
                        
                        // prefix={'$'} 
                        />
                </Col>

                            
            <Col md={6}>
                    <Label
                        for="issueUOMKey"

                    >
                        Accept UOM
                    </Label>

                    <Select
                        options={itemUOMList}
                        isClearable={true}
                        className={!issueUOMVal ? "form-error" : ""}
                        isDisabled={isSecurityAdminLogin}
                        placeholder={""}
                        //   className={!spendCategoryIDVal ? "form-error" : ""}
                        // defaultValue={getSelUOM(lineData.issueUOMKey)}
                        value={getSelUOM(lineData.issueUOMKey)}
                      
                        //     // placeholder=""
                            onChange={(e) => onTextChange("issueUOMKey", e, e)}
                            />
                </Col>

            </Row>
        </Form>

        <Form>
            <Row form>
                
                <Col md={6}>
                    <Label>
                        Return to Inv Location
                    </Label>

                  <Select
                        options={invLocList}
                        isClearable={true}
                        className={!toInvLocKeyVal ? "form-error" : ""}
                        // isDisabled={isSecurityAdminLogin}
                        // placeholder={""}
                        //   className={!spendCategoryIDVal ? "form-error" : ""}
                        // defaultValue={getSelUOM(lineData.issueUOMKey)}
                        // value={getSelUOM(lineData.issueUOMKey)}

                        // defaultValue={getSelInvLoc(lineData.toInvLocKey)}
                        value={getSelInvLoc(lineData.toInvLocKey)} 
                      
                        //     // placeholder=""
                            onChange={(e) => onTextChange("toInvLocKey", e, e)}
                            />
                </Col>

                            
            <Col md={6}>
                    <Label
                        for="issueUOMKey"

                    >
                        Unit Cost
                    </Label>

                    
    

                    <NumberFormat
                        className={!unitCostVal ? "form-error form-control text-md-right" : "form-control text-md-right"}
                        // className={"form-control text-md-right"}
                        value={lineData.unitCost}
                        fixedDecimalScale={true}
                        decimalScale={2}     
                        onValueChange={(e) => onTextChange("unitCost", e.value, e)}
                        thousandSeparator={true}
                        
                        prefix={'$'} 
                        />
                </Col>

            </Row>
        </Form>

        <Form>
            <Row form>
                <Col md={12}>
                    <Label>
                        Comment
                    </Label>

                    <Input type="textarea" name="lineComment"
                        rows={4}
                        value={lineData.lineComment}
                        // defaultValue={lineData.lineComment}
                        //disabled={isFormReadOnly}
                        id="lineComment" 
                        placeholder="Comment"
                       
                        onChange={(e) => onTextChange("lineComment", e.target.value, e)}
                    />
                </Col>

            </Row>
        </Form>

        <Form style={{ marginTop: "5px" }}>
            <Row>
                <Col md={12}>
                    <div className="float-right">
                        <Button color="warning" className="float-right"
                            onClick={() => closeForm2(true)}
                            >Close</Button>
                              <Button color="primary" className="float-right"
                              hidden = {mtlRequestLineKey < 0}
                            style={{ marginRight: "10px" }}
                            onClick={() => updateLineDetail() }
                            >Save</Button>

                    <Button outline={true}
                    hidden ={mtlRequestLineKey > 0}
                    color="primary" className="float-right"
                            style={{ marginRight: "10px" }}
                            onClick={() => createReceiverLineAndClose()}
                            
                            >Add and Close</Button>
                        
                          
                    </div>
                    <Button color="primary" className="float-left"
                            style={{ marginRight: "10px" }}
                            hidden = {mtlRequestLineKey > 0}
                            onClick={() => createReceiverLine()}
                            >Add</Button>
                </Col>
            </Row>
        </Form>

        <Modal isOpen={modalMsg}
                        toggle={() => toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>


    </div>)
}

export default class MaterialReturnDetail extends Component {
    static displayName = MaterialReturnDetail.name;

    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state={
            temp: "",
            isShowingAlert:false,
            createdBy: props.createdBy,
            scFilterOnLoad: null,
            spendCategoryID: -1,
            scHeaderBool:false,
            isSCLoaded:false,
            scList:[],
            isSecurityLoaded:false,
            siteList: [],
            isSiteLoaded:false,
            isMaterialRequestDetailLoaded:false,
            employeeList: [],
            isEmplooyeeLoaded:false,
            activeTab: '1',
            isFormReadOnly:false,
            incompleteBool:false,
            rejectBool:false,
            reviseBool:false,
            cancelBool:false,
            acceptBool:false,
            pendingBool:false,
            completeBool:false,
            alertMessage: "",
            msgHeader:"",
            msgBody: "",
            modalMsg: false,
            showAlert:false,
            modalLineCrud: false,
            lineData:[],
            mtlReqLine:[],
            isMtlReqLineLoaded: false,
            // requestedDate:null,
            afeData:[],
            selAFE: -1,
            afeList:[],
            isAFELoaded:false,
            basinList:[],
            selBasin:-1,
            mtlRequestKey: props.mtlRequestKey,
            mtlStatusKey: props.mtlStatusKey,
            selBasinKey: props.selBasinKey,
            selAFEKey: props.selAFEKey,
            locationKey: props.locationKey,
            // scHeaderBool2: props.scHeaderBool2,
            mtlRequestLineKey: -1,
            materialRequestData: null,
            isLoaded:false,
            isBasinLoaded:false,
            validDataItem:{
                employeeKey: true,
                locationKey: true,
                requestedDate: true,
                projectID: true,
                siteID: true,
                spendCategoryID: true
            },
            invalidDataItem:{
                comments: true
            }
        }

    }

    validateData = ()=>{
        const {invalidDataItem, temp} = this.state
        var bVal = true 
        var valItem = invalidDataItem
        console.log(temp)
        // console.log(typeof temp)
        if (temp == null){
            bVal = false
            valItem.comments= false
        }
        if (temp === ""){
            bVal = false
            valItem.comments= false
        }
        // if (temp == ""){
        //     bVal = false
        //     valItem.comment= false
        // }

        this.setState({invalidDataItem:valItem})
        // console.log(bVal)
        return bVal
    }

    saveData = () => {
        const {mtlRequestKey} = this.state
        if(mtlRequestKey > 0){
            this.saveMtlRequestHdr()
            this.setShowingAlert(true)
        }
        else{
            this.createMtlRequestHdr()
            // this.saveMtlRequestHdr()
            // this.setState({mtlRequestKey: 1})
        }
          
       
    }

    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const returnVal = { "ok": true, "message": "", "headerValidation": [] }
            const { materialRequestData, validDataItem, mtlReqLine } = this.state


            if (materialRequestData[0].locationKey === null) {
                validDataItem.locationKey = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data in red"
            }

            // if (materialRequestData[0].employeeKey === null) {
            //     validDataItem.employeeKey = false
            //     returnVal.ok = false
            //     returnVal.message = "Please enter missing data in orange"
            // }
            // if (materialRequestData[0].requestedBy === null) {
            //     validDataItem.requestedBy = false
            //     returnVal.ok = false
            //     returnVal.message = "Please enter missing data in orange"
            // }

            

            if ((materialRequestData[0].requestedDate || "") === "") {
                validDataItem.requestedDate = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data"
            }


            if ((  (materialRequestData[0].projectID || "") === "") &&  (materialRequestData[0].siteID || "") === "") {
                validDataItem.projectID = false
                validDataItem.siteID = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data"
            }

            if (   (((materialRequestData[0].siteID || "") !== "") && materialRequestData[0].spendCategoryID == null) || ((materialRequestData[0].siteID || "") !== "") && materialRequestData[0].spendCategoryID === "" ) {
                console.log("Is this hitting")
                // validDataItem.projectID = false
                validDataItem.spendCategoryID= false
                returnVal.ok = false
                returnVal.message = "Please enter missing data"
                console.log("Does this work?")
            }
            

  
            returnVal.headerValidation = validDataItem

            var mtlReqLines = mtlReqLine

            if(mtlReqLines.length <= 0){
                returnVal.ok = false
                returnVal.message = "Please add line details"
            }
            //Header Validation

            //Line Validation
            // var rcvrLines = rcvrLine.filter((e) => Number(e.qtyReceived) > 0)
            // if (rcvrLines.length > 0) {
            //     var lineNOs = ""
            //     rcvrLines = rcvrLine.filter((e) => Number(e.qtyReceived) > 0 && (e.itemMasterKey === null || e.itemMasterKey === 'null'))

            //     if (rcvrLines.length > 0) {
            //         rcvrLines.map(a => {
            //             lineNOs = (lineNOs !== "" ? "," : "") + a.rcrvLineNo
            //         })

            //         returnVal.ok = false
            //         returnVal.message = "Missing receive line item number. Please select item for line(s):" + lineNOs

            //     }
            // } else {
            //     returnVal.ok = false
            //     returnVal.message = "Missing line receive quantity. Please enter received quantity for line(s) you want to received then try again."
            //     //reject(Error())
            // }

            resolve(returnVal)
        })
    }

    deleteMtlReq = () => {
        const mtlReqForDeleteDTO = this.createDeleteMtlReqDTO()

        let myURI = apiURL + 'SupplyChain/MaterialRequest'

        fetch(myURI, apiDELETEHeader(mtlReqForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();

                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                } else {
                    this.closeForm()
                }


            })
            .catch(error => {

            })

    }

    rejectMtlReq = () => {

        try {
            // this.dataValidation().then((data) => {

                // if (data.ok) {
                    const { materialRequestData} = this.state

                    materialRequestData[0].statusKey = 1
                    this.setState({materialRequestData: materialRequestData },()=>{
                        if( materialRequestData[0].statusKey = 1){
                            // this.refreshFormForSubmit(true)
                            this.saveMtlRequestHdr()
                            console.log("This should have submitted")
                        }
                        else{
                        }
                            
                    })

                 
                    this.props.refreshData()
                    this.closeForm();

                // } else {
                    // this.setState({
                    //     alertMessage: data.message,
                    //     validDataItem: data.headerValidation,
                    //     showAlert: true
                    // })
                // }
            // })
        } catch (error) {
            console.log(error)
        }

    }

    cancelMtlReq = () => {
        if(this.validateData()){
        try {
            // this.dataValidation().then((data) => {

                // if (data.ok) {
                    const { materialRequestData, temp} = this.state
                    materialRequestData[0].comments = " " + temp
                    materialRequestData[0].statusKey = 5
                    this.setState({materialRequestData: materialRequestData },()=>{
                        if( materialRequestData[0].statusKey = 5){
                            // this.refreshFormForSubmit(true)
                            this.saveMtlRequestHdr()
                            console.log("This should have submitted")
                        }
                        else{
                        }
                            
                    })

                 
                    this.props.refreshData()
                    this.closeForm();

                // } else {
                    // this.setState({
                    //     alertMessage: data.message,
                    //     validDataItem: data.headerValidation,
                    //     showAlert: true
                    // })
                // }
            // })
        } catch (error) {
            console.log(error)
        }
    }

    }

    saveMtlRequestHdr = () => {
        const updateMtlRequestHeaderDTO = this.createMtlRequestHeaderUpdateDTO()

        let myURI = apiURL + 'SupplyChain/MaterialRequest'
        fetch(myURI, apiPUTHeader(updateMtlRequestHeaderDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                this.props.refreshData()
                // this.getMaterialRequestDetails()
            })
            .catch(error => {
                // this.getMaterialRequestDetails()
                console.log("PUT Error: " + error);
            })
    }

    // createMtlRequestHdr = () =>{

    //     console.log("This hit")
       
    //     const updateMtlRequestHeaderDTO = this.createMtlRequestHeaderCreateDTO()

    //     let myURI = apiURL + 'SupplyChain/MaterialRequest'
    //     fetch(myURI, apiPOSTHeader(updateMtlRequestHeaderDTO))
    //         .then(async response => {
    //             const rspData = response.text();
    //             if (!response.ok) {
    //                 console.log("Error return but you dont see")
    //                 const error = (rspData && rspData.message) || response.status;
    //                 return Promise.reject(error);
                   
    //             }
            
    //             console.log("No error here: " + rspData)

                
    //             //this.props.refreshData()
    //             // this.getMaterialRequest()
    //         })
    //         .catch(error => {
    //             // this.getMaterialRequest()
    //             console.log("POST Error: " + error);
    //         })

    // } 
    createMtlRequestHdr = () => {

        const newRCVRDTO = this.createMtlRequestHeaderCreateDTO()

        let myURI = apiURL + 'SupplyChain/MaterialRequest'
        fetch(myURI, apiPOSTHeader(newRCVRDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    
                    var error = (rspData && rspData.message) || response.status;
    
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    });
                    console.log("Error was returned")
                    return Promise.reject(error);
                } else

                    return response.json();

            })
            .then(json => {
                    console.log("Does it hit?")
                    console.log(json[0].mtlRequestKey)       
                if (json[0].mtlRequestKey > 0) {
                    this.setState({ mtlRequestKey: json[0].mtlRequestKey,
                                   })
                    // this.toggleMatReqDetails()
                }
                else{
                    console.log("Do nothing")
                }

            })
            .catch(error => {
                console.log(error)
            })
    }

    createMtlRequestHeaderUpdateDTO = () => {
        const { user } = this.props
        const { materialRequestData, mtlRequestKey, selBasinKey } = this.state

        const mtlRequestHeaderCreateDTO = {
            "MtlRequestKey": mtlRequestKey ,
            "MtlRequestNo": materialRequestData[0].mtlRequestNo,
            "RequestTypeKey": 1,
            "StatusKey": materialRequestData[0].statusKey,
            "FacilityKey": null,
            "ProjectID": materialRequestData[0].projectID,
            "WONumber": materialRequestData[0].woNumber,
            "FromLocationKey": null,
            "ToLocationKey": null,
            "RequestedBy": materialRequestData[0].requestedBy,
            "RequestedDate": materialRequestData[0].requestedDate,
            "ModifiedBy": getUserADAccount(user.account.userName),
            "ModifiedDateTime": new Date().toISOString(),
            // "LocationKey": materialRequestData[0].locationKey,
            "LocationKey": materialRequestData[0].locationKey,
            "SiteID": materialRequestData[0].siteID,
            // "EmployeeKey": (materialRequestData[0].employeeKey === null ? this.getDefaultName() : materialRequestData[0].employeeKey),
            "Comments": materialRequestData[0].comments,
            "SpendCategoryID": materialRequestData[0].spendCategoryID,
        }
        // this.setState({mtlRequestKey: mtlRequestKey})
        // console.log( mtlRequestHeaderCreateDTO)
        return mtlRequestHeaderCreateDTO;
    }

    // createMtlRequestHeaderCreateDTO = () => {
    //     const { user } = this.props
    //     const { materialRequestData, mtlRequestKey } = this.state

    //     const mtlRequestHeaderCreateDTO = {
    //         "MtlRequestNo": materialRequestData[0].mtlRequestNo,
    //         "RequestTypeKey": 2,
    //         "StatusKey": 0,
    //         "FacilityKey": null,
    //         "ProjectID": materialRequestData[0].projectID,
    //         "WONumber": materialRequestData[0].woNumber,
    //         "FromLocationKey": null,
    //         "ToLocationKey": null,
    //         "RequestedBy": materialRequestData[0].requestedBy,
    //         "RequestDate": materialRequestData[0].requestedDate,
    //         "createdBy": this.props.user.account.name,
    //         "LocationKey": materialRequestData[0].locationKey,
    //         "EmployeeKey": materialRequestData[0].employeeKey,
    //         "Comments": materialRequestData[0].comments
            
    //     }
    //     console.log(mtlRequestHeaderCreateDTO)
    //     return mtlRequestHeaderCreateDTO;
    // }

    createMtlRequestHeaderCreateDTO=()=>{
        const {materialRequestData} = this.state
        const {user}= this.props

        const matReqCreate={
            // "mtlRequestKey": -1,
            // "mtrReqStatusDesc": "Incomplete",
            "MtlRequestNo": "XXXXXX",
            // "mtlRequestNo": "Test",
            "RequestTypeKey": 1,
            "StatusKey": 0,
            "FacilityKey": null,
            "ProjectID": materialRequestData[0].projectID,
            "WONumber": materialRequestData[0].woNumber,
            "FromLocationKey": null,
            "ToLocationKey": materialRequestData[0].toLocationKey,
            "RequestedBy":  materialRequestData[0].requestedBy,
            // "RequestedDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            // "requestedDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            // "requestedDate": new Date().toISOString(),
            "RequestDate": materialRequestData[0].requestedDate,
            // "requestedDate":null,
            "LocationKey": materialRequestData[0].locationKey,
            // "createdDatetime": new Date().toISOString(),
            "CreatedBy": getUserADAccount(user.account.userName),
            "SiteID": materialRequestData[0].siteID,
            "AFENo": materialRequestData[0].afeNo
            // "LocationKey": null,
           
           


            // "EmployeeKey": (materialRequestData[0].employeeKey === null ? this.getDefaultName() : materialRequestData[0].employeeKey)
           
        }
        console.log(matReqCreate)
        return matReqCreate;
    }

    createDeleteMtlReqDTO = () => {
        const { user } = this.props
        const { materialRequestData, mtlRequestKey } = this.state

        const mtlRequestForDeleteDTO = {
            "MtlRequestKey": materialRequestData[0].mtlRequestKey,
            "deletedBy": getUserADAccount(user.account.userName), /*4=Submitted, 6=Reviewed*/
        }
        console.log(mtlRequestForDeleteDTO)
        return mtlRequestForDeleteDTO;
    }



    // createNewMatReqForm=()=>{
    //     const {user}= this.props
    //     const matReqCreate={
    //         "mtlRequestKey": -1,
    //         "mtrReqStatusDesc": "Incomplete",
    //         "MtlRequestNo": "",
    //         "RequestTypeKey": -1,
    //         "StatusKey": -1,
    //         "FacilityKey": null,
    //         "ProjectID": "",
    //         "WONumber": "",
    //         "FromLocationKey": null,
    //         "ToLocationKey": null,
    //         "RequestedBy": null,
    //         "RequestedDate": null,
    //         "createdDatetime": new Date().toISOString(),
    //         "createdBy": this.props.user.account.name,
    //         "LocationKey": null
           
    //     }
    //     return [matReqCreate]
    // }

    createNewMatReqForm=()=>{
        const {user}= this.props

        const matReqCreate={
            // "mtlRequestKey": -1,
            "mtrReqStatusDesc": "Incomplete",
            "mtlRequestNo": "XXXXXX",
            // "MtlRequestNo": "MTLREQXXXXX",
            // "mtlRequestNo": "Test",
            "RequestTypeKey": 1,
            "StatusKey": 0,
            "FacilityKey": null,
            "ProjectID": "",
            "WONumber": "",
            "FromLocationKey": null,
            "ToLocationKey": null,
            "requestedBy": "",
            "RequestedBy": "",
            // "RequestedBy": 51,
            // "requestedBy": "Test",
            // "RequestedDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            // "requestedDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            // "requestedDate": new Date().toISOString(),
            // "RequestedDate": new Date().toISOString(),
            "requestedDate": new Date().toISOString(),
            "LocationKey": null,
            "locationKey": null,
            // "createdDatetime": new Date().toISOString(),
            "createdBy": this.props.user.account.name,
            // "LocationKey": null,
            "Comments": "",
            "comments": "",
            // "EmployeeKey": null,
            // "employeeKey": null
           
        }
        console.log(matReqCreate)
        return [matReqCreate]
    }

    getMaterialRequestDetails=()=>{
        const {mtlRequestKey, materialRequestData} = this.state
        console.log("mtlRequestKey: " + mtlRequestKey )

        let myURI = apiURL + 'SupplyChain/MaterialRequest/' + mtlRequestKey

    console.log("myURI");
    console.log(myURI);
    // console.log(materialRequestData[0].mtlRequestKey + "ttt")
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
            this.setState({
                isLoaded: true,
                materialRequestData: json,
                rejectBool: (json[0].statusKey === 1 ? true : false),
                cancelBool: (json[0].statusKey === 5 ? true : false),
                acceptBool: (json[0].statusKey === 4 ? true : false),
                pendingBool:(json[0].statusKey === 3 ? true : false),
                completeBool: (json[0].statusKey === 6 ? true : false),
                // reviseBool:(json[0].statusKey === 3 ? true : false),
                isFormReadOnly: (json[0].statusKey !== 2 && json[0].statusKey !== 0),
                reviseBool:(json[0].statusKey === 2  ? true : false),
                incompleteBool: (json[0].statusKey === 0  ? true : false)
            },()=>{
                // console.log(json)
                // console.log(json[0].statusKey + "xxx")
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    createMtlRequestHdr = () => {

        const newRCVRDTO = this.createMtlRequestHeaderCreateDTO()

        let myURI = apiURL + 'SupplyChain/MaterialRequest'
        fetch(myURI, apiPOSTHeader(newRCVRDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    
                    var error = (rspData && rspData.message) || response.status;
    
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    });
                    console.log("Error was returned")
                    return Promise.reject(error);
                } else

                    return response.json();

            })
            .then(json => {
                    console.log("Does it hit?")
                    console.log(json[0].mtlRequestKey)       
                if (json[0].mtlRequestKey > 0) {
                    this.setState({ mtlRequestKey: json[0].mtlRequestKey,
                                   })
                    // this.toggleMatReqDetails()
                }
                else{
                    console.log("Do nothing")
                }

            })
            .catch(error => {
                console.log(error)
            })
    }


 
    getMaterialRequest = () => {
                    const {mtlRequestKey, materialRequestData} = this.state
            // if (mtlRequestKey > 0){
               
            this.getMaterialRequestDetails()
            //     console.log("Get material request details ran")
            // }
            // else{
            //     console.log("created new form")
            //     const newMtlRequestForm = this.createNewMatReqForm()
            //         this.setState({isLoaded:true,
            //         materialRequestData: newMtlRequestForm,
            //         })
                
            // }
                }

                getSecurity=()=>{
                    let myURI = apiURL + 'EmployeeDetails/Checkpoint/'+ this.props.user.account.userName   
                    const {security, appSecurityNo, meterVal} = this.state
                    fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
                    .then(res => res.json())
                    .then(json=>{
                        this.setState({
                            
                            isSecurityLoaded: true,
                            security: json,
                            isSecurityAdminLogin: (json.filter((list)=> list.appSecurityNo === 111).length > 0 ? false: true),
                            // isSecurityMeasurementTech: (json.filter((list)=> list.appSecurityNo === 15).length > 0 ? false: true),
                            // isOtherUser: (json.filter((list)=> list.appSecurityNo === 15 || list.appSecurityNo === 14 ).length == 0 ? true:false)
                        })
                        // console.log(this.state.isSecurityAdminLogin)
                        // console.log(this.state.isSecurityMeasurementTech)
                      
                    })
            
                    } 



         getMaterialRequestLine = () => {
                    const { mtlRequestKey, mtlReqLine } = this.state

                    let myURI = apiURL + 'SupplyChain/MaterialRequestLine/MtlReqKey/' + mtlRequestKey

                    // console.log(myURI);

                    fetch(myURI, { modes: 'no-cors' })
                        .then(res => res.json())
                        .then(json => {
                            var mtlReqLine = []
                            json.map(e => {
                                e.isUpdated = 0 /* Add this field to keep track of updated receiver line */
                                mtlReqLine.push(e)
                            })
                            this.setState({
                                mtlReqLine: mtlReqLine,
                                isMtlReqLineLoaded: true
                            })
                            // console.log("Test:" + rcvrLine.ItemMasterKey)
                            
                        });
                
                }

                getBasin = () =>{
                    const {selBasinKey} = this.state
                    let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName

                    console.log(myURI);

                    fetch(myURI, { modes: 'no-cors' })
                        .then(res => res.json())
                        .then(json => {
                            this.setState({
                                basinList: this.createBasinList(json),
                                isBasinLoaded: true
                            })
                        });
                }

                getEmployees=()=>{
                    let myURI = apiURL + 'SecAdmin/User' 
            
                console.log("myURI");
                console.log(myURI);
            
                fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                    .then(res => res.json())
                    .then(json => {
                        this.setState({
                            isEmplooyeeLoaded: true,
                            employeeList:this.createEmployeeList(json)
                        })
                    });
                }
            

                getAFE = () =>{
                    const {materialRequestData} = this.state
                    let myURI = apiURL + 'AFE/WDAFE' 

                    console.log(myURI);

                    fetch(myURI, { modes: 'no-cors' })
                        .then(res => res.json())
                        .then(json => {
                            this.setState({
                                afeList: this.createAFEList(json),
                                afeData: json,
                                isAFELoaded: true
                            })
                        });
                }

                getSite = () =>{
                    const {materialRequestData} = this.state
                    let myURI = apiURL + 'SupplyChain/Site' 
            
                    console.log(myURI);
            
                    fetch(myURI, { modes: 'no-cors' })
                        .then(res => res.json())
                        .then(json => {
                            this.setState({
                                siteList: this.createSiteList(json),
                                siteData: json,
                                isSiteLoaded: true
                            })
                        });
                }

              getSC = () => {
                    let myURI = apiURL + 'SupplyChain/SCHeader'
            
                    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                        .then(res => res.json())
                        .then(json => {
                           this.setState({
                                scList: this.createSCList(json),
                                isSCLoaded: true
                           }) 
                          
                        })
             
                }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }

    
    createEmployeeList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.adAccount
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }

    createAFEList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeMasterKey
            e.label = e.projectNoName
            tempList.push(e)
        })

        return tempList
    }

    createSiteList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.siteID
            e.label = e.siteDesc
            tempList.push(e)
        })

        return tempList
    }

    createSCList = (value) =>{
        var tempList=[]
        value.map(e=> {
            e.value = e.spendCategoryID

            e.label = e.spendCategoryDesc
        
            tempList.push(e)
        })

        return tempList
    }





    getSelBasin = (v) => {
        const {basinList} = this.state
        if (v !== null && v !== "") {
            return basinList.filter(e => e.locationKey === v)
        }
        return ""
    }

    getSelEmployee = (v) => {
        const {employeeList} = this.state
        if (v !== null && v !== "") {
            return employeeList.filter(e => e.adAccount === v)
        }
        return ""
    }

    getSelAFE = (v) => {
        const {afeList, materialRequestData} = this.state
        if (v !== null && v !== "") {
                // var test = afeList.filter(e => e.project_ID=== v)
                // console.log(test[0].projectNoName)
            return afeList.filter(e => e.project_ID=== v)
        }
        return null
    }
    getSelSite = (v) => {
        const {siteList} = this.state
        if (v !== null && v !== "") {
                // var test = siteList.filter(e => e.siteID=== v)
                // console.log(test[0].projectNoName)
                return siteList.filter(e => e.siteID=== v)
        }
        return null
    }
    getSelSC = (value) => {
        const{scList} = this.state
        if (value !== null && value !== "") {
            // console.log(scList.filter(e => e.spendCategoryID === value))
           return scList.filter(e => e.spendCategoryID === value)
           
       }
       return null
   }

    getProjectManager = (v) => {
        const {afeList, materialRequestData} = this.state
        const test =afeList.filter(e => e.project_ID=== v )
        // console.log(test)
        // console.log(v + "xxx")
        if (v !== null && v !== "" ) {
           
            if(test.length >= 1){
                // console.log("This hit")
                // console.log(test[0].project_Manager)
                return test[0].project_Manager
            }
            else{
                console.log("This hit 2")
                return ""
            }
      
        }

        return ""
       
    }

    getProjectName = (v) => {
        const {afeList, materialRequestData, selAFEKey} = this.state
        const test =afeList.filter(e => e.project_ID=== v)
        // console.log(test)
        if (selAFEKey !== null && selAFEKey !== "" ) {
           
            if(test.length >= 1){
                return test[0].project
            }
            else{
                // console.log("Do nothing")
                return ""
            }
      
        }

        return ""
       
    }


    
    



      closeForm = (refreshData) => {
        if (refreshData) {
            this.props.refreshData();
            //   this.getMaterialRequestDetails()
        }

        this.props.toggle()
    }

     getSelSCKey = (value) =>{
        return value.spendCategoryID
    }
    

    getSelAFEKey = (value) =>{
        return value.project_ID
    }

    getSelSiteKey = (value) =>{
        return value.siteID
    }


    getSelBasinKey = (value) =>{
        return value.locationKey
    }

    getSelEmployeeKey = (value) =>{
        return value.adAccount
    }

    onTextChange = (fieldName, fieldValue, e) => { 
        const {materialRequestData, selBasin, selAFE, afeData, validDataItem, invalidDataItem} = this.state
        
        var temp = null
        
        console.log(fieldName + '=' + fieldValue )


        switch (fieldName) {

            case "mtlRequestNo":

                materialRequestData[0].mtlRequestNo = fieldValue
                
           break;

           case "spendCategoryID": 

           materialRequestData[0].spendCategoryID = (e === null ? null : this.getSelSCKey(e))
           validDataItem.spendCategoryID = true
            //    setSpendCategoryIDVal(fieldValue != null)
            break;

            case "test":
                  if(fieldValue !== "" && fieldValue !== null){
                    temp = materialRequestData[0].comments + " " + fieldValue
                    // invalidDataItem.comment = (fieldValue != "")
                    invalidDataItem.comments = (fieldValue !== "" && fieldValue !== null)
                  }
                  else{
                      console.log("test")
                  }
        
              
                break;


            case "requestedDate":

             materialRequestData[0].requestedDate = fieldValue
            //  materialRequestData[0].requestDate = fieldValue
             validDataItem.requestedDate = true
            //  this.setState({requestedDate:fieldValue})
                        
             break;

             case "woNumber":

            materialRequestData[0].woNumber = fieldValue
            
            break; 
            
            case "afeNO":

            materialRequestData[0].afeNO = fieldValue
      
            break;

            case "createdDatetime":

            materialRequestData[0].createdDatetime = fieldValue
                
            break;

            case "confirmByName":

            materialRequestData[0].confirmByName = fieldValue
            
            break;

        //     case "basin":
        //         if (e !== null){
        //    this.setState({selBasin: e.value})
        //         }else{
        //             this.setState({selBasin:-1})
        //         }
        //     break;


            case "basin":

                materialRequestData[0].locationKey = (e === null ? null : this.getSelBasinKey(e))
                validDataItem.locationKey = true
                // console.log("locationKey: " + e.value)
                break;



            // case "employee":

            //         // materialRequestData[0].requestedBy = fieldValue
            //         materialRequestData[0].requestedBy = (e === null ? this.getDefaultName() : this.getSelEmployeeKey(e))
            //         // validDataItem.employeeKey = true
            // break;

            

            case "requestedBy":

                    // materialRequestData[0].requestedBy = fieldValue
                    materialRequestData[0].requestedBy = (e === null ? this.getDefaultName() : this.getSelEmployeeKey(e))
                    // validDataItem.employeeKey = true
            break;
            
            
            // case "afe":{
            //     if (e !== null){
    
            //         this.setState({selAFE: e.value})
            //         materialRequestData[0].projectID = e.project_ID
                   
            //     }
            //     else{
            //         this.setState({selAFE: -1})
            //         // this.setState({materialRequestData: null})
            //        this.setState({afeData})
            //     }
            // }

            case "afe": 
            // console.log("hit1")
            materialRequestData[0].projectID = (e === null ? null : this.getSelAFEKey(e))
            materialRequestData[0].siteID = null
            materialRequestData[0].spendCategoryID = null
            validDataItem.projectID = true
            validDataItem.siteID = true
            validDataItem.spendCategoryID = true
             break;


             case "site": 
            // console.log("hit2")
             materialRequestData[0].siteID = (e === null ? null : this.getSelSiteKey(e))
             materialRequestData[0].projectID = null
            
             validDataItem.siteID = true
             validDataItem.projectID = true
             if(e !== null && e !== ""){
                this.setState({scHeaderBool:false})
                // this.props.setStateOfParentSC(false)
                // this.props.setStateofParentHeaderColor(true)
             }
             else{
                //  console.log("this should be null")
                this.setState({scHeaderBool:true})
                // this.props.setStateOfParentSC(true)
                materialRequestData[0].spendCategoryID = null
             }
           
              break;


             case "comments":

                materialRequestData[0].comments = fieldValue
                    
                break;




            default:

        }

        this.setState({materialRequestData: materialRequestData, afeData: afeData, 
            validDataItem: validDataItem, showAlert:false, invalidDataItem: invalidDataItem, temp:temp})

    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.mtlReqLine;
    }

    onGridRowSelected(s, e) {
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({ 
                            mtlRequestLineKey: dataItem.mtlRequestLineKey,
                            lineData : dataItem,
                            spendCategoryID: dataItem.spendCategoryID,
                            itemMasterKey: dataItem.itemMasterKey                                          
                            })

             console.log(dataItem)
             console.log("MtlRequestLineKey: " + dataItem.mtlRequestLineKey)
        }
     
    }


    componentDidMount = () =>{
        // this.getMaterialRequestDetails()
        this.getMaterialRequest()
        this.getBasin()
        this.getAFE()
        this.getSite()
        this.getEmployees()
        this.getMaterialRequestLine()
        this.getSecurity()
        this.getSC()
    }

    closeForm2=(refreshFormNow)=>{
        if(refreshFormNow){
            this.getMaterialRequestDetails()
           
        }
        this.toggleLineCRUD()
       
    }

    createItemLineForm(){
        const {mtlRequestKey, itemMasterKey} = this.props
        const lineItemCreate = {
            "MtlRequestLineKey": -1,
            "MtlRequestKey": mtlRequestKey,
            "ItemMasterKey": -1,
            "ItemNumber": "",
            "ItemDescription": "",
            "requestQty": 0.00,
            "RequestUOMKey": null,
            "issueQty": 0.00,
            "IssueUOMKey": null,
            "lineComment": "",
            "CreatedBy": "",
            "LocationKey": null,
            // "rtnUnitCost": 0.00,
            "unitCost": 0.00
            // "RtnUnitCost": 0.00,
            // "UnitCost": 0.00
            
 
        }
        console.log(lineItemCreate)
        return lineItemCreate 
    } 

         test =()=>{
         
            const {spendCategoryID, itemMasterKey} = this.state
        let myURI = apiURL + 'ItemMaster/ItemBasinDetails'
       

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
              console.log("itemMasterKey: " + itemMasterKey)
              console.log("spendCategoryID: " + spendCategoryID)
             const testing = json.filter(a=>a.spendCategory.includes(spendCategoryID)).filter(a=>a.itemMasterKey === itemMasterKey)

            // const testing = json.filter(a=>a.spendCategory.includes(spendCategoryID))
            
                // console.log(testing)
            //  console.log(testing)
             if (testing.length > 0){
                const testing2 = testing[0].spendCategory
                console.log(testing2)
                this.setState({scFilterOnLoad: testing2},()=>{
                   this.setState({modalLineCRUD: !this.state.modalLineCRUD }) 
                })
             }
             else{
                // this.setState({modalLineCRUD: !this.state.modalLineCRUD }) 
                console.log("Error was found")
             }
             

                // if(testing.length>0){
                //     return testing[0].spendCategory
                // }
                // else{
                //     return ""
                // }
              
            });
          
          
}

    // toggleLineCRUDEdit = () => {
    //     const {mtlRequestLineKey} = this.state
    //     if(mtlRequestLineKey < 0){
    //     }
    //     else{

           
    //         console.log(this.test())
    //         this.setState({scFilterOnLoad: this.test()},()=>{
    //            this.setState({modalLineCRUD: !this.state.modalLineCRUD }) 
    //         })

    //     }


    // }

    resetLineDataOnAdd=(refreshDataOnAdd)=>{
        let newItemLine = this.createItemLineForm()
        if(refreshDataOnAdd){
            this.setState({ 
                lineData : newItemLine,
                mtlRequestLineKey: -1
             
            })
        }
       
    }

    deleteLineData = () => {
        
        const lineDataForDeleteDTO = this.createDeleteLineData()

        let myURI = apiURL + 'SupplyChain/MaterialRequestLine'

        fetch(myURI, apiDELETEHeader(lineDataForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                } else {
                }
            
                this.getMaterialRequestLine()
               
                this.setState({mtlRequestLineKey: -1})

            })
            .catch(error => {
          
                this.getMaterialRequestLine()

            })
      

    }

    
    createDeleteLineData = () => {
        const { lineData } = this.state
        const { user } = this.props

        const lineDataForDeleteDTO = {
            "MtlRequestLineKey": lineData.mtlRequestLineKey,
            "DeletedBy": getUserADAccount(user.account.userName),
        }

        return lineDataForDeleteDTO;
    }



    toggleLineCRUD = () => {
        let newItemLine = this.createItemLineForm()
        this.setState({ 
            lineData : newItemLine,
            mtlRequestLineKey: -1
         
        })
        this.toggleModalCrud()
       
    }
    toggleModalCrud=()=>{
        this.setState({
            modalLineCRUD: !this.state.modalLineCRUD
        })
    }
    toggleAlert = () => {

        this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    resetValidDataItem = () => {
        const { validDataItem } = this.state

        validDataItem.locationKey = true
        // validDataItem.employeeKey = true
        validDataItem.requestedDate = true
        validDataItem.siteID = true
        validDataItem.spendCategoryID = true
        validDataItem.employeeKey = true
        validDataItem.projectID = true

      
        this.setState({ validDataItem: validDataItem })
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    reviseReceiver = () => {
        const {statusKeyBool} = this.state
                try {
                 
                                const { materialRequestData} = this.state
            
                                materialRequestData[0].statusKey = 2
                                this.setState({materialRequestData: materialRequestData  },()=>{
                                    if( materialRequestData[0].statusKey = 2){
                                        this.refreshFormForSubmit(true)                                   
                                        this.closeForm();
                                        this.closeForm();
                                        // this.setState({testBoolean: true})
                                        // this.setState({statusKeyBool: true})
                                        this.props.setStateofParentHeaderColor(true)
                                                            
                                        console.log("This hit")
                                      
                               
                                    }
                                    else{
                                    }
                                        
                                })
                                this.closeForm();
                                // this.closeForm2()
                                           
            
                       
                        }
               
                catch (error) {
                }
            
            
            }

    issueMtlReq = () => {
        const { materialRequestData, isSecurityAdminLogin} = this.state
        
        try {
            this.dataValidation().then((data) => {

                if (data.ok) {
                    if (isSecurityAdminLogin === false && materialRequestData[0].statusKey === 0 ){
                        materialRequestData[0].statusKey = 4
                        this.setState({materialRequestData: materialRequestData},()=>{
                            this.saveMtlRequestHdr()
                        })
                        this.props.refreshData()
                        this.closeForm();

                    }

                   else if(  materialRequestData[0].statusKey === 2){
                        materialRequestData[0].statusKey = 6
                        this.setState({materialRequestData: materialRequestData},()=>{
                            this.saveMtlRequestHdr()
                        })
                        this.props.refreshData()
                        this.closeForm();
                    }
                  
                   else{
                       
                   
                    materialRequestData[0].statusKey = 3
                    this.setState({materialRequestData: materialRequestData },()=>{
                        if( materialRequestData[0].statusKey === 3){
                            // this.refreshFormForSubmit(true)
                            this.saveMtlRequestHdr()
                            // console.log("This should have submitted")
                        }
                        else{
                        }
                            
                    })

                 
                    this.props.refreshData()
                    this.closeForm();

                }} else {
                    this.setState({
                        alertMessage: data.message,
                        validDataItem: data.headerValidation,
                        showAlert: true
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }

    }

    acceptMtlReq = () => {

        try {
            // this.dataValidation().then((data) => {

                // if (data.ok) {
                    const { materialRequestData} = this.state

                    materialRequestData[0].statusKey = 4
                    this.setState({materialRequestData: materialRequestData },()=>{
                        if( materialRequestData[0].statusKey === 4){
                            this.refreshFormForSubmit(true)
                            console.log("This should have submitted")
                        }
                        else{
                        }
                            
                    })

                 
                    this.props.refreshData()
                    this.closeForm();

                // } else {
                    // this.setState({
                    //     alertMessage: data.message,
                    //     validDataItem: data.headerValidation,
                    //     showAlert: true
                    // })
                // }
            // })
        } catch (error) {
            console.log(error)
        }

    }

    completeMtlReq = () => {

        try {
            // this.dataValidation().then((data) => {

                // if (data.ok) {
                    const { materialRequestData} = this.state

                    materialRequestData[0].statusKey = 6
                    this.setState({materialRequestData: materialRequestData },()=>{
                        if( materialRequestData[0].statusKey === 6){
                            this.refreshFormForSubmit(true)
                            console.log("This should have submitted")
                        }
                        else{
                        }
                            
                    })

                 
                    this.props.refreshData()
                    this.closeForm();

                // } else {
                    // this.setState({
                    //     alertMessage: data.message,
                    //     validDataItem: data.headerValidation,
                    //     showAlert: true
                    // })
                // }
            // })
        } catch (error) {
            console.log(error)
        }

    }

    refreshFormForSubmit=(refreshForm)=>{
        if (refreshForm){
            this.getMaterialRequestLine()
            this.saveMtlRequestHdr()
            this.getMaterialRequestDetails()
           
            this.getMaterialRequest()
        }
    }

    getDefaultName=()=>{
        const{ employeeList} = this.state
        // value={this.props.user.account.name}
        // scListMain.filter(a=> e.spendCategory.includes(a.spendCategoryID))
        // Hau.Nguyen@h2obridge.com
        // console.log(this.props.user.account.userName.toLowerCase())
       var scListFilter = employeeList.filter(a=> a.azureAD.includes((this.props.user.account.userName).toLowerCase()))
            if(scListFilter.length>0){
                return scListFilter[0].adAccount
            }
            else{
                var scListFilter2 = employeeList.filter(a=> a.azureAD.includes((this.props.user.account.userName)))
                return scListFilter2[0].adAccount
            }
      
    //  return employeeList.filter(a=> a.azureAD.includes((this.props.user.account.userName).toLowerCase()))
    }

    setShowingAlert = (status) => {
        this.setState({

            isShowingAlert: status

        }

        )
    }

    setStateOfParentValidation=(newTitle)=>{
        this.setState({showAlert: newTitle})
    }
    
    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    isUserLogin = ()=>{
        const {materialRequestData} = this.state
      const { user } = this.props

      if(materialRequestData[0].createdBy !== getUserADAccount(user.account.userName))
      {
          console.log(materialRequestData[0].createdBy)
          console.log( getUserADAccount(user.account.userName))
          return true
      }
      else{
          return false
      }

    }




    reactToPrintContent = ()=>{
                    return this.componentRef;        
    }
    setComponentRef = (ref)=>{
        this.componentRef = ref;
    }

    handleAfterPrint = () => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
      };
    
      handleBeforePrint = () => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
      };
      handleOnBeforeGetContent = () => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        this.setState({ text: "Loading new text...", isLoadingTest: true });
    
        return new Promise((resolve) => {
          setTimeout(() => {
            this.setState(
              { text: "New, Updated Text!", isLoadingTest: false },
              resolve
            );
          }, 2000);
        });
      };
      reactToPrintTrigger = () => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.
    
        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;
    
        // Good
        return <Button outline color="secondary"
        className="btn-no-border"
        // onClick={this.createMtlRequestHdr}
        >
        <img
            src={Plus}
            alt="new"
            style={{ width: "25px", margin: "0 5px 0 0" }} />
        Print
    </Button>
      };
    

    render(){
        const {materialRequestData, isLoaded, isBasinLoaded, basinList, selBasin, isAFELoaded, 
            selAFE, afeList, afeData, isMtlReqLineLoaded, mtlReqLine, mtlRequestLineKey, mtlRequestKey, 
            lineData, msgBody, alertMessage, showAlert, cancelBool, isFormReadOnly, activeTab, acceptBool, pendingBool, 
            isEmplooyeeLoaded, employeeList, validDataItem, mtlStatusKey, 
            selBasinKey, selAFEKey, siteList, isSiteLoaded,
             locationKey, reviseBool, isSecurityLoaded, isSecurityAdminLogin, rejectBool,
              incompleteBool, completeBool, isSCLoaded, scList, 
              scHeaderBool, scHeaderBool2, spendCategoryID, itemMasterKey, 
              scFilterOnLoad, createdBy, isShowingAlert, msgHeader, invalidDataItem, temp} = this.state

        if (!isLoaded || !isBasinLoaded || !isAFELoaded || !isMtlReqLineLoaded
             || !isEmplooyeeLoaded ||!isSiteLoaded  ||!isSecurityLoaded ||!isSCLoaded) {
            return <div> Loading material request details</div>
        } else
        return (<div>
          
                    {/* <Form> */}
                    <Row form style={{ marginTop: "-10px", marginBottom: "10px" }}>

                            <Col sm={4}>

                            {/* <Button color="secondary" className="float-left"
                                    // style={{ margin: "0px 0px 0px 0px" }}
                                    // hidden={this.setIsReadOnly(statusKey)}
                                    onClick={() => this.getDefaultName()}
                                    >Testing</Button> */}


                                <Button color="secondary" className="float-left"
                                    // style={{ margin: "0px 0px 0px 0px" }}
                                    // hidden={this.setIsReadOnly(statusKey)}
                                    onClick={() => this.closeForm()}
                                    >Close</Button>

                                    <ConfirmModal
                                    color="success"
                                    className="float-left"
                                    buttonLabel="Revise"
                                    style={{ marginLeft: "5px" }}
                                    hidden={(!completeBool || isSecurityAdminLogin) || (this.isUserLogin() && isSecurityAdminLogin) }
                                    // disabled={mtlRequestKey < 0}
                                    formName="Success"
                                    // id={receiverKey}
                                    message="Are you sure you want to submit selected material request?"
                                    onClickYes={(e) => this.reviseReceiver()}
                                    >
                                </ConfirmModal>

                                <Button color="primary" className="float-left"
                                    style={{ marginLeft: "5px" }}
                                    hidden={( !incompleteBool && !reviseBool && !acceptBool) || (this.isUserLogin() && isSecurityAdminLogin)}
                                    
                                    onClick={() => this.saveData()}
                                    >Save</Button> 

                                    
                        {/* <ReactToPrint
                        content={this.reactToPrintContent}
                        documentTitle="Testing"
                        // onBeforeGetContent={this.handleOnBeforeGetContent}
                        // onAfterPrint={this.handleAfterPrint}
                        //  onBeforePrint={this.handleBeforePrint}
                         removeAfterPrint
                         trigger={this.reactToPrintTrigger}
                       
                     />
                       */}
                     

                            <ConfirmModal
                                    color="success"
                                    className="float-left"
                                    buttonLabel="Submit"
                                    style={{ marginLeft: "5px" }}
                                    hidden={(isFormReadOnly ||  reviseBool) || (this.isUserLogin() && isSecurityAdminLogin)}
                                    disabled={mtlRequestKey < 0}
                                    formName="Success"
                                    // id={receiverKey}
                                    message="Are you sure you want to submit selected material request?"
                                    onClickYes={(e) => this.issueMtlReq()}
                                    >
                                </ConfirmModal>

                                <ConfirmModal
                                
                                    color="success"
                                    className="float-left"
                                    buttonLabel="Accept"
                                    style={{ marginLeft: "5px" }}
                                    hidden={ (!pendingBool || isSecurityAdminLogin) || (this.isUserLogin() && isSecurityAdminLogin)  }
                                    // disabled={console.log(isSecurityAdminLogin)}
                                    formName="Accept"
                                    // id={receiverKey}
                                    message="Are you sure you want to accept selected material request?"
                                    onClickYes={(e) => this.acceptMtlReq()}
                                    >
                                </ConfirmModal>

                                <ConfirmModal
                                    color="success"
                                    className="float-left"
                                    buttonLabel="Complete"
                                    style={{ marginLeft: "5px" }}
                                    hidden={ (!acceptBool && !reviseBool || isSecurityAdminLogin) || (this.isUserLogin() && isSecurityAdminLogin) }
                                    formName="Confirm"
                                    // id={receiverKey}
                                    message="Are you sure you want to complete the selected material request?"
                                    onClickYes={(e) => this.completeMtlReq()}
                                    >
                                </ConfirmModal>
                            </Col>
                          

                            <Col sm={6}  >
                                <div>
                                <Alert color="danger" 
                                    toggle={() => this.toggleAlert()}
                                    isOpen={showAlert} 
                                  
                                    // style={{ marginBottom: "-5px" }}
                                    >
                                    {alertMessage}
                                </Alert >
                                </div>

                                 <div
                                    className={` alert-success ${isShowingAlert ? 'alert-shown2' : 'alert-hidden'}`}

                                    onTransitionEnd={() => this.setShowingAlert(false)}>

                                    Data is saved

                                </div>
                            </Col>

                        {/* 
                            <Col sm={3}>
                                 <div
                                    className={` alert-success ${isShowingAlert ? 'alert-shown2' : 'alert-hidden'}`}

                                    onTransitionEnd={() => this.setShowingAlert(false)}>

                                    Data is saved

                                </div>
                            </Col> */}

                           
                            <Col sm={2}>

                            <ConfirmModal outline={true}
                                    color="danger"
                                    className="float-right"
                                    buttonLabel="Delete"
                                    // hidden
                                    // style={{ marginLeft: "5px" }}
                                    hidden={(isFormReadOnly ||  reviseBool) || (this.isUserLogin() && isSecurityAdminLogin)}
                                    //hidden={(!((statusKey == 0 && safetyObservationKey > 0) || ((isQHSEAdmin || isQHSEApprover) && statusKey != 6 && statusKey != 4 && safetyObservationKey > 0)))}
                                    formName="Delete"
                                    // id={receiverKey}
                                    message="Are you sure you want to delete selected material request?"
                                    onClickYes={(e) => this.deleteMtlReq()}
                                    image={<img
                                        src={TrashBin}
                                        id={mtlRequestKey}
                                        alt="Delete"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>


                            <ConfirmModal
                                    color="danger"
                                    className="float-right"
                                    buttonLabel="Reject"
                                     style={{ marginLeft: "5px" }}
                                    // hidden={isFormReadOnly}
                                    hidden={(isSecurityAdminLogin || !pendingBool) || (this.isUserLogin() && isSecurityAdminLogin) }
                                    // id={receiverKey}
                                    message="Are you sure you want to reject selected material request?"
                                    onClickYes={(e) => this.rejectMtlReq()}
                                    // image={<img
                                    //     // src={TrashBin}
                                    //     // id={receiverKey}
                                    //     // alt="Reject"
                                    //     style={{ width: "20px", margin: "0 5px 0 0" }} 
                                    //     />}
                                        
                                        >
                                </ConfirmModal>

                                {/* <ConfirmModal
                                    color="danger"
                                    className="float-right"
                                    buttonLabel="Cancel"
                                    // value={console.log(cancelBool)}
                                     style={{ marginLeft: "5px" }}
                                    // hidden={isFormReadOnly}
                                            //True                      //false
                                    hidden={(!isSecurityAdminLogin || ( reviseBool || acceptBool || completeBool || incompleteBool ))
                                         && (isSecurityAdminLogin || incompleteBool || cancelBool)      }
        
                                    // id={receiverKey}
                                    message="Are you sure you want to cancel selected material request?"
                                    onClickYes={(e) => this.cancelMtlReq()}
                                    // image={<img
                                    //     // src={TrashBin}
                                    //     // id={receiverKey}
                                    //     // alt="Cancel"
                                    //     style={{ width: "20px", margin: "0 5px 0 0" }} 
                                    //     />}
                                        
                                        >
                                </ConfirmModal> */}

                                <ConfirmMeterValModal outline={false}
                                  color="danger"
                                  className="float-right"
                                  buttonLabel="Cancel"
                                  hidden={((!isSecurityAdminLogin || ( reviseBool || acceptBool || completeBool || incompleteBool )) || (this.isUserLogin() && isSecurityAdminLogin))
                                    && (isSecurityAdminLogin || incompleteBool || cancelBool)      }
                                //   value={console.log(cancelBool)}
                                  invalid={!invalidDataItem.comments}         
                                  formName="Cancel"
                                  id={mtlRequestKey}
                                  message="Are you sure you want to cancel selected receiver?"                 
                                  onChangeTest = {(e) => this.onTextChange("test",e.target.value,e)}
                                  onClickYes={(e) => this.cancelMtlReq()}
                                
                                   />


                                
                            </Col>
                    </Row>

                    <ColoredLine color="grey" />
                    <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col md={12}>
                            <Row form >
                            <Col  md={2}>
                                    <Label  for="woNo">Return No.</Label>
                                </Col>
                                <Col md={2}>
                                        <Input 
                                            type="text" name="mtlRequestNo"
                                            id="mtlRequestNo" placeholder=""
                                            value={materialRequestData[0].mtlRequestNo}
                                            disabled
                                            onChange={(e) => this.onTextChange("mtlRequestNo", e.target.value, e)}
                                        />
                                </Col>
                           
                                   


                                {/* <Col md={2}>
                                    <Label for="woNo">Status</Label>
                                </Col>    */}
                                 <Col md={2}>
                                        <Input type="text" name="mtrReqStatusDesc"
                                            id="mtrReqStatusDesc" 
                                            value={materialRequestData[0].mtrReqStatusDesc}
                                            disabled
                                            // defaultValue={materialRequestData[0].mtrReqStatusDesc}
                                        />
                                </Col> 

                                <Col md={2}>
                                    <Label for="basin">Basin</Label>
                                </Col>   
                                 <Col md={4}>

                                         <Input disabled type="text" name="basin"
                                            id="rcvrType" placeholder="Basin"
                                            value={materialRequestData[0].basin}
                                        />
                                </Col> 


                                

                            </Row>
                        </Col>

              
                     </Row>

                     <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col md={12}>
                            <Row form>
                            <Col md={2}>
                                    <Label for="employeeKey">Returned By</Label>
                                </Col>
                                     <Col md={4}>
                                        <Select 
                                    //    className={(!validDataItem.employeeKey ? "form-error" : "")}
                                        options={employeeList} 
                                        placeholder = ""
                                                        //true                        true         == true
                                        isDisabled= {((isFormReadOnly && (isSecurityAdminLogin === false && acceptBool === true ? false : true)) || (isSecurityAdminLogin && !incompleteBool)) || (this.isUserLogin() && isSecurityAdminLogin) }
                                        // value={this.getSelEmployee(this.getDefaultName())}

                                        value={this.getSelEmployee(materialRequestData[0].requestedBy === "" ? this.getDefaultName() : materialRequestData[0].requestedBy)}
                                        // value={this.getSelEmployee(console.log(this.props.user.account.name))}
                                        // value={this.getSelEmployee(console.log(this.getDefaultName()))}

                                        // disabled
                                        // value={this.getSelEmployee(materialRequestData[0].employeeKey)}
                                        // defaultValue={this.getSelEmployee(51)}
                                         onChange={(e) => this.onTextChange("requestedBy", e, e)}
                                         isClearable={true}
                                        />
                                </Col> 

                                

                                
                        
                                <Col md={2}>
                                    <Label for="woNo">Created By</Label>
                                </Col>   
                                 <Col md={4}>
                                        <Input type="text"
                                       
                                            disabled
                                            id="createdBy" placeholder=""
                                            // value={materialRequestData[0].createdBy}
                                            // defaultValue={this.props.user.account.name}
                                            value={materialRequestData[0].createdBy.replace(/\./g, " ")}
                                            // onChange={(v,f) => this.onTextChange("createdDatetime", v, f)}
                                        />
                                    </Col>
                                
                                
                              

                            </Row>
                        </Col>

              
                     </Row>

                     
                     <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col md={12}>
                            <Row form>
                            <Col md={2}>
                                    <Label for="woNo">Returned Date</Label>
                                </Col>   
                       
                                <Col md={4}>
                             
                                        <DatePicker  name="requestedDate"
                                        className={(!validDataItem.requestedDate ? "form-error" : "")}
                                            id="requestedDate" 
                                            // disabled={mtlStatusKey != 0}
                                            disabled= {((isFormReadOnly && (isSecurityAdminLogin === false && acceptBool === true ? false : true)) || (isSecurityAdminLogin && !incompleteBool)) || (this.isUserLogin() && isSecurityAdminLogin) }
                                            // value={materialRequestData[0].requestedDate == null ? new Date().toISOString() : materialRequestData[0].requestedDate }
                                            value={materialRequestData[0].requestedDate}
                                            // defaultValue={materialRequestData[0].requestedDate == null ? new Date().toISOString() : materialRequestData[0].requestedDate}
                                            onChange={(v,f) => this.onTextChange("requestedDate", v,f)}
                                        />
                            
                                </Col> 
                                
                        
                                <Col md={2}>
                                    <Label for="woNo">Created Date</Label>
                                </Col>   
                            
                                <Col md={4}>
                                        <Input type="text" disabled  name="createdDate"
                                            id="createdDate" placeholder=""              
                                            value={moment(materialRequestData[0].createdDatetime).format('MM-DD-YYYY')}
                                         
                                        />
                                    </Col>
                                
                                
                                
                              

                            </Row>
                        </Col>

              
                     </Row>

                     


                     <Row form style={{ marginTop: "5px", marginBottom: "5px" }} >
                        <Col md={12}>
                            <Row form>
                            <Col md={2}>
                                    <Label for="AFE #">AFE No.</Label>
                                </Col>  
      

                                 <Col md={4}>
                                        <Select  
                                        className={(!validDataItem.projectID ? "form-error" : "")}
                                        // isDisabled= {mtlStatusKey != 0}
                                        // isDisabled= {isFormReadOnly && !acceptBool}
                                        // isDisabled= {(isFormReadOnly && (isSecurityAdminLogin == false && acceptBool == true ? false : true))}
                                        // isDisabled={  (isFormReadOnly && (isSecurityAdminLogin == false && acceptBool == true ? false : true)) }
                                        isDisabled={ ((isFormReadOnly && !acceptBool  || materialRequestData[0].siteID !== null && materialRequestData[0].siteID !== "") || (isSecurityAdminLogin && !incompleteBool)) || (this.isUserLogin() && isSecurityAdminLogin) }
                                            isClearable={true}
                                            placeholder = ""
                                            options={afeList}
                                            value={this.getSelAFE(materialRequestData[0].projectID)}
                                            // defaultValue={console.log(materialRequestData[0].projectID)}
                                            // value={console.log(materialRequestData[0].projectID)}
                                            onChange={(e) => this.onTextChange("afe", e, e)}
                                        />
                                </Col> 

                            <Col md={2} >
                                    <Label  for="woNo">Project Manager</Label>
                                </Col>
                                <Col md={4}>
                                        <Input type="text" 
                                         name="project_Manager"
                                         id="project_Manager"
                                         
                                            disabled
                                            value={this.getProjectManager(materialRequestData[0].projectID)}
                                            // value={console.log(materialRequestData[0].projectID)}
                                            // value={afeData[0].project_Manager}
                                            // defaultValue={afeData[0].project_Manager}
                                        />
                                </Col>


              
                     </Row>

                     <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col md={12}>
                            <Row form>

                                     <Col md={2}>
                                    <Label for="site">Site</Label>
                                </Col>  
                                     
                                 <Col md={4}>
                                        <Select  
                                         className={(!validDataItem.siteID ? "form-error" : "")}
                                        // isDisabled= {mtlStatusKey != 0}
                                        // isDisabled= {isFormReadOnly && !acceptBool}
                                        // isDisabled= {isFormReadOnly && (isSecurityAdminLogin == false && acceptBool == true ? false : true)}
                                        isDisabled={((isFormReadOnly && !acceptBool  ||  materialRequestData[0].projectID !== null && materialRequestData[0].projectID !== "") || (isSecurityAdminLogin && !incompleteBool)) || (this.isUserLogin() && isSecurityAdminLogin)}
                                        // isDisabled={(materialRequestData[0].projectID !== null && materialRequestData[0].projectID !== "") && (isFormReadOnly && (isSecurityAdminLogin == false && acceptBool == true ? false : true))}
                                            isClearable={true}
                                            placeholder = ""
                                            options={siteList}
                                            value={this.getSelSite(materialRequestData[0].siteID)}
                                            // value={console.log(materialRequestData[0].projectID)}
                                            onChange={(e) => this.onTextChange("site", e, e)}
                                        />
                                </Col> 

                                           
                                        <Col md={2}>
                                    <Label for="woNo">Work Order No.</Label>
                                </Col>
                                <Col md={4}>
                                        <Input 
                                        type="text" name="woNumber"
                                        // disabled= {mtlStatusKey != 0}
                                            disabled= {((isFormReadOnly && !acceptBool) || (isSecurityAdminLogin && !incompleteBool)) || (this.isUserLogin() && isSecurityAdminLogin)}
                                            id="woNumber" placeholder=""
                                            value={materialRequestData[0].woNumber}
                                            // defaultValue={materialRequestData[0].woNumber}
                                            onChange={(e) => this.onTextChange("woNumber", e.target.value, e)}
                                        />
                                </Col>
  
                            </Row>
                        </Col>

         
                            </Row>







                            
                     <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col md={12}>
                            <Row form>

                                     <Col md={2}>
                                    <Label for="sc">Spend Category</Label>
                                </Col>  
                                     
                                 <Col md={4}>
                                        <Select  
                                        className={(!validDataItem.spendCategoryID ? "form-error" : "")}
                                        //  className={(!validDataItem.siteID ? "form-error" : "")}
                                        // isDisabled= {mtlStatusKey != 0}
                                        // isDisabled= {isFormReadOnly && scHeaderBool}
                                        isDisabled={((scHeaderBool || (isFormReadOnly && !acceptBool ) || materialRequestData[0].siteID === "" || materialRequestData[0].siteID === null) || (isSecurityAdminLogin && !incompleteBool)) || (this.isUserLogin() && isSecurityAdminLogin) }
                                            isClearable={true}
                                        
                                            placeholder = ""
                                            options={scList}
                                            value={this.getSelSC(materialRequestData[0].spendCategoryID)}
                                            // value={console.log(materialRequestData[0].projectID)}
                                            onChange={(e) => this.onTextChange("spendCategoryID", e, e)}
                                        />
                                </Col> 
                                           
                            </Row>
                        </Col>

         
                            </Row>
                        </Col>

              
                     </Row>

            <Row form

//  hidden={(receiverTypeKey !== 2) || isFormReadOnly}
            >

            <Col sm={12}>
                <Button color="primary" outline className="float-left"
                // hidden ={mtlRequestKey < 0}
                hidden
                        style={{ height: "35px"}}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.getMaterialRequestLine()}
                    >Refresh</Button>
                    {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                    <Button color="success" outline className="float-left"
                                        disabled ={mtlRequestKey < 0  }
                                        // hidden={(!isSecurityAdminLogin || ( reviseBool || acceptBool || completeBool || pendingBool )) } 
                                        hidden={ ((!acceptBool || !reviseBool && isFormReadOnly) && (isSecurityAdminLogin && !incompleteBool || pendingBool || completeBool ||cancelBool)) || (this.isUserLogin() && isSecurityAdminLogin)  }
                                        // hidden ={mtlRequestKey < 0 }
                                      style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}   
                                    onClick={() => this.toggleLineCRUD()}
                                    
                                ><img
                                src={Plus}
                                alt="new" 
                                
                                style={{ width: "20px", margin: "0 5px 0 0" }} />New Line</Button>

                    <Button color="primary" outline className="float-left"
                                    style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}
                                    // disabled ={mtlRequestLineKey < 0}
                                    disabled ={mtlRequestLineKey < 0}
                                    // hidden={mtlRequestKey < 0 }
                                    hidden={ (((!acceptBool || !reviseBool && isFormReadOnly) && (isSecurityAdminLogin && !incompleteBool || pendingBool || completeBool || cancelBool))) || (this.isUserLogin() && isSecurityAdminLogin)   }
                                //    hidden={(!isSecurityAdminLogin || ( reviseBool || acceptBool || completeBool || !pendingBool )) } 
                                    // onClick={() => this.toggleLineCRUDEdit()}
                                    onClick={() => this.test()}
                                    
                                ><img
                                src={MenuIcon}
                                alt="Edit"
                                style={{ width: "20px", margin: "0 5px 0 0" }} />Edit</Button>

                    <ConfirmModal outline={true} 
                    // hidden = {itemMasterKey < 0}
                      
                        color="danger"
                        className="float-right"
                        buttonLabel="Delete Line"
                        disabled ={mtlRequestLineKey < 0}
                        hidden={!incompleteBool || (this.isUserLogin() && isSecurityAdminLogin)}
                        // hidden ={mtlRequestKey < 0 }
                        // style={{height: "35px", marginBottom: "5px", marginRight: "10px" }}
                        //hidden={(!((statusKey == 0 && safetyObservationKey > 0) || ((isQHSEAdmin || isQHSEApprover) && statusKey != 6 && statusKey != 4 && safetyObservationKey > 0)))}
                        formName="Confirm"
                        // id={receiverKey}
                        message="Are you sure you want to delete selected receiver?"
                        onClickYes={(e) => this.deleteLineData()}
                        image={<img
                            src={TrashBin}
                            // id={receiverKey}
                            alt="Edit"
                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                    </ConfirmModal>
                 
                </Col>
                </Row>

                <Form >
                 <Row form style={{ zIndex: 0, position: 'relative', margin:"5px 0 0 0"  }}
                //  hidden ={mtlRequestKey < 0}
        
                 >
                                       
                            
                            <IgrDataGrid
                                ref={this.onGridRef}
                                height="250px"
                                width='100%'
                                dataSource={mtlReqLine}
                                isColumnOptionsEnabled="false"
                                autoGenerateColumns="false"
                                selectionMode="SingleRow"
                                editMode={0}  
                                // editModeClickAction="SingleClick"
                                // editMode={(isFormReadOnly ? 0 : 1)}
                                selectedKeysChanged={this.onGridRowSelected}
                                // cellValueChanging={this.onCellValueChanging}

                            //cellBackground={"#ffbfbf"}
                            >

                                <IgrTextColumn field="longItemNumber"
                                    headerText="Item Number"
                                    width="350"
                                    //disabled
                                    // cellUpdating={this.onCellUpdating}
                                />

                                 <IgrTextColumn field="spendCategoryDesc"
                                    headerText="Spend Category"
                                    width="250"
                                    //disabled
                                    // cellUpdating={this.onCellUpdating}
                                />
                                {/* <IgrTextColumn
                                    field="itemDescription"
                                    headerText="Item Description"
                                    width="300"
                                    // cellUpdating={this.onCellUpdating}
                                /> */}
                                 <IgrTextColumn field="toInvLocDesc"
                                    headerText="Return To"
                                    width="250"
                                    //disabled
                                    // cellUpdating={this.onCellUpdating}
                                />

                                   <IgrNumericColumn
                                    field="requestQty"
                                    headerText="Return Qty"
                                    width="120"
                                    minFractionDigits={2}
                                    // cellUpdating={this.onCellUpdating}
                                />
                                 <IgrTextColumn
                                    field="requestUOM"
                                    headerText="Return UOM"
                                    width="150"
                                    // cellUpdating={this.onCellUpdating}
                                />
                                   <IgrNumericColumn
                                    field="issueQty"
                                    headerText="Accept Qty"
                                    width="120"
                                    minFractionDigits={2}
                                    // cellUpdating={this.onCellUpdating}
                                />

                                 <IgrNumericColumn
                                    field="unitCost"
                                    headerText="Unit Cost"
                                    width="120"
                                    minFractionDigits={2}
                                    // cellUpdating={this.onCellUpdating}
                                />
                                   <IgrTextColumn
                                    field="issueUOM"
                                    headerText="Issue UOM"
                                    width="120"
                                    // cellUpdating={this.onCellUpdating}
                                />
                                   <IgrTextColumn
                                    field="lineComment"
                                    headerText="Comments"
                                    width="304"
                                    // cellUpdating={this.onCellUpdating}
                                />
                                  

                            </IgrDataGrid> 
                            </Row>

                                <FormGroup row   
                                // hidden ={mtlRequestKey < 0}
                                >

                                <Col md={12}>
                               
                               <Nav tabs >
                                   <NavItem>
                                       <NavLink
                                           className={classnames({ active: activeTab === '1' })}
                                           onClick={() => { this.toggleTab('1'); }}
                                       >
                                           Comments
                                       </NavLink>
                                   </NavItem>
                                   <NavItem>
                                       <NavLink
                                           className={classnames({ active: activeTab === '2' })}
                                           onClick={() => { this.toggleTab('2'); }}
                                       >
                                           Documents
                                       </NavLink>
                                   </NavItem>
                               </Nav>
                               <TabContent activeTab={activeTab}>
                                   <TabPane tabId="1">
                                       <Row form>
                                           <Input type="textarea" name="comments"
                                               rows={4}
                                            //    disabled={isFormReadOnly}
                                            disabled= {isFormReadOnly && (isSecurityAdminLogin === false && acceptBool === true ? false : true)}
                                               id="comments" 
                                               value={materialRequestData[0].comments}
                                               onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                           />
                                       </Row>
                                   </TabPane>
                                   <TabPane tabId="2">
                                       <Row>
                                           {/* <Col md={12}>
                                               <DocumentBrowser buttonLabel="View Image"
                                                   disabled={isFormReadOnly}
                                                   buttonColor="primary"
                                                   formName="Receiver Document"
                                                   linkTableName={"tpoReceiver"}
                                                   linkPrimaryKey={receiverKey}
                                                   apiURL={apiURL + 'SupplyChain/Receiver/Documents/' + receiverKey} />

                                           </Col> */}

                               <Col md={12}>
                                   <DocumentBrowserDragDrop buttonLabel="View Image"
                                       disabled={isFormReadOnly}
                                       buttonColor="primary"
                                       formName="Receiver Documents"
                                       linkTableName={"tpoReceiver"}
                                       linkPrimaryKey={mtlRequestKey}
                                       // allowDelete={isAFEManager || isAFEAdmin}
                                       apiURL={apiURL + 'SupplyChain/Receiver/Documents/' + mtlRequestKey} />

                                        </Col>
                                       </Row>
                                   </TabPane>
                               </TabContent>

                           </Col>


                                </FormGroup>
                
                        </Form>


                        <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader} 
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>

                        <div>
                        <Modal isOpen={this.state.modalLineCRUD}
                            toggle={() => this.toggleLineCRUD()}
                            size="lg" centered
                            backdrop={"static"}
                            >
                            <ModalHeader toggle={() => this.toggleLineCRUD()}>
                                {/* {msgHeader}  */}
                                {mtlRequestLineKey < 0 ? "Create New Line" : "Edit Line"}
                            </ModalHeader> 
                            <ModalBody>
                                <MaterialRequestLineCRUD 
                                    mtlReqLine = {mtlReqLine}
                                    mtlRequestLineKey = {mtlRequestLineKey}
                                    mtlRequestKey = {mtlRequestKey}
                                    lineData = {lineData}
                                    setStateOfParent={this.setStateOfParent}
                                    toggle={this.toggleLineCRUD}
                                    refreshData={this.getMaterialRequestLine}
                                    user ={this.props.user}
                                    resetLineDataOnAdd={this.resetLineDataOnAdd}
                                    selBasinKey={selBasinKey}
                                    locationKey={locationKey}
                                    spendCategoryID={spendCategoryID}
                                    itemMasterKey = {itemMasterKey}
                                    scFilterOnLoad={scFilterOnLoad}
                                    isSecurityAdminLogin={isSecurityAdminLogin}
                                    createdBy={createdBy}
                                    materialRequestData={materialRequestData}
                                    reviseBool={reviseBool}
                                    setStateOfParentValidation= {this.setStateOfParentValidation}
                                  >

                                </MaterialRequestLineCRUD>
                            </ModalBody>
                        </Modal>
                    </div>
                    
        </div>)
    }
    setStateOfParent = (newTitle) => {
        this.setState({lineData: newTitle}, ()=>{
        });
      }

}