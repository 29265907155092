import React, { Component, ReactDOM } from 'react';

import { CustomInput, Col, Row, Button, FormGroup, 
        Label, Input, FormFeedback, ListGroupItem, InputGroup } from 'reactstrap';
import { apiURL, apiDELETEHeader,apiHeader,apiPUTHeader,apiPOSTHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';
import { getRandomInt, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount,USDCurrencyFormat } from '../resources/utility'
import ConfirmModal from './ConfirmModal'
import ImageViewer from '../resources/ImageViewer';
import TrashBin from '../resources/trash.svg'
import { MorphSegment } from 'igniteui-react-gauges';

var DatePicker = require("reactstrap-date-picker");



export default class EventDetail extends Component {
    static displayName = EventDetail.name;

    constructor(props) {
        super(props);

        this.state={
            category:[],
            isCategory:false,
            priority:[],
            isPriorityLoaded:false,
            type:[],
            isTypeLoaded:false,
            subType:[],
            isStatusLoaded:false,
            eventStatus:[],
            isSubTypeLoaded:false,
            eventData:[],
            isLoaded:false,
            facilityEventKey:props.FacilityEventKey,
            offLineLinkKey:(getRandomInt(10000) * -1),
            isReadOnly:false,
            isFacilityLoaded:false,
            facility:[],
            facEventCategoryID:"",
            facEventSubTypeID:"",
            validDataItem:{
                eventCatKey:true,
                priorityKey:true,
                facilityKey:true,               
                eventTypeKey:true,
                eventSubTypeKey:true,
                eventDate:true,
                eventDesc:true,
                statusKey:true
            }
        }
    }

    closeForm = (refreshData) => {
        if (refreshData){
            this.props.refreshData()
        }
        this.props.toggle()
    }

    saveData = () => {
        const {facilityEventKey} = this.state

        if (this.validateData()){
        
            if (facilityEventKey > 0) {
                this.updateEvent()
            }else{
                this.addEvent()
            }
            
            this.closeForm(true)

        }
    }

    validateData=()=>{
        const {validDataItem,eventData} = this.state 
        var bVal = true 
        var valItem = validDataItem

        if (eventData[0].eventCatKey === null || eventData[0].eventCatKey < 0 ) {
            bVal = false
            valItem.eventCatKey = false
        }
        
        if (eventData[0].priorityKey === null || eventData[0].priorityKey < 0 ) {
            bVal = false
            valItem.priorityKey = false
        }

        if (eventData[0].eventTypeKey === null || eventData[0].eventTypeKey <= 0 ) {
            bVal = false
            valItem.eventTypeKey = false
        }

        if (eventData[0].eventSubTypeKey === null || eventData[0].eventSubTypeKey <= 0 ) {
            bVal = false
            valItem.eventSubTypeKey = false
        }

        if (eventData[0].facilityKey === null || eventData[0].facilityKey <= 0 ) {
            bVal = false
            valItem.facilityKey = false
        }

        if (eventData[0].eventDate === null || eventData[0].startDateTime === "") {
            bVal = false
            valItem.eventDate= false
        }
        if (eventData[0].eventDesc === null || eventData[0].eventDesc == "") {
            bVal = false
            valItem.eventDesc= false
        }
        
        
        this.setState({validDataItem:valItem})

        console.log("bVal")        
        console.log(validDataItem)
        return bVal
    }


    updateEvent = () => {
        const eventUpdateDTO = this.createEventUpdateDTO()

        let myURI = apiURL + 'FacilityEvent'
          fetch(myURI , apiPUTHeader(eventUpdateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
              console.log("No error here: " + rspData)
            })
            .catch(error =>{
              console.log("PUT Error: " + error);
            })
    }

    createEventDeleteDTO = () =>{
        const {eventData,facilityEventKey} = this.state;
        const {user} = this.props

        const eventDeleteDTO={
        "facilityEventKey":facilityEventKey,        
        "deletedBy": getUserADAccount(user.account.userName),        
        "logLatitude": eventData[0].latitude,
        "logLongtitude": eventData[0].longtitude}

        return eventDeleteDTO

    }


    createEventUpdateDTO = () =>{
        const {eventData,facilityEventKey} = this.state;
        const {user} = this.props

        const eventUpdateDTO={
        "facilityEventKey":facilityEventKey,
        "eventTypeKey": eventData[0].eventTypeKey,
        "eventDesc": eventData[0].eventDesc,
        "priorityKey": eventData[0].priorityKey,
        "statusKey":  eventData[0].statusKey,
        "startDateTime": eventData[0].eventDate,
        "endDateTime": eventData[0].endDateTime,
        "safetyObservationKey": eventData[0].safetyObservationKey,
        "facilityInspLineItemKey": eventData[0].facilityInspLineItemKey,
        "facilityKey": eventData[0].facilityKey,
        "meterKey": eventData[0].meterKey,
        "tankKey": eventData[0].tankKey,
        "eventCost": eventData[0].eventCost,
        "latitude": eventData[0].latitude,
        "longtitude": eventData[0].longtitude,
        "comments": eventData[0].comments,
        "modifiedBy": getUserADAccount(user.account.userName),       
        "eventCatKey": eventData[0].eventCatKey,
        "eventSubTypeKey": eventData[0].eventSubTypeKey
        }

        return eventUpdateDTO
    }

    createEventCreateDTO = () =>{
        const {offLineLinkKey,eventData} = this.state;
        const {user} = this.props
        const eventCreateDTO={
            "eventTypeKey": eventData[0].eventTypeKey,
            "eventDesc": eventData[0].eventDesc,
            "priorityKey": eventData[0].priorityKey,
            "statusKey":  eventData[0].statusKey,
            "startDateTime": eventData[0].eventDate,
            "endDateTime": eventData[0].endDateTime,
            "safetyObservationKey": eventData[0].safetyObservationKey,
            "facilityInspLineItemKey": eventData[0].facilityInspLineItemKey,
            "facilityKey": eventData[0].facilityKey,
            "meterKey": eventData[0].meterKey,
            "tankKey": eventData[0].tankKey,
            "eventCost": eventData[0].eventCost,
            "latitude": eventData[0].latitude,
            "longtitude": eventData[0].longtitude,
            "comments": eventData[0].comments,
            "createdBy": getUserADAccount(user.account.userName),
            "offLineLinkKey": offLineLinkKey,
            "eventCatKey": eventData[0].eventCatKey,
            "eventSubTypeKey": eventData[0].eventSubTypeKey
        }

        return eventCreateDTO
    }

    addEvent = () => {
        const eventCreateDTO = this.createEventCreateDTO()

        let myURI = apiURL + 'FacilityEvent'
          fetch(myURI , apiPOSTHeader(eventCreateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
              console.log("No error here: " + rspData)
            })
            .catch(error =>{
              console.log("PUT Error: " + error);
            })
    }

    deleteEvent = () => {
        const eventDeleteDTO = this.createEventDeleteDTO()

        let myURI = apiURL + 'FacilityEvent'
          fetch(myURI , apiDELETEHeader(eventDeleteDTO))
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
              console.log("No error here: " + rspData)
            })
            .catch(error =>{
              console.log("DELETE Error: " + error);
            })
        this.closeForm(true)
    }

   

    getFacility=()=>{
        const {user}=this.props;

        let myURI = apiURL + 'FacilityDetails/AzureAD/' + user.account.userName

        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isFacilityLoaded: true,
                facility: json
            })
        })      
    }

    getEventDetails=()=>{
        const {facilityEventKey} = this.state

        if (facilityEventKey<0){

            const json = this.createNewEventData()
            this.setState({
                isLoaded: true,
                eventData: json
            })
        }else{            
        let myURI = apiURL + 'FacilityEvent/'+ facilityEventKey
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isLoaded: true,
                    eventData: json,
                    facEventCategoryID:json[0].facEventCategoryID,
                    facEventSubTypeID:this.getFacEventSubTypeID(json[0].eventTypeKey)
                })
            });
        }
    }

    createNewEventData=()=>{
        const {user} = this.props
        const eventUpdateDTO={
            "facilityEventKey":-1,
            "eventTypeKey": -1,
            "eventDesc": "",            
            "priorityKey": -1,
            "statusKey":  4,
            "eventStatusDesc": "New Event",
            "eventDate": new Date().toISOString(),
            "startDateTime": new Date().toISOString(),
            "endDateTime": new Date().toISOString(),
            "safetyObservationKey": null,
            "facilityInspLineItemKey": null,
            "facilityKey": 0,
            "meterKey": null,
            "tankKey": null,
            "eventCost": 0,
            "costCount":0,
            "latitude": 0,
            "longtitude": 0,
            "comments": "",
            "createdBy": getUserADAccount(user.account.userName),       
            "createdByName":user.account.name,
            "eventCatKey": -1,
            "eventSubTypeKey": -1,
            "inspectionNo":""
            }
        return [eventUpdateDTO]
    }

    getSubType=()=>{
        let myURI = apiURL + 'FacilityEvent/SubType'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isSubTypeLoaded: true,
                    subType: json
                })
            });
    }

    getType=()=>{
        let myURI = apiURL + 'FacilityEvent/Type'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isTypeLoaded: true,
                    type: json
                })
            });
    }


    getPriority=()=>{
        let myURI = apiURL + 'FacilityEvent/Priority'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isPriorityLoaded: true,
                    priority: json
                })
            });
    }

    getCategory=()=>{
        let myURI = apiURL + 'FacilityEvent/Category'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isCategory: true,
                    category: json
                })
            });
    }

    getEventStatus=()=>{
        let myURI = apiURL + 'FacilityEvent/status'

        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isStatusLoaded: true,
                eventStatus: json
            })
        })
    }

    onTextChange=(fieldName,fieldValue,e)=>{
       // console.log("props")
        //console.log(fieldName + '=' + fieldValue)
        const {eventData,validDataItem}=this.state;    
        

        switch(fieldName){
            case "statusKey":
                eventData[0].statusKey=fieldValue
                validDataItem.statusKey = true
                break;
            case "eventCatKey":
                eventData[0].eventCatKey=fieldValue
                const facEventCategoryID=this.getfacEventCategoryID(fieldValue)
                validDataItem.eventCatKey = true
                this.setState({facEventCategoryID:facEventCategoryID})
                break;
            case "priorityKey":
                eventData[0].priorityKey=fieldValue
                validDataItem.priorityKey = true
                break;
            case "eventTypeKey":
                var facEventSubTypeID=''
                eventData[0].eventTypeKey=fieldValue
                validDataItem.eventTypeKey = true
                facEventSubTypeID = this.getFacEventSubTypeID(fieldValue)
                this.setState({facEventSubTypeID:facEventSubTypeID})
                break;
            case "eventSubTypeKey":
                console.log("eventSubTypeKey")
                console.log(fieldValue)
                console.log("facEventSubTypeID")
                console.log(facEventSubTypeID)
                eventData[0].eventSubTypeKey=fieldValue
                validDataItem.eventSubTypeKey= true
                break;
            case "eventDate":

                eventData[0].eventDate=fieldValue
                validDataItem.eventDate= true
                break;
            case "endDateTime":
                eventData[0].endDateTime=fieldValue
                break;
            case "comments":
                eventData[0].comments=fieldValue
                break;
            case "eventDesc":
                eventData[0].eventDesc=fieldValue
                validDataItem.eventDesc= true
                break;
            case "facilityKey":
                eventData[0].facilityKey=fieldValue
                validDataItem.facilityKey= true
                break;
                
            default:

        }
        
            this.setState({eventData:eventData,
                            validDataItem:validDataItem})
        }
    
    getfacEventCategoryID =(catKey)=>{
        const {category} = this.state
        const catID = category.filter(f => f.facEventCategoryKey == catKey)
        return (catID.length >0 ? catID[0].facEventCategoryID : "")
    }
    getFacEventSubTypeID = (typeKey) => {
        const {type} = this.state
        const subType = type.filter(f => f.facEventTypeKey == typeKey)
        return (subType.length>0 ? subType[0].facEventSubTypeID : -1)
    }

    componentDidMount(){
        this.getEventStatus()
        this.getFacility()
        this.getCategory()
        this.getPriority()
        this.getType()
        this.getSubType()
        this.getEventDetails()
    }

    render() {
        const {category,isCategory,priority,isPriorityLoaded,facilityEventKey,facility,facEventCategoryID,
                type,isTypeLoaded,subType,isSubTypeLoaded,offLineLinkKey,isFacilityLoaded,facEventSubTypeID,
                eventData,isLoaded,validDataItem,eventStatus,isStatusLoaded,
                isReadOnly} = this.state
        //console.log("category")
        //console.log(category)

        if (!isCategory){
            return (<div>
                <h3>Loading event category.....</h3>
            </div>)
        }else if (!isPriorityLoaded){
            return (<div>
                <h3>Loading event priority.....</h3>
            </div>)
        }else if (!isTypeLoaded){
            return (<div>
                <h3>Loading event type.....</h3>
            </div>)
        }else if (!isSubTypeLoaded){
            return (<div>
                <h3>Loading event subtype.....</h3>
            </div>)
        }else if (!isFacilityLoaded){
            return (<div>
                <h3>Loading faciltiy data.....</h3>
            </div>)
        }else  if (!isLoaded){
            return (<div>
                <h3>Loading event details data.....</h3>
            </div>)
        }else if (eventData.length <= 0){
            return (<div>
                <h3>Event details not found!.</h3>
            </div>)
        }else
        return (<div>

            <FormGroup>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="eventCat">Category</Label>
                            <CustomInput 
                                invalid={!validDataItem.eventCatKey}
                                type="select"
                                id="eventCat"
                                name="eventCat"
                                defaultValue={eventData[0].eventCatKey}
                                 disabled={isReadOnly}
                                 onChange={(e) => this.onTextChange("eventCatKey",e.target.value,e)}                          
                            >
                                <option value=""></option>
                                {category.map(v => <option value={v.facEventCategoryKey}>{v.facEventCategoryDesc}</option>)}
                            </CustomInput>
                            <FormFeedback tooltip> Please select event category</FormFeedback>
                               
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>

                        <Label for="facilityName">Facility Name</Label>
                            {/* <Input disabled type="text" name="facilityName" 
                            id="facilityName" placeholder="" value={eventData[0].facilityName} /> */}
                            < CustomInput type="select" 
                                    invalid={!validDataItem.facilityKey} 
                                    id="faility"
                                    name="faility"
                                    defaultValue={eventData[0].facilityKey}         
                                    value={eventData[0].facilityKey}    
                                    onChange={(e) => this.onTextChange("facilityKey",e.target.value,e)}                           
                                    >
                                    <option value={-100}></option>                                    
                                    {facility.map(v => <option value={v.facilityKey}>{v.facilityName}</option>)}                                
                                 </CustomInput>        

                                 <FormFeedback tooltip > Please select facility</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="priority">Priority</Label>
                            <CustomInput invalid={!validDataItem.priorityKey}
                                type="select"
                                id="priority"
                                name="priority"
                                defaultValue={eventData[0].priorityKey}
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("priorityKey",e.target.value,e)}
                               
                            >
                                <option value={-1}></option>
                                {priority.map(v => <option value={v.facEventPriorityKey}>{v.facEventPriorityDesc}</option>)}
                            </CustomInput>
                            <FormFeedback tooltip> Please select event priority</FormFeedback>
                             
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        
                        <FormGroup>

                            
                        <Label for="eventStatus">Status</Label>
                            {/* <Input disabled type="text" name="Status" id="Status" 
                                    laceholder="Status" 
                                    value={eventData[0].eventStatusDesc} /> */}
                            <CustomInput invalid={!validDataItem.statusKey} 
                                type="select"
                                id="eventStatus"
                                name="eventStatus"
                                defaultValue={eventData[0].statusKey}
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("statusKey",e.target.value,e)}
                            >
                                <option value={-1}></option>
                                {eventStatus.map(v => <option value={v.facEventStatusKey}>{v.facEventStatusDesc}</option>)}
                            </CustomInput>
                            <FormFeedback tooltip> Please select event status</FormFeedback>
                            
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="eventType">Type</Label>
                            <CustomInput invalid={!validDataItem.eventTypeKey} 
                                type="select"
                                id="eventType"
                                name="eventType"
                                defaultValue={eventData[0].eventTypeKey}
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("eventTypeKey",e.target.value,e)}
                            >
                                <option value={-1}></option>
                                {type.filter(f => f.facEventCatID == (facEventCategoryID=="ER" || facEventCategoryID=="MU" ? facEventCategoryID : "" ) )
                                      .map(v => <option value={v.facEventTypeKey}>{v.facEventTypeDesc}</option>)}
                            </CustomInput>
                            <FormFeedback tooltip> Please select event type</FormFeedback>
                             
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                        <Label for="createdBy">Created By</Label>
                            <Input disabled type="text" name="createdBy" 
                            id="createdBy" placeholder="" value={eventData[0].createdByName} />
                    
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="subType">Sub Type</Label>
                            <CustomInput invalid={!validDataItem.eventSubTypeKey}
                                type="select"
                                id="subType"
                                name="subType"
                                defaultValue={eventData[0].eventSubTypeKey}
                                value = {eventData[0].eventSubTypeKey}
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("eventSubTypeKey",e.target.value,e)}                         
                            >
                                <option value={0}></option>
                                {subType.filter(f => f.facEventSubTypeGroupID==facEventSubTypeID).map(v => <option value={v.facEventSubTypeKey}>{v.facEventSubTypeDesc}</option>)}
                            </CustomInput>
                            <FormFeedback tooltip> Please select event sub type</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="inspectionNo">Inspection No</Label>
                            <Input disabled type="text" name="inspectionNo" id="inspectionNo" 
                            placeholder="" value={eventData[0].inspectionNo} />
                        </FormGroup>
                    </Col>
                </Row>

                  
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="startDate">Start Date</Label>

                            <DatePicker invalid={!validDataItem.eventDate}
                            id="startDate"  
                            value={new Date(eventData[0].eventDate).toISOString()}
                            onChange={(v,f) => this.onTextChange("eventDate",v, f)}
                            disabled={isReadOnly}/> 
                            <FormFeedback tooltip>Please select event start date</FormFeedback>
                         

                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            
                        <Label for="costTotal">Cost Total</Label>
                            <Input disabled type="text" name="costTotal" id="costTotal" 
                                   placeholder="" 
                                   value={USDCurrencyFormat(eventData[0].eventCost)} 
                                   />
    
        
                        </FormGroup>
                    </Col>
                </Row>
                
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                        <Label for="endDate">End Date</Label>
                            <DatePicker id="endDate"  
                            value={new Date(eventData[0].endDateTime).toISOString()}
                            onChange={(v,f) => this.handleChange(v,f)} 
                            disabled={isReadOnly}/> 
                            
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="costCount">Cost Count</Label>
                            <Input disabled type="text" name="costCount" id="costCount" 
                                    value={eventData[0].costCount} 
                                   placeholder="" 
                                    />
    
                        </FormGroup>
                    </Col>
                </Row>                

            <Row form>
                <Col md={12}>
                <FormGroup>
            <Label for="eventDesc">Description</Label>
            <Input invalid={!validDataItem.eventDesc} 
                            type="textarea" 
                            name="eventDesc" 
                            id="eventDesc" 
                        rows={1}
                        disabled={isReadOnly}
                        defaultValue={eventData[0].eventDesc}
                        value={eventData[0].eventDesc} 
                        onChange={(e) => this.onTextChange("eventDesc",e.target.value,e)}
                        placeholder="" />

                 <FormFeedback tooltip>Please enter event description</FormFeedback>
                 </FormGroup>    
                 </Col>        
            </Row>

                <FormGroup>
                <Label for="comments">Comments</Label>
                <Input type="textarea" name="comments" id="comments" 
                 rows={4}
                 disabled={isReadOnly}
                 defaultValue={eventData[0].comments}
                 value={eventData[0].comments} 
                 onChange={(e) => this.onTextChange("comments",e.target.value,e)}
                placeholder="" />

            </FormGroup>

            <FormGroup>
                    <Row>
                    
                    <Col md={3}>
                            
                     <ConfirmModal outline={true}
                                  color="danger"
                                  className="float-left"
                                  buttonLabel="Delete"
                                  hidden = {(facilityEventKey<=-1)}
                                  formName="Confirm"
                                  id={facilityEventKey}
                                  message="Are you sure you want to delete selected cost?"
                                  onClickYes={(e) => this.deleteEvent()}
                                  image={<img
                                    src={TrashBin}
                                    id={facilityEventKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }}/>}>
                        </ConfirmModal>         

                    </Col>


                    <Col md={4}>

                       <ImageViewer buttonLabel="Documents" 
                                        buttonColor="primary" 
                                        formName="Cost Documents"  
                                        hasDoc={1}
                                        //hidden = {(facilityDailyOpCostKey<=-1)}
                                        className={"costDetail-modal-style"}
                                        linkTableName={"topFacilityEvent"}
                                        linkPrimaryKey={(facilityEventKey<0 ? offLineLinkKey:facilityEventKey)}
                                        inspAPIURL={apiURL + 'FacilityEvent/Documents/' + (facilityEventKey<0 ? offLineLinkKey:facilityEventKey)} />

                            
                     </Col>

                     <Col sm={5}>                    
                     <Button color="primary" className="float-right" 
                             onClick={() => this.closeForm(false)}>Cancel</Button>
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> this.saveData()}>OK</Button>
                     </Col>
                     </Row>
                    </FormGroup>


        </FormGroup>


        </div>
        )
    }
}