//Created By BN 11/1/2021



import React, { Component } from 'react';

import { Form, Col, Row, Button, TabContent, TabPane, NavItem, Nav, NavLink, FormGroup, Label, Input, CustomInput, ListGroupItem, ListInlineItem, InputGroup, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiGETHeader, apiDELETEHeader } from '../resources/apiURL';
import { IgrDataGrid } from 'igniteui-react-grids';


import { IgrTextColumn } from 'igniteui-react-grids';

import '../custom.css';

import { getUserADAccount } from '../resources/utility'
import ModalAddRecord from './ModalAddRecord'
import ConfirmModal from '../operation/ConfirmModal'


// import { IgrDataGridModule } from 'igniteui-react-grids';

// import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
// import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';


// IgrDataGridModule.register();
// IgrGridColumnOptionsModule.register();
// //IgrInputModule.register();
// IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class ManageSubform extends Component {



    static displayName = ManageSubform.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellValueChanging = this.onCellValueChanging.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        // const urlPara = this.getURLParameters(props.location)
        // this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state = {
            isLoaded: false,
            data: [],
            showAddNew: false,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            isShowingAlert: false,
            isChanged: false,
            dataSelected: null,
            subform: props.subform



        }
    }

    setShowingAlert = (status) => {
        this.setState({

            // isSafIncUser:false,
            isShowingAlert: status,
            isChanged: false
        }
        )

    }




    // getURLParameters = (inputPara) => {
    //     var paraMeters = {
    //         startDate: null,
    //         endDate: null,
    //         facilityKeys: null,
    //         statusDesc: null,
    //         meterValidationKey: null,
    //         meterVal: null,
    //     }



    //     if (inputPara !== undefined) {
    //         var query

    //         query = new URLSearchParams(inputPara.search);


    //         paraMeters = {
    //             startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
    //             endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
    //             statusDesc: query.get('statusDesc'),
    //             meterValidationKey: query.get('key')
    //         }

    //     }


    //     return paraMeters

    // }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.data;

        //this.grid.cells[0]
        // console.log(this.grid.cellBackground)

    }


    insertRecord = (a, b, c) => {
        const itemDTO = this.createforInsertDTO(a, b, c);
        console.log(itemDTO);

        // const putReq = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(itemDTO)
        // }

        let myURI = apiURL + 'AP/insertEnergyWDCustMap'

        fetch(myURI, apiPUTHeader(itemDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")

                this.setState({ howAddNew: false })
                this.getData()
                //this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })



        // fetch(myURI, putReq)
        //     .then(async response => {
        //         const rspData = response.text();
        //         if (!response.ok) {
        //             console.log("Error return but you dont see")
        //             const error = (rspData && rspData.message) || response.status;
        //             return Promise.reject(error);

        //         }

        //         console.log("No error here: " + rspData)
        //         this.setState({ txtnewWDCusomterID: '', txtnewEnergyLinkCustName: '', txtnewWDCustomerName: '', showAddNew: false })
        //         this.getData()
        //     })
        //     .catch(error => {
        //         console.log("POST Error: " + error);
        //     })


    }


    createforInsertDTO = (a, b, c) => {
        //const { type } = this.state
        // const { user } = this.props




        const dataForInsertDTO = {
            "WDCusomterID": a,
            "EnergyLinkCustName": b,
            "WDCustomerName": c



        }

        return dataForInsertDTO;
    }




    showAddNew = () => {
        this.setState({ showAddNew: !this.state.showAddNew })


    }

    getData = () => {

        // var { filenamesListSelected } = this.state;
        // var fSelected = ""
        // if (filenamesListSelected!=""){fSelected=filenamesListSelected}


        // let myURI = apiURL + 'AP/OGEnergyLink/startdate/' +
        //     this.state.startDate.toString().substring(0, 10) +
        //     '/enddate/' + this.state.endDate.toString().substring(0, 10);
        var myURI

        if (this.props.subform === "customer") {

            myURI = apiURL + 'AP/vciEnergyWDCustMap';
        }

        if (this.props.subform === "revenue") {

            myURI = apiURL + 'AP/vciWDRevenueCategory';
        }
        if (this.props.subform === "site") {

            myURI = apiURL + 'AP/vciEnergyWDSiteMap';
        }


        // let myURI = apiURL + 'AP/OGEnergyLink/startdate/2021-10-01/enddate/2021-10-30';



        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // this.getDataDetails();
                this.setState({
                    isLoaded: true,
                    //const isApprover = (json.filter(rec => rec.employee.toUpperCase() === user.toUpperCase() && rec.lineStatusKey === 3 && rec.actionKey === 1).length > 0)
                    data: json
                    // data: json
                })
            });


    }







    // onGridRef(grid) {
    //     if (!grid) {
    //         return;
    //     }
    //     this.grid = grid;
    //     this.grid.dataSource = this.state.meterValAction;

    // }





    componentDidMount() {

        this.getData();


    }

    // closeForm = (refreshData) => {
    //     const { isUrlPara } = this.state
    //     if (isUrlPara !== true) {
    //         console.log("Form was not closed due to Modal Key")
    //     }

    //     else if (isUrlPara !== false && refreshData) {
    //         this.props.refreshData()
    //         this.props.toggle()
    //     }
    //     else if (isUrlPara !== false) {
    //         this.props.toggle()
    //     }


    // }


    onCellValueChanging(s, e) {
        // if (this.state.showAlert) {
        //     this.setState({
        //         alertMessage: "",
        //         showAlert: false
        //     })
        // }

        this.updateLineValue(e.item.codeDefinitionKey, e.column.field, e.newValue);
    }

    updateLineValue = (key, fieldName, value) => {
        const { data } = this.state
        var temp = []

        data.map(e => {
            if (e.codeDefinitionKey === key) {
                e.isUpdated = 1
                switch (fieldName) {
                    // customer
                    case "wdCusomterID":
                        e.wdCusomterID = value
                        break
                    case "energyLinkCustName":
                        e.energyLinkCustName = value
                        break;
                    case "wdCustomerName":
                        e.wdCustomerName = value
                        break;
                    //revenue
                    case "wdRevenueCategory":
                        e.wdRevenueCategory = value
                        break
                    case "wdRevenueCategoryDesc":
                        e.wdRevenueCategoryDesc = value
                        break;
                    case "wdReferenceID":
                        e.wdReferenceID = value
                        break;

                    case "wdUnitofMeasure":
                        e.wdUnitofMeasure = value
                        break;
                    //site
                    case "wdSite":
                        e.wdCusomterID = value
                        break
                    case "energyPropDesc":
                        e.energyLinkCustName = value
                        break;
                    case "wdSiteName":
                        e.wdCustomerName = value
                        break;

                    case "rrcLeaseNumber":
                        e.rrcLeaseNumber = value
                        break;



                    default:
                        break
                }
                temp.push(e)
            } else {
                temp.push(e)
            }
        })


        this.setState({ data: data, isChanged: true })

    }

    saveData = () => {
        const { costType } = this.state
        //console.log("I was called to save the line: " + costType.afeLineTypeKey)
        const updateDTO = this.createLineUpdateDTO()

        var myURI
        if (this.props.subform === "customer") {
            myURI = apiURL + 'AP/updateEnergyWDCustMap'
        }
        if (this.props.subform === "revenue") {
            myURI = apiURL + 'AP/updateWDRevenueCategory'
        }
        if (this.props.subform === "site") {
            myURI = apiURL + 'AP/updateEnergyWDSiteMap'
        }


        fetch(myURI, apiPUTHeader(updateDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.setShowingAlert(true)
                //this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }


    createLineUpdateDTO = () => {
        const { data } = this.state
        const { user } = this.props
        const dataDTO = []

        data.map(e => {
            if (e.isUpdated !== undefined) {
                if (e.isUpdated !== 0) {
                    var itemDTO
                    if (this.props.subform === "customer") {

                        itemDTO = {
                            "CodeDefinitionKey": e.codeDefinitionKey,
                            "WDCusomterID": e.wdCusomterID,
                            "EnergyLinkCustName": e.energyLinkCustName,
                            "WDCustomerName": e.wdCustomerName
                        }
                    }
                    if (this.props.subform === "revenue") {

                        itemDTO = {
                            "CodeDefinitionKey": e.codeDefinitionKey,
                            "WDRevenueCategory": e.wdRevenueCategory,
                            "WDRevenueCategoryDesc": e.wdRevenueCategoryDesc,
                            "WDReferenceID": e.wdReferenceID,
                            "WDUnitofMeasure": e.wdUnitofMeasure
                        }
                    }

                    if (this.props.subform === "site") {

                        itemDTO = {
                            "CodeDefinitionKey": e.codeDefinitionKey,
                            "WDSite": e.wdSite,
                            "EnergyPropDesc": e.energyPropDesc,
                            "WDSiteName": e.wdSiteName,
                            "RRCLeaseNumber": e.rrcLeaseNumber
                        }
                    }





                    dataDTO.push(itemDTO)
                }
            }

            // console.log("afeLines")
            // console.log(e.isUpdated)

        })

        // console.log("afeLines")
        // console.log(afeLines)

        return dataDTO

    }


    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    deleteLine = () => {

        const createDataforDelete = this.createDataforDelete()


        let myURI = apiURL + '/AP/deleteEnergyWDCustMap'
        fetch(myURI, apiDELETEHeader(createDataforDelete))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.setState({ dataSelected: null })
                this.getData()
                // this.setShowingAlert(true)
                //this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
       
    }

    createDataforDelete = () => {
        const { dataSelected } = this.state
        // const { user } = this.props

        const dataForUpdateDTO = {
            "CodeDefinitionKey": dataSelected,
        }




        return dataForUpdateDTO;
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        var dataItem
        // const { dataSelected } = this.state
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            dataItem = clickedRow.value[0].codeDefinitionKey


        }
        // if (!this.state.hideAssociateDetails){
        //     this.setState({userGroupKey: dataItem.userGroupKey,
        //         userGroupName: dataItem.userGroupName,
        //         applicationSecurityKey: dataItem.applicationSecurityKey,
        //         appSecurityNo:dataItem.appSecurityNo,
        //         securityDetails: dataItem})       
        // }

        // const tempList = []
        // // let clickedRows = e.currentKeys

        // for (let i = 0; i < e.currentKeys.count; i++) {
        //     // console.log(e.currentKeys.item(i).value[0] )
        //     tempList.push(e.currentKeys.item(i).value[0].codeDefinitionKey);
        // }

        this.setState({ dataSelected: dataItem })
        //this.state.dataSelected = tempList;
        // this.state.dataSelected = dataSelected


        //}
    }





    render() {
        const { isLoaded, data, showAddNew, msgHeader, msgBody, isShowingAlert, isChanged, dataSelected, subform } = this.state;
        // var { isAbleToValidate, isSampleCollected, data, isDataLoaded, isSecurityLoaded, isOtherUser, isSecurityAdminLogin, isSecurityMeasurementTech, isLoaded, isLoadedActionRequired, isLoadedActionPerformed } = this.state;



        if (!isLoaded) {
            return (<div>Data is loading...</div>)
        } else

            return (<div>


                <Form height="50%">

                    <FormGroup row>
                        <Col md={12}>


                            <Button color="primary"
                                className="float-left"
                                disabled={!isChanged}
                                style={{ margin: "0px 10px 0px 0px" }}
                                // hidden={(isReadOnly)}

                                //  hidden={this.setIsReadOnly(statusKey)}
                                onClick={() => this.saveData()}
                            >Save</Button>

                            <button className="btn-no-border ">
                                <ModalAddRecord outline={true}
                                    color="white"
                                    className="btn btn-success"
                                    buttonLabel=" Add New "
                                    //   hidden ={(!(statusKey==4)) || (isSecurityAdminLogin)}
                                    // invalid={!invalidDataItem.reviewCommentCont}
                                    // value={invalidDataItem.reviewCommentText}
                                    // defaultValue = {invalidDataItem.reviewCommentText}
                                    // disabled= {dataSelected.length>0 ? "false" : "true"}
                                    // disabled="false"
                                    //inputKey={-1}
                                    // dataSelected={dataSelected}
                                    formName="Add New"
                                    //   id={meterValidationKey}
                                    message=""

                                    subform={subform}
                                    //   meterValidation={meterValidation}
                                    // onClosed={(e) => this.removeErrorZero()}
                                    // onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                                    // onClickYes={(a,b,c) => this.addCustomer(a, b, c)}
                                    onClickYes={(e) => this.getData()}


                                // insertCustomerOK={insertCustomerOK}
                                // image={<img
                                //     src={RemoveIcon}
                                //     style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}

                                />
                            </button>

                            <span
                                className={` alert-success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}
                                onTransitionEnd={() => this.setShowingAlert(false)}>
                                Data is saved
                            </span>

                            <button className="btn-no-border float-right">
                                <ConfirmModal outline={true}
                                    color="white"
                                    className="btn btn-danger"
                                    buttonLabel=" Delete"
                                    disabled={dataSelected === null}
                                    // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                    formName="Confirm"
                                    // id={facilityOilTicketKey}
                                    message="Are you sure you want to delete selected line?"
                                    onClickYes={this.deleteLine}

                                // image={<img
                                //     src={TrashIcon}
                                //     // id={facilityOilTicketKey}

                                //     style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}
                                >
                                </ConfirmModal>
                            </button>

                        </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                        <Col md={12}>
                            <div>
                                <Label>

                                    ddddd {subform}   </Label>

                            </div>
                        </Col>

                    </FormGroup> */}



                    <FormGroup>
                        <IgrDataGrid
                            ref={this.onGridRef}
                            // height={gridHeight}
                            dataSource={data}
                            // dataSource={safetyInc.filter( rec =>  ((rec.safetyObservationNo || '').toLowerCase().includes(searchText)) ||
                            //                                     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.qhseReportTypeDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.hazardDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.solutionDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.createdByName || '').toLowerCase().includes(searchText) ||       
                            //                                     (rec.managerName || '').toLowerCase().includes(searchText) ||                                                      
                            //                                     (rec.locationName || '').toLowerCase().includes(searchText))}
                            autoGenerateColumns="false"
                            selectionMode="SingleRow"
                            selectedKeysChanged={this.onGridRowSelected}

                            editModeClickAction="SingleClick"
                            // editMode={1}
                            cellValueChanging={this.onCellValueChanging}
                        // selectionMode="MultipleRow"
                        // selectedKeysChanged={this.onGridRowSelected}
                        //cellBackground={"#ffbfbf"}
                        >


                            {/* <IgrTextColumn field="oegEnergy" headerText="OEG Energy" width="*>140" /> */}


                            <IgrTextColumn isHidden={subform != "customer"} field="wdCusomterID" headerText="WD CustomerID" width="190" />
                            <IgrTextColumn isHidden={subform != "customer"} field="energyLinkCustName" headerText="EnergyLink CustName" width="*>250" />
                            <IgrTextColumn isHidden={subform != "customer"} field="wdCustomerName" headerText="WD CustomerName" width="*>250" />


                            <IgrTextColumn isHidden={subform != "revenue"} field="wdRevenueCategory" headerText="WD Revenue Category" width="210" />
                            <IgrTextColumn isHidden={subform != "revenue"} field="wdRevenueCategoryDesc" headerText="WD Revenue Category Desc" width="300" />
                            <IgrTextColumn isHidden={subform != "revenue"} field="wdReferenceID" headerText="WD ReferenceID" width="300" />
                            <IgrTextColumn isHidden={subform != "revenue"} field="wdUnitofMeasure" headerText="WD Unit of Measure" width="*>80" />

                            <IgrTextColumn isHidden={subform != "site"} field="wdSite" headerText="WD Site" width="120" />
                            <IgrTextColumn isHidden={subform != "site"} field="energyPropDesc" headerText="Energy Prop Desc" width="380" />
                            <IgrTextColumn isHidden={subform != "site"} field="wdSiteName" headerText="WD Site Name" width="380" />
                            <IgrTextColumn isHidden={subform != "site"} field="rrcLeaseNumber" headerText="RRC Lease Number" width="*>80" />


                            {/* <IgrNumericColumn field="oegEnergy" isHidden /> */}
                        </IgrDataGrid>
                    </FormGroup>



                    <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>














                </Form>

            </div>)
    }
}

