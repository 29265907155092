import React, { Component, useEffect, useState } from 'react';

import { Col, Row, Button, FormGroup, Label, Form } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import PlusIcon from '../../resources/plus.svg'
import {
    apiURL, apiHeader, apiPUTHeader,
    REACT_APP_MAPS_API_KEY, apiPOSTHeader, apiDELETEHeader
} from '../../resources/apiURL';

import '../../resources/mapAssetShape.css'

import { Loader } from "@googlemaps/js-api-loader"
import { FastReflectionHelper } from 'igniteui-react-core';


var map = ''
var dataLayer = ''
const additionalOptions = {};

const loader = new Loader({
    apiKey: REACT_APP_MAPS_API_KEY,
    version: "weekly",
    ...additionalOptions,
});



export default class AssetMapView extends Component {
    static displayName = AssetMapView.name;



    constructor(props) {
        super(props)
        document.title = "WaterBridge Portal - Asset Mapview"

        this.state = {
            assetCoor: [],
            isAssetCoorLoaded: false,
            basin: props.basin,
            assetTypeList: props.assetTypeList,
            subSystem: props.subSystem,
            companyID: props.companyID,
            assetCatID: props.assetCatID,
            selAssetType: props.selAssetType,
            selBasin: props.selBasin,
            selSubSystem: props.selSubSystem,
            assetTypeID: props.assetTypeID,
            assetKey: props.assetKey,
            parentAssetKey: props.parentAssetKey,
            mapzoom : (props.mapzoom === undefined ? 8 : 12),
            showFilterBar: true, //(props.showFilterBar === undefined ? true : props.showFilterBar),
            searchText: '',
            markerList: [],
            kmzFiles: props.kmzFiles,
            kml_URLs: [{
                basin_name: 'ARK',
                url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRARKRoutes.kml'
            },
            {
                basin_name: 'NDB',
                url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRNDBRoutes.kml'
            },
            {
                basin_name: 'EVX',
                url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBREVXRoutes.kml'
            },
            {
                basin_name: 'SDB',
                url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRSDBRoutes4.kml'
            },
            {
                basin_name: 'SDB1',
                url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRSDBRoutes5.kml'
            }
        ]
            ,

        }
    }

    getAssetCoordinate = () => {
        const { assetTypeID, assetKey, parentAssetKey,
            selAssetType, selBasin, selSubSystem } = this.state
        // console.log('getAssetCoordinate------')
        // console.log('assetTypeID -  ' + assetTypeID)
        // console.log('assetKey - ' + assetKey)
        // console.log('parentAssetKey - ' + parentAssetKey)

        let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/Coordinate'

        /*only load if map is not all assets. */
        if (assetTypeID !== "") {
            //myURI = myURI + '/ByAssetType/' + assetTypeID
            if (assetKey > 0) {
                myURI = myURI + '/ByAssetKey/' + assetKey
            } else if (assetTypeID !== "") {
                myURI = myURI + '/ByAssetType/' + assetTypeID
            }
        }

        if (parentAssetKey > 0) {
            myURI = myURI + '/ByParentKey/' + parentAssetKey
        }

        // console.log("myURI")
        // console.log(myURI)
        // console.log(this.state.assetCatID)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log(json)
                this.setState({
                    isAssetCoorLoaded: true,
                    assetCoor: json,
                })

                // console.log(" Asset Mapview ----> json")
                // console.log(json)
                /*only load if asset type is selected */
                if (assetTypeID !== "") {
                    this.initMap(json)
                } else {
                    //Load empty map
                    if (selAssetType.length > 0 || selBasin.length > 0 || selSubSystem.length > 0) {
                        this.getFitlerAsset(json, selAssetType, selBasin, selSubSystem)
                    } else {
                        this.initMap([])

                    }

                }

            });

    }



    async initMap(data) {

        // The location of Uluru
        //const position = { lat: 29.749907, lng: -95.358421 };

        // Request needed libraries.
        //@ts-ignore
        loader.importLibrary('maps').then(async () => {

            const { Map, InfoWindow, Point, KmlLayer } = await window.google.maps.importLibrary("maps");
            const { AdvancedMarkerElement, PinElement, Marker } = await window.google.maps.importLibrary("marker");
            //const {KmlLayer } =  await window.google.maps.importLibrary("KmlLayer");

            // The map, centered at Uluru
            if (map.data === undefined || this.state.markerList[0] === undefined) {
                map = new Map(document.getElementById("map"), {
                    zoom: this.state.mapzoom,
                    center: {
                        lat: (data.length > 0 ? data[0].lat : 29.740700),
                        lng: (data.length > 0 ? data[0].lng : -95.463600)
                    },
                    mapTypeId: 'hybrid',
                    mapId: "WBR_Map",

                });

            } else {

                // console.log("this.state.markerList")
                // console.log(this.state.markerList[0])
                for (var i = 0; i < this.state.markerList.length; i++) {
                    this.state.markerList[i].map = null //= null
                    //console.log(this.state.markerList[i])
                }
                this.state.markerList.length = 0
            }

            map.data.addListener('mouseover', (event) => {
                map.data.revertStyle();
                // ADD A STYLE WHEN YOU HOVER OVER A SPECIFIC POLYGON
                map.data.overrideStyle(event.feature, { strokeWeight: 3, fillOpacity: 0.1 });
                // IN CONSOLE LOG, YOU CAN SEE ALL THE DATA YOU CAN RETURN
                console.log(event.feature)
            });
            map.data.addListener('mouseout', (event) => {
                // REVERT THE STYLE TO HOW IT WAS WHEN YOU HOVER OUT
                map.data.revertStyle();
            });

            const infoWindow = new InfoWindow({
                content: "",
                disableAutoPan: true,
            });

            /** load kml files */
            this.state.kml_URLs.map(e => {
                const kmzLayer = new KmlLayer(e.url, {
                    suppressInfoWindows: true,
                    preserveViewport: false,
                    map: map ,
                })
                kmzLayer.setMap(map)
            })
            

            data.map((e, i) => {

                  /*Added by HN 05/28/2024
                loadd kmz or addtional file for selected asset */
                if (e.addon_file_type !== undefined &&
                    e.addon_file_type !== null &&
                    e.addon_file_type === 'kmz') {
                    let kmzFiles = JSON.parse(e.addon_file_urls)
                    // console.log('---->kmzFiles')
                    // console.log(kmzFiles)

                    /** load kml files */
                    if (kmzFiles !== undefined && kmzFiles !== null) {
                        // console.log("kmzFiles")
                        // console.log(kmzFiles)
                        try {
                            kmzFiles.map(e => {
                                console.log(e.url)
                                const kmlLayer = new KmlLayer(e.url, {
                                    //suppressInfoWindows: true,
                                    //preserveViewport: false,
                                    map: map,
                                })
                                kmlLayer.setMap(map)
                            })
                        }catch (err) {
                            console.log('Error in load map kmz files: ' + e.fileName )
                        }
                       
                    }
                }
                /**end */


                const title = e.asset_name;
                const pos = { lat: e.lat, lng: e.lng }

                const pin = new PinElement({
                    glyphColor: "white",
                    background: "#006994",
                    scale: 0.8,
                });

                const assetShape = document.createElement("div");
                assetShape.className = (e.html_class_name)   //"rectangle-blue";
                assetShape.textContent = "";

                //  Make first div    
                const div_form_group = document.createElement('div');
                div_form_group.setAttribute("class", "form-group");

                const label = document.createElement('label');
                //label.setAttribute('for','college')
                label.setAttribute('class', 'asset-title');
                label.innerHTML = title

                div_form_group.appendChild(label);
                div_form_group.appendChild(assetShape);

                const marker = new AdvancedMarkerElement({
                    position: pos,
                    map,
                    content: div_form_group, //assetShape, //pin.element,
                    title: title, //  `${i + 1}. ${title}`
                });

                this.state.markerList.push(marker)

                // markers can only be keyboard focusable when they have click listeners
                // open info window when marker is clicked
                marker.addListener("click", () => {
                    infoWindow.setContent(this.getAssetTitle(title, e.html_asset_detail));
                    infoWindow.open(map, marker);

                });

              


            });

            



        })

    }

    getAssetTitle = (title, asset_details) => {
        return (
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h4 id="firstHeading" class="firstHeading">' + title + '</h4>' +
            '<div id="bodyContent">' +
            asset_details +
            "</div>" +
            "</div>"
        )
    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { assetCoor } = this.state
        // console.log(fieldName + ' ------ ' + fieldValue)
        // console.log(e)

        switch (fieldName) {
            case "asset_type":
                this.getFitlerAsset(assetCoor, e, this.state.selBasin, this.state.selSubSystem)
                this.setState({ selAssetType: e })
                break;
            case "sub_system":
                this.setState({ selSubSystem: e })
                break;
            case "basin":
                this.setState({ selBasin: e })
                break;
            case "search":
                this.getFitlerAsset(assetCoor, [], [], [], fieldValue)
                this.setState({ searchText: fieldValue })
                break;
            default:
                break;
        }
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            //console.log('do validate')
            //this.getFitlerAsset(this.state.assetCoor, [], [], [], this.state.searchText)
        }
    }

    getFitlerAsset = (e, m, b, s, text) => {
        //const {fullAFEData} = this.state
        const { user } = this.props

        const assetTypes = m.map(e => e.asset_type_id).join(",")

        // const basins = b.map(e => e.basin).join(",")
        // const sub_system = s.map(e => e.afeStatusDesc).join(",")
        // const userName = user.account.userName.toLowerCase()

        var tempAFE = e

        if (assetTypes.length > 0) {
            tempAFE = tempAFE.filter(e => assetTypes.includes(e.asset_type_id))
        }



        // if (basins.length > 0) {
        //     tempAFE = tempAFE.filter(e => basins.includes(e.basin))
        // }

        // if (status.length > 0) {
        //     tempAFE = tempAFE.filter(e => status.includes(e.afeStatusDesc))
        // }

        if (text !== null || text !== undefined) {
            tempAFE = tempAFE.filter(e => e.asset_name.toLowerCase().includes(text.toLowerCase()))
        }
        this.initMap(tempAFE)

    }


    componentDidMount() {
        this.getAssetCoordinate()

    }

    render() {
        const { basin, assetTypeList, subSystem, parentAssetKey, assetTypeID,
            selAssetType, selBasin, selSubSystem } = this.state
        var { showFilterBar } = this.state

        showFilterBar = true // (showFilterBar && (parentAssetKey <= 0 && assetTypeID === '')) 
        const mapHeight = (showFilterBar ? '78vh' : '85vh')
        return (
            <div>
                <FormGroup>
                    <Row form hidden={!showFilterBar} style={{}}>
                        <Col md={3} hidden={true}>
                            <Row form>
                                <Col md={12}>
                                    <Label for="typeKey">Asset Type</Label>
                                    <Select
                                        options={assetTypeList}
                                        value={selAssetType}
                                        // defaultValue={selAFEQuery}
                                        onChange={(e) => this.onTextChange("asset_type", e, e)}
                                        isClearable={true}
                                        //width="200px"
                                        isMulti
                                    >
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} hidden={true}>
                            <Row form>
                                <Col md={12}>
                                    <Label for="typeKey">Basin</Label>
                                    <Select
                                        options={basin}
                                        value={selBasin}
                                        defaultValue={selBasin}
                                        onChange={(e) => this.onTextChange("basin", e, e)}
                                        isClearable={true}
                                        // width="200px"
                                        isMulti
                                        isVisible={false}
                                    >
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} hidden={true}>
                            <Col md={12}>
                                <Label for="typeKey">Assets </Label>

                                <Select
                                    options={subSystem}
                                    value={selSubSystem}
                                    defaultValue={selSubSystem}
                                    onChange={(e) => this.onTextChange("asset", e, e)}
                                    isClearable={true}
                                    isMulti
                                >
                                </Select>
                            </Col>
                        </Col>
                        <Col md={3} hidden={true}>
                            <Col md={12}>
                                <Label for="typeKey">Sub System</Label>

                                <Select
                                    options={subSystem}
                                    value={selSubSystem}
                                    defaultValue={selSubSystem}
                                    onChange={(e) => this.onTextChange("sub_system", e, e)}
                                    isClearable={true}
                                    isMulti

                                >
                                </Select>
                            </Col>
                        </Col>
                        <Col md={2}>

                            {/* <Label for="typeKey"> </Label> */}
                            <Input width="75%" className='floatRight'
                                placeholder="Type here to search......."
                                onChange={(e) => this.onTextChange("search", e.target.value, e)}
                                onKeyDown={this.handleKeyDown}
                            >

                            </Input>


                        </Col>

                    </Row>

                </FormGroup>

                <FormGroup>


                    <div id='mapContainer'>
                        <div style={{
                            width: '100%',
                            height: mapHeight,
                            padding: ("0px"),
                            margin: "0px"
                        }} id='map' />
                    </div>
                </FormGroup>
            </div>
        );
    }
}




// const markers = data.map((position, i) => {
//     const label = position.asset_name;
//     const marker = new Marker({
//         position,
//         icon: svgMarker
//     });



//     // markers can only be keyboard focusable when they have click listeners
//     // open info window when marker is clicked
//     marker.addListener("click", () => {
//         infoWindow.setContent(label);
//         infoWindow.open(map, marker);
//     });

//     return marker;
// });


//new MarkerClusterer({ markers, map, content: pinScaled.element, });



// // Adjust the scale.
// const pinScaled = new PinElement({
//     scale: 0.8,
// });
// // The marker, positioned at Uluru
// const marker = new AdvancedMarkerElement({
//     map: map,
//     position: position,
//     content: pinScaled.element,
//     title: "Houston",
// });

