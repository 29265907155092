import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");


// const CardSummary = (props) => {
//     const afeComment = props.afeComment
//     const linkDataLevel = props.linkDataLevel
//     const user = props.user

//     const style = { width: "100%", padding: "0px 0px 2px 10px" };

//     const hdrStyle = { height: "35px", padding: "5px 5px 0px 20px" };

//     const hdrStyleInspComplete = { height: "35px", padding: "5px 5px 0px 20px", backgroundColor: '#90EE90', borderColor: '#333' };


//     const CommentLineBreak = (props) => {

//         if (props.comments === null) {
//             return (<div></div>)
//         } else
//             return (<div>

//                 {props.comments.split("{/n}").map(i =>
//                     <div>{i}</div>
//                     // i.split("\n").map(k=> 
//                     // <p>{k}</p> )
//                 )

//                 }

//             </div>
//             )
//     }


//     const refreshComment = () => {
//         props.refreshComment()
//     }

//     const createAFECommentForDeleteDTO = () => {
//         const operationCommentKey = afeComment.operationCommentKey
//         const opsCmtDTO = {
//             "operationCommentKey": operationCommentKey,
//             "deletedBy": getUserADAccount(user.account.userName),
//         }
//         return opsCmtDTO
//     }

//     const deleteData = () => {
//         const delDTO = createAFECommentForDeleteDTO()
//         let myURI = apiURL + 'AFE/Comment'

//         fetch(myURI, apiDELETEHeader(delDTO))
//             .then(async response => {
//                 const rspData = response.text();
//                 if (!response.ok) {
//                     console.log("Error return but you dont see")
//                     const error = (rspData && rspData.message) || response.status;
//                     //return Promise.reject(error);
//                     return false
//                 }

//                 console.log("No error here: " + rspData)
//             })
//             .catch(error => {
//                 console.log("PUT Error: " + error);
//                 return false
//             })

//         return true
//     }


//     const editComment = () => {
//         props.editComment(afeComment.operationCommentKey,
//             afeComment.commentDate,
//             afeComment.comments)
//     }

//     const getHdrStyle = (v) => {
//         if (v.statusKey !== undefined && v.statusKey !== null) {
//             if (v.statusKey == 6) {
//                 return hdrStyleInspComplete
//             } else return hdrStyle
//         } else {
//             return hdrStyle
//         }
//     }

//     return (<div>
//         <Card style={style} body className="text-left">
//             <CardText>
//                 {new Date(afeComment.commentDate).toLocaleString() +
//                     " - By: " + afeComment.createdBy
//                     + ":  \n " + afeComment.comments}

//             </CardText>
//         </Card>

//         {/* 
//         <Button outline color="secondary"
//                         className="float-right btn-no-border"
//                         onClick={(e) => editComment()}
//                         hidden={!(afeComment.facilityInspectionHdrKey === null || (afeComment.facilityInspectionHdrKey === undefined))}
//                         style={{ padding: "0 0 5px 0" }}
//                     // style={{margin:"-15px 0 0 0"}}
//                     >
//                         <img
//                             src={MenuIcon}
//                             alt="new"
//                             style={{ width: "20px", margin: "0 5px 3px 0" }} />
//                         Edit
//                     </Button>{' '} */}
//     </div>)
// }


// const AFECommentCard = (props) => {
//     const afeComment = props.afeComment

//     const style = { width: "100%", padding: "0 0 10px 0" };
//     return <Row>
//         {afeComment.map(v =>
//             <Card style={style}>
//                 <CardSummary afeComment={v}
//                     refreshComment={props.refreshComment}
//                     editComment={props.editComment}
//                     user={props.user} />
//             </Card>
//         )
//         }
//     </Row>

// }


export default class AFEComment extends Component {
    static displayName = AFEComment.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
       
        this.state = {
            afeHeaderKey: props.afeHeaderKey,
            afeData: props.afeData,
            afeComment: [],
            isLoaded: false,
            isFormReadOnly: false,
            modal: false,
            user: props.user,
            operationCommentKey: -1,
            comments: ' ',
            cmtDate: new Date().toISOString(),
            isAFEAdmin: props.isAFEAdmin,
            isAFEManager: props.isAFEManager
        }

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.afeComment;
    }

    toggleOK = () => {
        if (this.saveAFEComment()) {
            this.setState({
                modal: !this.state.modal,
                comments: " "
            })

        }
    }

    validateData = () => {
        var { comments } = this.state
        comments = comments.trim()
        if (comments != "") {
            return true
        } else {
            this.setState({ comments: comments })
        }
    }

    createAFECommentForUpdateDTO = () => {
        const { operationCommentKey, comments, user } = this.state
        const opsCmtDTO = {
            "operationCommentKey": operationCommentKey,
            "comments": comments,
            "modifiedBy": getUserADAccount(user.account.userName),
            "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    updateData = () => {
        const uptDTO = this.createAFECommentForUpdateDTO()
        let myURI = apiURL + 'AFE/Comment'


        fetch(myURI, apiPUTHeader(uptDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    //return Promise.reject(error);
                    return false
                }

                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("PUT Error: " + error);
                return false
            })

        return true
    }
    createAFECommentForCreateDTO = () => {
        const { afeHeaderKey, cmtDate, comments, user } = this.state

        const afeCmtDTO = {
            "commentTypeKey": 3, //AFE
            "compObjectKey": afeHeaderKey, //selectedValue.key,
            "facilityKey": null,
            "commentDate": cmtDate,
            "comments": comments,
            "createdBy": getUserADAccount(user.account.userName),
            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return afeCmtDTO
    }

    saveAFEComment = () => {
        const { operationCommentKey } = this.state
        if (this.validateData()) {

            if (operationCommentKey > 0) {
                if (this.updateData()) {
                    this.setState({ operationCommentKey: -1 })
                }
            } else {
                const addDTO = this.createAFECommentForCreateDTO()
                let myURI = apiURL + 'AFE/Comment'
                fetch(myURI, apiPOSTHeader(addDTO))
                    .then(async response => {
                        const rspData = response.text();
                        if (!response.ok) {
                            console.log("Error return but you dont see")
                            const error = (rspData && rspData.message) || response.status;
                            //return Promise.reject(error);
                            return false
                        }

                        console.log("No error here: " + rspData)
                    })
                    .catch(error => {
                        console.log("PUT Error: " + error);
                        return false
                    })
            }
            return true
        }

        return false
    }

    onTextChange = (fieldName, fieldValue, e) => {
        this.setState({ comments: fieldValue });
    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })
    }

    newComment = () => {
        this.setState({
            comments: ' ',
            cmtDate: new Date().toISOString(),
            operationCommentKey: -1
        }) 
        this.toggle()
    }

    onGridRowSelected(s, e) {
        //console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({ selAFELine: dataItem })           
            this.setState({
                comments: dataItem.comments,
                cmtDate: dataItem.commentDate,
                operationCommentKey: dataItem.operationCommentKey
            }) 
        }
    }

    editComment = (key, date, text) => {
      
        this.toggle()
    }

    refreshComment = () => {
        this.getAFEComment()
    }

    getAFEComment = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Comment/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeComment: json,
                    isLoaded: true
                })
            });
    }
    handleChange = (v, f) => {
        this.setState({
            cmtDate: v
        })
    }

    componentDidMount() {
        this.getAFEComment();
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

        }

        switch (s.field) {
            case 'commentDate':

                var date = new Date(item.commentDate);
                link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;

            default:

        }

    }

    render() {
        const { user, afeHeaderKey, afeComment,
            isLoaded, isFormReadOnly,
            operationCommentKey,isAFEManager,isAFEAdmin,
            comments } = this.state

        if (!isLoaded) {
            return (<div>
                Loading Comment ......
            </div>)
        } else
            return (<div>
                <Row form style={{ marginTop: "-15px", marginBottom: "5px" }}>
                    {/* <Col sm={12}> */}
                    {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                    <Button color="primary" outline className="float-left"
                        style={{ height: "35px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.newComment()}
                    >Add</Button>

                    <Button color="primary" outline className="float-left"
                        style={{ height: "35px", marginLeft:"10px" }}
                        hidden={!isAFEManager && !isAFEAdmin}
                        onClick={() => this.toggle()}
                    >Edit</Button>

                </Row>

                <ColoredLine color="grey" />

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={(afeComment.length > 10 ? "300px" : "100%")}
                        width='100%'
                        dataSource={afeComment}
                        //isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}                
                    >

                        <IgrTemplateColumn
                            field="commentDate"
                            headerText="Date"
                            width="170"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTextColumn
                            field="createdBy"
                            headerText="By"
                            width="140"
                        />

                        <IgrTextColumn
                            field="comments"
                            headerText="Comments"
                            width="*>350"
                        />

                        <IgrNumericColumn field="operationCommentKey" isHidden />
                    </IgrDataGrid>

                </FormGroup>


                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                        onClosed={() => this.refreshComment()}
                        //contentClassName="oilTicketDetail-modal-style"
                        size='lg'
                    >
                        <ModalHeader toggle={() => this.toggle()}
                        > New Comments </ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Label for="commentDate" sm={2}>Date</Label>
                                <Col sm={10}>
                                    <DatePicker value={this.state.cmtDate} id="commentDate"
                                        onChange={(v, f) => this.handleChange(v, f)} disabled={true} />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="exampleText" sm={2}>Text Area</Label>
                                <Col sm={10}>
                                    <Input type="textarea"
                                        name="text"
                                        id="exampleText"
                                        rows="6"
                                        defaultValue={comments}
                                        value={comments}
                                        invalid={comments == ""}
                                        onChange={(e) => this.onTextChange("comments", e.target.value, e)} />
                                    <FormFeedback tooltip> Comment can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                        </ModalFooter>
                    </Modal>
                </div>

            </div>)
    }
}