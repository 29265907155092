
import React, { Component } from 'react';

import { Form, Card, CardHeader, CardBody, Col, Row, FormGroup, Label, Input, Button  } from 'reactstrap';
import { apiURL, apiHeader } from '../../resources/apiURL';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import '../../custom.css';

import { getUserADAccount } from '../../resources/utility'
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";

import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import classnames from 'classnames';
import FormRCATimeline from './FormRCATimeline';
import FormRCAInvestigative from './FormRCAInvestigative';
import FormRCAMethods from './FormRCAMethods';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



export default class FormRCA extends Component {
    static displayName = FormRCA.name;


    constructor(props) {
        super(props);
        // this.onGridRef = this.onGridRef.bind(this);
        // this.getAlert=this.getAlert.bind(this);


        // const urlPara = this.getURLParameters(props.location)
        // console.log("loc search")
        // console.log(this.props.location.search)

        this.state = {
            childValue: '',
            data: [],
            hdrData: [],
            isLoaded: false,
            isHdrLoaded: false,
            // facilityInspLineItemForUpdateDTO:[],
            // facilityComments:'',
            // inspComments:'',
            reloadForm: false,
            safIncHeaderKey: 0,
            // safIncLineKey: urlPara.safIncLineKey,
            safIncLineKey: props.safIncLineKey,
            ddEmployees: [],
            activeTab: '1',
            isddEmployeesLoaded: false,
            isReadOnly: props.isReadOnly,


            user: props.user,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            
        }
    }

    // getURLParameters = (inputPara) => {
    //   var paraMeters = {
    //     safIncLineKey: null
    //   }

    //   var query
    //   query = new URLSearchParams(this.props.location.search);
    //   console.log("query")
    //   // console.log(query.get('safIncLineKey'))

    //   paraMeters = {
    //     safIncLineKey: query.get('safIncLineKey')
    //   }
    //   return paraMeters
    // }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    bMultiField = (value, checkValue) => {
        return (value & checkValue) === checkValue
    }


    getInspHdrData = (intKey) => {
        const { isLoaded } = this.state

        // console.log("safIncHeaderKey")
        // console.log(intKey)

        let myURI = apiURL + 'SafetyIncident/SafIncHeaderKey/' + intKey
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    hdrData: json,
                    isHdrLoaded: true
                });
                // console.log(this.state.hdrData);
            });

    }

    getInpsData = () => {
        const { safIncLineKey, isLoaded } = this.state
        let myURI = apiURL + 'SafetyIncident/FormRCAKey/' + safIncLineKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    this.getInspHdrData(json[0].safIncHeaderKey)

                }

                this.setState({
                    data: json,
                    isLoaded: true,


                    //safIncHeaderKey:json[0].safIncHeaderKey
                    // inspComments:json[0].inspComments,
                    // facInspComments:json[0].facInspComments
                }
                );


                // console.log("---------")
                // console.log(this.state.data)

                // this.getInspHdrData(json[0].safIncHeaderKey)


            });
    }


    getddEmployees = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyObservation/SubmitBy'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isddEmployeesLoaded: true,
                    ddEmployees: json
                })
            });
    }





    // onGridRef(grid) {
    //   if (!grid) {
    //     return;
    //   }
    //   this.grid = grid;
    //   this.grid.dataSource = this.state.tblWitness;

    //   //this.grid.cells[0]
    //   console.log(this.grid.cellBackground)

    // }



    componentDidMount() {
        this.props.saveRCA(this.saveData);
        this.getInpsData()
        this.getddEmployees()
        //this.getInspHdrData()

    }


    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.lineStatusKey === 4) {
                content.style.background = "#b6a065";
            } else if (item.lineStatusKey === 6) {
                content.style.background = "#61a066";
            } else if (item.lineStatusKey === 3 || item.lineStatusKey === 5) {
                content.style.background = "#ad5f55";
            } else {
                content.style.background = "transparent";
            }
        }


        switch (s.field) {
            case 'name':
                link.textContent = item.name;
                break;
            case 'position':
                link.textContent = item.position;
                break;


            default:

        }

    }







    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const { data } = this.state;

        // /cbincEquipmentDamage
        //incClassif

        switch (fieldName) {

            case "incDateTime":
                // data[0].incDateTime = moment(fieldValue).format();
                data[0].incDateTime = fieldValue;
                break;

            case "comments":
                data[0].comments = fieldValue
                break;
            case "eventDescription":
                data[0].eventDescription = fieldValue
                break;

            case "findings":
                data[0].findings = fieldValue
                break;

            case "idofRootCause":
                data[0].idofRootCause = fieldValue
                break;

            case "correctiveAction":
                data[0].correctiveAction = fieldValue
                break;


            default:

        }

        this.setState({ data: data })
    }



    createDataForUpdateDTO = () => {
        const { data } = this.state
        const { user } = this.props

        const dataForUpdateDTO = {
            "FormRCAKey": data[0].formRCAKey,
            //"ModifiedByKey": 580, /*4=Submitted, 6=Reviewed*/
            // "StatusKey": data[0].lineStatusKey,
            "ModifiedByAD": getUserADAccount(user.account.userName),
            
            "IncDateTime": data[0].incDateTime ? moment(data[0].incDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
            // "IncDateTime": data[0].incDateTime,
            "Comments": data[0].comments,
            "EventDescription": data[0].eventDescription,
            "Findings": data[0].findings,
            "IdofRootCause": data[0].idofRootCause,
            "CorrectiveAction": data[0].correctiveAction




        }

        return dataForUpdateDTO;
    }


    saveData = () => {
        const itemDTO = this.createDataForUpdateDTO();
        // console.log(itemDTO);
        // alert("spill Report");

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'SafetyIncident/updateSafIncRCA'
        fetch(myURI, putReq)
        .then(async response => {
            if (!response.ok) {
                const rspData = response.text();
                //console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                rspData.then(t => {
                    this.setState({
                        msgBody: t,
                        msgHeader: "Error",
                        modalMsg: !this.state.modalMsg

                    })
                })
                return Promise.reject(error);
            }

                // console.log("No error here: " + rspData)

                this.getInpsData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }


    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }


    render() {

        // var {isLoaded, data, isHdrLoaded, hdrData} = this.state
        var { isLoaded, isHdrLoaded,
            data, hdrData,
            ddEmployees, isddEmployeesLoaded, isReadOnly, activeTab, safIncLineKey, user, msgHeader, msgBody

        } = this.state

        if (!isLoaded || !isHdrLoaded) return <h1> loading... </h1>

        if (!isddEmployeesLoaded) return <h1> loading Employees... </h1>



        //if (!isLoaded) return <h1> loading </h1> 

        return (
            <div>
                {/* <Form>
                <FormGroup>
                  <Row form>
                    <h3> First Report </h3>
                  </Row>
                  <h5>
                  <label>{hdrData[0].safIncID}</label>  <span>&nbsp;&nbsp;</span>
                  <label>| Line: {data[0].sLineNo} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| Status: {data[0].lineStatusDesc} </label>  <span>&nbsp;&nbsp;</span>                    
                    <label>| By: {data[0].createdByAD} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| Created: {new Date(data[0].createdDateTime).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                   
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup>

            </Form> */}
                <Form>
                    {/* <FormGroup row>
            <Col md={2}>
              <h3> Spill Report </h3>
            </Col>
            <Col md={2}>

              <Button color="primary"
                // className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}

              //  hidden={this.setIsReadOnly(statusKey)}
                onClick={() => this.saveData()}
                disabled={isReadOnly}
              >Save</Button>



            </Col>

          </FormGroup> */}
                    {/* <FormGroup row>
                        <Col md={12}>
                            <div>
                                <Label>

                                    ddddd {data[0].statusKey} {user} isReadOnly:<b>{isReadOnly ? "true" : "false"}</b>   isUser:<b>{isUser ? "true" : "false"}</b>   isAdmin:<b>{isAdmin ? "true" : "false"}</b>    isApprover:<b>{isApprover ? "true" : "false"}</b>  isEditApprover:<b>{isEditApprover ? "true" : "false"}</b>  
                                    dddd5 {data[0].formRCAKey}
                                </Label>


                            </div>
                        </Col>

                    </FormGroup> */}
                    <FormGroup row>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label for="incDateTime">Incident Datetime</Label>
                                </Col>
                                <Col md={8}>

                                    {/* <DatePicker id="incDateTime"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("incDateTime", v, f)}
                            value={data[0].incDateTime} /> */}
                                    <Datetime
                                        inputProps={{
                                            placeholder: "MM/DD/YYYY hh:mm",
                                            // disabled: false
                                            disabled: isReadOnly
                                        }}
                                        value={moment(data[0].incDateTime)}
                                        //value={data[0].incDateTime}
                                        onChange={(v, f) => this.onTextChange("incDateTime", v, f)}
                                    />

                                </Col>

                            </Row>
                        </Col>
                        {/* <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="modifiedByAD">Modified By</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="modifiedByAD"
                        id="modifiedByAD" placeholder=""
                        value={data[0].modifiedByAD}
                      
                      />
                    </Col>
                  </Row>
                </Col> */}
                    </FormGroup>

                    <FormGroup row>

                        <Col md={12}>



                            <div>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                            Comments
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggleTab('2'); }}
                                        >
                                            Documents
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { this.toggleTab('3'); }}
                      >
                        Additional Details
                      </NavLink>
                    </NavItem> */}
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col md={12}>
                                                {/* <Label for="youRObserve">WHAT DID YOU OBSERVE?</Label>       */}
                                                <Input
                                                    // invalid={!validateDataItem.hazardDesc}
                                                    type="textarea" name="comments" rows={5}
                                                    id="comments"
                                                    value={data[0].comments}
                                                    disabled={isReadOnly}
                                                    onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                                // defaultValue = {observation[0].hazardDesc}                               
                                                // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                                // disabled={isReadOnly} 

                                                />


                                            </Col>


                                        </Row>
                                    </TabPane>


                                    <TabPane tabId="2">
                                        <Row>
                                            <Col md={12}>
                                                {/* <DocumentBrowser  buttonLabel="View Image" 
                                        buttonColor="primary" 
                                        formName="Safety Observation Images"     
                                        linkTableName={"thsSafetyObservation"}
                                        linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                        apiURL={apiURL + 'SafetyObservation/Documents/' + (safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey) } /> */}
                                                <DocumentBrowserDragDrop buttonLabel="View Image"
                                                    buttonColor="primary"
                                                    formName="RCA Images"
                                                    linkTableName={"thseSafetyIncident_RCA"}
                                                    // linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                                    linkPrimaryKey={safIncLineKey}
                                                    apiURL={apiURL + 'SafetyIncident/Documents/' + safIncLineKey + '/bucket/thseSafetyIncident_RCA'}

                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            <Col md={12}>
                                                details tbd
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>

                            </div>



                        </Col>

                    </FormGroup>

                    <FormGroup row>

                        {/* <Col md={12}> */}
                        {/* 1 row */}

                        <Col md={12}>


                            <Label for="eventDescription">Event Description</Label>
                            <Input type="textarea" name="eventDescription" rows={4}
                                id="descript"
                                value={data[0].eventDescription}
                                disabled={isReadOnly}
                                onChange={(event) => this.onTextChange("eventDescription", event.target.value, event)}
                            />

                            {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                        </Col>




                        {/* </Col> */}


                    </FormGroup>

                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Timeline leading up to the event"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody>
                                    {/* 1 row */}
                                    <Row>
                                        <Col md={12}>
                                            <FormRCATimeline formRCAKey={data[0].formRCAKey}
                                                user={user}
                                                hidden= {isReadOnly}
                                            // key={data[0].formRCAKey}
                                            >

                                            </FormRCATimeline>



                                        </Col>

                                    </Row>
                                    {/* 2 row */}




                                </CardBody>


                            </Card>
                        </Col>

                    </FormGroup>

                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Investigative Team - Methods Used"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody>
                                    {/* 1 row */}
                                    <Row>
                                        <Col md={6}>
                                            <FormRCAInvestigative formRCAKey={data[0].formRCAKey}
                                                user={user}
                                                hidden= {isReadOnly}
                                            // key={data[0].formRCAKey}
                                            >

                                            </FormRCAInvestigative>



                                        </Col>
                                        
                                        

                                        <Col md={6}>
                                            <FormRCAMethods formRCAKey={data[0].formRCAKey}
                                                user={user}
                                                hidden= {isReadOnly}
                                            // key={data[0].formRCAKey}
                                            >

                                            </FormRCAMethods>



                                        </Col>

                                    </Row>
                                    {/* 2 row */}




                                </CardBody>


                            </Card>
                        </Col>

                    </FormGroup>



                  


                    <FormGroup row>

                        {/* <Col md={12}> */}
                        {/* 1 row */}

                        <Col md={12}>


                            <Label for="findings">Findings</Label>
                            <Input type="textarea" name="findings" rows={4}
                                id="findings"
                                value={data[0].findings}
                                disabled={isReadOnly}
                                onChange={(event) => this.onTextChange("findings", event.target.value, event)}
                            />

                            {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                        </Col>




                        {/* </Col> */}


                    </FormGroup>

                    <FormGroup row>

                        {/* <Col md={12}> */}
                        {/* 1 row */}

                        <Col md={12}>


                            <Label for="idofRootCause">Identification of Root Cause</Label>
                            <Input type="textarea" name="idofRootCause" rows={4}
                                id="idofRootCause"
                                value={data[0].idofRootCause}
                                disabled={isReadOnly}
                                onChange={(event) => this.onTextChange("idofRootCause", event.target.value, event)}
                            />

                            {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                        </Col>




                        {/* </Col> */}


                    </FormGroup>

                    <FormGroup row>

                        {/* <Col md={12}> */}
                        {/* 1 row */}

                        <Col md={12}>


                            <Label for="correctiveAction">Corrective Action</Label>
                            <Input type="textarea" name="correctiveAction" rows={4}
                                id="correctiveAction"
                                value={data[0].correctiveAction}
                                disabled={isReadOnly}
                                onChange={(event) => this.onTextChange("correctiveAction", event.target.value, event)}
                            />

                            {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                        </Col>




                        {/* </Col> */}


                    </FormGroup>




                </Form>
                <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>

            </div>

        );



        //       }
        //     }
        //   </FetchData>
        // );
    }
}