//Created By BN 12/21/2021



import React, { Component } from 'react';

import { Form, Col, Row, Button, TabContent, TabPane, NavItem, Nav, NavLink, FormGroup, Label, Input, CustomInput, ListGroupItem, ListInlineItem, InputGroup, Card, CardHeader, CardBody,  Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiGETHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import TrashBin from '../resources/trash.svg'
import { USDCurrencyFormat, NumberFormat } from '../resources/utility'
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from './ConfirmModal'
import ConfirmMeterValModal from './ConfirmMeterValModal'
import MeterValidationItem from './MeterValidationItem';
import DocumentBrowser from '../resources/DocumentBrowser';
import classnames from 'classnames';

import Select from 'react-select';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { JsonDictionaryParser, ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import MeterValidationAction from './MeterValidationAction'
import MeterValidationActionPerformed from './MeterValidationActionPerformed'
import ReviewComment from './ReviewComment'
import { ISpreadsheetDialogFontsService_$type } from 'igniteui-react-spreadsheet';
import InventoryItem from "./InventoryItem"
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class InventoryDetails extends Component {
  static displayName = InventoryDetails.name;

  constructor(props) {
    super(props);
    const urlPara = this.getURLParameters(props.location);
    this.state = {
      activeTab: "1",
      modalMsg: false,
      isInService: false,
      UOM: [],
      data: [],
      test: [],
      facInvCollector: [],
      facility: [],
      isLoaded: false,
      isFacilityLoaded: false,
      isUOMLoaded: false,
      isInvTemplateLoaded: false,
      createdDatetime: new Date().toISOString().substring(0, 10),
      isSecurityAdminLogin: props.isSecurityAdminLogin,
      isFleetSupervisors: props.isFleetSupervisors,
      inventoryDataDetails: props.inventoryData,
      itemTypeKeyDetails: props.itemTypeKey,
      facilityKey: props.facilityKey,
      statusType: props.statusType,
      facilityInvCollectorKey:
        urlPara.facilityInvCollectorKey === null
          ? props.facilityInvCollectorKey
          : urlPara.facilityInvCollectorKey,
      isBasinListLoaded: false,
      boolSwitch: false,
      basinList: [],
      itemType: [],
      isItemTypeLoaded: false,
      invalidDataItem: {
        itemDesc: true,
        qrCodeValue: true,
        facilityKey: true,
        qty: true,
        locationKey: true,
        prop24: true,
      },
      msgBody: "Test",
      msgHeader: "Test",
      returnKey: null,
    };
  }

  getURLParameters = (inputPara) => {
    var paraMeters = {
      facilityInvCollectorKey: null,
      security: null,
    };
    var query;
    if (inputPara !== undefined) {
      query = new URLSearchParams(inputPara.search);

      paraMeters = {
        facilityInvCollectorKey: query.get("key"),
      };
    }

    return paraMeters;
  };

  toggleTab = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

//   saveData = () => {
//     const { inventoryDataDetails, facilityInvCollectorKey } = this.state;
//     if (this.validateData()) {
//       if (inventoryDataDetails.facilityInvCollectorKey < 0) {
//         this.createInventory();
//       } else {
//         this.updateInventory();
//       }
//     }
//   };

  saveData = () => {
    const { inventoryDataDetails } = this.state;

    try {
      this.dataValidation().then((data) => {
        if (data.ok) {
          if (inventoryDataDetails.facilityInvCollectorKey < 0) {
            this.createInventory();
          } else {
            this.updateInventory();
          }
        } else {
          this.setState({
            alertMessage: data.message,
            validateDataItem: data.validateDataItem,
            showAlert: true,
          });
        }
      });
    } catch (error) {}
  };

  getFacInvCollector = () => {
    let myURI =
      apiURL +
      "ItemMaster/FacilityInventoryCollector/" +
      this.props.facilityInvCollectorKey;

    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            isLoaded: true,
            facInvCollector: json,
          }
        );
        if (json.length > 0) {
          this.setState({
            isInService: json[0].inService === 0 ? false : true,
            // isSampleCollected:(json[0].sampleCollected === 0 ? false : true),
            // statusKey:json[0].statusKey,
            // isReadOnly: this.setIsReadOnly(json[0].statusKey)
          });
        }
      });
  };

  getFacility = () => {
    const { user } = this.props;

    let myURI = apiURL + "FacilityDetails/AzureAD/" + user.account.userName;

    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isFacilityLoaded: true,
          facility: this.createFacilityList(json),
        });
      });
  };

  createFacilityList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.facilityKey;
      e.label = e.facilityName;
      tempList.push(e);
    });

    return tempList;
  };

  getBasin = () => {
    const { user } = this.props;

    // let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName
    // fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
    // .then(res => res.json())
    // .then(json=>{
    //     this.setState({
    //         isBasinListLoaded: true,
    //         basinList: this.createBasinList(json)
    //     })
    // })
    let myURI = apiURL + "Fiix/GetBasin";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isBasinListLoaded: true,
          basinList: this.createBasinList(json),
        });
      });
  };

  createBasinList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.locationKey;
      e.label = e.locationName;
      tempList.push(e);
    });

    return tempList;
  };

  createInventory() {
    const inventoryCreateDTO = this.createInventoryCreateDTO();

    let myURI = apiURL + "ItemMaster/FacilityInventoryCollector";
    fetch(myURI, apiPOSTHeader(inventoryCreateDTO))
      .then(async (response) => {
        if (!response.ok) {
          const rspData = response.text();
          var error = (rspData && rspData.message) || response.status;

          rspData.then((t) => {
            this.setState(
              {
                msgBody: t,
                msgHeader: "Error",
                modalMsg: !this.state.modalMsg,
              },
              () => {}
            );
          });

          return Promise.reject(error);
        } else this.closeForm(true);
        return response.json();
      })
      .then((json) => {
        if (json[0].returnKey > 0) {
          this.setState({ returnKey: json[0].returnKey }, () => {});
        }
      })
      .catch((error) => {});

    this.setState({ facilityInvCollectorKey: -1 });
    // this.closeForm(true);
  }

  updateInventory = () => {
    const { requiredKey } = this.state;
    const inventoryUpdateDTO = this.createInventoryUpdateDTO();

    let myURI = apiURL + "ItemMaster/FacilityInventoryCollector";

    fetch(myURI, apiPUTHeader(inventoryUpdateDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          rspData.then((t) => {
            this.setState(
              {
                msgBody: t,
                msgHeader: "Error",
                modalMsg: !this.state.modalMsg,
              },
              () => {}
            );
          });
          return Promise.reject(error);
        } else {
          this.closeForm(true);
        }
      })
      .catch((error) => {});
  };

  deleteData = () => {
    const inventoryData = this.InventoryForDeleteDto();

    let myURI = apiURL + "ItemMaster/FacilityInventoryCollector";

    fetch(myURI, apiDELETEHeader(inventoryData))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          rspData.then((t) => {
            this.setState({
              msgBody: t,
              msgHeader: "Error",
              modalMsg: !this.state.modalMsg,
            });
          });
          return Promise.reject(error);
        } else {
          // Call this.closeForm(true) only when there is no error
          this.closeForm(true);
        }
      })
      .catch((error) => {
        // Error handling
      });
  };

  InventoryForDeleteDto = () => {
    const { inventoryDataDetails } = this.state;
    const { user } = this.props;

    const inventoryForDeleteDTO = {
      facilityInvCollectorKey: inventoryDataDetails.facilityInvCollectorKey,
      deletedBy: getUserADAccount(user.account.userName),
    };
    // console.log(getUserADAccount(user.account.userName))
    return inventoryForDeleteDTO;
  };

  createInventoryCreateDTO = () => {
    const { inventoryDataDetails } = this.state;
    const { user } = this.props;

    const inventoryCreateDTO = {
      itemTypeKey: inventoryDataDetails.itemTypeKey,
      itemMasterKey: inventoryDataDetails.itemMasterKey,
      facilityKey: inventoryDataDetails.facilityKey,
      itemDesc: inventoryDataDetails.itemDesc,
      qty: inventoryDataDetails.qty,
      uomKey: inventoryDataDetails.uomKey,
      qrCodeValue: inventoryDataDetails.qrCodeValue,
      comments: inventoryDataDetails.comments,
      createdby: this.props.user.account.name,
      lat: inventoryDataDetails.lat,
      long: inventoryDataDetails.long,
      offlineLinkKey: inventoryDataDetails.offlineLinkKey,
      inService: inventoryDataDetails.inService,
      prop1: inventoryDataDetails.prop1,
      prop2: inventoryDataDetails.prop2,
      prop3: inventoryDataDetails.prop3,
      prop4: inventoryDataDetails.prop4,
      prop5: inventoryDataDetails.prop5,
      prop6: inventoryDataDetails.prop6,
      prop7: inventoryDataDetails.prop7,
      prop8: inventoryDataDetails.prop8,
      prop9: inventoryDataDetails.prop9,
      prop10: inventoryDataDetails.prop10,
      prop11: inventoryDataDetails.prop11,
      prop12: inventoryDataDetails.prop12,
      prop13: inventoryDataDetails.prop13,
      prop14: inventoryDataDetails.prop14,
      prop15: inventoryDataDetails.prop15,
      prop16: inventoryDataDetails.prop16,
      prop17: inventoryDataDetails.prop17,
      prop18: inventoryDataDetails.prop18,
      prop19: inventoryDataDetails.prop19,
      prop20: inventoryDataDetails.prop20,
      prop21: inventoryDataDetails.prop21,
      prop22: inventoryDataDetails.prop22,
      prop23: inventoryDataDetails.prop23,
      prop24: inventoryDataDetails.prop24,
      prop25: inventoryDataDetails.prop25,
      prop26: inventoryDataDetails.prop26,
      prop27: inventoryDataDetails.prop27,
      prop28: inventoryDataDetails.prop28,
      prop29: inventoryDataDetails.prop29,
      prop30: inventoryDataDetails.prop30,
      prop31: inventoryDataDetails.prop31,
      prop32: inventoryDataDetails.prop32,
      prop33: inventoryDataDetails.prop33,
      prop34: inventoryDataDetails.prop34,
      prop35: inventoryDataDetails.prop35,
      prop36: inventoryDataDetails.prop36,
      prop37: inventoryDataDetails.prop37,
      prop38: inventoryDataDetails.prop38,
      prop39: inventoryDataDetails.prop39,
      prop40: inventoryDataDetails.prop40,
      locationKey: inventoryDataDetails.locationKey,
    };
    // console.log(inventoryCreateDTO)
    return inventoryCreateDTO;
  };

  createInventoryUpdateDTO = () => {
    const { inventoryDataDetails } = this.state;

    const { user } = this.props;


    /*Removed by BN 8/16/24. Causes issue when saving data */
    // console.log(inventoryDataDetails.itemTypeKey)
    // switch (inventoryDataDetails.itemTypeKey) {
    //   case 2849: //Attach
    //     inventoryDataDetails.prop3 = "";
    //     inventoryDataDetails.prop6 = "";
    //     break;
    //   case 2850: //Trailer
    //   case 2832: //SKID Steer
    //   case 2846: //Manlift
    //   case 2847: //Water Truck
    //   case 2848: //Excavator
    //   case 2940: //Long Reach Excavator
    //   case 2941: //Haul Truck
    //   case 2942: //Grader
    //   case 2943: //Dump Truck
    //   case 2944: //Loader
    //   case 2945: //Dozer
    //   case 2946: //Boom Lift
    //   case 2947: //Articulating Lift
    //   case 2949: //ForkLift
    //     inventoryDataDetails.prop6 = "";
    //     break;
    // }

    const inventoryUpdateDTO = {
      facilityInvCollectorKey: inventoryDataDetails.facilityInvCollectorKey,
      itemMasterKey: inventoryDataDetails.itemMasterKey,
      facilityKey: inventoryDataDetails.facilityKey,
      itemDesc: inventoryDataDetails.itemDesc,
      //    itemTypeKey: inventoryDataDetails.itemTypeKey,
      qty: inventoryDataDetails.qty,
      uomKey: inventoryDataDetails.uomKey,
      qrCodeValue: inventoryDataDetails.qrCodeValue,
      comments: inventoryDataDetails.comments,
      modifiedBy: getUserADAccount(user.account.userName),
      lat: 0,
      long: 0,
      inService: inventoryDataDetails.inService,
      prop1: inventoryDataDetails.prop1,
      prop2: inventoryDataDetails.prop2,
      prop3: inventoryDataDetails.prop3,
      prop4: inventoryDataDetails.prop4,
      prop5: inventoryDataDetails.prop5,
      prop6: inventoryDataDetails.prop6,
      prop7: inventoryDataDetails.prop7,
      prop8: inventoryDataDetails.prop8,
      prop9: inventoryDataDetails.prop9,
      prop10: inventoryDataDetails.prop10,
      prop11: inventoryDataDetails.prop11,
      prop12: inventoryDataDetails.prop12,
      prop13: inventoryDataDetails.prop13,
      prop14: inventoryDataDetails.prop14,
      prop15: inventoryDataDetails.prop15,
      prop16: inventoryDataDetails.prop16,
      prop17: inventoryDataDetails.prop17,
      prop18: inventoryDataDetails.prop18,
      prop19: inventoryDataDetails.prop19,
      prop20: inventoryDataDetails.prop20,
      prop21: inventoryDataDetails.prop21,
      prop22: inventoryDataDetails.prop22,
      prop23: inventoryDataDetails.prop23,
      prop24: inventoryDataDetails.prop24,
      prop25: inventoryDataDetails.prop25,
      prop26: inventoryDataDetails.prop26,
      prop27: inventoryDataDetails.prop27,
      prop28: inventoryDataDetails.prop28,
      prop29: inventoryDataDetails.prop29,
      prop30: inventoryDataDetails.prop30,
      prop31: inventoryDataDetails.prop31,
      prop32: inventoryDataDetails.prop32,
      prop33: inventoryDataDetails.prop33,
      prop34: inventoryDataDetails.prop34,
      prop35: inventoryDataDetails.prop35,
      prop36: inventoryDataDetails.prop36,
      prop37: inventoryDataDetails.prop37,
      prop38: inventoryDataDetails.prop38,
      prop39: inventoryDataDetails.prop39,
      prop40: inventoryDataDetails.prop40,
      locationKey: inventoryDataDetails.locationKey,
    };
    // console.log(inventoryUpdateDTO)

    return inventoryUpdateDTO;
  };

  getItemType = () => {
    let myURI = apiURL + "ItemMaster/ItemType";

    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          itemType: json,
          isItemTypeLoaded: true,
        });
      });
  };

  getUOM = () => {
    let myURI = apiURL + "ItemMaster/ItemUOM";
    // /' + user.account.userName

    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isUOMLoaded: true,
          UOM: json,
        });
      });
  };

  getInvTemplate = () => {
    let myURI = apiURL + "ItemMaster/ItemPropertyMap/" + this.props.itemTypeKey;
    // /' + user.account.userName

    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isInvTemplateLoaded: true,
          data: json,
        });
        // console.log("ItemPropertyMap: " + JSON.stringify(this.state.data, null, 2));
      });
  };

  onTextChangeTest = (fieldName, fieldValue, e) => {
    const { inventoryDataDetails, invalidDataItem, itemTypeKeyDetails } =
      this.state;

    // if(inventoryDataDetails.facilityInvCollectorKey === key){
    switch (fieldName) {
      case "prop1":
        inventoryDataDetails.prop1 = fieldValue;
        break;

      case "prop2":
        inventoryDataDetails.prop2 = fieldValue;

        break;

      case "prop3":
        inventoryDataDetails.prop3 = fieldValue;

        break;

      case "prop4":
        inventoryDataDetails.prop4 = fieldValue;

        break;

      case "prop5":
        inventoryDataDetails.prop5 = fieldValue;

        break;

      case "prop6":
        inventoryDataDetails.prop6 = fieldValue;

        break;

      case "prop7":
        inventoryDataDetails.prop7 = fieldValue;

        break;

      case "prop8":
        inventoryDataDetails.prop8 = fieldValue;

        break;

      case "prop9":
        inventoryDataDetails.prop9 = fieldValue;

        break;

      case "prop10":
        inventoryDataDetails.prop10 = fieldValue;

        break;

      case "prop11":
        inventoryDataDetails.prop11 = fieldValue;

        break;

      case "prop12":
        inventoryDataDetails.prop12 = fieldValue;

        break;

      case "prop13":
        inventoryDataDetails.prop13 = fieldValue;

        break;

      case "prop14":
        inventoryDataDetails.prop14 = fieldValue;

        break;

      case "prop15":
        inventoryDataDetails.prop15 = fieldValue;

        break;

      case "prop16":
        inventoryDataDetails.prop16 = fieldValue;

        break;

      case "prop17":
        inventoryDataDetails.prop17 = fieldValue;

        break;

      case "prop18":
        inventoryDataDetails.prop18 = fieldValue;

        if (
          inventoryDataDetails.prop18 == "Returning" &&
          itemTypeKeyDetails == 1171
        ) {
          this.setState({
            msgBody:
              "If status is set to 'Out of Fleet', 'Disposed/Sold Date' field will be required to enter.",
            msgHeader: "Warning",
            modalMsg: !this.state.modalMsg,
          });
        }

        break;

      case "prop19":
        inventoryDataDetails.prop19 = fieldValue;

        break;

      case "prop20":
        inventoryDataDetails.prop20 = fieldValue;

        break;

      case "prop21":
        inventoryDataDetails.prop21 = fieldValue;

        break;

      case "prop22":
        inventoryDataDetails.prop22 = fieldValue;

        break;

      case "prop23":
        inventoryDataDetails.prop23 = fieldValue;

        break;

      case "prop24":
        inventoryDataDetails.prop24 = fieldValue;
        invalidDataItem.prop24 = fieldValue != "";
        break;

      case "prop25":
        inventoryDataDetails.prop25 = fieldValue;
        break;

      case "prop26":
        inventoryDataDetails.prop26 = fieldValue;
        break;

      case "prop27":
        inventoryDataDetails.prop27 = fieldValue;
        break;

      case "prop28":
        inventoryDataDetails.prop28 = fieldValue;
        break;

      case "prop29":
        inventoryDataDetails.prop29 = fieldValue;
        break;

      case "prop30":
        inventoryDataDetails.prop30 = fieldValue;
        break;

      case "prop31":
        inventoryDataDetails.prop31 = fieldValue;
        break;

      case "prop32":
        inventoryDataDetails.prop32 = fieldValue;
        break;

      case "prop33":
        inventoryDataDetails.prop33 = fieldValue;
        break;

      case "prop34":
        inventoryDataDetails.prop34 = fieldValue;
        break;

      case "prop35":
        inventoryDataDetails.prop35 = fieldValue;
        break;

      case "prop36":
        inventoryDataDetails.prop36 = fieldValue;
        break;

      case "prop37":
        inventoryDataDetails.prop37 = fieldValue;
        break;

      case "prop38":
        inventoryDataDetails.prop38 = fieldValue;
        break;

      case "prop39":
        inventoryDataDetails.prop39 = fieldValue;
        break;

      case "prop40":
        inventoryDataDetails.prop40 = fieldValue;
        break;

      default:
    }
    // }
    this.setState({
      inventoryDataDetails: inventoryDataDetails,
      invalidDataItem: invalidDataItem,
    });
  };

  componentDidMount() {
    this.getFacInvCollector();
    this.getUOM();
    this.getInvTemplate();
    this.getFacility();
    this.getBasin();
    this.getItemType();
  }

  validateData = () => {
    const { inventoryDataDetails, invalidDataItem, itemTypeKeyDetails } =
      this.state;
    var bVal = true;
    var valItem = invalidDataItem;

    if (inventoryDataDetails.itemDesc == "") {
      bVal = false;
      valItem.itemDesc = false;
    }

    if (inventoryDataDetails.qrCodeValue == "") {
      bVal = false;
      valItem.qrCodeValue = false;
    }

    if (
      inventoryDataDetails.facilityKey == 0 &&
      inventoryDataDetails.locationKey == 0
    ) {
      bVal = false;
      valItem.facilityKey = false;
      valItem.locationKey = false;
    }

    if (
      inventoryDataDetails.facilityKey == null &&
      inventoryDataDetails.locationKey == null
    ) {
      bVal = false;
      valItem.facilityKey = false;
      valItem.locationKey = false;
    }

    if (inventoryDataDetails.qty == 0) {
      bVal = false;
      valItem.qty = false;
    }
    if (
      itemTypeKeyDetails == 1171 &&
      inventoryDataDetails.prop18 == "Out of Fleet" &&
      (inventoryDataDetails.prop24 == "" ||
        inventoryDataDetails.prop24 == null)
    ) {
      bVal = false;
      valItem.prop24 = false;
    }

    this.setState({ invalidDataItem: valItem });
    return bVal;
  };

  dataValidation = () => {
    return new Promise((resolve, reject) => {
      const { inventoryDataDetails, invalidDataItem, itemTypeKeyDetails } =
        this.state;
      const returnVal = {
        ok: true,
        message: "",
        validationDataItem: [],
      };

      if (inventoryDataDetails.itemDesc == "") {
        returnVal.ok = false;
        invalidDataItem.itemDesc = false;
      }

      if (inventoryDataDetails.qrCodeValue == "") {
        returnVal.ok = false;
        invalidDataItem.qrCodeValue = false;
      }

      if (
        inventoryDataDetails.facilityKey == 0 &&
        inventoryDataDetails.locationKey == 0
      ) {
        returnVal.ok = false;
        invalidDataItem.facilityKey = false;
        invalidDataItem.locationKey = false;
      }

      if (
        inventoryDataDetails.facilityKey == null &&
        inventoryDataDetails.locationKey == null
      ) {
        returnVal.ok = false;
        invalidDataItem.facilityKey = false;
        invalidDataItem.locationKey = false;
      }

      if (inventoryDataDetails.qty == 0) {
        returnVal.ok = false;
        invalidDataItem.qty = false;
      }
      
      if (itemTypeKeyDetails == 1171 &&inventoryDataDetails.prop18 == "Out of Fleet" &&(inventoryDataDetails.prop24 == "" || inventoryDataDetails.prop24 == null)
      ) {
        returnVal.ok = false;
        invalidDataItem.prop24 = false;
      }

      resolve(returnVal);
    });
  };

  onTextChange = (fieldName, fieldValue, e) => {
    const { inventoryDataDetails, invalidDataItem } = this.state;
    // console.log(fieldName + '=' + fieldValue)

    switch (fieldName) {
      case "itemDesc":
        inventoryDataDetails.itemDesc = fieldValue;
        invalidDataItem.itemDesc = fieldValue != "";
        break;
      case "qrCodeValue":
        inventoryDataDetails.qrCodeValue = fieldValue;
        invalidDataItem.qrCodeValue = fieldValue != "";
        break;
      case "facilityName":
        inventoryDataDetails.facilityName = fieldValue;
        // invalidDataItem.facilityName=(fieldValue != "")
        break;
      // case "facilityKey":
      //     inventoryDataDetails.facilityKey=fieldValue
      //     inventoryDataDetails.locationKey=fieldValue.locationKey
      //     console.log(fieldValue)
      //     invalidDataItem.facilityKey=true
      //     break;

      case "facilityKey":
        inventoryDataDetails.facilityKey = e === null ? "" : e.facilityKey;
        inventoryDataDetails.locationKey = e === null ? "" : e.locationKey;

        if (e === null) {
          this.setState({ facilityKey: null });
        } else {
          this.setState({ facilityKey: e.facilityKey });
        }
        // inventoryDataDetails.locationKey=fieldValue.locationKey
        // console.log(fieldValue)
        invalidDataItem.locationKey = true;
        invalidDataItem.facilityKey = true;
        break;

      case "locationKey":
        inventoryDataDetails.locationKey = e === null ? "" : e.locationKey;
        // console.log(e.locationKey)
        invalidDataItem.locationKey = true;
        invalidDataItem.facilityKey = true;
        break;
      case "inService":
        inventoryDataDetails.inService = fieldValue;
        this.setState({ isInService: fieldValue != 0 });
        break;
      case "uomKey":
        inventoryDataDetails.uomKey = fieldValue;
        break;
      case "itemTypeDesc":
        inventoryDataDetails.itemTypeDesc = fieldValue;
        break;

      case "qty":
        inventoryDataDetails.qty = fieldValue;
        invalidDataItem.qty = fieldValue != 0;
        break;

      case "createdDatetime":
        inventoryDataDetails.createdDatetime = fieldValue;
        this.setState({ createdDatetime: fieldValue });
        break;

      default:
    }

    this.setState({
      inventoryDataDetails: inventoryDataDetails,
      invalidDataItem: invalidDataItem,
    });
  };

  handlehireDateChange = (v, f) => {
    this.setState({
      createdDatetime: v,
    });
  };

  // closeForm = (refreshData) => {
  //     if (this.props.currentView === 'fleet')
  //     {
  //         if (refreshData){
  //             this.props.refreshDataFilter();
  //             console.log("refresh hit")
  //             console.log(this.props.currentView)
  //             console.log(this.state.statusType)
  //         }
  //     }
  //     else{
  //         if (refreshData){
  //             this.props.refreshDataFilter();
  //         }
  //     }

  //     this.props.toggle()

  // }

  closeForm = (refreshData) => {
    if (refreshData) {
      this.props.refreshDataFilter();
    }
    this.props.toggle();
  };

  toggleMsg = () => {
    this.setState({ modalMsg: !this.state.modalMsg });
  };

  getSelFacility = (v) => {
    const { facility } = this.state;
    //     if (v !== null && v !== "") {
    //         return facility.filter(e => e.facilityKey === v)
    //     }
    //     return ""

    // const { basinList } = this.state;
    if (v !== null && v !== "") {
      // Check if any basin matches by locationKey
      const filteredByKey = facility.filter((e) => e.facilityKey === v);
      if (filteredByKey.length > 0) {
        return filteredByKey;
      }

      // If no match by locationKey, check by locationID
      return facility.filter((e) => e.facilityName === v);
    }
    return []; // Return an empty array for consistency
  };

  getSelBasin = (v) => {
    const { basinList } = this.state;
    if (v !== null && v !== "") {
      // Check if any basin matches by locationKey
      const filteredByKey = basinList.filter((e) => e.locationKey === v);
      if (filteredByKey.length > 0) {
        return filteredByKey;
      }

      // If no match by locationKey, check by locationID
      return basinList.filter((e) => e.locationID === v);
    }
    return []; // Return an empty array for consistency
  };

  getItemTypeDesc = (itemTypeKey) => {
    const { itemType, inventoryDataDetails } = this.state;

    if (itemTypeKey != null || itemTypeKey != "") {
      const itemTypeData = itemType.find(
        (a) => a.itemTypeKey === Number(itemTypeKey)
      );

      return itemTypeData.itemTypeDesc;
    }
  };

  render() {
    const {
      inventoryDataDetails,
      facilityInvCollectorKey,
      UOM,
      facility,
      isFacilityLoaded,
      isLoaded,
      isUOMLoaded,
      data,
      facInvCollector,
      isInvTemplateLoaded,
      itemTypeKeyDetails,
      invalidDataItem,
      currentView,
      msgHeader,
      msgBody,
      activeTab,
      isSecurityAdminLogin,
      isBasinListLoaded,
      basinList,
      boolSwitch,
      facilityKey,
      isFleetSupervisors,
      isItemTypeLoaded,
    } = this.state;
    var { isInService } = this.state;
    const { user } = this.props;
    if (
      !isLoaded ||
      !isUOMLoaded ||
      !isFacilityLoaded ||
      !isInvTemplateLoaded ||
      !isBasinListLoaded ||
      !isItemTypeLoaded
    ) {
      return <div>Data is loading...</div>;
    } else
      return (
        <div>
          <Form Group>
            <Row Form>
              <Col md={6}>
                <FormGroup>
                  <Label for="itemDesc">Item Name</Label>
                  <Input
                    type="text"
                    name="itemDesc"
                    id="itemDesc"
                    invalid={!invalidDataItem.itemDesc}
                    defaultValue={inventoryDataDetails.itemDesc}
                    value={inventoryDataDetails.itemDesc}
                    onChange={(e) =>
                      this.onTextChange("itemDesc", e.target.value, e)
                    }
                    disabled={
                      this.props.currentView === "fleet" && isFleetSupervisors
                    }
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="qrCodeValue">QR Code</Label>
                  <Input
                    type="text"
                    name="qrCodeValue"
                    invalid={!invalidDataItem.qrCodeValue}
                    id="qrCodeValue"
                    defaultValue={inventoryDataDetails.qrCodeValue}
                    value={inventoryDataDetails.qrCodeValue}
                    onChange={(e) =>
                      this.onTextChange("qrCodeValue", e.target.value, e)
                    }
                    disabled={
                      this.props.currentView === "fleet" && isFleetSupervisors
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <Form Group>
            <Row Form>
              <Col md={6} className={"alway-on-top-more"}>
                <FormGroup>
                  <Label for="facilityKey">Facility Name</Label>
                  {/* <CustomInput type="select" name="facilityKey" 
                                    id="facilityKey" 
                                    invalid={!invalidDataItem.facilityKey}
                                    defaultValue={inventoryDataDetails.facilityKey}
                                    value={inventoryDataDetails.facilityKey}
                                    onChange={(e) => this.onTextChange("facilityKey",e.target.value,e)}
                                   >
                                     <option value={-100}></option>    
                                    {facility.map(v => <option value={v.facilityKey}>{v.facilityName}</option>)}
                                    </CustomInput> */}

                  <Select
                    options={facility}
                    className={
                      !invalidDataItem.facilityKey
                        ? "alway-on-top-more form-error"
                        : ""
                    }
                    value={
                      inventoryDataDetails.facilityKey === undefined
                        ? this.getSelFacility(inventoryDataDetails.facilityName)
                        : this.getSelFacility(inventoryDataDetails.facilityKey)
                    }
                    onChange={(e) => this.onTextChange("facilityKey", e, e)}
                    isClearable={true}
                    isDisabled={
                      this.props.currentView === "fleet" && isFleetSupervisors
                    }
                  ></Select>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="itemTypeDesc">Item Type</Label>
                  <Input
                    type="text"
                    name="itemTypeDesc"
                    id="itemTypeDesc"
                    defaultValue={
                      inventoryDataDetails.itemTypeDesc == null
                        ? this.getItemTypeDesc(inventoryDataDetails.itemTypeKey)
                        : inventoryDataDetails.itemTypeDesc
                    }
                    // value={console.log(inventoryDataDetails)}
                    onChange={(e) =>
                      this.onTextChange("itemTypeDesc", e.target.value, e)
                    }
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <Form Group>
            <Row Form>
              {/* <Col md={6}>
                      <FormGroup>
                                    <Row form>
                                     <Col md={4}>
                                        <Label></Label>
                                    </Col>
                                    </Row>

                                     <Row form>
                                     <Col md={4}>
                                        <Label>In Service?</Label>
                                    </Col>
                                    <Col md={4}>
                                        <CustomInput type="checkbox" 
                                        id="ableToValidateYes" 
                                        label="Yes"
                                        checked = {isInService} 
                                        inline 
                                       
                                        onChange = {(e) => this.onTextChange("inService",1,e)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <CustomInput type="checkbox"
                                        id="ableToValidateNo" 
                                        label="No" inline
                                        checked = {!isInService}  
                                        onChange = {(e) => this.onTextChange("inService",0,e)}
                                        
                                        />
                                    </Col>
                                </Row>

                         </FormGroup>
                    </Col> */}

              <Col md={6} className={"alway-on-top"}>
                <FormGroup>
                  <Label for="locationKey">Basin Name</Label>
                  {/* <CustomInput type="select" name="locationKey" 
                                    id="locationKey" 
                                    // invalid={!invalidDataItem.locationKey}
                                    defaultValue={inventoryDataDetails.locationKey}
                                    value={inventoryDataDetails.locationKey}
                                    onChange={(e) => this.onTextChange("locationKey",e.target.value,e)}
                                   >
                                     <option value={-100}></option>    
                                    {basinList.map(v => <option value={v.locationKey}>{v.locationName}</option>)}
                                    </CustomInput> */}
                  <Select
                    options={basinList}
                    className={
                      !invalidDataItem.locationKey
                        ? "alway-on-top-more form-error"
                        : ""
                    }
                    value={
                      inventoryDataDetails.locationKey === undefined
                        ? this.getSelBasin(inventoryDataDetails.locationID)
                        : this.getSelBasin(inventoryDataDetails.locationKey)
                    }
                    onChange={(e) => this.onTextChange("locationKey", e, e)}
                    isDisabled={
                      this.props.currentView === "fleet" &&
                      isFleetSupervisors === true
                        ? true
                        : facilityKey !== null && facilityKey !== undefined
                        ? true
                        : false
                    }
                    // hidden={console.log("locationKey: " + inventoryDataDetails.locationKey)}
                    // hidden={console.log("facilityKey: " + facilityKey)}
                    //isDisabled={false}
                    // placeholder={""}
                    isClearable={true}
                  ></Select>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="createdby">Created By</Label>
                  <Input
                    type="text"
                    name="createdby"
                    id="createdby"
                    defaultValue={inventoryDataDetails.createdby}
                    // value = {ge}
                    onChange={(e) =>
                      this.onTextChange("createdby", e.target.value, e)
                    }
                    disabled
                    // hidden = {inventoryDataDetails.facilityInvCollectorKey < 0 ? true : false}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <Form Group>
            <Row Form>
              {/* <Col md={3}>
                      <FormGroup>
                            <Label for="qty">Qty</Label>
                            <Input type="number" name="qty"
                                    id="qty" 
                                    invalid={!invalidDataItem.qty}
                                    defaultValue={inventoryDataDetails.qty}
                                    value={inventoryDataDetails.qty}
                                    onChange={(e) => this.onTextChange("qty",e.target.value,e)}
                                    />
                         </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                            <Label for="uomKey">UOM</Label>
                            <CustomInput  type="select" name="uomKey" id="uomKey" 
                            // invalid={!invalidDataItem.compObjectKey}
                            defaultValue={inventoryDataDetails.uomKey}
                            value={inventoryDataDetails.uomKey}
                            onChange={(e) => this.onTextChange("uomKey",e.target.value,e)}
                            > 
                             <option value={null}></option> 
                            {UOM.map(v => <option value={v.uomKey}>{v.uomDesc}</option>)} 
                            </CustomInput>
                         </FormGroup>
                    </Col> */}

              <Col md={6}>
                <FormGroup>
                  <Row form>
                    <Col md={4}>
                      <Label></Label>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={4}>
                      <Label>In Service?</Label>
                    </Col>
                    <Col md={4}>
                      <CustomInput
                        type="checkbox"
                        id="ableToValidateYes"
                        label="Yes"
                        checked={isInService}
                        inline
                        disabled={
                          this.props.currentView === "fleet" &&
                          isFleetSupervisors
                        }
                        onChange={(e) => this.onTextChange("inService", 1, e)}
                      />
                    </Col>
                    <Col md={4}>
                      <CustomInput
                        type="checkbox"
                        id="ableToValidateNo"
                        label="No"
                        inline
                        checked={!isInService}
                        disabled={
                          this.props.currentView === "fleet" &&
                          isFleetSupervisors
                        }
                        onChange={(e) => this.onTextChange("inService", 0, e)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="createdDatetime">Created Date</Label>
                  <DatePicker
                    id="createdDatetime"
                    // defaultValue={inventoryDataDetails.createdDatetime}
                    value={
                      facilityInvCollectorKey < 0
                        ? new Date().toISOString()
                        : inventoryDataDetails.createdDatetime
                    }
                    onChange={(v, f) =>
                      this.onTextChange("createdDatetime", v, f)
                    }
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <Form Group>
            <Row Form>
              <Col md={6}>
                <FormGroup>
                  <Label for="qty">Qty</Label>
                  <Input
                    type="number"
                    name="qty"
                    id="qty"
                    invalid={!invalidDataItem.qty}
                    defaultValue={inventoryDataDetails.qty}
                    value={inventoryDataDetails.qty}
                    onChange={(e) =>
                      this.onTextChange("qty", e.target.value, e)
                    }
                    disabled={
                      this.props.currentView === "fleet" && isFleetSupervisors
                    }
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="uomKey">UOM</Label>
                  <CustomInput
                    type="select"
                    name="uomKey"
                    id="uomKey"
                    // invalid={!invalidDataItem.compObjectKey}
                    defaultValue={inventoryDataDetails.uomKey}
                    value={inventoryDataDetails.uomKey}
                    onChange={(e) =>
                      this.onTextChange("uomKey", e.target.value, e)
                    }
                    disabled={
                      this.props.currentView === "fleet" && isFleetSupervisors
                    }
                  >
                    <option value={null}></option>
                    {UOM.map((v) => (
                      <option value={v.uomKey}>{v.uomDesc}</option>
                    ))}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <FormGroup>
            <Row>
              <Col md={12}>
                <InventoryItem
                  data={data}
                  facInvCollector={facInvCollector}
                  // onHandleInvTempValueChange={this.onHandleInvTempValueChange}
                  invalidDataItem={!invalidDataItem.prop24}
                  onTextChangeTest={this.onTextChangeTest}
                  inventoryDataDetails={inventoryDataDetails}
                  // itemTypeKeyDetails={itemTypeKeyDetails}
                  facilityInvCollectorKey={facilityInvCollectorKey}
                  isFleetSupervisors={
                    this.props.currentView === "fleet" && isFleetSupervisors
                  }
                />

                <Col md={6}></Col>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row>
            <Col md={8}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col md={12}>
                      <DocumentBrowserDragDrop
                        buttonLabel="View Image"
                        buttonColor="primary"
                        formName="Facility Images"
                        linkTableName={"timFacilityInvCollector"}
                        linkPrimaryKey={facilityInvCollectorKey}
                        apiURL={
                          apiURL +
                          "ItemMaster/FacilityInventoryCollector/Documents/" +
                          facilityInvCollectorKey
                        }
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </FormGroup>

          <ColoredLine color="grey" />

          <FormGroup>
            <Row></Row>
            <Row>
              <Col sm={12} style={{ margin: "10px 0 0 0" }}>
                <Button
                  color="warning"
                  className="float-right"
                  onClick={() => this.props.toggle()}
                >
                  Close
                </Button>

                <Button
                  color="primary"
                  className="float-right"
                  style={{ margin: "0px 10px 0px 0px" }}
                  disabled={
                    this.props.currentView === "fleet" && isFleetSupervisors
                  }
                  //  hidden={this.setIsReadOnly(statusKey)}
                  onClick={() => this.saveData()}
                >
                  Save
                </Button>

                <ConfirmModal
                  outline={true}
                  color="danger"
                  buttonLabel="Delete"
                  hidden={isSecurityAdminLogin}
                  formName="Confirm"
                  //   disabled={currentView === "fleet" && isFleetSupervisors}
                  disabled={
                    this.props.currentView === "fleet" && isFleetSupervisors
                  }
                  //   disabled={console.log("currentView: " + this.props.currentView)}
                  message="Are you sure you want to delete selected record?"
                  onClickYes={(e) => this.deleteData()}
                  image={
                    <img
                      src={TrashBin}
                      alt="Edit"
                      style={{ width: "20px", margin: "0 5px 0 0" }}
                    />
                  }
                ></ConfirmModal>
              </Col>
            </Row>
          </FormGroup>

          <div>
            <Modal
              isOpen={this.state.modalMsg}
              toggle={() => this.toggleMsg()}
              size="lg"
            >
              <ModalHeader toggle={() => this.toggleMsg()}>
                {msgHeader}
              </ModalHeader>
              <ModalBody>{msgBody}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.toggleMsg()}>
                  OK
                </Button>{" "}
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
  }
}

