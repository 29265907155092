//Created By Brandon Nguyen 11/20/21





import React, { Component } from 'react';

import { Form, Col, Row, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input, CustomInput, ListGroupItem, ListInlineItem, InputGroup, Card, CardHeader, CardBody,  Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiGETHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import TrashBin from '../resources/trash.svg'
import { USDCurrencyFormat, NumberFormat } from '../resources/utility'
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from './ConfirmModal'
import MeterValidationItem from './MeterValidationItem';
import DocumentBrowser from '../resources/DocumentBrowser';


import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class MeterValidationAction extends Component {

    static displayName = MeterValidationAction.name;

    constructor(props){
        super(props)
        const urlPara=this.getURLParameters(props.location)

        this.state={
            // windowHeight:window.innerHeight,
            // windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
            modalComment:false,
            childValue:'',
            actionType1:1,
            actionType2:2,
            data:[],  
            performAction:[],
            requireAction:[],
            meterValAction:[],
            meterValidationAction:[],
            meterValDetails: props.meterValidation,
            isValTypeLoaded:false,            
            isValActionLoaded:false,
            valResult:['','Pass','Failed'],
            meterVal:[],
            meterValidation:null,
            meterValTypeDesc:[],
            validationDate:null,
            // isAbleToValidate: null,
            isMeterValLoaded:false,
            isReadOnly:false,
            meterValidationActionKey:-1,
            statusKey:0,
            isDataLoaded:false,
            // meterValidationActionKey:urlPara.meterValidationActionKey,
            // meterValidationActionKey:(urlPara.meterValidationActionKey === null ? props.meterValidationActionKey:urlPara.meterValidationActionKey),
            meterValidationKey:urlPara.meterValidationKey,
            meterValidationKey:(urlPara.meterValidationKey === null ? props.meterValidationKey:urlPara.meterValidationKey),
            hideAssociateDetails:((typeof(props.HideAssociateDetails) ==='undefined' || props.HideAssociateDetails===null) ? false : props.HideAssociateDetails)
        }
    }

    
    closeForm = (refreshData) => {
        if(refreshData) {        
            this.props.refreshData()
        }

         this.props.toggle()
     }

    getURLParameters = (inputPara) => {

        var paraMeters={
            meterValidationKey:null}
        var query
        if (inputPara !== undefined){
            query = new URLSearchParams(inputPara.search);
            console.log("query")
            console.log(query.get('key') )
    
            paraMeters={
                meterValidationKey:query.get('key') 
            }

            console.log("props.user")
            console.log(this.props.user)
        }
      
        return paraMeters

    }

    getMeterValAction=()=>{
        let myURI = apiURL + 'MeterValidation/MeterValAction/' + this.props.meterValidationKey
        // /' + user.account.userName
        console.log(myURI)
        const { meterValidationKey} = this.state 
        console.log("meterValidationKey for  meterValAction: " + meterValidationKey)
        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                meterValAction: json
            })
        })

        }

        getPerformAction=()=>{
            let myURI = apiURL + 'MeterValidation/PerformAction' 
            // /' + user.account.userName
    
            fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                this.setState({
                    isLoaded: true,
                    performAction: json
                })
            })
    
            } 

            getRequireAction=()=>{
                let myURI = apiURL + 'MeterValidation/Action' 
                // /' + user.account.userName
        
                fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
                .then(res => res.json())
                .then(json=>{
                    this.setState({
                        isLoaded: true,
                        requireAction: json
                    })
                })
        
                } 

                getMeterValAction=()=>{
                    let myURI = apiURL + 'MeterValidation/MeterValAction/' + this.props.meterValidationKey
                    // /' + user.account.userName
                    console.log(myURI)
                    const { meterValidationKey} = this.state 
                    console.log("meterValidationKey for  meterValAction: " + meterValidationKey)
                    fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
                    .then(res => res.json())
                    .then(json=>{
                        this.setState({
                            isLoaded: true,
                            meterValAction: json
                        })
                    })
            
                    }

                    getMeterValidation = ()=> {
                        const { meterValidationKey} = this.state 
                        console.log("meterValidationKey: " + meterValidationKey)
                        // console.log("Save group in group details")
                        // const userUpdatemeterValDTO= this.createMeterValUpdateDTO()
                        // console.log(userUpdatemeterValDTO)
                        if(meterValidationKey>0){
                            
                          let myURI = apiURL + 'MeterValidation/' + this.props.meterValidationKey
                
                            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                            .then(res => res.json())
                            .then(json => {
                
                                this.setState({
                                    isValTypeLoaded: true,
                                    meterVal: json
                                })
                
                                if (json.length>0){
                                    this.setState({
                                        isAbleToValidate:(json[0].ableToValidate === 0 ? false : true ),
                                        isSampleCollected:(json[0].sampleCollected === 0 ? false : true),
                                        // submittedDatetime: new Date(json[0].submittedDatetime).toISOString(),
                                        statusKey:json[0].statusKey,
                                        // isReadOnly: this.setIsReadOnly(json[0].statusKey)
                                    })
                                }
                            });
                            
                        }
                        
                    }


                    createMeterValAction(){
                        const {requiredKey} = this.state 
                        
                        // console.log("Create user in user details")
                        const meterValActionCreateDTO= this.createMeterValActionCreateDTO()
                        // console.log(userCreateDTO)
                       console.log(meterValActionCreateDTO)
                
                          let myURI = apiURL + 'MeterValidation/MeterValAction'
                          fetch(myURI , apiPOSTHeader(meterValActionCreateDTO) )
                            .then(async response => {
                              const rspData = response.text();
                              if (!response.ok){
                                // console.log("Error return but you dont see")
                                const error = (rspData && rspData.message) || response.status;
                                return Promise.reject(error);
                                
                              }
                              
                            //   this.refreshAction()
                              this.closeForm(true)
                              
                              console.log("No error here: " + rspData)
                            })
                            .catch(error =>{
                              console.log("POST Error: " + error);
                            })
                        }
                
                     
                
                      
                        
                     createMeterValActionCreateDTO=()=>{
                        const {meterValidationAction, meterValidationKey} = this.state
                        const {meterValDetails} = this.state
                        const meterValActionCreateDTO={
                            "meterValidationKey": meterValidationKey,
                            "actionType": meterValidationAction.actionType,
                            "actionKey": meterValidationAction.actionKey,
                            "comments": "",
                            "createdBy": "test"
                        }
                        console.log(meterValidationKey)
                        return  meterValActionCreateDTO
                    }
                

                componentDidMount(){
                    this.getMeterValidation()
                    // this.getType()
                    // this.getAction()
                    // this.getLineDetails()
                    this.getPerformAction()
                    this.getRequireAction()
                    this.getMeterValAction()
                    // this.refreshAction()
                    
                    
                  
                }

                onGridRowSelected(s, e) {
                    console.log(" On CLick")
                    //const content = e.content;
                    if (e.addedKeys.count > 0) {
                        let clickedRow = e.addedKeys.item(0) 
                        let dataItem = clickedRow.value[0] 
                          
                        console.log(dataItem.meterValidationActionKey)
            
                        console.log(dataItem)
                        
                        
                        this.setState({meterValidationKey: dataItem.meterValidationKey,
                                        meterValidationActionKey: dataItem.meterValidationActionKey,
                                        meterValidationAction: dataItem})       
                 
                    } 
                }

                refreshAction = () => {
                    // const {showAllFacility} = this.state
                    this.getMeterValAction()
                }
            
            
            


                onTextChange=(fieldName,fieldValue,e)=>{
                    console.log("props")
                    console.log(fieldName + '=' + fieldValue)
                    
                    const {meterValDetails, meterValidationAction, invalidDataItem}=this.state;
                    // var {requiredKey = 1, performedKey = 2, type1, type2} = this.state
                    // if (fieldName==="ticketNo"){
                        
                    // }   
                    var{actionType1, actionType2}= this.state
            
                    
                    switch(fieldName){                    
                            case "requiredKey":
                                meterValidationAction.actionKey = fieldValue                
                                    meterValidationAction.actionType =1
                                
                                    break;
                            
                            case "performedKey":
                                meterValidationAction.actionKey = fieldValue
                               
                                    meterValidationAction.actionType=2
                                    break;
                               
                            case "meterValidationKey":
                                meterValDetails.meterValidationKey=fieldValue
                                break;
                            
                            //     case "meterValidationKey":
                            //    meterValidationAction.meterValidationKey=fieldValue
                            //     break;
            
                            //     case "meterValidationActionKey":
                            //     meterValidationAction.meterValidationActionKey = fieldValue
                            //     break;
            
                        default:
            
                    }
                    
                    this.setState({meterValDetails:meterValDetails,
                                   invalidDataItem:invalidDataItem,
                                   meterValidationAction: meterValidationAction,
                                // requiredKey: requiredKey,
                                // performedKey: performedKey,
                                actionType1: actionType1,
                                actionType2: actionType2
                              
                                
                            })
                }
             

    render(){
        const { isValTypeLoaded, windowHeight, hideAssociateDetails,
            isValActionLoaded, meterValidationActionKey,
            valResult,meterVal,isMeterValLoaded,isReadOnly, meterValDetails,meterValidation, meterValidationAction, meterValTypeDesc,action, meterValAction, performAction, requireAction, meterValidationKey, validationDate, statusKey}=this.state;
        return(<div>


<Col md={15} style={{margin:"0px 0px 0 0"}}>      
                    
                    <FormGroup>
                    {/* <Label for="requiredKey">Action Required</Label> */}
                    <CustomInput type="select"
                        id="requiredKey"
                        name="requiredKey"
                        
                        // defaultValue={meterValAction.actionKey}
                        //  value={meterValidationAction.actionKey}
                      
                         onChange={(e) => this.onTextChange("requiredKey",e.target.value,e)}
                         disabled={isReadOnly}
                         
                    >
                        <option value={-100}></option>
                        {requireAction.map(v => <option value={v.meterValActionKey}>{v.meterValActionDesc}</option>)}
                       
                    </CustomInput>

                    <Button color="primary"className="float-right"
                     style={{margin:"10px 0px 0px 0px"}}
                     onClick={()=> this.createMeterValAction()}>Save Action</Button>


                </FormGroup>



               
                


                
            
        </Col>
               
        
               
        </div>)
    }

}