
import React, { useState, useEffect, Component } from 'react'
import {
    Form, Label, FormGroup, Card, Input, Col, Row, Container, Table,
    Button, CardBody, CardText, CardTitle, CardHeader
} from 'reactstrap'
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { getUserADAccount, getRandomInt, bitwise, FormatDate, IsDate } from '../../resources/utility'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import EditIcon from '../../resources/revise.png';
import TrashBin from '../../resources/trash.svg'
import reply from '../../resources/reply2.png'
import FilePreviewer from 'react-file-previewer';

import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import Select from 'react-select';

const Comment = (props) => {
    const { data, user, user_AD } = props
    const [showReply, setShowReply] = useState(props.showReply)

    // console.log("data")
    // console.log(data)
    const [modal, setModal] = useState(false);
    const [imageData,setImageURL]=useState({imageURL:'', 
                                            imageName:''})

    const onDeleteClick = (e, key) => {

        console.log("key")
        console.log(key)

        props.onDeleteClick(key)
    }
    const onClosed = () => {

        // if (props.onClosed !== undefined) {
        //     props.onClosed();
        // }
    }
    const clickHandler = (e) => {
        console.log("respond click")
        console.log(e)
        
        const target = e.target

        if (target !== null){
            if (target.localName==="img"){
                setImageURL({imageURL:target.currentSrc,imageName:target.alt})
                setModal(!modal)
            }
        }

        
    }
    const toggle = () => {
        setModal(!modal);
        //downloadAtt()
    }
    if (data === undefined) {
        return (<div> No comments</div>)
    }
    return (
        
        data.map((c) => {
        //     console.log("------------>c")
        // console.log(c)
        // console.log(user)
            return (
                <Form style={{ width: '98%' }}>
                    <FormGroup>
                        <Row form style={{ height: "30px", margin: "0px", padding: "0px" }}>
                            <Col sm={12}>
                                <strong className="text-muted">

                                    {c.user_name + '  -  '}

                                </strong>

                                <small>
                                    {c.comment_at}
                                </small>
                                <Button hidden style={{ width: "60px" }} color="link" outline className="btn-no-border" size="sm">
                                    <img
                                        src={EditIcon}
                                        alt="Edit"
                                        style={{ width: "18px", height: "18px", margin: "0 0 0 150" }} />
                                    Edit
                                </Button>
                                <Button color="link"
                                    outline
                                    className="btn-no-border float-right"
                                    size="sm"
                                    hidden={ (!(c.user_id === user_AD) ? 
                                                !(user.account.userName.toLowerCase() === 'hau.nguyen@h2obridge.com'): false)}
                                    onClick={(e) => onDeleteClick(e, c.operationCommentKey)}>
                                    <img
                                        src={TrashBin}
                                        alt="Delete"
                                        style={{ width: "18px", height: "18px", margin: "0 0 0 150" }} />
                                    Delete
                                </Button>

                            </Col>
                        </Row>
                        <Row form style={{ marginTop: "0px", paddingTop: "0px", marginLeft: "16px" }}>
                            {/* {c.text} */}
                            <div onClick={clickHandler} dangerouslySetInnerHTML={{ __html: c.text }}>

                            </div>

                        </Row>

                        <Row hidden={c.replies === undefined || c.replies === null || c.replies.count === 0}
                            form style={{ marginTop: "0px", paddingTop: "0px", marginLeft: "20px" }}>
                            <Col>
                                <Comment data={c.replies}
                                    showReply={false}>
                                </Comment>
                            </Col>
                        </Row>


                        <Button color="link"
                            outline
                            className="btn-no-border"
                            size="sm"
                            hidden={!showReply}>
                            <img
                                src={reply}
                                alt="Reply"
                                style={{ width: "14px", height: "14px", marginRight: "4px" }} />
                            Reply
                        </Button>


                        {/* <Input type='textarea'>

                        </Input> */}

                        <Modal isOpen={modal}
                            size="xl"
                            toggle={toggle}
                            onClosed={onClosed} >
                            <ModalHeader toggle={toggle}> {imageData.imageName}</ModalHeader>
                            <ModalBody>

                                <FilePreviewer
                                    file={{
                                        url:imageData.imageURL
                                    }}
                                />

                            </ModalBody>
                        </Modal>
                    </FormGroup>
                </Form>
            )
        })
    )
}


export default class AssetDiscussion extends Component {
    static displayName = AssetDiscussion.name;

    constructor(props) {
        super(props)
        this.state = {
            asset_detail: props.asset_detail,
            user: props.user,
            assetComment: [],
            isCommentLoaded: false,
            inputComment: '',
            controlHeight: (props.height === undefined || props.height === null ? "100%" : props.height),
            toolbarSettings: {
                items: [{
                    template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar" style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;"> &#10874  Send </div></button>',
                    undo: true,
                    click: this.onClick.bind(this),

                }, 'Image']
            },
            insertImageSettings: {
                saveUrl: this.createSaveURL(props.asset_detail),
                path: this.createPathURL(props.asset_detail)
            }
        }
    }

    onClick = () => {
        if (this.state.inputComment !== '') {
            this.saveComment(this.state.inputComment)
            this.setState({ inputComment: '' })
        }
    }

    createSaveURL = (asset_detail) => {
        let saveURL = apiURL + 'Documents/AssetDiscussionImage/Save?asset_type=' + asset_detail.asset_type_id
            + '&assetkey=' + asset_detail.assetkey
            + '&asset_id=' + ((asset_detail.asset_blob_id === null || asset_detail.asset_blob_id === '') ? asset_detail.asset_id : asset_detail.asset_blob_id) //asset_detail.asset_id
        return saveURL

    }

    createPathURL = (asset_detail) => {
        let saveURL = 'https://wbrstorageaccountprod.blob.core.windows.net/files/tasset/' + asset_detail.asset_type_id
            + '/' + ((asset_detail.asset_blob_id === null || asset_detail.asset_blob_id === '') ? asset_detail.asset_id : asset_detail.asset_blob_id)
            + '/Images/'
        return saveURL
    }


    saveComment = (inputComment) => {
        //this.state({saveLayout:this.grid.saveLayout()})
        const { user, asset_detail, } = this.state
        const commentDate = new Date()
        const addLayoutDTO = {
            "commentDate": commentDate,
            "comments": inputComment,
            "createdBy": getUserADAccount(user.account.userName),
            "linkPrimaryKey": asset_detail.assetkey,
            "headerComment": null,
            "offLineLinkKey": null,
            "CommentTypeKey": 9,
            "CompObjectKey": null,
            "FacilityKey": null
        }

        console.log("addLayoutDTO")
        console.log(addLayoutDTO)
        let myURI = apiURL + 'asset/commentadd'
        fetch(myURI, apiPOSTHeader(addLayoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    return Promise.reject(error)
                } else {
                    //// // console.log("i am here ")
                    //this.closeForm(true)
                    this.getAssetComment()
                }
            }).catch(error => {
                // console.log("PUT error: " + error)
            })


    }
    deleteComment = (key) => {
        //this.state({saveLayout:this.grid.saveLayout()})
        const { user, } = this.state
        const deleteDTO = {
            "operationCommentKey": key,
            "deletedBy": getUserADAccount(user.account.userName)
        }

        // console.log("deleteDTO")
        // console.log(deleteDTO)
        let myURI = apiURL + 'asset/CommentDelete'
        fetch(myURI, apiPOSTHeader(deleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error)
                } else {
                    this.getAssetComment()
                }
            }).catch(error => {

            })
    }
    onDeleteClick = (key) => {
        this.deleteComment(key)
    }

    onEnterPress = (e) => {
        // console.log("e.srcElement")
        // console.log(e)
        if (e.srcElement !== undefined) {
            if (e.srcElement.id.includes('ctrDisscussion')) {
                // console.log("Inside here")
                if (e.keyCode === 13 && e.altKey === false) {
                    console.log(" Save Data here then reset")
                    if (this.state.inputComment !== '' && this.state.inputComment !== '<br><br>') {
                        this.saveComment(this.state.inputComment)
                        this.setState({ inputComment: '' })
                    }
                    // console.log(e)
                } else {
                    // this.setState({inputComment: text})
                }
            }
        }

    }

    // docKeyUp = (e) => {
    //     console.log(e)
    //     if (e.alt == false && e.keyCode === 13) { /* t */
    //         // press alt+t to focus the component.
    //         console.log(" Save Data here then reset")
    //     }
    // }

    onTextChange = (text, e) => {

        console.log("text")
        console.log(e)
        var rightChar = ''
        let value = e.value
        if (value !== null) {
            let len = value.length

            console.log(len)
            if ((len - 8) > 0) {
                rightChar = value.substring(len - 8)
            } else {
                rightChar = value
            }

            console.log(rightChar)
        }

        if (rightChar !== null && rightChar.length > 7) {
            if (rightChar === '<br><br>') {
                this.saveComment(text)
                text = ''
            }
        }


        this.setState({ inputComment: text })

    }

    getAssetComment = () => {
        const { asset_detail } = this.state
        var myURI = apiURL + 'Asset/' + this.state.companyID + '/Comment/' + asset_detail.assetkey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isCommentLoaded: true,
                    assetComment: json
                })
            });
    }

    onUploadSuccess = (args) => {
        // console.log("onUploadSuccess")
        // console.log(args)

        // let filename = document.querySelectorAll(".e-file-name")[0];

        // console.log(args.element.currentSrc)
        //args.element.currentSrc = "https://wbrstorageaccountprod.blob.core.windows.net/files/tasset/WTC/1234/Images/34056.jpg"
    }

    componentDidMount() {
        //window.addEventListener("resize", this.handleResize);
        this.getAssetComment()
        //document.addEventListener('keyup', this.onEnterPress.bind(this));
    }

    render() {
        const { isCommentLoaded, assetComment, inputComment, user, controlHeight } = this.state

        console.log("controlHeight")
        console.log(controlHeight)


        if (!isCommentLoaded) {
            return (<div>
                <h3>Loading asset comment.....</h3>
            </div>)
        }
        return <div>

            <RichTextEditorComponent
                toolbarSettings={this.state.toolbarSettings}
                placeholder={'Type a message'}
                //imageUploadSuccess={(e) => this.onUploadSuccess(e)}
                value={inputComment}
                insertImageSettings={this.state.insertImageSettings}
                enterKey='BR'
                saveInterval={500}
                id={"ctrDisscussion"}
                change={(e) => this.onTextChange(e.value, e)}>

                <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>

            <div style={{
                height: controlHeight, 
                overflow: 'auto',
                width: '100%',
                maxHeight:"1250px",
                maxWidth: "460px"
            }}>
                <Comment data={assetComment}
                    showReply={false}
                    user={user}
                    user_AD={getUserADAccount(user.account.userName)}
                    onDeleteClick={this.onDeleteClick}> </Comment>
            </div>

        </div>
    }


}


   