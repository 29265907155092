import React, { Component } from 'react';
import '../../custom.css';

const WEB_PLAYER_SERVER_ROOT_URL = 'https://spotfire.h2obridge.com/spotfire/wp/';
const ANALYSIS_PATH = '/CMMS/CMMS_Metrics';
const API_VERSION = '7.14';

export default class WOSpotfireSpend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scriptLoaded: false,
            reportLoaded: false,
            error: null
        };
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.loadSpotfireScript(); // Ensure the Spotfire script is loaded
    }

    loadSpotfireScript = () => {
        const script = document.createElement("script");
        script.src = `https://spotfire-next.cloud.tibco.com/spotfire/js-api/loader.js`;
        script.async = true;

        script.onload = () => {
            console.log("Spotfire API script loaded successfully");
            this.setState({ scriptLoaded: true }, this.initializeSpotfire);
        };

        script.onerror = (error) => {
            console.error("Error loading Spotfire API script:", error);
            this.setState({ error: 'Failed to load Spotfire API script' });
        };

        document.head.appendChild(script);
    };

    initializeSpotfire = () => {
        console.log("Initializing Spotfire application");

        if (window.spotfire && window.spotfire.webPlayer) {
            try {
                const customizationInfo = {
                    showToolBar: false,
                    showPageNavigation: false,
                    showClose: false,
                    showAuthor: false,
                    showAbout: false,
                    showAnalysisInformationTool: false
                };
                const parameters = '';
                const reloadInstances = true;

                window.spotfire.webPlayer.createApplication(
                    WEB_PLAYER_SERVER_ROOT_URL,
                    customizationInfo,
                    ANALYSIS_PATH,
                    parameters,
                    reloadInstances,
                    API_VERSION,
                    this.onReadyCallback,
                    this.onCreateLoginElement
                );
            } catch (error) {
                console.error("Error initializing Spotfire application:", error);
                this.setState({ error: 'Error initializing Spotfire application' });
            }
        } else {
            console.error("Spotfire API is not available or not fully loaded yet.");
            this.setState({ error: 'Spotfire API is not available or not fully loaded yet.' });
        }
    };

    onReadyCallback = (response, newApp) => {
        if (response.status === "OK") {
            console.log("Spotfire application ready");
            this.app = newApp;
            newApp.openDocument("spotfire-connection-container3", "Cost", 1);
        }
    };

    onCreateLoginElement = () => {
        console.log("Creating the login element");
        return null; // Optionally create and return a div to host the login button
    };

    render() {
        const { error } = this.state;

        return (
            <div style={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <div
                    id="spotfire-connection-container3"
                    style={{
                        flex: 1,
                        width: '100%',
                        border: 'none'
                    }}
                />
            </div>
        );
    }
}
