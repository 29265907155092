import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { FilterFactory, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'
import AFEDetail from './AFEDetail'
import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
// //IgrInputModule.register();
//IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");

export default class AFE extends Component {
    static displayName = AFE.name;


    constructor(props) {
        super(props);
        document.title = "WaterBridge Portal - AFE Manager"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);

        const urlPara = this.getURLParameters(props.location)

        this.state = {
            afeHeaderKey: (urlPara.afeHeaderKey === null ? -1 : urlPara.afeHeaderKey),
            afeStatusDesc: "",
            afeNo: "",
            afeName: "",
            isDataLoaded: false,
            windowHeight: window.innerHeight,
            AFEData: [],
            fullAFEData: [],
            empList: [],
            isEmpListLoaded: false,
            budgetList: [],
            isBudgetLoaded: false,
            facilityList: [],
            isFacilityLoaded: false,
            costTypeList: [],
            isCostTypeLoaded: false,
            afeTypeList: [],
            isAFETypeLoaded: false,
            budgetClassList: [],
            isBudgetClassLoaded: false,
            uomList: [],
            isUOMListLoaded: false,
            linkAFEList: [],
            isLinkAFELoaded: false,
            basinList: [],
            isBasinLoaded: false,
            countyList: [],
            isCountyLoaded: false,
            afeStatusList: [],
            selAFEStatus: [],
            selBasin: [],
            isAFEStatusList: false,
            dropdownOpen: false,
            dropdownOpenManage: false,
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            modalAFEDetails: false,
            searchText: "",
            openAFEDetails: !(urlPara.afeHeaderKey === null),
            isAFEAdmin: false,
            isSecurityLoaded: false,
            afeBudgetListURL: [],
            projectStatusList: [],
            isProjectStatusListLoaded: false,
            areaList: [],
            isAreaListLoaded: false,
            dupAFEList: [],
            isDupAFEListLoaded: false,
            afeQueryList: [{
                "label": "My AFEs",
                "value": 1
            }, {
                "label": "All AFEs",
                "value": 2
            }],
            selAFEQuery: [],
            afeHeaderKeyReport: 0,
            closeoutStatusKey: 0,
            modalReport: false,
            reportName: "",
            statusKey: 0
        }
    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }


    getURLParameters = (inputPara) => {

        var paraMeters = {
            afeHeaderKey: null
        }
        var query
        if (inputPara !== undefined) {
            query = new URLSearchParams(inputPara.search);
            console.log("query")
            console.log(query.get('key'))

            paraMeters = {
                afeHeaderKey: query.get('key')
            }

            console.log("paraMeters")
            console.log(paraMeters.afeHeaderKey)
        }

        return paraMeters

    }

    toggleAFEDetails = () => {
        this.setState({
            modalAFEDetails: !this.state.modalAFEDetails
        })
    }

    closeAFEDetailsForm = () => {

        this.setState({
            modalAFEDetails: !this.state.modalAFEDetails,
            openAFEDetails: false,
            afeHeaderKey: -1
        })
    }
    setAFEDetailsFormHeader = (afeNo, afeStatusDesc, afeName, afeHeaderKey, closeoutStatusDesc) => {
        this.setState({
            afeNo: afeNo,
            afeStatusDesc: afeStatusDesc,
            afeName: afeName,
            afeHeaderKey: afeHeaderKey,
            closeoutStatusDesc: closeoutStatusDesc
        })
    }

    onGridRowSelected(s, e) {
        //  console.log(" On CLick")
        const content = e.content;

        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                afeHeaderKey: dataItem.afeHeaderKey,
                afeStatusDesc: dataItem.afeStatusDesc,
                afeNo: dataItem.afeNo,
                //modalAFEDetails: !this.state.modalAFEDetails,
                afeHeaderKeyReport: dataItem.afeHeaderKey,
                closeoutStatusKey: dataItem.closeoutStatusKey,
                statusKey: dataItem.statusKey
            })
        }

    }

    onGridRowSelected2(s, e) {
        if (e.isDoubleClick) {
            this.setState({ modalAFEDetails: !this.state.modalAFEDetails });
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.AFEData;
    }


    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
        });

    }

    getCounty = () => {

        let myURI = apiURL + 'AFE/County'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    countyList: this.createCountyList(json),
                    isCountyLoaded: true
                })
            });
    }
    createCountyList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.countyDesc
            e.label = e.countyState
            tempList.push(e)
        })

        return tempList
    }

    getAFEStatus = () => {
        let myURI = apiURL + 'AFE/StatusFilter'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeStatusList: this.createAFEStatusList(json),
                    isAFEStatusLoaded: true
                })
            });
    }

    createAFEStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeStatusKey
            e.label = e.afeStatusDesc
            tempList.push(e)
        })

        return tempList
    }


    getBasin = () => {

        //let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName
        let myURI = apiURL + 'AFE/Location' // + this.props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }


    getUOMList = () => {
        let myURI = apiURL + 'AFE/UOM'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    uomList: this.createUOMList(json),
                    isUOMListLoaded: true
                })
            });
    }



    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }

    getAFEBudgetListURL = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/AFEBudgetListURL'

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    afeBudgetListURL: json
                })
            });
    }



    createUOMList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.uomKey
            e.label = e.uomDesc
            tempList.push(e)
        })

        return tempList
    }

    getAssoAFE = () => {
        let myURI = apiURL + 'AFE/WDAFE/Associate'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    linkAFEList: this.createLinkAFEList(json),
                    isLinkAFELoaded: true
                })
            });
    }

    createLinkAFEList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.project_ID
            e.label = e.projectNoName
            tempList.push(e)
        })

        return tempList
    }


    getBudgeClass = () => {
        let myURI = apiURL + 'AFE/BudgetClass'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetClassList: this.createBudgetClassList(json),
                    isBudgetClassLoaded: true
                })
            });
    }



    createBudgetClassList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetClassKey
            e.label = e.afeBudgetClassDesc
            tempList.push(e)
        })

        return tempList
    }

    getAFEType = () => {
        let myURI = apiURL + 'AFE/Type'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeTypeList: this.createAFETypeList(json),
                    isAFETypeLoaded: true
                })
            });
    }

    createAFETypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeTypeKey
            e.label = e.afeTypeDesc
            tempList.push(e)
        })
        return tempList
    }

    getAFECostType = () => {
        let myURI = apiURL + 'AFE/LineType'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    costTypeList: this.createCostTypeList(json),
                    isCostTypeLoaded: true
                })
            });
    }

    createCostTypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeLineTypeKey
            e.label = e.afeLineTypeDesc
            tempList.push(e)
        })

        return tempList
    }

    getFacility = () => {
        const { user } = this.props
        let myURI = apiURL + 'EmployeeDetails/myfacility/AzureAD/' + user.account.userName

        //console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    facilityList: this.createFacilityList(json),
                    isFacilityLoaded: true
                })
            });
    }

    createFacilityList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.facilityKey
            e.label = e.facilityName
            tempList.push(e)
        })

        return tempList
    }

    getBudget = () => {

        let myURI = apiURL + 'AFE/Budget'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetList: this.createBudgetList(json),
                    isBudgetLoaded: true
                })
            });
    }

    createBudgetList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetDesc
            e.label = e.afeBudgetDesc
            tempList.push(e)
        })

        return tempList
    }

    getAFEdata = () => {

        let myURI = apiURL + 'AFE/Header/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName;

        console.log("myURI");
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    fullAFEData: json,
                    searchText: ""
                })
                this.getDefaultAFEList(json)
                this.openAFEDetailsFromKey(json)
            });

    }

    getDefaultAFEList = (e) => {
        console.log("this.state.selBasin")
        console.log(this.state.selBasin)
        console.log("this.state.selAFEStatus")
        console.log(this.state.selAFEStatus)

        this.getFitlerAFE(e, this.state.selAFEQuery, this.state.selBasin, this.state.selAFEStatus)
    }

    getProjectStatus = () => {
        let myURI = apiURL + 'AFE/ProjectStatus'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    projectStatusList: this.createProjectStatusList(json),
                    isProjectStatusListLoaded: true
                })
            });
    }

    createProjectStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.projectStatusKey
            e.label = e.projectStatusDesc
            tempList.push(e)
        })

        return tempList
    }

    getArea = () => {
        let myURI = apiURL + 'AFE/Area'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    areaList: this.createAreaList(json),
                    isAreaListLoaded: true
                })
            });
    }
    createAreaList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.areaKey
            e.label = e.areaName
            tempList.push(e)
        })

        return tempList
    }

    getEmpList = () => {

        let myURI = apiURL + 'EmployeeDetails'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    empList: this.createEmpList(json),
                    isEmpListLoaded: true
                })
            });
    }

    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.employeeKey
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }

    getDupAFE = () => {

        const { user } = this.props

        let myURI = apiURL + 'AFE/Header/AFEDuplicate/AzureAD/' + user.account.userName

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    dupAFEList: this.createDupFEList(json),
                    isDupAFEListLoaded: true
                })
            });
    }

    createDupFEList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeNo
            e.label = e.afeNo + ' - ' + e.afeName
            tempList.push(e)
        })

        return tempList
    }


    // getUserCheckPoint = () => {
    //     let myURI = apiURL + 'AFE/AFECheckPoints/' + this.props.user.account.userName;

    //     console.log("myURI");
    //     console.log(myURI);

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 isUserCheckPointLoaded: true,
    //                 userCheckPoint: json
    //             })
    //         });
    // }

    setDefaultAFEQuery = () => {
        this.setState({ selAFEQuery: this.state.afeQueryList[0] })
    }
    componentDidMount = () => {
        //this.getUserCheckPoint()
        this.getProjectStatus()
        this.getArea()
        this.getAFEBudgetListURL()
        this.getUserSecurity()
        this.setDefaultAFEQuery()
        this.getAFEStatus()
        this.getCounty()
        this.getBasin()
        this.getAssoAFE()
        this.getUOMList()
        this.getBudgeClass()
        this.getAFEType()
        this.getAFECostType()
        this.getFacility()
        this.getBudget()
        this.getEmpList()
        this.getAFEdata()
        this.getDupAFE()


    }

    openAFEDetailsFromKey = (afeList) => {
        const { openAFEDetails } = this.state
        if (openAFEDetails) {
            const selAfe = afeList.filter(a => a.afeHeaderKey === this.state.afeHeaderKey)
            console.log("openAFEDetails")
            console.log(selAfe)

            if (selAfe.length > 0) {
                this.setState({
                    afeNo: selAfe[0].afeNo,
                })
            }
            this.toggleAFEDetails()
        }
    }

    newAFE = () => {
        this.setState({
            afeHeaderKey: -1
        })
        this.toggleAFEDetails()
    }


    // getSelAFEStatus = (value) => {
    //     const { afeStatusList } = this.state

    //     if (value !== null && value !== "") {
    //         return costTypeList.filter(e => value.includes(e.afeLineTypeKey))
    //     }
    //     return ""
    // }

    openAFEBudgetList = () => {
        const { afeBudgetListURL } = this.state

        console.log("afeBudgetListURL")
        console.log(afeBudgetListURL)
        if (afeBudgetListURL.length > 0) {
            const newWindow = window.open(afeBudgetListURL[0].afeBudgetListURL, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null

        }

    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { fullAFEData } = this.state
        // console.log(fieldName + ' ------ ' + fieldValue)
        // console.log(e)

        switch (fieldName) {
            case "afeSearch":
                this.setState({ searchText: fieldValue.toLowerCase() })

                let ff = FilterFactory.instance;
                if (fieldValue === "") {
                    this.grid.filterExpressions.clear()
                } else {
                    let filter = ff.property("afeNo").toLower().contains(ff.literal(fieldValue).toLower())
                        .or(ff.property("afeName").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("afeStatusDesc").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("afeTypeDesc").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("afeManagerName").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("afeDescription").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("createdByName").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("comments").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("waitingOnApprovers").toLower().contains(ff.literal(fieldValue).toLower()))
                        .or(ff.property("basin").toLower().contains(ff.literal(fieldValue).toLower()))
                        

                    this.grid.filterExpressions.clear();
                    this.grid.filterExpressions.add(filter);
                }
                    break;
            case "afeStatus":
                this.getFitlerAFE(fullAFEData, this.state.selAFEQuery, this.state.selBasin, e)
                this.setState({ selAFEStatus: e })
                break;
            case "basin":
                this.getFitlerAFE(fullAFEData, this.state.selAFEQuery, e, this.state.selAFEStatus)
                this.setState({ selBasin: e })
                break;
            case "afeQuery":
                this.getFitlerAFE(fullAFEData, e, this.state.selBasin, this.state.selAFEStatus)
                this.setState({ selAFEQuery: e })
                break;
            default:
                break;
        }
    }

    getFitlerAFE = (e, m, b, s) => {
        //const {fullAFEData} = this.state
        const { user } = this.props

        const basins = b.map(e => e.locationName).join(",")
        const status = s.map(e => e.afeStatusDesc).join(",")

        const userName = user.account.userName.toLowerCase()

        var tempAFE = e

        if (m !== null) {
            if (m.value === 1) {
                tempAFE = tempAFE.filter(e => (e.afeManagerAzureAD || "").toLowerCase() === userName ||
                    (e.createdByAzureAD || "").toLowerCase() === userName ||
                    (e.afeSponsorAzureAD || "").toLowerCase() === userName ||
                    (e.approvers || "").toLowerCase().includes(userName))
            }
        }

        if (basins.length > 0) {
            tempAFE = tempAFE.filter(e => basins.includes(e.basin))
        }

        if (status.length > 0) {
            tempAFE = tempAFE.filter(e => status.includes(e.afeStatusDesc))
        }

        console.log("I am here first")
        console.log(tempAFE)
        this.setState({ AFEData: tempAFE })

        console.log('in cacnels')
        console.log(s)
        s.map(e => {
            if (e.afeStatusKey === 5) {
                this.getAFEdataByKey(5).then((cancelAFE) => {
                    tempAFE = [...tempAFE, ...cancelAFE]
                    this.setState({ AFEData: tempAFE })
                })
                //
            }
            if (e.afeStatusKey === 8) {
                this.getAFEdataByKey(8).then((closeAFE) => {
                    tempAFE = [...tempAFE, ...closeAFE]
                    this.setState({ AFEData: tempAFE })
                })
            }

        })

    }

    getAFEdataByKey(k) {

        let myURI = apiURL + 'AFE/Header/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName + "/StatusKey/" + k;

        console.log("myURI");
        console.log(myURI);

        return (fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json()));

    }

    getAFEHeaderColor = (closeoutStatusDesc,afeStatusDesc) => {

        if (closeoutStatusDesc !== null) {

            if (closeoutStatusDesc === "Rejected" || closeoutStatusDesc === "Cancelled" || closeoutStatusDesc === "Revised") {
                return "modal-header reject"
            } else if (closeoutStatusDesc === "Pending") {
                return "modal-header submit"
            } else if (afeStatusDesc == 'Closeout (Requested)'){
                return "modal-header closeoutRequested"
            } else if (closeoutStatusDesc !== "") {
                return "modal-header primary"
            } 

        } else { return "" }
    }

    onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    }

    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            let obj = budgetList.filter(e => e.afeBudgetDesc === v)
            if (obj === null || obj.length <= 0) {
                return '';
            }
            return obj[0].afeBudgetDesc;
        }
        return ''
    }

    async getAFEReport(reportName) {
        this.setState({ modalReport: !this.state.modalReport, reportName: reportName })
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKeyReport } = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKeyReport }];
        return reportPara
    }

    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport })
    }
    render() {

        const { isDataLoaded, AFEData, afeName, basinList, isBasinLoaded, selBasin, afeQueryList, selAFEQuery,
            windowHeight, afeHeaderKey, linkAFEList, isLinkAFELoaded, selAFEStatus, closeoutStatusDesc,
            empList, budgetList, facilityList, afeStatusDesc, afeNo, afeStatusList, isAFEStatusLoaded,
            costTypeList, afeTypeList, budgetClassList, uomList, countyList,
            isEmpListLoaded, isBudgetLoaded, isFacilityLoaded, isCostTypeLoaded,
            isAFETypeLoaded, isBudgetClassLoaded, isUOMListLoaded, projectStatusList,
            isProjectStatusListLoaded, areaList, isAreaListLoaded,
            searchText, isAFEAdmin, isDupAFEListLoaded, dupAFEList, afeHeaderKeyReport, closeoutStatusKey, reportName, statusKey } = this.state

        //const searchText = window.$searchText.toLowerCase()
        const gridHeight = (windowHeight * 0.75) + "px"


        if (!isProjectStatusListLoaded) {
            return (<div>
                <h3>Loading Project Status data.....</h3>
            </div>)
        } else if (!isAreaListLoaded) {
            return (<div>
                <h3>Loading Area data.....</h3>
            </div>)
        } else if (!isAFEStatusLoaded) {
            return (<div>
                <h3>Loading AFE Status data.....</h3>
            </div>)
        } else if (!isBasinLoaded) {
            return (<div>
                <h3>Loading Basin data.....</h3>
            </div>)
        } else if (!isLinkAFELoaded) {
            return (<div>
                <h3>Loading Link AFE data.....</h3>
            </div>)
        } else if (!isEmpListLoaded) {
            return (<div>
                <h3>Loading Employee data.....</h3>
            </div>)
        } else if (!isBudgetLoaded) {
            return (<div>
                <h3>Loading Budget data.....</h3>
            </div>)
        } else if (!isFacilityLoaded) {
            return (<div>
                <h3>Loading Facility data.....</h3>
            </div>)
        } else if (!isCostTypeLoaded) {
            return (<div>
                <h3>Loading Cost Type data.....</h3>
            </div>)
        } else if (!isAFETypeLoaded) {
            return (<div>
                <h3>Loading AFE Type data.....</h3>
            </div>)
        } else if (!isBudgetClassLoaded) {
            return (<div>
                <h3>Loading Budget Class data.....</h3>
            </div>)
        } else if (!isUOMListLoaded) {
            return (<div>
                <h3>Loading UOM List data.....</h3>
            </div>)
        } else if (!isDataLoaded) {
            return (<div>
                <h3>Loading AFE data.....</h3>
            </div>)
        } else if (!isDupAFEListLoaded) {
            return (<div>
                <h3>Loading AFE data.....</h3>
            </div>)
        }
        else
            return (<div>
                <Form onSubmit={e => e.preventDefault()}>


                    <FormGroup>
                        <Row form>
                            <Col sm={12}>
                                <Label><h2> AFE Manager |</h2></Label>
                                <Button outline
                                    color="secondary"
                                    className="btn-no-border"
                                    onClick={this.getAFEdata}>
                                    <img
                                        src={Refresh}
                                        alt="Refresh"
                                        style={{ width: "30px", margin: "0 5px 0 0" }} />
                                    Refresh
                                </Button>
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.saveWorkbook}>
                                    <img
                                        src={Excel}
                                        alt="excel"
                                        style={{ width: "50px", margin: "0 0 0 0" }} />
                                    Export To Excel
                                </Button>{' '}

                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.newAFE}>
                                    <img
                                        src={Plus}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    New AFE
                                </Button>{' '}
                                <Dropdown isOpen={this.state.dropdownOpen} nav={false}
                                    direction="left"
                                    className="no-border float-right"
                                    color='primary'
                                    outline={false}
                                    hidden={!isAFEAdmin}
                                    toggle={this.toggleDropdown}>
                                    <DropdownToggle caret>
                                        Manage
                                    </DropdownToggle>
                                    <DropdownMenu end>

                                        <DropdownItem>AFE Type Cost Template</DropdownItem>
                                        <DropdownItem>Approval Matrix</DropdownItem>
                                        <DropdownItem>County</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>



                                <Button outline color="secondary"
                                    className="btn-no-border float-right"
                                    onClick={this.openAFEBudgetList}>
                                    <img
                                        src={MenuIcon}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Approved Corporate Budget
                                </Button>{' '}
                                <Dropdown isOpen={this.state.dropdownOpenManage}// nav={false}
                                    direction="left"
                                    className="no-border float-right"
                                    color='primary'
                                    outline={false}
                                    hidden={afeHeaderKeyReport == undefined || afeHeaderKeyReport == null || afeHeaderKeyReport <= 0 || (statusKey <= 2 && closeoutStatusKey <= 2)}
                                    toggle={this.dropdownOpenManage}>
                                    <DropdownToggle caret>
                                        Reports
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <DropdownItem hidden={closeoutStatusKey <= 2} onClick={() => this.getAFEReport('AFECloseout')}>AFE Closeout Report</DropdownItem>
                                        <DropdownItem hidden={statusKey <= 2} onClick={() => this.getAFEReport('AFEApp')}>AFE Approval Report</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <ColoredLine color="grey" />
                    </FormGroup>

                    {/* <FormGroup>
                    <Row >
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)} />
                        </Col>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />

                        </Col>

                    </Row>
                </FormGroup> */}

                    {/* style={{ zIndex: 9999, position: 'relative' }}> */}

                    <FormGroup>
                        <Row form>
                            <Col md={2}>
                                <Row form>
                                    <Col md={12}>
                                        <Label for="typeKey">Filter</Label>
                                        <Select options={afeQueryList}
                                            //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                            //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                            //className={(!validateDataItem.afeCostType ? "form-error" : "")}
                                            value={selAFEQuery}
                                            defaultValue={selAFEQuery}
                                            onChange={(e) => this.onTextChange("afeQuery", e, e)}
                                            isClearable={true}
                                        //width="200px"
                                        //isMulti
                                        >
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <Row form>
                                    <Col md={12}>
                                        <Label for="typeKey">Entity</Label>
                                        <Select options={basinList}
                                            //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                            //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                            //className={(!validateDataItem.afeCostType ? "form-error" : "")}
                                            value={selBasin}
                                            defaultValue={selBasin}
                                            onChange={(e) => this.onTextChange("basin", e, e)}
                                            isClearable={true}
                                            // width="200px"
                                            isMulti
                                        >
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <Col md={12}>
                                    <Label for="typeKey">AFE Status</Label>

                                    <Select options={afeStatusList}
                                        //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                        //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                        //className={(!validateDataItem.afeCostType ? "form-error" : "")}
                                        value={selAFEStatus}
                                        defaultValue={selAFEStatus}
                                        onChange={(e) => this.onTextChange("afeStatus", e, e)}
                                        isClearable={true}
                                        isMulti

                                    >
                                    </Select>
                                </Col>
                            </Col>
                            <Col md={4}>

                                <Label for="typeKey"> </Label>
                                <Input width="75%" className='floatRight'
                                    placeholder="Type here to search......."
                                    onChange={(e) => this.onTextChange("afeSearch", e.target.value, e)}
                                >

                                </Input>


                            </Col>

                        </Row>
                    </FormGroup>


                    <FormGroup>
                        <Row form style={{ zIndex: 0, position: 'relative' }}>
                            <IgrDataGrid
                                ref={this.onGridRef}
                                height={gridHeight}
                                width={"100%"}
                                isColumnOptionsEnabled="true" 
                                editMode={0}
                                dataSource={AFEData} 
                                autoGenerateColumns="false"
                                selectionMode="SingleRow" 
                                selectedKeysChanged={this.onGridRowSelected}
                                cellClicked={this.onGridRowSelected2}
                                cellTextStyle="14px Calibri" headerTextStyle="14px Calibri" rowHeight={"25"}
                            >


                                <IgrTemplateColumn field="afeNo" headerText="AFE No"
                                    width="*>120"
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrTemplateColumn field="afeName"
                                    headerText="AFE Name"
                                    width="*>170"
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrTemplateColumn
                                    field="afeStatusDesc"
                                    headerText="Status"
                                    width="*>150"
                                    cellUpdating={this.onStatusCellUpdating}
                                />


                                <IgrNumericColumn
                                    field="afeTotalAmt"
                                    headerText={"Budget Amt"}
                                    width="*>160"
                                    positivePrefix="$"
                                    showGroupingSeparator="true"
                                    maxFractionDigits={0}
                                // cellUpdating={this.onCellUpdating}
                                />

                                <IgrNumericColumn
                                    field="invAmount"
                                    headerText={"Actual Amt"}
                                    width="*>160"
                                    positivePrefix="$"
                                    showGroupingSeparator="true"
                                    maxFractionDigits={0}
                                // cellUpdating={this.onCellUpdating}
                                />

                                <IgrTemplateColumn
                                    field="actualvsBudget"
                                    headerText={"Actual vs Budget"}
                                    width="*>170"
                                    positiveSuffix="%"
                                    showGroupingSeparator="true"
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrNumericColumn
                                    field="projectedAmount"
                                    headerText={"Projected Amt"}
                                    width="*>180"
                                    positivePrefix="$"
                                    maxFractionDigits={0}
                                    showGroupingSeparator="true"
                                // cellUpdating={this.onCellUpdating}
                                />

                                <IgrTemplateColumn
                                    field="projectedvsBudget"
                                    headerText={"Projected vs Budget"}
                                    width="*>180"
                                    positiveSuffix="%"
                                    showGroupingSeparator="true"
                                    cellUpdating={this.onStatusCellUpdating}
                                />

                                <IgrTextColumn field="basin" headerText="Entity"
                                    width="*>140"
                                />

                                <IgrTextColumn field="afeTypeDesc" headerText="AFE Type"
                                    width="*>120"
                                />


                                <IgrTextColumn field="afeManagerName" headerText="AFE Manager "
                                    width="*>150"
                                />

                                <IgrTemplateColumn field="afeApprovedDate" headerText="AFE Approved Date"
                                    width="*>160" cellUpdating={this.onStatusCellUpdating}
                                />



                                <IgrTextColumn field="afeCostTypeDesc" headerText="AFE Cost Type"
                                    width="*>120"
                                />

                                <IgrTemplateColumn field="afeSignedDate" headerText="Signed Date"
                                    width="*>150"
                                    cellUpdating={this.onStatusCellUpdating}
                                    isHidden
                                />


                                <IgrTextColumn field="waitingOnApprovers" headerText="Waiting On Approvers"
                                    width="*>200"
                                />


                                <IgrTextColumn field="afeDescription" headerText="Project Scope"
                                    width="*>400"
                                />


                                <IgrTemplateColumn field="estStartDate" headerText="Budget Start Date"
                                    width="*>160"
                                    cellUpdating={this.onStatusCellUpdating} />

                                <IgrTemplateColumn field="estComplete" headerText="Budget End Date"
                                    width="*>160"
                                    cellUpdating={this.onStatusCellUpdating} />
                                <IgrTextColumn field="projectStatusDesc" headerText="Project Status"
                                    width="*>120"
                                />


                                <IgrTemplateColumn field="createdDatetime" headerText="Created Date"
                                    width="*>140" cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrTextColumn field="createdByName" headerText="Created By"
                                    width="*>150"
                                />
                                <IgrTemplateColumn field="budgetID" headerText="Budget ID"
                                    width="*>150" cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrNumericColumn field="afeHeaderKey" isHidden />
                                <IgrNumericColumn field="closeoutStatusKey" headerText="closeoutStatusKey" width="*>150" isHidden={true} />
                                <IgrNumericColumn field="statusKey" headerText="StatusKey" width="*>150" isHidden={true} />
                            </IgrDataGrid>
                        </Row>
                    </FormGroup>

                    <div>
                        <Modal isOpen={this.state.modalAFEDetails}
                            toggle={() => this.toggleAFEDetails()}
                            size="xl"
                            backdrop={"static"}
                            onClosed={() => this.getAFEdata()}>
                            <ModalHeader toggle={() => this.closeAFEDetailsForm()}
                                className={this.getAFEHeaderColor(closeoutStatusDesc,afeStatusDesc)}>
                                {(afeHeaderKey < 0 ? "New AFE" : afeNo + " - " + afeName + " - " + afeStatusDesc)}
                            </ModalHeader>
                            <ModalBody>
                                <AFEDetail toggle={this.closeAFEDetailsForm}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    afeHeaderKey={afeHeaderKey}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    linkAFEList={linkAFEList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList}>
                                </AFEDetail>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            size={"xl"}
                            centered
                        >
                            <ModalHeader toggle={() => this.toggleReport()}> {reportName == 'AFECloseout' ? 'AFE Closeout Report' : 'AFE Approval Report'} </ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName={reportName}
                                    reportParameters={this.createReportParamter()}

                                    toggle={this.toggleReport}

                                >

                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div>
                </Form>
            </div >)

    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.statusKey === 3 || item.closeoutStatusKey === 3) {
                content.style.background = "#f0f5b0";
            } else if (item.statusKey === 1 || item.statusKey === 5 ||
                item.closeoutStatusKey === 1 || item.closeoutStatusKey === 10) {
                content.style.background = "#F3B5A2";
            } else if (item.statusKey === 6 && item.closeoutStatusKey === 15) {
                content.style.background = "#FFDDAF";  //Closeout (Requested) 
            } else if (item.statusKey === 6) {
                content.style.background = "#b8f2b1";
            } 
            else {
                content.style.background = "transparent";
            }

            if (s.field === "actualvsBudget") {
                if (item.actualvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.actualvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.actualvsBudget > 104.44 && item.actualvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }
            if (s.field === "projectedvsBudget") {
                if (item.projectedvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.projectedvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.projectedvsBudget > 104.44 && item.projectedvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }


        }

        switch (s.field) {
            case 'afeStatusDesc':
                link.textContent = item.afeStatusDesc;
                break;
            case 'afeName':
                link.textContent = item.afeName;
                break;
            case 'afeSignedDate':
                link.textContent = ""
                if (item.afeSignedDate !== null) {
                    var date = new Date(item.afeSignedDate);
                    link.textContent = date.toLocaleDateString();

                }
                content.style.background = "transparent";
                break;
            case 'afeNo':
                link.textContent = item.afeNo;
                break;

            case 'afeApprovedDate':
                // console.log("afeApprovedDate")
                // console.log(item.afeApprovedDate)
                link.textContent = ""

                if (item.afeApprovedDate !== null) {
                    var date = new Date(item.afeApprovedDate);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'createdDatetime':
                link.textContent = ""
                if (item.createdDatetime !== null) {
                    var date = new Date(item.createdDatetime);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'estStartDate':
                link.textContent = ""
                if (item.estStartDate !== null) {
                    var date = new Date(item.estStartDate);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'estComplete':
                link.textContent = ""
                if (item.estComplete !== null) {
                    var date = new Date(item.estComplete);
                    link.textContent = date.toLocaleDateString();
                    // content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'managerName':
                link.textContent = item.managerName;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            case 'actualvsBudget':
                link.textContent = (item.actualvsBudget >= 1 ? Math.round(item.actualvsBudget) : item.actualvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'projectedvsBudget':
                link.textContent = (item.projectedvsBudget >= 1 ? Math.round(item.projectedvsBudget) : item.projectedvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'budgetID':
                link.textContent = item.budgetID === undefined || item.budgetID === null ? '' : this.getSelBudget(item.budgetID);
                content.style.background = "transparent";
                break;

            default:

        }

    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "AFEManager");
    }

}