/*Modified by BN 9/13/24. Fix date conversion*/
import React, { Component } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import MenuIcon from '../../resources/menu.png';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import Revise from '../../resources/revise.png'
import Excel from '../../resources/excel.svg'
import spade from '../../resources/spade.png'
import '../../custom.css';
import { ExcelUtility } from '../../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid, IgrTextColumn } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import WORequest from './WORequest'

import { QrReader } from 'react-qr-reader';

import PowerBIReportViewer from '../../resources/PowerBIReportViewer'

import clearfilter from '../../components/img/clearfilter.png'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



export default class Workorder extends Component {
    static displayName = Workorder.name;

    constructor(props) {
        super(props);
        document.title = "WaterBridge Portal - Workorder Request"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.searchInput = React.createRef();  /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
        this.updateGridHeight = this.updateGridHeight.bind(this);

        const urlPara = this.getURLParameters(props.location)
        // console.log(urlPara)
        this.state = {
            workOrderKey: urlPara.workOrderKey === null ? -1 : urlPara.workOrderKey,
            openWODetails: !(urlPara.workOrderKey === null),
            woNumber: '',
            WOData: [],
            isDataLoaded: false,
            qrValue: "",
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            windowHeight: window.innerHeight,
            modalWODetails: false,
            modalQRCodeScanner: false,
            isAssetListLoaded: false,
            assetList: [],
            facilityKey: null,
            locationKey: null,
            facilityID: null,
            isSecurityLoaded: false,
            security: [],
            isSecurityAdminLogin: false,
            isBasinLoaded: false,
            basinList: [],
            selBasin: [],
            isStatusLoaded: false,
            statusList: [],
            selStatus: [],
            mainTypeList: [],
            isMainTypeListLoaded: false,
            selMainType: [],
            fullMaterialRequestData: [],
            searchText: "",
            fiixWOId: null,
            switchBool: null,
            fiixWOStatus: "",
            modalReport: false,
            isMainTypeSelected: true,
            maintenanceTypeID: null,
            isEmpListLoaded: false,
            empList: [],
            mtlRequestQueryList: [{
                "label": "My Work Orders",
                "value": 1
            }, {
                "label": "All Work Orders",
                "value": 2
            },
            {
              "label": "Unassigned Work Orders",
              "value": 3
          }
          ],
            selMtlRequestQuery: [],
            bigFormat: false,
            lastSelectedKey: null,
            isTyping:false,
            isFetchingWOData: false, // Add this flag
            gridHeight: window.innerHeight * 0.65,
        }

    }

    getURLParameters = (inputPara) => {

        var paraMeters = {
            workOrderKey: null
        }
        var query
        if (inputPara !== undefined) {
            query = new URLSearchParams(inputPara.search);

            paraMeters = {
                workOrderKey: query.get('key')
            }

        }
        //  console.log(paraMeters)

        return paraMeters

    }

    toggleQRCodeScanner = () => {
        this.setState({ modalQRCodeScanner: !this.state.modalQRCodeScanner })
    }

    setQRCodeValue = (qrValue) => {
        const { assetList } = this.state
        var test = assetList.filter(a => a.qrCodeValue == qrValue)
        var test2 = test[0].facilityKey
        var test3 = test[0].locationKey
        var test4 = test[0].facilityID
        this.setState({
            qrValue: qrValue,
            facilityKey: test2,
            locationKey: test3,
            bigFormat: false,
            facilityID: test4,
            switchBool: -1,
            modalQRCodeScanner: !this.state.modalQRCodeScanner,
            modalWODetails: !this.state.modalWODetails,
            isTyping: false
        })
    }
    getAssetList = () => {

        let myURI = apiURL + 'ItemMaster/FacilityInventoryCollector2'
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isAssetListLoaded: true,
                    assetList: this.createAssetList(json)
                })

            });
    }

    createAssetList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.facilityInvCollectorKey
            e.label = e.qrCodeValue
            tempList.push(e)
        })

        return tempList
    }


    // getWOdata = () => {
    //     let myURI = apiURL + 'Fiix/WorkOrder/' + 'AzureAD/' + this.props.user.account.userName;
    //     fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState((prevState) => ({
    //                 isDataLoaded: true,
    //                 fullMaterialRequestData: json,
    //                 searchText: "",
    //                 isTyping: false,
    //                 modalWODetails: prevState.modalWODetails // Keep the modal state unchanged
    //             }), () => {
    //                 this.getDefaultMtlRequestList(json);
    //                 this.openWODetailsFromKey(json);
    //             });
    //         });
    // }

    getWOdata = () => {

        if (this.state.isFetchingWOData) return;  // Prevent multiple calls
        this.setState({ isFetchingWOData: true });  // Set the flag
        let myURI = apiURL + 'Fiix/WorkOrder/' + 'AzureAD/' + this.props.user.account.userName;

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    fullMaterialRequestData: json,
                    searchText: "",
                    isTyping: false,
                })
                // console.log(json)
                this.getDefaultMtlRequestList(json)
                this.openWODetailsFromKey(json)
            });


    }



    // openWODetailsFromKey = (woList) => {
    //     const { workOrderKey } = this.state;
    //     const selMOC = woList.filter(a => parseInt(a.workOrderKey) === parseInt(workOrderKey));
    //     if (selMOC.length > 0) {
    //         this.setState({
    //             woNumber: selMOC[0].woNumber,
    //             bigFormat: true,
    //             modalWODetails: true // Modified by BN 6/17/24.  Ensure modal is open for hyperlink  
    //         });
    //     } else {
    //         this.setState({
    //             bigFormat: true,
    //             modalWODetails: true // Modified by BN 6/17/24.  Ensure modal is open for hyperlink  
    //         });
    //     }
    // }

    // openWODetailsFromKey = (woList) => {
    //     const { workOrderKey } = this.state;
        
    //     // Check if workOrderKey is provided and not equal to -1
    //     if (workOrderKey !== null && workOrderKey !== undefined && workOrderKey !== "" && workOrderKey !== -1) {
    //         const selMOC = woList.filter(a => parseInt(a.workOrderKey) === parseInt(workOrderKey));
    //         if (selMOC.length > 0) {
    //             this.setState({
    //                 woNumber: selMOC[0].woNumber,
    //                 bigFormat: true,
    //                 modalWODetails: true // Ensure modal is open
    //             });
    //         } else {
    //             this.setState({
    //                 bigFormat: true,
    //                 modalWODetails: true // Ensure modal is open
    //             });
    //         }
    //     }
    // }

    openWODetailsFromKey = (woList) => {
        const { openWODetails, workOrderKey } = this.state;
        // console.log("woList:", woList);
        // console.log("workOrderKey:", workOrderKey);
        // console.log(openWODetails)
        if (openWODetails) {
            // console.log(workOrderKey)
            const selMOC = woList.filter(a => parseInt(a.workOrderKey) === parseInt(workOrderKey));
            // console.log("selMOC:", selMOC);
            if (selMOC.length > 0) {

                this.setState({
                    woNumber: selMOC[0].woNumber,
                    // fiixWOStatus: selMOC[0].fiixWOStatus,
                    bigFormat: true
                });


            }
            else {
                this.setState({
                    bigFormat: true
                })
            }


            this.toggleWODetails();



        }


    }

    getWODataByStatus = (woStatus) => {
      let myURI = apiURL + 'Fiix/WorkOrder/startDate/' +
          this.state.startDate.toString().substring(0, 10) +
          '/enddate/' + this.state.endDate.toString().substring(0, 10)
          + '/AzureAD/' + this.props.user.account.userName
          + '/FiixStatus/' + woStatus;
  
      return fetch(myURI, { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => {
              // console.log(`Fetched data for status ${woStatus}:`, json);
              return json;
          });
  };


    getSecurity = () => {
        let myURI = apiURL + 'EmployeeDetails/Checkpoint/' + this.props.user.account.userName
        const { security, appSecurityNo, meterVal } = this.state
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log(this.props.user.account.userName)
                this.setState({

                    isSecurityLoaded: true,
                    security: json,
                    isSecurityAdminLogin: (json.filter((list) => list.appSecurityNo === 115).length > 0 ? false : true),
                    // isSecurityMeasurementTech: (json.filter((list)=> list.appSecurityNo === 15).length > 0 ? false: true),
                    // isOtherUser: (json.filter((list)=> list.appSecurityNo === 15 || list.appSecurityNo === 14 ).length == 0 ? true:false)
                }
                    , () => {
                        if (!this.state.isDataLoaded) {
                            this.setDefaultMtlRequestQuery();
                        }

                    }
                )

            })

    }
    setFocusToSearchInput = () => {  /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
        if (this.state.isTyping) {
          if (this.searchInput.current) {
            this.searchInput.current.focus();
          }
        }
      };
      




    onTextChange2 = async (fieldName, fieldValue, e) => {
        const { fullMaterialRequestData } = this.state;
    
        if (fieldName === "searchText") {
            let searchText = fieldValue.toLowerCase();
    
            if (searchText !== this.state.searchText) {
                this.setState({ searchText: searchText });
            }
    
            // Apply dropdown filters first
            let filteredData = await this.getFilteredData(fullMaterialRequestData);
    
            // If the search text is not empty, apply the search filter on top of the filtered data
            if (searchText !== '') {
                filteredData = filteredData.filter(item => {
                    return this.isItemMatchingSearchText(item, searchText);
                });
            }
    
            this.setState({ WOData: filteredData, searchText, isTyping: true });
        }
    };
      
      isItemMatchingSearchText = (item, searchText) => {
        const columnsToFilter = [
          "woNumber", "fiixWOStatus", "assetName", "qrCodeValue", "facilityName", 
          "woPriority", "maintenanceType", "fiixIssueType", "workOrderDesc", 
          "assetID", "createdByName", "reqestDateTime", "assignedToUserName", 
          "locationID", "locationName", "workdaySiteID", "estimateCost"
        ];
      
        return columnsToFilter.some(column => {
          if (item[column] && item[column].toString().toLowerCase().includes(searchText)) {
            return true;
          }
          return false;
        });
      };

      
    getFilteredData = async (data) => {
        const { selMtlRequestQuery, selBasin, selStatus, selMainType } = this.state;
    
        let filteredData = data;
    
        if (selMtlRequestQuery || (selBasin && selBasin.length > 0) || (selStatus && selStatus.length > 0) || ((selMainType && selMainType.length > 0))) {
            filteredData = await this.getFilterMtlRequest(filteredData, selMtlRequestQuery, selBasin, selStatus, selMainType);
        }
    
        return filteredData;
    };


    onTextChange = (fieldName, fieldValue, e) => {
        const { fullMaterialRequestData } = this.state;
    
        switch (fieldName) {
            case "mtlRequestQuery":
                this.setState({ selMtlRequestQuery: e }, async () => {
                    let filteredData = await this.getFilteredData(fullMaterialRequestData);
                    this.setState({ WOData: filteredData });
                });
                break;
    
            case "basin":
                this.setState({ selBasin: e }, async () => {
                    let filteredData = await this.getFilteredData(fullMaterialRequestData);
                    this.setState({ WOData: filteredData });
                });
                break;
    
            case "status":
                this.setState({ selStatus: e }, async () => {
                    let filteredData = await this.getFilteredData(fullMaterialRequestData);
                    this.setState({ WOData: filteredData });
                });
                break;
            case "maintenance":
                this.setState({ selMainType: e }, async () => {
                    let filteredData = await this.getFilteredData(fullMaterialRequestData);
                    this.setState({ WOData: filteredData });
                });
                break;            
    
            default:
                break;
        }
    };

//Modified by BN 6/17/24. Fixed issue where My Work Order/All work order, Basin, and Status drop down was not filtered with search bar
    getFilterMtlRequest = async (data, m, b, s, z) => {
        const { user } = this.props;
        // console.log(z)
        let tempMtlRequest = data;
    
        const basins = b ? b.map(e => e.locationName).join(",") : "";
        const status = s ? s.map(e => e.fiixWOStatus).join(",") : "";
        const maintenance = z ? z.map(e => e.fixxMainTypeDesc).join(",") : "";
    
        // Fetch additional data for specific statuses
        if (status.length > 0) {
            const specificStatuses = ["Canceled", "Closed", "Deleted", "Completed"];
            const statusArray = status.split(",");
            
            for (let e of statusArray) {
                if (specificStatuses.includes(e)) {
                    const additionalData = await this.getWODataByStatus(e);
                    // console.log("finished")
                    tempMtlRequest = [...tempMtlRequest, ...additionalData];
                }
            }
        }
    
        // Filter by material request query
        if (m !== null) {
            const userName = getUserADAccount(user.account.userName).toLowerCase();
    
            if (m.value === 1) {
                tempMtlRequest = tempMtlRequest.filter(e => 
                    (e.createdBy || "").toLowerCase() === userName ||
                    (e.approvers || "").toLowerCase().includes(userName) ||
                    (e.assignedToADAccount || "").toLowerCase().includes(userName)
                );
            }else if (m.value === 3) { // Filter for Unassigned Work Orders
              tempMtlRequest = tempMtlRequest.filter(e => 
                  (e.fiixAssignedToUser || "").toLowerCase() === "" ||
                  e.fiixAssignedToUser === null
              );
          }
        }
        // Filter by maintenance

        if (maintenance.length > 0) {
          tempMtlRequest = tempMtlRequest.filter(e => maintenance.includes(e.maintenanceType));
      }
    
        // Filter by status
        if (status.length > 0) {
            tempMtlRequest = tempMtlRequest.filter(e => status.includes(e.fiixWOStatus));
        }
    
        // Filter by basin
        if (basins.length > 0) {
            tempMtlRequest = tempMtlRequest.filter(e => basins.includes(e.locationName));
        }
    
        return tempMtlRequest;
    };



    getDefaultMtlRequestList = async (data) => {
        const filteredData = await this.getFilterMtlRequest(data, this.state.selMtlRequestQuery, this.state.selBasin, this.state.selStatus, this.state.selMainType);
        // console.log('Filtered data:', filteredData);
        this.setState({
            WOData: filteredData // Set the filtered data to the WOData
        });
    };


    // setDefaultMtlRequestQuery = () => {
    //     const { isSecurityAdminLogin } = this.state;
    //     if (isSecurityAdminLogin === false) {
    //         this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[1] }, async () => {
    //             await this.getWOdata();
    //             let filteredData = await this.getFilteredData(this.state.fullMaterialRequestData);
    //             this.setState({ WOData: filteredData }, () => {
    //                 this.openWODetailsFromKey(filteredData);
    //             });
    //         });
    //     } else {
    //         this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[0] }, async () => {
    //             await this.getWOdata();
    //             let filteredData = await this.getFilteredData(this.state.fullMaterialRequestData);
    //             this.setState({ WOData: filteredData }, () => {
    //                 this.openWODetailsFromKey(filteredData);
    //             });
    //         });
    //     }
    // }
    setDefaultMtlRequestQuery = () => {
        const { isSecurityAdminLogin } = this.state
        if (isSecurityAdminLogin === false) {
            this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[1] }, () => {
                this.getWOdata()
            })
        }
        else {
            this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[0] }, () => {
                this.getWOdata()
            })
        }

    }

    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
        });
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
        });

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.WOData;
    }

    onGridRowSelected(s, e) {
        const { fiixWOID } = this.state
        const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                workOrderKey: dataItem.workOrderKey,
                woNumber: dataItem.woNumber,
                bigFormat: true,
                fiixWOId: dataItem.fiixWOId,
                maintenanceTypeID: dataItem.maintenanceTypeID,
                fiixWOStatus: dataItem.fiixWOStatus,
                isTyping: false
            })

            // console.log(dataItem)
        }
    }



    onGridRowSelected2(s,e) {
        if (e.isDoubleClick){
            this.setState({ modalWODetails: !this.state.modalWODetails  });
        }
      }
    toggleEdit = () => {
        this.setState({
            modalWODetails: !this.state.modalWODetails,
            isTyping: false
        })

    }

    componentDidMount = () => {
        this.getAssetList();
        this.getSecurity();
        this.getBasin();
        this.getStatus();
        this.getEmpList();
        this.getMainType();
        if (!this.state.isDataLoaded) {
            this.setDefaultMtlRequestQuery();
        }
        document.addEventListener('click', this.handleClickOutside);  /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
        window.addEventListener('resize', this.updateGridHeight);
        this.updateGridHeight();
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateGridHeight);
        document.removeEventListener('click', this.handleClickOutside);
    }


    handleClickOutside = (event) => {  /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
        // Ensure that this.searchInput.current is not null
        if (this.searchInput.current && !this.searchInput.current.contains(event.target)) {
          // Clicked outside the search input, so set isTyping to false
          this.setState({ isTyping: false });
        }
      };



    getEmpList = () => {
        let myURI = apiURL + "EmployeeDetails";


        fetch(myURI, { modes: "no-cors" })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    empList: this.createEmpList(json),
                    isEmpListLoaded: true,
                });
            });
    };

    createEmpList = (value) => {
        var tempList = [];
        value.map((e) => {
            e.value = e.employeeKey;
            e.label = e.empName;
            tempList.push(e);
        });

        return tempList;
    };


    getBasin = () => {
        let myURI = apiURL + 'Fiix/GetBasin'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    getStatus = () => {
        let myURI = apiURL + 'Fiix/Status'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    statusList: this.createStatusList(json),
                    isStatusLoaded: true
                })
                // console.log(json)
            });
    }
    getMainType = () => {

      let myURI = apiURL + 'Fiix/MainType'
      fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
  
          this.setState({
            isMainTypeListLoaded: true,
            mainTypeList: this.createMainTypeList(json)
          })
          // console.log(json)
        });
  
    }
    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.strName
            tempList.push(e)
        })

        return tempList
    }

    createStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.fiixWOStatus
            e.label = e.fiixWOStatus
            tempList.push(e)
        })

        return tempList
    }

    createMainTypeList = (value) => {
      var tempList = []
      value.map(e => {
        e.value = e.fixxMainTypeKey
        e.label = e.fixxMainTypeDesc
        tempList.push(e)
      })
  
      return tempList
    }


    newWO = () => {
        this.setState({
            workOrderKey: -1,
            qrValue: "",
            switchBool: null,
            locationKey: null,
            facilityKey: null,
            bigFormat: false,
            isTyping: false
        })
        this.toggleWODetails()
    }

    toggleWODetails = () => {
        this.setState({
            modalWODetails: !this.state.modalWODetails
        })
    }

    closeWODetailsForm = () => {

        this.setState({
            modalWODetails: !this.state.modalWODetails,
            // workOrderKey: -1, //Removed by BN 6/17/24. Fixes issue where form would not load contents if closed and reopened.
            openWODetails: false,
        })
    }


    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport, isTyping: false })
    }

    createReportParamter = () => {
        const { WOData, workOrderKey } = this.state
        let reportPara = []

        if (WOData && WOData.length > 0) {
            reportPara = [{
                "name": "WorkOrderKey",
                "value": workOrderKey
            }]
        }

        return reportPara
    }

    // resetFilter = async () => {
    //   const { fullMaterialRequestData, selMtlRequestQuery, selBasin, selStatus } = this.state;
    
    //   // Clear filters on the grid
    //   for (var i = 0; i < this.grid.actualColumns.count; i++) {
    //     this.grid.actualColumns.item(i).filter = null;
    //   }
    //   this.grid.selectedItems.clear();
    //   this.grid.filterExpressions.clear();
    
    //   if (this.searchInput.current) {
    //     this.searchInput.current.value = '';
    //   }
    
    //   // Update the searchText state and reapply selected filters
    //   this.setState({ searchText: '' });
    
    //   // Reapply selected filters for "My Work Orders"/"All Work Orders", "Basin", and "Status"
    //   let filteredData = await this.getFilterMtlRequest(fullMaterialRequestData, selMtlRequestQuery, selBasin, selStatus);
    
    //   // Update the state with the filtered data
    //   this.setState({ WOData: filteredData });
    // };

    
    /*Modified by BN 6/21/24. Clears Basin, Status, and grid filters */
    resetFilter = async () => {
      const { fullMaterialRequestData, selMtlRequestQuery } = this.state;
    
      // Clear filters on the grid
      for (var i = 0; i < this.grid.actualColumns.count; i++) {
        this.grid.actualColumns.item(i).filter = null;
      }
      this.grid.selectedItems.clear();
      this.grid.filterExpressions.clear();
    
      if (this.searchInput.current) {
        this.searchInput.current.value = '';
      }
    
      // Update the searchText state and clear basin and status filters
      this.setState({ searchText: '', selBasin: [], selStatus: [], selMainType: [] });
    
      // Reapply "My Work Orders"/"All Work Orders" filter
      let filteredData = await this.getFilterMtlRequest(fullMaterialRequestData, selMtlRequestQuery, [], []);
    
      // Update the state with the filtered data
      this.setState({ WOData: filteredData });
    };
    updateGridHeight = () => {
      const availableHeight = window.innerHeight * 0.65; // Calculate 60% of the window height
      this.setState({ gridHeight: availableHeight });
  }

    render() {
        const { WOData, isDataLoaded, windowHeight, workOrderKey, woNumber, qrValue,
            isAssetListLoaded, assetList, facilityKey, isSecurityLoaded,
            mtlRequestQueryList, selMtlRequestQuery, isSecurityAdminLogin, isBasinLoaded, fiixWOStatus,
            basinList, selBasin, searchText, bigFormat, isStatusLoaded, statusList, selStatus, locationKey, facilityID, switchBool, fiixWOId,
             isMainTypeSelected, maintenanceTypeID, isEmpListLoaded, empList, gridHeight, mainTypeList, selMainType, isMainTypeListLoaded} = this.state
        // const gridHeight = (windowHeight * 0.60) + "px"
        if (!isSecurityLoaded || !isBasinLoaded || !isAssetListLoaded ||!isStatusLoaded ||!isEmpListLoaded ||!isMainTypeListLoaded) {
            return (<div>Loading WO data.....</div>)
        } else
            return (
              <div>
                <FormGroup>
                  <Row form>
                    <Col sm={12}>
                      {/* <img
                        src={spade}
                        alt="spad"
                        style={{
                          width: "40px",
                          marginBottom: "10px",
                          marginRight: "10px",
                        }}
                      /> */}
                      {/* <Label>
                        <h2>ACE Work Order |</h2>
                      </Label> */}
                      <Button
                        outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.newWO}
                      >
                        <img
                          src={Plus}
                          alt="new"
                          style={{ width: "25px", margin: "0 5px 0 0" }}
                        />
                        New Workorder
                      </Button>{" "}
                      <Button
                        outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={() => {
                          this.setState(
                            { isFetchingWOData: false },
                            this.getWOdata
                          );
                        }}
                      >
                        <img
                          src={Refresh}
                          alt="Refresh"
                          style={{ width: "30px", margin: "0 5px 0 0" }}
                        />
                        Refresh
                      </Button>
                      <Button
                        outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.resetFilter}
                      >
                        <img
                          src={clearfilter}
                          alt="Clear Filter"
                          style={{ width: "30px" }}
                        />
                        Clear Filter
                      </Button>
                      <Button
                        outline
                        className="btn-no-border"
                        onClick={this.toggleReport}
                      >
                        <img
                          src={Revise}
                          alt="details"
                          style={{ width: "30px", margin: "0 5px 0 0" }}
                        />
                        View Report
                      </Button>
                      <Button
                        outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.saveWorkbook}
                      >
                        <img
                          src={Excel}
                          alt="excel"
                          style={{ width: "50px", margin: "0 0 0 0" }}
                        />
                        Export To Excel
                      </Button>{" "}
                      <Button
                        outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.toggleQRCodeScanner}
                        hidden
                      >
                        <img
                          src={Plus}
                          alt="new"
                          style={{ width: "35px", margin: "0 5px 0 0" }}
                        />
                        QR Code
                      </Button>{" "}
                      {/* Modified by BN 6/17/24. Disable Edit button if Work Order Key < 0 */}
                      <Button
                        outline
                        color="secondary"
                        className="btn-no-border"
                        disabled={workOrderKey == null || workOrderKey < 0}
                        onClick={this.toggleEdit}
                        hidden
                      >
                        <img
                          src={MenuIcon}
                          alt="Revise"
                          style={{ width: "30px", margin: "0 5px 0 0" }}
                        />
                        Edit
                      </Button>

                    </Col>
                  </Row>

                  <ColoredLine color="grey" />
                </FormGroup>

                <FormGroup>
                  <Row form>
                    <Col md={2}>
                      <Row form>
                        <Col md={12}>
                          <Label>Filter</Label>

                          <div>
                            <Select
                              className="alway-in-between-more"
                              options={mtlRequestQueryList}
                              value={selMtlRequestQuery}
                              defaultValue={selMtlRequestQuery}
                              onChange={(e) =>
                                this.onTextChange("mtlRequestQuery", e, e)
                              }
                            ></Select>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={2}>
                      <Row form>
                        <Col md={12}>
                          <Label for="typeKey">Basin</Label>
                          <Select
                            options={basinList}
                            value={selBasin}
                            defaultValue={selBasin}
                            onChange={(e) => this.onTextChange("basin", e, e)}
                            isClearable={true}
                            // width="200px"
                            isMulti
                          ></Select>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={2}>
                      <Col md={12}>
                        <Label for="typeKey">Work Order Status</Label>

                        <Select
                          options={statusList}
                          value={selStatus}
                          defaultValue={selStatus}
                          onChange={(e) => this.onTextChange("status", e, e)}
                          isClearable={true}
                          isMulti
                        ></Select>
                      </Col>
                    </Col>

                    
                    <Col md={2}>
                      <Col md={12}>
                        <Label for="typeKey">Maintenance Type</Label>

                        <Select
                          options={mainTypeList}
                          value={selMainType}
                          defaultValue={selMainType}
                          onChange={(e) => this.onTextChange("maintenance", e, e)}
                          isClearable={true}
                          isMulti
                        ></Select>
                      </Col>
                    </Col>

                    <Col md={4}>
                      <Col md={12}>
                        <Label for="typeKey"> </Label>
                        <input
                          width="75%"
                          className="my-custom-reactstrap-input" /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
                          placeholder="Type here to search......."
                          ref={this.searchInput}
                          onBlur={this.setFocusToSearchInput}
                          onChange={(e) =>
                            this.onTextChange2("searchText", e.target.value, e)
                          }
                        ></input>
                      </Col>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row form style={{ zIndex: 0, position: "relative" }}>
                    <IgrDataGrid
                      ref={this.onGridRef}
                      // height={gridHeight}
                      height={`${gridHeight}px`} // Use dynamic grid height
                      width={"100%"}
                      // dataSource={WOData}
                      isColumnOptionsEnabled="true"
                      //filterUIType="FilterRow"
                      cellTextStyle="14px Calibri"
                      headerTextStyle="14px Calibri"
                      rowHeight={"25"}
                      editMode={0}
                      // dataSource={WOData.filter(rec => ((rec.woNumber || '').toLowerCase().includes(searchText)) ||
                      //     (rec.assetID || '').toLowerCase().includes(searchText) ||
                      //     (rec.facilityName || '').toLowerCase().includes(searchText) ||
                      //     (rec.woPriority || '').toLowerCase().includes(searchText) ||
                      //     (rec.maintenanceType || '').toLowerCase().includes(searchText) ||
                      //     (rec.fiixWOStatus || '').toLowerCase().includes(searchText) ||
                      //     (rec.reqestDateTime || '').toLowerCase().includes(searchText) ||
                      //     (rec.locationID || '').toLowerCase().includes(searchText) ||
                      //     (rec.locationName || '').toLowerCase().includes(searchText) ||
                      //     (rec.workOrderDesc || '').toLowerCase().includes(searchText) ||
                      //     (rec.fiixAssignedToUser || '').toLowerCase().includes(searchText) ||
                      //     (rec.workOrderDesc || '').toLowerCase().includes(searchText) ||
                      //     (rec.workdaySiteID || '').toLowerCase().includes(searchText) ||
                      //     (rec.qrCodeValue || '').toLowerCase().includes(searchText) ||
                      //     (rec.fiixIssueType || '').toLowerCase().includes(searchText) ||
                      //     (rec.createdByName || '').toLowerCase().includes(searchText) ||
                      //     (rec.assetName || '').toLowerCase().includes(searchText))}
                      dataSource={WOData}
                      autoGenerateColumns="false"
                      selectionMode="SingleRow"
                      // isGroupByAreaVisible="true"
                      // isGroupCollapsable="true"
                      cellClicked={this.onGridRowSelected2}
                      selectedKeysChanged={this.onGridRowSelected}
                      //cellBackground={"#ffbfbf"}
                    >
                      <IgrTextColumn
                        field="woNumber"
                        headerText="WO No"
                        width="*>120"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="fiixWOStatus"
                        headerText="Status"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="assetName"
                        headerText="Asset"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="qrCodeValue"
                        headerText="QR Code"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="facilityName"
                        headerText="Facility"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="woPriority"
                        headerText="Priority"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTemplateColumn
                        field="maintenanceType"
                        headerText="Maintenance Type"
                        width="*>250"
                        cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="fiixIssueType"
                        headerText="Issue Type"
                        width="*>250"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="workOrderDesc"
                        headerText="Description"
                        width="*>400"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="assetID"
                        headerText="Asset ID"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="createdByName"
                        headerText="Requested By"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="reqestDateTime"
                        headerText="Request Date"
                        width="*>200"
                        cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="modifiedBy"
                        headerText="Modified By"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="modifiedDateTime"
                        headerText="Modified Date"
                        width="*>200"
                        cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="assignedToUserName"
                        headerText="Assigned To"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="locationID"
                        headerText="Location ID"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="locationName"
                        headerText="Location"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="workdaySiteID"
                        headerText="Workday Site ID"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="estimateCost"
                        headerText="Est Cost $"
                        width="*>170"
                        cellUpdating={this.onStatusCellUpdating}
                        positivePrefix="$"
                        isHidden
                      />

                      <IgrNumericColumn field="workOrderKey" isHidden />
                    </IgrDataGrid>
                  </Row>
                </FormGroup>

                <div>
                  <Modal
                    isOpen={this.state.modalWODetails}
                    toggle={() => this.toggleWODetails()}
                    // size={bigFormat ? "xl" : "med"}
                    className={bigFormat ? "custom-modal-workorder" : "med"}
                    backdrop={"static"}
                    // onClosed={() => this.getWOdata()}
                  >
                    <ModalHeader
                      toggle={() => this.closeWODetailsForm()}
                      //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                    >
                      {workOrderKey < 0 ? "New WO" : "WO No " + woNumber}
                    </ModalHeader>
                    <ModalBody className="custom-modal-body">
                      <WORequest
                        toggle={this.closeWODetailsForm}
                        //setFormHeader={this.setAFEDetailsFormHeader}
                        isMainTypeSelected
                        qrValue={qrValue}
                        user={this.props.user}
                        workOrderKey={workOrderKey}
                        facilityKey={facilityKey}
                        locationKey={locationKey}
                        facilityID={facilityID}
                        switchBool={switchBool}
                        fiixWOId={fiixWOId}
                        maintenanceTypeID={maintenanceTypeID}
                        refreshData={() => {
                          // Ensure modal state is preserved
                          this.setState(
                            { isFetchingWOData: false },
                            this.getWOdata
                          );
                        }}
                        empList={empList}
                        woNumber={woNumber}
                        fiixWOStatus={fiixWOStatus}
                        // getSelEmpList= {this.getSelEmpList}
                      ></WORequest>
                    </ModalBody>
                  </Modal>
                </div>

                <div>
                  <Modal
                    isOpen={this.state.modalQRCodeScanner}
                    toggle={() => this.toggleQRCodeScanner()}
                    size="sm"
                    backdrop={"static"}
                  >
                    <ModalHeader toggle={() => this.toggleQRCodeScanner()}>
                      QR Code Scaner
                    </ModalHeader>
                    <ModalBody>
                      <QrReader
                        constraints={{ facingMode: "environment" }}
                        onResult={(result, error) => {
                          if (!!result) {
                            this.setQRCodeValue(result?.text);
                          }

                          if (!!error) {
                            console.info(error);
                          }
                        }}
                        style={{ width: "100%" }}
                      />
                    </ModalBody>
                  </Modal>
                </div>

                <div>
                  <Modal
                    isOpen={this.state.modalReport}
                    toggle={() => this.toggleReport()}
                    size={"xl"}
                    centered
                  >
                    <ModalHeader toggle={() => this.toggleReport()}>
                      {" "}
                      Work Order Report{" "}
                    </ModalHeader>
                    <ModalBody>
                      <PowerBIReportViewer
                        reportName={"WorkOrderReport"}
                        reportParameters={this.createReportParamter()}
                        toggle={this.toggleReport}
                      ></PowerBIReportViewer>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            );




    }
// Function to calculate luminance of a color
 getLuminance = (color) => {
  const rgb = color.match(/\w\w/g).map((c) => parseInt(c, 16) / 255);
  const luminance = rgb.map((c) => {
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  }).reduce((a, b, i) => a + b * [0.2126, 0.7152, 0.0722][i], 0);
  return luminance;
}

// Define a function to get the color based on maintenance type
 getColorForMaintenanceType = (maintenanceType) => {
  switch (maintenanceType) {
      case 'Fleet (Truck)':
          return '#1B65C4'; // Blue
      case 'Other (Valves, Fittings etc.)':
          return '#CC4140'; // Red
      case 'Cameras':
          return '#A2E09D'; // Light Green
      case 'Safety (QHSE)':
          return '#FF9900'; // Orange
      case 'Pumps':
          return '#1E8A0E'; // Green
      case 'I&E':
          return '#D2CA4E'; // Yellow
      case 'Tower Comms':
          return '#967855'; // Brown
      case 'Pipeline':
          return '#053A78'; // Dark Blue
      case 'Measurement':
          return '#386161'; // Teal
      case 'SCADA':
          return '#D36E87'; // Pink
      case 'Materials Management':
          return '#363636'; // Dark Gray
      case 'Tanks':
          return '#B2F0F7'; // Light Blue
      case 'VFD':
          return '#FFFFFF'; // White
      case 'Lightning Inspection':
          return '#FFFBC2'; // Light Yellow
      case 'Valve':
          return '#8F3499'; // Purple
      case 'Heavy Equipment':
          return '#694A0D'; // Dark Brown
      default:
          return 'transparent'; // Default color
  }
}

 onStatusCellUpdating = (s, e) => {
  const content = e.content;
  const info = e.cellInfo;
  const item = info.rowItem;

  let link;
  if (content.childElementCount === 0) {
      link = document.createElement("label");
      content.style.fontFamily = "Verdana";
      content.style.fontSize = "13px";
      content.style.margin = "0px -20px -10px -12px";
      content.style.padding = "0px 10px 10px 10px";
      content.appendChild(link);
  } else {
      link = content.children[0];
      content.style.background = "transparent";
      if (s.field !== 'reqestDateTime' && s.field !== "modifiedBy" && s.field !=="modifiedDateTime") {
          const backgroundColor = this.getColorForMaintenanceType(item.maintenanceType);
          content.style.background = backgroundColor;
          const luminance = this.getLuminance(backgroundColor);
          if (luminance > 0.5) {
              link.style.color = 'black'; // Dark text on light background
          } else {
              link.style.color = 'white'; // Light text on dark background
          }
      }
  }

  switch (s.field) {
      case 'woNumber':
          link.textContent = item.woNumber;
          break;
      case 'fiixWOStatus':
          link.textContent = item.fiixWOStatus;
          break;
      case 'assetName':
          link.textContent = item.assetName;
          break;
      case 'qrCodeValue':
          link.textContent = item.qrCodeValue;
          break;
      case 'facilityName':
          link.textContent = item.facilityName;
          break;
      case 'woPriority':
          link.textContent = item.woPriority;
          break;
      case 'maintenanceType':
          link.textContent = item.maintenanceType;
          break;
      case 'fiixIssueType':
          link.textContent = item.fiixIssueType;
          break;
      case 'workOrderDesc':
          link.textContent = item.workOrderDesc;
          break;
      case 'assetID':
          link.textContent = item.assetID;
          break;
      case 'createdByName':
          link.textContent = item.createdByName;
          break;
      case 'modifiedBy':
        link.textContent = item.modifiedBy
        ? item.modifiedBy.replace(".", " ")
        : "";
          break;    
      case 'modifiedDateTime':
    if (item.modifiedDateTime) {
        var date = new Date(item.modifiedDateTime);
        link.textContent = `${date.toLocaleString("en-US", {
            timeZone: "America/Chicago",
        })}`;
    } else {
        link.textContent = "";
    }
    break;       
    case 'reqestDateTime':
      if (item.reqestDateTime) {
          var date = new Date(item.reqestDateTime);
          link.textContent = `${date.toLocaleString("en-US", {
              timeZone: "America/Chicago",
          })}`;
      } else {
          link.textContent = "";
      } 
      break;
      case 'locationID':
          link.textContent = item.locationID;
          break;
      case 'assignedToUserName':
          link.textContent = item.assignedToUserName;
          break;
      case 'locationName':
          link.textContent = item.locationName;
          break;
      case 'workdaySiteID':
          link.textContent = item.workdaySiteID;
          break;
      case 'supplierID':
          link.textContent = item.supplierID;
          break;
      case 'estimateCost':
          link.textContent = item.estimateCost;
          break;
      default:
  }
}


    // onStatusCellUpdating(s, e) {
    //     const content = e.content;
    //     const info = e.cellInfo;
    //     const item = info.rowItem;

    //     let link;
    //     if (content.childElementCount === 0) {
    //         link = document.createElement("label");
    //         let cell = document.createElement("div");

    //         content.style.fontFamily = "Verdana";
    //         content.style.fontSize = "13px";

    //         content.style.margin = "0px -20px -10px -12px";
    //         content.style.padding = "0px 10px 10px 10px";

    //         content.appendChild(link);

    //     }
    //     else {
    //         link = content.children[0];

    //         content.style.background = "transparent";
    //         if (item !== null & item !== undefined) {
    //             if ((item.woPriority || "").includes("Urgent") && item.fiixWOStatus !== "Completed") {
    //                 content.style.background = "#89b6ef";
    //             }
    //             else {

    //                 // if (item.fiixWOStatus === "Completed") {
    //                 //     content.style.background = "#A2E09D";
    //                 // }

    //                 // else if (item.fiixWOStatus === "Canceled") {
    //                 //     content.style.background = "#f88379";
    //                 // }
    //                 // else if (item.fiixWOStatus === "Rejected") {
    //                 //     content.style.background = "#f4a460";
    //                 // }
    //                 // else if (item.fiixWOStatus === "Need Revision") {    //Added by BN 6/12/24 Per Josh Request
    //                 //     content.style.background = "#f4a460";
    //                 // }
    //                 // else if (item.fiixWOStatus === "Assigned") {
    //                 //     content.style.background = "#f0f5b0";
    //                 // }
    //                 // else if (item.fiixWOStatus === "Open") {
    //                 //     content.style.background = "#f0f5b0";
    //                 // }
    //                 // else if (item.fiixWOStatus === "Work In Progress") {
    //                 //     content.style.background = "#f0f5b0";
    //                 // }
    //                 // else {
    //                 //     content.style.background = "transparent";
    //                 // }
    //                 content.style.background = "transparent";
    //             }

    //         }


    //     }




    //     switch (s.field) {
    //         case 'woNumber':
    //             link.textContent = item.woNumber;

    //             break;
    //         case 'fiixWOStatus':
    //             link.textContent = item.fiixWOStatus;
    //             break;
    //         //Added by BN232803
    //         case 'assetName':
    //             link.textContent = item.assetName;
    //             break;
    //         case 'qrCodeValue':
    //             link.textContent = item.qrCodeValue;


    //             break;
    //         case 'facilityName':
    //             link.textContent = item.facilityName;

    //             break;
    //         case 'woPriority':
    //             link.textContent = item.woPriority;
    //             if ((item.woPriority).includes("Urgent") && item.fiixWOStatus != "Completed") {
    //                 content.style.background = "#89b6ef";
    //             }

    //             break;
    //         case 'maintenanceType':
    //             link.textContent = item.maintenanceType;
    //             break;

    //         case 'fiixIssueType':
    //             link.textContent = item.fiixIssueType;
    //             break;
    //         case 'workOrderDesc':
    //             link.textContent = item.workOrderDesc;

    //             break;

    //         case 'assetID':
    //             link.textContent = item.assetID;

    //             break;
    //         case 'createdByName':
    //             link.textContent = item.createdByName;

    //             break;
    //         case 'reqestDateTime':
    //             var date = new Date(item.reqestDateTime);
    //             link.textContent = date.toLocaleDateString();

    //             break;
    //         case 'locationID':
    //             link.textContent = item.locationID;

    //             break;
    //         case 'assignedToUserName':
    //             link.textContent = item.assignedToUserName;

    //             break;

    //         case 'locationName':
    //             link.textContent = item.locationName;

    //             break;
    //         case 'workdaySiteID':
    //             link.textContent = item.workdaySiteID;

    //             break;
    //         case 'supplierID':
    //             link.textContent = item.supplierID;

    //             break;
    //         case 'estimateCost':
    //             link.textContent = item.estimateCost;

    //             break;

    //         default:

    //     }

    // }
    saveWorkbook() {

        var actCols = this.grid.actualColumns
        let gridDts = this.grid.dataSource

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

            }
        }

        ExcelUtility.save(wb, "WorkOrder");
    }

}