
import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


const EventDetailModal = (props) => {

    const {buttonColor,
          buttonLabel,
          className,
          formName,
          facilityEventKey} = props

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return(
        <div>
            <Button outline = {facilityEventKey > 0 ? false : true} 
                    color={buttonColor}
                    onClick={toggle} style={{ margin: "0 5px 0 5px" }} >
                {buttonLabel}
            </Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>
                    Event details show here
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>OK</Button>
                    <Button color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EventDetailModal;