import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback } from 'reactstrap';
import { IgrDataGrid, IgrTemplateColumn, IgrTextColumn, IgrNumericColumn } from 'igniteui-react-grids';
import DatePicker from 'reactstrap-date-picker';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiHeader } from '../../resources/apiURL';
import { getUserADAccount } from '../../resources/utility';
import { IgrDataGridModule } from 'igniteui-react-grids'; 
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import moment from 'moment';
import Datetime from 'react-datetime';
import Select from 'react-select';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

export default class TaskAndInstructions extends Component {
    static displayName = TaskAndInstructions.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        // this.cellClicked={onGridRowSelected2}
        this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);
        // this.toggleCommentModal = this.toggleCommentModal.bind(this); // Bind the method
        
        this.state = {
            user: props.user,
            readOnly: props.readOnly,
            workOrderKey: props.workOrderKey,
            woTaskAndInstructions: [],
            isWOTaskAndInstructionsLoaded: false,
            modalComment: false,
            cmtDate: new Date().toISOString(),
            operationCommentKey: -1,
            comments: ' ',
            fiixWOId: props.fiixWOId,
            createdBy: "",
            expandedComment: "", // Add this
            isCommentModalOpen: false, // Add this
            fiixWOStatus: props.fiixWOStatus,
            mergedTask: [],
            modalTask: false,
            setModalTask: false,
            partsAndCostDataGrid: props.partsAndCostDataGrid,
            partsAndCostStatus: props.partsAndCostStatus,
            empList: props.empList,
            cachedTasks: [],
            statusChange:props.statusChange,
            partsAndCostDataByKey: [], 
            isPartsAndCostDataByKeyLoaded: false,
            refreshData: props.refreshData,
            partsAndCostKey: null,
            partsAndCostData:{
                partsAndCostKey: -1, // or null
                workOrderKey: null,
                costTypeKey: null,
                itemKey: null,
                description: "",
                quantity: 0.00,
                unitCost: 0.00,
                uomKey: null,
                completedDate: null,
                createdBy: getUserADAccount(this.props.user.account.userName),
                instructions: ""
                ,assignedDate: moment(),
                statusKey: 0
              }
        }
    }

    getPartsAndCost = (workOrderKey) => {
        // const { woNumber } = this.state
        let myURI = apiURL + 'Fiix/PartsAndCost/azureAD/'+ this.props.user.account.userName + '/workOrderKey/' + workOrderKey;
        // console.log(woNumber)
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => {
            this.setState({
              isPartsAndCostLoaded: true,
              partsAndCostData: json,
              partsAndCostDataGrid: json
            })
           console.log(json)
          });
    
      }

      getPartsAndCostDataByKey = ()=>{
        const {partsAndCostKey} = this.state
        if (partsAndCostKey > 0)
        {
            let myURI = apiURL + 'Fiix/PartsAndCost/partsAndCostKey/'+ partsAndCostKey
            // console.log(woNumber)
            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
              .then(res => res.json())
              .then(json => {
                this.setState({
                  partsAndCostDataByKey: [],
                  isPartsAndCostDataByKeyLoaded: true
                })
               console.log(json)
              });
        }
        else{
            const newWOPartsAndCost = this.createWOPartsAndCost()
            this.setState({
              isPartsAndCostDataByKeyLoaded: true,
              partsAndCostByKey: newWOPartsAndCost,
              readOnly: false
            })
        }

      }

      createWOPartsAndCost = () => {
        const {workOrderKey, partsAndCostDataByKey} = this.state
        const {user} = this.props
        const newWORecord = ({
            "workOrderKey": workOrderKey,
            "costTypeKey": 2,
            "itemKey": partsAndCostDataByKey.itemKey,
            "description": partsAndCostDataByKey.description,
            "quantity": partsAndCostDataByKey.quantity,
            "unitCost": partsAndCostDataByKey.unitCost,
            "uomKey": null,
            "completedDate": partsAndCostDataByKey.completedDate,
            "createdBy": getUserADAccount(user.account.userName),
            "instructions": partsAndCostDataByKey.instructions
            ,"assignedDate": partsAndCostDataByKey.assignedDate,
            "statusKey": partsAndCostDataByKey.statusKey
            
        })
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.woTaskAndInstructions;
    }

    componentDidMount() {
        this.getPartsAndCostDataByKey();
    }

    // getWOTaskAndInstructions = () => {
    //     const { workOrderKey } = this.state;
    //     let myURI = apiURL + 'Fiix/TaskAndInstructions/' + workOrderKey;
    
    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 woTaskAndInstructions: json,
    //                 isWOTaskAndInstructionsLoaded: true
    //             });
    //         });
    // }

    // toggleComments = () => {
    //     this.setState({ modalComment: !this.state.modalComment });
    // }

    // toggleCommentsNew = () => {
    //     this.setState({
    //         comments: " ",
    //         createdBy: "",
    //         cmtDate: new Date().toISOString(),
    //         operationCommentKey: -1,
    //         modalComment: !this.state.modalComment
    //     });
    // }

    // toggleCommentOk = () => {
    //     if (this.saveWOComment()) {
    //         this.getWOTaskAndInstructions();
    //         this.setState({
    //             modalComment: !this.state.modalComment,
    //             comments: " "
    //         });
    //         // this.syncWOCommentTOFiix();
    //     }
    // }

    // saveWOComment = () => {
    //     const { operationCommentKey } = this.state;
    //     if (this.validateData()) {
    //         if (operationCommentKey > 0) {
    //             if (this.updateComment()) {
    //                 this.setState({ operationCommentKey: -1 });
    //             }
    //         } else {
    //             const addDTO = this.createWOCommentForCreateDTO();
    //             let myURI = apiURL + 'Fiix/Comment';
    //             fetch(myURI, apiPOSTHeader(addDTO))
    //                 .then(async response => {
    //                     const rspData = response.text();
    //                     if (!response.ok) {
    //                         const error = (rspData && rspData.message) || response.status;
    //                         return false;
    //                     }
    //                     this.getWOTaskAndInstructions();
    //                 })
    //                 .catch(error => {
    //                     this.getWOTaskAndInstructions();
    //                     return false;
    //                 });
    //             this.getWOTaskAndInstructions();
    //         }
    //         return true;
    //     }
    //     return false;
    // }

    // syncWOCommentTOFiix = () => {     
    //     const { workOrderKey, fiixWOId } = this.state;
    //     if (workOrderKey > 0) {
    //         let myURI = apiURL + 'Fiix/Sync/WBCommentsToFiix/' + fiixWOId;
    //         fetch(myURI, apiHeader('GET'), { modes: 'no-cors' });
    //     } 
    // }

    // updateComment = () => {
    //     const uptDTO = this.createWOCommentForUpdateDTO();
    //     let myURI = apiURL + 'Fiix/Comment';

    //     fetch(myURI, apiPUTHeader(uptDTO))
    //         .then(async response => {
    //             const rspData = response.text();
    //             if (!response.ok) {
    //                 const error = (rspData && rspData.message) || response.status;
    //                 return false;
    //             }
    //             this.getWOTaskAndInstructions();
    //         })
    //         .catch(error => {
    //             this.getWOTaskAndInstructions();
    //             return false;
    //         });
    //     this.getWOTaskAndInstructions();
    //     return true;
    // }

    // validateData = () => {
    //     var { comments } = this.state;
    //     comments = comments.trim();
    //     if (comments !== "") {
    //         return true;
    //     } else {
    //         this.setState({ comments: comments });
    //     }
    // }

    // createWOCommentForCreateDTO = () => {
    //     const { cmtDate, comments, user, workOrderKey } = this.state;
    //     const afeCmtDTO = {
    //         "commentTypeKey": 10, 
    //         "compObjectKey": workOrderKey, 
    //         "workOrderKey": null,
    //         "commentDate": cmtDate,
    //         "comments": comments,
    //         "createdBy": getUserADAccount(user.account.userName)
    //     };
    //     return afeCmtDTO;
    // }

    // createWOCommentForUpdateDTO = () => {
    //     const { operationCommentKey, comments, user } = this.state;
    //     const opsCmtDTO = {
    //         "operationCommentKey": operationCommentKey,
    //         "comments": comments,
    //         "modifiedBy": getUserADAccount(user.account.userName)
    //     };
    //     return opsCmtDTO;
    // }

    // onGridRowSelected = (s, e) => {
    //     if (e.addedKeys.count > 0) {
    //         let clickedRow = e.addedKeys.item(0);
    //         let dataItem = clickedRow.value[0];
    //         this.setState({
    //             createdBy: dataItem.createdBy,
    //             comments: dataItem.comments,
    //             cmtDate: dataItem.commentDate,
    //             operationCommentKey: dataItem.operationCommentKey,
    //             expandedComment: dataItem.comments,
    //         });
    //     }
    // }

    // toggleCommentModal = () => {
    //     this.setState({ isCommentModalOpen: !this.state.isCommentModalOpen });
    // }

    // onTextChange = (fieldName, fieldValue, e) => {
    //     this.setState({ comments: fieldValue });
    // }

    onTextChangeTask = (fieldName, fieldValue, e) => {
        console.log(`Field Name: ${fieldName}`);
        console.log(`Field Value: ${fieldValue}`);
        console.log(`Value: ${e?.value}`);
    
        this.setState((prevState) => {
          let newValue = fieldValue;
          switch (fieldName) {
            case 'itemKey':
              newValue = e ? e.value : '';
              break;
            case 'instructions':
              newValue = fieldValue;
              break;
            case 'assignedDate':
              // newValue = fieldValue ? moment(fieldValue).format('YYYY-MM-DDTHH:mm:ss') : "";
              newValue = fieldValue;
              break;
            default:
              break;
          }
    
          return {
            partsAndCostData: {
              ...prevState.partsAndCostData,
              [fieldName]: newValue,
            },
          };
        });
      };

    // handleChange = (v, f) => {
    //     this.setState({
    //         cmtDate: v
    //     });
    // }

    // Added by BN 6/4/24 Function to get date central time
    getDate = () => {
        const { cmtDate } = this.state;
        const options = {
            timeZone: 'America/Chicago',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const centralTimeString = cmtDate.toLocaleString('en-US', options);         
        return centralTimeString;
    }

    
     toggleTask = () => {
        // setModalTask(!modalTask);
        const {modalTask} = this.state
        this.setState({modalTask: !modalTask})
      };

     handleCancel = () => {
    // Clear the cached tasks
    this.setState({cachedTasks: []})
    // Call the parent's toggle function to close the modal
    this.toggleTask();
};

updatePartsAndCost = () => {
    const {workOrderKey} = this.state
    const updatePartsAndCostDTO = this.updatePartsAndCostDTO()

    let myURI = apiURL + 'Fiix/WorkOrderPartsAndCost'
    fetch(myURI, apiPUTHeader(updatePartsAndCostDTO))
      .then(async response => {
        const rspData = response.text();
        if (!response.ok) {

          const error = (rspData && rspData.message) || response.status;
          rspData.then(t => {
            this.setState({
              msgBody: t,
              msgHeader: "Error",
              modalMsg: !this.state.modalMsg
            })
          })
          return Promise.reject(error);

        }
        console.log(workOrderKey)
        this.props.refreshData(workOrderKey)
      })
      .catch(error => {
        // this.getMaterialRequestDetails()


        // console.log("PUT Error: " + error);
      })
  }

  updatePartsAndCostDTO = () => {
    const {partsAndCostData, partsAndCostKey, workOrderKey} = this.state
    const { user } = this.props
    const woUpdatePartsAndCostDTO = {
        "partsAndCostKey": partsAndCostKey, 
        "workOrderKey": workOrderKey,
        "costTypeKey": 2,
        "itemKey": partsAndCostData.itemKey,
        "description": partsAndCostData.description,
        "quantity": partsAndCostData.quantity,
        "unitCost": partsAndCostData.unitCost,
        "uomKey": null,
        "completedDate": partsAndCostData.completedDate,
        "createdBy": getUserADAccount(user.account.userName),
        "instructions": partsAndCostData.instructions
        ,"assignedDate": partsAndCostData.assignedDate,
        "statusKey": partsAndCostData.statusKey

    }
    console.log(woUpdatePartsAndCostDTO)
    return woUpdatePartsAndCostDTO

  }

       saveTask = () => {
        const {partsAndCostData} = this.state
        if (partsAndCostData.partsAndCostKey < 0) { // This means it's a new task
          this.addTaskToCache(); // Cache the task instead of immediately saving it to the database
          this.toggleTask(); // Close the Add Task modal
        } else {
            console.log("Update hit")
          // You can implement logic here for updating existing tasks if necessary
          this.updatePartsAndCost()
          this.toggleTask(); // Close the Add Task modal
        }
      };
//        addTaskToCache = () => {
//         const {partsAndCostData, partsAndCostStatus, empList} = this.state
//         const woPartsAndCostCreateDTO = this.createTaskDTO();
        
//         // Log partsAndCostStatus and statusKey to debug
//         console.log("Parts and Cost Status Array: ", partsAndCostStatus);
//         console.log("Status Key from partsAndCostData: ", partsAndCostData.statusKey);
        
//         // Find the corresponding user name (description) from the empList using the itemKey
//         const selectedEmp = empList.find(emp => emp.value === partsAndCostData.itemKey);
        
//         // Log selectedEmp to make sure user is being selected correctly
//         console.log("Selected Employee: ", selectedEmp);
      
//         // Find the corresponding status description from partsAndCostStatus using statusKey
//         const selectedStatus = partsAndCostStatus.find(status => status.woPartsAndCostStatusKey === partsAndCostData.statusKey);
        
//         // Log selectedStatus to check if the correct status is being found
//         console.log("Selected Status: ", selectedStatus);
      
//         // Add the description (user name) and status description to the cached task
// // Add the description (user name) and status description to the cached task
//         const taskWithDescriptionAndStatus = {
//             ...woPartsAndCostCreateDTO,
//             description: selectedEmp ? selectedEmp.empName : "Unknown User", // Use the found user or a fallback
//             status: selectedStatus ? selectedStatus.woPartsAndCostStatusDesc : "Unknown Status" // Use the found status or a fallback
//         };
        
//         // Add task to local cache (this.setState version)
//         this.setState((prevState) => ({
//             cachedTasks: [...prevState.cachedTasks, taskWithDescriptionAndStatus],
//         }));
        
//         this.toggleTask(); // Close the modal after adding the task
//       };

addTaskToCache = () => { 
    const { partsAndCostData, partsAndCostStatus, empList } = this.state;
    const woPartsAndCostCreateDTO = this.createTaskDTO();
    
    // Find the corresponding user name (description) from the empList using the itemKey
    const selectedEmp = empList.find(emp => emp.value === partsAndCostData.itemKey);
    
    // Find the corresponding status description from partsAndCostStatus using statusKey
    const selectedStatus = partsAndCostStatus.find(status => status.woPartsAndCostStatusKey === partsAndCostData.statusKey);
    
    // Add the description (user name) and status description to the cached task
    const taskWithDescriptionAndStatus = {
        ...woPartsAndCostCreateDTO,
        description: selectedEmp ? selectedEmp.empName : "Unknown User",
        status: selectedStatus ? selectedStatus.woPartsAndCostStatusDesc : "Unknown Status"
    };

    this.setState((prevState) => ({
        cachedTasks: [...prevState.cachedTasks, taskWithDescriptionAndStatus],
    }));
    
    
    // Pass the task to the parent component using the callback prop
    this.props.onAddTasksToCache(taskWithDescriptionAndStatus);
    
    this.toggleTask(); // Close the modal after adding the task
};

       createTaskDTO = () => {
        const {partsAndCostData, workOrderKey} = this.state
        const {user} = this.props
        const woPartsAndCostCreateDTO = {
          "workOrderKey": workOrderKey,
          "costTypeKey": 2,
          "itemKey": partsAndCostData.itemKey,
          "description": partsAndCostData.description,
          "quantity": partsAndCostData.quantity,
          "unitCost": partsAndCostData.unitCost,
          "uomKey": null,
          "completedDate": partsAndCostData.completedDate,
          "createdBy": getUserADAccount(user.account.userName),
          "instructions": partsAndCostData.instructions
          ,"assignedDate": partsAndCostData.assignedDate,
          "statusKey": partsAndCostData.statusKey
        }
        // console.log(woPartsAndCostCreateDTO)
        return woPartsAndCostCreateDTO;
      }

  
      getMergedData = () => {
        const { partsAndCostDataGrid } = this.props;
        const { cachedTasks } = this.state;
        return [...partsAndCostDataGrid, ...cachedTasks];
      };

      
onGridRowSelected(s, e) {
    const content = e.content;
    if (e.addedKeys.count > 0) {
        let clickedRow = e.addedKeys.item(0)
        let dataItem = clickedRow.value[0]
        this.setState({
            workOrderKey: dataItem.workOrderKey,
            partsAndCostKey: dataItem.partsAndCostKey,
            costTypeKey: dataItem.costTypeKey,
            partsAndCostData: dataItem
        })
  
        //  console.log(dataItem)
    }
  }

      onGridRowSelected2 = (s, e) => {
        if (e.isDoubleClick) {
            this.toggleTask();
       
        }
      }
      


    render() {
        const { user, isWOTaskAndInstructionsLoaded, readOnly, 
            operationCommentKey, comments, woTaskAndInstructions, fiixWOId, createdBy, expandedComment, isCommentModalOpen, fiixWOStatus,
             partsAndCostDataGrid, modalTask, partsAndCostData, empList, cachedTasks, statusChange, partsAndCostDataByKey, isPartsAndCostDataByKeyLoaded } = this.state;
        const mergedData = this.getMergedData();
                 
        if (!isPartsAndCostDataByKeyLoaded) {
            return (<div>Loading WO ......</div>);
        } else {
            return (
                <div>
         <FormGroup>
              <Row
                form
                style={{
                  zIndex: 0,
                  position: "relative",
                  marginLeft: "0",
                  marginTop: "20px",
                }} // Adjusted marginTop here
              >
                <Button color="success" onClick={this.toggleTask} hidden={statusChange == "Work In Progress"} >
                  Add Task
                </Button>
              </Row>
              <Row
                form
                style={{ zIndex: 0, position: "relative", marginTop: "10px" }}
              >
                <IgrDataGrid
                  height={"250px"}
                  width={"100%"}
                  className="scrollable-grid"
                  isColumnOptionsEnabled="true"
                  editMode={0}
                //dataSource={partsAndCostDataGrid}
                  dataSource={mergedData}
                  autoGenerateColumns="false"
                  selectionMode="SingleRow"
                  summaryScope="Both"
                  selectedKeysChanged={this.onGridRowSelected}
                  cellClicked={this.onGridRowSelected2}
                >
                  <IgrTextColumn
                    field="description"
                    headerText="Description"
                    width="*>250"
                    // cellUpdating={onStatusCellUpdating}
                  />
                  <IgrTextColumn
                    field="instructions"
                    headerText="Work Instructions"
                    width="*>250"
                    // cellUpdating={onStatusCellUpdating}
                  />
                  <IgrTextColumn
                    field="status"
                    headerText="Status"
                    width="*>150"
                    // cellUpdating={onStatusCellUpdating}
                  />                  
                   <IgrTextColumn
                    field="assignedDate"
                    headerText="Assigned Date"
                    width="*>250"
                    // cellUpdating={onStatusCellUpdating}
                  />                 
                </IgrDataGrid>
              </Row>
              <Row form style={{ marginTop: "20px" }}>
                <Col>
                  {/* <DocumentBrowserDragDrop
                  buttonLabel="View Image"
                  buttonColor="primary"
                  formName="Workorder Images"
                  linkTableName={"topWorkOrder"}
                  linkPrimaryKey={workOrderKey < 0 ? offLineLinkKey : workOrderKey}
                  apiURL={`${apiURL}Fiix/Workorder/Documents/${workOrderKey < 0 ? offLineLinkKey : workOrderKey}`}
                /> */}
                </Col>
              </Row>
            </FormGroup>

            <Modal
          isOpen={modalTask}
          toggle={this.toggleTask}
          size="med"
          backdrop={"static"}
        >
          <ModalHeader toggle={this.toggleTask}>Add User</ModalHeader>{" "}
          {/* Updated header text */}
          <ModalBody>
            <FormGroup>
              <Row>
                <Col>
                  <Label>Please enter Assigned Date</Label>
                  <Datetime
                    inputProps={{ placeholder: "MM/DD/YYYY hh:mm" }}
                    // className={
                    //   !invalidDataItem.vendorCompletedDate
                    //     ? "form-error"
                    //     : ""
                    // }
                    value={moment(partsAndCostData.assignedDate).format(
                      "MM/DD/YYYY h:mm A"
                    )}
                    onChange={(v) => this.onTextChangeTask("assignedDate", v, null)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Label for="inputComment" style={{ marginTop: "20px" }}>Please select User</Label>
                  <Select
                    options={empList}
                    // className={
                    //   !invalidDataItem.laborItemKey
                    //     ? "form-error"
                    //     : ""
                    // }
                    id="empList"
                    isClearable={true}
                    // value={getSelEmpList(partsAndCostData.itemKey)}
                    onChange={(e) => this.onTextChangeTask("itemKey", e, e)}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Label for="inputComment" style={{ marginTop: "20px" }}>
                    Please enter work instructions
                  </Label>
                  <Input
                    type="textarea"
                    // invalid={invalid}
                    id="inputComment"
                    name="text"
                    value={partsAndCostData.instructions}
                    defaultValue={partsAndCostData.instructions}
                    rows={5}
                    onChange={(e) =>
                      this.onTextChangeTask("instructions", e.target.value, e)
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.saveTask()}>
              Save
            </Button>
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
                </div>
            );
         }
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");
    
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";
    
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            content.appendChild(link);
        } else {
            link = content.children[0];
        }
    
        switch (s.field) {
            case 'commentDate':
                var date = new Date(item.commentDate);
                link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
                break;
    
            case 'createdBy':
                link.textContent = item.createdBy.replace('.', ' ');
                break;
    
            case 'comments':
                link.textContent = item.comments;
                link.id = `comment-${item.operationCommentKey}`; // Ensure ID is correctly set
                break;
    
            default:
        }
    }
}