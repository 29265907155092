
import React, { Component, useState, useEffect } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, Breadcrumb, CustomInput } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert, InputGroupAddon } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPUTHeader, apiDELETEHeader, apiHeader, apiPOSTHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import MenuIcon from '../resources/menu.png'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import GlobeBW from '../resources/globebw.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ContainerState, ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
// import { copySync } from 'fs-extra';
import moment from "moment";
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class RiserDetail extends Component {
    static displayName = RiserDetail.name;

    constructor(props){
        super(props)

        // this.onGridRef = this.onGridRef.bind(this);
        // this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state={
            activeTab: '1',
            subSystemList:[],
            riserKey : props.riserKey,
            riserDetail : [],
            isLoaded:false,
            isSubSystemLoaded:false,
            invalidDataItem:{
                subSystemKey: true,
                riserName: true,
                riserSize: true,
                lat:true,
                long:true,
                qrCodeValue:true,
                comments:true
            },
            
            
        }

    }

    validateData = ()=>{
        const {invalidDataItem, riserDetail} = this.state
        var bVal = true 
        var valItem = invalidDataItem
        
        if (riserDetail[0].subSystemKey == null){
            bVal = false
            valItem.subSystemKey= false
        }

        if (riserDetail[0].qrCodeValue == null || riserDetail[0].qrCodeValue == ""){
            bVal = false
            valItem.qrCodeValue= false
        }

        if (riserDetail[0].long== null){
            bVal = false
            valItem.long= false
        }
        if (riserDetail[0].lat== null){
            bVal = false
            valItem.lat= false
        }
        
        if (riserDetail[0].riserSize == 0 || riserDetail[0].riserSize  < 0 || riserDetail[0].riserSize == null){
            bVal = false
            valItem.riserSize= false
        }

        if (riserDetail[0].riserName== "" || riserDetail[0].riserName == null){
            bVal = false
            valItem.riserName= false
        }

        if (riserDetail[0].comments== "" || riserDetail[0].comments== null){
            bVal = false
            valItem.comments= false
        }

        this.setState({invalidDataItem:valItem})
        
        return bVal
    }

    saveData=()=>{
        const {riserKey} = this.state
        
            if(this.validateData() ){   
            if(riserKey > 0){
                this.updateRiser()
            }
        else{
                this.createRiser()
                    }
            }
       
     }

    deleteData=()=>{

        const riserDeleteDTO = this.createRiserDeleteDTO()
        // console.log(userDeleteDTO)

        let myURI = apiURL + 'Risers'

        fetch(myURI , apiDELETEHeader(riserDeleteDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
            //   console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);
              
            }
    
            this.closeForm(true)
          })
          .catch(error =>{
            this.closeForm(true)
          })

       
    }


        
    createRiserDeleteDTO = () => {
        const { riserKey, riserDetail } = this.state
        const { user } = this.props

        const riserForDeleteDTO = {
            "RiserKey": riserKey,
            "Lat": riserDetail[0].lat,
            "Long": riserDetail[0].long,
            "DeletedBy": getUserADAccount(user.account.userName),
        }

        return riserForDeleteDTO;
    }

        
    

    
    createSubSystemList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.subSystemKey
            e.label = e.areaName
            tempList.push(e)
        })
        return tempList
    }

    getSubSystem=()=>{
        const {subSystemList} = this.state
        let myURI = apiURL + 'EmployeeDetails/SubSystem/AzureAD/' + this.props.user.account.userName;

        console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    subSystemList: this.createSubSystemList(json),
                    isSubSystemLoaded: true
                })
            
            });
    }

    getRiserData = () => {
        const {riserKey} = this.state

        let myURI = apiURL + 'Risers/' + riserKey 



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    riserDetail: json,
                    isLoaded: true
                })
             
            });

    }

    // getRiserData = () => {
    //     const {riserKey} = this.state

    //     let myURI = apiURL + 'Risers/' + riserKey 

    //     // console.log(myURI);

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             var riserData = []
    //             json.map(e => {
    //                 e.isUpdated = 0 /* Add this field to keep track of updated receiver line */
    //                 riserData.push(e)
    //             })
    //             this.setState({
    //                 riserDetail: riserData,
    //                 isLoaded: true
    //             })
    //             // console.log("Test:" + rcvrLine.ItemMasterKey)
                
    //         });
    
    // }

    getRiserDetails = ()=>{
        const {riserKey} = this.state

        if(riserKey > 0){
            this.getRiserData()
        }
        else{
            const riserDetailTemp = this.createRiserTemplate()
            this.setState({
                isLoaded:true,
                riserDetail: riserDetailTemp
            })
        }
    }

    updateRiser=()=>{
        const createRiserUpdateDTO = this.updateRiserDTO()
        let myURI = apiURL + 'Risers'
        console.log(myURI)
        fetch(myURI, apiPUTHeader(createRiserUpdateDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
    
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);
    
                }
               
                this.closeForm(true)
           
            })
            .catch(error => {
             
    
                this.closeForm(true)
            })
    }

    updateRiserDTO=()=>{
        const {riserKey, riserDetail} = this.state
        const {user} = this.props
        const riserUpdateDTO = {
            "RiserKey": riserKey,
            "SubSystemKey": riserDetail[0].subSystemKey,
            "RiserName": riserDetail[0].riserName,
            "RiserTypeKey": riserDetail[0].riserTypeKey,
            "RiserSize": riserDetail[0].riserSize,
            "RiserHeight": riserDetail[0].riserHeight,
            "RiserWidth": riserDetail[0].riserWidth,
            "RiserMake": riserDetail[0].riserMake,
            "Lat": riserDetail[0].lat,
            "Long": riserDetail[0].long,
            "AcquiredDate": riserDetail[0].acquiredDate,
            "OperateDate": riserDetail[0].operateDate,
            "StatusKey": riserDetail[0].statusKey,
            "QRCodeValue": riserDetail[0].qrCodeValue,
            "RiserSerialNo": riserDetail[0].riserSerialNo,
            "Comments": riserDetail[0].comments,
            "ModifiedBy": getUserADAccount(user.account.userName)
            
        }
        console.log(riserUpdateDTO)
        return riserUpdateDTO
    }

    createRiser=()=>{
        
        const riserCreateDTO= this.createRiserCreateDTO()
          
    
        let myURI = apiURL + 'Risers'
      
        fetch(myURI , apiPOSTHeader(riserCreateDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
     
              const error = (rspData && rspData.message) || response.status;
             
              return Promise.reject(error);
             
            }
         
   
            this.closeForm(true)
          
       
          })
          .catch(error =>{
            console.log(error)
            // this.closeForm(true)
          })
    }

    
    createRiserCreateDTO=()=>{
        const {riserKey, riserDetail} = this.state
        const {user} = this.props
        const riserCreateDTO = {
            "SubSystemKey": riserDetail[0].subSystemKey,
            "RiserName": riserDetail[0].riserName,
            "RiserTypeKey": riserDetail[0].riserTypeKey,
            "RiserSize": riserDetail[0].riserSize,
            "RiserHeight": null,
            "RiserWidth": null,
            "RiserMake": null,
            "Lat": riserDetail[0].lat,
            "Long": riserDetail[0].long,
            "AcquiredDate": null,
            "OperateDate": null,
            "StatusKey": 1,
            "QRCodeValue": riserDetail[0].qrCodeValue,
            "RiserSerialNo": null,
            "Comments": riserDetail[0].comments,
            "CreatedBy": getUserADAccount(user.account.userName),
            "OffLineLinkRiserKey": null
            
        }
        console.log(riserCreateDTO)
        return riserCreateDTO
    }

     createRiserTemplate = () => {
        const { user } = this.props
        const { riserDetail, riserKey } = this.state

        const riserCreateDTO = {
            "SubSystemKey": null,
            "RiserName": "",
            "RiserTypeKey": null,
            "RiserSize": 0,
            "riserSize": 0,
            "RiserHeight": null,
            "RiserWidth": null,
            "RiserMake": "",
            "Lat": null,
            "Long": null,
            "AcquiredDate": null,
            "OperateDate": null,
            "StatusKey": 1,
            "statusTypeDesc":1, 
            "QRCodeValue": null,
            "RiserSerialNo": "",
            "riserStatusDesc": "Active",
            // "Comments": "Test",
            "comments": "",
            // "CreatedBy": this.props.user.account.name,
            "createdBy": this.props.user.account.name,
            "OffLineLinkRiserKey": null,
            
        }
        console.log(riserCreateDTO)
        // this.setState({mtlRequestKey: mtlRequestKey})
        return [riserCreateDTO]
    }

    // createRiser = () => {
    //     const { user } = this.props
    //     const { riserDetail, riserKey } = this.state

    //     const riserCreateDTO = {
    //         "SubSystemKey": null,
    //         "RiserName": riserDetail[0].riserName,
    //         "RiserTypeKey": null,
    //         "RiserSize": riserDetail[0].riserSize,
    //         "RiserHeight": riserDetail[0].riserHeight,
    //         "RiserWidth": riserDetail[0].riserWidth,
    //         "RiserMake": riserDetail[0].riserMake,
    //         "Lat": riserDetail[0].lat,
    //         "Long": riserDetail[0].long,
    //         "AcquiredDate": riserDetail[0].acquiredDate,
    //         "OperateDate": riserDetail[0].operateDate,
    //         "StatusKey": null,
    //         "QRCodeValue": riserDetail[0].qrCodeValue,
    //         "RiserSerialNo": riserDetail[0].riserSerialNo,
    //         "Comments": riserDetail[0].comments,
    //         "CreatedBy": "",
    //         "OffLineLinkRiserKey": null,
            
    //     }
    //     // this.setState({mtlRequestKey: mtlRequestKey})
    //     return [riserCreateDTO]
    // }

    componentDidMount=()=>{
        this.getRiserDetails()
        this.getSubSystem()
    }

    closeForm=(refreshData)=>{
        if (refreshData){
            this.props.toggleModal()
        }
    }

    getSelSubSystem= (v) => {
        const {subSystemList} = this.state
        if (v !== null && v !== "") {
            return subSystemList.filter(e => e.subSystemKey === v)
        }
        return ""
    }

    getSelSubSystemKey = (value) => {
        return value.subSystemKey
    }
    


    onTextChange = (fieldName, fieldValue, e) => { 
        const {riserDetail, invalidDataItem, invalidSubArray,} = this.state

        console.log(fieldName + '=' + fieldValue )

  
        switch (fieldName) {

            case "subSystemKey": 

            riserDetail[0].subSystemKey = (e === null ? null : this.getSelSubSystemKey(e))
           
           invalidDataItem.subSystemKey = (fieldValue != null)

             break;

            case "riserName":

                riserDetail[0].riserName = fieldValue
                invalidDataItem.riserName = (fieldValue != "" || fieldValue != null )
           break;

           case "riserSize":

            riserDetail[0].riserSize = Number(fieldValue)
            invalidDataItem.riserSize = (fieldValue != null )
             break;

             case "qrCodeValue":

            riserDetail[0].qrCodeValue= fieldValue
            invalidDataItem.qrCodeValue = (fieldValue != null || fieldValue != "" )
             break;

             case "lat":

            riserDetail[0].lat = Number(fieldValue)
            invalidDataItem.lat = (fieldValue != null )
             break;

             case "long":

                riserDetail[0].long = Number(fieldValue)
                invalidDataItem.long = (fieldValue != null )
                 break;
       

             case "comments":

                riserDetail[0].comments = fieldValue
                invalidDataItem.comments = (fieldValue != "" || fieldValue != null )
                break;


            default:

        }

        this.setState({riserDetail: riserDetail, invalidDataItem: invalidDataItem})

    }

    googleMapLocation=()=>{
        const {riserDetail} = this.state
        const url = "https://www.google.com/maps/search/?api=1&query=" + riserDetail[0].lat + "," + riserDetail[0].long
        window.open(url,'_blank')
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }


    render(){
        const {isLoaded, isSubSystemLoaded, subSystemList, riserDetail, riserKey, activeTab, invalidDataItem} = this.state
        if (!isLoaded || !isSubSystemLoaded){            
            return (<div>Riser Data is loading...</div>)
        }else
        return(<div>
                <Form>

                    <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col md={6}>
                        <FormGroup>
                                <Label for="riserName">Subsystem</Label>
                                <Select
                                 className={!invalidDataItem.subSystemKey ? "form-error" : ""}
                                options={subSystemList}
                                isClearable={true}
                                // defaultValue={secDetails.applicationKey}
                                // invalid={!invalidDataItem.subSystemKey}
                                value={this.getSelSubSystem(riserDetail[0].subSystemKey)}
                                // placeholder=""
                                onChange={(e) => this.onTextChange("subSystemKey",e,e)}
                                > 
                    
                                </Select>
                                {/* <FormFeedback tooltip > Please select Application Name</FormFeedback> */}
                            </FormGroup>
                        </Col>


                        <Col md={2}>
                        <FormGroup>
                            <Label for="statusDesc">Status</Label>
                                <Label for="riserStatusDesc">&nbsp;</Label>
                                <Input  type="text" name="riserStatusDesc" id="riserStatusDesc" 
                                // defaultValue={secDetails.applicationKey}
                                // invalid={!invalidDataItem.applicationKey}
                                value={riserDetail[0].riserStatusDesc}
                                placeholder=""
                                disabled
                                // onChange={(e) => this.onTextChange("applicationKey",e.target.value,e)}
                                > 
                    
                                </Input>
                                {/* <FormFeedback tooltip > Please select Application Name</FormFeedback> */}
                            </FormGroup>
                            </Col>

                        <Col md={2}>
                        <FormGroup>

                            
                                <Label for="statusDesc">Created By</Label>
                                <Input  type="text" name="statusDesc" id="statusDesc" 
                                // defaultValue={secDetails.applicationKey}
                                // invalid={!invalidDataItem.applicationKey}
                                disabled
                                value={riserDetail[0].createdBy}
                                placeholder=""
                                // onChange={(e) => this.onTextChange("applicationKey",e.target.value,e)}
                                > 
                    
                                </Input>
                                {/* <FormFeedback tooltip > Please select Application Name</FormFeedback> */}
                            </FormGroup>
                        </Col>


                        
                        <Col md={2}>
                        <FormGroup>
                             <Label for="statusDesc">Created Date</Label>
                            <Label for="riserStatusDesc">&nbsp;</Label>
                                {/* <Label for="statusDesc">Created By</Label> */}
                                <Input  type="text" name="createdDate" id="createdDate" 
                                // defaultValue={secDetails.applicationKey}
                                // invalid={!invalidDataItem.applicationKey}
                                value={moment(riserDetail[0].createdDate).format('MM-DD-YYYY')}
                                // value={riserDetail[0].createdDate}
                                placeholder=""
                                disabled
                                // onChange={(e) => this.onTextChange("applicationKey",e.target.value,e)}
                                > 
                    
                                </Input>
                                {/* <FormFeedback tooltip > Please select Application Name</FormFeedback> */}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row form style={{ marginTop: "-15px" }}>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="riserName">Riser Name</Label>
                            <Input  type="text" name="riserName" id="riserName" 
                             invalid={!invalidDataItem.riserName}
                            // defaultValue={empDetails.firstName}
                            value={riserDetail[0].riserName}
                            placeholder=""
                            onChange={(e) => this.onTextChange("riserName",e.target.value,e)}
                             />
                             {/* <FormFeedback tooltip > Please enter First Name</FormFeedback> */}
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="riserSize">Size</Label>
                            <Input   type="number" name="riserSize" id="riserSize" 
                            invalid={!invalidDataItem.riserSize}
                            // defaultValue={empDetails.lastName}
                            value={riserDetail[0].riserSize}
                            placeholder=""
                            onChange={(e) => this.onTextChange("riserSize",e.target.value,e)} 
                            />
                            {/* <FormFeedback tooltip > Please enter Last Name</FormFeedback> */}
                        </FormGroup>
                    </Col>
        
            </Row>

                    <Row form style={{ marginTop: "-15px" }}>

                    <Col md={2.5}>
                        <FormGroup>
                            <Label for="lat">Latitude</Label>
                            <Input type="number"
                             invalid={!invalidDataItem.lat}
                            // defaultValue={empDetails.firstName}
                            value={riserDetail[0].lat}
                           
                            onChange={(e) => this.onTextChange("lat",e.target.value,e)}
                             />
                             {/* <FormFeedback tooltip > Please enter First Name</FormFeedback> */}
                        </FormGroup>
                    </Col>

                    <Col md={2.5}>
                        <FormGroup>
                            <Label for="long">Longitude</Label>
                            <Input type="number"
                             invalid={!invalidDataItem.long}
                            // defaultValue={empDetails.firstName}
                            value={riserDetail[0].long}
                           
                            onChange={(e) => this.onTextChange("long",e.target.value,e)}
                             />
                             {/* <FormFeedback tooltip > Please enter First Name</FormFeedback> */}
                        </FormGroup>
                    </Col>

                <Col>
                    <FormGroup style={{margin: "25px 0 0 30px"}}>
                       
                        <h6></h6>
                        
                     <Button
                           outline
                             color="link"
                               id="googleMap"
                              hidden = {riserKey < 0}
                                        onClick={(e) => this.googleMapLocation()}
                                      > 
                                  <img 
                                    src={GlobeBW}
                                    alt="Map"
                                    width="30px"
                                    // style={{textAlign: "right"}}
                                    />
                                   
                    </Button>
                    </FormGroup>
                    </Col>
                  
                    <Col md={6}>
                        <FormGroup>
                            <Label for="qrCodeValue">QR Code</Label>
                            <Input type = "text"
                            invalid={!invalidDataItem.qrCodeValue}
                            // defaultValue={empDetails.lastName}
                            value={riserDetail[0].qrCodeValue}
                           
                            onChange={(e) => this.onTextChange("qrCodeValue",e.target.value,e)} 
                            />
                            {/* <FormFeedback tooltip > Please enter Last Name</FormFeedback> */}
                        </FormGroup>
                    </Col>
        
            </Row>

            
            <FormGroup row>

<Col md={12}>

<Nav tabs>
   <NavItem>
       <NavLink
           className={classnames({ active: activeTab === '1' })}
           onClick={() => { this.toggleTab('1'); }}
       >
           Comments
       </NavLink>
   </NavItem>
   <NavItem>
       <NavLink
           className={classnames({ active: activeTab === '2' })}
           onClick={() => { this.toggleTab('2'); }}
       >
           Documents
       </NavLink>
   </NavItem>
</Nav>
<TabContent activeTab={activeTab}>
   <TabPane tabId="1">
       <Row form>
           <Input type="textarea" name="comments"
               rows={4}
               width="100%"
            //    disabled={isFormReadOnly}
               id="comments" 
               value={riserDetail[0].comments}
               invalid={!invalidDataItem.comments}
               onChange={(e) => this.onTextChange("comments", e.target.value, e)}
           />
       </Row>
   </TabPane>
   <TabPane tabId="2">
       <Row>

<Col md={12}>
   <DocumentBrowserDragDrop buttonLabel="View Image"
    //    disabled={isFormReadOnly}
       buttonColor="primary"
       formName="Receiver Documents"
       linkTableName={"topRiser"}
       linkPrimaryKey={riserKey}
       // allowDelete={isAFEManager || isAFEAdmin}
       apiURL={apiURL + 'Risers/Documents/' + riserKey} 
       />

        </Col>
       </Row>
   </TabPane>
</TabContent>

</Col>


</FormGroup>

            

            <FormGroup>
                    <Row>

                     <Col sm={12}>

            

                     <Button color="warning" className="float-right" 
                             onClick={() => this.closeForm(true)}
                             >Close</Button>

                    
                             
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                            //  hidden={this.setIsReadOnly(statusKey)}
                             onClick={()=> this.saveData()}
                             >Save</Button>

                    
                    <ConfirmModal outline={true}
                                  color="danger"
                                  
                                //   hidden = {!(empDetails.employeeKey>0)}
                                //   className="float-left"
                                  buttonLabel="Delete"
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected record?"
                                  onClickYes={(e) => this.deleteData()}
                                  image={<img
                                    src={TrashBin}
                                   // id={facilityOilTicketKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }}
                                    
                                    />}
                                    
                                    >
                        </ConfirmModal> 

                  

   
                        </Col>
                    </Row>
                 </FormGroup>  

                </Form>
        </div>)
    }
}