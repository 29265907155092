import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import RevisionPng from '../resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'; 
import FileManager from '../resources/FileManager'; 
import NumberFormat from 'react-number-format'; 
import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../resources/utility';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
import DailyFacilityReport from './DailyFacilityReport';
import DailyPipeLineReport from './DailyPipeLineReport';
import DailyGeneralReport from './DailyGeneralReport';
import ChangeOrder from './ChangeOrder';
import ReportAccording from './ReportAccordian';
import ConstructionReportingChangeOrders from './ConstructionReportingChangeOrders';
import { ThisYearExpressionDescription } from 'igniteui-react-core';
import ConstructionReportingCompleteChk from './ConstructionReportingCompleteChk';

var DatePicker = require("reactstrap-date-picker");
 

 

// const BugetSection = React.forwardRef((props, ref) => {

 

// const AFEDetail = React.forwardRef((props, ref) => {
class ConstructionReportingDetails extends React.Component {
    static displayName =  'Alex testing' //AFEDetail.name

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            constructionReportKey: props.constructionReportKey,
            constructionReportGeneralKey: null,
            isSecurityAdminLogin: props.isSecurityAdminLogin,
            afeList: props.afeList == undefined || props.afeList == null ? []: props.afeList,
            constructionReports: props.constructionReports,
            statusKey: null,
            closeoutStatusKey: -1,
            alertMessage: "",
            showAlert: false,
            showSaveAlert: false,
            isFormReadOnly: false,
            isApprover: false,
            isAFEManager: false,
            isAFEAdmin: false,
            showCostTracking: false,
            isLoaded: false,
            constructionReportDTO: null,
            empList: props.empList,
            isEmpListLoaded: false,
            budgetClassList: props.budgetClassList,
            isBudgetClass: false,
            afeTypeList: props.afeTypeList,
            isAFETypeLoaded: false,
            costTypeList: props.costTypeList.filter(x => x.afeLineTypeDesc != 'Accounting'),
            isCostTypeLoaded: false,
            facilityList: props.facilityList,
            isFacilityLoaded: false,
            budgetList: props.budgetList,
            dupAFEList: props.dupAFEList,
            isBudgetLoaded: false,
            isUserAdmin: false,
            isSecurityLoaded: false,
            uomList: props.uomList,
            linkAFEList: props.linkAFEList,
            basinList: props.basinList,
            countyList: props.countyList,
            projectStatusList: props.projectStatusList,
            areaList: props.areaList,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            alertMessage: "",
            alertColor: "danger",
            isForceBudgeLineUpdate: false,
            budgetLineRefreshKey: 0,
            activeTab: '1',
            hasDCCost: false,
            isDataChange: false,
            //isApprovalRevision:false,
            yesNoList: [{
                "label": "Yes",
                "value": 1
            }, {
                "label": "No",
                "value": 0
            }],
            validateDataItem: {
                afeHeaderKey: true,
                projectTypeKey: true,
                expectedCompletionDate: true,
                projectLength: true,
            },
            siteTransfer: null ,
            afesRevSupBuget: [],
            reimbursable: [{label: '',value: -1}, {label: 'No',value: 0},{label:'Yes', value: 1}],
            modalFacilityDailyReport: false,
            modalPipeLineDailyReport: false,
            modalGeneralDailyReport: false,
            modalChangeOrder: false,
            reportName: "",
            dropdownOpenManage: false,
            reportKey: 0,
            dailyReports: [],
            completedProjectQuestionsModel: false,
            completedProjectQuestionsAnswer: false,
            closeoutChk: [],
            edited: false,
        }   
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    dataValidation = (statusKey) => {
        return new Promise((resolve, reject) => {
            const { constructionReportDTO, validateDataItem ,constructionReports,dailyReports} = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }



            if (constructionReportDTO.afeHeaderKey === null || constructionReportDTO.afeHeaderKey <= 0 ) {
                validateDataItem.afeHeaderKey = false
                returnVal.ok = false
                returnVal.message = returnVal.message + "Please select an AFE"
            }
            else if (constructionReportDTO.constructionReportKey <=0 ){
                let obj = constructionReports.filter(x => x.AFEHeaderKey == constructionReportDTO.afeHeaderKey && 
                                                        (x.statusKey == 0 || //Draft
                                                         x.statusKey == 1 || //UpComing
                                                         x.statusKey == 2 || //Started
                                                         x.statusKey == 5)); // OnHold
                if (obj != null && obj.length > 0){
                    validateDataItem.afeHeaderKey = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + "An active Construciton Report exists.  Please select different AFE.  "
                }
            }

            if (constructionReportDTO.projectTypeKey === undefined || constructionReportDTO.projectTypeKey === null ) {
                validateDataItem.projectTypeKey = false
                returnVal.ok = false
                returnVal.message = returnVal.message + "Please select an Project Type"
            }
            if (constructionReportDTO.expectedCompletionDate === null || constructionReportDTO.expectedCompletionDate === "" ) {
                validateDataItem.expectedCompletionDate = false
                returnVal.ok = false
                returnVal.message = returnVal.message + "Please select an Expected Completion Date"
            }
 
            if (constructionReportDTO.projectTypeKey == 3 && (constructionReportDTO.projectLength == undefined || constructionReportDTO.projectLength == null || constructionReportDTO.projectLength <= 0)){
                validateDataItem.projectLength = false
                returnVal.ok = false
                returnVal.message = returnVal.message + "The Project Length needs to be greater than 0"
            }

            if (statusKey === 3 && dailyReports.length <= 0){
                //validateDataItem.afeHeaderKey = false;
                returnVal.ok = false;
                returnVal.message = returnVal.message + "One daily report is needed to set construction report to complete status."
            }

            returnVal.validateDataItem = validateDataItem
            if (returnVal.message !== "") {
                returnVal.message = "Please enter missing data: " + returnVal.message
            }
            resolve(returnVal)
        })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleAlert = () => {

        this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggleSaveAlsert = () => {
        this.setState({ showSaveAlert: !this.state.showSaveAlert },
            () => { window.setTimeout(() => { this.setState({ showSaveAlert: false }) }, 3000) })
    }

    resetValidDataItem = () => {
        
    }

    closeForm = () => {
        this.props.closeDetail(this.state.edited);
    }

    saveDataConstructionReport = async(statusKey,close) => {
        let { constructionReportDTO ,closeoutChk} = this.state
 
            this.dataValidation(statusKey).then((data) => {
                if (data.ok) {

                    //this.childRef.current.saveLineData()

                    if (constructionReportDTO.constructionReportKey > 0) {
                        if(statusKey === -1){
                            this.deleteConstructionReport();
                            return;
                        }
                        else if (statusKey === 3 && closeoutChk.length <= 0){ //completing project

                            this.openProjectQuestions(); 
                            return;
                        }

                        constructionReportDTO.topConstructionReportCloseoutLines = closeoutChk;
                        this.setState({constructionReportDTO: constructionReportDTO,edited: true});

                        this.updateConstructionReport(statusKey); 
                    } else {
                        this.addConstructionReport(statusKey)
                    }

                    this.toggleSaveAlsert();
                    
                    if (close === 1){
                        this.closeForm();
                        return;
                    }

                } else {
                    this.setState({
                        alertMessage: data.message,
                        validateDataItem: data.validateDataItem,
                        showAlert: true
                    })
                }
            })
        


    }

    openProjectQuestions = () =>{
        this.setState({completedProjectQuestionsModel: true});
    }


    toggleOpenQuestions =() => {
        this.setState({completedProjectQuestionsModel: !this.state.completedProjectQuestionsModel});
    }

    closeOpenQuestions =() => {
        this.setState({completedProjectQuestionsModel: false});
    }

    updateConstructionReport = async(statusKey) => { 

        let  {constructionReportDTO ,user} = this.state;
        constructionReportDTO.lastModifiedBy = user.account.userName
        constructionReportDTO.statusKey = statusKey;

        let myURI = apiURL + 'ConstructionReporting/Edit'

        await fetch(myURI, apiPOSTHeader(constructionReportDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else  
                    //return response.json()
                //this.setState({constructionReportDTO: response.json(), constructionReportKey: response.json().constructionReportKey});
                this.setState({edited: true});
            }).then(json => {
                this.setState({isLoaded: false });
                this.getConstructionReportDetails();
                this.setState({isLoaded: true }); 
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }
 
    updateAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeUpdateDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "AFENo": afeData[0].afeNo,
            "AFEName": afeData[0].afeName,
            "StatusKey": afeData[0].statusKey,
            "AFEManagerKey": afeData[0].afeManagerKey,
            "AFESponsor": afeData[0].afeSponsor,
            "AFEDescription": afeData[0].afeDescription,
            "Comments": afeData[0].comments,
            "TimingChange": afeData[0].timingChange,
            "TypeKey": afeData[0].typeKey,
            "BudgetClasskey": afeData[0].budgetClassKey,
            "EstStartDate": afeData[0].estStartDate,
            "EstComplete": afeData[0].estComplete,
            "BudgetID": afeData[0].budgetID,
            "WBOwnedPct": afeData[0].wbOwnedPct,
            "WDAFENo": afeData[0].wdAFENo,
            "FacilityKey": afeData[0].facilityKey,
            "WellName": afeData[0].wellName,
            "Lease": afeData[0].lease,
            "APINumber": afeData[0].apiNumber,
            "County": afeData[0].county,
            "Basin": afeData[0].basin,
            "State": afeData[0].state,
            "SurfaceLegal": afeData[0].surfaceLegal,
            "BottomHoleLegal": afeData[0].bottomHoleLegal,
            "Area": afeData[0].area,
            "ModifiedBy": getUserADAccount(user.account.userName),
            "AFECostType": afeData[0].afeCostType,
            "LinkAFE": afeData[0].linkAFE,
            "LocationKey": afeData[0].locationKey,
            "intangibleContigencyRate": afeData[0].intangibleContigencyRate / 100.00,
            "tangibleContigencyRate": afeData[0].tangibleContigencyRate / 100.00,
            "ProjectHierachy": afeData[0].projectHierachy,
            "CostCenterID": afeData[0].costCenterID,
            "CompanyID": afeData[0].companyID,
            "SiteID": afeData[0].siteID,
            "FixAssetsEnteredBy": afeData[0].fixAssetsEnteredBy,
            "FixAssetsModifiedDate": afeData[0].fixAssetsModifiedDate,
            "ActualStartDate": afeData[0].actualStartDate,
            "actualEndDate": afeData[0].actualEndDate,
            "lat": afeData[0].lat,
            "long": afeData[0].long,
            "estStartDate2": afeData[0].estStartDate2,
            "estEndDate2": afeData[0].estEndDate2,
            "projectStatusKey": afeData[0].projectStatusKey,
            "areaKey": afeData[0].areaKey,
            "tangibleTaxRate": afeData[0].tangibleTaxRate / 100.00,
            "intangibleTaxRate": afeData[0].intangibleTaxRate / 100.00,
            "closeoutStatusKey": afeData[0].closeoutStatusKey,
            "projectedAmount": afeData[0].projectedAmount,
            "projectedAmntComments": afeData[0].projectedAmntComments,
            "inServiceDate": afeData[0].inServiceDate,
            "SiteTransfer": afeData[0].siteTransfer,
            "ReimbursableProject": afeData[0].reimbursableProject 
        }

        return afeUpdateDTO;
    }
     
    dupAFE = (afeNo) =>{
        const newDupAFEDTO = this.dupAFEDTO(afeNo)
        //console.log(newAFEDTO)

        let myURI = apiURL + 'AFE/Header/Duplicate'
        fetch(myURI, apiPOSTHeader(newDupAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                if (json[0].afeHeaderKey > 0) {
                    console.log("New AFE Return")
                    console.log(json)


                    this.setState({
                        isLoaded:false,
                        afeHeaderKey: json[0].afeHeaderKey,
                        isDataChange: false
                    })
                    this.getAFEDetails()
                }
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    addConstructionReport = (statusKey) => {
        const  {constructionReportDTO} = this.state;
        constructionReportDTO.statusKey = statusKey;

        let myURI = apiURL + 'ConstructionReporting/Add'
        fetch(myURI, apiPOSTHeader(constructionReportDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else{
                    this.setState({edited: true });
                    return response.json()
                }
                    
            }).then(json => {
                this.setState({isLoaded: false,constructionReportKey: json.constructionReportKey});
                this.getConstructionReportDetails();
                this.setState({isLoaded: true }); 
            }).catch(error => {
                console.log("PUT error: " + error)
            })

    }

    deleteConstructionReport =()=>{
        const  {constructionReportDTO} = this.state;
         
        let myURI = apiURL + 'ConstructionReporting/Delete/' + constructionReportDTO.constructionReportKey
        fetch(myURI, apiPOSTHeader(constructionReportDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else{
                    this.setState({edited: true});
                    return response.json()
                }
                    
            }).then(json => {
              this.closeForm();
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    createAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeCreateDTO = {
            "AFENo": afeData[0].afeNo,
            "AFEName": afeData[0].afeName,
            "AFEManagerKey": afeData[0].afeManagerKey,
            "AFESponsor": afeData[0].afeSponsor,
            "AFEDescription": afeData[0].afeDescription,
            "Comments": afeData[0].comments,
            "TimingChange": afeData[0].timingChange,
            "TypeKey": afeData[0].typeKey,
            "BudgetClasskey": afeData[0].budgetClassKey,
            "EstStartDate": afeData[0].estStartDate,
            "EstComplete": afeData[0].estComplete,
            "BudgetID": afeData[0].budgetID,
            "WBOwnedPct": afeData[0].wbOwnedPct,
            "FacilityKey": afeData[0].facilityKey,
            "WellName": afeData[0].wellName,
            "Lease": afeData[0].lease,
            "APINumber": afeData[0].apiNumber,
            "County": afeData[0].county,
            "Basin": afeData[0].basin,
            "State": afeData[0].state,
            "SurfaceLegal": afeData[0].surfaceLegal,
            "BottomHoleLegal": afeData[0].bottomHoleLegal,
            "Area": afeData[0].area,
            "CreatedBy": getUserADAccount(user.account.userName),
            "AFECostType": afeData[0].afeCostType,
            "LinkAFE": afeData[0].linkAFE,
            "LocationKey": afeData[0].locationKey,
            "AreaKey": afeData[0].areaKey,
            "SiteTransfer": afeData[0].siteTransfer,
            "ReimbursableProject": afeData[0].reimbursableProject
        }

        return afeCreateDTO;
    }
    
    closeOutAFE = (e) => {
        const { afeData } = this.state

        if (afeData[0].actualStartDate === null || afeData[0].actualEndDate === null) {
            var sErr = ""
            if (afeData[0].actualStartDate === null) {
                sErr = "Actual Start Date"
            }
            if (afeData[0].actualEndDate === null) {
                if (sErr !== '') { sErr = sErr + ', ' }
                sErr = sErr + "Actual End Date"
            }
            this.setState({
                alertMessage: "Missing " + sErr + ". Please enter missing date in Additional Details.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].closeoutStatusKey = 0
            this.setState({ afeData: afeData },
                this.saveData(false))
        }

    }
     
    getSelLinkAFE = (v) => {
        const { afeList } = this.state
        if (v !== null && v !== "") {
            return afeList.filter(e => e.afeHeaderKey === v);
        }
        return ""

    }
    getAFTList =(constructionReportKey) =>{
        const { afeList } = this.state
        let result = [];
        
        if (constructionReportKey === null || constructionReportKey <= 0){
            result = afeList.filter( x => x.statusKey == 4 || x.statusKey == 3);  //Only get Approved or Pending AFE's for new Construction reports.
        }
        else{
            result = afeList;
        }
        
        return result;
    }

    getProjectType(){
        const {afeList ,constructionReportDTO} = this.state;
        if (constructionReportDTO !== null && constructionReportDTO.afeHeaderKey !== null && constructionReportDTO.afeHeaderKey !== "") {
            return afeList.filter(e => e.afeHeaderKey === constructionReportDTO.afeHeaderKey);
        }
        return "";
    }

    getEmpKey = () => {
        const { empList } = this.state
        const { user } = this.props
        const adAccount = getUserADAccount(user.account.userName)

        const selEmp = empList.filter(e => (e.adAccount || '').toLowerCase() === adAccount.toLowerCase());
        if (selEmp.length > 0) {
            return selEmp[0].employeeKey
        }
        return -1
    }
 
    getSelFacility = (v) => {
        const { facilityList } = this.state
        if (v !== null && v !== "") {
            return facilityList.filter(e => e.facilityKey === v);
        }
        return ""
    }
 
    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            return budgetList.filter(e => e.afeBudgetDesc === v);
        }
        return ""
    }
 
    getSelBudgetClass = (v) => {
        const { budgetClassList } = this.state
        if (v !== null && v !== "") {
            return budgetClassList.filter(e => e.afeBudgetClassKey === v);
        }
        return ""
    }
  
    getSelAFECostType = (value) => {
        const { costTypeList } = this.state

        //return "";

        if (value !== undefined && value !== null && value !== "") {
            let test = costTypeList.filter(e => e.afeLineTypeKey ==  value);
            console.log('Test: ' + test);
            return test;
            return costTypeList.filter(e => value.includes(e.afeLineTypeKey))
        }
        return ""
    }

    getFileterProjectTypeByAFE = (value) =>{
        const { afeList,costTypeList } = this.state

        if (value !== undefined && value !== null && value !== "") {
            let obj = afeList.filter(e => e.afeHeaderKey == value);

            if (obj !== null && obj.length > 0){
                let afeCostType = obj[0].afeCostType;
                if (afeCostType.includes(',')){
                  return costTypeList.filter( e => afeCostType.includes(e.afeLineTypeKey))
                }
                else {
                    return costTypeList.filter( e => afeCostType == e.afeLineTypeKey);
                }
            } 
        }
        return null;//costTypeList;
    }

    getProjectName = (value) =>{
        const { afeList } = this.state

        if (value !== undefined && value !== null && value !== "") {
            let obj = afeList.filter(e => e.afeHeaderKey == value);

            if (obj !== null && obj.length >0){
                return obj[0].afeName;
            } 
        }
        return ""
    }

    getProjectManager = (value) =>{
        const { afeList } = this.state

        if (value !== undefined && value !== null && value !== "") {
            let obj = afeList.filter(e => e.afeHeaderKey == value);

            if (obj !== null && obj.length >0){
                return obj[0].afeManagerName;
            } 
        }
        return ""
    }

    getStartDate = (value) =>{
        const { afeList } = this.state

        if (value !== undefined && value !== null && value !== "") {
            let obj = afeList.filter(e => e.afeHeaderKey == value);

            if (obj !== null && obj.length >0){
                var date = new Date(obj[0].estStartDate);
                return date.toLocaleDateString();
                //return obj[0].estStartDate;
                
            } 
        }
        return ""

    }

    getEndDate = (value) =>{
        const { afeList } = this.state

        if (value !== undefined && value !== null && value !== "") {
            let obj = afeList.filter(e => e.afeHeaderKey == value);

            if (obj !== null && obj.length >0){
                var date = new Date(obj[0].estComplete);
                return date.toLocaleDateString();
                //return obj[0].estStartDate;
                
            } 
        }
        return ""

    }

    getBasin = (value) =>{
        const { afeList } = this.state

        if (value !== undefined && value !== null && value !== "") {
            let obj = afeList.filter(e => e.afeHeaderKey == value);

            if (obj !== null && obj.length >0){
                 return obj[0].basin; 
            } 
        }
        return ""

    }
 
    getSelArea = (v) => {
        const { areaList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return areaList.filter(e => e.areaKey === v)
        }
        return ""
    }

    getReimbursable =(v) =>{
        const { reimbursable } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return reimbursable.filter(e => e.value === v)
        }
        return ""
    }
    
    getSelCounty = (v) => {
        const { countyList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && v !== "") {
            // console.log("v")
            // console.log(v)
            return countyList.filter(e => e.countyDesc === v)
        }
        return ""
    }
 
    getSelBasin = (v) => {
        const { basinList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            // console.log("v")
            // console.log(v)
            return basinList.filter(e => e.locationKey === Number(v))
        }
        return ""
    }
 
    getAFETypeCostString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.afeLineTypeKey
        })

        return grpKey;
    }
 
    getSelAFEType = (v) => {
        const { afeTypeList } = this.state
        if (v !== null && v !== "") {
            return afeTypeList.filter(e => e.afeTypeKey === v);
        }
        return ""
    }

    getSelYesNo = (v) => {
        const { yesNoList } = this.state
        if (v !== null && v !== "") {
            return yesNoList.filter(e => e.value === v);
        }
        return ""
    }
    
    async addDailyReport(value){
        
        await this.getDailyReports();
        
        this.setState({
            reportKey: 0
        });
        if (value == 'Pipeline'){
            this.toggleNewPipeLineDailyReport()
        }
        else if (value == 'Facility'){
            this.toggleNewFacilityDailyReport()
        }
        else if (value == 'General'){
            this.toggleNewGeneralDailyReport()
        } 
    }

    async getDailyReports(){
        const{constructionReportDTO} = this.state;
        
        if (constructionReportDTO === undefined || constructionReportDTO === null){
            this.setState({dailyReports: []});
            return;
        }
        
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportDTO.constructionReportKey + '/DailyReports';  
        console.log("myURI");
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    dailyReports: json 
                }) 
            });
    }

    onTextChange = (fieldName, fieldValue, e) => {
        let { afeData, validateDataItem ,constructionReportDTO,constructionReports,alertMessage} = this.state
        const { user } = this.props
        var showAlert = false
        var isForceBudgeLineUpdate = false
        var budgetLineRefreshKey = 0

        console.log(fieldName + ' ------ ' + fieldValue)
        console.log(e)

        switch (fieldName) {
            case "projectType":
                constructionReportDTO.projectType = fieldValue.afeLineTypeDesc;
                break;
            case "projectTypeKey":
                constructionReportDTO.projectTypeKey = fieldValue.afeLineTypeKey;
                validateDataItem.projectTypeKey = true;
                break;
            case "afeHeaderKey":
                //For new Construction Reports will not be able to choose a AFE thats on an active Construction Report. 
                if (fieldValue != undefined && fieldValue != null && constructionReportDTO.constructionReportKey <= 0){
                    let objCurrentAFEs = constructionReports.filter(x => x.afeHeaderKey == fieldValue.afeHeaderKey && 
                        (x.statusKey == 0 || //Draft
                         x.statusKey == 1 || //UpComing
                         x.statusKey == 2 || //Started
                         x.statusKey == 5)); // OnHold
                    if (objCurrentAFEs != null && objCurrentAFEs.length > 0){
                        alertMessage = "An Active Construciton Report exists with " + objCurrentAFEs[0].afeNo + ".  Please select different AFE.  "
                        showAlert = true
                        validateDataItem.afeHeaderKey = false;
                        break;
                    }
                }
                
                if (fieldValue === undefined || fieldValue === null){
                    constructionReportDTO.afeHeaderKey = null;
                    constructionReportDTO.projectTypeKey = null;
                    break;
                }

                constructionReportDTO.afeHeaderKey = fieldValue.afeHeaderKey;
                constructionReportDTO.projectTypeKey = constructionReportDTO.constructionReportKey > 0 ? constructionReportDTO.projectTypeKey :
                                                       (fieldValue.afeCostType.includes(',') ? null : +fieldValue.afeCostType);
                
                let objAFEInfor = this.getSelLinkAFE(constructionReportDTO.afeHeaderKey)
                
                if(objAFEInfor != null && objAFEInfor.length > 0){
                    constructionReportDTO.projectName =  objAFEInfor[0].afeName;
                }
                

                validateDataItem.afeHeaderKey = true;
                validateDataItem.projectTypeKey = constructionReportDTO.projectTypeKey > 0 ? true : validateDataItem.projectTypeKey;
                break;
            case "expectedCompletionDate":
                constructionReportDTO.expectedCompletionDate = fieldValue
                validateDataItem.expectedCompletionDate = true;
                break; 
            case "pipeLineSize":
                constructionReportDTO.pipeLineSize = +fieldValue;
                break;
            case "unCasedBoreLength":
                constructionReportDTO.unCasedBoreLength = +fieldValue;
                break;
            case "projectLength":
                constructionReportDTO.projectLength = +fieldValue;
                validateDataItem.projectLength = true;
                break;
            case "casedBoreLength":
                constructionReportDTO.casedBoreLength = +fieldValue;
                break;    
            case "surfaceLegal":
                afeData[0].surfaceLegal = fieldValue
                break;
            case "apiNumber":
                afeData[0].apiNumber = fieldValue
                break;
            case "wellName":
                afeData[0].wellName = fieldValue
                break;
            case "lease":
                afeData[0].lease = fieldValue
                break;
            case "estStartDate":
                afeData[0].estStartDate = fieldValue
                validateDataItem.estStartDate = true
                validateDataItem.estComplete = true
                break;
             

            case "actualStartDate":

                afeData[0].actualStartDate = fieldValue
                break;
            case "actualEndDate":
                afeData[0].actualEndDate = fieldValue
                break;

            case "estComplete":
                afeData[0].estComplete = fieldValue
                validateDataItem.estComplete = true
                validateDataItem.estStartDate = true
                break;
            case "afeName":
                afeData[0].afeName = fieldValue
                validateDataItem.afeName = true
                showAlert = false
                break;
             
             
            case "estStartDate2":
                afeData[0].estStartDate2 = fieldValue
                break;
            case "estEndDate2":
                afeData[0].estEndDate2 = fieldValue
                break;
            case "lat":
                let objLat = fieldValue === undefined || fieldValue === '' ? null : (+fieldValue >= 10000 ? 9999.9999 : fieldValue);
                constructionReportDTO.lat = objLat; 
                break;
            case "long":
                let objLong = fieldValue === undefined || fieldValue === '' ? null : (+fieldValue >= 10000 ? 9999.9999 : fieldValue);
                constructionReportDTO.long = objLong; 
                break;
            case "projectStatus":
                afeData[0].projectStatusKey = null
                if (e !== null) {
                    afeData[0].projectStatusKey = e.value
                }
                break;
            case "projectedAmount":
                afeData[0].projectedAmount = fieldValue
                break;
            case "projectedAmntComments":
                afeData[0].projectedAmntComments = fieldValue
                break;
            case "siteTransfer":
                afeData[0].siteTransfer = fieldValue;
                break;
            case 'reimbursable':
                afeData[0].reimbursableProject = fieldValue.value <0 ? null: fieldValue.value;
                break;
            default:
                break;
        }

        this.setState({
            afeData: afeData,
            constructionReportDTO: constructionReportDTO,
            validateDataItem: validateDataItem,
            isForceBudgeLineUpdate: isForceBudgeLineUpdate,
            budgetLineRefreshKey: budgetLineRefreshKey,
            showAlert: showAlert,
            isDataChange: true,
            alertMessage: alertMessage
        })
    }

    onSiteToSiteChange = (fieldValue) => {
        const { afeData  } = this.state
        afeData[0].siteTransfer = fieldValue;
        this.setState({ afeData: afeData })
    }

    getAFEDetailsAPI = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }

    getAFERevSup = async() => {
        const { afeHeaderKey } = this.state
        
        if (afeHeaderKey === undefined || afeHeaderKey === null || afeHeaderKey <= 1){
            return; 
        }
        
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey + '/RevisionSupplement';

        console.log(myURI);

        await fetch (myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afesRevSupBuget: json 
                }) 
            });

        console.log('getAFERevSup');    
    }

    setIsShowCostTracking = (statusKey) => {
        return !(statusKey === 0 || statusKey === 1 || statusKey === 2 || statusKey === 3)
    }

    setIsApprovalRevision = (statusKey, approver, isApprovalRevision, closeoutStatusKey) => {
        if (this.isAFEApprover(statusKey, approver, closeoutStatusKey) && isApprovalRevision !== 0) {
            this.setState({
                showAlert: true,
                alertColor: "info",
                alertMessage: "This AFE have been revised and resubmitted for your approval. Please view change log for update history."
            })
        }
    }

    isAFEApprover = (statusKey, approver, closeOutStatusKey) => {
        const { user } = this.props
        if (approver !== "" && (statusKey === 3 || closeOutStatusKey === 3)) {
            return approver.includes(user.account.userName.toLowerCase())
        } else {
            return false
        }
    }

    getDetails = async(constructionReportKey) => {
         
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    constructionReportDTO: json, 
                }) 
            });
    }
 
    setIsFormReadonly = (statusKey, afeManagerAzureAD) => {
        const { user } = this.props
        const { isAFEAdmin } = this.state


        if (statusKey === 1 || statusKey === 10) { //Rejected //10=Revise
            return !((afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase()) || isAFEAdmin)
        } if (statusKey === 6 || statusKey === 5) {
            return true
        } else if (statusKey === 3) {
            return true
        } else if (statusKey === 8) { // 8 - Closed
            return true
        } else if (statusKey === 11) {// 11=Project Started
            return true
        } else if (statusKey === 2) {
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } if (statusKey === 4) { //Approved 
            //return true
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } else {
            return false
        }

    }

    createConstructionReport = () => {
        const { user } = this.props;

        var date = new Date();
        let todaysDate = date.toLocaleDateString();

        const constructionReportDTO = {
            "constructionReportKey": 0,
            "constructionReportID": 'XXXXXX',
            "afeNo": null, 
            "statusKey": 0,
            "statusDescription": "Draft",
            "afeHeaderKey": 0,
            "projectName": "",
            "createdBy": user.account.userName,
            "createdDate": todaysDate, 
            "lastModifiedBy": user.account.userName,
            "lastModifiedDate": todaysDate,  
            "projectManager": "",
            "projectType": null,
            "projectTypeKey": null,
            "expectedCompletionDate": null,
            "pipeSize": null,
            "uncasedBoreLength": null,
            "projectLength": null,
            "casedBoreLength": null,
            "lat": null,
            "long": null,
            "comments": "",
            //"timeStamp":"-" + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString() 
            "blob_id": "-" + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString() + date.getMilliseconds().toString()
        }

        return constructionReportDTO
    }

    getUserSecurity = async() => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }
 
    async componentDidMount() {
        let {afeList} = this.state;
        
        if (afeList == undefined || afeList == null || afeList.length <=0){
            await this.getAFEList();
        }
         
        await this.getUserSecurity()
        await this.getConstructionReportDetails();
        await this.getDailyReports(); 
        this.setState({  isLoaded: true });
    }

    async getAFEList(){
        let myURI = apiURL + 'ConstructionReporting/AFE'

        console.log("myURI");
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeList: this.createAFEList(json)
                }) 
            });
    }

    createAFEList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeHeaderKey
            e.label = e.afeNo + ' - ' + e.afeName
            tempList.push(e)
        })

        return tempList
    }

    loadView = ()=>{
        let myURI = apiURL + 'ConstructionReporting/loadDetails'

        console.log("myURI");
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    costTypeList: this.createCostTypeList(json.costTypeList),
                }) 
            });
    }

    createCostTypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeLineTypeKey
            e.label = e.afeLineTypeDesc
            tempList.push(e)
        })

        return tempList
    }

    async getConstructionReportDetails(){
        const { constructionReportKey } = this.state 

        if (constructionReportKey > 0) { 
            await this.getDetails(constructionReportKey)
        } else {
            const constructionReportDTO = this.createConstructionReport();
            this.setState({ constructionReportDTO: constructionReportDTO }); 
        }
    }

    isFormReadOnly = () => {
        const { isFormReadOnly, isAFEAdmin, isApprover } = this.state
        return isFormReadOnly
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKey} = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKey }]; 
        return reportPara
    }
    
    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }

    async getAFEReport(reportName){
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }
 
    toggleNewFacilityDailyReport = () => {
        this.setState({
            modalFacilityDailyReport: !this.state.modalFacilityDailyReport
        })
    }

    closeFacilityDailyReport = () => {
        this.setState({ modalFacilityDailyReport: false })
    }

    toggleNewPipeLineDailyReport = () => {
        this.setState({
            modalPipeLineDailyReport: !this.state.modalPipeLineDailyReport
        })
    }

    closePipeLineDailyReport = () => {
        this.setState({ modalPipeLineDailyReport: false })
    }

    toggleNewGeneralDailyReport = () => {
        this.setState({
            modalGeneralDailyReport: !this.state.modalGeneralDailyReport
        })
    }

    closeGeneralDailyReport = () => {
        this.setState({ modalGeneralDailyReport: false })
    }

    toggleNewChangeOrder =() => {
        this.setState({
            modalChangeOrder: !this.state.modalChangeOrder
        })
    }

    closeChangeOrder = () => {
        this.setState({ modalChangeOrder: false })
    }

    addNewProject(value){
        if (value == 'Pipeline'){
            this.toggleNewPipeLineDailyReport()
        }
        else if (value == 'Facility'){
            this.toggleNewFacilityDailyReport()
        }
        else if (value == 'General'){
            this.toggleNewGeneralDailyReport()
        }
        
        
    }

    toggleCloseDailyReport = (edited) => {
        this.setState({edited: edited != undefined && edited != null ? edited : this.state.edited});    
        this.getConstructionReportDetails();
    } 

    completedChk =(closeoutChk)=>{
        console.log('questions' + closeoutChk);
        this.setState({closeoutChk:closeoutChk,completedProjectQuestionsModel: false });

        if (closeoutChk.length > 0){
            this.saveDataConstructionReport(3,0)
        }
         
    }

    cancelCompletedChk=()=>{

        this.setState({
            closeoutChk:[],
            completedProjectQuestionsModel: false,
            msgBody: 'Completion Checklist needs to be saved before setting Construction Report to Complete.',
            msgHeader: "Completion Checklist",
            modalMsg: !this.state.modalMsg
        });
 
    }
 
    attempCompleteChk=(statusKey,close)=>{
        
    }

    

    render() {
        const { afeHeaderKey, alertMessage, empList, basinList, showCostTracking,
            showAlert , isLoaded,  budgetClassList,  isSecurityAdminLogin, countyList, closeoutStatusKey, showSaveAlert,
            afeTypeList, msgHeader, msgBody, uomList, isAFEAdmin, isAFEManager, isSecurityLoaded,
            costTypeList, facilityList , afeList, alertColor, areaList, projectStatusList,
            budgetList, validateDataItem , isDataChange,dupAFEList ,afesRevSupBuget, 
            reportName,reportKey,constructionReports,modalGeneralDailyReport,dailyReports,modalFacilityDailyReport,modalPipeLineDailyReport,constructionReportKey,
            constructionReportDTO,constructionReportPipelineKey,constructionReportGeneralKey} = this.state
        const { user } = this.props

           

        let objAFEsRevSupBuget = 0;
        let objAFEsRevSupBugetDetails = '' 
        if (afesRevSupBuget != undefined && afesRevSupBuget !== null && afesRevSupBuget.length > 0){ 
            for (let i = 0; i < afesRevSupBuget.length; i++) {
                let index = afesRevSupBuget[i].afeNo.indexOf('-') + 1;
                let objLength = afesRevSupBuget[i].afeNo.length - index;
                objAFEsRevSupBuget  =  objAFEsRevSupBuget + afesRevSupBuget[i].afeTotalAmt;
                objAFEsRevSupBugetDetails += (i == 0 ? ' ' : ' + ')   + afesRevSupBuget[i].afeNo.substr(index, objLength) + ': ' +  USDCurrencyFormat(afesRevSupBuget[i].afeTotalAmt,0) ;
            }
            console.log(objAFEsRevSupBuget); 
        } 
 

        let projectType = '';
        if (constructionReportDTO != null && constructionReportDTO.projectType != null && constructionReportDTO.projectType != ''){
            projectType = constructionReportDTO.projectType;
        }

        if (!isLoaded) {
            return (<div>Loading Construction Report Data .....</div>)
        } 
        else{
             
            return (<div>  
                <Form onSubmit={e => e.preventDefault()}>
                    <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <Col sm={5}> 
                            <Button color="secondary" className="float-left" 
                                onClick={() => this.closeForm()}>Close</Button>  
                            <Button color="primary" className="float-left"
                                style={{ marginLeft: "5px" }} 
                                hidden={(constructionReportDTO.statusKey <= 0 || constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4 || constructionReportDTO.statusKey === 5)}
                                onClick={() => this.saveDataConstructionReport(constructionReportDTO.statusKey,0)}>
                                {"Save"}
                            </Button> 
                            <Button color="primary" className="float-left"
                                style={{ marginLeft: "5px" }} 
                                hidden={constructionReportDTO.statusKey > 0   }
                                onClick={() => this.saveDataConstructionReport(0,1)}>
                                {"Save As Draft"}
                            </Button>
                            <Button color="primary" className="float-left"
                                style={{ marginLeft: "5px" }} 
                                hidden={constructionReportDTO.statusKey > 0   }
                                onClick={() => this.saveDataConstructionReport(2,0)}>
                                {"Save and Start Project"}
                            </Button>
                            <Button 
                                color="primary" 
                                className="float-left"
                                style={{ marginLeft: "5px" }} 
                                hidden={ !(isSecurityAdminLogin && constructionReportDTO.statusKey === 3) } //Have to be Admin and completed status
                                onClick={() => this.saveDataConstructionReport(2,0)}>
                                {"Start Project"}
                            </Button>
                            <Button color="success" className="float-left"
                                style={{ marginLeft: "5px" }} 
                                hidden={ constructionReportDTO.statusKey != 2 && (constructionReportDTO.statusKey === 0 || constructionReportDTO.statusKey === 1 
                                                                                    || constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4
                                                                                    || constructionReportDTO.statusKey === 5) }
                                onClick={() => this.saveDataConstructionReport(5,0)}>On Hold</Button>
                            <Button color="success" className="float-left"
                                style={{ marginLeft: "5px" }} 
                                hidden={ constructionReportDTO.statusKey != 5 && (constructionReportDTO.statusKey === 0 || constructionReportDTO.statusKey === 1 
                                                                                    || constructionReportDTO.statusKey === 2
                                                                                    || constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4
                                                                                    ) }
                                onClick={() => this.saveDataConstructionReport(2,0)}>Start Project</Button>
                            <Button outline color="secondary"
                                className="btn-no-border"
                                //onClick={this.newProject}
                                onClick={ ()=> this.addDailyReport(constructionReportDTO === undefined || constructionReportDTO.statusKey <= 0 ? 0 : constructionReportDTO.projectType)}
                                disabled={constructionReportDTO === undefined || (!isSecurityAdminLogin && constructionReportDTO.statusKey != 1 && constructionReportDTO.statusKey != 2)} //Has to be in an Started Status  
                                hidden ={constructionReportDTO === undefined || (constructionReportDTO.statusKey != 1 && constructionReportDTO.statusKey != 2)} //Has to be in an Started Status  
                                >
                                <img
                                    src={Plus}
                                    alt="new"
                                    style={{ width: "25px", margin: "0 5px 0 0" }} />
                                Add Daily Report
                            </Button> 



                        </Col> 
                        <Col sm={7} className="float-right">  
                            <ConfirmModal 
                                outline={true}
                                color="danger"
                                className="float-right"
                                buttonLabel={"Cancel"}
                                hidden={ 
                                    constructionReportDTO.statusKey == 0  
                                    || constructionReportDTO.statusKey == 3 
                                    || constructionReportDTO.statusKey == 4    
                                    || constructionReportDTO.statusKey == 5  
                                    }
                                formName="Confirm"
                                id={afeHeaderKey}
                                message={"Are you sure you want to cancel the construction report?"}
                                onClickYes={(e) => this.saveDataConstructionReport(4,0)}
                                image={<img
                                    src={TrashBin}
                                    id={afeHeaderKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                            </ConfirmModal>
                            <ConfirmModal 
                                outline={true}
                                color="danger"
                                className="float-right"
                                buttonLabel={"Delete"}
                                hidden={constructionReportDTO.statusKey > 0 || constructionReportDTO.constructionReportKey <=0 }
                                formName="Confirm"
                                id={afeHeaderKey}
                                message={"Are you sure you want to delete the construction report?"}
                                onClickYes={(e) => this.saveDataConstructionReport(-1,0)}
                                image={<img
                                    src={TrashBin}
                                    id={afeHeaderKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                            </ConfirmModal> 
                            <Button color="success" 
                                className="float-right"
                                style={{ marginRight: "5px" }}
                                //ref={ref}
                                hidden={ constructionReportDTO.statusKey == 0 || constructionReportDTO.statusKey == 1 
                                        || constructionReportDTO.statusKey == 3 || constructionReportDTO.statusKey == 4 
                                        || constructionReportDTO.statusKey == 5}
                                onClick={() => this.saveDataConstructionReport(3,0)}>Complete</Button>
                            
                            
                            <Button 
                                //color="success" 
                                color="secondary"
                                className="float-right"
                                style={{ marginRight: "5px" }}
                                //ref={ref}
                                hidden={ constructionReportDTO.statusKey < 3 || constructionReportDTO.statusKey === 5 }
                                onClick={() => this.openProjectQuestions()}>Completed Questions</Button>      
                        </Col>
                    </Row>
                    <ColoredLine color="grey" />
                    
                    <Row form> 
                        <Alert color="success" isOpen={showSaveAlert} 
                            style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                            {"Data Save Successfuly !!!!"}
                        </Alert>  
                        <Alert color={alertColor} toggle={() => this.toggleAlert()}
                            isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {alertMessage}
                        </Alert >
                    </Row>
                    <Accordion allowMultipleExpanded={true}
                        allowZeroExpanded={true}
                        preExpanded={(afeHeaderKey > 0 ? (showCostTracking ? ['costTracking'] : ['general']) : ['general'])}>

                        <AccordionItem uuid='general'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>General Information</strong>
                                    <strong style={{ textAlign: 'right', paddingLeft: '45rem' }}>Status - {constructionReportDTO.statusDescription}</strong>   
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                            <Row form>
                                                <Col md={4}>
                                                    <Label for="afeNo">Construction Report ID</Label>
                                                </Col>
                                                <Col md={8}>
                                                    <Input 
                                                        disabled 
                                                        type="text" 
                                                        name="constructionReportID"
                                                        id="constructionReportID" placeholder=""
                                                        value={constructionReportDTO == undefined || constructionReportDTO == null  ? null : constructionReportDTO.constructionReportID} //this.getProjectName(constructionReportDTO.afeHeaderKey)}
                                                    /> 
                                                </Col> 
                                            </Row>
                                    </Col>
                                </Row>
                                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeNo">AFE No</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Select options={this.getAFTList(constructionReportDTO == undefined || constructionReportDTO == null || 
                                                                                constructionReportDTO.afeHeaderKey == null || constructionReportDTO.afeHeaderKey < 0 ? 0 
                                                                                :constructionReportDTO.constructionReportKey)}//{afeList}
                                                        isDisabled={constructionReportDTO.statusKey != 0}
                                                        className={(!validateDataItem.afeHeaderKey ? "form-error" : "")}
                                                        value={constructionReportDTO == undefined || constructionReportDTO == null || 
                                                                constructionReportDTO.afeHeaderKey == null || constructionReportDTO.afeHeaderKey < 0 ? null : 
                                                                this.getSelLinkAFE(constructionReportDTO.afeHeaderKey)}
                                                        //defaultValue={this.getSelLinkAFE(afeData[0].linkAFE)}
                                                        onChange={(e) => this.onTextChange("afeHeaderKey", e, e)}
                                                        isClearable={true}
                                                        invalid={!validateDataItem.afeHeaderKey} 
                                                    >
                                                </Select>
                                                <FormFeedback tooltip > Please select an AFE</FormFeedback>
                                            </Col> 
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="createdBy">Project Name</Label>
                                            </Col>
                                            <Col md={8}>
                                                 <Input disabled type="text" name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={constructionReportDTO == undefined || constructionReportDTO == null  ? null : constructionReportDTO.projectName} //this.getProjectName(constructionReportDTO.afeHeaderKey)}
                                                />
                                            </Col>

                                        </Row>
                                    </Col>

                                </Row>

                                <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeName">Created By</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    onChange={(e) => this.onTextChange("afeName", e.target.value, e)}
                                                    value={constructionReportDTO.createdBy}
                                                />
                                            </Col>

                                        </Row>
                                    </Col>

                                    <Col md={6}>

                                        <Row form>
                                            <Col md={4}>
                                                <Label for="typeKey">Created Date</Label>
                                            </Col> 
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={FormatDate(constructionReportDTO.createdDate)}
                                                />
                                            </Col> 
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginTop: "5px", marginBottom: "5px" }} hidden={true}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeName">Modified By</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={constructionReportDTO.lastModifiedBy}
                                                />
                                            </Col> 
                                        </Row>
                                    </Col> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="typeKey">Modified Date</Label>
                                            </Col> 
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={FormatDate(constructionReportDTO.lastModifiedDate)}
                                                />
                                            </Col> 
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Start Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={this.getStartDate(constructionReportDTO.afeHeaderKey)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                    <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">End Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={this.getEndDate(constructionReportDTO.afeHeaderKey)}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                </Row>  
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="afeManager">Project Manager</Label> 
                                            </Col>
                                            <Col md={8}> 
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={this.getProjectManager(constructionReportDTO.afeHeaderKey)}
                                                />  
                                            </Col>
                                        </Row> 
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSignedDate">Project Type</Label>
                                            </Col> 
                                            <Col md={8}> 
                                                <Select options={this.getFileterProjectTypeByAFE(constructionReportDTO.afeHeaderKey)}//{costTypeList.filter()}
                                                    isDisabled={constructionReportDTO.statusKey != 0}
                                                    className={(!validateDataItem.projectTypeKey ? "form-error" : "")}
                                                    value={constructionReportDTO.projectTypeKey !== undefined && constructionReportDTO.projectTypeKey !== null ? this.getSelAFECostType(constructionReportDTO.projectTypeKey)
                                                            : null }
                                                    onChange={(e) => this.onTextChange("projectTypeKey", e, e)}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    invalid={!validateDataItem.projectTypeKey}
                                                    >
                                                </Select>
                                                <FormFeedback tooltip > Please select an Project Type</FormFeedback>
                                            </Col> 
                                        </Row>
                                    </Col>
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="afeManager">Basin</Label> 
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={this.getBasin(constructionReportDTO.afeHeaderKey)}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="afeManager">Expected Completion Date</Label> 
                                            </Col>
                                            <Col md={8}>
                                                <DatePicker 
                                                    value={constructionReportDTO.expectedCompletionDate}
                                                    className={(!validateDataItem.expectedCompletionDate ? "form-error" : "")}
                                                    onChange={(v, f) => this.onTextChange("expectedCompletionDate", v, f)}
                                                    disabled={!isSecurityAdminLogin && (constructionReportDTO.statusKey == 3 
                                                                || constructionReportDTO.statusKey == 4 ||  constructionReportDTO.statusKey == 5)}
                                                />
                                                <FormFeedback tooltip > Please select an Expected Completion Date</FormFeedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }} hidden={constructionReportDTO.projectTypeKey == 3 ? false : true}>
                                    <Col md={6} > 
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="budgetClass">Pipe Size</Label> 
                                            </Col>
                                            <Col md={8}>
                                                <NumberFormat 
                                                    value={constructionReportDTO.pipeLineSize}
                                                    onValueChange={(e) => this.onTextChange("pipeLineSize", e.value, e)}
                                                    className={"form-control text-md-right"}
                                                    disabled={!isSecurityAdminLogin && constructionReportDTO.statusKey >1}
                                                    thousandSeparator={true}
                                                    />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="budgetID">Uncased Bore Length</Label> 
                                            </Col> 
                                            <Col md={8}>
                                                <NumberFormat 
                                                    value={constructionReportDTO.unCasedBoreLength}
                                                    onValueChange={(e) => this.onTextChange("unCasedBoreLength", e.value, e)}
                                                    className={"form-control text-md-right"}
                                                    thousandSeparator={true}
                                                    disabled={!isSecurityAdminLogin && constructionReportDTO.statusKey >1}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col>
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }} hidden={constructionReportDTO.projectTypeKey == 3 ? false : true}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="typeKey">Project Length (ft.)</Label>
                                            </Col> 
                                            <Col md={8}>
                                                <NumberFormat 
                                                    value={constructionReportDTO.projectLength}
                                                    onValueChange={(e) => this.onTextChange("projectLength", e.value, e)}
                                                    className={"form-control text-md-right"}
                                                    //className={(!validateDataItem.projectLength ? "form-error" : "")}
                                                    //className={(!validateDataItem.estimatedCompletion ? "form-error" : "")}
                                                    disabled={!isSecurityAdminLogin && constructionReportDTO.statusKey > 1}
                                                    thousandSeparator={true}
                                                    />
                                                <FormFeedback tooltip > Please input Project Lenght greater than 0.</FormFeedback>    
                                            </Col> 
                                        </Row>
                                    </Col> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="estStartDate">Cased Bore Length</Label> 
                                            </Col>
                                            <Col md={8}>
                                                <NumberFormat 
                                                    value={constructionReportDTO.casedBoreLength}
                                                    onValueChange={(e) => this.onTextChange("casedBoreLength", e.value, e)}
                                                    className={"form-control text-md-right"}
                                                    disabled={!isSecurityAdminLogin && constructionReportDTO.statusKey >1}
                                                    thousandSeparator={true}
                                                    />
                                            </Col> 
                                        </Row> 
                                    </Col>
                                </Row>
                                <Row form style={{ marginBottom: "5px" }} hidden={constructionReportDTO.projectType == 'Facility' ? false : true}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="lat">Latitude</Label>
                                            </Col> 
                                            <Col md={8}>
                                                <Input 
                                                    disabled={!isSecurityAdminLogin && (constructionReportDTO.statusKey == 2 || constructionReportDTO.statusKey == 3 || constructionReportDTO.statusKey == 4)}
                                                    type="number" 
                                                    name="lat" 
                                                    id="lat" 
                                                    value={constructionReportDTO != undefined && constructionReportDTO != null ? constructionReportDTO.lat : null}
                                                    onChange={(e) => this.onTextChange("lat", e.target.value, e)}
                                                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                /> 
                                            </Col> 
                                        </Row> 
                                    </Col> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label invalid for="long">Longtitude</Label> 
                                            </Col>
                                            <Col md={8}> 
                                                <Input 
                                                    disabled={ !isSecurityAdminLogin && (constructionReportDTO.statusKey == 2 || constructionReportDTO.statusKey == 3 || constructionReportDTO.statusKey == 4)}
                                                    type="number" 
                                                    name="long" 
                                                    id="long" 
                                                    value={constructionReportDTO != undefined && constructionReportDTO != null ? constructionReportDTO.long : null}
                                                    onChange={(e) => this.onTextChange("long", e.target.value, e)}
                                                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                                                />
                                            </Col> 
                                        </Row> 
                                    </Col>
                                </Row> 
                            </AccordionItemPanel>
                        </AccordionItem> 
                        <div hidden={afeHeaderKey < 0}> 
                            <AccordionItem hidden={!showCostTracking}
                                uuid='costTracking'>
                                <AccordionItemHeading>
                                    <AccordionItemButton > 
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel> 
                                </AccordionItemPanel>
                            </AccordionItem> 
                            <AccordionItem hidden={constructionReportKey == undefined || constructionReportKey == null || constructionReportKey <=0 || constructionReportDTO.statusKey <= 0}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Daily Reports"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel> 
                                    <ReportAccording
                                        constructionReportKey={constructionReportKey}
                                        projectType={constructionReportDTO.projectType}
                                        user={this.props.user}
                                        constructionReportDTO={constructionReportDTO}
                                        statusKey={constructionReportDTO.statusKey}
                                        isSecurityAdminLogin={isSecurityAdminLogin}
                                        toggleCloseDailyReport={this.toggleCloseDailyReport}  
                                    >     
                                    </ReportAccording> 
                                </AccordionItemPanel>
                            </AccordionItem> 
                            {/* <AccordionItem hidden={constructionReportKey == undefined || constructionReportKey == null || constructionReportKey <=0 || constructionReportDTO.statusKey <= 0}> */}
                            <AccordionItem hidden={constructionReportDTO == undefined || constructionReportDTO == null}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>Attachments</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel> 
                                <FileManager
                                    assetkey={constructionReportDTO.constructionReportKey > 0 ? constructionReportDTO.constructionReportKey : constructionReportDTO.blob_id}                    
                                    controlid={getRandomInt}
                                    folderPath={'constructionReport/' + constructionReportDTO.blob_id}
                                    linkTableName={'topConstructionReport'} 
                                    >
                                </FileManager>
                                </AccordionItemPanel>
                            </AccordionItem> 
                            <AccordionItem hidden={constructionReportKey == undefined || constructionReportKey == null || constructionReportKey <=0 || constructionReportDTO.statusKey <= 0}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Change Orders "}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ConstructionReportingChangeOrders
                                        constructionReportKey={constructionReportKey}  
                                        projectType={constructionReportDTO.projectType}
                                        user={this.props.user}  
                                        constructionReportDTO={constructionReportDTO}  
                                        allowNewChangeOrder={true}
                                        dailyReportKey={0}
                                        isSecurityAdminLogin={isSecurityAdminLogin}   
                                    >  
                                    </ConstructionReportingChangeOrders> 
                                </AccordionItemPanel>
                            </AccordionItem> 
                        </div>
                    </Accordion>
                    <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '} 
                            </ModalFooter>
                        </Modal>
                    </div> 
                    <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            size={"xl"}
                            centered
                            >
                            <ModalHeader toggle={() => this.toggleReport()}> {reportName == 'AFECloseout' ? 'AFE Closeout': "Approval Document"} </ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName = {reportName} 
                                    reportParameters = {this.createReportParamter()} 
                                    toggle={this.toggleReport} 
                                > 
                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div> 
                    <div>
                        <Modal isOpen={this.state.modalFacilityDailyReport}
                            toggle={() => this.toggleNewFacilityDailyReport()}
                            size="xl"
                            backdrop={"static"}
                            //onClosed={() => this.getAFEdata()}
                            >
                            <ModalHeader toggle={() => this.closeFacilityDailyReport()}
                                //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                                >
                                {"Facility Daily Report Form"}
                            </ModalHeader>
                            <ModalBody>
                                <DailyFacilityReport 
                                    toggle={this.closeFacilityDailyReport}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    constructionReportKey={constructionReportDTO.constructionReportKey}
                                    constructionReportFacilityKey={reportKey}
                                    constructionReportDTO={constructionReportDTO}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList} 
                                    constructionReports={dailyReports} 
                                    isSecurityAdminLogin={isSecurityAdminLogin}
                                    >
                                </DailyFacilityReport>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.modalPipeLineDailyReport}
                            toggle={() => this.toggleNewPipeLineDailyReport()}
                            size="xl"
                            backdrop={"static"} 
                            >
                            <ModalHeader toggle={() => this.closePipeLineDailyReport()} 
                                >
                                {"Pipeline Daily Report Form"}
                            </ModalHeader>
                            <ModalBody>
                                <DailyPipeLineReport 
                                    toggle={this.closePipeLineDailyReport}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    constructionReportKey={constructionReportDTO.constructionReportKey}  
                                    constructionReportPipelineKey={reportKey}
                                    constructionReportDTO={constructionReportDTO}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList}
                                    constructionReports={dailyReports}
                                    isSecurityAdminLogin={isSecurityAdminLogin} 
                                    >
                                </DailyPipeLineReport>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={modalGeneralDailyReport}
                            toggle={() => this.closeGeneralDailyReport()}
                            size="xl"
                            backdrop={"static"} 
                            >
                            <ModalHeader toggle={() => this.closeGeneralDailyReport()} 
                                >
                                {"General Daily Report Form"}
                            </ModalHeader>
                            <ModalBody>
                                <DailyGeneralReport 
                                    toggle={this.closeGeneralDailyReport} 
                                    user={this.props.user}
                                    constructionReportKey={constructionReportDTO.constructionReportKey} //constructionReportKey}
                                    constructionReportGeneralKey={reportKey}
                                    constructionReportDTO={constructionReportDTO}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList}
                                    constructionReports={dailyReports}
                                    isSecurityAdminLogin={isSecurityAdminLogin} 
                                    >
                                </DailyGeneralReport>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.modalChangeOrder}
                            toggle={() => this.toggleNewChangeOrder()}
                            size="md"
                            backdrop={"static"}
                            //onClosed={() => this.getAFEdata()}
                            >
                            <ModalHeader toggle={() => this.closeChangeOrder()}
                                //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                                >
                                {"New Change Order"}
                            </ModalHeader>
                            <ModalBody>
                                <ChangeOrder 
                                    toggle={this.closeChangeOrder}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    constructionReportKey={constructionReportKey}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList}>
                                </ChangeOrder>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div> 
                        <Modal isOpen={this.state.completedProjectQuestionsModel}
                            toggle={() => this.toggleOpenQuestions()}
                            size="xl"//"md"
                            backdrop={"static"} 
                            >
                            <ModalHeader toggle={() => this.closeOpenQuestions()} 
                                >
                                {"Complete Project"}
                            </ModalHeader>
                            <ModalBody> 
                                <ConstructionReportingCompleteChk
                                    constructionReportDTO={constructionReportDTO}
                                    savedData={this.completedChk}
                                    cancelSave={this.cancelCompletedChk}
                                    user={user}
                                >
                                    
                                </ConstructionReportingCompleteChk>
                            </ModalBody>
                        </Modal>
                    </div>

                </Form>

            </div>)
        }
            
    }

}

export default ConstructionReportingDetails
