import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';

import MenuIcon from '../resources/menu.png';
import PlusIcon from '../resources/plus.svg'
import RefreshIcon from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import ExcelIcon from '../resources/excel.svg'
import GlobeBW from '../resources/globebw.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'
import RiserDetail from '../operation/RiserDetail'


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

export default class Riser extends Component {
    static displayName=Riser.name;


    constructor(props){
        super(props)

            this.onGridRef = this.onGridRef.bind(this);
            this.onGridRowSelected = this.onGridRowSelected.bind(this);
            this.saveWorkbook = this.saveWorkbook.bind(this);

            this.state={
                windowHeight: window.innerHeight,
                riserDetail: [],
                modal:false,
                riserKey: -1,
                riserData:null,
                // materialRequestData: [],
                riser: [],
                isLoaded: false
            }
    
        
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.riser;
    }


    onGridRowSelected(s, e) {

        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 



            this.setState({
                            riserData: dataItem,
                            riserKey: dataItem.riserKey
                            },()=>{
            console.log(dataItem)
            console.log("Riser Key " + dataItem.riserKey)
                            })       

            
            
           
        } 
    }


    getRiser=() =>{

        let myURI = apiURL + 'Risers'
        
        console.log(myURI)
        fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                
                this.setState({
                    isLoaded: true,
                    riser: json      
                })
                
            });
           
          
    }



    addRiser=()=>{
        const {riserKey} = this.state
        this.setState({riserKey: -1},()=>{
            console.log("riserKey " + riserKey)
        })
        this.toggleModal()
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getRiser()
        // this.getMaterialRequest()
    }

    toggleModal=()=>{
        this.setState({modal: !this.state.modal})
    }

    toggleEdit=()=>{
        const {riserKey} = this.state
        if(riserKey > 0){
            this.toggleModal()
        }
        else{
            
        }
    }


    render(){
        const {riser, riserData, riserKey, windowHeight} = this.state
        const gridHeight = (windowHeight * 0.8) + "px"
        var {isLoaded} = this.state
        if (!isLoaded) {
            return (<div>
                <h3>Loading Risers.....</h3>
            </div>)
        } else
        return(<div>
             <FormGroup>
                    <Row form>
                        <h2> Riser |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={this.getFacInvCollector}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>


                                <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.addRiser}
                                // onClick={this.addItemTypeModal}
                                >
                                    <img 
                                    src={PlusIcon}
                                    alt="Add"
                                    style={{width:"25px", height:"25px", margin:"0 0 0 150"}}/>
                                    Add
                                </Button>{' '}

                                <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.toggleEdit}                      
                                >
                                    <img 
                                    src={MenuIcon}
                                    alt="Add"
                                    style={{width:"25px", height:"25px", margin:"0 0 0 150"}}/>
                                    Edit
                                </Button>{' '}
                        
                        
                                
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}
                                >
                            <img 
                            src={ExcelIcon}
                            alt="excel"
                            style={{width:"30px", height: "30px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                        {/* <label style={{margin:"10px 0 0 0"}}>Filter by:  </label> */}
              
                        {/* <CustomInput type = "select" value={this.state.filterType}
                                                id="test"
                                                label="test"
                                                onChange={this.onFilter}
                                                style={{width:"150px", height: "35px", margin:"5px 0 0 0"}}
                                                >       
                                    
                                   <option value={"test"}>No Filter</option>
                                    {itemType.map(v => <option value={v.itemTypeKey}>{v.itemTypeID}</option>)}
                               
                             
                                    </CustomInput> */}
{/* 
                                    <Button outline color="secondary" 
                                    hidden={isSecurityAdminLogin}
                                className="btn-no-border" 
                                onClick={this.toggleItemTypeCreator}>
                            <img 
                            src={EditorIcon}
                            alt="excel"
                            style={{width:"30px", height: "25px", margin:"0 0 0 0"}}/>
                            Item Type Editor
                        </Button>{' '} */}

                       
                                    
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>

            <FormGroup   >
                <IgrDataGrid
                      class="alway-on-bottom"
                     ref={this.onGridRef}
                     dataSource={riser}
                     width="100%"
                     height={gridHeight}
                     selectionMode="SingleRow"
                     isColumnOptionsEnabled="true"
                     selectedKeysChanged={this.onGridRowSelected}
                     scrollable={true}
                     autoGenerateColumns="false" 
                     
                     >
                      {/* <IgrTextColumn field="locationID" headerText="Location ID" width="*>200" 

                        /> */}
                        <IgrTextColumn field="areaName" headerText="Area Name" width="*>200" 

                        />

                        <IgrTextColumn field="qrCodeValue" headerText="QR Code" width="*>200" 

                        />  

                        
                            <IgrTextColumn field="riserName" headerText="Riser Name" width="*>200" 

                        />

                        <IgrTextColumn field="riserStatusDesc" headerText="Status" width="*>200" 

                        />

                           <IgrTextColumn field="riserSize" headerText="Size" width="*>200" 

                        />
                        

                          <IgrTemplateColumn 
                          field="sdfsdfsd" headerText="GPS"
                        //    src={GlobeBW}
                          width="90" 
                                cellUpdating={this.onGlobeUpdating}
                                // cellUpdating={this.onDeleteCellUpdating}
                                
                                // template={}
                        />
                            


                             <IgrTextColumn field="comments" headerText="Comments" width="*>200" 

                        />


                </IgrDataGrid>

            </FormGroup>


            <Modal isOpen={this.state.modal}
                 className="alway-on-top"
                        toggle={() => this.toggleModal()}
                        size="xl"
                        onClosed={() => this.getRiser()}
                        backdrop={"static"}
                    >
                        <ModalHeader toggle={() => this.toggleModal()}>
                            Riser Details
                        </ModalHeader>
                        <ModalBody>
                            <RiserDetail
                            
                            riserKey = {riserKey}
                            toggleModal={this.toggleModal}
                                user={this.props.user}
                                refreshData={this.getRiser}
                             
                            >

                            </RiserDetail>

                        </ModalBody>

                    </Modal>
        </div>)
    }



//    getProductivityChart(props) {
//         const info = props.dataContext ;
//         return (
//             <div className="container">
//                <IgrSparkline
//                    height="30px" width="100%"
//                    displayType="Column"
//                    dataSource={info.rowItem.lat}
//                    valueMemberPath="Value"
//                    labelMemberPath="Week"
//                    lineThickness={2}
//                    brush="rgb(21, 190, 6)"
//                    negativeBrush="rgb(211, 17, 3)" />
//             </div>
//         );
//     }
    saveWorkbook() {

        var actCols = this.grid.actualColumns

        let gridDts = this.grid.dataSource 

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                  
            }
        }

        ExcelUtility.save(wb, "Riser");
    }

    
    onGlobeUpdating(s, e) {
        const content = e.content;
        //  as HTMLDivElement

        const info = e.cellInfo;
        const item = info.rowItem;
        let link = HTMLAnchorElement;
        let test = HTMLImageElement
        if (content.childElementCount === 0) {

            link = document.createElement("a");
            test = document.createElement("img")
            content.style.verticalAlign = "center";
            // content.style.marginTop = "15px";
            content.style.lineHeight = "normal";
            // content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";
            content.style.color = "#4286f4";
            content.style.width = "20px";
            content.style.height = "30px"
            content.appendChild(link);
             link.appendChild(test);
        } else {
            link = content.children[0]; 
            // as HTMLAnchorElement;
        }

       

        link.href = "https://www.google.com/maps/search/?api=1&query=" + item.lat + "," + item.long
        link.target = "_blank"
        test.src= GlobeBW
        
        
        // link.textContent = item.lat
       
    }

   
}