import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class WOApprovalComment extends Component {
    static displayName = WOApprovalComment.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
       
        this.state = {
            user:props.user,
            readOnly: props.readOnly,
            workOrderKey: props.workOrderKey,
            woComment:[],
            isWOCommentLoaded: false,
            modalComment:false,
            cmtDate: new Date().toISOString(),
            operationCommentKey: -1,
            comments: ' ',
            fiixWOId: props.fiixWOId
        }

    }

        

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.woComment;
    }

    componentDidMount() {
          this.getWOComment()
    }


    getWOComment = () => {
        const { afeHeaderKey, workOrderKey } = this.state
        let myURI = apiURL + 'Fiix/ApprovalComment/' + workOrderKey
    
        // console.log(myURI);
    
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    woComment: json,
                    isWOCommentLoaded: true
                })
            });
    }

    toggleComments = () => {
        // console.log("modalComment: " + this.state.modalComment)
        this.setState({ modalComment: !this.state.modalComment })
    }

    toggleCommentOk=()=>{
        if (this.saveWOComment())
        {
            this.getWOComment()
            this.setState({
                modalComment: !this.state.modalComment,
                comments: " "
            })
            this.syncWOCommentTOFiix()
        }
    
    }

    saveWOComment = () => {
        const { operationCommentKey } = this.state
        if (this.validateData()) {

            if (operationCommentKey > 0) {
                if (this.updateComment()) {
                    this.setState({ operationCommentKey: -1 })
                }
            } else {
                const addDTO = this.createWOCommentForCreateDTO()
                let myURI = apiURL + 'Fiix/Comment'
                fetch(myURI, apiPOSTHeader(addDTO))
                    .then(async response => {
                        const rspData = response.text();
                        if (!response.ok) {
                            //("Error return but you dont see")
                            const error = (rspData && rspData.message) || response.status;
                            //return Promise.reject(error);
                            return false
                        }
                        this.getWOComment()
                       // console.log("No error here: " + rspData)
                    })
                    .catch(error => {
                       // console.log("PUT Error: " + error);
                        this.getWOComment()
                        return false
                    })
                    this.getWOComment()
            }
            return true
        }

        return false
    }


    syncWOCommentTOFiix = () => {     
            const { workOrderKey, fiixWOId } = this.state
            if (workOrderKey > 0) {
                let myURI = apiURL + 'Fiix/Sync/WBCommentsToFiix/' + fiixWOId
                fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })       
            } 
        
    }

    updateComment = () => {
        const uptDTO = this.createWOCommentForUpdateDTO()
        let myURI = apiURL + 'Fiix/Comment'


        fetch(myURI, apiPUTHeader(uptDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    //return Promise.reject(error);
                    return false
                }
                this.getWOComment()
              //  console.log("No error here: " + rspData)
            })
            .catch(error => {
               // console.log("PUT Error: " + error);
                this.getWOComment()
                return false
            })
            this.getWOComment()
        return true
    }

    
    validateData = () => {
        var { comments } = this.state
        comments = comments.trim()
        if (comments != "") {
            return true
        } else {
            this.setState({ comments: comments })
        }
    }

    createWOCommentForCreateDTO = () => {
        const { cmtDate, comments, user, workOrderKey } = this.state

        const afeCmtDTO = {
            "commentTypeKey": 7, //WO Approval
            "compObjectKey": workOrderKey, //selectedValue.key,
            "workOrderKey": null,
            "commentDate": cmtDate,
            "comments": comments,
            "createdBy": getUserADAccount(user.account.userName),
            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return afeCmtDTO
    }

    createWOCommentForUpdateDTO = () => {
        const { operationCommentKey, comments, user } = this.state
        const opsCmtDTO = {
            "operationCommentKey": operationCommentKey,
            "comments": comments,
            "modifiedBy": getUserADAccount(user.account.userName),
           // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    onGridRowSelected(s, e) {
        //console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // this.setState({ selAFELine: dataItem })           
            this.setState({
                comments: dataItem.comments,
                cmtDate: dataItem.commentDate,
                operationCommentKey: dataItem.operationCommentKey
            }) 
           // console.log(dataItem)
        }

    }

    onTextChange = (fieldName, fieldValue, e) => {
        this.setState({ comments: fieldValue });
    }

    handleChange = (v, f) => {
        this.setState({
            cmtDate: v
        })
    }



    render() {
        const { user, 
            isWOCommentLoaded, readOnly,
            operationCommentKey,
            comments, woComment, fiixWOId } = this.state

        if (!isWOCommentLoaded) {
            return (<div>
                Loading Comment ......
            </div>)
        } else
            return (<div>
                <Row>
                    <Col md={12}>
                                 
                    <Button color="primary" outline className="float-left"
                                        style={{ height: "35px", marginTop:"10px"  }}
                                        //hidden={this.setIsReadOnly(statusKey)}
                                        onClick={() => this.toggleComments()}
                                        >Add</Button>

                                     <Button color="primary" outline className="float-left"
                                        style={{ height: "35px", marginLeft:"10px", marginTop:"10px"  , marginBottom:"10px" }}
                                        hidden={!readOnly}
                                        onClick={() => this.toggleComments()}
                                        >Edit</Button>

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        // height={(woComment.length > 10 ? "300px" : "100%")}
                        height={'150px'}
                        width='100%'
                        dataSource={woComment}
                        //isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}                
                    >

                        <IgrTemplateColumn
                            field="commentDate"
                            headerText="Date"
                            width="170"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTextColumn
                            field="createdBy"
                            headerText="By"
                            width="140"
                        />

                        <IgrTextColumn
                            field="comments"
                            headerText="Comments"
                            width="*>350"
                        />

                        <IgrNumericColumn field="operationCommentKey" isHidden />
                    </IgrDataGrid>

                </FormGroup>
                   {/* <Label for="fiixWorkInstruction">Comments</Label> */}
                   {/* <Button color="primary" outline className="float-left"
                                        style={{ height: "35px" }}
                                        //hidden={this.setIsReadOnly(statusKey)}
                                        onClick={() => this.toggleComments()}
                                        >Add</Button>

                                     <Button color="primary" outline className="float-left"
                                        style={{ height: "35px", marginLeft:"10px" }}
                                        hidden={!readOnly}
                                        onClick={() => this.toggleComments()}
                                        >Edit</Button> */}

                                    </Col>

                                    
                                </Row>




                <div>
                    <Modal isOpen={this.state.modalComment} toggle={() => this.toggleComments()}
                        // onClosed={() => this.refreshComment()}
                        size='lg'
                    >
                        <ModalHeader toggle={() => this.toggleComments()}
                        > New Comments </ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Label for="commentDate" sm={2}>Date</Label>
                                <Col sm={10}>
                                    <DatePicker value={this.state.cmtDate} id="commentDate"
                                         onChange={(v, f) => this.handleChange(v, f)} disabled={readOnly} 
                                        
                                        />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="exampleText" sm={2}>Text Area</Label>
                                <Col sm={10}>
                                    <Input type="textarea"
                                        name="text"
                                        id="exampleText"
                                        rows="6"
                                        defaultValue={comments}
                                        value={comments}
                                        invalid={comments == ""}
                                        onChange={(e) => this.onTextChange("comments", e.target.value, e)} 
                                        />
                                    <FormFeedback tooltip> Comment can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleCommentOk()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggleComments()}>Cancel</Button>

                        </ModalFooter>
                    </Modal>
                </div>
                    
                    
                    
                    </div>




                )}

                onCellUpdating(s, e) {
                    const content = e.content;
                    const info = e.cellInfo;
                    const item = info.rowItem;
                    let link;
                    if (content.childElementCount === 0) {
                        link = document.createElement("label");
                        let cell = document.createElement("div");
            
                        content.style.fontFamily = "Verdana";
                        content.style.fontSize = "13px";
            
                        content.style.margin = "0px -20px -10px -12px";
                        content.style.padding = "0px 10px 10px 10px";
                        //content.style.width = "100%";
            
                        content.appendChild(link);
                    }
                    else {
                        link = content.children[0];
            
                    }
            
                    switch (s.field) {
                        case 'commentDate':
            
                            var date = new Date(item.commentDate);
                            link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;
            
                        default:
            
                    }
            
                }

                
}