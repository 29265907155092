import React, { Component } from 'react';
import { apiURL, apiHeader, apiDELETEHeader } from '../resources/apiURL';
import { Form } from 'reactstrap';
import moment from "moment";
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Input } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn, IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { ColoredLine } from '../resources/utility';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Refresh from '../resources/refresh.svg'
import Remove from '../resources/remove.png'
import TrashBin from '../resources/trash.svg'
import Revise from '../resources/revise.png'
import JibLineDet from './JibLineDet';
import CustomConfirmModal from '../resources/CustomConfirmModal'
import Report from '../resources/report.png'


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();



export default class JibDetail extends Component {
    static displayName = JibDetail.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onCellDataBound = this.onCellDataBound.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.toggleCustomConfirmModal = this.toggleCustomConfirmModal.bind(this);
        this.handleCustomConfirmYes = this.handleCustomConfirmYes.bind(this);

        this.state = {
            windowHeight: window.innerHeight,
            jibHeaderKey: props.jibHeaderKey,
            user: props.user,
            isJIBUser: props.isJIBUser,
            isJIBEdit: props.isJIBEdit,
            isJIBWorkday: props.isJIBWorkday,

            //selJibNo: props.selJibNo,
            jibHeaderdata: [],
            status: props.status,
            jibDetaildata: [],
            isHeaderLoaded: false,
            isLoaded: false,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            dropdownActionOpen: false,

            apiError: '',
            showBadAlert: false,
            showGoodAlert: false,
            isLoading: false,
            eibSubmitSupplierInvKey: -1,
            selEibLine: null,

            modalDetails: false,

            isCustomConfirmModalOpen: false,
            confirmModalMessage: '',
            confirmModalAction: null

        }
    }

    toggleCustomConfirmModal(message = '', action = null) {
        // if (!window.confirm(action)) {
        //     return;

        // }

        this.setState({
            isCustomConfirmModalOpen: !this.state.isCustomConfirmModalOpen,
            confirmModalMessage: message,
            confirmModalAction: action
        });
    }

    handleCustomConfirmYes() {
        if (this.state.confirmModalAction) {
            this.state.confirmModalAction();
        }
        this.toggleCustomConfirmModal();
    }

    onDismissAlert = () => {
        this.setState({ showBadAlert: false, showGoodAlert: false });
    }

    toggleDetails = () => {
        this.setState({
            modalDetails: !this.state.modalDetails
        })
    }
    closeDetailsForm = () => {
        this.setState({
            modalDetails: !this.state.modalDetails,
            eibSubmitSupplierInvKey: -1,
            selEibLine: null
            //,
            //mmStatusDesc: '',

        })
        this.getJibHeader();
        this.getDetails();
    }



    // callSandbox = () => {
    //     if (!window.confirm('Do you want to push the data to Sandbox?')) {
    //         return;
    //     }

    //     this.executeApiCall('sandbox');
    // }

    // callProduction = () => {
    //     if (!window.confirm('Do you want to push the data to Workday?')) {
    //         return;
    //     }

    //     this.executeApiCall('workday');
    // }


    callSandbox = () => {
        this.toggleCustomConfirmModal(
            'Do you want to push the data to Sandbox?',
            () => this.executeApiCall('sandbox')
        );
    }

    callProduction = () => {
        this.toggleCustomConfirmModal(
            'Do you want to push the data to Workday?',
            () => this.executeApiCall('workday')
        );
    }

    executeApiCall = (environment) => {

        const { jibHeaderKey } = this.state
        const user = getUserADAccount(this.state.user.account.userName)

        //const myURI = 'http://localhost:58000/api/Workday/WDSoapAPi/SupplierInvoice/primarykey/' + jibHeaderKey + '/environment/sandbox';

        const baseURI = apiURL + 'Workday/WDSoapAPi/SupplierInvoice/primarykey/';
        const myURI = `${baseURI}${jibHeaderKey}/environment/${environment}/user/${user}`;

        this.onDismissAlert();
        this.setState({ isLoading: true });
        fetch(myURI)
            .then(response => {
                if (!response.ok) {

                    return response.text().then(text => {
                        throw new Error(text);
                    });
                }

                return response.text();
            })
            .then(text => {
                // Assuming the 'ok' string indicates success
                if (text === "ok") {
                    this.setState({

                        apiError: null,
                        showGoodAlert: true,
                        showBadAlert: false,
                        isLoading: false,
                        // isHeaderLoaded:false,
                        // isLoaded: false
                    })


                    this.getJibHeader();
                    this.getDetails();
                    
                    
                } else {
                    // Handle any other text that is not 'ok' as an unexpected response
                    throw new Error('Unexpected response: ' + text);
                }
            })
            .catch(error => {


            });
    }

    printAll = () => {
        this.toggleCustomConfirmModal(
            'Are you sure you want to print all invoices? (It will be logged)',
            this.printAllandLogg
        );

    }

    printAllandLogg = () => {
        const { jibHeaderdata } = this.state
        const sharepointLoc = jibHeaderdata[0].sharepointLoc;
        const filename = jibHeaderdata[0].filename;

        // Remove the .xlsx extension and add _INVOICES.pdf
        const baseFilename = filename.replace('.xlsx', '');
        const newFilename = `${baseFilename}_INVOICES.pdf`;

        // Concatenate the new filename with the sharepoint location
        const fullUrl = `${sharepointLoc}/${newFilename}`;

        // console.log(fullUrl);

        // if (!window.confirm(jibHeaderdata[0].workdayAction)) {
        //     return;
        // }
        //const url = "https://h20bridge.sharepoint.com/sites/AccountingGroup/Shared Documents/04. Accounts Payable/AP ticket attachments/AP000002/Archived/202407_Arkoma_1/Arkoma JIB Workbook - 04.30.2024specsign_INVOICES.pdf";
        this.logPrint();

        window.open(fullUrl, '_blank');
    };

    logPrint = () => {

        const { jibHeaderKey } = this.state
        const user = getUserADAccount(this.state.user.account.userName)

        // console.log("_-----------------");




        //const myURI = 'http://localhost:58000/api/Workday/WDSoapAPi/SupplierInvoice/primarykey/' + jibHeaderKey + '/environment/sandbox';

        // let myURI = apiURL + 'AR/LogPrint/' + jibHeaderKey 

        const baseURI = apiURL + 'AR/LogPrint/';
        const myURI = `${baseURI}${jibHeaderKey}/user/${user}`;
        // console.log(myURI)


        // this.onDismissAlert();
        // this.setState({ isLoading: true });
        fetch(myURI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
            //,
            //body: JSON.stringify({}) // You can include additional data if needed
        })
            .then(response => {
                if (!response.ok) {

                    return response.text().then(text => {
                        throw new Error(text);
                    });
                }

                return response.text();
            })
            .then(text => {


                this.getJibHeader();
                this.getDetails();


            })
            .catch(error => {


                // this.getJibHeader();
            });
    }



    toggleDropdownAction = () => {
        this.setState({ dropdownActionOpen: !this.state.dropdownActionOpen })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.jibDetaildata;


        this.grid.actualColumns.toArray().forEach((column) => {
            column.dataBound = this.onCellDataBound;
        });

    }

    // onTextChange = (fieldName, fieldValue, e) => {
    //     const { mtlMoveHdr } = this.state

    //        switch (fieldName) {
    //         case "comments":
    //             mtlMoveHdr.comments = fieldValue
    //             break;
    //         default:
    //             break;
    //     }

    //     this.setState({ mtlMoveHdr: mtlMoveHdr })

    // }


    getDetails = () => {
        const { jibHeaderKey } = this.state

        let myURI = apiURL + 'AR/GetJIBDetails/' + jibHeaderKey

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isLoaded: true,
                    jibDetaildata: json
                })
            });
    }


    createDeleteHdrDTO = () => {
        const { jibHeaderKey } = this.state

        const { user } = this.props

        const hdrForDeleteDTO = {
            "JIBHeaderKey": jibHeaderKey,
            "DeletedBy": getUserADAccount(user.account.userName), /*4=Submitted, 6=Reviewed*/
        }

        return hdrForDeleteDTO;
    }

    deleteHeader = () => {
        this.toggleCustomConfirmModal(
            'Do you want to delete the selected JIB NO?',
            this.executeDeleteHeader
        );
    }

    executeDeleteHeader = () => {

        // if (!window.confirm('Do you want to delete the selected JIB NO?')) {
        //     return;

        // }
        const hdrForDeleteDTO = this.createDeleteHdrDTO()

        let myURI = apiURL + 'AR/JIBHeaderDel'

        fetch(myURI, apiDELETEHeader(hdrForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = await response.text();

                    const error = rspData || response.statusText;

                    this.setState({
                        apiError: error,
                        showBadAlert: true
                    })

                    return Promise.reject(new Error(error));
                } else {
                    this.closeForm()
                }


            })
            .catch(error => {

            })

    }

    closeForm = () => {
        this.props.toggle()

    }

    refresh = () => {
        this.getJibHeader();
        this.getDetails();
    }

    // saveClose = () => {
    //     this.saveData()
    //     // this.closeForm()
    // }

    // submitMM = (e) => {
    //     this.saveData(2)
    // }
    // approveMM = (comment) => {
    //     this.saveData(6)
    // }

    // rejectMM = (comment) => {
    //     this.saveData(1)
    // }

    // saveData = (statusKey = -1) => {
    //     const mmHdrUpdateDTO = this.mmHeaderUpdateDTO(statusKey)
    //     console.log("mmHdrUpdateDTO")
    //     console.log(mmHdrUpdateDTO)
    //     let myURI = apiURL + 'ItemMaster/MTLMovement'

    //     fetch(myURI, apiPUTHeader(mmHdrUpdateDTO))
    //         .then(async response => {
    //             if (!response.ok) {
    //                 const rspData = response.text();
    //                 var error = (rspData && rspData.message) || response.status;

    //                 rspData.then(t => {
    //                     this.setState({
    //                         msgBody: t,
    //                         msgHeader: "Error",
    //                         modalMsg: !this.state.modalMsg
    //                     })
    //                 })

    //                 return Promise.reject(error)
    //             } else
    //                 return response.json()
    //         }).then(json => {
    //             this.closeForm()
    //         }).catch(error => {
    //             console.log(error)
    //         })
    // }

    // mmHeaderUpdateDTO = (statusKey) => {
    //     const { user } = this.props
    //     const { mtlMoveHdr } = this.state
    //     const mmHdrUpdateDTO = {
    //         "jibHeaderKey": mtlMoveHdr.jibHeaderKey,
    //         "StatusKey": (statusKey < 0 ? mtlMoveHdr.statusKey : statusKey),
    //         "ModifiedBy": getUserADAccount(user.account.userName),
    //         "ExportedToWDDatetime": mtlMoveHdr.exportedToWDDatetime,
    //         "Comments": mtlMoveHdr.comments,
    //         "FiscalYrMonth": mtlMoveHdr.fiscalYrMonth
    //     }

    //     return mmHdrUpdateDTO
    // }

    getJibHeader = () => {
        const { jibHeaderKey } = this.state

        let myURI = apiURL + 'AR/GetJIBHeaderbyKey/' + jibHeaderKey



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                this.setState({
                    isHeaderLoaded: true,
                    jibHeaderdata: json,
                }
                )


            });
        // if (this.searchInput.current) {
        //     this.searchInput.current.value = '';
        // } else {
        // }

    }



    onCellDataBound(s, e) {
        // console.log("onCellDataBound")
        // console.log(e)
        // console.log(s)
        if (e.cellInfo !== undefined &&
            e.cellInfo.rowItem !== undefined && e.cellInfo.rowItem !== null) {

            var stat = e.cellInfo.rowItem.status


            if ((stat !== undefined || stat !== null)) {
                e.cellInfo.background = "transparent"

                if (["status", "comments", "result"].includes(s.field)) {
                    console.log('stat:', stat)
                    if (stat === "Failed") {
                        e.cellInfo.background = "#fb9898";
                    }

                    if (stat === "Revised") {
                        e.cellInfo.background = "#ccffcc";

                    }
                    if (stat === "Success(warning)") {
                       e.cellInfo.background = "#ffffcc";
                    }

                }


            }


        }
    }

    onCellClicked(s, e) {
        const { selEibLine, eibSubmitSupplierInvKey } = this.state

        if (e.isDoubleClick) {
            let column_id = s.activeCell.columnUniqueKey


            if (column_id === 'comments' || column_id === 'result') {
                let commentContent = e.cellInfo.textValue;

                if (commentContent) {
                    alert(commentContent);
                } else {
                    alert("Nothing to show");
                }

                // if (!window.confirm(`Are you sure about ${e.cellInfo.textValue}?`)) {
                //     return;
                // }

            } else {
                //const isLineFailed = selEibLine && (selEibLine.status === 'Failed');
                //const isLineEditable = selEibLine && (selEibLine.status === 'Failed' || selEibLine.status === 'Revised');


                if (eibSubmitSupplierInvKey > 0) {
                    this.toggleDetails();
                }
            }
            // this.toggleEdit()
            // console.log("in OnCellClick")
            // console.log("s", s)
            // console.log("e", e)

            //let config_meta = this.getAssetConfigMeta(column_id)
            var bPopUp = true

            // if (config_meta !== undefined && config_meta !== null) {
            //     if (config_meta.meta_options !== undefined  && config_meta.meta_options !== null) {
            //         console.log("config_meta.meta_options & 32")
            //         console.log(config_meta.meta_options & 32)
            //         console.log(config_meta)
            //         if (column_id === 'wtc_projectname' || (Number(config_meta.meta_options) & 32) !== 32) {
            //             bPopUp = false
            //             this.toggleEdit()
            //         } else if (config_meta.data_type === "daterange") {
            //             //this.toggleModalDateRange()
            //             this.setState({
            //                 selectedDateRange: e.cellInfo.textValue,
            //                 modalDateRange: !this.state.modalDateRange
            //             })
            //         }
            //     }

            // }

            // if  (bPopUp)
            // {
            //     this.toggleEdit()
            // }
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getJibHeader()
        this.getDetails()
    }
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    isFormReadOnly = () => {
        return false;
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)
            // if (!window.confirm(dataItem.eibSubmitSupplierInvKey)) {
            //     return;
            // }
            this.setState({ eibSubmitSupplierInvKey: dataItem.eibSubmitSupplierInvKey })
            // this.setState({ jibno: dataItem.jibno })

            this.setState({ selEibLine: dataItem })
            //this.toggle()
            //this.onVolRefresh()    
        }
        else {
            this.setState({ eibSubmitSupplierInvKey: -1 })
            this.setState({ selEibLine: null })
            // this.setState({ jibNo: '' })

        }
    }



    formatAnyDate = (date) => {
        if (!date || new Date(date).getTime() <= 0) {
            return ''; // Return an empty string if date is null or invalid
        }
        return new Date(date).toLocaleString(); // Format the date as a local string
    };

    render() {
        const { jibDetaildata, isLoaded, isHeaderLoaded, windowHeight, msgHeader, msgBody, status,
            jibHeaderKey, jibHeaderdata, apiError, showBadAlert, showGoodAlert, isLoading, selEibLine, modalDetails, eibSubmitSupplierInvKey } = this.state

        const { isJIBUser, isJIBEdit, isJIBWorkday } = this.state;
        // const gridHeight = (windowHeight * 0.70) + "px"
        const gridHeight = "65vh"
        // const createdDateTime = new Date(selJibNo.createdDateTime);
        // const formattedDate = createdDateTime.toISOString().split('T')[0];
        //const status = selJibNo ? selJibNo.status : 'Unknown Status';



        if (!isLoaded || !isHeaderLoaded) {
            return (<div>Loading details.....</div>)
        }

        const createdDateTime = new Date(jibHeaderdata[0].createdDateTime);
        const formattedDate = moment(createdDateTime).format('MM/DD/YYYY h:mm A');

        const finishedDateTime = new Date(jibHeaderdata[0].finishedDateTime);
        const finishedDate = moment(finishedDateTime).format('MM/DD/YYYY h:mm A');

        const sentDateTime = new Date(jibHeaderdata[0].sendDateTime);
        const sentDate = moment(sentDateTime).format('MM/DD/YYYY h:mm A');

        const printDateTime = new Date(jibHeaderdata[0].printDateTime);
        const printDate = moment(printDateTime).format('MM/DD/YYYY h:mm A');

        const isDeletable = jibHeaderdata[0].status && (jibHeaderdata[0].status === 'Failed' || jibHeaderdata[0].status.toLowerCase().startsWith('eib ready'));
        // const isSandboxpushable = jibHeaderdata[0].status && (jibHeaderdata[0].status === 'Revised' || jibHeaderdata[0].status.toLowerCase().startsWith('eib ready'));


        const isSandboxpushable =
            jibHeaderdata[0].status &&
            (jibHeaderdata[0].status === 'Revised' || jibHeaderdata[0].status.toLowerCase().startsWith('eib ready')) &&
            (!jibHeaderdata[0].workdayAction || !jibHeaderdata[0].workdayAction.toLowerCase().includes('pushedtoworkday'));


        const isWDpushable =
            (
                jibHeaderdata[0].status &&
                (jibHeaderdata[0].status === 'Revised' || jibHeaderdata[0].status.toLowerCase().startsWith('eib ready'))
            )
            ||
            (!jibHeaderdata[0].workdayAction || !jibHeaderdata[0].workdayAction.toLowerCase().includes('pushedtoworkday'));

        const isPrintable =
            (jibHeaderdata[0].workdayAction && jibHeaderdata[0].workdayAction.toLowerCase().includes('pushedtoworkday'));
        //const isLineEditable = selEibLine && (selEibLine.status === 'Failed' || selEibLine.status === 'Revised' || selEibLine.status.length===1);
        const isLineEditable = true

        return (<div>

            <Form onSubmit={e => e.preventDefault()}>


                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                    <Col md={7}>
                        {/* <Label>{selEibLine ? selEibLine.status.length:"-"}</Label> */}
                        {/* <Button outline
                            color="secondary"
                            className="btn-no-border"
                            style={{ position: 'relative', paddingRight: '35px' }}
                            onClick={() => this.closeForm()}>
                                <img
                                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                                alt="Close"
                                style={{ width: "30px", margin: "0 5px 0 0" }}

                            />
                                Close
                            </Button> */}


                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={(e) => this.refresh()}
                        >
                            <img
                                src={Refresh}
                                alt="refresh"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>{' '}





                        {/* <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.refresh}
                            disabled={isLoading}
                        >
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }}

                            />
                            Refresh
                        </Button> */}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.toggleDetails}
                            disabled={!isLineEditable}
                        >
                            <img
                                src={Revise}
                                alt="details"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Line Details

                        </Button>{' '}

                        <Button outline color="danger"
                            className="btn-no-border"
                            onClick={this.deleteHeader}
                            disabled={jibHeaderKey < 0 || isLoading || !isDeletable || (!isJIBEdit && !isJIBWorkday)}
                        >
                            <img
                                src={TrashBin}
                                alt="delete"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Delete
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "44px", height: "22px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>

                        <Button
                            outline color="secondary"
                            className="btn-no-border"
                            disabled={!isPrintable}
                            onClick={this.printAll}>
                            <img
                                src={Report}
                                alt="print"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Print All Invoices
                        </Button>



                        {/* <ConfirmModal
                            color="secondary"
                            buttonLabel={"Close"}
                            className="float-left"
                            
                            formName="Confirm"
                            id={jibHeaderKey}
                            message={"Do you want to save your changes ?"}
                            onClickYes={(e) => this.saveData(true)}
                            onClickNo={this.closeForm}
                        >
                        </ConfirmModal> */}


                        {/* <Button color="primary" className="float-left"
                            style={{ marginLeft: "5px" }}
                            //ref={ref}
                            hidden={this.isFormReadOnly() }
                            onClick={() => this.saveData()}>Edit</Button> */}

                        {/* <ConfirmModal
                            color="success"
                            buttonLabel={"Submit"}
                            className="float-left"
                            style={{ marginLeft: "5px" }}
                            hidden={(statusKey !== 0 && statusKey !== 1)} 
                            formName="Confirm"
                            id={jibHeaderKey}
                            message={"Are you sure you want to submit this material movement ?"}
                            onClickYes={(e) => this.submitMM()}
                        
                        >
                        </ConfirmModal> */}
                        {/* 
                        <ConfirmModal
                            color="success"
                            buttonLabel="Approve"
                            style={{ marginLeft: "5px" }}
                            hidden={statusKey !== 2}
                            formName="Confirm"
                            id={jibHeaderKey}
                            message={"Are you sure you want to approve this material movement ?"}
                             onClickYes={(e) => this.approveMM()}>
                        </ConfirmModal> */}

                        {/* <ConfirmWithMsgModal
                            color="success"
                            buttonLabel="Approve"
                            style={{ marginLeft: "5px" }}
                            hidden={true}
                            formName="Confirm"
                            id={jibHeaderKey}
                            message=""
                            commentPrompMsg="Please Enter Approval Comment:"
                            onClickYes={(e) => this.approveMM(e)}>
                        </ConfirmWithMsgModal> */}


                    </Col>
                    <Col>
                        <FormGroup style={{ marginTop: "-2px", marginBottom: "-15px", padding: "0px" }}>
                            <row form>
                                <div>
                                    {isLoading &&
                                        <Alert color="info" isOpen={isLoading}>
                                            The services are running...Please wait...
                                        </Alert>
                                    }
                                    {showBadAlert &&
                                        <Alert color="danger" isOpen={showBadAlert} toggle={this.onDismissAlert}>
                                            {apiError}
                                        </Alert>
                                    }
                                    {showGoodAlert &&
                                        <Alert color="success" isOpen={showGoodAlert} toggle={this.onDismissAlert}>
                                            Services finished running
                                            {/* : {JSON.stringify(apiResponse)} */}
                                        </Alert>
                                    }


                                </div>

                            </row>

                        </FormGroup>
                    </Col>
                    <Col sm={1} className="text-right">
                        <Dropdown isOpen={this.state.dropdownActionOpen} nav={false}
                            direction="left"
                            className="no-border float-right"
                            color='primary'
                            outline={true}
                            toggle={this.toggleDropdownAction}>
                            <DropdownToggle caret>
                                Action
                            </DropdownToggle>
                            <DropdownMenu end>

                                <DropdownItem
                                    onClick={() => this.callSandbox()}
                                    disabled={jibHeaderKey < 0 || !isSandboxpushable || !isJIBWorkday}
                                >
                                    WorkDay Sandbox
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => this.callProduction()}
                                    disabled={jibHeaderKey < 0 || !isWDpushable || !isJIBWorkday}
                                >
                                    Workday Production
                                </DropdownItem>

                            </DropdownMenu>
                        </Dropdown>


                    </Col>
                    {/* <Col sm={5}>


                    </Col> */}
                </Row>

                {/* <row form>
                    <div>
                        {isLoading &&
                            <Alert color="info" isOpen={isLoading}>
                                The services are running...Please wait...
                            </Alert>
                        }
                        {showBadAlert &&
                            <Alert color="danger" isOpen={showBadAlert} toggle={this.onDismissAlert}>
                                {apiError}
                            </Alert>
                        }
                        {showGoodAlert &&
                            <Alert color="success" isOpen={showGoodAlert} toggle={this.onDismissAlert}>
                                Services finished running
                               
                            </Alert>
                        }


                    </div>

                </row> */}


                <ColoredLine color="grey" />



                <FormGroup className="mt-4" >
                    <Row>
                        <Col md={6}>
                            <Row >

                                <Col md={3}>
                                    <Label for="jibno">JIB NO</Label>
                                    <Input type="text" name="jibno"
                                        id="jibno"
                                        disabled={true}
                                        value={jibHeaderdata[0].jibno}
                                    // onChange={(e) => this.onTextChange("emergencyone", e.target.value, e)}

                                    />

                                </Col>




                                <Col md={3}>
                                    <Label for="formattedDate">Started</Label>
                                    <Input type="text" name="formattedDate"
                                        id="formattedDate"
                                        disabled={true}
                                        value={formattedDate}
                                    // onChange={(e) => this.onTextChange("emergencytwo", e.target.value, e)}

                                    />


                                </Col>

                                <Col md={3}>
                                    <Label for="finishedDate">Finished</Label>
                                    <Input type="text" name="finishedDate"
                                        id="finishedDate"
                                        disabled={true}
                                        value={finishedDate}
                                    // onChange={(e) => this.onTextChange("emergencytwo", e.target.value, e)}

                                    />


                                </Col>



                                <Col md={3}>
                                    <Label for="status">Status</Label>
                                    <Input type="text" name="status"
                                        id="status"
                                        disabled={true}
                                        value={jibHeaderdata[0].status}
                                    // onChange={(e) => this.onTextChange("emergencythree", e.target.value, e)}

                                    />

                                </Col>

                            </Row>
                        </Col>

                        <Col md={6}>
                            <Row >

                                <Col md={3}>
                                    <Label for="sendDateTime">Sent Date</Label>
                                    <Input type="text" name="sendDateTime"
                                        id="sendDateTime"
                                        disabled={true}
                                        value={this.formatAnyDate(sentDate)}
                                    // onChange={(e) => this.onTextChange("emergencytwo", e.target.value, e)}

                                    />


                                </Col>

                                <Col md={3}>
                                    <Label for="sentby">Sent By</Label>
                                    <Input type="text" name="sentby"
                                        id="sentby"
                                        disabled={true}
                                        value={jibHeaderdata[0].sendBy}

                                    />

                                </Col>

                                <Col md={3}>
                                    <Label for="printDateTime">Print Date</Label>
                                    <Input type="text" name="printDateTime"
                                        id="printDateTime"
                                        disabled={true}
                                        value={this.formatAnyDate(printDate)}


                                    />


                                </Col>


                                <Col md={3}>
                                    <Label for="printby">Printed By</Label>
                                    <Input type="text" name="printby"
                                        id="printby"
                                        disabled={true}
                                        value={jibHeaderdata[0].printBy}

                                    />

                                </Col>

                            </Row>
                        </Col>




                    </Row>


                    <Row >


                        <Col md={3}>
                            <Label for="sharepointLoc">Sharepoint Location</Label>

                            <Input
                                type="text"
                                name="sharepointLoc"
                                id="sharepointLoc"
                                readOnly={true}
                                value={jibHeaderdata[0].sharepointLoc}
                                onClick={() => jibHeaderdata[0].sharepointLoc && window.open(jibHeaderdata[0].sharepointLoc)}
                                style={{
                                    cursor: 'pointer',
                                    color: 'blue',      // Sets the text color to blue
                                    textDecoration: 'underline'  // Underlines the text
                                }}
                            />


                        </Col>

                        <Col md={3}>
                            <Label for="filename">Filename</Label>
                            <Input type="text" name="filename"
                                id="filename"
                                disabled={true}
                                value={jibHeaderdata[0].filename}
                            // onChange={(e) => this.onTextChange("emergencyone", e.target.value, e)}

                            />

                        </Col>

                        <Col md={6}>
                            <Label for="comments">Comments</Label>
                            <Input type="textarea"
                                rows={1}
                                name="comments"
                                id="comments"
                                disabled={true}
                                value={jibHeaderdata[0].comments}
                            // onChange={(e) => this.onTextChange("emergencyone", e.target.value, e)}

                            />

                        </Col>




                    </Row>



                </FormGroup>

                <IgrDataGrid
                    ref={this.onGridRef}
                    cellTextStyle="14px Calibri"
                    headerTextStyle="14px Calibri"
                    rowHeight={"25"}
                    height={gridHeight}
                    dataSource={jibDetaildata}

                    cellClicked={this.onCellClicked}

                    autoGenerateColumns="false"
                    selectionMode="SingleRow"

                    selectedKeysChanged={this.onGridRowSelected}
                    //width={"100%"}
                    // isColumnOptionsEnabled="true"
                    onCellDataBound={this.onCellDataBound}

                //useAccessibility="true"

                // isHeaderSeparatorVisible="true"
                // editMode={0}


                >

                    <IgrNumericColumn field="eibSubmitSupplierInvKey" />

                    <IgrTextColumn field="status"
                        headerText="Status" width="160" />
                    <IgrTextColumn field="comments"
                        headerText="Comments" width="160" />

                    <IgrTextColumn field="sheetName"
                        headerText="Excel Sheet" width="170" />

                    <IgrTextColumn field="originalOrder"
                        headerText="Excel Row No" width="160" />

                    <IgrTextColumn field="sheetToPdf"
                        headerText="Excel Sheet To Pdf" width="210" />




                    <IgrTextColumn field="spreadsheetKey"
                        headerText="SpreadsheetKey" width="180" />

                    <IgrTextColumn field="submit"
                        headerText="Submit" width="169" />

                    <IgrTextColumn field="lockedInWorkday"
                        headerText="LockedInWorkday" width="200" />

                    <IgrTextColumn field="invoiceAccountingDate"
                        headerText="Invoice AccountingDate" width="240" />

                    <IgrTextColumn field="company"
                        headerText="Company" width="140" />

                    <IgrTextColumn field="currency"
                        headerText="Currency" width="140" />

                    <IgrTextColumn field="supplier"
                        headerText="Supplier" width="140" />

                    <IgrTextColumn field="invoiceDate"
                        headerText="Invoice Date" width="160" />

                    <IgrTextColumn field="invoiceReceivedDate"
                        headerText="Invoice ReceivedDate" width="220" />

                    <IgrTextColumn field="controlAmount"
                        headerText="Control Amount" width="200" />

                    <IgrTextColumn field="suppliersInvoiceNumber"
                        headerText="Suppliers InvoiceNumber" width="240" />

                    <IgrTextColumn field="memo"
                        headerText="Memo" width="180" />

                    <IgrTextColumn field="rowID"
                        headerText="RowID" width="110" />

                    <IgrTextColumn field="intercompanyAffiliate"
                        headerText="Intercompany Affiliate" width="220" />


                    <IgrTextColumn field="spendCategory"
                        headerText="SpendCategory" width="180" />


                    <IgrTextColumn field="quantity"
                        headerText="Quantity" width="160" />


                    <IgrTextColumn field="unitCost"
                        headerText="Unit Cost" width="160" />


                    <IgrTextColumn field="extendedAmount"
                        headerText="Extended Amount" width="200" />


                    <IgrTextColumn field="memo2"
                        headerText="Comment" width="180" />

                    <IgrTextColumn field="project"
                        headerText="Project" width="160" />

                    <IgrTextColumn field="costCenter"
                        headerText="Cost Center" width="160" />

                    <IgrTextColumn field="site"
                        headerText="Site" width="150" />

                    <IgrTextColumn field="serviceMonth"
                        headerText="Service Month" width="170" />

                    <IgrTextColumn field="serviceYear"
                        headerText="Service Year" width="160" />

                    <IgrTextColumn field="intercompanyAffiliate2"
                        headerText="Intercompany Affiliate2" width="230" />

                    <IgrTextColumn field="rowID2"
                        headerText="RowID2" width="130" />

                    <IgrTextColumn field="result"
                        headerText="Result" width="210" />

                    <IgrTextColumn field="supplier_Invoice_Reference_ID"
                        headerText="Supplier_Invoice_Reference_ID" width="290" />



                </IgrDataGrid>

                <CustomConfirmModal
                    isOpen={this.state.isCustomConfirmModalOpen}
                    toggle={this.toggleCustomConfirmModal}
                    formName="Confirm"
                    message={this.state.confirmModalMessage}
                    onClickYes={this.handleCustomConfirmYes}
                />


                <div>
                    <Modal isOpen={modalDetails}
                        contentClassName="full-screen-modal2"
                        toggle={() => this.toggleDetails()}
                        style={{ margin: 0, flex: 1 }}
                        backdrop={"static"}
                    //onClosed={() => this.getMtlMovement()}
                    >
                        <ModalHeader toggle={() => this.closeDetailsForm()}>
                            Line Details - {selEibLine ? selEibLine.status : ""}


                        </ModalHeader>
                        <ModalBody>

                            <JibLineDet toggle={this.closeDetailsForm}
                                user={this.props.user}
                                isJIBUser={isJIBUser}
                                isJIBEdit={isJIBEdit}
                                isJIBWorkday={isJIBWorkday}
                                isLineEditable={isLineEditable}
                                //selJibNo={selJibNo}
                                //statusKey={statusKey}
                                eibSubmitSupplierInvKey={eibSubmitSupplierInvKey}
                            >
                            </JibLineDet>
                        </ModalBody>
                    </Modal>
                </div>
            </Form>

            <div>
                <Modal isOpen={this.state.modalMsg}
                    toggle={() => this.toggleMsg()}
                    size="lg">
                    <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                    </ModalHeader>
                    <ModalBody>
                        {msgBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.toggleMsg()}
                        >
                            OK
                        </Button>
                        {' '}

                    </ModalFooter>
                </Modal>
            </div>

        </div>)
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "JIBdetails");
    }
}