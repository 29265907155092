import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, FormGroup, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { Form, Col, Row } from 'reactstrap';
import Select from 'react-select';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { ConsolidatedItemsPosition_$type } from 'igniteui-react-charts';

const AFERejectModal = (props) => {

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        buttonLabel,
        id,
        style,
        disabled,
        commentPrompMsg,
        afeHeaderKey
    } = props

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [inputComment, setComment] = useState(props.inputComment)
    const [isValid, setIsValid] = useState(true)
    const [actionLineKey, setActionLineKey] = useState(0)
    const [approvedList, setApprovedList] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)


    const getAFEApproval = () => {

        let myURI = apiURL + 'AFE/Approval/' + afeHeaderKey
        console.log("myURI")
        console.log(myURI)
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                setIsLoaded(true)
                setApprovedList(getApprovalList(json))
            });
    }

    const getApprovalList = (value) => {
        var tempList = []
        value.map(e => {
            if (e.lineStatusKey === 6 && e.actionID > 1) {
                e.value = e.actionLineKey
                e.label = e.actionDesc
                tempList.push(e)
            }

        })

        // console.log("tempList")
        // console.log(tempList)
        return tempList
    }


    useEffect(() => {
        console.log("Here")
        getAFEApproval()
    }, [afeHeaderKey])



    const toggleYes = () => {
        console.log("actionLineKey")
        console.log(actionLineKey)
        console.log(actionLineKey)
        if (inputComment === "" || inputComment === undefined) {
            setIsValid(false)
        } else {
            setModal(!modal)
            props.onClickYes(inputComment, actionLineKey);
            setComment("")
        }

    }

    const onClosed = () => {

        if (props.onClosed !== undefined) {
            props.onClosed();
        }

        setIsValid(true)
        setComment("")
    }

    const onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)

        switch (fieldName) {
            case "comment":
                setComment(fieldValue)
                setIsValid(fieldValue !== "")
                break;
            case "actionLineKey":
                setActionLineKey(0)
                if (e !== null) {
                    setActionLineKey(e.value)
                }
                break;
            default:
                break;
        }
    }

    const getSelApproved = (v) => {

        if (approvedList !== undefined && v !== null && v !== "" && !isNaN(v)) {
            if (v === 0) {
                return approvedList.filter(e => e.actionID === 2);
            } else {
                return approvedList.filter(e => e.actionLineKey === Number(v));
            }
        }
        return ""
    }

    // if (!isLoaded) {
    //     return (<div>loading..</div>)
    // } else
        return (
            <div>
                <Button outline={outline}
                    style={style}
                    color={color}
                    className={className}
                    hidden={hidden}
                    disabled={disabled}
                    onClick={toggle} style={{ margin: "0 5px 0 5px" }} >
                    {image}
                    {buttonLabel}

                </Button>

                <Modal isOpen={modal}
                    toggle={toggle}
                    onClosed={onClosed} >
                    <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                    <ModalBody>
                        <Row form style={{ marginBottom: "5px" }}>

                            <Col md={12}>

                                <Row form>
                                    <Col md={4}>
                                        <Label invalid for="approvalList">Reject to</Label>

                                    </Col>
                                    <Col md={8}>

                                        <Select options={approvedList}
                                            //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                            // isDisabled={this.isFormReadOnly() || statusKey >= 4}
                                            // className={(!validateDataItem.afeManagerKey ? "form-error" : "")}
                                            value={getSelApproved(actionLineKey)}
                                            defaultValue={getSelApproved(actionLineKey)}
                                            onChange={(e) => onTextChange("actionLineKey", e, e)}
                                            isClearable={true}

                                        >
                                        </Select>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        {message}
                        <FormGroup style={{ marginBottom: "-10px" }}>
                            <Label for="inputComment">
                                {commentPrompMsg}
                            </Label>
                            <Input invalid={!isValid}
                                id="inputComment"
                                name="text"
                                type="textarea"
                                value={inputComment}
                                defaultValue={inputComment}
                                rows={3}
                                onChange={(e) => onTextChange("comment", e.target.value, e)}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleYes}>OK</Button>
                        <Button color="primary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )

}

export default AFERejectModal;