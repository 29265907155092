
import React,{Component, useState} from 'react';
import { Col, Row, Button, FormGroup, Label,TabContent, TabPane,Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import {apiURL} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { Tab } from 'bootstrap';
// import TankList from './TankList';
// import TankVolume from './TankVolume';
import SecurityCheckpointAdmin from './SecurityCheckpointAdmin'
import SecurityCheckpointListView from './SecurityCheckpointListView'

export default class FacilityTank extends Component {

    constructor(props){
        super(props);

        this.state ={
            activeTab:'1',
        }

        
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState(() => ({
                activeTab: tab
            }))
        }
    }

    getSelectedTabData(){
        console.log("Get selected tab data click")
    }

    render(){
        const {activeTab} = this.state;

        return (
            <div>

                <FormGroup>

                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '1'})}
                                      onClick={() => {this.toggle('1')}}>
                                <h5>Security Checkpoint Admin</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '2'})}
                                      onClick={() => {this.toggle('2')}}>
                                <h5>List View</h5>
                            </NavLink>
                        </NavItem>                        
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            
                                <SecurityCheckpointAdmin user={this.props.user}/>                                
                                                        
                        </TabPane>

                        <TabPane tabId="2">
                             <SecurityCheckpointListView 
                            //  VoldHeaderLabel="" 
                             user={this.props.user}
                                                            // hideAllFacilitySwitch={true}
                                                            /> 
                                                            
                        </TabPane>
                    </TabContent>
                </FormGroup>
                
            </div>
        );
    }
}
