//Created By BN 11/22/21


import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, CustomInput} from 'reactstrap';


const ConfirmMeterValModal = (props) => {


 

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        disabled,
        buttonLabel,
        id,
        style,
        invalid,
        defaultValue,
        value,
        onClickNo
    } = props

    const [modal, setModal] = useState(false);
    
    const toggle = () => {
        setModal(!modal);
        // props.onClickNo()
        
    }

    // const toggleNo = () => {
    //     // setModal(! modal);
    //     props.onClickNo()
    // }

    const toggleYes = () => {
        // setModal(!modal)
        props.onClickYes();
    }

 

    const onClosed = () => {

        if (props.onClosed!==undefined)
        {
            props.onClosed();
        }        
    }

  

    const onChange = (e) =>{
        props.onChangeTest(e);
    }

    return(
        <div>
            <Button outline = {outline} 
                    style={style}
                    color={color}
                    className={className}
                    hidden={hidden}
                    disabled={disabled}
                    onClick={toggle}  >
                {image}
                {buttonLabel}
                
            </Button>

            <Modal isOpen={modal} 
                   toggle={toggle}
                   onClosed={onClosed} >
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>
                <FormGroup>
                {message}
                
                <Label style={{margin:"10px 0 0 0"}}>Please enter comment: </Label>
                <Input type="textarea" name="commentReview" id="commentReview"                            
                            defaultValue={defaultValue} 
                            value={value}
                            // defaultValue={meterValDetails.reviewComment}
                            onChange={onChange}
                            invalid={invalid}
                           >
                            
                </Input>
                </FormGroup>
                  
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleYes}>Yes</Button>
                    <Button color="secondary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default ConfirmMeterValModal;