//Created by BN - 1/18/22


import React, { Component, useEffect, useState } from 'react';

import { Form, Col, Row, Button, TabContent, TabPane, NavItem, Nav, NavLink, FormGroup, Label, Input, CustomInput, ListGroupItem, ListInlineItem, InputGroup, Card, CardHeader, CardBody,  Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiGETHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import TrashBin from '../resources/trash.svg'
import PlusIcon from '../resources/plus.svg'
import RefreshIcon from '../resources/refresh.svg'
import { USDCurrencyFormat, NumberFormat } from '../resources/utility'
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from './ConfirmModal'
import ConfirmMeterValModal from './ConfirmMeterValModal'
import MeterValidationItem from './MeterValidationItem';
import DocumentBrowser from '../resources/DocumentBrowser';
import classnames from 'classnames';


import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import MeterValidationAction from './MeterValidationAction'
import MeterValidationActionPerformed from './MeterValidationActionPerformed'
import ReviewComment from './ReviewComment'
import { ISpreadsheetDialogFontsService_$type } from 'igniteui-react-spreadsheet';
import InventoryItem from "./InventoryItem"
import ItemTypeDetails from "./ItemTypeDetails"
import ItemTypeHeader from "./ItemTypeHeader"

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


const ItemType = (props) =>{
    const [isLoaded,setIsLoaded] = useState(false)
    const [itemType, setItemType] = useState([])
    const [itemTypeLinkKey, setItemTypeLinkKey] = useState(-1)
    const [modalItemType, setModalItemType] = useState(false)
    const [itemTypeDetails, setItemTypeDetails] = useState(null)
    const [isRequired, setIsRequired] = useState(false)
    const [isHasValueList, setIsHasValueList] = useState(false)
    const [user, setUser]= useState(props.user)
  

    function onGridRowSelected(s, e) {

        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 

            
            setItemTypeLinkKey(dataItem.itemPropertyMapKey)
            setItemTypeDetails(dataItem)
      
                setIsRequired(dataItem.isRequired === 0 ? false : true)
                setIsHasValueList(dataItem.hasValueList === 0 ? false : true)

        } 
    }

    

   function createItemTypeProps(){
        const itemTypePropsCreate ={
            "itemPropertyMapKey": -1,
            "itemTypeKey": props.itemTypeKey,
            "itemField": "",
            "dataType": "",
            "defaultValue": "",
            "isRequired": 0,
            "hasValueList": 0,
            "valueList": "",
            "itemFieldDesc": "",
            "createdBy": getUserADAccount(user.account.userName)
        }
      
        return itemTypePropsCreate
    }

        function addItemTypePropsModal(){
          setIsRequired(false)
          setIsHasValueList(false)
          setItemTypeLinkKey(-1)
         let newItemTypeProps = createItemTypeProps()
         setItemTypeDetails(newItemTypeProps)
         toggleModalItemType()
         
     }

     function deleteItemTypeProps(){
         if(itemTypeLinkKey < 0){
         }
         else{
            const itemTypePropsDeleteDTO = createitemTypePropsDeleteDTO()

           
            let myURI = apiURL + 'ItemMaster/ItemPropertyMap'
    
            fetch(myURI , apiDELETEHeader(itemTypePropsDeleteDTO) )
              .then(async response => {
                const rspData = response.text();
                if (!response.ok){

                  const error = (rspData && rspData.message) || response.status;
                  return Promise.reject(error);
                  
                }
                refreshData(true)

              })
              .catch(error =>{

              })
            
            // this.closeForm(true)

         }
        
    }

    function createitemTypePropsDeleteDTO(){
        // const {grpDetails} = this.state 
        // const {user} = this.props
        const itemTypePropsDeleteDTO= {
            "itemPropertyMapKey" : itemTypeLinkKey,
            // "deletedBy": getUserADAccount(user.account.userName)
            "deletedBy": getUserADAccount(user.account.userName)

        }

        return  itemTypePropsDeleteDTO;
    }

 
    
    function toggleModalItemType(){
      
            setModalItemType(!modalItemType)
        
        
        
        
    }

    function refreshData(bFreshData){
        if(bFreshData == true){
            getItemTypePropertyMap()

        }
        
    }

    function getItemTypePropertyMap(){
        let myURI = apiURL + 'ItemMaster/ItemPropertyMap/' + props.itemTypeKey

        fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                
              setIsLoaded(true);
              setItemType(json)


           
            });
           
        }

        


    useEffect(()=>{
   
        getItemTypePropertyMap()
           

    },[props.itemTypeKey])

    if(!isLoaded){
        return(<div>
           <h4>Loading Item Type Property ...</h4> 
        </div>)
    }
    else{
        return(<div>

            <FormGroup>
                            <Row>
                             <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={getItemTypePropertyMap}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>

                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={addItemTypePropsModal}
                                hidden={props.itemTypeKey < 0}
                                >
                                    <img
                                    src={PlusIcon}
                                    alt="Add"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Add Props
                                </Button>

                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={toggleModalItemType}
                                hidden={itemTypeLinkKey < 0}
                                >
                                    <img
                                    src={MenuIcon}
                                    alt="Link"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Edit
                                </Button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={deleteItemTypeProps}
                                >
                                    <img
                                    src={TrashBin}
                                    alt="Link"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Delete
                                </Button> */}
                
                        <ConfirmModal outline={true}
                                //   color="danger"
                                  className="btn-no-border"
                                  buttonLabel="Delete"
                                  hidden={itemTypeLinkKey < 0}
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected item property?"
                                  onClickYes={deleteItemTypeProps}
                                  image={<img
                                    src={TrashBin}
                                   // id={facilityOilTicketKey}
                                    
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}}/>}
                                    >
                        </ConfirmModal>    

                        </Row>
                <IgrDataGrid
                // height="600px"
                //width="Calc(100% - 5px)"
                width="100%"
                height="50vh"
                // width="115vh"
                dataSource={itemType}
                scrollable={true}
                editMode={0}                                       
                autoGenerateColumns="false"                                   
                selectionMode="SingleRow"
                isColumnOptionsEnabled="true"
                selectedKeysChanged={onGridRowSelected}
                >
<IgrTextColumn field="propField" headerText="Prop Field" width="*>140" isEditable = "false" />    
<IgrTextColumn field="itemField" headerText="Item Field" width="*>140" isEditable = "false" />    
<IgrTextColumn field="dataType" headerText="Data Type" width="*>140" isEditable = "false" />
<IgrTextColumn field="defaultValue" headerText="Default Value" width="*>150" isEditable = "false" />    
<IgrTextColumn field="isRequired" headerText="Is Required" width="*>140" isEditable = "false" />   
<IgrTextColumn field="hasValueList" headerText="Has Value List" width="*>150" isEditable = "false" />   
<IgrTextColumn field="valueList" headerText="Value List" width="*>140" isEditable = "false" />   
<IgrTextColumn field="itemFieldDesc" headerText="Item Field Description" width="*>200" isEditable = "false" />   

                </IgrDataGrid>
            </FormGroup>

            <Modal isOpen={modalItemType} toggle={() => toggleModalItemType() } size="lg" 
                    //    contentClassName="safetyObsDetail-modal-style"
                    //    onClosed={()=> props.selectedItemTypeKeyChanged(itemTypeLinkKey)}
                       >
                    <ModalHeader toggle={() => toggleModalItemType()}>{itemTypeLinkKey < 0 ? "Item Type Add"  : "Item Type Edit"}</ModalHeader>
                    <ModalBody>
                        <ItemTypeDetails  
                                        toggle={toggleModalItemType}
                                        // applicationSecurityDetails={applicationSecurityDetails}
                                        itemTypeLinkKey = {itemTypeLinkKey}
                                        // itemType = {itemType}
                                        itemTypeDetails = {itemTypeDetails}
                                        isRequired = {isRequired}
                                        isHasValueList = {isHasValueList}
                                        refreshData  ={refreshData}
                                        user={user}
                                        refreshData2 = {getItemTypePropertyMap}
                                        // toggleModalApplicationSecurity = {this.toggleModalApplicationSecurity}
                                        // userInput={this.props.user}
                                        >
                         
                        </ItemTypeDetails>
                       
                    </ModalBody>
                </Modal>

               
                
            
        </div>)
    }
}



export default class InventoryItemTypeCreator extends Component {


    static displayName = InventoryItemTypeCreator.name

    constructor(props){
        super(props)
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state={
          itemPropertyMapKey: -1,
          modalItemType: false,
          modalItemTypeHeader:false,
          values: [],
          itemType: [],
          isLoaded:false,
          activeTab:'1',
          itemTypeKey: -1,
          itemTypeData: null,
        }
    }
    

     createItemTypeProps(){
        const {user} = this.props
        const {itemTypeKey} = this.state 
        const itemTypePropsCreate ={
            "itemPropertyMapKey": -1,
            "itemTypeKey": itemTypeKey,
            "itemField": "",
            "dataType": "",
            "defaultValue": "",
            "isRequired": 0,
            "hasValueList": 0,
            "valueList": "",
            "itemFieldDesc": "",
            "createdBy": "Brandon" 
        }
      
        return itemTypePropsCreate
    }

    

     toggleModalItemType = () =>{
         const {itemTypeKey} = this.state
         
            this.setState({modalItemType: !this.state.modalItemType}) 
          
     }

     toggleModalItemTypeHeader=()=>{
         this.setState({modalItemTypeHeader: !this.state.modalItemTypeHeader})
     }

     editItemTypeHeader=()=>{
         const {itemTypeKey} = this.state
         if(itemTypeKey < 0){
         }
         else{
            this.toggleModalItemTypeHeader()
         }
        
     }
     closeForm=()=>{
         this.props.toggle()
     }
     
 

     getItemType=() =>{

        const {facilityKeys} = this.state

        let myURI = apiURL + 'ItemMaster/ItemType'
        

        fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                
                this.setState({
                    isLoaded: true,
                    itemType:json         
                })

            });

    }

    

    createItemTypeHeaderForm(){
        const {user} = this.props
        const {grpDetails} = this.state 
        const itemTypeHeaderCreate ={
            "itemTypeKey": -1,
            "itemTypeDesc": "",
          
        }
        return itemTypeHeaderCreate
    }


    addItemTypeHeader = () => {
        
        let newItemType = this.createItemTypeHeaderForm()
        this.setState({itemTypeKey: -1, itemTypeData:newItemType})
        this.toggleModalItemTypeHeader();
    }



    onGridRowSelected(s, e) {

        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 



            this.setState({itemTypeKey: dataItem.itemTypeKey,
                            itemTypeData: dataItem,
                            })       


       

            this.selectedItemTypeKeyChanged(dataItem.itemTypeKey)
           
        } 
    }

    selectedItemTypeKeyChanged = (itemTypeKey) => {
        this.setState({itemTypeKey:itemTypeKey})
    }




    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getItemType()

    }

    
    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.itemType;
        
      
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }
  

   
    render(){
        const {itemType, isLoaded, activeTab, itemTypeKey, itemTypeData} = this.state
        const searchText = window.$searchText.toLowerCase()
       
        if (!isLoaded){
            return (<div>
                <h3>Loading Item Type .....</h3>
            </div>)
        }else  {

       return(<div>



        

                    <Button outline color="secondary" 
                                className="btn-no-border" 
                                // onClick={this.addInventory}
                                onClick={this.getItemType}
                                >
                                    <img 
                                    src={RefreshIcon}
                                    alt="Add"
                                    style={{width:"25px", height:"25px", margin:"0 0 0 150"}}/>
                                    Refresh
                                </Button>{' '}
            
           <Button outline color="secondary" 
                                className="btn-no-border" 
                                // onClick={this.addInventory}
                                onClick={this.addItemTypeHeader}
                               
                                >
                                    <img 
                                    src={PlusIcon}
                                    alt="Add"
                                    style={{width:"25px", height:"25px", margin:"0 0 0 150"}}/>
                                    Create New Item Type
                                </Button>{' '}


                     <Button outline color="secondary" 
                                className="btn-no-border" 
                                // onClick={this.addInventory}
                                onClick={this.editItemTypeHeader}
                                hidden={itemTypeKey< 0}
                                >
                                    <img 
                                    src={MenuIcon}
                                    alt="Add"
                                    style={{width:"25px", height:"25px", margin:"0 0 0 150"}}/>
                                    Edit Item Type
                                </Button>{' '}

        
            
               
            <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        // height={gridHeight}
                        height="30vh"
                        //width="100%"
                        //  dataSource={itemType.filter(rec => ((rec.itemTypeDesc || '').toLowerCase().includes(searchText)))}
                        dataSource={itemType}
                         scrollable={true}
                         editMode={0}                                       
                         autoGenerateColumns="false"                                   
                         selectionMode="SingleRow"
                         isColumnOptionsEnabled="true"
                         selectedKeysChanged={this.onGridRowSelected}
                            >

                            <IgrTextColumn field="itemTypeDesc" headerText="Item Type" width="*>100" ></IgrTextColumn>

                                
                            </IgrDataGrid>
             </FormGroup>

             <ItemType user={this.props.user} itemTypeKey = {itemTypeKey} toggleModalItemType = {this.toggleModalItemType} editItemTypePropsModal = {this.editItemTypePropsModal}   addItemTypePropsModal = {this.addItemTypePropsModal}  

                           >

            </ItemType>
             <FormGroup>



<Modal isOpen={this.state.modalItemTypeHeader} toggle={() => this.toggleModalItemTypeHeader() } size="med" 
                    //    contentClassName="safetyObsDetail-modal-style"
                       onClosed={()=> this.selectedItemTypeKeyChanged(this.state.itemTypeKey)}>
                    <ModalHeader toggle={() => this.toggleModalItemTypeHeader()}> {itemTypeKey < 0 ? "Item Type Add" : "Item Type Edit"}</ModalHeader>
                    <ModalBody>
                        <ItemTypeHeader 
                                        toggle={this.toggleModalItemTypeHeader}
                                        // applicationSecurityDetails={applicationSecurityDetails}
                                        itemTypeKey = {itemTypeKey}
                                        refreshData3 = {this.getItemType}
                                        itemTypeData = {itemTypeData}
                                        user={this.props.user}
                                        // toggleModalApplicationSecurity = {this.toggleModalApplicationSecurity}
                                        // userInput={this.props.user}
                                        >
                         
                        </ItemTypeHeader>
                       
                    </ModalBody>
                </Modal>


                <FormGroup>
                <Row>
             <Col sm={12}>
                     <Button color="warning" className="float-right" 
                            onClick={() => this.closeForm(true)}>Close</Button>
                             

                </Col>
                </Row>
                </FormGroup>

             </FormGroup>


            
        </div>)
    }

}
}