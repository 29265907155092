

import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import AFECloseoutSC from './AFECloseoutSC'
import AFECloseoutDept from './AFECloseoutDept'
import AFECloseoutSummary from './AFECloseoutSummary'
import { ComponentRenderer } from 'igniteui-react-core';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
var DatePicker = require("reactstrap-date-picker");

export default class AFECostTracking extends Component {
    static displayName = AFECostTracking.name;

    constructor(props) {
        super(props)

        this.state = {
            afeHeaderKey: props.afeHeaderKey,
            user: props.user,
            afeData: props.afeData,
            selectedDept:''
        }

    }

    onTextChange = (fieldName, fieldValue, e) => {      
        this.props.onTextChange(fieldName, fieldValue, e)
    }

    selectDeptChange = (value) =>{
        console.log("Department change")
        console.log(value)
        this.setState({selectedDept:value})
    }

    render() {
        const { afeHeaderKey, user,afeData,selectedDept } = this.state

        console.log('selectedDept----')
        console.log(selectedDept)

        return (<div>
            <FormGroup>
            <ColoredLine color="grey" />
                <Form style={{marginBottom:"10px",marginTop: "15px"}}>
                    <AFECloseoutSummary
                        afeData={afeData}
                        user={user}
                        onTextChange={this.onTextChange}
                    >

                    </AFECloseoutSummary>
                    
                </Form>
                <ColoredLine color="grey" />
                <Form style={{marginBottom:"10px",marginTop: "15px"}}>
                    <AFECloseoutDept
                        afeHeaderKey={afeHeaderKey}
                        isFormReadOnly={false}
                        user={user}
                        selectDeptChange={this.selectDeptChange}
                    >

                    </AFECloseoutDept>
                </Form>
                <ColoredLine color="grey" />
                <Form style={{ marginTop: "15px", height:"350px" }}>
                    <AFECloseoutSC
                        afeHeaderKey={afeHeaderKey}
                        isFormReadOnly={false}
                        user={user}
                        key={selectedDept}
                        selectedDept={selectedDept}
                    >
                    </AFECloseoutSC>
                </Form>
            </FormGroup>
        </div>)
    }
}