//Created By BN 11/22/21


import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, CustomInput} from 'reactstrap';
import { getUserADAccount, getRandomInt, FormatDate } from '../../resources/utility'
import Datetime from 'react-datetime';
import moment from "moment";
import Select from 'react-select';
const ConfirmFiixWOComplete = (props) => {


 

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        disabled,
        buttonLabel,
        id,
        style,
        invalid,
        defaultValue,
        value,
        empList,
        issueTypeListFiltered,
        // dateValue,
        // defaultDateValue,
        onClickNo,
        completedDate,
        completedUser,
        isSecurityAdminLogin,
        invalidDate,
        invalidIssueType
    } = props

    const [modal, setModal] = useState(false);
    
    const toggle = () => {
        setModal(!modal);
         props.onClickNo()
        
    }

    // const toggleNo = () => {
    //     // setModal(! modal);
    //     props.onClickNo()
    // }

    const toggleYes = () => {
        // setModal(!modal)
        props.onClickYes();
    }

 

    const onClosed = () => {

        if (props.onClosed!==undefined)
        {
            props.onClosed();
        }        
    }

  

    const onChange = (e) =>{
        props.onChangeTest(e);
    }

    const onChange2 = (fieldValue, e) =>{ //Commented out by BN 7/11
        props.onChangeTest2(moment(fieldValue));
    }
    const onChange3 = (e) =>{ //Commented out by BN 7/11
        props.onChangeTest3(e);
    }

    const onChange4 = (e) =>{ //Commented out by BN 7/11
        props.onChangeTest4(e);
    }

    // const getSelEmpList = (value) => {
    //     // const { empList } = this.state

    //     if (value !== null && value !== "") {
    //         return props.empList.filter(e => e.adAccount === "Brandon.Nguyen")
    //     }
    //     return ""
    // }




    return(
        <div>
            <Button outline = {outline} 
                    style={style}
                    color={color}
                    className={className}
                    hidden={hidden}
                    disabled={disabled}
                    onClick={toggle}  >
                {image}
                {buttonLabel}
                
            </Button>

            <Modal isOpen={modal} 
                   toggle={toggle}
                   onClosed={onClosed} >
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>
                <FormGroup>
                {message}
                
                <Label style={{margin:"10px 0 0 0"}}>Please enter the amount of hours spent on the task: </Label>
                <Input type="number" name="completedHours" id="completedHours"                            
                            defaultValue={defaultValue} 
                            value={value}
                            //min="0"
                            //onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                            // defaultValue={meterValDetails.reviewComment}
                            onChange={onChange}
                            invalid={invalid}
                           >
                            
                </Input> 
                <Label 
                  hidden = {isSecurityAdminLogin} 
                style={{margin:"10px 0 0 0"}}>Please enter the completion date: </Label> 
                <Datetime
                      inputProps={{
                         placeholder: "MM/DD/YYYY hh:mm",
                           hidden: isSecurityAdminLogin,
                        // disabled: this.statusFieldReadOnly()
                      }}

                    // hidden = {!isSecurityAdminLogin}
                    hidden
                    //  value={completedDate}
                    // value={moment(completedDate)} 
                    className={invalidDate ? "form-error" : ""}
                    value={moment(completedDate)}
                        onChange={(v, f) =>
                            onChange2(v, f)
                          }
                      
                    />

                {/* <Row form>
                    <Col md={12}> */}
                        <FormGroup 
                        hidden= {isSecurityAdminLogin}
                        >
                            <Label for="empList" > Please select completed by user: </Label>

                            <Select options={empList}
                                // isMulti
                                name='empList'
                                id='empList'
                                // defaultValue={selectedEmpList}
                                //  value={(getUserADAccount(user.account.userName))}
                                // hidden={console.log(completedUser)}
                                 value={props.getSelEmpList(completedUser)}
                                onChange={(e) =>
                                    onChange3(e)
                                  }
                                ></Select>
                        </FormGroup>


                        <FormGroup 
                        // hidden= {isSecurityAdminLogin}
                        hidden = {props.woData[0].maintenanceTypeID != "MainID27" && props.woData[0].maintenanceTypeID != "MainID31"}
                        >
                            <Label for="issueTypeList" >Issue Type </Label>

                            <Select options={issueTypeListFiltered}
                                name='issueTypeList'
                                id='issueTypeList'
                                  value={props.getSelIssueType(props.woData[0].fiixIssueType)}
                                  className={invalidIssueType ? "form-error" : ""}
                                 onChange={(e) =>
                                     onChange4(e)
                                     
                                   }
                                ></Select>
                        </FormGroup>
                    {/* </Col>
                </Row> */}
                </FormGroup>
                  
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleYes}>Yes</Button>
                    <Button color="secondary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default ConfirmFiixWOComplete;