import React, { Component, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Table } from 'reactstrap';
import FetchData from '../resources/FetchData';
import { apiURL } from '../resources/apiURL';
import { UncontrolledCarousel } from 'reactstrap';
import DocumentBrowser from '../resources/DocumentBrowser';


const ImageCarousel = (props) => <UncontrolledCarousel items={props.list} />;

const IsLoading = (props) => {
    console.log("has doc" + props.isLoading);
    if (props.hasDoc < 1){
        return <h4>No documents Available</h4>
    }else if (props.isLoading) {
        return <h4>Loading ... </h4>
    }else {return ''};
}

const ImageViewer = (props) => {
    const {
        buttonLabel,
        className,
        formName,
        buttonColor,
        hasDoc,
        inspAPIURL,
        linkTableName,
        linkPrimaryKey,
        hidden
    } = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [isLoading, setIsLoading] = useState(false);
    var [imgList, setImageList] = useState([]);

    const openModelForm = () => {

        var imgs = [];

        //if (modal) {
        { console.log(inspAPIURL) }
        
        setIsLoading(true);
        setModal(!modal);

        if (!modal) {
            fetch(inspAPIURL)
                .then(res => res.json())
                .then((data) => {
                    data.map(function (doc) {
                        console.log(doc.documentKey);
                        let myURI = apiURL + 'Documents/Base64/' + doc.documentKey
                        //console.log(myURI)
                        fetch(myURI)
                            .then(res => res.json())
                            .then((data) => {
                                
                                const image = {
                                    src: 'data:image/jpeg;base64,' + data[0].docFileData,
                                    altText: doc.docFileName,
                                    caption: '',
                                    header: doc.docFileName,
                                    key: doc.documentKey
                                }

                                imgs.push(image)
                             
                            })
                    })

                    setImageList(imgs);
                   // 
                });
                //console.log(imgs);

                //setImageList(imgs);
                setIsLoading(false);
        }

       
    }
    const [base64Image, setBase64Image] = useState('...');

    const rowClickHandle = (docKey) => {
        console.log("Row click click - " + docKey)
        let myURI = apiURL + 'Documents/Base64/' + docKey
        console.log(myURI)
        fetch(myURI)
            .then(res => res.json())
            .then((data) => {
                console.log(data[0].docFileData);
                setBase64Image(data[0].docFileData)
            })



    }

    


    return (

        <div>
            <Button outline={hasDoc > 0 ? false : true}
                color={buttonColor}
                onClick={openModelForm}
                hidden={hidden}>
                {buttonLabel}
            </Button>

            <Modal isOpen={modal} toggle={toggle} contentClassName={className}>
                <ModalHeader toggle={toggle}>{formName}</ModalHeader>
                <ModalBody>

                    {/* <IsLoading isLoading={isLoading} hasDoc={hasDoc}/>
                    <ImageCarousel list={imgList} /> */}

                <DocumentBrowser  buttonLabel={buttonLabel}
                    buttonColor={buttonColor} 
                    formName={formName}     
                    linkTableName={linkTableName}
                    linkPrimaryKey={linkPrimaryKey}                                   
                    apiURL={inspAPIURL} />
                   
                    
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={toggle}>OK</Button>
                    {/* <Button color="primary" onClick={toggle}>Cancel</Button> */}
                </ModalFooter>
            </Modal>
        </div>






    );
}

export default ImageViewer;