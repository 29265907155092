
import React,{Component} from 'react';
import { Col, CustomInput,Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {apiURL} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class FacilityMeter extends Component{
    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state={
            isLoaded:false,
            facMeters:[],
            meterKey:0,
            facilityKey:props.FacilityKey,
            windowHeight:window.innerHeight,
            modal:false,
            gridHeightRatio: 0.8,
            gridFilter:((typeof(props.GridFilter) ==='undefined' || props.GridFilter===null) ? true : props.GridFilter),
            meterHeader:(( typeof(props.FacilityName) ==='undefined' || props.FacilityName===null) ? "Facility Meters | " : ((props.FacilityName !== "") ? props.FacilityName + " Meters | " : "")), // new Date().toISOString(),
            hideHeader:false,
            showAllFacility: (props.FacilityKey === null ? true : false)
        }
    }

    toggle=()=>{
        this.setState({modal: !this.state.modal})
    }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.facMeters;
    }
    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            console.log(dataItem)
            
            this.setState({meterKey: dataItem.meterKey})       
            //this.toggle()
            //this.onVolRefresh()    
        } 
    }

    onLoadStatusChange = () =>{
        this.getMeterList(!this.state.showAllFacility)
        this.setState({showAllFacility:!this.state.showAllFacility})
       
    }

    componentWillReceiveProps(props) {
       
        //console.log("In tank volume reresh")
        this.setState({
            facilityKey:props.FacilityKey,
            gridHeightRatio:(props.GridHeightRatio),
            hideHeader:props.HideHeader,
            meterHeader:(( typeof(props.FacilityName) ==='undefined' || props.FacilityName===null) ? "Facility Meters | " : ((props.FacilityName !== "") ? props.FacilityName + " Meters | " : "")), // new Date().toISOString(),
      
            //facilityHeader: (( typeof(props.VoldHeaderLabel) ==='undefined' || props.VoldHeaderLabel===null) ? "Tank Volumes | " : ((props.VoldHeaderLabel !== "") ? props.VoldHeaderLabel + " | " : "")), // new Date().toISOString(),
        })
        this.getMeterList()
      }

    refreshMeterList = () => {
        const {showAllFacility}=this.state
        this.getMeterList(showAllFacility)
    }

    getMeterList = (showAllFacility=false) => {

        let myURI
        if (showAllFacility){
            myURI = apiURL + 'MeterDetails' + '/AzureAD/' + this.props.user.account.userName;        
        }
        else{
            myURI = apiURL + 'MeterDetails/MyFacility' + '/AzureAD/' + this.props.user.account.userName;        
        }
        console.log("Meter Facilitykey: " + this.state.facilityKey);

        fetch(myURI , { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {

            if (!isNaN(this.state.facilityKey)){
                this.setState({
                    isLoaded: true,
                    facMeters: json.filter(meters => meters.facilityKey == this.state.facilityKey)                   
                })
            }else{
                this.setState({
                    isLoaded: true,
                    facMeters: json                   
                })
            }
        });
    }

    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 
    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getMeterList();
    }

    render(){
        const {isLoaded,facMeters,windowHeight,gridHeightRatio,showAllFacility,
               gridFilter,meterHeader,hideHeader} = this.state
        const gridHeight=(windowHeight * gridHeightRatio) + "px"
        var searchText = window.$searchText.toLowerCase()

        if (!gridFilter){
            searchText = "";
        }

        if (!isLoaded){
            return(<div>
                <h3>Loading Facility Meters .....</h3>
            </div>)
        }else
        return(
            <div>
                <FormGroup hidden={hideHeader}> 
                    <Row form>
                        <h2> {meterHeader}</h2>

                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.refreshMeterList}>
                            <img 
                            src={MenuIcon}
                            alt="Refresh"
                            style={{width:"30px", margin:"0 0 0 0"}}
                            />
                            Refresh
                        </Button>

                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}         


                         <FormGroup  style={{margin:"10px 0 0 100px"}} >
                        <h6>
                        <CustomInput className="float-right" 
                                    type="switch" 
                                    id="accept" 
                                    name="accept" 
                                    label="Show Meters for all Facilities"                                   
                                    checked={showAllFacility} 
                                    onClick={(e) => this.onLoadStatusChange()} />
                                    </h6>
                                    </FormGroup>               
                    </Row>
                    <ColoredLine color="grey" />     
                </FormGroup>

                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}
                        width="100%"
                        dataSource={facMeters.filter(rec => ((rec.createdBy || '').toLowerCase().includes(searchText)) ||
                                                    (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                    rec.meterID.toLowerCase().includes(searchText) ||
                                                    (rec.meterName || '').toLowerCase().includes(searchText) ||
                                                    (rec.operatorName || '').toLowerCase().includes(searchText) ||
                                                    (rec.qrCodeValue || '').toLowerCase().includes(searchText) ||
                                                    (rec.flowTypeDesc || '').toLowerCase().includes(searchText) ||
                                                    (rec.meterMake || '').toLowerCase().includes(searchText) ||
                                                    (rec.meterSerialNo || '').toLowerCase().includes(searchText) ||
                                                    (rec.meterStatusDesc || '').toLowerCase().includes(searchText) ||                                                    
                                                    rec.areaName.toLowerCase().includes(searchText) ||
                                                    (rec.companyName || '').toLowerCase().includes(searchText) ||                                                            
                                                    (rec.meterOpDesc || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}>                      
                        
                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>190" />
                        <IgrTextColumn field="meterID" headerText="Meter ID" width="*>140" />
                        <IgrTextColumn field="meterName" headerText="Meter Name" width="*>190" />
                        <IgrTextColumn field="meterStatusDesc" headerText="Status" width="*>110" />
                        
                        <IgrTextColumn field="operatorName" headerText="Operator" width="*>130" />
                        <IgrTextColumn field="qrCodeValue" headerText="QR Code" width="*>120" />
                        <IgrTextColumn field="meterTypeDesc" headerText="Meter Type" width="*>160" />
                        <IgrTextColumn field="flowTypeDesc" headerText="Flow Type" width="*>160" />
                        <IgrNumericColumn field="lat" headerText="Latitude" width="*>130"/>
                        <IgrNumericColumn field="long" headerText="Longtitude" width="*>130"/>
                        <IgrNumericColumn field="meterSize" headerText="Meter Size" width="*>130"/>
                        <IgrTextColumn field="meterMake" headerText="Meter Make" width="*>160" />
                        <IgrTextColumn field="meterSerialNo" headerText="Serial No" width="*>160" />
                        <IgrTextColumn field="meterOpDesc" headerText="Comments" width="*>160" />

                        <IgrTemplateColumn field="acquiredDate" headerText="Acquired Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}/>
                                            
                        <IgrTemplateColumn field="operateDate" headerText="Operate Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}/>
                        <IgrTextColumn field="createdBy" headerText="Created By" width="*>150" />                                   
                        
                        <IgrTextColumn field="locationName" headerText="Basin" width="*>160" />
                        <IgrTextColumn field="areaName" headerText="Sub System" width="*>130" />
                        
                        
                        
                        
                        <IgrNumericColumn field="meterKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>
                
                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className="{className}">
                        <ModalHeader toggle={() => this.toggle()}> Meter Detail </ModalHeader>
                        <ModalBody>
                        Meter Details here
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggle()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "FacilityMeters");
    }

}




