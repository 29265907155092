

import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, Card, CardBody, CardHeader } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import { ComponentRenderer, ProvideCalculatorEventArgs } from 'igniteui-react-core';
import '../../custom.css';


import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../../resources/utility';


export default class AFEListSelector extends Component {
    static displayName = AFEListSelector.name;

    constructor(props) {
        super(props)

        this.state = {
            afeList: props.dupAFEList,
            isDataLoaded: true,
            modal: false,
            showAlert: false,
            alertMsg: '',
            formName: "AFE List",
            selAFE:"",
            hidden:props.hidden,
            className:props.className,
            buttonLabel:props.buttonLabel
        }
    }

    toggleAlert = () => {

        // this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    toggleYes = () => {
        const {selAFE} = this.state
        var isValid = true
        var sErrMsg = ''

        if (selAFE === "") {
            isValid = false
            sErrMsg = "Missing AFE No. Please select an AFE then try again."          
        }
  

        console.log("selAFE") 
        console.log(selAFE) 

        if (isValid) {
            this.toggle()
            this.setState({ showAlert: false })
            this.props.onClickYes(selAFE);
        } else {
            this.setState({
                isValid: isValid,
                showAlert: true,
                alertMsg: sErrMsg
            })
        }

    }


    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData, validateDataItem } = this.state
        const { user } = this.props
        var selAFE = ""
        console.log(fieldName + ' ------ ' + fieldValue)
        console.log(e)

        switch (fieldName) {
            case "selAFE":
                selAFE =""
                if (e !== null){
                    selAFE = e.value
                }
                this.setState({selAFE:selAFE})
                break;
        }        
    }

    

    getSelAFE = (v) => {
        const { afeList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && v !== "") {
            // console.log("v")
            // console.log(v)
            return afeList.filter(e => e.afeNo === v)
        }
        return ""
    }

    render() {
        const { afeList, isDataLoaded,hidden, modal, showAlert,alertMsg, 
                className, formName,selAFE,buttonLabel } = this.state

        if (!isDataLoaded) {
            return(<div>Loadding data .....</div>)
        } else
            return (<div>
            
            <Button outline
                color="success"
                hidden={hidden}
                className={className}
                onClick={this.toggle} style={{ margin: "0 5px 0 5px" }} >

                {buttonLabel}

            </Button>

                <Modal isOpen={modal}
                    toggle={this.toggle}
                    size={"md"}
                    onClosed={this.onClosed}>
                    <ModalHeader toggle={this.toggle}> {formName}</ModalHeader>
                    <ModalBody>
                        <div>
                            <Alert color="danger" isOpen={showAlert}
                                toggle={() => this.toggleAlert()}
                                style={{ marginTop: "5px", marginBottom: "10px" }}
                            >
                                {alertMsg}
                            </Alert>
                        </div>

                        <FormGroup>
                            <Row form>
                                <Col md={4}>
                                    <Label for="coypy">Select an AFE</Label>
                                </Col>

                                <Col md={8}>
                                    <Select options={afeList}
                                        //className={(!validateDataItem.linkAFE ? "form-error" : "")}
                                        value={this.getSelAFE(selAFE)}
                                        defaultValue={this.getSelAFE(selAFE)}
                                        onChange={(e) => this.onTextChange("selAFE", e, e)}
                                        isClearable={true}

                                    >
                                    </Select>
                                </Col>

                            </Row>


                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.toggleYes} >OK</Button>
                    <Button color="primary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
                </Modal>
            </div>)
    }
}