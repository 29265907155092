
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { authProvider } from './authProvider';
import  { accountInfo }  from './resources/AccountInfo';
import reportWebVitals from './reportWebVitals';

//Added by HN 06/23/2023
import { registerLicense } from '@syncfusion/ej2-base';
// Registering Syncfusion license key
//registerLicense('GTIlMmhhYX1hfWRoYGJ8YmF8ampqanNhYGlmamlmamg7MiZ9PTQmKjY9EzthPDEhOjc0Nn0wPD4=');
// registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZjUX9YcHZVTmJVUE1xVw==');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cXGNCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXdcdHVUR2RZV01/X0c='); //Brandon
registerLicense(process.env.REACT_APP_SYNC_FUSION_LICENSEKEY);
 
// console.log('process.env.REACT_APP_SYNC_FUSION_LICENSEKEY: ' + process.env.REACT_APP_SYNC_FUSION_LICENSEKEY);


window.$searchText = '' //global Searh text
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


ReactDOM.render(
   <AzureAD provider={authProvider} forceLogin={true}>  
       {
           ({ authenticationState, accountInfo }) => {
               return (                    
                           authenticationState === AuthenticationState.Authenticated &&
                           <React.Fragment>
                                <App user={accountInfo}/>
                               {/* <BrowserRouter basename={baseUrl}>
                                   <App user={accountInfo}/>
                               </BrowserRouter>  */}
                           </React.Fragment>                       
               );
           }
     
       }
   </AzureAD>,
   rootElement     
);
reportWebVitals();



// ReactDOM.render(
//   <BrowserRouter basename={baseUrl}>
    
//         <App user={accountInfo}/>
       
//   </BrowserRouter>,
//   rootElement);
// reportWebVitals()





// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
