import React, { Component} from 'react';
import { apiURL, apiHeader} from '../resources/apiURL';

import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';


import {Row, Button, FormGroup} from 'reactstrap';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';

import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';


import { ColoredLine} from '../resources/utility';
import MtlMoveTrans from './SubForms/MtlMoveTrans'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");

export default class MaterialMovement extends Component {
    static displayName = MaterialMovement.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state = {
            mtlMoveTran: [],
            windowHeight: window.innerHeight,
            windowWidth: window.windowWidth,
            statusKey:-1,
            isLoaded: false,
            modalMMDetails: false,
            selMtlMoveHdr:null
        }
    }

    handleResize = (e) => {
        // console.log("document.getElementById('mainDiv')")
        // console.log(document.getElementById('mainDiv'))

        if (document.getElementById('mainDiv') !== null) {
            this.setState({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth //document.getElementById('mainDiv').offsetWidth
            });

        }
    };

    onGridRowSelected(s, e) {
        //  console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                materialMovementKey: dataItem.materialMovementKey,
                mmStatusDesc: dataItem.mmStatusDesc,
                statusKey: dataItem.statusKey,
                mmNo: dataItem.mmNo,
                modalMMDetails: true,
                selMtlMoveHdr:dataItem
            })
            //this.toggleAFEDetails()
        }
    }

    toggleMMDetails = () => {
        this.setState({
            modalMMDetails: !this.state.modalMMDetails
        })
    }

    closeMMDetailsForm = () => {
        this.setState({
            modalMMDetails: !this.state.modalMMDetails,
            materialMovementKey: -1,
            mmStatusDesc: '',

        })
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.mtlMoveTran;

    }

    getMtlMovement = () => {
        let myURI = apiURL + 'ItemMaster/mtlmovement'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isLoaded: true,
                    mtlMoveTran: json
                })
            });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getMtlMovement()
    }
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { mtlMoveTran, isLoaded, windowHeight,mmStatusDesc,mmNo,statusKey,selMtlMoveHdr,
            modalMMDetails,materialMovementKey,windowWidth } = this.state
        const gridHeight = (windowHeight * 0.75) + "px"
        const test = windowWidth + "px"
        return (<div id='mainDiv'>
            <FormGroup>
                <Row form>
                    <h2> Material Movement Managment |</h2>
                    <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.getMtlMovement}>
                        <img
                            src={Refresh}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 5px 0 0" }} />
                        Refresh
                    </Button>
                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.saveWorkbook}>
                        <img
                            src={Excel}
                            alt="excel"
                            style={{ width: "50px", margin: "0 0 0 0" }} />
                        Export To Excel
                    </Button>{' '}
                    {/* 
                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.newSupplierPOSelector}>
                        <img
                            src={MenuIcon}
                            alt="costRollup"
                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                        Run Cost Rollup
                    </Button>{' '} */}

                </Row>
                <ColoredLine color="grey" />
                {/* Material In/Out Transaction. */}
            </FormGroup>

            <IgrDataGrid
                ref={this.onGridRef}
                height={gridHeight}
                width={"100%"}
                dataSource={mtlMoveTran}
                isColumnOptionsEnabled="true"
                autoGenerateColumns="false"
                selectionMode="SingleRow"
                isGroupCollapsable="true"
                isGroupExpandedDefault="true"
                isHeaderSeparatorVisible="false"
                groupHeaderDisplayMode="split"
                editMode={0}
                selectedKeysChanged={this.onGridRowSelected}
            // selectedKeysChanged={this.onGridRowSelected}
            >

                <IgrTextColumn field="mmNo"
                    headerText="MMNo" width="*>110" />
                <IgrTextColumn field="fiscalYrMonth"
                    headerText="Yr Month" width="*>130" />
                <IgrTextColumn field="mmStatusDesc"
                    headerText="Status" width="*>130" />
                <IgrTextColumn field="createdByName"
                    headerText="Created By" width="*>230" />
                <IgrTextColumn field="createdDatetime"
                    headerText="Created Datetime" width="*>230" />
                <IgrTextColumn field="modifiedByName"
                    headerText="Modified By" width="*>230" />
                <IgrTextColumn field="exportedToWDDatetime"
                    headerText="Exported To Workday Date" width="*>230" />
                <IgrTextColumn field="comments"
                    headerText="Comment" width="*>550" />
                <IgrNumericColumn field="materialMovementKey" isHidden />

            </IgrDataGrid>

            <div>
                <Modal isOpen={modalMMDetails}
                     contentClassName="full-screen-modal"
                    toggle={() => this.toggleMMDetails()}
                    style={{margin: 0, flex :1}}
                    backdrop={"static"}
                    onClosed={() => this.getMtlMovement()}>
                    <ModalHeader toggle={() => this.closeMMDetailsForm()}>
                        {(materialMovementKey < 0 ? "New Material Movement" : "Material Movement Details - " + mmNo + " - " + mmStatusDesc)}
                    </ModalHeader>
                    <ModalBody>
                        <MtlMoveTrans toggle={this.closeMMDetailsForm}
                            user={this.props.user}
                            mtlMoveHdr={selMtlMoveHdr}
                            statusKey={statusKey}
                            materialMovementKey={materialMovementKey}
                        >
                        </MtlMoveTrans>
                    </ModalBody>
                </Modal>
            </div>
        </div>)
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "MaterialMovement");
    }
}