import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';

import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'
import MaterialRequestDetail from './MaterialRequestDetail'
import moment from "moment";
import ReactToPrint from 'react-to-print';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");
const NewMtlRequest = (props) =>{

    const {basinList, afeList, siteList} = props

    const [afeListDefault, setAFEListDefault] = useState(afeList)

    const [selBasin, setSelBasin] = useState(-1)
    const [selAFE, setSelAFE] = useState("")
    const[selAFE2, setSelAFE2]= useState("")
    const [selSite, setSelSite] = useState("")
    const[selSiteKey,setSelSiteKey]= useState("")
    const [woNo,setWoNo] =useState("")
    const [basinVal, setBasinVal]= useState(true)
    const [afeVal, setAFEVal]= useState(true)
    const [siteVal, setSiteVal] = useState(true)
    const [scHeaderBool2, setHeaderBool2] = useState(false)
    const [switchBool, setSwitchBool] = useState(false)
    const [switchBool2, setSwitchBool2] = useState(false)
    const onTextChange = (fieldName, fieldValue, e) => {
        console.log(fieldName + '=' + fieldValue )
        switch (fieldName) {
            case "basin":
                if (e !== null){
                    setSelBasin(e.value)
                   setBasinVal(fieldValue != null)
                   setSelAFE("")
                //    console.log(selSite)
                setAFEListDefault(afeList.filter(a=> Number(a.locationKey) === e.value ))
                setSwitchBool(false)
                setSwitchBool2(false)
                }else{
                    setSelAFE("")
                    setSelSite("")
                    setAFEListDefault(afeList)
                    setBasinVal(fieldValue != null)
                    setSelBasin(null)
                    setSwitchBool(false)
                    setSwitchBool2(false)
                }
              
                break;
            case "afe":

                if (e !== null) {
                    // console.log(e.value)
                var test  = afeList.filter(a => a.afeMasterKey === e.value)
                // console.log(test[0].project_ID)
                    setSelAFE2(test[0].project_ID)
                    setSelAFE(e.value)
                    setAFEVal(fieldValue != null)
                    setSiteVal(fieldValue != null)
                    setSwitchBool(true)
                    
                    // getSupplierPOs(e.value)
                } else {
                    setSwitchBool(false)
                    setSelAFE(null)
                    setSelAFE2(null)
                    // setSelAFE2(-1)
                    // getSupplierPOs(null)
                }

                break;
            case "site":
                if (e !== null ) {
                    var test2 = siteList.filter(a => a.siteID=== e.value)
                    setSelSiteKey(test2[0].referenceIDKey)
                    setSelSite(e.value)
                    setSiteVal(fieldValue != null)
                    setAFEVal(fieldValue != null)
                    setHeaderBool2(false)
                    setSwitchBool2(true)
                    // setSelSupplier(e.supplierID)
                } else {
                    setSelSite(null)
                    setSelSiteKey(null)
                    setHeaderBool2(true)
                    setSwitchBool2(false)
                    // setSiteVal(fieldValue != null)
                }

                break;

                case "woNo":

                    setWoNo(fieldValue)
                    
                    break; 
            default:
        }
    }

    const saveData = () => {
        var cont = true;
        if(selBasin === -1 || selBasin ===null){
            console.log(selBasin + "xxx")
            setBasinVal(false)
            cont = false
        }
        if((selAFE === "" || selAFE === -1 || selAFE === null) && (selSite === "" ||  selSite === -1 || selSite === null || selSite === false)){
            console.log(selAFE + "vvv")
            setAFEVal(false)
            setSiteVal(false)
            cont = false
        }
        // if(selSite === "" ||  selSite === -1 || selSite === null || selSite === false){
        //     console.log(selSite + "ttt")
        //     setSelSite(false)
        //     cont = false
        // }
        console.log(cont)
        if (cont){
            props.onClickYes(selBasin, selAFE2, selSiteKey, woNo, selSite, scHeaderBool2)
        }
       
        console.log(selBasin, selAFE2, selSiteKey, woNo)
        // props.setStateOfParent2(0)
        // props.setStateOfParent3("MISC")
    
    }

   const getSelBasin = (v) => {

        if (v !== null && v !== "") {
            return basinList.filter(e => e.locationKey === v)
        }
        return ""
    }

    const getSelAFE = (v) => {

        if (v !== null && v !== "") {
            // console.log(v)
      var test =  afeList.filter(e => e.afeMasterKey=== v)
            // console.log(test[0])
                // console.log(test[0].project_ID)
                // setSelAFE2(test[0].project_ID)
            return afeList.filter(e => e.afeMasterKey=== v)
        }
        return ""
    }

    const getSelSite = (v) => {

        if (v !== null && v !== "") {

            return siteList.filter(e => e.siteID=== v)
        }
        return ""
    }

    return(<div>
         <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
              To Basin
            </Label>
            <Col sm={9}>
                <Select 
                    options={basinList}
                    value={getSelBasin(selBasin)}
                    className={!basinVal ? "form-error" : ""}
                    onChange={(e) => onTextChange("basin", e, e)}
                    isClearable={true}>

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="afe"
                sm={3}
            >
              To AFE
            </Label>
            <Col sm={9}>
                <Select 
                    options={afeListDefault}
                    isDisabled={switchBool2}
                    value={getSelAFE(selAFE)}
                    className={!afeVal ? "form-error" : ""}
                    onChange={(e) => onTextChange("afe", e, e)}
                    isClearable={true}>

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="site"
                sm={3}
            >
               To Site
            </Label>
            <Col sm={9}>
                <Select 
                className={!siteVal ? "form-error" : ""}
                isDisabled={switchBool}
                    options={siteList}
                    value={getSelSite(selSite)}
                    onChange={(e) => onTextChange("site", e, e)}
                    isClearable={true}>
                </Select>

            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="woNo"
                sm={3}
            >
               To Work Order No.
            </Label>
            <Col sm={9}>
                <Input
                        type="text" name="woNo"
                        // disabled= {mtlStatusKey != 0}
                            // disabled= {isFormReadOnly}
                            id="woNo" placeholder=""
                            value={woNo}
                            // defaultValue={materialRequestData[0].woNumber}
                            onChange={(e) => onTextChange("woNo", e.target.value, e)}     
                    >
                </Input>

            </Col>
        </FormGroup>


        <div className="float-right">
            <Button color="primary" className="float-right"
                onClick={() => props.toggle()}>Cancel</Button>
            <Button color="primary" className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}
                onClick={() => saveData()}
                >OK</Button>
        </div>
    </div>)
}



export default class MaterialRequest extends Component {
    static displayName=MaterialRequest.name;

    constructor(props){
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);

        this.state={
            isSecurityAdminLogin:false,
            createdBy: null,
            scHeaderBool2: false,
            isSecurityLoaded: false,
            headerColorBool: false,
            mtlReqLine: [],
            // isMtlReqLineLoaded:false,
            locationKey: -1,
            selAFEKey: -1,
            selBasinKey: -1,
            isSiteLoaded:false,
            siteList: [],
            isAFELoaded: false,
            isBasinLoaded: false,
            afeList:[],
            basinList:[],
            modal:false,
            text: "old boring text",
            isLoadingTest: false,
            mtlStatusKey: null,
            mtlReqData: [],
            msgBody: "",
            msgHeader: "",
            modalMsg:false,
            mtlRequestKey: -1,
            modalMatReqDetails:false,
            isLoaded: false,
            materialRequestData: [],
            fullMaterialRequestData: [],
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            windowHeight: window.innerHeight,
            mtlRequestQueryList: [{
                "label": "My Issues/Requests",
                "value": 1
            },{
                "label": "All Requests",
                "value": 2
            }],
            selMtlRequestQuery: []
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.materialRequestData;
    }

    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
        });

    }

    getMaterialRequest = () => {
        const {user} = this.props
        const { materialRequestData} = this.state
        let myURI = apiURL + 'SupplyChain/MaterialRequest/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + getUserADAccount(user.account.userName);
            // this.props.user.account.userName;
        console.log("myURI");
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    materialRequestData: json.filter(a => a.requestTypeKey === 2),
                    fullMaterialRequestData: json.filter(a => a.requestTypeKey === 2)
                },()=>{
                    // this.setDefaultMtlRequestQuery()
                    console.log(json)
                })
                this.getDefaultMtlRequestList(json.filter(a => a.requestTypeKey === 2))
              
            });
            console.log(this.props.user.account.userName)
            console.log(getUserADAccount(user.account.userName))

    }


    // getMaterialRequestLine = () => {
    //     const { mtlRequestKey, mtlReqLine } = this.state

    //     let myURI = apiURL + 'SupplyChain/MaterialRequestLine/MtlReqKey/' + mtlRequestKey

    //     // console.log(myURI);

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             var mtlReqLine = []
    //             json.map(e => {
    //                 e.isUpdated = 0 /* Add this field to keep track of updated receiver line */
    //                 mtlReqLine.push(e)
    //             })
    //             this.setState({
    //                 mtlReqLine: mtlReqLine,
    //                 isMtlReqLineLoaded: true
    //             })
    //             // console.log("Test:" + rcvrLine.ItemMasterKey)
                
    //         });
    
    // }

    
    getMaterialRequestLine = () => {
        const { mtlRequestKey, mtlReqLine } = this.state

        let myURI = apiURL + 'SupplyChain/MaterialRequestLine/MtlReqKey/' + mtlRequestKey

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                var mtlReqLine = []
                json.map(e => {
                    e.isUpdated = 0 /* Add this field to keep track of updated receiver line */
                    mtlReqLine.push(e)
                })
                this.setState({
                    mtlReqLine: mtlReqLine,
                    isMtlReqLineLoaded: true
                })
                // console.log("Test:" + rcvrLine.ItemMasterKey)
                
            });
    
    }

    
    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }

    getSecurity=()=>{
        let myURI = apiURL + 'EmployeeDetails/Checkpoint/'+ this.props.user.account.userName   
        const {security, appSecurityNo, meterVal} = this.state
        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                
                isSecurityLoaded: true,
                security: json,
                isSecurityAdminLogin: (json.filter((list)=> list.appSecurityNo === 111).length > 0 ? false: true),
                // isSecurityMeasurementTech: (json.filter((list)=> list.appSecurityNo === 15).length > 0 ? false: true),
                // isOtherUser: (json.filter((list)=> list.appSecurityNo === 15 || list.appSecurityNo === 14 ).length == 0 ? true:false)
            }
            ,()=>{
                console.log("Does default hit")
                    this.setDefaultMtlRequestQuery()
                
            }
            )
            // console.log(this.state.isSecurityAdminLogin)
            // console.log(this.state.isSecurityMeasurementTech)
          
        })

        } 

    


    getBasin = () =>{
                    
        let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    createAFEList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeMasterKey
            e.label = e.projectNoName
            tempList.push(e)
        })

        return tempList
    }

    getAFE = () =>{
        let myURI = apiURL + 'AFE/AFEFilter' 

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeList: this.createAFEList(json),
                    // afeData: json,
                    isAFELoaded: true
                })
            });
    }

    createSiteList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.siteID
            e.label = e.siteDesc
            tempList.push(e)
        })

        return tempList
    }

    
    getSite = () =>{
        let myURI = apiURL + 'SupplyChain/Site' 

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    siteList: this.createSiteList(json),
                    siteData: json,
                    isSiteLoaded: true
                })
            });
    }

    // createMtlRequestHdr = () =>{
       
    //     const updateMtlRequestHeaderDTO = this.createMtlRequestHeaderCreateDTO()

    //     let myURI = apiURL + 'SupplyChain/MaterialRequest'
    //     fetch(myURI, apiPOSTHeader(updateMtlRequestHeaderDTO))
    //         .then(async response => {
    //             const rspData = response.text();
    //             if (!response.ok) {
    //                 console.log("Error return but you dont see")
    //                 const error = (rspData && rspData.message) || response.status;
    //                 return Promise.reject(error);
                   
    //             }
            
    //             console.log("No error here: " + rspData)
    //             //this.props.refreshData()
    //             // this.getMaterialRequest()
    //         })
    //         .catch(error => {
    //             // this.getMaterialRequest()
    //             console.log("POST Error: " + error);
    //         })

    // } 

    // createMtlRequestHdr = () => {

    //     const newRCVRDTO = this.createMtlRequestHeaderCreateDTO()

    //     let myURI = apiURL + 'SupplyChain/MaterialRequest'
    //     fetch(myURI, apiPOSTHeader(newRCVRDTO))
    //         .then(async response => {
    //             if (!response.ok) {
    //                 const rspData = response.text();
                    
    //                 var error = (rspData && rspData.message) || response.status;
    
    //                 rspData.then(t => {
    //                     this.setState({
    //                         msgBody: t,
    //                         msgHeader: "Error",
    //                         modalMsg: !this.state.modalMsg
    //                     })
    //                 });
    //                 console.log("Error was returned")
    //                 return Promise.reject(error);
    //             } else

    //                 return response.json();

    //         })
    //         .then(json => {
    //                 console.log("Does it hit?")
    //                 console.log(json[0].mtlRequestKey)       
    //             if (json[0].mtlRequestKey > 0) {
    //                 this.setState({ mtlRequestKey: json[0].mtlRequestKey,
    //                                })
    //                 this.toggleMatReqDetails()
    //             }
    //             else{
    //                 console.log("Do nothing")
    //             }

    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    // }


    createMtlRequestHdr=()=>{
            this.setState({mtlRequestKey: -1,
                      })
        this.toggleMatReqDetails()
    }


    createMtlRequestHeaderCreateDTO=()=>{
        const {user}= this.props

        const matReqCreate={
            // "mtlRequestKey": -1,
            // "mtrReqStatusDesc": "Incomplete",
            "MtlRequestNo": "MTLREQXXXXX",
            // "mtlRequestNo": "Test",
            "RequestTypeKey": 2,
            "StatusKey": 0,
            "FacilityKey": null,
            "ProjectID": "",
            "WONumber": "",
            "FromLocationKey": null,
            "ToLocationKey": null,
            "RequestedBy": null,
            // "RequestedDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            // "requestedDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            // "requestedDate": new Date().toISOString(),
            "RequestedDate": null,
            "requestedDate":null,
            "LocationKey": null,
            // "createdDatetime": new Date().toISOString(),
            "createdBy": this.props.user.account.name,
            // "LocationKey": null,
            "Comments": "",
            "comments": "testing",
            "EmployeeKey": null
           
        }
        console.log(matReqCreate)
        return matReqCreate;
    }

    // toggleMatReqDetailsCreate=()=>{
    //     this.createMtlRequestHdr()
    //     // this.setState({mtlRequestKey: -1,
    //     //               })
    //  this.toggleMatReqDetails()
    // }
    
    toggleMatReqDetails=()=>{
        this.setState({modalMatReqDetails: !this.state.modalMatReqDetails})
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }



    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]


            this.setState({ 
                mtlRequestKey: dataItem.mtlRequestKey,
                mtlReqData: dataItem,
                mtlStatusKey: dataItem.statusKey,
                locationKey: dataItem.locationKey,
                selBasinKey: dataItem.locationKey
                        })
            // this.toggleRcvDeails()
            this.toggleMatReqDetails()
         console.log(dataItem)
         console.log("Material Request Key: " + dataItem.mtlRequestKey)
         console.log("StatusKey: " +  dataItem.statusKey)
         console.log(dataItem.locationKey)
        }
        
    }
    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        // this.getMaterialRequest()
        // this.getMaterialRequestLine()
        this.getBasin()
        this.getAFE()
        this.getSite()
        this.getSecurity()
        // this.setDefaultMtlRequestQuery()
    }

    // newMaterialRequest=()=>{
        
    //     // this.setState({mtlRequestKey:-1})
    //     this.toggleAddMaterialRequest()
    // }

    // toggleAddMaterialRequest=()=>{
    //     const newMaterialRequest = this.createMaterialRequestForm()
    //     this.setState({materialRequestData: newMaterialRequest})
    //     this.toggleMatReqDetails()
    // }

    // createMaterialRequestForm=()=>{
    //     const matReqCreate={
    //         "mtlRequestNo": "",
    //         "requestTypeKey": null,
    //         "statusKey": null,
    //         "facilityKey": null,
    //         "projectID": "",
    //         "woNumber": "",
    //         "fromLocationKey": null,
    //         "toLocationKey": null,
    //         "requestedBy": "",
    //         "requestDate":null,
    //         "createdBy": "",
    //         "locationKey": null
    //     }
    //     return matReqCreate
    // }
    reactToPrintContent = ()=>{
                    return this.componentRef;        
    }
    setComponentRef = (ref)=>{
        this.componentRef = ref;
    }

    handleAfterPrint = () => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
      };
    
      handleBeforePrint = () => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
      };
      handleOnBeforeGetContent = () => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        this.setState({ text: "Loading new text...", isLoadingTest: true });
    
        return new Promise((resolve) => {
          setTimeout(() => {
            this.setState(
              { text: "New, Updated Text!", isLoadingTest: false },
              resolve
            );
          }, 2000);
        });
      };
      reactToPrintTrigger = () => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.
    
        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;
    
        // Good
        return <Button outline color="secondary"
        className="btn-no-border"
        // onClick={this.createMtlRequestHdr}
        >
        <img
            src={Plus}
            alt="new"
            style={{ width: "25px", margin: "0 5px 0 0" }} />
        Print
    </Button>
      };

      toggle =() =>{
        this.setState({ modal: !this.state.modal })
      }
      newSelector = () => {
        this.toggle()

    }
      
      newMtlReq=(basin, afe2, site, WoNo, selSite, scHeaderBool2)=>{

            this.setState({scHeaderBool2: scHeaderBool2})
          this.createNewReceiver(basin, afe2, site, WoNo, selSite)
         this.toggle()
         
      }


      createNewReceiver = (basin, afe2, site, WoNo, selSite,  scHeaderBool2) => {
        const newMtlReqDTO = this.createMtlReqDTO(basin, afe2, site, WoNo, selSite,  scHeaderBool2)

        let myURI = apiURL + 'SupplyChain/MaterialRequest'
        fetch(myURI, apiPOSTHeader(newMtlReqDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    
                    var error = (rspData && rspData.message) || response.status;
    
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    });
                    return Promise.reject(error);
                } else

                    return response.json();

            })
            .then(json => {
                           
                if (json[0].mtlRequestKey > 0) {
                    this.setState({ 
                                    mtlRequestKey: json[0].mtlRequestKey,
                                    selBasinKey: basin, 
                                    selAFEKey: afe2,
                                    createdBy: json[0].createdBy
                                    // selProjectID: afe
                                })
                    this.toggleMatReqDetails()
                }

            })
            .catch(error => {

            })
    }

    createMtlReqDTO = (basin, afe2, site, WoNo, selSite,  scHeaderBool2) => {
        const { user } = this.props
        const rcvrCreateDTO = {
            "MtlRequestNo": "XXXXXX",
            "RequestTypeKey": 2,
            "StatusKey": 0,
            "FacilityKey": null,
            "ProjectID": afe2,
            "WONumber": WoNo,
            "FromLocationKey": null,
            "ToLocationKey": site,
            "RequestedBy": "",
            "RequestDate": moment(new Date().toISOString()).format('MM-DD-YYYY'),
            "CreatedBy": getUserADAccount(user.account.userName),
            "LocationKey": basin,      
            "SiteID": selSite,
            "AFENo": ""
        
          
        }

        console.log(rcvrCreateDTO)
        return rcvrCreateDTO;

    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { fullMaterialRequestData } = this.state
        // console.log(fieldName + ' ------ ' + fieldValue + '------' + e)
        // console.log(e)

        switch (fieldName) {

            case "mtlRequestQuery":
                // console.log(e.value)
                this.getFilterMtlRequest(fullMaterialRequestData, e)
                this.setState({ selMtlRequestQuery: e })
                break;
            default:
                break;
        }
    }

    getFilterMtlRequest = (e,x) => {
        const {fullMaterialRequestData} = this.state
        const { user } = this.props

        // const basins = b.map(e => e.locationName).join(",")
        // const status = s.map(e => e.afeStatusDesc).join(",")

        const userName = getUserADAccount(user.account.userName).toLowerCase()

        var tempMtlRequest = e
        console.log(tempMtlRequest.filter(e => (e.createdBy || "").toLowerCase() === userName))
        // console.log(tempMtlRequest.filter(e => (e.createdBy || "").toLowerCase()))
        console.log("xxx" + x.value)
        console.log(tempMtlRequest)
        if (x.value === 1) {
            tempMtlRequest = tempMtlRequest.filter(e => (e.createdBy || "").toLowerCase() === userName 
            // ||
            //     (e.createdByAzureAD || "").toLowerCase() === userName 
                // ||
                // (e.afeSponsorAzureAD || "").toLowerCase() === userName ||
                // (e.approvers || "").toLowerCase().includes(userName)
                )
        }
        // if (x.value === 2){
        //     console.log("Does this hit")
        //     this.setState({materialRequestData: fullMaterialRequestData})
        // }

    



        console.log("tempMtlRequest")
        console.log(tempMtlRequest)

        this.setState({ materialRequestData: tempMtlRequest })
    }

    getDefaultMtlRequestList = (e) => {
        this.getFilterMtlRequest(e, this.state.selMtlRequestQuery)
    }
    setDefaultMtlRequestQuery = () => {
     const {isSecurityAdminLogin} = this.state
     if(isSecurityAdminLogin === false){
         console.log("hit 1")
        this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[1] },()=>{
            this.getMaterialRequest()
        })
     }
     else{
        console.log("hit 2")
        this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[0] },()=>{
            this.getMaterialRequest()
        })
     }
       
    }
   

    render(){
        const { materialRequestData, windowHeight, mtlRequestKey, msgHeader, 
            msgBody, mtlReqData, mtlStatusKey, isBasinLoaded, basinList, afeList,
             isAFELoaded, siteList, isSiteLoaded, selBasinKey, 
             selAFEKey, locationKey, headerColorBool, isSecurityLoaded,
             scHeaderBool2, createdBy, mtlRequestQueryList, selMtlRequestQuery} = this.state
        var {isLoaded} = this.state
        
        const searchText = window.$searchText.toLowerCase()
        const gridHeight = (windowHeight * 0.7) + "px"
        if (!isLoaded || !isBasinLoaded || !isAFELoaded ||!isSiteLoaded || !isSecurityLoaded) {
            return (<div>
                <h3 >Loading issues/requests.....</h3>
            </div>)
        } else
        return(<div>
            <FormGroup>
                    <Row form>
                        <h2  > Issues/Requests |</h2>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.getMaterialRequest}
                            ref={this.setComponentRef}
                            >
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>
                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}
                            >
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.newSelector}
                            >
                            <img
                                src={Plus}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            New Material Request
                        </Button>{' '}
                        {/* <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.toggle}
                            >
                            <img
                                src={Plus}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            New Material Request 2
                        </Button>{' '} */}


       



                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>
                <FormGroup >
                    <Row    >
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                Start Date
                            </Label>
                            <DatePicker
                             value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)}
                                 />
                        </Col>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                End Date
                            </Label>
                            <DatePicker 
                            value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)}
                                 />

                        </Col>
                        <Col md={3} >
                                    <Label for="typeKey" style={{ margin: "0 0 0 0", padding: "0" }}>Filter</Label>
                                    <Select 
                                        className="alway-in-between-more"
                                        options={mtlRequestQueryList}
                                        value={selMtlRequestQuery}
                                        defaultValue={selMtlRequestQuery}
                                        onChange={(e) => this.onTextChange("mtlRequestQuery", e, e)}
                                        isClearable={true}
                               
                                    >
                                    </Select>
                        </Col>

                    </Row>
                </FormGroup>
                
                <FormGroup 
                >
                    <IgrDataGrid
                            ref={this.onGridRef}
                            // style={{zIndex: -1}}
                            className="alway-on-bottom"
                            height={gridHeight}
                            
                            isColumnOptionsEnabled="true"
                            filterUIType="FilterRow"
                            dataSource={materialRequestData.filter(rec => ((rec.basin || '').toLowerCase().includes(searchText)) ||   
                            (rec.mtlRequestNo || '').toLowerCase().includes(searchText) ||
                            (rec.projectID || '').toLowerCase().includes(searchText) ||
                            (rec.mtrReqStatusDesc || '').toLowerCase().includes(searchText) ||
                            (rec.spendCategoryDesc || '').toLowerCase().includes(searchText) ||
                            (rec.woNumber || '').toLowerCase().includes(searchText) || 
                            (rec.requestTypeDesc || '').toLowerCase().includes(searchText) ||
                            (rec.siteDesc || '').toLowerCase().includes(searchText) ||
                            (rec.createdBy || '').toLowerCase().includes(searchText) ||
                            (rec.comments || '').toLowerCase().includes(searchText) ||
                            (rec.createdDateTime || '').toLowerCase().includes(searchText))}
                            // dataSource={console.log(materialRequestData)}
                            // isGroupByAreaVisible="true"
                            scrollable={true}
                            editMode={0}                                       
                            autoGenerateColumns="false"                                   
                            selectionMode="SingleRow"
                            selectedKeysChanged={this.onGridRowSelected}>

                            <IgrTemplateColumn field="basin" headerText="Basin"
                                cellUpdating={this.onStatusCellUpdating}

                                width="*>180"
                            />
                            <IgrTemplateColumn field="mtlRequestNo" headerText="Request No."
                                width="*>180"
                                 cellUpdating={this.onStatusCellUpdating}
                            />

                          <IgrTemplateColumn field="mtrReqStatusDesc" headerText="Request Status"
                                width="*>200"
                                cellUpdating={this.onStatusCellUpdating}
                            />
                            <IgrTemplateColumn field="projectID" headerText="Project ID" width="*>170"
                                    cellUpdating={this.onStatusCellUpdating}

                            />
                             <IgrTemplateColumn field="spendCategoryDesc" headerText="Spend Category" width="*>250"
                                    cellUpdating={this.onStatusCellUpdating}

                            />
                            <IgrTemplateColumn field="woNumber" headerText="Work Order No."
                                 cellUpdating={this.onStatusCellUpdating}    
                                width="*>200"
                            />
                            <IgrTemplateColumn field="requestTypeDesc" headerText="Request Type"
                             className="alway-on-bottom"
                                cellUpdating={this.onStatusCellUpdating}
                                width="*>200"
                            />
                              <IgrTemplateColumn field="siteDesc" headerText="Site"
                                cellUpdating={this.onStatusCellUpdating}

                                width="*>180"
                            />
                            
                              <IgrTemplateColumn field="createdBy" headerText="Created By"
                                cellUpdating={this.onStatusCellUpdating}
                                width="*>180"
                            />
                          
                        <IgrTemplateColumn field="createdDateTime" headerText="Created Date"
                       cellUpdating={this.onStatusCellUpdating}
                            width="*>150"
                
                        />
                           {/* <IgrTextColumn
                                    field="createdDatetime"
                                    headerText="Created Date"
                                    width="145"
                         
                                /> */}
                        
                   
                              <IgrTemplateColumn field="comments" headerText="Comments"
                                cellUpdating={this.onStatusCellUpdating}
                                width="*>180"
                            />
                            
                           

                          
                        </IgrDataGrid>
                </FormGroup>

                <Modal isOpen={this.state.modalMatReqDetails}
                        toggle={() => this.toggleMatReqDetails()}
                        size="xl"
                        onClosed={() => this.getMaterialRequest()}
                        backdrop={"static"}
                    >
                        <ModalHeader  className={this.getMtlReqHeaderColor(mtlReqData)}
                         toggle={() => this.toggleMatReqDetails()}>
                            Material Request Details
                        </ModalHeader>
                        <ModalBody  className="alway-on-top">
                            <MaterialRequestDetail 
                            toggle={this.toggleMatReqDetails}
                               mtlRequestKey = {mtlRequestKey}
                                user={this.props.user}
                                refreshData={this.getMaterialRequest}
                                mtlStatusKey = {mtlStatusKey}
                                selBasinKey = {selBasinKey}
                                selAFEKey = {selAFEKey}
                                locationKey = {locationKey}
                                setStateofParentHeaderColor={this.setStateofParentHeaderColor}
                                scHeaderBool2 = {scHeaderBool2}
                                setStateOfParentSC = {this.setStateOfParentSC}
                                createdBy={createdBy}
                              
                                // setStateOfParent={this.setStateOfParent}
                                // materialRequestData={materialRequestData}
                             
                            >

                            </MaterialRequestDetail>

                        </ModalBody>

                    </Modal>



                    <div>
                    <Modal isOpen={this.state.modal}
                        toggle={() => this.toggle()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggle()}>New Material Request
                        </ModalHeader>
                        <ModalBody>
                            <NewMtlRequest toggle={this.toggle}
                               
                                // supplierList={supplier}
                                // poList={POList}
                                basinList={basinList}
                                afeList={afeList}         
                                siteList={siteList}   
                                // receiverKey={receiverKey}
                                onClickYes={this.newMtlReq}
                                >
                            </NewMtlRequest>
                        </ModalBody>
                    </Modal>
                </div>

                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>

        </div>)
        
    }
    setStateofParentHeaderColor=(newTitle)=>{
        this.setState({headerColorBool: newTitle})
    }
    setStateOfParentSC=(newTitle)=>{
        this.setState({scHeaderBool2: newTitle})
    }

    getMtlReqHeaderColor = (mtlReqData) => {
       const {mtlRequestKey, headerColorBool} = this.state
       if (headerColorBool){
        return "modal-header primary"
    }

        if (mtlReqData!=null){

            if (mtlRequestKey === -1){
               return  "modal-header primary"

            }
            

            else {
                const statusKey = mtlReqData.statusKey

                if (statusKey===3) {
                    return "modal-header submit"
                }
    
               else if (statusKey===4 || statusKey===6) {
                    return "modal-header approve"
                }
                else if (statusKey===1 || statusKey===5) {
                    return "modal-header reject"
                }
                // else if (statusKey===5) {
                //     return "modal-header reject"
                // }
                else{
                    return "modal-header primary"
                }
    
            }
     
        }else {return ""}
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");
            
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";          
            
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
        
            content.appendChild(link);
        
        }
        else {
            link = content.children[0];

            if(item.statusKey === 3){
                content.style.background = "#f0f5b0";
            }
            else if (item.statusKey === 4){
                content.style.background = "#b8f2b1";
            }
            
            else if (item.statusKey===2){
                content.style.background = "#05e1fa";
            }

            else if (item.statusKey===6){
                content.style.background = "#61a066";
            }
            
            else if (item.statusKey===1 || item.statusKey===5){
                content.style.background = "#ad5f55";
            }else {
                content.style.background = "transparent";
            }
        
        }
 

        switch(s.field){
            case 'mtlRequestNo':
                link.textContent = item.mtlRequestNo;
                break;
            case 'mtrReqStatusDesc':
                link.textContent = item.mtrReqStatusDesc;
                break;
            case 'projectID':
                link.textContent = item.projectID;
                break;
                case 'spendCategoryDesc':
                    link.textContent = item.spendCategoryDesc;
                    break; 
            // case 'receivedDate':
            //     var date = new Date(item.receivedDate);                
            //     link.textContent = date.toLocaleDateString();
            //     break;
            case 'woNumber':
                link.textContent = item.woNumber;
                break;     
            case 'requestTypeDesc':
                link.textContent = item.requestTypeDesc;
                break;    
                
                case 'siteDesc':
                    link.textContent = item.siteDesc;
                    break;    
                       
              case 'basin':
                    link.textContent = item.basin;
                    break;   
                case 'createdBy':
                    link.textContent = item.createdBy.replace(/\./g, " ");
                    break;
                
                    case 'createdDateTime':
                        var date = new Date(item.createdDateTime);                
                        link.textContent = date.toLocaleDateString();  
                     
                    break;

                    case 'comments':
                        link.textContent = item.comments;
                        break;               

            default:

        }        
        
    }
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");
    
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
             content.style.width = "100%";
    
            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }
    
        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
        // link.textContent = new Date(item.receivedDate).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "MaterialRequest");
    }
}