//Ready for to Push to Master from local Master 10/14/21

import React, { Component, useEffect, useState} from 'react';
import {Col, Row, Button, FormGroup, Label} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';

import {apiURL, apiHeader, apiPOSTHeader, apiDELETEHeader} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';
import RefreshIcon from '../resources/refresh.svg';
import PlusIcon from '../resources/plus.svg';
import TrashIcon from '../resources/trash.svg';
import ExcelIcon from '../resources/excel.svg';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from '../operation/ExcelUtility';
import UserDetails from './UserDetails'
import { ColoredLine, getFirstDayofMonth,useWindowDimensions } from '../resources/utility';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'
import { ServerRequestParameters } from 'msal/lib-commonjs/ServerRequestParameters';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

const UserUserGroup = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [users, setUsers] = useState([]) 
    const [userGroupEmployeeLinkKey, setUserGroupEmployeeLinkKey] = useState([])
    const [user, setUser]= useState(props.userInput)
    const [modalUserGroup, setModalUserGroup] = useState(false)
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]

     
    function toggleModalUserGroup(){  
        // console.log("Modal: " + this.state.modal)
        // this.setState({modalUserGroup: !this.state.modalUserGroup})
        setModalUserGroup(!modalUserGroup)
    }

    //  function toggleModalUserGroup() {  
    //     // console.log("Modal: " + this.state.modal)
    //     // this.setState({modalUser: !this.state.modalUser})
    //     // setModalUser(!modalUser);
    //     props.toggleModalUserGroup()
    // }

   function addEmployeeUserGroupLink(){
        // let newGroup = this.createUserGroupEmployeeLinkForm()
        // this.setState({groupDetails:newGroup})
         
        toggleModalUserGroup();
    }



    const deleteUserGroupFromEmployee = () => {   
        // console.log("Save user in user details")
        const deleteUserGroupFromEmployeeDTO= deleteUserGroupEmployeeSelect()
        // console.log(deleteUserGroupFromEmployeeDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/Employee'
          fetch(myURI , apiDELETEHeader(deleteUserGroupFromEmployeeDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
              refreshUserData()
            //   console.log("No error here: " + rspData)
            })
            .catch(error =>{
                refreshUserData()
            //   console.log("PUT Error: " + error);
            })       
    }

    const deleteUserGroupEmployeeSelect=()=>{
        var tempDTO=[];
        var i=0;
        userGroupEmployeeLinkKey.map(function (line) {
           const userGroupFromEmployeeDelete = {
               "userGroupEmployeeLinkKey": line,
               "deletedBy": getUserADAccount(user.account.userName) 
   
           }
           tempDTO[i] = userGroupFromEmployeeDelete ;
           i = i + 1
        });
       
       return tempDTO;
   }


    
   function onGridRowSelected(s, e) {
    // console.log(" On CLick")
    //const content = e.content;
    // if (e.addedKeys.count > 0) {
        // let clickedRow = e.addedKeys.item(0) 
        // let dataItem = clickedRow.value[0] 
        // console.log(dataItem)
        
        // if (!this.state.hideAssociateDetails){
            // this.setState({userGroupKey: dataItem.userGroupKey,
            //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
        // }

        const tempList=[] 
        let clickedRows = e.currentKeys
        
        for (let i = 0; i < e.currentKeys.count; i++){
        //    console.log(e.currentKeys.item(i).value[0] )
            tempList.push(e.currentKeys.item(i).value[0].userGroupEmployeeLinkKey) ; 
        }
        
        // selectedUserGroupChanged(dataItem.employeeKey)
        // console.log(tempList)
        setUserGroupEmployeeLinkKey(tempList)
        // setEmployeeKey(tempList);
       
        // console.log("hello")
    // }
       
}

function refreshUserData(){
    const myURL = apiURL + 'SecAdmin/User/' + props.UserGroupKey + '/UserGroup'
    // console.log("UserUserGroup ")       
    // console.log(myURL)

    fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            setIsLoaded(true);
            setUsers(json);
        })
        
     }

     
   

    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/User/' + props.UserGroupKey + '/UserGroup'
    //  console.log("UserUserGroup ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setUsers(json);
         })
         .catch(error => {
            console.log("GET Error: " + error);
        })
     },[props.UserGroupKey])
 
     if (!isLoaded){
         return(<div>
             <h4> loading users list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>
            <div>
                                 <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={refreshUserData}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>

                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={addEmployeeUserGroupLink}
                                >
                                    <img
                                    src={PlusIcon}
                                    alt="Link"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Link
                                </Button>
                    
                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={deleteUserGroupFromEmployee}
                                >
                                    <img
                                    src={TrashIcon}
                                    alt="Unlink"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Unlink
                                </Button> */}
                     <button className="btn-no-border">           
                        <ConfirmModal outline={true}
                                //   color="danger"
                                  className="btn-no-border"
                                  buttonLabel="Unlink"
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected user group?"
                                  onClickYes={deleteUserGroupFromEmployee}
                                  image={<img
                                    src={TrashIcon}
                                   // id={facilityOilTicketKey}
                                    
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}}/>}
                                    >
                        </ConfirmModal>    
                    </button>
                   
                                
{/* 
                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={saveWorkbook}
                                >
                                    <img
                                    src={ExcelIcon}
                                    alt="Export To Excel"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Export To Excel
                                </Button>  */}

                                </div>
     
                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="300px"
                         //width="Calc(100% - 5px)"
                         //width="100%"
                         dataSource={users}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         selectedKeysChanged={onGridRowSelected}
                         >         
 
                         <IgrTextColumn field="userGroupName" headerText="Group Name" width="*>140" isEditable = "false" />                              
                         
                         <IgrTextColumn field="userGroupDesc" headerText="Description" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="empName" headerText="Employee Name" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="emailAddress" headerText="Email Address" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="adAccount" headerText="AD account" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="deptID" headerText="Department ID" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="empStatusDesc" headerText="Employee Status Description" width="*>100" isEditable = "false" />
 
                         <IgrTextColumn field="locationName" headerText="Location Name" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="memo" headerText="Memo" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="azureAD" headerText="Azure AD" width="*>100" isEditable = "false" />

                         {/* <IgrTextColumn field="managerKey" headerText="Azure AD" width="*>100" isEditable = "false" /> */}
 
                         <IgrTextColumn field="userGroupKey" isHidden  />     

                         <IgrTextColumn field="userGroupEmployeeLinkKey" isHidden />           

                         <IgrTextColumn field="employeeKey" isHidden />               
                
                     </IgrDataGrid>

                     
                 </FormGroup>

                 <Modal isOpen={modalUserGroup} toggle={() => toggleModalUserGroup()} 
                       size="xl">
                    <ModalHeader toggle={() => toggleModalUserGroup()}>User Group Details</ModalHeader>
                    <ModalBody>
                        <UserGroupList 
                                        toggle={toggleModalUserGroup}
                                        // userGroupDetails={userGroupDetails}
                                        employeeKey = {props.UserGroupKey}
                                        // toggleModalUserGroup = {this.toggleModalUserGroup}
                                        userInput ={user}
                                        refreshData={refreshUserData}
                                        >
                         
                        </UserGroupList>
                       
                    </ModalBody>
                </Modal>
     </div>)

 }

 const UserSecCheckPoint = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [users, setUsers] = useState([]) 
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]
    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/User/' + props.UserGroupKey + '/SecCheckPoint'
    //  console.log("UserSecCheckPoint Test ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setUsers(json);
         })
         .catch(error => {
            console.log("GET Error: " + error);
        })
     },[props.UserGroupKey])
 
     if (!isLoaded){
         return(<div>
             <h4> loading users list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>

                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="600px"
                         //width="Calc(100% - 5px)"
                         //width="100%"
                         dataSource={users}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         //selectedKeysChanged={this.onGridRowSelected}
                         >  

                         <IgrTextColumn field="appSecurityNo" headerText="Application Security No" width="*>140" isEditable = "false" />         
 
                         <IgrTextColumn field="appSecurityName" headerText="Application Security Name" width="*>140" isEditable = "false" />                              
                         
                         <IgrTextColumn field="appSecurityDesc" headerText="Description" width="*>100" isEditable = "false" />
 
                         <IgrTextColumn field="applicationName" headerText="Application Name" width="*>200" isEditable = "false" />                                                            
 
                         <IgrTextColumn field="applicationDesc" headerText="Application Description" width="*>150" isEditable = "false"/>    
 
                         <IgrTextColumn field="applicationKey" isHidden/> 

                         <IgrTextColumn field="applicationSecurityUserGroupLinkKey" isHidden/> 

                         <IgrTextColumn field="applicationKeySecurityKey" isHidden/>   

                         <IgrTextColumn field="userGroupKey" isHidden/>  

                         <IgrTextColumn field="createdDateTime" headerText="Created Date"  width="*>140" isEditable = "false"/>

                         <IgrTextColumn field="createdBy" headerText="Created By"  width="*>150" isEditable = "false"/>  
                         
                                                             
                
                     </IgrDataGrid>
                 </FormGroup>
     </div>)
 }

 const UserFacility = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [users, setUsers] = useState([]) 
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]
    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/User/' + props.UserGroupKey + '/Facility'
    //  console.log("UserFacility Test ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setUsers(json);
         })
        //  .catch(error => {
        //     console.log("GET Error: " + error);
        // })
        .catch(err => {
             const mute = err 
        })
     },[props.UserGroupKey])
 
     if (!isLoaded){
         return(<div>
             <h4> loading facility list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>
                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="600px"
                         //width="Calc(100% - 5px)"
                         //width="100%"
                         dataSource={users}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         //selectedKeysChanged={this.onGridRowSelected}
                         >         
 
                         <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>140" isEditable = "false" />                              
                         
                         <IgrTextColumn field="facilityTypeDesc" headerText="Facility Type Description" width="*>140" isEditable = "false" />
 
                         <IgrTextColumn field="active" headerText="Status" width="*>100" isEditable = "false" />                                                            
 
                         <IgrTextColumn field="locationID" headerText="Location ID" width="*>150" isEditable = "false"/>    

                         <IgrTextColumn field="locationName" headerText="Location Name" width="*>150" isEditable = "false"/> 

                         <IgrTextColumn field="areaName" headerText="Area Name" width="*>150" isEditable = "false"/> 

                         <IgrTextColumn field="subAreaName" headerText="Sub Area Name" width="*>150" isEditable = "false"/> 

                         <IgrTextColumn field="subSystem" headerText="Sub System" width="*>150"/> 

                         <IgrTextColumn field="subSystemDesc" headerText="Sub System Description" width="*>150" isEditable = "false"/> 


                         <IgrTextColumn field="locationKey"  isHidden /> 

                         <IgrTextColumn field="subSystemKey"  isHidden /> 

                         <IgrTextColumn field="facilitySubAreaKey"  isHidden /> 

                         <IgrTextColumn field="facilityAreaKey"  isHidden /> 

                       
       
                     </IgrDataGrid>
                 </FormGroup>
     </div>)
 }

 

const UserGroupList = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [userGroupList, setUserGroupList] = useState([]) 
    const [userGroupKey, setUserGroupKey] = useState([])
    const [employeeKey, setEmployeeKey] = useState(props.employeeKey)
    const [users, setUsers] = useState([]) 
    const [user, setUser]= useState(props.userInput)
    // const [refreshData, setRefreshData] = useState(props.refreshData)
  
    const closeForm=(refreshData)=>{
        if(refreshData){
           props.refreshData()
        }
        props.toggle();
       
    }
    const updateEmployeeGroup = () => {   
    
        // console.log("Save user in user details")
        const updateEmployeeGroupDTO= createUserGroupEmployeeLinkForm()
        // console.log(updateEmployeeGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/Employee'
          fetch(myURI , apiPOSTHeader(updateEmployeeGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
            //   console.log("No error here: " + rspData)
              refreshUserData()
              
              closeForm(true);
              
            })
            .catch(error =>{
            //   console.log("PUT Error: " + error);
            })       
    }

    const createUserGroupEmployeeLinkForm=()=>{
        var tempDTO=[];
        var i=0;
        userGroupKey.map(function (line) {
           const userGroupCreate = {
               "userGroupKey": line,
               "employeeKey": employeeKey,
               "linkBy": getUserADAccount(user.account.userName) 
   
           }
           tempDTO[i] = userGroupCreate;
           i = i + 1
        });
       
       return tempDTO;
   }

  

   function onGridRowSelected(s, e) {
    // console.log(" On CLick")
    //const content = e.content;
    // if (e.addedKeys.count > 0) {
        // let clickedRow = e.addedKeys.item(0) 
        // let dataItem = clickedRow.value[0] 
        // console.log(dataItem)
        
        // if (!this.state.hideAssociateDetails){
            // this.setState({userGroupKey: dataItem.userGroupKey,
            //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
        // }

        const tempList=[] 
        let clickedRows = e.currentKeys
        
        for (let i = 0; i < e.currentKeys.count; i++){
        //    console.log(e.currentKeys.item(i).value[0] )
            tempList.push(e.currentKeys.item(i).value[0].userGroupKey) ; 
        }
        
        // selectedUserGroupChanged(dataItem.employeeKey)
        // console.log(tempList)
        setUserGroupKey(tempList)
        
        // setEmployeeKey(tempList);
       
        // console.log("hello")
    // }
       
}

function refreshUserData(){
    
    const myURL = apiURL + 'SecAdmin/User/' + props.UserGroupKey + '/UserGroup'
    // console.log("UserGroupUsers ")       
    // console.log(myURL)

    fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            setIsLoaded(true);
            setUserGroupList(json);
            
        })
        console.log(myURL)
        
     }
     





  
    useEffect(() =>{
        
        const myURL = apiURL + 'SecAdmin/UserGroup'
        // console.log("UserGroupUsers ")       
        // console.log(myURL)
    
        fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                setIsLoaded(true);
                setUserGroupList(json);
                
            })
        },[props.employeeKey]) 

        if (!isLoaded){
            return(<div>
                <h4> loading UserList list ....</h4>
            </div>)
        }else
        return(<div>
             <FormGroup>
                <IgrDataGrid                        
                        // ref={this.onGridRef}
                        height="550px"
                        //width="Calc(100% - 5px)"
                        //width="100%"
                        dataSource={userGroupList}
                        autoGenerateColumns="false"                                   
                        selectionMode="MultipleRow"
                        selectedKeysChanged={onGridRowSelected}
                        >         

                        <IgrTextColumn field="userGroupName" headerText="Group Name" width="*>140" />                              
                        
                        <IgrTextColumn field="userGroupDesc" headerText="Description" width="*>250" />

                        <IgrTextColumn field="active" headerText="Active" width="100" 
                        // cellUpdating={this.onActiveUpdating}
                        />                                

                        <IgrTextColumn field="createdBy" headerText="Created By" width="*>150"/>    

                        <IgrTextColumn field="createdDatetime" headerText="Created Date" width="*>140"                                           
                                            // cellUpdating={this.onReportDateUpdating}
                                            />                               
                        
                        <IgrTextColumn field="modifiedBy" headerText="Modified By" width="*>150" />                                   
                        
                        <IgrTextColumn field="modifiedDateTime" headerText="Modified Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            />
                       
                        <IgrNumericColumn field="userGroupKey" isHidden/>
                    </IgrDataGrid>
                    
                    <div>
                    <Button color="primary" className="floatButtonCancel"
                            // style={{margin:"0px 10px 10px px"}} 
                             onClick={() => closeForm(true)}
                             >Cancel</Button>
                    </div>
                        <Button color="primary"className="floatButtonAdd"
                            //  style={{margin:"10px 10px 0px 0px"}}
                             onClick={()=> updateEmployeeGroup()}      
                                                   
                             >Add</Button>
                </FormGroup>
        </div>)
}


export default class UserAdmin extends Component {
    constructor (props){
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        
        this.state = {
            user:[],
            isLoaded:false,
            employeeKey:-1,
            employeeDetails:null,
            
            windowHeight:window.innerHeight,
            windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
            activeTab:'1',
            modal:false,
            userGroupDetails:false,
            user:this.props.user,
            empName:'',
            hireDate: new Date().toISOString().substring(0,10),
            hideAssociateDetails:((typeof(props.HideAssociateDetails) ==='undefined' || props.HideAssociateDetails===null) ? false : props.HideAssociateDetails),
         
        }
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }
  


    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.user;
    }

    selectedUserGroupChanged = (employeeKey) => {
        // if ((typeof(this.props.SelectedUserGroupChanged) !=='undefined' && this.props.SelectedUserGroupChanged!==null)){
        //     this.props.SelectedUserGroupChanged(userGroupKey)
        // }

        this.setState({employeeKey:employeeKey})
                    
    } 

    onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            // console.log("dataItem")
            
            // console.log(dataItem)
            
            if (!this.state.hideAssociateDetails){
                this.setState({employeeKey: dataItem.employeeKey,
                    userGroupName: dataItem.userGroupName,
                    employeeDetails: dataItem})      
            }

            // const tempList=[]
            // //let clickedRows = e.currentKeys
            
            // for (let i = 0; i < e.currentKeys.count; i++){
            //    // console.log(e.currentKeys.item(i).value[0] )
            //     tempList.push(e.currentKeys.item(i).value[0].employeeKey); 
            // }
            
            this.selectedUserGroupChanged(dataItem.employeeKey)
            // console.log("hello");
        } 
    }
    
    toggleModal = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
    }

    toggleModalUserGroup = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modalUserGroup: !this.state.modalUserGroup})
    }

    refreshUserData =()=>{
        this.getUserData();
        // console.log("this is a test");
    }

    

    getUserData = () =>{
        const {user}=this.state;
        let myURI = apiURL + 'SecAdmin/User' 
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                user: json
            })
        })
        .catch(error => {
            console.log("GET Error: " + error);
        })
        
    }

    handleResize = (e) => {
   
        if (document.getElementById('mainDiv') !== null){
            this.setState({ windowHeight: window.innerHeight,
                windowWidth: document.getElementById('mainDiv').offsetWidth });

        }
    };

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getUserData();
        this.refreshUserData();
        //this.selectedFacilityChanged(this.state.facilityKeys)
    }


    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    deleteUser = () =>{

    } 
    
    editUser = () => {
        
        // console.log("In edit  log")
        //this.getUserData()
        this.toggleModal()
       
    }
    

    

    createUserForm(){
        const {user} = this.props
        const {empDetails} = this.state
        const employeeCreate = {
        "employeeKey":-1,
        "wbrEmpID": null,
        "compObjectKey": 0,
        "deptKey": null,
        "firstName": "",
        "lastName": "",
        "commonName": "",
        "locationName": "",
        "empPosition": "",
        "empType": "",
        "emailAddress": "",
        "adAccount": "",
        "adDomain": "WBR",
        "statusID": "Active",
        "memo": "",
        "phoneNo": "",
        "azureAD": "",
        "empName": "",
        "managerKey": null,
        // "hireDate": new Date().toISOString().substring(0,10),
        "hireDate": new Date().toISOString(),
        "createdBy": getUserADAccount(user.account.userName) 
        }
        return employeeCreate 
    } 

    addUser = () => {
        let newUser=this.createUserForm()
        this.setState({employeeDetails:newUser})
        this.toggleModal()

        console.log(newUser)
    }
    handlehireDateChange = (v,f) => {
        this.setState({
            hireDate:v
        })
    }

    render(){
        const {employeeKey,isLoaded, hideAssociateDetails,user, 
            windowHeight,userGroupKey,activeTab, employeeDetails, userGroupDetails} = this.state
     const gridHeight=(windowHeight * (hideAssociateDetails ? 0.8: 0.4)) + "px"
     const searchText = window.$searchText.toLowerCase()

     if (!isLoaded){
        return(<div>
            <h3>Loading User list ...... </h3>
        </div>)
    }
        return (
            <div>
                 <FormGroup>
                    <Row form>
                        <h2> User |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.refreshUserData}>
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.addUser}>
                            <img 
                            src={PlusIcon}
                            alt="Add"
                            style={{width:"25px", height:"25px", margin:"0 0 0 150"}}/>
                            Add
                        </Button>{' '}
                  
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.editUser}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"25px", height:"25px", margin:"0 0 0 0"}}/>
                            Edit
                        </Button>{' '}
                   

                        {/* <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.deleteUser}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Delete
                        </Button>{' '} */}


                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={ExcelIcon}
                            alt="excel"
                            style={{width:"25px", height:"25px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                       
                            
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>
                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}
                        //width="Calc(100% - 5px)"
                        //width="100%"
                        dataSource={user.filter(rec => ((rec.wbrEmpID || '').toLowerCase().includes(searchText)) ||
                                                (rec.empName || '').toLowerCase().includes(searchText) ||
                                                (rec.empStatusDesc || '').toLowerCase().includes(searchText) ||
                                                (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                                                (rec.azureAD || '').toLowerCase().includes(searchText) ||
                                                (rec.locationName || '').toLowerCase().includes(searchText) ||                                                            
                                                (rec.managerNameLvl1 || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        
                        selectedKeysChanged={this.onGridRowSelected}>         


                        <IgrTextColumn field="wbrEmpID" headerText="ID" width="*>140" isEditable = "false" />   
                        <IgrTextColumn field="empName" headerText="Name" width="*>140" isEditable = "false"/>                              
                        
                        <IgrTextColumn field="empStatusDesc" headerText="Status" width="*>100" isEditable = "false" />

                        <IgrTextColumn field="emailAddress" headerText="Email" width="*>200"isEditable = "false" />                                
                        <IgrTextColumn field="azureAD" headerText="AzureAD" width="*>200"isEditable = "false" />                                

                        <IgrTextColumn field="locationName" headerText="Location" width="*>150" isEditable = "false" />    

                        <IgrTemplateColumn field="hireDate" headerText="Hired Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}
                                            isEditable = "false"
                                            />                               
                        
                        <IgrTextColumn field="modifiedBy" headerText="Modified By" width="*>150" isEditable = "false" />                                   
                        
                        <IgrTextColumn field="modifiedDateTime" headerText="Modified Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            isEditable = "false"
                                            />
                         <IgrTextColumn field="lastLoginDateTime" headerText="Last Login Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            isEditable = "false"
                                            />
                        <IgrTextColumn field="managerNameLvl1" headerText="Manager Level 1" width="*>150" isEditable = "false" />       
                        <IgrTextColumn field="managerNameLvl2" headerText="Manager Level 2" width="*>150" isEditable = "false" />       
                        <IgrTextColumn field="managerNameLvl3" headerText="Manager Level 3" width="*>150" isEditable = "false" />       
                        <IgrTextColumn field="managerNameLvl4" headerText="Manager Level 5" width="*>150" isEditable = "false" />       
                        <IgrTextColumn field="managerNameLvl5" headerText="Manager Level 5" width="*>150" isEditable = "false" />       

                        <IgrNumericColumn field="employeeKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>

                <FormGroup hidden={hideAssociateDetails}> 
                <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '1'})}
                                      onClick={() => {this.toggle('1')}}>
                                <h5>UserGroup</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({active: activeTab === '2'})}
                                      onClick={() => {this.toggle('2')}}>
                                <h5>Application Security</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({active: activeTab === '3'})}
                                      onClick={() => {this.toggle('3')}}>
                                <h5>Facility</h5>
                            </NavLink>
                        </NavItem>
                        {/* <NavItem >
                            <NavLink className={classnames({active: activeTab === '4'})}
                                      onClick={() => {this.toggle('4')}}>
                                <h5>Sub System</h5>
                            </NavLink>
                        </NavItem>                         */}
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">                                                        
                                {/* <h3> User Group</h3> */}
                                <UserUserGroup UserGroupKey = {employeeKey} 
                                userInput ={this.props.user} 
                                toggleModalUserGroup = {this.toggleModalUserGroup} 
                                onGridRef = { this.onGridRef} >

                                </UserUserGroup>
                        </TabPane>

                        <TabPane tabId="2">
                            {/* <TankVolume VoldHeaderLabel=""/>                                 */}
                            <UserSecCheckPoint UserGroupKey = {employeeKey} userInput ={this.props.user} >
                            </UserSecCheckPoint>
                            
                        </TabPane>

                        <TabPane tabId="3">
                            {/* <TankVolume VoldHeaderLabel=""/>                                 */}
                            <UserFacility UserGroupKey = {employeeKey} userInput ={this.props.user} >
                            </UserFacility>
                          
                        </TabPane>
                       

                    
                        
                    </TabContent>
                </FormGroup>

                <Modal isOpen={this.state.modal} 
                       toggle={() => this.toggleModal()} 
                       size="lg"
                       onClosed={() => this.getUserData()}>
                    <ModalHeader toggle={() => this.toggleModal()}> Users Details </ModalHeader>
                    <ModalBody>
                    <UserDetails    
                                     user={this.props.user}
                                     toggle={this.toggleModal}
                                     employeeDetails={employeeDetails}
                                     refreshUserData={this.getUserData}
                                     userInput ={this.props.user}
                                     >

                    </UserDetails>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="primary" className="mr-auto">View Ticket Image</Button>
                     
                        <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                        <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                    </ModalFooter> */}
                </Modal>



                {/* <Modal isOpen={this.state.modalUserGroup} toggle={() => this.toggleModalUserGroup()} 
                       size="xl">
                    <ModalHeader toggle={() => this.toggleModalUserGroup()}>User Group Details</ModalHeader>
                    <ModalBody>
                        <UserGroupList 
                                        toggle={this.toggleModalUserGroup}
                                        userGroupDetails={userGroupDetails}
                                        employeeKey = {employeeKey}
                                        toggleModalUserGroup = {this.toggleModalUserGroup}
                                        userInput ={this.props.user}
                                        >
                         
                        </UserGroupList>
                       
                    </ModalBody>
                </Modal> */}


            </div>
        );
    }
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.hireDate).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        // console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "EmployeeList");
    }


}