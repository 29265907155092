


import React, { Component } from 'react';

import '../../custom.css';
import { apiURL } from '../../resources/apiURL';

import { USDCurrencyFormat } from '../../resources/utility';


// data chart's elements for category series:
import { IgrNumericYAxis } from 'igniteui-react-charts';
import { IgrCategoryXAxis } from 'igniteui-react-charts';
import { IgrColumnSeries } from 'igniteui-react-charts';
// data chart's modules:
import { IgrDataChart } from 'igniteui-react-charts';
import { IgrDataChartCoreModule } from 'igniteui-react-charts';
import { IgrDataChartCategoryModule } from 'igniteui-react-charts';
import { IgrDataChartInteractivityModule } from 'igniteui-react-charts';
// legend's modules:
import { IgrLegend, IgrCategoryChart } from 'igniteui-react-charts';
import { IgrLegendModule } from 'igniteui-react-charts';

import { IgrDataChartStackedModule, IgrStackedFragmentSeriesModule } from 'igniteui-react-charts';
import { IgrStackedColumnSeries, IgrStackedFragmentSeries } from 'igniteui-react-charts';
import { IgrChartMouseEventArgs } from 'igniteui-react-charts';

// const mods = [
//     IgrDataGridModule,
//     IgrGridColumnOptionsModule,
//     IgrDataChartInteractivityModule,
//     IgrDataChartCategoryModule,
//     IgrDataChartCoreModule,
//     IgrLegendModule
// ];
// mods.forEach((m) => m.register());

IgrDataChartCoreModule.register();
IgrDataChartCategoryModule.register();
IgrDataChartInteractivityModule.register();
IgrLegendModule.register();
IgrStackedFragmentSeriesModule.register();
IgrDataChartStackedModule.register();
//grChartMouseEventArgs.register();

export default class AFECostSummary extends Component {
    static displayName = AFECostSummary.name;

    constructor(props) {
        super(props)
        //this.onGridRef = this.onGridRef.bind(this);
        this.onChartRef = this.onChartRef.bind(this);
        this.onLegendRef = this.onLegendRef.bind(this);
        this.onSeriesMouseEnter = this.onSeriesMouseEnter.bind(this);
      
        this.onTooltipRender = this.onTooltipRender.bind(this)
            ;
        this.state = {
            afeHeaderKey: props.afeHeaderKey,
            costSummaryChart: [],
            isCostSummaryLoaded: false
        }
    }

    getCostForecastSummary = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/CostSummary/Transpose/' + afeHeaderKey

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    costSummaryChart: this.createCostSummaryChart(json),
                    isCostSummaryLoaded: true
                })
            });
    }

    createCostSummaryChart = (v) => {
        var chartData = []

        v.map(e => {
            const temp = {
                YRMonth: e.monthYrLabel + '',
                general: e.general,
                dc: e.dc,
                facility: e.facility,
                pipeLine: e.pipeLine,
                accounting:e.accounting

            }

            chartData.push(temp)
        })

        console.log("chartData")
        console.log(chartData)

        return chartData
    }

    initSampleData = () => {
        let sampleData = [
            { YRMonth: "202202", General: 400, DC: 100, Gas: 175, Nuclear: 225, Hydro: 350 },
            { YRMonth: "202203", General: 7000, DC: 800, Gas: 350, Nuclear: 400, Hydro: 625 },
            // { YRMonth: "Russia", Coal: 550, Oil: 200, Gas: 250, Nuclear: 475, Hydro: 425 },
            // { YRMonth: "Australia", Coal: 450, Oil: 100, Gas: 150, Nuclear: 175, Hydro: 350 },
            // { YRMonth: "United States", Coal: 800, Oil: 250, Gas: 475, Nuclear: 575, Hydro: 750 },
            // { YRMonth: "France", Coal: 375, Oil: 150, Gas: 350, Nuclear: 275, Hydro: 325 }
        ];

        console.log("samepleData")
        console.log(sampleData)
        this.setState({ costSummaryChart: sampleData })
    }

    onChartRef(chart) {
        if (!chart) { return; }

        this.chart = chart;
        if (this.legend) {
            this.chart.legend = this.legend;
        }
        this.chart.dataSource = this.costSummaryChart
    }

    onLegendRef(legend) {
        if (!legend) { return; }

        this.legend = legend;
        if (this.chart) {
            this.chart.legend = this.legend;
        }
    }

    onSeriesMouseEnter = (s, e) => {
        if (e.series.tooltipTemplate === null ||
            e.series.tooltipTemplate === undefined) {
            e.series.tooltipTemplate = this.onTooltipRender;
        }
    }

    onTooltipRender(context) {
        const { costSummaryChart } = this.state

        const dataContext = context.dataContext;

        if (!dataContext)
            return null;
        const series = dataContext.series;
        if (!series)
            return null;
        const dataItem = dataContext.item;
        if (!dataItem)
            return null;

        const dc = dataItem.dc;
        const facility = dataItem.facility;
        const pipeline = dataItem.pipeLine;
        const general = dataItem.general;
        const accounting = dataItem.accounting;
        
        // console.log("series")
        // console.log(series)
        // console.log("series[0]")
        // console.log(series.contentSeries[0].actualBrush)
        // console.log("item")
        // console.log(dataItem)
        // console.log(facility)
        // console.log(pipeline)
        // console.log(general )

        return (<div>
            <div className="tooltipTitle">{dataItem.YRMonth}</div>
            <div className="tooltipBox">
                {(isNaN(dc) || dc===null || dc===undefined?"":
                <div className="tooltipRow">
                    <div className="tooltipLbl" style={{color:series.contentSeries[0].actualBrush, 
                                                        fontWeight:'bold', 
                                                        fontStyle: 'italic'}} >  {"D & C: "}</div>
                    <div className="tooltipVal">{USDCurrencyFormat(dc,0)}</div>
                </div>)}
                {(isNaN(facility)  || facility===null || facility===undefined?"":<div className="tooltipRow">
                    <div className="tooltipLbl" style={{color:series.contentSeries[1].actualBrush, 
                                                        fontWeight:'bold', 
                                                        fontStyle: 'italic'}}>{"Facility: "}</div>
                    <div className="tooltipVal">{USDCurrencyFormat(facility,0)}</div>
                </div>)}

                {(isNaN(pipeline)  || pipeline===null || pipeline===undefined ?"":<div className="tooltipRow">
                    <div className="tooltipLbl" style={{color:series.contentSeries[2].actualBrush, 
                                                        fontWeight:'bold', 
                                                        fontStyle: 'italic'}}>{"Pipeline: "}</div>
                    <div className="tooltipVal">{USDCurrencyFormat(pipeline,0)}</div>
                </div>)}

                {(isNaN(general)  || general===null || general===undefined ?"":<div className="tooltipRow">
                    <div className="tooltipLbl" style={{color:series.contentSeries[3].actualBrush, 
                                                        fontWeight:'bold', 
                                                        fontStyle: 'italic'}}>{"General: "}</div>
                    <div className="tooltipVal">{USDCurrencyFormat(general,0)}</div>
                </div>)}

                {(isNaN(accounting)  || accounting===null || accounting===undefined ?"":<div className="tooltipRow">
                    <div className="tooltipLbl" style={{color:series.contentSeries[3].actualBrush, 
                                                        fontWeight:'bold', 
                                                        fontStyle: 'italic'}}>{"Accounting: "}</div>
                    <div className="tooltipVal">{USDCurrencyFormat(accounting,0)}</div>
                </div>)}
            </div>
        </div>)

    }

    componentDidMount() {
        //  this.initSampleData()
        this.getCostForecastSummary()
    }

    render() {
        const { isCostSummaryLoaded, costSummaryChart, costSummaryData } = this.state

        // if (!isCostSummaryLoaded) {
        //     return (<h3>Loading Forecast cost summary ......</h3>)
        // } else
        return (<div className="igContainer" style={{ height: "Calc(100% - 0px)" }}>



            <div className="igLegend">
                <IgrLegend ref={this.onLegendRef} orientation="Horizontal" />
            </div>

            <div className="igComponent" style={{ height: "300px" }} >
                <IgrDataChart
                    dataSource={costSummaryChart}
                    ref={this.onChartRef}
                    legend={this.onLegendRef}
                    width="100%" height="100%"
                    chartTitle="AFE Cost Summary"
                    subtitle="Results over a AFE Start and End Date"
                    isHorizontalZoomEnabled={false}
                    isVerticalZoomEnabled={false}
                    seriesMouseEnter={this.onSeriesMouseEnter}
                   
                >

                    <IgrCategoryXAxis name="xAxis"
                        label="YRMonth" />
                    <IgrNumericYAxis
                        name="yAxis"
                        minimumValue={0}
                        abbreviateLargeNumbers="true"

                    />

                    <IgrStackedColumnSeries
                        xAxisName="xAxis"
                        yAxisName="yAxis"
                        showDefaultTooltip="false"
                        name="StackedColumnSeries">
                        <IgrStackedFragmentSeries
                            name="DC"
                            title="D & C"
                            valueMemberPath="dc">
                        </IgrStackedFragmentSeries>
                        <IgrStackedFragmentSeries
                            name="facility"
                            title="Facility"
                            valueMemberPath="facility">
                        </IgrStackedFragmentSeries>
                        <IgrStackedFragmentSeries
                            name="PipeLine"
                            title="Pipeline"
                            valueMemberPath="pipeLine">
                        </IgrStackedFragmentSeries>
                        <IgrStackedFragmentSeries
                            name="general"
                            title="General"
                            valueMemberPath="general">
                        </IgrStackedFragmentSeries>
                        <IgrStackedFragmentSeries
                            name="accounting"
                            title="Accounting"
                            valueMemberPath="accounting">
                        </IgrStackedFragmentSeries>
                    </IgrStackedColumnSeries>
                </IgrDataChart>

            </div>



            {/* 
                <div className="igComponent" style={{ height: "300px" }} >
                    <IgrCategoryChart
                        dataSource={costSummaryChart}
                        ref={this.onChartRef}
                        width="100%" height="100%"
                        chartTitle="AFE Cost Summary"
                        subtitle="Results over a AFE Start and End Date"
                        isHorizontalZoomEnabled={false}
                        isVerticalZoomEnabled={false} >

                        <IgrCategoryXAxis name="xAxis" label="yrMonth" />
                        <IgrNumericYAxis name="yAxis" minimumValue={0} />                    
                    </IgrCategoryChart>

                </div> */}

            {/* <IgrColumnSeries name="series1" title="DC" xAxisName="xAxis"
                            yAxisName="yAxis" valueMemberPath="dc" isHidden/>
                        <IgrColumnSeries name="series2" title="General" xAxisName="xAxis"
                            yAxisName="yAxis" valueMemberPath="general" />
                        <IgrColumnSeries name="series3" title="PipeLine" xAxisName="xAxis"
                            yAxisName="yAxis" valueMemberPath="pipeLine" />
                        <IgrColumnSeries name="series4" title="Facility" xAxisName="xAxis"
                            yAxisName="yAxis" valueMemberPath="facility" /> */}
            {/* <IgrColumnSeries name="series5" title="Oil" xAxisName="xAxis"
                            yAxisName="yAxis" valueMemberPath="Oil" /> */}


        </div>)
    }
}