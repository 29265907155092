//Created By BN 11/1/2021



import React,{Component}  from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {apiURL,apiHeader} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { getUserADAccount } from '../resources/utility'
import RefreshIcon from '../resources/refresh.svg';
import PlusIcon from '../resources/plus.svg';
import TrashIcon from '../resources/trash.svg';
import ExcelIcon from '../resources/excel.svg';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import MeterValidationDetail from './MeterValidationDetail'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class MeterValidation extends Component {
    static displayName=MeterValidation.name;

    constructor(props){
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        // this.onMetValIDCellUpdating  = this.onMetValIDCellUpdating.bind(this);

        const urlPara=this.getURLParameters(props.location)

        this.state = {
            meterVal:[],
            data:[],
            meterValidation:null,
            isLoaded:false,
            isSecurityLoaded: false,
            startDate: (urlPara.startDate === null? getFirstDayofMonth().toISOString() : urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            endDate: (urlPara.endDate === null ? new Date().toISOString():urlPara.endDate),
            modal:false,
            // meterValidationKey:0,
            meterValidationKey:(urlPara.meterValidationKey === null ? -1 : urlPara.meterValidationKey),
            meterValidationActionKey:0,
            windowHeight:window.innerHeight,            
            facilityKeys: urlPara.facilityKeys,
            fromParamStartDate:urlPara.startDate,
            fromParamEndDate:urlPara.endDate,
            statusDesc: urlPara.statusDesc
            
        }

    }

    toggle=()=>{
        this.setState({modal: !this.state.modal})
    }



    
    createMeterValidationForm(){
        const {user} = this.props
        const {grpDetails} = this.state 
        const meterValidationCreate ={
            "meterKey": null,
            "validationType": 0,
            "validationDate": null,
            "latitude": null,
            "longtitude": null,
            "comment": "",
            "createdBy": getUserADAccount(user.account.userName)
        }
        return meterValidationCreate
    }


    
    addMeterValidation = () => {
        let newGroup = this.createMeterValidationForm()
        this.setState({meterValidation:newGroup})
        this.setState({meterValidationKey: -1})    
        this.toggle()
       
    }







    onGridRowSelected(s, e) {
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 



            this.setState({meterValidationKey: dataItem.meterValidationKey,
                            meterValidationActionKey: dataItem.meterValidationActionKey,
                            meterValidation: dataItem,
                            })       
            this.toggle()

           //console.log(dataItem)
        } 

    }


    getURLParameters = (inputPara) => {
        var paraMeters={
            startDate: null ,
            endDate:null ,
            facilityKeys: null,
            statusDesc:null,
            meterValidationKey:null,
            meterVal:null,}
         
        //     var query
        //     query = new URLSearchParams(inputPara)
        //     paraMeters={
        //         startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
        //         endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
        //         statusDesc: query.get('statusDesc'),
        //         meterValidationKey:query.get('key') 
        // }
            
        if ( inputPara!==undefined){   
            var query
            
            query = new URLSearchParams(inputPara.search);            
            

            paraMeters={
                startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
                endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
                statusDesc: query.get('statusDesc'),
                meterValidationKey:query.get('key') 
        }
        
        }
        console.log("inputPara")
        console.log(inputPara)

        console.log( "paraMeters.meterValidationKey" )
        console.log( paraMeters )
        console.log("paraMeters")
        console.log(paraMeters)
        
        return paraMeters
        
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.meterVal;

        
      
    }
    getMeterValidationKeySearch=()=>{
        const {meterValidationKey} = this.state
        console.log("MeterValidationKey: " + meterValidationKey)

        if(meterValidationKey > 0){
            this.toggle()
            this.setState({meterValidationKey: -1})
    
        }
        else{
            console.log("Did not work")
        }
      
           
    
    }

    getSecurity=()=>{
        let myURI = apiURL + 'EmployeeDetails/Checkpoint/'+ this.props.user.account.userName   
        const {security, appSecurityNo, meterVal} = this.state
        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                
                isSecurityLoaded: true,
                security: json,
                isSecurityAdminLogin: (json.filter((list)=> list.appSecurityNo === 14).length > 0 ? false: true),
                isSecurityMeasurementTech: (json.filter((list)=> list.appSecurityNo === 15).length > 0 ? false: true),
                isOtherUser: (json.filter((list)=> list.appSecurityNo === 15 || list.appSecurityNo === 14 ).length == 0 ? true:false)
            })
            console.log(this.state.isSecurityAdminLogin)
            // console.log(this.state.isSecurityMeasurementTech)
          
        })

        } 

        //Added by Hau Nguyen 03/18/2022 to reset the key so the pop up will not open again 
        //after from refresh
    getMeterValidation=(bResetKey=false) =>{
       
        if (bResetKey){
            this.setState({meterValidationKey:-1})
        }

        const {facilityKeys, fromParamStartDate, fromParamEndDate, statusDesc, isSecurityAdminLogin, meterValidationKey} = this.state

        
        let myURI = apiURL + 'MeterValidation/startdate/' + 
                            this.state.startDate.toString().substring(0, 10) + 
                              '/enddate/' + this.state.endDate.toString().substring(0, 10) 
                              + '/AzureAD/' + this.props.user.account.userName;
        
        fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                
                this.setState({
                    isLoaded: true,
                    meterVal: ((isSecurityAdminLogin == false) ? json.filter(rec => ((statusDesc===null ? true : 
                        (rec.statusKey == statusDesc))) &&
                        ((fromParamStartDate===null && fromParamStartDate===null) ? true : 
                         (rec.createdDatetime >= fromParamStartDate && rec.createdDatetime <= fromParamEndDate)))
                    .sort((a, b) => ((a.statusKey==4 ? 0 : a.statusKey ) > (b.statusKey==4 ? 0 : b.statusKey)) ? 1:-1) : json.filter(rec => ((statusDesc===null ? true : 
                        (rec.statusKey == statusDesc))) &&
                        ((fromParamStartDate===null && fromParamStartDate===null) ? true : 
                         (rec.createdDatetime >= fromParamStartDate && rec.createdDatetime <= fromParamEndDate)))
                    .sort((a, b) => ((a.statusKey==2 ? 0 : a.statusKey ) > (b.statusKey==2 ? 0 : b.statusKey)) ? 1:-1) )
                })
                this.getMeterValidationKeySearch()
                
            });
     
           // console.log(myURI)
         
    }

  


    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getSecurity()
        this.getMeterValidation()
        // this.getMeterValidationKeySearch()
       
    }
    handleChange(value,formattedValue){
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
       
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue

        });
       
    }

    getMeterValidationHeaderColor = (meterValidation) => {
       

        if (meterValidation!=null){

        const statusKey = meterValidation.statusKey

       
            if (statusKey===4) {
                return "modal-header submit"
            }
            else if (statusKey===6) {
                return "modal-header approve"
            }
            else if (statusKey===2) {
                return "modal-header reject"
            }
            else if (statusKey===5) {
                return "modal-header reject"
            }
            else{
                return "modal-header primary"
            }

        }else {return ""}
    }


    render(){
        var {isLoaded,meterVal, windowHeight, data, isSecurityLoaded} = this.state;
        const {meterValidation, meterValidationKey, meterValidationActionKey, toggle} = this.state;
        const gridHeight=(windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()
        
      
        if(!isSecurityLoaded){
        return (<div>
            <h3>Loading Security Users .....</h3>
        </div>)}
          
          if (!isLoaded){
              this.getMeterValidation()
            return (<div>
                <h3>Loading Meter Validations .....</h3>
            </div>)
        }
       else
        return(<div>
            <FormGroup>
                    <Row form>
                        <h2> Meters Validation |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getMeterValidation}>
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>
                                {/* <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.addMeterValidation}
                                >
                            <img 
                            src={PlusIcon}
                            alt="new"
                            style={{width:"25px", margin:"0 5px 0 0"}}/>
                            New Meter Validation
                        </Button>{' '} */}
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>
            <FormGroup>
                <Row >
                        <Col md={3}>
                            <Label style={{margin: "0 0 0 0", padding:"0"}}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate} 
                                        onChange={(v,f) => this.handleChange(v,f)} /> 
                        </Col>
                        <Col md={3}>
                            <Label style={{margin: "0 0 0 0", padding:"0"}} >
                                End Date                                
                            </Label>
                            <DatePicker value={this.state.endDate} 
                                        onChange={(v,f) => this.handleEndDateChange(v,f)} /> 
                          
                        </Col>

                    </Row>
            </FormGroup>

            <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}
                        //width="100%"
                        dataSource={meterVal.filter( rec => ((rec.qrCodeValue|| '').toLowerCase().includes(searchText) ||
                                                            (rec.meterName || '').toLowerCase().includes(searchText) ||
                                                            (rec.meterValidationID || '').toLowerCase().includes(searchText) ||
                                                            (rec.modifiedByName || '').toLowerCase().includes(searchText) ||
                                                            (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                                                            (rec.locationName || '').toLowerCase().includes(searchText) ||
                                                            (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                            (rec.areaName || '').toLowerCase().includes(searchText) ||                                                            
                                                            (rec.comment || '').toLowerCase().includes(searchText) ||
                                                            // (rec.sampleCollected || '').toLowerCase().includes(searchText) ||
                                                            // (rec.ableToValidate || '').toLowerCase().includes(searchText) ||
                                                            (rec.modifiedDatetime || '').toLowerCase().includes(searchText) ||
                                                            (rec.validationDate || '').toLowerCase().includes(searchText))
                                                            )}
                        scrollable={true}
                        editMode={0}                                       
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}>   

                        <IgrTemplateColumn field="meterValidationID" headerText="ID" width="*>100" cellUpdating=
                        {this.onStatusCellUpdating}
                        
                         />
                                        
                        
                        <IgrTemplateColumn field="qrCodeValue" headerText="QR Code" width="*>120"
                         cellUpdating=
                         {this.onStatusCellUpdating}
                      
                          />
                        
                        <IgrTemplateColumn field="meterName" headerText="Meter Name" width="*>140"
                         cellUpdating={this.onStatusCellUpdating} 
                         />
                    
                       
                       
                        <IgrTemplateColumn field="statusDesc" headerText="Status" width="*>120"
                         cellUpdating={this.onStatusCellUpdating} 
                         />

                        <IgrTemplateColumn field="validationDate" headerText="Report Date" width="*>140"                                           
                                           
                                            cellUpdating={this.onStatusCellUpdating}
                                            />                        
                        <IgrTemplateColumn field="modifiedByName" headerText="Modified By" width="*>140"
                         cellUpdating={this.onStatusCellUpdating}
                          />
                                     
                        <IgrTemplateColumn field="comment" headerText="Comments" width="*>150" 
                        cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="locationName" headerText="Basin" width="*>150"
                         cellUpdating={this.onStatusCellUpdating} 
                         />
                        <IgrTemplateColumn field="areaName" headerText="Area Name" width="*>130" 
                        cellUpdating={this.onStatusCellUpdating} 
                        />
                        <IgrTemplateColumn field="facilityName" headerText="Facility Name" width="*>150"
                         cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="ableToValidate" headerText="Able to Validate" width="*>160"
                         cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="sampleCollected" headerText="Sample Collected" width="*>170"
                         cellUpdating={this.onStatusCellUpdating} />

                
                        <IgrTemplateColumn field="modifiedDatetime" headerText="Modified Datetime" width="*>200"
                         cellUpdating={this.onStatusCellUpdating} /> 
                        
                        
                        
                    </IgrDataGrid>
                </FormGroup>

                <div>
                <Modal isOpen={this.state.modal} toggle={() => this.toggle()} size="xl"
                scrollable={true}
                >
                    <ModalHeader  className={this.getMeterValidationHeaderColor(meterValidation)}
                                 toggle={() => this.toggle()}> Meter Validation Details </ModalHeader>
                    <ModalBody>

                    <MeterValidationDetail 
                    toggle = {this.toggle}
                    user={this.props.user}
                    refreshData={this.getMeterValidation}
                    meterValidationKey = {meterValidationKey}
                   
                    meterValidation={meterValidation}
                    >

                    </MeterValidationDetail>


                    </ModalBody>
                   
                </Modal>
                </div>

        </div>)
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
       // content.style.backgroundColor = "#4286f4";

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");
            
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";          
            
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        
        }
        else {
            link = content.children[0];
          
            if (item.statusKey==4){
                content.style.background = "#b6a065";
            }
           
            else  if (item.statusKey==6){
                content.style.background = "#61a066";
            }
            
            else if (item.statusKey==2 || item.statusKey==5){
                content.style.background = "#ad5f55";
            }else {
                content.style.background = "transparent";
            }
        
        }
       

        switch(s.field){
            case 'meterName':
                link.textContent = item.meterName;
                break;
            case 'statusDesc':
                link.textContent = item.statusDesc;
                break;
            case 'meterValidationID':
                link.textContent = item.meterValidationID;
                break;
            case 'createdDatetime':
                var date = new Date(item.createdDatetime);                
                link.textContent = date.toLocaleDateString();

                break;
            case 'validationType':
                link.textContent = item.validationType;
                break;
                case 'validationDate':
                    var date = new Date(item.validationDate);                
                    link.textContent = date.toLocaleDateString();
    
                    break;     
            case 'qrCodeValue':
                link.textContent = item.qrCodeValue;
                break;       
            case 'actionRequiredKey':
                link.textContent = item.actionRequiredKey;
                break;          
            case 'actionRequired2Key':
                link.textContent = item.actionRequired2Key;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break; 
            case 'modifiedByName':
                    link.textContent = item.modifiedByName;
                    break; 
            case 'locationName':
                        link.textContent = item.locationName;
                        break;
            case 'facilityName':
                    link.textContent = item.facilityName;
                    break;
            case 'areaName':
                        link.textContent = item.areaName;
                        break;
            case 'comment':
                link.textContent = item.comment;
                break;    
                case 'ableToValidate':
                    link.textContent = item.ableToValidate;
                    break;  
                    
                    case 'sampleCollected':
                        link.textContent = item.sampleCollected;
                        break;  

                            case 'modifiedDatetime':
                                var date = new Date(item.modifiedDatetime);  
                                link.textContent = date.toLocaleDateString();
                                break;  
            default:

        }        
        
    }

    

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        
        link.textContent = new Date(item.validationDate).toLocaleDateString("es-PA");
    }
    newMeterValidation=()=>{
        this.setState({meterValidationKey: -1})

        this.toggle()
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        
        let gridDts = this.grid.dataSource
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText 
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                           
            }
        }

        ExcelUtility.save(wb, "MeterValidation");
    }

}