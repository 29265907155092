import React, { Component, useState } from 'react';
import { apiURL, apiPOSTHeader} from '../resources/apiURL';

import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount} from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ReceiverDetail from './ReceiverDetail'
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");

const NewReceiver = (props) => {

    const { poList, supplierList,basinList, POListDefault } = props

    const [supplier, setSupplier] = useState(supplierList)
    const [poListDefault, setPOListDefault] = useState(poList)
    const [poNo, setPONo] = useState(poList)
    const [selBasin,setSelBasin] = useState(-1)
    const [selSupplier, setSelSupplier] = useState("")
    const [selPO, setSelPO] = useState(poList)
    const [basinVal, setBasinVal] = useState(true)
    const [supplierVal, setSupplierVal] = useState(true)
    const [poNoVal, setPoNoVal] = useState(true)

 


    const getSupplierPOs = (v) => {

        if (v !== null && v !== "" && selBasin !== -1 ) {
            console.log(v)
            console.log(selBasin)
            console.log(poList.filter(e => e.supplierID === v && e.locationKey === selBasin))
            console.log("Does this hit")
            setPOListDefault(poList.filter(e => e.supplierID === v && e.locationKey === selBasin))
        }
        else if (selBasin === -1 || selBasin == null){
            console.log("This should hit")
            console.log(poList.filter(e => e.supplierID === v))
            setPOListDefault(poList.filter(e => e.supplierID === v))
        }
        else {
            // console("return all")
            // setSelPO((poList.filter(e => e.locationKey === selBasin)))
            console.log(poList.filter(e => Number(e.locationKey) === selBasin))
            console.log("This hits for sure")
            setPOListDefault(poList.filter(e => e.locationKey === selBasin))
        }
    }



    const onTextChange = (fieldName, fieldValue, e) => {
        switch (fieldName) {
            case "basin":
                if (e !== null){
                    setSelBasin(e.value)
                    setPOListDefault(poList.filter(e => Number(e.locationKey) === fieldValue.locationKey))
                    setBasinVal(fieldValue !=null)
                    setSelPO(null)
                    setSelSupplier("")
                }else{
                    setPOListDefault(poList)
                    setSelBasin(-1)
                    setSelPO(null)
                    setSelSupplier("")
                }
              
                break;
            case "supplier":

                if (e !== null) {

                    setSelSupplier(e.value)
                    getSupplierPOs(e.value)
                    setSupplierVal(fieldValue != null)
                    setPoNoVal(fieldValue != null)
                    
                } else {
                    setSelPO(null)
                    setSelSupplier("")
                    getSupplierPOs(null)
                }

                break;
            case "poNo":
                if (e !== null) {
                    if(selBasin === -1 || selBasin == null && selSupplier === "" || selSupplier == null){
                        setPOListDefault(POListDefault)
                    }
                    setSelPO(e.value)
                    setSelSupplier(e.supplierID)
                    setSelBasin(e.locationKey)
                    setPoNoVal(fieldValue != null)
                    setSupplierVal (fieldValue != null)
                } else {
                    setSelPO("")
                    setSelSupplier("")
                }

                break;
            default:
        }
    }

    const saveData = () => {
        var cont = true;
        if(selBasin === -1 || selBasin ==null){
            setBasinVal(false)
            cont = false
        }
        if((selSupplier === "" || selSupplier === -1 || selSupplier === null || selSupplier === false) && (selPO === "" ||  selPO === -1 || selPO === null || selPO === false)){
            setSupplierVal(false)
            setPoNoVal(false)
            cont = false
        }
        if (cont){
        props.onClickYes(selSupplier, selPO, selBasin)
        props.setStateOfParent2(0)
        props.setStateOfParent3("MISC")
        }
    }

    
    const getSelBasin = (v) => {

        if (v !== null && v !== "") {
            return basinList.filter(e => e.locationKey === v)
        }
        return ""
    }

    const getSelSupplier = (v) => {

        if (v !== null && v !== "") {
            return supplier.filter(e => e.supplier_ID === v)
        }
        return ""
    }

    const getSelPO = (v) => {
        if (v !== null && v !== "") {
            return poList.filter(e => e.purchase_Order === v)
        }
        return ""
    }

    return (<div>
          <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                Basin
            </Label>
            <Col sm={9}>
                <Select options={basinList}
                    value={getSelBasin(selBasin)}
                    className={!basinVal ? "form-error" : ""}
                    onChange={(e) => onTextChange("basin", e, e)}
                    isClearable={true}>

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="supplier"
                sm={3}
            >
                Supplier
            </Label>
            <Col sm={9}>
                <Select options={supplier}
                    value={getSelSupplier(selSupplier)}
                    className={!supplierVal ? "form-error" : ""}
                    onChange={(e) => onTextChange("supplier", e, e)}
                    isClearable={true}>

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="poNo"
                sm={3}
            >
                Purchase Order
            </Label>
            <Col sm={9}>
                <Select 
                options={poListDefault}
                // options={poNoList}
                    className={!poNoVal ? "form-error" : ""}
                    value={getSelPO(selPO)}
                    onChange={(e) => onTextChange("poNo", e, e)}
                    isClearable={true}>
                </Select>

            </Col>
        </FormGroup>


        <div className="float-right">
            <Button color="primary" className="float-right"
                onClick={() => props.toggle()}>Cancel</Button>
            <Button color="primary" className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}
                onClick={() => saveData()}>OK</Button>
        </div>

    </div>)
}



export default class Receiver extends Component {
    static displayName = Receiver.name;

    constructor(props) {
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);

        
        this.state = {
            statusKeyBool:false,
            headerColorBool:false,
            statusKey: null,
            rcvrTypeID: null,
            toLocationKey:null,
            receiverStatusData: [],
            poNumber:"",
            receiverData: [],
            isLoaded: [],
            receiverKey: 0,
            selBasinKey: -1,
            windowHeight: window.innerHeight,
            modal: false,
            modalRcvDetails: false,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            isReceiverAdmin: false,
            isWHUser: false,
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            supplier: [],
            POList: [],
            POListDefault:[],
            basinList:[]
     
        }
    }

    setStateOfParent3=(newTitle)=>{
        this.setState({rcvrTypeID: newTitle})
    }
    onGridRowSelected(s, e) {
        const {statusKey} = this.state
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({ 
                            receiverStatusData: dataItem,
                            receiverKey: dataItem.receiverKey,
                            selBasinKey:dataItem.locationKey,
                            poNumber: dataItem.poNo,
                            toLocationKey: dataItem.toLocationKey,
                            rcvrTypeID: dataItem.rcvrTypeID,
                            statusKey: dataItem.statusKey
                        })
                        console.log(dataItem)
                        console.log(statusKey)
            this.toggleRcvDeails()
           
        }
        
    }
    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.receiverData;
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }


    toggleRcvDeails = () => {
        this.setState({ modalRcvDetails: !this.state.modalRcvDetails })
    }


    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }


    getPO = () => {
        let myURI = apiURL + 'SupplyChain/PO'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    POList: this.createPOList(json),
                    POListDefault: this.createPOList(json)
                })
            });
    }

    createPOList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.purchase_Order
            e.label = e.purchase_Order
            tempList.push(e)
        })

        return tempList
    }

    getBasin = () =>{
        
        let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json)
                })
            });
    }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }

    getSupplier = () => {
        let myURI = apiURL + 'SupplyChain/Supplier'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    supplier: this.createSupList(json)
                })
            });
    }

    createSupList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.supplier_ID
            e.label = e.alternate_Name
            tempList.push(e)
        })

        return tempList
    }

    getReceiver = () => {

        let myURI = apiURL + 'SupplyChain/Receiver/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName;

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    receiverData: json
                })
               

            });

        
    }



    newSupplierPOSelector = () => {
        this.toggle()

    }

    newReceiver = (s, po, basin) => {
        // console.log("Supplier: " + s + " - PO: " + po + '- Basin: ' + basin)

        this.createNewReceiver(s, po, basin)
        this.toggle()
 
    }

    createNewReceiver = (s, po, basin) => {
        const newRCVRDTO = this.createReceiverDTO(s, po,basin)

        let myURI = apiURL + 'SupplyChain/Receiver'
        fetch(myURI, apiPOSTHeader(newRCVRDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    
                    var error = (rspData && rspData.message) || response.status;
    
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    });
                    return Promise.reject(error);
                } else

                    return response.json();

            })
            .then(json => {
                           
                if (json[0].receiverKey > 0) {
                    this.setState({ receiverKey: json[0].receiverKey,
                                    selBasinKey: basin })
                    this.toggleRcvDeails()
                }

            })
            .catch(error => {

            })
    }
    createReceiverDTO = (s, po, basin) => {
        const { user } = this.props
        const rcvrCreateDTO = {
            "CompanyID": "WBR",
            "StatusKey": 0,
            "SupplierID": s,
            "PONo": po,
            "ToLocationKey": null,
            "ProjectNo": null,
            "InvoiceNo": null,
            "InvoiceDate": null,
            "OrderDate": null,
            "SubTotalAmount": 0,
            "TaxAmount": 0,
            "FreightAmount": 0,
            "OtherAmount": 0,
            "InvoiceAmount": 0,
            "Comment": "",
            "CreatedBy": getUserADAccount(user.account.userName),
            "OffLineLinkKey": -1,
            "LocationKey":basin
         
        }

        return rcvrCreateDTO;

    }


    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
        });

    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
        });

    }

    // getSecurity=()=>{
    //     let myURI = apiURL + 'EmployeeDetails/Checkpoint/'+ this.props.user.account.userName   
    //     const {security, appSecurityNo, meterVal} = this.state
    //     fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
    //     .then(res => res.json())
    //     .then(json=>{
    //         this.setState({
                
    //             isSecurityLoaded: true,
    //             security: json,
    //             isSecurityAdminLogin: (json.filter((list)=> list.appSecurityNo === 14).length > 0 ? false: true),
    //             isSecurityMeasurementTech: (json.filter((list)=> list.appSecurityNo === 15).length > 0 ? false: true),
    //             isOtherUser: (json.filter((list)=> list.appSecurityNo === 15 || list.appSecurityNo === 14 ).length == 0 ? true:false)
    //         })
    //         console.log(this.state.isSecurityAdminLogin)
    //         // console.log(this.state.isSecurityMeasurementTech)
          
    //     })

    //     } 


    componentDidMount = () => {
        this.getBasin()
        this.getSupplier()
        this.getPO()
        this.getReceiver()
    }


    refreshData=(refreshForm)=>{
        if(refreshForm){
            this.getReceiver()
        }

    }



    render() {
        const { isLoaded, receiverData,
            windowHeight,
            receiverKey,
            selBasinKey,
            supplier,
            POList,
            POListDefault,
            basinList,
            msgHeader,
            msgBody,
            poNumber,
            receiverStatusData, 
            toLocationKey,
            rcvrTypeID, statusKey } = this.state

        const searchText = window.$searchText.toLowerCase()
        const gridHeight = (windowHeight * 0.70) + "px"
        if (!isLoaded) {
            return (<div>
                <h3>Loading receivers.....</h3>
            </div>)
        } else
            return (<div>
                <FormGroup>
                    <Row form>
                        <h2> Receiver |</h2>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.getReceiver}>
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>
                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>{' '}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.newSupplierPOSelector}>
                            <img
                                src={Plus}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            New Receiver
                        </Button>{' '}



                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>
                <FormGroup>
                    <Row >
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)} />
                        </Col>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />

                        </Col>

                    </Row>
                </FormGroup>

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={gridHeight}
                        isColumnOptionsEnabled="true"
                        filterUIType="FilterRow"
                        dataSource={receiverData.filter(rec => ((rec.rcvrTypeDesc || '').toLowerCase().includes(searchText)) ||
                            (rec.receiverNo || '').toLowerCase().includes(searchText) ||
                            (rec.rcvrStausDesc || '').toLowerCase().includes(searchText) ||
                            (rec.invoiceNo || '').toLowerCase().includes(searchText) ||
                            (rec.poNo || '').toLowerCase().includes(searchText) ||
                            (rec.receivedDate || '').toLowerCase().includes(searchText) ||
                            (rec.invLocationID|| '').toLowerCase().includes(searchText) ||
                            (rec.createdByName|| '').toLowerCase().includes(searchText) ||
                            (rec.createdDate || '').toLowerCase().includes(searchText) ||
                            (rec.supplierID || '').toLowerCase().includes(searchText) ||
                            (rec.comment || '').toLowerCase().includes(searchText) ||
                            (rec.supplier_Name|| '').toLowerCase().includes(searchText) 
                            )}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                    >
                        <IgrTemplateColumn  field="locationName" headerText="Basin"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating}
                        />

                        <IgrTemplateColumn  field="rcvrTypeDesc" headerText="Receiver Type"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="receiverNo" headerText="Receiver No." width="*>170"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="rcvrStausDesc" headerText="Status"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="invoiceNo" headerText="Supplier Invoice No."
                            width="*>200"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="poNo" headerText="Purchase Order No."
                            width="*>200"
                            cellUpdating={this.onStatusCellUpdating}
                        />

                          

                        <IgrTemplateColumn field="receivedDate" headerText="Received Date"
                          
                            cellUpdating={this.onStatusCellUpdating}
                            width="*>150"
                        />

                        <IgrTemplateColumn field="invLocationID" headerText="Received Inventory Location"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating}
                        />


                        <IgrTemplateColumn field="supplier_Name" headerText="Supplier Name"
                            width="*>250" 
                            cellUpdating={this.onStatusCellUpdating}
                            />


                        <IgrTemplateColumn field="createdByName" headerText="Created By"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating}
                        />

                   

                        <IgrTemplateColumn field="createdDate" headerText="Created Date"
                       cellUpdating={this.onStatusCellUpdating}
                            width="*>150"
                
                        />



                        <IgrTemplateColumn field="supplierID" headerText="Supplier ID"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating}
                        />


                        <IgrTemplateColumn field="comment" headerText="Comment"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating}
                        />


                        <IgrNumericColumn field="ReceiverKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>

                <div>
                    <Modal isOpen={this.state.modal}
                        toggle={() => this.toggle()}
                        size="lg"
                        backdrop={"static"}
                        >
                        <ModalHeader toggle={() => this.toggle()}>New Receiver
                        </ModalHeader>
                        <ModalBody>
                            <NewReceiver toggle={this.toggle}
                                setStateOfParent2={this.setStateOfParent2}
                                setStateOfParent3={this.setStateOfParent3}
                                supplierList={supplier}
                                poList={POList}
                                POListDefault={POListDefault}
                                basinList={basinList}
                                receiverKey={receiverKey}
                                onClickYes={this.newReceiver}>
                            </NewReceiver>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalRcvDetails}
                        toggle={() => this.toggleRcvDeails()}
                        size="xl"
                        onClosed={() => this.getReceiver()}
                        backdrop={"static"}
                    >
                        <ModalHeader className={this.getReceiverHeaderColor(receiverStatusData)}
                        toggle={() => this.toggleRcvDeails()}>
                            Receiver details
                        </ModalHeader>
                        <ModalBody>
                            <ReceiverDetail toggle={this.toggleRcvDeails}
                            setStateOfParent={this.setStateOfParent}
                            receiverStatusData = {receiverStatusData}
                                receiverKey={receiverKey}
                                selBasinKey={selBasinKey}
                                poNumber = {poNumber}
                                toLocationKey = {toLocationKey}
                                rcvrTypeID = {rcvrTypeID}
                                user={this.props.user}
                                refreshData={this.getReceiver}
                                statusKey= {statusKey}
                                setStateOfParent3={this.setStateOfParent3}
                                getReceiverHeaderColor={this.getReceiverHeaderColor}
                                setStateofParentHeaderColor={this.setStateofParentHeaderColor}
                                setStateOfStatus={this.setStateOfStatus}
                                // testClose={this.testClose}
                            >

                            </ReceiverDetail>

                        </ModalBody>

                    </Modal>
                </div>

            </div>)
    }


    setStateOfParent = (newTitle) =>{

        this.setState({receiverStatusData: newTitle}, ()=>{

        })
        
    }

    setStateOfParent2 = (newTitle) =>{
        
        this.setState({receiverStatusData: newTitle}, ()=>{

        })
        
    }
    setStateofParentHeaderColor=(newTitle)=>{
        this.setState({headerColorBool: newTitle})
    }

    setStateOfStatus=(newTitle)=>{
        const {statusKey} = this.state
        this.setState({statusKey: newTitle},()=>{
            console.log("statusKey: " + statusKey)
        })
    }

 

    getReceiverHeaderColor = (receiverStatusData) => {
       const {headerColorBool} = this.state

        if (headerColorBool){
            return "modal-header primary"
        }

       else if (receiverStatusData!=null){

        const statusKey = receiverStatusData.statusKey

         if (receiverStatusData === "test"){
            console.log("This worked")
            return "modal-header primary"
        }
           else if (statusKey===4) {
                return "modal-header approve"
            }
            // else if (statusKey===2) {
            //     return "modal-header reject"
            // }
            if (statusKey===5) {
                return "modal-header reject"
            }
            else{
                return "modal-header primary"
            }

        }
      
       
        else {return ""}
    }


    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            // let cell = document.createElement("div");
            
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";          
            
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
        
            content.appendChild(link);
        
        }
        else {
            link = content.children[0];
       
           
            if (item.statusKey===4){
                content.style.background = "#61a066";
            }
            
            else if (item.statusKey===5){
                content.style.background = "#ad5f55";
            }
            else if (item.statusKey===2){
                content.style.background = "#05e1fa";
            }
            else {
                content.style.background = "transparent";
            }
        
        }
       

        switch(s.field){
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'rcvrTypeDesc':
                link.textContent = item.rcvrTypeDesc;
                break;
            case 'receiverNo':
                link.textContent = item.receiverNo;
                break;
            case 'rcvrStausDesc':
                link.textContent = item.rcvrStausDesc;
                break;
            case 'receivedDate':
                var date = new Date(item.receivedDate);                
                link.textContent = date.toLocaleDateString();
                break;
            case 'invoiceNo':
                link.textContent = item.invoiceNo;
                break;
                case 'createdDate':
                    var date2 = new Date(item.createdDate);                
                    link.textContent = date2.toLocaleDateString();  
                break;     
            case 'poNo':
                link.textContent = item.poNo;
                break;       
            case 'invLocationID':
                link.textContent = item.invLocationID;
                break;          
            case 'supplier_Name':
                link.textContent = item.supplier_Name;
                break;
            case 'createdByName':
                link.textContent = item.createdByName.replace(/\./g, " ");
                break; 
            case 'supplierID':
                    link.textContent = item.supplierID;
                    break; 
            case 'comment':
                        link.textContent = item.comment;
                        break;

            default:

        }        
        
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
    
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
             content.style.width = "100%";
    
            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }
    
        link.textContent = new Date(item.createdDate).toLocaleDateString("es-PA");
        link.textContent = new Date(item.receivedDate).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns

        let gridDts = this.grid.dataSource 

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                  
            }
        }

        ExcelUtility.save(wb, "Receiver");
    }
}