//Created By BN 11/22/21


import React, {  useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';


const ModalYNComment = (props) => {

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        disabled,
        buttonLabel,
        style,
        //invalid,
        // defaultValue,
        // value
    } = props

    const [modal, setModal] = useState(false);
    const [invalid, setInvalid] = useState(false)
    const [comment,setComment] = useState("")

    const toggle = () => {
        setModal(!modal);
        // props.onClickNo()

    }

    // const toggleNo = () => {
    //     // setModal(! modal);
    //     props.onClickNo()
    // }

    // const toggleYes = (e) => {
        
    //     if ((props.invalid === false) && (!props.value === ""))
    //     {
    //     setModal(!modal)
    //     props.onClickYes();
    //     }
    //     else 
    //     {
    //         props.onChangeTest(e);
    //     }
    // }

    const toggleYes = (e) => {
        

        if (comment === ""){
            setInvalid(true)
        }else{            
            props.onClickYes(comment);
            setModal(!modal)
        }


        
    //    Dragan supreme code is here 
        // if ((props.invalid === false) && (!props.value === ""))
        // {
        // setModal(!modal)
        // props.onClickYes();
        // }
        // else 
        // {
        //     props.onChangeTest(e);
        // }


    }

    // useEffect( () => {
    //     setComment("")
    //     setInvalid(false)
    // },props.dataSelected)


    const onClosed = () => {
        setComment("")
        setInvalid(false)
        // if (props.onClosed !== undefined) {
        //     props.onClosed();
        // }
    }



    const onChange = (e) => {
        setComment(e.target.value)
        
        setInvalid(e.target.value==="")
        
        // props.onChangeTest(e);
    }

    return (
        <div>
            <Button outline={outline}
                style={style}
                color={color}
                className={className}
                hidden={hidden}
                disabled={disabled}
                onClick={toggle}
            // style={{ margin: "0 5px 0 5px" }} 
            >
                {image}
                {buttonLabel}

            </Button>

            <Modal isOpen={modal}
                toggle={toggle}
                onClosed={onClosed} 
                >
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        {message}

                        <Label style={{ margin: "10px 0 0 0" }}>Please enter your comment:</Label>
                        <Input  name="commentReview" id="commentReview"
                            defaultValue={comment}
                            value={comment}
                             type="textarea"
                            rows={3}
                            // defaultValue={meterValDetails.reviewComment}
                            onChange={onChange}
                            invalid={invalid}
                        >

                        </Input>
                    </FormGroup>

                </ModalBody>
                
                <ModalFooter>
                    <Button color="primary" onClick={toggleYes}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default ModalYNComment;