import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

export default class RecordLogs extends Component {
    static displayName = RecordLogs.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        // this.onCellUpdating = this.onCellUpdating.bind(this);
        // this.onGridRowSelected = this.onGridRowSelected.bind(this);
       
        this.state = {
            primaryKey: props.primaryKey,
            closeVisable: props.closeVisable === undefined || props.closeVisable === null ? false : props.closeVisable,
            recordLogs:[],
            isLoaded: false,
            isDataChange: props.isDataChange, 
        }

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.recordLogs;
    }

    toggleOK = () => {
        if (this.saveAFEComment()) {
            this.setState({
                modal: !this.state.modal,
                comments: " "
            })

        }
    }

    async componentDidUpdate(prevProps,prevState){
        
        if(prevProps.isDataChange !== this.props.isDataChange){
            this.setState({isLoaded: false});
            await this.getLogs();
            this.setState({isLoaded: true});
        }
        
    }
    
    async componentDidMount() {
        await this.getLogs();
         this.setState({isLoaded: true});
    }

    async getLogs(){
        const {primaryKey} = this.state;
        let myURI = apiURL + 'AFE/Header/' + primaryKey + '/ChangeLog' 

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    recordLogs: json,
                    isLoaded: true,
                });
            });
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

        }

        switch (s.field) {
            case 'actionDate':

                var date = new Date(item.actionDate);
                link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;

            default:

        }

    }

    closeForm=()=>{
        this.props.closeForm();
    }

    render() {
        const {recordLogs, closeVisable,  isLoaded  } = this.state

        if (!isLoaded) {
            return (<div>
                Loading Comment ......
            </div>)
        } else
            return (<div>
                <Row form style={{ marginTop: "-15px", marginBottom: "5px" }}> 
                    <Button 
                        color="secondary" 
                        className="float-left"
                        hidden={!closeVisable}
                        onClick={() => this.closeForm()}>
                        Close
                    </Button>
                </Row>

                <ColoredLine color="grey" />

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={(recordLogs.length > 10 ? "300px" : "100%")}
                        width='100%'
                        dataSource={recordLogs}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        //selectedKeysChanged={this.onGridRowSelected}                
                    >
                        <IgrNumericColumn field="changedLogsKey" headerText="Changed Logs Key" isHidden />
                        <IgrTextColumn field="actionBy" headerText="By" width="140" />
                        <IgrTemplateColumn field="actionDate" headerText="Date" width="170" cellUpdating={this.onCellUpdating}/>
                        <IgrTextColumn field="fieldName" headerText="Field Name" width="140" />
                        <IgrTextColumn field="oldValue" headerText="Old Value" width="*>350" />
                        <IgrTextColumn field="newValue" headerText="New Value" width="*>350" /> 
                    </IgrDataGrid>

                </FormGroup>


                 

            </div>)
    }
}