import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import RevisionPng from '../resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal';
import DocumentBrowserDragDrop from  '../resources/DocumentBrowserDragDrop'; // '../../resources/DocumentBrowserDragDrop';

import NumberFormat from 'react-number-format';
 

import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../resources/utility';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
import { CT_ThemeableLineStyle } from 'igniteui-react-excel';

var DatePicker = require("reactstrap-date-picker");
  
class ChangeOrder extends React.Component {
    static displayName =  'Alex testing' //AFEDetail.name

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            constructionReportChangeOrderKey: props.constructionReportChangeOrderKey == undefined || props.constructionReportChangeOrderKey == null ? 0 : props.constructionReportChangeOrderKey,
            constructionReportKey: props.constructionReportKey,
            constructionReportDTO: props.constructionReportDTO,
            dailyReportKey: props.dailyReportKey,
            isSecurityAdminLogin: props.isSecurityAdminLogin,
            changeOrderDTO: null,
            user: props.user,
            alertMessage: "",
            showAlert: false,
            showSaveAlert: false,
            isFormReadOnly: false, 
            isLoaded: false, 
            isUserAdmin: false,
            isSecurityLoaded: false, 
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            alertMessage: "",
            alertColor: "danger",
            isForceBudgeLineUpdate: false,
            budgetLineRefreshKey: 0,
            activeTab: '1',
            hasDCCost: false,
            isDataChange: false,
            //isApprovalRevision:false,
            yesNoList: [{
                "label": "Yes",
                "value": 1
            }, {
                "label": "No",
                "value": 0
            }],
            validateDataItem: {
                description: true,
                contractor: true,
                changeOrderDate: true,
                amount: true, 
            },
            siteTransfer: null ,
            afesRevSupBuget: [],
            reimbursable: [{label: '',value: -1}, {label: 'No',value: 0},{label:'Yes', value: 1}],
            modalReport: false,
            reportName: "",
            dropdownOpenManage: false,
            changeOrderDate: null, 
            reportDate: props.reportDate,
            dailyReports: props.dailyReports
        }

        //this.childRef = createRef()
        console.log('tst');
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const { changeOrderDTO, validateDataItem } = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }



            if (changeOrderDTO.changeOrderDate === null || changeOrderDTO.changeOrderDate === "") {
                validateDataItem.changeOrderDate = false
                returnVal.ok = false
                returnVal.message = returnVal.message + " Change Order Date"
            }

            if (changeOrderDTO.dailyReportKey === null || changeOrderDTO.dailyReportKey === "" || changeOrderDTO.dailyReportKey <= 0) {
                validateDataItem.changeOrderDate = false
                returnVal.ok = false
                returnVal.message = returnVal.message + " Change Order Date"
            }
            


            if (changeOrderDTO.contractor === null || changeOrderDTO.contractor === '' ) {
                validateDataItem.contractor = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Contractor"
            }


            if (changeOrderDTO.description === null || changeOrderDTO.description === "") {
                validateDataItem.description = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Description"
            }

            if (changeOrderDTO.amount === null || changeOrderDTO.amount <= 0 ) {
                validateDataItem.amount = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Amount"
            }  
 
            returnVal.validateDataItem = validateDataItem
            if (returnVal.message !== "") {
                returnVal.message = "Please enter missing data: " + returnVal.message
            }
            resolve(returnVal)
        })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleAlert = () => {

        this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggleSaveAlsert = () => {
        this.setState({ showSaveAlert: !this.state.showSaveAlert },
            () => { window.setTimeout(() => { this.setState({ showSaveAlert: false }) }, 3000) })
    }

    resetValidDataItem = () => {
        // const { validDataItem } = this.state

        // validDataItem.toLocationKey = true
        // validDataItem.invoiceDate = true
        // validDataItem.invoiceNo = true

        // this.setState({ validDataItem: validDataItem })
    }

    closeForm = () => {
        this.props.toggle()
    }

    saveData = (bCloseForm = false) => {
        const { constructionReportChangeOrderKey } = this.state

        try {
            this.dataValidation().then((data) => {
                if (data.ok) { 
                    this.setState({isLoaded: false});
                    if (constructionReportChangeOrderKey > 0) {
                        
                        this.updateChangeOrder(bCloseForm)
                    } else {
                        this.addChangeOrder()
                    }

                    this.toggleSaveAlsert()
                } else {
                    this.setState({
                        alertMessage: data.message,
                        validateDataItem: data.validateDataItem,
                        showAlert: true
                    })
                }
            })
        } catch (error) {

        }


    }

    updateChangeOrder = (bCloseForm = false) => {
        const {changeOrderDTO} = this.state;
        let myURI = apiURL + 'ConstructionReporting/ChangeOrders/Edit'
        fetch(myURI, apiPOSTHeader(changeOrderDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    this.closeForm();
                    return response.json();
            }).then(json => {

                 
            }).catch(error => {
                console.log("PUT error: " + error)
            })

    }

    updateAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeUpdateDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "AFENo": afeData[0].afeNo,
            "AFEName": afeData[0].afeName,
            "StatusKey": afeData[0].statusKey,
            "AFEManagerKey": afeData[0].afeManagerKey,
            "AFESponsor": afeData[0].afeSponsor,
            "AFEDescription": afeData[0].afeDescription,
            "Comments": afeData[0].comments,
            "TimingChange": afeData[0].timingChange,
            "TypeKey": afeData[0].typeKey,
            "BudgetClasskey": afeData[0].budgetClassKey,
            "EstStartDate": afeData[0].estStartDate,
            "EstComplete": afeData[0].estComplete,
            "BudgetID": afeData[0].budgetID,
            "WBOwnedPct": afeData[0].wbOwnedPct,
            "WDAFENo": afeData[0].wdAFENo,
            "FacilityKey": afeData[0].facilityKey,
            "WellName": afeData[0].wellName,
            "Lease": afeData[0].lease,
            "APINumber": afeData[0].apiNumber,
            "County": afeData[0].county,
            "Basin": afeData[0].basin,
            "State": afeData[0].state,
            "SurfaceLegal": afeData[0].surfaceLegal,
            "BottomHoleLegal": afeData[0].bottomHoleLegal,
            "Area": afeData[0].area,
            "ModifiedBy": getUserADAccount(user.account.userName),
            "AFECostType": afeData[0].afeCostType,
            "LinkAFE": afeData[0].linkAFE,
            "LocationKey": afeData[0].locationKey,
            "intangibleContigencyRate": afeData[0].intangibleContigencyRate / 100.00,
            "tangibleContigencyRate": afeData[0].tangibleContigencyRate / 100.00,
            "ProjectHierachy": afeData[0].projectHierachy,
            "CostCenterID": afeData[0].costCenterID,
            "CompanyID": afeData[0].companyID,
            "SiteID": afeData[0].siteID,
            "FixAssetsEnteredBy": afeData[0].fixAssetsEnteredBy,
            "FixAssetsModifiedDate": afeData[0].fixAssetsModifiedDate,
            "ActualStartDate": afeData[0].actualStartDate,
            "actualEndDate": afeData[0].actualEndDate,
            "lat": afeData[0].lat,
            "long": afeData[0].long,
            "estStartDate2": afeData[0].estStartDate2,
            "estEndDate2": afeData[0].estEndDate2,
            "projectStatusKey": afeData[0].projectStatusKey,
            "areaKey": afeData[0].areaKey,
            "tangibleTaxRate": afeData[0].tangibleTaxRate / 100.00,
            "intangibleTaxRate": afeData[0].intangibleTaxRate / 100.00,
            "closeoutStatusKey": afeData[0].closeoutStatusKey,
            "projectedAmount": afeData[0].projectedAmount,
            "projectedAmntComments": afeData[0].projectedAmntComments,
            "inServiceDate": afeData[0].inServiceDate,
            "SiteTransfer": afeData[0].siteTransfer,
            "ReimbursableProject": afeData[0].reimbursableProject 
        }

        return afeUpdateDTO;
    }
     
    deleteAFE = () => {
        const deleteAFEDTO = this.deleteAFEDTO()
        let myURI = apiURL + 'AFE/Header'
        fetch(myURI, apiDELETEHeader(deleteAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    this.props.toggle()
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    deleteAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeDeleteDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "DeletedBy": getUserADAccount(user.account.userName)
        }

        return afeDeleteDTO
    }

    dupAFEDTO = (afeNo) => {
        const { user } = this.props
        const { afeData } = this.state
        const afeDeleteDTO = {
            "DupAFENo": afeNo,
            "RequestBy": getUserADAccount(user.account.userName)
        }

        return afeDeleteDTO
    }


    dupAFE = (afeNo) =>{
        const newDupAFEDTO = this.dupAFEDTO(afeNo)
        //console.log(newAFEDTO)

        let myURI = apiURL + 'AFE/Header/Duplicate'
        fetch(myURI, apiPOSTHeader(newDupAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                if (json[0].afeHeaderKey > 0) {
                    console.log("New AFE Return")
                    console.log(json)


                    this.setState({
                        isLoaded:false,
                        afeHeaderKey: json[0].afeHeaderKey,
                        isDataChange: false
                    })
                    this.getAFEDetails()
                }
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    addChangeOrder = () => {
        //const newAFEDTO = this.createAFEDTO()
        //console.log(newAFEDTO)
        const {changeOrderDTO} = this.state;

        let myURI = apiURL + 'ConstructionReporting/ChangeOrders/Add'
        fetch(myURI, apiPOSTHeader(changeOrderDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else{
                    this.closeForm();
                }
                return response.json()
            }).then(json => {

                if (json[0].afeHeaderKey > 0) {
                    console.log("New AFE Return")
                    console.log(json)


                    this.setState({
                        isLoaded:false,
                        afeHeaderKey: json[0].afeHeaderKey,
                        isDataChange: false
                    })
                    this.getAFEDetails()
                }
            }).catch(error => {
                console.log("PUT error: " + error)
            })

    }

    createAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeCreateDTO = {
            "AFENo": afeData[0].afeNo,
            "AFEName": afeData[0].afeName,
            "AFEManagerKey": afeData[0].afeManagerKey,
            "AFESponsor": afeData[0].afeSponsor,
            "AFEDescription": afeData[0].afeDescription,
            "Comments": afeData[0].comments,
            "TimingChange": afeData[0].timingChange,
            "TypeKey": afeData[0].typeKey,
            "BudgetClasskey": afeData[0].budgetClassKey,
            "EstStartDate": afeData[0].estStartDate,
            "EstComplete": afeData[0].estComplete,
            "BudgetID": afeData[0].budgetID,
            "WBOwnedPct": afeData[0].wbOwnedPct,
            "FacilityKey": afeData[0].facilityKey,
            "WellName": afeData[0].wellName,
            "Lease": afeData[0].lease,
            "APINumber": afeData[0].apiNumber,
            "County": afeData[0].county,
            "Basin": afeData[0].basin,
            "State": afeData[0].state,
            "SurfaceLegal": afeData[0].surfaceLegal,
            "BottomHoleLegal": afeData[0].bottomHoleLegal,
            "Area": afeData[0].area,
            "CreatedBy": getUserADAccount(user.account.userName),
            "AFECostType": afeData[0].afeCostType,
            "LinkAFE": afeData[0].linkAFE,
            "LocationKey": afeData[0].locationKey,
            "AreaKey": afeData[0].areaKey,
            "SiteTransfer": afeData[0].siteTransfer,
            "ReimbursableProject": afeData[0].reimbursableProject
        }

        return afeCreateDTO;
    }

    reviseAFE = (statusKey) => {
        const { afeData } = this.state
        afeData[0].statusKey = statusKey
        this.setState({ afeData: afeData })
        this.saveData(true)
    }


    reviseCloseoutAFE = (statusKey) => {
        const { afeData } = this.state
        afeData[0].closeoutStatusKey = statusKey
        this.setState({ afeData: afeData })
        this.saveData(true)
    }

    issueAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 4
        this.setState({ afeData: afeData })
        //this.updateAFE()
        this.saveData(true)
    }


    completeAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 6
        this.setState({ afeData: afeData })
        //this.updateAFE()
        this.saveData(true)
    }



    startedAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 11
        this.setState({ afeData: afeData })
        console.log("here here")
        //this.updateAFE()
        this.saveData(true)
    }


    startAFE = () => {
        const { afeData } = this.state
        if (afeData[0].actualStartDate === null) {
            this.setState({
                alertMessage: "Missing Actual Start Date. Please enter Actual Start Date in Additional Detail.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].statusKey = 11 //Project Start
            this.setState({ afeData: afeData })
            //this.updateAFE()
            this.saveData(true)
        }
    }

    completeAFE = () => {
        const { afeData } = this.state
        if (afeData[0].actualEndDate === null) {
            this.setState({
                alertMessage: "Missing Actual End Date. Please enter Actual End Date in Additional Detail.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].statusKey = 6 //Project Start
            this.setState({ afeData: afeData })
            //this.updateAFE()
            this.saveData(true)
        }
    }

    approveAFE = (comment) => {
        this.approvalAFE(6, comment, null)
    }

    sendBackAFE = (comment) => {
        this.approvalAFE(10, comment, null)
    }

    duplidateFromAFE = (afeNo) => {
        this.dupAFE(afeNo)
    }

    fixAssetApproveAFE = (comment) => {
        this.saveData(false)
        this.approvalAFE(6, comment, null)
    }

    approveCloseoutAFE = (comment) => {
        this.approvalAFE(6, comment, null)
    }

    closeOutAFE = (e) => {
        const { afeData } = this.state

        if (afeData[0].actualStartDate === null || afeData[0].actualEndDate === null) {
            var sErr = ""
            if (afeData[0].actualStartDate === null) {
                sErr = "Actual Start Date"
            }
            if (afeData[0].actualEndDate === null) {
                if (sErr !== '') { sErr = sErr + ', ' }
                sErr = sErr + "Actual End Date"
            }
            this.setState({
                alertMessage: "Missing " + sErr + ". Please enter missing date in Additional Details.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].closeoutStatusKey = 0
            this.setState({ afeData: afeData },
                this.saveData(false))
        }

    }

    submitCloseoutValidation = () => {
        const { afeHeaderKey } = this.state
        var bPass = true
        let myURI = apiURL + 'AFE/closeout/Validate/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    if (json[0].hardStop != 0) {
                        bPass = false
                        this.setState({
                            msgBody: json[0].validationErrorMsg,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    }
                }

                if (bPass) {
                    this.submitCloseoutAFE()
                }
            });
    }




    submitCloseoutAFE = () => {

        const closeoutDTO = this.closeoutAFEDTO()
        console.log(closeoutDTO)
        let myURI = apiURL + 'AFE/Header/Closeout'

        fetch(myURI, apiPUTHeader(closeoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })

    }

    closeoutAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const closeoutAFEDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "CloseoutBy": getUserADAccount(user.account.userName)
        }

        return closeoutAFEDTO
    }


    rejectAFE = (comment, key) => {
        // console.log("reject Comment")
        // console.log(key)
        this.approvalAFE(0, comment, key)

    }

    rejectCloseoutAFE = (comment, key) => {
        this.approvalAFE(0, comment, key)
    }

    
    approvalAFE = (statusKey, comment, key) => {
        const approvalFEDTO = this.approvalAFEDTO(statusKey, comment, key)
        console.log(approvalFEDTO)
        let myURI = apiURL + 'AFE/Approval'

        fetch(myURI, apiPUTHeader(approvalFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })
    }

    approvalAFEDTO = (statusKey, comment, actionLineKey) => {
        const { user } = this.props
        const { afeData } = this.state
        const afeApprovalDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "ApprovalUser": getUserADAccount(user.account.userName),
            "ApprovalStatusKey": statusKey,
            "ApprovalComment": comment,
            "ActionLineKey": actionLineKey
        }

        return afeApprovalDTO
    }

    getSelEmp = (v) => {
        const { empList } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return empList.filter(e => e.employeeKey === Number(v));
        }
        return ""

    }


    getSelLinkAFE = (v) => {
        const { linkAFEList } = this.state
        if (v !== null && v !== "") {
            return linkAFEList.filter(e => e.project_ID === v);
        }
        return ""

    }



    getEmpKey = () => {
        const { empList } = this.state
        const { user } = this.props
        const adAccount = getUserADAccount(user.account.userName)

        const selEmp = empList.filter(e => (e.adAccount || '').toLowerCase() === adAccount.toLowerCase());
        if (selEmp.length > 0) {
            return selEmp[0].employeeKey
        }
        return -1
    }




    getSelFacility = (v) => {
        const { facilityList } = this.state
        if (v !== null && v !== "") {
            return facilityList.filter(e => e.facilityKey === v);
        }
        return ""
    }



    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            return budgetList.filter(e => e.afeBudgetDesc === v);
        }
        return ""
    }


    getSelBudgetClass = (v) => {
        const { budgetClassList } = this.state
        if (v !== null && v !== "") {
            return budgetClassList.filter(e => e.afeBudgetClassKey === v);
        }
        return ""
    }


    getSelAFECostType = (value) => {
        const { costTypeList } = this.state

        if (value !== null && value !== "") {
            return costTypeList.filter(e => value.includes(e.afeLineTypeKey))
        }
        return ""
    }

    getSelArea = (v) => {
        const { areaList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return areaList.filter(e => e.areaKey === v)
        }
        return ""
    }

    getReimbursable =(v) =>{
        const { reimbursable } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return reimbursable.filter(e => e.value === v)
        }
        return ""
    }
    
    getSelCounty = (v) => {
        const { countyList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && v !== "") {
            // console.log("v")
            // console.log(v)
            return countyList.filter(e => e.countyDesc === v)
        }
        return ""
    }


    getSelBasin = (v) => {
        const { basinList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            // console.log("v")
            // console.log(v)
            return basinList.filter(e => e.locationKey === Number(v))
        }
        return ""
    }



    getAFETypeCostString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.afeLineTypeKey
        })

        return grpKey;
    }



    getSelAFEType = (v) => {
        const { afeTypeList } = this.state
        if (v !== null && v !== "") {
            return afeTypeList.filter(e => e.afeTypeKey === v);
        }
        return ""
    }

    getSelYesNo = (v) => {
        const { yesNoList } = this.state
        if (v !== null && v !== "") {
            return yesNoList.filter(e => e.value === v);
        }
        return ""
    }


    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData, validateDataItem,changeOrderDTO } = this.state
        const { user } = this.props
        var showAlert = false
        var isForceBudgeLineUpdate = false
        var budgetLineRefreshKey = 0

        console.log(fieldName + ' ------ ' + fieldValue)
        console.log(e)

        switch (fieldName) {
            case "changeOrderDate":
                changeOrderDTO.changeOrderDate = fieldValue;
                validateDataItem.changeOrderDate = true;
                break;
            case "changeOrderDate_ByDailyReport":
                if (e !== undefined && e !== null){
                    changeOrderDTO.dailyReportKey = e.value;
                    validateDataItem.changeOrderDate = this.formatDate(e.reportDate);
                } 
                break; 
            case "contractor":
                changeOrderDTO.contractor = fieldValue;
                validateDataItem.contractor = true;
                break;
            case "description":
                changeOrderDTO.description = fieldValue;
                validateDataItem.description = true;
                break;
            case 'amount':
                changeOrderDTO.amount = fieldValue <= 0 ? 0 :  Math.round(fieldValue) ;
                validateDataItem.amount = true;
            default:
                break;
        }

        this.setState({
            changeOrderDTO: changeOrderDTO,
            validateDataItem: validateDataItem, 
        })
    }

    onSiteToSiteChange = (fieldValue) => {
        const { afeData  } = this.state
        afeData[0].siteTransfer = fieldValue;
        this.setState({ afeData: afeData })
    }

    getAFEDetailsAPI = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }

    getAFERevSup = async() => {
        const { afeHeaderKey } = this.state
        
        if (afeHeaderKey === undefined || afeHeaderKey === null || afeHeaderKey <= 1){
            return; 
        }
        
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey + '/RevisionSupplement';

        console.log(myURI);

        await fetch (myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afesRevSupBuget: json 
                }) 
            });

        console.log('getAFERevSup');    
    }

    setIsShowCostTracking = (statusKey) => {
        return !(statusKey === 0 || statusKey === 1 || statusKey === 2 || statusKey === 3)
    }

    setIsApprovalRevision = (statusKey, approver, isApprovalRevision, closeoutStatusKey) => {
        if (this.isAFEApprover(statusKey, approver, closeoutStatusKey) && isApprovalRevision !== 0) {
            this.setState({
                showAlert: true,
                alertColor: "info",
                alertMessage: "This AFE have been revised and resubmitted for your approval. Please view change log for update history."
            })
        }
    }

    isAFEApprover = (statusKey, approver, closeOutStatusKey) => {
        const { user } = this.props
        if (approver !== "" && (statusKey === 3 || closeOutStatusKey === 3)) {
            return approver.includes(user.account.userName.toLowerCase())
        } else {
            return false
        }
    }

    getDetails = (constructionReportKey) => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + constructionReportKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }



    setIsFormReadonly = (statusKey, afeManagerAzureAD) => {
        const { user } = this.props
        const { isAFEAdmin } = this.state


        if (statusKey === 1 || statusKey === 10) { //Rejected //10=Revise
            return !((afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase()) || isAFEAdmin)
        } if (statusKey === 6 || statusKey === 5) {
            return true
        } else if (statusKey === 3) {
            return true
        } else if (statusKey === 8) { // 8 - Closed
            return true
        } else if (statusKey === 11) {// 11=Project Started
            return true
        } else if (statusKey === 2) {
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } if (statusKey === 4) { //Approved 
            //return true
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } else {
            return false
        }

    }

    createConstructionReport = () => {
        const { user } = this.props;

        var date = new Date();
        let todaysDate = date.toLocaleDateString();

        const constructionReportDTO = {
            "constructionReportKey": 0,
            "afeNo": null,
            // "afeName": "",
            "constructionReportStatusDesc": "Initiated",
            "statusKey": 1,
            "afeHeaderKey": 0,
            "projectName": "",
            "createdBy": user.account.name,
            "createdDate": todaysDate, 
            "modifiedBy": user.account.name,
            "modifiedDate": todaysDate,  
            "projectManager": "",
            "projectType": null,
            "requiredEndDate": null,
            "pipeSize": null,
            "uncasedBoreLength": null,
            "projectLength": null,
            "casedBoreLength": null,
            "lat": null,
            "long": null,
            "comments": "", 
        }

        return [constructionReportDTO]
    }

    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }


    async componentDidMount() {
        let {constructionReportChangeOrderKey,dailyReports} = this.state;
       

        if(dailyReports !== undefined && dailyReports !== null && dailyReports.length > 0){
            let objDailyReports = this.createChangeOrderDateDropDown(dailyReports);
            this.setState({dailyReports: objDailyReports});
        }

        if (constructionReportChangeOrderKey <= 0) {
            const changeOrderDTO = this.createChangeOrderDTO()
            this.setState({isLoaded: true,changeOrderDTO: changeOrderDTO})
        }
        else {
            this.getChangeOrder();
        }
    }
 
    async getChangeOrder(){
        const {constructionReportKey,dailyReportKey,constructionReportChangeOrderKey} = this.state;
        let myURI = apiURL + 'ConstructionReporting/ChangeOrder/' + constructionReportChangeOrderKey;

        console.log("myURI");
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => { 
                this.setState({ 
                    changeOrderDTO: json,
                    isLoaded: true
                }) 
                 
            });
    }

    createChangeOrderDTO (){ 
        const { user,constructionReportKey,dailyReportKey,reportDate} = this.props;

        var date = new Date();
        let todaysDate = this.formatDate(date);

        const changeOrderDTO = {
            "constructionReportChangeOrderKey": 0,
            "constructionReportKey": constructionReportKey,
            "dailyReportKey": dailyReportKey,
            "changeOrderDate": reportDate !== undefined && reportDate !== null && reportDate !== '' ? reportDate : todaysDate,
            "contractor": "",
            "description": "",
            "amount": 0, 
            "createdByKey": 0,
            "createdBy": user.account.userName,
            "createdDate": date//todaysDate, 
        }

        return changeOrderDTO
    }

    async getConstructionReportDetails(){
        const { constructionReportKey } = this.state 

        if (constructionReportKey > 0) {
            console.log("get new API Details")
            this.getDetails(constructionReportKey)
        } else {
            const constructionReportDTO = this.createConstructionReport()
            this.setState({
                isLoaded: true,
                constructionReportDTO: constructionReportDTO 
            })
            //this.props.setFormHeader("New AFE", "InComplete", "", -1, "")
        }
    }

    createChangeOrderDateDropDown = (v) => {
        const tempList = []
        v.map(e => {
            e.value = this.getDailyReportKey(e)
            e.label = this.formatDate(e.reportDate)
            tempList.push(e)
        })

        return tempList
    }

    getDailyReportKey(e){
        if (e.constructionReportGeneralKey != undefined){
            return e.constructionReportGeneralKey;
        }
        if (e.constructionReportFacilityKey != undefined){
            return e.constructionReportFacilityKey;
        }
        if (e.constructionReportPipelineKey != undefined){
            return e.constructionReportPipelineKey;
        }

    }

    isFormReadOnly = () => {
        const { isFormReadOnly, isAFEAdmin, isApprover,constructionReportDTO } = this.state
        
        if (constructionReportDTO == undefined || constructionReportDTO == null ||
            constructionReportDTO.statusKey == 0 || constructionReportDTO.statusKey > 2 ){
            return true;
        }
        else {
            return false;
        }
        
        
        return isFormReadOnly
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKey} = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKey }]; 
        return reportPara
    }
    
    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }

    async getAFEReport(reportName){
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }

    formatDate = (value) => {

        if (IsDate(value)) {
            const months = {
                0: '01',
                1: '02',
                2: '03',
                3: '04',
                4: '05',
                5: '06',
                6: '07',
                7: '08',
                8: '09',
                9: '10',
                10: '11',
                11: '12',
            }
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            const d = new Date(value)
            const year = d.getFullYear()
            const date = (d.getDate() < 10 ? '0' : '') + d.getDate()
            const monthIndex = d.getMonth()
            const monthName = months[d.getMonth()]
            const dayName = days[d.getDay()] // Thu
    
            //const formatted = `${monthName}/${date}/${year}`
            const formatted = `${year}-${monthName}-${date}`
            return formatted.toString()
        } else {
            return ""
        }
    
    }
    formatDate2 = (value) => {

        if (IsDate(value)) {
            const months = {
                0: '01',
                1: '02',
                2: '03',
                3: '04',
                4: '05',
                5: '06',
                6: '07',
                7: '08',
                8: '09',
                9: '10',
                10: '11',
                11: '12',
            }
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            const d = new Date(value)
            const year = d.getFullYear()
            const date = (d.getDate() < 10 ? '0' : '') + d.getDate()
            const monthIndex = d.getMonth()
            const monthName = months[d.getMonth()]
            const dayName = days[d.getDay()] // Thu
    
            //const formatted = `${monthName}/${date}/${year}`
            const formatted = `${monthName}/${date}/${year}`
            return formatted.toString()
        } else {
            return ""
        }
    
    }

    selDailyReports(dailyReportKey){
        const {dailyReports} = this.state;
        if (dailyReportKey !== null && dailyReportKey !== "") {
            return dailyReports.filter(e => e.value === dailyReportKey);
        }
        return ""
    }

    render() {
        const {changeOrderDTO, alertMessage,  
            showAlert , isLoaded , alertColor , validateDataItem, isDataChange, 
            reportDate,dailyReports,isSecurityAdminLogin} = this.state
          
        let index = 0;

        if (!isLoaded) {
            return (<div>Loading Construction Report Data .....</div>)
        } else
            return (<div>

                <Form onSubmit={e => e.preventDefault()}>
                    <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <Col sm={5}> 
                            <Button color="secondary" className="float-left" 
                                onClick={() => this.closeForm()}>Close</Button>  
                            <Button color="primary" className="float-left"
                                style={{ marginLeft: "5px" }}
                                //ref={ref}
                                hidden={this.isFormReadOnly() && !isSecurityAdminLogin}
                                onClick={() => this.saveData()}>Save</Button> 
                        </Col>  
                    </Row>
                    <ColoredLine color="grey" />
                    <Row form>
                        <Alert color={alertColor} toggle={() => this.toggleAlert()}
                            isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {alertMessage}
                        </Alert >
                    </Row>

                    <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                        <Col md={12}>
                            <Row form>
                                <Col md={4}>
                                    <Label for="afeNo">Change Order Date</Label>
                                </Col> 
                                <Col md={8} hidden={reportDate !== undefined && reportDate !== null && reportDate !== ''}>
                                    <Select options={dailyReports} 
                                        className={(!validateDataItem.changeOrderDate ? "form-error" : "")}
                                        value={changeOrderDTO === undefined ||changeOrderDTO === null || changeOrderDTO.changeOrderDate === undefined ? '' : this.selDailyReports(changeOrderDTO.dailyReportKey)}
                                        onChange={(e) => this.onTextChange("changeOrderDate_ByDailyReport", e,e)}
                                        isClearable={true}
                                        invalid={!validateDataItem.changeOrderDate}
                                        isDisabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                    >
                                    </Select>
                                    <FormFeedback tooltip>Please select change order date</FormFeedback>
                                </Col>
                                <Col md={8} hidden={reportDate === undefined || reportDate === null || reportDate === ''}>
                                    <Input  
                                        type="text" 
                                        name="changeOrderDate"
                                        className={(!validateDataItem.contractor ? "form-error" : "")}
                                        id="changeOrderDate" 
                                        placeholder="Change Order Date"
                                        disabled={this.isFormReadOnly()} 
                                        value={changeOrderDTO === undefined ||changeOrderDTO === null || changeOrderDTO.changeOrderDate === undefined ? '' : this.formatDate2(changeOrderDTO.changeOrderDate)}
                                    />
                                    <FormFeedback tooltip>Please select change order date</FormFeedback>
                                </Col> 
                            </Row>
                        </Col> 
                    </Row> 
                    <Row form style={{ marginBottom: "5px" }}> 
                        <Col md={12}> 
                            <Row form>
                                <Col md={4}>
                                    <Label for="afeSponsor">Contractor</Label>
                                </Col>
                                <Col md={8}>
                                    <Input type="text" name="contractor"
                                        className={(!validateDataItem.contractor ? "form-error" : "")}
                                        id="contractor" 
                                        placeholder="Contractor Name"
                                        disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                        onChange={(e) => this.onTextChange("contractor", e.target.value, e)}
                                        value={changeOrderDTO === undefined ||changeOrderDTO === null || changeOrderDTO.contractor === undefined ? '' : changeOrderDTO.contractor}
                                    />
                                </Col>
                            </Row>
                        </Col>  
                    </Row>
                    <Row form style={{ marginBottom: "5px" }}> 
                        <Col md={12}> 
                            <Row form>
                                <Col md={4}>
                                    <Label for="afeSponsor">Description</Label>
                                </Col>
                                <Col md={8}>
                                    <Input type="textarea" name="description"
                                        rows={8}
                                        className={(!validateDataItem.description ? "form-error" : "")}
                                        disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                        id="description" 
                                        placeholder="Change Order Description"
                                        value={changeOrderDTO === undefined ||changeOrderDTO === null ||  changeOrderDTO.description === undefined ? '' : changeOrderDTO.description} 
                                        onChange={(e) => this.onTextChange("description", e.target.value, e)}
                                    />
                                </Col>
                            </Row>
                        </Col>  
                    </Row>
                    <Row form style={{ marginBottom: "5px" }}> 
                        <Col md={12}> 
                            <Row form>
                                <Col md={4}>
                                    <Label for="afeSponsor">Amount</Label>
                                </Col>
                                <Col md={8}>
                                    <NumberFormat 
                                        value={changeOrderDTO === undefined ||changeOrderDTO === null || changeOrderDTO.amount === undefined ? 0 : changeOrderDTO.amount}
                                        //className={("form-control text-md-right")}
                                        className={(!validateDataItem.amount ? "form-error" : "form-control text-md-right")}
                                        fixedDecimalScale={true}
                                        decimalScale={0}
                                        displayType={''} 
                                        onValueChange={(e) => this.onTextChange("amount", e.value, e)}
                                        prefix={'$'}
                                        placeholder="Change Order Amount in $"
                                        thousandSeparator={true}
                                        min="0"
                                        disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                    />
                                </Col> 
                            </Row>
                        </Col>  
                    </Row>  
                </Form>

            </div>)
    }

}  
export default ChangeOrder
