
import React, { Component } from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputGroup, InputGroupAddon, InputGroupText, Input,CustomInput } from 'reactstrap';
import MenuIcon from '../resources/menu.png';
import '../resources/DockManagerStyles.css'
import {apiURL,apiHeader} from '../resources/apiURL';

import { IgcDockManagerComponent, IgcContentPane } from '@infragistics/igniteui-dockmanager';
import { IgcDockManagerPaneType, IgcSplitPaneOrientation } from '@infragistics/igniteui-dockmanager';
import { defineCustomElements } from '@infragistics/igniteui-dockmanager/loader';
import OperatorSummary from './OperatorSummary'
import MeterVolumeChart from './MeterVolumeChart';
import OperationChart from './OperationChart';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrInputModule } from 'igniteui-react-inputs';
import { FilterFactory } from 'igniteui-react-core';
import { ConsolidatedItemsPosition_$type } from 'igniteui-react-charts';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();

defineCustomElements();

var DatePicker = require("reactstrap-date-picker");
var filterFac=[];
    
export default class OperatorDashboard extends Component {


    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onMyFacilityUpdating = this.onMyFacilityUpdating.bind(this);
      
      this.onFilterChanged = this.onFilterChanged.bind(this)

        this.state = {
            facility:[],    
            myFacility:[],                  
            windowHeight: window.innerHeight,
            startDate: getFirstDayofMonth().toISOString() ,
            endDate: new Date().toISOString(),
            facilityKeys:[],
            isLoaded:false,
            facilitySummary:[],
            selectedFacility:0,
            dayCounts:0,
            faciltyViewList:'my',
            onFormLoad:false            
        }
    }

   
    
    onFilterChanged = () => {
    
        //console.log("filter Change")
        //console.log(this.grid.filterExpressions)
        //console.log(this.grid.actualColumns.item(0).filter.e._inner[0])

        var actualDs = this.grid.actualDataSource.dataSource
        var filterDs = []

        //console.log("ActualDs")
        //console.log(actualDs)

         for (var i=0; i<this.grid.actualColumns.count; i++){
            
            if (this.grid.actualColumns.item(i).filter!==null){
                filterDs = []
                let filter=this.grid.actualColumns.item(i).filter

                console.log(this.grid.actualColumns.item(i).filter)

                if (filter.g===true) {
                    console.log("filter")
                    console.log(filter)
                    var tempDs=[]
                    if (i===0){
                        tempDs = actualDs.filter(rec => (rec.facilityName.toLowerCase() === filter.j.toLowerCase()))
                    }else if (i===1) {
                        tempDs = actualDs.filter(rec => ( (rec.areaName === null ? "" : rec.areaName.toLowerCase()) === filter.j.toLowerCase()))
                    }else {

                        tempDs = actualDs.filter(rec => ( (rec.subAreaName === null ? "" : rec.subAreaName.toLowerCase()) === filter.j.toLowerCase()))
                    }

                    tempDs.forEach(rec => (filterDs.push(rec)))
                    
                }else{
                    console.log('dfdf')
                    let filters=filter.e._inner

                    filters.forEach((f) => {
                        console.log(f.j)
                        var tempDs=[]
                        if (i===0){
                            tempDs = actualDs.filter(rec => (rec.facilityName.toLowerCase() === f.j.toLowerCase()))
                        }else if (i===1){
                            tempDs = actualDs.filter(rec => ((rec.areaName === null ? "" : rec.areaName.toLowerCase()) === f.j.toLowerCase()))
                        }  else {
                            tempDs = actualDs.filter(rec => ( (rec.subAreaName === null ? "" : rec.subAreaName.toLowerCase()) === f.j.toLowerCase()))
              
                        }

                        tempDs.forEach(rec => (
                            filterDs.push(rec)
                        ))
                        
                    })
                }

                // if (this.grid.actualColumns.item(i).filter.e._inner === 'undefined'){
                
                // }else{
                //     console.log(filterCon)

                //     filterCon.forEach((f) => {
                //         console.log(f.j)
                //      })
                // }
                
                actualDs = filterDs
            }
             
           
        }

        filterFac=filterDs
        console.log(filterFac)
        //this.getOpDashboard();
        
    }

    handleResize = (e) => {
        console.log("resize: ")
        this.setState({ windowHeight: window.innerHeight });
    };

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    handleChange=(v,f) => {
        this.setState({
            startDate:v
        })
    }

    handleEndDateChange = (v,f) => {
        this.setState({
            endDate:v
        })
    }

   
    removeMyFacility = (fKey) => {

    }

    addMyFacility = (fKey) => {

    }


    getOpDashboard = () => {
        var {facility,onFormLoad} = this.state

        console.log("getOpDashboard")
        //let gridDts = this.grid.actualDataSource
        console.log(facility)
        //console.log("Facilitykeys: " + this.state.facilityKeys);


        if (!onFormLoad){
            facility=this.grid.actualDataSource.dataSource;
        }
        

        var facilityKeys=[]
        if (filterFac.length>0){
            filterFac.forEach((item,i) => {
                facilityKeys.push(item.facilityKey);
            })
        } else{
            facility.forEach((item,i) => {
                facilityKeys.push(item.facilityKey);
            })
        }

        // if (facilityKeys.length <=0){
        //     facility.forEach((item,i) => {
        //         facilityKeys.push(item.facilityKey);
        //     })
        // }
        
        let myURI = apiURL + 'FacilityDetails/summary?azureAd='+ this.props.user.account.userName + 
                            '&startdate=' + this.state.startDate.toString().substring(0, 10) + 
                            '&enddate=' + this.state.endDate.toString().substring(0, 10) +
                            '&FacilityKey=' + facilityKeys + '&AreaKey=0&Locationkey=0'
        //+ '/Employee/' + this.props.user.account.userName;
        // console.log("Show Dragan")
        // console.log(myURI);

        fetch(myURI , apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
            this.setState({
                isLoaded: true,
                facilitySummary: json,
                selectedFacility:(json.length > 0 ? json[0].facilityCount : 1) ,
                dayCounts: (json.length > 0 ? json[0].dayCount : 1)                  
            })
        });

        var facilitysummary = document.getElementById("summary");
        //console.log(facilitysummary)

    }


    // componentDidMount(){
    //     window.addEventListener("resize", this.handleResize);
    //     this.getOpDashboard();
    // }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.facility;
    }


    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            //console.log(dataItem)
            
            if (!this.state.hideAssociateDetails){
                this.setState({facilityKey: dataItem.facilityKey,
                    facilityName: dataItem.facilityName})       
            }

            const tempList=[]
            let clickedRows = e.currentKeys
            
            for (let i = 0; i < e.currentKeys.count; i++){
               // console.log(e.currentKeys.item(i).value[0] )
                tempList.push(e.currentKeys.item(i).value[0].facilityKey); 
            }
            
            this.selectedFacilityChanged(tempList)
        }else{
            const tempList=[]
            this.selectedFacilityChanged(tempList)
        } 
    }

    getFacilityData = () =>{
        this.setState({
            onFormLoad:true
        })

        if (this.state.faciltyViewList === 'my')
        {
            this.getMyFacilityData();
        }
        else {         
            //console.log("come here and should not")   
           this.getAssignFacility();
        }    
        
    }

    getAssignFacility = () => {

        let myURI = apiURL + 'EmployeeDetails/Facility/AzureAD/' + this.props.user.account.userName 

        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                facility: json
            })
        })

    }

    getMyFacilityData = () => {
        let myURI = apiURL + 'EmployeeDetails/MyFacility/AzureAD/' + this.props.user.account.userName 
        
        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                facility: json,
                myFacility: json
            })
        })        
    }

    selectedFacilityChanged = (facilityKeys) => {
        this.setState({facilityKeys:facilityKeys});
    }

    onFacilityRadioChanged= (e) => {        
        //console.log("Facilitytype: " + e.target.value)
        this.setState({faciltyViewList:e.target.value})
        if (e.target.value === "my"){
           this.getMyFacilityData();
        }else {
            this.getAssignFacility();
        }
    }

    resetFilter = () =>{
        for (var i=0; i<this.grid.actualColumns.count; i++){
            this.grid.actualColumns.item(i).filter=null
        }
        this.grid.selectedItems.clear()
        filterFac = []
        this.getOpDashboard();
    }
  

    render() {
        var { windowHeight,facilitySummary,facility } = this.state;
        const {isLoaded,  selectedFacility, dayCounts,onFormLoad,faciltyViewList } = this.state ;

        const frameHeight = (windowHeight - 100) + "px";
        console.log("framHeight:" + frameHeight)

        const gridHeight=(windowHeight *  0.8) + "px"
        var searchText = window.$searchText.toLowerCase();
        
        if (isLoaded){
            if (onFormLoad){
//                this.setState({onFormLoad:false})
                if (faciltyViewList==="my" && facility.length === 0){
                    console.log("this.setState({faciltyViewList:")
                    this.setState({faciltyViewList:"all"})
                    this.getAssignFacility()
                    this.setState({isLoaded:false})
                }else{this.setState({onFormLoad:false})}                                               
                this.getOpDashboard()    
            }
            
        }      

        return (<div style={{ height: frameHeight }}>
            <FormGroup>
                <Row form>
                    <h3> Operation Dashboard | </h3>
                    <Col md={3}>
                        <FormGroup row>
                            <Label for="startDate" sm={4} style={{padding:"5px 0 0 20px"}}>Start Date</Label>
                            <Col sm={8}>
                            <DatePicker value={this.state.startDate}
                            onChange={(v, f) => this.handleChange(v, f)} />
                            </Col>
                        </FormGroup>

                    </Col>

                    <Col md={3}>
                        <FormGroup row>
                            <Label for="endDate" sm={4} style={{padding:"5px 0 0 20px"}}>End Date</Label>
                            <Col sm={8}>
                                <DatePicker value={this.state.endDate}
                                    onChange={(v, f) => this.handleEndDateChange(v, f)} />
                            </Col>
                        </FormGroup>

                    </Col>

                    <Button outline
                        style={{margin: "0 0 20px 20px", padding:"0 0 0 0" }}
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.getOpDashboard}>
                        <img src={MenuIcon}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                            Refresh
                    </Button>

                    <Button outline
                        style={{margin: "0 0 20px 20px", padding:"0 0 0 0" }}
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.resetFilter}>
                        <img src={MenuIcon}
                            alt="Reset"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                            Reset Filter
                    </Button>

                    

                </Row>
                <h5>{selectedFacility} {(selectedFacility>1 ? 'facilites' : 'facility' )} selected for {dayCounts} days</h5>
                <ColoredLine color="grey"/>
                
            </FormGroup>

            <igc-dockmanager id="dockManager">

                <div className="dockManagerContent"
                    slot="facility"
                    id="facilityListContainer" >                    
                    <FormGroup>                        
                        
                            <div>
                            <CustomInput type="radio" 
                                        id="allFacility" 
                                        name='facility' 
                                        label='All Facilities' 
                                        value='all'
                                        checked = {this.state.faciltyViewList=='all'}
                                        onChange={this.onFacilityRadioChanged} 
                                        inline />                 
                            <CustomInput type="radio" id="myFAcility" 
                                         name='facility' label='My Facilities' 
                                         value='my' 
                                         checked = {this.state.faciltyViewList=='my'}
                                         onChange={this.onFacilityRadioChanged} 
                                         inline/>
                           
                            </div>
                            
                    </FormGroup>

                    <FormGroup>
                        <IgrDataGrid                        
                            ref={this.onGridRef}
                            height={gridHeight}                     
                            dataSource={facility.filter(rec => ((rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                    (rec.areaName || '').toLowerCase().includes(searchText) || 
                                                    (rec.subAreaName || '').toLowerCase().includes(searchText) ))}

                            autoGenerateColumns="false"                                   
                            selectionMode="MultipleRow"
                            selectedKeysChanged={this.onGridRowSelected}
                            filterExpressionsChanged={this.onFilterChanged}>         

                            {/* <IgrTemplateColumn field="myFacility" 
                            headerText="" width="40" 
                            cellUpdating={this.onMyFacilityUpdating}/>
                         */}

                            <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>190" />
                        
                            <IgrTextColumn field="areaName" headerText="Area" width="*>130" />
                            <IgrTextColumn field="subAreaName" headerText="Sub Area" width="*>130" />
                                                               
                            <IgrNumericColumn field="facilityKey" isHidden/>

                        </IgrDataGrid>
                </FormGroup>

                </div>


                <div className="dockManagerContent"
                    slot="summary"
                    id="productivityChartContainer">
                    <OperatorSummary FacilitySummary={facilitySummary} user={this.props.user}/>

                </div>

                <div className="dockManagerContent"
                    slot="graph"
                    id="oprationChartConainer" 
                    hidden>
                    <OperationChart />
                </div>

                <div className="dockManagerContent"
                    slot="volumeChart"
                    id="volumeChartContainer"
                    hidden>
                    <MeterVolumeChart />
                </div>

            </igc-dockmanager>
        
        
        </div>
        )
    }

    isMyFacility = (k) => {
        if (this.state != null){
        const {myFacility} = this.state;
        const myFac = myFacility.filter(f => f.facilityKey == k)
        // console.log("myFac.length")
        // console.log(myFac.length > 0)
        return (myFac.length > 0)
        }else return false;
    }

    saveMyFacility(e) {
        console.log("fKey-----------------------")
        console.log(e.target.id + " is "+ e.target.checked)
    }

    onMyFacilityUpdating (s, e) {
        
        if (this.state != null){
            const {faciltyViewList} = this.state

        if (faciltyViewList!=="my" ){
            const content = e.content;
            const info = e.cellInfo;
            const item = info.rowItem;

       // console.log(item)
        let chkActive;
        
        var bFound = false        
        for (var i=0; i<content.children.length; i++){
            const chk = content.children[i];

            if (chk.id == item.facilityName){                    
               // console.log("founded")
               
                chkActive = chk
                bFound=true;
                break
            }
        }

        if (!bFound ){
            //console.log("Add new check box - " + item.facilityKey)
            chkActive = document.createElement("INPUT");
            chkActive.setAttribute("type", "checkbox");           
            chkActive.id = item.facilityName
            content.style.display = "inline-block";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            content.style.width = "100%";    
            content.appendChild(chkActive);
            chkActive.addEventListener('click', this.saveMyFacility); 
        } 

       // console.log("here again")
        chkActive.removeAttribute("checked");

        // if (item.facilityKey != 0){
        //    // console.log("here")
        //     if (this.isMyFacility(item.facilityKey)){
        //         chkActive.setAttribute("checked","checked");   
        //         console.log("here") 
        //     }
                          
        //     }
         }
        }
    }

    componentDidMount() {
        //this.getOpDashboard();

        this.getFacilityData();
        
        window.addEventListener("resize", this.handleResize);

        var { dayCounts,selectedFacility } = this.state;
        console.log('daycount:' + dayCounts)
        const {isLoaded } = this.state ;

        const facilityPane = this.createContentPane('facility', 'Facility', 100)
        const graphPane = this.createContentPane('graph', 'Graph Content Pane', 100)
        const graphVolumePane = this.createContentPane('volumeChart', 'Meter Volume Chart', 100)

        const summaryPane = this.createContentPane('summary', 'Facility Summary', 200)
        var dockManager = document.getElementById("dockManager");

        dockManager.layout = {
            rootPane: {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                panes: [
                    {
                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 100,
                        panes: [facilityPane]
                    },
                    {                        
                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 300,
                        panes: [{
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.vertical,
                            size: 100,
                            panes: [summaryPane]
                        },
                        // {
                        //     type: IgcDockManagerPaneType.splitPane,
                        //     orientation: IgcSplitPaneOrientation.horizontal,
                        //     size: 60,
                        //     panes: [graphPane, graphVolumePane]
                        // }
                        ]
                    }
                ]
            }
        };


    }

    createContentPane(contentID, paneHeader, size) {
        const pane = {
            size: size,
            header: paneHeader,
            type: IgcDockManagerPaneType.contentPane,
            contentId: contentID
        };
        return pane;
    }

    createSplitPane(orientation, contentPanes, size) {
        const pane = {
            type: IgcDockManagerPaneType.splitPane,
            orientation: orientation,
            panes: contentPanes,
            size: size
        };
        return pane;
    }
    createTabPane(orientation, contentPanes, size) {
        const pane = {
            type: IgcDockManagerPaneType.documentHost,
            size: size,
            rootPane: {
                type: IgcDockManagerPaneType.splitPane,
                orientation: orientation,
                panes: [
                    {
                        type: IgcDockManagerPaneType.tabGroupPane,
                        panes: contentPanes
                    }
                ]
            }
        };
        return pane;
    }
}