//Modified by BN231107 Updated
import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");



export default class MOCComments extends Component {
    static displayName = MOCComments.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
       
        this.state = {
            user:props.user,
            readOnly: props.readOnly,
            mocKey: props.mocKey,
            mocComment:[],
            isWOCommentLoaded: false,
            modalComment:false,
            cmtDate: new Date().toISOString(),
            operationCommentKey: -1,
            comments: ' ',
            offLineLinkKey: props.offLineLinkKey
           // fiixWOId: props.fiixWOId
        }

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.mocComment;
    }

    toggleOK = () => {
        if (this.saveMOCComment()) {
            this.setState({
                modal: !this.state.modal,
                comments: " "
            })

        }
    }

    validateData = () => {
        var { comments } = this.state
        comments = comments.trim()
        if (comments !== "") {
            return true
        } else {
            // this.setState({ comments: comments })
            return false
        }
    }

    createMOCCommentForUpdateDTO = () => {
        const { operationCommentKey, comments, user } = this.state
        const opsCmtDTO = {
            "operationCommentKey": operationCommentKey,
            "comments": comments,
            "modifiedBy": getUserADAccount(user.account.userName),
            "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    updateData = () => {
        const uptDTO = this.createMOCCommentForUpdateDTO()
        let myURI = apiURL + 'MOC/Comment'


        fetch(myURI, apiPUTHeader(uptDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    //return Promise.reject(error);
                    return false
                }

            })
            .catch(error => {
                return false
            })

        return true
    }
    createMOCCommentForCreateDTO = () => {
        const { mocKey, cmtDate, comments, user, offLineLinkKey } = this.state
        // console.log(mocKey)
        const afeCmtDTO = {
            "commentTypeKey": 6, //MOC
            "compObjectKey": mocKey === undefined || mocKey === null || mocKey === "" || mocKey === -1? offLineLinkKey : mocKey, //selectedValue.key,
            "facilityKey": null,
            "commentDate": cmtDate,
            "comments": comments,
            "createdBy": getUserADAccount(user.account.userName),
            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return afeCmtDTO
    }

    

    saveMOCComment = () => {
        const { operationCommentKey } = this.state
        if (this.validateData()) {

            if (operationCommentKey > 0) {
                if (this.updateData()) {
                    this.setState({ operationCommentKey: -1 })
                }
            } else {
                const addDTO = this.createMOCCommentForCreateDTO()
                let myURI = apiURL + 'MOC/Comment'
                fetch(myURI, apiPOSTHeader(addDTO))
                    .then(async response => {
                        const rspData = response.text();
                        if (!response.ok) {
                            const error = (rspData && rspData.message) || response.status;
                            //return Promise.reject(error);
                            return false
                        }
                    })
                    .catch(error => {
                        return false
                    })
            }
            return true
        }

        return false
    }

    onTextChange = (fieldName, fieldValue, e) => {
        this.setState({ comments: fieldValue });
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    newComment = () => {
        this.setState({
            comments: ' ',
            cmtDate: new Date().toISOString(),
            operationCommentKey: -1
        }) 
        this.toggle()
        this.getMOCComment()
    }

    onGridRowSelected(s, e) {
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // this.setState({ selAFELine: dataItem })           
            this.setState({
                comments: dataItem.comments,
                cmtDate: dataItem.commentDate,
                operationCommentKey: dataItem.operationCommentKey
            }) 
        }
    }

    editComment = (key, date, text) => {
      
        this.toggle()
    }

    refreshComment = () => {
        this.getMOCComment()
    }

    getMOCComment = () => {
        const { mocKey, offLineLinkKey} = this.state
        // console.log(mocKey)
        // console.log(offLineLinkKey)
        let myURI = apiURL + 'MOC/Comment/' + (mocKey === -1 ? offLineLinkKey : mocKey);


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    mocComment: json,
                    isLoaded: true
                })
                // console.log(json)
            });
    }
    handleChange = (v, f) => {
        this.setState({
            cmtDate: v
        })
    }

    componentDidMount() {
        this.getMOCComment();
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

        }

        switch (s.field) {
            case 'commentDate':

                var date = new Date(item.commentDate);
                link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;

            default:

        }

    }

    render() {
        const { user, mocComment,
            isLoaded, isFormReadOnly,
            operationCommentKey,
            comments } = this.state

        if (!isLoaded) {
            return (<div>
                Loading Comment ......
            </div>)
        } else
            return (<div>
                <Row form style={{ marginTop: "-15px", marginBottom: "5px" }}>
                    {/* <Col sm={12}> */}
                    {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                    <Button color="primary" outline className="float-left"
                        style={{ height: "35px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.newComment()}
                    >Add</Button>

                    <Button color="primary" outline className="float-left"
                        style={{ height: "35px", marginLeft:"10px" }}
                        // hidden={!isAFEManager && !isAFEAdmin}
                        onClick={() => this.toggle()}
                    >Edit</Button>

                </Row>

                <ColoredLine color="grey" />

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        // height={(afeComment.length > 10 ? "300px" : "100%")}
                        width='100%'
                        dataSource={mocComment}
                        //isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}                
                    >

                        <IgrTemplateColumn
                            field="commentDate"
                            headerText="Date"
                            width="170"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTextColumn
                            field="createdBy"
                            headerText="By"
                            width="140"
                        />

                        <IgrTextColumn
                            field="comments"
                            headerText="Comments"
                            width="*>350"
                        />

                        <IgrNumericColumn field="operationCommentKey" isHidden />
                    </IgrDataGrid>

                </FormGroup>


                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                        onClosed={() => this.refreshComment()}
                        //contentClassName="oilTicketDetail-modal-style"
                        size='lg'
                    >
                        <ModalHeader toggle={() => this.toggle()}
                        > New Comments </ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Label for="commentDate" sm={2}>Date</Label>
                                <Col sm={10}>
                                    <DatePicker value={this.state.cmtDate} id="commentDate"
                                        onChange={(v, f) => this.handleChange(v, f)} disabled={true} />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="exampleText" sm={2}>Text Area</Label>
                                <Col sm={10}>
                                    <Input type="textarea"
                                        name="text"
                                        id="exampleText"
                                        rows="6"
                                        defaultValue={comments}
                                        value={comments}
                                        invalid={comments === ""}
                                        onChange={(e) => this.onTextChange("comments", e.target.value, e)} />
                                    <FormFeedback tooltip> Comment can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                        </ModalFooter>
                    </Modal>
                </div>

            </div>)
    }
}