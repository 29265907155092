import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'

import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, USDCurrencyFormat } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';



import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';
import { FilterFactory, MarketFacilitationIndexIndicatorDescriptionModule, isNaN_ } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ConsolidatedItemsPosition } from 'igniteui-react-charts';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
var DatePicker = require("reactstrap-date-picker");

export default class AssetMetaList extends Component {
    static displayName = AssetMetaList.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.state = {
            assetkey: props.assetkey,
            selAssetMeta: [],
            isLoaded: false,
            asset_metas: [],
            asset_metas_filter: [],
            isFormReadOnly: true,
            modal: false,
            asset_type_id: props.asset_type_id,
            companyID: props.companyID,
            assetConfig: [],
            assetConfigMetas: [],
            searchText: '',
            selConfigId: [],
            selConfigMeta: [],
            asset_meta_value: "",
            assetDetais: [],
            asset_meta_key: -1
        }

    }


    getAssetDetails = () => {
        const { assetkey, companyID } = this.state

        let myURI = apiURL + 'Asset/' + companyID + '/Detail/ByAssetKey/' + assetkey
        // console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                console.log("json")
                console.log(json)
                this.setState({ assetDetais: json })
            });

    }


    onGridRowSelected(s, e) {
        const {assetConfigMetas} = this.state
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            const tempFilterMeta = assetConfigMetas.filter(e => e.asset_config_meta_key === dataItem.asset_config_meta_key)

            console.log("dataItem")
            console.log(dataItem)
            console.log(tempFilterMeta)

            var asset_meta_key = -1
            if (tempFilterMeta.length > 0) {
                if (tempFilterMeta[0].data_type !== "value_list") {
                    asset_meta_key = dataItem.asset_meta_key
                }
            }

            this.setState({
                selAssetMeta: dataItem,
                asset_meta_key: asset_meta_key
            }, () => {
                //this.loadAssetConfig(dataItem.asset_type_id)
            })
        }
    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.asset_metas_filter;
    }

    addAssetMeta = () => {
        this.setState({
            asset_meta_value: '',
            selConfigId: [],
            selConfigMeta: [],
            asset_meta_key: -1
        })
        this.toggleModal()
    }

    editAssetMeta = () => {
        const { selAssetMeta, assetConfigMetas, assetConfig } = this.state
        const tempFilter = assetConfig.filter(e => e.value === selAssetMeta.asset_config_key)
        const tempFilterMeta = assetConfigMetas.filter(e => e.asset_config_meta_key === selAssetMeta.asset_config_meta_key)

        this.setState({
            asset_meta_value: selAssetMeta.meta_text,
            selConfigId: (tempFilter.length > 0 ? tempFilter[0] : []),
            selConfigMeta: (tempFilterMeta.length > 0 ? tempFilterMeta[0] : [])
        })
        this.toggleModal()
    }

    getAssetMetas = () => {
        const { assetkey } = this.state

        let myURI = apiURL + 'Asset/AssetMeta/ByAssetKey/' + assetkey
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    asset_meta: json,
                    asset_metas_filter: json,
                    isLoaded: true,
                })
            });
    }

    getAssetConfig = () => {
        const { asset_type_id, companyID } = this.state

        let myURI = apiURL + 'Asset/' + companyID + '/AssetConfig/ByAssetType/' + asset_type_id
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                var temp_metas = []
                var temp_config = []

                console.log(json)

                json[0].asset_config.map(e => {
                    if (e.config_id !== "product_line" && e.config_id !== "basin_facility") {
                        const temp = { "label": "", "value": 0 }
                        temp.label = (e.config_name === "_" ? e.config_id : e.config_name)
                        temp.value = e.asset_config_key
                        temp_config.push(temp)

                        e.meta_datas.map(f => {
                            if (f.data_type !== "value_list") {
                                f.label = f.meta_name
                                f.value = f.meta_id
                            }
                        })

                        temp_metas = [...temp_metas, ...e.meta_datas]
                    }

                })

                this.setState({
                    assetConfig: temp_config,
                    assetConfigMetas: temp_metas
                })

                console.log("assetConfigMetas")
                console.log(temp_metas)

                console.log("assetConfig")
                console.log(temp_config)
            });
    }

    onTextChange = (fieldName, fieldValue, e) => {
        var { searchText } = this.state
        console.log(fieldValue)


        switch (fieldName) {
            case "searchBox":
                let searchText = (fieldValue || "").toLowerCase()
                if (this.grid.activeCell !== null) {
                    this.grid.activeCell = null
                }

                this.grid.filterExpressions.clear()
                if (searchText === "") {
                    return
                }

                this.filterFactory = new FilterFactory()
                const col_meta_text = this.filterFactory.property("filter_tag").toLower();
                let filter_meta_text = col_meta_text.contains(searchText)
                this.grid.filterExpressions.add(filter_meta_text);

                this.setState({ searchText: searchText })

                break;
            case "config_id":
                this.setState({ selConfigId: e })
                break;
            case "config_meta":
                this.setState({ selConfigMeta: e })
                break;
            case "meta_value":
                this.setState({ asset_meta_value: fieldValue })
                break;
            default:
                break;

        }

        this.setState({ searchText: searchText })
    }

    createUpdateAssetMetaDTO = () => {
        const { assetkey,
            asset_meta_value, selConfigMeta } = this.state
        var temp_metas = []


        const meta = {
            "asset_meta_key": -1,
            "meta_id": selConfigMeta.meta_id,
            "meta_value": asset_meta_value, //b.meta_value,
            "meta_text": asset_meta_value, //b.meta_text,
            "meta_name": selConfigMeta.meta_name,
            "asset_config_meta_key": selConfigMeta.asset_config_meta_key,
            "assetkey": assetkey,
            "created_by": getUserADAccount(this.props.user.account.userName)
        }

        temp_metas.push(meta)

        return temp_metas;
    }

    createUpdateAssetDTO = () => {
        const { assetkey, assetDetais, companyID } = this.state
        const updateDTO = {
            "company_id": companyID,
            "assetkey": assetkey,
            "modified_by": getUserADAccount(this.props.user.account.userName),
            "parent_assetkey": -1,
            "asset_name": assetDetais[0].asset_name,
            "status_id": assetDetais[0].status_id,
            "meta_datas": this.createUpdateAssetMetaDTO()
        }

        return updateDTO
    }

    updateAssetMeta = () => {
        //let myURI = apiURL + 'Asset/MetaAdd'      
        let myURI = apiURL + 'Asset/AssetUpdate'

        const u_meta_datas = this.createUpdateAssetDTO()

        console.log("assetDetails[0]")
        console.log(u_meta_datas)


        fetch(myURI, apiPOSTHeader(u_meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    // rspData.then(t => {
                    //     setMsgBody(t)
                    //     setMsgHeader("Error")
                    //     setModalMsg(!modalMsg)

                    // })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                this.getAssetMetas()
                this.toggleModal();

            }).catch(error => {
                // setMsgBody("PUT error: " + error)
                // setMsgHeader("Error")
                // setModalMsg(!modalMsg)
            })
    }


    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,

        })
    }

    componentDidMount() {
        this.getAssetDetails()
        this.getAssetMetas()
        this.getAssetConfig()
    }


    InputControl = () => {
        const { selConfigMeta } = this.state

        console.log("selConfigMeta")
        console.log(selConfigMeta)

        if (selConfigMeta !== undefined) {
            if (selConfigMeta !== null) {
                if (selConfigMeta.data_type === "number") {
                    return (<NumberFormat
                        value={this.state.asset_meta_value}
                        className={'form-control text-md-right'}
                        //fixedDecimalScale={true}
                        //decimalScale={2}
                        //displayType={(config_meta.is_readonly === 1 ? 'text' : '')}
                        onValueChange={(e) => this.onTextChange("meta_value",
                            e.value,
                            e)}
                        thousandSeparator={true}
                    //suffix={'%'}
                    />)
                } else if (selConfigMeta.data_type === "date") {
                    return (<DatePicker
                        value={this.state.asset_meta_value}
                        //disabled={(config_meta.is_readonly === 1)}
                        //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                        onChange={(v, f) => this.onTextChange("meta_value", v, f)}
                    />)
                }
            }
        }

        return (<Input type={(selConfigMeta.data_type === 'textarea' ? "textarea" : 'text')}
            value={this.state.asset_meta_value}
            onChange={(e) => this.onTextChange("meta_value", e.target.value, e)}>
        </Input>)
    }


    render() {
        const { asset_meta, asset_metas_filter, asset_meta_key,
            isLoaded, isFormReadOnly, selConfigId, selConfigMeta } = this.state

        console.log("this.state.selConfigId")
        console.log(this.state.selConfigId)

        if (!isLoaded) {
            return (<div>Loadding Asset Metas.....</div>)
        }
        return (<div>
            <Row form style={{ marginTop: "-10px", marginBottom: "10px" }}>
                <Col sm={6}>
                    {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                    <Button color="primary" outline className="float-left"
                        // disabled={isFormReadOnly}
                        // hidden={isFormReadOnly}
                        style={{ height: "40px", marginRight: "5px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.getAssetMetas()}
                    >Refresh</Button>

                    <Button color="primary" outline className="float-left"
                        // disabled={isFormReadOnly}
                        // hidden={isFormReadOnly}
                        style={{ height: "40px", marginRight: "5px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.addAssetMeta()}
                    >Add</Button>
                    <Button color="primary" outline className="float-left"
                        disabled={asset_meta_key < 0}
                        // hidden={isFormReadOnly}
                        style={{ height: "40px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.editAssetMeta()}
                    >Edit</Button>
                </Col>

                {/* </Row>
            <Row form style={{ marginTop: "5px", marginBottom: "2px" }}> */}
                <Col sm={6}>
                    <Input type="text" placeholder='Type here to search .....'
                        autoFocus
                        onChange={(e) => this.onTextChange("searchBox", e.target.value, e)}
                        className="float-left"
                    />
                </Col>
            </Row>

            <ColoredLine color="grey" />

            <Row form style={{ zIndex: 0, position: 'relative' }}>
                {/* <FormGroup> */}

                <IgrDataGrid
                    ref={this.onGridRef}
                    height="400px"
                    width='100%'
                    //autoFocus={true}
                    dataSource={asset_metas_filter}
                    isColumnOptionsEnabled="true"
                    autoGenerateColumns="false"
                    //headerClickAction="none"
                    selectionMode={"SingleRow"}
                    editModeClickAction="DoubleClick"
                    editMode={(isFormReadOnly ? 0 : 1)}
                    editOnKeyPress={"false"}
                    selectedKeysChanged={this.onGridRowSelected}
                    cellValueChanging={this.onCellValueChanging}
                >

                    <IgrTextColumn //IgrTemplateColumn
                        field="config_name"
                        headerText="Subgroup"
                        width="*>150"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="meta_name"
                        headerText="Property"
                        width="*>200"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="meta_text"
                        headerText="Value"
                        width="*>300"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="created_at"
                        headerText="Created At"
                        width="*>200"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="created_by"
                        headerText="Created By"
                        width="*>200"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="modified_at"
                        headerText="Modified at"
                        width="*>200"
                        cellUpdating={this.onCellUpdating}
                    />


                    <IgrTextColumn //IgrTemplateColumn
                        field="modified_by"
                        headerText="Modified By"
                        width="*>200"
                        cellUpdating={this.onCellUpdating}
                    />



                    {/* <IgrTextColumn field="codeDefinitionKey" headerText="Item Number"
                                                        /> */}

                    <IgrNumericColumn field="assetkey" isHidden />
                    <IgrTextColumn field="filter_tag" isHidden />
                    <IgrNumericColumn field="asset_meta_key" isHidden />
                    <IgrNumericColumn field="asset_config_key" isHidden />
                </IgrDataGrid>

                {/* </FormGroup> */}
            </Row>

            <div>
                <Modal isOpen={this.state.modal}
                    className="alway-on-top"
                    toggle={() => this.toggleModal()}
                    size="md"
                    //onClosed={() => this.getRiser()}
                    backdrop={"static"}
                >
                    <ModalHeader toggle={() => this.toggleModal()}>
                        New Property
                        {/* {(assetKey < 0 ? 'New ' + formHeaderText : selAsset.asset_id + ' - ' + selAsset.asset_name + ' Details')} */}
                    </ModalHeader>
                    <ModalBody>

                        <div>
                            <FormGroup>
                                <Row form style={{ marginBottom: "10px" }}>
                                    <Col md="4">
                                        <Label >Sub Group</Label>
                                    </Col>
                                    <Col md="8">
                                        <Select
                                            options={this.state.assetConfig}
                                            //isDisabled={(config_meta.is_readonly === 1)}
                                            //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                            //className={(!validateDataItem.locationKey ? "form-error" : "")}
                                            value={selConfigId}
                                            defaultValue={selConfigId}
                                            onChange={(e) => this.onTextChange("config_id", e, e)}
                                        //isClearable={true}

                                        >
                                        </Select>
                                    </Col>
                                </Row>

                                <Row form style={{ marginBottom: "10px" }}>
                                    <Col md="4">
                                        <Label >Property </Label>
                                    </Col>
                                    <Col md="8">
                                        <Select
                                            options={(this.state.selConfigId !== undefined ?
                                                     (isNaN(selConfigId.value) ? this.state.assetConfigMetas : this.state.assetConfigMetas.filter(e => e.asset_config_key === selConfigId.value))
                                                     : this.state.assetConfigMetas)}
                                            //isDisabled={(config_meta.is_readonly === 1)}
                                            //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                            //className={(!validateDataItem.locationKey ? "form-error" : "")}
                                            value={selConfigMeta}
                                            defaultValue={selConfigMeta}
                                            onChange={(e) => this.onTextChange("config_meta", e, e)}
                                        //isClearable={true}

                                        >
                                        </Select>
                                    </Col>
                                </Row>
                                <Row form style={{ marginBottom: "10px" }}>
                                    <Col md="4">
                                        <Label >Value </Label>
                                    </Col>
                                    <Col md="8">
                                        {this.InputControl()}

                                        {/* <Input type='text'
                                            value={this.state.asset_meta_value}
                                            onChange={(e) => this.onTextChange("meta_value", e.target.value, e)}>
                                        </Input> */}
                                    </Col>
                                </Row>
                            </FormGroup>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            outline
                            onClick={() => this.updateAssetMeta()}
                        >
                            OK
                        </Button>
                        {' '}
                        <Button
                            color="danger"
                            outline
                            onClick={() => this.toggleModal()}
                        >
                            Cancel
                        </Button>
                        {' '}

                    </ModalFooter>

                </Modal>
            </div>

        </div>)


    }
}