//Ready for to Push to Master from local Master 10/14/21
//Created by BN - 09/07/2021 


import React, { Component } from 'react';

import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, 
        FormFeedback, InputGroup } from 'reactstrap';
import { apiURL, apiHeader,apiPUTHeader,apiPOSTHeader,apiDELETEHeader } from '../resources/apiURL';

import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import ImageViewer from '../resources/ImageViewer';
import ConfirmModal from '../operation/ConfirmModal'

var DatePicker = require("reactstrap-date-picker");


export default class userDetails extends Component {
    static displayName = userDetails.name;

    constructor(props){
        super(props)
        this.textValue="Initial Value"
        this.state={
            userEmployee: [],
            empDetails: props.employeeDetails,
            grpDetails: props.groupDetails,
            isLoaded:false,
            location:[],
            empName:[],
            isLocationLoaded:false,
            // hireDate: new Date().toISOString().substring(0,10),
            hireDate: null,
            termDate:null,
            invalidDataItem:{
                wbrEmpID: true,
                firstName: true,
                lastName: true,
                commonName: true,
                empPosition: true,
                empType: true,
                emailAddress: true,
                adAccount: true,
                // adDomain: true,
                statusID: true,
                phoneNo: true,
                hireDate: true,
                azureAD: true,
                // locationName: true,
                managerKey:true,
                // empName:true,
                compObjectKey:true
            }
        }
        // console.log(props.employeeDetails)
    }
    
  
    closeForm = (refreshUserData) => {
       // if (bFreshData) this.props.refreshOilTicket();
       if(refreshUserData){
           this.props.refreshUserData()
       }
        this.props.toggle()
    }

    // deleteData = () =>{

    // }

    saveData= ()=>{
        const {empDetails} = this.state  
        if(this.validateDate()){            
            
            if (empDetails.employeeKey<0){
                this.createUser()
            }
            
            else if(empDetails.employeeKey>0){
                this.updateUser()
            }
            this.closeForm(true)
            
        }
            
     }
        // if (this.validateDate()){ }



        getUserData = () =>{
            const {user}=this.state;
            let myURI = apiURL + 'SecAdmin/User' 
            // /' + user.account.userName
    
            fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                this.setState({
                    isLoaded: true,
                    user: json
                })
            })
        }

        getUserLocation = () =>{
            let myURI = apiURL + 'SecAdmin/Location' 
            // /' + user.account.userName
            
            fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                this.setState({
                    isLocationLoaded: true,
                    location: json
                    
                })
            })
        }

        getManager = ()=> {
            
        let myURI = apiURL + 'SecAdmin/User' 
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                empName: json
            })
        })

        }
        
        
        
        
    createUser(){
        const {empDetails} = this.state   
        // console.log("Create user in user details")
        const userCreateDTO= this.createUserCreateDTO()
        // console.log(userCreateDTO)
      

          let myURI = apiURL + 'SecAdmin/User'
          fetch(myURI , apiPOSTHeader(userCreateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
              
            //   console.log("No error here: " + rspData)
            })
            .catch(error =>{
            //   console.log("POST Error: " + error);
            })
           

    }

    updateUser = () => {
        const {empDetails} = this.state   
        // console.log("Save user in user details")
        const userUpdateDTO= this.createUserUpdateDTO()
        // console.log(userUpdateDTO)
      

          let myURI = apiURL + 'SecAdmin/User'
          fetch(myURI , apiPUTHeader(userUpdateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
            //   console.log("No error here: " + rspData)
            })
            .catch(error =>{
            //   console.log("PUT Error: " + error);
            })
            
    }

    deleteUser = () => {
        const userDeleteDTO = this.createUserDeleteDTO()
        // console.log(userDeleteDTO)

        let myURI = apiURL + 'SecAdmin/User'

        fetch(myURI , apiDELETEHeader(userDeleteDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
            //   console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);
              
            }
    
            // console.log("No error here: " + rspData)
          })
          .catch(error =>{
            // console.log("DELETE Error: " + error);
          })

        this.closeForm(true)
    }

    createUserCreateDTO=()=>{
        // const {users,loadStatusKey} = this.state
        const {user} = this.props
        const {empDetails} = this.state   
        // console.log("oilTicket.comments")
        // console.log(oilTicket[0].comments)
        
        const userCreateDTO= {
        "wbrEmpID": empDetails.wbrEmpID,
        "compObjectKey": empDetails.compObjectKey,
        "locationName": empDetails.locationName,
        "deptKey": empDetails.deptKey,
        "firstName": empDetails.firstName,
        "lastName": empDetails.lastName,
        "commonName": empDetails.commonName,
        "empPosition": empDetails.empPosition,
        "empType": empDetails.empType,
        "emailAddress": empDetails.emailAddress,
        "adAccount": empDetails.adAccount,
        "adDomain": empDetails.adDomain,
        "azureAD": empDetails.azureAD,
        "managerKey": empDetails.managerKey,
        "empName": empDetails.empName,
        "statusID": empDetails.statusID,
        "memo": empDetails.memo,
        "phoneNo": empDetails.phoneNo,
        "hireDate": empDetails.hireDate,
        // "loadStatusKey": loadStatusKey,
        "createdBy": getUserADAccount(user.account.userName)
        //EMPLOYEE KEY OUTPUT?????
        }
        return userCreateDTO;

        
    }


createUserUpdateDTO=()=>{
        // const {users,loadStatusKey} = this.state
        // const {user} = this.props
        const {empDetails} = this.state   
        // console.log("oilTicket.comments")
        // console.log(oilTicket[0].comments)
    
        const {user} = this.props
        const userUpdateDTO= {
        "employeeKey": empDetails.employeeKey,
        "wbrEmpID": empDetails.wbrEmpID,
        "compObjectKey": empDetails.compObjectKey,
        "deptKey": 0,
        "firstName": empDetails.firstName,
        "lastName": empDetails.lastName,
        "commonName": empDetails.commonName,
        "locationName": empDetails.locationName,
        "empPosition": empDetails.empPosition,
        "empType": empDetails.empType,
        "emailAddress": empDetails.emailAddress,
        "adAccount": empDetails.adAccount,
        "adDomain": empDetails.adDomain,
        "statusID": empDetails.statusID,
        "memo": empDetails.memo,
        "azureAD": empDetails.azureAD,
        "managerKey": empDetails.managerKey,
        "empName": empDetails.empName,
        "phoneNo": empDetails.phoneNo,
        "hireDate": empDetails.hireDate,
        "termDate":empDetails.termDate,
        // "loadStatusKey": loadStatusKey,
        "updatedBy":  getUserADAccount(user.account.userName)
        }
      
        return userUpdateDTO;
    }

    createUserDeleteDTO=()=>{
        const {empDetails} = this.state 
        const {user} = this.props
        const userDeleteDTO= {
            "employeeKey" : empDetails.employeeKey,
            "deletedBy": getUserADAccount(user.account.userName)
        }
        return userDeleteDTO;
    }

    validateDate = () => {
        const {invalidDataItem,empDetails} = this.state 
        var bVal = true 
        var valItem = invalidDataItem
  
        if (empDetails.wbrEmpID === null || empDetails.wbrEmpID == "") {
            bVal = false    
            valItem.wbrEmpID= false
        }
        if (empDetails.compObjectKey == 0){
            bVal = false
            valItem.compObjectKey= false
        }
  
        if (empDetails.firstName == ""){
            bVal = false
            valItem.firstName= false
        }
        if (empDetails.lastName == ""){
            bVal = false
            valItem.lastName= false
        }
        if (empDetails.commonName == ""){
            bVal = false
            valItem.commonName= false
        }
        if (empDetails.empPosition == ""){
            bVal = false
            valItem.empPosition= false
        }
        if (empDetails.empType == ""){
            bVal = false
            valItem.empType= false
        }
        if (empDetails.emailAddress== ""){
            bVal = false
            valItem.emailAddress= false
        }
        // if (empDetails.locationName== 0){
        //     bVal = false
        //     valItem.locationName= false
        // }
        if (empDetails.adAccount== ""){
            bVal = false
            valItem.adAccount= false
        }
        // if (empDetails.adDomain == 0){
        //     bVal = false
        //     valItem.adDomain= false
        // }
        if (empDetails.statusID == ""){
            bVal = false
            valItem.statusID= false
        }
        // if (empDetails.empName == ""){
        //     bVal = false
        //     valItem.empName= false
        // }
        if (empDetails.azureAD == ""){
            bVal = false
            valItem.azureAD= false
        }
        if (empDetails.phoneNo == ""){
            bVal = false
            valItem.phoneNo= false
        }
        if (empDetails.hireDate == ""){
            bVal = false
            valItem.hireDate= false
        }
        if (empDetails.managerKey === null || empDetails.managerKey == 0){
            bVal = false
            valItem.managerKey= false
        }
  
       
        this.setState({invalidDataItem:valItem})
  
        // console.log("bVal")        
        // console.log(invalidDataItem)
        return bVal
    }


    onTextChange=(fieldName,fieldValue,e)=>{
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const {empDetails,invalidDataItem}=this.state;
        // if (fieldName==="ticketNo"){
            
        // }       
        
        switch(fieldName){
            case "employeeKey":
                empDetails.employeeKey=fieldValue
                // invalidDataItem.ticketNo=(fieldValue != "")
                break;
            case "wbrEmpID":
                empDetails.wbrEmpID = fieldValue
                // this.setState({reportDate:fieldValue})
                invalidDataItem.wbrEmpID=(fieldValue != "") 
                break;
            case "compObjectKey":
                empDetails.compObjectKey=fieldValue
                invalidDataItem.compObjectKey=true
                break;
            case "deptKey":
                empDetails.deptKey=fieldValue
                break;
            case "firstName":
                empDetails.firstName=fieldValue 
                empDetails.commonName = fieldValue
                empDetails.emailAddress = fieldValue 
                empDetails.azureAD = fieldValue
                invalidDataItem.firstName=(fieldValue != "") 
                invalidDataItem.commonName=(fieldValue != "") 
                break;
            case "lastName":
                empDetails.lastName=fieldValue
                empDetails.adAccount = empDetails.commonName + "." + fieldValue
                empDetails.emailAddress = empDetails.commonName + "." + fieldValue + "@h2obridge.com"
                empDetails.azureAD = empDetails.commonName + "." + fieldValue + "@h2obridge.com" 
                invalidDataItem.lastName=(fieldValue != "")
                invalidDataItem.adAccount=(fieldValue != "")
                break;
            case "commonName":
                empDetails.commonName = fieldValue 
                empDetails.adAccount = fieldValue + "." + empDetails.lastName
                invalidDataItem.commonName=(fieldValue != "")
                invalidDataItem.adAccount=(fieldValue != "")
                break;
            case "locationName":
                empDetails.locationName = fieldValue
                // invalidDataItem.locationName=(fieldValue != "")
                invalidDataItem.locationName=true
                break;
            case "empPosition":
                empDetails.empPosition = fieldValue
                // invalidDataItem.empPosition=(fieldValue != "")
                invalidDataItem.empPosition=true
                
                break;
            case "empType":
                empDetails.empType = fieldValue
                invalidDataItem.empType=(fieldValue != "")
                break;
            case "emailAddress":
                empDetails.emailAddress = fieldValue 
                empDetails.azureAD = fieldValue 
                // empDetails.adAccount = fieldValue
                invalidDataItem.emailAddress=(fieldValue != "")
                invalidDataItem.azureAD=(fieldValue != "")
                break;

            case "adAccount":
                empDetails.adAccount = fieldValue
                empDetails.emailAddress = fieldValue + "@h2obridge.com"
                empDetails.azureAD = fieldValue + "@h2obridge.com"              
                invalidDataItem.adAccount=(fieldValue != "")
                break;
            // case "adDomain":
            //     empDetails.adDomain = fieldValue
            //     invalidDataItem.adDomain=(fieldValue != "")
            //     // invalidDataItem.truckByKey=true
            //     break;
            case "azureAD":
                empDetails.azureAD = fieldValue  
                empDetails.emailAddress = fieldValue 
                invalidDataItem.azureAD=(fieldValue != "")           
                break;
            case "managerKey":
                    empDetails.managerKey = fieldValue
                    invalidDataItem.managerKey=true            
                    break;
            case "empName":
                empDetails.empName= fieldValue
                
                invalidDataItem.empName=(fieldValue != "")           
                break;

            case "statusID":
                empDetails.statusID = fieldValue
                invalidDataItem.statusID=(fieldValue != "")
                break;  
            case "Memo":
                empDetails.memo = fieldValue
                    break;              
            case "phoneNo":
                empDetails.phoneNo= fieldValue
                invalidDataItem.phoneNo=(fieldValue != "")
                        break;
            case "hireDate":
                empDetails.hireDate= fieldValue
                this.setState({hireDate:fieldValue})
                invalidDataItem.hireDate=(fieldValue != "")
                            break; 
            case "termDate":
                empDetails.termDate= fieldValue
                this.setState({termDate:fieldValue})
                                break;
            case "updatedBy":
                empDetails.updatedBy = fieldValue
                                    break;  


            default:

        }
        
        this.setState({empDetails:empDetails,  invalidDataItem:invalidDataItem})
    }
    handlehireDateChange = (v,f) => {
        this.setState({
            hireDate:v
        })
    }
    handletermDateChange = (v,f) => {
        this.setState({
            termDate:v
        })
    }

    componentDidMount(){
        
        this.getUserLocation();
        this.getManager();
        //this.getUserData();
        // this.getUserLocation();
    }
    render(){
        const {empDetails, userEmployee,location, isLocationLoaded, hireDate, invalidDataItem, empName}=this.state
        const {user, isLoaded} = this.props
        if (!isLocationLoaded){
            return (<div>
                <h3>Loading Employee Locations.....</h3>
            </div>)
        }else
        return (<div>
            <Form>

            <FormGroup >
            <Row form>
                    <Col md={6}>
                        <FormGroup >
                            <Label for="wbrEmpID">Employee ID</Label>
                            <Input  style={{ margin: "-5px 0 0 0" }} type="text" name="wbrEmpID" id="wbrEmpID" 
                            invalid={!invalidDataItem.wbrEmpID}
                             defaultValue={empDetails.wbrEmpID}
                             value={empDetails.wbrEmpID}
                             placeholder="" 
                             onChange={(e) => this.onTextChange("wbrEmpID",e.target.value,e)} 
                              />
                              <FormFeedback tooltip > Please enter Employee ID</FormFeedback>
                        </FormGroup>
                    </Col>

                    
                    <Col md={6}>
                        <FormGroup>
                            <Label for="StatusID">Status</Label>
                            {/* <Input type="select" name="statusID" id="statusID" /> */}                                                         
                              < CustomInput   style={{ margin: "-5px 0 0 0" }}  type="select" 
                            //   invalid={!invalidDataItem.statusID}            
                              id="statusID"
                              name="statusID"
                              defaultValue={empDetails.statusID}         
                              value={empDetails.statusID}    
                              onChange={(e) => this.onTextChange("statusID",e.target.value,e)}                                     
                              >                       
                              {/* <option value= ""></option> */}
                              <option value= "A">Active</option> 
                              <option value= "T">Terminate</option>
                              <option value= "S">Suspend</option>
                        
                           
                                                       
                           </CustomInput>        
                            
                            
                           
                        </FormGroup>
                    </Col>
           
            </Row>



            <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="firstName">First Name</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="firstName" id="firstName" 
                             invalid={!invalidDataItem.firstName}
                            defaultValue={empDetails.firstName}
                            value={empDetails.firstName}
                            placeholder=""
                            onChange={(e) => this.onTextChange("firstName",e.target.value,e)} />
                             <FormFeedback tooltip > Please enter First Name</FormFeedback>
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="lastName">Last Name</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="lastName" id="lastName" 
                            invalid={!invalidDataItem.lastName}
                            defaultValue={empDetails.lastName}
                            value={empDetails.lastName}
                            placeholder=""
                            onChange={(e) => this.onTextChange("lastName",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Last Name</FormFeedback>
                        </FormGroup>
                    </Col>
        
            </Row>


            <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="CommonName">Common Name</Label>

                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="CommonName" id="CommonName" 
                            invalid={!invalidDataItem.commonName}
                             defaultValue={empDetails.commonName}
                             value= {empDetails.commonName}
                            placeholder=""
                            onChange={(e) => this.onTextChange("commonName",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Common Name</FormFeedback>                               

                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="compObjectKey">location Name</Label>
                            <CustomInput style={{ margin: "-5px 0 0 0" }}  type="select" name="compObjectKey" id="compObjectKey" 
                            invalid={!invalidDataItem.compObjectKey}
                            defaultValue={empDetails.compObjectKey}
                            value={empDetails.compObjectKey}
                            onChange={(e) => this.onTextChange("compObjectKey",e.target.value,e)}
                            > 
                             <option value={-100}></option> 
                            {location.map(v => <option value={v.compObjectKey}>{v.locationName}</option>)} 
                            </CustomInput>
                            <FormFeedback tooltip > Please select Location</FormFeedback>
                        </FormGroup>
                    </Col>

            </Row>

           
            <Row form>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="Email">Email</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="Email" id="Email" 
                            invalid={!invalidDataItem.emailAddress}
                            // defaultValue={empDetails.emailAddress + "@h2obridge.com" }
                            defaultValue={empDetails.emailAddress  }
                            value={empDetails.emailAddress}                   
                            onChange={(e) => this.onTextChange("emailAddress",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Email</FormFeedback>
                        </FormGroup>
                    </Col>
        
                    <Col md={6}>
                        <FormGroup>
                            <Label for="PhoneNo">Phone #</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}   type="text" name="PhoneNo" id="PhoneNo" 
                            invalid={!invalidDataItem.phoneNo}
                            defaultValue={empDetails.phoneNo}
                            value={empDetails.phoneNo}
                            placeholder=""
                            onChange={(e) => this.onTextChange("phoneNo",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Phone Number</FormFeedback>
                        </FormGroup>
                    </Col>
            </Row>

            <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="EmpType">Employee Type</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="EmpType" id="EmpType" 
                            invalid={!invalidDataItem.empType}
                            defaultValue={empDetails.empType}
                            value={empDetails.empType}
                            placeholder="" 
                            onChange={(e) => this.onTextChange("empType",e.target.value,e)}/>
                            <FormFeedback tooltip > Please enter Employee Type</FormFeedback>
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="EmpPosition">Employee Position</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="EmpPosition" id="EmpPosition" 
                            invalid={!invalidDataItem.empPosition}
                            defaultValue={empDetails.empPosition}
                            value={empDetails.empPosition}
                            placeholder=""
                            onChange={(e) => this.onTextChange("empPosition",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Employee Position</FormFeedback>
                        </FormGroup>
                    </Col>
            </Row>

            <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="ADAccount">AD Account</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="ADAccount" id="ADAccount" 
                            invalid={!invalidDataItem.adAccount} 
                            defaultValue={empDetails.adAccount}
                            value={empDetails.adAccount}
                            placeholder=""
                            
                            onChange={(e) => this.onTextChange("adAccount",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter AD Account</FormFeedback>
                        </FormGroup>
                    </Col>

                    

                    <Col md={6}>
                        <FormGroup>
                            <Label for="ADDomain">AD Domain</Label>
                            <Input style={{ margin: "-5px 0 0 0" }} type="text" name="ADDomain" id="ADDomain" 
                            disabled
                            defaultValue={empDetails.adDomain}
                            value={empDetails.adDomain}
                            placeholder="WBR.local"
                            onChange={(e) => this.onTextChange("adDomain",e.target.value,e)} />
                        </FormGroup>
                    </Col>


            </Row>
            <Row Form>

            <Col md={6}>
                    <FormGroup>
                            <Label for="empName">Managers</Label>
                            <CustomInput style={{ margin: "-5px 0 0 0" }}   type="select" name="empName" id="empName" 
                            invalid={!invalidDataItem.managerKey}
                            defaultValue={empDetails.managerKey}
                            value={empDetails.managerKey}
                            placeholder=""
                            onChange={(e) => this.onTextChange("managerKey",e.target.value,e)}> 
                             <option value={-100}></option> 
                            {empName.map(v => <option value={v.employeeKey}>{v.empName}</option>)} 
                            </CustomInput>
                            <FormFeedback tooltip > Please select Manager</FormFeedback>
                        </FormGroup>
                    </Col>

                    {/* <Col md={6}>
                        <FormGroup>
                            <Label for="locationName">locationName</Label>
                            <CustomInput  type="select" name="locationName" id="locationName" 
                            defaultValue={empDetails.compObjectKey}
                            value={empDetails.compObjectKey}
                            placeholder=""
                            onChange={(e) => this.onTextChange("compObjectKey",e.target.value,e)}> 
                             <option value={-100}></option> 
                            {location.map(v => <option value={v.compObjectKey}>{v.locationName}</option>)} 
                            </CustomInput>
                        </FormGroup>
                    </Col> */}

             <Col md={6}>
                        <FormGroup>
                            <Label for="azureAD">Azure AD</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="text" name="azureAD" id="azureAD" 
                            invalid={!invalidDataItem.azureAD} 
                            defaultValue={empDetails.azureAD}
                            value={empDetails.azureAD}
                            placeholder=""
                            onChange={(e) => this.onTextChange("azureAD",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Azure AD</FormFeedback>
                        </FormGroup>
                    </Col>

            </Row>

            

        
         
         

            <Row form>
                    {/* <Col md={6}>
                        <FormGroup>
                            <Label for="HireDate">Hire Date</Label>
                            <Input type="text" name="HireDate" id="HireDate" 
                            invalid={!invalidDataItem.hireDate}
                            defaultValue={empDetails.hireDate}
                            value={empDetails.hireDate.substring(0,10)}
                            onChange={(e) => this.onTextChange("hireDate",e.target.value,e)} 
                            
                            />
                        </FormGroup>
                    </Col> */}

                
                    <Col md={6}>
                        <FormGroup>
                            <Label for="HireDate">Hire Date</Label>
                            <DatePicker   id="HireDate"  
                            invalid={!invalidDataItem.hireDate}  
                            // defaultValue={empDetails.hireDate}
                            value={empDetails.hireDate}
                            onChange={(v, f) => this.onTextChange("hireDate",v,f)} 
                            // onChange={(e) => this.onTextChange("hireDate",e.target.value,e)} 
                            />
                            
                           
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label for="TermDate">Term Date</Label>
                            <DatePicker  id="TermDate"

                            hidden={!(empDetails.employeeKey>0)}

                            value={empDetails.termDate}
                            // defaultValue={empDetails.termDate}
                            onChange={(v,f) => this.onTextChange("termDate",v,f)}
                             />
                        </FormGroup>
                    </Col>
            </Row>
            <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="Memo">Memo</Label>
                            <Input style={{ margin: "-5px 0 0 0" }}  type="textarea" name="Memo" id="Memo" rows="2" 
                            defaultValue={empDetails.memo}
                            value={empDetails.memo}
                            placeholder=""
                            onChange={(e) => this.onTextChange("Memo",e.target.value,e)} />
                        </FormGroup>
                    </Col>
            </Row>






{/* Edited by --BN 9/19/21 */}
                    <Row>
                    {/* <Col md={3}>

                  
                     </Col>                     */}
                    <Col md={6}>
                        <ConfirmModal outline={true}
                                  color="danger"
                                  hidden = {!(empDetails.employeeKey>0)}
                                  className="float-right"
                                  buttonLabel="Delete"
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected employee?"
                                  onClickYes={(e) => this.deleteUser()}
                                  image={<img
                                    src={TrashBin}
                                   // id={facilityOilTicketKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }}/>}>
                        </ConfirmModal>    

                    </Col>

                     <Col sm={6}>
                     <Button color="warning" className="float-right" 
                             onClick={() => this.closeForm(true)}>Cancel</Button>
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> this.saveData()}>Save</Button>
                     </Col>
                     </Row>
                </FormGroup>
            </Form>

 

    
           

            
        </div>)


       
    }

    
}
