import React, { Component, useEffect, useReducer, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

import MenuIcon from '../../resources/menu.png';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Excel from '../../resources/excel.svg'
import Revise from '../../resources/revise.png'
import '../../custom.css';
import { ExcelUtility } from '../../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import ImageViewer from '../../resources/ImageViewer';
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import ConfirmModal from '../../operation/ConfirmModal'
import ConfirmWithMsgModal from '../../resources/ConfirmWithMsgModal'

import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import { useSyncExternalStore } from 'react';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


export default class InvoiceBatchCRUD extends Component {
    static displayName = InvoiceBatchCRUD.name

    constructor(props) {
        super(props);
        document.title = "Waterbridge Invoice Batch"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        // this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        // this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            invBatch: [],
            isLoaded: false,
            invoiceBatchKey: -1,
            modal: false,
            user: props.user,
            windowHeight: window.innerHeight,
            invoiceType: [],
            invoiceTypeLoaded: false,
            statusKey: -1
        }

    }
    onTextChange = (fieldName, fieldValue, e) => {

        switch (fieldName) {
            case "invoiceType":
                break;
            default:
                break;
        }
    }
    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)

            // this.setState({
            //     supplierRequestKey: dataItem.supplierRequestKey,
            //     selSupReqRec: dataItem,
            //     statusKey: dataItem.statusKey
            // })
            // this.setState({selSafeObs: dataItem})
            // this.toggle()
            //this.onVolRefresh()    
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.invBatch;
    }

    getInvBatch = () => {

    }

    getInvType = () => {

    }

    componentDidMount() {
        this.getInvType()
        this.getInvBatch()
    }

    render() {
        const { invBatch, invoiceType,windowHeight } = this.state
        const gridHeight = (windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()

        return (<div>
            <Form onSubmit={e => e.preventDefault}>
                <FormGroup>
                    <FormGroup>
                        <Row form>
                            <Col md={6}>
                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={4}>
                                        <Label for="typeKey">Invoice Type</Label>
                                    </Col>

                                    <Col md={8}>
                                        <Select
                                            //options={entity}
                                            //className={(!valItem.entity ? "form-error" : "")}
                                            // isDisabled={this.isFormReadOnly() || statusKey === 4}
                                            // className={(!validateDataItem.typeKey ? "form-error" : "")}
                                            //value={this.getSelAFEType(afeData[0].typeKey)}
                                            //defaultValue={getSelEntity(reqData.entity)}
                                            onChange={(e) => this.onTextChange("entity", e, e)}
                                            isClearable={true}

                                        >
                                        </Select>

                                    </Col>

                                </Row>

                            </Col>
                            <Col md={6}>
                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={4}>
                                        <Label for="typeKey">Invoice Date</Label>
                                    </Col>

                                    <Col md={8}>
                                        <Select
                                            //options={entity}
                                            //className={(!valItem.entity ? "form-error" : "")}
                                            // isDisabled={this.isFormReadOnly() || statusKey === 4}
                                            // className={(!validateDataItem.typeKey ? "form-error" : "")}
                                            //value={this.getSelAFEType(afeData[0].typeKey)}
                                            //defaultValue={getSelEntity(reqData.entity)}
                                            onChange={(e) => this.onTextChange("entity", e, e)}
                                            isClearable={true}

                                        >
                                        </Select>

                                    </Col>

                                </Row>

                            </Col>

                            <></>

                        </Row>
                        <ColoredLine color="grey" />
                        <FormGroup>
                            <Row form>
                                <Col sm={8}>
                                    {/*                         
                            <Button outline color="secondary"
                                className="btn-no-border"
                                onClick={this.saveWorkbook}>
                                <img
                                    src={Excel}
                                    alt="excel"
                                    style={{ width: "50px", margin: "0 0 0 0" }} />
                                Export To Excel
                            </Button>{' '} */}

                                    <Button outline color="secondary"
                                        className="btn-no-border"
                                        onClick={(e) => this.newSupReq()}
                                    >
                                        <img
                                            src={Plus}
                                            alt="new"
                                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                                        Add
                                    </Button>{' '}

                                    <Button outline color="secondary"
                                        className="btn-no-border"
                                        onClick={this.supReqDetails}
                                    //disabled={supplierRequestKey < 0}
                                    >
                                        <img
                                            src={Revise}
                                            alt="details"
                                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                                        View Details
                                    </Button>{' '}

                                </Col>
                                <Col sm={4}>
                                    <ConfirmModal outline
                                        color="danger"
                                        className="float-right"
                                        buttonLabel="Delete"
                                        //hidden={!(statusKey === 0 && afeHeaderKey > 0)}
                                        formName="Confirm"
                                        //disabled={supplierRequestKey < 0 || statusKey > 0}
                                        message="Are you sure you want to delete selected Supplier Request?"
                                        onClickYes={(e) => this.deleteSupplierReq()}
                                        image={<img
                                            src={TrashBin}
                                            //id={afeHeaderKey}
                                            alt="Edit"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                    </ConfirmModal>
                                </Col>
                            </Row>

                        </FormGroup>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={gridHeight}
                        dataSource={invBatch}
                        // dataSource={supplierReq.filter(rec => ((rec.entity || '').toLowerCase().includes(searchText)) ||
                        //     (rec.supplierReqID || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplierName || '').toLowerCase().includes(searchText) ||
                        //     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplierCategory || '').toLowerCase().includes(searchText) ||
                        //     (rec.contactName || '').toLowerCase().includes(searchText) ||
                        //     (rec.phoneNo || '').toLowerCase().includes(searchText) ||
                        //     (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                        //     (rec.createdByName || '').toLowerCase().includes(searchText) ||
                        //     (rec.comments || '').toLowerCase().includes(searchText) ||
                        //     (rec.scopOfWork || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                    //cellBackground={"#ffbfbf"}
                    >


                        <IgrTemplateColumn field="entity" headerText="Entity " width="*>180"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="supplierReqID" headerText="Supplier Request ID" width="*>180"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTextColumn field="supplierName" headerText="Supplier Name"
                            width="*>240"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="statusDesc" headerText="Request Status"
                            width="*>140" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="supplierCategory" headerText="Category"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="contactName" headerText="Contact Name"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="phoneNo" headerText="Phone No"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="emailAddress" headerText="Email Address"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="serviceType" headerText="Service Type"
                            width="*>140" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="isOffSite" headerText="Is Off Site"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="scopOfWork" headerText="Scop Of Work"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="createdByName" headerText="Requested By"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="createdDatetime" headerText="Created Date"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="comments" headerText="Comments"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrNumericColumn field="supplierRequestKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>

            </Form>
        </div>)
    }
}