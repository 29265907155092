
import React, { Component } from 'react';

import { Form, Card, CardHeader, CardBody, CardFooter, Col, Row, FormGroup, Label, Input, CustomInput, Button, FormFeedback } from 'reactstrap';
import { apiURL, apiHeader } from '../../resources/apiURL';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import '../../custom.css';

import { getUserADAccount } from '../../resources/utility'
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";

import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import classnames from 'classnames';
import Select from 'react-select';

import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import FormJSAJobTask from './FormJSAJobTask';
import FormJSASignatures from './FormJSASignatures';

import jsabanner01 from '../../resources/jsabanner01.png'
import jsabanner02 from '../../resources/jsabanner02.png'
import jsabanner03 from '../../resources/jsabanner03.png'
import jsabanner04 from '../../resources/jsabanner04.png'
import jsabanner05 from '../../resources/jsabanner05.png'
import jsabanner06 from '../../resources/jsabanner06.png'
import jsabanner07 from '../../resources/jsabanner07.png'
import jsabanner08 from '../../resources/jsabanner08.png'




export default class FormJSA extends Component {
    static displayName = FormJSA.name;


    constructor(props) {
        super(props);
        // this.onGridRef = this.onGridRef.bind(this);
        // this.getAlert=this.getAlert.bind(this);


        // const urlPara = this.getURLParameters(props.location)
        // console.log("loc search")
        // console.log(this.props.location.search)

        this.state = {
            isValidatedFormJSAJobTask: false,
            childValue: '',
            data: [],
            hdrData: [],
            isLoaded: false,
            isHdrLoaded: false,
            // facilityInspLineItemForUpdateDTO:[],
            // facilityComments:'',
            // inspComments:'',
            reloadForm: false,
            safIncHeaderKey: 0,
            // safIncLineKey: urlPara.safIncLineKey,
            safIncLineKey: props.safIncLineKey,
            ddEmployees: [],
            activeTab: '1',
            isddEmployeesLoaded: false,
            isReadOnly: props.isReadOnly,
            isAdmin: props.isAdmin,

            user: props.user,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",

            ddTemplates: [],
            isddTemplatesLoaded: false,





            validateDataItem: {
                iunderstand: true,
                addtlEquipment: true,
                hazEnergyControl: true
                // empShift:true
            }
        }
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }









    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.employeeKey
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }


    createEmpOtherList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.empname
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }



    getSelEmp = (v) => {
        const { ddEmployees } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return ddEmployees.filter(e => e.employeeKey === Number(v));
        }
        return ""

    }


    getSelEmpOther = (v) => {
        const { ddEmployees } = this.state
        console.log("xzdczxczxc")
        console.log(v)
        if (v !== null && v !== "") {
            console.log("this hit")
            console.log(ddEmployees)
            return ddEmployees.filter(e => v.includes(e.empName));
        }
        return ""

    }



    getSelName = (v) => {
        if (v.length > 0) {
            return v[0].empName;
        }
        return ""


    }


    createTemplList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.jsaTemplateKey
            e.label = e.templateName
            tempList.push(e)
        })

        return tempList
    }


    getSelTempl = (v) => {
        const { ddTemplates } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return ddTemplates.filter(e => e.jsaTemplateKey === Number(v));
        }
        return ""

    }


    isUserEmployee = () => {
        const { user, data } = this.state
        //var emp = this.getSelName(this.getSelEmp(data[0].employeeKey));
        var emp = data[0].createdByAD.toLowerCase();

        var adaccount = getUserADAccount(user.account.userName).toLowerCase();


        // console.log(emp)
        // console.log(adaccount)


        if (emp === adaccount)
            return true
        else
            return false





    }

    bMultiField = (value, checkValue) => {
        return (value & checkValue) === checkValue
    }


    getInspHdrData = (intKey) => {
        const { isLoaded } = this.state

        // console.log("safIncHeaderKey")
        // console.log(intKey)

        let myURI = apiURL + 'SafetyIncident/SafIncHeaderKey/' + intKey
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    hdrData: json,
                    isHdrLoaded: true
                });
                // console.log(this.state.hdrData);
            });

    }

    // getInpsData = () => {
    //     const { safIncLineKey, isLoaded } = this.state
    //     let myURI = apiURL + 'SafetyIncident/FormJSAKey/' + safIncLineKey

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {

    //             this.setState({
    //                 data: json,
    //                 isLoaded: true,
    //                 safIncHeaderKey: json[0].safIncHeaderKey


    //                 //safIncHeaderKey:json[0].safIncHeaderKey
    //                 // inspComments:json[0].inspComments,
    //                 // facInspComments:json[0].facInspComments
    //             },()=>{
    //                 console.log(json[0].safIncHeaderKey)
    //                 // if (json.length > 0) {
    //                     this.getInspHdrData(this.state.safIncHeaderKey)

    //                 // }
    //             }

    //             );
    //             console.log(json)





    //             // console.log("---------")
    //             // console.log(this.state.data)

    //             // this.getInspHdrData(json[0].safIncHeaderKey)


    //         });
    // }

    getInpsData = () => {
        const { safIncLineKey } = this.state; // Removed isLoaded since it's not used in the snippet
        console.log("safIncLineKey: " + safIncLineKey)
        let myURI = apiURL + 'SafetyIncident/FormJSAKey/' + safIncLineKey;

        fetch(myURI, { modes: 'no-cors' })// Corrected "modes" to "mode"
            .then(res => res.json())
            .then(json => {
                // Check if json is not empty and safIncHeaderKey exists in the first object
                const safIncHeaderKey = json.length > 0 ? json[0].safIncHeaderKey : undefined;

                if (safIncHeaderKey !== undefined) {
                    this.setState({
                        data: json,
                        isLoaded: true,
                        safIncHeaderKey: safIncHeaderKey
                    }, () => {
                        console.log(safIncHeaderKey);
                        // Now that we're sure safIncHeaderKey is defined, call getInspHdrData
                        this.getInspHdrData(this.state.safIncHeaderKey);
                    });
                } else {
                    // Handle the case where safIncHeaderKey is undefined
                    console.error("safIncHeaderKey is undefined.");
                    // Consider setting state to reflect the error condition or taking other actions
                }
            });
    }


    getddEmployees = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyObservation/SubmitBy'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isddEmployeesLoaded: true,
                    ddEmployees: json
                })
            });
    }



    getddTemplates = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetJSATemplate'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isddTemplatesLoaded: true,
                    ddTemplates: json
                })
            });
    }


    // onGridRef(grid) {
    //   if (!grid) {
    //     return;

    //   }
    //   this.grid = grid;
    //   this.grid.dataSource = this.state.tblWitness;

    //   //this.grid.cells[0]
    //   console.log(this.grid.cellBackground)


    // }

    validateDataForSubmit = () => {
        //in JSA form
        // this.props.onValidate("dfff")
        // val kk = subform value zz
        const { validateDataItem, data } = this.state
        var bVal = true
        var valItem = validateDataItem
        if (data[0].iunderstand === null || data[0].iunderstand === 0) {
            valItem.iunderstand = false
            bVal = false
        }

        if (!this.validateJSAJobTaskForSubmit()) {
            bVal = false
        }

        if (data[0].addtlEquipment === null || data[0].addtlEquipment === 0) {
            valItem.addtlEquipment = false
            bVal = false
        }

        if (data[0].hazEnergyControl === null || data[0].hazEnergyControl === 0) {
            valItem.hazEnergyControl = false
            bVal = false
        }







        this.setState({ validateDataItem: valItem })
        return bVal

    }

    componentDidMount() {
        this.props.saveJSA(this.saveData);
        this.props.validateJSAForSubmit(this.validateDataForSubmit);
        this.getInpsData()
        this.getddEmployees()
        this.getddTemplates()

        //this.getInspHdrData() ...

    }


    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.lineStatusKey === 4) {
                content.style.background = "#b6a065";
            } else if (item.lineStatusKey === 6) {
                content.style.background = "#61a066";
            } else if (item.lineStatusKey === 3 || item.lineStatusKey === 5) {
                content.style.background = "#ad5f55";
            } else {
                content.style.background = "transparent";
            }
        }


        switch (s.field) {
            case 'name':
                link.textContent = item.name;
                break;
            case 'position':
                link.textContent = item.position;
                break;


            default:

        }

    }







    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const { data, validateDataItem } = this.state;

        // /cbincEquipmentDamage
        //incClassif

        switch (fieldName) {

            case "incDateTime":
                data[0].incDateTime = fieldValue;
                break;

            case "comments":
                data[0].comments = fieldValue
                break;
            case "employeeKey":
                if (fieldValue != null) {

                    data[0].employeeKey = fieldValue.value
                } else {
                    data[0].employeeKey = null

                }
                data[0].iunderstand = 0
                break;

            case "shift":
                data[0].empShift = fieldValue

                // validDataItem.costTypeKey = true
                break;

            case "contactSupervisorKey":
                if (fieldValue != null) {

                    data[0].contactSupervisorKey = fieldValue.value
                } else {
                    data[0].contactSupervisorKey = null

                }
                break;

            case "contactSupervisorText":
                data[0].contactSupervisorText = fieldValue
                break;


            case "contactHSE":
                if (fieldValue != null) {

                    data[0].contactHSE = fieldValue.value
                } else {
                    data[0].contactHSE = null

                }
                break;

            case "contactHSEText":
                data[0].contactHSEText = fieldValue
                break;






            case "contactOtherText":
                data[0].contactOtherText = fieldValue
                break;

            case "iunderstand":
                data[0].iunderstand = e ? 1 : 0;
                data[0].iunderstand === 1 ? validateDataItem.iunderstand = true : validateDataItem.iunderstand = false;
                break;

            case "templateKey":

                if (window.confirm('Use this template? Existing job/tasks will be replaced')) {


                    if (fieldValue != null) {

                        data[0].templateKey = fieldValue.value
                    } else {
                        data[0].templateKey = null

                    }

                    this.insertFromTemplate(data[0].templateKey)
                    // this.deleteWitness(wkey);
                    // e.preventDefault();
                } else {
                    // e.preventDefault();
                }

                break;



            case "addtlEquipment":
                if (this.bMultiField(data[0].addtlEquipment, fieldValue)) {
                    data[0].addtlEquipment = data[0].addtlEquipment - fieldValue
                } else {
                    data[0].addtlEquipment = data[0].addtlEquipment + fieldValue
                }

                validateDataItem.addtlEquipment = data[0].addtlEquipment > 0;

                //validateDataItem.riskInvolvePeopleValue=true
                break;




            case "addtlEquipmentRespiratory":
                data[0].addtlEquipmentRespiratory = fieldValue
                break;

            case "addtlEquipmentOther":
                data[0].addtlEquipmentOther = fieldValue
                break;

            case "enviroWeather":
                data[0].enviroWeather = fieldValue
                break;

            case "enviroTerrain":
                data[0].enviroTerrain = fieldValue
                break;

            case "enviroWildlife":
                data[0].enviroWildlife = fieldValue
                break;

            case "emergencyone":
                data[0].emergencyone = fieldValue
                break;
            case "emergencytwo":
                data[0].emergencytwo = fieldValue
                break;
            case "emergencythree":
                data[0].emergencythree = fieldValue
                break;
            case "permitsreq1":
                data[0].permitsreq1 = fieldValue
                break;
            case "permitsreq2":
                data[0].permitsreq2 = fieldValue
                break;
            case "permitsreq3":
                data[0].permitsreq3 = fieldValue
                break;

            case "atmosphere1":
                data[0].atmosphere1 = fieldValue
                break;
            case "atmosphere2":
                data[0].atmosphere2 = fieldValue
                break;
            case "atmosphere3":
                data[0].atmosphere3 = fieldValue
                break;
            case "atmosphere4":
                data[0].atmosphere4 = fieldValue
                break;

            case "hazEnergyControl":
                if (this.bMultiField(data[0].hazEnergyControl, fieldValue)) {
                    data[0].hazEnergyControl = data[0].hazEnergyControl - fieldValue
                } else {
                    data[0].hazEnergyControl = data[0].hazEnergyControl + fieldValue
                }
                //validateDataItem.riskInvolvePeopleValue=true
                validateDataItem.hazEnergyControl = data[0].hazEnergyControl > 0;

                break;




            default:

        }

        this.setState({ data: data, validateDataItem: validateDataItem })
    }

    createDataForCreateDTO = (templateKey) => {
        const { data, user } = this.state

        const linetoAdd = {
            "FormJSAKey": data[0].formJSAKey,
            "TemplateKey": templateKey,
            "CreatedBy": getUserADAccount(user.account.userName),

            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }

    insertFromTemplate = (templateKey) => {

        const addDTO = this.createDataForCreateDTO(templateKey)
        let myURI = apiURL + 'SafetyIncident/addSafIncJSAfromTemplate'

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(addDTO)
        }

        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                    // return false;
                }
                // console.log("No error here: " + rspData)
                this.refreshJobTask()

            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })



    }


    createDataForUpdateDTO = () => {
        const { data } = this.state
        const { user } = this.props

        const dataForUpdateDTO = {
            "FormJSAKey": data[0].formJSAKey,
            //"ModifiedByKey": 580, /*4=Submitted, 6=Reviewed*/
            // "StatusKey": data[0].lineStatusKey,
            "ModifiedByAD": getUserADAccount(user.account.userName),
            // "IncDateTime": data[0].incDateTime,
            "IncDateTime": data[0].incDateTime ? moment(data[0].incDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
            "Comments": data[0].comments,
            "JobTask": data[0].jobTask,
            "EmployeeKey": data[0].employeeKey,
            "EmpShift": data[0].empShift,
            "ContactSupervisorKey": data[0].contactSupervisorKey,
            "ContactSupervisorText": data[0].contactSupervisorText,
            "ContactHSE": data[0].contactHSE,
            "ContactHSEText": data[0].contactHSEText,
            "ContactOther": data[0].contactOther,
            "ContactOtherText": data[0].contactOtherText,
            "Iunderstand": data[0].iunderstand,
            "AddtlEquipment": data[0].addtlEquipment,
            "AddtlEquipmentOther": data[0].addtlEquipmentOther,
            "AddtlEquipmentRespiratory": data[0].addtlEquipmentRespiratory,
            "EnviroWeather": data[0].enviroWeather,
            "EnviroTerrain": data[0].enviroTerrain,
            "EnviroWildlife": data[0].enviroWildlife,
            "HazEnergyControl": data[0].hazEnergyControl,
            "Emergencyone": data[0].emergencyone,
            "Emergencytwo": data[0].emergencytwo,
            "Emergencythree": data[0].emergencythree,
            "Permitsreq1": data[0].permitsreq1,
            "Permitsreq2": data[0].permitsreq2,
            "Permitsreq3": data[0].permitsreq3,
            "Atmosphere1": data[0].atmosphere1,
            "Atmosphere2": data[0].atmosphere2,
            "Atmosphere3": data[0].atmosphere3,
            "Atmosphere4": data[0].atmosphere4




        }

        return dataForUpdateDTO;
    }


    saveData = () => {
        const itemDTO = this.createDataForUpdateDTO();
        // console.log(itemDTO);
        // alert("spill Report");

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'SafetyIncident/updateSafIncJSA'
        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                }

                // console.log("No error here: " + rspData)

                this.getInpsData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }


    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    setStateOfParent = (newTitle) => {
        this.setState = ({
            isValidatedFormJSAJobTask: newTitle
        })
    }


    render() {

        // var {isLoaded, data, isHdrLoaded, hdrData} = this.state
        var { isLoaded, isHdrLoaded,
            data, hdrData,
            ddEmployees, isddEmployeesLoaded, isReadOnly, activeTab, safIncLineKey, user, msgHeader, msgBody,
            ddTemplates, isddTemplatesLoaded, isAdmin, validateDataItem

        } = this.state

        // if (!isLoaded) return <h1> loading... </h1>
        // if (!isHdrLoaded) return <h1> loading hdr... </h1>

        // if (!isddEmployeesLoaded) return <h1> loading Employees... </h1>
        // if (!isddTemplatesLoaded) return <h1> loading Templates... </h1>

        if (!isLoaded || !isHdrLoaded || !isddEmployeesLoaded || !isddTemplatesLoaded)
            return (<div>
                <h1>Loading Data...</h1>
            </div>)



        //if (!isLoaded) return <h1> loading </h1> 

        return (
            <div>
                {/* <Form>
                <FormGroup>
                  <Row form>
                    <h3> First Report </h3>
                  </Row>
                  <h5>
                  <label>{hdrData[0].safIncID}</label>  <span>&nbsp;&nbsp;</span>
                  <label>| Line: {data[0].sLineNo} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| Status: {data[0].lineStatusDesc} </label>  <span>&nbsp;&nbsp;</span>                    
                    <label>| By: {data[0].createdByAD} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| Created: {new Date(data[0].createdDateTime).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                   
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup>

            </Form> */}
                <Form>
                    {/* <FormGroup row>
            <Col md={2}>
              <h3> Spill Report </h3>
            </Col>
            <Col md={2}>

              <Button color="primary"
                // className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}

              //  hidden={this.setIsReadOnly(statusKey)}
                onClick={() => this.saveData()}
                disabled={isReadOnly}
              >Save</Button>



            </Col>

          </FormGroup> */}
                    {/* <FormGroup row>
                        <Col md={12}>
                            <div>
                                <Label>

                                    ddddd {data[0].statusKey} {user} isReadOnly:<b>{isReadOnly ? "true" : "false"}</b>   isUser:<b>{isUser ? "true" : "false"}</b>   isAdmin:<b>{isAdmin ? "true" : "false"}</b>    isApprover:<b>{isApprover ? "true" : "false"}</b>  isEditApprover:<b>{isEditApprover ? "true" : "false"}</b>  
                                    dddd5 {data[0].formRCAKey}
                                </Label>


                            </div>
                        </Col>

                    </FormGroup> */}

                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                {/* <Input
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="8 Life Saving Rules"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input> */}
                                <CardHeader style={{ backgroundColor: "orange", padding: "0", margin: "0" }}>
                                    <h2 style={{ fontWeight: 'bold', color: 'darkblue', textAlign: 'center', margin: '10px 0' }}>
                                        8 Life Saving Rules
                                    </h2>
                                </CardHeader>



                                <CardBody >
                                    <Table bordered>

                                        <tbody>
                                            <tr>
                                                <td style={{ width: '25%' }}>
                                                    <img src={jsabanner01} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Drive Safely</div>
                                                </td>
                                                <td style={{ width: '25%' }}>
                                                    <img src={jsabanner02} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Have a Safe Work Permit</div>

                                                </td>
                                                <td style={{ width: '25%' }}>
                                                    <img src={jsabanner03} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Isolate Energy Sources</div>

                                                </td>
                                                <td style={{ width: '25%' }}>
                                                    <img src={jsabanner04} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Control Entry to Confined Space</div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src={jsabanner05} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Manage Ground Disturbances</div>

                                                </td>
                                                <td>
                                                    <img src={jsabanner06} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Work Safely at Heights</div>

                                                </td>
                                                <td>
                                                    <img src={jsabanner07} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Avoid the Line of Fire</div>

                                                </td>
                                                <td>
                                                    <img src={jsabanner08} alt="Banner" style={{ display: 'block', maxWidth: '100%', height: 'auto', margin: '0 auto' }} />
                                                    <div style={{ textAlign: 'center' }}>Manage Exposure to Hazardous Substances and Mixtures</div>

                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>



                                </CardBody>

                                <CardFooter style={{ backgroundColor: "orange", padding: "0", margin: "0" }}>
                                    <h2 style={{ fontWeight: 'bold', color: 'darkblue', textAlign: 'center', margin: '10px 0' }}>
                                        {' '}
                                    </h2>

                                </CardFooter>


                            </Card>
                        </Col>

                    </FormGroup>





                    <FormGroup row>

                        <Col md={6}>
                            <Label for="templateKey">Job/Task</Label>
                            <Select options={this.createTemplList(ddTemplates)}
                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                // styles={customStyles}
                                className="alway-in-between2"
                                isDisabled={isReadOnly}
                                id="templateKey"
                                name="templateKey"
                                value={this.getSelTempl(data[0].templateKey)}

                                onChange={(e) => this.onTextChange("templateKey", e, e)}
                                isClearable={true}

                            >
                            </Select>
                        </Col>

                        <Col md={6}>


                            <Label for="incDateTime">Datetime</Label>

                            <Datetime
                                inputProps={{
                                    placeholder: "MM/DD/YYYY hh:mm",
                                    // disabled: false
                                    disabled: isReadOnly
                                }}
                                value={moment(data[0].incDateTime)}

                                onChange={(v, f) => this.onTextChange("incDateTime", v, f)}
                            />






                        </Col>
                        {/* <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="modifiedByAD">Modified By</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="modifiedByAD"
                        id="modifiedByAD" placeholder=""
                        value={data[0].modifiedByAD}
                      
                      />
                    </Col>
                  </Row>
                </Col> */}
                    </FormGroup>

                    <FormGroup row>

                        <Col md={6}>
                            <Label for="employeeKey">Employee</Label>
                            <Select options={this.createEmpList(ddEmployees)}
                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                // styles={customStyles}

                                isDisabled={isReadOnly || !isAdmin}
                                id="employeeKey"
                                name="employeeKey"
                                value={this.getSelEmp(data[0].employeeKey)}
                                // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                onChange={(e) => this.onTextChange("employeeKey", e, e)}
                                isClearable={true}

                            >
                            </Select>
                        </Col>

                        <Col md={6}>
                            <Label for="shift">Shift</Label>
                            <CustomInput

                                type="select"
                                id="shift"
                                name="shift"
                                value={data[0].empShift}
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("shift", e.target.value, e)}
                            >
                                <option value={0}></option>
                                <option value={1}>First</option>
                                <option value={2}>Second</option>
                            </CustomInput>
                        </Col>

                    </FormGroup>

                    <FormGroup row>

                        <Col md={12}>



                            <div>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                            Comments
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggleTab('2'); }}
                                        >
                                            Documents
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { this.toggleTab('3'); }}
                      >
                        Additional Details
                      </NavLink>
                    </NavItem> */}
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col md={12}>
                                                {/* <Label for="youRObserve">WHAT DID YOU OBSERVE?</Label>       */}
                                                <Input
                                                    // invalid={!validateDataItem.hazardDesc}
                                                    type="textarea" name="comments" rows={5}
                                                    id="comments"
                                                    value={data[0].comments}
                                                    disabled={isReadOnly}
                                                    onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                                // defaultValue = {observation[0].hazardDesc}                               
                                                // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                                // disabled={isReadOnly} 

                                                />


                                            </Col>


                                        </Row>
                                    </TabPane>


                                    <TabPane tabId="2">
                                        <Row>
                                            <Col md={12}>
                                                {/* <DocumentBrowser  buttonLabel="View Image" 
                                        buttonColor="primary" 
                                        formName="Safety Observation Images"     
                                        linkTableName={"thsSafetyObservation"}
                                        linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                        apiURL={apiURL + 'SafetyObservation/Documents/' + (safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey) } /> */}
                                                <DocumentBrowserDragDrop buttonLabel="View Image"
                                                    buttonColor="primary"
                                                    formName="JSA Images"
                                                    linkTableName={"thseSafetyIncident_JSA"}
                                                    // linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                                    linkPrimaryKey={safIncLineKey}
                                                    apiURL={apiURL + 'SafetyIncident/Documents/' + safIncLineKey + '/bucket/thseSafetyIncident_JSA'}

                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            <Col md={12}>
                                                details tbd
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>

                            </div>



                        </Col>

                    </FormGroup>





                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Safety Analysis"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody >
                                    {/* 1 row */}
                                    {/* <Row > */}
                                    {/* <Col md={10}> */}

                                    <FormJSAJobTask formJSAKey={data[0].formJSAKey}
                                        user={user}
                                        isAdmin={isAdmin}
                                        // hidden={isReadOnly}
                                        isFormReadOnly={isReadOnly}
                                        refreshJobTask={click => this.refreshJobTask = click}
                                        validateJSAJobTaskForSubmit={click => this.validateJSAJobTaskForSubmit = click}

                                        // validateJobTask={click => this.validateJobTask = click}
                                        onClose={() => this.getddTemplates()}
                                        setStateOfParent={this.setStateOfParent}
                                    // key={data[0].formRCAKey}
                                    >

                                    </FormJSAJobTask>

                                    {/* </Col> */}
                                    {/* </Row> */}



                                </CardBody>


                            </Card>
                        </Col>

                    </FormGroup>







                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Safety Equipment/PPE"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody style={
                                    !validateDataItem.addtlEquipment ? { border: '1px solid red' } : {}
                                }>
                                    {/* 1 row */}
                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment1"
                                                disabled={isReadOnly}
                                                label="Face Shield"
                                                checked={this.bMultiField(data[0].addtlEquipment, 1)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 1, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment2"
                                                label="Chemical goggles" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 2)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 2, e)}
                                            />

                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment4"
                                                label="Chemical Protective Clothing" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 4)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 4, e)}
                                            />
                                        </Col>

                                    </Row>
                                    {/* 2 row */}

                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment8"
                                                disabled={isReadOnly}
                                                label="Ruber Boots"
                                                checked={this.bMultiField(data[0].addtlEquipment, 8)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 8, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment16"
                                                label="Chemical resistant gloves" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 16)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 16, e)}
                                            />

                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment31"
                                                label="Arm Protection" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 32)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 32, e)}
                                            />
                                        </Col>

                                    </Row>


                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment64"
                                                disabled={isReadOnly}
                                                label="Hearing protection"
                                                checked={this.bMultiField(data[0].addtlEquipment, 64)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 64, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment128"
                                                label="Fall protection" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 128)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 128, e)}
                                            />

                                        </Col>

                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment1024"
                                                label="Access" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 1024)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 1024, e)}
                                            />

                                        </Col>


                                    </Row>



                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment2048"
                                                disabled={isReadOnly}
                                                label="Excavation"
                                                checked={this.bMultiField(data[0].addtlEquipment, 2048)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 2048, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment4096"
                                                label="Overhead Work" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 4096)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 4096, e)}
                                            />

                                        </Col>

                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment8192"
                                                label="Equipment/Lifting" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 8192)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 8192, e)}
                                            />

                                        </Col>


                                    </Row>


                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment16384"
                                                disabled={isReadOnly}
                                                label="Respiratory protection"
                                                checked={this.bMultiField(data[0].addtlEquipment, 16384)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 16384, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment32768"
                                                label="Stand-By Person" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 32768)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 32768, e)}
                                            />

                                        </Col>

                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment65536"
                                                label="Welding/Burning" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 65536)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 65536, e)}
                                            />

                                        </Col>


                                    </Row>



                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment131072"
                                                disabled={isReadOnly}
                                                label="Short Service Employee (SSE)"
                                                checked={this.bMultiField(data[0].addtlEquipment, 131072)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 131072, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment262144"
                                                label="Hard Hat" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 262144)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 262144, e)}
                                            />

                                        </Col>

                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment524288"
                                                label="Steel Toe Boots" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 524288)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 524288, e)}
                                            />

                                        </Col>


                                    </Row>




                                    <Row >

                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="addtlEquipment1048576"
                                                label="H2S/4 Gas Monitor" inline
                                                checked={this.bMultiField(data[0].addtlEquipment, 1048576)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 1048576, e)}
                                            />

                                        </Col>

                                        <Col md={3}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment256"
                                                disabled={isReadOnly}
                                                label="Hazards (Body)"
                                                checked={this.bMultiField(data[0].addtlEquipment, 256)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 256, e)}

                                                inline



                                            />
                                            {/* </Col>
                                        <Col md={3}> */}
                                            <Input type="text" name="addtlEquipmentRespiratory"
                                                id="addtlEquipmentRespiratory"
                                                // disabled={isReadOnly}
                                                // value={data[0].addtlEquipmentRespiratory}
                                                value={this.bMultiField(data[0].addtlEquipment, 256) ? data[0].addtlEquipmentRespiratory : ""}
                                                disabled={this.bMultiField(data[0].addtlEquipment, 256) ? isReadOnly : true}

                                                onChange={(e) => this.onTextChange("addtlEquipmentRespiratory", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col md={1}>

                                        </Col>


                                        {/* </Row>
                                    <Row > */}
                                        <Col md={3}>
                                            <CustomInput type="checkbox"
                                                id="addtlEquipment512"
                                                disabled={isReadOnly}
                                                label="Other"
                                                checked={this.bMultiField(data[0].addtlEquipment, 512)}
                                                onChange={(e) => this.onTextChange("addtlEquipment", 512, e)}

                                                inline



                                            />
                                            {/* </Col>
                                        <Col md={3}> */}
                                            <Input type="text" name="addtlEquipmentOther"
                                                id="addtlEquipmentOther"
                                                // disabled={isReadOnly}
                                                // value={data[0].addtlEquipmentOther}
                                                value={this.bMultiField(data[0].addtlEquipment, 512) ? data[0].addtlEquipmentOther : ""}
                                                disabled={this.bMultiField(data[0].addtlEquipment, 512) ? isReadOnly : true}


                                                onChange={(e) => this.onTextChange("addtlEquipmentOther", e.target.value, e)}

                                            />


                                        </Col>


                                    </Row>

                                </CardBody>
                                {!validateDataItem.addtlEquipment && (
                                    <label style={{ color: 'red' }}>
                                        Please select at least one checkbox
                                    </label>
                                )}

                            </Card>
                        </Col>
                        {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
                    </FormGroup>


                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Emergency Assembly Point"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody >
                                    {/* 1 row */}
                                    <Row >
                                        <Col md={3}>
                                            <Label for="emergencyone">Primary</Label>
                                            <Input type="text" name="emergencyone"
                                                id="emergencyone"
                                                disabled={isReadOnly}
                                                value={data[0].emergencyone}
                                                onChange={(e) => this.onTextChange("emergencyone", e.target.value, e)}

                                            />

                                        </Col>

                                        <Col md={1}>


                                        </Col>


                                        <Col md={3}>
                                            <Label for="emergencytwo">Secondary</Label>
                                            <Input type="text" name="emergencytwo"
                                                id="emergencytwo"
                                                disabled={isReadOnly}
                                                value={data[0].emergencytwo}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("emergencytwo", e.target.value, e)}

                                            />


                                        </Col>


                                        <Col md={1}>


                                        </Col>
                                        <Col md={3}>
                                            <Label for="emergencythree">Wind Direction</Label>
                                            <Input type="text" name="emergencythree"
                                                id="emergencythree"
                                                disabled={isReadOnly}
                                                value={data[0].emergencythree}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("emergencythree", e.target.value, e)}

                                            />

                                        </Col>

                                    </Row>
                                    {/* 2 row */}



                                </CardBody>


                            </Card>
                        </Col>
                        {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
                    </FormGroup>





                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Temperature"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody >
                                    {/* 1 row */}
                                    <Row >
                                        <Col md={3}>
                                            <Label for="enviroWeather">Weather</Label>
                                            <Input type="text" name="enviroWeather"
                                                id="enviroWeather"
                                                disabled={isReadOnly}
                                                value={data[0].enviroWeather}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("enviroWeather", e.target.value, e)}

                                            />

                                        </Col>

                                        <Col md={1}>


                                        </Col>


                                        <Col md={3}>
                                            <Label for="enviroTerrain">Terrain</Label>
                                            <Input type="text" name="enviroTerrain"
                                                id="enviroTerrain"
                                                disabled={isReadOnly}
                                                value={data[0].enviroTerrain}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("enviroTerrain", e.target.value, e)}

                                            />


                                        </Col>


                                        <Col md={1}>


                                        </Col>
                                        <Col md={3}>
                                            <Label for="enviroWildlife">Wildlife</Label>
                                            <Input type="text" name="enviroWildlife"
                                                id="enviroWildlife"
                                                disabled={isReadOnly}
                                                value={data[0].enviroWildlife}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("enviroWildlife", e.target.value, e)}

                                            />

                                        </Col>

                                    </Row>
                                    {/* 2 row */}



                                </CardBody>


                            </Card>
                        </Col>
                        {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
                    </FormGroup>




                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Atmosphere Monitoring"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody >
                                    {/* 1 row */}
                                    <Row >
                                        <Col md={3}>
                                        <Label for="atmosphere1">Required</Label>
                                            <CustomInput

                                                type="select"
                                                id="atmosphere1"
                                                name="atmosphere1"
                                                value={data[0].atmosphere1}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("atmosphere1", e.target.value, e)}
                                            >
                                                <option value={""}></option>
                                                <option value={"N/A"}>N/A</option>
                                                <option value={"No"}>No</option>
                                                <option value={"Yes"}>Yes</option>
                                            </CustomInput>

                                        </Col>

                                        <Col md={1}>


                                        </Col>


                                        <Col md={3}>
                                           


                                        </Col>


                                        <Col md={1}>


                                        </Col>
                                        <Col md={3}>
                                           

                                        </Col>

                                    </Row>

                                    <Row >
                                        <Col md={3}>
                                            <Label for="atmosphere2">Comb. Gas / Flamm. Vapor Test - Reading</Label>
                                            <Input type="text" name="atmosphere2"
                                                id="atmosphere2"
                                                disabled={data[0].atmosphere1==="Yes" ? isReadOnly : true}
                                                value={data[0].atmosphere1==="Yes" ? data[0].atmosphere2 : ""}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("atmosphere2", e.target.value, e)}

                                            />

                                        </Col>

                                        <Col md={1}>


                                        </Col>


                                        <Col md={3}>
                                            <Label for="atmosphere3">Oxygen Concentration - Reading</Label>
                                            <Input type="text" name="atmosphere3"
                                                id="atmosphere3"
                                                disabled={data[0].atmosphere1==="Yes" ? isReadOnly : true}
                                                value={data[0].atmosphere1==="Yes" ? data[0].atmosphere3 : ""}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("atmosphere3", e.target.value, e)}

                                            />


                                        </Col>


                                        <Col md={1}>


                                        </Col>
                                        <Col md={3}>
                                            <Label for="atmosphere4">Hazardous / Toxic Gas Test - Reading</Label>
                                            <Input type="text" name="atmosphere4"
                                                id="atmosphere4"
                                                disabled={data[0].atmosphere1==="Yes" ? isReadOnly : true}
                                                value={data[0].atmosphere1==="Yes" ? data[0].atmosphere4 : ""}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("atmosphere4", e.target.value, e)}

                                            />

                                        </Col>

                                    </Row>




                                </CardBody>


                            </Card>
                        </Col>
                        {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
                    </FormGroup>







                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Permits Required"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody >
                                    {/* 1 row */}
                                    <Row >
                                        <Col md={3}>
                                            <Label for="permitsreq1">Hot Work</Label>
                                            <CustomInput

                                                type="select"
                                                id="permitsreq1"
                                                name="permitsreq1"
                                                value={data[0].permitsreq1}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("permitsreq1", e.target.value, e)}
                                            >
                                                <option value={""}></option>
                                                <option value={"N/A"}>N/A</option>
                                                <option value={"No"}>No</option>
                                                <option value={"Yes"}>Yes</option>
                                            </CustomInput>

                                        </Col>

                                        <Col md={1}>


                                        </Col>


                                        <Col md={3}>
                                            <Label for="permitsreq2">Excavation</Label>
                                            <CustomInput

                                                type="select"
                                                id="permitsreq2"
                                                name="permitsreq2"
                                                value={data[0].permitsreq2}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("permitsreq2", e.target.value, e)}
                                            >
                                                <option value={""}></option>
                                                <option value={"N/A"}>N/A</option>
                                                <option value={"No"}>No</option>
                                                <option value={"Yes"}>Yes</option>
                                            </CustomInput>

                                        </Col>


                                        <Col md={1}>


                                        </Col>
                                        <Col md={3}>
                                            <Label for="permitsreq3">Confined Space</Label>
                                            <CustomInput

                                                type="select"
                                                id="permitsreq3"
                                                name="permitsreq3"
                                                value={data[0].permitsreq3}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("permitsreq3", e.target.value, e)}
                                            >
                                                <option value={""}></option>
                                                <option value={"N/A"}>N/A</option>
                                                <option value={"No"}>No</option>
                                                <option value={"Yes"}>Yes</option>
                                            </CustomInput>

                                        </Col>

                                    </Row>
                                    {/* 2 row */}



                                </CardBody>


                            </Card>
                        </Col>
                        {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
                    </FormGroup>





                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Hazard Controls"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody style={
                                    !validateDataItem.hazEnergyControl ? { border: '1px solid red' } : {}
                                }>
                                    {/* 1 row */}
                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="hazEnergyControl1"
                                                disabled={isReadOnly}
                                                label="Line Pressure"
                                                checked={this.bMultiField(data[0].hazEnergyControl, 1)}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 1, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="hazEnergyControl2"
                                                label="LO/TO devices in place" inline
                                                checked={this.bMultiField(data[0].hazEnergyControl, 2)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 2, e)}
                                            />

                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="hazEnergyControl4"
                                                label="Energy isolation verified" inline
                                                checked={this.bMultiField(data[0].hazEnergyControl, 4)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 4, e)}
                                            />
                                        </Col>

                                    </Row>
                                    {/* 2 row */}
                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="hazEnergyControl8"
                                                disabled={isReadOnly}
                                                label="Electrical"
                                                checked={this.bMultiField(data[0].hazEnergyControl, 8)}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 8, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="hazEnergyControl16"
                                                label="Hydraulic" inline
                                                checked={this.bMultiField(data[0].hazEnergyControl, 16)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 16, e)}
                                            />

                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="hazEnergyControl32"
                                                label="Pneumatic" inline
                                                checked={this.bMultiField(data[0].hazEnergyControl, 32)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 32, e)}
                                            />
                                        </Col>

                                    </Row>

                                    <Row >
                                        <Col md={4}>
                                            <CustomInput type="checkbox"
                                                id="hazEnergyControl64"
                                                disabled={isReadOnly}
                                                label="Mechanical"
                                                checked={this.bMultiField(data[0].hazEnergyControl, 64)}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 64, e)}

                                                inline



                                            />
                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="hazEnergyControl128"
                                                label="Thermal" inline
                                                checked={this.bMultiField(data[0].hazEnergyControl, 128)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 128, e)}
                                            />

                                        </Col>
                                        <Col md={4}>
                                            <CustomInput type="checkbox" id="hazEnergyControl256"
                                                label="Chemical" inline
                                                checked={this.bMultiField(data[0].hazEnergyControl, 256)}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("hazEnergyControl", 256, e)}
                                            />

                                        </Col>


                                    </Row>



                                </CardBody>
                                {!validateDataItem.hazEnergyControl && (
                                    <label style={{ color: 'red' }}>
                                        Please select at least one checkbox
                                    </label>
                                )}

                            </Card>
                        </Col>
                        {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
                    </FormGroup>



                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="In case of an incident, the following people will be contacted:"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>


                                <CardBody>
                                    <Row>

                                        <Col md={4}>
                                            <Label for="contactSupervisorKey">Supervisor</Label>
                                            <Select options={this.createEmpList(ddEmployees)}
                                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                // styles={customStyles}
                                                className="alway-in-between3"
                                                isDisabled={true}
                                                id="contactSupervisorKey"
                                                name="contactSupervisorKey"
                                                value={this.getSelEmp(data[0].contactSupervisorKey)}
                                                // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                                onChange={(e) => this.onTextChange("contactSupervisorKey", e, e)}
                                                isClearable={true}

                                            >
                                            </Select>
                                        </Col>
                                        <Col md={8}>
                                            <Label for="contactSupervisorText">Supervisor info/phone</Label>
                                            <Input type="text" name="contactSupervisorText"
                                                id="contactSupervisorText"
                                                disabled={true}
                                                value={data[0].contactSupervisorText}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("contactSupervisorText", e.target.value, e)}

                                            />
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md={4}>
                                            <Label for="contactHSE">HSE</Label>
                                            <Select options={this.createEmpList(ddEmployees)}
                                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                                // styles={customStyles}
                                                className="alway-in-between2"
                                                isDisabled={true}
                                                id="contactHSE"
                                                name="contactHSE"
                                                value={this.getSelEmp(data[0].contactHSE)}
                                                // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                                onChange={(e) => this.onTextChange("contactHSE", e, e)}
                                                isClearable={true}

                                            >
                                            </Select>
                                        </Col>
                                        <Col md={8}>
                                            <Label for="contactHSEText">HSE Contact info/phone</Label>
                                            <Input type="text" name="contactHSEText"
                                                id="contactHSEText"
                                                disabled={true}
                                                value={data[0].contactHSEText}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("contactHSEText", e.target.value, e)}

                                            />
                                        </Col>
                                    </Row>

                                    {/* <Row>

                                        <Col md={4}>
                                            <Label for="contactOtherEmp">Other</Label>
                                            <Select
                                                options={ddEmployees} 
                                                className="alway-in-between"
                                                isDisabled={isReadOnly}
                                                id="contactOtherEmp"
                                                name="contactOtherEmp"
                                                value={this.getSelEmpOther(data[0].contactOtherEmp)}
                                                onChange={(e) => this.onTextChange("contactOtherEmp", e, e)}
                                                isMulti
                                                isClearable={true}

                                            >
                                            </Select>
                                        </Col>
                                        <Col md={8}>
                                            <Label for="contactOtherText">Contact info/phone</Label>
                                            <Input type="text" name="contactOtherText"
                                                id="contactOtherText"
                                                disabled={isReadOnly}
                                                value={data[0].contactOtherText}
                                     
                                                onChange={(e) => this.onTextChange("contactOtherText", e.target.value, e)}

                                            />
                                        </Col>
                                    </Row> */}



                                </CardBody>

                            </Card>
                        </Col>
                    </FormGroup>


                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Other involved Employees/Contractors "
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody >
                                    {/* 1 row */}
                                    <Row >
                                        <Col md={12}>

                                            <FormJSASignatures formJSAKey={data[0].formJSAKey}
                                                user={user}
                                                isAdmin={isAdmin}
                                                hidden={isReadOnly}
                                                refreshSignature={click => this.refreshSignature = click}
                                                ddEmployees={ddEmployees}
                                                // validateJSAJobTaskForSubmit={click => this.validateJSAJobTaskForSubmit = click}

                                                // validateJobTask={click => this.validateJobTask = click}
                                                // onClose={() => this.getddTemplates()}
                                                setStateOfParent={this.setStateOfParent}
                                            // key={data[0].formRCAKey}
                                            >

                                            </FormJSASignatures>

                                        </Col>
                                    </Row>



                                </CardBody>


                            </Card>
                        </Col>

                    </FormGroup>






                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value={"To be completed by " + this.getSelName(this.getSelEmp(data[0].employeeKey))}
                                        // To be completed by {this.getSelName(this.getSelEmp(data[0].employeeKey))}:
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>


                                <CardBody>
                                    <Row>

                                        <Col md={12}>

                                            <Input type="textarea" id="info" name="info" rows={4}
                                                value="I understand & will adhere to the steps, hazards & controls as described in this JSA. I understand that performing steps out of sequence may pose hazards that have not been evaluated, nor authorized.
I will contact my supervisor prior to continuing work, if the scope of work changes or new hazards are introduced. I understand I have the authority and responsibility to stop work I believe to be unsafe.
"

                                            />


                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <CustomInput
                                                type="checkbox"
                                                // invalid={true}
                                                invalid={!validateDataItem.iunderstand}
                                                id="iunderstand"
                                                name="iunderstand"
                                                label="I understand"
                                                checked={data[0].iunderstand === 1}
                                                // inline
                                                disabled={!this.isUserEmployee() || isReadOnly}
                                                // disabled={isReadOnly}
                                                // onChange={(e) => this.onTextChange("iunderstand", 1, e)}
                                                onChange={(e) => this.onTextChange("iunderstand", 1, e.target.checked)}


                                            >
                                                <FormFeedback invalid>Please check the box</FormFeedback>
                                            </CustomInput>
                                        </Col>
                                    </Row>
                                    <Row hidden={data[0].iunderstand != 1}     >
                                        <Col md={12}>
                                            <Label><i> {this.getSelName(this.getSelEmp(data[0].employeeKey))} </i></Label>


                                        </Col>

                                    </Row>



                                </CardBody>

                            </Card>
                        </Col>
                    </FormGroup>




                </Form>
                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

            </div>

        );



        //       }
        //     }
        //   </FetchData>
        // );
    }
}