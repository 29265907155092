//Created By Brandon Nguyen 1/12/22

import React, { Component } from 'react';

import { Form, Col, Row, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input, CustomInput, ListGroupItem, ListInlineItem, InputGroup, Card, CardHeader, CardBody,  Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiGETHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import TrashBin from '../resources/trash.svg'
import { USDCurrencyFormat, NumberFormat } from '../resources/utility'
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from './ConfirmModal'
import MeterValidationItem from './MeterValidationItem';
import DocumentBrowser from '../resources/DocumentBrowser';


import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


 class InventoryItemType extends Component {

    static displayName = InventoryItemType.name;

    constructor(props){
        super(props)
        // this.onFilter = this.onFilter.bind(this);

        this.state={

            facInvCollector:[],
            data:[],
            itemType:[],
            itemPropertyMap:[],
            filterType:"",
            itemTypeKey: 0,
            inventoryData:null,
            filterBool:true,
            isLoaded:false,
            isItemMapLoaded:false,
            modal:false,
            facilityInvCollectorKey:0,
            windowHeight:window.innerHeight, 
            dropDownOpen:false, 
            createdDatetime: new Date().toISOString().substring(0,10),
            selectItem:null
        }
    }


    closeForm = (refreshData) => {

        if(this.props.currentView === 'fleet')
        {
            if(refreshData) {        
                this.props.refreshData()
            }
        }
        else{
            if(refreshData) {        
                this.props.refreshData()
            }
        }


         this.props.toggle()
     }


    getItemType=() =>{


        // console.log("1 hit")
        if (this.props.currentView === 'fleet')
        {
            let myURI = apiURL + 'ItemMaster/ItemTypeFleet'
        

            fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    
                    this.setState({
                        isLoaded: true,
                        itemType:json                             
                    })
    
                });
        }
        else{
            // console.log("2 hit")
            let myURI = apiURL + 'ItemMaster/ItemTypeFacilityAsset'
    
            fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    
                    this.setState({
                      isLoaded: true,
                      itemType: json
                    //   itemType: json.filter(
                    //     (item) =>
                    //       item.itemTypeKey !== 1171 && //Truck
                    //       item.itemTypeKey !== 2832 && //Skid Steer
                    //       item.itemTypeKey !== 2846 && //Man Lift
                    //       item.itemTypeKey !== 2847 && //Water Truck
                    //       item.itemTypeKey !== 2848 && //Excavator
                    //       item.itemTypeKey !== 2849 && //Attachment
                    //       item.itemTypeKey !== 2850 && //Trailer
                    //       item.itemTypeKey !== 2940 && //Long Reach Excavator
                    //       item.itemTypeKey !== 2941 && //Haul Truck
                    //       item.itemTypeKey !== 2942 && //Grader
                    //       item.itemTypeKey !== 2943 && //Dump Truck
                    //       item.itemTypeKey !== 2944 && //Loader
                    //       item.itemTypeKey !== 2945 && //Dozer
                    //       item.itemTypeKey !== 2946 && //Boom Lift
                    //       item.itemTypeKey !== 2947 && //Articulating Lift
                    //       item.itemTypeKey !== 2948 //Fork Lift
                    //   ),
                    });
    
                });                
        }



    }


    
 componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getItemType()
        
       
  
    
    }

    onChangeValue = (e)=>{
        this.setState({selectItem:e})
    }

    render(){
        var {isLoaded,selectItem} = this.state

        if (!isLoaded){
            return (<div>
                <h3>Loading Inventory .....</h3>
            </div>)
        }else  {
        const {itemType} = this.state
  
        return(<div>
            <FormGroup>
            <Row>
            <Col sm={12}>
           <CustomInput type = "select"
            //  value={this.props.itemTypeTitle}
             onChange={e => this.onChangeValue(e)}
                                                >
            <option value={-1}>Please Select</option>
            {itemType.map(v => <option value={v.itemTypeKey}>{v.itemTypeDesc}</option>)}
                                 
                                                
           </CustomInput>
           </Col>
           </Row>
           </FormGroup>
          
          
          
           <FormGroup>

               <Row>

               
               <Col sm={12}>
           <Button color="warning" className="float-right" 
                             onClick={() => this.props.toggle()}>Close</Button>

                    
                             
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                            //  hidden={this.setIsReadOnly(statusKey)}
                             onClick={(e) => this.props.onClickYes(selectItem)}>Okay</Button>
                </Col>

                </Row>
            </FormGroup>

           
          
        </div>)
    }

}


 }
 export default InventoryItemType


