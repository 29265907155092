// This comment put in by Hau Nguyen on 06/06/2021

import React, { Component } from 'react';

import { apiURL, apiHeader } from '../resources/apiURL';
// import MenuIcon from '../resources/menu.png';
// import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
// import { IgrDateTimeColumn } from 'igniteui-react-grids';
// import { IgrColumnGroupDescription } from 'igniteui-react-grids';
// import { JsonDictionaryArray, ListSortDirection, tryParseUInt16_1 } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrTemplateCellInfo } from 'igniteui-react-grids';
// import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import SafetyIncidentNew from './safetyIncident/SafetyIncidentNew'
// import ConfirmModal from '../operation/ConfirmModal'
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'
import icontrash from '../resources/trash.svg'
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
//import Revise from '../resources/revise.png'
import Report from '../resources/report.png'
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();
IgrDataGridToolbarModule.register(); 


var DatePicker = require("reactstrap-date-picker");

export default class SafetyIncident extends Component {
    static displayName = SafetyIncident.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onGridfilterExpressionsChanged = this.onGridfilterExpressionsChanged.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.onIncIDCellUpdating = this.onIncIDCellUpdating.bind(this);
        this.onToolbarRef = this.onToolbarRef.bind(this);

        const urlPara = this.getURLParameters(props.parameters)

        this.state = {
            safetyInc: [],
            isLoaded: false,
            startDate: (urlPara.startDate === null ? getFirstDayofMonth().toISOString() : urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            endDate: (urlPara.endDate === null ? new Date().toISOString() : urlPara.endDate),
            modalOpen: true,
            safIncHeaderKey: -1,
            //selSafeObs:null,
            windowHeight: window.innerHeight,
            modal: false,
            modalReport:false,
            //safetyType : urlPara.safetyType,
            // isQHSEAdmin:false,
            // isQHSEApprover: false,
            fromParamStartDate: urlPara.startDate,
            fromParamEndDate: urlPara.endDate,
            ddTypes: [],
            // ddLocation: [],
            isSafIncAdmin: false,
            isSafIncAdminLoaded: false
        }
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }

    createReportParamter = () => {
        const {safIncHeaderKey} = this.state
        // const reportPara = [{
        //     "name": "InvoiceKey",
        //     "value": invoiceKey  
        // }]
        const reportPara = [{
            "name": "SafIncHeaderKey",
            "value": safIncHeaderKey
        }]

        console.log(reportPara)
        return reportPara
    
    }

    getURLParameters = (inputPara) => {
        var paraMeters = {
            startDate: null,
            endDate: null,
            facilityKeys: null,
            //safetyType: null,
            safetyInc: null
        }


        if (!this.props.location === 'undefined' || inputPara !== 'undefined') {
            var query
            if (!this.props.location === 'undefined') {
                query = new URLSearchParams(this.props.location.search);

            } else {
                query = new URLSearchParams(inputPara);
            }


            paraMeters = {
                startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
                endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
                //safetyType:query.get('SafetyType'),
                facilityKeys: query.get('Facility')
            }

        }





        console.log(paraMeters)
        return paraMeters
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.safetyInc;

        if (this.toolbar !== null){
            this.toolbar.targetGrid = this.grid
            
        }

    }

    onToolbarRef(toolbar) {
        this.toolbar = toolbar;
        if (this.toolbar) {
            this.toolbar.targetGrid = this.grid;
        }
    }


    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })

    }
    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)

            this.setState({ safIncHeaderKey: dataItem.safIncHeaderKey })
            this.setState({ safIncID: dataItem.safIncID })
            this.setState({ selSafeObs: dataItem })
            this.toggle()
            //this.onVolRefresh()    
        }
        else{
            this.setState({ safIncHeaderKey: -1 }) 
        }
    }


    onGridfilterExpressionsChanged(s, e) {
         const {safIncHeaderKey} = this.state
        const searchText = window.$searchText.toLowerCase()
        if (safIncHeaderKey>0 && searchText.length===0 )
        {
             this.setState({ safIncHeaderKey: -1 })
        }
    }

    // getIsQHSEadmin=() => {

    //     let myURI = apiURL + 'SafetyObservation/IsQHSEAdmin/' + this.props.user.account.userName;

    //     console.log(myURI);

    //     fetch(myURI , { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({                  
    //                 isQHSEAdmin: (json.isQHSEAdmin != 0)     
    //             })
    //         });
    // }

    // getIsQHSEApprover=() => {

    //     let myURI = apiURL + 'SafetyObservation/IsQHSEApprover/' + this.props.user.account.userName;

    //     console.log(myURI);

    //     fetch(myURI , { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({                  
    //                 isQHSEApprover: (json.isQHSEApprover != 0)     
    //             })
    //         });
    // }

    getIsSafIncAdmin = () => {

        // const { isLoaded, isSafIncAdmin } = this.state

        let myURI = apiURL + 'SafetyIncident/IsSafIncAdmin/' + this.props.user.account.userName;

        console.log(myURI);

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({

                    isSafIncAdmin: (json.isSafIncAdmin !== 0),
                    // isSafIncAdmin: false,
                    isSafIncAdminLoaded: true,

                }
                )
                // console.log('inside getIsSafIncAdmin')
                // console.log(isSafIncAdmin)
                // console.log(isLoaded)
                this.getSafetyIncData(json.isSafIncAdmin !== 0)
                this.getddTypes(json.isSafIncAdmin !== 0)
            });



    }



    getddTypes = (isSafIncAdmin) => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetSafIncType'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    // isddTypesLoaded: true,
                    
                    // ddTypes: isSafIncAdmin ? json : json.filter(rec => (rec.typeID === "JSA"))
                    ddTypes: json.filter(rec => (rec.typeID !== "JSA"))
                    //ddTypes: json.filter(rec => (rec.typeID === "JSA"))


                   // ddTypes: isSafIncAdmin ? json.filter(rec => (rec.typeID !== "FiR" && rec.typeID !== "SpR")) : json.filter(rec => (rec.typeID === "JSA"))
                })
            });
    }




    getSafetyIncData = (isSafIncAdmin) => {
        // isSafIncAdmin=false;
        //console.log(this.state.startDate);
        // const { isSafIncAdminLoaded } = this.state
        // console.log("safetyType");
        // console.log(safetyType);
        let startD = this.state.startDate===null ? '2019-01-01T06:00:00.000Z' :  this.state.startDate.toString()
        let endD = this.state.endDate===null ? '2099-01-01T06:00:00.000Z' :  this.state.endDate.toString()
        
        // let myURI = apiURL + 'SafetyObservation/startdate/' + 
        //                       this.state.startDate.toString().substring(0, 10) + 
        //                       '/enddate/' + this.state.endDate.toString().substring(0, 10) 
        //                       + '/AzureAD/' + this.props.user.account.userName;
        //'2023-01-01T06:00:00.000Z'


        // let myURI = apiURL + 'SafetyIncident/GetSafIncHeader/startdate/' +
        //     this.state.startDate.toString().substring(0, 10) +
        //     '/enddate/' + this.state.endDate.toString().substring(0, 10)

        let myURI = apiURL + 'SafetyIncident/GetSafIncHeader/startdate/' +
        startD.toString().substring(0, 10) +
            '/enddate/' + endD.toString().substring(0, 10)
            //+ '/AzureAD/' + 'Xochitl.Garcia@h2obridge.com';
            // + '/AzureAD/' + 'brad.stauffer@h2obridge.com';


            + '/AzureAD/' + this.props.user.account.userName;
        // let myURI = apiURL + 'SafetyIncident/GetSafIncHeader';     
        console.log(myURI);
        console.log('isSafIncAdmin')
        console.log(isSafIncAdmin)





        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                this.setState({
                    isLoaded: true,
                    // safetyInc:  json.filter(rec => ((safetyType===null ? true : 
                    //                                (rec.safetyTypeKey == safetyType))) &&
                    //                                ((fromParamStartDate===null && fromParamStartDate===null) ? true : 
                    //                                 (rec.createdDatetime >= fromParamStartDate && rec.createdDatetime <= fromParamEndDate)))
                    //                 .sort((a, b) => ((a.statusKey==5 ? 3 : a.statusKey) > (b.statusKey==5 ? 3 : b.statusKey)) ? 1:-1)                   
                    // safetyInc:  json.filter(rec => ((this.state.startDate===null && this.state.endDate===null) ? true : 
                    //                                 (rec.createdDatetime >= this.state.startDate && rec.createdDatetime <= this.state.endDate)))
                    //safetyInc:  json.filter(rec => (rec.SafIncHeaderKey >= 2))                
                    // safetyInc: json
                    // safetyInc: json.sort((a, b) => b.statusKey - a.statusKey)
                    // safetyInc: json.sort((a, b) => a.statusKey==3 ? -1:1)
                    // safetyInc: json.sort((a, b) => isSafIncAdmin===true ? (a.statusKey==3 ? -1:1) : (a.statusKey==3 ? 1:-1))
                    // safetyInc: json,
                    safetyInc: json.filter(rec =>
                        rec.typeDesc2 !== "JSA" 
                    ),




                    safIncHeaderKey: -1


                }
                )


            });


    }
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getIsSafIncAdmin()
        // this.getIsQHSEApprover()

       

    }


    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue

        });

    }



    // getSafetyObsHeaderColor = (selSafeObs) => {
    //     console.log("selSafeObs")
    //     console.log(selSafeObs)


    //     if (selSafeObs!=null){

    //     const statusKey = selSafeObs.statusKey
    //     console.log("statusKey")
    //     console.log(statusKey)

    //         if (statusKey===4) {
    //             return "modal-header submit"
    //         }else if (statusKey===6) {
    //             return "modal-header approve"
    //         }else if (statusKey===3) {
    //             return "modal-header reject"
    //         }else if (statusKey===5) {
    //             return "modal-header reject"
    //         }else{
    //             return "modal-header primary"
    //         }

    //     }else {return ""}
    // }

    render() {
        // var { isLoaded, safetyInc, windowHeight, ddTypes, ddLocation } = this.state;
        const { isLoaded, safetyInc, windowHeight, ddTypes, isSafIncAdminLoaded, isSafIncAdmin } = this.state;
        //const {SafIncHeaderKey,selSafeObs} = this.state;
        const { safIncHeaderKey, safIncID } = this.state;
        const gridHeight = (windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()

        // console.log("isQHSEAdmin")


        if (!isSafIncAdminLoaded) { return <h1> loading admin status... </h1> }
        else if (!isLoaded) {
            return (<div>
                <h3>Loading safety incident.....</h3>
            </div>)
        }
        else
            return (<div>
                <FormGroup>
                    <Row form>
                        <h2> Safety Incident |</h2>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.getIsSafIncAdmin}
                        >
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>
                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>

                        <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.toggleReport}
                                   disabled={safIncHeaderKey < 0}
                                >
                                    <img
                                        src={Report}
                                        alt="Report"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Report
                                </Button>
                        {/* {' '} */}

                        {/* <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.newReport}>
                            <img 
                            src={Plus}
                            alt="new"
                            style={{width:"25px", margin:"0 5px 0 0"}}/>
                            New Incidente
                        </Button>{' '} */}
                        {/* <ConfirmModal outline={true}
                                  color="secondary"
                                  className="btn-no-border"
                                  buttonLabel=" New Incident"
                                  hidden = {false}
                                  formName="Confirm"
                                  id={2}
                                  message="Create a new incident?"
                                  onClickYes={(e) => this.newReport()}
                                  image={<img
                                    src={Plus}
                                    // id={facilityDailyOpCostKey}
                                    alt="New"
                                    style={{ width: "25px", margin: "0 0px 0 0" }}/>}>
                        </ConfirmModal>        */}

                        <button outline className="btn-no-border" 
                        //New Incident
                        //  hidden={!isSafIncAdmin}
                        >

                            <SafetyIncidentNew
                                ddropdown={ddTypes}
                                // ddloc={ddLocation}
                                isSafIncAdmin={isSafIncAdmin}
                                // typeDefaultValue= {(isSafIncAdmin ? null : 7)}
                                //  onClickOK(ddKey.value)
                                onClickOK={(ddtyp, ddloc, desc) => this.newIncident(ddtyp, ddloc, desc)}
                            // onClickOK={this.newReport()}
                            // safIncLineKey={2}

                            />
                        </button>


                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>

                {/* <FormGroup row>
                    <Col md={12}>
                        <div>
                            <Label>

                                ddddd   isSafIncAdmin:<b>{isSafIncAdmin ? "true" : "false"}</b>      </Label>

                        </div>
                    </Col>

                </FormGroup> */}

                <FormGroup>
                    <Row >
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)} />
                        </Col>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />

                        </Col>

                    </Row>
                </FormGroup>

                <FormGroup>
                <IgrDataGridToolbar
                            ref={this.onToolbarRef}
                            toolbarTitle="Incidents"
                            columnChooser="true"
                        />
                    <IgrDataGrid
                        ref={this.onGridRef}
                        cellTextStyle="14px Calibri"
                        headerTextStyle="14px Calibri"
                        rowHeight={"25"}
                        height={gridHeight}
                        // dataSource={safetyInc}
                        dataSource={searchText.length>0 ? safetyInc.filter(rec =>  (
                        (rec.safIncID || '').toLowerCase().includes(searchText)) ||
                        (rec.statusDesc || '').toLowerCase().includes(searchText)  ||
                        (rec.typeDesc2 || '').toLowerCase().includes(searchText) ||
                        (rec.basin || '').toLowerCase().includes(searchText) ||
                        (rec.priorityID || '').toLowerCase().includes(searchText) ||
                        (rec.createdByEmpName || '').toLowerCase().includes(searchText) ||       
                        (rec.modifiedByEmpName || '').toLowerCase().includes(searchText) ||                                                      
                        (rec.descript || '').toLowerCase().includes(searchText) ||
                        (rec.comment || '').toLowerCase().includes(searchText)
                        )
                        :safetyInc
                    }


  
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        // reactsToFilterChanges="true"
                        selectedKeysChanged={this.onGridRowSelected}
                        filterExpressionsChanged={this.onGridfilterExpressionsChanged}

                    //cellBackground={"#ffbfbf"}
                    >
                     <IgrTemplateColumn field="basin" headerText="Basin " width="*>120" 
                        cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="safIncID" headerText="Safety Incident ID" width="*>170"
                            cellUpdating={this.onIncIDCellUpdating}
                        />
                        <IgrTemplateColumn field="statusDesc" headerText="Status"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="priorityID" headerText="Priority"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="typeDesc2" headerText="Type"
                            width="*>160"
                            cellUpdating={this.onStatusCellUpdating} />



                        <IgrTemplateColumn field="incDateTime" headerText="Incident Date"
                            width="*>140" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="createdByEmpName" headerText="Created By"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="modifiedDateTime" headerText="Date Modified"
                            width="*>140" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="modifiedByEmpName" headerText="Modified By"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTemplateColumn field="descript" headerText="Description"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTemplateColumn field="comment" headerText="Comment"
                            width="*>250"
                            cellUpdating={this.onStatusCellUpdating}

                        />
                        {/* <IgrTemplateColumn isColumnOptionsEnabled="false" field="DeleteColumn" headerText="" width="140" cellUpdating={this.onDeleteCellUpdating} /> */}

                        <IgrNumericColumn field="safIncHeaderKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>

                <div>
                <Modal isOpen={this.state.modalReport}
                    toggle={() => this.toggleReport()}
                    //style={{ margin: 0, flex: 1 }}
                    //onClosed={() => this.getSupplierReq()}
                    size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    //contentClassName="full-screen-modal"
                    centered
                    >
                    <ModalHeader toggle={() => this.toggleReport()}> Safety Incident Report </ModalHeader>
                    <ModalBody>
                        <PowerBIReportViewer
                            reportName = {"InspectionReport"} //{"R9Invoice"}
                            reportParameters = {this.createReportParamter()}
                            //invoiceKey={invoiceKey}                            
                            toggle={this.toggleReport}
                            //refreshData={this.getInvovice}
                        >

                        </PowerBIReportViewer>
                    </ModalBody>
                </Modal>
            </div>



            </div>)
    }

    onIncIDCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

            // content.style.verticalAlign = "center";
            // content.style.marginTop = "15px";
            // content.style.lineHeight = "normal";
            // content.style.display = "inline-block";
            // content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";
            content.style.color = "#4286f4";
            // content.style.width = "100%";
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
            if (item.statusKey === 3) {
                content.style.background = "#f0f5b0";
            } else if (item.statusKey === 6) {
                content.style.background = "#b8f2b1";
            } else if (item.statusKey === 1 || item.statusKey === 5) {
                content.style.background = "#fc4e42";
            } else {
                content.style.background = "transparent";
            }


        }

        //link.href = "tel:" + item.inspectionNo;
        link.href = "/#/SafetyIncidentHeader?safIncHeaderKey=" + item.safIncHeaderKey;
        link.target = "_blank"
        link.textContent = item.safIncID;
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;


        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            var cont = true
            // if (s.field === "priorityID") {
            //     cont = false

            //     if (item.priorityID === "Urgent") {
            //         content.style.background = "purple";
            //     } 
            //     if (item.priorityID === "High") {
            //         content.style.background = "cyan";
            //     }



            // }

            if (cont) {

                if (item.statusKey === 3) {
                    content.style.background = "#f0f5b0";
                } else if (item.statusKey === 6) {
                    content.style.background = "#b8f2b1";
                } else if (item.statusKey === 1 || item.statusKey === 5) {
                    content.style.background = "#fc4e42";
                } else {
                    content.style.background = "transparent";
                }

            }
        }

        switch (s.field) {
            case 'basin':
                link.textContent = item.basin;
                    
                break;
            case 'statusDesc':
                link.textContent = item.statusDesc;
                break;
            case 'safIncID':
                link.textContent = item.safIncID;
                break;

            case 'priorityID':
                link.textContent = item.priorityID;
                break;
            case 'typeDesc2':
                link.textContent = item.typeDesc2;
                break;
            case 'incDateTime':
                var date = new Date(item.incDateTime);
                link.textContent = date.toLocaleDateString();

                break;
            case 'modifiedDateTime':

                if (item.modifiedDateTime === null) {
                    link.textContent = "";

                } else {
                    var date = new Date(item.modifiedDateTime);
                    link.textContent = date.toLocaleDateString();
                }


                break;
            case 'createdByEmpName':
                link.textContent = item.createdByEmpName;
                break;
            case 'modifiedByEmpName':
                link.textContent = item.modifiedByEmpName;
                break;
            case 'solutionDesc':
                link.textContent = item.solutionDesc;
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'descript':
                link.textContent = item.descript;
                break;
            case 'comment':
                link.textContent = item.comment;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            default:

        }

    }




    onDeleteCellUpdating = (s, e) => {
        const content = e.content;
        const { isSafIncAdmin } = this.state;

        if (e.cellInfo.rowItem.statusDesc !== "InComplete") {
            return;
        }


        if (content.childElementCount === 0) {
            // if (!e.cellInfo.rowItem.statusDesc === "InComplete")
            // {return;}

            const button = document.createElement("input");
            // button.innerText = "Delete";
            button.setAttribute('type', 'image');
            button.src = icontrash;
            button.title = "Delete Incident";
            button.width = "12";
            button.height = "12";

            // button.innerHTML = "<img height=12px width=11px src='" + icontrash + "'>";


            button.addEventListener("click", this.onDeleteRowClick);
            // button.addEventListener("click", if(window.confirm('Delete the item?')){this.onDeleteRowClick});

            // button.addEventListener('click', function (e) {
            //   if (window.confirm('Do you confirm?')) {
            //     this.onDeleteRowClick;
            //   } else {
            //     e.preventDefault();
            //   }

            // });


            content.appendChild(button);

            // const img = document.createElement('IMG');
            // img.setAttribute('src', icontrash);
            // // img.setAttribute('class', 'mark');
            // img.addEventListener("click", this.onDeleteRowClick);
            // content.appendChild(img);

        }

        const button = content.children[0];
        // button.disabled = e.cellInfo.isDeleted;
        // button.id = e.cellInfo.dataRow.toString();
        button.id = e.cellInfo.rowItem.safIncHeaderKey.toString();
        button.statusKey = e.cellInfo.rowItem.statusKey.toString();

    }


    onDeleteRowClick = (e) => {
        const button = e.srcElement;
        const wstatuskey = parseInt(button.statusKey);
        const wkey = parseInt(button.id);


        if (window.confirm('Delete incident?')) {
            console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh');
            console.log(wkey);
            this.deleteHeader(wkey, wstatuskey);
            e.preventDefault();
        } else {
            e.preventDefault();
        }

        // if(window.confirm('Delete the item?')) {this.deleteWitness(wkey)};
        // this.deleteWitness(wkey);
        // const rowItem = this.grid.actualDataSource.getItemAtIndex(viewIndex);
        // this.grid.removeItem(rowItem);
        // this.setState({ });

    }



    deleteHeader = (key, statuskey) => {
        const { data } = this.state
        const { user } = this.props

        const dataForDeleteDTO = {
            "SafIncHeaderKey": key,
            "DeletedByAD": getUserADAccount(user.account.userName),
            "StatusKey": statuskey
        }



        const putReq = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataForDeleteDTO)
        }

        let myURI = apiURL + 'SafetyIncident/deleteSafIncHeader'



        fetch(myURI, putReq)
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }
                this.getIsSafIncAdmin();
                // console.log("No error here: " + rspData)
                // window.location = '/#/sSafetyIncident';
                // window.open("/#/SafetyIncident")
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })

        return true;
    }

    creatHeaderforInsertDTO = (ddtyp, ddloc, desc) => {
        const { data, safIncHeaderKey } = this.state
        const { user } = this.props
        if (!ddtyp) { ddtyp = 0 }
        if (!ddloc) { ddloc = 0 }
        if (!desc) { desc = "" }


        const dataForInsertDTO = {
            "CreatedByAD": getUserADAccount(user.account.userName),
            "LocationKey": ddloc,
            "Descript": desc,
            "SafIncType": ddtyp




        }

        return dataForInsertDTO;
    }


    openHeaderForm = (dd) => {
        const url = "/#/SafetyIncidentHeader?safIncHeaderKey=" + dd;
        window.open(url, '_blank')
        // window.location(url)

        console.log("22222222");
        console.log(dd);
    }


    // newReport = (z) => {
    //     const itemDTO = this.creatHeaderforInsertDTO();
    //     console.log(itemDTO);
    // }


    newIncident = (ddtyp, ddloc, desc) => {
        const itemDTO = this.creatHeaderforInsertDTO(ddtyp, ddloc, desc);
        console.log(itemDTO);

        const putReq = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }


        let myURI = apiURL + 'SafetyIncident/insertSafIncHeader'

        // this.openHeaderForm(4)

        fetch(myURI, putReq)
            .then(response => response.json())
            .then(data => {
                this.getIsSafIncAdmin();

                this.openHeaderForm(data[0].safIncHeaderKey);

            });


        //   .then(async response => {
        //     const rspData = response.text();
        //     console.log("dddddddddddd");
        //     console.log(response.body);
        //     if (!response.ok) {
        //       console.log("Error return but you dont see")
        //       const error = (rspData && rspData.message) || response.status;
        //       return Promise.reject(error);

        //     }
        //     // this.openHeaderForm(response.data[0].safIncHeaderKey)
        //     console.log("No error here: " + rspData)
        //     // this.getSafetyIncData();
        //     // this.openHeaderForm();


        //   })
        //  .catch(error => {
        //     console.log("POST Error: " + error);
        //   })


    }



    // newObservation=()=>{
    //     this.setState({safIncHeaderKey: -1})       
    //     //this.setState({selSafeObs: dataItem})
    //     this.toggle()
    // }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "SafetyIncident");
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.reportDate).toLocaleDateString("es-PA");
    }


}