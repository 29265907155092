
import React, { Component } from 'react';
import { Form, Card, CardHeader, CardBody, Col, Row, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { apiURL, apiHeader, branchName ,commitNumber,commitLink} from '../resources/apiURL';
import '../custom.css';
import { getUserADAccount } from '../resources/utility'
import "react-datetime/css/react-datetime.css";

import UserGroupforAbout from './UserGroupforAbout';


const VersionHistory = () => {
    var HNVersion = require('./VersionHistory/HNVersionHist.json')
    var DNUpdated = require('./VersionHistory/DNUpdate.json')
    var BNUpdated = require('./VersionHistory/BNUpdate.json')
    var APUpdated = require('./VersionHistory/APUpdate.json')

    const getLateUpdate = () => {
        var latestUpdate = ''
        if (HNVersion.length > 0) {
            latestUpdate = HNVersion[0].by + " - " + HNVersion[0].date + '-' + HNVersion[0].description + '\n'
        }
        if (DNUpdated.length > 0) {
            latestUpdate = latestUpdate + DNUpdated[0].by + " - " + DNUpdated[0].date + '-' + DNUpdated[0].description + '\n'
        }

        if (APUpdated.length > 0) {
            latestUpdate = latestUpdate + APUpdated[0].by + " - " + APUpdated[0].date + '-' + APUpdated[0].description + '\n'
        }

        if (BNUpdated.length > 0) {

            latestUpdate = latestUpdate + BNUpdated[0].by + " - " + BNUpdated[0].date + '-' + BNUpdated[0].description + '\n'
        }

        
        return (<p style={{ marginBottom: "0px", marginTop: "0px", marginLeft: "20px" }}>
            {latestUpdate}
        </p>)

    }

    const getHistoryUpdate = (data) => {
        var history = ''
        data.map((e, i) => {
            history = history + (i > 0 && i < 6 ? e.by + " - " + e.date + '-' + e.description + '\n' : '')
        })

        return (<p style={{ marginBottom: "0px", marginTop: "0px", marginLeft: "20px" }}>
            {history}
        </p>)
    }
    return (
        <div>
            <Col style={{ marginBottom: "10px" }} className="col-md-12">
                <p style={{ marginBottom: "0px" }} className="font-weight-bold"> {"Latest Update:"}</p>
                {
                    getLateUpdate()
                }
            </Col>

            <Col className="col-md-12">
                <p style={{ marginBottom: "0px" }} className="font-weight-bold">{"Version history:"}</p>
                {

                    getHistoryUpdate(HNVersion)
                }
                {
                    getHistoryUpdate(DNUpdated)

                }
                {
                    getHistoryUpdate(APUpdated)

                }
                {
                    getHistoryUpdate(BNUpdated)

                }
            </Col>
        </div>
    )
}

export default class About extends Component {
    static displayName = About.name;


    constructor(props) {
        super(props);
        // this.onGridRef = this.onGridRef.bind(this);


        const urlPara = this.getURLParameters(props.location)

        this.state = {

            user: getUserADAccount(this.props.user.account.userName),
            username: this.props.user.account.name,

            employeeKey: 0,
            supervisorName: "",
            supervisorKey: 0,

            isLoaded: false,
            empName: [],
            branchName: branchName,
            commitNumber: commitNumber.toString().substring(0, 7),
            commitLink: commitLink

        }
    }



    getURLParameters = (inputPara) => {
        var paraMeters = {
            safIncHeaderKey: null
        }

        var query
        query = new URLSearchParams(this.props.location.search);

        return paraMeters
    }


    getManager = () => {

        const { empName } = this.state
        let myURI = apiURL + 'SecAdmin/User'
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    empName: json.filter(rec => rec.adAccount != null && rec.adAccount.toUpperCase() === getUserADAccount(this.props.user.account.userName).toUpperCase())
                    //empName: json.filter(rec => rec.adAccount.toUpperCase() === getUserADAccount(this.props.user.account.userName).toUpperCase())
                })
                // console.log(json.filter(rec => rec.adAccount.toUpperCase() === "ADAM.FEW"))
            }).then(this.getEmpKeys)


    }


    getEmpKeys = () => {
        const { empName, } = this.state
        const { user } = this.props
        var empKey
        const adAccount = getUserADAccount(user.account.userName)

        var employeeKey
        var supervisorName
        var supervisorKey
        console.log(empName[0].employeeKey);
        if (empName.length > 0) {
            employeeKey = empName[0].employeeKey;
            supervisorName = empName[0].managerName;
            supervisorKey = empName[0].managerKey;
        }
        this.setState({ employeeKey: employeeKey, supervisorName: supervisorName, supervisorKey: supervisorKey })

    }





    componentDidMount() {

        this.getManager()
console.log("Alejandro Perez 19 New testing update..");
    }


    getddEmployees = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyObservation/SubmitBy'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    //   isddEmployeesLoaded: true,
                    ddEmployees: json
                })
            });
    }


    render() {

        const { user,
            username, supervisorName, data, ddReason, employeeKey,branchName,commitNumber,commitLink
        } = this.state

        return (
            <div>

                <Form onSubmit={e => e.preventDefault()}>

                    <h2>About </h2>
                    {/* </Col> */}


                    <FormGroup row  >
                        <Col className="col-md-3" >
                            <Row form>
                                <Label for="user">Name</Label>
                                <Input disabled type="text" name="user"
                                    id="user" placeholder=""
                                    value={username}
                                />
                            </Row>
                        </Col>
                        <Col className="col-md-3" >
                            <Row form>
                                <Label for="supervisor">Supervisor's Name</Label>
                                <Input disabled type="text" name="supervisor"
                                    id="supervisor" placeholder=""
                                    value={supervisorName}
                                />
                            </Row>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <VersionHistory> </VersionHistory>

                    </FormGroup>
                    <FormGroup row> 
                        <p style={{ marginBottom: "0px", marginLeft: "20px" }} className="font-weight-bold">{"Application Source:"}</p> 
                    </FormGroup>
                    <FormGroup row> 
                        <p style={{ marginTop: "-17px", marginBottom: "0px", marginLeft: "40px" }} >Commit: <a href={commitLink} target="_blank" rel="noopener noreferrer">{commitNumber}</a></p>
                    </FormGroup>  
                    <FormGroup row> 
                        <p style={{ marginTop: "-17px", marginBottom: "0px", marginLeft: "40px" }} >Branch: {branchName}</p>
                    </FormGroup>
 
                    <UserGroupforAbout UserGroupKey={employeeKey}
                        userInput={this.props.user}
                        toggleModalUserGroup={true}
                        onGridRef={this.onGridRef}
                    >

                    </UserGroupforAbout>





                </Form>




            </div>

        );



        //       }
        //     }
        //   </FetchData>
        // );
    }
}
