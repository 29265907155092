import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import RevisionPng from '../resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal';
import DocumentBrowserDragDrop from  '../resources/DocumentBrowserDragDrop'; // '../../resources/DocumentBrowserDragDrop';

import NumberFormat from 'react-number-format'; 
import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../resources/utility';
import PowerBIReportViewer from '../resources/PowerBIReportViewer' 
import ConstructionReportingChangeOrders from './ConstructionReportingChangeOrders';
import FileManager from '../resources/FileManager';
import { EG_ThemeableFillStyle } from 'igniteui-react-excel';

var DatePicker = require("reactstrap-date-picker");
  
class DailyGeneralReport extends React.Component {
    static displayName =  'Alex testing' //AFEDetail.name

    constructor(props) {
        super(props)

        this.isFormReadOnly = this.isFormReadOnly.bind(this);
        this.closeForm = this.closeForm.bind(this);
        

        this.state = {
            user: props.user,
            constructionReportKey: props.constructionReportKey,
            constructionReportGeneralKey: props.constructionReportGeneralKey === undefined || 
                                         props.constructionReportGeneralKey === null ? 0 : 
                                         props.constructionReportGeneralKey,
            constructionReports: props.constructionReports,
            constructionReportDTO: props.constructionReportDTO,
            isSecurityAdminLogin:props.isSecurityAdminLogin,
            statusKey: null,
            closeoutStatusKey: -1,
            alertMessage: "",
            showAlert: false,
            showSaveAlert: false,
            isFormReadOnly: false,
            isApprover: false,
            isAFEManager: false,
            isAFEAdmin: false,
            showCostTracking: false,
            isLoaded: false, 
            generalReportDTO: null,
            empList: props.empList,
            isEmpListLoaded: false,
            budgetClassList: props.budgetClassList,
            isBudgetClass: false,
            afeTypeList: props.afeTypeList,
            isAFETypeLoaded: false,
            costTypeList: props.costTypeList,
            isCostTypeLoaded: false,
            facilityList: props.facilityList,
            isFacilityLoaded: false,
            budgetList: props.budgetList,
            dupAFEList: props.dupAFEList,
            isBudgetLoaded: false,
            isUserAdmin: false,
            isSecurityLoaded: false,
            uomList: props.uomList,
            linkAFEList: props.linkAFEList,
            basinList: props.basinList,
            countyList: props.countyList,
            projectStatusList: props.projectStatusList,
            areaList: props.areaList,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            alertMessage: "",
            alertColor: "danger",
            isForceBudgeLineUpdate: false,
            budgetLineRefreshKey: 0,
            activeTab: '1',
            hasDCCost: false,
            isDataChange: false,
            //isApprovalRevision:false,
            yesNoList: [{
                "label": "Yes",
                "value": 1
            }, {
                "label": "No",
                "value": 0
            }],
            validateDataItem: {
                reportDate: true,
                workSummary: true,
                afeHeaderKey: true,
                estimatedCompletion: true,
                issues: true,
                integrityTestingComplete:true,
                inspectorName: true,
                expectedCompletionDate: true 
            },
            siteTransfer: null ,
            afesRevSupBuget: [],
            reimbursable: [{label: '',value: -1}, {label: 'No',value: 0},{label:'Yes', value: 1}],
            modalReport: false,
            reportName: "",
            dropdownOpenManage: false,
            showFileManager: true,
            dailyReports: [],
            edited: false,
        }

        console.log('test');
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const { generalReportDTO, validateDataItem } = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }



            if (generalReportDTO.afeHeaderKey === null || generalReportDTO.afeHeaderKey <= 0) {
                validateDataItem.afeHeaderKey = false
                returnVal.ok = false
                returnVal.message = returnVal.message + " No AFE is selected"
            }

            if (!IsDate(generalReportDTO.reportDate)) {
                validateDataItem.reportDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                } 
                returnVal.message = returnVal.message + " A Report Date was not provided."
            }

            let objReportDate = this.isReportDateExistOnDifferentDailyReport(generalReportDTO.reportDate);

            if (objReportDate){
                validateDataItem.reportDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                } 
                returnVal.message = returnVal.message + ' Report Date ' + generalReportDTO.reportDate.toString().substr(0,10) + ' is being used by another daily report.'
            }

            if (!IsDate(generalReportDTO.expectedCompletionDate)) {
                validateDataItem.expectedCompletionDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " A ExpectedCompletion Date was not provided."
            }

            if (generalReportDTO.workSummary === null || generalReportDTO.workSummary.trim() === '') {
                validateDataItem.workSummary = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Work  Summary is required."
            }
 
            if (generalReportDTO.estimatedCompletion === null || generalReportDTO.estimatedCompletion < 0) {
                validateDataItem.estimatedCompletion = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Estimated Completion needs to be greater than -1%."
            }
  
            if (generalReportDTO.issues === null) {
                validateDataItem.issues = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Issues needs to be selected."
            }

            if (generalReportDTO.integrityTestingComplete === null) {
                validateDataItem.integrityTestingComplete = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Integrity Testing Complete needs to be selected."
            }

            if (generalReportDTO.inspectorName === null || generalReportDTO.inspectorName.trim() === '') {
                validateDataItem.inspectorName = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Inspectors Name needs to be filled out."
            }
 

            returnVal.validateDataItem = validateDataItem
            if (returnVal.message !== "") {
                returnVal.message = "Please enter missing data: " + returnVal.message
            }
            resolve(returnVal)
        })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleAlert = () => { 
        this.setState({ showAlert: !this.state.showAlert });
    }

    toggleSaveAlsert = () => {
        this.setState({ showSaveAlert: !this.state.showSaveAlert },
            () => { window.setTimeout(() => { this.setState({ showSaveAlert: false }) }, 3000) })
    }
   
    closeForm = () => {
        this.setState({isLoaded: false}); 
         this.props.toggle(this.state.edited);
    }
 
    saveData = async(statusKey) => {
        const { constructionReportGeneralKey } = this.state
 
        this.setState({ showAlert: false, alertMessage: '' });

        this.dataValidation().then(async (data) => {
            if (data.ok) {

                if (constructionReportGeneralKey > 0) {
                    await  this.updateReport(statusKey)
                } else {
                    this.addReport(statusKey)
                }

                this.toggleSaveAlsert()
            } else {
                this.setState({
                    alertMessage: data.message,
                    validateDataItem: data.validateDataItem,
                    showAlert: true
                })
            }
        })
         


    }

    updateReport = async(statusKey) => {
        let {generalReportDTO,user} = this.state;
        
        generalReportDTO.statusKey = statusKey;
        generalReportDTO.modifiedBy = user.account.userName;

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/GeneralDailyReport/Edit/' + generalReportDTO.expectedCompletionDate;
        this.setState({isLoaded: false});
        
        await fetch(myURI, apiPOSTHeader(generalReportDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg,
                            isLoaded: true,
                        })
                    })

                    return Promise.reject(error)
                } 
                else {
                  this.setState({ edited: true});
                } 
                 
            }).then(json => {
                //this.setState({  isLoaded: false,constructionReportGeneralKey: json.constructionReportGeneralKey });  
                //this.componentDidMount(); 
                this.closeForm();

            }).catch(error => {
                console.log("PUT error: " + error)
            })

            
    }
   
    addReport = (statusKey) => {
      
    let {generalReportDTO} = this.state;

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/GeneralDailyReport/Add/' + generalReportDTO.expectedCompletionDate;
        generalReportDTO.statusKey = statusKey;

        this.setState({isLoaded: false});

        fetch(myURI, apiPOSTHeader(generalReportDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg,
                            isLoaded: true,
                        })
                    })

                    return Promise.reject(error)
                } else{
                    this.setState({edited: true});
                }
                
                return response.json()
            }).then(json => {
                // console.log(json);
                // this.setState({generalReportDTO: json,constructionReportGeneralKey: json.constructionReportGeneralKey})
                // this.componentDidMount();
                // this.toggleSaveAlsert()
                this.closeForm();
            }).catch(error => {
                console.log("PUT error: " + error)
            })

    }
    
    async getAllDailyReports(){
        
        const { constructionReportKey} = this.state;

        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey + '/DailyReports'; 
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                 
                this.setState({ 
                    dailyReports: json, 
                }) 
            });
    }

    onTextChange = (fieldName, fieldValue, e) => {
        let { constructionReportDTO,generalReportDTO, validateDataItem,alertMessage,showAlert } = this.state
        const { user } = this.props
      

        showAlert = false;

        switch (fieldName) {
            case 'reportDate':
                // validateDataItem.reportDate = true;
                // generalReportDTO.reportDate = fieldValue;

                let objReportDate = this.isReportDateExistOnDifferentDailyReport(fieldValue);
                if (objReportDate){
                    alertMessage = 'Report Date ' + fieldValue.toString().substr(0,10) + ' is being used by another daily report.';
                    showAlert = true;
                    validateDataItem.reportDate = false; 
                }
                else{
                    validateDataItem.reportDate = true;
                    generalReportDTO.reportDate = fieldValue;
                }  
                break;
            case "expectedCompletionDate":
                    validateDataItem.expectedCompletionDate = true;
                    generalReportDTO.expectedCompletionDate = fieldValue;
                    break;
            case 'workSummary':
                validateDataItem.workSummary = true;
                generalReportDTO.workSummary = fieldValue;
                break;
            case 'estimatedCompletion':
                validateDataItem.estimatedCompletion = true;
                generalReportDTO.estimatedCompletion = (fieldValue < 0 ||  fieldValue > 100) ? generalReportDTO.estimatedCompletion : fieldValue;
                break;
            case 'rowClearedCompletedToday':
                let obj = null;

                if (fieldValue == 'true'){
                    obj = true;
                }
                else if (fieldValue == 'false' ){
                    obj = false;
                }

                constructionReportDTO.rowClearedCompletedToday = obj;
            case 'issues':
                validateDataItem.issues = true;
                generalReportDTO.issues = fieldValue ? 1 : 0;
                break;
            case 'integrityTestingComplete':
                validateDataItem.integrityTestingComplete = true;
                generalReportDTO.integrityTestingComplete = fieldValue ? 1 : 0;
                break;
            case 'inspectorName':
                validateDataItem.inspectorName = true;
                generalReportDTO.inspectorName = fieldValue;
                break;
            default:
                break;  
        }

        this.setState({
            generalReportDTO: generalReportDTO,
            validateDataItem: validateDataItem,
            alertMessage: alertMessage,
            showAlert: showAlert, 
        }) 
    }

    isReportDateExistOnDifferentDailyReport(reportDate){
        const {constructionReports,constructionReportGeneralKey,generalReportDTO} = this.state;

        if(reportDate === undefined || reportDate === null ||  reportDate == ''){
            return false;
        }

        reportDate = reportDate.substr(0,10);

        if (constructionReports !== undefined && constructionReports !== null){
            let obj = constructionReports.filter(x => x.constructionReportGeneralKey !== generalReportDTO.constructionReportGeneralKey &&
                                                      x.reportDate.substr(0,10) == reportDate);
            if (obj != null && obj.length > 0){
                return true;
            }
        }
        return false;
    }

    issues = (fieldName) => {
        let {generalReportDTO} = this.state;
        let result = false;

        if (generalReportDTO == undefined || generalReportDTO == null){
            return result;
        }


        if (generalReportDTO.issues == null){
            result = false;
        }
        else if (generalReportDTO.issues && fieldName == 'YES'){
            result = true;
        }
        else if (generalReportDTO.issues == false && fieldName == 'YES'){
            result = false;
        }
        else if (generalReportDTO.issues && fieldName == 'NO'){
            result = false;
        }
        else if (generalReportDTO.issues == false && fieldName == 'NO'){
            result = true;
        }

        return result; 
    }

    integrityTestingCompleted = (fieldName) => {
        let {generalReportDTO} = this.state;
        let result = false;

        if (generalReportDTO == undefined || generalReportDTO == null){
            return result;
        }

        if (generalReportDTO.integrityTestingComplete == null){
            result = false;
        }
        else if (generalReportDTO.integrityTestingComplete && fieldName == 'YES'){
            result = true;
        }
        else if (generalReportDTO.integrityTestingComplete == false && fieldName == 'YES'){
            result = false;
        }
        else if (generalReportDTO.integrityTestingComplete && fieldName == 'NO'){
            result = false;
        }
        else if (generalReportDTO.integrityTestingComplete == false && fieldName == 'NO'){
            result = true;
        }

        return result; 
    }

    onSiteToSiteChange = (fieldValue) => {
        const { afeData  } = this.state
        afeData[0].siteTransfer = fieldValue;
        this.setState({ afeData: afeData })
    }

    getAFEDetailsAPI = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }
 

    setIsShowCostTracking = (statusKey) => {
        return !(statusKey === 0 || statusKey === 1 || statusKey === 2 || statusKey === 3)
    }
 

    isAFEApprover = (statusKey, approver, closeOutStatusKey) => {
        const { user } = this.props
        if (approver !== "" && (statusKey === 3 || closeOutStatusKey === 3)) {
            return approver.includes(user.account.userName.toLowerCase())
        } else {
            return false
        }
    }

    async getConstructionReportDetails(constructionReportKey) {
        //const { constructionReportKey } = this.state
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({ 
                    constructionReportDTO: json, 
                }) 
            });
    }



    setIsFormReadonly = (statusKey, afeManagerAzureAD) => {
        const { user } = this.props
        const { isAFEAdmin } = this.state


        if (statusKey === 1 || statusKey === 10) { //Rejected //10=Revise
            return !((afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase()) || isAFEAdmin)
        } if (statusKey === 6 || statusKey === 5) {
            return true
        } else if (statusKey === 3) {
            return true
        } else if (statusKey === 8) { // 8 - Closed
            return true
        } else if (statusKey === 11) {// 11=Project Started
            return true
        } else if (statusKey === 2) {
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } if (statusKey === 4) { //Approved 
            //return true
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } else {
            return false
        }

    }

    createConstructionReport = () => {
        const { user } = this.props;

        var date = new Date();
        let todaysDate = date.toLocaleDateString();

        const constructionReportDTO = {
            "constructionReportKey": 0,
            "afeNo": null,
            // "afeName": "",
            "constructionReportStatusDesc": "Initiated",
            "statusKey": 1,
            "afeHeaderKey": 0,
            "projectName": "",
            "createdBy": user.account.name,
            "createdDate": todaysDate, 
            "modifiedBy": user.account.name,
            "modifiedDate": todaysDate,  
            "projectManager": "",
            "projectType": null,
            "requiredEndDate": null,
            "pipeSize": null,
            "uncasedBoreLength": null,
            "projectLength": null,
            "casedBoreLength": null,
            "lat": null,
            "long": null,
            "comments": "", 
        }

        return [constructionReportDTO]
    }

    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }

    async componentDidUpdate(prevProps,prevState){
        if(prevProps.constructionReportGeneralKey !== this.props.constructionReportGeneralKey){
            this.state.isLoaded = false;
            
             
            this.setState({
                isLoaded:true,
                constructionReportGeneralKey: prevProps.constructionReportGeneralKey,
                //projectTypeKey:this.props.children[3], 
            });
   
        }

         console.log('tewt');
    }

    async componentDidMount() { 
        const { constructionReportKey,constructionReportGeneralKey } = this.state;  
        await this.loadReportData();
        this.setState({  isLoaded: true });
    }
  
    async loadReportData(){
        const {constructionReportDTO,constructionReportKey,constructionReportGeneralKey,user} = this.state;
        if (constructionReportGeneralKey == undefined || constructionReportGeneralKey == null || constructionReportGeneralKey <=0 ){

            await this.getAllDailyReports();
            const {dailyReports} = this.state;
            let objDailyReport = [];

            if(dailyReports.length > 0){
                objDailyReport = dailyReports[0];
            }

            var date = new Date();
            let todaysDate = this.formatDate(date);
             
            let generalReportDTO = {
                constructionReportGeneralKey: 0,
                constructionReportKey: constructionReportKey,
                reportDate: todaysDate,
                workSummary: null, 
                estimatedCompletion: constructionReportDTO.estimatedCompletion,
                estimatedCompletionTotalCompleted: objDailyReport != null && objDailyReport.estimatedCompletionTotalCompleted != null ? objDailyReport.estimatedCompletionTotalCompleted : 0,
                issues: null,
                accident: 0,
                integrityTestingComplete: null,
                inspectorName: user.account.name,
                createdBy: user.account.userName,
                createdByKey: 0,
                createdDate: todaysDate,
                statusKey: 0,
                status: "Draft",
                afeNo: constructionReportDTO.afeNo,
                afeHeaderKey: constructionReportDTO.afeHeaderKey,
                projectName: constructionReportDTO.projectName,
                startDate: constructionReportDTO.startDate,
                endDate: constructionReportDTO.estComplete,
                expectedCompletionDate: constructionReportDTO.expectedCompletionDate,
                locationKey: constructionReportDTO.locationKey,
                basin: constructionReportDTO.basin,
                afeStatusKey: 0,
                afeStatusDesc: '',
                blob_id: "-" + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString() + date.getMilliseconds().toString()
            }

            this.setState({generalReportDTO: generalReportDTO})
        }
        else if (constructionReportGeneralKey > 0){
            //Call API;
            await this.getReportData();
        }
    }

    async getReportData(){
        
        const {constructionReportDTO,constructionReportKey,constructionReportGeneralKey,user} = this.state;

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/' + constructionReportKey + '/DailyReport/' + constructionReportGeneralKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({ 
                    generalReportDTO: json, 
                }) 
            });
    }


    isFormReadOnly = () => {
        const {  constructionReportDTO} = this.state

        if (constructionReportDTO !== undefined){
            return constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4 || constructionReportDTO.statusKey === 5 ? true : false;
        }
        return false;
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKey} = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKey }]; 
        return reportPara
    }
    
    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }

    async getAFEReport(reportName){
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }

    formatDate = (value) => {

        if (IsDate(value)) {
            const months = {
                0: '01',
                1: '02',
                2: '03',
                3: '04',
                4: '05',
                5: '06',
                6: '07',
                7: '08',
                8: '09',
                9: '10',
                10: '11',
                11: '12',
            }
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            const d = new Date(value)
            const year = d.getFullYear()
            const date = (d.getDate() < 10 ? '0' : '') + d.getDate()
            const monthIndex = d.getMonth()
            const monthName = months[d.getMonth()]
            const dayName = days[d.getDay()] // Thu
    
            //const formatted = `${monthName}/${date}/${year}`
            const formatted = `${year}-${monthName}-${date}`
            return formatted.toString()
        } else {
            return ""
        }
    
    }

    render() {
        const {constructionReportGeneralKey,reportName, constructionReportDTO,generalReportDTO, alertMessage,  
            showAlert , isLoaded , showSaveAlert, 
             alertColor, validateDataItem,isSecurityAdminLogin } = this.state
        const { user } = this.props
  
 
        let index = 0;
  
        if (!isLoaded) {
            return (<div>Loading General Daily Report Data .....</div>)
        }
        else if (generalReportDTO == undefined || generalReportDTO === null || constructionReportDTO === undefined || constructionReportDTO === null){
            return (<div>Loading General Daily Re port Data .....</div>)
        }
        else
            //console.log('generalReportDTO.issues' & generalReportDTO.issues)
            return (<div>
                 
                <Form onSubmit={e => e.preventDefault()}>
                    <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <Col sm={5}>

                            <Button color="secondary" className="float-left"
                                //hidden={isDataChange}
                                onClick={() =>this.closeForm()}
                                >Close
                            </Button>  
                            <Button color="primary" className="float-left"
                                style={{ marginLeft: "5px" }}
                                //ref={ref}
                                hidden={generalReportDTO == undefined || generalReportDTO == null || ( !isSecurityAdminLogin && (generalReportDTO.statusKey == 0 || this.isFormReadOnly()))}
                                onClick={() => this.saveData(2)}>Save</Button>
                            <Button color="success" className="float-left"
                                style={{ marginLeft: "5px" }}
                                //ref={ref}
                                hidden={generalReportDTO == undefined || generalReportDTO == null || generalReportDTO.statusKey > 0 }
                                onClick={() => this.saveData(2)}>Submit</Button>     
                        </Col> 
                        <Col sm={1}> 
                              
                        </Col>  
                    </Row>
                    <ColoredLine color="grey" />
                    <Row form>
                        <Alert color="success" isOpen={showSaveAlert}
                            toggle={this.toggleSaveAlsert}
                            style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                            {"Data Save Successfuly !!!!"}
                        </Alert>
                        <Alert color={alertColor} toggle={() => this.toggleAlert()}
                            isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {alertMessage}
                        </Alert >
                    </Row>
                    <Accordion allowMultipleExpanded={true}
                        allowZeroExpanded={true}
                        preExpanded={(['general','workSummary'])}>

                        <AccordionItem uuid='general'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>General Information</strong>  
                                    {/* <strong style={{ textAlign: 'right', paddingLeft: '45rem' }}>Status - {generalReportDTO.status}</strong>  */}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeNo">AFE No</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    disabled type="text" 
                                                    name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={generalReportDTO == undefined || generalReportDTO == null ||
                                                        generalReportDTO.afeNo == undefined || generalReportDTO.afeNo==null ? '' : generalReportDTO.afeNo}
                                                />
                                            </Col> 
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="createdBy">Project Name</Label>
                                            </Col>
                                            <Col md={8}>
                                                 <Input disabled type="text" name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={generalReportDTO == undefined || generalReportDTO == null ||
                                                        generalReportDTO.projectName == undefined || generalReportDTO.projectName==null ? '' : generalReportDTO.projectName}
                                                />
                                            </Col>

                                        </Row>
                                    </Col> 
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Start Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    //className={(!validateDataItem.afeName ? "form-error" : "")}
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : FormatDate(generalReportDTO.startDate)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">End Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="afeName"
                                                    //className={(!validateDataItem.afeName ? "form-error" : "")}
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : FormatDate(generalReportDTO.endDate)}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row> 
                                
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Report Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <DatePicker
                                                    className={(!validateDataItem.reportDate ? "form-error" : "")} 
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : generalReportDTO.reportDate}
                                                    onChange={(v, f) => this.onTextChange("reportDate", v, f)}
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                    <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Basin</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="afeName"
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : generalReportDTO.basin}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Expected Completion Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <DatePicker 
                                                    value={generalReportDTO.expectedCompletionDate}
                                                    className={(!validateDataItem.expectedCompletionDate ? "form-error" : "")}
                                                    onChange={(v, f) => this.onTextChange("expectedCompletionDate", v, f)}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={12}> 
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeSponsor">Work Summary</Label>
                                            </Col>
                                            <Col md={10}>
                                                <Input type="textarea" name="afeDescription"
                                                    rows={8}
                                                    className={(!validateDataItem.workSummary ? "form-error" : "")}
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()}
                                                    id="summary" placeholder=""
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : generalReportDTO.workSummary}
                                                    onChange={(e) => this.onTextChange("workSummary", e.target.value, e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>  
                                </Row> 
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem uuid='workSummary'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>Details</strong>  
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Row form style={{   marginBottom: "5px" }}>
                                    <Col md={12}>
                                        <Row form>
                                            <Col md={4}>
                                                    
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "8px"}}>
                                                <Label for="afeNo"><strong>Completed Today</strong></Label>
                                            </Col>
                                            <Col md={2} style={{paddingLeft: "17px"}}>
                                                <Label for="afeNo"><strong>Total Completed</strong></Label> 
                                            </Col> 
                                        </Row>
                                    </Col>
                                </Row>
                                <Row form style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Col md={12}>
                                        <Row form>
                                            <Col md={2}>
                                                
                                            </Col>
                                             
                                        </Row>
                                    </Col>
                                    <Col md={12} >
                                        <Row form  style={{ marginBottom: "5px" }}>
                                            <Col md={2}>
                                                <Label for="afeNo"><strong>Estimated Completion</strong></Label>
                                            </Col>
                                            <Col md={2} > 
                                            </Col> 
                                            <Col md={2} style={{ paddingRight:"14px"}}>
                                                <NumberFormat
                                                    className={(!validateDataItem.estimatedCompletion ? "form-error" : "")}
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()} 
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : generalReportDTO.estimatedCompletion}
                                                    onValueChange={(e) => this.onTextChange("estimatedCompletion", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                    style={{height: "37px"}}
                                                    />  
                                            </Col>
                                            <Col md={1} style={{marginLeft: "8px",marginRight: "5px"}}>
                                                <NumberFormat
                                                    //className={(!validateDataItem.estimatedCompletion ? "form-error" : "")}
                                                    disabled={true} 
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : generalReportDTO.estimatedCompletionTotalCompleted}
                                                    //onValueChange={(e) => this.onTextChange("estimatedCompletion", e.value, e)}
                                                    fixedDecimalScale={true}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                    suffix={'%'} 
                                                    style={{height: "37px"}}
                                                    />  
                                            </Col>  
                                        </Row> 
                                         
                                    </Col>
                                     
                                </Row> 
                                   

                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={4}> 
                                        <Row form>
                                            <Col md={12}>
                                                <Label for="afeSponsor">Issues: If yes, describe in work summary above</Label>
                                            </Col> 
                                        </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Row form> 
                                            <Col md={3}>
                                                <CustomInput
                                                    className={(!validateDataItem.issues ? "form-error" : "")}  
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()}
                                                    type="checkbox"
                                                    id={`q${index + 1}Yes`}
                                                    label={"Yes"}
                                                    checked={this.issues('YES')}
                                                    onChange={(e) => this.onTextChange("issues", true, e)
                                                    }
                                                /> 
                                            </Col>
                                            <Col md={3}>
                                                <CustomInput
                                                    className={(!validateDataItem.issues ? "form-error" : "")}  
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()}
                                                    type="checkbox"
                                                    id={`q${index + 1}No`}
                                                    label={"No"} 
                                                    checked={this.issues('NO')}
                                                    onChange={(e) => this.onTextChange("issues", false, e)
                                                    }
                                                /> 
                                            </Col>
                                             
                                        </Row>
                                    </Col>  
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={4}> 
                                        <Row form>
                                            <Col md={12}>
                                                <Label for="afeSponsor">Integrity Testing Completed</Label>
                                            </Col> 
                                        </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Row form> 
                                            <Col md={3}>
                                                <CustomInput
                                                    className={(!validateDataItem.integrityTestingComplete ? "form-error" : "")}  
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()}
                                                    type="checkbox"
                                                    id={`q${index + 1}Yes2`}
                                                    label={"Yes"}
                                                    checked={this.integrityTestingCompleted('YES')}
                                                    onChange={(e) => this.onTextChange("integrityTestingComplete", true, e)
                                                    }
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <CustomInput
                                                    className={(!validateDataItem.integrityTestingComplete ? "form-error" : "")}  
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()}
                                                    type="checkbox"
                                                    id={`q${index + 1}No2`}
                                                    label={"No"}
                                                    checked={this.integrityTestingCompleted('NO')}
                                                    onChange={(e) => this.onTextChange("integrityTestingComplete",false,e)
                                                    }
                                                /> 
                                            </Col>
                                             
                                        </Row>
                                    </Col>  
                                </Row> 
                                <Row form style={{ marginBottom: "20px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={12}>
                                                <Label for="afeSponsor"><strong>Inspectors Name</strong></Label>
                                            </Col> 
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row form>   
                                            <Col md={6}>
                                                <Input
                                                    className={(!validateDataItem.inspectorName ? "form-error" : "")}
                                                    disabled={!isSecurityAdminLogin && this.isFormReadOnly()} 
                                                    type="text" 
                                                    name="inspectorName"
                                                    id="inspectorName" 
                                                    placeholder={"Enter Inspectors Name"}
                                                    value={generalReportDTO == undefined || generalReportDTO == null ? null : generalReportDTO.inspectorName}
                                                    onChange={(e) => this.onTextChange("inspectorName", e.target.value, e)}
                                                />
                                            </Col> 
                                        </Row>
                                    </Col>  
                                </Row> 
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem hidden={ constructionReportDTO === undefined || constructionReportDTO === null ||
                                                generalReportDTO === undefined || generalReportDTO === null}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Attachments"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {/* <FileManager
                                        assetkey={generalReportDTO.constructionReportGeneralKey}
                                        controlid={getRandomInt}
                                        folderPath={'constructionReport/' + constructionReportDTO.blob_id + '/DailyReportsDocuments/' + generalReportDTO.constructionReportGeneralKey}
                                        linkTableName= {'topConstructionReportGeneral'}
                                    >
                                    </FileManager>  */}
                                    <FileManager
                                        assetkey={generalReportDTO.constructionReportGeneralKey > 0 ? generalReportDTO.constructionReportGeneralKey : generalReportDTO.blob_id}
                                        controlid={getRandomInt}
                                        folderPath={'constructionReport/' + constructionReportDTO.blob_id + '/DailyReportsDocuments/' + generalReportDTO.blob_id}
                                        linkTableName= {'topConstructionReportGeneral'}
                                        blobContainter={constructionReportDTO.blob_id}
                                    >
                                    </FileManager> 
                                </AccordionItemPanel>
                        </AccordionItem> 
                        <AccordionItem hidden={constructionReportDTO.constructionReportKey == undefined || constructionReportDTO.constructionReportKey == null || 
                                                constructionReportDTO.constructionReportKey <=0 || constructionReportDTO.statusKey <= 0 ||
                                                constructionReportGeneralKey == undefined ||  constructionReportGeneralKey <= 0}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Change Orders "}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ConstructionReportingChangeOrders
                                        constructionReportKey={constructionReportDTO.constructionReportKey}
                                        projectType={constructionReportDTO.projectType}
                                        user={this.props.user}
                                        constructionReportDTO={constructionReportDTO}
                                        reportKey={constructionReportDTO.constructionReportGeneralKey}
                                        dailyReportKey={this.props.constructionReportGeneralKey}
                                        reportDate={generalReportDTO.reportDate}
                                        isSecurityAdminLogin={isSecurityAdminLogin} 
                                    >  
                                    </ConstructionReportingChangeOrders> 
                                </AccordionItemPanel>
                        </AccordionItem>  
                    </Accordion>

                    <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            size={"xl"}
                            centered
                            >
                            <ModalHeader>"Daily Report"</ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName = {reportName} 
                                    reportParameters = {this.createReportParamter()}
                                                        
                                    toggle={this.toggleReport}
                                
                                >

                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div>

                     
                </Form>

            </div>)
    }

}
 

export default DailyGeneralReport
