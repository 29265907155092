//Created by BN 2/3/22


import React, { Component, useEffect, useState} from 'react';
import {Col, Row, Button, FormGroup, Label} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';

import {apiURL, apiHeader, apiPOSTHeader, apiDELETEHeader} from '../resources/apiURL';

import '../custom.css';

import MenuIcon from '../resources/menu.png';
import RefreshIcon from '../resources/refresh.svg';
import PlusIcon from '../resources/plus.svg';
import TrashIcon from '../resources/trash.svg';
import ExcelIcon from '../resources/excel.svg';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import { ExcelUtility } from '../operation/ExcelUtility';
import SecurityCheckpointAdminDetails from './SecurityCheckpointAdminDetails'
import ConfirmModal from '../operation/ConfirmModal'
import { ColoredLine, getFirstDayofMonth,useWindowDimensions } from '../resources/utility';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();


export default class SecurityCheckpointListView extends Component{
    constructor (props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        // this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
                chkPointListView: [],
                isLoaded: false,
                windowHeight:window.innerHeight,
                windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
                hideAssociateDetails:((typeof(props.HideAssociateDetails) ==='undefined' || props.HideAssociateDetails===null) ? false : props.HideAssociateDetails)
                
        }
    }


    
    getListView= () =>{
        // const {user}=this.state;

        let myURI = apiURL + 'SecAdmin/SecCheckPointListView' 
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                chkPointListView: json
            })
        })
    }


    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.chkPointListView;
    }


    
    handleResize = (e) => {
   
        if (document.getElementById('mainDiv') !== null){
            this.setState({ windowHeight: window.innerHeight,
                windowWidth: document.getElementById('mainDiv').offsetWidth });

        }
    };

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getListView()
    }


    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 


    render(){
        const {isLoaded, chkPointListView, windowHeight, hideAssociateDetails} = this.state
        const gridHeight=(windowHeight * 0.80) + "px"
        // const searchText = window.$searchText.toLowerCase()
        if(!isLoaded){
            <h3>Loading Security Checkpoint List...... </h3>
        }
        return(<div>
             <FormGroup>
                    <Row form>
                        {/* <h2> Security CheckPoint |</h2> */}
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={this.refreshUserData}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>

                         <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}
                                >
                            <img 
                            src={ExcelIcon}
                            alt="excel"
                            style={{width:"25px", height:"25px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '} 

                       
                            
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>

                <div>
                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        //height= "600px"
                        height={gridHeight}
                        // width="Calc(100% - 5px)"
                        // width="100%"
                        // dataSource={chkPointListView.filter(rec => ((rec.wbrEmpID || '').toLowerCase().includes(searchText)) ||
                        //                         (rec.empName || '').toLowerCase().includes(searchText) ||
                        //                         (rec.empStatusDesc || '').toLowerCase().includes(searchText) ||
                        //                         (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                        //                         (rec.azureAD || '').toLowerCase().includes(searchText) ||
                        //                         (rec.locationName || '').toLowerCase().includes(searchText) ||                                                            
                        //                         (rec.managerNameLvl1 || '').toLowerCase().includes(searchText) )}
                        dataSource={chkPointListView}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        // selectedKeysChanged={this.onGridRowSelected}
                        >         


                        <IgrTextColumn field="userGroupName" headerText="User Group Name" width="150"  isEditable = "false" />  
                        <IgrTextColumn field="userGroupDesc" headerText="User Group Desc" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="appSecurityNo" headerText="Application Security No" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="appSecurityName" headerText="App Security Name" width="150"  isEditable = "false" />  
                        <IgrTextColumn field="appSecurityDesc" headerText="App Security Desc" width="150"  isEditable = "false" />  
                        <IgrTextColumn field="applicationName" headerText="App Name" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="applicationDesc" headerText="App Desc" width="150"  isEditable = "false" />        
                        <IgrTextColumn field="empName" headerText="Employee Name" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="emailAddress" headerText="Email Address" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="adAccount" headerText="AD Account" width="150"  isEditable = "false" />
                        <IgrTextColumn field="appModuleID" headerText="App Module ID" width="150"  isEditable = "false" />        
                        <IgrTextColumn field="appModuleName" headerText="App Module Name" width="150"  isEditable = "false" />    
                        <IgrTextColumn field="azureAD" headerText="azureAD" width="150"  isEditable = "false" />    



                    </IgrDataGrid>
                </FormGroup>
                </div>
      




        </div>)
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        // console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "SecurityCheckPointListView");
    }
}