
import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';

export class AFEFixAssetsPopUp extends Component {
    static displayName = AFEFixAssetsPopUp.name;

    constructor(props) {
        super(props)

        this.state = {
            formName: props.formName,
            message: props.message,
            buttonLabel: props.buttonLabel,
            id: props.id,
            commentPrompMsg: props.commentPrompMsg,
            modal: false,
            inputComment: props.inputComment,
            isValid: true,
            afeData: props.afeData,
            hidden: props.hidden,
            showAlert: false,
            alertMsg: ''
        }
    }

    toggleAlert = () => {

        // this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    setIsValid = (v) => {
        this.setState({ isValid: v })
    }

    setComment = (v) => {
        this.setState({ inputComment: v })
    }
    onClosed = () => {

        if (this.props.onClosed !== undefined) {
            this.props.onClosed();
        }

        this.setIsValid(true)
        this.setComment("")
    }

    fixxedInputValidate = () => {
        const { afeData } = this.state

        if (afeData[0].projectHierachy === "" || afeData[0].siteID === ""
            || afeData[0].costCenterID === "" || afeData[0].companyID === "") {
            this.setState({ alertMsg: "Please enter missing fixed assets data." })
            return true
        }
        return false
    }

    toggleYes = () => {
        const { inputComment } = this.state

        if (inputComment === "" || inputComment === undefined) {
            this.setIsValid(false)
        } else if (this.fixxedInputValidate()) {
            this.toggleAlert()
        } else {
            this.toggle()
            this.setState({ showAlert: false })
            this.props.onClickYes(inputComment);
            this.setComment("")
        }

    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData } = this.state
        var  showAlert=false 
        switch (fieldName) {
            case "comment":
                this.setComment(fieldValue)
                this.setIsValid(fieldValue !== "")
                break;

            case "projectHierachy":
                showAlert = false

                // afeData[0].projectHierachy = ""
                // //afeData[0].siteName = ""

                // if (e !== null && e !== undefined) {
                //     afeData[0].projectHierachy = e.value
                // }
                this.props.onTextChange(fieldName, fieldValue, e)
                break;

            case "siteID":
                showAlert = false

                // afeData[0].siteID = ""
                // afeData[0].siteName = ""

                // if (e !== null && e !== undefined) {
                //     afeData[0].siteID = e.value
                //     afeData[0].siteName = e.label
                //     showAlert = false
                // }
                this.props.onTextChange(fieldName, fieldValue, e)
                break;
            case "costCenterID":
                showAlert = false
                // afeData[0].costCenterID = ""
                // afeData[0].costCenterName = ""

                // if (e !== null && e !== undefined) {
                //     afeData[0].costCenterID = e.value
                //     afeData[0].costCenterName = e.label
                //     showAlert = false
                // }
                this.props.onTextChange(fieldName, fieldValue, e)
                break;
            case "companyID":
                showAlert = false
                // afeData[0].companyID = ""
                // afeData[0].companyName=""
                
                // if (e !== null && e !== undefined) {
                //     afeData[0].companyID = e.value
                //     afeData[0].companyName = e.label
                //     showAlert = false
                // }
                this.props.onTextChange(fieldName, fieldValue, e)
                break;
            default:
                break;

        }

        this.setState({ showAlert: showAlert})

    }

    render() {
        const { formName, message, image, buttonLabel, afeData, hidden, alertMsg, showAlert
            , commentPrompMsg, modal, inputComment, isValid } = this.state

        return (
            <div>
                <Button outline
                    color="success"
                    hidden={hidden}
                    onClick={this.toggle} style={{ margin: "0 5px 0 5px" }} >

                    {buttonLabel}

                </Button>

                <Modal isOpen={modal}
                    toggle={this.toggle}
                    size="lg"
                    onClosed={this.onClosed} >
                    <ModalHeader toggle={this.toggle}> {formName}</ModalHeader>
                    <ModalBody>
                        {message}
                        <div>
                            <Alert color="danger" isOpen={showAlert}
                                toggle={() => this.toggleAlert()}
                                style={{ marginTop: "5px", marginBottom: "10px" }}
                            >
                                {alertMsg}
                            </Alert>
                        </div>

                        <FormGroup style={{ marginBottom: "-10px" }}>
                            <Label for="inputComment">
                                {commentPrompMsg}
                            </Label>
                            <Input invalid={!isValid}
                                id="inputComment"
                                name="text"
                                type="textarea"
                                value={inputComment}
                                defaultValue={inputComment}
                                rows={3}
                                onChange={(e) => this.onTextChange("comment", e.target.value, e)}
                            />
                            <AFEFixAssets
                                afeData={afeData}
                                isFormReadOnly={false}
                                onTextChange={this.onTextChange}>

                            </AFEFixAssets>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleYes}>OK</Button>
                        <Button color="primary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default class AFEFixAssets extends Component {
    static displayName = AFEFixAssets.name;

    constructor(props) {
        super(props)

        this.state = {
            afeData: props.afeData,
            wdCompany: [],
            isWDCompanyLoaded: false,
            wdSite: [],
            isWDSiteLoaded: false,
            wdCostCenter: [],
            isWDCostCenterLoaded: false,
            wdProHierachy: [],
            isWDProHierachyHLoaded: false,
            isFormReadOnly: props.isFormReadOnly
        }
    }


    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData } = this.state
        var showAlert = false

        //         console.log("fieldName")        
        //         console.log(fieldName)

        // console.log(e.value)

        switch (fieldName) {
            case "projectHierachy":
                // console.log("fieldName")        
                // console.log(afeData[0].projectHierachy)

                // console.log(e.value)

                afeData[0].projectHierachy = ""
                //afeData[0].siteName = ""

                if (e !== null && e !== undefined) {
                    afeData[0].projectHierachy = e.value
                    //afeData[0].projectHierachy = e.label
                    showAlert = false
                }
                break;

            case "siteID":
                afeData[0].siteID = ""
                //afeData[0].siteName = ""

                if (e !== null && e !== undefined) {
                    afeData[0].siteID = e.value
                    afeData[0].siteName = e.label
                    showAlert = false
                }
                break;
            case "costCenterID":
                afeData[0].costCenterID = ""
                // afeData[0].costCenterName = ""
                if (e !== null && e !== undefined) {
                    afeData[0].costCenterID = e.value
                    afeData[0].costCenterName = e.label
                    showAlert = false
                }
                break;
            case "companyID":
                afeData[0].companyID = ""
                //afeData[0].companyName=""
                if (e !== null && e !== undefined) {
                    afeData[0].companyID = e.value
                    afeData[0].companyName = e.label
                    showAlert = false
                }
                break;
            default:
                break;
        }

        this.setState({ afeData: afeData })
        this.props.onTextChange(fieldName,fieldValue,e)
    }

    getWDCompany = () => {
        let myURI = apiURL + 'AFE/WDCompany'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    wdCompany: this.createWDCompanyList(json),
                    isWDCompanyLoaded: true
                })
            });
    }

    createWDCompanyList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.companyID
            e.label = e.companyDesc
            tempList.push(e)
        })

        return tempList
    }

    getSelWDCompany = (v) => {
        const { wdCompany } = this.state
        if (v !== null && v !== "") {
            return wdCompany.filter(e => e.companyID === v);
        }
        return ""
    }

    getWDCostCenter = () => {
        let myURI = apiURL + 'AFE/WDCostCenter'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    wdCostCenter: this.createWDCostCenterList(json),
                    isWDCostCenterLoaded: true
                })
            });
    }

    createWDCostCenterList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.costCenterID
            e.label = e.costCenterDesc
            tempList.push(e)
        })

        return tempList
    }

    getSelWDCostCenter = (v) => {
        const { wdCostCenter } = this.state
        if (v !== null && v !== "") {
            return wdCostCenter.filter(e => e.costCenterID === v);
        }
        return ""
    }

    getProjectHierachy = () => {
        let myURI = apiURL + 'AFE/ProjectHierachyLvl4'

        //console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    wdProHierachy: this.createProHierachyList(json),
                    isWDProHierachyHLoaded: true
                })
            });
    }

    createProHierachyList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.projectHierarchyID
            e.label = e.projectHierarchyID
            tempList.push(e)
        })

        return tempList
    }

    getSelProHierachyList = (v) => {
        const { wdProHierachy } = this.state
        // console.log("v---")
        // console.log(v)
        // console.log("v--")

        if (v !== null && v !== "") {
            return wdProHierachy.filter(e => e.projectHierarchyID === v);
        }
        return ""
    }

    getWDSite = () => {
        let myURI = apiURL + 'AFE/WDSite'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    wdSite: this.createWDSiteList(json),
                    isWDSiteLoaded: true
                })
            });
    }

    createWDSiteList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.siteID
            e.label = e.siteDesc
            tempList.push(e)
        })

        return tempList
    }



    getSelWDSite = (v) => {
        const { wdSite } = this.state
        if (v !== null && v !== "") {
            return wdSite.filter(e => e.siteID === v);
        }
        return ""
    }

    componentDidMount() {
        this.getWDCompany()
        this.getWDCostCenter()
        this.getWDSite()
        this.getProjectHierachy()
    }

    render() {
        const { afeData, wdCompany, isWDCompanyLoaded, wdSite, isWDSiteLoaded
            , wdCostCenter, isWDCostCenterLoaded, isFormReadOnly
            , wdProHierachy, isWDProHierachyHLoaded } = this.state

        if (!isWDSiteLoaded) {
            return (<div><h3>Loading WD Site....</h3></div>)
        } else if (!isWDCostCenterLoaded) {
            return (<div><h3>Loading WD Cost Center....</h3></div>)
        } else if (!isWDCompanyLoaded) {
            return (<div><h3>Loading WD Company....</h3></div>)
        } else if (!isWDProHierachyHLoaded) {
            return (<div><h3>Loading WD Project Hierachy....</h3></div>)
        } else
            return (<div>
                <Form>
                    <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col sm={6}>
                            <Label for="projectHierachy">Project Hierachy</Label>
                            <Select options={wdProHierachy}
                                isDisabled={isFormReadOnly}
                                name={"projectHierachy"}
                                //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                value={this.getSelProHierachyList(afeData[0].projectHierachy)}
                                defaultValue={this.getSelProHierachyList(afeData[0].projectHierachy)}
                                onChange={(e) => this.onTextChange("projectHierachy", e, e)}
                                isClearable={true}

                            >
                            </Select>


                            {/* <Input type="text" name="projectHierachy"
                                //  className={(!validateDataItem.wellName ? "form-error" : "")}
                                id="projectHierachy" placeholder=""
                                disabled={isFormReadOnly}
                                onChange={(e) => this.onTextChange("projectHierachy", e.target.value, e)}
                                value={afeData[0].projectHierachy}
                            /> */}
                        </Col>

                        <Col sm={6}>
                            <Row form>
                                <Col sm={12}>
                                    <Label for="companyID">Company Code</Label>
                                </Col>
                                <Col sm={12}>
                                    <Select options={wdCompany}
                                        isDisabled={isFormReadOnly}
                                        name={"companyID"}
                                        //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                        value={this.getSelWDCompany(afeData[0].companyID)}
                                        defaultValue={this.getSelWDCompany(afeData[0].companyID)}
                                        onChange={(e) => this.onTextChange("companyID", e, e)}
                                        isClearable={true}

                                    >
                                    </Select>
                                </Col>
                            </Row>
                        </Col>

                    </Row>



                    <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Col md={6}>
                            <Row form>
                                <Col md={12}>
                                    <Label for="siteID">Site ID</Label>
                                </Col>

                                <Col md={12}>
                                    <Select options={wdSite}
                                        //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                        isDisabled={isFormReadOnly}
                                        //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                        value={this.getSelWDSite(afeData[0].siteID)}
                                        defaultValue={this.getSelWDSite(afeData[0].siteID)}
                                        onChange={(e) => this.onTextChange("siteID", e, e)}
                                        isClearable={true}
                                    >
                                    </Select>
                                </Col>
                            </Row>


                        </Col>

                        <Col md={6}>
                            <Row form>
                                <Col md={12}>
                                    <Label for="costCenterID">Cost Center Code

                                    </Label>
                                </Col>

                                <Col md={12}>

                                    <Select options={wdCostCenter}
                                        isDisabled={isFormReadOnly}
                                        //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                        value={this.getSelWDCostCenter(afeData[0].costCenterID)}
                                        defaultValue={this.getSelWDCostCenter(afeData[0].costCenterID)}
                                        onChange={(e) => this.onTextChange("costCenterID", e, e)}
                                        isClearable={true}

                                    >
                                    </Select>
                                </Col>
                            </Row>
                        </Col>


                    </Row>


                </Form>
            </div>)
    }
}





