import React, { Component, useEffect, useReducer, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

import MenuIcon from '../../resources/menu.png';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Excel from '../../resources/excel.svg'
import Revise from '../../resources/revise.png'
import floppy from '../../resources/floppy.png'
import '../../custom.css';
import { ExcelUtility } from '../../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import ImageViewer from '../../resources/ImageViewer';
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import PowerBIReportViewer from '../../resources/PowerBIReportViewer'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import ConfirmModal from '../../operation/ConfirmModal'
import ConfirmWithMsgModal from '../../resources/ConfirmWithMsgModal'

import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import { useSyncExternalStore } from 'react';
import { ColorSortingCriteriaControl_ColorSortingCriteriaElementPart } from 'igniteui-react-spreadsheet';
import { isInaccessible } from '@testing-library/react';

var DatePicker = require("reactstrap-date-picker");

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

const InvoiceLineCRUD = (props) => {
    const { uomList, itemList, user,isFormDisabled } = props


    const [selInvLine, setSelInvLine] = useReducer((state, updates) => ({ ...state, ...updates }), props.selInvLine)

    const closeForm = (bRefresh) => {
        props.toggle()
    }

    const updateLineInvDTO = () => {

        const invUpdateDTO = {
            "InvoiceLineKey": selInvLine.invoiceLineKey,
            "Qty": selInvLine.qty,
            "UnitCost": selInvLine.unitCost,
            "Rate": selInvLine.rate,
            "UoM": selInvLine.uoM,
            "ItemID": selInvLine.itemID,
            "ModifiedBy": getUserADAccount(user.account.userName)
        }
        return invUpdateDTO
    }

    const updateInvLine = () => {
        const updateInvLineDTO = updateLineInvDTO()
        console.log("updateInvLineDTO")
        console.log(updateInvLineDTO)
        let myURI = apiURL + 'AR/InvoiceLineUpdate'

        fetch(myURI, apiPOSTHeader(updateInvLineDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        // this.setState({
                        //     msgBody: t,
                        //     msgHeader: "Error",
                        //     modalMsg: !this.state.modalMsg
                        // })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                closeForm(true)
            }).catch(error => {
                console.log(error)
            })
    }

    const saveData = () => {
        if (selInvLine.invoiceLineKey > 0) {
            updateInvLine()
        } else {
            console.log("new line")
        }
    }


    const onTextChange = (fieldName, fieldValue, e) => {

        switch (fieldName) {
            case "uom":
                setSelInvLine({ uoM: e.value })
                break;
            case "itemID":
                setSelInvLine({
                    itemID: e.value,
                    uoM: e.uoMID
                })

                break;
            case "qty":

                setSelInvLine({
                    qty: fieldValue,
                    extAmnt: fieldValue * selInvLine.unitCost
                })

                break
            case "unitCost":
                selInvLine.unitCost = fieldValue
                selInvLine.extAmnt = fieldValue * selInvLine.qty

                setSelInvLine({
                    unitCost: fieldValue,
                    extAmnt: fieldValue * selInvLine.qty
                })

                console.log(selInvLine)
                break;
            case "custName":
                // selInvoice.custID = ""
                // if (e !== null) {
                //     selInvLine.custID = e.customer_ID
                // }

                break
            default:
                break;
        }
    }

    const getSelItem = (v) => {
        if (v !== null && v !== "") {
            return itemList.filter(e => e.itemID === v)
        }
        return ""
    }


    const getSelUOM = (v) => {
        if (v !== null && v !== "") {
            return uomList.filter(e => e.uomid === v)
        }
        return ""
    }


    return (<div>
        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Col md={12}>
                <Row form >
                    <Col md={2}>
                        <Label for="woNo">Line No.</Label>
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text" name="invoiceNo"
                            id="invoiceNo" placeholder="Line No"
                            value={selInvLine.invLineNo}
                            disabled
                        //onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                        />
                    </Col>


                    <Col md={2}>
                        <Label for="basin">Ticket No</Label>
                    </Col>
                    <Col md={4}>

                        <Input disabled type="text" name="basin"
                            id="rcvrType" placeholder="Basin"
                            value={selInvLine.ticketNo}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Col md={12}>
                <Row form >
                    <Col md={2}>
                        <Label for="ticketDate">Ticket Date.</Label>
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text" name="ticketDate"
                            id="ticketDate" placeholder="Ticket DAte"
                            value={selInvLine.ticketDateFormated}
                            disabled
                        //onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                        />
                    </Col>


                    <Col md={2}>
                        <Label for="truckNo">Truck No</Label>
                    </Col>
                    <Col md={4}>

                        <Input disabled type="text" name="basin"
                            id="truckNo" placeholder="Truck No"
                            value={selInvLine.truckNo}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Col md={12}>
                <Row form >
                    <Col md={2}>
                        <Label for="transporter">Transporter</Label>
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text" name="transporter"
                            id="ticketDate" placeholder="Transporter"
                            value={selInvLine.transporter}
                            disabled
                        //onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                        />
                    </Col>


                    <Col md={2}>
                        <Label for="truckNo">Manifest No</Label>
                    </Col>
                    <Col md={4}>

                        <Input disabled type="text" name="manifestNo"
                            id="manifiestNo" placeholder="Manifest No"
                            value={selInvLine.manifestNo}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Col md={12}>
                <Row form >
                    <Col md={2}>
                        <Label for="leaseNo">Lease No</Label>
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text" name="leaseNo"
                            id="leaseNo" placeholder="Lease No"
                            value={selInvLine.leaseNo}
                            disabled
                        //onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                        />
                    </Col>


                    <Col md={2}>
                        {/* <Label for="truckNo">Manifest No</Label> */}
                    </Col>
                    <Col md={4}>

                        {/* <Input disabled type="text" name="manifestNo"
                            id="manifiestNo" placeholder="Manifest No"
                            value={selInvLine.manifestNo}
                        /> */}
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Col md={12}>
                <Row form >
                    <Col md={2}>
                        <Label for="leaseNo">Item Description</Label>
                    </Col>
                    <Col md={4}>
                        {/* <Input
                            type="text" name="leaseNo"
                            id="leaseNo" placeholder="Lease No"
                            value={selInvLine.lineDescription}
                            disabled
                        //onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                        /> */}
                        <Select
                            //    className={(!validDataItem.employeeKey ? "form-error" : "")}
                            options={itemList}
                            placeholder=""
                            isDisabled={isFormDisabled}
                            value={getSelItem(selInvLine.itemID)}
                            defaultValue={getSelItem(selInvLine.itemID)}
                            onChange={(e) => onTextChange("itemID", e, e)}
                            isClearable={false}
                            isMulti={false}
                        />
                    </Col>


                    <Col md={2}>
                        <Label for="truckNo">U of M</Label>
                    </Col>
                    <Col md={4}>

                        {/* <Input disabled type="text" name="manifestNo"
                            id="manifiestNo" placeholder="Manifest No"
                            value={selInvLine.uoM}
                        /> */}

                        <Select
                            //    className={(!validDataItem.employeeKey ? "form-error" : "")}
                            options={uomList}
                            placeholder=""
                            isDisabled={isFormDisabled}
                            value={getSelUOM(selInvLine.uoM)}
                            defaultValue={getSelUOM(selInvLine.uoM)}
                            onChange={(e) => onTextChange("uom", e, e)}
                            isClearable={false}
                            isMulti={false}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Col md={12}>
                <Row form >
                    <Col md={2}>
                        <Label for="leaseNo">Quantity</Label>
                    </Col>
                    <Col md={4}>
                        <NumberFormat value={selInvLine.qty}
                            onValueChange={(e) => onTextChange("qty", e.value, e)}
                            className={("form-control text-md-right")}
                            fixedDecimalScale={true}
                            //decimalScale={2}
                            //displayType={'text'} 
                            disabled={isFormDisabled}
                            thousandSeparator={true}
                        />

                    </Col>


                    <Col md={2}>
                        <Label for="unitPrice">Unit Price $</Label>
                    </Col>
                    <Col md={4}>
                        {/* 
                        <Input disabled type="text" name="manifestNo"
                            id="unitCost" placeholder="Unit Price"
                            value={selInvLine.unitCost}
                        /> */}
                        <NumberFormat value={selInvLine.unitCost}
                            onValueChange={(e) => onTextChange("unitCost", e.value, e)}
                            className={("form-control text-md-right")}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            //displayType={'text'} 
                            disabled={isFormDisabled}
                            thousandSeparator={true}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Col md={12}>
                <Row form >
                    <Col md={2}>
                        {/* <Label for="leaseNo">Quantity</Label> */}
                    </Col>
                    <Col md={4}>
                        {/* <Input
                            type="text" name="quantity"
                            id="quantity" placeholder="Quanity"
                            value={selInvLine.qty}
                            disabled
                        //onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                        /> */}
                    </Col>


                    <Col md={2}>
                        <Label for="extAmnt">Line Total $</Label>
                    </Col>
                    <Col md={4}>

                        <NumberFormat value={selInvLine.extAmnt}
                            //onValueChange={(e) => this.onTextChange("lat", e.value, e)}
                            className={("form-control text-md-right")}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            //displayType={'text'} 
                            disabled={true}
                            thousandSeparator={true}
                        />

                    </Col>
                </Row>
            </Col>
        </Row>

        <ColoredLine color="grey" />

        <FormGroup>
            <Row style={{ marginTop: "15px" }}>

                <Col md={3}>

                    <ConfirmModal outline={true}
                        color="danger"
                        className="float-left"
                        buttonLabel="Delete"
                        hidden={isFormDisabled}
                        formName="Confirm"
                        //id={supplierRequestKey}
                        message="Are you sure you want to delete invoice line?"
                        //onClickYes={(e) => deleteSuReq()}
                        image={<img
                            src={TrashBin}
                            //id={invoiceLineKey}
                            alt="Edit"
                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                    </ConfirmModal>



                </Col>


                <Col md={4}>


                </Col>

                <Col sm={5}>
                    <Button color="primary" className="float-right"
                        onClick={() => closeForm(false)}
                    >Close</Button>
                    <Button color="primary" className="float-right"
                        style={{ margin: "0px 10px 0px 0px" }}
                        disabled={isFormDisabled}
                        onClick={() => saveData()}
                    >Save</Button>

                    {/* <ConfirmModal outline={true}
                            color="success"
                            className="float-left"
                            // buttonLabel={(reqData.statusKey === 0 ? "Submit" : "Completed")}
                            // hidden={(reqData.statusKey !== 0 && reqData.statusKey !== 4)}
                            // formName="Confirm"
                            // id={supplierRequestKey}
                            // message={"Are you sure you want to " + (reqData.statusKey === 0 ? "Submit" : "Completed") + " supplier request?"}
                            // onClickYes={(e) => saveData((reqData.statusKey === 0 ? 4 : 6))}

                        // image={<img
                        //     src={TrashBin}
                        //     id={supplierRequestKey}
                        //     alt="Edit"
                        //     style={{ width: "20px", margin: "0 5px 0 0" }} />}
                        >

                        </ConfirmModal> */}
                </Col>
            </Row>
        </FormGroup>

    </div>)

}


export default class InvoiceDetails extends Component {
    static displayName = InvoiceDetails.name

    constructor(props) {
        super(props);
        document.title = "Invoice Details"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        // this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        // this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            invLine: [],
            isLoaded: false,
            invoiceKey: props.invoiceKey,
            selInvoice: props.selInvoice,
            isDisabled: this.isFormDisabled(props.selInvoice),
            invStatusKey: -1,
            modal: false,
            modalReport:false,
            user: props.user,
            custList: props.custList,
            itemList: props.itemList,
            paymentTermList: props.paymentTermList,
            uomList: props.uomList,
            windowHeight: window.innerHeight,
            statusKey: -1,
            activeTab: '1',
        }

    }

    isFormDisabled = (e) => {
        // console.log("Selected Invoice")
        // console.log(e)
        return (e.invStatusKey=== 8 || 
                e.invStatusKey===6 || 
                e.invStatusKey === 5)
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }


    onTextChange = (fieldName, fieldValue, e) => {
        const { selInvoice } = this.state
        switch (fieldName) {
            case "invoiceType":
                break;
            case "custName":
                selInvoice.custID = ""
                if (e !== null) {
                    selInvoice.custID = e.customer_ID
                }
                this.setState({ selInvoice: selInvoice })
                break
            default:
                break;
        }
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)

            this.setState({
                selInvLine: dataItem,
                invoiceLineKey: dataItem.invoiceLineKey
            })
            // this.setState({selSafeObs: dataItem})
            // this.toggle()
            //this.onVolRefresh()    
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.invBatch;
    }


    getInvoviceLine = () => {
        const { invoiceBatchKey } = this.state
        // let myURI = apiURL + 'SafetyObservation/startdate/' +
        //     this.state.startDate.toString().substring(0, 10) +
        //     '/enddate/' + this.state.endDate.toString().substring(0, 10)
        //     + '/AzureAD/' + this.props.user.account.userName;
        // console.log(myURI);

        let myURI = apiURL + 'ar/InvoiceLine/ByInvoice/' + this.props.invoiceKey;
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    invLine: json
                })
            });

    }

    getSelCustomer = (v) => {
        const { custList } = this.state
        if (v !== null && v !== "") {
            return custList.filter(e => e.customer_ID === v);
        }

        return ""

    }

    getSelPMT = (v) => {
        const { paymentTermList } = this.state
        if (v !== null && v !== "") {
            return paymentTermList.filter(e => e.paymentTermID === v)
        }

        return null
    }


    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }


    invoiceLineEdit = () => {
        this.toggle()
    }

    saveData = () => {
        this.updateInvoice()
    }

    updateInvDTO = () => {
        const { user } = this.props
        const { selInvoice } = this.state

        const invUpdateDTO = {
            "InvoiceKey": selInvoice.invoiceKey,
            "InvoiceDate": selInvoice.invoiceDate,
            "CustID": selInvoice.custID,
            "LeaseNo": selInvoice.leaseNo,
            "WellNo": selInvoice.wellNo,
            "InvStatusKey": selInvoice.invStatusKey,
            "PaymentTermID": selInvoice.paymentTermID,
            "Comments": selInvoice.comments,
            "ModifiedBy": selInvoice.modifiedBy,
            "PrintDateTime": selInvoice.printDateTime,
            "EmailDateTime": selInvoice.emailDateTime,
            "BillToAddressKey": selInvoice.billToAddressKey,
            "ShipToAddressKey": selInvoice.shipToAddressKey,

        }
        return invUpdateDTO
    }

    updateInvoice = () => {
        const updateInvDTO = this.updateInvDTO()
        console.log("updateInvDTO")
        console.log(updateInvDTO)
        let myURI = apiURL + 'AR/InvoiceUpdate'

        fetch(myURI, apiPOSTHeader(updateInvDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        // this.setState({
                        //     msgBody: t,
                        //     msgHeader: "Error",
                        //     modalMsg: !this.state.modalMsg
                        // })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.getInvoviceLine()
            }).catch(error => {
                console.log(error)
            })
    }

    closeForm = (bSaveData) => {
        this.props.toggle()
    }

    deleteData = () => {

    }

    printData = () => {
        this.toggleReport()
    }

    componentDidMount() {
        this.getInvoviceLine()
    }

    createReportParamter = () => {
        const {invoiceKey} = this.state
        const reportPara = [{
            "name": "InvoiceKey",
            "value": invoiceKey  
        }]
        
        console.log(reportPara)
        return reportPara
    }


    render() {
        const { invLine, isLoaded, windowHeight, invoiceLineKey, 
                selInvLine, itemList,isDisabled,
            activeTab, selInvoice, custList, paymentTermList, uomList,invoiceKey } = this.state
        const gridHeight = (windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()

        return (<div>
            <Form onSubmit={e => e.preventDefault}>
                <FormGroup>
                    <Row form style={{ marginTop: "-12px", marginBottom: "5px" }}>
                        <Col sm={4}>
                            <Button color="primary" outline 
                                    className="float-left"
                                     style={{  margin: "0 5px 0 0" }}
                                onClick={() => this.closeForm(false)}
                            >Close</Button>

                            <Button outline color="secondary"
                                //className="btn-no-border float-left"
                                onClick={(e) => this.saveData()}
                                hidden={isDisabled}
                            >
                                <img
                                    src={floppy}
                                    alt="save"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />
                                Save
                            </Button>{' '}

                            <Button outline color="secondary"
                                //className="btn-no-border float-left"
                                onClick={this.printData}
                            //disabled={supplierRequestKey < 0}
                            >
                                <img
                                    src={Revise}
                                    alt="details"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />
                                Print
                            </Button>{' '}

                        </Col>
                        <Col>
                            <ConfirmModal outline
                                color="danger"
                                className="float-right btn-no-border"
                                buttonLabel="Delete"
                                hidden={true} //{!(statusKey === 0 && afeHeaderKey > 0)}
                                formName="Confirm"
                                //disabled={supplierRequestKey < 0 || statusKey > 0}
                                message="Are you sure you want to delete selected Supplier Request?"
                                onClickYes={(e) => this.deleteData()}
                                image={<img
                                    src={TrashBin}
                                    //id={afeHeaderKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                            </ConfirmModal>
                        </Col>
                    </Row>

                    <ColoredLine color="grey" />


                    <FormGroup>
                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={12}>
                                <Row form >
                                    <Col md={2}>
                                        <Label for="woNo">Invoice No.</Label>
                                    </Col>
                                    <Col md={2}>
                                        <Input
                                            type="text" name="invoiceNo"
                                            id="invoiceNo" placeholder="Invoice No"
                                            value={selInvoice.invoiceNo}
                                            disabled
                                        //onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                                        />
                                    </Col>




                                    {/* <Col md={2}>
                                    <Label for="woNo">Status</Label>
                                </Col>    */}
                                    <Col md={2}>
                                        <Input type="text" name="arInvStatusDesc"
                                            id="arInvStatusDesc"
                                            value={selInvoice.arInvStatusDesc}
                                            disabled
                                        // defaultValue={materialRequestData[0].mtrReqStatusDesc}
                                        />
                                    </Col>

                                    <Col md={2}>
                                        <Label for="basin">Basin</Label>
                                    </Col>
                                    <Col md={4}>

                                        <Input disabled type="text" name="basin"
                                            id="rcvrType" placeholder="Basin"
                                            value={selInvoice.companyID}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={12}>
                                <Row form>
                                    <Col md={2}>
                                        <Label for="invoiceDate">Invoice Date</Label>
                                    </Col>

                                    <Col md={4}>

                                        <DatePicker value={selInvoice.invoiceDate}
                                            //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                                            onChange={(v, f) => this.onTextChange("invoiceDate", v, f)}
                                            disabled={isDisabled}
                                        />

                                    </Col>


                                    <Col md={2}>
                                        <Label for="woNo">Create Date</Label>
                                    </Col>
                                    <Col md={2}>

                                        <DatePicker value={selInvoice.createdDatetime}
                                            //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                                            //onChange={(v, f) => this.onTextChange("actualStartDate", v, f)}
                                            disabled={true}
                                        />


                                    </Col>
                                    <Col md={2}>
                                        <Input type="text"
                                            disabled
                                            id="createdBy" placeholder="By"
                                            value={selInvoice.createdByName}
                                        // defaultValue={this.props.user.account.name}
                                        //value={materialRequestData[0].createdBy.replace(/\./g, " ")}
                                        // onChange={(v,f) => this.onTextChange("createdDatetime", v, f)}
                                        />
                                    </Col>

                                </Row>
                            </Col>


                        </Row>
                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={12}>
                                <Row form>
                                    <Col md={2}>
                                        <Label for="employeeKey">Customer </Label>
                                    </Col>
                                    <Col md={4}>
                                        <Select
                                            //    className={(!validDataItem.employeeKey ? "form-error" : "")}
                                            options={custList}
                                            placeholder=""
                                            isDisabled={isDisabled}
                                            value={this.getSelCustomer(selInvoice.custID)}
                                            defaultValue={this.getSelCustomer(selInvoice.custID)}
                                            onChange={(e) => this.onTextChange("custName", e, e)}
                                            isClearable={true}
                                            isMulti={false}
                                        />
                                    </Col>





                                    <Col md={2}>
                                        <Label for="woNo">Ticket Customer</Label>
                                    </Col>
                                    <Col md={4}>
                                        <Input type="text"
                                            disabled
                                            id="invoiceTypeDesc" placeholder=""
                                            value={selInvoice.ticketCustName}
                                        // defaultValue={this.props.user.account.name}
                                        //value={materialRequestData[0].createdBy.replace(/\./g, " ")}
                                        // onChange={(v,f) => this.onTextChange("createdDatetime", v, f)}
                                        />
                                    </Col>



                                </Row>
                            </Col>


                        </Row>

                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={12}>
                                <Row form>
                                    <Col md={2}>
                                        <Label for="employeeKey">Payment Term </Label>
                                    </Col>
                                    <Col md={4}>
                                        <Select
                                            //    className={(!validDataItem.employeeKey ? "form-error" : "")}
                                            options={paymentTermList}
                                            placeholder=""
                                            isDisabled ={isDisabled}
                                            value={this.getSelPMT(selInvoice.paymentTermID)}
                                            defaultValue={this.getSelPMT(selInvoice.paymentTermID)}
                                            onChange={(e) => this.onTextChange("paymentTerm", e, e)}
                                            isClearable={true}
                                        />
                                    </Col>

                                    <Col md={2}>
                                        <Label for="dueDate">Due Date</Label>
                                    </Col>
                                    <Col md={4}>

                                        <DatePicker value={selInvoice.dueDate}
                                            //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                                            //onChange={(v, f) => this.onTextChange("actualStartDate", v, f)}
                                            disabled={true}
                                        />

                                    </Col>

                                </Row>
                            </Col>
                        </Row>


                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={4}>
                                        <Label for="billTo">Bill To </Label>
                                    </Col>
                                    <Col md={8}>
                                        <Input type="textarea"
                                            disabled
                                            rows="3"
                                            id="createdBy" placeholder=""
                                            value={selInvoice.billToAddress}
                                        // defaultValue={this.props.user.account.name}
                                        //value={materialRequestData[0].createdBy.replace(/\./g, " ")}
                                        // onChange={(v,f) => this.onTextChange("createdDatetime", v, f)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>


                                <Row form>

                                    <Col md={4}>
                                        <Label for="woNo">Bill Type</Label>
                                    </Col>
                                    <Col md={8}>
                                        <Input type="text"
                                            disabled
                                            id="invoiceTypeDesc" placeholder=""
                                            value={selInvoice.invoiceTypeDesc}
                                        // defaultValue={this.props.user.account.name}
                                        //value={materialRequestData[0].createdBy.replace(/\./g, " ")}
                                        // onChange={(v,f) => this.onTextChange("createdDatetime", v, f)}
                                        />
                                    </Col>




                                </Row>

                                <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>

                                    <Col md={4}>
                                        <Label for="woNo">Invoice Total $</Label>
                                    </Col>
                                    <Col md={8}>

                                        <NumberFormat value={selInvoice.totalInvAmnt}
                                            //onValueChange={(e) => this.onTextChange("lat", e.value, e)}
                                            className={("form-control text-md-right")}
                                            fixedDecimalScale={true}
                                            //decimalScale={2}
                                            //displayType={'text'} 
                                            disabled={true}
                                            thousandSeparator={true}
                                        />

                                    </Col>

                                </Row>
                            </Col>


                        </Row>

                    </FormGroup>
                    <Row form>

                        <Col sm={12}>
                            <Button color="primary" outline className="float-left"
                                // hidden ={mtlRequestKey < 0}
                                hidden
                                style={{ height: "35px" }}
                                //hidden={this.setIsReadOnly(statusKey)}
                                onClick={() => this.getMaterialRequestLine()}
                            >Refresh</Button>
                            {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                            <Button color="success" outline className="float-left"
                                // hidden={(!isSecurityAdminLogin || ( reviseBool || acceptBool || completeBool || pendingBool )) } 
                                // hidden ={mtlRequestKey < 0 }
                                style={{  marginRight: "10px" }}
                                onClick={() => this.getInvoviceLine()}
                                hidden
                            ><img
                                    src={Plus}
                                    alt="new"

                                    style={{ width: "20px", margin: "0 5px 0 0" }} />New Line</Button>

                            <Button color="primary" outline className="float-left"
                                //style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}
                                //disabled ={isDisabled}
                                //    hidden={(!isSecurityAdminLogin || ( reviseBool || acceptBool || completeBool || !pendingBool )) } 
                                // onClick={() => this.toggleLineCRUDEdit()}
                                onClick={() => this.invoiceLineEdit()}

                            ><img
                                    src={MenuIcon}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} /> {(isDisabled ? "View" : "Edit")}</Button>

                            <ConfirmModal outline={true}
                                // hidden = {itemMasterKey < 0}

                                color="danger"
                                className="float-right"
                                buttonLabel="Delete Line"
                                hidden = {true} //{mtlRequestKey < 0 }
                                // style={{height: "35px", marginBottom: "5px", marginRight: "10px" }}
                                //hidden={(!((statusKey == 0 && safetyObservationKey > 0) || ((isQHSEAdmin || isQHSEApprover) && statusKey != 6 && statusKey != 4 && safetyObservationKey > 0)))}
                                formName="Confirm"
                                message="Are you sure you want to delete selected receiver?"
                                onClickYes={(e) => this.invoiceLineDelete()}
                                image={<img
                                    src={TrashBin}
                                    // id={receiverKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                            </ConfirmModal>

                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={"30vh"}
                        dataSource={invLine}
                        // dataSource={supplierReq.filter(rec => ((rec.entity || '').toLowerCase().includes(searchText)) ||
                        //     (rec.supplierReqID || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplierName || '').toLowerCase().includes(searchText) ||
                        //     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplierCategory || '').toLowerCase().includes(searchText) ||
                        //     (rec.contactName || '').toLowerCase().includes(searchText) ||
                        //     (rec.phoneNo || '').toLowerCase().includes(searchText) ||
                        //     (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                        //     (rec.createdByName || '').toLowerCase().includes(searchText) ||
                        //     (rec.comments || '').toLowerCase().includes(searchText) ||
                        //     (rec.scopOfWork || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                    //cellBackground={"#ffbfbf"}
                    >


                        <IgrTextColumn field="invLineNo" headerText="No" width="*>100"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTextColumn field="ticketNo" headerText="Ticket No" width="*>220"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTextColumn field="ticketDateFormated" headerText="Ticket Date"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="truckNo" headerText="Truck No"
                            width="*>120" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="manifestNo" headerText="Manifiest No"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="transporter" headerText="Transporter"
                            width="*>200"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="leaseNo" headerText="Lease No"
                            width="*>200"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="lineDescription" headerText="Line Item"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrNumericColumn field="qty" headerText="Qty"
                            width="*>120" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="uoM" headerText="UoM"
                            width="*>100" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrNumericColumn field="unitCost" headerText="Unit Cost"
                            width="*>120"
                            positivePrefix="$"
                            maxFractionDigits={0}
                            showGroupingSeparator="true"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrNumericColumn field="extAmnt" headerText="Line Amount"
                            width="*>150"
                            positivePrefix="$"
                            maxFractionDigits={0}
                            showGroupingSeparator="true"
                            cellUpdating={this.onStatusCellUpdating} />
                        {/* <IgrTextColumn field="comments" headerText="Comments"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating} /> */}

                        <IgrNumericColumn field="invoiceLineKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>
            </Form >

            <div>
                <Modal isOpen={this.state.modal}
                    toggle={() => this.toggle()}
                    //onClosed={() => this.getSupplierReq()}
                    size={"lg"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    centered>
                    <ModalHeader toggle={() => this.toggle()}> Invoice Line Details </ModalHeader>
                    <ModalBody>
                        <InvoiceLineCRUD
                            invoiceLineKey={invoiceLineKey}
                            selInvLine={selInvLine}
                            uomList={uomList}
                            itemList={itemList}
                            user={this.props.user}
                            toggle={this.toggle}
                            isFormDisabled ={isDisabled}
                        // deleteSupReq={(e) => this.deleteSupplierReq(e)}
                        // supplierCat={supplierCat}
                        // entity={entity}
                        // refreshData={this.getSupplierReq}
                        >

                        </InvoiceLineCRUD>
                    </ModalBody>
                </Modal>
            </div>


            <div>
                <Modal isOpen={this.state.modalReport}
                    toggle={() => this.toggleReport()}
                    //style={{ margin: 0, flex: 1 }}
                    //onClosed={() => this.getSupplierReq()}
                    size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    //contentClassName="full-screen-modal"
                    centered
                    >
                    <ModalHeader toggle={() => this.toggleReport()}> Invoice Report </ModalHeader>
                    <ModalBody>
                        <PowerBIReportViewer
                            reportName = {"R9Invoice"}
                            reportParameters = {this.createReportParamter()}
                            invoiceKey={invoiceKey}                            
                            toggle={this.toggleReport}
                            // refreshData={this.getInvovice}
                        >

                        </PowerBIReportViewer>
                    </ModalBody>
                </Modal>
            </div>


        </div >)
    }
}







