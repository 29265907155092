import React, { useState } from 'react';
import { Row, Button, FormGroup, Label, Input } from 'reactstrap';
import { apiURL, apiPOSTHeader } from '../resources/apiURL';

const DocumentUploader = (props) => {
    let fileReader;
    const [selectedFile, setSelectedFile] = useState(props.selectedFile);
    const [isSelected, setIsSelected] = useState((props.selectedFile !== null && props.selectedFile !== undefined));
    const [linkPrimaryKey, setLinkPrimaryKey] = useState(props.linkPrimaryKey);
    const [linkTableName, setLinkTableName] = useState(props.linkTableName);
    const [docFileDesc, setDocFileDesc] = useState(((props.selectedFile !== null && props.selectedFile !== undefined) ? props.selectedFile.name : ""))
    const [isPreSelected] = useState((props.selectedFile !== null && props.selectedFile !== undefined))
   
    // console.log("selectedFile")
    // console.log(isSelected)


    const handleFileRead = (e) => {
        const content = fileReader.result;
        //console.log(content)

        const hdrDTO = documentForCreateDto(content)

        console.log(hdrDTO)

        let myURI = apiURL + 'Documents/Base64'
        fetch(myURI, apiPOSTHeader(hdrDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }
                props.refreshDocList();
                closeForm();

                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })


    }

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        // fileReader = new FileReader();
        // fileReader.onloadend = handleFileRead;
        // fileReader.readAsDataURL(event.target.files[0])

        setIsSelected(true);
    };

    const handleSubmission = () => {
        // console.log("selectedFile")
        // console.log(selectedFile)
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsDataURL(selectedFile)

    };

    const closeForm = () => {
        props.toggle();
    }


    const documentForCreateDto = (data) => {

        console.log("documentForCreateDto")

        const docCreateDTO = {
            "DocFileName": selectedFile.name,
            "DocFileDesc": docFileDesc,
            "DocFileDataBase64": data,
            "LinkTableName": linkTableName,
            "LinkPrimaryKey": linkPrimaryKey
        }
        return docCreateDTO;
    }


    const onTextChange = (text) => {
        setDocFileDesc(text)
    }

    return (        
        <div>
            <FormGroup>
                <Row hidden={isPreSelected}>
                    <input type="file" name="file" onChange={changeHandler} />
                </Row>
                <Row hidden={!isPreSelected}>
                    <label name="fileName">  {"Uploaded Filename: " + (isSelected ? selectedFile.name : "")} </label>
                </Row>
                <Row>
                    <Label for="youObserve">Description</Label>
                    <Input type="textarea" name="comment" rows={2}
                        id="comment"
                        defaultValue={(isSelected ? selectedFile.name : "")}
                        value={(isSelected ? docFileDesc : "")}
                        onChange={(e) => onTextChange(e.target.value)} />
                </Row>
            </FormGroup>

            <div>
                {/* <button onClick={handleSubmission}>Submit</button> */}
                <Button color="primary" className="float-right"
                    onClick={() => closeForm()}>Cancel</Button>
                <Button color="primary" className="float-right"
                    style={{ margin: "0px 10px 0px 0px" }}
                    disabled={!isSelected}
                    onClick={() => handleSubmission()}>OK</Button>
            </div>
        </div>
    )
}

export default DocumentUploader;