import React, { Component } from 'react';
import { apiURL, apiHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';

import { Col, Row, Button, FormGroup, Label,ListGroupItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ItemDetail from './ItemDetail'
import TreeMenu, { defaultChildren, ItemComponent }  from 'react-simple-tree-menu';
// import default minimal styling or your own styling
//import '../../node_modules/react-simple-tree-menu/dist/main.css';
import '../../src/resources/TreeDataMain.css'

IgrDataGridModule.register();
// IgrDataGrid.register()
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");
const DEFAULT_PADDING = 0;
const ICON_SIZE = 8;
const LEVEL_SPACE = 16;
const ToggleIcon = ({ on }) => <span style={{ marginRight: 8 }}>{on ? '-' : '+'}</span>;

const ListItem = ({
    level = 0,
    hasNodes,
    isOpen,
    label,
    searchTerm,
    openNodes,
    toggleNode,
    matchSearch,
    focused,
    ...props
  }) => (
    <ListGroupItem
      {...props}
      style={{
        paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
        cursor: 'pointer',
        boxShadow: focused ? '0px 0px 0px 0px #222' : 'none',
        zIndex: focused ? 999 : 'unset',
        position: 'relative',
      }}
    >
      {hasNodes && (
        <div
          style={{ display: 'inline-block' }}
          onClick={e => {
            hasNodes && toggleNode && toggleNode();
            e.stopPropagation();
          }}
        >
          <ToggleIcon on={isOpen} />
        </div>
      )}
      {label}
    </ListGroupItem>
  );

export default class ItemInventory extends Component {
    static displayName = ItemInventory.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        //this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onLoad = this.onLoad.bind(this);

        this.state = {
            isLoaded: false,
            itemInv: [],
            itemInvTree: [],
            isItemInvTreeLoaded: false,
            windowHeight: window.innerHeight
        }
    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.itemInv;
        this.onLoad()
    }

    // onGridRowSelected(s, e) {
    //     // console.log(" On CLick")
    //     // //const content = e.content;
    //     // if (e.addedKeys.count > 0) {
    //     //     let clickedRow = e.addedKeys.item(0)
    //     //     let dataItem = clickedRow.value[0]
    //     //     console.log(dataItem)

    //     //     this.setState({
    //     //         itemMasterKey: dataItem.itemMasterKey,
    //     //         itemID: dataItem.itemID,
    //     //         itemStatus: dataItem.itemStatus
    //     //     })
    //     //     this.toggle()
    //     //     //this.onVolRefresh()    
    //     // }
    // }
    getItemInventory = () => {
        let myURI = apiURL + 'ItemMaster/Inventory'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    itemInv: json
                })
            });

        this.onLoad();
    }


    getItemInventoryTree = () => {
        let myURI = apiURL + 'ItemMaster//InventoryTree'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isItemInvTreeLoaded: true,
                    itemInvTree: json
                })
            });
    }


    onLoad() {
        console.log("here on load --------------------------")
        if (this.grid !== undefined) {
            const location = new IgrColumnGroupDescription();
            location.field = "locationName";
            location.displayName = "Basin";

            const areaName = new IgrColumnGroupDescription();
            areaName.field = "warehouseInvLocation";
            areaName.displayName = "";

            // const subArea = new IgrColumnGroupDescription();
            // subArea.field = "subAreaName";
            // subArea.displayName = "";

            this.grid.groupDescriptions.add(location);
            this.grid.groupDescriptions.add(areaName);
            // this.grid.groupDescriptions.add(subArea);

            //this.grid.isGroupExpandedDefault="false"

        }
    }


    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);

    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getItemInventoryTree();
        this.getItemInventory()
        this.onLoad()
        window.addEventListener('load', this.onLoad);

    }

    render() {
        const { isLoaded, itemInv, windowHeight,
            itemInvTree, isItemInvTreeLoaded } = this.state;
        const gridHeight = (windowHeight * 0.8) + "px"
        const searchText = window.$searchText.toLowerCase()


        if (!isItemInvTreeLoaded) {
            return (<div>
                <h3>Loading Current Inventory.....</h3>
            </div>)
        } else
            return (<div>
                <FormGroup>
                    <Row form>
                        <h2> Current Inventory |</h2>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.getItemInventory}>
                            <img
                                src={Refresh}
                                alt="Refresh"
                                style={{ width: "30px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>
                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button>{' '}

                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        //height={gridHeight}
                        height={"300px"}
                        //dataSource={safetyObs}
                        dataSource={itemInv.filter(rec => ((rec.locationName || '').toLowerCase().includes(searchText)) ||
                            (rec.itemNumber || '').toLowerCase().includes(searchText) ||
                            (rec.itemID || '').toLowerCase().includes(searchText) ||
                            (rec.itemDesc || '').toLowerCase().includes(searchText) ||
                            (rec.spendCategory || '').toLowerCase().includes(searchText) ||
                            (rec.inventoryCode || '').toLowerCase().includes(searchText) ||
                            (rec.lastTransBy || '').toLowerCase().includes(searchText) ||
                            (rec.warehouseInvLocation || '').toLowerCase().includes(searchText))}
                        isHeaderSeparatorVisible="false"
                        groupHeaderDisplayMode="split"
                        isGroupCollapsable="true"
                        isGroupExpandedDefault="true"
                        selectionMode="SingleRow"
                        editMode={0}
                        //isGroupByAreaVisible="true"
                        //filterUIType="FilterRow"
                        autoGenerateColumns="false"
                        //selectedKeysChanged={this.onGridRowSelected}
                        //cellBackground={"#ffbfbf"}
                        isColumnOptionsEnabled="true"
                    >

                        <IgrTextColumn field="locationName" headerText="Basin"
                            width="*>150" />

                        <IgrTextColumn field="warehouseInvLocation" headerText="Inventory Location"
                            width="*>150" />

                        {/* <IgrTextColumn field="itemID"
                            headerText="Item ID" width="*>120"
                            isHidden
                        />
                         */}
                        <IgrTextColumn field="itemNumber" headerText="Item Number"
                            width="*>180" />

                        <IgrTextColumn field="itemStatus" headerText="Status"
                            width="*>120"
                        />
                        <IgrTextColumn field="itemDesc" headerText="Item Description"
                            width="*>250" />

                        <IgrNumericColumn field="locQtyOH"
                            headerText="Loc Qty"
                            showGroupingSeparator="true"
                            width="*>150" />
                        <IgrNumericColumn field="stdPurchCost"
                            headerText="Purch Cost"
                            positivePrefix="$"
                            showGroupingSeparator="true"
                            width="*>130" />

                        <IgrTextColumn field="spendCategory" headerText="Spend Category"
                            width="*>250" />
                        <IgrTextColumn field="inventoryCode" headerText="Inventory Code"
                            width="*>180" />




                        <IgrTextColumn field="lastTransBy" headerText="Last Transaction By"
                            width="*>150" />

                        <IgrTextColumn field="lastQtyChgDateTime" headerText="Last Change Date"
                            width="*>140" dateTimeFormat={"DateShort"}
                        //cellUpdating={this.onReportDateUpdating} 
                        />


                        <IgrNumericColumn field="onHandByLocKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>
                <FormGroup>
                    <TreeMenu data={itemInvTree}
                        debounceTime={125}
                        disableKeyboard={false}
                        hasSearch={true}
                        //onClickItem={function noRefCheck(){}}
                        resetOpenNodesOnDataUpdate={false}>

                    </TreeMenu>

                    {/* <TreeMenu data={itemInvTree}>
                        {({ search, items }) => (
                            <ul>
                                {items.map(({ key, ...props }) => (
                                     <ListItem {...props}/> 
                                    // <ItemComponent key={key} label="helllllll" {...props} 
                                    
                                ))}
                            </ul>
                        )}
                    </TreeMenu> */}
                </FormGroup>

            </div>)
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "ItemInventory");
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
    }


}
