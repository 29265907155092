import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import ModalYNName from './ModalYNName';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");





export default class FormJSASignatures extends Component {
    static displayName = FormJSASignatures.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state = {
            formJSAKey: props.formJSAKey,
            Data: [],
            isLoaded: false,
            isFormReadOnly: false,
            modal: false,
            user: props.user,
            isAdmin: props.isAdmin,
            ddEmployees: props.ddEmployees,
            formJSASignaturesKey: -1,
            firstLastName: null,
            invalidfirstLastName: false,


            company: ' ',
            emailAddress: ' ',
            phoneNo: ' ',
            signature: ' ',
            employeeKey: null,
            cmtDate: new Date().toISOString(),
            hidden: props.hidden,

            modalMsg: false,
            msgHeader: "",
            msgBody: "",

            validateDataItem: {
                sequence: true,
                toValidate: false
                // empShift:true
            }





        }

    }


    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.employeeKey
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }

    getSelEmp = (v) => {
        const { ddEmployees } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return ddEmployees.filter(e => e.employeeKey === Number(v));
        }
        return ""

    }


    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.Data;
    }

    toggleOK = () => {
        if (this.saveData()) {
            this.setState({
                modal: !this.state.modal,
                firstLastName: null,
                invalidfirstLastName: false,
                employeeKey: null,
                company: " ",
                emailAddress: " ",
                phoneNo: " ",
                signature: " "

            })

        }
    }

    isNumeric = (str) => {
        // if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }



    validateData = () => {
        var { firstLastName, invalidfirstLastName, employeeKey } = this.state




        if (firstLastName === '' || firstLastName === null) {
            return !invalidfirstLastName;
            invalidfirstLastName = true
        }
        else {
            invalidfirstLastName = false
        }

        if (employeeKey) {
            invalidfirstLastName = false
        }



        this.setState({ invalidfirstLastName: invalidfirstLastName })
        return !invalidfirstLastName;

    }

    createDataForUpdateDTO = () => {
        const { formJSASignaturesKey, firstLastName, company, emailAddress, phoneNo, signature, employeeKey, user } = this.state
        const opsCmtDTO = {
            "formJSASignaturesKey": formJSASignaturesKey,
            "employeeKey": employeeKey,
            "firstLastName": firstLastName,
            "company": company,
            "emailAddress": emailAddress,
            "phoneNo": phoneNo,
            "signature": signature

            // "modifiedBy": getUserADAccount(user.account.userName),
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    updateData = () => {
        const uptDTO = this.createDataForUpdateDTO()
        // let myURI = apiURL + 'SafetyIncident/updateSafIncJSAJobTaske'

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(uptDTO)
        }

        let myURI = apiURL + 'SafetyIncident/updateSafIncJSASignature'
        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    // return Promise.reject(error);
                    return false;
                }

                // console.log("No error here: " + rspData)

                // this.getInpsData()
                return true

            })
            .catch(error => {
                console.log("PUT Error: " + error);
                return false;
            })

        // fetch(myURI, apiPUTHeader(uptDTO))
        //     .then(async response => {
        //         const rspData = response.text();
        //         if (!response.ok) {
        //             console.log("Error return but you dont see")
        //             const error = (rspData && rspData.message) || response.status;
        //             //return Promise.reject(error);
        //             return false
        //         }

        //         console.log("No error here: " + rspData)
        //     })
        //     .catch(error => {
        //         console.log("PUT Error: " + error);
        //         return false
        //     })

        // return true
    }
    createDataForCreateDTO = () => {
        const { formJSAKey, firstLastName, company, emailAddress, phoneNo, signature, employeeKey, user } = this.state

        const linetoAdd = {
            "formJSAKey": formJSAKey, //selectedValue.key,
            "employeeKey": employeeKey,
            "firstLastName": firstLastName,
            "company": company,
            "emailAddress": emailAddress,
            "phoneNo": phoneNo,
            "signature": signature,
            "CreatedBy": getUserADAccount(user.account.userName),

            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }

    saveData = () => {
        const { formJSASignaturesKey } = this.state
        if (this.validateData()) {

            if (formJSASignaturesKey > 0) {
                if (this.updateData()) {
                    this.setState({ formJSASignaturesKey: -1 })
                }
            } else {
                const addDTO = this.createDataForCreateDTO()
                let myURI = apiURL + 'SafetyIncident/addSafIncJSASignature'

                const putReq = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(addDTO)
                }

                fetch(myURI, putReq)
                    .then(async response => {
                        if (!response.ok) {
                            const rspData = response.text();
                            //console.log("Error return but you dont see")
                            const error = (rspData && rspData.message) || response.status;
                            rspData.then(t => {
                                this.setState({
                                    msgBody: t,
                                    msgHeader: "Error",
                                    modalMsg: !this.state.modalMsg

                                })
                            })
                            // return Promise.reject(error);
                            return false;
                        }

                        // console.log("No error here: " + rspData)
                        this.getData()

                        // this.getInpsData()
                        return true

                    })
                    .catch(error => {
                        console.log("PUT Error: " + error);
                        return false;
                    })

                // fetch(myURI, apiPOSTHeader(addDTO))
                //     .then(async response => {
                //         const rspData = response.text();
                //         if (!response.ok) {
                //             console.log("Error return but you dont see")
                //             const error = (rspData && rspData.message) || response.status;
                //             //return Promise.reject(error);
                //             return false
                //         }

                //         console.log("No error here: " + rspData)
                //     })
                //     .catch(error => {
                //         console.log("PUT Error: " + error);
                //         return false
                //     })
            }
            return true
        }

        return false
    }


    createNewTemplateDTO = (templateName) => {
        const { formJSAKey, user } = this.state

        const linetoAdd = {
            "FormJSAKey": formJSAKey, //selectedValue.key,
            "TemplateName": templateName,
            "CreatedBy": getUserADAccount(user.account.userName),

            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }


    insertTemplate = (templateName) => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetJSATemplate'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.insertTemplateTF(templateName, json.filter(rec => rec.templateName === templateName.trim()).length > 0 ? true : false)
                // return json.filter(rec => rec.templateName === templateName).length > 0 ? true : false
                // this.setState({
                //     // isddTemplatesLoaded: true,
                //     ddTemplates: json
                // })
            });
    }



    insertTemplateTF = (templateName, exists) => {
        var cont = true

        if (exists) {
            if (!window.confirm('Job/task with that name already exists. Do you want to overwrite?')) {
                cont = false
            }

        }

        if (cont) {
            this.insertTemplateSQL(templateName)

        }




    }

    insertTemplateSQL = (templateName) => {
        const itemDTO = this.createNewTemplateDTO(templateName);
        console.log(itemDTO);
        let myURI = apiURL + 'SafetyIncident/addSafIncJSATemplatefromForm'



        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                // this.getData()
                this.props.onClose();

                // this.setShowingAlert(true)


            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }




    onTextChange = (fieldName, fieldValue, e) => {
        switch (fieldName) {
            case "firstLastName":
                this.setState({ firstLastName: fieldValue });
                break;

            case "employeeKey":
                if (fieldValue != null) {

                    this.setState({ employeeKey: fieldValue.employeeKey });
                } else {
                    this.setState({ employeeKey: null });

                }

                break;

            case "company":
                this.setState({ company: fieldValue });
                break;

            case "emailAddress":
                this.setState({ emailAddress: fieldValue });
                break;
            case "phoneNo":
                this.setState({ phoneNo: fieldValue });
                break;

            case "signature":
                this.setState({ signature: fieldValue });
                break;


            default:
        }





    }

    toggle = () => {
        console.log("Modal xxx: " + this.state.modal)
        const { employeeKey } = this.state
var whattoadd = employeeKey?"employee":"contractor";
        this.setState({ modal: !this.state.modal, whattoadd: whattoadd })
    }

    toggleNew = () => {
        
        this.setState({ modal: !this.state.modal })
    }

    newData = (whattoadd) => {
        this.setState({
            firstLastName: null,
            invalidfirstLastName: false,
            employeeKey: null,
            company: ' ',
            emailAddress: ' ',
            phoneNo: ' ',
            signature: ' ',
            whattoadd: whattoadd,

            formJSASignaturesKey: -1
        },()=>{
            this.toggleNew()  
        })
  
    }

    onGridRowSelected(s, e) {
        //console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // this.setState({ selAFELine: dataItem })           
            this.setState({
                firstLastName: dataItem.firstLastName,
                invalidfirstLastName: false,
                employeeKey: dataItem.employeeKey,
                company: dataItem.company,
                emailAddress: dataItem.emailAddress,
                phoneNo: dataItem.phoneNo,
                signature: dataItem.signature,
                formJSASignaturesKey: dataItem.formJSASignaturesKey
            })
        } else {
            this.setState({

                formJSASignaturesKey: -1
            })
        }
    }

    editData = (key, date, text) => {

        this.toggle()
    }

    refreshData = () => {
        this.getData()
    }

    getData = () => {
        const { formJSAKey } = this.state
        let myURI = apiURL + 'SafetyIncident/getJSASignatures/' + formJSAKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    Data: json,
                    isLoaded: true
                }
                )
                this.validateDataForSubmit(false);

            });
    }

    validateDataForSubmit = (bSubmit) => {

        const { validateDataItem, Data } = this.state
        var bVal = true
        var valItem = validateDataItem
        // if (data[0].iunderstand === null || data[0].iunderstand == 0) {
        //     valItem.iunderstand = false
        //     bVal = false
        // }


        if (Data.length === 0) {
            valItem.sequence = false
            bVal = false

        }
        else {
            valItem.sequence = true
            bVal = true


        }
        if (bSubmit) valItem.toValidate = true

        this.setState({ validateDataItem: valItem })

        return bVal

    }

    validateDataForSubmitMain = () => {
        var bVal = this.validateDataForSubmit(true)

        return bVal

    }





    componentDidMount() {
        this.props.refreshSignature(this.getData);
        //this.props.validateJSAJobTaskForSubmit(this.validateDataForSubmitMain);


        this.getData();
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

        }

        // switch (s.field) {
        //     case 'datetime':

        //         var date = new Date(item.datetime);
        //         link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;

        //     default:

        // }

    }
    createDataForDeleteLine = () => {
        const { formJSASignaturesKey } = this.state


        const dataForDeleteDTO = {
            "formJSASignaturesKey": formJSASignaturesKey,
            // "DeletedByAD": getUserADAccount(user.account.userName)
            // ,
            // "StatusKey": data[0].statusKey
        }

        return dataForDeleteDTO;
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        content.style.fontStyle = "italic";
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            content.appendChild(link);
        }
        else {
            // link = content.children[0];

            // if (item.statusKey === 4) {
            //     content.style.background = "#f0f5b0";
            // } else if (item.statusKey === 5 || item.statusKey === 7) {
            //     content.style.background = "#F3B5A2";
            // } else if (item.statusKey === 6) {
            //     content.style.background = "#b8f2b1";
            // }
            // else {
            //     content.style.background = "transparent";
            // }
        }

        switch (s.field) {
            case 'signature':
                link.textContent = item.signature;
                break;


            default:

        }

    }



    deleteLine = () => {

        const createDataforDelete = this.createDataForDeleteLine()


        let myURI = apiURL + 'SafetyIncident/deleteSafIncJSASignature'
        fetch(myURI, apiDELETEHeader(createDataforDelete))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.getData()

                this.setState({ formJSASignaturesKey: -1 })
                // this.setShowingAlert(true)
                //this.props.refreshData()
            })

            .catch(error => {
                console.log("PUT Error: " + error);
            })

    }




    render() {
        const { user, isAdmin, formJSAKey, Data,
            isLoaded, isFormReadOnly,
            formJSASignaturesKey,
            firstLastName, invalidfirstLastName, company, emailAddress, phoneNo, signature,
            hidden, msgHeader, msgBody, validateDataItem, ddEmployees, employeeKey, whattoadd } = this.state

        if (!isLoaded) {
            return (<div>
                Loading Data ......
            </div>)
        } else
            return (<div>

                {/* <FormGroup row>
                    <Label>
                    whattoadd {whattoadd}
                    </Label>
                </FormGroup> */}



                <Row form style={{ marginLeft: "-17px", marginTop: "-4px" }}
                    className={(!validateDataItem.sequence && validateDataItem.toValidate ? "form-error" : "")}
                >
                    <Col md={8} >
                        {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                        <Button color="primary" outline className="float-left"
                            style={{ height: "35px" }}

                            hidden={hidden}

                            onClick={() => this.newData("employee")}
                        >Add Employee</Button>
                        <Button color="primary" outline className="float-left"
                            style={{ height: "35px", marginLeft: "10px" }}

                            hidden={hidden}
                            onClick={() => this.newData("contractor")}
                        >Add Contractor</Button>

                        <Button color="primary" outline className="float-left"
                            style={{ height: "35px", marginLeft: "10px" }}
                            // disabled={formJSASignaturesKey < 0 || signature.length > 1}
                            disabled={formJSASignaturesKey < 0}
                            hidden={hidden}
                            //hidden={this.setIsReadOnly(statusKey)}
                            onClick={() => this.toggle()}
                        >Edit</Button>

                        <ConfirmModal outline={true}
                            color="danger"
                            className="float-left"
                            style={{ height: "35px", marginLeft: "10px" }}
                            buttonLabel="Delete"
                            // hidden={!(data[0].statusKey == 0 && isAdmin)}
                            hidden={hidden}
                            disabled={formJSASignaturesKey < 0}
                            formName="Confirm"
                            // id={safetyObservationKey}
                            message="Are you sure you want to delete? (cannot be undone)"
                            // onClickYes={(e) => this.deleteLine()}
                            onClickYes={this.deleteLine}
                        />


                        <ModalYNName outline={false}
                            color="success"
                            className="float-right"
                            buttonLabel="Save as Job/Task"
                            // hidden={!((data[0].statusKey == 3) && (isApprover || isEditApprover))}
                            hidden={true}
                            disabled={Data.length === 0}

                            // invalid={!invalidDataItem.reviewCommentCont}
                            // value={invalidDataItem.reviewCommentText}
                            // defaultValue = {invalidDataItem.reviewCommentText}
                            // disabled= {dataSelected.length>0 ? "false" : "true"}
                            // disabled="false"
                            //inputKey={-1}
                            // dataSelected={dataSelected}
                            formName="Confirm"
                            //   id={meterValidationKey}
                            message="Please enter Job/Task:                 "
                            //   meterValidation={meterValidation}
                            // onClosed={(e) => this.removeErrorZero()}
                            // onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                            onClickYes={(e) => this.insertTemplate(e)}
                        // image={<img
                        //     src={RemoveIcon}
                        //     style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}

                        />
                        {/* <Label>   *Please add some Job Steps </Label> */}



                        {/* <div class= {!validateDataItem.sequence ? "text-danger": "d-none"}>     */}

                    </Col>
                    {/* <Col md={8}   >
                        <div
                            class={(!validateDataItem.sequence && validateDataItem.toValidate ? "text-danger" : "d-none")}>
                            *Please add some Job Steps
                        </div>

                    </Col> */}

                </Row>

                {/* <ColoredLine color="grey" /> */}
                {/* <FormGroup row>
                    <Col md={12}>
                        <div>
                            <Label>

                                dddd5 {formJSAKey}
                            </Label>


                        </div>
                    </Col>

                </FormGroup> */}

                <Row style={{ paddingTop: "5px", paddingRight: "20px" }}
                // className={(!validateDataItem.afeCostType ? "form-error" : "")}
                // className={"form-error"}
                >
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={(Data.length > 10 ? "300px" : "100%")}
                        width='100%'
                        dataSource={Data}
                        //isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}
                    >

                        <IgrTextColumn field="firstLastName"
                            headerText="Name"
                            width="*>110"
                        />

                        <IgrTextColumn
                            field="company"
                            headerText="Company"
                            width="*>170"
                        />

                        <IgrTextColumn
                            field="emailAddress"
                            headerText="Email"
                            width="*>170"
                        />

                        <IgrTextColumn
                            field="phoneNo"
                            headerText="Phone No"
                            width="*>110"
                        />
                        <IgrTextColumn
                            field="signature"
                            headerText="Signature"
                            width="*>150"
                        />
                        {/* <IgrTemplateColumn
                            field="signature"
                            headerText="Signature"
                            width="*>150"
                            cellUpdating={this.onStatusCellUpdating}
                        /> */}




                        <IgrNumericColumn field="formJSASignaturesKey" isHidden />
                    </IgrDataGrid>

                </Row>


                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                        onClosed={() => this.refreshData()}
                        //contentClassName="oilTicketDetail-modal-style"
                        size='lg'
                    >
                        <ModalHeader toggle={() => this.toggle()}
                        > Employee/Contractor </ModalHeader>
                        <ModalBody>

                        <FormGroup row hidden={whattoadd!=="employee"}>
                                 <Label for="emp" sm={2}>Employee</Label>
                                <Col sm={10}>
                                    <Select options={this.createEmpList(ddEmployees)}
                                        //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                        // styles={customStyles}

                                        // isDisabled={isReadOnly || !isAdmin}
                                        id="employeeKey"
                                        name="employeeKey"
                                        value={this.getSelEmp(employeeKey)}
                                        isDisabled={firstLastName ? true : false}
                                        onChange={(e) => this.onTextChange("employeeKey", e, e)}
                                        isClearable={true}

                                    >
                                    </Select>
                                    {/* <FormFeedback tooltip>Name can not be blank</FormFeedback> */}
                                </Col>
                            </FormGroup>


                            <FormGroup row hidden={whattoadd!=="contractor"}>
                                <Label for="firstLastName" sm={2}>Name</Label>
                                <Col sm={10}>
                                    <Input type="text"
                                        name="firstLastName"
                                        id="firstLastName"
                                        defaultValue={firstLastName}
                                        value={firstLastName}
                                        invalid={invalidfirstLastName}
                                        readOnly={employeeKey ? true : false}
                                        onChange={(e) => this.onTextChange("firstLastName", e.target.value, e)} />
                                    <FormFeedback tooltip>Name can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row hidden={whattoadd!=="contractor"}>
                                <Label for="company" sm={2}>Company</Label>
                                <Col sm={10}>
                                    <Input type="text"
                                        id="company"
                                        defaultValue={company}
                                        value={company}
                                        // invalid={company === ""}
                                        readOnly={employeeKey ? true : false}
                                        onChange={(e) => this.onTextChange("company", e.target.value, e)} />
                                    {/* <FormFeedback tooltip>Company can not be blank</FormFeedback> */}
                                </Col>
                            </FormGroup>

                            <FormGroup row hidden={whattoadd!=="contractor"}>
                                <Label for="emailAddress" sm={2}>Email</Label>
                                <Col sm={10}>
                                    <Input type="text"
                                        id="emailAddress"
                                        defaultValue={emailAddress}
                                        value={emailAddress}
                                        // invalid={emailAddress === ""}
                                        readOnly={employeeKey ? true : false}
                                        onChange={(e) => this.onTextChange("emailAddress", e.target.value, e)} />
                                    <FormFeedback tooltip>Email can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row hidden={whattoadd!=="contractor"}>
                                <Label for="phoneNo" sm={2}>Phone No</Label>
                                <Col sm={10}>
                                    <Input type="text"
                                        id="phoneNo"
                                        defaultValue={phoneNo}
                                        value={phoneNo}
                                        readOnly={employeeKey ? true : false}
                                        // invalid={emailAddress === ""}
                                        onChange={(e) => this.onTextChange("phoneNo", e.target.value, e)} />
                                    {/* <FormFeedback tooltip>Email can not be blank</FormFeedback> */}
                                </Col>
                            </FormGroup>

                            <FormGroup row>

                                <Label for="sigtext" sm={2}></Label>
                                <Col sm={10}>
                                    <Input type="textarea" id="sigtext" name="sigtext" rows={6}
                                        value="I understand & will adhere to the steps, hazards & controls as described in this JSA. I understand that performing steps out of sequence may pose hazards that have not been evaluated, nor authorized.
I will contact my supervisor prior to continuing work, if the scope of work changes or new hazards are introduced. I understand I have the authority and responsibility to stop work I believe to be unsafe."
                                    />
                                </Col>
                            </FormGroup>


                            <FormGroup row>

                                <Label for="signature" sm={2}>Signature</Label>
                                <Col sm={10}>

                                    <Input type="text"
                                        // name="text"
                                        id="signature"
                                        defaultValue={signature}
                                        value={signature}
                                        // invalid={signature === ""}
                                        onChange={(e) => this.onTextChange("signature", e.target.value, e)} />
                                    {/* <FormFeedback tooltip>Email can not be blank</FormFeedback> */}
                                </Col>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

            </div>)
    }
}