
import React,{Component} from 'react';

import { Col, CustomInput,Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {apiURL} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'

import '../custom.css';


import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { isNumber, ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import OilTicketDetail from './OilTicketDetail'
import ConfirmModal from './ConfirmModal'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class FacilityOilTicket extends Component {
    constructor(props){
        super(props);

        this.onGridRef=this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onBSWPercentUpdating = this.onBSWPercentUpdating.bind(this);
        this.onGridRowCellClick = this.onGridRowCellClick.bind(this);

        const urlPara = this.getURLParameters(props.parameters)

        this.state={
            oilTickets:[],
            isLoaded:false,
            startDate: (urlPara.startDate === null? getFirstDayofMonth().toISOString() : urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            endDate: (urlPara.endDate === null ? new Date().toISOString():urlPara.endDate),
            facilityOilTicketKey:0,
            windowHeight:window.innerHeight,
            modal:false,
            loadStatus: urlPara.loadStatus,
            facilityKeys: urlPara.facilityKeys,
            saveTicketData:false,
            showAllFacility: true
            // showAllFacility: true,  (urlPara.facilityKeys === null ? false : true)
        }

    }

    onLoadStatusChange = (value,e) =>{
        this.getOilTicketData(!this.state.showAllFacility)
        this.setState({showAllFacility:!this.state.showAllFacility})
       
    }

    getURLParameters = (inputPara) => {
        var paraMeters={
            startDate: null ,
            endDate:null ,
            facilityKeys: null ,
            loadStatus:null}

            
        if ( !this.props.location==='undefined' || inputPara!=='undefined'){   
            var query
            if (!this.props.location==='undefined')
            {
                 query = new URLSearchParams(this.props.location.search);
            
            }else{
                 query = new URLSearchParams(inputPara);            
            }
            paraMeters={
                startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
                endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
                facilityKeys:query.get('Facility') ,
                loadStatus:query.get('LoadStatus')
        }
        
        }
     
        return paraMeters
    }


    toggleOK = () => {        
        console.log("Modal OK: " + this.state.modal)
        this.setState({modal: !this.state.modal,
            saveTicketData:true})
                
    }

    toggle = () => {  
        console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
    }


    onGridRowCellClick(s,e) {
        // console.log("e double click")
        // console.log(e)
        if (e.isDoubleClick){
                 this.setState({saveTicketData:false})      
                this.setState({facilityOilTicketKey: e.cellInfo.rowItem.facilityOilTicketKey})       
                this.toggle()           
        }
        
    }

    onGridRowSelected(s, e) {
/*disabled by HN - 12/14/2023 - user need to double click */
        /*
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            this.setState({saveTicketData:false})      
           this.setState({facilityOilTicketKey: dataItem.facilityOilTicketKey})       
           this.toggle()
         

        } 
        */
    }


    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid=grid;
        this.grid.datasource=this.state.oilTickets;
    }

    refreshOilTicketData = () => {
        const {showAllFacility} = this.state
        this.getOilTicketData(showAllFacility)
    }

    getOilTicketData=(showAllFacility=false)=>{
        const {facilityKeys, loadStatus} = this.state
        console.log("load Status:" + loadStatus);
        let myURI
        if (showAllFacility){
            myURI = apiURL + 'FacilityOilTicket/startdate/' +
                             this.state.startDate.toString().substring(0,10) + 
                             '/enddate/' + this.state.endDate.toString().substring(0,10)
                             + '/AzureAD/' + this.props.user.account.userName;
        }else{
            myURI = apiURL + 'FacilityOilTicket/startdate/' +
                             this.state.startDate.toString().substring(0,10) + 
                             '/enddate/' + this.state.endDate.toString().substring(0,10)
                             + '/MyFacility/AzureAD/' + this.props.user.account.userName;
        }

        fetch(myURI, {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=> {
            this.setState({
                isLoaded:true,
                oilTickets:(facilityKeys === null ? json:
                            json.filter(faclist => (facilityKeys.includes(faclist.facilityKey)))
                           ).filter(rec => (loadStatus === null ? true:(rec.loadStatusKey==loadStatus)))
            })
            //console.log(json)
        })

        
        //this.grid.datasource = this.state.oilTickets;
    }

    handleChange=(v,f) => {
        this.setState({
            startDate:v
        })
    }

    handleEndDateChange = (v,f) => {
        this.setState({
            endDate:v
        })
    }

    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.refreshOilTicketData();
    }

    render(){
        var {isLoaded, oilTickets,windowHeight, modal,showAllFacility,facilityKeys,
            facilityOilTicketKey,saveTicketData}=this.state;
        const gridHeight=(windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()
       

        if (!isLoaded){
            return(<div>
                <h3>Loading facility oil tickets ....</h3>
            </div>)
        }return(
            <div>
                <FormGroup>
                    <Row form>
                        <h2> Oil Tickets | </h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.refreshOilTicketData}>
                            <img src={Refresh}
                                 alt="Refresh"
                                 style={{width: "30px", margin:"0 5px 0 0"}}/>
                            Refresh
                        </Button>

                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.saveWorkbook}>
                            <img src={Excel}
                                 alt="excel"
                                 style={{width:"50px", margin:"0 0 0 0"}}/>
                            Export to Excel
                        </Button>

                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.newOilTicket}>
                            <img src={Plus}
                                 alt="new"
                                 style={{width:"30px", margin:"0 5px 0 0"}}/>
                            New Oil Ticket
                        </Button>


                        <FormGroup  style={{margin:"10px 0 0 100px"}} >
                        <h6>
                        <CustomInput className="float-right" 
                                    hidden={(facilityKeys === null ? false : true)}                                    
                                    type="switch" 
                                    id="accept" 
                                    name="accept" 
                                    label="Show All Facilities"                                   
                                    checked={showAllFacility} 
                                    onClick={(e) => this.onLoadStatusChange(1,e)} />
                                    </h6>
                        </FormGroup>

                    </Row>
                    <ColoredLine color="grey"/>

                </FormGroup>

                <FormGroup>
                    <Row>
                    <Col md={3}>
                        <Label style={{margin: "0 0 0 0", padding:"0"}}>
                            Start Date
                        </Label>
                        <DatePicker value={this.state.startDate} 
                            onChange={(v,f) => this.handleChange(v,f)}/>
                    </Col>
                    <Col md={3}>
                        <Label style={{margin: "0 0 0 0", padding:"0"}}>
                            End Date
                        </Label>
                        <DatePicker value={this.state.endDate}
                                    onChange={(v,f) => this.handleEndDateChange(v,f)} />
                    </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}  //"calc(100% - 2.75rem)"
                        width="100%"
                        dataSource={oilTickets.filter(rec => ((rec.createdByName || '').toLowerCase().includes(searchText)) ||
                                                            (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                            rec.ticketNo.toLowerCase().includes(searchText) ||
                                                            rec.loadStatusDesc.toLowerCase().includes(searchText) ||
                                                            rec.areaName.toLowerCase().includes(searchText) ||
                                                            (rec.companyName || '').toLowerCase().includes(searchText) ||                                                            
                                                            (rec.comments || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        sortDescriptionsChanged={this.onSortChanged}
                        groupDescriptionsChanged={this.onGroupChanged}
                        filterExpressionsChanged={this.onFilterChanged}
                        selectedKeysChanged={this.onGridRowSelected}  
                        cellClicked={this.onGridRowCellClick}
                        editMode={0}>                      
                        
                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>190" />                        
                        <IgrTextColumn field="ticketNo" headerText="Ticket No" width="*>140" />
                        <IgrTextColumn field="loadStatusDesc" headerText="Load Status" width="*>140" />
                       
                        <IgrTemplateColumn field="reportDate" headerText="Report Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}/>

                        <IgrTemplateColumn field="bswPercent" headerText="BSW Percent" width="*>140"
                                            cellUpdating={this.onBSWPercentUpdating}/>
                        <IgrNumericColumn field="tempObserved" headerText="Temp Observed" width="*>160"/>
                        <IgrNumericColumn field="tempBottom" headerText="Temp Bottom" width="*>160"/>
                        <IgrNumericColumn field="tempTop" headerText="Temp Top" width="*>160"/>
                        <IgrNumericColumn field="observedGravity" headerText="Observed Gravity" width="*>160"/>
                        <IgrNumericColumn field="correctedGravity" headerText="Corrected Gravity" width="*>160"/>
                        <IgrNumericColumn field="estimateGrossBarrels" headerText="Gross Barrels" width="*>160"/>
                        <IgrNumericColumn field="estimateNetBarrels" headerText="Net Barrels" width="*>160"/>
                        <IgrTextColumn field="companyName" headerText="Trucking Company" width="*>210" />
                       
                        <IgrTextColumn field="createdByName" headerText="Created By" width="*>150" />                                   
                        <IgrTextColumn field="comments" headerText="Comments" width="*>450"/>
                        <IgrTextColumn field="areaName" headerText="Area Name" width="*>130" />
                        <IgrTextColumn field="locationName" headerText="Basin" width="*>130" />
                        
                        <IgrNumericColumn field="facilityOilTicketKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>

                <div>
                <Modal isOpen={this.state.modal} toggle={() => this.toggle()} 
                       //contentClassName="oilTicketDetail-modal-style"
                       size='lg'
                       >
                    <ModalHeader toggle={() => this.toggle()}> Oil ticket Details </ModalHeader>
                    <ModalBody>
                    <OilTicketDetail FacilityOilTicketKey={facilityOilTicketKey} 
                                     user={this.props.user}
                                     toggle={this.toggle}
                                     refreshOilTicket={this.getOilTicketData}>

                    </OilTicketDetail>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="primary" className="mr-auto">View Ticket Image</Button>
                     
                        <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                        <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                    </ModalFooter> */}
                </Modal>
                </div>

            </div>
        );
    }

    newOilTicket=()=>{
        this.setState({facilityOilTicketKey: -1})             
        this.toggle()
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "OilTickets");
    }

    
    onBSWPercentUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let textBox;

        if (content.childElementCount === 0) {
            textBox = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(textBox);
        }
        else {
            textBox= content.children[0];
        }

        
        textBox.textContent = (!isNaN(item.bswPercent) ? item.bswPercent * 100.0 : 0)
        // textBox.textContent = item.bswPercent * 100.0
        // console.log("item.bswPercent")
        // console.log(!isNaN(item.bswPercent))

    }
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.reportDate).toLocaleDateString("es-PA");
    }
} 