
import React, { Component, ReactDOM, useState, useEffect, useCallback } from 'react';

import {
    Table, Alert, UncontrolledTooltip, Form,
    Media, Col, Row, Button, FormGroup, Modal, ModalHeader, ModalBody,
    Label, Input, InputGroup, ModalFooter, ListGroup
} from 'reactstrap';
import { apiURL, apiDELETEHeader, apiHeader, apiPOSTHeader } from '../../resources/apiURL';
import MenuIcon from '../../resources/menu.png';
import '../../custom.css';
import Select from 'react-select';
import { FormatDateUTC, getRandomInt, getFirstDayofMonth } from '../../resources/utility';
import { getUserADAccount, USDCurrencyFormat, ColoredLine, bitwise } from '../../resources/utility'
import ImageViewer from '../../resources/ImageViewer';
import TrashBin from '../../resources/trash.svg'
import ConfirmModal from '../../operation/ConfirmModal'
import EditIcon from '../../resources/revise.png';
import filter from '../../resources/filter.png'
import OpenLink from '../../resources/OpenLink.png'
import add from '../../resources/add.png'
import link_icon from '../../resources/link_icon.png'
import AFEDetail from '../AFEDetail'

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { FilterFactory } from 'igniteui-react-core';
import { FilterExpression } from 'igniteui-react-core';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


const AFEUnlink = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const {
        asset_detail,
        buttonLabel,
        className,
        formName,
        config_meta,
        isReadOnly,
        user,
        afeHeaderKey
    } = props

    const toggleOK = () => {
        props.onTextChange(config_meta, config_meta.meta_id, "", "")
        setModal(!modal)
    }

    return (
        <div>
            <Button style={{
                marginRight: "5px", marginLeft: "5px",
                width: "25px", padding: "0px"
            }}
                outline={false}
                onClick={() => toggle()}
                id="unlinkAFE"
                //disabled={props.isReadOnly}
                hidden={isReadOnly ||
                    (afeHeaderKey === null || afeHeaderKey <= 0)}
                color="link">
                <img
                    src={TrashBin}
                    alt="Edit"
                    style={{ width: "20px", height: "20px", padding: "0px" }} />

            </Button>
            <UncontrolledTooltip
                placement="right"
                target="unlinkAFE"
            >
                Unlink AFE from Water Connect
            </UncontrolledTooltip>

            <Modal isOpen={modal}
                toggle={toggle}
                className={className}
                size="md">
                <ModalHeader toggle={toggle}> {"AFE Look up"}</ModalHeader>
                <ModalBody>
                    Are you sure you want to unlink AFE  from Water Connect ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleOK}>OK</Button>
                    <Button color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}



const AFELink = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [selAFE, setSelAFE] = useState(null)

    const {
        asset_detail,
        buttonLabel,
        className,
        formName,
        config_meta,
        isReadOnly,
        user,
    } = props

    const AFESelected = (e) => {
        // console.log("e")
        // console.log(e)
        setSelAFE(e)

    }

    const toggleOK = () => {
        props.onTextChange(config_meta, config_meta.meta_id, selAFE.afeHeaderKey, selAFE.afeNo)
        setModal(!modal)
    }

    return (
        <div>
            <Button style={{
                marginRight: "5px", marginLeft: "5px",
                width: "25px", padding: "0px"
            }}
                outline={false}
                onClick={() => toggle()}
                id="openSearchAFE"
                //disabled={props.isReadOnly}
                hidden={props.isReadOnly}
                color="link">
                <img
                    src={link_icon}
                    alt="Edit"
                    style={{ width: "20px", height: "20px", padding: "0px" }} />

            </Button>
            <UncontrolledTooltip
                placement="right"
                target="openSearchAFE"
            >
                Open Search AFE
            </UncontrolledTooltip>

            <Modal isOpen={modal}
                toggle={toggle}
                className={className}
                size="xl">
                <ModalHeader toggle={toggle}> {"AFE Look up"}</ModalHeader>
                <ModalBody>
                    <SearchAFEForm
                        user={user}
                        AFESelected={AFESelected}>

                    </SearchAFEForm>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleOK}>OK</Button>
                    <Button color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const AFEAddView = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [selAFE, setSelAFE] = useState(null)

    const {
        asset_detail,
        buttonLabel,
        className,
        config_meta,
        isReadOnly,
        user,
        afeHeaderKey,
        mode
    } = props


    const AFESelected = (e) => {
        // console.log("-----> in Add new AFE from Asset e")
        // console.log(e)
        setSelAFE(e)
        props.onTextChange(config_meta, config_meta.meta_id, e.afeHeaderKey, e.afeNo)


    }
    const toggleOK = () => {
        props.onTextChange(config_meta, config_meta.meta_id, selAFE.afeHeaderKey, selAFE.afeNo)
        setModal(!modal)
    }

    const openAFEInNewWindows = () => {
        console.log("window.location.href")
        console.log(window.location.href)
        console.log("window.location.pathname")
        console.log(window.location.hostname)


        console.log(afeHeaderKey)
        var url = window.location.href
        url = url.replace("#/wtc", "")
        console.log(url)
        window.open(url + '#/afe?key=' + afeHeaderKey, '_blank', 'noreferrer');
    }

    return (
        <div>
            <Button onClick={() => (mode !== 'add' ? openAFEInNewWindows() : toggle())}
                style={{ width: "25px", padding: "0px" }}
                outline={false}
                id={(mode === 'add' ? "addNewAFE" : "viewFE")}
                hidden={isReadOnly ||
                    ((afeHeaderKey === null || afeHeaderKey == "" || afeHeaderKey < 0) && mode === 'view') ||
                    (afeHeaderKey !== null && afeHeaderKey > 0 && mode === 'add')
                }
                color="link">

                <img
                    src={(mode === 'add' ? add : OpenLink)}
                    alt="Edit"
                    style={{ width: "20px", height: "20px", padding: "0px" }} />

            </Button>

            <UncontrolledTooltip
                placement="right"
                target="addNewAFE"
            >
                New AFE
            </UncontrolledTooltip>

            <UncontrolledTooltip
                placement="right"
                target="viewFE"
            >
                View AFE Details
            </UncontrolledTooltip>

            <Modal isOpen={modal}
                toggle={toggle}
                className={className}
                size="xl">
                <ModalHeader toggle={toggle}> {((afeHeaderKey === undefined) ? "New AFE" : "View AFE Details")}</ModalHeader>
                <ModalBody>
                    <AddAFEForm
                        afeHeaderKey={((afeHeaderKey == '' ||
                            afeHeaderKey === null ||
                            afeHeaderKey === undefined) ? -1 : afeHeaderKey)}
                        user={user}
                        toggle={toggle}
                        toggleOK={toggleOK}
                        AFESelected={AFESelected}
                        asset_detail={asset_detail}>
                    </AddAFEForm>
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>OK</Button>
                    <Button color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter> */}
            </Modal>
        </div>
    );
}


class AddAFEForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            empList: [],
            isEmpListLoaded: false,
            budgetList: [],
            isBudgetLoaded: false,
            facilityList: [],
            isFacilityLoaded: false,
            costTypeList: [],
            isCostTypeLoaded: false,
            afeTypeList: [],
            isAFETypeLoaded: false,
            budgetClassList: [],
            isBudgetClassLoaded: false,
            uomList: [],
            isUOMListLoaded: false,
            linkAFEList: [],
            isLinkAFELoaded: false,
            basinList: [],
            isBasinLoaded: false,
            countyList: [],
            isCountyLoaded: false,
            afeStatusList: [],
            selAFEStatus: [],
            selBasin: [],
            isAFEStatusList: false,
            isAFEAdmin: false,
            isSecurityLoaded: false,
            afeBudgetListURL: [],
            projectStatusList: [],
            isProjectStatusListLoaded: false,
            areaList: [],
            isAreaListLoaded: false,
            dupAFEList: [],
            isDupAFEListLoaded: false,
            statusKey: 0,
            afeNo: '',
            afeStatusDesc: '',
            afeName: '',
            afeHeaderKey: props.afeHeaderKey,
            closeoutStatusDesc: '',
            prefillAFEData: [],
            isPrefillDataLoaded: false,
            afeManagerSponsorLink: [],
            isAFEManagerSponsorLinkLoaded: false
        }
    }
    get_meta_text = (meta_id, asset_detail) => {

        if (asset_detail.meta_datas !== null) {
            let meta_data = asset_detail.meta_datas.filter(e => e.meta_id === meta_id);
            if (meta_data !== undefined && meta_data !== null) {
                if (meta_data.length > 0) {

                    if (meta_data[0].data_type === 'date' &&
                        meta_data[0].meta_text !== null &&
                        meta_data[0].meta_text !== '') {

                        return new Date(meta_data[0].meta_text)

                    } else if (meta_data[0].data_type === "datetime") {
                        //return new Date(meta_data[0].meta_value)
                    }
                    return meta_data[0].meta_text
                }
            }
        }
        return null
    }
    get_meta_value = (meta_id, asset_detail) => {
        if (asset_detail.meta_datas !== null) {
            let meta_data = asset_detail.meta_datas.filter(e => e.meta_id === meta_id);
            if (meta_data !== undefined && meta_data !== null) {
                if (meta_data.length > 0) {

                    if ((meta_data[0].data_type === 'date') &&
                        meta_data[0].meta_value !== null &&
                        meta_data[0].meta_value !== '') {

                        return new Date(meta_data[0].meta_value)

                    } else if (meta_data[0].data_type === "datetime") {
                        //return new Date(meta_data[0].meta_value)
                    }
                    return meta_data[0].meta_value
                }
            }
        }
        return null
    }

    get_afe_sponsor = (manager) => {

        let myURI = apiURL + 'Asset/WBR/AFEManagerSponsorLink'
        var sponsor_ad = null

        let sponsor = this.state.afeManagerSponsorLink.filter(a => a.afe_manager_ad === manager)
        // console.log("sponsor")
        // console.log(sponsor)
        // console.log(manager)

        if (sponsor.length > 0) {
            sponsor_ad = sponsor[0].afe_sponsor_ad
        }


        return sponsor_ad
    }

    createSelList = (v, l) => {

        const selRecord = {
            value: 4,
            label: l
        }

        return selRecord
    }

    get_estStartDate = (wtc_plandstartdate, index) => {
        // console.log("wtc_plandstartdate")
        // console.log(wtc_plandstartdate)
        if (wtc_plandstartdate !== null) {
            let planDate = wtc_plandstartdate.split('-')
            console.log(planDate)
            if (planDate.length > index) {
                return (FormatDateUTC(planDate[index].trim())) //'2023-03-23T00:00:00'
            }
        }
        return null
    }

    prepAFEData = () => {
        const { afeHeaderKey } = this.state
        let asset_detail = this.props.asset_detail

        console.log("--->data<-----")
        console.log(asset_detail)

        if (afeHeaderKey > 0) {
            this.setState({ isPrefillDataLoaded: true })
        } else {
            const afeData = {
                "afeNo": "XXXXXX",
                "afeName": this.get_meta_value("wtc_projectname", asset_detail),
                "afeStatusDesc": "Initiated",
                "afeManagerName": this.get_meta_value("wtc_projectmanager", asset_detail),
                "basin": this.get_meta_text("basin", asset_detail),
                "comments": "",
                "typeKey": 0, /** Set to Original by default - request by Zoe 03/18/2024*/
                "afeTypeDesc": "",
                "afeDescription": "",
                "estStartDate": this.get_estStartDate(this.get_meta_value("wtc_plandstartdate", asset_detail), 0),
                "estComplete": this.get_estStartDate(this.get_meta_value("wtc_plandstartdate", asset_detail), 1),
                "budgetClassDesc": "",
                "budgetID": "",
                "wbOwnedPct": null,
                "NonOperatedWIPct": 0,
                "wdAFENo": "",
                "facilityKey": null,
                "wellName": "",
                "lease": "",
                "county": "",
                "state": "",
                "area": this.get_meta_text("sub_system", asset_detail),
                "intangibleCost": 0,
                "tangibleCost": 0,
                "partnerCost": 0,
                //"afeManagerKey": this.getEmpKey(),
                "afeSponsor": this.get_afe_sponsor(this.get_meta_value("wtc_projectmanager", asset_detail)),
                "afeCostType": "",
                "locationKey": Number(this.get_meta_value("basin", asset_detail)),
                "areaKey": Number(this.get_meta_value("sub_system", asset_detail)),
            }

            console.log("Prefill afe data ---> afeData")
            console.log(afeData)

            this.setState({
                isPrefillDataLoaded: true,
                prefillAFEData: afeData
            })
        }

    }

    getCounty = () => {

        let myURI = apiURL + 'AFE/County'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    countyList: this.createCountyList(json),
                    isCountyLoaded: true
                })
            });
    }
    createCountyList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.countyDesc
            e.label = e.countyState
            tempList.push(e)
        })

        return tempList
    }

    getAFEStatus = () => {
        let myURI = apiURL + 'AFE/StatusFilter'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeStatusList: this.createAFEStatusList(json),
                    isAFEStatusLoaded: true
                })
            });
    }

    createAFEStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeStatusKey
            e.label = e.afeStatusDesc
            tempList.push(e)
        })

        return tempList
    }


    getBasin = () => {

        //let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName
        let myURI = apiURL + 'AFE/Location' // + this.props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }


    getUOMList = () => {
        let myURI = apiURL + 'AFE/UOM'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    uomList: this.createUOMList(json),
                    isUOMListLoaded: true
                })
            });
    }



    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }

    getAFEBudgetListURL = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/AFEBudgetListURL'

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    afeBudgetListURL: json
                })
            });
    }



    createUOMList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.uomKey
            e.label = e.uomDesc
            tempList.push(e)
        })

        return tempList
    }

    getAssoAFE = () => {
        let myURI = apiURL + 'AFE/WDAFE/Associate'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    linkAFEList: this.createLinkAFEList(json),
                    isLinkAFELoaded: true
                })
            });
    }

    createLinkAFEList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.project_ID
            e.label = e.projectNoName
            tempList.push(e)
        })

        return tempList
    }


    getBudgeClass = () => {
        let myURI = apiURL + 'AFE/BudgetClass'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetClassList: this.createBudgetClassList(json),
                    isBudgetClassLoaded: true
                })
            });
    }



    createBudgetClassList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetClassKey
            e.label = e.afeBudgetClassDesc
            tempList.push(e)
        })

        return tempList
    }

    getAFEType = () => {
        let myURI = apiURL + 'AFE/Type'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeTypeList: this.createAFETypeList(json),
                    isAFETypeLoaded: true
                })
            });
    }

    createAFETypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeTypeKey
            e.label = e.afeTypeDesc
            tempList.push(e)
        })
        return tempList
    }

    getAFECostType = () => {
        let myURI = apiURL + 'AFE/LineType'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    costTypeList: this.createCostTypeList(json),
                    isCostTypeLoaded: true
                })
            });
    }

    createCostTypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeLineTypeKey
            e.label = e.afeLineTypeDesc
            tempList.push(e)
        })

        return tempList
    }

    getFacility = () => {
        const { user } = this.props
        let myURI = apiURL + 'EmployeeDetails/myfacility/AzureAD/' + user.account.userName

        //console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    facilityList: this.createFacilityList(json),
                    isFacilityLoaded: true
                })
            });
    }

    createFacilityList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.facilityKey
            e.label = e.facilityName
            tempList.push(e)
        })

        return tempList
    }

    getBudget = () => {

        let myURI = apiURL + 'AFE/Budget'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetList: this.createBudgetList(json),
                    isBudgetLoaded: true
                })
            });
    }

    createBudgetList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetDesc
            e.label = e.afeBudgetDesc
            tempList.push(e)
        })

        return tempList
    }

    getProjectStatus = () => {
        let myURI = apiURL + 'AFE/ProjectStatus'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    projectStatusList: this.createProjectStatusList(json),
                    isProjectStatusListLoaded: true
                })
            });
    }

    createProjectStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.projectStatusKey
            e.label = e.projectStatusDesc
            tempList.push(e)
        })

        return tempList
    }

    getArea = () => {
        let myURI = apiURL + 'AFE/Area'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    areaList: this.createAreaList(json),
                    isAreaListLoaded: true
                })
            });
    }
    createAreaList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.areaKey
            e.label = e.areaName
            tempList.push(e)
        })

        return tempList
    }

    getEmpList = () => {

        let myURI = apiURL + 'EmployeeDetails'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    empList: this.createEmpList(json),
                    isEmpListLoaded: true
                })
            });
    }

    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.employeeKey
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }

    getDupAFE = () => {

        const { user } = this.props

        let myURI = apiURL + 'AFE/Header/AFEDuplicate/AzureAD/' + user.account.userName

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    dupAFEList: this.createDupFEList(json),
                    isDupAFEListLoaded: true
                })
            });
    }

    createDupFEList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeNo
            e.label = e.afeNo + ' - ' + e.afeName
            tempList.push(e)
        })

        return tempList
    }

    getAFEManagerSponsorLink = () => {

        const { user } = this.props

        let myURI = apiURL + 'Asset/WBR/AFEManagerSponsorLink'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeManagerSponsorLink: json,
                    isAFEManagerSponsorLinkLoaded: true
                })

            });
    }

    componentDidMount = () => {
        this.getAFEManagerSponsorLink()
        this.getProjectStatus()
        this.getArea()
        this.getAFEBudgetListURL()
        //this.getUserSecurity()
        //this.setDefaultAFEQuery()
        this.getAFEStatus()
        this.getCounty()
        this.getBasin()
        this.getAssoAFE()
        this.getUOMList()
        this.getBudgeClass()
        this.getAFEType()
        this.getAFECostType()
        this.getFacility()
        this.getBudget()
        this.getEmpList()
        this.getDupAFE()
        //this.prepAFEData()
    }

    setAFEDetailsFormHeader = (afeNo, afeStatusDesc, afeName, afeHeaderKey, closeoutStatusDesc) => {
        this.setState({
            afeNo: afeNo,
            afeStatusDesc: afeStatusDesc,
            afeName: afeName,
            afeHeaderKey: afeHeaderKey,
            closeoutStatusDesc: closeoutStatusDesc
        })
    }

    closeAFEDetailsForm = () => {
        this.setState({
            modalAFEDetails: !this.state.modalAFEDetails,
            openAFEDetails: false,
            afeHeaderKey: -1
        })
    }


    render() {
        const { basinList, isBasinLoaded, isPrefillDataLoaded, prefillAFEData,
            windowHeight, afeHeaderKey, linkAFEList, isLinkAFELoaded,
            empList, budgetList, facilityList, isAFEStatusLoaded,
            costTypeList, afeTypeList, budgetClassList, uomList, countyList,
            isEmpListLoaded, isBudgetLoaded, isFacilityLoaded, isCostTypeLoaded,
            isAFETypeLoaded, isBudgetClassLoaded, isUOMListLoaded, projectStatusList,
            isProjectStatusListLoaded, areaList, isAreaListLoaded, isAFEManagerSponsorLinkLoaded,
            dupAFEList } = this.state

        //const searchText = window.$searchText.toLowerCase()
        const gridHeight = (windowHeight * 0.75) + "px"

        if (!isProjectStatusListLoaded) {
            return (<div>
                <h3>Loading Project Status data.....</h3>
            </div>)
        } else if (!isAreaListLoaded) {
            return (<div>
                <h3>Loading Area data.....</h3>
            </div>)
        } else if (!isAFEStatusLoaded) {
            return (<div>
                <h3>Loading AFE Status data.....</h3>
            </div>)
        } else if (!isBasinLoaded) {
            return (<div>
                <h3>Loading Basin data.....</h3>
            </div>)
        } else if (!isLinkAFELoaded) {
            return (<div>
                <h3>Loading Link AFE data.....</h3>
            </div>)
        } else if (!isEmpListLoaded) {
            return (<div>
                <h3>Loading Employee data.....</h3>
            </div>)
        } else if (!isBudgetLoaded) {
            return (<div>
                <h3>Loading Budget data.....</h3>
            </div>)
        } else if (!isFacilityLoaded) {
            return (<div>
                <h3>Loading Facility data.....</h3>
            </div>)
        } else if (!isCostTypeLoaded) {
            return (<div>
                <h3>Loading Cost Type data.....</h3>
            </div>)
        } else if (!isAFETypeLoaded) {
            return (<div>
                <h3>Loading AFE Type data.....</h3>
            </div>)
        } else if (!isBudgetClassLoaded) {
            return (<div>
                <h3>Loading Budget Class data.....</h3>
            </div>)
        } else if (!isUOMListLoaded) {
            return (<div>
                <h3>Loading UOM List data.....</h3>
            </div>)
        } else if (!isAFEManagerSponsorLinkLoaded) {
            return (<div>
                <h3>Preparing AFE Data.....</h3>
            </div>)
        } else if (!isPrefillDataLoaded && isAFEManagerSponsorLinkLoaded) {
            this.prepAFEData()
            return (<div>
                <h3>Preparing AFE Data.....</h3>
            </div>)
        }

        return (
            <div>
                <AFEDetail
                    toggle={this.props.toggle}
                    setFormHeader={this.setAFEDetailsFormHeader}
                    user={this.props.user}
                    afeHeaderKey={afeHeaderKey}
                    empList={empList}
                    budgetList={budgetList}
                    facilityList={facilityList}
                    costTypeList={costTypeList}
                    afeTypeList={afeTypeList}
                    budgetClassList={budgetClassList}
                    uomList={uomList}
                    linkAFEList={linkAFEList}
                    countyList={countyList}
                    areaList={areaList}
                    projectStatusList={projectStatusList}
                    basinList={basinList}
                    dupAFEList={dupAFEList}
                    prefillAFEData={prefillAFEData}

                    WaterConnectLink={this.props.AFESelected}
                >
                </AFEDetail>
            </div>
        )
    }
}

class SearchAFEForm extends Component {
    static displayName = SearchAFEForm.name

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this)

        this.searchInput = React.createRef();

        this.state = {
            isAFEDataLoaded: false,
            afeData: [],
            afeDataFilter: [],
            user: props.user,
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            searchText: '',
            isTyping: false
        }

    }

    onGridRowSelected(s, e) {

        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]

            // console.log("dataItem<-------------------")
            // console.log(dataItem)

            this.props.AFESelected(dataItem)
        }
    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.afeDataFilter;

    }

    setFocusToSearchInput = () => {
        console.log("here")
        if (this.state.isTyping) {
            console.log("inside here")
            console.log(this.searchInput.current)
            if (this.searchInput.current) {
                this.searchInput.current.focus();
            }
        }
    };
    getAFEData = () => {
        // const { afeNo, dept, scid } = this.state

        let myURI = apiURL + 'AFE/Header/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName;

        // console.log("myURI")
        // console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // let afeData = json.filter(e => e.afeStatusDesc !== 'Closeout (Completed)').map(e => {
                //     return {
                //         ...e,
                //         searchtag: e.afeNo + ';' + e.afeName + ';' + e.afeDescription + ';' + e.afeManagerName
                //     }
                // })

                // console.log(afeData)

                this.setState({
                    isAFEDataLoaded: true,
                    afeData: json,
                    afeDataFilter: json
                })
            });

    }
    componentDidMount() {
        this.getAFEData()
    }
    onTextChange = (fieldName, fieldValue, e) => {
        // const { fullAFEData } = this.state
        // console.log(fieldName + ' ------ ' + fieldValue)
        // console.log(e)

        switch (fieldName) {
            case "afeSearch":

                let searchText = fieldValue.toLowerCase()
                this.grid.filterExpressions.clear()
                if (searchText === "") {

                    this.setState({
                        searchText: searchText,
                        isTyping: true,
                        afeDataFilter: this.state.afeData
                    })
                    return
                }

                //this.filterFactory = new FilterFactory()
                //const column = this.filterFactory.property("asset_name").toLower();
                // const column = this.filterFactory.property("afeNo").toLower();



                // let filter = column.contains("searchText")

                // this.grid.filterExpressions.add(filter);

                let afeDataFilter = this.state.afeData.filter(e => (
                    e.afeNo.toLowerCase().includes(searchText) ||
                    e.afeName.toLowerCase().includes(searchText) ||
                    e.afeManagerName.toLowerCase().includes(searchText) ||
                    e.afeDescription.toLowerCase().includes(searchText)
                ))
                this.setState({
                    searchText: searchText,
                    isTyping: true,
                    afeDataFilter: afeDataFilter
                })

                // console.log("here")
                break;

            default:
                break;
        }
    }
    render() {
        const { isAFEDataLoaded, afeDataFilter, searchText } = this.state

        if (!isAFEDataLoaded) {
            return (<div>Loading AFE Data .......</div>)

        } else
            return (<div>
                <Form>
                    <FormGroup>
                        <Row>
                            <Col md={8}>
                            </Col>
                            <Col md={4}>

                                <input width="100%"
                                    className="float-right"
                                    ref={this.searchInput}
                                    placeholder="Type here to search......."
                                    onBlur={this.setFocusToSearchInput}
                                    onChange={(e) => this.onTextChange("afeSearch", e.target.value, e)}
                                    value={this.state.searchText}
                                >

                                </input>


                            </Col>

                        </Row>
                    </FormGroup>

                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="350px"
                        width='100%'
                        dataSource={afeDataFilter}
                        isColumnOptionsEnabled="true"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        groupSummaryDisplayMode={"RowTop"}
                        groupHeaderDisplayMode="combined"
                        summaryScope="Both"
                        editMode={0}
                        useAccessibility="true"
                        selectedKeysChanged={this.onGridRowSelected}
                    // key={Math.random}
                    // editMode={(isFormReadOnly ? 0 : 1)}
                    //cellValueChanging={this.onCellValueChanging}
                    >
                        <IgrTextColumn field="afeNo" headerText="AFE No"
                            width="*>120"

                        />
                        <IgrTextColumn field="afeName"
                            headerText="AFE Name"
                            width="*>170"

                        />
                        <IgrTextColumn
                            field="afeStatusDesc"
                            headerText="Status"
                            width="*>150"

                        />


                        <IgrTextColumn field="basin" headerText="Entity"
                            width="*>140"
                        />

                        <IgrTextColumn field="afeTypeDesc" headerText="AFE Type"
                            width="*>120"
                        />


                        <IgrTextColumn field="afeManagerName" headerText="AFE Manager "
                            width="*>150"
                        />
                        <IgrTextColumn field="afeDescription" headerText="Project Scope"
                            width="*>400"
                        />


                        <IgrNumericColumn field="afeHeaderKey" isHidden />
                        <IgrNumericColumn field="closeoutStatusKey" headerText="closeoutStatusKey" width="*>150" isHidden={true} />
                        <IgrNumericColumn field="statusKey" headerText="StatusKey" width="*>150" isHidden={true} />

                    </IgrDataGrid>

                </Form>
            </div>)
    }

}
export { AFEAddView, AFEUnlink, AFELink }