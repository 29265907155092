

import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './DDMenu.css';
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap/es';

const DDMenuTemplate = (props) => {


    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => {
        setDropdownOpen(prevState => !prevState)
        if (props.parentDropdown !== undefined) {
            if (dropdownOpen) {
                props.parentDropdown()
            }

        }
    };
    // this.items = this.
    return (
        <Dropdown isOpen={dropdownOpen}
            toggle={toggle}
            direction={props.direction}>
            <DropdownToggle className={props.ClassName}
                color="light"
                light
                caret
                style={(props.subMenu === "1" ? {
                    margin: "2px 10px 0 0px",
                    width: "100%",
                    textAlign: "left"
                }
                    : { margin: "2px 10px 0 0px" })}>

                {(props.subMenu === "1" ?
                    <Label style={{ marginLeft: "10px", height:"18px" }}>
                        {props.DDName}
                    </Label> :
                    props.DDName)}
            </DropdownToggle>

            <DropdownMenu className="last-dropdown">

                {props.DDItems.map(function (item) {
                    if (item.divider === "1") {
                        return <DropdownItem divider key={item.id} />;
                    } if (item.disabled === "1") {
                        return <DropdownItem disabled tag={Link} to={item.URL} key={item.id}>{item.name}</DropdownItem>;
                    } else if (item.header === "1") {
                        return <DropdownItem header key={item.id}>{item.name}</DropdownItem>;
                    } else if (item.dropdown === "1") {
                        return <DDMenuTemplate DDName={item.name}
                            ClassName="no-border"
                            direction={item.direction}
                            subMenu={"1"}
                            parentDropdown={setDropdownOpen}
                            DDItems={item.DDItems} />
                    }
                    else {
                        return <DropdownItem tag={Link} to={item.URL} key={item.id} >{item.name}</DropdownItem>;
                    }

                })
                }

            </DropdownMenu>
        </Dropdown>
    );
}

export default DDMenuTemplate;