
import React, { Component } from 'react';
import {
     Col, Row, Button, FormGroup, Label,
    CustomInput, Input
} from 'reactstrap';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'


import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount} from '../../resources/utility'

import NumberFormat from 'react-number-format';

import { ColoredLine} from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

export default class ItemSupplierLink extends Component {
    static displayName = ItemSupplierLink.name;

    constructor(props) {
        super(props)

        this.onGridRef = this.onGridRef.bind(this);        
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
      
        this.state = {
            windowHeight: window.innerHeight,
            msgHeader : "",
            msgBody: "",
            modalMsg: false,
            itemSupplierLinkKey: -1,
            isFormReadOnly: false,
            itemMasterKey: props.itemMasterKey,
            supplierLink: [],
            modalLineAdd: false,
            selSupplierLink: [],
            isLoaded: false,
            supList: props.supList,
            uomList: this.createUOMList(props.uomList),
            supplierVal: true
        }
    }

    createUOMList = (uomList) => {
        var tempList = []
        uomList.map(e => {
            e.value = e.uomKey
            e.label = e.uomDesc

            tempList.push(e)
        })

        return tempList
    }


    deleteSupplierLink=()=>{
        const supplerLinkDeleteDTO = this.createSupplierLinkDeleteDTO()
        // console.log(userDeleteDTO)

        let myURI = apiURL + 'ItemMaster/SupplierLink'

        fetch(myURI , apiDELETEHeader(supplerLinkDeleteDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
            //   console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);
              
            }
            this.getSupplierlink()
            // console.log("No error here: " + rspData)
          })
          .catch(error =>{
            this.getSupplierlink()
            // console.log("DELETE Error: " + error);
          })

          this.setState({itemSupplierLinkKey: -1})

        
    }

    createSupplierLinkDeleteDTO=()=>{
        const {selSupplierLink} = this.state
        const {user} = this.props

        const supplierLinkDeleteDTO = {
            "ItemSupplierLinkKey": selSupplierLink.itemSupplierLinkKey,
            "DeletedBy": getUserADAccount(user.account.userName)
        }
        return supplierLinkDeleteDTO
    }

    toggleModalLineAdd = () => {
        const selSupplierLink = this.createNewSupplierLinkDTO()
        ///this.saveAFELine()
        //console.log("this.state.supList")

        //console.log(this.state.supList)
        this.setState({
            supplierVal: true,
            itemSupplierLinkKey: -1,
            modalLineAdd: !this.state.modalLineAdd,
            selSupplierLink: selSupplierLink
        })
    }

    toggleModalLineEdit=()=>{
        this.setState({
            modalLineAdd: !this.state.modalLineAdd,
        })
    }

    supplierLinkEdit = (closeForm) => {

        const updateSupplierLinkDTO = this.createSupplierLinkUpdateDTO()

        let myURI = apiURL + 'ItemMaster/SupplierLink'
        fetch(myURI, apiPUTHeader(updateSupplierLinkDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                //this.props.refreshData()
                this.getSupplierlink()
                this.toggleModalLineEdit()
            })
            .catch(error => {
                this.getSupplierlink()
                this.toggleModalLineEdit()
                console.log("PUT Error: " + error);
            })
    }

    createSupplierLinkUpdateDTO=()=>{
        const {selSupplierLink, itemMasterKey} = this.state
        const {user} = this.props

        const supplierLinkUpdateDTO = {
            "ItemSupplierLinkKey": selSupplierLink.itemSupplierLinkKey,
            "itemMasterKey": itemMasterKey,
            "supplierID": selSupplierLink.supplierID,
            "supplierPartNumber": selSupplierLink.supplierPartNumber,
            "prefer": selSupplierLink.prefer,
            "uomKey": selSupplierLink.uomKey,
            "unitPrice": selSupplierLink.unitPrice,
            "minQtyOrder": selSupplierLink.minQtyOrder,
            "comments": selSupplierLink.comments,
            "modifiedBy": getUserADAccount(user.account.userName)
        }
        return supplierLinkUpdateDTO
    }

    supplierLinkAdd = (closeForm) => {

     

        const { selSupplierLink } = this.state

        var cont = true; 
        
        if (selSupplierLink.supplierID === null || selSupplierLink.supplierID === ""){
            console.log("This hit")
            this.setState({supplierVal : false},()=>{
                console.log(selSupplierLink.supplierID + "xxx")
            })
            cont = false
        }


        if (cont){
            console.log("selSupplierLink")
            console.log(selSupplierLink)
    
            let myURI = apiURL + 'ItemMaster/SupplierLink'
            fetch(myURI, apiPOSTHeader(selSupplierLink))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        //console.log("Error return but you dont see")
                        const error = (rspData && rspData.message) || response.status;
                        rspData.then(t => {
                            this.setState({
                                msgBody: t,
                                msgHeader: "Error",
                                modalMsg: !this.state.modalMsg
                            })
                        })
                        return Promise.reject(error);
                    }
    
                    console.log("No error here: ")
                    this.getSupplierlink()
                })
                .catch(error => {
                    console.log("PUT Error: " + error);
                })
    
            if (closeForm) {
                this.toggleModalLineAdd()
            } else {
                const selSupplierLink = this.createNewSupplierLinkDTO()
                this.setState({ selSupplierLink: selSupplierLink })
            }

        }
       

    }
    
    getSelSupplierKey = (value) => {
        // return value.wdSupplierKey
    }
    



    onTextChange = (fieldName, fieldValue, e) => {
        console.log("props")
        console.log(fieldName + '=' + fieldValue)
        const { selSupplierLink } = this.state;

        switch (fieldName) {
            case "supplierID":
                selSupplierLink.supplierID = (e === null ? null : e.value)
                // selSupplierLink.supplierID = (e === null ? null : this.getSelSupplierKey(e))
               this.setState({supplierVal : fieldValue != null})
                //validDataItem.itemNumber = true
                break;
            case "uomKey":
                selSupplierLink.uomKey = e.value
                break;
            case "supplierPartNumber":
                selSupplierLink.supplierPartNumber = fieldValue
                break;
            case "minQtyOrder":
                selSupplierLink.minQtyOrder = fieldValue
                break;
            case "unitPrice":
                selSupplierLink.unitPrice = fieldValue
                break;

            case "comments":
                selSupplierLink.comments = fieldValue
                break;
            case "prefer":
                selSupplierLink.prefer = (fieldValue ? 1 : 0)
                break;
            default:

        }

        this.setState({ selSupplierLink: selSupplierLink})
    }

    createNewSupplierLinkDTO() {
        const { user } = this.props
        const { itemMasterKey } = this.state
        const itemDTO = {
            "itemMasterKey": itemMasterKey,
            "supplierID": '',
            "supplierPartNumber": "",
            "prefer": 0,
            "uomKey": null,
            "unitPrice": 0.0,
            "minQtyOrder": 0.0,
            "comments": "",
            "createdBy": getUserADAccount(user.account.userName)
        }
        return itemDTO
    }

    getSupplierlink = () => {
        const { itemMasterKey } = this.state
        let myURI = apiURL + 'ItemMaster/SupplierLink/' + itemMasterKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    supplierLink: json,
                    isLoaded: true
                })
            });
    }


    getSelSup = (value) => {
        const { supList } = this.state
        return supList.filter(e => value === e.supplier_ID)
        // return supList.filter(e => e.wdSupplierKey === value)

    }

    getSelUOM = (value) => {
        const { uomList } = this.state
        return uomList.filter(e => value === e.uomKey)
    }

    componentDidMount() {
        this.getSupplierlink()
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.supplierLink;
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            //console.log(dataItem)

            this.setState({
                selSupplierLink: dataItem,
                itemSupplierLinkKey: dataItem.itemSupplierLinkKey
            })
            console.log(dataItem)
            //this.toggle()
            //this.onVolRefresh()    
        }
    }

    


    render() {
        const { isFormReadOnly, itemMasterKey, supplierLink,
            selSupplierLink, supList, uomList,
            isLoaded, itemSupplierLinkKey, msgBody, msgHeader, supplierVal, windowHeight } = this.state
            const gridHeight = (windowHeight * 0.25) + "px"
        if (!isLoaded) {
            return (<div>Loading Supplier Link Data .......</div>)
        }
        else
        return (<div>
            <Row form style={{ marginTop: "5px", marginBottom: "2px" }}>
                <Col sm={6}>
                    {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                    <Button color="primary" outline className="float-left"
                        disabled={isFormReadOnly}
                        hidden={isFormReadOnly}
                        style={{ height: "40px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.toggleModalLineAdd()}
                    >New</Button>

                    <Button color="success" outline className="float-left"
                        style={{ height: "40px", marginBottom: "5px", marginLeft: "10px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        disabled = {itemSupplierLinkKey < 0}
                        onClick={() => this.toggleModalLineEdit()}
                    >Edit</Button>


                    {/* <Button color="success" outline className="float-left"
                            style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}
                            //hidden={this.setIsReadOnly(statusKey)}
                            onClick={() => this.toggleLineCRUD()}
                        >Import from Excel</Button> */}


                    {/* <Button color="success" outline className="float-left"
                            style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}
                            //hidden={this.setIsReadOnly(statusKey)}
                            onClick={() => this.toggleLineCRUD()}
                        >Export to Excel</Button> */}

                
                </Col>
                <Col sm={6}>
                <ConfirmModal outline={true}
                        disabled={isFormReadOnly || itemSupplierLinkKey < 0}
                        hidden={isFormReadOnly}
                        color="danger"
                        className="float-right"
                        buttonLabel="Delete"
                        //hidden={(!((statusKey == 0 && safetyObservationKey > 0) || ((isQHSEAdmin || isQHSEApprover) && statusKey != 6 && statusKey != 4 && safetyObservationKey > 0)))}
                        formName="Confirm"
                        id={itemMasterKey}
                        message="Are you sure you want to unlink selected supplier from selected item?"
                        onClickYes={(e) => this.deleteSupplierLink()}
                        image={<img
                            src={TrashBin}
                            id={itemMasterKey}
                            alt="Edit"
                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                    </ConfirmModal>
                </Col>
            </Row>

            {/* <ColoredLine color="grey" /> */}

            {/* <Row form style={{ zIndex: 0, position: 'relative' }}> */}
                {/* <FormGroup> */}

                <IgrDataGrid
                    ref={this.onGridRef}
                    height={gridHeight}
                    width='100%'
                    //autoFocus={true}
                    dataSource={supplierLink}
                    isColumnOptionsEnabled="false"
                    autoGenerateColumns="false"
                    headerClickAction="none"
                    selectionMode={"SingleRow"}
                    editModeClickAction="DoubleClick"
                    editMode={(isFormReadOnly ? 0 : 1)}
                    editOnKeyPress={"false"}
                    selectedKeysChanged={this.onGridRowSelected}
                    cellValueChanging={this.onCellValueChanging}
                >


                    <IgrTextColumn
                        field="supplierID"
                        headerText="ID"
                        width="120"
                        cellUpdating={this.onCellUpdating}
                       
                    />

                    <IgrTextColumn
                        field="prefer"
                        headerText="Prefer"
                        width="100"
                    />

                    <IgrTextColumn
                        field="supplierName"
                        headerText="Name"
                        width="200"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn
                        field="supplierStatus"
                        headerText="Status"
                        width="130"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn
                        field="supplierPartNumber"
                        headerText="Part No"
                        width="*>340"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrNumericColumn
                        field="unitPrice"
                        headerText="Purchase Price"
                        positivePrefix="$"
                        width="*>150"
                        showGroupingSeparator="true"
                    //cellUpdating={this.onStatusCellUpdating}
                    />

                    <IgrTextColumn
                        field="uomDesc"
                        headerText="UOM"
                        width="100"
                        cellUpdating={this.onItemCellUpdating}
                    />


                    <IgrTextColumn
                        field="comments"
                        headerText="Comments"
                        width="*>280"
                    //cellUpdating={this.onStatusCellUpdating}
                    />
                    {/* <IgrTextColumn field="codeDefinitionKey" headerText="Item Number"
                                                        /> */}

                    <IgrNumericColumn field="itemSupplierLinkKey" isHidden />
                </IgrDataGrid>

                {/* </FormGroup> */}
            {/* </Row> */}

            <div>
                <Modal isOpen={this.state.modalLineAdd}
                    toggle={() => this.toggleModalLineAdd()}
                    size="md">
                    <ModalHeader toggle={() => this.toggleModalLineAdd()}> {msgHeader}
                        {" Supplier Link Add"}

                    </ModalHeader>

                    <ModalBody>
                        {msgBody}
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="supplier" > Supplier </Label>

                                    <Select
                                    className = {!supplierVal ? "form-error" : ""}

                                        options={supList}
                                        name='supplier'
                                        id='supplier'
                                        defaultValue={this.getSelSup(selSupplierLink.supplierID)}
                                        value={this.getSelSup(selSupplierLink.supplierID)}
                                        onChange={(e) => this.onTextChange("supplierID", e, e)}
                                        isDisabled= {itemSupplierLinkKey > -1}
                                        isClearable={true}>

                                    </Select>
                                </FormGroup>
                            </Col>


                        </Row>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="supplierPartNumber" > Supplier Item Number </Label>
                                    <Input type="text" name="supplierPartNumber"
                                        rows={3}
                                        //disabled={this.isFormReadOnly()}
                                        id="supplierPartNumber" placeholder="Supplier Item Number"
                                        value={selSupplierLink.supplierPartNumber}
                                        defaultValue={selSupplierLink.supplierPartNumber}
                                        onChange={(e) => this.onTextChange("supplierPartNumber", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>

                        <Row form style={{ marginBottom: "10px", zIndex: 0, position: 'relative' }}>
                            <Col md={12}>
                                <FormGroup check inline>
                                    <CustomInput
                                        type="checkbox" name="prefer" id="prefer"
                                        placeholder=""
                                        checked={selSupplierLink.prefer !== 0}
                                        onChange={(e) => this.onTextChange("prefer", e.target.checked, e)}
                                    />
                                    <Label check for="prefer">Prefer Supplier </Label>
                                </FormGroup>
                            </Col>

                        </Row>


                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="minQtyOrder">Order Qty</Label>

                                    <NumberFormat
                                        value={selSupplierLink.minQtyOrder}
                                        onValueChange={(e) => this.onTextChange("minQtyOrder", e.value, e)}
                                        className='form-control text-md-right'
                                        fixedDecimalScale={true}
                                        //decimalScale={2}                                       
                                        thousandSeparator={true}
                                    //prefix={'$'} 
                                    />

                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="unitPrice">{"Contract Price"}</Label>

                                    <NumberFormat
                                        value={selSupplierLink.unitPrice}
                                        onValueChange={(e) => this.onTextChange("unitPrice", e.value, e)}
                                        className='form-control text-md-right'
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        //displayType={'text'} 
                                        //disabled={isFormReadOnly}
                                        thousandSeparator={true}
                                        prefix={'$'} />


                                </FormGroup>
                            </Col>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="uom">UOM</Label>
                                    <Select
                                        options={uomList}
                                        name='uom'
                                        id='uom'
                                        defaultValue={this.getSelUOM(selSupplierLink.uomKey)}
                                        value={this.getSelUOM(selSupplierLink.uomKey)}
                                        onChange={(e) => this.onTextChange("uomKey", e, e)}
                                        isClearable={true}></Select>
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="comments">Comments</Label>

                                    <Input type="textarea" name="lineComments"
                                        rows={3}
                                        //disabled={this.isFormReadOnly()}
                                        id="lineComments" placeholder="Comments"
                                        value={selSupplierLink.comments}
                                        defaultValue={selSupplierLink.comments}
                                        onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>
                        <ColoredLine color="grey" />
                        <FormGroup>
                            <Row style={{ margin: "15px 0 0 0" }}>
                                <Col sm={12}>
                                    <Button outline
                                        color="warning" className="float-right"
                                        onClick={() => this.toggleModalLineAdd()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button outline
                                        color="primary" className="float-right"
                                        style={{ margin: "0px 10px 0px 0px" }}
                                        onClick={() => this.supplierLinkAdd(true)}
                                        hidden = {itemSupplierLinkKey > 0}
                                    >

                                        {"Add & Close"}
                                    </Button>

                                    <Button outline  //disabled={this.isLineAddButtonDisable()}
                                        color="primary" className="float-left"
                                        onClick={() => this.supplierLinkAdd(false)}
                                        hidden = {itemSupplierLinkKey > 0}
                                    >
                                        Add
                                    </Button>

                                    <Button outline  //disabled={this.isLineAddButtonDisable()}
                                        color="primary" className="float-left"
                                        onClick={() => this.supplierLinkEdit(false)}
                                        hidden = {itemSupplierLinkKey < 0}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>

                        </FormGroup>
                    </ModalBody>
                </Modal>
            </div>
        </div>)
    }
} 