

//export const apiURL = "https://wbrapi.azurewebsites.net/api/"
//export const apiURL = "https://wbrtestapi.azurewebsites.net/api/"



//export const apiURL = "http://localhost:58000/api/"
//export const apiURL = "http://localhost:58684/api/"
 
export const apiURL = process.env.REACT_APP_API_URL;
export const apiURLSvc = process.env.REACT_APP_API_URL8;
export const branchName = process.env.REACT_APP_BRANCH_NAME;
export const commitNumber = process.env.REACT_APP_COMMIT_NUMBER;
export const commitLink = process.env.REACT_APP_COMMIT_LINK;
 
export const apiKey = "WBR-API-Key"


export const  apiKeyValue = "3951c979b2a0158243bcbd67aa4c350c01b0cd67"

export const version="20230627"
/*this key use digital.license@h2obridge.com google account*/
export const REACT_APP_MAPS_API_KEY = "AIzaSyC9RDqScPqhaOmos1w0sV12g2AGRg9HX4Y"


/*Added by Hn 06-26-2023 - SyncFusion File Manager API*/
//export const apiSyncFusionAzureFileManagerURL = "http://localhost:62869/"
//export const apiSyncFusionAzureFileManagerURL = "https://wbr-filemanager-prod.azurewebsites.net/"
export const apiSyncFusionAzureFileManagerURL = process.env.REACT_APP_Sync_Fusion_FileManager_URL;

console.log("apiSyncFusionAzureFileManagerURL: " + apiSyncFusionAzureFileManagerURL)

export const apiHeader = (m) => {

    const header = {
        method: m,
        headers: {'Content-Type': 'application/json'},
        headers: {'WBR-API-Key': apiKeyValue }
    }

    const { REACT_APP_MY_ENV } = process.env;
    let test = process.env.API_URL
    console.log('Testing Test Branch');
    console.log("process.env.REACT_APP_API_URL: " + process.env.REACT_APP_API_URL)
    return header;
}


export const apiDELETEHeader = (b) => {

    const header = {
        method:  'DELETE',
        headers: {'Content-Type': 'application/json'},
        //headers: {'WBR-API-Key': apiKeyValue },
        body: JSON.stringify(b)
    }

    return header;
}

export const apiPUTHeader = (b) => {

    const header = {
        method:  'PUT',
        headers: {'Content-Type': 'application/json'},
        //headers: {'WBR-API-Key': apiKeyValue },
        body: JSON.stringify(b)
    }

    return header;
}

export const apiPOSTHeader = (b) => {

    const header = {
        method:  'POST',
        headers: {'Content-Type': 'application/json'},
        //headers: {'WBR-API-Key': apiKeyValue },
        body: JSON.stringify(b)
    }

    return header;
}


export const apiGETHeader = (b) => {

    const header = {
        method:  'GET',
        headers: {'Content-Type': 'application/json'},
        //headers: {'WBR-API-Key': apiKeyValue },
        body: JSON.stringify(b)
    }

    return header;
}

