
import React, { useState, useEffect } from 'react';
import { Col, Row, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText, FormFeedback } from 'reactstrap';
import { apiURL, apiHeader } from '../../resources/apiURL';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import { Form, CustomInput, Container } from 'reactstrap';
import Select from 'react-select';


const SafetyIncidentNew = (props) => {

    // const {buttonColor,
    //       buttonLabel,
    //       className,
    //       formName,
    //       facilityEventKey} = props

    // var ddtyp;
    // var ddloc;
    var desc;


    const [modal, setModal] = useState(false);
    const [invalid, setInvalid] = useState(false)
    const [invalidLoc, setInvalidLoc] = useState(false)
    const [loc, setLoc] = useState("")
    const [locValue, setLocValue] = useState("")
    const [comment, setComment] = useState("")
    const [ddtyp, setDdtyp] = useState("")
    const [ddLoc, setDdLoc] = useState("")

    useEffect(() => {
        // if (props.typeDefaultValue !== null) setDdtyp(props.typeDefaultValue)
        if (!props.isSafIncAdmin) setDdtyp(7)

        getLoc()
    }, []);
    // },[props.UserGroupKey])


    const toggle = () => setModal(!modal);

    const toggleOK = () => {
        if (comment === "" || loc === "") {
            if (comment === "") { setInvalid(true) }
            if (loc === "") { setInvalidLoc(true) }
        } else {
            setModal(!modal)
            // props.onClickOK(ddKey.value)
            props.onClickOK(ddtyp, loc, comment)
        }


    }


    const onTextChange = (fieldName, fieldValue, e) => {
        switch (fieldName) {



            case "type":
                // ddtyp = fieldValue;
                setDdtyp(fieldValue)
                break;
            case "location":
                // ddloc = fieldValue;
                if (fieldValue != null) {
                    setLoc(fieldValue.value)
                    setLocValue(fieldValue)
                    setInvalidLoc(fieldValue === "")
                }
                else {
                    setLoc("")
                    setLocValue("")
                    setInvalidLoc(true)

                }
                break;
            case "description":
                desc = fieldValue;
                setComment(fieldValue)
                setInvalid(fieldValue === "")
                break;




            default:
        }


    }


    const getLoc = () => {

        let myURI = apiURL + 'SafetyIncident/LocationsPlusOther'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // setDdLoc(createLocList(json))
                if (props.isSafIncAdmin) {
                    setDdLoc(createLocList(json));
                } else {
                    const filteredJson = json.filter(item => item.facilityKey > 0);
                    setDdLoc(createLocList(filteredJson));
                }


            });

        console.log(ddLoc);

    }

    const createLocList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.facilityKey
            e.label = e.facilityName
            tempList.push(e)
        })

        return tempList
    }


    const onClosed = () => {
        setComment("")
        setInvalid(false)
        setLoc("")
        setLocValue("")
        setInvalidLoc(false)
        // if (props.onClosed !== undefined) {
        //     props.onClosed();
        // }
    }




    return (
        <span>
            {/* <Button 
                    // outline = {facilityEventKey > 0 ? false : true} 
                    // color={buttonColor}
                    onClick={toggle} style={{ margin: "0 5px 0 5px" }} >
                Add New Report
            </Button> */}
            <Button outline color="secondary"
                className="btn-no-border"
                onClick={toggle}

            >
                <img
                    src={Plus}
                    alt="new"
                    style={{ width: "25px", margin: "0 5px 0 0" }} />
                New
            </Button>
            <Modal isOpen={modal}
                toggle={toggle}
                onClosed={onClosed}
            >
                <ModalHeader toggle={toggle}>
                    {props.isSafIncAdmin ? "New Incident" : "New JSA"}
                </ModalHeader>

                <ModalBody>

                    <div>

                        <FormGroup row>
                            <Col md={12}>



                                {/* 1 row */}
                                <FormGroup row>

                                    <Col md={3}>
                                        <Label for="location">Location</Label>
                                    </Col>
                                    <Col md={9}>


                                        {/* <CustomInput
                                            type="select"
                                            id="locKey"
                                            name="locKey"
                                            invalid={invalidLoc}
                                            //defaultValue={2}
                                            //value={2}
                                            //disabled={true}        //{isReadOnly || (safetyObservationKey<0)}
                                            onChange={(e) => { onTextChange("location", e.target.value, e) }}
                                        >
                                            <option value=""></option>

                                            {props.ddloc.map(v => <option value={v.facilityKey}>{v.facilityName}</option>)}
                                        </CustomInput> */}
                                        <Select options={ddLoc}
                                            className={(invalidLoc ? "form-error" : "")}
                                            // isDisabled={isFormReadOnly}
                                            value={locValue}
                                            // defaultValue={this.getSelInvLoc(rcvrDetail[0].toLocationKey)}
                                            onChange={(e) => onTextChange("location", e, e)}
                                            isClearable={true}
                                        >
                                        </Select>




                                    </Col>
                                </FormGroup>
                                <FormGroup row hidden={!props.isSafIncAdmin}>

                                    <Col md={3}>
                                        <Label for="Type">Type</Label>
                                    </Col>
                                    <Col md={9}>


                                        <CustomInput
                                            type="select"
                                            id="ddKey"
                                            name="ddKey"
                                            defaultValue={ddtyp}
                                            // value={props.typeDefaultValue}setDdtyp(fieldValue)
                                            // value={props.typeDefaultValue}
                                            //defaultValue={7}
                                            disabled={!props.isSafIncAdmin}        //{isReadOnly || (safetyObservationKey<0)}
                                            onChange={(e) => { onTextChange("type", e.target.value, e) }}
                                        >
                                            <option value=""></option>

                                            {props.ddropdown.map(v => <option value={v.typeKey}>{v.typeDesc}</option>)}
                                        </CustomInput>




                                    </Col>
                                </FormGroup>
                                <FormGroup row>

                                    <Col md={3}>
                                        <Label for="description">Description</Label>
                                    </Col>
                                    <Col md={9}>

                                        <Input
                                            // invalid={!validateDataItem.hazardDesc}
                                            invalid={invalid}
                                            type="textarea" name="description" rows={5}
                                            id="description"
                                            placeholder="please enter something here"
                                            // value={data[0].comments}
                                            // onChange={(e) => this.onTextChange("description", e.target.value, e)}
                                            onChange={(e) => { onTextChange("description", e.target.value, e) }}
                                        // defaultValue = {observation[0].hazardDesc}                               
                                        // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                        // disabled={isReadOnly} 

                                        />





                                    </Col>
                                </FormGroup>













                            </Col>


                        </FormGroup>


                    </div>


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleOK}>OK</Button>
                    <Button outline color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </span>
    );
}

export default SafetyIncidentNew;