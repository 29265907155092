

import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
//import AFECloseoutSC from './AFECloseoutSC'
// import AFECloseoutDept from './AFECloseoutDept'
import AFECloseoutChk from './AFECloseoutChk'

import { ComponentRenderer } from 'igniteui-react-core';
var DatePicker = require("reactstrap-date-picker");

export class AFECloseOutPopUp extends Component {
    static displayName = AFECloseOutPopUp.name;

    constructor(props) {
        super(props)

        this.state = {
            formName: props.formName,
            message: props.message,
            buttonLabel: props.buttonLabel,
            id: props.id,
            commentPrompMsg: props.commentPrompMsg,
            modal: false,
            isValid: true,
            afeData: props.afeData,
            hidden: props.hidden,
            showAlert: false,
            alertMsg: '',
            afeHeaderKey: props.afeHeaderKey,
            user: props.user,
            closeoutChk: [],
            formMode: props.formMode, /*1=Actual Start, 2=CheckList */
            budgetActualValidationComment: "",
            budgetActualValidationHardstop: 0,
            budgetActualValidatedLoaded: (props.formMode === "2", false, true),
            validateDataItem: {
                actualStartDate: true,
                actualEndDate: true,
                inServiceDate: true
            }
        }
    }

    toggleAlert = () => {

        // this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggle = () => {
        console.log("Click")

        if (this.state.formMode === "2" && !this.state.modal) {
            this.setState({ modal: !this.state.modal,
                            budgetActualValidatedLoaded: false})
            this.getActualBudgetCommentValidation()
            this.getCloseoutSC()
            
        }
        else{
            this.setState({ modal: !this.state.modal })
        }
    }

    getCloseoutSC = () => {
        const { afeHeaderKey } = this.state

        let myURI = apiURL + 'AFE/closeout/CHKL/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    closeoutChk: json
                })
            });
    }

    getActualBudgetCommentValidation = () => {
        const { afeHeaderKey } = this.state
        var bPass = true
        let myURI = apiURL + 'AFE/closeout/Validate/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    this.setState({
                        budgetActualValidationComment: json[0].validationErrorMsg,
                        budgetActualValidationHardstop: json[0].hardStop,
                        isValid: !(json[0].hardStop!==0 && json[0].validationErrorMsg!==""),
                        budgetActualValidatedLoaded:true
                    })

                    console.log("json Validate")
                    console.log(json)

                }else{
                    this.setState({
                        budgetActualValidationComment:"",
                        budgetActualValidationHardstop:false,
                        isValid: true,
                        budgetActualValidatedLoaded:true
                    })

                }
            });
    }




    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData, validateDataItem } = this.state
        var showAlert = false
        switch (fieldName) {
            case "actualStartDate":
                afeData[0].actualStartDate = fieldValue
                validateDataItem.actualStartDate = true
                break;
            case "actualEndDate":
                afeData[0].actualEndDate = fieldValue
                validateDataItem.actualEndDate = true
                break;
            case "inServiceDate":
                afeData[0].inServiceDate = fieldValue
                validateDataItem.inServiceDate = true
                break;
            default:
                break;
        }

        this.setState({
            afeData: afeData,
            validateDataItem: validateDataItem,
            showAlert: false
        })
    }

    setIsValid = (v) => {
        this.setState({ isValid: v })
    }

    toggleYes = () => {
        const { afeData, validateDataItem, formMode } = this.state
        var isValid = true
        var sErrMsg = ''

        if (formMode === "1") {
            if (afeData[0].actualStartDate === null || afeData[0].actualStartDate === undefined) {
                validateDataItem.actualStartDate = false
                isValid = false
                sErrMsg = sErrMsg + "Actual Start Date"
            }

            if (afeData[0].actualEndDate === null || afeData[0].actualEndDate === undefined) {
                validateDataItem.actualEndDate = false
                isValid = false
                if (sErrMsg !== '') { sErrMsg = sErrMsg + ', ' }
                sErrMsg = sErrMsg + "Actual End Date"
            }

            if (afeData[0].inServiceDate === null || afeData[0].inServiceDate === undefined) {
                validateDataItem.inServiceDate = false
                isValid = false
                if (sErrMsg !== '') { sErrMsg = sErrMsg + ', ' }
                sErrMsg = sErrMsg + "In-Service Date"
            }


            if (isValid) {
                if (afeData[0].actualEndDate < afeData[0].actualStartDate) {
                    isValid = false
                    sErrMsg = "Error: Actual End Date is less than Actual Start Date."
                }
            } else {
                sErrMsg = 'Missing: ' + sErrMsg
            }
        }
        else if (formMode === "3") {
            if (afeData[0].actualStartDate === null || afeData[0].actualStartDate === undefined) {
                validateDataItem.actualStartDate = false
                isValid = false
                sErrMsg = sErrMsg + "Actual Start Date"
            }
        } else {
            const { closeoutChk,budgetActualValidationComment,budgetActualValidationHardstop } = this.state

            if (budgetActualValidationComment!==""){
                isValid = false
                sErrMsg=""
            }
            else{
            closeoutChk.map(k => {
                if ((k.miscValue1 ?? "") === "" || k.miscValue1 === "-1") {
                    isValid = false
                    sErrMsg = "Please answer 'Yes' or 'No' on all item(s)."
                    return
                }

                if (k.miscValue1 === "No" && (k.lineID ?? "") === "CHKL1") {
                    isValid = false
                    sErrMsg = "Please make sure all material(s) have been return to warehouse then try again."
                    return
                }

                if (k.miscValue1 === "No" && (k.comments ?? "") === "") {
                    isValid = false
                    sErrMsg = "Please enter comments for 'No' item(s)."
                    return
                }



            })
            }
        }

        // isValid=false 

        if (isValid) {
            this.toggle()
            this.setState({ showAlert: false })
            this.props.onClickYes("");
        } else {
            this.setState({
                isValid: isValid,
                validateDataItem: validateDataItem,
                showAlert: true,
                alertMsg: sErrMsg
            })

        }

    }

    chkUpdate = (e) => {
        console.log("closeoutChk")
        console.log(e)
        this.setState({ closeoutChk: e })
    }


    componentDidMount() {
        if (this.state.formMode === "2") {
            this.getActualBudgetCommentValidation()
            this.getCloseoutSC()
        }
    }

    render() {
        const { formName, message, image, buttonLabel,
            afeData, hidden, alertMsg, showAlert, budgetActualValidationComment,budgetActualValidationHardstop,
            validateDataItem, modal, isValid, afeHeaderKey,budgetActualValidatedLoaded,
            formMode, user } = this.state
        
        if (!budgetActualValidatedLoaded){
            return <div>Loading Data Validation.....</div>
        }
        return (<div>

            <Button outline
                color="success"
                hidden={hidden}
                onClick={this.toggle} style={{ margin: "0 5px 0 5px" }} >

                {buttonLabel}

            </Button>

            <Modal isOpen={modal}
                toggle={this.toggle}
                size={(formMode === "2" ? "xl" : "md")}
                onClosed={this.onClosed} >
                <ModalHeader toggle={this.toggle}> {formName}</ModalHeader>
                <ModalBody>
                    <div>
                        <Alert color="danger" isOpen={showAlert}
                            toggle={() => this.toggleAlert()}
                            style={{ marginTop: "5px", marginBottom: "10px" }}
                        >
                            {alertMsg}
                        </Alert>
                    </div>

                    {message}

                    <FormGroup
                        hidden={(formMode !== "1" && formMode !== "3")}>
                        <Label for="actualStartDate">Actual Start Date</Label>

                        <DatePicker value={afeData[0].actualStartDate}
                            className={(!validateDataItem.actualStartDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("actualStartDate", v, f)}
                        // disabled={isFormReadOnly}
                        />

                    </FormGroup>

                    <FormGroup //style={{ marginBottom: "-10px" }}
                        hidden={(formMode !== "1")}>

                        <Label invalid={false} for="actualEndDate">Actual End Date</Label>
                        <DatePicker value={afeData[0].actualEndDate}
                            className={(!validateDataItem.actualEndDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("actualEndDate", v, f)}
                        //disabled={isFormReadOnly}
                        />
                    </FormGroup>

                    <FormGroup style={{ marginTop: "5px" }}
                        hidden={(formMode !== "1")}
                    >

                        <Label invalid={false} for="inServiceDate">In-Service Date</Label>
                        <DatePicker value={afeData[0].inServiceDate}
                            className={(!validateDataItem.inServiceDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("inServiceDate", v, f)}
                        //disabled={isFormReadOnly}
                        />
                    </FormGroup>
                    {/* && formMode !== "2" && budgetActualValidationComment==="" */}
                    <FormGroup style={{ marginBottom: "-10px" }}
                        hidden= {!(formMode === "2" && budgetActualValidationComment==="")}>                            
                        <AFECloseoutChk
                            afeHeaderKey={afeHeaderKey}
                            isFormReadOnly={false}
                            user={user}
                            chkUpdate={e => this.chkUpdate(e)}
                        >
                        </AFECloseoutChk>
                    </FormGroup>

                    
                    <FormGroup style={{ marginBottom: "0px" }}
                        hidden={!(formMode === "2" && budgetActualValidationComment !== "")}>                            
                        <Label>
                        {budgetActualValidationComment}
                        </Label>                            
                    </FormGroup>


                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={this.toggleYes}  hidden={(formMode === "2" && budgetActualValidationComment !== "")} >OK</Button>
                    <Button color="primary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>)
    }
}

export default class AFECloseout extends Component {
    static displayName = AFECloseout.name;

    constructor(props) {
        super(props)

        this.state = {
            afeHeaderKey: props.afeHeaderKey,
            user: props.user
        }

    }

    render() {
        const { afeHeaderKey, user } = this.state
        return (<div>
            <FormGroup>
                <Form>
                    <AFECloseoutChk
                        afeHeaderKey={afeHeaderKey}
                        isFormReadOnly={false}
                        user={user}
                    >
                    </AFECloseoutChk>
                </Form>
            </FormGroup>
        </div>)
    }
}