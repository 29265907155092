
import React, { Component } from 'react';

import { Form, Card, CardHeader, CardBody, Col, Row, FormGroup, Label, Input, CustomInput, Button } from 'reactstrap';
import { apiURL, apiHeader } from '../../resources/apiURL';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import '../../custom.css';

import { getUserADAccount } from '../../resources/utility'
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";

import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import classnames from 'classnames';
import Select from 'react-select';
import FormErgoFollowUp from './FormErgoFollowUp';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



export default class FormErgo extends Component {
    static displayName = FormErgo.name;


    constructor(props) {
        super(props);
        // this.onGridRef = this.onGridRef.bind(this);
        // this.getAlert=this.getAlert.bind(this);


        // const urlPara = this.getURLParameters(props.location)
        // console.log("loc search")
        // console.log(this.props.location.search)

        this.state = {
            childValue: '',
            data: [],
            hdrData: [],
            isLoaded: false,
            isHdrLoaded: false,
            // facilityInspLineItemForUpdateDTO:[],
            // facilityComments:'',
            // inspComments:'',
            reloadForm: false,
            safIncHeaderKey: 0,
            // safIncLineKey: urlPara.safIncLineKey,
            safIncLineKey: props.safIncLineKey,
            ddEmployees: [],
            activeTab: '1',
            isddEmployeesLoaded: false,
            isReadOnly: props.isReadOnly,
            user: props.user,

            ddErgo: [],
            isddErgoLoaded: false,

            equList: [],
            isEquDDLoaded: false,



            modalMsg: false,
            msgHeader: "",
            msgBody: "",

          

        }
    }

    // getURLParameters = (inputPara) => {
    //   var paraMeters = {
    //     safIncLineKey: null
    //   }

    //   var query
    //   query = new URLSearchParams(this.props.location.search);
    //   console.log("query")
    //   // console.log(query.get('safIncLineKey'))

    //   paraMeters = {
    //     safIncLineKey: query.get('safIncLineKey')
    //   }
    //   return paraMeters



    // }


    getEquString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.equipmentID
        })

        return grpKey;
    }



    getEquDD = () => {
        let myURI = apiURL + 'SafetyIncident/GetSafIncErgoEquipment'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isEquDDLoaded: true,
                    equList: this.createEquList(json)
                })
            });
    }

    createEquList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.equipmentID
            e.label = e.equipmentID
            tempList.push(e)
        })

        return tempList
    }

    getSelEqu = (value) => {
        const { equList } = this.state

        if (value !== null && value !== "") {
            return equList.filter(e => value.includes(e.equipmentID))
        }
        return ""
    }


    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }


    getddErgo = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetSafIncErgoItems'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isddErgoLoaded: true,
                    ddErgo: json
                })
            });
    }



    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.employeeKey
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }

    getSelEmp = (v) => {
        const { ddEmployees } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return ddEmployees.filter(e => e.employeeKey === Number(v));
        }
        return ""

    }



    bMultiField = (value, checkValue) => {
        return (value & checkValue) === checkValue
    }


    getInspHdrData = (intKey) => {
        const { isLoaded } = this.state

        // console.log("safIncHeaderKey")
        // console.log(intKey)

        let myURI = apiURL + 'SafetyIncident/SafIncHeaderKey/' + intKey
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    hdrData: json,
                    isHdrLoaded: true
                });
                // console.log(this.state.hdrData);
            });

    }

    getInpsData = () => {
        const { safIncLineKey, isLoaded } = this.state
        let myURI = apiURL + 'SafetyIncident/FormErgoKey/' + safIncLineKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    this.getInspHdrData(json[0].safIncHeaderKey)

                }

                this.setState({
                    data: json,
                    isLoaded: true,


                    //safIncHeaderKey:json[0].safIncHeaderKey
                    // inspComments:json[0].inspComments,
                    // facInspComments:json[0].facInspComments
                }
                );


                // console.log("---------")
                // console.log(this.state.data)

                // this.getInspHdrData(json[0].safIncHeaderKey)


            });
    }


    getddEmployees = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyObservation/SubmitBy'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isddEmployeesLoaded: true,
                    ddEmployees: json
                })
            });
    }

    




    // onGridRef(grid) {
    //   if (!grid) {
    //     return;
    //   }
    //   this.grid = grid;
    //   this.grid.dataSource = this.state.tblWitness;

    //   //this.grid.cells[0]
    //   console.log(this.grid.cellBackground)

    // }



    componentDidMount() {
        this.props.saveErgo(this.saveData);
        this.getInpsData()
        this.getddEmployees()
        this.getddErgo()
        this.getEquDD()
        //this.getInspHdrData()

    }


    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.lineStatusKey === 4) {
                content.style.background = "#b6a065";
            } else if (item.lineStatusKey === 6) {
                content.style.background = "#61a066";
            } else if (item.lineStatusKey === 3 || item.lineStatusKey === 5) {
                content.style.background = "#ad5f55";
            } else {
                content.style.background = "transparent";
            }
        }


        switch (s.field) {
            case 'name':
                link.textContent = item.name;
                break;
            case 'position':
                link.textContent = item.position;
                break;


            default:

        }

    }







    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const { data } = this.state;

        // /cbincEquipmentDamage
        //incClassif

        switch (fieldName) {

            case "incDateTime":


                if (moment(fieldValue).isValid() === true) {
                    // data[0].incDateTime = moment(fieldValue).format();
                    data[0].incDateTime = fieldValue;
                }
                else {
                    data[0].incDateTime = null;
                }



                break;

            case "comments":
                data[0].comments = fieldValue
                break;

            case "employeeKey":
                if (fieldValue != null) {

                    data[0].employeeKey = fieldValue.value
                    data[0].supervisorKey = fieldValue.managerKey

                } else {
                    data[0].employeeKey = null

                }
                break;

            case "location":
                data[0].location = fieldValue
                break;


            case "supervisorKey":
                if (fieldValue != null) {

                    data[0].supervisorKey = fieldValue.value
                } else {
                    data[0].supervisorKey = null

                }
                break;


            case "evaluatorKey":
                if (fieldValue != null) {

                    data[0].evaluatorKey = fieldValue.value
                } else {
                    data[0].evaluatorKey = null

                }
                break;


            case "eqListExisting":
                data[0].eqListExisting = this.getEquString(e)
                break;
            case "eqListRecommended":
                data[0].eqListRecommended = this.getEquString(e)
                break;




            case "cwHeight":
                data[0].cwHeight = fieldValue
                break;
            case "cwNoMonitor":
                const reg = /^[0-9\b]+$/
                let preval = fieldValue
                if (fieldValue === '' || reg.test(fieldValue)) { }
                else fieldValue = preval.substring(0, (preval.length - 1))




                data[0].cwNoMonitor = fieldValue;


                break;
            case "cwSitStand":
                // var zz = e.target.checked;
                // var dd = e.target.id;
                // data[0].cwSitStand = e.target.checked ? 1 : 0

                if (fieldValue === data[0].cwSitStand) {
                    data[0].cwSitStand = null
                }
                else { data[0].cwSitStand = fieldValue }


                break;
            case "cwMonitorHeight":
                data[0].cwMonitorHeight = fieldValue
                break;
            case "cwHeightElbow":
                data[0].cwHeightElbow = fieldValue
                break;
            case "cwChair":
                data[0].cwChair = fieldValue
                break;

            case "recHeight":
                data[0].recHeight = fieldValue
                break;
            case "recSitStand":

                // data[0].recSitStand = e.target.checked ? 1 : 0

                if (fieldValue === data[0].recSitStand) {
                    data[0].recSitStand = null
                }
                else { data[0].recSitStand = fieldValue }


                break;
            // if (e.target.checked)
            //     data[0].recSitStand = 1
            // else
            //     data[0].recSitStand = 0
            // break;
            case "recSitStandHeight":
                data[0].recSitStandHeight = fieldValue
                break;
            case "recMonitorHeight":
                data[0].recMonitorHeight = fieldValue
                break;
            case "recHeightElbow":
                data[0].recHeightElbow = fieldValue
                break;
            case "recChair":
                data[0].recChair = fieldValue
                break;

            case "symptoms":
                if (fieldValue === data[0].symptoms) {
                    data[0].symptoms = null
                }
                else { data[0].symptoms = fieldValue }

                //validateDataItem.riskInvolvePeopleValue=true
                break;
            case "sympotomsText":
                data[0].sympotomsText = fieldValue
                break;

            case "confidentalUsed":
                if (fieldValue === data[0].confidentalUsed) {
                    data[0].confidentalUsed = null
                }
                else { data[0].confidentalUsed = fieldValue }

                //validateDataItem.riskInvolvePeopleValue=true
                break;

            case "itemsFeet":
                data[0].itemsFeet = fieldValue
                break;
            case "itemsKnees":
                data[0].itemsKnees = fieldValue
                break;
            case "itemsThighs":
                data[0].itemsThighs = fieldValue
                break;
            case "itemsElbows":
                data[0].itemsElbows = fieldValue
                break;
            case "itemsBack":
                data[0].itemsBack = fieldValue
                break;
            case "itemsChair1":
                data[0].itemsChair1 = fieldValue
                break;
            case "itemsChair2":
                data[0].itemsChair2 = fieldValue
                break;
            case "itemsArms":
                data[0].itemsArms = fieldValue
                break;
            case "itemsShoulders":
                data[0].itemsShoulders = fieldValue
                break;
            case "itemsWrists":
                data[0].itemsWrists = fieldValue
                break;
            case "itemsFingers":
                data[0].itemsFingers = fieldValue
                break;
            case "itemsKeyboard":
                data[0].itemsKeyboard = fieldValue
                break;
            case "itemsMouse":
                data[0].itemsMouse = fieldValue
                break;
            case "itemsHead":
                data[0].itemsHead = fieldValue
                break;
            case "itemsMonitor":
                data[0].itemsMonitor = fieldValue
                break;
            case "itemsDesk":
                data[0].itemsDesk = fieldValue
                break;
            case "itemsBreaks":
                data[0].itemsBreaks = fieldValue
                break;
            case "itemsExcercise":
                data[0].itemsExcercise = fieldValue
                break;

            case "recommendations":
                data[0].recommendations = fieldValue
                break;


            // case "followupDateTime":
            //     if (moment(fieldValue).isValid() === true) {
            //         data[0].followupDateTime = moment(fieldValue).format();
            //     }
            //     else {
            //         data[0].followupDateTime = null;
            //     }



            //     break;


            // case "followupDesc":
            //     data[0].followupDesc = fieldValue
            //     break;








            default:

        }

        this.setState({ data: data })
    }



    createDataForUpdateDTO = () => {
        const { data } = this.state
        const { user } = this.props

        const dataForUpdateDTO = {
            "FormErgoKey": data[0].formErgoKey,
            //"ModifiedByKey": 580, /*4=Submitted, 6=Reviewed*/
            // "StatusKey": data[0].lineStatusKey,
            "ModifiedByAD": getUserADAccount(user.account.userName),
            // "IncDateTime": data[0].incDateTime,
            "IncDateTime": data[0].incDateTime ? moment(data[0].incDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
            "Comments": data[0].comments,
            "EmployeeKey": data[0].employeeKey,
            "Location": data[0].location,
            "Phone": data[0].phone,
            "SupervisorKey": data[0].supervisorKey,
            "EvaluatorKey": data[0].evaluatorKey,
            "EqListExisting": data[0].eqListExisting,
            "EqListRecommended": data[0].eqListRecommended,
            "CWHeight": data[0].cwHeight,
            "CWNoMonitor": data[0].cwNoMonitor,
            "CWSitStand": data[0].cwSitStand,
            "CWMonitorHeight": data[0].cwMonitorHeight,
            "CWHeightElbow": data[0].cwHeightElbow,
            "CWChair": data[0].cwChair,
            "RecHeight": data[0].recHeight,
            "RecSitStand": data[0].recSitStand,
            "RecSitStandHeight": data[0].recSitStandHeight,
            "RecMonitorHeight": data[0].recMonitorHeight,
            "RecHeightElbow": data[0].recHeightElbow,
            "RecChair": data[0].recChair,
            "Symptoms": data[0].symptoms,
            "SympotomsText": data[0].sympotomsText,
            "ConfidentalUsed": data[0].confidentalUsed,
            "ItemsFeet": data[0].itemsFeet,
            "ItemsKnees": data[0].itemsKnees,
            "ItemsThighs": data[0].itemsThighs,
            "ItemsElbows": data[0].itemsElbows,
            "ItemsBack": data[0].itemsBack,
            "ItemsChair1": data[0].itemsChair1,
            "ItemsChair2": data[0].itemsChair2,
            "ItemsArms": data[0].itemsArms,
            "ItemsShoulders": data[0].itemsShoulders,
            "ItemsWrists": data[0].itemsWrists,
            "ItemsFingers": data[0].itemsFingers,
            "ItemsKeyboard": data[0].itemsKeyboard,
            "ItemsMouse": data[0].itemsMouse,
            "ItemsHead": data[0].itemsHead,
            "ItemsMonitor": data[0].itemsMonitor,
            "ItemsDesk": data[0].itemsDesk,
            "ItemsBreaks": data[0].itemsBreaks,
            "ItemsExcercise": data[0].itemsExcercise,
            "Recommendations": data[0].recommendations
            // "FollowupDateTime": data[0].followupDateTime,
            // "FollowupDesc": data[0].followupDesc,





        }

        return dataForUpdateDTO;
    }


    saveData = () => {
        const itemDTO = this.createDataForUpdateDTO();
        // console.log(itemDTO);
        // alert("spill Report");

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'SafetyIncident/updateSafIncErgo'
        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                }

                // console.log("No error here: " + rspData)

                this.getInpsData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }


    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }


    render() {

        // var {isLoaded, data, isHdrLoaded, hdrData} = this.state
        var { isLoaded, isHdrLoaded,
            data, hdrData,
            ddEmployees, isddEmployeesLoaded, isReadOnly, activeTab, safIncLineKey, user,
            ddErgo, isddErgoLoaded, msgHeader, msgBody, equList, isEquDDLoaded, 


        } = this.state

        if (!isLoaded) return <h1> loading... </h1>
        if (!isHdrLoaded) return <h1> loading hdr... </h1>

        if (!isddEmployeesLoaded) return <h1> loading Employees... </h1>
        if (!isddErgoLoaded) return <h1> loading Ergos... </h1>
        if (!isEquDDLoaded) return <h1> loading Equipment... </h1>
        



        //if (!isLoaded) return <h1> loading </h1> 

        return (
            <div>
                {/* <Form>
                <FormGroup>
                  <Row form>
                    <h3> First Report </h3>
                  </Row>
                  <h5>
                  <label>{hdrData[0].safIncID}</label>  <span>&nbsp;&nbsp;</span>
                  <label>| Line: {data[0].sLineNo} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| Status: {data[0].lineStatusDesc} </label>  <span>&nbsp;&nbsp;</span>                    
                    <label>| By: {data[0].createdByAD} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| Created: {new Date(data[0].createdDateTime).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                   
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup>

            </Form> */}
                <Form>
                    {/* <FormGroup row>
            <Col md={2}>
              <h3> Spill Report </h3>
            </Col>
            <Col md={2}>

              <Button color="primary"
                // className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}

              //  hidden={this.setIsReadOnly(statusKey)}
                onClick={() => this.saveData()}
                disabled={isReadOnly}
              >Save</Button>



            </Col>

          </FormGroup> */}
                    {/* <FormGroup row>
                        <Col md={12}>
                            <div>
                                <Label>

                                    ddddd {data[0].statusKey} {user} isReadOnly:<b>{isReadOnly ? "true" : "false"}</b>   isUser:<b>{isUser ? "true" : "false"}</b>   isAdmin:<b>{isAdmin ? "true" : "false"}</b>    isApprover:<b>{isApprover ? "true" : "false"}</b>  isEditApprover:<b>{isEditApprover ? "true" : "false"}</b>  
                                    dddd5 {data[0].formRCAKey}
                                </Label>


                            </div>
                        </Col>

                    </FormGroup> */}
                    {/* <FormGroup row>

                        <Col md={6}>


                            <Button color="primary"
                                className="float-left"
                                style={{ margin: "0px 10px 0px 0px" }}
                                hidden={(isReadOnly)}

                                //  hidden={this.setIsReadOnly(statusKey)}
                                onClick={() => this.saveData("")}>Save</Button>
                        </Col>
                    </FormGroup> */}


                    <FormGroup row>

                        <Col md={6}>
                            <Label for="employeeKey">Employee</Label>


                            <Select options={this.createEmpList(ddEmployees)}
                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                // styles={customStyles}

                                isDisabled={isReadOnly}
                                id="employeeKey"
                                name="employeeKey"
                                value={this.getSelEmp(data[0].employeeKey)}
                                // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                onChange={(e) => this.onTextChange("employeeKey", e, e)}
                                isClearable={true}

                            >
                            </Select>
                        </Col>
                        {/* <Col md={2}>
                        </Col> */}
                        <Col md={6}>
                            <Label for="location">Location</Label>
                            <Input type="text" name="location"
                                id="location"
                                disabled={isReadOnly}
                                value={data[0].location}
                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                onChange={(e) => this.onTextChange("location", e.target.value, e)}

                            />


                        </Col>



                    </FormGroup>

                    <FormGroup row>

                        <Col md={6}>
                            <Label for="supervisorKey">Supervisor</Label>


                            <Select options={this.createEmpList(ddEmployees)}
                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                // styles={customStyles}

                                isDisabled={isReadOnly}
                                id="supervisorKey"
                                name="supervisorKey"
                                value={this.getSelEmp(data[0].supervisorKey)}
                                // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                onChange={(e) => this.onTextChange("supervisorKey", e, e)}
                                isClearable={true}

                            >
                            </Select>
                        </Col>
                        {/* <Col md={2}>
                        </Col> */}
                        <Col md={6}>
                            <Label for="evaluatorKey">Evaluator</Label>


                            <Select options={this.createEmpList(ddEmployees)}
                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                // styles={customStyles}

                                isDisabled={isReadOnly}
                                id="evaluatorKey"
                                name="evaluatorKey"
                                value={this.getSelEmp(data[0].evaluatorKey)}
                                // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                onChange={(e) => this.onTextChange("evaluatorKey", e, e)}
                                isClearable={true}

                            >
                            </Select>
                        </Col>

                    </FormGroup>

            


                    <FormGroup row>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label for="incDateTime">Evaluation Datetime</Label>
                                </Col>
                                <Col md={8}>

                                    {/* <DatePicker id="incDateTime"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("incDateTime", v, f)}
                            value={data[0].incDateTime} /> */}
                                    <Datetime
                                        inputProps={{
                                            placeholder: "MM/DD/YYYY hh:mm",
                                            // disabled: false
                                            disabled: isReadOnly
                                        }}
                                        value={moment(data[0].incDateTime)}

                                        onChange={(v, f) => this.onTextChange("incDateTime", v, f)}
                                    />

                                </Col>

                            </Row>
                        </Col>
                        {/* <Col md={6}>
                  <Row form>
                    <Col md={4}>
                      <Label for="modifiedByAD">Modified By</Label>
                    </Col>
                    <Col md={8}>
                      <Input disabled type="text" name="modifiedByAD"
                        id="modifiedByAD" placeholder=""
                        value={data[0].modifiedByAD}
                      
                      />
                    </Col>
                  </Row>
                </Col> */}
                    </FormGroup>

                    <FormGroup row>

                        <Col md={12}>



                            <div>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                            Comments
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggleTab('2'); }}
                                        >
                                            Documents
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { this.toggleTab('3'); }}
                      >
                        Additional Details
                      </NavLink>
                    </NavItem> */}
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col md={12}>
                                                {/* <Label for="youRObserve">WHAT DID YOU OBSERVE?</Label>       */}
                                                <Input
                                                    // invalid={!validateDataItem.hazardDesc}
                                                    type="textarea" name="comments" rows={5}
                                                    id="comments"
                                                    value={data[0].comments}
                                                    disabled={isReadOnly}
                                                    onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                                // defaultValue = {observation[0].hazardDesc}                               
                                                // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                                // disabled={isReadOnly} 

                                                />


                                            </Col>


                                        </Row>
                                    </TabPane>


                                    <TabPane tabId="2">
                                        <Row>
                                            <Col md={12}>
                                                {/* <DocumentBrowser  buttonLabel="View Image" 
                                        buttonColor="primary" 
                                        formName="Safety Observation Images"     
                                        linkTableName={"thsSafetyObservation"}
                                        linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                        apiURL={apiURL + 'SafetyObservation/Documents/' + (safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey) } /> */}
                                                <DocumentBrowserDragDrop buttonLabel="View Image"
                                                    buttonColor="primary"
                                                    formName="Ergo Images"
                                                    linkTableName={"thseSafetyIncident_Ergo"}
                                                    // linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                                    linkPrimaryKey={safIncLineKey}
                                                    apiURL={apiURL + 'SafetyIncident/Documents/' + safIncLineKey + '/bucket/thseSafetyIncident_Ergo'}

                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            <Col md={12}>
                                                details tbd
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>

                            </div>



                        </Col>

                    </FormGroup>





                    <FormGroup row>
                        <Col md={6}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Current workstation"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody>
                                    {/* 1 row */}
                                    {/* <Row style={{ margin: "0 0 5px 0" }}> */}
                                    <FormGroup row>

                                        <Col md={4} lg={3}>

                                            <Input type="number" name="cwHeight"
                                                id="cwHeight"
                                                disabled={isReadOnly}
                                                value={data[0].cwHeight}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("cwHeight", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col >
                                            <Label for="cwHeight">Computer workstation height (inches)</Label>
                                        </Col>

                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>

                                            <Input type="text" name="cwNoMonitor"
                                                id="cwNoMonitor"
                                                disabled={isReadOnly}
                                                value={data[0].cwNoMonitor}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("cwNoMonitor", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col >
                                            <Label for="cwNoMonitor">Number of Monitors</Label>
                                        </Col>


                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>

                                            {/* <CustomInput
                                                type="checkbox"
                                                className="float-right"
                                                width="100%"
                                                id="cwSitStand"
                                                // label="Yes"
                                                checked={data[0].cwSitStand == 1}
                                                // inline
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("cwSitStand", 1, e)}
                                            /> */}


                                            <CustomInput
                                                type="checkbox"
                                                // width="100%"

                                                id="cwSitStandY"
                                                label="Yes"
                                                checked={data[0].cwSitStand === 1}
                                                inline
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("cwSitStand", 1, e)}
                                            />
                                            {/* </Col>
                                <Col md={3}> */}
                                            <CustomInput type="checkbox"
                                                id="cwSitStandN"
                                                // width="100%"

                                                label="No"
                                                inline
                                                checked={data[0].cwSitStand === 0}
                                                onChange={(e) => this.onTextChange("cwSitStand", 0, e)}
                                                disabled={isReadOnly}

                                            />


                                        </Col>
                                        <Col >
                                            <Label for="cwSitStand">Sit/Stand Desk</Label>
                                        </Col>


                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>

                                            <Input type="number" name="cwMonitorHeight"
                                                id="cwMonitorHeight"
                                                disabled={isReadOnly}
                                                value={data[0].cwMonitorHeight}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("cwMonitorHeight", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col>
                                            <Label for="cwMonitorHeight">Monitor height (desk top to monitor top) (inches)</Label>
                                        </Col>


                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>
                                            <Input type="number" name="cwHeightElbow"
                                                id="cwHeightElbow"
                                                disabled={isReadOnly}
                                                value={data[0].cwHeightElbow}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("cwHeightElbow", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col>
                                            <Label for="cwHeightElbow">Height from elbow to floor (inches)</Label>
                                        </Col>


                                    </FormGroup>

                                    <FormGroup row>
                                        <Col md={12}>


                                            <Label for="eqListExisting">Equipment</Label>



                                            <Select options={equList}
                                                className="alway-in-between2"
                                                isMulti
                                                value={this.getSelEqu(data[0].eqListExisting)}
                                                //  defaultValue ={this.getSelSC(itemDetail[0].spendCategory)}
                                                onChange={(e) => this.onTextChange("eqListExisting", e, e)}
                                                isClearable={true}></Select>

                                        </Col>

                                    </FormGroup>

                                    {/* 2 row */}


                                </CardBody>


                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Recommended changes for workstation"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>

                                            <Input type="number" name="recHeight"
                                                id="recHeight"
                                                disabled={isReadOnly}
                                                value={data[0].recHeight}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("recHeight", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col>
                                            <Label for="recHeight">Computer workstation height (inches)</Label>
                                        </Col>

                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>
                                            {/* <CustomInput
                                                type="checkbox"
                                                className="float-right"
                                                width="100%"
                                                id="recSitStand"
                                                // label="Yes"
                                                checked={data[0].recSitStand == 1}
                                                // inline
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("recSitStand", 1, e)}
                                            /> */}
                                            <CustomInput
                                                type="checkbox"
                                                width="100%"
                                                id="recSitStandY"
                                                label="Yes"
                                                checked={data[0].recSitStand === 1}
                                                inline
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("recSitStand", 1, e)}
                                            />
                                            {/* </Col>
                                <Col md={3}> */}
                                            <CustomInput type="checkbox"
                                                id="recSitStandN"
                                                width="100%"
                                                label="No" inline
                                                checked={data[0].recSitStand === 0}
                                                onChange={(e) => this.onTextChange("recSitStand", 0, e)}
                                                disabled={isReadOnly}

                                            />


                                        </Col>
                                        <Col>
                                            <Label for="recSitStand">Sit/Stand Desk</Label>
                                        </Col>


                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>

                                            <Input type="number" name="recSitStandHeight"
                                                id="recSitStandHeight"
                                                // disabled={isReadOnly}
                                                disabled={data[0].recSitStand === 1 ? isReadOnly : true}
                                                // value={data[0].recSitStandHeight}
                                                value={data[0].recSitStand === 1 ? data[0].recSitStandHeight : ""}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("recSitStandHeight", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col>
                                            <Label for="recSitStandHeight">if Sit/Stand Desk, height (inches)</Label>
                                        </Col>


                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>

                                            <Input type="number" name="recMonitorHeight"
                                                id="recMonitorHeight"
                                                disabled={isReadOnly}
                                                value={data[0].recMonitorHeight}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("recMonitorHeight", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col>
                                            <Label for="recMonitorHeight">Monitor height (desk top to monitor top) (inches)</Label>
                                        </Col>


                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={4} lg={3}>

                                            <Input type="number" name="recHeightElbow"
                                                id="recHeightElbow"
                                                disabled={isReadOnly}
                                                value={data[0].recHeightElbow}
                                                // value={'xxx-xxx-' + this.state.fieldValue.substring(this.state.fieldValue.length - 4)}

                                                onChange={(e) => this.onTextChange("recHeightElbow", e.target.value, e)}

                                            />

                                        </Col>
                                        <Col >
                                            <Label for="recHeightElbow">Height from elbow to floor (inches)</Label>
                                        </Col>


                                    </FormGroup>

                                    <FormGroup row>
                                        <Col md={12}>


                                            <Label for="eqListRecommended">Equipment</Label>



                                            <Select options={equList}
                                                className="alway-in-between2"
                                                isMulti
                                                value={this.getSelEqu(data[0].eqListRecommended)}
                                                //  defaultValue ={this.getSelSC(itemDetail[0].spendCategory)}
                                                onChange={(e) => this.onTextChange("eqListRecommended", e, e)}
                                                isClearable={true}></Select>

                                        </Col>

                                    </FormGroup>




                                </CardBody>


                            </Card>
                        </Col>





                        {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
                    </FormGroup>
                    <FormGroup row>

                        <Col lg={3} md={4}>
                            {/* <Row form> */}
                            {/* <Col md={12} > */}
                            <Label>Symptoms  </Label>
                            {/* </Col>
                                <Col md={6}> */}
                            <CustomInput
                                type="checkbox"
                                width="100%"
                                id="symptomsY"
                                label="Yes"
                                checked={data[0].symptoms === 1}
                                inline
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("symptoms", 1, e)}
                            />
                            {/* </Col>
                                <Col md={3}> */}
                            <CustomInput type="checkbox"
                                id="symptomsN"
                                width="100%"
                                label="No" inline
                                checked={data[0].symptoms === 0}
                                onChange={(e) => this.onTextChange("symptoms", 0, e)}
                                disabled={isReadOnly}

                            />
                            {/* </Col> */}
                            {/* </Row> */}
                        </Col>
                        {/* <Col md={1}>
                            <Label className="float-right" >Describe</Label>
                            <Label>Dexscribe... </Label>
                        </Col> */}
                        <Col lg={9} md={8}>
                            <Label>If yes, describe</Label>
                            <Input type="textarea"
                                rows={2} name="sympotomsText"
                                // className="form-inline"
                                id="sympotomsText"


                                value={data[0].symptoms === 1 ? data[0].sympotomsText : ""}
                                disabled={data[0].symptoms === 1 ? isReadOnly : true}

                                onChange={(e) => this.onTextChange("sympotomsText", e.target.value, e)}

                            />
                        </Col>


                    </FormGroup>

                    <FormGroup row>

                        <Col md={12}>
                            <Row form>
                                <Col md={3} lg={2}>
                                    <Label>Is Private Note used</Label>
                                </Col>
                                <Col >
                                    <CustomInput
                                        type="checkbox"

                                        id="confidentalUsedY"
                                        label="Yes"
                                        checked={data[0].confidentalUsed === 1}
                                        inline
                                        disabled={isReadOnly}
                                        onChange={(e) => this.onTextChange("confidentalUsed", 1, e)}
                                    />
                                    {/* </Col>
                                <Col md={1}> */}
                                    <CustomInput type="checkbox"
                                        id="confidentalUsedN"
                                        label="No" inline
                                        checked={data[0].confidentalUsed === 0}
                                        onChange={(e) => this.onTextChange("confidentalUsed", 0, e)}
                                        disabled={isReadOnly}

                                    />
                                </Col>
                            </Row>
                        </Col>



                    </FormGroup>

                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Basic Ergonomic Workstation Items Covered at the Time of Evaluation"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>


                                <CardBody>
                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsFeet"
                                                name="itemsFeet"

                                                value={data[0].itemsFeet}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsFeet", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Feet: Flat on the floor or supported on a foot rest
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsKnees"
                                                name="itemsKnees"

                                                value={data[0].itemsKnees}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsKnees", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Knees: Bent approximately 90 degrees and slightly lower than hips
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsThighs"
                                                name="itemsThighs"

                                                value={data[0].itemsThighs}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsThighs", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Thighs: Parallel to the floor and lower legs perpendicular to the floor
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsElbows"
                                                name="itemsElbows"

                                                value={data[0].itemsElbows}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsElbows", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Elbows: Close to the body and positioned or supported at the side
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsBack"
                                                name="itemsBack"

                                                value={data[0].itemsBack}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsBack", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Back: Spine and neck supported in natural curves
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsChair1"
                                                name="itemsChair1"

                                                value={data[0].itemsChair1}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsChair1", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Chair: No sharp edges that press against the back of leg, wrist, or arms
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsChair2"
                                                name="itemsChair2"

                                                value={data[0].itemsChair2}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsChair2", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Chair: Demonstrate adjustments on the chair
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsArms"
                                                name="itemsArms"

                                                value={data[0].itemsArms}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsArms", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Arms: Bent approximately 90 degrees without extended reaching
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsShoulders"
                                                name="itemsShoulders"

                                                value={data[0].itemsShoulders}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsShoulders", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Shoulders: Relaxed and neutral (not shrugging or reaching)
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsWrists"
                                                name="itemsWrists"

                                                value={data[0].itemsWrists}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsWrists", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Wrists: Neutral position (level and in line with the forearm)
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsFingers"
                                                name="itemsFingers"

                                                value={data[0].itemsFingers}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsFingers", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Fingers: Neutral position
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsKeyboard"
                                                name="itemsKeyboard"

                                                value={data[0].itemsKeyboard}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsKeyboard", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Keyboard: Placement at a comfortable distance from the body (a wrist/palm rest may be justified)
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsMouse"
                                                name="itemsMouse"

                                                value={data[0].itemsMouse}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsMouse", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Mouse: Placement at a comfortable distance from the body (a wrist/ palm rest may be justified)
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsHead"
                                                name="itemsHead"

                                                value={data[0].itemsHead}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsHead", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Head: Directly over shoulders and facing forward (prevent tilting or turning head)
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsMonitor"
                                                name="itemsMonitor"

                                                value={data[0].itemsMonitor}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsMonitor", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Monitor: Placement at a comfortable distance and height for bifocals and directly in front
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsDesk"
                                                name="itemsDesk"

                                                value={data[0].itemsDesk}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsDesk", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Desk items: Placement of frequently used items within easy reach
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsBreaks"
                                                name="itemsBreaks"

                                                value={data[0].itemsBreaks}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsBreaks", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Breaks: Small task changes during the day designed to disrupt static or prolonged tasks
                                        </Col>
                                    </FormGroup>


                                    <FormGroup row>

                                        <Col md={4}>
                                            <CustomInput
                                                type="select"
                                                id="itemsExcercise"
                                                name="itemsExcercise"

                                                value={data[0].itemsExcercise}
                                                disabled={isReadOnly}        //{isReadOnly || (safetyObservationKey<0)}
                                                onChange={(e) => { this.onTextChange("itemsExcercise", e.target.value, e) }}
                                            >
                                                <option value=""></option>

                                                {ddErgo.map(v => <option value={v.ergoKey}>{v.ergoDesc}</option>)}
                                            </CustomInput>
                                        </Col>
                                        <Col md={8}>
                                            Exercise: Demonstrate / discuss periodic exercise and micro‐breaks to vary tasks and body position
                                        </Col>
                                    </FormGroup>

                                </CardBody>

                            </Card>
                        </Col>
                    </FormGroup>


                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Recommendations and Special Instructions (be specific)"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>


                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            {/* <Label for="youRObserve">WHAT DID YOU OBSERVE?</Label>       */}
                                            <Input
                                                // invalid={!validateDataItem.hazardDesc}
                                                type="textarea" name="recommendations" rows={5}
                                                id="recommendations"
                                                value={data[0].recommendations}
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("recommendations", e.target.value, e)}
                                            // defaultValue = {observation[0].hazardDesc}                               
                                            // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                            // disabled={isReadOnly} 

                                            />


                                        </Col>


                                    </Row>


                                </CardBody>

                            </Card>
                        </Col>
                    </FormGroup>


                    <FormGroup row>
                        <Col md={12}>
                            <Card>
                                <CardHeader style={{ padding: "0", margin: "0" }}>
                                    <Input
                                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                                        invalid={false}
                                        disabled
                                        className="bg-transparent"
                                        value="Follow up"
                                        style={{ border: "0px", padding: "0", margin: "0" }}>
                                    </Input>
                                    {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                                </CardHeader>
                                <CardBody>
                                    {/* 1 row */}
                                    <Row>
                                        <Col md={12}>
                                            <FormErgoFollowUp formErgoKey={data[0].formErgoKey}
                                                user={user}
                                                hidden={isReadOnly}
                                            // key={data[0].formRCAKey}
                                            >

                                            </FormErgoFollowUp>



                                        </Col>

                                    </Row>
                                    {/* 2 row */}




                                </CardBody>


                            </Card>
                        </Col>

                    </FormGroup>






                </Form>

                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

            </div>

        );



        //       }
        //     }
        //   </FetchData>
        // );
    }
}