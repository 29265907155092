import { ColumnsModel, QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns'
import { getComponent, isNullorUndefined } from '@syncfusion/ej2-base'
import React, { Component, ReactDOM, useState, useEffect, useCallback } from 'react';
import {
    FormGroup, Row, Button, Form, Label, InputGroup, Col, InputGroupText,
    Alert, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../../resources/utility';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';


import Select from 'react-select';
const AssetFilter = (props) => {
    const { assetFilter, dsSource, userFilter, filterName, selFilter, user, asset_type_key } = props

    const [filterInput, setFilterInput] = useState(props.filterResult)
    const [formMode, setFormMode] = useState((props.FormMode === undefined || props.FormMode === null ? 'Filter' : props.FormMode)) /**New - Manage - Filter */
    const [isUserFilter, setIsUserFilter] = useState(!(props.selFilter === undefined) && !(props.selFilter === null) && !(props.selFilter.user_id === null))
    const [modalFilterName, setModalFilterName] = useState(false)
    const [newFilterName, setNewFilterName] = useState('')
    const [isDefault, setIsDefault] = useState(false)
    const [alertColor, setAlertColor] = useState("danger")
    const [alertMessage, setAlertMessage] = useState("")
    const [toggleAlert, setToggleAlert] = useState(false)
    const [modalAlert, setModalAlert] = useState(false)
    const [modalAlertMessage, setModalAlertMessage] = useState('')
    const [modalAlertType, setModalAlertType] = useState(1) /**1 = Error, 2 = Confirm */

    let qryBldrObj
    let dropDownObj
    let elem

    console.log("----isUserFilter---")
    console.log(isUserFilter)
    console.log(selFilter)
    


    let columnData = (assetFilter === undefined ? [] :
        (assetFilter.length > 0 ? assetFilter[0].filter_option : []));

    const onCreated = () => {
        if (filterInput !== '') {
            qryBldrObj.setRulesFromSql(filterInput)
        }
    }

    const updateRule = (args) => {
        setFilterInput(qryBldrObj.getSqlFromRules(args.rule))

    }

    const saveData = () => {
        if (isUserFilter) {
            savefilterUser()
        } else {
            addfilterUser()
        }
    }

    const deleteFilter = () => {
        if (selFilter !== undefined && selFilter !== null) {

            const userFilterDTO = {
                "filter_user_key": selFilter.filter_user_key,
                "user_id": user.account.userName
            }
            let myURI = apiURL + 'Asset/Filteruser/Delete'
            fetch(myURI, apiPOSTHeader(userFilterDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;

                        return Promise.reject(error)
                    } else {
                        setModalAlert(false)
                        props.toggleOK("", true,true)
                    }
                }).catch(error => {
                    // console.log("PUT error: " + error)
                })
        }
    }
    
    const deleteFilterConfirm = () => {
        setModalAlertType(2)
        setModalAlertMessage("Are you sure you want to delete this filter?")
        setModalAlert(true)
    }
    const addfilterUser = () => {

        if (newFilterName !== '') {

            const userFilterDTO = {
                "asset_type_key": asset_type_key,
                "filter_key": assetFilter[0].filter_key,
                "filter_name": newFilterName,
                "user_id": user.account.userName,
                "is_default": (isDefault ? 1 : 0),
                "filter_expression_sql": filterInput,
                "filter_expression_json": ''
            }
            let myURI = apiURL + 'Asset/Filteruser/Add'
            fetch(myURI, apiPOSTHeader(userFilterDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;

                        console.log(error.message)
                        rspData.then(t => {
                            setAlertMessage(t)
                            setToggleAlert(true)
                        })
                        return Promise.reject(error)
                    } else {
                        setModalFilterName(!modalFilterName)
                        props.toggleOK(filterInput, true,false,newFilterName)
                    }
                }).catch(error => {
                    console.log("PUT error: " + error)
                })


        }

    }

    const savefilterUser = () => {

        if (selFilter !== undefined && selFilter !== null) {

            const userFilterDTO = {
                "filter_user_key": selFilter.filter_user_key,
                "user_id": user.account.userName,
                "is_default": selFilter.is_default,
                "filter_expression_sql": filterInput,
                "filter_expression_json": ''
            }
            let myURI = apiURL + 'Asset/Filteruser/Update'
            fetch(myURI, apiPOSTHeader(userFilterDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;

                        return Promise.reject(error)
                    } else {
                        props.toggleOK(filterInput, true)
                    }
                }).catch(error => {
                    // console.log("PUT error: " + error)
                })


        }
    }

    const onTextChange = (fieldName, fieldValue, e) => {
        //const { fullAssetTable } = this.state
        console.log(fieldName + ' ------ ' + fieldValue)
        console.log(e)

        switch (fieldName) {
            case "filter_name":
                setFilterInput(e.filter_expression_sql)
                qryBldrObj.setRulesFromSql(e.filter_expression_sql)
                break;
            case "newFilterName":
                if (toggleAlert) {
                    setAlertMessage('')
                    setToggleAlert(false)
                }

                setNewFilterName(fieldValue)
                break;
            case "isDefault":
                if (toggleAlert) {
                    setAlertMessage('')
                    setToggleAlert(false)
                }

                setIsDefault(fieldValue)
                break;
            case "assetSearch":

                break;
            default:
                break;
        }
    }

    useEffect(() => {
        //console.log("--------->useEffect ---> AssetFilter")

    })

    if (columnData.length === 0) {
        return ("No filter found")
    } else

        return (
            <div>
                <QueryBuilderComponent width='100%'
                    dataSource={dsSource}
                    columns={columnData}
                    ref={(scope) => { qryBldrObj = scope }}
                    created={onCreated}
                    ruleChange={updateRule}></QueryBuilderComponent>
                <div>
                    <Form style={{ marginTop: "10px" }}  >
                        <ColoredLine color="grey" />
                    </Form>
                    <Form style={{ marginTop: "10px" }}>
                        <Button color="danger" outline
                            className='float-right'
                            onClick={() => props.toggle()}>Cancel</Button>

                        <Button color="primary" outline
                            className='float-right'
                            style={{ marginRight: "20px" }}
                            onClick={() => props.toggleOK(filterInput)}>OK</Button>

                        <Button color="primary" outline
                            className='float-left'
                            style={{ marginRight: "20px" }}
                            onClick={() => (isUserFilter ? saveData() : setModalFilterName(!modalFilterName))}>{(isUserFilter ? "Save" : "Save As")}</Button>

                        <Button color="danger" outline
                            className='float-left'
                            style={{ marginRight: "20px" }}
                            hidden={(isUserFilter === undefined || isUserFilter === null || !isUserFilter)}
                            onClick={() => deleteFilterConfirm()}>{"Delete"}</Button>
                    </Form>
                </div>

                <Modal isOpen={modalAlert}
                    className="alway-on-top"
                    size="md"
                    toggle={() => setModalAlert(!modalAlert)}
                    // style={{ margin: 0, flex: 1 }}
                    backdrop={"static"}>
                    <ModalHeader toggle={() => setModalAlert(!modalAlert)}>
                        {(modalAlertType === 1 ? "Error" : "Confirm")}
                    </ModalHeader>
                    <ModalBody>
                        {modalAlertMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline
                            className='float-right'
                            style={{ marginRight: "20px" }}
                            onClick={() => (modalAlertType === 2 ? deleteFilter() : setModalAlert(!modalAlert))}>OK</Button>

                        <Button color="danger" outline
                            className='float-right'
                            hidden = {(modalAlertType === 1)}
                            onClick={() => setModalAlert(!modalAlert)}>Cancel</Button>
                    </ModalFooter>

                </Modal>

                <Modal isOpen={modalFilterName}
                    className="alway-on-top"
                    size="md"
                    toggle={() => setModalFilterName(!modalFilterName)}
                    // style={{ margin: 0, flex: 1 }}
                    backdrop={"static"}
                >
                    <ModalHeader toggle={() => setModalFilterName(!modalFilterName)}>
                        {"Pleas eneter filter name"}
                    </ModalHeader>
                    <ModalBody>
                        <Alert color={alertColor}
                            toggle={() => setToggleAlert(!toggleAlert)}
                            isOpen={toggleAlert}
                            style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {alertMessage}
                        </Alert >
                        <InputGroup hidden>

                            <InputGroupText className="btn-no-border">
                                Set as Default
                            </InputGroupText>


                            <Input
                                addon
                                aria-label="set as default filter"
                                type="checkbox"
                                onChange={(e) => onTextChange("isDefault", e.target.checked, e)}
                            />

                        </InputGroup>
                        <InputGroup>
                            <InputGroupText className="btn-no-border">
                                Filter Name :
                            </InputGroupText>
                            <Input onChange={(e) => onTextChange("newFilterName", e.target.value, e)} />
                        </InputGroup>


                    </ModalBody>
                    <ModalFooter>


                        <Button color="primary" outline
                            className='float-right'
                            style={{ marginRight: "20px" }}
                            onClick={() => saveData()}>OK</Button>

                        <Button color="danger" outline
                            className='float-right'
                            onClick={() => props.toggle()}>Cancel</Button>
                    </ModalFooter>

                </Modal>
            </div>
        )


}

export default AssetFilter;