import React, { Component } from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { apiURL, apiHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from '../operation/ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class StandardProcedure extends Component {
    constructor(props) {
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onReportDateUpdating = this.onReportDateUpdating.bind(this);

        this.state = {
            isLoaded: false,
            stdProd: [],
            windowHeight: window.innerHeight,
            gridHeightRatio: 0.8,
            standardProducedureKey: 0
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.stdProd;
    }
    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)

            this.setState({ standardProducedureKey: dataItem.standardProducedureKey })

            const newWindow = window.open(dataItem.urlLink, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
            //this.toggle()
            //this.onVolRefresh()    
        }
    }

    getStdPro = () => {
        let myURI = apiURL + 'StandardProcedure'
        //+ '/AzureAD/' + this.props.user.account.userName;
        console.log(myURI);

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isLoaded: true,
                    stdProd: json
                })

            });
    }

    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getStdPro();
    }

    render() {
        const { isLoaded, stdProd, windowHeight, gridHeightRatio } = this.state
        const gridHeight = (windowHeight * gridHeightRatio) + "px"
        var searchText = window.$searchText.toLowerCase()

        return (<div>
            <FormGroup>
                <Row form>
                    <h2> Procedure and Programs</h2>

                    <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.getStdPro}>
                        <img
                            src={MenuIcon}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 0 0 0" }}
                        />
                        Refresh
                    </Button>

                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.saveWorkbook}>
                        <img
                            src={MenuIcon}
                            alt="excel"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                        Export To Excel
                    </Button>{' '}
                </Row>
                <ColoredLine color="grey" />
            </FormGroup>

            <FormGroup>
                <IgrDataGrid
                    ref={this.onGridRef}
                    height={gridHeight}
                    width="100%"
                    dataSource={stdProd}
                    autoGenerateColumns="false"
                    selectionMode="SingleRow"
                    selectedKeysChanged={this.onGridRowSelected}>
                    <IgrTextColumn field="locationName" headerText="Basin" width="*>160" />
                    <IgrTextColumn isHidden field="standardProcedureNo" headerText="Procedure No" width="*>190" />
                    <IgrTextColumn field="standardProcedureDesc" headerText="Procedure Desc" width="*>190" />

                    <IgrTextColumn isHidden field="revision" headerText="Revision" width="*>130" />
                    <IgrTextColumn field="standardProcedureStatusDesc" headerText="Status" width="*>120" />

                    <IgrTemplateColumn field="createdDatetime" headerText="Created Date" width="*>140"
                        cellUpdating={this.onReportDateUpdating} />


                    <IgrTextColumn field="urlLink" headerText="URL" width="*>160" />

                    <IgrNumericColumn field="standardProcedureKey" isHidden />
                </IgrDataGrid>
            </FormGroup>
        </div>)
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "StandardProcedures");
    }
}