import React,{Component} from 'react';
import {apiURL} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import {ExcelUtility} from '../operation/ExcelUtility';

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

export default class PurchaseOrder extends Component{
    static displayName = PurchaseOrder.name;

    constructor(props){
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);

        this.state ={
            isPOLoaded:false,
            poData:[],
            windowHeight:window.innerHeight,
            widpo:-1,
            poStatus:'',
            poNo:'',
            modal:false
        }
    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
                
    }
    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.poData;
      
    }

    getPOData=() =>{
        let myURI = apiURL + 'SupplyChain/PO'

        console.log(myURI);

        fetch(myURI , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isPOLoaded: true,
                    poData:  json
                })
            });
            

    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            console.log(dataItem)
            
            this.setState({widpo: dataItem.widpo,
                          poStatus: dataItem.purchase_Order_Status,
                          poNo:dataItem.purchase_Order})       
            //this.toggle()
            //this.onVolRefresh()    
        } 
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getPOData()
    }

    render() {
        var {isPOLoaded,poData, windowHeight,poNo,poStatus, widpo} = this.state;
        const gridHeight=(windowHeight * 0.85) + "px"
        const searchText = window.$searchText.toLowerCase()

        if (!isPOLoaded){
            return(<div>
                <h3>Loading Purchase Order Data .....</h3>
            </div>)
        }

        return(<div>
            <FormGroup>
                   <Row form>
                       <h2> Workday Purchase Order |</h2>
                       <Button outline
                               color="secondary"
                               className="btn-no-border"
                               onClick={this.getPOData}>
                                   <img
                                   src={Refresh}
                                   alt="Refresh"
                                   style={{width: "30px", margin:"0 5px 0 0"}} />
                                   Refresh
                               </Button>
                       <Button outline color="secondary" 
                               className="btn-no-border" 
                               onClick={this.saveWorkbook}>
                           <img 
                           src={Excel}
                           alt="excel"
                           style={{width:"50px", margin:"0 0 0 0"}}/>
                           Export To Excel
                       </Button>{' '}

                       <Button outline color="secondary" 
                               className="btn-no-border" 
                               onClick={this.toggle}>
                           <img 
                           src={Plus}
                           alt="new"
                           style={{width:"25px", margin:"0 5px 0 0"}}/>
                           Edit
                       </Button>{' '}

                           
                   </Row>
                   <ColoredLine color="grey" />      
               </FormGroup>

               <FormGroup>
               <IgrDataGrid                        
                       ref={this.onGridRef}
                       height={gridHeight}                       
                       //dataSource={safetyObs}
                       dataSource={poData.filter( rec =>  ((rec.supplierID || '').toLowerCase().includes(searchText)) ||
                                                           (rec.supplier_Name || '').toLowerCase().includes(searchText) ||
                                                           (rec.purchase_Order_Status || '').toLowerCase().includes(searchText) ||
                                                           (rec.buyer || '').toLowerCase().includes(searchText) ||
                                                           (rec.purchase_Order || '').toLowerCase().includes(searchText))}
                       autoGenerateColumns="false"                                   
                       selectionMode="SingleRow"
                       selectedKeysChanged={this.onGridRowSelected}
                       //cellBackground={"#ffbfbf"}
                       >                      
                       

                       <IgrTextColumn field="purchase_Order" headerText="PO No" 
                           width="*>150"                       
                           />

                                                   
                       <IgrTextColumn field="purchase_Order_Status" headerText="Order Status" 
                           width="*>140" />

                       <IgrTextColumn field="supplierID" headerText="Supplier ID" width="*>140"                      
                       />
                       <IgrTextColumn field="supplier_Name" headerText="Supplier Name" 
                           width="*>240"  
                        />
                      

                       <IgrTextColumn field="company" headerText="Company" 
                           width="*>180" />
                      
                      <IgrTextColumn field="buyer" headerText="Buyer" 
                           width="*>150" />                                                                

                       <IgrTemplateColumn field="createdDate" headerText="Created Date" 
                           width="*>140" dateTimeFormat={"DateShort"}
                           cellUpdating={this.onReportDateUpdating} />
                       
                       
                       <IgrNumericColumn field="widpo" isHidden/>
                   </IgrDataGrid>
               </FormGroup>

           
               <div>
               <Modal isOpen={this.state.modal} 
                     toggle={() => this.toggle()}                  
                 size="lg"
               centered>
                   <ModalHeader className={(poStatus==="Cancled"?"modal-header reject":"")}  
                       toggle={() => this.toggle()}>{ "PO Details - " + poNo + ' - ' + poStatus} 
                   </ModalHeader>
                   <ModalBody>
                    {/* <WarehouseDetail 
                       wdSupplierKey={wdSupplierKey}
                       user={this.props.user}
                       toggle={this.toggle}
                       refreshData={this.getSupplierData}>

                       </WarehouseDetail> */}
                     Purchase Order Details - Comming in 2022
                   </ModalBody>                    
               </Modal>
               </div>


       </div>)
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
       // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");
            
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";          
            
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
           
            if (item.statusKey===4){
                content.style.background = "#b6a065";
            }else  if (item.statusKey===6){
                content.style.background = "#61a066";
            }else if (item.statusKey===0 || item.statusKey===3 || item.statusKey===5){
                content.style.background = "#c69089";
               
            }else {
                content.style.background = "transparent";
            }
        }


        switch(s.field){
            case 'statusDesc':
                link.textContent = item.statusDesc;
            break;
            case 'warehouseID':
                link.textContent = item.warehouseID;
                break;
            case 'warehouseName':
                link.textContent = item.warehouseName;

                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            // case 'hazardDesc':
            //     link.textContent = item.hazardDesc;
            //     break;      
            // case 'solutionDesc':
            //     link.textContent = item.solutionDesc;
            //     break;       
            // case 'createdByName':
            //     link.textContent = item.createdByName;
            //     break;          
            // case 'managerName':
            //     link.textContent = item.managerName;
            //     break;
            // case 'locationName':
            //     link.textContent = item.locationName;
            //     break;    
            // case 'facilityName':
            //     link.textContent = item.facilityName;
            //     break;                                     
            default:

        }        
      
    }


    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDate).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]===actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]===actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "POLIst");
    }
}