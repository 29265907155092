//Modified by BN 2/16/24
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, FormGroup, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import Select from 'react-select';
const ApproveConfirmationModal = (props) => {

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        buttonLabel,
        id,
        style,
        disabled,
        commentPrompMsg, 
        empList,
        isOpen,
        toggle         
    } = props

    const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);
    const [inputComment, setComment] = useState(props.inputComment)
    const [isValid, setIsValid] = useState(true)
    const [isUserValid, setisUserValid] = useState(true)
    const [isCommentRequired, setIsCommentRequired] = useState((props.isCommentRequired === undefined ? true : props.isCommentRequired))
    
    const [user, setUser] = useState("")

    const toggleYes = () => {
     
        // console.log(inputComment)
        // console.log(user)
        if (inputComment === "" || inputComment === undefined) {
            setIsValid(false)
        }
        if(user === "" || user === undefined)
        {
          setisUserValid(false)
        }
        else {
            // setModal(!modal)
            props.onClickYes(inputComment, user);
            setComment("")
            toggle();
        }

    }

    const onClosed = () => {

        if (props.onClosed !== undefined) {
            props.onClosed();
        }

        setIsValid(true)
        setComment("")
    }

    const onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)

        switch (fieldName) {
            case "comment":
                setComment(fieldValue)
                if (isCommentRequired) { setIsValid(fieldValue !== "") } 
                break;
                case "empList":
                    // console.log(e)
                    // console.log(e.emailAddress)
                    setisUserValid(e === null || e==="" || e=== undefined ? false : true)
                     setUser(e === null ? "" : e.emailAddress)
                    break;
            default:
                break;
        }
    }
    const getSelUser = (value) => {
    
        if (value !== null && value !== "") {
          return empList.filter((e) => e.emailAddress === value);
        }
        return "";
      };

    return (
      <div>
        {/* <Button
          outline={outline}
          style={style}
          color={color}
          className={className}
          hidden={hidden}
          disabled={disabled}
          onClick={toggle}
          // style={{ margin: "0 5px 0 5px" }}
        >
          {image}
          {buttonLabel}
        </Button> */}

        <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
          <ModalHeader toggle={toggle}> {formName}</ModalHeader>
          <ModalBody>
            {message}
            <FormGroup style={{ marginBottom: "10px" }}>
            <Select
            style={{ marginBottom: '20px' }} 
            
              options={empList}
            //   isMulti
              name="empList"
              id="empList"
              defaultValue={getSelUser(user)}
              value={getSelUser(user)}
              onChange={(e) => onTextChange("empList", e, e)}
              isClearable={true}
              className={!isUserValid ? "form-error" : ""}
            ></Select>
         
            
              <Label for="inputComment" style={{ marginTop: '20px' }}>{commentPrompMsg}</Label>
              <Input
                invalid={!isValid}
                id="inputComment"
                name="text"
                type="textarea"
                value={inputComment}
                defaultValue={inputComment}
                rows={3}
                onChange={(e) => onTextChange("comment", e.target.value, e)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleYes}>
              OK
            </Button>
            <Button color="primary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );

}

export default ApproveConfirmationModal;
