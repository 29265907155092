
import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Excel from '../../resources/excel.svg'


import { IgrDataGrid } from 'igniteui-react-grids';
import { ExcelUtility } from '../../operation/ExcelUtility';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ContainerState, ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();
export default class AFEExportCostDetails extends Component {
    static displayName = AFEExportCostDetails.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.state = {
            afeHeaderKey: props.afeHeaderKey,
            lineCost: [],
            isLoaded: false
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.lineCost;
    }

    exportCostToExcel = () => {
        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "CostLines");
    }

    getAFELineCostExport = () => {
        const { afeHeaderKey } = this.state

        let myURI = apiURL + 'AFE/LineCostForcast/Export/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    lineCost: json,
                    isLoaded: true
                })
            });
    }

    componentDidMount() {
        this.getAFELineCostExport()

    }

    render() {
        const { lineCost, isLoaded } = this.state

        if (!isLoaded) {
            return (<div>Loadding Line Cost details ......</div>)
        } else
            return (<div>
                <FormGroup>
                    <Row form style={{ marginTop: "10px", marginBottom: "-10px" }}>

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.exportCostToExcel}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", marginLeft: "-15px" }} />
                            Export Cost Details To Excel
                        </Button>{' '}
                    </Row>
                </FormGroup>

                <div hidden > Hello
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="1%"
                        width='100%'
                        dataSource={lineCost}
                        isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        hidden
                        editMode={0}
                    >


                        <IgrTextColumn
                            field="afeNo"
                            headerText="AFENo"
                            width="70"
                        />
                        <IgrTextColumn
                            field="lineID"
                            headerText="LineID"
                            width="120"
                        />
                        <IgrTextColumn
                            field="yrMonth"
                            headerText="YrMonth"
                            width="70"
                        />

                        <IgrTextColumn
                            field="monthYrLabel"
                            headerText="Month Year"
                            width="250"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTextColumn
                            field="percentText"
                            headerText="Percent"
                            width="100"
                        />

                        <IgrTextColumn
                            field="dayCount"
                            headerText="Day Count"
                            width="120"
                        />

                        <IgrTextColumn
                            field="forcastAmount"
                            headerText="forcastAmount"
                            width="120"
                        />

                        <IgrTextColumn
                            field="forcastTaxAmount"
                            headerText="Tax Amount"
                            width="120"
                        />
                        <IgrTextColumn
                            field="amount"
                            headerText="amount"
                            width="120"
                        />
                        <IgrTextColumn
                            field="wdsc"
                            headerText="Spend Category"
                            width="120"
                        />

                        <IgrTextColumn
                            field="costTypeDesc"
                            headerText="Cost Type"
                            width="70"
                        />

                        <IgrTextColumn
                            field="itemName"
                            headerText="ItemName"
                            width="120"
                        />

                        <IgrTextColumn
                            field="itemDesc"
                            headerText="Item Desc"
                            width="*>350"
                        />
                        <IgrTextColumn
                            field="assetTypeDesc"
                            headerText="Asset Type"
                            width="*>350"
                        />
                 <IgrTextColumn
                            field="taxRate"
                            headerText="Tax Rate"
                            width="*>150"
                        />



                        <IgrTextColumn
                            field="extLineAmt"
                            headerText="Line Amount"
                            width="120"
                        />

                        <IgrTextColumn
                            field="qty"
                            headerText="Line Qty"
                            width="120"
                        />

                        <IgrTextColumn
                            field="unitPrice"
                            headerText="UnitPrice"
                            width="120"
                        />

                        <IgrTextColumn
                            field="unitPrice2"
                            headerText="UnitPrice2"
                            width="120"
                        />


                        <IgrNumericColumn field="userGroupKey" isHidden />
                        <IgrNumericColumn field="actionLineKey" isHidden />
                    </IgrDataGrid>
                </div>
            </div>)
    }


}