import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const CustomConfirmModal = (props) => {
    const {
        isOpen,
        toggle,
        formName,
        message,
        onClickYes,
        onClickNo,
        onClosed
    } = props;

    const handleYes = () => {
        toggle();
        onClickYes();
    };

    const handleNo = () => {
        toggle();
        if (onClickNo) {
            onClickNo();
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            <ModalHeader toggle={toggle}>{formName}</ModalHeader>
            <ModalBody>{message}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleYes}>Yes</Button>
                <Button color="secondary" onClick={handleNo}>No</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CustomConfirmModal;
