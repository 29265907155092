import React, { Component } from 'react';

import {
    Form, Col, Row, Button, FormGroup, Label, Input, CustomInput,
    FormFeedback, InputGroup
} from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import { TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import ImageViewer from '../resources/ImageViewer';
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop';
import ConfirmModal from '../operation/ConfirmModal'

var DatePicker = require("reactstrap-date-picker");


export default class OilTicketDetail extends Component {
    static displayName = OilTicketDetail.name;

    constructor(props) {
        super(props)

        this.state = {
            truckBy: [],
            isTruckByLoaded: false,
            oilTicket: [],
            facilityOilTicketKey: props.FacilityOilTicketKey,
            isLoaded: false,
            isReadOnly: false,
            loadStatusKey: -1,
            reportDate: null,
            facility: [],
            isFacilityLoaded: false,
            activeTab: '1',
            offLineOilTicketKey: (getRandomInt(10000) * -1),
            invalidDataItem: {
                ticketNo: true,
                facilityKey: true,
                truckByKey: true,
            }
        }

    }
    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    deleteData = () => {
        const oilTK = this.createOilTicketDELETEDTO()
        console.log(oilTK)

        let myURI = apiURL + 'FacilityOilTicket'

        fetch(myURI, apiDELETEHeader(oilTK))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("DELETE Error: " + error);
            })

        this.closeForm(true)
    }

    createOilTicketDELETEDTO = () => {
        const { facilityOilTicketKey } = this.state
        const { user } = this.props

        const oilTKDELETE = {
            "facilityOilTicketKey": facilityOilTicketKey,
            "deletedBy": getUserADAccount(user.account.userName), /*4=Submitted, 6=Reviewed*/
            "latitude": 0,
            "longtitude": 0
        }

        return oilTKDELETE;
    }

    saveData = () => {
        const { facilityOilTicketKey } = this.state
        if (this.validateDate()) {
            if (facilityOilTicketKey < 0) {
                this.addOilTK()
            } else {
                this.updateOilTK()
            }
            this.closeForm(true)
        }
    }



    addOilTK = () => {
        console.log("add oil details")
        const oilTKCreateDTO = this.createOilTicketCreateDTO()
        console.log(oilTKCreateDTO)


        let myURI = apiURL + 'FacilityOilTicket'
        fetch(myURI, apiPOSTHeader(oilTKCreateDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })
    }

    validateDate = () => {
        const { invalidDataItem, oilTicket } = this.state
        var bVal = true
        var valItem = invalidDataItem

        if (oilTicket[0].ticketNo == "") {
            bVal = false
            valItem.ticketNo = false
        }

        if (oilTicket[0].facilityKey == 0) {
            bVal = false
            valItem.facilityKey = false
        }

        if (oilTicket[0].truckByKey == 0) {
            bVal = false
            valItem.truckByKey = false
        }

        this.setState({ invalidDataItem: valItem })

        console.log("bVal")
        console.log(invalidDataItem)
        return bVal
    }

    updateOilTK = () => {
        console.log("Save data in oil details")
        const oilTKUpdateDTO = this.createOilTicketUpdateDTO()
        console.log(oilTKUpdateDTO)


        let myURI = apiURL + 'FacilityOilTicket'
        fetch(myURI, apiPUTHeader(oilTKUpdateDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    createOilTicketCreateDTO = () => {
        const { oilTicket, loadStatusKey, offLineOilTicketKey } = this.state
        const { user } = this.props

        // console.log("oilTicket.comments")
        // console.log(oilTicket[0].comments)

        const oilTKUpdateDTO = {
            "facilityKey": oilTicket[0].facilityKey,
            "truckByKey": oilTicket[0].truckByKey,
            "ticketNo": oilTicket[0].ticketNo,
            "reportDate": oilTicket[0].reportDate,
            "bswPercent": oilTicket[0].bswPercent,
            "observedGravity": oilTicket[0].observedGravity,
            "correctedGravity": oilTicket[0].correctedGravity,
            "estimateGrossBarrels": oilTicket[0].estimateGrossBarrels,
            "estimateNetBarrels": oilTicket[0].estimateNetBarrels,
            "tempObserved": oilTicket[0].tempObserved,
            "tempBottom": oilTicket[0].tempBottom,
            "tempTop": oilTicket[0].tempTop,
            "loadStatusKey": loadStatusKey,
            "latitude": oilTicket[0].latitude,
            "longtitude": oilTicket[0].longtitude,
            "comments": oilTicket[0].comments,
            "createdBy": getUserADAccount(user.account.userName),
            "offLineOilTicketKey": offLineOilTicketKey
        }
        return oilTKUpdateDTO;
    }

    createOilTicketUpdateDTO = () => {
        const { oilTicket, loadStatusKey } = this.state
        const { user } = this.props

        // console.log("oilTicket.comments")
        // console.log(oilTicket[0].comments)

        const oilTKUpdateDTO = {
            "facilityOilTicketKey": oilTicket[0].facilityOilTicketKey,
            "facilityKey": oilTicket[0].facilityKey,
            "truckByKey": oilTicket[0].truckByKey,
            "ticketNo": oilTicket[0].ticketNo,
            "reportDate": oilTicket[0].reportDate,
            "bswPercent": oilTicket[0].bswPercent,
            "observedGravity": oilTicket[0].observedGravity,
            "correctedGravity": oilTicket[0].correctedGravity,
            "estimateGrossBarrels": oilTicket[0].estimateGrossBarrels,
            "estimateNetBarrels": oilTicket[0].estimateNetBarrels,
            "latitude": oilTicket[0].latitude,
            "longtitude": oilTicket[0].longtitude,
            "comments": oilTicket[0].comments,
            "modifiedBy": getUserADAccount(user.account.userName),
            "logLatitude": oilTicket[0].logLatitude,
            "logLongtitude": oilTicket[0].logLongtitude,
            "tempObserved": oilTicket[0].tempObserved,
            "tempBottom": oilTicket[0].tempBottom,
            "tempTop": oilTicket[0].tempTop,
            "loadStatusKey": loadStatusKey,
        }
        return oilTKUpdateDTO;
    }

    viewOilTicketImage = () => {

    }

    getTruckBy = () => {
        let myURI = apiURL + 'FacilityOilTicket/TruckOilCompany'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isTruckByLoaded: true,
                    truckBy: json
                })
            });
    }

    getOilTicket = () => {
        let { facilityOilTicketKey } = this.state

        if (facilityOilTicketKey < 0) {
            const oilTKData = this.createOilTicketData()
            this.setState({
                isLoaded: true,
                oilTicket: oilTKData,
                loadStatusKey: 1,
                reportDate: new Date(oilTKData[0].reportDate).toISOString()
            })
        } else {
            let myURI = apiURL + 'FacilityOilTicket/' + facilityOilTicketKey
            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    this.setState({
                        isLoaded: true,
                        oilTicket: json,
                        loadStatusKey: json[0].loadStatusKey,
                        reportDate: new Date(json[0].reportDate).toISOString()
                    })
                });
        }
    }

    getFacility = () => {
        const { user } = this.props;

        let myURI = apiURL + 'FacilityDetails/AzureAD/' + user.account.userName

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isFacilityLoaded: true,
                    facility: json
                })
            })
    }

    createOilTicketData = () => {
        const { user } = this.props
        const oilTicket = {
            "facilityOilTicketKey": -1,
            "facilityKey": 0,
            "truckByKey": 0,
            "ticketNo": "",
            "reportDate": new Date().toISOString(),
            "bswPercent": 0,
            "observedGravity": 0,
            "correctedGravity": 0,
            "estimateGrossBarrels": 0,
            "estimateNetBarrels": 0,
            "latitude": 0,
            "longtitude": 0,
            "comments": '',
            "modifiedBy": getUserADAccount(user.account.userName),
            "createdBy": user.account.name,
            "createdByName": user.account.name,
            "tempObserved": 0,
            "tempBottom": 0,
            "tempTop": 0,
            "loadStatusKey": 1,
            "locationKey": 4
        }
        return [oilTicket]
    }
    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const { oilTicket, invalidDataItem } = this.state;
        // if (fieldName==="ticketNo"){

        // }       

        switch (fieldName) {
            case "ticketNo":
                oilTicket[0].ticketNo = fieldValue
                invalidDataItem.ticketNo = (fieldValue != "")
                break;
            case "reportDate":
                oilTicket[0].reportDate = fieldValue
                this.setState({ reportDate: fieldValue })
                break;
            case "comments":
                oilTicket[0].comments = fieldValue
                break;
            case "bsw":
                oilTicket[0].bswPercent = fieldValue / 100.00
                break;
            case "observedGravity":
                oilTicket[0].observedGravity = fieldValue
                break;
            case "correctedGravity":
                oilTicket[0].correctedGravity = fieldValue
                break;
            case "tempTop":
                oilTicket[0].tempTop = fieldValue
                break;
            case "tempBottom":
                oilTicket[0].tempBottom = fieldValue
                break;
            case "tempObserved":
                oilTicket[0].tempObserved = fieldValue
                break;
            case "estimateGrossBarrels":
                oilTicket[0].estimateGrossBarrels = fieldValue
                break;
            case "estimateNetBarrels":
                oilTicket[0].estimateNetBarrels = fieldValue
                break;
            case "truckByKey":
                oilTicket[0].truckByKey = fieldValue
                invalidDataItem.truckByKey = true
                break;
            case "facilityKey":
                oilTicket[0].facilityKey = fieldValue
                //oilTicket[0].locationKey= this.getLocationKey(fieldValue)
                this.getLocationKey(fieldValue)
                invalidDataItem.facilityKey = true
                break;
            default:

        }

        this.setState({
            oilTicket: oilTicket,
            invalidDataItem: invalidDataItem
        })
    }

    getLocationKey = (facilityKey) => {

        const { facility, oilTicket } = this.state

        const filterFac = facility.filter(v => v.facilityKey == facilityKey)
        if (filterFac.length > 0) {
            oilTicket[0].locationKey = filterFac[0].locationKey
            oilTicket[0].locationName = filterFac[0].locationName
            this.setState({ oilTicket: oilTicket })
        } else return 0
    }


    onLoadStatusChange = (value, e) => {
        this.setState({ loadStatusKey: value })
    }

    componentDidMount() {
        this.getFacility()
        this.getTruckBy()
        this.getOilTicket()
    }

    closeForm = (bFreshData) => {
        if (bFreshData) this.props.refreshOilTicket();
        this.props.toggle()
    }


    render() {
        const { oilTicket, isLoaded, truckBy, isTruckByLoaded, facility, invalidDataItem, activeTab,
            facilityOilTicketKey, isReadOnly, loadStatusKey, reportDate, offLineOilTicketKey } = this.state
        const { user } = this.props
        if (!isTruckByLoaded) {
            return (<div>
                <h3>Loading oil ticket trucking company.....</h3>
            </div>)
        } else if (!isLoaded) {
            return (<div>
                <h3>Loading oil ticket details data.....</h3>
            </div>)
        } else if (oilTicket.length <= 0) {
            return (<div>
                <h3>Oil ticket details not found.</h3>
            </div>)
        } else
            return (<div>
                <Form>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="ticketNo">Ticket No</Label>
                                <Input invalid={!invalidDataItem.ticketNo} type="text" name="ticketNo"
                                    id="ticketNo" disabled={isReadOnly}
                                    defaultValue={oilTicket[0].ticketNo}
                                    onChange={(e) => this.onTextChange("ticketNo", e.target.value, e)} />
                                <FormFeedback tooltip > Please enter ticket No</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="createdBy">Created By</Label>
                                <Input disabled type="text" name="createdBy" id="createdBy"
                                    defaultValue={oilTicket[0].createdByName}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="Ticket Date">Ticket Date</Label>
                                <DatePicker id="ticketDate"
                                    disabled={isReadOnly}
                                    value={reportDate}
                                    onChange={(v, f) => this.onTextChange("reportDate", v, f)} />

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="location">Location</Label>
                                <Input disabled type="text" name="location" id="location"
                                    defaultValue={oilTicket[0].locationName}
                                    placeholder="" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="bsw">BSW %</Label>
                                <Input type="number" name="bsw"
                                    defaultValue={oilTicket[0].bswPercent * 100.00}
                                    id="bsq" disabled={isReadOnly}
                                    onChange={(e) => this.onTextChange("bsw", e.target.value, e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="facility">Facility Name</Label>

                                {/* <Input disabled type="text" name="facility" id="facility" 
                            defaultValue={oilTicket[0].facilityName}
                            placeholder="" 
                             /> */}
                                < CustomInput type="select"
                                    invalid={!invalidDataItem.facilityKey}
                                    id="faility"
                                    name="faility"
                                    defaultValue={oilTicket[0].facilityKey}
                                    value={oilTicket[0].facilityKey}
                                    onChange={(e) => this.onTextChange("facilityKey", e.target.value, e)}
                                >
                                    <option value={-100}></option>
                                    {facility.map(v => <option value={v.facilityKey}>{v.facilityName}</option>)}
                                </CustomInput>
                                <FormFeedback tooltip > Please select facility</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form style={{ margin: "0px 0px 5px 0px" }}>


                        {/* <Input disabled type="text" name="truckBY" id="truckBY" 
                            defaultValue={oilTicket[0].companyName}
                            placeholder="" 
                             />                            */}

                        <Col md={12}>
                            <FormGroup>
                                <Label>Truck By</Label>
                                < CustomInput type="select"
                                    invalid={!invalidDataItem.truckByKey}
                                    id="truckBy"
                                    name="truckBy"
                                    defaultValue={oilTicket[0].truckByKey}
                                    value={oilTicket[0].truckByKe}
                                    onChange={(e) => this.onTextChange("truckByKey", e.target.value, e)}
                                >
                                    <option value={-100}></option>
                                    {truckBy.filter(rec => (rec.locationKey === oilTicket[0].locationKey)).map(v => <option value={v.truckOilCompanyKey}>{v.companyName}</option>)}
                                </CustomInput>
                                <FormFeedback tooltip> Please select truck by company</FormFeedback>
                            </FormGroup>
                        </Col>

                    </Row>


                    {/* <ColoredLine  color="black"></ColoredLine> */}

                    <Row style={{ margin: "5px 0px 0 0px" }} form>

                        <Col md={3}>
                            <Label>Load Status: </Label>
                        </Col>
                        <Col md={4}>
                            <CustomInput type="switch"
                                id="accept"
                                name="accept"
                                label="Accept"
                                disabled={isReadOnly}
                                checked={(loadStatusKey == 1 ? true : false)}
                                onClick={(e) => this.onLoadStatusChange(1, e)} />

                        </Col>
                        <Col md={4}>
                            <CustomInput type="switch"
                                id="reject"
                                name="reject"
                                label="Reject"
                                disabled={isReadOnly}
                                checked={(loadStatusKey != 1 ? true : false)}
                                onClick={(e) => this.onLoadStatusChange(0, e)} />

                        </Col>
                    </Row>

                    {/* <ColoredLine  color="black"></ColoredLine> */}

                    <Row style={{ margin: "5px 0px 5px 0px" }} form>

                        <Col md={3}>
                            <Label>Gravity </Label>
                        </Col>
                        <Col md={4}>
                            <Label for="observed"> Observed </Label>
                            <Input type="number"
                                id="observed"
                                defaultValue={oilTicket[0].observedGravity}
                                name="observed"
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("observedGravity", e.target.value, e)}>
                            </Input>

                        </Col>
                        <Col md={4}>
                            <Label for="corrected"> Corrected </Label>
                            <Input type="number"
                                id="corrected"
                                defaultValue={oilTicket[0].correctedGravity}
                                name="corrected"
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("correctedGravity", e.target.value, e)}>
                            </Input>

                        </Col>
                    </Row>

                    {/* <ColoredLine  color="black"></ColoredLine> */}

                    <Row style={{ margin: "5px 0px 5px 0px" }} form>

                        <Col md={3}>
                            <Label>Temperature </Label>
                        </Col>
                        <Col md={3}>
                            <Label for="tempTop"> Top </Label>
                            <Input type="number"
                                id="tempTop"
                                defaultValue={oilTicket[0].tempTop}
                                name="tempTop"
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("tempTop", e.target.value, e)}>
                            </Input>

                        </Col>

                        <Col md={3}>
                            <Label for="tempBottom"> Bottom </Label>
                            <Input type="number"
                                id="tempBottom"
                                defaultValue={oilTicket[0].tempBottom}
                                name="tempBottom"
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("tempBottom", e.target.value, e)}>
                            </Input>

                        </Col>

                        <Col md={3}>
                            <Label for="tempObs"> Observed </Label>
                            <Input type="number"
                                id="tempObs"
                                defaultValue={oilTicket[0].tempObserved}
                                name="tempObs"
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("tempObserved", e.target.value, e)}>
                            </Input>
                        </Col>
                    </Row>

                    {/* <ColoredLine  color="black"></ColoredLine> */}

                    <Row style={{ margin: "5px 0px 5px 0px" }} form>

                        <Col md={3}>
                            <Label>Estimate Barrels </Label>
                        </Col>
                        <Col md={4}>
                            <Label for="grossed"> Gross </Label>
                            <Input type="number"
                                id="grossed"
                                defaultValue={oilTicket[0].estimateGrossBarrels}
                                name="grossed"
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("estimateGrossBarrels", e.target.value, e)}>
                            </Input>

                        </Col>
                        <Col md={4}>
                            <Label for="net"> Net </Label>
                            <Input type="number"
                                id="net"
                                defaultValue={oilTicket[0].estimateNetBarrels}
                                name="net"
                                disabled={isReadOnly}
                                onChange={(e) => this.onTextChange("estimateNetBarrels", e.target.value, e)}>
                            </Input>
                        </Col>
                    </Row>
                    <FormGroup>
                        <div style={{height:"150px"}}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >
                                        Comments
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { this.toggleTab('2'); }}
                                    >
                                        Documents
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col md={12}>
                                            {/* <Label for="comments">Comments</Label> */}
                                            <Input type="textarea" name="comments"
                                                id="comments"
                                                defaultValue={oilTicket[0].comments}
                                                disabled={isReadOnly}
                                                rows="4"
                                                onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col md={12}>
                                            <DocumentBrowserDragDrop buttonLabel="View Image"
                                                buttonColor="primary"
                                                formName="Oil Tikcet Images"
                                                linkTableName={"topFacilityOilTicket"}
                                                linkPrimaryKey={(facilityOilTicketKey < 0 ? offLineOilTicketKey : facilityOilTicketKey)}
                                                apiURL={apiURL + 'FacilityOilTicket/Documents/' + (facilityOilTicketKey < 0 ? offLineOilTicketKey : facilityOilTicketKey)} />
                            </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>




                    </FormGroup>

                    <FormGroup>
                        <Row>

                            <Col md={6}>
                                <ConfirmModal outline={true}
                                    color="danger"
                                    className="float-left"
                                    buttonLabel="Delete"
                                    hidden={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey > 0)}
                                    formName="Confirm"
                                    id={facilityOilTicketKey}
                                    message="Are you sure you want to delete selected oil ticket?"
                                    onClickYes={(e) => this.deleteData()}
                                    image={<img
                                        src={TrashBin}
                                        id={facilityOilTicketKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>

                            </Col>

                            <Col sm={6}>
                                <Button color="primary" className="float-right"
                                    onClick={() => this.closeForm(false)}>Cancel</Button>
                                <Button color="primary" className="float-right"
                                    style={{ margin: "0px 10px 0px 0px" }}
                                    onClick={() => this.saveData()}>Save</Button>
                            </Col>
                        </Row>
                    </FormGroup>

                </Form>
            </div>)
    }
}

