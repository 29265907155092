import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, FormGroup, ModalBody, ModalFooter, Label, Row } from 'reactstrap';

const WOConfirmModal = (props) => {
  const {
    color,
    className,
    formName,
    message,
    hidden,
    buttonLabel,
    style,
    commentPrompMsg,
    isOpen,
    toggle,
    onClickYes
  } = props;

  useEffect(() => {
    // Additional logic can be added here if needed
  }, [isOpen]);

  const toggleYes = () => {
    if (onClickYes) {
      onClickYes(); // Call the onClickYes function passed from props
    }
    toggle();
  };

  const onClosed = () => {
    if (props.onClosed !== undefined) {
      props.onClosed();
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
        <ModalHeader toggle={toggle}>{formName}</ModalHeader>
        <ModalBody>
          <Row form style={{ marginBottom: "5px" }}>
          </Row>
          {message}
          <FormGroup style={{ marginBottom: "-10px" }}>
            <Label for="inputComment">
              {commentPrompMsg}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleYes}>OK</Button>
          <Button color="primary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WOConfirmModal;
