//Ready for to Push to Master from local Master 10/14/21

//Created by BN - 09/08/2021 


import React, { Component } from 'react';

import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, 
        FormFeedback, InputGroup } from 'reactstrap';
import { apiURL, apiHeader,apiPUTHeader,apiPOSTHeader,apiDELETEHeader } from '../resources/apiURL';

import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import ImageViewer from '../resources/ImageViewer';

import ConfirmModal from '../operation/ConfirmModal'
// import  './format.css'

var DatePicker = require("reactstrap-date-picker");


export default class UserGroupDetails extends Component {
    static displayName = UserGroupDetails.name;

    constructor(props){
        super(props)
        
        this.state={
            userGroup: [],
            grpDetails: props.groupDetails,
            invalidDataItem:{
              userGroupName: true,
              userGroupDesc:true
            }
        }
        
    }

    closeForm = (refreshData) => {
        // if (bFreshData) this.props.refreshOilTicket();
        if(refreshData){
          this.props.refreshData()
        }
         this.props.toggle()
     }




    saveData = () =>{
    const {grpDetails} = this.state 
  if(this.validateDate()){
    if (grpDetails.userGroupKey<0){
        this.createGroup()
        // console.log("It should have created a group");
    }else{
        this.updateGroup()
    }
    // this.closeForm(true);  
    }
  }
    createUserGroupEmployee = () =>{
      
      const {grpDetails} = this.state   
      // console.log("Create group in group details")
      const userGroupEmployeeCreateDTO= this.createUserGroupEmployeeDTO()
      // console.log(userGroupEmployeeCreateDTO)
    

        let myURI = apiURL + 'SecAdmin/UserGroup'
        fetch(myURI , apiPOSTHeader(userGroupEmployeeCreateDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
              // console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);
              
            }
    
            // console.log("No error here: " + rspData)
          })
          .catch(error =>{
            // console.log("POST Error: " + error);
          })


    }

    createUserGroupEmployeeDTO=()=>{
      const {grpDetails} = this.state 
      const {user} = this.props
        const userGroupEmployeeCreate ={
          "userGroupKey": 5,
          "employeeKey": "",
          "linkedBy": getUserADAccount(user.account.userName)
        }
        return userGroupEmployeeCreate
    }



     createGroup = () =>{

        const {grpDetails} = this.state   
        // console.log("Create group in group details")
        const userGroupCreateDTO= this.createUserGroupCreateDTO()
        // console.log(userGroupCreateDTO)
      

          let myURI = apiURL + 'SecAdmin/UserGroup'
        
          fetch(myURI , apiPOSTHeader(userGroupCreateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
               
                return Promise.reject(error);
               
              }
              this.closeForm(true)
              // console.log("No error here: " + rspData)
            })
            .catch(error =>{
              // console.log("POST Error: " + error);
              this.closeForm(true)
            })

         
            // this.closeForm(true)

     }


     

     updateGroup = () => {
        const {grpDetails} = this.state 
        // console.log("Save group in group details")
        const userUpdateGroupDTO= this.createUserGroupUpdateDTO()
        // console.log(userUpdateGroupDTO)
      

          let myURI = apiURL + 'SecAdmin/UserGroup'
          fetch(myURI , apiPUTHeader(userUpdateGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see") 
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
              this.closeForm(true)
              // console.log("No error here: " + rspData) 
            })
            .catch(error =>{
              // console.log("PUT Error: " + error);
              this.closeForm(true)
            })
           
    }

    deleteGroup = () => {
        const userGroupDeleteDTO = this.createUserGroupDeleteDTO()
        // console.log(userGroupDeleteDTO)

        let myURI = apiURL + 'SecAdmin/UserGroup'

        fetch(myURI , apiDELETEHeader(userGroupDeleteDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
              // console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              // this.closeForm(true)
              return Promise.reject(error);
              
            }
            this.closeForm(true)
            // console.log("No error here: " + rspData)
          })
          .catch(error =>{
            // console.log("DELETE Error: " + error);
            this.closeForm(true)
          })

      
    }

    createUserGroupCreateDTO=()=>{
        const {grpDetails} = this.state 
        const {user} = this.props
        const userGroupCreateDTO = {
            // "userGroupKey" : grpDetails.userGroupKey,
            "userGroupName": grpDetails.userGroupName,
            "userGroupDesc": grpDetails.userGroupDesc,
            "active": grpDetails.active,
            "createdBy": getUserADAccount(user.account.userName)
             //USER GROUP KEY OUTPUT????

         }
         return userGroupCreateDTO
    }
 
     createUserGroupUpdateDTO=()=>{
        const {grpDetails} = this.state
        const {user} = this.props
         const userGroupUpdateDTO = {
             "userGroupKey" : grpDetails.userGroupKey,
             "userGroupName": grpDetails.userGroupName,
             "userGroupDesc": grpDetails.userGroupDesc,
             "active": grpDetails.active,
             "modifiedBy": getUserADAccount(user.account.userName)
             
         }
         return userGroupUpdateDTO
     }

     createUserGroupDeleteDTO=()=>{
        const {grpDetails} = this.state 
        const {user} = this.props
        const userGroupDeleteDTO= {
            "userGroupKey" : grpDetails.userGroupKey,
            "deletedBy": getUserADAccount(user.account.userName)
        }
        return userGroupDeleteDTO;
    }

    validateDate = () => {
      const {invalidDataItem,grpDetails} = this.state 
      var bVal = true 
      var valItem = invalidDataItem

      if (grpDetails.userGroupName == "") {
          bVal = false
          valItem.userGroupName= false
      }

      if (grpDetails.userGroupDesc == 0){
          bVal = false
          valItem.userGroupDesc = false
      }

     
      this.setState({invalidDataItem:valItem})

      // console.log("bVal")        
      // console.log(invalidDataItem)
      return bVal
  }

     onTextChange=(fieldName,fieldValue,e)=>{
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const {grpDetails,invalidDataItem}=this.state;
        // if (fieldName==="ticketNo"){
            
        // }       
        
        switch(fieldName){
            case "userGroupKey":
                grpDetails.userGroupKey=fieldValue
                // invalidDataItem.ticketNo=(fieldValue != "")
                break;
            case "userGroupName":
                grpDetails.userGroupName= fieldValue
                invalidDataItem.userGroupName=(fieldValue != "")
                break;
            case "userGroupDesc":
                grpDetails.userGroupDesc=fieldValue
                invalidDataItem.userGroupDesc=(fieldValue != "")
                break;
            case "active":
                grpDetails.active=fieldValue
                break;
            case "createdBy":
                grpDetails.createdBy=fieldValue
                break;

            default:

        }
        
        this.setState({grpDetails:grpDetails,
                       invalidDataItem:invalidDataItem})
    }

    render(){
        const {grpDetails, invalidDataItem} = this.state
        const {user} = this.props
        
        return (<div>
           
            <Form>

            <FormGroup >
            <Row form>


                    <Col md={10}>
                        <FormGroup>
                            <Label for="userGroupName">User Group Name</Label>
                            <Input invalid={!invalidDataItem.userGroupName} type="text" name="userGroupName" id="userGroupName" 
                             defaultValue={grpDetails.userGroupName}
                             placeholder="" 
                             onChange={(e) => this.onTextChange("userGroupName",e.target.value,e)}
                            
                             />
                            <FormFeedback tooltip > Please enter User Group Name</FormFeedback>
                        </FormGroup>
                        
                    </Col>


                    <Col md={1}>

                    <FormGroup>
                    <Label for="active">Active</Label>                   
                    <CustomInput  type="checkbox" name="active" id="active" onClick= "clickMe()" checked="checked"
                    defaultValue={grpDetails.active}
                    placeholder="" 
                     onChange={(e) => this.onTextChange("active",e.target.value,e)}  
                    />                                        
                    </FormGroup>                       
                    </Col>



            </Row>

            <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="userGroupDesc">User Group Description</Label>
                            <Input invalid={!invalidDataItem.userGroupDesc}  type="textarea" name="userGroupDesc" id="userGroupDesc" rows="5" 
                            defaultValue={grpDetails.userGroupDesc}
                            placeholder=""
                            onChange={(e) => this.onTextChange("userGroupDesc",e.target.value,e)} 
                            />
                            <FormFeedback tooltip > Please enter User Group Description</FormFeedback>
                        </FormGroup>
                        
                    </Col>
            </Row>



            <Row>
                    <Col md={3}>

                  
                     </Col> 
                    
                    <Col md={3}>
                        <ConfirmModal outline={true}
                            
                                  color="danger"
                                  hidden = {!(grpDetails.userGroupKey>0)}
                                  className="float-left"
                                  buttonLabel="Delete"
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected user group?"
                              
                                  onClickYes={(e) => this.deleteGroup()}
                                  image={<img
                                   
                                    src={TrashBin}
                                   // id={facilityOilTicketKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 10px 0 0" }}/>}>
                        </ConfirmModal>    

                    </Col>

                     <Col sm={6}>
                     <Button color="warning" className="float-right" 
                             onClick={() => this.closeForm(true)}>Cancel</Button>
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> this.saveData()}>Save</Button>
                     </Col>
                     </Row>

                </FormGroup>
            </Form>
           

            
        </div>)
        
    }


    // onActiveUpdating(s,e){
    //     const content = e.content;
    //     const info = e.cellInfo;
    //     const item = info.rowItem;

    //     if (content.childElementCount === 0) {
    //         //link = document.createElement("INPUT");
    //         var chkActive = document.createElement("INPUT");
    //         chkActive.setAttribute("type", "checkbox");           
       
    //         content.style.display = "inline-block";
    //         //content.style.display = "inline-grid";
    //         content.style.fontFamily = "Verdana";
    //         content.style.fontSize = "12px";
    //         // content.style.color = "#4286f4";
    //          content.style.width = "100%";

    //         content.appendChild(chkActive);

    //         chkActive.removeAttribute("checked")
    //         if (item.active != 0){
    //             chkActive.setAttribute("checked", "checked");     
    //         }
    //     }
     
    // }
  onCheck(){
    if(document.getElementById('active').checked)
    {
      
    }
  }
  
}