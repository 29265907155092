
import React, { Component } from 'react';
import moment from "moment";


import { Link } from 'react-router-dom';
import { Form, Card, CardHeader, CardBody, Col, Row, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { apiURL, apiHeader } from '../../resources/apiURL';
import '../../custom.css';
// import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

// import { IFunctionFilterExpression_$type, TemplateSectionHeaderCellInfoDescription } from 'igniteui-react-core';
import { getUserADAccount } from '../../resources/utility'
// import Datetime from 'react-datetime';
// import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { IgrDataGrid, IgrTextColumn } from 'igniteui-react-grids';
import { IgrTemplateColumn } from 'igniteui-react-grids';
// import { IgrNumericColumn } from 'igniteui-react-grids';
// import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrGridCellValueChangingEventArgs } from 'igniteui-react-grids';
// import { IgrGridRowEditEndedEventArgs } from 'igniteui-react-grids';
// import SafetyIncidentLine from './SafetyIncidentLine';

import { ColoredLine } from '../../resources/utility';
import ConfirmModal from '../../operation/ConfirmModal';
import ModalYNComment from '../../accounting/ModalYNComment'

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


import Select from 'react-select';


import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isPlainObject } from 'jquery';

// import iconplus from '../resources/plus.svg'
// import icontrash from '../resources/trash.svg'
// import Plus from '../resources/plus.svg'

var DatePicker = require("reactstrap-date-picker");





export default class ErgonomicEvaluationRequest extends Component {
    static displayName = ErgonomicEvaluationRequest.name;


    constructor(props) {
        super(props);
        // this.onGridRef = this.onGridRef.bind(this);


        const urlPara = this.getURLParameters(props.location)

        this.state = {
            data: {
                reason: null,
                explain: "",
                seat: ""
            },
            ddReason: [],
            prevData: [],
            strPrevData: "",
            isPending: false,
            linkPrev: "",
            linkNo: "",

            user: getUserADAccount(this.props.user.account.userName),
            username: this.props.user.account.name,

            employeeKey: 0,
            supervisorName: "",
            supervisorKey: 0,

            isLoaded: false,
            empName: [],

            isShowingAlert: false,

            modalMsg: false,
            msgHeader: "",
            msgBody: "",

            validateDataItem: {
                reason: true,
                explain: true,
                seat: true
                // empShift:true
            }

            // modal:false,

        }
    }

    validateDataForSubmit = () => {
        //in JSA form
        // this.props.onValidate("dfff")
        // val kk = subform value zz
        const { validateDataItem, data } = this.state
        var bVal = true
        var valItem = validateDataItem


        if (data.reason === null) {
            valItem.reason = false
            bVal = false
        }

        if (data.reason === '4' && data.explain === "") {
            valItem.explain = false
            bVal = false
        }

        if (data.seat === "") {
            valItem.seat = false
            bVal = false
        }



        this.setState({ validateDataItem: valItem })
        return bVal

    }


    createDataForCreateDTO = () => {
        const { data, employeeKey } = this.state

        const linetoAdd = {
            "EmployeeKey": employeeKey, //selectedValue.key,
            "Seat": data.seat,
            "ReasonKey": data.reason,
            "Explain": data.explain


            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }





    submitForm = () => {
        var bVal = true
        bVal = this.validateDataForSubmit()
        if (!bVal) {
            const isConfirmed = alert("Please fill in the required fields");
        }



        if (bVal) {

            const addDTO = this.createDataForCreateDTO()
            let myURI = apiURL + 'SafetyIncident/addErgoEvalRequest'

            const putReq = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(addDTO)
            }

            fetch(myURI, putReq)
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        //console.log("Error return but you dont see")
                        const error = (rspData && rspData.message) || response.status;
                        rspData.then(t => {
                            this.setState({
                                msgBody: t,
                                msgHeader: "Error",
                                modalMsg: !this.state.modalMsg

                            })
                        })
                        // return Promise.reject(error);
                        // return false;
                    }

                    // console.log("No error here: " + rspData)
                    window.location = '/#/SafetyIncident';

                    // this.getInpsData()
                    // return true

                })
                .catch(error => {
                    console.log("PUT Error: " + error);
                    // return false;
                })

        }


    }


    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }



    setShowingAlert = (status) => {
        this.setState({

            // isSafIncUser:false,
            isShowingAlert: status
        }
        )

    }

    getURLParameters = (inputPara) => {
        var paraMeters = {
            safIncHeaderKey: null
        }




        var query
        query = new URLSearchParams(this.props.location.search);
        console.log("query")
        // console.log(query.get('safIncHeaderKey'))

        // paraMeters = {
        //     safIncHeaderKey: query.get('safIncHeaderKey')
        // }
        return paraMeters
    }


    getddReason = () => {
        // const { user, isSafIncAdmin } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetvciSafIncErgoEvalReason'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    // isddTypesLoaded: true,
                    ddReason: json
                })
            });
    }




    getPrevData = (employeeKey) => {
        // const { employeeKey } = this.state
        let myURI = apiURL + 'SafetyIncident/FormErgoEmployeeKey/' + employeeKey
        var strPrevData = ""
        var isPending = false;
        var linkPrev = ""
        var linkNo = ""
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // if (json.length > 0) {
                //     this.getInspHdrData(json[0].safIncHeaderKey)

                // }
                if (json.length > 0) {
                    strPrevData = "Last Request: " + new Date(json[0].createdDateTime).toLocaleDateString() + ' ' + json[0].statusDesc + '  '
                    if (json[0].statusDesc != "Complete" && json[0].statusDesc != "Cancel"  && json[0].statusDesc != "Rejected") {
                        isPending = true
                    }

                    linkPrev = "/SafetyIncidentHeader?safIncHeaderKey=" + json[0].safIncHeaderKey;
                    linkNo = json[0].safIncID

                }



                this.setState({
                    prevData: json,
                    strPrevData: strPrevData,
                    isPending: isPending,
                    linkPrev: linkPrev,
                    linkNo: linkNo


                    //safIncHeaderKey:json[0].safIncHeaderKey
                    // inspComments:json[0].inspComments,
                    // facInspComments:json[0].facInspComments
                }
                );


                // console.log("---------")
                // console.log(this.state.data)

                // this.getInspHdrData(json[0].safIncHeaderKey)


            });
    }





    getManager = () => {

        const { empName } = this.state
        let myURI = apiURL + 'SecAdmin/User'
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    empName: json.filter(rec => rec.adAccount!=null && rec.adAccount.toUpperCase() === getUserADAccount(this.props.user.account.userName).toUpperCase())
                    //empName: json.filter(rec => rec.adAccount.toUpperCase() === getUserADAccount(this.props.user.account.userName).toUpperCase())
                })
                // console.log(json.filter(rec => rec.adAccount.toUpperCase() === "ADAM.FEW"))
            }).then(this.getEmpKeys)


    }


    getEmpKeys = () => {
        const { empName, } = this.state
        const { user } = this.props
        var empKey
        const adAccount = getUserADAccount(user.account.userName)

        var employeeKey
        var supervisorName
        var supervisorKey
        console.log(empName[0].employeeKey);
        if (empName.length > 0) {
            employeeKey = empName[0].employeeKey;
            supervisorName = empName[0].managerName;
            supervisorKey = empName[0].managerKey;
        }
        this.setState({ employeeKey: employeeKey, supervisorName: supervisorName, supervisorKey: supervisorKey })
        this.getPrevData(employeeKey)
    }



    componentDidMount() {

        this.getManager()
        this.getddReason()
        // var zz = getUserADAccount(this.props.user.account.userName).toUpperCase()



    }









    getddEmployees = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyObservation/SubmitBy'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    //   isddEmployeesLoaded: true,
                    ddEmployees: json
                })
            });
    }














    createDataForUpdateDTO = (ApprovalComment) => {
        const { data } = this.state
        const { user } = this.props

        const dataForUpdateDTO = {
            "SafIncHeaderKey": data[0].safIncHeaderKey,
            //"ModifiedByKey": 580, /*4=Submitted, 6=Reviewed*/
            "StatusKey": data[0].statusKey,
            "ModifiedByAD": getUserADAccount(user.account.userName),
            "Comment": data[0].comment,
            "Descript": data[0].descript,
            "PrivateNote": data[0].privateNote,
            "PriorityKey": data[0].priorityKey,
            "IsContractor": data[0].isContractor,
            "ApprovalComment": ApprovalComment



        }

        return dataForUpdateDTO;
    }

    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const { data, validateDataItem } = this.state;

        // /cbincEquipmentDamage
        //incClassif

        switch (fieldName) {



            case "seat":
                data.seat = fieldValue
                fieldValue === '' ? validateDataItem.seat = false : validateDataItem.seat = true
                break;
            case "explain":
                data.explain = fieldValue
                validateDataItem.explain = fieldValue === "" ? (data.reason === '4' ? false : true) : true
                break;

            case "reason":
                // ddtyp = fieldValue;
                data.reason = fieldValue
                fieldValue === '' ? validateDataItem.reason = false : validateDataItem.reason = true
                validateDataItem.explain = data.explain === "" ? (fieldValue === '4' ? false : true) : true
                break;










            default:

        }

        this.setState({ data: data, validateDataItem: validateDataItem })
    }













    render() {

        // var {isLoaded, data, isHdrLoaded, hdrData} = this.state
        const { user,
            isShowingAlert, msgHeader, msgBody, username, supervisorName, data, ddReason,
            validateDataItem, strPrevData, isPending, linkPrev, linkNo
        } = this.state
        // console.log('isSafIncAdmin')
        // console.log(isSafIncAdmin)
        // if (!isUserLoaded) return <h1> loading user status... </h1>
        // if (!isAdminLoaded) return <h1> loading admin status... </h1>
        // if (!isddPriorityLoaded) return <h1> loading priorities... </h1>
        // if (!isLoaded) return <h1> loading... </h1>
        // if (!isReadOnlyLoaded) return <h1> loading readonly properties... </h1>




        return (
            <div>

                <Form onSubmit={e => e.preventDefault()}>

                    <Col className="col-md-6 offset-md-3" >
                        <h2>Ergonomic Evaluation Request </h2>
                    </Col>




                    <div
                        className={` alert-success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}
                        onTransitionEnd={() => this.setShowingAlert(false)}>
                        Data is saved
                    </div>




                    <FormGroup row  >
                        <Col className="col-md-6 offset-md-3" >
                            <Row form>
                                <p class="font-weight-light">{strPrevData}</p>
                                <Link to={linkPrev}>{linkNo}</Link>


                            </Row>
                        </Col>
                    </FormGroup>


                    <FormGroup row  >
                        <Col className="col-md-6 offset-md-3" >
                            <Row form>
                                <Label for="user">Name</Label>
                                <Input disabled type="text" name="user"
                                    id="user" placeholder=""
                                    value={username}
                                />
                            </Row>
                        </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                        <Col md={6}>
                            <Row form>

                            </Row>
                        </Col>
                    </FormGroup> */}

                    <FormGroup row>
                        <Col className="col-md-6 offset-md-3" >
                            <Row form>
                                <Label for="supervisor">Supervisor's Name</Label>
                                <Input disabled type="text" name="supervisor"
                                    id="supervisor" placeholder=""
                                    value={supervisorName}
                                />
                            </Row>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col className="col-md-6 offset-md-3" >
                            <Row form>
                                <Label for="seat">Seat/Office Number</Label>
                                <Input
                                    type="text"
                                    name="seat"
                                    id="seat"
                                    disabled={isPending}
                                    invalid={!validateDataItem.seat}
                                    placeholder=""
                                    value={data.seat}
                                    onChange={(e) => this.onTextChange("seat", e.target.value, e)}

                                />
                            </Row>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col className="col-md-6 offset-md-3" >
                            <Row form>
                                <Label for="seat">Reason for request:</Label>
                                <CustomInput
                                    type="select"
                                    id="ddReason"
                                    name="ddReason"
                                    disabled={isPending}
                                    invalid={!validateDataItem.reason}
                                    //defaultValue={2}
                                    // value={2}
                                    //disabled={true}        //{isReadOnly || (safetyObservationKey<0)}
                                    onChange={(e) => { this.onTextChange("reason", e.target.value, e) }}
                                >
                                    <option value=""></option>

                                    {ddReason.map(v => <option value={v.reasonKey}>{v.reasonID}</option>)}
                                </CustomInput>
                            </Row>
                        </Col>
                    </FormGroup>



                    <FormGroup row>
                        <Col className="col-md-6 offset-md-3" >
                            <Row form>
                                <Label for="seat">If you selected "Other" for the previous question, please explain.</Label>
                                <Input
                                    type="textarea"
                                    rows={4}
                                    disabled={isPending}
                                    invalid={!validateDataItem.explain}
                                    name="explain"
                                    id="explain"
                                    placeholder=""
                                    value={data.explain}
                                    onChange={(e) => this.onTextChange("explain", e.target.value, e)}

                                />
                            </Row>
                        </Col>
                    </FormGroup>


                    <FormGroup row>
                        <Col className="col-md-6 offset-md-3" >
                            <Row form>
                                <ConfirmModal outline={false}
                                    color="success"
                                    className="float-left"
                                    buttonLabel="Submit"
                                    disabled={isPending}
                                    // hidden={!((data[0].statusKey == 0 || data[0].statusKey == 1) && (isAdmin || isCreator)) || linedata.length === 0}
                                    formName="Confirm"
                                    // id={safetyObservationKey}
                                    message="Are you sure you want to submit?"
                                    onClickYes={(e) => this.submitForm()}
                                />
                            </Row>
                        </Col>
                    </FormGroup>












                </Form>

                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>


            </div>

        );



        //       }
        //     }
        //   </FetchData>
        // );
    }
}