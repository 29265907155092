

import React, { Component } from "react";
import { Button } from "reactstrap"
// import HomePage from '../public/HomePage.png'

const divStyle = {
  width: '98vw',
  height: '84vh',
  backgroundImage: `url(${process.env.PUBLIC_URL + "/HomePage.png"})`,
  backgroundSize: 'cover'
};

const floatinBtn = {
  position: 'absolute',
  bottom: 50,
  right: 90,
};



export default class WaveAsset extends Component {
  constructor(props) {
    super(props);
  }

  openWAVE = () =>{
    const newWindow = window.open("https://spotfire.h2obridge.com/spotfire/wp/OpenAnalysis?file=/WAVE/WAVE%20Reporting&configurationBlock=SetPage%28pageIndex%3D1%29%3B&options=7-1,9-1,10-1,11-1,12-1,13-1,14-1,1-0,2-1,3-0,4-0,5-0,6-0,15-0,17-0", '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  


  render() {
    return <div style={divStyle}>

      {/* <iframe width="100%" height="900" src="https://spotfire.h2obridge.com/spotfire/wp/OpenAnalysis?file=/WAVE/WAVE%20Reporting&configurationBlock=SetPage%28pageIndex%3D1%29%3B&options=7-1,9-1,10-1,11-1,12-1,13-1,14-1,1-0,2-1,3-0,4-0,5-0,6-0,15-0,17-0"></iframe>      */}
      <Button color="primary" className="float-right"
        style={floatinBtn}
        //ref={ref}
        //hidden={this.isFormReadOnly() && !isDataChange}
        onClick={() => this.openWAVE()}>
        Login to View WAVE Dashboard</Button>
    </div>

  }

}

