import React, { Component } from 'react';
import {FormGroup} from 'reactstrap';
import '../../custom.css';
import { apiURL, apiHeader } from '../../resources/apiURL';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';

import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';


// import { copySync } from 'fs-extra';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();



export default class ItemTransDetail extends Component {
    static displayName = ItemTransDetail.name;

    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);

        this.state = {
            itemMasterKey : props.itemMasterKey,
            isTransactionLoaded:false,
            transaction:[],
            // itemMasterKey:-1,
            windowHeight: window.innerHeight
        }

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.transaction;
    }





    getTransaction = () => {
        let myURI = apiURL + 'ItemMaster/Inventory/Transaction/' + this.props.itemMasterKey

        console.log(myURI)
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isTransactionLoaded: true,
                    transaction: json
                })
            });
    }

    componentDidMount=()=>{
        this.getTransaction()
    }

    render(){
        const {isTransactionLoaded, transaction, windowHeight} = this.state
        const gridHeight = (windowHeight * 0.25) + "px"
        if(!isTransactionLoaded){
            return (<div>
                <h3>Loading Transaction Details......</h3>
            </div>)
        }
        else    
        return(<div>
          
             <FormGroup>
                                    <IgrDataGrid
                                        ref={this.onGridRef}
                                        height={gridHeight}
                                        dataSource={transaction}
                                        autoGenerateColumns="false"
                                        selectionMode="SingleRow"
                                    //filterUIType="FilterRow"
                                    //cellBackground={"#ffbfbf"}
                                    >
                                        <IgrTextColumn field="toLocationDesc" headerText="To Location Desc" width="*>180"

                                        />
                                        <IgrTextColumn field="invTransCode" headerText="Inv Trans Code" width="*>180"

                                        />

                                        <IgrTextColumn field="transDateTime" headerText="Trans Date" width="*>180"

                                        />

                                        <IgrTextColumn field="transQty" headerText="Trans Qty" width="*>180"

                                        />

                                        <IgrTextColumn field="reason_Memo" headerText="Reason Memo" width="*>180"

                                        />


                                        <IgrTextColumn field="locQtyAfter" headerText="Loc Qty After" width="*>180"

                                        />


                                        <IgrTextColumn field="locQtyAfter_from" headerText="Loc Qty After From" width="*>180"

                                        />


                                        <IgrTextColumn field="from_LocationDesc" headerText="From Location Desc" width="*>180"

                                        />




                                    </IgrDataGrid>
                                </FormGroup>
        </div>)
    }

}