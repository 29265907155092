//Created by BN 1/24/22


import React, { Component } from 'react';

import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, 
        FormFeedback, InputGroup } from 'reactstrap';
import { apiURL, apiHeader,apiPUTHeader,apiPOSTHeader,apiDELETEHeader } from '../resources/apiURL';

import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import ImageViewer from '../resources/ImageViewer';

import ConfirmModal from '../operation/ConfirmModal'
// import  './format.css'


var DatePicker = require("reactstrap-date-picker");



export default class ItemTypeHeader extends Component {
    static displayName = ItemTypeHeader.name

    constructor(props){
        super(props)


        this.state = {
            itemTypeKey: props.itemTypeKey,
            itemTypeHeaderDetails: props.itemTypeData,
            invalidDataItem: {
                itemTypeDesc: true
            }
        }
    }

    validateData=()=>{
        const {itemTypeHeaderDetails, invalidDataItem} = this.state
        var bVal = true 
        var valItem = invalidDataItem
  
        if (itemTypeHeaderDetails.itemTypeDesc == "") {
            bVal = false
            valItem.itemTypeDesc= false
        }

        this.setState({invalidDataItem:valItem})

        return bVal
    }
    onTextChange=(fieldName,fieldValue,e)=>{

        const {itemTypeHeaderDetails, invalidDataItem}=this.state;

        
        switch(fieldName){
            case "itemTypeDesc":
                itemTypeHeaderDetails.itemTypeDesc=fieldValue
                invalidDataItem.itemTypeDesc=(fieldValue != "")
                break;
            default:

        }
        
        this.setState({itemTypeHeaderDetails:itemTypeHeaderDetails,
                       invalidDataItem:invalidDataItem
                       
  
                })
    }

    createItemTypeHeader = () =>{

        const itemTypeHeaderCreateDTO= this.createItemTypeHeaderCreateDTO()

      

          let myURI = apiURL + 'ItemMaster/ItemType'
        
          fetch(myURI , apiPOSTHeader(itemTypeHeaderCreateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){

                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
            //   this.closeForm(true);

            })
            .catch(error =>{

            })
            this.closeForm(true);
         


     }


    
    updateItemTypeHeader= () => {
        const {requiredKey} = this.state 
        const itemTypeHeaderUpdateDTO= this. createItemTypeHeaderUpdateDTO()

        let myURI = apiURL + 'ItemMaster/ItemType'

          fetch(myURI , apiPUTHeader(itemTypeHeaderUpdateDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){

                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
            })
            .catch(error =>{

            })

            this.closeForm(true);
    }

    
    createItemTypeHeaderCreateDTO=()=>{
        const {itemTypeHeaderDetails} = this.state 
        const {user} = this.props
        const itemTypeCreateDTO = {
            "itemTypeDesc": itemTypeHeaderDetails.itemTypeDesc,

         }
         return itemTypeCreateDTO
    }

    createItemTypeHeaderUpdateDTO=()=>{
        const {itemTypeHeaderDetails} = this.state
        
        const {user} = this.props
        const itemTypeHeaderUpdateDTO={
            "itemTypeKey": itemTypeHeaderDetails.itemTypeKey,
            "itemTypeDesc": itemTypeHeaderDetails.itemTypeDesc,
         

        }

        return itemTypeHeaderUpdateDTO
        
    }

    saveData=() =>{
        const {itemTypeDetails,inventoryPropertyMapKey, itemTypeKey} = this.state  

        if(this.validateData()){
           

            
        if (itemTypeKey<0) {            
            this.createItemTypeHeader()

        }else{
            this.updateItemTypeHeader()
            
        }
        // this.setState({isHasValueList: false,
        //                 isRequired: false })


        this.closeForm(true);
    
    }
      
     }

     closeForm = (refreshData3) => {
         
        if(refreshData3){
            this.props.toggle()
            this.props.refreshData3()
        }
         
     }




    render(){

        const {itemTypeHeaderDetails, invalidDataItem} = this.state
        return(
        <div>
           
           <FormGroup>
          
           <Label for="itemTypeDesc">Item Type Name</Label>
                            <Input type="text" name="itemTypeDesc" 
                                    id="itemTypeDesc" 
                                    invalid={!invalidDataItem.itemTypeDesc}
                                    defaultValue={ itemTypeHeaderDetails.itemTypeDesc}
                                    value={ itemTypeHeaderDetails.itemTypeDesc}
                                    onChange={(e) => this.onTextChange("itemTypeDesc",e.target.value,e)}
                                    
            />
             <FormFeedback tooltip > Please enter Item Type Name</FormFeedback>
            </FormGroup>
            
            <FormGroup>
                    <Row>

                     <Col sm={12}>
                     <Button color="warning" className="float-right" 
                             onClick={() => this.closeForm(true)}
                             >Close</Button>

                    
                             
                     <Button color="primary"className="float-right"
                             style={{margin:"0px 10px 0px 0px"}}
                            //  hidden={this.setIsReadOnly(statusKey)}
                             onClick={()=> this.saveData()}
                             >Save</Button>

                        </Col>
                    </Row>
                 </FormGroup>   
               
        </div>)
    }



}

