

import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import { ComponentRenderer } from 'igniteui-react-core';
import '../../custom.css';

import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'
import AFEWDBGDetails from './AFEWDBGDetails'

import AFEWDJLDetails from './AFEWDJLDetails'
import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



export default class AFECloseoutDept extends Component {
    static displayName = AFECloseoutDept.name;
    constructor(props) {
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onCellValueChanging = this.onCellValueChanging.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onLoad = this.onLoad.bind(this);

        this.state = {
            closeoutDept: [],
            isDataLoaded: false,
            afeHeaderKey: props.afeHeaderKey,
            showAlert: false,
            selDept: '',
            selAFENo: '',
            alertMsg: '',
            isFormReadOnly: props.isFormReadOnly,
            modalWDBDDetails: false,
            modalWDJLDetails: false,
            selectedDept:''

        }
    }

    toggleModalWDBDDetails = () => {
        this.setState({
            modalWDBDDetails: !this.state.modalWDBDDetails
        })
    }

    toggleModalWDJLDetails = () => {
        this.setState({
            modalWDJLDetails: !this.state.modalWDJLDetails
        })
    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.closeoutDept;
        this.onLoad()
    }

    onGridRowSelected(s, e) {
        //  console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            //console.log(dataItem)

            this.props.selectDeptChange(dataItem.lineID)
         
        }
    }

    onLoad() {
        if (this.grid !== undefined) {
            this.grid.summaryDescriptions.clear()

            const invSum = new IgrColumnSummaryDescription();
            invSum.field = "invoicedAmount";
            invSum.operand = SummaryOperand.Sum;
            invSum.formatOverride = new Intl.NumberFormat('en-EN', {
                style: 'currency', currency: 'USD',
                minimumFractionDigits: 0, maximumFractionDigits: 0
            });
            this.grid.summaryDescriptions.add(invSum);


            const bgtSum = new IgrColumnSummaryDescription();
            bgtSum.field = "budgetAmount";
            bgtSum.operand = SummaryOperand.Sum;
            bgtSum.formatOverride = new Intl.NumberFormat('en-EN', {
                style: 'currency', currency: 'USD',
                minimumFractionDigits: 0, maximumFractionDigits: 0
            });
            this.grid.summaryDescriptions.add(bgtSum);



        }
    }



    componentDidMount() {
        window.addEventListener('load', this.onLoad);
        this.getCloseoutSC()
    }

    getCloseoutSC = () => {
        const { afeHeaderKey } = this.state

        let myURI = apiURL + 'AFE/closeout/Dept/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    closeoutDept: json
                })
            });
    }


    render() {
        const { isDataLoaded, closeoutDept, isFormReadOnly, selDept, selAFENo } = this.state

        if (!isDataLoaded) {
            return (<div>Loading Closeout department .......</div>)
        } else
            return (<div>

                <Form onSubmit={e => e.preventDefault()}>
                    <h5>Spend Department</h5>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="100%"
                        width='100%'
                        dataSource={closeoutDept}
                        isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        groupSummaryDisplayMode={"RowTop"}
                        groupHeaderDisplayMode="combined"
                        summaryScope="Both"
                        editMode={(isFormReadOnly ? 0 : 1)}
                        // editMode={(isFormReadOnly ? 0 : 1)}
                        selectedKeysChanged={this.onGridRowSelected}
                        cellValueChanging={this.onCellValueChanging}
                    >

                        <IgrTemplateColumn
                            field="lineDescription"
                            headerText="Spend Department"
                            width="280"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="budgetAmount"
                            headerText="Budget"
                            showGroupingSeparator="true"
                            positivePrefix="$"
                            maxFractionDigits={2}
                            width="140"
                            cellUpdating={this.onCellUpdating}
                        />
                        <IgrTemplateColumn
                            field="details"
                            headerText=""
                            width="40"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="invoicedAmount"
                            headerText="Actuals"
                            showGroupingSeparator="true"
                            positivePrefix="$"
                            maxFractionDigits={2}
                            width="140"
                            cellUpdating={this.onCellUpdating}
                        />
                        <IgrTemplateColumn
                            field="jlDetails"
                            headerText=""
                            width="40"
                            cellUpdating={this.onCellUpdating}
                        />


                        <IgrTemplateColumn
                            field="actualvsBudget"
                            headerText="Actuals/AFE"
                            width="150"
                            showGroupingSeparator="true"
                            positiveSuffix="%"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            cellUpdating={this.onCellUpdating}

                        />
                        <IgrTextColumn
                            field="comments"
                            headerText="Comments"
                            width="*>250"
                        //cellUpdating={this.onCellUpdating}
                        />

                        <IgrNumericColumn field="afeHeaderKey" isHidden />
                        <IgrNumericColumn field="afeCloseoutLinesKey" isHidden />
                    </IgrDataGrid>
                </Form>

                <div>
                    <Modal isOpen={this.state.modalWDBDDetails}
                        toggle={() => this.toggleModalWDBDDetails()}
                        size="xl">
                        <ModalHeader toggle={() => this.toggleModalWDBDDetails()}>
                            Workday Budget Details
                        </ModalHeader>
                        <ModalBody>
                            <AFEWDBGDetails
                                afeNo={selAFENo}
                                dept={selDept}
                                scid=''
                                user={this.props.user}
                                isFormReadOnly={isFormReadOnly}
                                toggleModalWDBDDetails={this.toggleModalWDBDDetails}>

                            </AFEWDBGDetails>
                        </ModalBody>

                    </Modal>
                </div>


                <div>
                    <Modal isOpen={this.state.modalWDJLDetails}
                        toggle={() => this.toggleModalWDJLDetails()}
                        size="xl">
                        <ModalHeader toggle={() => this.toggleModalWDJLDetails()}>
                            Workday Journal Details
                        </ModalHeader>
                        <ModalBody>
                            <AFEWDJLDetails
                                afeNo={selAFENo}
                                dept={selDept}
                                scid={''}
                                user={this.props.user}
                                isFormReadOnly={isFormReadOnly}
                                toggleModalWDJLDetails={this.toggleModalWDJLDetails}>

                            </AFEWDJLDetails>
                        </ModalBody>

                    </Modal>
                </div>
            </div>)
    }

    onCellValueChanging(s, e) {
        // if (this.state.showAlert) {
        //     this.setState({
        //         alertMessage: "",
        //         showAlert: false
        //     })
        // }
        console.log("afeCloseoutLinesKey")
        console.log(e.item.afeCloseoutLinesKey)

        this.updateCloseoutLine(e.item.afeCloseoutLinesKey, e.column.field, e.newValue, e)
    }

    updateCloseoutLine = (key, fieldName, value, e) => {
        const { user } = this.props
        const closeoutDTO = {
            "afeCloseoutLinesKey": key,
            "miscValue1": (fieldName === "miscValue1" ? value : e.item.miscValue1),
            "comments": (fieldName === "comments" ? value : e.item.comments),
            "modifiedBy": getUserADAccount(user.account.userName)
        }

        console.log("closeoutDTO")
        console.log(closeoutDTO)

        let myURI = apiURL + 'AFE/Closeout'
        fetch(myURI, apiPOSTHeader(closeoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    return Promise.reject(error)
                } else
                    return "Success"
            }).then(json => {
                console.log("Closeout Updated")
            }).catch(error => {
                console.log("PUT error: " + error)
            })


    }



    onCellUpdating(s, e) {

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        let button

        if (s.field === "details" || s.field === 'jlDetails') {
            if (content.childElementCount > 0) {
                const temp = content.children[0]
                // console.log("temp.id")
                // console.log(temp.id)
                // console.log(item.afeLineKey)

                if (Number(temp.id) !== Number(item.afeCloseoutLinesKey)) {
                    temp.remove()
                    //console.log("remove")

                }
            }
        }

        if (content.childElementCount === 0) {
            if (s.field === "details" || s.field === 'jlDetails') {
                button = document.createElement('button')
                button.innerText = "...";
                button.setAttribute("id", s.field + item.afeCloseoutLinesKey)

                button.setAttribute("class", "text-md-left form-control selectBorderLess");
                button.style.margin = "0px 0px 0px -10px";
                //button.style.padding = "0px 0px 0px 0px";
                button.style.width = "125%"
                button.style.backgroundcolor = "#transparent";
                content.appendChild(button);

                if (document.getElementById(s.field + item.afeCloseoutLinesKey) !== null) {
                    if (s.field === 'details') {


                        document.getElementById(s.field + item.afeCloseoutLinesKey).addEventListener("click", (e) => {
                            this.onDetailClick(e,item.afeCloseoutLinesKey)
                        })


                    } else {
                        document.getElementById(s.field + item.afeCloseoutLinesKey).addEventListener("click", (e) => {
                            this.onJLDetailClick(e,item.afeCloseoutLinesKey)
                        })
                    }
                }
                //button.addEventListener("click", ()=> {this.onDetailClick(item.afeCloseoutLinesKey)});
            } else {
                link = document.createElement("label");
                let cell = document.createElement("div");

                link.setAttribute("class", "form-control selectBorderLess");
                //link.style.fontFamily = "Verdana";
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";

                // content.style.margin = "0px -20px -10px -12px";
                link.style.padding = "8px 0px 0px 0px";
                // //content.style.width = "100%";

                content.appendChild(link);
            }
        } else {
            link = content.children[0]

            if (s.field === "actualvsBudget") {


                if (item.actualvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.actualvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.actualvsBudget > 104.44 && item.actualvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
            }
        }


        if (link !== undefined) {


            switch (s.field) {
                case 'actualvsBudget':
                    link.textContent = (item.actualvsBudget >= 1? Math.round(item.actualvsBudget):item.actualvsBudget) + '%';
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");

                    break;
                case 'lineDescription':
                    link.textContent = item.lineDescription;
                    link.setAttribute("class", "text-md-left form-control selectBorderLess");

                    break;
                case 'budgetAmount':
                    link.textContent = USDCurrencyFormat(item.budgetAmount, 0);
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");
                    break;
                case 'invoicedAmount':
                    link.textContent = USDCurrencyFormat(item.invoicedAmount, 0);
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");
                    break;

                default:
            }
        }
    }

    onJLDetailClick = (e,key) => {
        const { closeoutDept } = this.state
        console.log("SC JL Details Click")
        console.log(key)
        e.preventDefault();
        closeoutDept.map(a => {
            if (a.afeCloseoutLinesKey === key) {
                this.setState({
                    selDept: a.lineID
                    , selAFENo: a.afeNo
                })
                return;
            }
        })
        this.toggleModalWDJLDetails()
    }

    onDetailClick = (e,key) => {
        const { closeoutDept } = this.state
        console.log("SC Details Click")
        console.log(key)
        e.preventDefault();
        closeoutDept.map(a => {
            if (a.afeCloseoutLinesKey === key) {
                this.setState({ selDept: a.lineID, selAFENo: a.afeNo })
                return;
            }
        })
        this.toggleModalWDBDDetails()
    }
} 