import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import BarChart from '../resources/barChart.svg'
import EditIcon from '../resources/edit.png';
import Revise from '../resources/revise.png'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'
import ConstructionReportingDetails from './ConstructionReportingDetails'
import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'

import DailyFacilityReport from './DailyFacilityReport';
import DailyPipeLineReport from './DailyPipeLineReport';
import DailyGeneralReport from './DailyGeneralReport';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
// //IgrInputModule.register();
//IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");

export default class ReportAccording extends Component {
    //static displayName = AFE.name;
   

    constructor(props) { 
        super(props);

        
        document.title = "Report According"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);
        this.onGridRowSelectedFacility = this.onGridRowSelectedFacility.bind(this);
        this.onGridRowSelectedPipeLine = this.onGridRowSelectedPipeLine.bind(this);
        this.getAFEConstructionReporting = this.getAFEConstructionReporting.bind(this);
        
        const urlPara = this.getURLParameters(props.location)

        this.state = { 
            constructionReportKey: props.constructionReportKey,
            reportKey: 0, 
            projectType: props.projectType,
            constructionReportDTO: props.constructionReportDTO,
            statusKey: 0,
            isSecurityAdminLogin: props.isSecurityAdminLogin,
            afeStatusDesc: "",
            afeNo: "",
            afeName: "",
            isDataLoaded: false,
            windowHeight: window.innerHeight,
            AFEData: [],
            fullAFEData: [],
            empList: [],
            isEmpListLoaded: false,
            budgetList: [],
            isBudgetLoaded: false,
            facilityList: [],
            isFacilityLoaded: false,
            costTypeList: [],
            isCostTypeLoaded: false,
            afeTypeList: [],
            isAFETypeLoaded: false,
            budgetClassList: [],
            isBudgetClassLoaded: false,
            uomList: [],
            isUOMListLoaded: false,
            linkAFEList: [],
            isLinkAFELoaded: false,
            basinList: [],
            isBasinLoaded: false,
            countyList: [],
            isCountyLoaded: false,
            afeStatusList: [],
            selAFEStatus: [],
            selBasin: [],
            isAFEStatusList: false,
            dropdownOpen: false,
            dropdownOpenManage: false,
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            modalProjectDetails: false,
            searchText: "",
            openAFEDetails: !(urlPara.afeHeaderKey === null),
            isAFEAdmin: false,
            isSecurityLoaded: false,
            afeBudgetListURL: [],
            projectStatusList: [],
            isProjectStatusListLoaded: false,
            areaList: [],
            isAreaListLoaded: false,
            dupAFEList:[],
            isDupAFEListLoaded:false,
            afeQueryList: [{
                "label": "My AFEs",
                "value": 1
            }, {
                "label": "All AFEs",
                "value": 2
            }],
            selAFEQuery: [],
            constructionReportKey: 0,
            closeoutStatusKey: 0,
            modalReport: false,
            reportName: "", 
            managerList: [],
            constructionReports: [],
            consturctionReportStatusList: [],
            afeList: [],
            modalFacilityDailyReport: false,
            modalPipeLineDailyReport: false,
            modalGeneralDailyReport: false,
            modalChangeOrder: false,
            reportName: "",
            edited: false,
        }

        console.log('constructionReportDTO: ' + urlPara.constructionReportDTO);
    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }
    

    getURLParameters = (inputPara) => {

        var paraMeters = {
            afeHeaderKey: null
        }
        var query
        if (inputPara !== undefined) {
            query = new URLSearchParams(inputPara.search);
            console.log("query")
            console.log(query.get('key'))

            paraMeters = {
                afeHeaderKey: query.get('key')
            }

            console.log("paraMeters")
            console.log(paraMeters.afeHeaderKey)
        }

        return paraMeters

    }

    toggleAFEDetails = () => {
        this.setState({
            modalProjectDetails: !this.state.modalProjectDetails
        })
    }

    closeAFEDetailsForm = () => {

        this.setState({
            modalProjectDetails: !this.state.modalProjectDetails,
            // openAFEDetails: false,
            // afeHeaderKey: -1
        })
    }
    setAFEDetailsFormHeader = (afeNo, afeStatusDesc, afeName, afeHeaderKey, closeoutStatusDesc) => {
        this.setState({
            afeNo: afeNo,
            afeStatusDesc: afeStatusDesc,
            afeName: afeName,
            afeHeaderKey: afeHeaderKey,
            closeoutStatusDesc: closeoutStatusDesc
        })
    }

    onGridRowSelected(s, e) {
        //  console.log(" On CLick")
        const content = e.content;
        const {projectType} = this.state;

        if (e.addedKeys.count > 0) {
            let reportKey = 0;

            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]

            if (projectType === 'Pipeline'){
                reportKey = dataItem.constructionReportPipelineKey;
            }
            else if (projectType === 'Facility'){
                reportKey = dataItem.constructionReportFacilityKey;
            }
            else if (projectType === 'General'){
                reportKey = dataItem.constructionReportGeneralKey; 
            }

            this.setState({
                constructionReportKey: dataItem.constructionReportKey,
                reportKey: reportKey 
            }) 
        } 
        
    }

    onGridRowSelected2(s,e) {
        if (e.isDoubleClick){
            this.setState({ modalGeneralDailyReport: !this.state.modalGeneralDailyReport  });
        }
      }

    onGridRowSelectedFacility(s,e) {
        if (e.isDoubleClick){
            this.setState({ modalFacilityDailyReport: !this.state.modalFacilityDailyReport  });
        }
      }  
    onGridRowSelectedPipeLine(s,e) {
        if (e.isDoubleClick){
            this.setState({ modalPipeLineDailyReport: !this.state.modalPipeLineDailyReport  });
        }
      }  

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.AFEData;
    }


    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
        });

    }

    getCounty = () => {

        let myURI = apiURL + 'AFE/County'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    countyList: this.createCountyList(json),
                    isCountyLoaded: true
                })
            });
    }
    createCountyList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.countyDesc
            e.label = e.countyState
            tempList.push(e)
        })

        return tempList
    }

    getAFEStatus = () => {
        let myURI = apiURL + 'AFE/StatusFilter'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeStatusList: this.createAFEStatusList(json),
                    isAFEStatusLoaded: true
                })
            });
    }

    createConstructionReportStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.consturctionReportStatusKey
            e.label = e.consturctionReportStatusDesc
            tempList.push(e)
        })

        return tempList
    }


    getBasin = () => {

        //let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName
        let myURI = apiURL + 'AFE/Location' // + this.props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }

 

    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }
  

    getAssoAFE = () => {
        let myURI = apiURL + 'ConstructionReporting/AFE'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    linkAFEList: this.createLinkAFEList(json),
                    isLinkAFELoaded: true
                })
            });
    }

    createAFEList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeHeaderKey
            e.label = e.afeNo
            tempList.push(e)
        })

        return tempList
    }


    getBudgeClass = () => {
        let myURI = apiURL + 'AFE/BudgetClass'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetClassList: this.createBudgetClassList(json),
                    isBudgetClassLoaded: true
                })
            });
    }



    createBudgetClassList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetClassKey
            e.label = e.afeBudgetClassDesc
            tempList.push(e)
        })

        return tempList
    }

    getAFEType = () => {
        let myURI = apiURL + 'AFE/Type'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeTypeList: this.createAFETypeList(json),
                    isAFETypeLoaded: true
                })
            });
    }

    createAFETypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeTypeKey
            e.label = e.afeTypeDesc
            tempList.push(e)
        })
        return tempList
    }

    getAFECostType = () => {
        let myURI = apiURL + 'AFE/LineType'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    costTypeList: this.createCostTypeList(json),
                    isCostTypeLoaded: true
                })
            });
    }

    createCostTypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeLineTypeKey
            e.label = e.afeLineTypeDesc
            tempList.push(e)
        })

        return tempList
    }
  

    getBudget = () => {

        let myURI = apiURL + 'AFE/Budget'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetList: this.createBudgetList(json),
                    isBudgetLoaded: true
                })
            });
    }

    createBudgetList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetDesc
            e.label = e.afeBudgetDesc
            tempList.push(e)
        })

        return tempList
    }

    getAFEdata = () => {

        let myURI = apiURL + 'AFE/Header/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName;

        console.log("myURI");
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    fullAFEData: json,
                    searchText: ""
                })
                this.getDefaultAFEList(json)
                this.openAFEDetailsFromKey(json)
            });

    }


    async loadView(){
        let myURI = apiURL + 'ConstructionReporting/load'

        console.log("myURI");
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json.basinInformation),
                    costTypeList: this.createCostTypeList(json.projectType),
                    managerList: this.createEmpList(json.employeesDetails),
                    consturctionReportStatusList: this.createConstructionReportStatusList(json.consturctionReportStatus),
                    afeList: this.createAFEList(json.afeList),
                    //constructionReports: json.constructionReports,
                    searchText: "",
                    //isDataLoaded: true,
                }) 
            });
    }

    // getAFEConstructionReporting = async()=>{
    getAFEConstructionReporting(){
        const{constructionReportKey,constructionReportDTO} = this.state;
        
        if (constructionReportDTO === undefined || constructionReportDTO === null){
            return;
        }
        
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportDTO.constructionReportKey + '/DailyReports';  
        console.log("myURI");
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    //isDataLoaded: true,
                    constructionReports: json,
                    searchText: ""
                }) 
            });
    }
    
    getDefaultAFEList = (e) => {
        console.log("this.state.selBasin")
        console.log(this.state.selBasin)
        console.log("this.state.selAFEStatus")
        console.log(this.state.selAFEStatus)

        this.getFitlerAFE(e, this.state.selAFEQuery, this.state.selBasin, this.state.selAFEStatus)
    }

    getProjectStatus = () => {
        let myURI = apiURL + 'AFE/ProjectStatus'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    projectStatusList: this.createProjectStatusList(json),
                    isProjectStatusListLoaded: true
                })
            });
    }

    createProjectStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.projectStatusKey
            e.label = e.projectStatusDesc
            tempList.push(e)
        })

        return tempList
    }

    getArea = () => {
        let myURI = apiURL + 'AFE/Area'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    areaList: this.createAreaList(json),
                    isAreaListLoaded: true
                })
            });
    }
    createAreaList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.areaKey
            e.label = e.areaName
            tempList.push(e)
        })

        return tempList
    }

    getEmpList = () => {

        let myURI = apiURL + 'EmployeeDetails'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    empList: this.createEmpList(json),
                    isEmpListLoaded: true
                })
            });
    }

    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.employeeKey
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }

    getDupAFE = () => {
      
        const { user } = this.props

        let myURI = apiURL + 'AFE/Header/AFEDuplicate/AzureAD/' + user.account.userName

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    dupAFEList: this.createDupFEList(json),
                    isDupAFEListLoaded:true
                })
            });
    }

    createDupFEList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeNo
            e.label = e.afeNo + ' - ' + e.afeName
            tempList.push(e)
        })

        return tempList
    }


    // getUserCheckPoint = () => {
    //     let myURI = apiURL + 'AFE/AFECheckPoints/' + this.props.user.account.userName;

    //     console.log("myURI");
    //     console.log(myURI);

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 isUserCheckPointLoaded: true,
    //                 userCheckPoint: json
    //             })
    //         });
    // }

    setDefaultAFEQuery = () => {
        
    }

    async componentDidUpdate(prevProps,prevState){
        if(prevProps.constructionReportKey !== this.props.constructionReportKey){
            this.state.isLoaded = false;
            
             
            this.setState({
                isLoaded:true,
                constructionReportKey: prevProps.constructionReportKey,
                //projectTypeKey:this.props.children[3], 
            });
   
        }

         console.log('tewt');
    }

    componentDidMount = async () => {  
        //await this.loadView();
        this.getAFEConstructionReporting();
        //this.getAFEDetailsAPI();
        this.setState({  isDataLoaded: true });
    }

    getAFEDetailsAPI = async() => {
        const { constructionReportDTO } = this.state
        let myURI = apiURL + 'AFE/Header/' + constructionReportDTO.afeHeaderKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json, 
                })
            });
    }

    openAFEDetailsFromKey = (afeList) => {
        const { openAFEDetails } = this.state
        if (openAFEDetails) {
            const selAfe = afeList.filter(a => a.afeHeaderKey === this.state.afeHeaderKey)
            console.log("openAFEDetails")
            console.log(selAfe)

            if (selAfe.length > 0) {
                this.setState({
                    afeNo: selAfe[0].afeNo,
                })
            }
            this.toggleAFEDetails()
        }
    }

    newProject = () => {
        this.setState({
            constructionReportKey: -1
        })
        this.toggleAFEDetails()
    }

 

    openAFEBudgetList = () => {
        const { afeBudgetListURL } = this.state

        console.log("afeBudgetListURL")
        console.log(afeBudgetListURL)
        if (afeBudgetListURL.length > 0) {
            const newWindow = window.open(afeBudgetListURL[0].afeBudgetListURL, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null

        }

    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { fullAFEData } = this.state 

        switch (fieldName) {
            case "afeSearch":
                this.setState({ searchText: fieldValue.toLowerCase() })
                break;
            case "afeStatus":
                this.getFitlerAFE(fullAFEData, this.state.selAFEQuery, this.state.selBasin, e)
                this.setState({ selAFEStatus: e })
                break;
            case "basin":
                this.getFitlerAFE(fullAFEData, this.state.selAFEQuery, e, this.state.selAFEStatus)
                this.setState({ selBasin: e })
                break;
            case "afeQuery":
                this.getFitlerAFE(fullAFEData, e, this.state.selBasin, this.state.selAFEStatus)
                this.setState({ selAFEQuery: e })
                break;
            default:
                break;
        }
    }

    getFitlerAFE = (e, m, b, s) => {
        
        const { user } = this.props

        const basins = b.map(e => e.locationName).join(",")
        const status = s.map(e => e.afeStatusDesc).join(",")

        const userName = user.account.userName.toLowerCase()

        var tempAFE = e

        if (m !== null) {
            if (m.value === 1) {
                tempAFE = tempAFE.filter(e => (e.afeManagerAzureAD || "").toLowerCase() === userName ||
                    (e.createdByAzureAD || "").toLowerCase() === userName ||
                    (e.afeSponsorAzureAD || "").toLowerCase() === userName ||
                    (e.approvers || "").toLowerCase().includes(userName))
            }
        }

        if (basins.length > 0) {
            tempAFE = tempAFE.filter(e => basins.includes(e.basin))
        }

        if (status.length > 0) {
            tempAFE = tempAFE.filter(e => status.includes(e.afeStatusDesc))           
        }

        console.log("I am here first")
        console.log(tempAFE)
        this.setState({ AFEData: tempAFE.filter(x => x.getAFECostTypeDesc != 'Accounting') })

        console.log('in cacnels')
        console.log(s)
        s.map(e => {
            if (e.afeStatusKey === 5) {
                this.getAFEdataByKey(5).then((cancelAFE)=>{                  
                    tempAFE = [...tempAFE,...cancelAFE]                            
                    this.setState({ AFEData: tempAFE })
                })
                //
            }
            if (e.afeStatusKey === 8) {
                this.getAFEdataByKey(8).then((closeAFE)=>{
                    tempAFE = [...tempAFE,...closeAFE]
                    this.setState({AFEData: tempAFE})
                })
            }
            
        })

    }

    getAFEdataByKey(k){

        let myURI = apiURL + 'AFE/Header/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName + "/StatusKey/" + k;

        console.log("myURI");
        console.log(myURI);

        return(fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json()));

    }

    getAFEHeaderColor = (closeoutStatusDesc) => {

        if (closeoutStatusDesc !== null) {

            if (closeoutStatusDesc === "Rejected" || closeoutStatusDesc === "Cancelled" || closeoutStatusDesc === "Revised") {
                return "modal-header reject"
            } else if (closeoutStatusDesc === "Pending") {
                return "modal-header submit"
            } else if (closeoutStatusDesc !== "") {
                return "modal-header primary"
            }

        } else { return "" }
    }

    onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    }

    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            let obj = budgetList.filter(e => e.afeBudgetDesc === v) 
            if (obj === null || obj.length <=0) {
                return '';
            }
            return obj[0].afeBudgetDesc;
        }
        return ''
    }

    getDailyReport = (reportName) =>{
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    viewDetails = (projectType) =>{

        if(projectType ==='Pipeline'){
            this.setState({ modalPipeLineDailyReport: !this.state.modalPipeLineDailyReport  });
            } 
        else if (projectType ==='Facility'){
            this.setState({ modalFacilityDailyReport: !this.state.modalFacilityDailyReport  });
        }
        else if (projectType ==='General'){
            this.setState({ modalGeneralDailyReport: !this.state.modalGeneralDailyReport  });
        }

    }

    createReportParamter = (projectType) => {
        const { reportName, reportKey} = this.state 
        
        // if(projectType ==='Pipeline'){
        //     return [{ "name": "ReportKey", "value": reportKey }]; 
        // } 
        // else if (projectType ==='Facility'){
        //     return [{ "name": "ReportKey", "value": reportKey }]; 
        // }
        // else if (projectType ==='General'){
        //     return [{ "name": "ReportKey", "value": reportKey }]; 
        // }

        return  [{ "name": "ReportKey", "value": reportKey }];
    }
    createReportName = (projectType) => {
        const { reportName, reportKey} = this.state
        // let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKeyReport }]; 
        // return reportPara
        
        if(projectType ==='Pipeline'){
            return 'PipelineDailyReport'; 
        } 
        else if (projectType ==='Facility'){
            return 'FacilityDailyReport'; 
        }
        else if (projectType ==='General'){
            return 'GeneralDailyReport'; 
        }
    }
    

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }

    addDailyReport(value){
        this.setState({
            reportKey: 0
        });
        if (value == 'Pipeline'){
            this.toggleNewPipeLineDailyReport()
        }
        else if (value == 'Facility'){
            this.toggleNewFacilityDailyReport()
        }
        else if (value == 'General'){
            this.toggleNewGeneralDailyReport()
        } 
    }

    toggleNewFacilityDailyReport = () => {
        this.setState({
            modalFacilityDailyReport: !this.state.modalFacilityDailyReport
        })
    }
    closeFacilityDailyReport = async(edited) => {
        this.setState({ modalFacilityDailyReport: false,isDataLoaded: false, edited: (edited != undefined && edited != null ? edited : this.state.edited) });
        await this.getAFEConstructionReporting();
        this.setState({  isDataLoaded: true });
        this.props.toggleCloseDailyReport((edited != undefined && edited != null ? edited : this.state.edited));
    }

    toggleNewPipeLineDailyReport = () => {
        this.setState({
            modalPipeLineDailyReport: !this.state.modalPipeLineDailyReport
        })
    }
    closePipeLineDailyReport = (edited) => {
        this.setState({ modalPipeLineDailyReport: false,isDataLoaded: false ,edited:(edited != undefined && edited != null ? edited : this.state.edited)});
        this.getAFEConstructionReporting(); 
        this.setState({  isDataLoaded: true });
        this.props.toggleCloseDailyReport(edited != undefined && edited != null ? edited : this.state.edited);
    }

    toggleNewGeneralDailyReport = () => {
        this.setState({
            modalGeneralDailyReport: !this.state.modalGeneralDailyReport
        })
    }
    closeGeneralDailyReport = async(edited) => {
        this.setState({ modalGeneralDailyReport: false , edited: (edited != undefined && edited != null ? edited : this.state.edited)});
        await this.getAFEConstructionReporting(); 
        //await this.componentDidMount(); 
        this.props.toggleCloseDailyReport((edited != undefined && edited != null ? edited : this.state.edited))
    }
    render() {

        const { isDataLoaded, 
            constructionReportDTO, basinList , projectType ,  
            windowHeight, constructionReportKey, afeList , 
            empList, budgetList, facilityList ,
            costTypeList, afeTypeList, budgetClassList, 
            uomList, countyList ,  projectStatusList,  areaList,  
            dupAFEList ,constructionReports,reportKey,modalGeneralDailyReport,isSecurityAdminLogin} = this.state

     
        const gridHeight = (windowHeight * 0.75) + "px"
 
        if (!isDataLoaded ) {
            return (<div>
                <h2>Loading Report Data.....</h2>
            </div>)
        }
        else if (constructionReportDTO === undefined || constructionReportDTO === null){
            <h2>Loading Report Data.....</h2>
        } 
        else
            console.log('constructionReports: ' + constructionReports);

            let banner = 'New Construction Report Project'
            if (this.state.modalProjectDetails && constructionReportKey > 0){
                let objReport = constructionReports.filter (x => x.constructionReportKey == constructionReportKey);
                if (objReport !== null && objReport.length > 0){
                    banner = objReport[0].afeNo + ': ' + objReport[0].projectName + ' - ' +  objReport[0].statusDescription;
                }
            }


            console.log('constructionReportDTO: ' + constructionReportDTO);
            console.log('According projectType: ' + projectType)
            return (<div>
                <Form onSubmit={e => e.preventDefault()}> 
                    <FormGroup>
                        <Row form>
                            <Col sm={12}> 
                                <Button outline
                                    color="secondary"
                                    className="btn-no-border"
                                    onClick={this.getAFEConstructionReporting}>
                                    <img
                                        src={Refresh}
                                        alt="Refresh"
                                        style={{ width: "30px", margin: "0 5px 0 0" }} />
                                    Refresh
                                </Button>
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.saveWorkbook}>
                                    <img
                                        src={Excel}
                                        alt="excel"
                                        style={{ width: "50px", margin: "0 0 0 0" }} />
                                    Export To Excel
                                </Button>{' '}
                                
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    //onClick={this.newProject}
                                    onClick={ ()=> this.addDailyReport(projectType)}
                                    disabled={constructionReportDTO == undefined || (!isSecurityAdminLogin && constructionReportDTO.statusKey != 1 && constructionReportDTO.statusKey != 2)} //Has to be in an Started Status  
                                    >
                                    <img
                                        src={Plus}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    Add Report
                                </Button>{' '}
                                <Button 
                                    outline color="secondary"
                                    className="btn-no-border"
                                    disabled={reportKey === undefined || reportKey === null || reportKey <= 0 ? true: false} 
                                    onClick={() => this.viewDetails(projectType)}>
                                    <img
                                        src={EditIcon}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Details
                                </Button>{' '}  
                                <Button outline color="secondary"
                                    //className="btn-no-border"
                                    className="float-right"
                                    onClick={() => this.getDailyReport(projectType)}
                                    disabled={reportKey === undefined || reportKey === null || reportKey <= 0 ? true: false} 
                                    >
                                    <img
                                        src={Revise}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Report
                                </Button>{' '}
                                <Button outline color="secondary"
                                    //className="btn-no-border"
                                    className="float-right"
                                    onClick={this.newProject}
                                    disabled={true}
                                    hidden={true} 
                                    >
                                    <img
                                        src={Revise}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View All Reports
                                </Button>{' '}   
                            </Col>
                        </Row>
                        <ColoredLine color="grey" />
                    </FormGroup>  
                    <FormGroup>
                        <Row form style={{ zIndex: 0, position: 'relative' }} hidden={projectType == undefined || projectType == null || projectType !='Pipeline'}>
                            <IgrDataGrid
                                ref={this.onGridRef}
                                height={gridHeight}
                                width={"100%"} 
                                isColumnOptionsEnabled="true" 
                                editMode={0}
                                dataSource={constructionReports}
                                autoGenerateColumns="false"
                                selectionMode="SingleRow" 
                                selectedKeysChanged={this.onGridRowSelected}
                                cellClicked={this.onGridRowSelectedPipeLine}
                                cellTextStyle="14px Calibri" headerTextStyle="14px Calibri" rowHeight={"25"}
                            >
                                <IgrNumericColumn
                                    field="constructionReportKey"
                                    headerText={"ConstructionReportKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                
                                <IgrTemplateColumn 
                                    field="reportDate" 
                                    headerText="Report Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />

                                <IgrTextColumn 
                                    field="workSummary" 
                                    headerText="Summary"
                                    width="*>110" 
                                />
                                
                                <IgrTextColumn
                                    field="rowClearedCompletedToday" 
                                    headerText="ROW Cleared"
                                    width="*>150" 
                                /> 

                                
                                <IgrTextColumn 
                                    field="trenchDugCompletedToday" 
                                    headerText="Trench Dug"
                                    width="*>160" 
                                />   
                                <IgrTextColumn 
                                    field="pipeStrungCompletedToday" 
                                    headerText="Pipe Strung"
                                    width="*>140"
                                />
                                <IgrNumericColumn
                                    field="loweredInCompletedToday"
                                    headerText="Lowered In"
                                    width="*>160"
                                    //isHidden={true}
                                />

                                <IgrTextColumn 
                                    field="backFilledCompletedToday" 
                                    headerText="Back Filled"
                                    width="*>140"
                                />
                                <IgrTextColumn 
                                    field="rowCleanUpCompletedToday"
                                    headerText="ROW Clean-up"
                                    width="*>170" 
                                />
                                <IgrTextColumn 
                                    field="createdByKey"
                                    headerText="Cased Bore"
                                    width="*>170" 
                                />
                                <IgrTextColumn 
                                    field="createdBy"
                                    headerText="Uncased Bore"
                                    width="*>170"
                                    isHidden={true} 
                                /> 
                                <IgrTextColumn 
                                    field="lastModifiedByKey"
                                    headerText="Rock Encountered"
                                    width="*>170" 
                                /> 
                                <IgrTextColumn 
                                    field="tieInsCompletedToday"
                                    headerText="# Of Tie-Ins"
                                    width="*>170" 
                                />
                                 
                                <IgrTextColumn 
                                    field="fenceGapsCompletedToday"
                                    headerText="# Of Fence Gaps"
                                    width="*>170" 
                                />
                                <IgrTextColumn 
                                    field="hydrovacsCompletedToday"
                                    headerText="# Of Hydrovacs"
                                    width="*>170" 
                                />
                                <IgrTextColumn 
                                    field="matsCompletedToday"
                                    headerText="# Of Mats"
                                    width="*>170"
                                    isHidden={true} 
                                />
                                 
                                <IgrTextColumn 
                                    field="testStationsCompletedToday"
                                    headerText="# Of Test Stations"
                                    width="*>170" 
                                />
                                <IgrTextColumn 
                                    field="pipeSupportsCompletedToday"
                                    headerText="Pipe Support"
                                    width="*>170" 
                                />     
                            </IgrDataGrid>
                        </Row>
                        <Row form style={{ zIndex: 0, position: 'relative' }} hidden={projectType == undefined || projectType == null || projectType !='Facility'}>
                            <IgrDataGrid
                                ref={this.onGridRef}
                                height={gridHeight}
                                width={"100%"} 
                                isColumnOptionsEnabled="true" 
                                editMode={0}
                               dataSource={constructionReports}
                                autoGenerateColumns="false"
                                selectionMode="SingleRow" 
                                selectedKeysChanged={this.onGridRowSelected}
                                cellClicked={this.onGridRowSelectedFacility} 
                            >
                                <IgrNumericColumn
                                    field="constructionReportFacilityKey"
                                    headerText={"ConstructionReportGeneralKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                <IgrNumericColumn
                                    field="constructionReportKey"
                                    headerText={"ConstructionReportKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                
                                <IgrTemplateColumn 
                                    field="reportDate" 
                                    headerText="Report Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />

                                <IgrTextColumn 
                                    field="workSummary" 
                                    headerText="Summary"
                                    width="*>110" 
                                />
                                
                                <IgrTextColumn
                                    field='padConstruction' 
                                    headerText="Pad Construction"
                                    width="*>150"
                                /> 

                                
                                <IgrTextColumn 
                                    field="concreteContainment" 
                                    headerText="Containment"
                                    width="*>160" 
                                    //cellUpdating={this.onStatusCellUpdating}
                                />  
                                <IgrTextColumn 
                                    field="tanksInstalled" 
                                    headerText="Tanks"
                                    width="*>140"
                                />  
                                <IgrNumericColumn
                                    field="pumpsInstalled"
                                    headerText="Pumps"
                                    width="*>160" 
                                />
                                <IgrTextColumn 
                                    field="basin" 
                                    headerText="Piping"
                                    width="*>140"
                                />
                                <IgrTextColumn 
                                    field="automationElectrical"
                                    headerText="Automation/Electrical"
                                    width="*>170" 
                                />
                                <IgrTextColumn 
                                    field="createdByKey"
                                    headerText="Created By Key"
                                    width="*>170"
                                    isHidden={true} 
                                />
                                <IgrTextColumn 
                                    field="createdBy"
                                    headerText="Created By"
                                    width="*>170"
                                    isHidden={true} 
                                /> 
                                <IgrTextColumn 
                                    field="lastModifiedByKey"
                                    headerText="lastModifiedByKey"
                                    width="*>170"
                                    isHidden={true} 
                                />
                                <IgrTextColumn 
                                    field="lastModifiedBy"
                                    headerText="lastModifiedBy"
                                    width="*>170"
                                    isHidden={true} 
                                />
                                <IgrTextColumn 
                                    field="createdDate"
                                    headerText="createdDate"
                                    width="*>170"
                                    isHidden={true} 
                                />
                                <IgrTextColumn 
                                    field="lastModifiedDate"
                                    headerText="lastModifiedDate"
                                    width="*>170"
                                    isHidden={true} 
                                /> 
                                 
                                <IgrTextColumn 
                                    field="statusKey"
                                    headerText="statusKey"
                                    width="*>170" 
                                    isHidden={true}
                                />
                                <IgrTextColumn 
                                    field="statusDescription"
                                    headerText="statusDescription"
                                    width="*>170"
                                    isHidden={true} 
                                /> 
                            </IgrDataGrid>
                        </Row>
                        <Row form style={{ zIndex: 0, position: 'relative' }} hidden={projectType == undefined || projectType == null || projectType !='General'}>
                            <IgrDataGrid
                                ref={this.onGridRef}
                                height={gridHeight}
                                width={"100%"} 
                                isColumnOptionsEnabled="true" 
                                editMode={0}
                               dataSource={constructionReports}
                                autoGenerateColumns="false"
                                selectionMode="SingleRow" 
                                selectedKeysChanged={this.onGridRowSelected}
                                cellClicked={this.onGridRowSelected2}
                         
                            >
                                <IgrNumericColumn
                                    field="constructionReportGeneralKey"
                                    headerText={"ConstructionReportGeneralKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                <IgrNumericColumn
                                    field="constructionReportKey"
                                    headerText={"ConstructionReportKey"}
                                    width="*>160"
                                    isHidden={true}
                                /> 
                                <IgrTemplateColumn 
                                    field="reportDate" 
                                    headerText="Report Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />

                                <IgrTextColumn 
                                    field="workSummary" 
                                    headerText="Summary"
                                    width="*>110" 
                                />
                                <IgrTextColumn 
                                    field="status" 
                                    headerText="ReportStatus"
                                    width="*>110" 
                                />
                                <IgrNumericColumn
                                    field="estimatedCompletion"
                                    headerText="EstimatedCompletion"
                                    width="*>160"
                                    isHidden={false}
                                />
                                <IgrTemplateColumn
                                    field="issues" 
                                    headerText="Issues"
                                    width="*>150"
                                    cellUpdating={this.onStatusCellUpdating} 
                                /> 
                                <IgrTemplateColumn
                                    field="integrityTestingComplete" 
                                    headerText="IntegrityTestingComplete"
                                    width="*>150"
                                    cellUpdating={this.onStatusCellUpdating} 
                                />
                                <IgrTextColumn
                                    field="inspectorName" 
                                    headerText="InspectorName"
                                    width="*>150" 
                                />
                                <IgrTextColumn
                                    field="createdBy" 
                                    headerText="CreatedBy"
                                    width="*>150" 
                                />
                                 
                                 
                            </IgrDataGrid>
                        </Row>
                    </FormGroup>

                    <div>
                        <Modal isOpen={this.state.modalFacilityDailyReport}
                            toggle={() => this.toggleNewFacilityDailyReport()}
                            size="xl"
                            backdrop={"static"}
                            //onClosed={() => this.getAFEdata()}
                            >
                            <ModalHeader toggle={() => this.closeFacilityDailyReport()}
                                //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                                >
                                {"Facility Daily Report Form"}
                            </ModalHeader>
                            <ModalBody>
                                <DailyFacilityReport 
                                    toggle={this.closeFacilityDailyReport}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    constructionReportKey={this.props.constructionReportKey}
                                    constructionReportFacilityKey={reportKey}
                                    constructionReportDTO={constructionReportDTO}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList} 
                                    constructionReports={constructionReports} 
                                    isSecurityAdminLogin={isSecurityAdminLogin}
                                    >
                                </DailyFacilityReport>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.modalPipeLineDailyReport}
                            toggle={() => this.toggleNewPipeLineDailyReport()}
                            size="xl"
                            backdrop={"static"}
                            //onClosed={() => this.getAFEdata()}
                            >
                            <ModalHeader toggle={() => this.closePipeLineDailyReport()}
                                //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                                >
                                {"Pipeline Daily Report Form"}
                            </ModalHeader>
                            <ModalBody>
                                <DailyPipeLineReport 
                                    toggle={this.closePipeLineDailyReport}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    constructionReportKey={this.props.constructionReportKey} //constructionReportKey}
                                    constructionReportPipelineKey={reportKey}
                                    constructionReportDTO={constructionReportDTO}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList}
                                    constructionReports={constructionReports}
                                    isSecurityAdminLogin={isSecurityAdminLogin} 
                                    >
                                </DailyPipeLineReport>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={modalGeneralDailyReport}
                            toggle={() => this.closeGeneralDailyReport()}
                            size="xl"
                            backdrop={"static"}
                            //onClosed={() => this.getAFEdata()}
                            >
                            <ModalHeader toggle={() => this.closeGeneralDailyReport()}
                                //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                                >
                                {"General Daily Report Form"}
                            </ModalHeader>
                            <ModalBody>
                                <DailyGeneralReport 
                                    toggle={this.closeGeneralDailyReport}
                                    //setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    constructionReportKey={this.props.constructionReportKey} //constructionReportKey}
                                    constructionReportGeneralKey={reportKey}
                                    constructionReportDTO={constructionReportDTO}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList}
                                    constructionReports={constructionReports}
                                    isSecurityAdminLogin={isSecurityAdminLogin} 
                                    >
                                </DailyGeneralReport>
                            </ModalBody>
                        </Modal>
                    </div>

                    <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            size={"xl"}
                            centered
                            >
                            <ModalHeader toggle={() => this.toggleReport()}> {() => this.createReportParamter(projectType)} </ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName = {this.createReportName(projectType)} 
                                    reportParameters = {this.createReportParamter(projectType)}
                                                        
                                    toggle={this.toggleReport}
                                
                                >

                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div>  
                </Form>
            </div >)

    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.statusKey === 3 || item.closeoutStatusKey === 3) {
                content.style.background = "#f0f5b0";
            } else if (item.statusKey === 1 || item.statusKey === 5 ||
                item.closeoutStatusKey === 1 || item.closeoutStatusKey === 10) {
                content.style.background = "#F3B5A2";
            } else if (item.statusKey === 6) {
                content.style.background = "#b8f2b1";
            }
            else {
                content.style.background = "transparent";
            }

            if (s.field === "actualvsBudget") {
                if (item.actualvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.actualvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.actualvsBudget > 104.44 && item.actualvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }
            if (s.field === "projectedvsBudget") {
                if (item.projectedvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.projectedvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.projectedvsBudget > 104.44 && item.projectedvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }


        }

        switch (s.field) {
            case 'afeStatusDesc': 
                link.textContent = "60%";
                break;
            case 'afeName':
                link.textContent = item.afeName;
                break;
            case 'reportDate':
                link.textContent = ""
                if (item.reportDate !== null) {
                    var date = new Date(item.reportDate);
                    link.textContent = date.toLocaleDateString();

                }
                content.style.background = "transparent";
                break;
            case 'afeNo':
                link.textContent = item.afeNo;
                break;

            case 'afeApprovedDate': 
                link.textContent = ""

                if (item.afeApprovedDate !== null) {
                    var date = new Date(item.afeApprovedDate);
                    link.textContent = date.toLocaleDateString(); 

                }
                content.style.background = "transparent";
                break;
            case 'createdDatetime':
                link.textContent = ""
                if (item.createdDatetime !== null) {
                    var date = new Date(item.createdDatetime);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'estStartDate':
                link.textContent = ""
                if (item.estStartDate !== null) {
                    var date = new Date(item.estStartDate);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'requiredEndDate':
                link.textContent = ""
                if (item.requiredEndDate !== null) {
                    var date = new Date(item.requiredEndDate);
                    link.textContent = date.toLocaleDateString(); 

                }
                content.style.background = "transparent";
                break;
            case 'estComplete':
                link.textContent = ""
                if (item.estComplete !== null) {
                    var date = new Date(item.estComplete);
                    link.textContent = date.toLocaleDateString();
                    // content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'managerName':
                link.textContent = item.managerName;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            case 'actualvsBudget':
                link.textContent = (item.actualvsBudget >= 1 ? Math.round(item.actualvsBudget) : item.actualvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'projectedvsBudget':
                link.textContent = (item.projectedvsBudget >= 1 ? Math.round(item.projectedvsBudget) : item.projectedvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'budgetID':
                link.textContent = item.budgetID === undefined || item.budgetID === null ? '' : this.getSelBudget(item.budgetID);
                content.style.background = "transparent";
                break;
            case 'issues':
                link.textContent = item.issues === 1 ? 'Yes' : 'No';
                break;
            case 'integrityTestingComplete':
                link.textContent = item.integrityTestingComplete === 1 ? 'Yes' : 'No';
                break;
            default:

        }

    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "Daily Reports");
    }

}