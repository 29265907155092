
import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import NumberFormat from 'react-number-format';
import GlobeBW from '../../resources/globebw.svg'

var DatePicker = require("reactstrap-date-picker");

export default class AFEAdditionalDetails extends Component {
    static displayName = AFEAdditionalDetails.name;

    constructor(props) {
        super(props)

        this.state = {
            afeData: props.afeData,
            isFormReadOnly: props.isFormReadOnly,
            projectStatusList: props.projectStatusList
        }
    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { afeData } = this.state
        var showAlert = false
        switch (fieldName) {
            case "actualStartDate":

                afeData[0].actualStartDate = fieldValue
                break;
                
            case "inServiceDate":
                afeData[0].inServiceDate = fieldValue
                break;
            case "actualEndDate":
                afeData[0].actualEndDate = fieldValue
                break;
            case "estStartDate2":
                afeData[0].estStartDate2 = fieldValue
                break;
            case "estEndDate2":
                afeData[0].estEndDate2 = fieldValue
                break;
            case "lat":
                afeData[0].lat = fieldValue
                break;
            case "long":
                afeData[0].long = fieldValue
                break;
            case "projectStatus":
                afeData[0].projectStatusKey = null
                if (e !== null) {
                    afeData[0].projectStatusKey = e.value
                }
                break;
            default:
                break;
        }

        this.setState({ afeData: afeData })
        this.props.onTextChange(fieldName, fieldValue, e)
    }

    openInspInGooleMap = () => {
        const { afeData } = this.state
        const url = "https://www.google.com/maps/search/?api=1&query=" + afeData[0].lat + "," + afeData[0].long
        window.open(url, '_blank')
    }

    getSelProjStatus = (v) => {
        const { projectStatusList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return projectStatusList.filter(e => e.projectStatusKey === v)
        }
        return ""
    }

    render() {
        const { isFormReadOnly, afeData, projectStatusList } = this.state
        return (<div>
            <Form>
                <Row form style={{ marginTop: "5px" }}>
                    <Col md={4}>
                        {/* <Label invalid for="projectStatus">Project Status</Label>

                        <Select options={projectStatusList}
                            isDisabled={isFormReadOnly}
                            //className={(!validateDataItem.county ? "form-error" : "")}
                            value={this.getSelProjStatus(afeData[0].projectStatusKey)}
                            defaultValue={this.getSelProjStatus(afeData[0].projectStatusKey)}
                            onChange={(e) => this.onTextChange("projectStatus", e, e)}
                            isClearable={true}

                        >
                        </Select> */}

                        <Label invalid for="inServiceDate">In-Service Date</Label>

                        <DatePicker value={afeData[0].inServiceDate}
                            //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("inServiceDate", v, f)}
                            disabled={isFormReadOnly}
                        />


                    </Col>

                    <Col md={4}>
                        <Label invalid for="estStartDate2">Updated Start Date</Label>

                        <DatePicker value={afeData[0].estStartDate2}
                            //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("estStartDate2", v, f)}
                            disabled={isFormReadOnly}
                        />


                    </Col>

                    <Col md={4}>

                        <Label invalid for="actualStartDate">Actual Start Date</Label>

                        <DatePicker value={afeData[0].actualStartDate}
                            //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("actualStartDate", v, f)}
                            disabled={isFormReadOnly}
                        />

                    </Col>
                </Row>
                <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>

                    <Col md={4}>
                        <Label invalid for="coordinate">Estimated Coordinate</Label>

                        <Row form>
                            <Col md={11}>
                                <Row form >
                                    <Col md={6}>
                                        <NumberFormat value={afeData[0].lat}
                                            onValueChange={(e) => this.onTextChange("lat", e.value, e)}
                                            className={("form-control text-md-right")}
                                            fixedDecimalScale={true}
                                            //decimalScale={2}
                                            //displayType={'text'} 
                                            disabled={isFormReadOnly}
                                            thousandSeparator={true}
                                        />

                                    </Col>

                                    <Col md={6}>
                                        <NumberFormat value={afeData[0].long}
                                            onValueChange={(e) => this.onTextChange("long", e.value, e)}
                                            className={("form-control text-md-right")}
                                            fixedDecimalScale={true}
                                            //decimalScale={2}
                                            //displayType={'text'} 
                                            disabled={isFormReadOnly}
                                            thousandSeparator={true}
                                        />

                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}>
                                <Button className="float-right"
                                    outline
                                    color="link"
                                    style={{ padding: "0 0 8px 0" }}
                                    id="googleMap"
                                    //hidden={isNaN(opsInspCmt.facilityInspectionHdrKey)} // linkDataLevel!=3}
                                    onClick={(e) => this.openInspInGooleMap()}>
                                    <img
                                        src={GlobeBW}
                                        alt="Map"
                                        style={{ width: "20px", marginTop: "5px" }} />

                                </Button>
                            </Col>
                        </Row>


                    </Col>

                    <Col md={4}>
                        <Label invalid for="estEndDate2">Updated End Date</Label>
                        <DatePicker value={afeData[0].estEndDate2}
                            // className={(!validateDataItem.estStartDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("estEndDate2", v, f)}
                            disabled={isFormReadOnly}
                        />

                    </Col>

                    <Col md={4}>

                        <Label invalid for="actualEndDate">Actual End Date</Label>
                        <DatePicker value={afeData[0].actualEndDate}
                            // className={(!validateDataItem.estStartDate ? "form-error" : "")}
                            onChange={(v, f) => this.onTextChange("actualEndDate", v, f)}
                            disabled={isFormReadOnly}
                        />

                    </Col>
                </Row>
            </Form>
        </div>)
    }


}