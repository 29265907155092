//Ready for to Push to Master from local Master 10/14/21

import React, { Component, useEffect, useState } from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';

import { apiURL, apiURLSvc, apiSyncFusionAzureFileManagerURL, apiHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';
import RefreshIcon from '../resources/refresh.svg';
import PlusIcon from '../resources/plus.svg';
import TrashIcon from '../resources/trash.svg';
import ExcelIcon from '../resources/excel.svg';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from '../operation/ExcelUtility';
import UserDetails from './UserDetails'
import { ColoredLine, getFirstDayofMonth, useWindowDimensions } from '../resources/utility';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'
import { ServerRequestParameters } from 'msal/lib-commonjs/ServerRequestParameters';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

const UserGroupforAbout = (props) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [isDBInfoLoaded, setIsDBInfoLoaded] = useState(false)
    const [users, setUsers] = useState([])
    const [admin, setAdmin] = useState([])

    const [userGroupEmployeeLinkKey, setUserGroupEmployeeLinkKey] = useState([])
    const [user, setUser] = useState(props.userInput)
    const [modalUserGroup, setModalUserGroup] = useState(false)
    const [dbInfo, setDBInfo] = useState("")

    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
    //var users=[]




    const getUserGroup = () => {
        const myURL = apiURL + 'SecAdmin/User/' + props.UserGroupKey + '/UserGroup'
        //  console.log("UserUserGroup ")       
        //  console.log(myURL)

        fetch(myURL, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                setIsLoaded(true);
                setUsers(json);
                // console.log(JSON.stringify(json))

                setAdmin(json.filter(rec => rec.userGroupName === "Administrator").length)
                // console.log("jsondn: " + json.filter(rec => rec.userGroupName === "Administrator").length);
                // console.log("jsondn: " + json.filter(rec => rec.userGroupName === "Administrator").length);
            })
            .catch(error => {
                console.log("GET Error: " + error);
            })
    }


    const getDBInfo = () => {
        const myURL = apiURL + 'SecAdmin/DBConnectionInfo'
        //  console.log("UserUserGroup ")       
        //  console.log(myURL)

        fetch(myURL, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                setIsDBInfoLoaded(true);

                console.log("dbInfo")
                console.log(json)
                if (json.length > 0) {
                    setDBInfo(json[0].server_name + '(' + json[0].db_name + ')');
                }
            })
            .catch(error => {
                console.log("GET Error: " + error);
            })
    }


    useEffect(() => {

        getUserGroup()
        getDBInfo()


    }, [props.UserGroupKey])

    if (!isLoaded) {
        return (<div>
            <h4> loading users list ....</h4>
        </div>)
    } else if (!isDBInfoLoaded) {
        return (<div>
            <h4> loading dbInfo ....</h4>
        </div>)
    } else
        return (<div>
            <FormGroup>
                <p style={{ marginBottom: "0px" }} className="font-weight-bold"> {"Connection Information:"}</p>
                <p style={{ marginBottom: "0px", marginLeft: "20px" }}>
                    {admin > 0 ? apiURL + " --> " + dbInfo + '\n' : apiURL.slice(0, 20) + '\n'}
                    {admin > 0 ? apiURLSvc + '\n' : apiURLSvc.slice(0, 20) + '\n'}
                    {admin > 0 ? apiSyncFusionAzureFileManagerURL : apiSyncFusionAzureFileManagerURL.slice(0, 20)}
                </p>
            </FormGroup>

            <FormGroup>


                <IgrDataGrid
                    // ref={this.onGridRef}
                    height="300px"
                    //width="Calc(100% - 5px)"
                    //width="100%"
                    dataSource={users}
                    autoGenerateColumns="false"

                >

                    <IgrTextColumn field="userGroupName" headerText="Group Name" width="*>140" isEditable="false" />

                    <IgrTextColumn field="userGroupDesc" headerText="Description" width="*>100" isEditable="false" />

                    <IgrTextColumn field="empName" headerText="Employee Name" width="*>100" isEditable="false" />

                    <IgrTextColumn field="emailAddress" headerText="Email Address" width="*>100" isEditable="false" />

                    <IgrTextColumn field="adAccount" headerText="AD account" width="*>100" isEditable="false" />

                    <IgrTextColumn field="deptID" headerText="Department ID" width="*>100" isEditable="false" />

                    <IgrTextColumn field="empStatusDesc" headerText="Employee Status Description" width="*>100" isEditable="false" />

                    <IgrTextColumn field="locationName" headerText="Location Name" width="*>100" isEditable="false" />

                    <IgrTextColumn field="memo" headerText="Memo" width="*>100" isEditable="false" />

                    <IgrTextColumn field="azureAD" headerText="Azure AD" width="*>100" isEditable="false" />

                    {/* <IgrTextColumn field="managerKey" headerText="Azure AD" width="*>100" isEditable = "false" /> */}

                    <IgrTextColumn field="userGroupKey" isHidden />

                    <IgrTextColumn field="userGroupEmployeeLinkKey" isHidden />

                    <IgrTextColumn field="employeeKey" isHidden />

                </IgrDataGrid>


            </FormGroup>


        </div>)

}


export default UserGroupforAbout