import React from 'react';
import { InputGroup, Input } from 'reactstrap';





const SearchBox = (props) => {

    
const onTextChange = (v,e) =>{
    props.onTextChange(v,e)
}

    return (
        <div className="w-25">
            <InputGroup>            
                <Input placeholder="Begin Search.."
                onChange={(e) => onTextChange(e.target.value,e)}/>
            </InputGroup>
        </div>
    );
};

export default SearchBox;