
import React, { useState } from 'react';
import { Col, Row, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText, FormFeedback } from 'reactstrap';
// import { apiURL, apiHeader } from '../../resources/apiURL';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import { Form, CustomInput, Container } from 'reactstrap';
import Select from 'react-select';


const SafetyIncidentNewApproval = (props) => {

  // const {buttonColor,
  //       buttonLabel,
  //       className,
  //       formName,
  //       facilityEventKey} = props

  // var ddtyp;
  var ddemp;
  // var desc;


  const [modal, setModal] = useState(false);
  const [invalid, setInvalid] = useState(false)
  const [invalidEmp, setInvalidEmp] = useState(false)
  const [emp, setEmp] = useState("")
  const [comment, setComment] = useState("")

  const [appr, setAppr] = useState(false)
  const [editappr, setEditAppr] = useState(false)
  const [emai, setEmai] = useState(false)
  const [invalidEmaiAppr, setInvalidEmaiAppr] = useState(false)

  // const [ddtyp, setDdtyp] = useState("")

  const toggle = () => setModal(!modal);

  const toggleOK = () => {
    if (comment === "" || emp === "" || (appr === false && editappr === false && emai === false)) {
      if (comment === "") { setInvalid(true) }
      if (emp === "") { setInvalidEmp(true) }
      if (appr === false && editappr === false && emai === false) { setInvalidEmaiAppr(true) }
    } else {
      setModal(!modal)
      // props.onClickOK(ddKey.value)
      props.onClickOK(emp, comment, appr, editappr, emai)
    }


  }

  const createEmpList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.employeeKey
      e.label = e.empName
      tempList.push(e)
    })

    return tempList
  }

  const onTextChange = (fieldName, fieldValue, e) => {
    switch (fieldName) {



      // case "type":

      //     setDdtyp(fieldValue)
      //     break;
      case "employee":
        // ddemp = fieldValue;
        // setEmp(fieldValue)
        // setInvalidEmp(fieldValue === "")
        if (fieldValue != null) {
          setEmp(fieldValue.value)
          // setLocValue(fieldValue)
          // setInvalidLoc(fieldValue === "")
        }
        else {
          setEmp("")
          // setLocValue("")
          setInvalidEmp(true)

        }


        break;
      case "comment":
        // desc = fieldValue;
        setComment(fieldValue)
        setInvalid(fieldValue === "")
        break;

      case "approval":
        // appr = fieldValue;
        setAppr(!appr)
        if (fieldValue) { setEditAppr(false) }

        setInvalidEmaiAppr(fieldValue === false && editappr === false && emai === false)
        // var ee = invalidEmaiAppr
        // setInvalidApprEmail(fieldValue === "")
        break;
      case "editapproval":
        // appr = fieldValue;
        setEditAppr(!editappr)
        if (fieldValue) { setAppr(false) }
        setInvalidEmaiAppr(fieldValue === false && editappr === false && emai === false)
        // var ee = invalidEmaiAppr
        // setInvalidApprEmail(fieldValue === "")
        break;
      case "email":
        // appr = fieldValue;

        setEmai(!emai)
        setInvalidEmaiAppr(appr === false && editappr === false && fieldValue === false)
        // setInvalidApprEmail(fieldValue === "")
        // var zz = invalidEmaiAppr
        break;

      default:
    }


  }

  const onClosed = () => {
    setComment("")
    setInvalid(false)
    setEmp("")
    setInvalidEmp(false)
    setAppr(false)
    setEditAppr(false)
    setEmai(false)
    setInvalidEmaiAppr(false)
    // if (props.onClosed !== undefined) {
    //     props.onClosed();
    // }
  }




  return (
    <div>
      {/* <Button 
                    // outline = {facilityEventKey > 0 ? false : true} 
                    // color={buttonColor}
                    onClick={toggle} style={{ margin: "0 5px 0 5px" }} >
                Add New Report
            </Button> */}
      <Button outline color="secondary"
        className="btn-no-border"
        disabled={props.disabled}
        onClick={toggle}

      >
        <img
          src={Plus}
          alt="new"
          style={{ width: "25px", margin: "0 5px 0 0" }} />
        Add user for approval or email
      </Button>
      <Modal isOpen={modal}
        toggle={toggle}
        onClosed={onClosed}
      >
        <ModalHeader toggle={toggle}> New Approval</ModalHeader>
        <ModalBody>

          <div>

            <FormGroup row>
              <Col md={12}>



                {/* 1 row */}
                <FormGroup row>

                  <Col md={4}>
                    <Label for="employee">Employee</Label>
                  </Col>

                  <Col md={8}>


                    {/* <CustomInput
                      type="select"
                      id="empKey"
                      name="empKey"
                      invalid={invalidEmp}

                      onChange={(e) => { onTextChange("employee", e.target.value, e) }}
                    >
                      <option value=""></option>

                      {props.ddemp.map(v => <option value={v.employeeKey}>{v.empName}</option>)}

                    </CustomInput> */}
                    <div>
                      <Select options={createEmpList(props.ddemp)}
                        className={(invalidEmp ? "form-error" : "")}
                        // isDisabled={isFormReadOnly}
                        // value={locValue}
                        // defaultValue={this.getSelInvLoc(rcvrDetail[0].toLocationKey)}
                        onChange={(e) => onTextChange("employee", e, e)}
                        isClearable={true}
                      >
                      </Select>
                    </div>

                  </Col>

                </FormGroup>
                {/* <FormGroup row>

                                    <Col md={3}>
                                        <Label for="Type">Type</Label>
                                    </Col>
                                    <Col md={9}>


                                        <CustomInput
                                            type="select"
                                            id="ddKey"
                                            name="ddKey"
                                           
                                            onChange={(e) => { onTextChange("type", e.target.value, e) }}
                                        >
                                            <option value=""></option>

                                            {props.ddropdown.map(v => <option value={v.typeKey}>{v.typeDesc}</option>)}
                                        </CustomInput>




                                    </Col>
                                </FormGroup> */}
                <FormGroup row >
                  <Col md={4} >
                    <Label for="aproval">Approval</Label>
                  </Col>
                  <Col md={8}>

                    <input type="checkbox"
                      id="approval"
                      invalid={invalidEmaiAppr}
                      label={invalidEmaiAppr ? "<--" : ""}
                      // checked={this.bMultiField(data[0].incidentType, 1)}
                      // onChange={(e) => this.onTextChange("incidentType", 1, e)}
                      checked={appr}
                      onChange={(e) => { onTextChange("approval", e.target.checked, e) }}





                    />
                  </Col>


                </FormGroup>

                <FormGroup row >
                  <Col md={4} >
                    <Label for="editaproval">Edit and Approval</Label>
                  </Col>
                  <Col md={8}>

                    <input type="checkbox"
                      id="editapproval"
                      invalid={invalidEmaiAppr}
                      label={invalidEmaiAppr ? "<--" : ""}
                      // checked={this.bMultiField(data[0].incidentType, 1)}
                      // onChange={(e) => this.onTextChange("incidentType", 1, e)}
                      checked={editappr}
                      onChange={(e) => { onTextChange("editapproval", e.target.checked, e) }}





                    />
                    {/* <Label for="fdf" >Emafffil</Label> */}
                  </Col>


                </FormGroup>


                <FormGroup row>
                  <Col md={4}>
                    <Label for="email" >Email</Label>
                  </Col>
                  <Col md={8}>

                    <input type="checkbox"
                      id="email"
                      invalid={invalidEmaiAppr}
                      label={invalidEmaiAppr ? "<--" : ""}
                      // checked={this.bMultiField(data[0].incidentType, 1)}
                      // onChange={(e) => this.onTextChange("incidentType", 1, e)}
                      checked={emai}
                      onChange={(e) => { onTextChange("email", e.target.checked, e) }}

                      inline



                    />
                  </Col>


                </FormGroup>

                <FormGroup row>

                  <Col md={4}>
                    <Label for="comment">Comment</Label>
                  </Col>
                  <Col md={8}>

                    <Input
                      // invalid={!validateDataItem.hazardDesc}
                      invalid={invalid}
                      type="textarea" name="comment" rows={5}
                      id="comment"
                      placeholder="please enter something here"
                      // value={data[0].comments}
                      // onChange={(e) => this.onTextChange("description", e.target.value, e)}
                      onChange={(e) => { onTextChange("comment", e.target.value, e) }}
                    // defaultValue = {observation[0].hazardDesc}                               
                    // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                    // disabled={isReadOnly} 

                    />





                  </Col>
                </FormGroup>













              </Col>


            </FormGroup>


          </div>


        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleOK}>OK</Button>
          <Button outline color="primary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SafetyIncidentNewApproval;