

import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './DDMenu.css';
import { Link } from 'react-router-dom';

const DDProfile = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
    // this.items = this.
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className={props.ClassName} color="light" light style={{ margin: "2px 10px 0 -10px" }}>
                {props.DDName}
            </DropdownToggle>

            <DropdownMenu className="last-dropdown">

                {props.DDItems.map(function (item) {
                    if (item.divider === "1") {
                        return <DropdownItem divider key={item.id}/>;
                    } if (item.disabled === "1") {
                        return <DropdownItem disabled tag={Link} to={item.URL} key={item.id}>{item.name}</DropdownItem>;
                    } else if (item.header === "1") {
                        return <DropdownItem header key={item.id}>{item.name}</DropdownItem>;
                    }
                    else {
                        return <DropdownItem tag={Link} to={item.URL} key={item.id}>{item.name}</DropdownItem>;
                    }

                })
                }

            </DropdownMenu>
        </Dropdown>
    );
}

export default DDProfile;