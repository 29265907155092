import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Nav, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './WBR.png';
import './NavMenu.css';
import DDMenuTemplate from './menu/DDMenuTemplate';
import DDProfile from './menu/DDProfile';
import SearchBox from './menu/SearchBox';
import { apiURL } from '../resources/apiURL';
import AppModules from '../resources/AppModules';

function AdministratorMenu(props) {
  const isAllow = props.isAllow
  if (isAllow) {
    return <DDMenuTemplate DDName="Administrator" ClassName="no-border"
      DDItems={[{ id: 1, name: "Management", URL: "", header: "1" },
      { id: 10, name: "Users", URL: "/users" },
      { id: 12, name: "UserGroups", URL: "/userGroups" },
      { id: 13, name: "Security Checkpoints", URL: "/secCheckHome" },
      { id: 131, name: "Job Profiles", URL: "/jobProfile" },
      { id: 14, name: "", URL: "", divider: "1" },
      { id: 15, name: "Email Notification Manager", URL: "/emailnotificationmanager" },                
      { id: 230325, name: "Loss Power Procedure Manager", URL: "/LossOfUtilityPowerProcedure" },
      { id: 18, name: "Approvals", URL: "/fetch-data" },
      { id: 16, name: "Application Modules", URL: "/fetch-data" },
      { id: 17, name: "System Maintenance", URL: "/fetch-data" }]} />;
  }
  return null
}

function OperationMenu(props) {
  const isAllow = props.isAllow;
  if (isAllow) {
    return <DDMenuTemplate DDName="Operation" ClassName="no-border"
      DDItems={[
        { id: 200, name: "Operator Dashboard", URL: "/operator-dashboard" },
        { id: 201, name: "", URL: "", divider: "1" },
        { id: 220, name: "Asset Management", URL: "", header: "1" },
        { id: 2204, name: "All Assets", URL: "/asset-all" },
        { id: 2205, name: "All Assets Map", URL: "/asset-map" },
        { id: 225, name: "Wellbore", URL: "/Wellbore" },
        //{ id: 225, name: "Pond", URL: "/pond" },
        {
          id: 2303, name: "Water Sales", URL: "", dropdown: "1", direction: "right",
          DDItems: [{ id: 230304, name: "Pond", URL: "/pond" },
          { id: 230310, name: "Pond Level", URL: "/PondLvl" },
          { id: 230315, name: "Pond Depth Chart", URL: "/pondDepth" },
          { id: 230316, name: "Water Quality Tracking", URL: "/pondwqt" },
           {id: 230317, name: "Chemical Inventory", URL: "/pondcinv" },
           {id: 230318, name: "Pond Job", URL: "/pondjob" },
           {id: 230319, name: "Reuse Connect", URL: "/pcnt" },         
           {id: 230321, name: "", URL: "", divider: "1" },
           {id: 230322, name: "Pond Inspection", URL: "/pni" }
        ]
        

        },


        {
          id: 221, name: "Facility", URL: "", dropdown: "1", direction: "right",
          DDItems: [{ id: 230304, name: "Facility", URL: "/facility" },
          { id: 230310, name: "Daily Inspections", URL: "/facility-insp" },
          { id: 230315, name: "Quarterly Inspections", URL: "/fqi" },
          { id: 230320, name: "Lightning Inspections", URL: "/facility-lighting-insp" },
          { id: 230326, name: "Third Party Lightning Inspections", URL: "/insp3li" },          
          { id: 230335, name: "Loss Power Procedure", URL: "/FacilityLPF" },
          { id: 230335, name: "Communication Tower Inspections", URL: "/cominsp" }
        ]

        },

        { id: 222, name: "Meters", URL: "/facility-meter" },
        { id: 223, name: "Tanks", URL: "/facility-tank" },
        //Created by BN 3/22/22
        { id: 2322, name: "Riser", URL: "/riser" },
        
        { id: 23001, name: "Flowback", URL: "", header: "1" },
        { id: 23005, name: "Preflowback Checklist", URL: "/pfbchklist" },        
        { id: 23010, name: "POP", URL: "/pop" },        
        { id: 23015, name: "POP Tasks", URL: "/pts" },        
        
        { id: 230, name: "Management", URL: "", header: "1" },
        { id: 234, name: "Tank Volumes", URL: "/tankVol" },
        { id: 235, name: "Inspections Dashboard", URL: "/insp-dashboard" },
        { id: 236, name: "Inspections", URL: "/facility-insp" },
        // { id: 237, name: "Events", URL: "/facility-event" },
        // { id: 238, name: "Costs", URL: "/facility-cost" },
        { id: 239, name: "Oil Tickets", URL: "/facility-oil" },
        { id: 2301, name: "Meter Validation", URL: "/meter-val" },
        // Created by BN 12/7/21
        { id: 2302, name: "Facility Asset", URL: "/inventory?view=facility" },
        { id: 2304, name: "Supplier Request", URL: "/supplierrequest" },
        { id: 2314, name: "Prefered Supplier List", URL: "/PreferSup" },
        { id: 2404, name: "PO Request", URL: "/porequest" }, //BN 08/23/23
        
      ]} />;
  }
  return null

}

function QHSEMenu(props) {
  const isAllow = props.isAllow
  if (isAllow) {
    return <DDMenuTemplate DDName="QHSE" ClassName="no-border"
      DDItems={[{ id: 1, name: "Safety Observations", URL: "/safety-obs" },
      { id: 100, name: "Safety Incidents", URL: "/SafetyIncident" }, //by dn
      { id: 103, name: "JSA", URL: "/SafetyIncJSA" }, //by dn
      { id: 101, name: "Ergonomic Evaluation Request", URL: "/ErgonomicEvaluationRequest" }, //by dn
      { id: 30, name: "", URL: "", divider: "1" },
      { id: 31, name: "Standard Procedures and Programs", URL: "/qhse-sop" }
      ]} />;
  }
  return null
}

function AccountingMenu(props) {
  const isAllow = props.isAllow
  if (isAllow == "Yes") {
    return <DDMenuTemplate DDName="Accounting" ClassName="no-border"
      DDItems={[{ id: 42, name: "Management", URL: "", header: "1" },
      { id: 41, name: "AP Tickets", URL: "apticket" },
      { id: 43, name: "Statement Reconciliation", URL: "/apticket/Statements" }, //HN-11/18/21
      { id: 44, name: "Dupplicate Invoice", URL: "/apticket/Duplicates" },//HN-11/18/21
      { id: 402, name: "Supplier Request", URL: "/supplierrequest" },
      { id: 46, name: "", URL: "", divider: "1" },
      { id: 45, name: "O&G Energy Link", URL: "/OGEnergyLink" },
      // { id: 49, name: "", URL: "", divider: "1" },
      { id: 401, name: "Desert Reclamation Invoice", URL: "/invoiceHeader" }, //HN-11/09/22
      { id: 405, name: "JIB", URL: "/jibHeader" }, 
      { id: 410, name: "Customer Contract", URL: "/customercontract" },

        // { id: 403, name: "Statement Recon", URL: "/fetch-data" }
      ]} />;
  }
  return null;
}


function LandMenu(props) {
  const isAllow = props.isAllow
  if (isAllow) {
    return <DDMenuTemplate DDName="Land" ClassName="no-border"
      DDItems={[
        { id: 530, name: "Land Connect", URL: "/lcn" },
        { id: 535, name: "", URL: "", divider: "1" },
        { id: 510, name: "Management", URL: "", header: "1" },
        { id: 58, name: "Land Owners", URL: "/supplier" }

      ]} />;
  }
  return null
}

function MOCWOMenu(props) {
  const isAllow = props.isAllow
  if (isAllow) {
    return <DDMenuTemplate DDName="CMMS" ClassName="no-border"
      DDItems={[
        { id: 2306, name: "ACE Work Orders", URL: "/WOHome" },
        { id: 2307, name: "WO KPI Dashboard", URL: "/WOSpotfireHome" },
        // { id: 28, name: "WAVE", URL: "/fetch-data" }
        { id: 28, name: "MOC", URL: "/moc" }
      ]} />;
  }
  return null
}

function SCMenu2(props) {
  const isAllow = props.isAllow
  if (isAllow) {
    return <DDMenuTemplate DDName="Supply Chain" ClassName="no-border"
      DDItems={[
        { id: 800, name: "Management", URL: "", header: "1" },
        { id: 810, name: "Supplier", URL: "/supplier" },
        { id: 820, name: "Supplier Request", URL: "/supplierrequest" },
        { id: 830, name: "Preferred Supplier List", URL: "/PreferSup" },
        { id: 840, name: "Equipment Rental", URL: "/EquipRental" },
        { id: 860, name: "Field Housing", URL: "/fieldhousing" },

        { id: 57, name: "Purchase Order", URL: "/po" },
        { id: 404, name: "PO Request", URL: "/porequest" }, //BN 08/23/23
        { id: 654, name: "", URL: "", divider: "1" },
        { id: 520, name: "Material", URL: "", header: "1" },
        { id: 55, name: "Receive", URL: "/receiver" },
        { id: 551, name: "Issue/Request", URL: "/materialrequest" },
        { id: 561, name: "Return", URL: "/materialreturn" },
        { id: 571, name: "Transfer", URL: "/materialrequest" },
        { id: 688, name: "", URL: "", divider: "1" }, //BN 11/29/30 Add per Josh Request
        { id: 500, name: "Data Management", URL: "", header: "1" },
        { id: 5, name: "Item Master", URL: "/itemmaster" },
        { id: 550, name: "Current Inventory", URL: "/iteminventory" },
        { id: 59, name: "Warehouse/Yard", URL: "/warehouse" },
        { id: 599, name: "Location Details", URL: "/invLocation" },
        { id: 591, name: "Material Movement Report", URL: "/materialmovement" },
        { id: 689, name: "", URL: "", divider: "1" }, //BN 11/29/30 Add per Josh Request
        { id: 560, name: "Fleet", URL: "", header: "1" },
        { id: 592, name: "Fleet Master Spreadsheet", URL: "/inventory?view=fleet" },
        { id: 850, name: "DVI Report", URL: "/divreport" },
      ]} />;
  }
  return null
}


//Budgeting and Planning
function BPMenu(props) {
  const { isAllow, user } = props
  if (isAllow) {
    return <DDMenuTemplate DDName={(user.account.userName === "Hau.Nguyen" ? "N.O.R.M.A" : "AFE")} ClassName="no-border"
      DDItems={[{ id: 350, name: "AFE Manager", URL: "/afe" },
                { id: 351, name: "Construction Report", URL: "/constructionReporting" },
                {id: 380, name: "Booster Tracking", URL: "/btk" },
      { id: 355, name: "", URL: "", divider: "1" },
      { id: 360, name: "Water Connect", URL: "/wtc" }
      ]} />;
  }
  return null
}


function UserProfileMenu(props) {
  const name = props.user.account.name.trim();
  const email = props.user.account.userName;

  //version history

  const ver = "About"

  var initial = name.substring(0, 1) + name.substring(name.indexOf(" ") + 1, name.indexOf(" ") + 2);
  return <DDProfile DDName={initial} ClassName="btn-circle"
    DDItems={[{ id: 51, name: name, URL: "/Counter" },
    { id: 52, name: "", URL: "", divider: "1" },
    { id: 53, name: email, URL: "/fetch-data" },
    { id: 54, name: "", URL: "", divider: "1" },
    { id: 60, name: ver, URL: "/About" }

    ]} />;


  //return (< NavItem >

  //    <Button outline color="success" size="lg" className="btn-circle" > Hau </Button>{' '}

  //       </NavItem >);
}

function ConstructionReporting(props){
  const { isAllow, user } = props
  if (isAllow) {
    return <DDMenuTemplate DDName={(user.account.userName === "hau.nguyen@h2obridge.com" ? "N.O.R.M.A" : "Construction Report")} ClassName="no-border"
      DDItems={[{ id: 351, name: "Construction Report", URL: "/constructionReport" }
      ]} />;
  }
  return null
}
//<NavLink tag={Link} className="text-dark btn btn-circle btn-lg" to="/fetch-data">{initial}</NavLink>

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.user = props.user;
    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  // onSearchTextChange(v,e){
  //   console.log("SearchText: " + v)
  // }

  render() {

    let myURI = apiURL + 'EmployeeDetails/AppModules/' + this.user.account.userName;
    return (
      <AppModules url={myURI}>
        {
          ({ loading, error, data }) => {
            if (loading) return <h1> loading </h1>
            if (error) return <h1> error </h1>

            return (
              <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light expand="md">

                  <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />

                  <Nav>
                    <NavbarBrand tag={Link} to="/" style={{ width: "180px" }}>
                      <img
                        src={logo}
                        alt="logo"
                        style={{ width: "40px", margin: "0 10px 0 0" }}
                      />
                      WaterBridge
                    </NavbarBrand>
                  </Nav>

                  <SearchBox onTextChange={this.props.onSearchTextChange} />


                  <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                    <ul className="navbar-nav flex-grow">

                      <NavLink tag={Link} className="text-dark" to="/" style={{ margin: "0 10px 0 0" }}>Home</NavLink>

                      <AdministratorMenu isAllow={(data.filter(ID => ID.appModuleID === "ADMIN").length > 0 ? true : false)} />

                      <OperationMenu isAllow={(data.filter(ID => ID.appModuleID === "OP").length > 0 ? true : false)}
                        onSearchTextChange={this.onSearchTextChange} />

                      <MOCWOMenu isAllow={(data.filter(ID => ID.appModuleID === "OP").length > 0 ? true : false)}
                        onSearchTextChange={this.onSearchTextChange} />



                      <QHSEMenu isAllow={(data.filter(ID => ID.appModuleID === "QHSE").length > 0 ? true : false)} />


                      <SCMenu2 isAllow={(data.filter(ID => ID.appModuleID === "SC").length > 0 ? true : false)} />

                      <BPMenu user={this.user} isAllow={(data.filter(ID => ID.appModuleID === "BP").length > 0 ? true : false)} />

                      <LandMenu user={this.user} isAllow={(data.filter(ID => ID.appModuleID === "BP").length > 0 ? true : false)} />

                      
                      <AccountingMenu isAllow={(data.filter(ID => ID.appModuleID === "ACCT").length > 0 ? "Yes" : "No")} />

                      {/* <SCMenu isAllow={(data.filter(ID => ID.appModuleID === "SC").length > 0 ? true : false)} /> */}

                      {/* <ConstructionReporting isAllow={(data.filter(ID => ID.appModuleID === "ACCT").length > 0 ? "Yes" : "No")} /> */}
                      {/* <NavLink tag={Link} className="text-dark" to="/constructionReporting" style={{ margin: "0 10px 0 0" }}>Construction Reporting</NavLink> */}

                      <UserProfileMenu user={this.user} />

                    </ul>
                  </Collapse>

                </Navbar>

                <div>

                  {/* //<pre>{data.filter(ID => ID.appModuleID==="ACCT").length > 0 ? "true" : "false"}</pre>
                          //<pre>{JSON.stringify(data,null,2)}</pre> */}
                  {/* <pre>{myURI}  {this.user.userName}</pre> */}
                </div>
              </header>
            );
          }
        }
      </AppModules>

    );
  }
}


//<NavItem>
//    <NavLink tag={Link} className="text-dark" to="/fetch-data">{this.user.account.name}</NavLink>
//</NavItem>