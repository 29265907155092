import React, { Component} from 'react';
import {FormGroup} from 'reactstrap';
import '../../custom.css';
import { apiURL, apiHeader } from '../../resources/apiURL';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';

import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';


// import { copySync } from 'fs-extra';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();



export default class ItemTransDetail extends Component {
    static displayName = ItemTransDetail.name;

    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);

        this.state = {
            itemMasterKey : props.itemMasterKey,
            isOnHandByLocLoaded:false,
            transaction:[],
            windowHeight: window.innerHeight
        }

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.onHandByLoc;

    }




    getOnHandByLoc = () => {
        let myURI = apiURL + 'ItemMaster/Inventory/ByItem/' + this.props.itemMasterKey

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isOnHandByLocLoaded: true,
                    onHandByLoc: json
                })
            });
    }

    componentDidMount=()=>{
        this.getOnHandByLoc()
    }

    render(){
        const {onHandByLoc, isOnHandByLocLoaded, windowHeight} = this.state
        const gridHeight = (windowHeight * 0.25) + "px"
        if(!isOnHandByLocLoaded){
            return (<div>
                <h3>Loading Transaction Details......</h3>
            </div>)
        }
        else    
        return(<div>
             {/* <Row form style={{ marginTop: "5px", marginBottom: "2px" }}></Row> */}
             <FormGroup>
                                 <IgrDataGrid
                                        ref={this.onGridRef}
                                        height={gridHeight}
                                        // height="200%"
                                        dataSource={onHandByLoc}
                                        autoGenerateColumns="false"
                                        selectionMode="SingleRow"
                                    //filterUIType="FilterRow"
                                    >

                                        <IgrTextColumn field="locationName" headerText="Location Name" width="*>180"

                                        />

                                        <IgrTextColumn field="warehouseInvLocation" headerText="Warehouse Inventory Location" width="*>180"

                                        />

                                        <IgrTextColumn field="itemStatus" headerText="Item Status" width="*>180"

                                        />


                                        <IgrTextColumn field="locQtyOH" headerText="Location Qty On Hand" width="*>180"

                                        />

                                        <IgrTextColumn field="lastQtyChgTransCode" headerText="Last Qty Change Trans Code" width="*>200"

                                        />

                                        <IgrTextColumn field="lastQtyChgDateTime" headerText="Last Qty Change Date" width="*>180"

                                        />

                                    </IgrDataGrid>
                                </FormGroup>
        </div>)
    }

}