
import  {Component} from 'react';
import { apiHeader} from '../resources/apiURL';

export default class FetchData extends Component{
    state = {
        loading: true,
        error: false,
        data: [],
    };

    componentDidMount(){
        fetch(this.props.url,apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then((data) => this.setState({data, loading: false}))
        .catch((err) => this.setState({loading: false, error: true}))
    }

    render(){
        return (
            <div>
                {this.props.children({
                    ...this.props,
                    ...this.state,
                })}
            </div>
        )
    }
}