

import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, Card, CardBody, CardHeader } from 'reactstrap'; 
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import { Color, ComponentRenderer, LinkedListNode$1 } from 'igniteui-react-core';
import '../../custom.css';
//import '../custom.css';
import { v4 as uuidv4 } from 'uuid';

 

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';
//import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../resources/utility';
import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate,getUserADAccount } from '../../resources/utility'
import { prop } from 'ramda';
import NumberFormat from 'react-number-format';
var DatePicker = require("reactstrap-date-picker");

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



export default class 
AFEManualEdits extends Component {
    static displayName = AFEManualEdits.name;
    constructor(props) {
        super(props)
 
        this.state = {
            showAlert: false,
            alertMsg: '',
            alertColor: "danger", 
            afeDTO: props.afeDTO,
            afeDTOCopy: props.afeDTO,
            user: props.user,  
            validateDataItem: {
                projectName: true,
                afeSponsor: true,
                projectManager: true,
                budgetClassification: true,
                entity: true,
                county: true,
                area: true,
                budgetID: true,
                wbOwnedPct: true,
                projectScope: true,
                afeDescription: true
            },
            afeTypeList: props.afeTypeList,
            empList: props.empList,
            linkAFEList: props.linkAFEList,
            budgetClassList: props.budgetClassList,
            budgetList: props.budgetList,
            costTypeList: props.costTypeList,
            basinList: props.basinList,
            countyList: props.countyList,
            areaList: props.areaList,
            isSavingData: false, 
        }
    }
 
    componentDidMount() {
         
        this.setState({isDataLoaded: true});
    }
   
    saveData = async()=>{
        const { afeDTO } = this.state
 
            this.dataValidation().then((data) => {
                if (data.ok) { 
                     this.updateAFE(); 
                } else {
                    this.setState({
                        alertMessage: data.message,
                        validateDataItem: data.validateDataItem,
                        showAlert: true
                    })
                }
            }) 
    }

    updateAFE = () => {
        this.setState({isSavingData: true});
        const updateAFEDTO = this.updateAFEDTO()
        //console.log(updateAFEDTO)
        
        let myURI = apiURL + 'AFE/Header/ManualEdits'

        fetch(myURI, apiPUTHeader(updateAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg,
                            isSavingData: false,
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.setState({
                    afeHeaderKey: json.afeHeaderKey,
                    isDataChange: false,
                    isSavingData: false,
                })
 
                this.closeForm();
                
            }).catch(error => {
                console.log(error)
            })
    }

    updateAFEDTO = () => {
        const { user } = this.props
        const { afeDTO } = this.state
        const afeUpdateDTO = {
            "AFEHeaderKey": afeDTO.afeHeaderKey,
            "AFENo": afeDTO.afeNo,
            "AFEName": afeDTO.afeName,
            "StatusKey": afeDTO.statusKey,
            "AFEManagerKey": afeDTO.afeManagerKey,
            "AFESponsor": afeDTO.afeSponsor,
            "AFEDescription": afeDTO.afeDescription,
            "Comments": afeDTO.comments,
            "TimingChange": afeDTO.timingChange,
            "TypeKey": afeDTO.typeKey,
            "BudgetClasskey": afeDTO.budgetClassKey,
            "EstStartDate": afeDTO.estStartDate,
            "EstComplete": afeDTO.estComplete,
            "BudgetID": afeDTO.budgetID,
            "WBOwnedPct": afeDTO.wbOwnedPct,
            "WDAFENo": afeDTO.wdAFENo,
            "FacilityKey": afeDTO.facilityKey,
            "WellName": afeDTO.wellName,
            "Lease": afeDTO.lease,
            "APINumber": afeDTO.apiNumber,
            "County": afeDTO.county,
            "Basin": afeDTO.basin,
            "State": afeDTO.state,
            "SurfaceLegal": afeDTO.surfaceLegal,
            "BottomHoleLegal": afeDTO.bottomHoleLegal,
            "Area": afeDTO.area,
            "ModifiedBy": user.account.userName,
            "AFECostType": afeDTO.afeCostType,
            "LinkAFE": afeDTO.linkAFE,
            "LocationKey": afeDTO.locationKey,
            "intangibleContigencyRate": afeDTO.intangibleContigencyRate / 100.00,
            "tangibleContigencyRate": afeDTO.tangibleContigencyRate / 100.00,
            "ProjectHierachy": afeDTO.projectHierachy,
            "CostCenterID": afeDTO.costCenterID,
            "CompanyID": afeDTO.companyID,
            "SiteID": afeDTO.siteID,
            "FixAssetsEnteredBy": afeDTO.fixAssetsEnteredBy,
            "FixAssetsModifiedDate": afeDTO.fixAssetsModifiedDate,
            "ActualStartDate": afeDTO.actualStartDate,
            "actualEndDate": afeDTO.actualEndDate,
            "lat": afeDTO.lat,
            "long": afeDTO.long,
            "estStartDate2": afeDTO.estStartDate2,
            "estEndDate2": afeDTO.estEndDate2,
            "projectStatusKey": afeDTO.projectStatusKey,
            "areaKey": afeDTO.areaKey,
            "tangibleTaxRate": afeDTO.tangibleTaxRate / 100.00,
            "intangibleTaxRate": afeDTO.intangibleTaxRate / 100.00,
            "closeoutStatusKey": afeDTO.closeoutStatusKey,
            "projectedAmount": afeDTO.projectedAmount,
            "projectedAmntComments": afeDTO.projectedAmntComments,
            "inServiceDate": afeDTO.inServiceDate,
            "SiteTransfer": afeDTO.siteTransfer,
            "ReimbursableProject": afeDTO.reimbursableProject
        }

        return afeUpdateDTO;
    }

    cancel=()=>{
        //this.setState({afeDTO:  this.state.afeDTOCopy});
        this.props.cancelSave();
    }

    closeForm=()=>{
        this.props.closeForm();
    }

    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const { afeDTO, validateDataItem ,constructionReports,closeoutChkFilter} = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }

 
            if (afeDTO.afeName === undefined || afeDTO.afeName === ''){ 
                validateDataItem.projectName = false 
                returnVal.ok = false
                returnVal.message = returnVal.message + " Project Name"
                //alertMessage = "Comment is required for question 2.";
            }
            
            if (afeDTO.afeSponsor === null || afeDTO.afeSponsor === "") {
                validateDataItem.afeSponsor = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Project Sponsor"
            }

            if (afeDTO.afeManagerKey === null || isNaN(afeDTO.afeManagerKey)) {
                validateDataItem.projectManager = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Project Manager"
            }

            if (afeDTO.budgetClassKey === null || isNaN(afeDTO.budgetClassKey)) {
                validateDataItem.budgetClassKey = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Budget Classification"
            } else {
                if (Number(afeDTO.budgetClassKey) === 1) {
                    if (afeDTO.budgetID === null || afeDTO.budgetID === "") {
                        validateDataItem.budgetID = false
                        returnVal.ok = false
                        if (returnVal.message !== "") {
                            returnVal.message = returnVal.message + ","
                        }
                        returnVal.message = returnVal.message + " Budget ID"
                    }
                }
            }
            returnVal.validateDataItem = validateDataItem;
            if (returnVal.message !== "") {
                returnVal.message = "Please enter missing data: " + returnVal.message
            }

            resolve(returnVal)
        })
    }
  
    toggleAlert = () => { 
        this.setState({ showAlert: !this.state.showAlert  });
    }
  
    onTextChange = (fieldName, fieldValue, e) => {
        const { afeDTO, validateDataItem } = this.state
        const { user } = this.props
        var showAlert = false
        var isForceBudgeLineUpdate = false
        var budgetLineRefreshKey = 0

        console.log(fieldName + ' ------ ' + fieldValue)
        console.log(fieldValue)

        switch (fieldName) {
            case "field":
                afeDTO.field = fieldValue
                break;
            case "bottomHoleLegal":
                afeDTO.bottomHoleLegal = fieldValue
                break;
            case "surfaceLegal":
                afeDTO.surfaceLegal = fieldValue
                break;
            case "apiNumber":
                afeDTO.apiNumber = fieldValue
                break;
            case "wellName":
                afeDTO.wellName = fieldValue
                break;
            case "lease":
                afeDTO.lease = fieldValue
                break;
            case "estStartDate":
                afeDTO.estStartDate = fieldValue
                validateDataItem.estStartDate = true
                validateDataItem.estComplete = true
                break;
            case "actualStartDate":

                afeDTO.actualStartDate = fieldValue
                break;
            case "actualEndDate":
                afeDTO.actualEndDate = fieldValue
                break;

            case "estComplete":
                afeDTO.estComplete = fieldValue
                validateDataItem.estComplete = true
                validateDataItem.estStartDate = true
                break;
            case "afeName":
                afeDTO.afeName = fieldValue
                validateDataItem.afeName = true
                showAlert = false
                break;
            case "afeSponsor":
                afeDTO.afeSponsor = null
                if (e !== null) {
                    afeDTO.afeSponsor = e.employeeKey
                    validateDataItem.afeSponsor = true
                    showAlert = false
                }
                break;
            case "budgetID":
                afeDTO.budgetID = null
                afeDTO.afeBudgetAmount = 0.0
                if (e !== null && e !== undefined) {
                    afeDTO.budgetID = e.value
                    afeDTO.afeBudgetAmount = e.afeBudgetAmount
                    validateDataItem.budgetID = true
                    showAlert = false
                }
                break;
            case "county":
                afeDTO.county = null
                afeDTO.state = null
                if (e !== null && e !== undefined) {
                    afeDTO.county = e.value
                    afeDTO.state = e.stateID
                    validateDataItem.county = true
                    showAlert = false
                }
                break;
            case "basin":
                afeDTO.basin = fieldValue

                break;
            case "state":
                afeDTO.state = fieldValue
                break;
            case "areaKey":
                afeDTO.areaKey = -1

                if (e !== null && e !== undefined) {
                    afeDTO.areaKey = e.value
                    validateDataItem.area = true
                    showAlert = false
                }
                break;
            case "wbOwnedPct":
                afeDTO.wbOwnedPct = fieldValue
                afeDTO.nonOperatedWIPct = 100.0 - Number(fieldValue)
                validateDataItem.wbOwnedPct = true
                showAlert = false
                break;
            case "afeDescription":
                afeDTO.afeDescription = fieldValue;
                validateDataItem.afeDescription = true;
                showAlert = false;
                break;
            case "afeManagerKey":
                afeDTO.afeManagerKey = null
                if (e !== null && e !== undefined) {
                    afeDTO.afeManagerKey = e.employeeKey
                    validateDataItem.projectManager = true
                    showAlert = false
                }

                break;
            case "budgetClassKey":
                afeDTO.budgetClassKey = e.afeBudgetClassKey
                validateDataItem.budgetClassification = true
                validateDataItem.budgetID = true
                showAlert = false
                if (e.afeBudgetClassKey !== 1) {
                    afeDTO.budgetID = null
                }
                break;
            case "typeKey":

                afeDTO.typeKey = e.afeTypeKey
                validateDataItem.typeKey = true
                validateDataItem.linkAFE = true

                if (e.afeTypeKey === 0) {
                    afeDTO.linkAFE = ""
                }

                showAlert = false
                break;
            case "timingChange":
                afeDTO.timingChange = e.value
                break;
            case "afeCostType":
                afeDTO.afeCostType = this.getAFETypeCostString(e)
                validateDataItem.afeCostType = true
                isForceBudgeLineUpdate = true
                budgetLineRefreshKey = Math.random()
                showAlert = false
                break;
            case "linkAFE":
                afeDTO.linkAFE = ""
                if (e !== null || e != undefined) {
                    afeDTO.linkAFE = e.value
                    validateDataItem.linkAFE = true
                }
                showAlert = false
                break;
            case "locationKey":
                afeDTO.areaKey = -1
                afeDTO.locationKey = e.value
                validateDataItem.locationKey = true



                /*Comment out per Haydent request 03/30/3022
                default to null and force user to enter value */
                // if (afeDTO.locationKey === 51) {
                //     afeDTO.wbOwnedPct = 50.00
                // } else {
                //     afeDTO.wbOwnedPct = 100
                // }

                showAlert = false
                break;
            case "intangibleContigencyRate":
                afeDTO.intangibleContigencyRate = e.value

                break;
            case "tangibleContigencyRate":
                afeDTO.tangibleContigencyRate = e.value
                break;
            case "intangibleTaxRate":
                afeDTO.intangibleTaxRate = e.value
                break;
            case "tangibleTaxRate":
                afeDTO.tangibleTaxRate = e.value
                break;
            case "companyID":
                afeDTO.companyID = ""
                //afeDTO.companyName=""
                if (e !== null && e !== undefined) {
                    if (afeDTO.companyID !== e.value) {
                        afeDTO.fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    }
                    afeDTO.companyID = e.value
                    afeDTO.companyName = e.label

                    showAlert = false
                }
                break;
            case "projectHierachy":
                afeDTO.projectHierachy = ""

                if (e !== null && e !== undefined) {
                    // if (afeDTO.projectHierachy !== e.value) {
                    //     afeDTO.fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    // }

                    afeDTO.projectHierachy = e.value
                    showAlert = false

                }

                break;
            case "siteID":
                afeDTO.siteID = ""
                //afeDTO.siteName = ""

                if (e !== null && e !== undefined) {
                    if (afeDTO.siteID !== e.value) {
                        afeDTO.fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    }
                    afeDTO.siteID = e.value
                    afeDTO.siteName = e.label
                    showAlert = false

                }
                break;
            case "costCenterID":
                afeDTO.costCenterID = ""
                // afeDTO.costCenterName = ""
                if (e !== null && e !== undefined) {
                    if (afeDTO.costCenterID !== e.value) {
                        afeDTO.fixAssetsEnteredBy = getUserADAccount(user.account.userName)
                    }

                    afeDTO.costCenterID = e.value
                    afeDTO.costCenterName = e.label

                    showAlert = false
                }
                break;
            case "facilityKey":

                //afeDTO.basin = ""
                afeDTO.area = ""
                if (e !== null && e !== undefined) {
                    afeDTO.facilityKey = e.value
                    if (!isNaN(e.value)) {
                        afeDTO.basin = e.locationName
                        afeDTO.area = e.areaName
                    }
                } else {
                    afeDTO.facilityKey = null
                }
            case "estStartDate2":
                afeDTO.estStartDate2 = fieldValue
                break;
            case "estEndDate2":
                afeDTO.estEndDate2 = fieldValue
                break;
            case "lat":
                afeDTO.lat = fieldValue
                break;
            case "long":
                afeDTO.long = fieldValue
                break;
            case "projectStatus":
                afeDTO.projectStatusKey = null
                if (e !== null) {
                    afeDTO.projectStatusKey = e.value
                }
                break;
            case "projectedAmount":
                afeDTO.projectedAmount = fieldValue
                break;
            case "projectedAmntComments":
                afeDTO.projectedAmntComments = fieldValue
                break;
            case "siteTransfer":
                afeDTO.siteTransfer = fieldValue;
                break;
            case 'reimbursable':
                afeDTO.reimbursableProject = fieldValue.value < 0 ? null : fieldValue.value;
                break;
            default:
                break;
        }

        this.setState({
            afeDTO: afeDTO,
            validateDataItem: validateDataItem,
            isForceBudgeLineUpdate: isForceBudgeLineUpdate,
            budgetLineRefreshKey: budgetLineRefreshKey,
            showAlert: showAlert,
            isDataChange: true
        })
    }
    
    getSelAFEType = (v) => {
        const { afeTypeList } = this.state
        if (v !== null && v !== "") {
            return afeTypeList.filter(e => e.afeTypeKey === v);
        }
        return ""
    }

    getSelEmp = (v) => {
        const { empList } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return empList.filter(e => e.employeeKey === Number(v));
        }
        return ""

    }

    getSelLinkAFE = (v) => {
        const { linkAFEList } = this.state
        if (v !== null && v !== "") {
            return linkAFEList.filter(e => e.project_ID === v);
        }
        return ""

    }

    getSelBudgetClass = (v) => {
        const { budgetClassList } = this.state
        if (v !== null && v !== "") {
            return budgetClassList.filter(e => e.afeBudgetClassKey === v);
        }
        return ""
    }

    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            return budgetList.filter(e => e.afeBudgetDesc === v);
        }
        return ""
    }

    getSelAFECostType = (value) => {
        const { costTypeList } = this.state

        if (value !== null && value !== "") {
            return costTypeList.filter(e => value.includes(e.afeLineTypeKey))
        }
        return ""
    }

    getSelBasin = (v) => {
        const { basinList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            // console.log("v")
            // console.log(v)
            return basinList.filter(e => e.locationKey === Number(v))
        }
        return ""
    }

    getSelCounty = (v) => {
        const { countyList } = this.state
 
        if (v !== null && v !== "") { 
            return countyList.filter(e => e.countyDesc === v)
        }
        return ""
    }

    getSelArea = (v) => {
        const { areaList } = this.state 
        if (v !== null && !isNaN(v)) {
            return areaList.filter(e => e.areaKey === v)
        }
        return ""
    }

    render() {
        const {afeDTO,isDataLoaded ,alertMessage,showAlert ,alertColor,linkAFEList,empList,budgetClassList,budgetList,costTypeList
            ,basinList,countyList,areaList,afeTypeList,validateDataItem,isSavingData} = this.state
 
 
        if (!isDataLoaded) {
            return (<div>Loading Screen .......</div>)
        }
        else if (isSavingData)
            return (<div>Saving Data .......</div>) 
        else{
              
            return (<div>
                <Form>
                    <Row form style={{ marginTop: "1px", marginBottom: "5px" }} > 
                        <Col sm={4} className="float-left">  
                            <Button color="danger"
                                className="float-left"
                                style={{ marginRight: "5px" }}  
                                //hidden={ constructionReportDTO.statusKey == 3 || constructionReportDTO.statusKey == 4 || constructionReportDTO.statusKey == 5}
                                onClick={() => this.cancel()}
                                >Cancel</Button>
                            <Button color="primary" 
                                className="float-left"
                                style={{ marginRight: "5px" }} 
                                onClick={() => this.saveData()}
                                //hidden={ constructionReportDTO.statusKey == 3 || constructionReportDTO.statusKey == 4 || constructionReportDTO.statusKey == 5}
                                >
                                Save
                                </Button>
                        </Col>
                    </Row>
                    <ColoredLine color="grey" />
                    <Row form>  
                            <Alert color={alertColor} toggle={() => this.toggleAlert()}
                                isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                                {alertMessage}
                            </Alert >
                           
                    </Row>
                    
                    <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label for="afeNo">AFE No</Label>
                                </Col>
                                <Col md={4}>
                                    <Input disabled type="text" name="afeNo"
                                        id="afeNo" placeholder=""
                                        value={afeDTO.afeNo}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Input disabled type="text" name="afeStatusDesc"
                                        id="afeStatusDesc" placeholder=""
                                        value={afeDTO.afeStatusDesc}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label for="afeName">Project Name</Label>
                                </Col>
                                <Col md={8}>
                                    <Input type="text" name="afeName"
                                        className={(!validateDataItem.projectName ? "form-error" : "")}
                                        id="afeName" placeholder=""
                                        //disabled={this.isFormReadOnly() || statusKey === 4}
                                        onChange={(e) => this.onTextChange("afeName", e.target.value, e)}
                                        value={afeDTO.afeName}
                                    />
                                </Col>

                            </Row>
                        </Col>
                    </Row>  
                    <Row form style={{ marginBottom: "5px" }}> 
                        <Col md={6}> 
                            <Row form>
                                <Col md={4}>
                                    <Label for="afeSponsor">Project Sponsor </Label>
                                </Col>
                                <Col md={8}>
                                    {/* <Col md={8}> */}

                                    <Select options={empList}
                                        //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                        //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                        className={(!validateDataItem.afeSponsor ? "form-error" : "")}
                                        value={this.getSelEmp(afeDTO.afeSponsor)}
                                        defaultValue={this.getSelEmp(afeDTO.afeSponsor)}
                                        onChange={(e) => this.onTextChange("afeSponsor", e, e)}
                                        isClearable={true}

                                    >
                                    </Select> 
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label invalid for="afeManager">Project Manager</Label> 
                                </Col>
                                <Col md={8}> 
                                    <Select options={empList}
                                        //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                        //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                        className={(!validateDataItem.projectManager ? "form-error" : "")}
                                        value={this.getSelEmp(afeDTO.afeManagerKey)}
                                        defaultValue={this.getSelEmp(afeDTO.afeManagerKey)}
                                        onChange={(e) => this.onTextChange("afeManagerKey", e, e)}
                                        isClearable={true} 
                                    >
                                    </Select>
                                </Col>
                            </Row> 
                        </Col>


                    </Row>
                    <Row form style={{ marginBottom: "5px" }}>
                         
                    </Row> 
                    <Row form style={{ marginBottom: "5px" }}> 
                        <Col md={6}> 
                            <Row form>
                                <Col md={4}>
                                    <Label invalid for="budgetClass">Budget Classification</Label> 
                                </Col>
                                <Col md={8}> 
                                    <Select 
                                        options={budgetClassList}  
                                        className={(!validateDataItem.budgetClassification ? "form-error" : "")}
                                        value={this.getSelBudgetClass(afeDTO.budgetClassKey)}
                                        defaultValue={this.getSelBudgetClass(afeDTO.budgetClassKey)}
                                        onChange={(e) => this.onTextChange("budgetClassKey", e, e)} 
                                    >
                                    </Select>
                                </Col> 
                            </Row>
                            
                        </Col>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label for="budgetID">Budget ID</Label> 
                                </Col> 
                                <Col md={8}>
                                    <Select options={budgetList} 
                                        isDisabled={afeDTO.budgetClassKey !== 1}
                                        className={(!validateDataItem.budgetID ? "form-error" : "")}
                                        value={this.getSelBudget(afeDTO.budgetID)}
                                        defaultValue={this.getSelBudget(afeDTO.budgetID)}
                                        onChange={(e) => this.onTextChange("budgetID", e, e)}
                                        isClearable={true} 
                                    >
                                    </Select>
                                </Col>
                            </Row> 
                        </Col> 
                    </Row> 
                    <Row form style={{ marginBottom: "5px" }}> 
                        <Col md={6}> 
                            <Row form>
                                <Col md={4}>
                                    <Label for="basin">Entity</Label>
                                </Col>
                                <Col md={8}>
                                    <Select options={basinList}
                                        className={(!validateDataItem.entity ? "form-error" : "")}
                                        value={this.getSelBasin(afeDTO.locationKey)}
                                        defaultValue={this.getSelBasin(afeDTO.locationKey)}
                                        onChange={(e) => this.onTextChange("locationKey", e, e)} 
                                    >
                                    </Select> 
                                </Col> 
                            </Row> 
                        </Col>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label invalid for="county">County</Label> 
                                </Col>
                                <Col md={8}> 
                                    <Select options={countyList}
                                        //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                        className={(!validateDataItem.county ? "form-error" : "")}
                                        value={this.getSelCounty(afeDTO.county)}
                                        defaultValue={this.getSelCounty(afeDTO.county)}
                                        onChange={(e) => this.onTextChange("county", e, e)}
                                        isClearable={true}
                                    >
                                    </Select> 
                                </Col>
                            </Row> 
                        </Col> 
                    </Row> 
                    <Row form style={{ marginBottom: "5px" }}> 
                        <Col md={6}>
                            
                        </Col> 
                    </Row> 
                    <Row form style={{ marginBottom: "5px" }}>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label invalid for="area">Area</Label>
                                </Col>
                                <Col md={8}>
                                    <Select 
                                        options={areaList.filter(e => e.locationKey === afeDTO.locationKey)}
                                        className={(!validateDataItem.area ? "form-error" : "")}
                                        value={this.getSelArea(afeDTO.areaKey)}
                                        defaultValue={this.getSelArea(afeDTO.areaKey)}
                                        onChange={(e) => this.onTextChange("areaKey", e, e)}
                                        isClearable={true}
                                    >
                                    </Select>
                                </Col>
                            </Row>
                            
                        </Col>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label for="wbOwnedPct">WI%</Label>
                                </Col>
                                <Col md={8}>
                                    <NumberFormat 
                                        value={afeDTO.wbOwnedPct}
                                        onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                        className={(!validateDataItem.wbOwnedPct ? "form-error form-control text-md-right" : "form-control text-md-right")}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        suffix={'%'} />
                                </Col>
                            </Row>
                        </Col> 
                    </Row>
                    <Row form>
                        <Col md={4}>
                            <Label for="wbOwnedPct">Project Scope</Label> 
                        </Col> 
                    </Row>
                    <Row form>
                        <Col md={12}>
                            <Input type="textarea" name="afeDescription"
                                rows={8}
                                className={(!validateDataItem.afeDescription ? "form-error" : "")}
                                //disabled={this.isFormReadOnly()}
                                id="afeDescription" placeholder="Project Scope"
                                value={afeDTO.afeDescription}
                                defaultValue={afeDTO.afeDescription}
                                onChange={(e) => this.onTextChange("afeDescription", e.target.value, e)}
                            />
                        </Col>
                    </Row>
                </Form>

            </div >)
        }

            
    }

   
 
} 