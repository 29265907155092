
import React, { Component } from 'react';




import { Form, Card, CardHeader, CardBody, Col, Row, Button, FormGroup, Label, Input, CustomInput, Alert } from 'reactstrap';
import { apiURL, apiHeader, apiDELETEHeader } from '../../resources/apiURL';
import '../../custom.css';
// import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

// import { IFunctionFilterExpression_$type, TemplateSectionHeaderCellInfoDescription } from 'igniteui-react-core';
import { getUserADAccount } from '../../resources/utility'
// import Datetime from 'react-datetime';
// import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { IgrDataGrid, IgrTextColumn } from 'igniteui-react-grids';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
// import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrGridCellValueChangingEventArgs } from 'igniteui-react-grids';
// import { IgrGridRowEditEndedEventArgs } from 'igniteui-react-grids';
// import SafetyIncidentLine from './SafetyIncidentLine';
import SafetyIncidentNewReport from './SafetyIncidentNewReport'
import SafetyIncidentNewApproval from './SafetyIncidentNewApproval'
import { ColoredLine } from '../../resources/utility';
import ConfirmModal from '../../operation/ConfirmModal';
import ModalYNComment from '../../accounting/ModalYNComment'

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


import FormFirstReport from './FormFirstReport'
import FormSpillReport from './FormSpillReport'
import FormRCA from './FormRCA'
import FormJSA from './FormJSA'
import FormErgo from './FormErgo'
import Select from 'react-select';


import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PowerBIReportViewer from '../../resources/PowerBIReportViewer'


// import iconplus from '../resources/plus.svg'
// import icontrash from '../resources/trash.svg'
// import Plus from '../resources/plus.svg'

var DatePicker = require("reactstrap-date-picker");





export default class SafetyIncidentHeader extends Component {
    static displayName = SafetyIncidentHeader.name;


    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        const urlPara = this.getURLParameters(props.location)
        // console.log("loc search")
        // console.log(this.props.location.search)

        this.state = {
            childValue: '',
            data: [],
            isLoaded: false,
            reloadForm: false,
            // safIncHeaderKey:0,
            linedata: [],
            islineLoaded: false,
            safIncHeaderKey: urlPara.safIncHeaderKey,
            isddTypesLoaded: false,
            ddTypes: [],
            ddReports: [],
            isddPriorityLoaded: false,
            ddPriority: [],


            ddEmployees: [],
            tblApprovals: [],

            intnewReport: -1,
            isReadOnly: false,
            isReadOnlyLoaded: false,


            isUser: false,
            isAdmin: false,
            isUserLoaded: false,
            isAdminLoaded: false,
            isApprover: false,
            isEditApprover: false,
            isCreator: false,
            statusKey: -1,
            user: getUserADAccount(this.props.user.account.userName),
            isShowingAlert: false,

            modalMsg: false,
            msgHeader: "",
            msgBody: "",

            modalReport: false,

            modalMsgExt: false,
            msgHeaderExt: "",
            msgBodyExt: "",
            modalReportExt: false,

            isJSAorErgoform: false,

            showAlert: false,
            alertMessage: "",
            alertColor: "danger",

            actionLineKey: -1,
            hasJSA: false,
            jsaSafIncLineKey: -1

            // modal:false,

        }
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleMsgExt = () => {
        this.setState({ modalMsgExt: !this.state.modalMsgExt })
    }

    toggleAlert = () => {

        // this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }


    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport })
    }

    toggleReportExt = () => {
        this.setState({ modalReportExt: !this.state.modalReportExt })
    }

    createReportParamter = () => {
        const { safIncHeaderKey } = this.state
        // const reportPara = [{
        //     "name": "InvoiceKey",
        //     "value": invoiceKey  
        // }]
        const reportPara = [{
            "name": "SafIncHeaderKey",
            "value": safIncHeaderKey
        }]

        // console.log(reportPara)
        return reportPara
    }

    setShowingAlert = (status) => {
        this.setState({

            // isSafIncUser:false,
            isShowingAlert: status
        }
        )

    }

    getURLParameters = (inputPara) => {
        var paraMeters = {
            safIncHeaderKey: null
        }




        var query
        query = new URLSearchParams(this.props.location.search);
        // console.log("query")
        // console.log(query.get('safIncHeaderKey'))

        paraMeters = {
            safIncHeaderKey: query.get('safIncHeaderKey')
        }
        return paraMeters
    }


    setIsReadOnly = (iStatusKey, isEditApprover) => {
        const { isUser, isAdmin, isCreator, user } = this.state
        //          --('SafIncHeadStatus',0.0,'INC',0,'InComplete'),
        //          --('SafIncHeadStatus',1.0,'REJ',1,'Rejected'),
        //          --('SafIncHeadStatus',3.0,'PEN',3,'Pending'),
        //          --('SafIncHeadStatus',4.0,'ACT',4,'Active'),
        //          --('SafIncHeadStatus',5.0,'CAN',5,'Cancel'),
        //          --('SafIncHeadStatus',6.0,'COM',6,'Complete')
        var boolreadonly = true

        //boolreadonly = (data[0].statusKey == 4 || data[0].statusKey == 5 || data[0].statusKey == 6)

        if (isAdmin && (iStatusKey === 0 || iStatusKey === 1 || iStatusKey === 3 || iStatusKey === 4)) {
            boolreadonly = false
        }

        if (isEditApprover && iStatusKey === 3) {
            boolreadonly = false
        }

        if (isCreator && (iStatusKey === 1 || iStatusKey === 0)) {
            boolreadonly = false
        }

        //if (isSafIncAdmin) { boolreadonly = false }
        // console.log(isUser)

        // if (user.toLowerCase()==='desertkiosk'){
        //     boolreadonly = true
        // }


        // return (statusKey == 5 || statusKey == 6)
        this.setState({
            isReadOnly: boolreadonly,
            isReadOnlyLoaded: true
        })


    }

    getIsUser = () => {

        // const { isSafIncUser, isSafIncAdmin } = this.state
        let myURI = apiURL + 'SafetyIncident/IsSafIncUser/' + this.props.user.account.userName;

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isUser: (json.isSafIncUser != 0),
                    // isSafIncUser:false,
                    isUserLoaded: true
                }
                )
            });



    }


    getIsAdmin = () => {


        let myURI = apiURL + 'SafetyIncident/IsSafIncAdmin/' + this.props.user.account.userName;

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("---------------");
                this.setState({

                    isAdmin: (json.isSafIncAdmin != 0),
                    // isSafIncAdmin: false,
                    isAdminLoaded: true

                }
                )
            });



    }



    bMultiField = (value, checkValue) => {
        return (value & checkValue) === checkValue
    }



    getData = () => {
        const { safIncHeaderKey, isLoaded, user } = this.state
        let myURI = apiURL + 'SafetyIncident/SafIncHeaderKey/' + safIncHeaderKey
        // var isJSAErgoform = false

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    data: json,
                    isLoaded: true,
                    //safIncHeaderKey:json[0].safIncHeaderKey
                    // inspComments:json[0].inspComments,
                    // facInspComments:json[0].facInspComments
                    statusKey: json[0].statusKey,
                    isJSAErgoform: json[0].typeDesc2.toLowerCase().includes('jsa') || json[0].typeDesc2.toLowerCase().includes('ergo') ? true : false



                });

                // "Safety Incident -" &&
                document.title = "Safety Incident -" + json[0].safIncID
                var bisCreator = false;
                user.toUpperCase() === json[0].createdByAD.toUpperCase() ? bisCreator = true : bisCreator = false;
                this.setState({
                    isCreator: bisCreator

                })

                this.getlineData(json[0].statusKey)

                // console.log("---------")
                // console.log(this.state.data)

                // this.getInspHdrData(json[0].safIncHeaderKey)


                // this.getWitness()

            });
    }

    getlineData = (iStatusKey) => {
        const { safIncHeaderKey, isLoaded } = this.state
        let myURI = apiURL + 'SafetyIncident/SafIncLineKey/' + safIncHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                const hasJSA = json.some(item => item.typeID === 'JSA');
                const jsaSafIncLineKey = json.find(item => item.typeID === 'JSA')?.safIncLineKey;
                console.log("jsaSafIncLineKey:" + jsaSafIncLineKey)
                this.setState({
                    linedata: json,
                    islineLoaded: true,
                    //safIncHeaderKey:json[0].safIncHeaderKey
                    // inspComments:json[0].inspComments,
                    // facInspComments:json[0].facInspComments
                    hasJSA,
                    jsaSafIncLineKey: jsaSafIncLineKey
                }
                );



                // this.getApprovals()


                // this.getInspHdrData(json[0].safIncHeaderKey)
                // this.getWitness()

            }).then(this.getApprovals(iStatusKey));
    }






    //   onGridRef(grid) {
    //     if (!grid) {
    //       return;
    //     }
    //     this.grid = grid;
    //     this.grid.dataSource = this.state.tblWitness;

    //     //this.grid.cells[0]
    //     console.log(this.grid.cellBackground)

    //   }



    componentDidMount() {
        this.getIsUser()
        this.getIsAdmin()
        // this.getApprovals()
        this.getData()
        // this.getlineData()
        this.getddTypes()
        this.getddPriority()
        this.getddEmployees()




        //this.getInspHdrData()

    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.tblApprovals;

        //this.grid.cells[0]
        // console.log(this.grid.cellBackground)

    }





    getddTypes = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetSafIncType'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isddTypesLoaded: true,
                    //for testing only, REMOVE!!!
                    //ddTypes: json
                    ddTypes: json.filter(rec => (rec.typeID !== "JSA"))
                    //ddTypes: json.filter(rec => (rec.typeID !== "JSA" && rec.typeID !== "FiR" && rec.typeID !== "SpR"))
                })
            });
    }

    getddPriority = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetPriority'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.createddPriority(json)


            });
    }

    createddPriority = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.helpDeskPriorityKey
            e.label = e.helpDeskPriorityID
            tempList.push(e)
        })

        // return tempList
        this.setState({
            isddPriorityLoaded: true,
            ddPriority: tempList
        })
    }


    getddEmployees = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyObservation/SubmitBy'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    //   isddEmployeesLoaded: true,
                    ddEmployees: json
                })
            });
    }


    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.lineStatusKey === 4) {
                content.style.background = "#b6a065";
            } else if (item.lineStatusKey === 6) {
                content.style.background = "#61a066";
            } else if (item.lineStatusKey === 3 || item.lineStatusKey === 5) {
                content.style.background = "#ad5f55";
            } else {
                content.style.background = "transparent";
            }
        }


        switch (s.field) {
            case 'name':
                link.textContent = item.name;
                break;
            case 'position':
                link.textContent = item.position;
                break;


            default:

        }

    }

    getddReports = () => {
        const { data } = this.state

        //console.log("ggg-g-g-g-g-g")
        //console.log(data[0].typeDesc2.toLowerCase().includes(searchText))

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        const options = [
            //{ value: '*', label: 'Select All' },
            //{ value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
            //{ value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
            { value: 'print', label: 'Print', isDisabled: true },
            { value: 'report', label: 'Regular Report' },
            { value: 'externalspill', label: 'External Spill Report', isDisabled: data[0].typeDesc2.toLowerCase().includes("spill") ? false : true },

        ];

        this.setState({

            ddReports: options

        })
    }



    onSelectReport = (fieldName, fieldValue, event) => {
        // event.preve
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        // const { data } = this.state;

        // /cbincEquipmentDamage
        //incClassif

        switch (fieldValue.value) {



            case "report":
                this.toggleReport()
                break;
            case "externalspill":
                this.toggleReportExt()
                break;





            default:

        }

        //this.setState({ data: data })
    }






    onTextChange = (fieldName, fieldValue, event) => {
        // event.preve
        // console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        const { data } = this.state;

        // /cbincEquipmentDamage
        //incClassif

        switch (fieldName) {



            case "comment":
                data[0].comment = fieldValue
                break;


            case "isContractor":
                // data[0].isContractor = fieldValue ? 1 : 0
                if (fieldValue === data[0].isContractor) {
                    data[0].isContractor = null
                }
                else { data[0].isContractor = fieldValue }



                break;
            case "priority":
                data[0].priorityKey = fieldValue.value
                break;

            case "descript":
                data[0].descript = fieldValue
                break;
            case "privateNote":
                data[0].privateNote = fieldValue
                break;

            default:

        }

        this.setState({ data: data })
    }




    createDataForUpdateDTO = (ApprovalComment) => {
        const { data } = this.state
        const { user } = this.props

        const dataForUpdateDTO = {
            "SafIncHeaderKey": data[0].safIncHeaderKey,
            //"ModifiedByKey": 580, /*4=Submitted, 6=Reviewed*/
            "StatusKey": data[0].statusKey,
            "ModifiedByAD": getUserADAccount(user.account.userName),
            "Comment": data[0].comment,
            "Descript": data[0].descript,
            "PrivateNote": data[0].privateNote,
            "PriorityKey": data[0].priorityKey,
            "IsContractor": data[0].isContractor,
            "ApprovalComment": ApprovalComment



        }

        return dataForUpdateDTO;
    }

    newReport = (z) => {

        this.intnewReport = z;
        //this.intnewReport = 2;
        //remove 2 after you add another report!!!

        this.insertLine()

    }

    newApproval = (ddemp, desc, appr, editappr, emai) => {
        const { safIncHeaderKey } = this.state
        const itemDTO = this.creatLineforNewApproval(ddemp, desc, appr, editappr, emai);
        // console.log(itemDTO);
        const putReq = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'SafetyIncident/insertSafIncApproval'
        fetch(myURI, putReq)
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    // console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                // console.log("No error here: " + rspData)
                this.getData()

            })
            .catch(error => {
                console.log("POST Error: " + error);
            })

    }


    getApprovals = (iStatusKey) => {
        //console.log(this.state.startDate);

        const { safIncHeaderKey, user } = this.state
        // const { facilityKeys, safetyType,
        //     fromParamStartDate, fromParamEndDate, data } = this.state
        // console.log("safetyType");
        // console.log(safetyType);

        // let myURI = apiURL + 'SafetyObservation/startdate/' + 
        //                       this.state.startDate.toString().substring(0, 10) + 
        //                       '/enddate/' + this.state.endDate.toString().substring(0, 10) 
        //                       + '/AzureAD/' + this.props.user.account.userName;

        var isEditApprover

        if (safIncHeaderKey) {
            let myURI = apiURL + 'SafetyIncident/SafIncApprovalsByKey/appkey/11/key/' + safIncHeaderKey
            // + this.state.startDate.toString().substring(0, 10) + '/enddate/' + this.state.endDate.toString().substring(0, 10) ;
            // let myURI = apiURL + 'SafetyIncident/GetSafIncHeader';                              
            // console.log(myURI);

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    const isApprover = (json.filter(rec => rec.employee.toUpperCase() === user.toUpperCase() && rec.lineStatusKey === 3 && rec.actionKey === 1).length > 0)
                    isEditApprover = (json.filter(rec => rec.employee.toUpperCase() === user.toUpperCase() && rec.lineStatusKey === 3 && rec.actionKey === 4).length > 0)
                    this.setIsReadOnly(iStatusKey, isEditApprover)

                    this.setState({
                        //isLoaded: true,
                        // safetyInc:  json.filter(rec => ((safetyType===null ? true : 
                        //                                (rec.safetyTypeKey == safetyType))) &&
                        //                                ((fromParamStartDate===null && fromParamStartDate===null) ? true : 
                        //                                 (rec.createdDatetime >= fromParamStartDate && rec.createdDatetime <= fromParamEndDate)))
                        //                 .sort((a, b) => ((a.statusKey==5 ? 3 : a.statusKey) > (b.statusKey==5 ? 3 : b.statusKey)) ? 1:-1)                   
                        // safetyInc:  json.filter(rec => ((this.state.startDate===null && this.state.endDate===null) ? true : 
                        //                                 (rec.createdDatetime >= this.state.startDate && rec.createdDatetime <= this.state.endDate)))
                        //safetyInc:  json.filter(rec => (rec.SafIncHeaderKey >= 2))    
                        // isApprover: (json.filter(rec => rec.employee.toupper === user.toupper && rec.lineStatusKey===3).length>0)
                        // console.log(json.filter(rec => rec.employee === 'Adam.Few' && rec.lineStatusKey===3).length)


                        tblApprovals: json,
                        isApprover: isApprover,
                        isEditApprover: isEditApprover,
                        // isReadOnly: 

                    })

                    // console.log('--------xxxxxxxxxxxx---------------')


                    //  console.log(json.filter(rec => rec.employee.toupper === user.toupper && rec.lineStatusKey===3))

                });
        }

        this.getddReports()

    }



    creatLineforNewApproval = (ddemp, desc, appr, editappr, emai) => {
        const { data, safIncHeaderKey, txtnewWitnessName, txtnewPosition } = this.state
        const { user } = this.props

        const dataForInsertDTO = {
            "CreatedByAD": getUserADAccount(user.account.userName),
            "SafIncHeaderKey": safIncHeaderKey,
            "EmployeeKey": ddemp,
            "Comment": desc,
            "Approve": appr ? 1 : 0,
            "EditApprove": editappr ? 1 : 0,
            "Email": emai ? 1 : 0


        }

        return dataForInsertDTO;
    }

    creatLineforInsertDTO = () => {
        const { data, safIncHeaderKey, txtnewWitnessName, txtnewPosition } = this.state
        const { user } = this.props

        const dataForInsertDTO = {
            "CreatedByAD": getUserADAccount(user.account.userName),
            "SafIncHeaderKey": safIncHeaderKey,
            "SafIncType": this.intnewReport


        }

        return dataForInsertDTO;
    }

    insertLine = () => {
        const itemDTO = this.creatLineforInsertDTO();
        // console.log(itemDTO);

        const putReq = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'SafetyIncident/insertSafIncLine'
        fetch(myURI, putReq)
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    // console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                // console.log("No error here: " + rspData)
                // this.getlineData()
                this.getData()
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })


    }



    saveData = (ApprovalComment) => {
        const itemDTO = this.createDataForUpdateDTO(ApprovalComment);
        const { hasJSA } = this.state
        // console.log(itemDTO);

        if (typeof this.saveFirst !== 'undefined') {

            this.saveFirst()
        }

        if (typeof this.saveSpill !== 'undefined') {

            this.saveSpill()
        }

        if (typeof this.saveRCA !== 'undefined') {

            this.saveRCA()
        }

        // if (typeof this.saveJSA !== 'undefined') {
       if (hasJSA) {

            this.saveJSA()
        }

        if (typeof this.saveErgo !== 'undefined') {

            this.saveErgo()
        }




        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'SafetyIncident'
        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                }

                // console.log("No error here: ")
                this.getData()


                this.setShowingAlert(true)


            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }

    createDataForDeleteHeader = () => {
        const { data } = this.state
        const { user } = this.props

        const dataForDeleteDTO = {
            "SafIncHeaderKey": data[0].safIncHeaderKey,
            "DeletedByAD": getUserADAccount(user.account.userName)
            // ,
            // "StatusKey": data[0].statusKey
        }

        return dataForDeleteDTO;
    }


    deleteHeader = () => {
        
        const { hasJSA } = this.state

        const itemDTO = this.createDataForDeleteHeader();
        // console.log(itemDTO);

        const putReq = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'SafetyIncident/deleteSafIncHeader'

            // fetch(myURI, putReq)
            // .then(response => response.json())
            // .then(data => {
            //     this.getSafetyIncData();

            //     this.openHeaderForm(data[0].safIncHeaderKey);

            // })
            ;


        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                }
               
                // window.location = '/#/SafetyIncident';
                if (hasJSA) {
                    window.location.href = '/#/SafetyIncJSA';
                } else {
                    window.location.href = '/#/SafetyIncident';
                }
                

                
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })

        return true;
    }






    updateStatus = (stat, comment) => {
        var validated = true;

        const {hasJSA} = this.state;

        var notvalidforms = "";

        var toValidate = (stat === "submit" ? true : false)



        // if (toValidate && typeof this.validateJSAForSubmit !== 'undefined') {
       if (toValidate && hasJSA) {
                // if (isValidatedFormJSAJobTask)
            // var zz = false
            validated = this.validateJSAForSubmit()
            if (!validated) {
                notvalidforms = " JSA form "
            }
        }

        // var notvalidforms = "";


        // if (typeof this.validateJSAForSubmit !== 'undefined') {



        // if (!validated) {
        //     this.setState({
        //         alertMessage: "Please fill in the required fields: " + notvalidforms,
        //         showAlert: true
        //     })
        // }

        if (!validated) {
            this.setState({
                alertMessage: "Please fill in the required fields: " + notvalidforms,
                showAlert: true
            },
                () => { window.setTimeout(() => { this.setState({ showAlert: false }) }, 5000) }


            )



        }



        if (validated) {

            // console.log("insubmit")
            const { data } = this.state
            var varComment

            var tempData = data

            if (stat === "submit") {
                tempData[0].statusKey = 3
            }
            else if (stat === "approve") {
                tempData[0].statusKey = 4
                varComment = comment
            }
            else if (stat === "reject") {
                tempData[0].statusKey = 1
                varComment = comment
            }
            else if (stat === "complete") {
                tempData[0].statusKey = 6
            }
            else if (stat === "cancel") {
                tempData[0].statusKey = 5
            }




            this.setState({ data: tempData })
            this.saveData(comment)
        }
    }


    onApprovalsCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;


        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            //0 Reject
            //3 Pending
            //6 Approved
            link = content.children[0];

            if (item.lineStatusKey === 3) {
                content.style.background = "transparent";
            } else if (item.lineStatusKey === 0) {
                content.style.background = "#EC7063";
            } else if (item.lineStatusKey === 6) {
                content.style.background = "#A3E4D7";
            } else {
                content.style.background = "transparent";
            }

        }


        switch (s.field) {
            case 'actionDesc':
                link.textContent = item.actionDesc;
                break;
            case 'employeeName':
                link.textContent = item.employeeName;
                break;

            case 'lineStatusDesc':
                link.textContent = item.lineStatusDesc;
                break;
            case 'comments':
                link.textContent = item.comments;
                break;
            case 'createdByEmpName':
                link.textContent = item.createdByEmpName;
                break;

            default:

        }

    }



    // newReport=()=>{
    //     // this.setState({safetyObservationKey: -1})       
    //     //this.setState({selSafeObs: dataItem})
    //     this.toggle()
    // }

    // toggle = () => {
    //     console.log("Modal: " + this.state.modal)
    //     this.setState({modal: !this.state.modal})

    // }

    onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // this.setState({ selAFELine: dataItem })           
            this.setState({
                // templateStep: dataItem.templateStep,
                // invalidStep: false,
                // templateSequence: dataItem.templateSequence,
                // templateHazard: dataItem.templateHazard,
                // templateAction: dataItem.templateAction,
                actionLineKey: dataItem.lineStatusDesc === "Pending" ? dataItem.actionLineKey : -1
            })
        } else {
            this.setState({
                // templateStep: dataItem.templateStep,

                actionLineKey: -1
            })
        }
    }

    createDataForDeletePendingLine = () => {
        const { actionLineKey } = this.state


        const dataForDeleteDTO = {
            "ActionLineKey": actionLineKey,
            // "DeletedByAD": getUserADAccount(user.account.userName)
            // ,
            // "StatusKey": data[0].statusKey
        }

        return dataForDeleteDTO;
    }



    deletePendingLine = () => {

        const createDataforDelete = this.createDataForDeletePendingLine()


        let myURI = apiURL + 'SafetyIncident/deleteSafIncPendingLine'
        fetch(myURI, apiDELETEHeader(createDataforDelete))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                // console.log("No error here: ")
                this.getData()

                this.setState({ actionLineKey: -1 })
                // this.setShowingAlert(true)
                //this.props.refreshData()
            })

            .catch(error => {
                console.log("PUT Error: " + error);
            })

    }






    render() {

        // var {isLoaded, data, isHdrLoaded, hdrData} = this.state
        const { safIncHeaderKey, isLoaded,
            data, linedata,
            ddTypes, ddPriority, isddPriorityLoaded, ddEmployees, isReadOnly, isReadOnlyLoaded,
            isUser, isAdmin, isCreator,
            isUserLoaded, isAdminLoaded, tblApprovals, isApprover, isEditApprover, user,
            isShowingAlert, msgHeader, msgBody, isJSAErgoform, alertMessage, alertColor, showAlert, actionLineKey, ddReports, hasJSA, jsaSafIncLineKey, islineLoaded
        } = this.state
        // console.log('isSafIncAdmin')
        // console.log(isSafIncAdmin)
        if (!isUserLoaded) return <h1> loading user status... </h1>
        if (!isAdminLoaded) return <h1> loading admin status... </h1>
        if (!isddPriorityLoaded) return <h1> loading priorities... </h1>
        if (!isLoaded) return <h1> loading... </h1>
        if (!isReadOnlyLoaded) return <h1> loading readonly properties... </h1>




        if (!islineLoaded) return <h1> loading lines... </h1>

        // if (!isddEmployeesLoaded) return <h1> loading Employees... </h1>



        //if (!isLoaded) return <h1> loading </h1> 

        return (
            <div>
                {/* <Form>
                <FormGroup>
                  <Row form>
                    <h3> First Report </h3>
                  </Row>
                  <h5>
                  <label>{data[0].safIncID}</label>  <span>&nbsp;&nbsp;</span>
                  <label>| isReadOnly: {isReadOnly} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| Status: {data[0].lineStatusDesc} </label>  <span>&nbsp;&nbsp;</span>                    
                    <label>| By: {data[0].createdByAD} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| Created: {new Date(data[0].createdDateTime).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                   
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup>

            </Form> */}
                <Form onSubmit={e => e.preventDefault()}>

                    <Col md={12}>
                    {hasJSA ? <h2>JSA - {data[0].safIncID} - {data[0].statusDesc}</h2> : <h2>Safety Incident - {data[0].safIncID} - {data[0].statusDesc}</h2>}
                        
                    </Col>

                    <div style={{ margin: "0px 0px 4px 0px" }}>
                        <ColoredLine color="Gainsboro" />

                    </div>
                    {/* <FormGroup>
                 
                  <h5>
                 
                  <label>| Line: {data[0].sLineNo} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| Status: {data[0].statusKey} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| isCreator: {isCreator ? "yes":"no"} </label>  <span>&nbsp;&nbsp;</span>
                  <label>| one: {((data[0].statusKey === 0 || data[0].statusKey === 1) && isAdmin) ? "yes":"no"} </label>  <span>&nbsp;&nbsp;</span>
                  <label>| two: {(data[0].statusKey === 0  && isCreator) ? "yes":"no"} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| Created: {new Date(data[0].createdDateTime).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                   
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup> */}

                    <FormGroup row>
                        <Col md={12}>


                            <Button color="primary"
                                className="float-left"
                                style={{ margin: "0px 10px 0px 0px" }}
                                hidden={(isReadOnly)}


                                onClick={() => this.saveData("")}>Save</Button>

                            <ConfirmModal outline={false}
                                color="success"
                                className="float-left"
                                buttonLabel="Submit"
                                // hidden={! (((data[0].statusKey === 0 || data[0].statusKey === 1) && isAdmin) || (data[0].statusKey === 0  && isCreator)) }
                                hidden={!((data[0].statusKey === 0 || data[0].statusKey === 1) && (isAdmin || isCreator))}
                                formName="Confirm"
                                // id={safetyObservationKey}
                                message="Are you sure you want to submit?"
                                onClickYes={(e) => this.updateStatus("submit", "comment")}
                            />


                            {/* <Button color="secondary"
                                         className="float-left"
                                            style={{ margin: "0px 10px 0px 0px" }}
                                    onClick={this.toggleReport}
                               
                                >
                                    
                                    View Report
                                </Button> */}
                            {/* <div style={{width: '300px'}}> */}
                            <Select //options={ddPriority}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '200px',
                                        margin: "0px 0px 0px 10px"
                                    }),
                                }}

                                className="float-right"
                                //defaultValue={"fruit"}



                                options={ddReports}
                                //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                //isDisabled={isReadOnly}
                                //className={(!validateDataItem.budgetClassKey ? "form-error" : "")}
                                //value={ddPriority.filter(e => e.helpDeskPriorityKey === data[0].priorityKey)}
                                value={ddReports[0]}
                                //defaultValue={['meat']}
                                //defaultValue={this.getSelCounty(afeData[0].county)}

                                onChange={(e) => this.onSelectReport("priority", e, e)}
                            //isClearable={true}

                            >
                            </Select>

                            {/* </div>       */}





                            <ModalYNComment outline={false}
                                color="success"
                                className="float-left"
                                buttonLabel="Approve"
                                hidden={!((data[0].statusKey === 3) && (isApprover || isEditApprover))}
                                // invalid={!invalidDataItem.reviewCommentCont}
                                // value={invalidDataItem.reviewCommentText}
                                // defaultValue = {invalidDataItem.reviewCommentText}
                                // disabled= {dataSelected.length>0 ? "false" : "true"}
                                // disabled="false"
                                //inputKey={-1}
                                // dataSelected={dataSelected}
                                formName="Confirm"
                                //   id={meterValidationKey}
                                message="Are you sure you want to approve?                   "
                                //   meterValidation={meterValidation}
                                // onClosed={(e) => this.removeErrorZero()}
                                // onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                                onClickYes={(e) => this.updateStatus("approve", e)}
                            // image={<img
                            //     src={RemoveIcon}
                            //     style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}

                            />


                            <ConfirmModal outline={false}
                                color="success"
                                className="float-left"
                                buttonLabel="Complete"
                                // hidden={!(statusKey == 4 && (isQHSEAdmin || isQHSEApprover))}
                                hidden={!((data[0].statusKey === 4) && (isAdmin))}
                                formName="Confirm"
                                // id={safetyObservationKey}
                                message="Are you sure you want to complete?"
                                onClickYes={(e) => this.updateStatus("complete", "comment")}
                            />





                            {/* <ConfirmModal outline={true}
                                color="danger"
                                className="float-right"
                                buttonLabel="Reject"
                                hidden={!((data[0].statusKey == 3) && (isAdmin || isApprover))}
                                formName="Confirm"
                                // id={safetyObservationKey}
                                message="Are you sure you want to reject?"
                                onClickYes={(e) => this.updateStatus("reject", "comment")}
                            /> */}


                            <ModalYNComment outline={false}
                                color="danger"
                                className="float-right"
                                style={{ marginLeft: "10px" }}
                                buttonLabel="Reject"
                                hidden={!((data[0].statusKey === 3) && (isAdmin || isApprover || isEditApprover))}
                                // invalid={!invalidDataItem.reviewCommentCont}
                                // value={invalidDataItem.reviewCommentText}
                                // defaultValue = {invalidDataItem.reviewCommentText}
                                // disabled= {dataSelected.length>0 ? "false" : "true"}
                                // disabled="false"
                                //inputKey={-1}
                                // dataSelected={dataSelected}
                                formName="Confirm"
                                //   id={meterValidationKey}
                                message="Are you sure you want to reject?                   "
                                //   meterValidation={meterValidation}
                                // onClosed={(e) => this.removeErrorZero()}
                                // onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                                onClickYes={(e) => this.updateStatus("reject", e)}
                            // image={<img
                            //     src={RemoveIcon}
                            //     style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}

                            />



                            <ConfirmModal outline={true}
                                color="danger"
                                className="float-right"
                                buttonLabel="Cancel"
                                hidden={!((data[0].statusKey === 1 || data[0].statusKey === 3 || data[0].statusKey === 4) && isAdmin)}
                                formName="Confirm"
                                // id={safetyObservationKey}
                                message="Are you sure you want to cancel?"
                                onClickYes={(e) => this.updateStatus("cancel", "comment")}
                            />
                            <button className="btn-no-border float-right">
                                <ConfirmModal outline={true}
                                    color="danger"
                                    // className="float-right"
                                    buttonLabel="Delete"
                                    hidden={!(data[0].statusKey === 0)}
                                    formName="Confirm"
                                    // id={safetyObservationKey}
                                    message="Are you sure you want to delete? (cannot be undone)"
                                    onClickYes={(e) => this.deleteHeader()}
                                />
                            </button>

                        </Col>
                        <Col md={12}>
                            <div style={{ margin: "3px 0px 0px 0px" }} >
                                <ColoredLine color="DarkGray" />

                            </div>
                        </Col>


                    </FormGroup>
                    <div
                        className={` alert-success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}
                        onTransitionEnd={() => this.setShowingAlert(false)}>
                        Data is saved
                    </div>






                    {/* <FormGroup row>
                        <Col md={12}>
                            <div>
                                <Label>
                                    ddddd {data[0].statusKey} {user} isJSAErgoform:<b>{isJSAErgoform ? "true" : "false"}</b> isReadOnlyLoaded:<b>{isReadOnlyLoaded ? "true" : "false"}</b> isReadOnly:<b>{isReadOnly ? "true" : "false"}</b> isCreator:<b>{isCreator ? "true" : "false"}</b>   isUser:<b>{isUser ? "true" : "false"}</b>   isAdmin:<b>{isAdmin ? "true" : "false"}</b>    isApprover:<b>{isApprover ? "true" : "false"}</b>  isEditApprover:<b>{isEditApprover ? "true" : "false"}</b>
                                    dddd5 Linedata: {linedata.length}
                                </Label>


                            </div>
                        </Col>

                    </FormGroup> */}
                    <FormGroup row>
                        <Col md={12}>
                            <div>

                                <Alert color={alertColor} toggle={() => this.toggleAlert()}
                                    isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                                    {alertMessage}
                                </Alert>


                            </div>

                        </Col>

                    </FormGroup>

                    <FormGroup row>
                        <Col md={12}>
                            <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="safIncID">
                                            {hasJSA ? "JSA ID" : "Incident ID"}
                                                </Label>
                                        </Col>
                                        <Col md={5}>
                                            <Input disabled type="text" name="safIncID"
                                                id="safIncID" placeholder=""
                                                value={data[0].safIncID}
                                            />
                                        </Col>

                                        <Col md={3}>
                                            <Input disabled type="text" name="statusDesc"
                                                id="statusDesc" placeholder=""
                                                value={data[0].statusDesc}
                                            />
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="createdByEmpName">Created By</Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="createdByEmpName"
                                                id="createdByEmpName" placeholder=""
                                                value={data[0].createdByEmpName}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="facilityName">Location</Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="facilityName"
                                                id="facilityName" placeholder=""
                                                value={data[0].facilityName}
                                            />
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="createdDateTime">Created Date</Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="createdDateTime"
                                                id="createdDateTime" placeholder=""
                                                value={new Date(data[0].createdDateTime).toLocaleDateString()}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>

                            {/* <FormGroup row>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="PriorityID">Priority</Label>
                                        </Col>
                                        <Col md={8}>

                                            <Select options={ddPriority}
                                                isDisabled={isReadOnly}
                                                value={ddPriority.filter(e => e.helpDeskPriorityKey === data[0].priorityKey)}
                                                onChange={(e) => this.onTextChange("priority", e, e)}
                                                isClearable={true}>
                                            </Select>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="isContractor">Contractor</Label>
                                        </Col>
                                       
                                        <Col md={2}>
                                            <CustomInput
                                                type="checkbox"

                                                id="isContractorY"
                                                label="Yes"
                                                checked={data[0].isContractor === 1}
                                                inline
                                                disabled={isReadOnly}
                                                onChange={(e) => this.onTextChange("isContractor", 1, e)}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <CustomInput type="checkbox"
                                                id="isContractorN"
                                                label="No" inline
                                                checked={data[0].isContractor === 0}
                                                onChange={(e) => this.onTextChange("isContractor", 0, e)}
                                                disabled={isReadOnly}

                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup> */}

                            <FormGroup row hidden={data[0].safetyObservationKey ? false : true}>

                                {/* <Col md={12}> */}
                                {/* 1 row */}

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="safobs">Safety Observation</Label>
                                        </Col>
                                        <Col md={4}>
                                            <a href={"/#/safety-obs-dtl?key=" + data[0].safetyObservationKey} target="_blank">{data[0].safetyObservationNo}</a>

                                        </Col>
                                        {data[0].mvadescription !== null && (
    <>
                                        <Col md={4}>
                                            <Label for="mvadescription">MVA: {data[0].mvadescription}</Label>
                                        </Col>
                                        
                                    </>
)}
                                    </Row>
                                </Col>










                            </FormGroup>


                            <FormGroup row>

                                {/* <Col md={12}> */}
                                {/* 1 row */}

                                <Col md={12}>


                                    <Label for="descript">Description</Label>
                                    <Input type="text" name="descript"
                                        id="descript"
                                        value={data[0].descript}
                                        disabled={isReadOnly}
                                        onChange={(event) => this.onTextChange("descript", event.target.value, event)}
                                    />

                                    {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                                </Col>




                                {/* </Col> */}


                            </FormGroup>

                            <FormGroup row>

                                {/* <Col md={12}> */}
                                {/* 1 row */}

                                <Col md={!isAdmin ? 12 : 6} hidden={hasJSA}>
                                    <Label for="comments">Comments</Label>
                                    <Input
                                        // invalid={!validateDataItem.hazardDesc}
                                        type="textarea" name="comment" rows={3}
                                        id="comment"
                                        value={data[0].comment}
                                        disabled={isReadOnly}
                                        onChange={(e) => this.onTextChange("comment", e.target.value, e)}
                                    // defaultValue = {observation[0].hazardDesc}                               
                                    // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                    // disabled={isReadOnly} 

                                    />
                                    {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                                </Col>




                                {/* </Col> */}


                                {/* </FormGroup>
                            <FormGroup row hidden={!isAdmin}> */}

                                {/* <Col md={12}> */}
                                {/* 1 row */}

                                <Col md={hasJSA ? 12 : 6} hidden={!isAdmin}>
                                    <Label for="privateNote">Private Note  <small>*Admins only</small></Label>
                                    <Input
                                        // invalid={!validateDataItem.hazardDesc}
                                        type="textarea" name="privateNote" rows={3}
                                        id="privateNote"
                                        value={data[0].privateNote}
                                        disabled={isReadOnly}
                                        onChange={(e) => this.onTextChange("privateNote", e.target.value, e)}
                                    // defaultValue = {observation[0].hazardDesc}                               
                                    // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                    // disabled={isReadOnly} 

                                    />
                                    {/* <FormFeedback>Please enter what you did you observe</FormFeedback>                     */}
                                </Col>




                                {/* </Col> */}


                            </FormGroup>





                        </Col>



                    </FormGroup>
                    <FormGroup  hidden={hasJSA}>
                        <Row >


                            <SafetyIncidentNewReport
                                //Add New Report
                                ddropdown={ddTypes}
                                disabled={isReadOnly || !isAdmin || isJSAErgoform}
                                // disabled={isReadOnly}
                                //  onClickOK(ddKey.value)
                                onClickOK={(e) => this.newReport(e)}
                            // safIncLineKey={2}


                            />

                        </Row>
                    </FormGroup>



                    {/* <SafetyIncidentLine 
                                 datac={linedata}
                                 safIncHeaderKey={safIncHeaderKey}
                                 user={this.props.user}
                                 isReadOnly={isReadOnly}
                                /> */}

   <div hidden={!hasJSA}>
   <FormJSA safIncLineKey={jsaSafIncLineKey}
                                                user={this.props.user}
                                                isAdmin={isAdmin}
                                                isReadOnly={isReadOnly}
                                                key={isReadOnly}
                                                saveJSA={click => this.saveJSA = click}
                                                validateJSAForSubmit={click => this.validateJSAForSubmit = click}
                                           
                                            />
</div>



                    <div hidden={hasJSA}>
                        <Accordion allowZeroExpanded>
                            {linedata.map((item) => (

                                <AccordionItem>
                                    {/* <AccordionItemHeading style={{fontSize:2}}> */}
                                    <AccordionItemHeading>
                                        {/* {item.typeDesc} */}
                                        <AccordionItemButton>
                                            <strong>
                                                <label>Line {item.sLineNo}</label> <span>&nbsp;</span>
                                                <label>{item.typeDesc}</label>
                                                <label> by {item.createdByEmpName}</label>
                                                <label> on {new Date(item.createdDateTime).toLocaleDateString()} |</label>  <span>&nbsp;&nbsp;</span>
                                            </strong>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel >


                                        {item.typeID === "FiR" ?
                                            (<FormFirstReport safIncLineKey={item.safIncLineKey}
                                                user={this.props.user}
                                                isReadOnly={isReadOnly}

                                                key={isReadOnly}
                                                saveFirst={click => this.saveFirst = click}

                                            />)
                                            : (<div></div>)

                                        }

                                        {item.typeID === "SpR" ?
                                            (<FormSpillReport safIncLineKey={item.safIncLineKey}
                                                user={this.props.user}
                                                isReadOnly={isReadOnly}
                                                key={isReadOnly}
                                                saveSpill={click => this.saveSpill = click}

                                            />)
                                            : (<div></div>)

                                        }


                                        {item.typeID === "RCA" ?
                                            (<FormRCA safIncLineKey={item.safIncLineKey}
                                                user={this.props.user}
                                                isReadOnly={isReadOnly}
                                                key={isReadOnly}
                                                saveRCA={click => this.saveRCA = click}

                                            />)
                                            : (<div></div>)

                                        }

                                        {/* {item.typeID === "JSA" ?
                                            (<FormJSA safIncLineKey={item.safIncLineKey}
                                                user={this.props.user}
                                                isAdmin={isAdmin}
                                                isReadOnly={isReadOnly}
                                                key={isReadOnly}
                                                saveJSA={click => this.saveJSA = click}
                                                validateJSAForSubmit={click => this.validateJSAForSubmit = click}
                                           
                                            />)
                                            : (<div></div>)

                                        } */}

                                        {item.typeID === "Erg" ?
                                            (<FormErgo safIncLineKey={item.safIncLineKey}
                                                user={this.props.user}
                                                isReadOnly={isReadOnly}
                                                key={isReadOnly}
                                                saveErgo={click => this.saveErgo = click}

                                            />)
                                            : (<div></div>)

                                        }




                                        {/* <Itemtype typeID={item.typeID}  
                      safIncLineKey={item.safIncLineKey}  
                      user={props.user}
                      isReadOnly={props.isReadOnly}
                      key={3}
            /> */}




                                    </AccordionItemPanel>


                                </AccordionItem>



                            ))}

                        </Accordion>




                    </div>



                    <FormGroup hidden={(data[0].safIncID === "SXXXXX") || !isAdmin || isReadOnly} >
                        <span>&nbsp;&nbsp;</span>


                        {/* <p hidden={!(isReadOnly || data[0].statusKey === 0)}> <small class="text-muted">*Please submit the incident before adding approvals</small></p> */}
                        

                        <Row >




                            <SafetyIncidentNewApproval
                                // ddropdown={ddTypes}
                                // Add user for approval or email
                                ddemp={ddEmployees}
                                disabled={(data[0].safIncID === "SXXXXX") || !isAdmin || isReadOnly}
                                //  onClickOK(ddKey.value)
                                onClickOK={(ddemp, desc, appr, editappr, emai) => this.newApproval(ddemp, desc, appr, editappr, emai)}
                            // onClickOK={this.newReport()}
                            // safIncLineKey={2}

                            />

                            <ConfirmModal outline={true}
                                color="danger"
                                className="float-left"
                                style={{ height: "35px", marginLeft: "10px" }}
                                buttonLabel="Delete line"
                                //hidden={hidden}
                                //disabled={objectLinkKey < 0}
                                disabled={(data[0].safIncID === "SXXXXX") || !isAdmin || isReadOnly || actionLineKey < 0}
                                formName="Confirm"
                                message="Are you sure you want to delete? (cannot be undone)"
                                // onClickYes={(e) => this.deleteLine()}
                                onClickYes={this.deletePendingLine}
                            />

                        </Row>

                    </FormGroup>
                    {/* <FormGroup hidden={(data[0].safIncID === "SXXXXX") || !isAdmin || isReadOnly}> */}
                    <FormGroup hidden={(data[0].safIncID === "SXXXXX") }>


                        <IgrDataGrid
                            ref={this.onGridRef}
                            // height={gridHeight}   
                            cellTextStyle="14px Calibri"
                            headerTextStyle="14px Calibri"
                            rowHeight={"25"}         
                            height="100%"
                            width="100%"
                            dataSource={tblApprovals}
                            // dataSource={safetyInc.filter( rec =>  ((rec.safetyObservationNo || '').toLowerCase().includes(searchText)) ||
                            //                                     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.qhseReportTypeDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.hazardDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.solutionDesc || '').toLowerCase().includes(searchText) ||
                            //                                     (rec.createdByName || '').toLowerCase().includes(searchText) ||       
                            //                                     (rec.managerName || '').toLowerCase().includes(searchText) ||                                                      
                            //                                     (rec.locationName || '').toLowerCase().includes(searchText))}
                            autoGenerateColumns="false"
                            selectionMode="SingleRow"
                            editModeClickAction="SingleClick"
                            editMode={0}
                            selectedKeysChanged={this.onGridRowSelected}
                        //cellBackground={"#ffbfbf"}
                        >
                            <IgrTemplateColumn field="actionDesc" headerText="Action" width="*>120" cellUpdating={this.onApprovalsCellUpdating} />
                            <IgrTemplateColumn field="employeeName" headerText="Employee" width="*>120" cellUpdating={this.onApprovalsCellUpdating} />
                            <IgrTemplateColumn field="lineStatusDesc" headerText="Status" width="*>120" cellUpdating={this.onApprovalsCellUpdating} />
                            <IgrTemplateColumn field="comments" headerText="Comment" width="*>120" cellUpdating={this.onApprovalsCellUpdating} />
                            <IgrTemplateColumn field="createdByEmpName" headerText="Added By" width="*>120" cellUpdating={this.onApprovalsCellUpdating} />
                            {/* <IgrTextColumn field="lineStatusKey" headerText="lineStatusKey" width="*>120" /> */}

                            <IgrNumericColumn field="actionLineKey" isHidden />


                        </IgrDataGrid>





                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <span>&nbsp;&nbsp;</span>
                        </Row>
                    </FormGroup>



                </Form>


                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalReport}
                        toggle={() => this.toggleReport()}
                        //style={{ margin: 0, flex: 1 }}
                        //onClosed={() => this.getSupplierReq()}
                        size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                        //contentClassName="full-screen-modal"
                        centered
                    >
                        <ModalHeader toggle={() => this.toggleReport()}> Inspection Report </ModalHeader>
                        <ModalBody>
                            <PowerBIReportViewer
                                reportName={"InspectionReport"} //{"R9Invoice"}
                                reportParameters={this.createReportParamter()}
                                //invoiceKey={invoiceKey}                            
                                toggle={this.toggleReport}
                            //refreshData={this.getInvovice}
                            >

                            </PowerBIReportViewer>
                        </ModalBody>
                    </Modal>
                </div>



                <div>
                    <Modal isOpen={this.state.modalMsgExt}
                        toggle={() => this.toggleMsgExt()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsgExt()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsgExt()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalReportExt}
                        toggle={() => this.toggleReportExt()}
                        //style={{ margin: 0, flex: 1 }}
                        //onClosed={() => this.getSupplierReq()}
                        size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                        //contentClassName="full-screen-modal"
                        centered
                    >
                        <ModalHeader toggle={() => this.toggleReportExt()}> External Spill Report </ModalHeader>
                        <ModalBody>
                            <PowerBIReportViewer
                                reportName={"ExternalSpillReport"} //{"R9Invoice"}
                                reportParameters={this.createReportParamter()}
                                //invoiceKey={invoiceKey}                            
                                toggle={this.toggleReportExt}
                            //refreshData={this.getInvovice}
                            >

                            </PowerBIReportViewer>
                        </ModalBody>
                    </Modal>
                </div>



            </div>

        );



        //       }
        //     }
        //   </FetchData>
        // );
    }
}