import React, { Component } from 'react';
import { Container,Alert } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  
  render() {
    // console.log("window.location.href")
    // console.log(window.location.href)
    return (    
      <div>
        <Alert color="warning" style={{ margin:"0px", padding:"0px"}} 
          isOpen={!(window.location.href.includes('https://portal.h2obridge.com/')) && !(window.location.href.includes('https://wbr-portal.azurewebsites.net'))}>
          {"****Development**** - " + window.location.href}
        </Alert>
        <NavMenu user={this.props.user}
          onSearchTextChange={this.props.onSearchTextChange} />
        <Container fluid={true}>
          {this.props.children}
        </Container>

      </div>
    );
  }
}
