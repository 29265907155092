import React, { useState } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, CardHeader, CardFooter } from 'reactstrap';
//import { USDCurrencyFormat, NumberFormat } from '../../resources/utility'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../custom.css';


const CardSummary = (props) => {
    var { Header, Title, TextLine1, TextLine2, ButtonText } = props

    return (<div>
        <CardHeader tag="h4"> {Header}</CardHeader>
        <Card body className="text-center" outline color="info">
            <CardTitle tag="h2"> <CreateLink LinkInfo={Title} onDetailClick={props.onDetailClick}> </CreateLink> </CardTitle>
            {/* <CardText>{TextLine1}</CardText> */}
            <CardText tag="h5"><CreateLink LinkInfo={TextLine1} onDetailClick={props.onDetailClick}> </CreateLink></CardText>
            {/* <Button>{ButtonText}</Button> */}
        </Card>
    </div>)
}


const CreateLink = (props) => {
    const { LinkInfo } = props
    console.log('Type:' + typeof (LinkInfo))

    if (typeof (LinkInfo) === "undefined" || LinkInfo === null) {
        return (<div>
            {'Not Available'}
        </div>)
    } else if (typeof (LinkInfo) === "number" || typeof (LinkInfo) === "boolean") {

        return (<div>
            <h5>{LinkInfo}</h5>
        </div>)

    } else if (LinkInfo.indexOf('@') < 0) {
        return (<div>
            <h5>{LinkInfo}</h5>
        </div>)
    } else {

        const links = LinkInfo.split(";")
        const hrefs = []

        links.forEach((link) => {
            if (hrefs.length > 0) {
                hrefs.push(<a>{' - '}</a>)
            }
            const details = link.split("@")
            // hrefs.push(<a href={details[1]} target="_blank"> {details[0]}</a>)
            hrefs.push(<a href="javascript:void(0);" onClick={() => props.onDetailClick(details[1], details[0])}> {details[0]}</a>)

        })

        return (
            <div>
                {hrefs}
            </div>
        )

    }
}

const SWIMSSummary = (props) => {

    const [modal, setModal] = useState(false)
    const [formParas, setFormParas] = useState({
        id: '',
        name: '',
        parameter: '',
        title: ''
    })

    const toggle = () => {
        //console.log("Modal: ")
        //this.setState({modal: !modal})
        setModal(!modal)      
    }

    const onDetailClick = (urls, urlName) => {
        //console.log("in URL:" + urls)
       // getFormParamenter(urls, urlName)
        toggle();
    }
const getFormName = (id) => {
        switch (id){
            case 'facility-insp':
                return 'Facility Inspections'
            case 'facility-cost':
                    return 'Facility Costs'
            case 'facility-event':
                return 'Facility Events'
            case 'facility-oil':
                return 'Oil Tickets'
            case 'facility-vol':
                return 'Meter Volumes'
            case 'tankVol':
                return 'Tank Volumes'
            case 'safety-obs':
                return 'Safety Observations'
            case 'facility-vol':
                return 'Meters Volumes'
            default:
                return''
        }
    }
    return(<div>
         <Row style={{padding:"0 0 15px 0"}}>
            <Col sm="4">
                <CardSummary Header="Low Stock Items" 
                            Title= "Low Stock" //{opsSummary.InspTitle}
                            TextLine1= "123 items" //{opsSummary.InspTextLine1} //"0 Need Reviews - 0 Missing" 
                            ButtonText="View Details"
                            onDetailClick={onDetailClick}> </CardSummary>
            </Col>

            <Col sm="4">
               <CardSummary Header="Issue/Request" 
                            Title="Issue/Request"//{opsSummary.CostTitle}
                            TextLine1="40 Pending Issues, 20 Active" //{opsSummary.CostTextLine1} 
                            //TextLine1=" (2) Labors= $661.75@facility-cost?CostType=Labor&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=422,423,424,425,427,428,429,430; Material= $128.45@facility-cost?CostType=Material&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=422,423,424,425,427,428,429,430"
                            ButtonText="View Details"
                            onDetailClick={onDetailClick}> </CardSummary>
            </Col>

            <Col sm="4">
                <CardSummary Header="Returns" 
                             Title="Returns" //{opsSummary.EventTitle} 
                             TextLine1= "40 Active -  60 Completed"//{opsSummary.EventTextLine1} 
                             //TextLine1="3 Normal (3 active)@facility-event?priority=2&amp;status=4&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility="
                             ButtonText="View Details"
                             onDetailClick={onDetailClick}> </CardSummary>
                
            </Col>
            
        </Row>
        <Row style={{padding:"0 0 15px 0"}}>
            <Col sm="4">
                <CardSummary Header="Receipts" 
                            Title= "Receipt MTD" //{opsSummary.InspTitle}
                            TextLine1= "123 items" //{opsSummary.InspTextLine1} //"0 Need Reviews - 0 Missing" 
                            ButtonText="View Details"
                            onDetailClick={onDetailClick}> </CardSummary>
            </Col>
           

            <Col sm="8">
                <CardSummary Header="Total Inventory" 
                             Title=" $15,233,999.89 values" //{opsSummary.EventTitle} 
                             TextLine1= "1200 Active Items - 4 Warehouses - 120 Inventory Locations"//{opsSummary.EventTextLine1} 
                             //TextLine1="3 Normal (3 active)@facility-event?priority=2&amp;status=4&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility="
                             ButtonText="View Details"
                             onDetailClick={onDetailClick}> </CardSummary>
                
            </Col>
            
        </Row>

    </div>)
}



export default SWIMSSummary;
