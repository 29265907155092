

import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, Card, CardBody, CardHeader } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert } from 'reactstrap';
import { ComponentRenderer } from 'igniteui-react-core';
import '../../custom.css';

import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



export default class AFECloseoutChk extends Component {
    static displayName = AFECloseoutChk.name;
    constructor(props) {
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onCellValueChanging = this.onCellValueChanging.bind(this);

        this.state = {
            closeoutChk: [],
            isDataLoaded: false,
            afeHeaderKey: props.afeHeaderKey,
            showAlert: false,
            alertMsg: '',
            isFormReadOnly: props.isFormReadOnly
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.closeoutChk;
        //this.onLoad()
    }


    componentDidMount() {
        this.getCloseoutSC()
    }

    getCloseoutSC = () => {
        const { afeHeaderKey } = this.state

        let myURI = apiURL + 'AFE/closeout/CHKL/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    closeoutChk: json
                })
            });
    }


    render() {
        const { isDataLoaded, closeoutChk, isFormReadOnly } = this.state

        if (!isDataLoaded) {
            return (<div>Loading Closeout department .......</div>)
        } else
            return (<div>
                <Form>

                    <h5>Project Manager Completion Checklist (Answer Yes or No)</h5>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="100%"
                        width='100%'
                        dataSource={closeoutChk}
                        isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        groupSummaryDisplayMode={"RowTop"}
                        groupHeaderDisplayMode="combined"
                        summaryScope="Both"
                        editMode={(isFormReadOnly ? 0 : 1)}
                        // editMode={(isFormReadOnly ? 0 : 1)}
                        // selectedKeysChanged={this.onGridRowSelected}
                        cellValueChanging={this.onCellValueChanging}
                    >

                        <IgrTemplateColumn
                            field="lineDescription"
                            headerText="Check List"
                            width="500"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="miscValue1"
                            headerText=""
                            width="150"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTextColumn
                            field="comments"
                            headerText="Comments"
                            width="*>250"
                        //cellUpdating={this.onCellUpdating}
                        />


                        <IgrNumericColumn field="afeHeaderKey" isHidden />
                        <IgrNumericColumn field="afeCloseoutLinesKey" isHidden />
                    </IgrDataGrid>
                </Form>

            </div >)
    }

    onCellValueChanging(s, e) {

        this.updateCloseoutLine(e.item.afeCloseoutLinesKey, e.column.field, e.newValue, e)
    }

    updateCloseoutLine = (key, fieldName, value, e) => {
        const { user } = this.props
        const {closeoutChk} = this.state

        var closeoutDTO

        closeoutChk.map(k=>{
            if (k.afeCloseoutLinesKey===key){
                closeoutDTO = {
                    "afeCloseoutLinesKey": key,
                    "miscValue1": (fieldName === "miscValue1" ? value : k.miscValue1),
                    "comments": (fieldName === "comments" ? value : k.comments),
                    "modifiedBy": getUserADAccount(user.account.userName)
                }                
                return
            }
        })

      

        console.log("closeoutDTO")
        console.log(closeoutDTO)

        let myURI = apiURL + 'AFE/Closeout'
        fetch(myURI, apiPOSTHeader(closeoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    return Promise.reject(error)
                } else
                    return "Success"
            }).then(json => {
                console.log("Closeout Updated")
            }).catch(error => {
                console.log("PUT error: " + error)
            })

            this.props.chkUpdate(closeoutChk)
    }


    onCellUpdating(s, e) {

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        let select;

        if (content.childElementCount === 0) {

            if (s.field === "miscValue1") {
                select = document.createElement("select");

                select.addEventListener("change", () => {
                    this.closeoutChkChange(item.afeCloseoutLinesKey, select.value)
                })


                var option = document.createElement("option");
                option.setAttribute("value", -1);
                option.text = "";
                select.appendChild(option);

                var opYes = document.createElement("option");
                opYes.setAttribute("Yes", -1);
                opYes.text = "Yes";
                opYes.style.fontSize = "13px";
                opYes.style.fontFamily = "Verdana";
                select.appendChild(opYes);

                var opNo = document.createElement("option");
                opNo.setAttribute("No", -1);
                opNo.text = "No";
                opNo.style.fontFamily = "Verdana";
                opNo.style.fontSize = "13px";
                select.appendChild(opNo);
                
                select.style.padding = "0px 0px 0px 0px";
                select.style.margin = "0px 0px 0px 0px";
                select.style.width = "105%";
                select.style.fontFamily = "Verdana";
                select.style.fontSize = "13px";
                //select.style.verticalAlign = "center";

                select.setAttribute("class", "text-md-center form-control selectBorderLess");
               
                content.appendChild(select);


            } else {

                link = document.createElement("label");
                let cell = document.createElement("div");

                link.setAttribute("class", "form-control selectBorderLess");
                //link.style.fontFamily = "Verdana";
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";

                // content.style.margin = "0px -20px -10px -12px";
                link.style.padding = "8px 0px 0px 0px";
                // //content.style.width = "100%";
                content.appendChild(link);
            }
        } else {
            link = content.children[0]

            if (item.miscValue1 === "No" && s.field === "miscValue1") {
                link.style.background = "#F3B5A2";
            }
          
        }


        if (link !== undefined) {
            switch (s.field) {
                case 'lineDescription':
                    link.textContent = item.lineDescription;
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");

                    break;
                case 'miscValue1':
                    link.value = item.miscValue1;
                    break;
                default:
            }
        }
    }
    
    closeoutChkChange = (key, value) =>{
        const {closeoutChk} = this.state

        closeoutChk.map(k=>{
            if (k.afeCloseoutLinesKey===key){
                k.miscValue1 = value
                return
            }
        })

        this.updateCloseoutLine(key, 'miscValue1', value ,null)
        
    }

} 