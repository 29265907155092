
import React, { Component,ReactDOM } from 'react';

import { Container, Col, Row, Button, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup } from 'reactstrap';
import { apiURL } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import FetchData from '../resources/FetchData';
import InspectionItem from './InspectionItem';
import { CT_NonVisualGroupDrawingShapeProps } from 'igniteui-react-excel';
import { IFunctionFilterExpression_$type, TemplateSectionHeaderCellInfoDescription } from 'igniteui-react-core';
import {getUserADAccount} from '../resources/utility'
import ConfirmModal from './ConfirmModal'

export default class InspectionDetail extends Component {
  static displayName = InspectionDetail.name;

  constructor(props) {
    super(props);



    const urlPara=this.getURLParameters(props.location)
      console.log("loc search")
        console.log(this.props.location.search)

    this.state = {childValue: '',
                data:[],
                hdrData:[],
                isLoaded:false,
                isHdrLoaded: false,
                facilityInspLineItemForUpdateDTO:[],
                facilityComments:'',
                inspComments:'',
                reloadForm:false,
                inspHdrKey:urlPara.inspHdrKey} 
  }

  getURLParameters = (inputPara) => {
    var paraMeters={
        inspHdrKey:null}
       

        // if ( !this.props.location==='undefined' || inputPara!=='undefined')
        // {
        //     var query
        //     if (!this.props.location==='undefined')
        //     {
        //         query = new URLSearchParams(this.props.location.search);
            
        //     }else{
        //         query = new URLSearchParams(inputPara);            
        //     }
            
        //     paraMeters={
        //        inspHdrKey:query.get('inspHdrKey') 
        //     }
        // }
    
        var query
        query = new URLSearchParams(this.props.location.search);
        console.log("query")
        console.log(query.get('inspHdrKey') )

        paraMeters={
          inspHdrKey:query.get('inspHdrKey') 
       }
    return paraMeters
}


  isAllowEdit = (hdrData) => {
    if (hdrData.statusKey === 6){
      return false
    }else{return true}
  }

  

  createHdrForUpdateDTO=()=>{
    const {hdrData} = this.state; 
    const {user} = this.props   

    const hdrDTO = {
      "facilityInspectionHdrKey": hdrData[0].facilityInspectionHdrKey,
      "comments": hdrData[0].inspComments,
      "inspComments": hdrData[0].inspComments,
      "facInspComments": hdrData[0].facInspComments,
      "statusKey": hdrData[0].statusKey,
      "modifiedBy": getUserADAccount(user.account.userName) // "hau.nguyen"
    }
    return hdrDTO;
  }
  
  createInspLineItemForUpdateDTO=()=>{
    const {data,inspComments,facInspComments,hdrData} = this.state;
    const {user} = this.props
    var tempDTO=[];
    var i=0;
    data.map(function (line) {
      const uLine={
        "facilityInspLineItemKey": line.facilityInspLineItemKey,
        "inspLineItemTemplateKey": line.inspLineItemTemplateKey,
        "lineReportDate": line.lineReportDate,
        "inspLineValue": line.inspLineValue,
        "comments": line.comments,
        "lineModifiedBy": getUserADAccount(user.account.userName), //line.lineModifiedBy,
        "latitude":line.latitude,
        "longtitude":line.longtitude,
        "customValueLabel1": line.customValueLabel1,
        "customValue1": line.customValue1,
        "customValueLabel2": line.customValueLabel2,
        "customValue2": line.customValue2,
        "customValueLabel3": line.customValueLabel3,
        "customValue3": line.customValue3,
        "createEvent": 0,
        "customValueLabel4": line.customValueLabel4,
        "customValue4": line.customValue4,
        "customValueLabel5": line.customValueLabel5,
        "customValue5": line.customValue5,
        "customValueLabel6": line.customValueLabel6,
        "customValue6": line.customValue6,
        "customValueLabel7": line.customValueLabel7,
        "customValue7": line.customValue7,
        "customValueLabel8": line.customValueLabel8,
        "customValue8": line.customValue8,
        "customValueLabel9": line.customValueLabel9,
        "customValue9": line.customValue9,
        "customValueLabel10": line.customValueLabel10,
        "customValue10": line.customValue10,
        "facilityInspComment": hdrData[0].inspComments + '@@#@@' + hdrData[0].facInspComments
      }

      tempDTO[i] = uLine;
      i = i + 1;
    });

    return tempDTO;
  }

  onHandleInspHdrChange = (fieldID, value) => {
    console.log(fieldID + ': ' + value )
    
    if (fieldID === 'inspComments') {
      var {isHdrLoaded, hdrData} = this.state;

      if (isHdrLoaded){
        hdrData[0].inspComments = value;
      }
      this.setState({inspComments:value})
    }
  
    if (fieldID === 'facInspComments'){
      var {isLoaded, data} = this.state;  
      var {isHdrLoaded, hdrData} = this.state;
      
      this.setState({facInspComments:value})

      if (isHdrLoaded){
        hdrData[0].facInspComments = value;
      }

      if (isLoaded){
        data.map(function (line){
          line.facilityInspComment = value;
          //console.log("update")
        });        
      }

    }
  }

  onHandleInpsLineCustValueChange = (key, custValueLabel, value) => {
    console.log(key + ' ' + custValueLabel + ' ' + value)
    this.setState({childValue: value});
    var {isLoaded,data} = this.state
    if (isLoaded){       
        data.map(function (line){
          if (line.facilityInspLineItemKey === key){
              console.log("found it: " )

              switch(custValueLabel){
                case "comments": 
                  line.comments = value              
                  return  
                case "customValue1": 
                  line.customValue1 = value;
                  return;
                case "customValue2": 
                  line.customValue2 = value;
                  return;
                case "customValue3": 
                  line.customValue3 = value;
                  return;
                case "customValue4": 
                  line.customValue4 = value;
                  return;
                case "customValue5": 
                  line.customValue5 = value;
                  return;
                case "customValue6": 
                  line.customValue6 = value;
                  return;
                case "customValue7": 
                  line.customValue7 = value;
                  return;
                case "customValue8": 
                  line.customValue8 = value;
                  return;
                case "customValue9": 
                  line.customValue9 = value;
                  return;
                case "customValue10": 
                  line.customValue10 = value;
                  return
                case "inspLineValue": 
                  line.inspLineValue = value;
                  return                           
                default:
                  return
              }
              return;             
          } 
        })

    }
  }

  saveHdrData = () => {
    const hdrDTO = this.createHdrForUpdateDTO();
    console.log(hdrDTO)

    const putReq = {
      method: 'PUT',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(hdrDTO)
    }

    let myURI = apiURL + 'FacilityInspLineItem/Header'
    fetch(myURI , putReq )
      .then(async response => {
        const rspData = response.text();
        if (!response.ok){
          console.log("Error in update hdr return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          return Promise.reject(error);
          
        }

        console.log("No in update hdr here: " + rspData)
      })
      .catch(error =>{
        console.log("PUT HDR update Error: " + error);
      })
  }

  

  saveDataDtl = () => {
    const itemDTO = this.createInspLineItemForUpdateDTO();
    console.log(itemDTO);

    const putReq = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(itemDTO)      
    }
    
    let myURI = apiURL + 'FacilityInspLineItem'
    fetch(myURI , putReq )
      .then(async response => {
        const rspData = response.text();
        if (!response.ok){
          console.log("Error return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          return Promise.reject(error);
          
        }

        console.log("No error here: " + rspData)
      })
      .catch(error =>{
        console.log("PUT Error: " + error);
      })
    //.then(json => console.log(json));

  }     

  saveData = () => {
    this.saveDataDtl()
    this.saveHdrData()

  }

  setComplete = () => {
    const {hdrData} = this.state;
    hdrData[0].statusKey = 6
    this.saveData()
    this.setState({reloadForm:!this.state.reloadForm})
  }

  
  reOpen = () => {
    const {hdrData} = this.state;
    hdrData[0].statusKey = 4
    this.saveData()
    this.setState({reloadForm:!this.state.reloadForm})
  }

  
  getInspHdrData = () => {
    const {inspHdrKey} = this.state
    console.log("inspKey")
    console.log(inspHdrKey)
    
    let myURI = apiURL + 'FacilityInspLineItem/Header/Key/' + inspHdrKey
    console.log(myURI)
    fetch(myURI, {modes: 'no-cors'})
    .then(res => res.json())
    .then(json => {
      this.setState({hdrData:json,
                     isHdrLoaded:true});
    });
    
  }


  getInpsData = () => {
    const {inspHdrKey, isHdrLoaded} = this.state
    let myURI = apiURL + 'FacilityInspLineItem/lineitem/' + inspHdrKey

    console.log("isHdrLoaded")
    console.log(isHdrLoaded)

    fetch(myURI, {modes: 'no-cors'})
    .then(res => res.json())
    .then(json => {
      this.setState({data:json,
                     isLoaded:true,
                    inspComments:json[0].inspComments,
                    facInspComments:json[0].facInspComments});
      
      
      console.log(this.state.data)
    });
  }

  componentDidMount(){
  

    this.getInspHdrData()
    this.getInpsData()
   
  }

 

  render() {
  
    var {isLoaded, data, isHdrLoaded, hdrData} = this.state

        if (!isLoaded || !isHdrLoaded) return <h1> loading </h1> 

            return (              
              <div>
                <FormGroup>
                  <Row form>
                    <h3> Inspection Details |</h3>
                    <Button outline
                      color="secondary"
                      className="btn-no-border"
                      onClick={this.getInspDtlData}>
                      <img
                        src={MenuIcon}
                        alt="refresh"
                        style={{ width: "30px", margin: "0 0 0 0" }} />
                        Refresh
                    </Button>

                    <Button outline
                      color="secondary"
                      className="btn-no-border"
                      onClick={this.saveData}>
                      <img
                        src={MenuIcon}
                        alt="save"
                        style={{ width: "30px", margin: "0 0 0 0" }} />
                      Save
                    </Button>
                    
                    {/* <Button outline
                      color="secondary"
                      className="btn-no-border"
                      onClick={this.setComplete}
                      hidden={hdrData[0].statusKey !== 6 ? false : true}>
                      <img
                        src={MenuIcon}
                        alt="SetComplete"
                        style={{ width: "30px", margin: "0 0 0 0" }} />
                      Set Complete
                    </Button> */}

                    {/* <Button outline
                      color="secondary"
                      className="btn-no-border"
                      onClick={this.reOpen}
                      hidden={hdrData[0].statusKey === 6 ? false : true}
              
                      >
                      <img
                        src={MenuIcon}
                        alt="Edit"
                        style={{ width: "30px", margin: "0 0 0 0" }} />
                      ReOpen
                    </Button> */}

                    <ConfirmModal outline={true}
                                  color="secondary"
                                  className="btn-no-border"
                                  buttonLabel="ReOpen"
                                  hidden = {hdrData[0].statusKey === 6 ? false : true}
                                  formName="Confirm"
                                  message="Are you sure you want to reopen this Facility Inspection?"
                                  onClickYes={this.reOpen}
                                  image={<img
                                    src={MenuIcon}
                                    alt="Edit"
                                    style={{ width: "30px", margin: "0 0 0 0" }}/>}>

                    </ConfirmModal>

                    <ConfirmModal outline={true}
                                  color="secondary"
                                  className="btn-no-border"
                                  buttonLabel="Set Complete"
                                  hidden={hdrData[0].statusKey !== 6 ? false : true}
                                  formName="Confirm"
                                  message="Are you sure you want to set Facility Inspection to complete?"
                                  onClickYes={this.setComplete}
                                  image={<img
                                    src={MenuIcon}
                                    alt="Edit"
                                    style={{ width: "30px", margin: "0 0 0 0" }}/>}>

                    </ConfirmModal>

                  </Row>
                  
                  <h5><label>{data[0].inspectionNo}</label>  <span>&nbsp;&nbsp;</span>
                    <label>| Status: {data[0].facilityInspStatusDesc} </label>  <span>&nbsp;&nbsp;</span>                    
                    <label>| By: {data[0].lineCreatedByName} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| On: {new Date(data[0].reportDate).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                    <label>| At: {data[0].facilityName}</label>
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup>

                <Container className="float-left">

                  <FormGroup>
                    <Row>
                 
                      <Col md={12}>
                        <Label for="txtFacCmt">
                          Facility Notes
                      </Label>
                        <Input type="textarea"
                          id="txtFacCmt"
                          defaultValue={hdrData[0].facInspComments}
                          rows="4"
                          onChange={(e) => this.onHandleInspHdrChange("facInspComments",e.target.value,e)}
                          readOnly={hdrData[0].statusKey === 6 ? true : false}>
                          
                        </Input>


                        <Label for="txtInspCmt">
                          Inspection Daily Comments
                        </Label>
                        <Input type="textarea"
                          id="txtinstCmt"
                          defaultValue={hdrData[0].inspComments}
                          rows="4"
                          onChange={(e) => this.onHandleInspHdrChange("inspComments",e.target.value,e)}
                          readOnly={hdrData[0].statusKey === 6 ? true : false}>

                        </Input>

                      </Col>
                    </Row>

                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <InspectionItem data={data} isAllowEdit={this.isAllowEdit(hdrData[0])} onHandleInpsLineCustValueChange={this.onHandleInpsLineCustValueChange} 
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Container>


                {/* <div>
                 
                  <pre>{JSON.stringify(this.props.user, null, 2)}</pre>
                </div> */}

              </div>

            );



    //       }
    //     }
    //   </FetchData>
    // );
  }
}