
import React, { useState } from 'react';
import { Col, Row, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText, FormFeedback } from 'reactstrap';
// import { apiURL, apiHeader } from '../../resources/apiURL';
// 
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import { Form, Card, CardHeader, CardBody, CustomInput, Container } from 'reactstrap';



const SafetyIncidentNewReport = (props) => {

  // const {buttonColor,
  //       buttonLabel,
  //       className,
  //       formName,
  //       facilityEventKey} = props

  var ddval;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const toggleOK = () => {
    setModal(!modal)
    // props.onClickOK(ddKey.value)
    props.onClickOK(ddval);


  }


  const onTextChange = (fieldValue, e) => {
    ddval = fieldValue;

  }




  return (
    <div>
      {/* <Button 
                    // outline = {facilityEventKey > 0 ? false : true} 
                    // color={buttonColor}
                    onClick={toggle} style={{ margin: "0 5px 0 5px" }} >
                Add New Report
            </Button> */}
      <Button outline color="secondary"
        disabled={props.disabled}
        className="btn-no-border"
        onClick={toggle}>
        <img
          src={Plus}
          alt="new"
          style={{ width: "25px", margin: "0 5px 0 0" }} />
        Add New Report
      </Button>
      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}> New Report</ModalHeader>
        <ModalBody>

          <div>

            <FormGroup row>
              <Col md={12}>
                
                
                    {/* 1 row */}
                    <FormGroup row>

                      <Col md={12}>
                        {/* <Label for="otherInfoSupervisorKey">Supervisor</Label> */}

                        <CustomInput
                          type="select"
                          id="ddKey"
                          name="ddKey"
                          //defaultValue={2}
                          //  value={2}
                          //disabled={true}        //{isReadOnly || (safetyObservationKey<0)}
                          onChange={(e) => { onTextChange(e.target.value, e) }}
                        >
                          <option value=""></option>

                          {props.ddropdown.map(v => <option value={v.typeKey}>{v.typeDesc}</option>)}
                        </CustomInput>




                      </Col>
                    </FormGroup>









                 
              </Col>


            </FormGroup>


          </div>


        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleOK}>OK</Button>
          <Button outline color="primary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SafetyIncidentNewReport;