import React, { Component } from 'react';
import { apiURL, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import CopyIcon from '../resources/copyicon.png'
import TrashIcon from '../resources/trash.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';
import ConfirmModal from '../operation/ConfirmModal'
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import InventoryLocDetails from './InventoryLocDetails'
import { apiHeader } from '../resources/apiURL';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();


export default class InventoryLoc extends Component {

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        //this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.onLoad = this.onLoad.bind(this);

        this.state = {
            locationKey: null,
            duplicateBool: false,
            modalMsg:false,
            msgBody:"",
            msgHeader:"",
            showAlert:false,
            alertMessage:"",
            invLocationData: [],
            isInvLocLoaded: false,
            invLoc: [],
            windowHeight: window.innerHeight,
            invLocationKey: -1,
            statusDesc: '',
            warehouseKey: -1,
            statusKey: 1,
            modal: false,
            invGrpModal: false
        }
    }
    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }
    toggle = () => {
        // console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })

    }

    duplicateToggle=()=>{
        this.setState({ 
            duplicateBool: true,
            modal: !this.state.modal })
    }

    invGroupToggle = () => {
        console.log("Modal: " + this.state.invGrpModal)
        this.setState({ invGrpModal: !this.state.invGrpModal })

    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.invLoc;

    }
    onLoad() {
        const { isInvLocLoaded } = this.state
        // //if (isInvLocLoaded) {            
        // const location = new IgrColumnGroupDescription();
        // location.field = "locationName";
        // location.displayName = "Basin";
        // const warehouseID = new IgrColumnGroupDescription();
        // warehouseID.field = "warehouseName";
        // warehouseID.displayName = "WarehouseName";
        // const subArea = new IgrColumnGroupDescription();
        // subArea.field = "locAisle";
        // subArea.displayName = "";

        // this.grid.groupDescriptions.add(location);
        // this.grid.groupDescriptions.add(warehouseID);
        // // this.grid.groupDescriptions.add(subArea);
        // //}

    }

    getInvLocData = () => {

        let { warehouseKey } = this.state
        var myURI = apiURL + 'SupplyChain/InvLoc'

        if (warehouseKey > 0) {
            myURI = myURI + '/WH/' + warehouseKey
        }

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isInvLocLoaded: true,
                    invLoc: json
                })
            });

    }

    newInvLoc = () => {
        this.setState({ invLocationKey: -1 })
        this.toggle()
    }

    openInventoryGroup = () => {

        this.invGroupToggle()
    }

    onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // console.log("dataItem")
            console.log(dataItem)

            if (dataItem.level == null) {
                let clickedRow = e.addedKeys.item(0)
                let dataItem = clickedRow.value[0]
                // console.log(dataItem)

                this.setState({
                    invLocationKey: dataItem.invLocationKey,
                    statusDesc: dataItem.statusDesc,
                    statusKey: dataItem.statusKey,
                    invLocationData: dataItem,
                    locationKey: dataItem.locationKey
                })
                // this.toggle()
                //this.onVolRefresh()
            }

        }
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    deleteData = () => {
        
        const dataForDeleteDTO = this.createDeleteData()

        let myURI = apiURL + 'SupplyChain/InvLoc'

        fetch(myURI, apiDELETEHeader(dataForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                } else {
                }
            
                this.setState({invLocationKey: -1})
                this.getInvLocData()
            })
            .catch(error => {
                console.log(error)
                // this.getReceiverLine()
                this.getInvLocData()

            })

    }

    createDeleteData = () => {
        const { invLocationData } = this.state
        const { user } = this.props

        const lineDataForDeleteDTO = {
            "InvLocationKey": invLocationData.invLocationKey,
            "DeletedBy": getUserADAccount(user.account.userName),
        }

        return lineDataForDeleteDTO;
    }

    setStateOfParent = (newTitle) =>{

        this.setState({duplicateBool: newTitle}, ()=>{

        })
        
    }


    render() {
        // var { isInvLocLoaded, invLoc, windowHeight, invLocationKey,
        //     warehouseKey, statusDesc, statusKey, invLocationData, msgHeader,msgBody, duplicateBool } = this.state;
            const { isInvLocLoaded, invLoc, windowHeight, invLocationKey,
                warehouseKey, statusDesc, statusKey, invLocationData,
                 msgHeader,msgBody, duplicateBool, locationKey} = this.state
        const gridHeight = (windowHeight * 0.80) + "px"
        const searchText = window.$searchText.toLowerCase()

        // if (!isInvLocLoaded) {
        //     return (<div>
        //         <h3>Loading Inventory Locations Data.....</h3>
        //     </div>)
        // } else
        return (<div>
            <FormGroup>
                <Row form>
                    <h2> Location Details |</h2>
                    <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.getInvLocData}>
                        <img
                            src={Refresh}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 5px 0 0" }} />
                        Refresh
                    </Button>
                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.saveWorkbook}>
                        <img
                            src={Excel}
                            alt="excel"
                            style={{ width: "50px", margin: "0 0 0 0" }} />
                        Export To Excel
                    </Button>{' '}

                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.newInvLoc}>
                        <img
                            src={Plus}
                            alt="new"
                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                        New
                    </Button>{' '}

                    <Button outline color="secondary"
                        className="btn-no-border"
                        disabled={invLocationKey < 0}
                        onClick={this.toggle}>
                        <img
                            src={MenuIcon}
                            alt="new"
                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                       Edit
                    </Button>{' '}

                    <Button outline color="secondary"
                            className="btn-no-border"
                            disabled={invLocationKey < 0}
                            onClick={this.duplicateToggle}>
                            <img
                                src={CopyIcon}
                                alt="new"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Duplicate
                        </Button>{' '}

                    {/* <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.openInventoryGroup}>
                        <img
                            src={MenuIcon}
                            alt="new"
                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                        Inventory Group
                    </Button>{' '} */}

                    <Col>
                        <ConfirmModal 
                        // outline={true}
                        
                                    color="danger"
                                    // className="btn-no-border:focus"
                                    className="float-right"
                                    // className="btn-no-border"
                                    buttonLabel="Delete"
                                    // hidden={isFormReadOnly}
                                    disabled={invLocationKey < 0}                         
                                    formName="Delete"
                                    id={invLocationKey}
                                    message="Are you sure you want to delete selected item?"
                                    onClickYes={(e) => this.deleteData()}
                                    image={<img
                                        
                                        src={TrashIcon}
                                        id={invLocationKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>
                                </Col>

                    <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader} 
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>
                </Row>
                <ColoredLine color="grey" />
            </FormGroup>

            <FormGroup>
                <IgrDataGrid
                    ref={this.onGridRef}
                    height={gridHeight}
                    // dataSource={invLoc}
                    dataSource={invLoc.filter(rec => ((rec.invLocationID || '').toLowerCase().includes(searchText)) ||
                        (rec.invLocationDesc || '').toLowerCase().includes(searchText) ||
                        (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                        (rec.warehouseID || '').toLowerCase().includes(searchText) ||
                        (rec.warehouseName || '').toLowerCase().includes(searchText) ||
                        (rec.locationName || '').toLowerCase().includes(searchText) ||
                        (rec.rack || '').toLowerCase().includes(searchText) ||
                        (rec.locRow || '').toLowerCase().includes(searchText) ||
                        (rec.bin || '').toLowerCase().includes(searchText) ||
                        (rec.createdBy || '').toLowerCase().includes(searchText))
                    
                    
                    }
                    
                    isColumnOptionsEnabled="true"
                    autoGenerateColumns="false"
                    editMode={0}
                    filterUIType="FilterRow"
                    selectionMode="SingleRow"
                    isGroupCollapsable={true}
                    isGroupExpandedDefault={false}
                    groupHeaderDisplayMode="Split"
                    selectedKeysChanged={this.onGridRowSelected}
                    scrollable={true}
                //cellBackground={"#ffbfbf"}
                >
                    <IgrTextColumn field="locationName" headerText="Basin" width="*>190" horizontalAlignment="center"

                    />
                    {/* <IgrTextColumn field="warehouseID" headerText="Warehouse ID"
                        width="*>140" horizontalAlignment="center"
                    /> */}


                    <IgrTextColumn field="warehouseName" headerText="Warehouse/Yard Name"
                        width="*>150"

                    />

                    <IgrTextColumn field="invLocationID" headerText="Location ID"
                        width="*>150" />


                    <IgrTextColumn field="invLocationDesc" headerText="Location Description"
                        width="*>400" />


                    <IgrTextColumn field="locAisle" headerText="Aisle"
                        width="*>110" />

                    <IgrTextColumn field="rack" headerText="Rack"
                        width="*>110" />    

                    <IgrTextColumn field="locRow" headerText="Row"
                        width="*>110" />


                    <IgrTextColumn field="bin" headerText="Bin"
                        width="*>110" />

                    <IgrTextColumn field="statusDesc" headerText="Status"
                        width="*>140" />

                    <IgrTextColumn field="createdBy" headerText="Created By"
                        width="*>150" />

                    <IgrTextColumn field="createdDateTime" headerText="Create Date"
                        width="*>140" dateTimeFormat={"DateShort"}
                    />


                    <IgrNumericColumn field="warehouseKey" isHidden />
                    <IgrNumericColumn field="invLocationKey" isHidden />
                </IgrDataGrid>
            </FormGroup>


            <div>
                <Modal isOpen={this.state.modal}
                    toggle={() => this.toggle()}
                    size="md"
                    onClosed={()=> this.setState({duplicateBool:false})}
                    centered>
                    <ModalHeader className={(statusKey == 0 ? "modal-header reject" : "")}
                        toggle={() => this.toggle()}>{duplicateBool == true ? "Location Details " + "-" + statusDesc + " - " + "Duplicate" : invLocationKey < 0 ? "New Location" : "Location Details - " + statusDesc}
                    </ModalHeader>
                    <ModalBody>
                        <InventoryLocDetails
                            invLocationKey={invLocationKey}
                            user={this.props.user}
                            toggle={this.toggle}
                            refreshData={this.getInvLocData}
                            duplicateBool = {duplicateBool}
                            setStateOfParent = {this.setStateOfParent}
                            locationKey = {locationKey}
                            >

                        </InventoryLocDetails>

                    </ModalBody>
                </Modal>
            </div>

            <div>
                <Modal isOpen={this.state.invGrpModal}
                    toggle={() => this.invGroupToggle()}
                    size="lg"
                    centered>
                    <ModalHeader className={(statusKey == 0 ? "modal-header reject" : "")}
                        toggle={() => this.invGroupToggle()}>Inventory Group
                    </ModalHeader>
                    <ModalBody>
                        {/* <InventoryLocDetails 
                        invLocationKey={invLocationKey}
                        user={this.props.user}
                        toggle={this.toggle}
                        refreshData={this.getInvLocData}>

                        </InventoryLocDetails> */}
                        Inventory Group
                    </ModalBody>
                </Modal>
            </div>


        </div>)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.grid.isGroupCollapsable = true;

        this.getInvLocData()
        this.onLoad();

        // window.addEventListener('load', this.onLoad);
    }



    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.statusKey == 4) {
                content.style.background = "#b6a065";
            } else if (item.statusKey == 6) {
                content.style.background = "#61a066";
            } else if (item.statusKey == 0 || item.statusKey == 3 || item.statusKey == 5) {
                content.style.background = "#c69089";

            } else {
                content.style.background = "transparent";
            }
        }


        switch (s.field) {
            case 'statusDesc':
                link.textContent = item.statusDesc;
                break;
            case 'warehouseID':
                link.textContent = item.warehouseID;
                break;
            case 'warehouseName':
                link.textContent = item.warehouseName;

                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            // case 'hazardDesc':
            //     link.textContent = item.hazardDesc;
            //     break;      
            // case 'solutionDesc':
            //     link.textContent = item.solutionDesc;
            //     break;       
            // case 'createdByName':
            //     link.textContent = item.createdByName;
            //     break;          
            // case 'managerName':
            //     link.textContent = item.managerName;
            //     break;
            // case 'locationName':
            //     link.textContent = item.locationName;
            //     break;    
            // case 'facilityName':
            //     link.textContent = item.facilityName;
            //     break;                                     
            default:

        }

    }


    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "InventoryLocation");
    }
}