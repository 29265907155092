import React,{useState} from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, CardHeader, CardFooter } from 'reactstrap';
import {USDCurrencyFormat,NumberFormat} from '../resources/utility'
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import '../custom.css';
import FacilityInsp from './FacilityInspection';
import TankVolume from './TankVolume';
import FacilityCost from './FacilityCost';
import FacilityOilTicket from './FacilityOilTicket'
import FacilityEvent from './FacilityEvent'
import SafetyObservation from '../qhse/SafetyObservation'
import MeterVolume from './MeterVolume'
//const [modal, setModal] = useState(false)

const CartSummary = (props) => {
    var {Header,Title,TextLine1,TextLine2,ButtonText} = props 

    return(<div>
         <CardHeader tag="h4"> {Header}</CardHeader>
                <Card body className="text-center" outline color="info">
                    <CardTitle tag="h2"> <CreateLink LinkInfo={Title} onDetailClick={props.onDetailClick}> </CreateLink> </CardTitle>
                    {/* <CardText>{TextLine1}</CardText> */}
                    <CardText tag="h5"><CreateLink LinkInfo={TextLine1} onDetailClick={props.onDetailClick}> </CreateLink></CardText>                    
                    {/* <Button>{ButtonText}</Button> */}
         </Card>
    </div>)
}

const CreateLink = (props) => {
    const {LinkInfo}=props
    console.log('Type:' + typeof(LinkInfo))

    if (typeof(LinkInfo)==="undefined" || LinkInfo === null){
        return(<div>
            {'Not Available'}
        </div>)
    }else if (typeof(LinkInfo)==="number" || typeof(LinkInfo)==="boolean"){
        
        return(<div>
            <h5>{LinkInfo}</h5>
        </div>)

    }else if (LinkInfo.indexOf('@')<0){
        return(<div>
            <h5>{LinkInfo}</h5>
        </div>)    
    }else{

    const links = LinkInfo.split(";")
    const hrefs =[]
    
    links.forEach((link) => {
        if (hrefs.length>0) {
            hrefs.push(<a>{' - '}</a>)
        }
        const details = link.split("@")
        // hrefs.push(<a href={details[1]} target="_blank"> {details[0]}</a>)
        hrefs.push(<a  href="javascript:void(0);" onClick={() => props.onDetailClick(details[1],details[0])}> {details[0]}</a>)
        
    })

    return (
        <div>
          {hrefs}
        </div>
      )

    }
}

const ShowModalData = (props) => {
    const {details,parameters,user} = props;
    //console.log("details.toLowerCase : " + details)
    
    switch (details.toLowerCase()) {
        case 'facility-insp':
            return <FacilityInsp parameters={parameters}  user={user}/>
        case 'facility-cost':
            return <FacilityCost parameters={parameters}  user={user}/>
        case 'facility-event':
            return <FacilityEvent parameters={parameters}  user={user}/>
        case 'facility-oil':
            return <FacilityOilTicket parameters={parameters}  user={user}/>
        case 'facility-vol':            
            return <MeterVolume parameters={parameters}  user={user} />
        case 'tankvol':
            return <TankVolume VoldHeaderLabel="" parameters={parameters}  user={user} hideAllFacilitySwitch={true}/>   
        case 'safety-obs' :
            return <SafetyObservation parameters={parameters}  user={user} />
         
        default:
            return <div>Data Not Available</div>
    }
}



const OperatorSummary = (props) => {
    const {FacilitySummary,user} = props
    const [modal, setModal] = useState(false)
    
    const [formParas,setFormParas] = useState({id:'',
                                               name:'',
                                               parameter:'',
                                               title:'' })

    const toggle = () => {
        //console.log("Modal: ")
        //this.setState({modal: !modal})
        setModal(!modal)      
    }

    const onDetailClick = (urls, urlName) => {
        //console.log("in URL:" + urls)
        getFormParamenter(urls, urlName)
        toggle();
    }

    const getFormName = (id) => {
        switch (id){
            case 'facility-insp':
                return 'Facility Inspections'
            case 'facility-cost':
                    return 'Facility Costs'
            case 'facility-event':
                return 'Facility Events'
            case 'facility-oil':
                return 'Oil Tickets'
            case 'facility-vol':
                return 'Meter Volumes'
            case 'tankVol':
                return 'Tank Volumes'
            case 'safety-obs':
                return 'Safety Observations'
            case 'facility-vol':
                return 'Meters Volumes'
            default:
                return''
        }
    }

    const getFormParamenter = (urls, urlName) => {
        //setModalDetails(urls)
        const para = urls.split('?')
        if (para.length >= 1) {
            //setFormName(para[0])
            setFormParas({id:para[0],
                         name:getFormName(para[0]),
                         parameter:para[1],
                         title:urlName})
        }
    }

   const getOpsSummary = () => {
        var OpSummary = {
            InspTitle:"0",
            InpsTextLine1:"0",
            InspButtonText:"",
            CostTitle:"$0.0",
            CostTextLine1:"0",
            CostButtonText:"",
            EventTitle:"$0.0",
            EventTextLine1:"0",
            EventButtonText:""
        }

        console.log(FacilitySummary)

        if (typeof(FacilitySummary) !== 'undefined') {
            if (FacilitySummary.length > 0){
                OpSummary = {
                    InspHeader: "Inspections" ,
                    startDate: FacilitySummary[0].startDate,
                    endDate: FacilitySummary[0].endDate,
                    InspTitle: FacilitySummary[0].headerInsp,
                    InspTextLine1:FacilitySummary[0].summaryInspDetails,
                    InspButtonText:"View Details",
                    CostTitle: FacilitySummary[0].headerCost ,//USDCurrencyFormat(FacilitySummary[0].totalAllCost),
                    CostTextLine1:FacilitySummary[0].summaryCostDetails,
                    CostButtonText:"View Cost Details",                    
                    EventTitle: FacilitySummary[0].headerEvent,
                    EventTextLine1:FacilitySummary[0].summaryEventDetails,
                    EventButtonText:"View Details",
                    OilTKTitle: FacilitySummary[0].headerSummaryOilTicket,
                    OilTKTextLine1:FacilitySummary[0].summaryOilTicket,
                    OilTKButtonText:"View Details",
                    MtrVolTitle: FacilitySummary[0].headerMeterVolume ,//NumberFormat(FacilitySummary[0].totalMeterVolumesInlet + FacilitySummary[0].totalMeterVolumesOutlet + FacilitySummary[0].totalMeterVolumesOther) + " bbl" ,
                    MtrVolTextLine1:FacilitySummary[0].summaryMeterVolume,
                    MtrVolButtonText:"View Details",  
                    SafetyTitle: FacilitySummary[0].headerSafetyObservation,
                    SafetyTextLine1:FacilitySummary[0].summarySafetyObservation,
                    SafetyButtonText:"View Details",
                    TankVolTitle: FacilitySummary[0].headerTankVolume,
                    TankVolTextLine1:FacilitySummary[0].summaryTankVolume,
                    TankVolButtonText:"View Details",                    
                }
            }
        }

        return OpSummary;
    }

    const opsSummary = getOpsSummary()
   
    return (<div>
        <Row style={{padding:"0 0 15px 0"}}>
            <Col sm="4">
                <CartSummary Header="Inspections" 
                            Title= {opsSummary.InspTitle}
                            TextLine1={opsSummary.InspTextLine1} //"0 Need Reviews - 0 Missing" 
                            ButtonText="View Details"
                            onDetailClick={onDetailClick}> </CartSummary>
            </Col>

            <Col sm="4">
               <CartSummary Header="Costs" 
                            Title={opsSummary.CostTitle}
                            TextLine1={opsSummary.CostTextLine1} 
                            //TextLine1=" (2) Labors= $661.75@facility-cost?CostType=Labor&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=422,423,424,425,427,428,429,430; Material= $128.45@facility-cost?CostType=Material&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=422,423,424,425,427,428,429,430"
                            ButtonText="View Details"
                            onDetailClick={onDetailClick}> </CartSummary>
            </Col>

            <Col sm="4">
                <CartSummary Header="Events" 
                             Title={opsSummary.EventTitle} 
                             TextLine1={opsSummary.EventTextLine1} 
                             //TextLine1="3 Normal (3 active)@facility-event?priority=2&amp;status=4&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility="
                             ButtonText="View Details"
                             onDetailClick={onDetailClick}> </CartSummary>
                
            </Col>
        </Row>
        <Row>
            <Col sm="4">
            <CartSummary Header="Oil Tickets" 
                             Title={opsSummary.OilTKTitle}
                             TextLine1={opsSummary.OilTKTextLine1} //"39 Accepted - 1 Rejected" 
                             //TextLine1="106 Accept (19215.80 bbl)@facility-oil?LoadStatus=1&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility="
                             ButtonText="View Details"
                             onDetailClick={onDetailClick}> </CartSummary>                
            </Col>
            <Col sm="4">
                
            <CartSummary Header="Safety Observations" 
                             Title={opsSummary.SafetyTitle} 
                             TextLine1={opsSummary.SafetyTextLine1} //"38 Complete - 1 Rejected - 1 Submitted" 
                             //TextLine1="11 Spill/Leak@safety-obs?SafetyType=3&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;11 Near Miss@safety-obs?SafetyType=1&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;2 Lockout/Tagout (LOTO)@safety-obs?SafetyType=9&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;1 Incident@safety-obs?SafetyType=4&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;9 Hazard@safety-obs?SafetyType=8&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility="
                             ButtonText="View Details"
                             onDetailClick={onDetailClick}> </CartSummary>

            </Col>

            <Col sm="4">

            <CartSummary Header="Meter Volumes" 
                             Title={opsSummary.MtrVolTitle} 
                             TextLine1={opsSummary.MtrVolTextLine1} //"200,000 Inlet - 180,000 outlet - 20,000 others" 
                             //TextLine1="1,235,750.00 Inlet@facility-vol?volType=Inlet&startDate=04/01/2021&endDate=04/30/2021&Facility=;2,271,685.00 Outlet@facility-vol?volType=Inlet&startDate=04/01/2021&endDate=04/30/2021&Facility=;-137,387.00 Other@facility-vol?volType=Inlet&startDate=04/01/2021&endDate=04/30/2021&Facility="
                            ButtonText="View Details"
                            onDetailClick={onDetailClick}> </CartSummary>

            </Col>
        </Row>

        <Row>
            <Col sm="4">
            <CartSummary Header="Tank Volumes" 
                             Title={opsSummary.TankVolTitle}
                             TextLine1={opsSummary.TankVolLine1} //"39 Accepted - 1 Rejected" 
                             //TextLine1="106 Accept (19215.80 bbl)@facility-oil?LoadStatus=1&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility="
                             ButtonText="View Details"
                             onDetailClick={onDetailClick}> </CartSummary>                
            </Col>
            <Col sm="4">
                
            <CartSummary Header="Meter Validation" 
                             Title={'Coming Soon'} 
                             TextLine1={'Coming Soon'} //"38 Complete - 1 Rejected - 1 Submitted" 
                             //TextLine1="11 Spill/Leak@safety-obs?SafetyType=3&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;11 Near Miss@safety-obs?SafetyType=1&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;2 Lockout/Tagout (LOTO)@safety-obs?SafetyType=9&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;1 Incident@safety-obs?SafetyType=4&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility=;9 Hazard@safety-obs?SafetyType=8&amp;User=art.Rodriguez@h2obridge.com&amp;startDate=04/01/2021&amp;endDate=04/30/2021&amp;Facility="
                             ButtonText="View Details"
                             onDetailClick={onDetailClick}> </CartSummary>

            </Col>

            <Col sm="4">

            <CartSummary Header="WorkOrder Requests" 
                             Title={"Coming Soon"} 
                             TextLine1={"Coming Soon"} //"200,000 Inlet - 180,000 outlet - 20,000 others" 
                             //TextLine1="1,235,750.00 Inlet@facility-vol?volType=Inlet&startDate=04/01/2021&endDate=04/30/2021&Facility=;2,271,685.00 Outlet@facility-vol?volType=Inlet&startDate=04/01/2021&endDate=04/30/2021&Facility=;-137,387.00 Other@facility-vol?volType=Inlet&startDate=04/01/2021&endDate=04/30/2021&Facility="
                            ButtonText="View Details"
                            onDetailClick={onDetailClick}> </CartSummary>

            </Col>
        </Row>

        
        <div>
            <Modal isOpen={modal} toggle={() => toggle()} contentClassName="custom-modal-style">
                    <ModalHeader toggle={() => toggle()}> {formParas.title}</ModalHeader>
                    <ModalBody>
                     {/* {formParas.parameter} */}
                        <ShowModalData details={formParas.id} parameters={formParas.parameter} user={user}></ShowModalData>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="primary" onClick={() => toggle()}>OK</Button>
                        <Button color="primary" onClick={() => toggle()}>Cancel</Button>
                    </ModalFooter> */}
            </Modal>
        </div>

    </div>);
};

export default OperatorSummary;
