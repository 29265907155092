//Modified by BN232208 
import React, { Component } from "react";
import {
  apiURL,
  apiHeader,
  apiPUTHeader,
  apiPOSTHeader,
  apiDELETEHeader,
} from "../../resources/apiURL";
import Revise from "../../resources/revise.png";
import Plus from "../../resources/plus.svg";
import Refresh from "../../resources/refresh.svg";
import Excel from "../../resources/excel.svg";
import "../../custom.css";
import { ExcelUtility } from "../../operation/ExcelUtility";
import Select from "react-select";
import { getUserADAccount, getRandomInt } from "../../resources/utility";
import { IgrTextColumn } from "igniteui-react-grids";
import { Col, Row, Button, FormGroup, Label, Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Input, CustomInput, InputGroupText } from "reactstrap";

import { IgrDataGrid } from "igniteui-react-grids";

import { IgrDataGridModule } from "igniteui-react-grids";
import { IgrNumericColumn } from "igniteui-react-grids";
import { IgrGridColumnOptionsModule } from "igniteui-react-grids";
import { Workbook } from "igniteui-react-excel";
import { WorkbookFormat } from "igniteui-react-excel";

import { IgrTemplateColumn } from "igniteui-react-grids";
import { ColoredLine, getFirstDayofMonth } from "../../resources/utility";
import MOCDetails from "./MOCDetails";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

export default class MOC extends Component {
  static displayName = MOC.name;

  constructor(props) {
    super(props);

    this.onGridRef = this.onGridRef.bind(this);
    this.onGridRowSelected = this.onGridRowSelected.bind(this);
    this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
    this.saveWorkbook = this.saveWorkbook.bind(this);
    this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);

    const urlPara = this.getURLParameters(props.location);
    // console.log(props.location)
    this.state = {
      startDate: getFirstDayofMonth().toISOString(),
      endDate: new Date().toISOString(),
      MOCData: [],
      isDataLoaded: false,
      mocQuestions: [],
      isMOCQuestionsLoaded: false,
      mocQuestionsResult: null,
      searchText: "",
      windowHeight: window.innerHeight,
      modalMOCDetails: false,
      modalMOCQuestions: false,
      mocKey: urlPara.mocKey === null ? -1 : urlPara.mocKey,
      mocStatus: null,
      mocNumber: null,
      statusKey: null,
      isMocSafetyAndHealthListLoaded: false,
      mocSafetyAndHealthList: [],
      isMocPriorityListLoaded: false,
      mocPriorityList: [],
      openMOCDetails: !(urlPara.mocKey === null),
      basinList: [],
      isBasinLoaded: false,
      facilityList: [],
      isFacilityListLoaded: false,
      assetList: [],
      isAssetListLoaded: false,
      selBasin: [],
      selStatus: [],
      fullMOCData: [],
      selMOCQuery: [],
      isMocEnvironmentListLoaded: false,
      mocEnvironmentList: [],
      isMocFinancialListLoaded: false,
      mocFinancialList: [],
      isStatusLoaded: false,
      isEmpListLoaded: false,
      isSecurityLoaded: false,
      isSecurityAdminLogin: true,
      isMOCApprover: true,
      isMOCUser: true,
      isMOCCommentStatusListLoaded: false,
      mocCommentStatusList: [],
      empList: [],
      statusList: [],
      mocUserList: [],
      isMOCUserListLoaded: false,
      mocCommentRecords: [],
      isMOCCommentLoaded: false,
      alertMessage: "",
      showAlert: false,
      mocQueryList: [
        {
          label: "My MOC",
          value: 1,
        },
        {
          label: "All MOC",
          value: 2,
        },
      ],
    };
  }
  getMOCData = () => {
    let myURI =
      apiURL +
      "MOC/" +
      "ADAccount/" +
      getUserADAccount(this.props.user.account.userName);
    //let myURI = apiURL + "MOC/GetMOC";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isDataLoaded: true,
          MOCData: json,
          fullMOCData: json,
          searchText: "",
          // MOCDetailsModal: false,
        });
        this.getDefaultMOCList(json);
        this.openMOCDetailsFromKey(json);
      });
  };

  getMOCCommentLine = () => {
    const { mocKey, mocCommentKey } = this.state;
    const { mocComment } = this.state;
    let myURI =
      apiURL +
      "MOC/Task/adAccount/" +
      getUserADAccount(this.props.user.account.userName);
    fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          mocCommentRecords: json.filter(
            (a) =>
              a.mocCommentStatusDesc != "Completed" &&
              a.mocCommentStatusDesc != "Canceled"
          ),
          isMOCCommentLoaded: true,
        });
      });
  };

  getDefaultMOCList = (e) => {
    // const filteredMOCData = e.filter((item) => item.statusKey !== 6);
    this.getFilterMOC(
      e,
      this.state.selMOCQuery,
      this.state.selBasin,
      this.state.selStatus
    );
  };

  setDefaultMOCQuery = () => {
    this.setState({ selMOCQuery: this.state.mocQueryList[0] });
  };

  openMOCDetailsFromKey = (mocList) => {
    const { openMOCDetails, mocNumber, mocStatus, mocKey } = this.state;
    if (openMOCDetails) {
      const selMOC = mocList.filter(
        (a) => Number(a.mocKey) === Number(this.state.mocKey)
      );
      if (selMOC.length > 0) {
        this.setState({
          mocNumber: selMOC[0].mocNumber,
          mocStatus: selMOC[0].mocStatusDesc,
        });
      }

      this.toggleMOCDetailsEdit()
    }
  };

  getURLParameters = (inputPara) => {
    var paraMeters = {
      mocKey: null,
    };
    var query;
    if (inputPara !== undefined) {
      query = new URLSearchParams(inputPara.search);

      paraMeters = {
        mocKey: query.get("key"),
      };
    }

    return paraMeters;
  };

  getMOCDataByStatus = (mocStatus) => {
    let myURI =
      apiURL +
      "MOC/startDate/" +
      this.state.startDate.toString().substring(0, 10) +
      "/enddate/" +
      this.state.endDate.toString().substring(0, 10) +
      "/AzureAD/" +
      this.props.user.account.userName +
      "/MOCStatus/" +
      mocStatus;

    return fetch(myURI, { modes: "no-cors" }).then((res) => res.json());
  };

  getMOCPriorityList = () => {
    let myURI = apiURL + "MOC/GetMOCPriority";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isMocPriorityListLoaded: true,
          mocPriorityList: this.createMOCPriorityList(json),
        });
      });
  };
  createMOCPriorityList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.mocPriorityKey;
      e.label = e.mocPriorityDesc;
      tempList.push(e);
    });

    return tempList;
  };

  getMOCSafetyAndHealthList = () => {
    let myURI = apiURL + "MOC/GetMOCSafetyAndHealth";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isMocSafetyAndHealthListLoaded: true,
          mocSafetyAndHealthList: this.createMOCSafetyAndHealthList(json),
        });
      });
  };

  createMOCSafetyAndHealthList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.mocSafetyAndHealthKey;
      e.label = e.mocSafetyAndHealthDesc;
      tempList.push(e);
    });

    return tempList;
  };

  getMOCEnvironmentList = () => {
    let myURI = apiURL + "MOC/GetMOCEnvironment";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isMocEnvironmentListLoaded: true,
          mocEnvironmentList: this.createEnvironmentList(json),
        });
      });
  };

  createEnvironmentList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.mocEnvironmentKey;
      e.label = e.mocEnvironmentDesc;
      tempList.push(e);
    });

    return tempList;
  };

  getMOCFinancialList = () => {
    let myURI = apiURL + "MOC/GetMOCFinancial";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isMocFinancialListLoaded: true,
          mocFinancialList: this.createFinancialList(json),
        });
      });
  };

  createFinancialList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.mocFinancialKey;
      e.label = e.mocFinancialDesc;
      tempList.push(e);
    });

    return tempList;
  };

  getBasin = () => {
    let myURI = apiURL + "Fiix/GetBasin";

    fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          basinList: this.createBasinList(json),
          isBasinLoaded: true,
        });
      });
  };

  createBasinList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.locationKey;
      e.label = e.strName;
      tempList.push(e);
    });

    return tempList;
  };

  getFacilityList = () => {
    let myURI = apiURL + "FacilityDetails";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isFacilityListLoaded: true,
          facilityList: this.createFacilityList(json),
        });
      });
  };

  createFacilityList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.facilityKey;
      e.label = e.facilityName;
      tempList.push(e);
    });

    return tempList;
  };

  getEmpList = () => {
    let myURI = apiURL + "EmployeeDetails";

    fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          empList: this.createEmpList(json),
          isEmpListLoaded: true,
        });
      });
  };

  createEmpList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.employeeKey;
      e.label = e.empName;
      tempList.push(e);
    });

    return tempList;
  };

  getAssetList = () => {
    let myURI = apiURL + "ItemMaster/FacilityInventoryCollector";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        // this.setState({
        //   isAssetListLoaded: true,
        //   assetList: this.createAssetList(json),
        // });
        const sortedData = json
          .map((item) => {
            // Remove leading spaces from itemDesc
            if (item.itemDesc) {
              item.itemDesc = item.itemDesc.trimStart();
            }
            return item;
          })
          .sort((a, b) => {
            const nameA = (a.itemDesc || "").toUpperCase();
            const nameB = (b.itemDesc || "").toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });

        this.setState({
          assetList: this.createAssetList(sortedData),
          isAssetListLoaded: true,
        });
      });
  };

  createAssetList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.facilityInvCollectorKey;
      e.label = e.itemDesc + " (" + e.qrCodeValue + ")";
      tempList.push(e);
    });

    return tempList;
  };

  getStatus = () => {
    let myURI = apiURL + "MOC/Status";

    fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          statusList: this.createStatusList(json),
          isStatusLoaded: true,
        });
      });
  };

  getMOCQuestions = () => {
    let myURI = apiURL + "MOC/Questions";

    fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          mocQuestions: json,
          isMOCQuestionsLoaded: true,
        });
        // console.log(json)
      });

  };

  getMOCUserList = () => {
    let myURI = apiURL + "SecAdmin/UserGroup/" + 67 + "/Employee"; //PROD
    // let myURI = apiURL + 'SecAdmin/UserGroup/' + 77 + '/Employee' //Preview1

    fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        // this.setState({
        //   mocUserList: this.createMOCUserList(json),
        //   isMOCUserListLoaded: true,
        // });
        const sortedData = json.sort((a, b) => {
          const nameA = (a.empName || "").toUpperCase();
          const nameB = (b.empName || "").toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.setState({
          mocUserList: this.createMOCUserList(sortedData),
          isMOCUserListLoaded: true,
        });
      });
  };

  createMOCUserList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.employeeKey;
      e.label = e.empName;
      tempList.push(e);
    });

    return tempList;
  };

  createStatusList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.mocStatusKey;
      e.label = e.mocStatusDesc;
      tempList.push(e);
    });

    return tempList;
  };

  getMOCCommentStatusList = () => {
    let myURI = apiURL + "MOC/MOCTaskStatus";
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isMOCCommentStatusListLoaded: true,
          mocCommentStatusList: this.createMOCCommentStatusList(json),
        });
      });
  };

  createMOCCommentStatusList = (value) => {
    var tempList = [];
    value.map((e) => {
      e.value = e.mocCommentStatusKey;
      e.label = e.mocCommentStatusDesc;
      tempList.push(e);
    });

    return tempList;
  };

  onGridRef(grid) {
    if (!grid) {
      return;
    }
    this.grid = grid;
    this.grid.dataSource = this.state.MOCData;
  }

  onGridRowSelected(s, e) {
    const { mocStatus } = this.state;
    const content = e.content;
    if (e.addedKeys.count > 0) {
      let clickedRow = e.addedKeys.item(0);
      let dataItem = clickedRow.value[0];
      // console.log(dataItem)
      this.setState({
        mocKey: dataItem.mocKey,
        mocStatus: dataItem.mocStatusDesc,
        mocNumber: dataItem.mocNumber,
        statusKey: dataItem.statusKey,
      });
    }
  }


  onTextChange = (fieldName, fieldValue, e) => {
    const { fullMOCData } = this.state;
    let { mocQuestionsResult } = this.state
    switch (fieldName) {
      case "mocSearch":
        this.setState({ searchText: fieldValue.toLowerCase() });
        break;

      case "mocQuery":
        this.getFilterMOC(
          fullMOCData,
          e,
          this.state.selBasin,
          this.state.selStatus
        );
        this.setState({ selMOCQuery: e });
        break;

      case "basin":
        this.getFilterMOC(
          fullMOCData,
          this.state.selMOCQuery,
          e,
          this.state.selStatus
        );

        this.setState({ selBasin: e });

        break;

      case "status":
        this.getFilterMOC(
          fullMOCData,
          this.state.selMOCQuery,
          this.state.selBasin,
          e
        );
        this.setState({ selStatus: e });
        break;

      // case "mocQuestions":
      //   console.log(fieldValue)
      // if (this.bMultiField(MOCData[0].mocQuestions, fieldValue)) {
      //   MOCData[0].mocQuestions = MOCData[0].mocQuestions - fieldValue;
      //   this.setState({MOCData: MOCData})
      // } else {
      //   MOCData[0].mocQuestions = MOCData[0].mocQuestions + fieldValue;
      //   this.setState({MOCData: MOCData})
      // }
      // console.log(fieldValue)

      // // if (this.bMultiField(mocData[0].hazardsKey, 256) === true) {
      // //   this.setState({ isTempHazardsOther: true });
      // // } else {
      // //   mocData[0].hazardsOther = "";
      // //   this.setState({ isTempHazardsOther: false });
      // //   validDataItem.hazardsOther = true;
      // // }

      // // validDataItem.hazardsKey = true;
      // break;

      case "mocQuestions":
        if (this.bMultiField(mocQuestionsResult, fieldValue)) {
          mocQuestionsResult &= ~(1 << (fieldValue - 1));
        } else {
          mocQuestionsResult |= 1 << (fieldValue - 1);
        }
        this.setState({ mocQuestionsResult: mocQuestionsResult });
        break;

      default:
        break;
    }
  };

  getFilterMOC = (e, m, b, s) => {
    const { user } = this.props;
    const { isSecurityAdminLogin, mocCommentRecords } = this.state;

    const basins = b.map((e) => e.locationName).join(",");
    const status = s.map((e) => e.mocStatusDesc).join(",");

    if (m !== null) {
      const userName = getUserADAccount(user.account.userName).toLowerCase();

      var tempMtlRequest = e;
      if (m.value === 1 && isSecurityAdminLogin === true) {
        tempMtlRequest = tempMtlRequest.filter(
          (e) =>
            (e.createdBy || "").toLowerCase() === userName ||
            (e.approvers || "").toLowerCase().includes(userName) ||
            (e.statusKey || "") === 8 ||
            (e.statusKey || "") === 6
        );
      }

      // else if (m.value === 1 && isSecurityAdminLogin === false) {
      //   console.log("this hit 1");
      //   console.log(mocCommentRecords.some((mocRecord) => mocRecord.mocKey === e.mocKey))
      //   tempMtlRequest = tempMtlRequest.filter(
      //     (e) =>
      //       ((e.createdBy || "").toLowerCase() === userName ||
      //       ((e.approvers || "").toLowerCase().includes(userName) && (e.statusKey || "") !== 8))
      //       && (e.statusKey || "") !== 6

      //   );
      // }

      // Create an empty array to store the matching mocCommentRecords
      else if (m.value === 1 && isSecurityAdminLogin === false) {
        tempMtlRequest = tempMtlRequest.filter((e) => {
          const eMocKey = e.mocKey || ""; // Assuming the property in `tempMtlRequest` is called `mocKey`
          return (
            (((e.createdBy || "").toLowerCase() === userName ||
              ((e.approvers || "").toLowerCase().includes(userName) &&
                (e.statusKey || "") !== 8)) &&
              (e.statusKey || "") !== 6) ||
            (mocCommentRecords.some((record) => record.mocKey === eMocKey) &&
              e.statusKey !== 6)
          );
        });
      }

      // if (m.value === 2 && isSecurityAdminLogin === true)
      // {

      //   tempMtlRequest = tempMtlRequest.filter(
      //     (e) =>
      //       (e.createdBy || "").toLowerCase() === userName ||
      //       (e.approvers || "").toLowerCase().includes(userName)
      //       || (e.statusKey || "") === 8
      //       || (e.statusKey || "") === 6
      //       // || (e.statusKey || "") !== 6

      //   );
      // }
      // else
      if (m.value === 2 && isSecurityAdminLogin === false) {
        // console.log("This hit 2")
        // console.log(tempMtlRequest)
        tempMtlRequest = tempMtlRequest.filter(
          (e) =>
            // ((e.createdBy || "").toLowerCase() === userName ||
            // (e.approvers || "").toLowerCase().includes(userName))
            //  &&
            (e.statusKey || "") !== 6
        );
      }
    }

    if (basins.length > 0) {
      tempMtlRequest = tempMtlRequest.filter((e) =>
        basins.includes(e.locationName)
      );
    }
    if (status.length > 0) {
      tempMtlRequest = tempMtlRequest.filter((e) =>
        status.includes(e.mocStatusDesc)
      );
    }

    this.setState({ MOCData: tempMtlRequest });

    s.map((e) => {
      if (
        e.mocStatusDesc === "Canceled" ||
        e.mocStatusDesc === "Closed" ||
        e.mocStatusDesc === "Completed" ||
        e.mocStatusDesc === "Implemented"
      ) {
        this.getMOCDataByStatus(e.mocStatusDesc).then((rtnAFE) => {
          tempMtlRequest = [...tempMtlRequest, ...rtnAFE];
          this.setState({ MOCData: tempMtlRequest });
        });
        //
      }
    });
  };

  getSecurity = () => {
    let myURI =
      apiURL + "EmployeeDetails/Checkpoint/" + this.props.user.account.userName;
    const { security, appSecurityNo, meterVal, isMOCUser } = this.state;
    fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            isSecurityLoaded: true,
            security: json,
            isSecurityAdminLogin:
              json.filter((list) => list.appSecurityNo === 125).length > 0
                ? true
                : false, //PROD
            isMOCUser:
              json.filter((list) => list.appSecurityNo === 126).length > 0
                ? true
                : false, //PROD
            isMOCApprover:
              json.filter((list) => list.appSecurityNo === 128).length > 0
                ? true
                : false, //PROD
            // isMOCUser:((json.filter((list) => list.appSecurityNo === 132).length > 0 ? true : false)), //Preview1
            // isMOCApprover: ((json.filter((list) => list.appSecurityNo === 133).length > 0 ? true : false)), //Preview1
            // isSecurityAdminLogin: (json.filter((list) => list.appSecurityNo === 122).length > 0 ? true : false), //Preview1
          },
          () => {
            this.getMOCData();
          }
        );
      });
  };

  componentDidMount = () => {
    // this.getMOCData();
    this.getBasin();
    this.getFacilityList();
    this.getAssetList();
    this.getStatus();
    this.setDefaultMOCQuery();
    this.getMOCPriorityList();
    this.getMOCSafetyAndHealthList();
    this.getMOCEnvironmentList();
    this.getMOCFinancialList();
    this.getEmpList();
    this.getSecurity();
    this.getMOCCommentStatusList();
    this.getMOCUserList();
    this.getMOCCommentLine();
    this.getMOCQuestions();
  };

  toggleMOCDetails = () => {
    const { modalMOCDetails } = this.state;
    this.setState({
      modalMOCDetails: !this.state.modalMOCDetails,
      modalMOCQuestions: !this.state.modalMOCQuestions,
      // mocKey: -1
    });
  };

  toggleMOCDetailsEdit = () => {
    this.setState({
      modalMOCDetails: !this.state.modalMOCDetails,
    });
  }

  onGridRowSelected2(s,e) {
    if (e.isDoubleClick){
        this.setState({ modalMOCDetails: !this.state.modalMOCDetails,  });
    }
  }

  toggleMOCQuestions = () => {
    const { mocQuestionsResult } = this.state;
    // mocQuestionsResult[0].mocQuestions = null;
    this.setState({
      modalMOCQuestions: !this.state.modalMOCQuestions,
      mocQuestionsResult: null,
      mocKey: -1
    });
  };

  closeMOCDetailsForm = () => {
    this.setState({
      modalMOCDetails: !this.state.modalMOCDetails,
      openMOCDetails: false,
    });
  };

  newMOC = () => {
    this.toggleMOCDetails();
    this.setState({
      mocKey: -1,
    });
  };

  newMOCQuestions = () => {
    this.toggleMOCQuestions();
  };

  handleChange(value, formattedValue) {
    this.setState({
      startDate: value,
    });
  }

  handleEndDateChange(value, formattedValue) {
    this.setState({
      endDate: value,
    });
  }

  toggleAlert = () => {
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  handleShowAlert = (message) => {
    this.setState({
      alertMessage: message,
      showAlert: true,
    });
  };

  bMultiField = (value, checkValue) => {
    return (value & (1 << (checkValue - 1))) !== 0;
  };

  render() {
    const {
      MOCData,
      isDataLoaded,
      windowHeight,
      mocKey,
      mocStatus,
      mocNumber,
      statusKey,
      isBasinLoaded,
      basinList,
      selBasin,
      mocQueryList,
      selMOCQuery,
      isStatusLoaded,
      statusList,
      selStatus,
      searchText,
      facilityList,
      isFacilityListLoaded,
      assetList,
      isAssetListLoaded,
      mocPriorityList,
      isMocPriorityListLoaded,
      isMocSafetyAndHealthListLoaded,
      mocSafetyAndHealthList,
      isMocEnvironmentListLoaded,
      mocEnvironmentList,
      isMocFinancialListLoaded,
      mocFinancialList,
      isEmpListLoaded,
      empList,
      isSecurityAdminLogin,
      isSecurityLoaded,
      isMOCUser,
      isMOCApprover,
      isMOCCommentStatusListLoaded,
      mocCommentStatusList,
      mocUserList,
      isMOCUserListLoaded,
      isMOCCommentLoaded,
      alertMessage,
      showAlert,
      mocQuestions,
      isMOCQuestionsLoaded,
      mocQuestionsResult
    } = this.state;
    const gridHeight = "72vh";
    if (
      !isBasinLoaded ||
      !isStatusLoaded ||
      !isDataLoaded ||
      !isFacilityListLoaded ||
      !isAssetListLoaded ||
      !isMocPriorityListLoaded ||
      !isMocSafetyAndHealthListLoaded ||
      !isMocEnvironmentListLoaded ||
      !isMocFinancialListLoaded ||
      !isEmpListLoaded ||
      !isSecurityLoaded ||
      !isMOCCommentStatusListLoaded ||
      !isMOCUserListLoaded ||
      !isMOCCommentLoaded ||
      !isMOCQuestionsLoaded
    ) {
      return <div>Loading MOC data.....</div>;
    } else if (!isSecurityAdminLogin && !isMOCUser) {
      return (
        <h2>
          You are not part of the MOC Users group. Please contact your MOC
          Manager for assistance.
        </h2>
      );
    } else
      return (
        <div>
          <FormGroup>
            <Row form>
              <Col sm={15}>
                <Label>
                  <h2>MOC |</h2>
                </Label>
                <Button
                  outline
                  color="secondary"
                  className="btn-no-border"
                  onClick={this.getMOCData}
                >
                  <img
                    src={Refresh}
                    alt="Refresh"
                    style={{ width: "30px", margin: "0 5px 0 0" }}
                  />
                  Refresh
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="btn-no-border"
                  onClick={this.saveWorkbook}
                >
                  <img
                    src={Excel}
                    alt="excel"
                    style={{ width: "50px", margin: "0 0 0 0" }}
                  />
                  Export To Excel
                </Button>{" "}
                <Button
                  outline
                  color="secondary"
                  className="btn-no-border"
                  // onClick={this.newMOC}
                  onClick={this.newMOCQuestions}
                >
                  <img
                    src={Plus}
                    alt="new"
                    style={{ width: "25px", margin: "0 5px 0 0" }}
                  />
                  New MOC
                </Button>{" "}
                <Button
                  outline
                  color="secondary"
                  className="btn-no-border"
                  onClick={this.toggleMOCDetailsEdit}
                  disabled={mocKey == -1}
                >
                  <img
                    src={Revise}
                    alt="Revise"
                    style={{ width: "30px", margin: "0 5px 0 0" }}
                  />
                  Edit
                </Button>
              </Col>
              <Alert
                color="success" // Set the color to "success" for green
                toggle={this.toggleAlert}
                isOpen={showAlert}
                style={{ fontSize: "14px" }}
              >
                {alertMessage}
              </Alert>
            </Row>
            <ColoredLine color="grey" />
          </FormGroup>

          <FormGroup>
            <Row form>
              <Col md={2}>
                <Row form>
                  <Col md={12}>
                    <Label>Filter</Label>

                    <div>
                      <Select
                        className="alway-in-between-more"
                        options={mocQueryList}
                        value={selMOCQuery}
                        defaultValue={selMOCQuery}
                        onChange={(e) => this.onTextChange("mocQuery", e, e)}
                      ></Select>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={2}>
                <Row form>
                  <Col md={12}>
                    <Label>Entity</Label>

                    <div>
                      <Select
                        options={basinList}
                        isMulti
                        // isDisabled={readOnly}
                        //  className={(!validDataItem.facilityID ? "alway-on-top-more form-error" : "")}

                        value={selBasin}
                        defaultValue={selBasin}
                        onChange={(e) => this.onTextChange("basin", e, e)}
                        isClearable={true}
                      ></Select>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={5}>
                <Row form>
                  <Col md={12}>
                    <Label>Status</Label>

                    <div>
                      <Select
                        className="alway-in-between-more"
                        options={statusList}
                        value={selStatus}
                        defaultValue={selStatus}
                        isClearable={true}
                        isMulti
                        onChange={(e) => this.onTextChange("status", e, e)}
                      ></Select>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={3}>
                <Row form>
                  <Col md={12}>
                    <div>
                      <Label for="typeKey"> </Label>
                      <Input
                        width="100%"
                        className="floatRight"
                        placeholder="Type here to search......."
                        onChange={(e) =>
                          this.onTextChange("mocSearch", e.target.value, e)
                        }
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row form style={{ zIndex: 0, position: "relative" }}>
              <IgrDataGrid
                ref={this.onGridRef}
                height={gridHeight}
                width={"100%"}
                cellTextStyle="14px Calibri"
                headerTextStyle="14px Calibri"
                rowHeight={"25"}
                // dataSource={MOCData}
                isColumnOptionsEnabled="true"
                //  editMode="true"
                dataSource={MOCData.filter(
                  (rec) =>
                    (rec.mocNumber || "").toLowerCase().includes(searchText) ||
                    (rec.requestedBy || "")
                      .toLowerCase()
                      .includes(searchText) ||
                    (rec.updatedBy || "").toLowerCase().includes(searchText) ||
                    (rec.locationName || "")
                      .toLowerCase()
                      .includes(searchText) ||
                    (rec.summaryOfRequestedChange || "")
                      .toLowerCase()
                      .includes(searchText) ||
                    (rec.mocStatusDesc || "")
                      .toLowerCase()
                      .includes(searchText) ||
                    (rec.assetName || "").toLowerCase().includes(searchText) ||
                    (rec.facilityName || "")
                      .toLowerCase()
                      .includes(searchText) ||
                    (rec.safetyAndHealthType || "")
                      .toLowerCase()
                      .includes(searchText) ||
                    (rec.financialType || "")
                      .toLowerCase()
                      .includes(searchText) ||
                    (rec.environmentType || "")
                      .toLowerCase()
                      .includes(searchText)
                  // (rec.reqestDateTime || '').toLowerCase().includes(searchText) ||
                  // (rec.locationID || '').toLowerCase().includes(searchText) ||
                  // (rec.locationName || '').toLowerCase().includes(searchText) ||
                  // (rec.workOrderDesc || '').toLowerCase().includes(searchText) ||
                  // (rec.fiixAssignedToUser || '').toLowerCase().includes(searchText) ||
                  // (rec.workOrderDesc || '').toLowerCase().includes(searchText) ||
                  // (rec.workdaySiteID || '').toLowerCase().includes(searchText)
                )}
                autoGenerateColumns="false"
                selectionMode="SingleRow"
                selectedKeysChanged={this.onGridRowSelected}
                cellClicked={this.onGridRowSelected2}
              >
                <IgrTemplateColumn
                  field="mocNumber"
                  headerText="MOC No"
                  width="*>120"
                  cellUpdating={this.onStatusCellUpdating}
                />
                <IgrTemplateColumn
                  field="requestedBy"
                  headerText="Requested By"
                  width="*>150"
                  cellUpdating={this.onStatusCellUpdating}
                />

                <IgrTemplateColumn
                  field="modifiedBy"
                  headerText="Updated By"
                  width="*>150"
                  cellUpdating={this.onStatusCellUpdating}
                />

                <IgrTemplateColumn
                  field="mocStatusDesc"
                  headerText="Status"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />

                <IgrTemplateColumn
                  field="locationName"
                  headerText="Basin"
                  width="*>120"
                  cellUpdating={this.onStatusCellUpdating}
                />
                <IgrTemplateColumn
                  field="facilityName"
                  headerText="Facility"
                  width="*>120"
                  cellUpdating={this.onStatusCellUpdating}
                />

                <IgrTemplateColumn
                  field="assetName"
                  headerText="Asset"
                  width="*>300"
                  cellUpdating={this.onStatusCellUpdating}
                />

                <IgrTemplateColumn
                  field="summaryOfRequestedChange"
                  headerText="Summary"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />
                <IgrTemplateColumn
                  field="safetyAndHealthType"
                  headerText="Safety and Health Risk"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />
                <IgrTemplateColumn
                  field="environmentType"
                  headerText="Environment Risk"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />
                <IgrTemplateColumn
                  field="financialType"
                  headerText="Financial Risk"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />

                <IgrTemplateColumn
                  field="listOfAffectedEquipment"
                  headerText="Affected Equipment"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />
                <IgrTemplateColumn
                  field="listOfAffectedJobsRoles"
                  headerText="Affected Jobs/Roles"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />
                <IgrTemplateColumn
                  field="listOfAffectedMisc"
                  headerText="Affected documents, procedures, tests, and/or inspections"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />

                <IgrTemplateColumn
                  field="createdDatetime"
                  headerText="Created Date Time"
                  width="*>200"
                  cellUpdating={this.onStatusCellUpdating}
                />
              </IgrDataGrid>
            </Row>
          </FormGroup>

          <div>
            <Modal
              isOpen={this.state.modalMOCDetails}
              toggle={() => this.toggleMOCDetails()}
              // size="xl"
              className="custom-modal"
              backdrop={"static"}
            // onClosed={() => this.toggleMOCDetails()}
            >
              <ModalHeader
                toggle={() => this.closeMOCDetailsForm()}
              // className={this.getAFEHeaderColor(closeoutStatusDesc)}
              >
                {/* {(afeHeaderKey < 0 ? "New AFE" : afeNo + " - " + afeName + " - " + afeStatusDesc)} */}
                {mocKey < 0
                  ? "New Management of Change"
                  : mocNumber + " (" + mocStatus + ")"}
              </ModalHeader>
              <ModalBody>
                <MOCDetails
                  toggle={this.closeMOCDetailsForm}
                  mocKey={mocKey}
                  user={this.props.user}
                  refreshData={this.getMOCData}
                  statusKey={statusKey}
                  mocStatus={mocStatus}
                  basinList={basinList}
                  facilityList={facilityList}
                  assetList={assetList}
                  mocPriorityList={mocPriorityList}
                  mocSafetyAndHealthList={mocSafetyAndHealthList}
                  mocEnvironmentList={mocEnvironmentList}
                  mocFinancialList={mocFinancialList}
                  empList={empList}
                  isSecurityAdminLogin={isSecurityAdminLogin}
                  isMOCUser={isMOCUser}
                  isMOCApprover={isMOCApprover}
                  mocCommentStatusList={mocCommentStatusList}
                  mocUserList={mocUserList}
                  handleShowAlert={this.handleShowAlert}
                  MOCDataParent={MOCData}
                  mocQuestionsResult={mocQuestionsResult}
                ></MOCDetails>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal
              isOpen={this.state.modalMOCQuestions}
              toggle={() => this.toggleMOCQuestions()}
              size="lg"
              backdrop={"static"}
            >
              <ModalHeader toggle={() => this.toggleMOCQuestions()}>
                MOC Questions
              </ModalHeader>
              <ModalBody>
                <div className="text-center mb-3">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">
                        Please select all that apply
                      </h5>
                    </div>
                  </div>
                </div>

                {mocQuestions.map((question, index) => (
                  <Row form key={index}>
                    <Col md={10}>
                      <Label>{question.mocQuestionsDesc}</Label>
                    </Col>
                    <Col md={1}>
                      <CustomInput
                        type="checkbox"
                        id={`q${index + 1}Yes`}
                        label={"Yes"}
                        checked={this.bMultiField(
                          mocQuestionsResult,
                          index + 1
                        )}
                        onChange={(e) =>
                          this.onTextChange("mocQuestions", index + 1, e)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <CustomInput
                        type="checkbox"
                        id={`q${index + 1}No`}
                        label={"No"}
                        checked={
                          !this.bMultiField(mocQuestionsResult, index + 1)
                        }
                        onChange={(e) =>
                          this.onTextChange("mocQuestions", index + 1, e)
                        }
                      />
                    </Col>
                  </Row>
                ))}
                {/* <Row form>
                <Col md={10}>
                   <Label>
                   {mocQuestions[0].mocQuestionsDesc}
                   </Label>                                      
                  </Col>
                  <Col md={1}>
                    <CustomInput
                      type="checkbox"
                      id="q1Yes"
                      label={"Yes"}
                      checked={this.bMultiField(MOCData[0].mocQuestions, 1)}
                      onChange={(e) => this.onTextChange("mocQuestions", 1, e)}
                    />
                  </Col>
                  <Col md={1}>
                    <CustomInput
                      type="checkbox"
                      id="q1No"
                      label={"No"}
                      // checked={!this.bMultiField(MOCData[0].mocQuestions, 1)}
                      checked={!this.bMultiField(MOCData[0].mocQuestions, 1)}
                      onChange={(e) => this.onTextChange("mocQuestions", 1, e)}
                    />
                  </Col>
                 
                </Row>

                <Row form>
                <Col md={10}>
                   <Label>
                   {mocQuestions[1].mocQuestionsDesc}
                   </Label>                                      
                  </Col>
                  <Col md={1}>
                    <CustomInput
                      type="checkbox"
                      id="q2Yes"
                      label={"Yes"}
                      checked={this.bMultiField(MOCData[0].mocQuestions, 2)}
                      onChange={(e) => this.onTextChange("mocQuestions", 2, e)}
                    />
                  </Col>
                  <Col md={1}>
                    <CustomInput
                      type="checkbox"
                      id="q2No"
                      label={"No"}
                      checked={!this.bMultiField(MOCData[0].mocQuestions, 2)}
                      onChange={(e) => this.onTextChange("mocQuestions", 2, e)}
                    />
                  </Col>                

                </Row> */}
              </ModalBody>
              <ModalFooter className="justify-content-end">
                <Button
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={this.toggleMOCDetails}
                  disabled={
                    mocQuestionsResult === null ||
                    mocQuestionsResult === 0
                  }
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  style={{ marginRight: "10px" }}
                  onClick={this.toggleMOCQuestions}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
  }

  onStatusCellUpdating(s, e) {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;

    let link;
    if (content.childElementCount === 0) {
      link = document.createElement("label");
      let cell = document.createElement("div");

      content.style.fontFamily = "Verdana";
      content.style.fontSize = "13px";

      content.style.margin = "0px -20px -10px -12px";
      content.style.padding = "0px 10px 10px 10px";

      content.appendChild(link);
    } else {
      link = content.children[0];

      content.style.background = "transparent";
      if ((item !== null) & (item !== undefined)) {
        // if (item.mocStatusDesc !== "Completed") {
        //     content.style.background = "#89b6ef";
        // }
        // else {

        if (item.mocStatusDesc === "Completed") {
          content.style.background = "#A2E09D";
        } else if (item.mocStatusDesc === "Canceled") {
          content.style.background = "#f88379";
        } else if (item.mocStatusDesc === "Rejected") {
          content.style.background = "#f4a460";
        }
        // else if (item.mocStatusDesc === "Assigned") {
        //     content.style.background = "#f0f5b0";
        // }
        else if (item.mocStatusDesc === "Open") {
          content.style.background = "#f0f5b0";
        } else if (item.mocStatusDesc === "Active") {
          content.style.background = "#75ebe9";
        } else if (item.mocStatusDesc === "Pending") {
          content.style.background = "#f0f5b0";
        } else if (item.mocStatusDesc === "Pending-MOCManager") {
          content.style.background = "#509BD2";
        } else if (item.mocStatusDesc === "Implemented") {
          content.style.background = "#915BA4";
        } else if (item.mocStatusDesc === "Revise") {
          content.style.background = "#906f83";
        } else {
          content.style.background = "transparent";
        }
        // }
      }
      if (item.mocStatusDesc === "Completed") {
        content.style.background = "#A2E09D";
      } else {
        if (s.field === "safetyAndHealthType") {
          if (item.safetyAndHealthType === "Critical") {
            content.style.backgroundColor = "#d6850b";
          } else if (item.safetyAndHealthType === "Major") {
            content.style.backgroundColor = "#fafc5b";
          }
        }

        if (s.field === "environmentType") {
          if (item.environmentType === "Critical") {
            content.style.backgroundColor = "#d6850b";
          } else if (item.environmentType === "Major") {
            content.style.backgroundColor = "#fafc5b";
          }
        }

        if (s.field === "financialType") {
          if (item.financialType === "Critical") {
            content.style.backgroundColor = "#d6850b";
          } else if (item.financialType === "Major") {
            content.style.backgroundColor = "#fafc5b";
          }
        }
      }
    }

    switch (s.field) {
      case "mocNumber":
        link.textContent = item.mocNumber;

        break;
      case "requestedBy":
        link.textContent = item.requestedBy
          ? item.requestedBy.replace(".", " ")
          : "";
        break;
      case "modifiedBy":
        link.textContent = item.modifiedBy
          ? item.modifiedBy.replace(".", " ")
          : "";

        break;
      case "locationName":
        link.textContent = item.locationName;

        break;

      case "facilityName":
        link.textContent = item.facilityName;

        break;

      case "assetName":
        link.textContent = item.assetName;

        break;
      case "summaryOfRequestedChange":
        link.textContent = item.summaryOfRequestedChange;

        break;
      case "safetyAndHealthType":
        link.textContent = item.safetyAndHealthType;

        break;
      case "environmentType":
        link.textContent = item.environmentType;

        break;

      case "financialType":
        link.textContent = item.financialType;
        break;

      case "listOfAffectedEquipment":
        link.textContent = item.listOfAffectedEquipment;
        break;

      case "listOfAffectedJobsRoles":
        link.textContent = item.listOfAffectedJobsRoles;
        break;

      case "listOfAffectedMisc":
        link.textContent = item.listOfAffectedMisc;
        break;
      // case 'woPriority':
      //     link.textContent = item.woPriority;
      //     if ((item.woPriority).includes("Urgent") && item.fiixWOStatus != "Completed") {
      //         content.style.background = "#89b6ef";
      //     }

      //     break;
      case "mocStatusDesc":
        link.textContent = item.mocStatusDesc;
        break;
      case "workOrderDesc":
        link.textContent = item.workOrderDesc;

        break;

      case "createdDatetime":
        var date = new Date(item.createdDatetime);
        link.textContent = `${date.toLocaleString("en-US", {
          timeZone: "America/Chicago",
        })}`;
        break;

      default:
    }
  }

  saveWorkbook() {
    var actCols = this.grid.actualColumns;

    let gridDts = this.grid.dataSource; // this.state.inspItems

    const headers = Object.keys(gridDts[0]);
    headers.pop();
    const wb = new Workbook(WorkbookFormat.Excel2007);
    const ws = wb.worksheets().add("Sheet1");

    for (let i = 0; i < headers.length; i++) {
      for (let j = 0; j < actCols.count; j++) {
        if (headers[i] === actCols.item(j).field) {
          ws.rows(0).cells(j).value = actCols.item(j).headerText; //headers[i];
          if ((actCols.item(j).headerText || "") === "") {
            ws.rows(0).cells(j).value = actCols.item(j).field;
          }
        }
      }
    }

    for (let i = 0; i < gridDts.length; i++) {
      const dataRow = gridDts[i];
      const xlRow = ws.rows(i + 1);
      for (let j = 0; j < headers.length; j++) {
        for (let k = 0; k < actCols.count; k++) {
          if (headers[j] === actCols.item(k).field) {
            xlRow.setCellValue(k, dataRow[headers[j]]);
          }
        }

        //xlRow.setCellValue(j, dataRow[headers[j]]);
      }
    }

    ExcelUtility.save(wb, "MOC");
  }
}
