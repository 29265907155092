
import React, {useState} from 'react';
import {Button} from 'reactstrap';

const inspState = (a) => {
    //console.log("print State: " + a)
    if (a === 1) {
        return "success";
    } else if (a === 2) {
        return "danger";
    } else {
        return "secondary";
    }
}



const InspectionButton = (props) => {
    const {isReadOnly} = props
    // console.log(props);
    const [inspLine,setInspLine] = useState(props.InspLine);
    const [itemState,setItemState]= useState(props.InspLine.inspLineValue);
    

    const onHandleInspState = (key) => {
       
        //console.log("item State: " + itemState)
        const iState =  ((itemState + 1) > 2 ? 0 : itemState + 1 );
        setItemState(iState) ;      
        props.onChange(iState);
        //console.log("After State: " + this.State.itemState)
    }
    

    return (
        <Button color={inspState(itemState)}
                style={{margin: "0 5px 0 0"}}
                disabled = {isReadOnly}
                onClick={(e) => onHandleInspState(inspLine.assetkey,e)}
                ><span>&nbsp;&nbsp;</span> </Button>
    );
}

export default InspectionButton;
