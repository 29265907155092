import { Component } from "react";

import {
    Accordion, AccordionItem, AccordionItemHeading,
    AccordionItemButton, AccordionItemPanel
} from 'react-accessible-accordion';
import { apiURL, apiHeader, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';


import { Asset } from './Asset'
import AssetDetails, { getValueList } from './AssetDetails';
import AssetWellboreDocuments from './AssetWellboreDocuments';
import AssetMetaList from "./AssetMetaList";
import AssetMetaLog from "./AssetMetaLog";
import FileManager from '../../resources/FileManager';

const AssetSections = (props) => {
    const { sectionData, assetKey, user, hierachy_parentkeys } = props

    const get_parentsAssetKey = (asset_type_id, hierachy_parentkeys) => {

        let parentsKey = ''

        if (hierachy_parentkeys !== '') {
            asset_type_id = asset_type_id + ':'

            hierachy_parentkeys.split(';').map(e => {
                console.log("get_parentsAssetKey")
                console.log(e)
                if (e !== null && e !== '') {
                    if (e.includes(asset_type_id)) {
                        let keys = e.split(":")
                        console.log("-------------->keys")
                        console.log(keys)
                        parentsKey = keys[1]
                    }
                }
            })
        }

        console.log("parentsKey")
        console.log(parentsKey)

        return parentsKey
    }

    return (
        sectionData.map(a => {
            //console.log("WBP Doc: -----> " + a.link_asset_type_id)
            if (a.link_asset_type_id === "WBP_Documents" || a.link_asset_type_id.includes("_Documents")) {
                return (<AccordionItem uuid={a.link_asset_type_id}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <strong>{a.link_asset_type_desc}</strong>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div>
                            <AssetWellboreDocuments
                                user={user}
                                assetkey={assetKey}
                                asset_type_id={a.asset_type_id}
                                isAssetAdmin={true}
                            />


                        </div>
                    </AccordionItemPanel>
                </AccordionItem>)
            } else if (a.link_asset_type_id === "WBP_Detail" || a.link_asset_type_id.includes("_Detail_")) {
                return (<AccordionItem uuid={a.link_asset_type_id}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <strong>{a.link_asset_type_desc}</strong>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div>
                            <AssetMetaList
                                companyID={a.company_id}
                                user={user}
                                assetkey={assetKey}
                                asset_type_id={a.asset_type_id}
                                isAssetAdmin={true}
                                isFormReadOnly={true}
                                assetCatID={a.asset_category_id}
                            />
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>)
            } else if (a.link_asset_type_id.includes("_MetaLog")) {
                return (<AccordionItem uuid={a.link_asset_type_id}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <strong>{a.link_asset_type_desc}</strong>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div>
                            <AssetMetaLog
                                companyID={a.company_id}
                                user={user}
                                assetkey={assetKey}
                                asset_type_id={a.asset_type_id}
                                isAssetAdmin={true}
                                isFormReadOnly={true}
                                assetCatID={a.asset_category_id}
                            />
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>)
            } else {
                //return("empty")
                return (<AccordionItem uuid={a.link_asset_type_id}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <strong>{a.link_asset_type_desc}</strong>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div>
                            <Asset companyID={a.company_id}
                                assetTypeID={a.link_asset_type_id}
                                parentAssetKey={assetKey}
                                parentsAssetKey={get_parentsAssetKey(a.link_asset_type_id, hierachy_parentkeys)}
                                user={user}
                                assetCatID={a.asset_category_id}
                                divGridid={"childuiGrid"}
                                contextMenuid={"childcontextMenu"}
                            />
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>)
            }
        })

    )
}



export class AssetDetailsSections extends Component {
    static displayName = AssetDetailsSections.name;

    constructor(props) {
        super(props)
        document.title = "WaterBridge Portal - Asset Details"

        this.state = ({
            isSectionLoaded: false,
            sectionData: [],
            company_id: props.companyID,
            asset_type_id: props.assetTypeID,
            asset_cat_id: props.assetCatID, // "OP",
            assetKey: props.assetKey,
            isAssetConfigLoaded: false,
            assetConfigList: props.assetConfigList,
            assetConfig: [],
            user: props.user,
            isReadOnly: props.isReadOnly,
            parentAssetKey: props.parentAssetKey,
            assetStatus: props.assetStatus,
            assetType: props.assetType,
            assetCatID: props.assetCatID,
            hierachy_parentkeys: props.hierachy_parentkeys

        })
    }

    getAssetHierachy = () => {

        let myURI = apiURL + 'Asset/' + this.state.company_id + '/' + this.state.asset_cat_id + '/Assethierarchy/ByAssetType/' + this.state.asset_type_id
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isSectionLoaded: true,
                    sectionData: json
                })

            });



    }



    componentDidMount() {
        this.getAssetHierachy();
    }


    render() {
        const { isSectionLoaded, sectionData, assetKey, user, isReadOnly, hierachy_parentkeys,
            isAssetConfigLoaded, assetConfig, assetType } = this.state

        if (!isSectionLoaded) {
            return (<h3><div>
                Loading Asset Section Details.....
            </div></h3>)
        }
        // else if (!isAssetConfigLoaded) {
        //     return (<h3><div>
        //         Loading Asset Asset Configuration Details.....
        //     </div></h3>)
        // }
        return (<div>
            <Accordion allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={['general']}
            >
                <AccordionItem uuid='general'>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <strong>Details</strong>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <AssetDetails assetTypeID={this.props.assetTypeID}
                            //assetConfig={assetConfig}
                            assetConfigList={this.state.assetConfigList}
                            companyID={this.props.companyID}
                            assetKey={assetKey}
                            toggle={this.props.toggle}
                            user={this.props.user}
                            refreshData={this.props.refreshData}
                            isReadOnly={isReadOnly}
                            assetType={assetType}
                            assetCatID={this.state.assetCatID}
                            assetStatus={this.state.assetStatus} />
                    </AccordionItemPanel>
                </AccordionItem>
                <AssetSections sectionData={sectionData}
                    assetKey={assetKey}
                    user={user}
                    isReadOnly={isReadOnly}
                    hierachy_parentkeys={hierachy_parentkeys} />
            </Accordion>
        </div>)
    }

}