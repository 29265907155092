import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, FormGroup, ModalBody, ModalFooter, Label, Input,  Col, Row  } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { getUserADAccount, getRandomInt, FormatDate } from '../../resources/utility'
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import moment from 'moment';
import TaskAndInstructions from './TaskAndInstructions';
const RerouteConfirmationModal = (props) => {

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        buttonLabel,
        id,
        style,
        disabled,
        commentPrompMsg, 
        // mainTypeApprovalList,
        mainTypeList,
        // issueTypeList,
        issueTypeListDefault,
        woData : woDataProp,
        isOpen,
        toggle,
        oldMaintenanceType,
        empList,
        invalid,
        // partsAndCostData,
        partsAndCostDataGrid,
        workOrderKey,
        user,
        partsAndCostStatus,
        fiixWOStatus,
        readOnly,
        fiixWOId,
        refreshData
    } = props

    const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);
    const [inputComment, setComment] = useState(props.inputComment)
    const [isValid, setIsValid] = useState(true)
    const [isIssueValid, setIsIssueValid] = useState(true)
    const [isCommentRequired, setIsCommentRequired] = useState((props.isCommentRequired === undefined ? true : props.isCommentRequired))
    // const [user, setUser] = useState("")
    const [mainType, setMainType] = useState("")
    const [issueTypeList, setIssueTypeList] = useState(props.issueTypeList)
    const [issueType, setIssueType] = useState("")
    const [localWoData, setLocalWoData] = useState(woDataProp);
    const [rerouteUser, setRerouteUser] = useState("")
    const [modalTask, setModalTask] = useState(false)
    const [statusChange, setStatusChange] = useState("Reroute")
    const [partsAndCostData, setPartsAndCostData] = useState({
      partsAndCostKey: -1, // or null
      workOrderKey: null,
      costTypeKey: null,
      itemKey: null,
      description: "",
      quantity: 0.00,
      unitCost: 0.00,
      uomKey: null,
      completedDate: null,
      createdBy: getUserADAccount(user.account.userName),
      instructions: ""
      ,assignedDate: moment(),
      statusKey: 0
    });
    const [cachedTasks, setCachedTasks] = useState([]);

    const [modalMsg, setModalMsg] = useState({
      msgBody: '',
      msgHeader: '',
      modalMsg: false
    });
  
    // const toggleYes = () => {
    //     console.log(inputComment)
    //     if (mainType === "" || mainType === undefined) {
    //         setIsValid(false)
    //     } else {
    //         setModal(!modal)
    //         props.onClickYes(mainType, issueType);
            
    //         setComment("")
    //     }

    // }
    useEffect(() => {
      // Assuming woDataProp is an array and you're interested in the first item
      if (woDataProp && woDataProp.length > 0) {
        setLocalWoData(woDataProp);
        // Optionally, initialize mainType and issueType based on woDataProp
        setMainType(woDataProp[0].maintenanceType || "");
        setIssueType(woDataProp[0].fiixIssueType || "");
      }
    }, [woDataProp]);

    // const toggleYes = () => {
    //     // console.log(mainType)
    //     // console.log(localWoData[0].maintenanceType)

    //     // if(oldMaintenanceType === localWoData[0].maintenanceType)
    //     // {
    //     //   setIsValid(false); // Show validation error
    //     // }

    //     if ((mainType === "" || mainType === undefined)  && (localWoData[0].maintenanceType ==="" || localWoData[0].maintenanceType === null)) {
    //       setIsValid(false); // Show validation error

    //     }


    //     if ((issueType === "" || issueType === undefined) && (localWoData[0].fiixIssueType ==="" || localWoData[0].fiixIssueType === null))
    //     {
    //       setIsIssueValid(false)
    //     }
      

    //     else {
    //       // if (!invalid)
    //       //   {
    //                       // Perform the action for the "Yes" button being clicked
            
    //         // console.log("Sending mainType from child:", mainType);
    //         props.onClickYes(mainType, issueType);

    //         // Reset the comment
    //         setComment("");
    //         // Then close the modal by calling the parent's toggle function
    //         toggle();
    //         // console.log("This hit ")
    //         }

    //     // }
    // };

    /*Commented out by BN 10/10/24. Not on version 1 release */
    // const onAddTasksToCache = (newTask) => {
    //   setCachedTasks((prevTasks) => [...prevTasks, newTask]);
    // };

    const toggleYes = () => {
      console.log("This hit")
      // Validate Maintenance Type and Issue Type fields
      if ((mainType === "" || mainType === undefined) && (localWoData[0].maintenanceType === "" || localWoData[0].maintenanceType === null)) {
          setIsValid(false); // Show validation error
          return; // Prevent further execution if validation fails
      }
  
      if ((issueType === "" || issueType === undefined) && (localWoData[0].fiixIssueType === "" || localWoData[0].fiixIssueType === null)) {
          setIsIssueValid(false); // Show validation error
          return; // Prevent further execution if validation fails
      }
  
      console.log("Cached Tasks before submission:", cachedTasks);
      // If validation passes

      /*Commented out by BN 10/10/24. Not on version 1 release */
      // Insert cached tasks into the database
      // cachedTasks.forEach(task => {
      //   console.log("does this hit at all?")
      //     let myURI = apiURL + 'Fiix/WorkOrderPartsAndCost';
      //     fetch(myURI, apiPOSTHeader(task))
      //         .then(response => {
      //             if (!response.ok) {
      //                 return Promise.reject("Error inserting task");
      //             }
      //             return response.json(); // Optional: Handle response data
      //         })
      //         .then(data => {
      //             console.log("Task added successfully:", data);
      //             // Optionally: Refresh partsAndCostDataGrid to reflect changes
      //             props.getWorkOrder(); // Assuming this function reloads the grid data
      //         })
      //         .catch(error => {
      //             console.error("Error adding parts and cost:", error);
      //         });
      // });
  

      // Clear the cached tasks after successful insertion
      setCachedTasks([]);
        // Perform the action for the "Yes" button being clicked
      props.onClickYes(mainType, issueType);
      // Reset the comment and close the modal
      setComment("");
      toggle(); // Close the modal by calling the parent's toggle function
  };

    const onClosed = () => {

        if (props.onClosed !== undefined) {
            props.onClosed();
        }

        setIsValid(true)
        setComment("")
    }



    const onTextChange = (fieldName, fieldValue, e) => {
      // console.log(`Field Name: ${fieldName}, Field Value: ${fieldValue}, Event:`, e);
      // Use a local variable to handle updates, starting with a copy of the current state.
      let updatedWoData = [...localWoData];
  
      switch (fieldName) {
          case "comment":
              setComment(fieldValue);
              if (isCommentRequired) { setIsValid(fieldValue !== ""); }
              break;
  
          case "maintenanceType":
              // Update the maintenanceType and reset fiixIssueType to ensure consistency.
              updatedWoData[0] = { 
                  ...updatedWoData[0], 
                  maintenanceType: e === null ? "" : e.fixxMainTypeDesc, 
                  fiixIssueType: ""  // Reset fiixIssueType when maintenanceType changes
              };
              setLocalWoData(updatedWoData);
              setMainType(e === null ? "" : e.fixxMainTypeDesc);
              getIssueTypeFiltered(e === null ? "" : e.fixxMainTypeID);
              setIssueType(""); // Reset issueType to ensure it's cleared when maintenanceType changes.
              setIsValid(true)
              break;
  
              case "issueType":
                if (fieldName === "issueType") {
                    // Ensure this doesn't unintentionally reset maintenanceType
                    updatedWoData[0].fiixIssueType = e === null ? "" : e.fiixMainTypeDesc;
                    setLocalWoData(updatedWoData);
                    setIssueType(e === null ? "" : e.fiixMainTypeDesc);
                    setIsIssueValid(true)
                }
                break;
                
  
          default:
              break;
      }
  }


  const onTextChangeTask = (fieldName, fieldValue, e) => {
  console.log(`Field Name: ${fieldName}`);
  console.log(`Field Value: ${fieldValue}`);
  console.log(`Value: ${e?.value}`);

  setPartsAndCostData((prevState) => {
    let newValue = fieldValue;
    switch (fieldName) {
      
      case "itemKey":
        newValue =  (e ? e.value : "");
        break;
        case "instructions":
          newValue = fieldValue;
          break;
          
      case "assignedDate":
        // newValue = fieldValue ? moment(fieldValue).format('YYYY-MM-DDTHH:mm:ss') : "";
        newValue = fieldValue
        break;



      default:
        break;
    }

    return {
      ...prevState,
      [fieldName]: newValue,
    };
  })
}

  const onChange3 = (e) =>{ //Commented out by BN 7/11
    props.onChangeTest3(e);
}
    const getIssueTypeFiltered = (v) => {
        // const { issueTypeList, issueTypeListDefault } = this.state
        if (v !== null) {
            // console.log(v)
          setIssueTypeList(issueTypeListDefault.filter(e => (e.codeDefinitionParentKey == v.toString())))
    
        }
      }
    const getSelMainType = (value) => {
    
        if (value !== null && value !== "") {
          return mainTypeList.filter((e) => e.fixxMainTypeDesc === value);
        }
        return "";
      };

    const  getSelIssueType = (value) => {
    
        if (value !== null && value !== "") {
          return issueTypeList.filter(e => e.fiixMainTypeDesc === value)
        }
        else{
          return ""
        }
    
      }

    //   //Added by BN 6/4/24 Users are 
    // const onChange = (e) =>{
    //     props.onChangeComments(e);
    // }

    // const toggleTask = () => {
    //   setModalTask(!modalTask);
    // };

    // const createTaskDTO = () => {
    //   const woPartsAndCostCreateDTO = {
    //     "workOrderKey": workOrderKey,
    //     "costTypeKey": 2,
    //     "itemKey": partsAndCostData.itemKey,
    //     "description": partsAndCostData.description,
    //     "quantity": partsAndCostData.quantity,
    //     "unitCost": partsAndCostData.unitCost,
    //     "uomKey": null,
    //     "completedDate": partsAndCostData.completedDate,
    //     "createdBy": getUserADAccount(user.account.userName),
    //     "instructions": partsAndCostData.instructions
    //     ,"assignedDate": partsAndCostData.assignedDate,
    //     "statusKey": partsAndCostData.statusKey
    //   }
    //   console.log(woPartsAndCostCreateDTO)
    //   return woPartsAndCostCreateDTO;
    // }
    

    // const addTask = () => {
    //   const woPartsAndCostCreateDTO = createTaskDTO();
    
    //   let myURI = apiURL + 'Fiix/WorkOrderPartsAndCost';
    //   fetch(myURI, apiPOSTHeader(woPartsAndCostCreateDTO))
    //     .then(async response => {
    //       const rspData = response.text();
    //       if (!response.ok) {
    
    //         const error = (rspData && rspData.message) || response.status;
    //         rspData.then(t => {
    //           setModalMsg({
    //             msgBody: t,
    //             msgHeader: "Error",
    //             modalMsg: true
    //           });
    //         })
    //         return Promise.reject(error);
    //       }
    //       // Call the function to refresh the work order data
    //       props.getWorkOrder();
    //     })
    //     .catch(error => {
    //       console.error("Error adding parts and cost:", error);
    //     });
    // };


 

    // const saveTask = () => {
    //   if (partsAndCostData.partsAndCostKey < 0) { // This means it's a new task
    //     addTaskToCache(); // Cache the task instead of immediately saving it to the database
    //     toggleTask(); // Close the Add Task modal
    //   } else {
    //     // You can implement logic here for updating existing tasks if necessary
    //     toggleTask(); // Close the Add Task modal
    //   }
    // };


  //   const addTaskToCache = () => {
  //     const woPartsAndCostCreateDTO = createTaskDTO();
  //     // Add task to local cache
  //     setCachedTasks((prevTasks) => [...prevTasks, woPartsAndCostCreateDTO]);
  //     toggleTask(); // Close the modal after adding the task
  //  };

//   const addTaskToCache = () => {
//     const woPartsAndCostCreateDTO = createTaskDTO();
    
//     // Log partsAndCostStatus and statusKey to debug
//     console.log("Parts and Cost Status Array: ", partsAndCostStatus);
//     console.log("Status Key from partsAndCostData: ", partsAndCostData.statusKey);
    
//     // Find the corresponding user name (description) from the empList using the itemKey
//     const selectedEmp = empList.find(emp => emp.value === partsAndCostData.itemKey);
    
//     // Log selectedEmp to make sure user is being selected correctly
//     console.log("Selected Employee: ", selectedEmp);
  
//     // Find the corresponding status description from partsAndCostStatus using statusKey
//     const selectedStatus = partsAndCostStatus.find(status => status.woPartsAndCostStatusKey === partsAndCostData.statusKey);
    
//     // Log selectedStatus to check if the correct status is being found
//     console.log("Selected Status: ", selectedStatus);
  
//     // Add the description (user name) and status description to the cached task
//     const taskWithDescriptionAndStatus = {
//       ...woPartsAndCostCreateDTO,
//       description: selectedEmp ? selectedEmp.empName : "Unknown User", // Use the found user or a fallback
//       status: selectedStatus ? selectedStatus.woPartsAndCostStatusDesc : "Unknown Status" // Use the found status or a fallback
//     };
    
//     // Add task to local cache
//     setCachedTasks((prevTasks) => [...prevTasks, taskWithDescriptionAndStatus]);
    
//     toggleTask(); // Close the modal after adding the task
//   };

   const handleCancel = () => {
    // Clear the cached tasks
    setCachedTasks([]);
    // Call the parent's toggle function to close the modal
    toggle();
};
  
  //  const mergedData = [...partsAndCostDataGrid, ...cachedTasks];

    return (
      <div>
        <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed} size="lg">
          <ModalHeader toggle={toggle}> {formName}</ModalHeader>
          <ModalBody>
            {/* {message} */}
            <FormGroup style={{ marginBottom: "10px" }}>
              <Row>
                <Col md={12}>
                  <Label for="inputComment">
                    Please select Maintenance Type
                  </Label>
                  <Select
                    options={mainTypeList}
                    className={
                      !isValid
                        ? "alway-on-top-more form-error"
                        : "alway-on-top-more"
                    }
                    value={getSelMainType(
                      mainType === ""
                        ? localWoData[0].maintenanceType
                        : mainType
                    )}
                    placeholder={""}
                    onChange={(e) => onTextChange("maintenanceType", e, e)}
                    isClearable={true}
                  ></Select>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Label for="inputComment">Please select Issue Type</Label>
                  <Select
                    className={
                      !isIssueValid ? "alway-on-top form-error" : "alway-on-top"
                    }
                    options={issueTypeList}
                    value={getSelIssueType(
                      issueType === ""
                        ? localWoData[0].fiixIssueType
                        : issueType
                    )}
                    placeholder={""}
                    onChange={(e) => onTextChange("issueType", e, e)}
                    isClearable={true}
                  ></Select>
                </Col>
              </Row>

              
              <Row>
                <Col md={12}>
                  <Label for="empList" > Please select user to reroute to </Label>

                      <Select
                        options={empList}
                        name="empList"
                        id="empList"
                        isClearable={true}
                        value={props.getSelRerouteEmpList(rerouteUser)}
                        onChange={(e) => onChange3(e)}
                      ></Select>
                </Col>
              </Row>
            </FormGroup>

            
   
             
            {/* <Label for="inputComment" style={{ marginTop: "20px" }}>{commentPrompMsg}</Label>
              <Input
                type="textarea"
                // invalid={invalid}
                id="inputComment"
                name="text"
                // value={inputComment}
                // defaultValue={console.log("inputComment:" + inputComment)}
                rows={3}
                onChange={onChange}
              /> */}

          {/* <FormGroup>
              <Row
                form
                style={{
                  zIndex: 0,
                  position: "relative",
                  marginLeft: "0",
                  marginTop: "20px",
                }} // Adjusted marginTop here
              >
                <Button color="success" onClick={toggleTask}>
                  Add Task
                </Button>
              </Row> 
            <Row
                form
                style={{ zIndex: 0, position: "relative", marginTop: "10px" }}
              > 
               <IgrDataGrid
                  height={"250px"}
                  width={"100%"}
                  className="scrollable-grid"
                  isColumnOptionsEnabled="true"
                  editMode={0}
                  // dataSource={partsAndCostDataGrid}
                  dataSource={mergedData}
                  autoGenerateColumns="false"
                  selectionMode="SingleRow"
                  summaryScope="Both"
                  // selectedKeysChanged={onGridRowSelected}
                  // cellClicked={onGridRowSelected2}
                >
                  <IgrTextColumn
                    field="description"
                    headerText="Description"
                    width="*>250"
                    // cellUpdating={onStatusCellUpdating}
                  />
                  <IgrTextColumn
                    field="instructions"
                    headerText="Work Instructions"
                    width="*>250"
                    // cellUpdating={onStatusCellUpdating}
                  />
                  <IgrTextColumn
                    field="status"
                    headerText="Status"
                    width="*>150"
                    // cellUpdating={onStatusCellUpdating}
                  />                  
                   <IgrTextColumn
                    field="assignedDate"
                    headerText="Assigned Date"
                    width="*>250"
                    // cellUpdating={onStatusCellUpdating}
                  />                 
                </IgrDataGrid>
              </Row>
              <Row form style={{ marginTop: "20px" }}>
                <Col>
                 <DocumentBrowserDragDrop
                  buttonLabel="View Image"
                  buttonColor="primary"
                  formName="Workorder Images"
                  linkTableName={"topWorkOrder"}
                  linkPrimaryKey={workOrderKey < 0 ? offLineLinkKey : workOrderKey}
                  apiURL={`${apiURL}Fiix/Workorder/Documents/${workOrderKey < 0 ? offLineLinkKey : workOrderKey}`}
                /> 
            </Col>
              </Row> */}
            {/* </FormGroup>  */}


{/*Commented out by BN 10/10/24. Not on version 1 release*/}
                   {/* <TaskAndInstructions
                   statusChange={statusChange}
                    fiixWOStatus={fiixWOStatus}
                    user={props.user}
                    workOrderKey={workOrderKey}
                    // readOnly={readOnly}
                    fiixWOId={fiixWOId}
                    partsAndCostData={partsAndCostData}
                    partsAndCostDataGrid={partsAndCostDataGrid}
                    partsAndCostStatus={partsAndCostStatus}
                    refreshData={refreshData}
                    empList={empList}
                    onAddTasksToCache={onAddTasksToCache}
                  ></TaskAndInstructions> */}


          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleYes}>
              OK
            </Button>
            <Button color="primary" 
            onClick={handleCancel}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* <Modal
          isOpen={modalTask}
          toggle={toggleTask}
          size="med"
          backdrop={"static"}
        >
          <ModalHeader toggle={toggleTask}>Add User</ModalHeader>{" "}
          {/* Updated header text */}
         {/* <ModalBody>
            <FormGroup>
              <Row>
                <Col>
                  <Label>Please enter Assigned Date</Label>
                  <Datetime
                    inputProps={{ placeholder: "MM/DD/YYYY hh:mm" }}
                    // className={
                    //   !invalidDataItem.vendorCompletedDate
                    //     ? "form-error"
                    //     : ""
                    // }
                    value={moment(partsAndCostData.assignedDate).format(
                      "MM/DD/YYYY h:mm A"
                    )}
                    onChange={(v) => onTextChangeTask("assignedDate", v, null)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Label for="inputComment" style={{ marginTop: "20px" }}>Please select User</Label>
                  <Select
                    options={empList}
                    // className={
                    //   !invalidDataItem.laborItemKey
                    //     ? "form-error"
                    //     : ""
                    // }
                    id="empList"
                    isClearable={true}
                    // value={getSelEmpList(partsAndCostData.itemKey)}
                    onChange={(e) => onTextChangeTask("itemKey", e, e)}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Label for="inputComment" style={{ marginTop: "20px" }}>
                    Please enter work instructions
                  </Label>
                  <Input
                    type="textarea"
                    // invalid={invalid}
                    id="inputComment"
                    name="text"
                    // value={inputComment}
                    // defaultValue={console.log("inputComment:" + inputComment)}
                    rows={5}
                    onChange={(e) =>
                      onTextChangeTask("instructions", e.target.value, e)
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          */} 
          {/* <ModalFooter>
            <Button color="primary" onClick={() => saveTask()}>
              Save
            </Button>
            <Button color="secondary" onClick={toggleTask}>
              Cancel
            </Button>
          </ModalFooter> */}
        {/* </Modal> */}
      </div>
    );

}

export default RerouteConfirmationModal;
