import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Revise from '../resources/revise.png'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { ST_TrueFalseBlank, Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'

import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import InvoiceBatchCRUD from './desert/InvoiceBatchCRUD'
import InvoiceHeader from './desert/InvoiceHeader'



IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
// //IgrInputModule.register();
//IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");


export default class ReclamInvoiceBatch extends Component {
    static displayName = ReclamInvoiceBatch.name;

    constructor(props) {
        super(props);
        document.title = "WaterBridge Portal - Desert Reclamation Invoice"
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        //this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        // this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            modal: false,
            modalInv: false,
            invBatch: [],
            isLoaded: false,
            windowHeight: window.innerHeight,
            invoiceBatchkey: -1,
            selInvBatch: [],
            batchStatusKey: -1
        }
    }
    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem.invoiceBatchkey)

            this.setState({
                invoiceBatchkey: dataItem.invoiceBatchkey,
                selInvBatch: dataItem,
                batchStatusKey: dataItem.batchStatusKey
            })
            // this.setState({selSafeObs: dataItem})
            // this.toggle()
            //this.onVolRefresh()    
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.invBatch;
    }

    toggleModalInv = () => {
        this.setState({ modalInv: !this.state.modalInv })
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }


    newBatch = () => {
        this.toggle()
    }

    getInvBatch = () => {

        // let myURI = apiURL + 'SafetyObservation/startdate/' +
        //     this.state.startDate.toString().substring(0, 10) +
        //     '/enddate/' + this.state.endDate.toString().substring(0, 10)
        //     + '/AzureAD/' + this.props.user.account.userName;
        // console.log(myURI);

        let myURI = apiURL + '/ar/invoiceBatch/AzureAD/' + this.props.user.account.userName;

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    invBatch: json
                })
            });

    }

    invoiceDetail = () => {
        this.toggleModalInv()
    }

    componentDidMount() {
        this.getInvBatch()
    }


    render() {
        const { windowHeight, invBatch, invoiceBatchkey, modalInv } = this.state
        const gridHeight = (windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()
        console.log("invoiceBatchKey")
        console.log(invoiceBatchkey)

        return (<div>
            <Form onSubmit={e => e.preventDefault}>
                <FormGroup>
                    <Row form>
                        <Col sm={12}>
                            <Label><h3> Reclamation Invoice Batch |</h3></Label>
                            <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getAFEdata}>
                                <img
                                    src={Refresh}
                                    alt="Refresh"
                                    style={{ width: "30px", margin: "0 5px 0 0" }} />
                                Refresh
                            </Button>
                            <Button outline color="secondary"
                                className="btn-no-border"
                                onClick={this.saveWorkbook}>
                                <img
                                    src={Excel}
                                    alt="excel"
                                    style={{ width: "50px", margin: "0 0 0 0" }} />
                                Export To Excel
                            </Button>{' '}


                            <Button outline color="secondary"
                                className="btn-no-border"
                                onClick={this.invoiceDetail}
                            >
                                <img
                                    src={Revise}
                                    alt="details"
                                    style={{ width: "25px", margin: "0 5px 0 0" }} />
                                View Details
                            </Button>{' '}

                            <Button outline color="secondary"
                                className="btn-no-border"
                                onClick={this.newBatch}
                            >
                                <img
                                    src={Plus}
                                    alt="new"
                                    style={{ width: "25px", margin: "0 5px 0 0" }} />
                                New Invoice Batch
                            </Button>{' '}


                        </Col>
                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>

                <FormGroup>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={"77vh"}
                        dataSource={invBatch}
                        // dataSource={supplierReq.filter(rec => ((rec.entity || '').toLowerCase().includes(searchText)) ||
                        //     (rec.supplierReqID || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplierName || '').toLowerCase().includes(searchText) ||
                        //     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplierCategory || '').toLowerCase().includes(searchText) ||
                        //     (rec.contactName || '').toLowerCase().includes(searchText) ||
                        //     (rec.phoneNo || '').toLowerCase().includes(searchText) ||
                        //     (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                        //     (rec.createdByName || '').toLowerCase().includes(searchText) ||
                        //     (rec.comments || '').toLowerCase().includes(searchText) ||
                        //     (rec.scopOfWork || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                    //cellBackground={"#ffbfbf"}
                    >


                        <IgrTextColumn field="companyID" headerText="Entity " width="*>120"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTextColumn field="batchID" headerText="Batcj ID" width="*>140"
                            cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTextColumn field="batchTypeDesc" headerText="Type"
                            width="*>140"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="BatchStatusDesc" headerText="Status"
                            width="*>120"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="batchDate" headerText="Batch Date"
                            width="*>120"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrTextColumn field="printDate" headerText="Print Date"
                            width="*>120"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="createdByName" headerText="Requested By"
                            width="*>120"
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="createdDatetime" headerText="Created Date"
                            width="*>120" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onStatusCellUpdating} />
                        <IgrTextColumn field="batchComment" headerText="Comments"
                            width="*>350"
                            cellUpdating={this.onStatusCellUpdating} />

                        <IgrNumericColumn field="invoiceBatchkey" isHidden />
                        <IgrNumericColumn field="batchStatusKey" isHidden />
                    </IgrDataGrid>
                </FormGroup>
            </Form>

            <div>
                <Modal isOpen={this.state.modalInv}
                    toggle={() => this.toggleModalInv()}
                    contentClassName="full-screen-modal"
                    style={{ margin: 0, flex: 1 }}
                    backdrop={"static"}
                    //onClosed={() => this.getSupplierReq()}
                    //size={"lg"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    centered>
                    <ModalHeader toggle={() => this.toggleModalInv()}> Invoices </ModalHeader>
                    <ModalBody>
                        <InvoiceHeader
                            invoiceBatchKey={invoiceBatchkey}
                            // selSupReqRec={selSupReqRec}
                            user={this.props.user}
                        // toggle={this.toggle}
                        // deleteSupReq={(e) => this.deleteSupplierReq(e)}
                        // supplierCat={supplierCat}
                        // entity={entity}
                        // refreshData={this.getSupplierReq}
                        >

                        </InvoiceHeader>
                    </ModalBody>
                </Modal>
            </div>

            <div>
                <Modal isOpen={this.state.modal}
                    toggle={() => this.toggle()}
                    //onClosed={() => this.getSupplierReq()}
                    size={"lg"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    centered>
                    <ModalHeader toggle={() => this.toggle()}> New Invoice Batch </ModalHeader>
                    <ModalBody>
                        <InvoiceBatchCRUD
                        // supplierRequestKey={supplierRequestKey}
                        // selSupReqRec={selSupReqRec}
                        // user={this.props.user}
                        // toggle={this.toggle}
                        // deleteSupReq={(e) => this.deleteSupplierReq(e)}
                        // supplierCat={supplierCat}
                        // entity={entity}
                        // refreshData={this.getSupplierReq}
                        >

                        </InvoiceBatchCRUD>
                    </ModalBody>
                </Modal>
            </div>
        </div>)
    }
}