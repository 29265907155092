import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");





export default class FormRCATimeline extends Component {
    static displayName = FormRCATimeline.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state = {
            formRCAKey: props.formRCAKey,
            Data: [],
            isLoaded: false,
            isFormReadOnly: false,
            modal: false,
            user: props.user,
            timelineKey: -1,
            sequence: ' ',
            cmtDate: moment(new Date().toISOString()).format(),
            
            hidden: props.hidden
        }

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.Data;
    }

    toggleOK = () => {
        if (this.saveData()) {
            this.setState({
                modal: !this.state.modal,
                sequence: " "
                
            })

        }
    }

    validateData = () => {
        var { sequence, cmtDate } = this.state
        sequence = sequence.trim()
        if (sequence != "" && cmtDate !== 'Invalid date') {
            return true
        } else {
            this.setState({ sequence: sequence })
        }
    }

    createDataForUpdateDTO = () => {
        const { timelineKey, sequence, cmtDate, user } = this.state
        const opsCmtDTO = {
            "timelineKey": timelineKey,
            "sequence": sequence,
            "sdatetime": cmtDate ? moment(cmtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            // "modifiedBy": getUserADAccount(user.account.userName),
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    updateData = () => {
        const uptDTO = this.createDataForUpdateDTO()
        let myURI = apiURL + 'SafetyIncident/UpdateTimeline'


        fetch(myURI, apiPUTHeader(uptDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    //return Promise.reject(error);
                    return false
                }

                console.log("No error here: " + rspData)
            })
            .catch(error => {
                console.log("PUT Error: " + error);
                return false
            })

        return true
    }
    createDataForCreateDTO = () => {
        const { formRCAKey, cmtDate, sequence, user } = this.state

        const linetoAdd = {
            "FormRCAKey": formRCAKey, //selectedValue.key,
            "Sequence": sequence,
            // "Sdatetime": cmtDate,
            "Sdatetime": cmtDate ? moment(cmtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            "CreatedBy": getUserADAccount(user.account.userName),

            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }

    saveData = () => {
        const { timelineKey } = this.state
        if (this.validateData()) {

            if (timelineKey > 0) {
                if (this.updateData()) {
                    this.setState({ timelineKey: -1 })
                }
            } else {
                const addDTO = this.createDataForCreateDTO()
                let myURI = apiURL + 'SafetyIncident/AddTimeline'
                fetch(myURI, apiPOSTHeader(addDTO))
                    .then(async response => {
                        const rspData = response.text();
                        if (!response.ok) {
                            console.log("Error return but you dont see")
                            const error = (rspData && rspData.message) || response.status;
                            //return Promise.reject(error);
                            return false
                        }

                        console.log("No error here: " + rspData)
                    })
                    .catch(error => {
                        console.log("PUT Error: " + error);
                        return false
                    })
            }
            return true
        }

        return false
    }

    onTextChange = (fieldName, fieldValue, e) => {
        this.setState({ sequence: fieldValue });
    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })
    }

    newData = () => {
        this.setState({
            sequence: ' ',
            cmtDate: moment(new Date().toISOString()).format(),
            timelineKey: -1
        })
        console.log("xxxx: " + this.state.cmtDate)
        this.toggle()
    }

    onGridRowSelected(s, e) {
        //console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // this.setState({ selAFELine: dataItem })           
            this.setState({
                sequence: dataItem.sequence,
                cmtDate: dataItem.datetime,
                timelineKey: dataItem.timelineKey
            })
        }else {
            this.setState({
                // sequence: dataItem.sequence,
    
                timelineKey: -1
            })
        }
    }

    editData = (key, date, text) => {

        this.toggle()
    }

    refreshData = () => {
        this.getData()
    }

    getData = () => {
        const { formRCAKey } = this.state
        let myURI = apiURL + 'SafetyIncident/getTimeline/' + formRCAKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    Data: json,
                    isLoaded: true
                })
            });
    }
    handleChange = (v, f) => {
        // if (v == '') {
        //     this.setState({ invalidDate: true })
        // }

        this.setState({
            // data[0].incDateTime = moment(fieldValue).format();
            // cmtDate: moment(v).format()
            cmtDate: v

            // cmtDate: v
        })
    }

    componentDidMount() {
        this.getData();
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

        }

        switch (s.field) {
            case 'datetime':

                var date = new Date(item.datetime);
                link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;

            default:

        }

    }

    render() {
        const { user, formRCAKey, Data,
            isLoaded, isFormReadOnly,
            timelineKey, cmtDate,
            sequence, hidden } = this.state

        if (!isLoaded) {
            return (<div>
                Loading Data ......
            </div>)
        } else
            return (<div>
                {/* <FormGroup row>

                    <Label>

                        ddddd {data[0].statusKey} {user} isReadOnly:<b>{isReadOnly ? "true" : "false"}</b>   isUser:<b>{isUser ? "true" : "false"}</b>   isAdmin:<b>{isAdmin ? "true" : "false"}</b>    isApprover:<b>{isApprover ? "true" : "false"}</b>  isEditApprover:<b>{isEditApprover ? "true" : "false"}</b>  
                        ddddtl {timelineKey} sequence:{sequence}-- cmtDate:{cmtDate}
                    </Label>




                </FormGroup> */}
                <Row form style={{ marginLeft: "-14px", marginTop: "-4px"}}>
                    {/* <Col sm={12}> */}
                    {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                    <Button color="primary" outline className="float-left"
                        style={{ height: "35px" }}
                        hidden={hidden}
                        onClick={() => this.newData()}
                    >Add</Button>

                    <Button color="primary" outline className="float-left"
                        style={{ height: "35px", marginLeft: "10px" }}
                        disabled={timelineKey < 0}
                        hidden={hidden}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.toggle()}
                    >Edit</Button>

                </Row>

                {/* <ColoredLine color="grey" /> */}
                {/* <FormGroup row>
                    <Col md={12}>
                        <div>
                            <Label>

                                dddd5 {formRCAKey}
                            </Label>


                        </div>
                    </Col>

                </FormGroup> */}

                <Row style={{ paddingTop: "5px", paddingRight: "20px"}}>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={(Data.length > 10 ? "300px" : "100%")}
                        width='100%'
                        dataSource={Data}
                        //isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}
                    >

                        <IgrTemplateColumn
                            field="datetime"
                            headerText="Date"
                            width="170"
                            cellUpdating={this.onCellUpdating}
                        />

                        {/* <IgrTextColumn
                            field="createdBy"
                            headerText="By"
                            width="140"
                        /> */}

                        <IgrTextColumn
                            field="sequence"
                            headerText="Sequence of Events"
                            width="*>350"
                        />

                        <IgrNumericColumn field="timelineKey" isHidden />
                    </IgrDataGrid>

                </Row>


                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                        onClosed={() => this.refreshData()}
                        //contentClassName="oilTicketDetail-modal-style"
                        size='lg'
                    >
                        <ModalHeader toggle={() => this.toggle()}
                        > New Timeline </ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Label for="commentDate" sm={2}>Datetime</Label>
                                <Col sm={10}>
                                    {/* <DatePicker value={this.state.cmtDate} id="commentDate"
                                        onChange={(v, f) => this.handleChange(v, f)} /> */}

                                    <Datetime
                                        id="commentDate"
                                        inputProps={{
                                            placeholder: "MM/DD/YYYY hh:mm",
                                            // disabled: isReadOnly
                                        }}
                                        value={moment(cmtDate)}
                                        className={(cmtDate === 'Invalid date' ? "form-error" : "")}
                                        // invalid={invalidDate}

                                        onChange={(v, f) => this.handleChange(v, f)}
                                    />
                                    <FormFeedback tooltip> Date can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="exampleText" sm={2}>Sequence</Label>
                                <Col sm={10}>
                                    <Input type="textarea"
                                        name="text"
                                        id="exampleText"
                                        rows="6"
                                        defaultValue={sequence}
                                        value={sequence}
                                        invalid={sequence === ""}
                                        onChange={(e) => this.onTextChange("sequence", e.target.value, e)} />
                                    <FormFeedback tooltip> Sequence can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                        </ModalFooter>
                    </Modal>
                </div>

            </div>)
    }
}