// This comment put in by Hau Nguyen on 06/06/2021

import React, { Component } from 'react';



import { apiURL, apiHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import TrashIcon from '../resources/trash.svg';
import RemoveIcon from '../resources/remove.png';
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';

import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { IgrExcelModule } from 'igniteui-react-excel';


import { IgrTemplateColumn } from 'igniteui-react-grids';
import ConfirmModal from '../operation/ConfirmModal'
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'

// import ExcFile from '../resources/Submit_Customer_Invoice.xlsx'
import ExcFile from '../resources/sub.xlsx';
import ModalYNComment from './ModalYNComment'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ManageSubform from './ManageSubform'

import Select from 'react-select';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();
IgrExcelModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class OGEnergyLink extends Component {
    static displayName = OGEnergyLink.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.saveExcel = this.saveExcel.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);


        const urlPara = this.getURLParameters(props.parameters)

        this.state = {
            data: [],
            dataDetails: [],
            isLoaded: false,
            // startDate: (urlPara.startDate === null ? getFirstDayofMonth().toISOString() : urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            // endDate: (urlPara.endDate === null ? new Date().toISOString() : urlPara.endDate),
            // modalOpen: true,
            //OGEnergyKey: 0,
            //selSafeObs:null,
            windowHeight: window.innerHeight,
            // modal: false,
            //safetyType : urlPara.safetyType,
            // isQHSEAdmin:false,
            // isQHSEApprover: false,
            // fromParamStartDate: urlPara.startDate,
            // fromParamEndDate: urlPara.endDate,
            dataSelected: [],
            isFilenamesLoaded: false,
            filenamesList: [],
            filenamesListSelected: "",
            modalCustomer: false,
            modalRevenue: false,
            modalSite: false,

            modalMsg: false,
            msgHeader: "",
            msgBody: ""

            // ,
            // invalidDataItem: {
            //     reviewCommentCont: true,
            //     reviewCommentText: ""
            // },

        }
    }
    getFilenames = () => {
        let myURI = apiURL + 'AP/OGEnergyLink/Filenames/'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isFilenamesLoaded: true,
                    filenamesList: this.createfilenamesList(json)

                })
            });
    }

    createfilenamesList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.fileName
            e.label = e.fileName
            tempList.push(e)
        })

        return tempList
    }

    onTextChange = (fieldName, fieldValue, e) => {
        console.log("props")
        console.log(fieldName + '=' + fieldValue)
        var { filenamesListSelected } = this.state;

        switch (fieldName) {

            case "filenamesList":
                filenamesListSelected = this.getFilenameString(e)

                break;

            default:

        }

        this.setState({ filenamesListSelected: filenamesListSelected })

    }

    onManageChange = (e) => {
        console.log("props")
        // console.log(fieldName + '=' + fieldValue)
        // var { filenamesListSelected } = this.state;

        switch (e.value) {

            case "customer":
                this.toggleCustomer()

                break;
            case "revenue":
                this.toggleRevenue()

                break;
            case "site":
                this.toggleSite()

                break;

            default:

        }

        // this.setState({ filenamesListSelected: filenamesListSelected })

    }

    getFilenameString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.fileName
        })

        return grpKey;
    }


    getURLParameters = (inputPara) => {
        var paraMeters = {
            // startDate: null,
            // endDate: null,
            facilityKeys: null,
            //safetyType: null,
            data: null
        }


        if (!this.props.location === 'undefined' || inputPara !== 'undefined') {
            var query
            if (!this.props.location === 'undefined') {
                query = new URLSearchParams(this.props.location.search);

            } else {
                query = new URLSearchParams(inputPara);
            }


            paraMeters = {
                // startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
                // endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
                //safetyType:query.get('SafetyType'),
                facilityKeys: query.get('Facility')
            }

        }





        console.log(paraMeters)
        return paraMeters
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.data;

        //this.grid.cells[0]
        console.log(this.grid.cellBackground)

    }



    toggleCustomer = () => {
        this.setState({ modalCustomer: !this.state.modalCustomer })
    }

    toggleRevenue = () => {
        this.setState({ modalRevenue: !this.state.modalRevenue })
    }

    toggleSite = () => {
        this.setState({ modalSite: !this.state.modalSite })
    }


    // onGridRowSelected(s, e) {
    //     console.log(" On CLick")
    //     //const content = e.content;
    //     if (e.addedKeys.count > 0) {
    //         let clickedRow = e.addedKeys.item(0)
    //         let dataItem = clickedRow.value[0]
    //         console.log(dataItem)

    //         // this.setState({ safIncHeaderKey: dataItem.safIncHeaderKey })
    //         // this.setState({ safIncID: dataItem.safIncID })
    //         // this.setState({ selSafeObs: dataItem })
    //         this.toggle()
    //         //this.onVolRefresh()    
    //     }
    // }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        // const { datatoDelete } = this.state

        // if (e.removedKeys.count > 0) 
        // {

        // }
        //const content = e.content;
        //if (e.addedKeys.count > 0) {
        // let clickedRow = e.addedKeys.item(0)
        // let dataItem = clickedRow.value[0]
        //console.log(dataItem)

        const tempList = []
        // let clickedRows = e.currentKeys

        for (let i = 0; i < e.currentKeys.count; i++) {
            // console.log(e.currentKeys.item(i).value[0] )
            tempList.push(e.currentKeys.item(i).value[0].oegEnergy);
        }

        this.setState({ dataSelected: tempList })
        //this.state.dataSelected = tempList;


        //}
    }


    deleteLines = () => {

        const createDataforDelete = this.createDataforDelete()


        let myURI = apiURL + '/AP/spapOGEDelete'
        fetch(myURI, apiDELETEHeader(createDataforDelete))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);
                }
                this.getData()

            })
            .catch(error => {

            })
    }


    deleteFilenames = () => {

        const createDataforDelete = this.createDataforDeleteFilenames()
        // const { filenamesList, filenamesListSelected } = this.state
        // const createDataforDelete = this.createDataforDelete()


        let myURI = apiURL + '/AP/spapOGEDeleteFilenames'
        fetch(myURI, apiDELETEHeader(createDataforDelete))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.setState({ dataSelected: null, filenamesListSelected: "" })


                this.getFilenames()
                this.getData()
                // this.setShowingAlert(true)
                //this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    createDataforDeleteFilenames = () => {
        const { filenamesList, filenamesListSelected } = this.state
        const createDataforDelete = filenamesList.filter(rec => filenamesListSelected.includes(rec.fileName))

        const dataDTO = []

        createDataforDelete.map(e => {


            const itemDTO = {
                "FileName": e.fileName
            }

            dataDTO.push(itemDTO)

        })

        return dataDTO

    }






    excludeLinesfromEIB = () => {

        const createDataforExclude = this.createDataforExclude()
        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createDataforExclude)
        }

        let myURI = apiURL + '/AP/spapOGEExcludeFromEIB'
        fetch(myURI, putReq)
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);
                }
                this.getData()

            })
            .catch(error => {

            })
    }





    createDataforDelete = () => {
        const { dataSelected } = this.state
        const { user } = this.props

        var tempDTO = [];
        var i = 0;
        dataSelected.map(function (line) {
            const createDataforDelete = {
                "OEGEnergy": line,
                "deletedby": getUserADAccount(user.account.userName)

            }
            tempDTO[i] = createDataforDelete;
            i = i + 1
        });

        return tempDTO;
    }

    createDataforExclude = () => {
        const { dataSelected } = this.state
        const { user } = this.props

        var tempDTO = [];
        var i = 0;
        dataSelected.map(function (line) {
            const createDataforExclude = {
                "OEGEnergy": line,
                "updatedby": getUserADAccount(user.account.userName)

            }
            tempDTO[i] = createDataforExclude;
            i = i + 1
        });

        return tempDTO;
    }





    getData = () => {

        var { filenamesListSelected } = this.state;
        // var fSelected = ""
        // if (filenamesListSelected!=""){fSelected=filenamesListSelected}


        // let myURI = apiURL + 'AP/OGEnergyLink/startdate/' +
        //     this.state.startDate.toString().substring(0, 10) +
        //     '/enddate/' + this.state.endDate.toString().substring(0, 10);




        let myURI = apiURL + 'AP/OGEnergyLink';

        // let myURI = apiURL + 'AP/OGEnergyLink/startdate/2021-10-01/enddate/2021-10-30';



        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.getDataDetails();
                this.setState({
                    isLoaded: true,
                    //const isApprover = (json.filter(rec => rec.employee.toUpperCase() === user.toUpperCase() && rec.lineStatusKey === 3 && rec.actionKey === 1).length > 0)
                    data: json.filter(rec => filenamesListSelected.includes(rec.fileName))
                    // data: json
                })
            });


    }

    getDataDetails = () => {
        var { filenamesListSelected } = this.state;


        // let myURI = apiURL + 'AP/OGEnergyLinkDetails/startdate/' +
        //     this.state.startDate.toString().substring(0, 10) +
        //     '/enddate/' + this.state.endDate.toString().substring(0, 10);
        let myURI = apiURL + 'AP/OGEnergyLinkDetails';
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    // isLoaded: true,
                    //dataDetails: json
                    //const isApprover = (json.filter(rec => rec.employee.toUpperCase() === user.toUpperCase() && rec.lineStatusKey === 3 && rec.actionKey === 1).length > 0)
                    dataDetails: json.filter(rec => filenamesListSelected.includes(rec.fileName))

                })
            });


    }




    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        // this.getIsQHSEadmin()
        // this.getIsQHSEApprover()
        this.getFilenames()
        this.getData()

        // this.getDataDetails()

    }





    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    
    // handleChange(value, formattedValue) {
    //     this.setState({
    //         startDate: value,
    //         formattedValue: formattedValue
    //     });
    //     console.log(this.state.startDate)
    // }

    // handleEndDateChange(value, formattedValue) {
    //     this.setState({
    //         endDate: value,
    //         formattedValue: formattedValue

    //     });

    // }

    createDatafoRemoveError = (e) => {
        const { dataSelected } = this.state
        const { user } = this.props

        var tempDTO = [];
        var i = 0;
        dataSelected.map(function (line) {
            const createDataforDelete = {
                "OEGEnergy": line,
                "Comment": e,
                "updatedby": getUserADAccount(user.account.userName)

            }
            tempDTO[i] = createDataforDelete;
            i = i + 1
        });

        return tempDTO;
    }







    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;



        // content.style.backgroundColor = "#4286f4";

        // const pe = content.parentElement;

        // content. = "#b6a065"

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            // let cell = document.createElement("div");



            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.errorCode === 1) {
                content.style.background = "#ff4040";
            } else if (item.exportBy) {
                content.style.background = "#A3E4D7";
            } else if (item.lineStatusKey === 3 || item.lineStatusKey === 5) {
                content.style.background = "#ad5f55";
            } else {
                content.style.background = "transparent";
            }
        }



        switch (s.field) {
            // case 'oegEnergy':
            //     link.textContent = item.oegEnergy;
            //     break;
            case 'fileName':
                link.textContent = item.fileName;

                break;
            case 'company':
                link.textContent = item.company;
                break;
            // case 'createdDateTime':
            //     var date = new Date(item.createdDateTime);
            //     link.textContent = date.toLocaleDateString();

            //     break;
            // case 'modifiedDateTime':

            //     if (item.modifiedDateTime == null) {
            //         link.textContent = "";

            //     } else {
            //         var date = new Date(item.modifiedDateTime);
            //         link.textContent = date.toLocaleDateString();
            //     }


            //     break;

            case 'currency':
                link.textContent = item.currency;
                break;
            // case 'customer':
            //     link.textContent = item.customer;
            //     break;

            case 'errorComment':
                link.textContent = item.errorComment;
                // content.style.background = "red";
                link.title = item.errorComment;

                // link.className="tohover";

                break;





            case 'invoice_Date':
                var date = new Date(item.invoice_Date);
                link.textContent = date.toLocaleDateString();

                break;


            case 'errorCode':
                link.textContent = item.errorCode;
                break;





            // case 'revenue_Category':
            //     link.textContent = item.revenue_Category;
            //     break;
            // case 'line_Item_Description':
            //     link.textContent = item.line_Item_Description;
            //     break;
            // case 'unit_of_Measure':
            //     link.textContent = item.unit_of_Measure;
            //     break;
            // case 'owner_Volume':
            //     link.textContent = item.owner_Volume;
            //     break;
            // case 'owner_Value':
            //     link.textContent = item.owner_Value;
            //     break;
            // case 'owner_Deducts':
            //     link.textContent = item.owner_Taxes;
            //     break;
            // case 'owner_Taxes':
            //     link.textContent = item.owner_Taxes;
            //     break;

            default:

        }

    }

    onStatusCellUpdatingNoHighlight(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;



        // content.style.backgroundColor = "#4286f4";

        // const pe = content.parentElement;

        // content. = "#b6a065"

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            // let cell = document.createElement("div");



            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
            content.style.background = "transparent";

            // if (item.errorCode == 1) {
            //     content.style.background = "#ffcccc";
            // } else if (item.lineStatusKey == 6) {
            //     content.style.background = "#61a066";
            // } else if (item.lineStatusKey == 3 || item.lineStatusKey == 5) {
            //     content.style.background = "#ad5f55";
            // } else {
            //     content.style.background = "transparent";
            // }
        }



        switch (s.field) {


            case 'check_Date':
                var date = new Date(item.check_Date);
                link.textContent = date.toLocaleDateString();

                break;
            case 'prod_Date':
                var date = new Date(item.prod_Date);
                link.textContent = date.toLocaleDateString();

                break;


            default:

        }

    }









    // newObservation=()=>{
    //     this.setState({safIncHeaderKey: -1})       
    //     //this.setState({selSafeObs: dataItem})
    //     this.toggle()
    // }





    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            // let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.reportDate).toLocaleDateString("es-PA");
    }

    saveExcel() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }


        ExcelUtility.save(wb, "OGEnergyLink");
        // ExcelUtility.save(wb, "OGEnergyLinkA").then((w) => {
        //     var ee = w;



        // });

    }



    saveWorkbook() {

        // var actCols = this.grid.actualColumns
        // console.log(actCols.item(0))

        // let gridDts = this.grid.dataSource // this.state.inspItems

        // const headers = Object.keys(gridDts[0]);
        // headers.pop();

        // var wb = new Workbook(WorkbookFormat.Excel2007);
        // var wb = ExcelUtility.loadFromUrl("https://h20bridge.sharepoint.com/:x:/s/IT/EdjkfAVP4NFEuV471JiiCfwBwFezDKTbEhMWPLee5Pde9w?");
        // wb = ExcelUtility.loadFromUrl(ExcFile);

        // const excelFile = '../resources/Submit_Customer_Invoice.xlsx';
        // const excelFile = 'https://static.infragistics.com/xplatform/excel/SalesData.xlsx';

        // const excelFile = 'https://h20bridge.sharepoint.com/:x:/s/IT/ERDHCt-Row5Jp3QVxqYHITEBlbK9_M3o_LRSHI0DRdZYfg?e=zcRIKK'

        // const excelFile = 'https://h20bridge.sharepoint.com/:x:/s/IT/EdjkfAVP4NFEuV471JiiCfwBwFezDKTbEhMWPLee5Pde9w?';
        // const excelFile = '../resources/test.xlsx';
        //const excelFile = 's:\\Public\\Scans\\Submit_Supplier_Invoice_Request2021.xlsx';
        // const excelFile = '../resources/SalesData.xlsx';
        // const excelFile = '../resources/plus.svg'; 
        // const excelFile = 'c://temp//SalesData2.xls';

        // const excelFile = 'SalesData3.xml';
        const { dataDetails } = this.state

        //var currentRow = 6;

        // ExcelUtility.loadFromUrl(excelFile).then((w) => {
        // this.getDataDetails();

        // ExcelUtility.loadFromUrl(excelFile).then((w) => {
        //     var wb = w;
        //     ExcelUtility.save(wb, "EIBExport_OEG");
        // });

        ExcelUtility.loadFromUrl(ExcFile).then((w) => {
            var wb = w;
            // const ws = wb.worksheets().add("Submit Customer Invoice2");
            const ws = wb.worksheets("Submit Customer Invoice");
            var spreadsheetkey = 0;
            dataDetails.forEach((item, index) => {
                // ws.rows(index + 5).cells(5).value = item.viewkey;



                if (item.rowID2 === 1) {


                    // ws.rows(index + 5).cells(1).value = "b"; Spreadsheet Key*


                    spreadsheetkey++;
                    // ws.rows(index + 5).cells(3).value = "d"; Company*
                    ws.rows(index + 5).cells(3).value = item.companyID;

                    // ws.rows(index + 5).cells(4).value = "e"; Currency	
                    ws.rows(index + 5).cells(4).value = item.currency;

                    // ws.rows(index + 5).cells(5).value = "f"; Customer*
                    ws.rows(index + 5).cells(5).value = item.customer;

                    // ws.rows(index + 5).cells(8).value = "i"; Invoice Date
                    ws.rows(index + 5).cells(8).value = (item.invoiceDate).substring(0, 10);

                    // ws.rows(index + 5).cells(12).value = "m"; Memo	
                    ws.rows(index + 5).cells(12).value = item.memo;
                }

                // ws.rows(index + 5).cells(1).value = item.spreadsheetKey;
                ws.rows(index + 5).cells(1).value = spreadsheetkey;
                // ws.rows(index + 5).cells(13).value = "n"; Row ID*	
                ws.rows(index + 5).cells(13).value = item.rowID2;

                // ws.rows(index + 5).cells(14).value = "o"; Customer Invoice Line Reference ID
                ws.rows(index + 5).cells(14).value = item.customerInvoiceLineReferenceID;

                // ws.rows(index + 5).cells(15).value = "p"; Sales Item
                ws.rows(index + 5).cells(15).value = item.salesItem;

                // ws.rows(index + 5).cells(16).value = "q"; Revenue Category
                ws.rows(index + 5).cells(16).value = item.revenueCategory;

                // ws.rows(index + 5).cells(17).value = "r"; Line Item Description
                ws.rows(index + 5).cells(17).value = item.lineItemDescription;

                // ws.rows(index + 5).cells(18).value = "s"; Row ID*
                ws.rows(index + 5).cells(18).value = item.rowID3;

                // ws.rows(index + 5).cells(19).value = "t"; Quantity	
                ws.rows(index + 5).cells(19).value = item.quantity;

                // ws.rows(index + 5).cells(20).value = "u"; Unit of Measure
                ws.rows(index + 5).cells(20).value = item.unitofMeasure;

                // ws.rows(index + 5).cells(21).value = "v"; Unit Cost
                ws.rows(index + 5).cells(21).value = item.unitCost;

                // ws.rows(index + 5).cells(22).value = "w"; Extended Amount
                ws.rows(index + 5).cells(22).value = item.extendedAmount;

                // ws.rows(index + 5).cells(23).value = "x"; Memo	
                ws.rows(index + 5).cells(23).value = item.lineMemo;

                // ws.rows(index + 5).cells(24).value = "y"; Site	
                ws.rows(index + 5).cells(24).value = item.site;

                // ws.rows(index + 5).cells(25).value = "z"; Service Month
                ws.rows(index + 5).cells(25).value = item.serviceMonth;

                // ws.rows(index + 5).cells(26).value = "aa"; Service Year
                ws.rows(index + 5).cells(26).value = item.serviceYear;

                // ws.rows(index + 5).cells(27).value = "ab"; Cost Center
                ws.rows(index + 5).cells(27).value = item.costCenter;

            })






            // ws.Cell("C7").value=24234;
            // ws.setCellValue

            ExcelUtility.save(wb, "EIBExport_OEG")
                .then((w) => {
                    this.saveDataDtl()

                });
        });



    }

    saveDataDtl = () => {
        const itemDTO = this.createAEDateForUpdateDTO();
        console.log(itemDTO);

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'AP/OGUpdateDateExport'
        fetch(myURI, putReq)
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                this.getData();
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
        //.then(json => console.log(json));

    }



    createAEDateForUpdateDTO = () => {
        const { data } = this.state;
        const { user } = this.props
        var tempDTO = [];
        var i = 0;

        data.map(function (line) {


            const uLine = {

                "UniqueKey": line.uniqueKey,
                "ExportBy": getUserADAccount(user.account.userName), //line.lineModifiedBy,

            }

            tempDTO[i] = uLine;
            i = i + 1;
        });

        return tempDTO;
    }


    removeError = (e) => {
        // const { meterValDetails, invalidDataItem } = this.state
        // var valItem = invalidDataItem
        // const { user } = this.props
        if (e) {
            // meterValDetails.statusKey=2
            // this.saveData()
            this.spRemoveError(e);
            // valItem.reviewCommentCont = true
            // valItem.reviewCommentText = ""
            // this.setState({ invalidDataItem: valItem })
            // this.toggle()
        }

        // this.setState({meterValDetails:meterValDetails})

    }

    // validateData = () => {
    //     const { meterValDetails, invalidDataItem } = this.state
    //     var bVal = true
    //     var valItem = invalidDataItem

    //     if (invalidDataItem.reviewCommentText == "") {
    //         bVal = false
    //         valItem.reviewCommentCont = false
    //     }

    //     this.setState({ invalidDataItem: valItem })

    //     return bVal
    // }


    spRemoveError = (e) => {
        const createDatafoRemoveError = this.createDatafoRemoveError(e);

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(createDatafoRemoveError)
        }

        let myURI = apiURL + 'AP/spapOGERemoveError'
        fetch(myURI, putReq)
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                this.getData();
                this.toggle()
                // this.closeForm(true);
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
        //.then(json => console.log(json));

    }

    // toggle=()=>{
    //     this.setState({modal: !this.state.modal})
    // }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }



    render() {
        var { isLoaded, data, windowHeight, dataSelected
            ,
            isFilenamesLoaded, filenamesList, filenamesListSelected, modalCustomer, modalRevenue, modalSite, msgHeader, msgBody
        } = this.state;
        //const {safetyObservationKey,selSafeObs,isQHSEAdmin, isQHSEApprover} = this.state;
        //const {SafIncHeaderKey,selSafeObs} = this.state;

        const gridHeight = (windowHeight * 0.7) + "px"
        // const searchText = window.$searchText.toLowerCase()

        // console.log("isQHSEAdmin")
        // console.log(isQHSEAdmin)

        if (!isLoaded) { return (<div> <h3>Loading O&G Energy Link data.....</h3>    </div>) }
        if (!isFilenamesLoaded) return <h1> loading filenames... </h1>


        return (<div>
            <FormGroup row>
                <Col md={9}>
                    <Label><h2> O&G Energy Link  |</h2></Label>            
                    <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.getData}
                    >
                        <img
                            src={Refresh}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                        Refresh
                    </Button>
                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.saveExcel}>
                        <img
                            src={Excel}
                            alt="excel"
                            style={{ width: "50px", margin: "0 0 0 0" }} />
                        Export To Excel
                    </Button>



                    {/* {' '} */}
                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.saveWorkbook}>
                        <img
                            src={MenuIcon}
                            alt="excel"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                        Create EIB
                    </Button>
                    {/* {' '} */}
                    <button className="btn-no-border">
                        <ConfirmModal
                            outline={true}
                            //   color="danger"
                            className="btn-no-border"
                            buttonLabel=" Delete filenames"
                            // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                            formName="Confirm"
                            // id={facilityOilTicketKey}
                            message="Are you sure you want to delete selected filenames?"

                            //deleteLines kept just in case we need to delete data lines that were selected 
                            // onClickYes={this.deleteLines}
                            onClickYes={this.deleteFilenames}


                            image={<img
                                src={TrashIcon}
                                style={{ width: "20px", height: "20px", margin: "0 0 0 0" }} />}
                        >
                        </ConfirmModal>
                    </button>


                </Col>
                <Col md={3}>
                    <Select
                        options={[
                            { value: 'customer', label: 'Manage Customers' },
                            { value: 'revenue', label: 'Manage Revenues' },
                            { value: 'site', label: 'Manage Sites' }
                        ]}
                        className="float-right alway-in-between3"
                        // style={{ float: \"right\", z-index: \"200\" }}
                        // closeMenuOnSelect={true}
                        // escapeClearsValue={true}
                        // menuPlacement="top"
                        // isMulti
                        value="null"
                        placeholder="Manage...                                "
                        isSearchable={false}

                        //  value = {this.getSelSC(itemDetail[0].spendCategory)}
                        //  defaultValue ={this.getSelSC(itemDetail[0].spendCategory)}
                        onChange={(e) => this.onManageChange(e)}
                    // isClearable={true}
                    >


                    </Select>



                    {/* {(dataSelected)} */}
                    {/* <button className="btn-no-border">
                        <ModalYNComment outline={true}
                            //   color="secondary"
                            className="btn-no-border"
                            buttonLabel=" Remove error(s)"
                            //   hidden ={(!(statusKey==4)) || (isSecurityAdminLogin)}
                            // invalid={!invalidDataItem.reviewCommentCont}
                            // value={invalidDataItem.reviewCommentText}
                            // defaultValue = {invalidDataItem.reviewCommentText}
                            // disabled= {dataSelected.length>0 ? "false" : "true"}
                            // disabled="false"
                            //inputKey={-1}
                            // dataSelected={dataSelected}
                            formName="Confirm"
                            //   id={meterValidationKey}
                            message="Are you sure you want to remove error?                   "
                            //   meterValidation={meterValidation}
                            // onClosed={(e) => this.removeErrorZero()}
                            // onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                            onClickYes={(e) => this.removeError(e)}
                            image={<img
                                src={RemoveIcon}
                                style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}

                        />
                    </button>
                    <button className="btn-no-border">
                        <ConfirmModal outline={true}
                            //   color="danger"
                            className="btn-no-border"
                            buttonLabel=" Exclude EIB"
                            // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                            formName="Confirm"
                            // id={facilityOilTicketKey}
                            message="Exclude line(s) from exporting to EIB?"
                            onClickYes={this.excludeLinesfromEIB}
                            image={<img
                                src={MenuIcon}
                                // id={facilityOilTicketKey}

                                style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}
                        >
                        </ConfirmModal>
                    </button> */}


                    {/* <button className="btn-no-border">
                        <ModalCustomer outline={true}
                            //   color="secondary"
                            className="btn-no-border"
                            buttonLabel=" Manage Customer"
                            //   hidden ={(!(statusKey==4)) || (isSecurityAdminLogin)}
                            // invalid={!invalidDataItem.reviewCommentCont}
                            // value={invalidDataItem.reviewCommentText}
                            // defaultValue = {invalidDataItem.reviewCommentText}
                            // disabled= {dataSelected.length>0 ? "false" : "true"}
                            // disabled="false"
                            //inputKey={-1}
                            // dataSelected={dataSelected}
                            formName="Confirm"
                            //   id={meterValidationKey}
                            message="Are you sure you want to remove error?                   "
                            //   meterValidation={meterValidation}
                            // onClosed={(e) => this.removeErrorZero()}
                            // onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                            onClickYes={(e) => this.removeError(e)}
                            // image={<img
                            //     src={RemoveIcon}
                            //     style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}
                            size="lg"

                        />
                    </button> */}





                    {/* <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.toggleCustomer}
                    >Manage Customers</Button> */}






                </Col>
                <Col md={12}>
                    <div style={{ margin: "3px 0px 0px 0px" }} >
                        <ColoredLine color="grey" />

                    </div>
                </Col>


                {/* <ColoredLine color="grey" /> */}
            </FormGroup>

            <div>
                <Modal isOpen={modalCustomer} toggle={() => this.toggleCustomer()} size="xl"
                    scrollable={true}
                >
                    <ModalHeader
                        // className={this.getMeterValidationHeaderColor(meterValidation)}
                        toggle={() => this.toggleCustomer()}> Manage Customers</ModalHeader>
                    <ModalBody>


                        <ManageSubform
                            toggle={this.toggleCustomer}
                            subform="customer"
                        //user={this.props.user}
                        // refreshData={this.getMeterValidation}
                        // meterValidationKey = {meterValidationKey}

                        // meterValidation={meterValidation}
                        >

                        </ManageSubform>


                    </ModalBody>

                </Modal>
            </div>

            {/* <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.toggleRevenue}
                    >Manage Revenues</Button> */}

            <div>
                <Modal isOpen={modalRevenue} toggle={() => this.toggleRevenue()} size="xl"
                    scrollable={true}
                >
                    <ModalHeader
                        // className={this.getMeterValidationHeaderColor(meterValidation)}
                        toggle={() => this.toggleRevenue()}> Manage Revenue</ModalHeader>
                    <ModalBody>


                        <ManageSubform
                            toggle={this.toggleRevenue}
                            subform="revenue"
                        //user={this.props.user}
                        // refreshData={this.getMeterValidation}
                        // meterValidationKey = {meterValidationKey}

                        // meterValidation={meterValidation}
                        >

                        </ManageSubform>


                    </ModalBody>

                </Modal>
            </div>

            {/* <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.toggleSite}
                    >Manage Sites</Button> */}

            <div>
                <Modal isOpen={modalSite} toggle={() => this.toggleSite()} size="xl"
                    scrollable={true}
                >
                    <ModalHeader
                        // className={this.getMeterValidationHeaderColor(meterValidation)}
                        toggle={() => this.toggleSite()}> Manage Sites</ModalHeader>
                    <ModalBody>


                        <ManageSubform
                            toggle={this.toggleSite}
                            subform="site"
                        //user={this.props.user}
                        // refreshData={this.getMeterValidation}
                        // meterValidationKey = {meterValidationKey}

                        // meterValidation={meterValidation}
                        >

                        </ManageSubform>


                    </ModalBody>

                </Modal>
            </div>

            {/* <FormGroup>
                    <Row >
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleChange(v, f)} />
                        </Col>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />

                        </Col>

                    </Row>

                    
                </FormGroup> */}
            {/* <FormGroup row>
                <Col md={12}>
                    <div>
                        <Label>

                            ddddd {filenamesListSelected}   </Label>

                    </div>
                </Col>

            </FormGroup> */}

            <FormGroup>

                <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                    Filenames
                </Label>
                <Select options={filenamesList}
                    className="alway-in-between2"
                    isMulti
                    value={filenamesList.filter(rec => filenamesListSelected.includes(rec.fileName))}
                    //  defaultValue ={this.getSelSC(itemDetail[0].spendCategory)}
                    onChange={(e) => this.onTextChange("filenamesList", e, e)}
                    isClearable={true}></Select>



            </FormGroup>
            <div>
                <Modal isOpen={this.state.modalMsg}
                    toggle={() => this.toggleMsg()}
                    size="lg">
                    <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                    </ModalHeader>
                    <ModalBody>
                        {msgBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => this.toggleMsg()}
                        >
                            OK
                        </Button>
                        {' '}

                    </ModalFooter>
                </Modal>
            </div>


            <FormGroup>
                <IgrDataGrid
                    ref={this.onGridRef}
                    height={gridHeight}
                    dataSource={data}
                    // dataSource={safetyInc.filter( rec =>  ((rec.safetyObservationNo || '').toLowerCase().includes(searchText)) ||
                    //                                     (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.qhseReportTypeDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.hazardDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.solutionDesc || '').toLowerCase().includes(searchText) ||
                    //                                     (rec.createdByName || '').toLowerCase().includes(searchText) ||       
                    //                                     (rec.managerName || '').toLowerCase().includes(searchText) ||                                                      
                    //                                     (rec.locationName || '').toLowerCase().includes(searchText))}
                    autoGenerateColumns="false"
                    selectionMode="MultipleRow"
                    selectedKeysChanged={this.onGridRowSelected}
                   
                //cellBackground={"#ffbfbf"}
                >


                    {/* <IgrTextColumn field="oegEnergy" headerText="OEG Energy" width="*>140" /> */}
                    <IgrTemplateColumn field="fileName" headerText="Filename" width="*>250" cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="company" headerText="Company" width="*>120" cellUpdating={this.onStatusCellUpdating} />
                    <IgrTemplateColumn field="currency" headerText="Currency" width="*>120" cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="invoice_Date" headerText="Invoice Date" width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTemplateColumn field="errorComment" headerText="Error Comment" width="*>250" cellUpdating={this.onStatusCellUpdating} />

                    {/* <IgrTextColumn field="errorComment" headerText="Error Comment" width="*>250" /> */}
                    <IgrTextColumn field="customer" headerText="Customer" width="*>120" />
                    <IgrTextColumn field="sales_Item" headerText="Sales Item" width="*>250" />
                    <IgrTextColumn field="revenue_Category" headerText="Revenue Category" width="*>160" />
                    {/* <IgrTextColumn field="line_Item_Description" headerText="Line Item Description" width="*>200" /> */}
                    <IgrTextColumn field="check_Number" headerText="Check Number" width="*>180" />
                    <IgrTemplateColumn field="check_Date" headerText="Check Date" width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdatingNoHighlight} />
                    <IgrNumericColumn field="check_Amount" headerText="Check Amount" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>160" />
                    <IgrTextColumn field="unit_of_Measure" headerText="Unit" width="*>100" />
                    <IgrNumericColumn field="owner_Volume" headerText="Owner Volume" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>160" />
                    <IgrNumericColumn field="owner_Value" headerText="Owner Value" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>140" />
                    <IgrNumericColumn field="owner_Taxes" headerText="Owner Taxes" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>140" />
                    <IgrNumericColumn field="owner_Deducts" headerText="Owner Deducts" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>160" />
                    <IgrNumericColumn field="unit_Cost" headerText="Unit Cost" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>120" />
                    <IgrNumericColumn field="extended_Amount" headerText="Extended Amt" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>150" />
                    <IgrTextColumn field="site" headerText="Site" width="*>140" />
                    <IgrTextColumn field="service_Month" headerText="Service Month" width="*>150" />
                    <IgrTextColumn field="service_Year" headerText="Service Year" width="*>140" />
                    <IgrTextColumn field="cost_Center" headerText="Cost Center" width="*>140" />
                    <IgrTextColumn field="fiscalYrMonth" headerText="Fiscal YrMonth" width="*>150" />
                    <IgrTemplateColumn field="errorCode" headerText="Error Code" width="*>130" cellUpdating={this.onStatusCellUpdating} />

                    <IgrTextColumn field="operator_Name" headerText="Operator Name" width="*>220" />
                    <IgrTextColumn field="owner_Name" headerText="Owner Name" width="*>220" />
                    <IgrTextColumn field="owner_Number" headerText="Owner Number" width="*>180" />
                    <IgrTextColumn field="operator_CC" headerText="Operator CC" width="*>140" />
                    <IgrTextColumn field="partner_CC" headerText="Partner CC" width="*>140" />
                    <IgrTextColumn field="property_Description" headerText="Property Description" width="*>300" />
                    <IgrTextColumn field="product_Code" headerText="Product Code" width="*>160" />
                    <IgrTextColumn field="product_Code_Description" headerText="Product Code Description" width="*>240" />
                    <IgrTextColumn field="interest_Type" headerText="Interest Type" width="*>140" />
                    <IgrNumericColumn field="owner_Percent" headerText="Owner Percent" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>160" />
                    <IgrNumericColumn field="distribution_Percent" headerText="Distribution Percent" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>190" />

                    <IgrTemplateColumn field="prod_Date" headerText="Prod Date" width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdatingNoHighlight} />

                    <IgrNumericColumn field="price" headerText="Price" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>120" />

                    <IgrTextColumn field="btU_Factor" headerText="BtU Factor" width="*>140" />

                    <IgrNumericColumn field="gross_Volume" headerText="Gross Volume" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>150" />
                    <IgrNumericColumn field="gross_Value" headerText="Gross Value" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>140" />
                    <IgrNumericColumn field="gross_Taxes" headerText="Gross Taxes" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>140" />
                    <IgrNumericColumn field="gross_Deducts" headerText="Gross Deducts" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>150" />
                    <IgrNumericColumn field="net_Value" headerText="Net Value" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>120" />
                    <IgrNumericColumn field="owner_Net_Value" headerText="Owner Net Value" showGroupingSeparator="true" minFractionDigits="2" maxFractionDigits="2" width="*>170" />

                    <IgrNumericColumn field="oegEnergy" width="*>170" />


                    {/* <IgrNumericColumn field="oegEnergy" isHidden /> */}
                </IgrDataGrid>
            </FormGroup>


        </div>)
    }




    // closeForm = (refreshData) => {
    //     if(refreshData) {        
    //         this.getData()
    //     }

    //      this.toggle()
    //  }









}