import React, { Component} from 'react';
import {Col, Row, Button, FormGroup, Label} from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPUTHeader, apiDELETEHeader, apiPOSTHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount} from '../../resources/utility'
import NumberFormat from 'react-number-format';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { ColoredLine,  } from '../../resources/utility';
// import { copySync } from 'fs-extra';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();


export default class BasinDetails extends Component {
    static displayName = BasinDetails.name;

    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onColumnResizingAnimationModeChange = this.onColumnResizingAnimationModeChange.bind(this);
        this.state = {
            msgBody: "",
            msgHeader: "",
            modalMsg: false,
            itemBasinDetailsKey: -1,
            basinLinkData: [],
            basinList:[],
            isBasinLoaded:false,
            modalLineAdd: false,
            itemIDKey: props.itemIDKey,
            itemMasterKey : props.itemMasterKey,
            // itemMasterKey:-1,
            isLoaded:false,
            basinData: [],
            locationKeyVal: true,
            windowHeight: window.innerHeight
        }

    }
    onColumnResizingAnimationModeChange = (e, any) => {
        this.grid.columnResizingAnimationMode = e.target.value;
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.basinData;
    }


    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        console.log(fieldName + '=' + fieldValue)
        const {itemMasterKey, basinLinkData } = this.state;

        switch (fieldName) {
            case "locationKey":
                // console.log(e.value)
                basinLinkData.locationKey = (e === null ? null : e.value)

                this.setState({locationKeyVal: fieldValue != null})
                // selSupplierLink.supplierID = (e === null ? null : this.getSelSupplierKey(e))
            //    this.setState({supplierVal : fieldValue != null})
                //validDataItem.itemNumber = true
                break;
            case "stdPurchCost":
                basinLinkData.stdPurchCost = fieldValue
                console.log(itemMasterKey)
                break;
            case "reorderQty":
                basinLinkData.reorderQty = fieldValue
                break;
            case "minQty":
                basinLinkData.minQty = fieldValue
                break;

            case "leadTime":
                basinLinkData.leadTime = fieldValue
                break;
            case "reOrderPoint":
                basinLinkData.reOrderPoint = fieldValue
                break;
             case "createdBy":
                basinLinkData.createdBy = fieldValue
                break;    
            default:

        }

        this.setState({ basinLinkData: basinLinkData})
    }
    
    getBasin = () =>{
        const {itemIDKey} = this.state
        // console.log(itemIDKey +"xxx")
        let myURI = apiURL + 'ItemMaster/ItemBasinDetails/' + itemIDKey


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinData: json,
                    isLoaded: true
                })
            });
    }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }

    getBasinList = () =>{
                    
        let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }




    componentDidMount=()=>{
        this.getBasinList()
        this.getBasin()
    }

    createNewBasinLinkDTO() {
        const { user } = this.props
        const itemDTO = {
            "itemMasterKey": -1,
            "locationKey": null,
            "stdPurchCost": 0.00,
            "reorderQty": 0.00,
            "minQty": 0.00,
            "leadTime": 0,
            "reOrderPoint": 0.00,
            "createdBy":  getUserADAccount(user.account.userName),
        
        }
        // console.log(itemDTO + "vvv")
        return itemDTO
    }

    toggleModalLineAdd = () => {
        const basinLinkData = this.createNewBasinLinkDTO()
        ///this.saveAFELine()
        //console.log("this.state.supList")

        //console.log(this.state.supList)
        // console.log(itemMasterKey)
        this.setState({
            locationKeyVal: true,
            itemBasinDetailsKey: -1,
            modalLineAdd: !this.state.modalLineAdd,
            basinLinkData: basinLinkData
        })
    }

    deleteBasinDataLink=()=>{
        const basinDataLinkDeleteDTO = this.createBasinDataLinkDeleteDTO()
        // console.log(userDeleteDTO)

        let myURI = apiURL + 'ItemMaster/ItemBasinDetails'

        fetch(myURI , apiDELETEHeader(basinDataLinkDeleteDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
            //   console.log("Error return but you dont see")
              const error = (rspData && rspData.message) || response.status;
              return Promise.reject(error);
              
            }
            this.getBasin()
            // console.log("No error here: " + rspData)
          })
          .catch(error =>{
            this.getBasin()
            // console.log("DELETE Error: " + error);
          })

          this.setState({itemBasinDetailsKey: -1})

        
    }

    createBasinDataLinkDeleteDTO=()=>{
        const {basinLinkData} = this.state
        const {user} = this.props

        const supplierLinkDeleteDTO = {
            "ItemBasinDetailsKey": basinLinkData.itemBasinDetailsKey,
            "DeletedBy": getUserADAccount(user.account.userName)
        }
        return supplierLinkDeleteDTO
    }

    closeModal=()=>{
        this.setState({modalLineAdd: !this.state.modalLineAdd})
        this.getBasin()
    }

    
    toggleModalLineEdit = () => {
        this.setState({
            modalLineAdd: !this.state.modalLineAdd,

        },()=>{
            this.getBasin()
            // console.log(itemBasinDetailsKey)
            
        })
    }

    basinDetailsAddAndClose=(closeForm)=>{
        const {basinLinkData} = this.state
        const createBasinDataDTO = this.createBasinDataCreateDTO()
        console.log(createBasinDataDTO)

        
        var cont = true; 
        if (basinLinkData.locationKey === null || basinLinkData.locationKey === ""){
            console.log("This hit")
            this.setState({locationKeyVal : false})
            cont = false
        }
        if (cont){
            let myURI = apiURL + 'ItemMaster/ItemBasinDetails'
            fetch(myURI, apiPOSTHeader(createBasinDataDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        //console.log("Error return but you dont see")
                        const error = (rspData && rspData.message) || response.status;
                        rspData.then(t => {
                            this.setState({
                                msgBody: t,
                                msgHeader: "Error",
                                modalMsg: !this.state.modalMsg
                            })
                        })
                        // console.log(basinData)
                        return Promise.reject(error);
                        
                    }
    
                    console.log("No error here: ")
                    this.getBasin()
                })
                .catch(error => {
                    console.log("POST Error: " + error);
                })
                if(closeForm){
                    this.toggleModalLineAdd()
                } else{
                    const basinDataLink = this.createNewBasinLinkDTO()
                    this.setState({basinLinkData: basinDataLink})
                }
        }

        
    }


    basinDetailsAdd=(closeForm)=>{
        const {basinLinkData} = this.state
        const createBasinDataDTO = this.createBasinDataCreateDTO()
        console.log(createBasinDataDTO)


        var cont = true; 
        if (basinLinkData.locationKey === null || basinLinkData.locationKey === ""){
            console.log("This hit")
            this.setState({locationKeyVal : false})
            cont = false
        }
        if (cont){
        let myURI = apiURL + 'ItemMaster/ItemBasinDetails'
        fetch(myURI, apiPOSTHeader(createBasinDataDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    // console.log(basinData)
                    return Promise.reject(error);
                    
                }

                console.log("No error here: ")
                this.getBasin()
            })
            .catch(error => {
                console.log("POST Error: " + error);
            })
            if(closeForm){
                this.toggleModalLineAdd()
            } else{
                console.log("This hit")
                const basinDataLink = this.createNewBasinLinkDTO()
                this.setState({basinLinkData: basinDataLink})
            }
        }
    }

    supplierLinkEdit = (closeForm) => {

        const updateBasinDataLinkDTO = this.basinDataLinkUpdateDTO()

        let myURI = apiURL + 'ItemMaster/ItemBasinDetails'
        fetch(myURI, apiPUTHeader(updateBasinDataLinkDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                //this.props.refreshData()
                this.getBasin()
                this.toggleModalLineEdit()
            })
            .catch(error => {
                this.getBasin()
                this.toggleModalLineEdit()
                console.log("PUT Error: " + error);
            })
    }

    basinDataLinkUpdateDTO=()=>{
        const {basinLinkData} = this.state
        const {user} = this.props

        const basinDataLinkUpdateDTO = {
            "ItemBasinDetailsKey": basinLinkData.itemBasinDetailsKey,
            "StdPurchCost": basinLinkData.stdPurchCost,
            "ReorderQty": basinLinkData.reorderQty,
            "MinQty": basinLinkData.minQty,
            "LeadTime": basinLinkData.leadTime,
            "ReOrderPoint": basinLinkData.reOrderPoint,
            "ModifiedBy": getUserADAccount(user.account.userName)
        }
        console.log(basinDataLinkUpdateDTO)
        return basinDataLinkUpdateDTO
    }

    createBasinDataCreateDTO = () => {
        const { basinLinkData, itemMasterKey } = this.state
        const { user } = this.props
        const itemUpdateDTO = {
            "ItemMasterKey": itemMasterKey,
            "LocationKey": basinLinkData.locationKey,
            "StdPurchCost": basinLinkData.stdPurchCost,
            "ReorderQty": basinLinkData.reorderQty,
            "MinQty": basinLinkData.minQty,
            "LeadTime": basinLinkData.leadTime,
            "ReOrderPoint": basinLinkData.reorderQty,
            "CreatedBy":  getUserADAccount(user.account.userName),
        }
        console.log(itemMasterKey)
        console.log(itemUpdateDTO)
        return itemUpdateDTO;
    }

    
    
     getSelBasin = (v) => {
        const {basinList} = this.state
        if (v !== null && v !== "") {
            return basinList.filter(e => e.locationKey === v)
        }
        return ""
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        const {itemBasinDetailsKey} = this.state
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            //console.log(dataItem)

            this.setState({
                basinLinkData: dataItem,
                itemBasinDetailsKey: dataItem.itemBasinDetailsKey
            },()=>{
                console.log("itemBasinDetailsKey: " + itemBasinDetailsKey)
            })
            console.log(dataItem)
            console.log(dataItem.itemBasinDetailsKey)
            //this.toggle()
            //this.onVolRefresh()    
        }
    }
    
    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }


    render(){
        const {isLoaded, basinData, basinList, 
            isBasinLoaded, itemBasinDetailsKey, msgBody, msgHeader, 
            basinLinkData, locationKeyVal} = this.state
        // console.log(itemMasterKey)
        if (!isLoaded || !isBasinLoaded) {
            return (<div>
                <h4>Loading basin data.....</h4>
            </div>)
        } else
        return(<div>
         
             <Row form style={{ marginTop: "5px", marginBottom: "2px" }}>
                <Col sm={6}>
             
                    <Button color="primary" outline className="float-left"
                        // disabled={isFormReadOnly}
                        // hidden={isFormReadOnly}
                        style={{ height: "40px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.toggleModalLineAdd()}
                    >New</Button>

                    <Button color="success" outline className="float-left"
                        style={{ height: "40px", marginBottom: "5px", marginLeft: "10px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        // disabled = {itemSupplierLinkKey < 0}
                        onClick={() => this.toggleModalLineEdit()}
                    >Edit</Button>

            
                </Col>
                <Col sm={6}>
                <ConfirmModal outline={true}
                        // disabled={isFormReadOnly || itemSupplierLinkKey < 0}
                        // hidden={isFormReadOnly}
                        color="danger"
                        className="float-right"
                        buttonLabel="Delete"
                        
                        formName="Confirm"
                        // id={itemMasterKey}
                        message="Are you sure you want to unlink selected supplier from selected item?"
                        onClickYes={(e) => this.deleteBasinDataLink()}
                        image={<img
                            src={TrashBin}
                            // id={itemMasterKey}
                            alt="Edit"
                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                    </ConfirmModal>
                </Col>
                
            </Row>
            
           
                        <FormGroup>
                                    <IgrDataGrid
                                        ref={this.onGridRef}
                                        // height={gridHeight}
                                        height='100%'
                                        width = '100%'
                                        dataSource={basinData}
                                        autoGenerateColumns="false"
                                        selectionMode="SingleRow"
                                        scrollable={true}
                                        isColumnOptionsEnabled="true"
                                        filterUIType="FilterRow"
                                        columnResizingMode="Deferred"
                                        columnResizingSeparatorWidth={1}
                                        defaultColumnMinWidth={200}
                                      
                                        // headerClickAction="none"
                                        editMode={0}
                                        selectedKeysChanged={this.onGridRowSelected}
                                        columnResizingAnimationMode="Interpolate"
                                    
                                    //filterUIType="FilterRow"
                                    //cellBackground={"#ffbfbf"}
                                    >
                                        <IgrTextColumn field="locationName" headerText="Location"onChange={this.onColumnResizingAnimationModeChange}
                                    
                                        />
                                        <IgrNumericColumn field="qtyOnHand" headerText="Qty On Hand" minFractionDigits={2}  onChange={this.onColumnResizingAnimationModeChange}
                                           
                            
                                        />
                                         <IgrNumericColumn field="stdPurchCost" headerText="Average Cost"  minFractionDigits={2} positivePrefix="$"onChange={this.onColumnResizingAnimationModeChange}

                                        />
                                        <IgrNumericColumn field="reorderQty" headerText="Reorder Qty" minFractionDigits={2} onChange={this.onColumnResizingAnimationModeChange}

                                        />
                                        <IgrNumericColumn field="minQty" headerText="Minimum Qty"  minFractionDigits={2}onChange={this.onColumnResizingAnimationModeChange}

                                        />
                                       
                                        {/* <IgrTextColumn field="stdPurchCost" headerText="Std Purch Cost" width="*>100"

                                        /> */}
                                        <IgrNumericColumn field="leadTime" headerText="Lead Time (Days)" onChange={this.onColumnResizingAnimationModeChange}

                                        />
                                        <IgrNumericColumn field="reOrderPoint" headerText="Reorder Point"  minFractionDigits={2} onChange={this.onColumnResizingAnimationModeChange}

                                        />
                                        <IgrTextColumn field="createdBy" headerText="Created By" onChange={this.onColumnResizingAnimationModeChange}

                                        />


                                    </IgrDataGrid>
                                </FormGroup>
                


         <div>
         <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader} 
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>

                <Modal 
                isOpen={this.state.modalLineAdd}
                    toggle={() => this.toggleModalLineAdd()}
                    size="md">
                    <ModalHeader 
                    toggle={() => this.toggleModalLineAdd()}
                    > 
                    {/* {msgHeader} */}
                        {itemBasinDetailsKey < 0 ? " Basin Link Add" : "Basin Link Edit"}

                    </ModalHeader>

                    <ModalBody>
                        {/* {msgBody} */}
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label  > Location Name </Label>

                                    <Select
                                    // className = {!supplierVal ? "form-error" : ""}
                                    className = {!locationKeyVal ? "form-error" : ""}
                                        options={basinList}
                                        isDisabled={itemBasinDetailsKey > -1}
                                        // defaultValue={this.getSelSup(selSupplierLink.supplierID)}
                                        value={this.getSelBasin(basinLinkData.locationKey)}
                                        onChange={(e) => this.onTextChange("locationKey", e, e)}
                                        // isDisabled= {itemSupplierLinkKey > -1}
                                        isClearable={true}>

                                    </Select>
                                </FormGroup>
                            </Col>


                        </Row>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="supplierPartNumber" > Average Cost </Label>
                                    <NumberFormat 
                                        className="form-control text-md-right"
                                        //disabled={this.isFormReadOnly()}
                                        id="Std Purch Cost"
                                        value={basinLinkData.stdPurchCost}
                                        // defaultValue={selSupplierLink.supplierPartNumber}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        onValueChange={(e) => this.onTextChange("stdPurchCost", e.value, e)}
                                        prefix={'$'} 
                                    />

                                </FormGroup>
                            </Col>

                        </Row>
                        <Row form>

                        <Col md={6}>
                                <FormGroup>
                                    <Label>Qty on hand </Label>
                                    <NumberFormat 
                                        className="form-control text-md-right"
                                        //disabled={this.isFormReadOnly()}
                                        disabled
                                        id="Qty on hand" 
                                        value={basinLinkData.qtyOnHand}
                                        // defaultValue={selSupplierLink.supplierPartNumber}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        // onValueChange={(e) => this.onTextChange("reorderQty", e.value, e)}
                                    />

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Reorder Qty </Label>
                                    <NumberFormat 
                                        className="form-control text-md-right"
                                        //disabled={this.isFormReadOnly()}
                                        id="Std Purch Cost" 
                                        value={basinLinkData.reorderQty}
                                        // defaultValue={selSupplierLink.supplierPartNumber}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        onValueChange={(e) => this.onTextChange("reorderQty", e.value, e)}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>



                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="minQty">Min Qty</Label>

                                    <NumberFormat
                                        value={basinLinkData.minQty}
                                        onValueChange={(e) => this.onTextChange("minQty", e.value, e)}
                                        className='form-control text-md-right'
                                        fixedDecimalScale={true}
                                        decimalScale={2}                                       
                                        thousandSeparator={true}
                                    //prefix={'$'} 
                                    />

                                </FormGroup>
                            </Col>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="reorderQty">Reorder Point</Label>

                                    <NumberFormat
                                        value={basinLinkData.reOrderPoint}
                                        onValueChange={(e) => this.onTextChange("reOrderPoint", e.value, e)}
                                        className='form-control text-md-right'
                                        fixedDecimalScale={true}
                                        decimalScale={2}                                       
                                        thousandSeparator={true}
                                    //prefix={'$'} 
                                    />

                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="leadTime">Lead Time</Label>
                                             <NumberFormat 
                                                         className='form-control text-md-right'
                                                            rows={4}
                                                            value={basinLinkData.leadTime}
                                                            // defaultValue={lineData.lineComment}
                                                            //disabled={isFormReadOnly}
                                                            id="leadTime" 
                                                            // placeholder="leadTime"
                                                
                                                            onValueChange={(e) => this.onTextChange("leadTime", e.value, e)}
                                                        />

                                </FormGroup>
                            </Col>


                        </Row>

                        {/* <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="comments">Comments</Label>

                                    <Input type="textarea" name="lineComments"
                                        rows={3}
                                        //disabled={this.isFormReadOnly()}
                                        id="lineComments" placeholder="Comments"
                                        // value={selSupplierLink.comments}
                                        // defaultValue={selSupplierLink.comments}
                                        onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                                    />

                                </FormGroup>
                            </Col>

                        </Row> */}
                        <ColoredLine color="grey" />
                        <FormGroup>
                            <Row style={{ margin: "15px 0 0 0" }}>
                                <Col sm={12}>
                                    <Button outline
                                        color="warning" className="float-right"
                                        onClick={() => this.closeModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button outline
                                        color="primary" className="float-right"
                                        style={{ margin: "0px 10px 0px 0px" }}
                                        onClick={() => this.basinDetailsAddAndClose(true)}
                                        hidden = {itemBasinDetailsKey > 0}
                                        // disabled={console.log(itemBasinDetailsKey)}
                                    >

                                        {"Add & Close"}
                                    </Button>

                                    <Button outline  //disabled={this.isLineAddButtonDisable()}
                                        color="primary" className="float-left"
                                        onClick={() => this.basinDetailsAdd(false)}
                                        hidden = {itemBasinDetailsKey > 0}
                                    >
                                        Add
                                    </Button>

                                    <Button outline  //disabled={this.isLineAddButtonDisable()}
                                        color="primary" className="float-left"
                                        onClick={() => this.supplierLinkEdit(false)}
                                        hidden = {itemBasinDetailsKey < 0}
                              
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>

                        </FormGroup>
                    </ModalBody>
                </Modal>
                
            </div>

     


        </div>)
    }

}