import React,{Component} from 'react';
import { Col, CustomInput,Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';

import {apiURL, apiHeader} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

import InspectionDashBoardComment from '../operation/InspectionDashBoardComment'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class InspectionDashBoardLink extends Component {
    static displayName = InspectionDashBoardLink.name;

    constructor(props){
        super(props)
        this.state = {
            // activeTab:'1',
            activeTab:this.getActiveTab(this.props.selectValue),
            modal:false,
            user:this.props.user,
            selectedValue:this.props.selectValue,
            startDate: this.props.startDate,
            endDate: this.props.endDate
        }
    }

    getActiveTab = (v) => {
        if (v!==undefined){      
            if (!this.props.selectValue.hasSubArea){
                if (v.level == 1 || v.level == 3) 
                    return '3'
                else
                    return v.level + 1    
            }else                 
                return v.level + 1
        }else {return '1'}
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    componentWillReceiveProps = () =>
    {
        const tab=this.getActiveTab(this.state.selectedValue)
        console.log("tab")
        console.log(tab)
        this.setState({activeTab: tab.toString()})
    }

    render() {
        const {activeTab,selectedValue,user,startDate,endDate} = this.state

        // console.log("selectedValue")
        // console.log(selectedValue)

        return (<div>
                <FormGroup> 
                <Nav tabs>
                        <NavItem hidden={(selectedValue.level>0)}>
                            <NavLink className={classnames({active: activeTab === '1'})}
                                      onClick={() => {this.toggle('1')}}>      
                                Area                           
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={(selectedValue.level>1) || selectedValue.key===null || !selectedValue.hasSubArea} >
                            <NavLink className={classnames({active: activeTab === '2'})}
                                      onClick={() => {this.toggle('2')}}>
                                Sub Area
                            </NavLink>
                        </NavItem>
                      
                        <NavItem hidden={(selectedValue.level>3)}>
                            <NavLink className={classnames({active: activeTab === '3'})}
                                      onClick={() => {this.toggle('3')}}
                                      >
                               Facility
                            </NavLink>
                        </NavItem>
                        
                                      
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="3"> 
                        <InspectionDashBoardComment user={this.props.user}
                                                          selectedValue={selectedValue}
                                                          startDate={startDate}
                                                          endDate={endDate}
                                                          linkDataLevel={3}/>
                        </TabPane>

                        <TabPane tabId="2">
                        <InspectionDashBoardComment user={this.props.user}
                                                          selectedValue={selectedValue}
                                                          startDate={startDate}
                                                          endDate={endDate}
                                                          linkDataLevel={2}/>
                        </TabPane>

                        <TabPane tabId="1">
                            {/* sfsfsfsf
                        Area Inpsection Comment */}
                        <InspectionDashBoardComment user={this.props.user}
                                                          selectedValue={selectedValue}
                                                          startDate={startDate}
                                                          endDate={endDate}
                                                          linkDataLevel={1}/>
                        </TabPane>
                        

                    </TabContent>
                </FormGroup>
        </div>)
    }
}