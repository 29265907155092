import React, { Component } from 'react';

import { Alert, Form, Button } from 'reactstrap';

import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';


import { USDCurrencyFormat, ColoredLine, getUserADAccount } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


export default class AFEApprovalHistory extends Component {
    static displayName = AFEApprovalHistory.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);

        this.state = {
            approvalArchived: [],
            afeHeaderKey: props.afeHeaderKey,
            isLoaded: false
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.approvalArchived;
    }

    getApprovalHistory = () => {
        const { afeHeaderKey } = this.state

        let myURI = apiURL + 'AFE/approval/Archived/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    approvalArchived: json
                })
            });
    }

    componentDidMount() {

        window.addEventListener('load', this.onLoad);
        this.getApprovalHistory()
    }

    render() {
        const { approvalArchived, isLoaded } = this.state

        if (!isLoaded) {
            return (<div>Loading Costing Data .......</div>)
        } else
            return (<div>
                <IgrDataGrid
                    ref={this.onGridRef}
                    height="400px"
                    width='100%'
                    dataSource={approvalArchived}
                    isColumnOptionsEnabled="false"
                    autoGenerateColumns="false"
                    selectionMode="SingleRow"
                    editModeClickAction="SingleClick"
                    editMode={0}

                >

                    <IgrTemplateColumn
                        field="revision"
                        headerText="Rev"
                        width="90"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTemplateColumn
                        field="actionID"
                        headerText="ID"
                        width="70"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTemplateColumn
                        field="actionDesc"
                        headerText="Approval Desc"
                        width="250"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTemplateColumn
                        field="lineStatusDesc"
                        headerText="Status"
                        width="100"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTemplateColumn
                        field="isRequired"
                        headerText="Required"
                        width="120"
                        cellUpdating={this.onCellUpdating}
                    />


                    <IgrTemplateColumn
                        field="actionDate"
                        headerText="Approval Date"
                        width="120"
                        cellUpdating={this.onCellUpdating}
                    />
                    <IgrTemplateColumn
                        field="modifiedByName"
                        headerText="Approval By"
                        width="120"
                        cellUpdating={this.onCellUpdating}
                    />
                    <IgrTextColumn
                        field="displayMaxAmount"
                        headerText="Max Approval"
                        width="120"
                    //  cellUpdating={this.onCellUpdating}
                    />
                    <IgrNumericColumn
                        field="minAmount"
                        headerText="Min"
                        width="120"
                        isHidden
                        positivePrefix="$"
                        showGroupingSeparator="true"
                    //cellUpdating={this.onCellUpdating}

                    />

                    {/* <IgrNumericColumn
                        field="maxAmount"
                        headerText="Max"
                        width="*>120"
                        positivePrefix="$"
                        showGroupingSeparator="true"
                    // cellUpdating={this.onCellUpdating}
                    /> */}
                    <IgrTextColumn
                        field="comments"
                        headerText="Approval Comments"
                        width="*>350"
                    //cellUpdating={this.onStatusCellUpdating}
                    />
                    {/* <IgrTextColumn field="codeDefinitionKey" headerText="Item Number"
                                                        /> */}

                    <IgrNumericColumn field="lineActionKey" isHidden />
                </IgrDataGrid>

                <ColoredLine color="grey" />

                <div>
                    <Form>

                        {/* <Button
                            color="danger"
                            className='float-right'
                            onClick={() => this.props.toggleModalAppHist()}
                            
                            style={{marginTop:"5px"}}
                        >
                            Cancel
                        </Button> */}

                        <Button
                            color="primary"
                            className='float-right'
                            // disabled={showAlert}
                            onClick={() => this.props.toggleModalAppHist()}
                            style={{ marginTop: "5px", marginRight: "20px" }}
                        >
                            OK
                        </Button>
                        {' '}
                    </Form>
                </div>
            </div>)
    }


    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.lineStatusKey === 3) {
                //content.style.background = "#f0f5b0";
                content.style.background = "transparent";
            } else if (item.lineStatusKey === 6) {
                content.style.background = "#b8f2b1";
            } else if (item.lineStatusKey === 0) {
                content.style.background = "#fc4e42";
            } else {
                content.style.background = "transparent";
            }
        }

        switch (s.field) {
            case 'revision':
                link.textContent = item.revision;
                break;
            case 'actionDesc':
                link.textContent = item.actionDesc;
                break;
            case 'actionID':
                link.textContent = item.actionID;
                break;
            case 'actionDate':
                if (item.lineStatusKey === 6 || item.lineStatusKey === 1) {

                    var date = new Date(item.actionDate);
                    link.textContent = date.toLocaleDateString();

                }
                break;
            case 'lineStatusDesc':
                link.textContent = item.lineStatusDesc;
                break;
            case 'isRequired':
                link.textContent = item.isRequired;
                break;
            case 'modifiedByName':
                link.textContent = item.modifiedByName;
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'managerName':
                link.textContent = item.managerName;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            default:

        }

    }

}