import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';


export default class AFEFieldReport extends Component {
    static displayName = AFEFieldReport.name;

    constructor(props){
        super(props)


    }

    render(){
        return(<div>
             <ColoredLine color="grey" />
            <h2>AFE FEILD REPORT SECTION ------------------------------------------------------</h2>
        </div>)
    }
}