//Ready for to Push to Master from local Master 10/14/21


import React, { Component } from 'react';

import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, 
        FormFeedback, InputGroup } from 'reactstrap';
import { apiURL, apiHeader,apiPUTHeader,apiPOSTHeader,apiDELETEHeader } from '../resources/apiURL';

import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import ImageViewer from '../resources/ImageViewer';
import ConfirmModal from '../operation/ConfirmModal'

var DatePicker = require("reactstrap-date-picker");

export default class SecurityCheckpointAdminDetails extends Component {
    static displayName = SecurityCheckpointAdminDetails.name;

    constructor(props){
        super(props)

        this.state={
            secDetails: props.securityDetails,
            isLoaded:false,
            applicationName: [],
            invalidDataItem:{
                applicationKey: true,
                appSecurityName: true,
                appSecurityDesc: true
            }
        }
    }

    closeForm = (refreshUserData) => {
        if (refreshUserData){
            this.props.refreshUserData()
        } 
         this.props.toggle()
     }

     saveData= ()=>{
        const {secDetails} = this.state   
        if(this.validateDate()){           
            if (secDetails.applicationSecurityKey<0){
                this.createSecurity()
            }else{
                this.updateSecurity() 
                // console.log("Trying to save")
            }
            // this.closeForm(true)
         }   
        }

        createSecurity(){
            const {secDetails} = this.state   
            // console.log("Create user in user details")
            const securityCreateDTO= this.createSecurityCreateDTO()
            // console.log(securityCreateDTO)
          
    
              let myURI = apiURL + 'SecAdmin/SecCheckPoint'
              fetch(myURI , apiPOSTHeader(securityCreateDTO) )
                .then(async response => {
                  const rspData = response.text();
                  if (!response.ok){
                    // console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);
                    
                  }
                  this.closeForm(true)
                //   console.log("No error here: " + rspData)
                })
                .catch(error =>{
                    this.closeForm(true)
                //   console.log("POST Error: " + error);
                })
    
        }

        updateSecurity = () => {
            const {empDetails} = this.state   
            // console.log("Save user in user details")
            const securityUpdateDTO= this.createSecurityUpdateDTO ()
            // console.log(securityUpdateDTO)
          
    
              let myURI = apiURL + 'SecAdmin/SecCheckPoint'
              fetch(myURI , apiPUTHeader(securityUpdateDTO) )
                .then(async response => {
                  const rspData = response.text();
                  if (!response.ok){
                    // console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);
                    
                  }
          
                //   console.log("No error here: " + rspData)
                })
                .catch(error =>{
                //   console.log("PUT Error: " + error);
                })
                
        }

        deleteUser = () => {
            const securityDeleteDTO = this.createSecurityDeleteDTO ()
            // console.log(securityDeleteDTO)
    
            let myURI = apiURL + 'SecAdmin/SecCheckPoint'
    
            fetch(myURI , apiDELETEHeader(securityDeleteDTO) )
              .then(async response => {
                const rspData = response.text();
                if (!response.ok){
                //   console.log("Error return but you dont see")
                  const error = (rspData && rspData.message) || response.status;
                  return Promise.reject(error);
                  
                }
        
                // console.log("No error here: " + rspData)
              })
              .catch(error =>{
                // console.log("DELETE Error: " + error);
              })
    
            this.closeForm(true)
        }

        getApplicationName = () =>{
            let myURI = apiURL + 'SecAdmin/Application' 
            // /' + user.account.userName
            
            fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                this.setState({
                    isLocationLoaded: true,
                    applicationName: json
                    
                })
            })
        }
    

        createSecurityCreateDTO =()=>{
            const {secDetails} = this.state
            const {user} = this.props
            const securityCreateDTO={
                "applicationKey": secDetails.applicationKey,
                "applicationName": secDetails.applicationName,
                "appSecurityName": secDetails.appSecurityName,
                "appSecurityDesc": secDetails.appSecurityDesc,
                "createdBy": getUserADAccount(user.account.userName)
            }
            return securityCreateDTO
        }

        createSecurityUpdateDTO =()=>{
            const {secDetails} = this.state
            const {user} = this.props
            const securityUpdateDTO={
                "applicationSecurityKey": secDetails.applicationSecurityKey,
                "applicationKey": secDetails.applicationKey,
                "applicationName": secDetails.applicationName,
                "appSecurityName": secDetails.appSecurityName,
                "appSecurityDesc": secDetails.appSecurityDesc,
                "modifiedBy": getUserADAccount(user.account.userName)
            }
            return securityUpdateDTO
        }

        createSecurityDeleteDTO =()=>{
            const {secDetails} = this.state 
            const {user} = this.props
            const securityDeleteDTO={
                "applicationSecurityKey": secDetails.applicationSecurityKey,
                "deletedBy": getUserADAccount(user.account.userName)
            }
            return securityDeleteDTO;
        }

        
    validateDate = () => {
        const {invalidDataItem,secDetails} = this.state 
        var bVal = true 
        var valItem = invalidDataItem
  
        if (secDetails.applicationKey == 0) {
            bVal = false
            valItem.applicationKey= false
        }
  
        if (secDetails.appSecurityName== ""){
            bVal = false
            valItem.appSecurityName= false
        }
        if (secDetails.appSecurityDesc== ""){
            bVal = false
            valItem.appSecurityDesc= false
        }
        this.setState({invalidDataItem:valItem})

        // console.log("bVal")        
        // console.log(invalidDataItem)
        return bVal
    }


        onTextChange=(fieldName,fieldValue,e)=>{
            // console.log("props")
            // console.log(fieldName + '=' + fieldValue)
            const {secDetails,invalidDataItem}=this.state;
            // if (fieldName==="ticketNo"){
                
            // }       
            
            switch(fieldName){
                case "applicationKey":
                    secDetails.applicationKey=fieldValue
                    invalidDataItem.applicationKey=true
                    break;
                // case "applicationName":
                //     secDetails.applicationName=fieldValue
                //     invalidDataItem.applicationName=(fieldValue != "")
                //     break;
                case "appSecurityName":
                    secDetails.appSecurityName = fieldValue
                    this.setState({reportDate:fieldValue})
                    invalidDataItem.appSecurityName=(fieldValue != "")
                    break;
                case "appSecurityDesc":
                    secDetails.appSecurityDesc=fieldValue
                    invalidDataItem.appSecurityDesc=(fieldValue != "")
                    break;
                case "createdBy":
                    secDetails.createdBy=fieldValue
                    break;
                case "applicationSecurityKey":
                    secDetails.applicationSecurityKey=fieldValue
                    break;
               
    
                default:
    
            }
            
            this.setState({secDetails:secDetails})
        }
        
    componentDidMount(){
        
        this.getApplicationName();
        //this.getUserData();
        // this.getUserLocation();
    }
        render(){
            const {secDetails, userEmployee,location, isLocationLoaded, applicationName, invalidDataItem}=this.state

            return (<div>
                <Form>
    
                <FormGroup>
                <Row form>
                        {/* <Col md={6}>
                            <FormGroup>
                                <Label for="applicationKey">Application Key</Label>
                                <Input type="text" name="applicationKey" id="applicationKey" 
                                 defaultValue={secDetails.applicationKey}
                                 placeholder="" 
                                 onChange={(e) => this.onTextChange("applicationKey",e.target.value,e)} 
                                  />
                            </FormGroup>
                        </Col> */}

                        <Col md={12}>
                            <FormGroup>
                                <Label for="applicationName">Application Name</Label>
                                <CustomInput  type="select" name="applicationName" id="applicationName" 
                                defaultValue={secDetails.applicationKey}
                                invalid={!invalidDataItem.applicationKey}
                                value={secDetails.applicationKey}
                                placeholder=""
                                onChange={(e) => this.onTextChange("applicationKey",e.target.value,e)}> 
                                <option value={-100}></option> 
                                {applicationName.map(v => <option value={v.applicationKey}>{v.applicationName}</option>)} 
                                </CustomInput>
                                <FormFeedback tooltip > Please select Application Name</FormFeedback>
                            </FormGroup>
                        </Col>
                </Row>

                <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="appSecurityName">Application Security Name</Label>
                            <Input type="text" name="appSecurityName" id="appSecurityName" 
                            invalid={!invalidDataItem.appSecurityName}
                            defaultValue={secDetails.appSecurityName}
                            placeholder=""
                            onChange={(e) => this.onTextChange("appSecurityName",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Application Security Name</FormFeedback>
                        </FormGroup>
                    </Col>
            
            </Row>
                
            <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="appSecurityDesc">Application Security Description</Label>                         
                            <Input type="text" name="appSecurityDesc" id="appSecurityDesc" 
                            invalid={!invalidDataItem.appSecurityDesc}
                            defaultValue={secDetails.appSecurityDesc}
                            placeholder=""
                            onChange={(e) => this.onTextChange("appSecurityDesc",e.target.value,e)} />
                            <FormFeedback tooltip > Please enter Application Security Description</FormFeedback>
                        </FormGroup>
                    </Col>
            </Row>

            {/* <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="createdBy">Created By</Label>
                            <Input type="text" name="createdBy" id="createdBy" 
                            defaultValue={secDetails.createdBy}
                            placeholder=""
                            onChange={(e) => this.onTextChange("createdBy",e.target.value,e)} />
                        </FormGroup>
                    </Col>
            </Row> */}
    
    
           
    
               
    
    
    
    
    
    
    {/* Edited by --BN 9/19/21 */}
                        <Row>
                        <Col md={3}>
    
                      
                         </Col>                    
                        <Col md={3}>
                            <ConfirmModal outline={true}
                                      color="danger"
                                      hidden= {!(secDetails.applicationSecurityKey>0)}
                                      className="float-left"
                                      buttonLabel="Delete"
                                     // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                      formName="Confirm"
                                     // id={facilityOilTicketKey}
                                      message="Are you sure you want to delete selected appliation security record?"
                                      onClickYes={(e) => this.deleteUser()}
                                      image={<img
                                        src={TrashBin}
                                       // id={facilityOilTicketKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }}/>}>
                            </ConfirmModal>    
    
                        </Col>
    
                         <Col sm={6}>
                         <Button color="primary" className="float-right" 
                                 onClick={() => this.closeForm(true)}>Cancel</Button>
                         <Button color="primary"className="float-right"
                                 style={{margin:"0px 10px 0px 0px"}}
                                 onClick={()=> this.saveData()}>Save
                                 </Button>
                         </Col>
                         </Row>
                    </FormGroup>
                </Form>
    
     
    
        
               
    
                
            </div>)
            
        }
    
}