
import * as React from 'react';
import { LocalDataSource } from 'igniteui-react-core';
import { SortDescription } from 'igniteui-react-core';
import { IgrColumnSortDescriptionCollection } from 'igniteui-react-grids';
import { IgrColumnGroupDescriptionCollection } from 'igniteui-react-grids';
import { IgrFilterExpressionCollection } from 'igniteui-react-core';
import '../resources/DataGridPager.css';
import '../index.css'

/**
 * This component is designed to be used in conjunction with the IgrDataGrid component
 * to provide a small paged view of available data.
 */
export class DataGridPager extends React.Component {
    /**
     * Constructs a new Pager component.
     * @param props The properties for the Pager.
     */
    constructor(props) {
        super(props);
        /**
         * Defining a version of the component's state property with the
         * DataGridPagerState interface.
         */
        this.state = {
            dataSource: new LocalDataSource(),
            pageCount: 0,
            pageNumber: 1,
            sortCount: 0,
            groupCount: 0,
            filterCount: 0
        };
        /**
         * Click handler for the first page button.
         */
        this.firstPage = () => {
            this.setState({ pageNumber: 1 });
        };
        /**
         * Click handler for the previous page button.
         */
        this.previousPage = () => {
            this.setState({ pageNumber: Math.max(this.state.pageNumber - 1, 1) });
        };
        /**
         * Click handler for the next page button.
         */
        this.nextPage = () => {
            this.setState({ pageNumber: Math.min(this.state.pageNumber + 1, this.state.pageCount) });
        };
        /**
         * Click handler for the last page button.
         */
        this.lastPage = () => {
            this.setState({ pageNumber: this.state.pageCount });
        };
        /**
         * Quick helper to determine if one of the paging buttons is disabled or not.
         */
        this.isButtonDisabled = (which) => {
            switch (which) {
                case "left": return this.state.pageNumber <= 1;
                case "right": return this.state.pageNumber === this.state.pageCount;
                default: return false;
            }
        };
        // Don't want to emit these in our internal data source.
        this.state.dataSource.shouldEmitSectionFooters = false;
        this.state.dataSource.shouldEmitSectionHeaders = false;
        this.state.dataSource.shouldEmitShiftedRows = false;
    }
    componentDidMount() {
        // data was provided initially so update the pager state
        if (this.props.dataSource.length > 0) {
            // this.state.dataSource.dataSource = this.props.dataSource;
            let ds = this.state.dataSource;
            ds.dataSource = this.props.dataSource;
            const count = Math.round(this.props.dataSource.length / this.props.pageSize);
            this.setState({ pageCount: count, pageNumber: 1, dataSource: ds });
        }
    }
    componentDidUpdate(previousProps, previousState) {
        // property changes
        if (previousProps.dataSource !== this.props.dataSource) {
            // this.state.dataSource.dataSource = this.props.dataSource;
            let ds = this.state.dataSource;
            ds.dataSource = this.props.dataSource;
            // new data so calculate a new page count and update the state.
            const count = Math.round(this.state.dataSource.actualCount / this.props.pageSize);
            this.setState({ pageCount: count, pageNumber: 1, dataSource: ds });
        }
        // if any of these state properties changed then we need to provide new data.
        if (previousState.pageNumber !== this.state.pageNumber ||
            previousState.pageCount !== this.state.pageCount ||
            previousState.sortCount !== this.state.sortCount ||
            previousState.groupCount !== this.state.groupCount ||
            previousState.filterCount !== this.state.filterCount) {
            const pageData = this.getPage(this.state.pageNumber);
            if (pageData.length > 0) {
                this.notifyPageChanged(this.state.pageNumber, pageData);
            }
        }
    }
    render() {
        const leftSideBtnClasses = "icon-button " + (this.isButtonDisabled("left") ? "icon-button-disable" : "");
        const rightSideBtnClasses = "icon-button " + (this.isButtonDisabled("right") ? "icon-button-disable" : "");
        return (React.createElement("div", { className: "pager-root" },
            React.createElement("div", { className: leftSideBtnClasses, onClick: this.firstPage },
                React.createElement("i", { className: "material-icons" }, "first_page")),
            React.createElement("div", { className: leftSideBtnClasses, onClick: this.previousPage },
                React.createElement("i", { className: "material-icons" }, "chevron_left")),
            React.createElement("div", { className: "pager-text" },
                this.state.pageNumber,
                " of ",
                this.state.pageCount),
            React.createElement("div", { className: rightSideBtnClasses, onClick: this.nextPage },
                React.createElement("i", { className: "material-icons" }, "chevron_right")),
            React.createElement("div", { className: rightSideBtnClasses, onClick: this.lastPage },
                React.createElement("i", { className: "material-icons" }, "last_page"))));
    }
    /**
     * Update the pager's internal data source with sorting.
     * @param sortDescriptions The latest sort descriptions to apply.
     */
    applySorts(sortDescriptions) {
        this.state.dataSource.sortDescriptions.clear();
        for (let i = 0; i < sortDescriptions.count; i++) {
            const sd = sortDescriptions.item(i);
            this.state.dataSource.sortDescriptions.add(SortDescription.create(sd.field, sd.sortDirection));
        }
        this.update(true);
    }
    /**
     * Update the pager's internal data source with grouping.
     * @param groupDescriptions The latest group descriptions to apply.
     */
    applyGroups(groupDescriptions) {
        this.state.dataSource.groupDescriptions.clear();
        for (let i = 0; i < groupDescriptions.count; i++) {
            const gd = groupDescriptions.item(i);
            this.state.dataSource.groupDescriptions.add(SortDescription.create(gd.field, gd.sortDirection));
        }
        this.update(true);
    }
    /**
     * Update the pager's internal data source with filtering.
     * @param filterExpressions The latest filters to apply.
     */
    applyFilters(filterExpressions) {
        this.state.dataSource.filterExpressions.clear();
        for (let i = 0; i < filterExpressions.size(); i++) {
            const fe = filterExpressions.get(i);
            this.state.dataSource.filterExpressions.add(fe);
        }
        this.update(true);
    }
    /**
     * Notifies about what the data is for the current page.
     * @param pageNumber The page number to report.
     * @param data The data for the current page.
     */
    notifyPageChanged(pageNumber, data) {
        if (this.props.pagedChanged) {
            this.props.pagedChanged(pageNumber, data);
        }
    }
    /**
     * Helper function to get data for a specific page.
     * @param pageNumber The page to get data for.
     */
    getPage(pageNumber) {
        const data = [];
        if (this.state.dataSource) {
            const pageStart = (pageNumber - 1) * this.props.pageSize;
            for (let i = pageStart; i < pageStart + this.props.pageSize; i++) {
                if (i > this.state.dataSource.actualCount - 1) {
                    break;
                }
                const item = this.state.dataSource.getItemAtIndex(i);
                data.push(item);
            }
        }
        return data;
    }
    /**
     * Updates the pager and notifies about the data for the current page.
     * @param flush Whether to flush the internal LocalDataSource or not.
     * @param notify Whether this update needs to notify or not.
     */
    update(flush) {
        if (flush) {
            // flushing the data source is required when adding filter, sort and group objects to it
            // so they immediately get processed.  Otherwise we'll continue working with old data.
            this.state.dataSource.flushAutoRefresh();
        }
        const count = Math.round(this.state.dataSource.actualCount / this.props.pageSize);
        const page = Math.max(Math.min(this.state.pageNumber, count), 1);
        this.setState({
            pageCount: count,
            pageNumber: page,
            sortCount: this.state.dataSource.sortDescriptions.size(),
            groupCount: this.state.dataSource.groupDescriptions.size(),
            filterCount: this.state.dataSource.filterExpressions.size()
        });
    }
}