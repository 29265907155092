//Ready for to Push to Master from local Master 10/14/21

import React, { Component, useEffect, useState} from 'react';
import {Col, Row, Button, FormGroup, Label} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import FetchData from '../resources/FetchData';

import {apiURL, apiHeader, apiPOSTHeader, apiDELETEHeader} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import RefreshIcon from '../resources/refresh.svg';
import PlusIcon from '../resources/plus.svg';
import TrashIcon from '../resources/trash.svg';
import ExcelIcon from '../resources/excel.svg';
import '../custom.css';


import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { dateToday, ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from '../operation/ExcelUtility';
import UserGroupDetails from './UserGroupDetails';
import UserDetails from './UserDetails';
import { ColoredLine, getFirstDayofMonth,useWindowDimensions } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import { getUserADAccount,getRandomInt } from '../resources/utility'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();




const UserGroupUsers = (props) => {
   const [isLoaded,setIsLoaded] = useState(false)
   const [users, setUsers] = useState([]) 
   const [modalUser, setModalUser] = useState(false)
   const [userGroupEmployeeLinkKey, setUserGroupEmployeeLinkKey] = useState([])
   const [user, setUser]= useState(props.userInput)
   const [modalUserGroup, setModalUserGroup] = useState(false)
   const [userGroupKey, setUserGroupKey] = useState(props.userGroupKey)
   const [employeeDetails, setEmployeeDetails] = useState(props.employeeDetails)
   //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
    //var users=[]

    
   function toggleModalUserGroup() {  
        // console.log("Modal: " + this.state.modal)
        // this.setState({modalUserGroup: !this.state.modalUserGroup})
        setModalUserGroup(!modalUserGroup)
    }


//    function toggleModalUser() {  
//         // console.log("Modal: " + this.state.modal)
//         // this.setState({modalUser: !this.state.modalUser})
//         // setModalUser(!modalUser);
//         props.toggleModalUser()
//     }

   function addUserGroupEmployeeLink(){
        // let newGroup = this.createUserGroupEmployeeLinkForm()
        // this.setState({groupDetails:newGroup})
        
        toggleModalUserGroup()
    }

    function refreshUserData(){
        const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/Employee'
        // console.log("UserGroupUsers ")       
        // console.log(myURL)
    
        fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                setIsLoaded(true);
                setUsers(json);
            })
        
    }

    const deleteEmployeeFromGroup = () => {   
        // console.log("Save user in user details")
        const deleteEmployeeFromGroupDTO= deleteEmployeeFromGroupSelect()
        // console.log(deleteEmployeeFromGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/Employee'
          fetch(myURI , apiDELETEHeader(deleteEmployeeFromGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
              refreshUserData()
            //   console.log("No error here: " + rspData)
            })
            .catch(error =>{
                refreshUserData()
            //   console.log("PUT Error: " + error);
            })       
    }

    const deleteEmployeeFromGroupSelect=()=>{
        var tempDTO=[];
        var i=0;
        userGroupEmployeeLinkKey.map(function (line) {
           const employeeFromGroupDelete = {
               "userGroupEmployeeLinkKey": line,
               "deletedBy": getUserADAccount(user.account.userName)

           }
           tempDTO[i] = employeeFromGroupDelete ;
           i = i + 1
        });
       
       return tempDTO;
   }


   function onGridRowSelected(s, e) {
    // console.log(" On CLick")
    //const content = e.content;
    // if (e.addedKeys.count > 0) {
        // let clickedRow = e.addedKeys.item(0) 
        // let dataItem = clickedRow.value[0] 
        // console.log(dataItem)
        
        // if (!this.state.hideAssociateDetails){
            // this.setState({userGroupKey: dataItem.userGroupKey,
            //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
        // }

        const tempList=[] 
        let clickedRows = e.currentKeys
        
        for (let i = 0; i < e.currentKeys.count; i++){
        //    console.log(e.currentKeys.item(i).value[0] )
            tempList.push(e.currentKeys.item(i).value[0].userGroupEmployeeLinkKey) ; 
        }
        
        // selectedUserGroupChanged(dataItem.employeeKey)
        // console.log(tempList)
        setUserGroupEmployeeLinkKey(tempList)
        // setEmployeeKey(tempList);
       
        // console.log("hello")
    // }
    
       
}

   useEffect(() =>{
    
    const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/Employee'
    // console.log("UserGroupUsers ")       
    // console.log(myURL)

    fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            setIsLoaded(true);
            setUsers(json);
        })
        .catch(error => {
            console.log("GET Error: " + error);
        })
    },[props.UserGroupKey])

    if (!isLoaded){
        return(<div>
            <h4> loading users list ....</h4>
        </div>)
    }else
    return(<div>
       <FormGroup>
                              <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={refreshUserData}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>

                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={addUserGroupEmployeeLink}
                                >
                                    <img
                                    src={PlusIcon}
                                    alt="Link"
                                    style={{width: "25px", margin:"0 0 0 0"}} />
                                    Link
                                </Button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={deleteEmployeeFromGroup}
                                >
                                    <img
                                    src={TrashIcon}
                                    alt="Unlink"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Unlink
                                </Button> */}

                                <button className="btn-no-border">           
                        <ConfirmModal outline={true}
                                //   color="danger"
                                  className="btn-no-border"
                                  buttonLabel="Unlink"
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected employee?"
                                  onClickYes={deleteEmployeeFromGroup}
                                  image={<img
                                    src={TrashIcon}
                                   // id={facilityOilTicketKey}
                                    
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}}/>}
                                    >
                        </ConfirmModal>    
                    </button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={this.getUserGroupData}
                                >
                                    <img
                                    src={ExcelIcon}
                                    alt="Export To Excel"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Export To Excel
                                </Button>  */}
       <div>                         
            <div>
                <IgrDataGrid                        
                       // ref={this.onGridRef}
                        height="600px"
                        //width="Calc(100% - 5px)"
                        //width="100%"
                        dataSource={users}
                        autoGenerateColumns="false"                                   
                        selectionMode="MultipleRow"
                        selectedKeysChanged={onGridRowSelected}
                        >
                                     

                        <IgrTextColumn field="empName" headerText="Name" width="*>140" isEditable="false" />                              
                        
                        <IgrTextColumn field="empStatusDesc" headerText="Status" width="*>100" isEditable="false" />

                        <IgrTextColumn field="emailAddress" headerText="Email" width="*>200" isEditable="false" />                                
                        <IgrTextColumn field="azureAD" headerText="AzureAD" width="*>200" isEditable="false" />                                

                        <IgrTextColumn field="locationName" headerText="Location" width="*>150" isEditable="false"/>    

                        <IgrTextColumn field="hireDate" headerText="Hired Date" width="*>140"                                           
                                            // cellUpdating={this.onReportDateUpdating}
                                            isEditable="false"
                                            />                               
                        
                        <IgrTextColumn field="linkedBy" headerText="Linked By" width="*>150" isEditable="false" />                                   
                        
                        <IgrTextColumn field="linkedDateTime" headerText="Linked Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            isEditable="false"
                                            />                         
                        <IgrNumericColumn field="employeeKey" isHidden/>
                    </IgrDataGrid>
            </div>
        </div>
                </FormGroup>

                <Modal isOpen={modalUserGroup} toggle={() => toggleModalUserGroup()} 
                       contentClassName="safetyObsDetail-modal-style">
                    <ModalHeader toggle={() => toggleModalUserGroup()}> User Group Details Link </ModalHeader>
                    <ModalBody>
                        <UserList  
                                        toggle={toggleModalUserGroup}
                                        employeeDetails={employeeDetails}
                                        userGroupKey = {props.UserGroupKey}
                                        toggleModalUser = {toggleModalUserGroup}
                                        userInput={user}
                                        refreshData = {refreshUserData}
                                        >
                         
                        </UserList>
                       
                    </ModalBody>
                </Modal>

                

    </div>)






}

const UserGroupSecurity = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [chkPoint, setchkPoint] = useState([]) 
    const [applicationSecurityUserGroupLinkKey, setapplicationSecurityUserGroupLinkKey] = useState([])
    const [user, setUser]= useState(props.userInput)
    const [modalApplicationSecurity, setModalApplicationSecurity] = useState(false)
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]
  




     function toggleModalApplicationSecurity() {  
        // console.log("Modal: " + this.state.modal)
        // this.setState({modalUser: !this.state.modalUser})
        // setModalUser(!modalUser);
        setModalApplicationSecurity(!modalApplicationSecurity)
    }

   function addApplicationSecurityUserGroupLink(){
        // let newGroup = this.createUserGroupEmployeeLinkForm()
        // this.setState({groupDetails:newGroup})
        
        toggleModalApplicationSecurity();
    }

    const deleteApplicationSecurityFromGroup = () => {   
        // console.log("Save user in user details")
        const deleteApplicationSecurityFromGroupDTO= deleteApplicationSecurityFromGroupSelect()
        // console.log(deleteApplicationSecurityFromGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/ApplicationSecurity'
          fetch(myURI , apiDELETEHeader(deleteApplicationSecurityFromGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
              refreshUserData()
            //   console.log("No error here: " + rspData)
            })
            .catch(error =>{
                refreshUserData()
            //   console.log("PUT Error: " + error);
            })       
    }

    const deleteApplicationSecurityFromGroupSelect=()=>{
        var tempDTO=[];
        var i=0;
        applicationSecurityUserGroupLinkKey.map(function (line) {
           const applicationSecurityFromGroupDelete = {
               "applicationSecurityUserGroupLinkKey": line,
               "deletedBy": getUserADAccount(user.account.userName)
   
           }
           tempDTO[i] = applicationSecurityFromGroupDelete ;
           i = i + 1
        });
       
       return tempDTO;
   }


   function onGridRowSelected(s, e) {
    // console.log(" On CLick")
    //const content = e.content;
    // if (e.addedKeys.count > 0) {
        // let clickedRow = e.addedKeys.item(0) 
        // let dataItem = clickedRow.value[0] 
        // console.log(dataItem)
        
        // if (!this.state.hideAssociateDetails){
            // this.setState({userGroupKey: dataItem.userGroupKey,
            //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
        // }

        const tempList=[] 
        let clickedRows = e.currentKeys
        
        for (let i = 0; i < e.currentKeys.count; i++){
        //    console.log(e.currentKeys.item(i).value[0] )
            tempList.push(e.currentKeys.item(i).value[0].applicationSecurityUserGroupLinkKey) ; 
        }
        
        // selectedUserGroupChanged(dataItem.employeeKey)
        // console.log(tempList)
        setapplicationSecurityUserGroupLinkKey(tempList)
        // setEmployeeKey(tempList);
       
        // console.log("hello")

    // }    
}

function refreshUserData(){
    const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/checkpoint'
    //  console.log("UserGroupUsers ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setchkPoint(json);
         })
     }



    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/checkpoint'
    //  console.log("UserGroupUsers ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setchkPoint(json);
         })
         .catch(error => {
            console.log("GET Error: " + error);
        })
     },[props.UserGroupKey])
 
     if (!isLoaded){
         return(<div>
             <h4> loading checkpoint list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>

                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={refreshUserData}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>

                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={addApplicationSecurityUserGroupLink}
                                >
                                    <img
                                    src={PlusIcon}
                                    alt="Link"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Link
                                </Button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={deleteApplicationSecurityFromGroup}
                                >
                                    <img
                                    src={TrashIcon}
                                    alt="Unlink"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Unlink
                                </Button> */}

                    <button className="btn-no-border">           
                        <ConfirmModal outline={true}
                                //   color="danger"
                                  className="btn-no-border"
                                  buttonLabel="Unlink"
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected application security?"
                                  onClickYes={deleteApplicationSecurityFromGroup}
                                  image={<img
                                    src={TrashIcon}
                                   // id={facilityOilTicketKey}
                                    
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}}/>}
                                    >
                        </ConfirmModal>    
                    </button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={this.getUserGroupData}
                                >
                                    <img
                                    src={ExcelIcon}
                                    alt="Export To Excel"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Export To Excel
                                </Button>  */}
                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="600px"
                         //width="Calc(100% - 5px)"
                         //width="100%"
                         dataSource={chkPoint}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         selectedKeysChanged={onGridRowSelected}
                         >         
 
                    <IgrTextColumn field="appSecurityNo" headerText="ID" width="100" />   
                        <IgrTextColumn field="appSecurityName" headerText="Name" width="*>140" />                              
                        
                        <IgrTextColumn field="appSecurityDesc" headerText="Description" width="*>100" />

                                         
                         <IgrTextColumn field="createdDateTime" headerText="Created Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            />
                        <IgrTextColumn field="applicationName" headerText="Application Name" width="*>120" />       
                        <IgrTextColumn field="applicationDesc" headerText="Appliction Desc" width="*>230" />       
                        <IgrTextColumn field="appModuleName" headerText="Module Name" width="*>150" />       
                        <IgrTextColumn field="appModuleID" headerText="Module ID" width="*>150" />       
                        
                        <IgrNumericColumn field="applicationSecurityKey" isHidden/>
                     </IgrDataGrid>
                 </FormGroup>

                 <Modal isOpen={modalApplicationSecurity} toggle={() => toggleModalApplicationSecurity()} 
                       contentClassName="safetyObsDetail-modal-style"
                    //    onClosed={()=> selectedUserGroupChanged(userGroupKey)}
                       >
                    <ModalHeader toggle={() => toggleModalApplicationSecurity()}> Application Security User Group Details</ModalHeader>
                    <ModalBody>
                        <ApplicationSecurityList  
                                        toggle={toggleModalApplicationSecurity}
                                        applicationSecurityDetails={props.applicationSecurityDetails}
                                        userGroupKey = {props.UserGroupKey}
                                        toggleModalApplicationSecurity = {toggleModalApplicationSecurity}
                                        userInput={user}
                                        refreshData={refreshUserData}
                                        >
                         
                        </ApplicationSecurityList>
                       
                    </ModalBody>
                </Modal>

     </div>)


}






const UserGroupFacility = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [facility, setFacility] = useState([]) 
    const [userGroupFacilityLinkKey, setUserGroupFacilityKey] = useState([])
    const [user, setUser]= useState(props.userInput)
    const [modalFacility, setModalFacility] = useState(false)
    // const [user, setUser]= useState(props)
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]

 

     function toggleModalFacility() {  
        // console.log("Modal: " + this.state.modal)
        // this.setState({modalUser: !this.state.modalUser})
        // setModalUser(!modalUser);
        // props.toggleModalFacility()
        setModalFacility(!modalFacility)
    }

   function addUserGroupFacilityLink(){
        // let newGroup = this.createUserGroupEmployeeLinkForm()
        // this.setState({groupDetails:newGroup})
        
        toggleModalFacility();
    }

    const deleteFacilityFromGroup = () => {   
        // console.log("Save user in user details")
        const deleteFacilityFromGroupDTO= deleteFacilityFromGroupSelect()
        // console.log(deleteFacilityFromGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/Facility'
          fetch(myURI , apiDELETEHeader(deleteFacilityFromGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
                refreshUserData()
            //   console.log("No error here: " + rspData)
            })
            .catch(error =>{
            //   console.log("PUT Error: " + error);
            refreshUserData()
            })       
    }

    const deleteFacilityFromGroupSelect=()=>{
        var tempDTO=[];
        var i=0;
        userGroupFacilityLinkKey.map(function (line) {
           const facilityFromGroupDelete = {
               "userGroupFacilityLinkKey": line,
               "deletedBy": getUserADAccount(user.account.userName)
            //    "deletedBy": getUserADAccount(user.account.userName)
   
           }
           tempDTO[i] = facilityFromGroupDelete ;
           i = i + 1
        });
       
       return tempDTO;
   }

    function onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        // if (e.addedKeys.count > 0) {
            // let clickedRow = e.addedKeys.item(0) 
            // let dataItem = clickedRow.value[0] 
            // console.log(dataItem)
            
            // if (!this.state.hideAssociateDetails){
                // this.setState({userGroupKey: dataItem.userGroupKey,
                //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
            // }
    
            const tempList=[] 
            let clickedRows = e.currentKeys
            
            for (let i = 0; i < e.currentKeys.count; i++){
            //    console.log(e.currentKeys.item(i).value[0] )
                tempList.push(e.currentKeys.item(i).value[0].userGroupFacilityLinkKey) ; 
            }
            
            // selectedUserGroupChanged(dataItem.employeeKey)
            // console.log(tempList)
            setUserGroupFacilityKey(tempList)
            // setEmployeeKey(tempList);
           
            // console.log("hello")
        // }
           
    }

    function refreshUserData(){
        const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/Facility'
            // console.log("UserGroupUsers ")       
            // console.log(myURL)
        
            fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
                .then(res => res.json())
                .then(json=>{
                    setIsLoaded(true);
                    setFacility(json);
                })
         }

    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/Facility'
    //  console.log("UserGroupUsers ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setFacility(json);
         })
         .catch(error => {
            console.log("GET Error: " + error);
        })
     },[props.UserGroupKey])

     
 
     if (!isLoaded){
         return(<div>
             <h4> loading facility list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>
        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={refreshUserData}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>

                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={addUserGroupFacilityLink}
                                >
                                    <img
                                    src={PlusIcon}
                                    alt="Link"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Link
                                </Button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={deleteFacilityFromGroup}
                                >
                                    <img
                                    src={TrashIcon}
                                    alt="Unlink"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Unlink
                                </Button> */}

                                <button className="btn-no-border">           
                                     <ConfirmModal outline={true}
                                //   color="danger"
                                        className="btn-no-border"
                                        buttonLabel="Unlink"
                                        // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                        formName="Confirm"
                                        // id={facilityOilTicketKey}
                                        message="Are you sure you want to delete selected facility?"
                                        onClickYes={deleteFacilityFromGroup}
                                        image={<img
                                            src={TrashIcon}
                                        // id={facilityOilTicketKey}
                                            
                                            style={{width: "25px", height:"25px", margin:"0 0 0 0"}}/>}
                                            >
                                     </ConfirmModal>    
                                 </button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={this.getUserGroupData}
                                >
                                    <img
                                    src={ExcelIcon}
                                    alt="Export To Excel"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Export To Excel
                                </Button> */}
                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="600px"
                         //width="Calc(100% - 5px)"
                         //width="100%"
                         dataSource={facility}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         selectedKeysChanged={onGridRowSelected}
                         >         
 
                    <IgrTextColumn field="userGroupName" headerText="Group Name" width="100" isEditable = "false" />   
                        <IgrTextColumn field="facilityID" headerText="Facility ID" width="*>140" isEditable = "false" />                              
                        
                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>100" isEditable = "false" />

                                         
                         <IgrTextColumn field="linkedDateTime" headerText="Linked Date" width="*>140" isEditable = "false"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            />
                        <IgrTextColumn field="linkedBy" headerText="Linked By" width="*>100" isEditable = "false" />

                        
                        <IgrTextColumn field="facilityTypeDesc" headerText="Facility Type" width="*>120" isEditable = "false" />       
                        <IgrTextColumn field="locationID" headerText="Location ID" width="*>230" isEditable = "false" />       
                        <IgrTextColumn field="locationName" headerText="Location Name" width="*>150" isEditable = "false" />       
                        <IgrTextColumn field="areaName" headerText="Area Name" width="*>150" isEditable = "false" />       
                        <IgrTextColumn field="subAreaName" headerText="SubArea Name" width="*>150" isEditable = "false" />       
                        
                        <IgrNumericColumn field="facilityKey" isHidden/>
                     </IgrDataGrid>
                 </FormGroup>

                 <Modal isOpen={modalFacility} toggle={() => toggleModalFacility()}  
                       contentClassName="oilTicketDetail-modal-style">
                    <ModalHeader toggle={() => toggleModalFacility()}> Facility User Group Details</ModalHeader>
                    <ModalBody>
                        <FacilityList  
                                        toggle={toggleModalFacility}
                                        facilityDetails={props.facilityDetails}
                                        userGroupKey = {props.UserGroupKey}
                                        toggleModalFacility = {toggleModalFacility}
                                        userInput={user}
                                        refreshData={refreshUserData}
                                        >
                         
                        </FacilityList>
                       
                    </ModalBody>
                </Modal>
     </div>)
}
// function selectedUserGroupChanged (userGroupKey)  {
//     // if ((typeof(this.props.SelectedUserGroupChanged) !=='undefined' && this.props.SelectedUserGroupChanged!==null)){
//     //     this.props.SelectedUserGroupChanged(userGroupKey)
//     // }
//     this.setState({userGroupKey:userGroupKey})
                
// }
const FacilityList=(props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [facilityList, setFacilityList] = useState([]) 
    const [facilityKey, setFacilityKey] = useState(props.facilityKey)
    const [userGroupkey, setUserGroupkey] = useState(props.userGroupKey)
    const [user, setUser]= useState(props.userInput)
 
    const closeForm=(refreshData)=>{
        if(refreshData){
           props.refreshData()
        }
        props.toggle();
       
    }

    const updateFacilityGroup = () => {
        // console.log("Save user in user details")
        const updateFacilityGroupDTO= createUserGroupFacilityLinkForm()
        // console.log(updateFacilityGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/Facility'
          fetch(myURI , apiPOSTHeader(updateFacilityGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
            //   console.log("No error here: " + rspData)
              closeForm(true)
            })
            .catch(error =>{
            //   console.log("PUT Error: " + error);
            closeForm(true)
            }) 
    }

    const createUserGroupFacilityLinkForm=()=>{
       
       
        var tempDTO=[];
        var i=0;
        facilityKey.map(function (line) {
           const userGroupFacilityCreate = {
               "UserGroupKey": userGroupkey,
               "FacilityKey": line,
                "LinkedBy": getUserADAccount(user.account.userName)
   
           }
           tempDTO[i] = userGroupFacilityCreate;
           i = i + 1
        });
       
       return tempDTO;
   }

   function onGridRowSelected(s, e) {
    // console.log(" On CLick")
    //const content = e.content;
    // if (e.addedKeys.count > 0) {
        // let clickedRow = e.addedKeys.item(0) 
        // let dataItem = clickedRow.value[0] 
        // console.log(dataItem)
        
        // if (!this.state.hideAssociateDetails){
            // this.setState({userGroupKey: dataItem.userGroupKey,
            //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
        // }

        const tempList=[] 
        let clickedRows = e.currentKeys
        
        for (let i = 0; i < e.currentKeys.count; i++){
        //    console.log(e.currentKeys.item(i).value[0] )
            tempList.push(e.currentKeys.item(i).value[0].facilityKey) ; 
        }
        
        // selectedUserGroupChanged(dataItem.employeeKey)
        // console.log(tempList)
        setFacilityKey(tempList)
        // setEmployeeKey(tempList);
       
        // console.log("hello")
    // }
       
}

    useEffect(() =>{
     
        const myURL = apiURL + 'FacilityDetails'
        // console.log("UserGroupUsers ")       
        // console.log(myURL)
    
        fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                setIsLoaded(true);
                setFacilityList(json);
            })
            .catch(error => {
                console.log("GET Error: " + error);
            })
        },[props.UserGroupKey]) 


        if (!isLoaded){
            return(<div>
                <h4> loading UserList list ....</h4>
            </div>)
        }else
        return(<div>
                <FormGroup>
                <IgrDataGrid                        
                        // ref={this.onGridRef}
                        height="500px"
                        // width="Calc(100% - 5px)"
                        // width="100%"
                        dataSource={facilityList}
                        autoGenerateColumns="false"                                   
                        selectionMode="MultipleRow"
                        selectedKeysChanged={onGridRowSelected}
                        
                        >         

                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>190" />
                        <IgrTextColumn field="facilityID" headerText="Facility ID" width="*>140"
                            // isHidden={(windowWidth < 450 ? true : false)} 
                            />                        
                        
                        <IgrTextColumn field="locationName" headerText="Basin" width="*>140" 
                                    //    isHidden={(windowWidth < 450 ? true : false)}
                                        />

                        <IgrTextColumn field="areaName" headerText="Area" width="*>130" 
                                        />
                        
                        <IgrTextColumn field="subAreaName" headerText="Sub Area" width="*>130" 
                                        /> 
                        
                        <IgrTextColumn field="subSystem" headerText="Sub System" width="*>130" 
                                        />                                                           
                        {/* <IgrNumericColumn field="permitCap" headerText="Permit Cap" width="*>100"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />
                        <IgrNumericColumn field="operationCap" headerText="Operation Cap" width="*>130"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />
                        <IgrNumericColumn field="royaltyCost" headerText="Royalty" width="*>130"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />                     */}
                        <IgrTextColumn field="facilityTypeDesc" headerText="Facility Type" width="*>120" 
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />
                        {/* <IgrNumericColumn field="meterCount" headerText="Meter Count" width="*>130"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />
                        <IgrNumericColumn field="tankCount" headerText="Tank Count" width="*>130"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        /> */}
                        {/* <IgrNumericColumn field="lat" headerText="Latitude" width="*>130"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />
                        <IgrNumericColumn field="long" headerText="Longtitude" width="*>130"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        /> */}

                        {/* <IgrTemplateColumn field="acquiredDate" headerText="Acquired Date" width="*>140"                                           
                                            // cellUpdating={this.onReportDateUpdating}
                                            // isHidden={(windowWidth < 450 ? true : false)}
                                            />
                        <IgrTextColumn field="areaManageBy" headerText="Area Manage By" width="*>150" 
                                            // isHidden={(windowWidth < 450 ? true : false)}
                                            />                                    */}
  
                        {/* <IgrTextColumn field="costDescription" headerText="Cost Desc" width="*>210" />
                        */}
                        {/* <IgrTextColumn field="createdBy" headerText="Created By" width="*>150"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                         />                                   
                        
                        <IgrTextColumn field="modifiedBy" headerText="Modified By" width="*>150" 
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />                                   
                        
                        <IgrTextColumn field="inspComments" headerText="Inspection Note" width="*>450"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />
                        
                        <IgrTextColumn field="facilityComments" headerText="Facility Comments" width="*>450"
                                        // isHidden={(windowWidth < 450 ? true : false)}
                                        />
                         */}
                        
                        <IgrNumericColumn field="facilityDailyOpCostKey" isHidden/>
                    </IgrDataGrid>
                    <div>
                    <Button color="primary" className="floatButtonCancel" 
                             onClick={() => closeForm(true)}
                             >Cancel</Button>
                    </div>
                        <Button color="primary"className="floatButtonAdd"
                            //  style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> updateFacilityGroup()}
                             >Add</Button>
                </FormGroup>

        </div>)


} 


const ApplicationSecurityList = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [applicationSecurityList, setUserList] = useState([])
    const [applicationSecurityKey, setApplicationSecurityKey] = useState([])
    const [userGroupkey, setUserGroupkey] = useState(props.userGroupKey)
    const [user, setUser]= useState(props.userInput)


    const closeForm=(refreshData)=>{
        if(refreshData){
           props.refreshData()
        }
        props.toggle();
       
    }


    const updateApplicationSecurityGroup = () => {   
        // console.log("Save application security in user group details")
        const updateApplicationSecurityGroupDTO= createUserGroupApplicationSecurityLinkForm()
        // console.log(updateApplicationSecurityGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/ApplicationSecurity'
          fetch(myURI , apiPOSTHeader(updateApplicationSecurityGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
              
            //   console.log("No error here: " + rspData)
            //   test()
              closeForm(true)
            })
            .catch(error =>{
            //   console.log("PUT Error: " + error);
            closeForm(true)
            })       
    }



    const createUserGroupApplicationSecurityLinkForm=()=>{
        var tempDTO=[];
        var i=0;
        applicationSecurityKey.map(function (line) {
           const applicationSecurityCreate = {
               "applicationSecurityKey": line,
               "userGroupkey": userGroupkey,
               "createdBy": getUserADAccount(user.account.userName)
   
           }
           tempDTO[i] = applicationSecurityCreate;
           i = i + 1
        });
       
       return tempDTO;
   }
    
    
    
   function onGridRowSelected(s, e) {
    // console.log(" On CLick")
    //const content = e.content;
    // if (e.addedKeys.count > 0) {
        // let clickedRow = e.addedKeys.item(0) 
        // let dataItem = clickedRow.value[0] 
        // console.log(dataItem)
        
        // if (!this.state.hideAssociateDetails){
            // this.setState({userGroupKey: dataItem.userGroupKey,
            //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
        // }

        const tempList=[] 
        let clickedRows = e.currentKeys
        
        for (let i = 0; i < e.currentKeys.count; i++){
        //    console.log(e.currentKeys.item(i).value[0] )
            tempList.push(e.currentKeys.item(i).value[0].applicationSecurityKey) ; 
        }
        
        // selectedUserGroupChanged(dataItem.employeeKey)
        // console.log(tempList)
        setApplicationSecurityKey(tempList)
        // setEmployeeKey(tempList);
       
        // console.log("hello")
    // }
       
}

// function test(){
// const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/Employee'
// // /' + user.account.userName

// fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
// .then(res => res.json())
// .then(json=>{         
//         setIsLoaded(true);
//         setUserList(json);          
// })
// }


    
    
    
    useEffect(() =>{
     
        let myURI = apiURL + 'SecAdmin/SecCheckPoint' 
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{         
                setIsLoaded(true);
                setUserList(json);          
        })
        .catch(error => {
            console.log("GET Error: " + error);
        })
        },[props.UserGroupKey])

        // const closeForm=()=>{
        //     props.toggleModalApplicationSecurity();
        // }

        if (!isLoaded){
            return(<div>
                <h4> loading UserList list ....</h4>
            </div>)
        }else
        return(<div>
        <FormGroup>
        <IgrDataGrid                        
                // ref={this.onGridRef}
                height="500px"
                //width="Calc(100% - 5px)"
                //width="100%"
                dataSource={applicationSecurityList}
                autoGenerateColumns="false"                                   
                selectionMode="MultipleRow"
                selectedKeysChanged={onGridRowSelected}
                >         


                <IgrTextColumn field="appSecurityNo" headerText="ID" width="100" />   
                <IgrTextColumn field="appSecurityName" headerText="Name" width="*>140" />                              
                
                <IgrTextColumn field="appSecurityDesc" headerText="Description" width="*>100" />

                                 
                 <IgrTemplateColumn field="createdDateTime" headerText="Created Date" width="*>140"                                           
                                    // cellUpdating={this.onReportDateUpdating}
                                    />
                <IgrTextColumn field="applicationName" headerText="Application Name" width="*>120" />       
                <IgrTextColumn field="applicationDesc" headerText="Appliction Desc" width="*>230" />       
                <IgrTextColumn field="appModuleName" headerText="Module Name" width="*>150" />       
                <IgrTextColumn field="appModuleID" headerText="Module ID" width="*>150" />       
                
                <IgrNumericColumn field="applicationSecurityKey" isHidden/>
            </IgrDataGrid>
            <div>
            <Button color="primary" className="floatButtonCancel" 
                             onClick={() => closeForm()}
                             >Cancel</Button>
             </div>
                        <Button color="primary"className="floatButtonAdd"
                            //  style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> updateApplicationSecurityGroup()}
                             >Add</Button>
        </FormGroup>
    </div>)
    }



const UserList = (props) => {
    const [isLoaded,setIsLoaded] = useState(false)
    const [userList, setUserList] = useState([]) 
    const [employeeKey, setEmployeeKey] = useState([])
    // const [data,setEmployeeKey] = useState([])
    const [userGroupKey, setUserGroupKey] = useState(props.userGroupKey)
    const [user, setUser]= useState(props.userInput)



    const closeForm=(refreshData)=>{
        if(refreshData){
           props.refreshData()
        }
        props.toggle();
       
    }
    
   const updateEmployeeGroup = () => {   
        // console.log("Save user in user details")
        const updateEmployeeGroupDTO= createUserGroupEmployeeLinkForm()
        // console.log(updateEmployeeGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/Employee'
          fetch(myURI , apiPOSTHeader(updateEmployeeGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
      
            //   console.log("No error here: " + rspData)
            //   refreshUserData()
            closeForm(true)  
             
            })
            .catch(error =>{
            //   console.log("PUT Error: " + error);
            closeForm(true)  
            })  
            // closeForm(true)     
    }

//    const createUserGroupEmployeeLinkForm=()=>{
        
//         const userGroupCreate = [{
//             "userGroupEmployeeLinkKey": null,
//             "userGroupKey": userGroupKey,
//             "employeeKey": employeeKey,
//             "linkBy": "test"
            
//         }]
//         return userGroupCreate
//     }

const createUserGroupEmployeeLinkForm=()=>{
     var tempDTO=[];
     var i=0;
     employeeKey.map(function (line) {
        const userGroupCreate = {
            "userGroupEmployeeLinkKey": null,
            "userGroupKey": userGroupKey,
            "employeeKey": line,
            "linkBy": getUserADAccount(user.account.userName)

        }
        tempDTO[i] = userGroupCreate;
        i = i + 1
     });
    
    return tempDTO;
}



    function onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        // if (e.addedKeys.count > 0) {
            // let clickedRow = e.addedKeys.item(0) 
            // let dataItem = clickedRow.value[0] 
            // console.log(dataItem)
            
            // if (!this.state.hideAssociateDetails){
                // this.setState({userGroupKey: dataItem.userGroupKey,
                //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
            // }
    
            const tempList=[] 
            let clickedRows = e.currentKeys
            
            for (let i = 0; i < e.currentKeys.count; i++){
            //    console.log(e.currentKeys.item(i).value[0] )
                tempList.push(e.currentKeys.item(i).value[0].employeeKey) ; 
            }
            
            // selectedUserGroupChanged(dataItem.employeeKey)
            // console.log(tempList)
            setEmployeeKey(tempList)
            // setEmployeeKey(tempList);
           
            // console.log("hello")
        // }
           
    }

    // function refreshUserData(){
    //     const myURL = apiURL + 'SecAdmin/UserGroup/' + props.UserGroupKey + '/Employee'
    //     console.log("UserGroupUsers ")       
    //     console.log(myURL)
    
    //     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
    //         .then(res => res.json())
    //         .then(json=>{
    //             setIsLoaded(true);
    //             setUserList(json);
    //         })
        
    // }
    

    // const closeForm=()=>{
    //     props.toggleModalUser();
    // }
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]

     
    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/User'
    //  console.log("UserGroupUsers ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setUserList(json);
         })
         .catch(error => {
            console.log("GET Error: " + error);
        })
     },[props.UserGroupKey])

     if (!isLoaded){
         return(<div>
             <h4> loading UserList list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>

                         <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={refreshUserData()}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Refresh
                            </Button>
                <div>
                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="500px"
                         //width="Calc(100% - 5px)"
                        //  width="100%"
                         dataSource={userList}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         selectedKeysChanged={onGridRowSelected}
                         >         
                   
                         <IgrTextColumn field="wbrEmpID" headerText="ID" width="*>140" />   
                        <IgrTextColumn field="empName" headerText="Name" width="*>140"  />                              
                        
                        <IgrTextColumn field="empStatusDesc" headerText="Status" width="*>100" />

                        <IgrTextColumn field="emailAddress" headerText="Email" width="*>200" />                                
                        <IgrTextColumn field="azureAD" headerText="AzureAD" width="*>200" />                                

                        <IgrTextColumn field="locationName" headerText="Location" width="*>150"/>    

                                                    
                        
                        <IgrTextColumn field="modifiedBy" headerText="Modified By" width="*>150" />                                   
                        
                        <IgrTextColumn field="modifiedDateTime" headerText="Modified Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            />
                         <IgrTextColumn field="lastLoginDateTime" headerText="Last Login Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            />
                        <IgrTextColumn field="managerNameLvl1" headerText="Manager Level 1" width="*>150" />       
                        <IgrTextColumn field="managerNameLvl2" headerText="Manager Level 2" width="*>150" />       
                        <IgrTextColumn field="managerNameLvl3" headerText="Manager Level 3" width="*>150" />       
                        <IgrTextColumn field="managerNameLvl4" headerText="Manager Level 5" width="*>150" />       
                        <IgrTextColumn field="managerNameLvl5" headerText="Manager Level 5" width="*>150" />       

                        <IgrNumericColumn field="employeeKey" isHidden/>
                       
                     </IgrDataGrid>
                        <div>
                         <Button color="primary" className="floatButtonCancel" 
                             onClick={() => closeForm(true)}
                             >Cancel</Button>
                        </div>
                        <Button color="primary"className="floatButtonAdd"
                            //  style={{margin:"0px 10px 0px 0px"}}
                             onClick={()=> updateEmployeeGroup()}
                             >Add</Button>
                             </div>
                 </FormGroup>
     </div>)

   
     
     
}


export default class UserGroupAdmin extends Component {
    constructor(props){
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        
        this.state = {
            userGroup:[],
            isLoaded:false,
            userGroupKey:-1,
            userGroupEmployeeLinkKey: -1,
            groupDetails:null,
            employeeDetails:null,
            applicationSecurityDetails:null,
            facilityDetails: null,
            windowHeight:window.innerHeight,
            windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
            activeTab:'1',
            modal:false,
            modalUser:false,
            modalApplicationSecurity:false,
            modalFacility:false,
            user:this.props.user,
            error: null,
            userGroupName:'',
            hideAssociateDetails:((typeof(props.HideAssociateDetails) ==='undefined' || props.HideAssociateDetails===null) ? false : props.HideAssociateDetails),
         
        }
    }

    closeForm = (bFreshData) => {
        // if (bFreshData) this.props.refreshOilTicket();
         this.props.toggleModalUserGroup()
     }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.userGroup;
    }

    selectedUserGroupChanged = (userGroupKey) => {
        // if ((typeof(this.props.SelectedUserGroupChanged) !=='undefined' && this.props.SelectedUserGroupChanged!==null)){
        //     this.props.SelectedUserGroupChanged(userGroupKey)
        // }
        this.setState({userGroupKey:userGroupKey})
                    
    } 

    onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            // console.log(dataItem)
            
            if (!this.state.hideAssociateDetails){
                this.setState({userGroupKey: dataItem.userGroupKey,
                    userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
            }

            // const tempList=[]
            // //let clickedRows = e.currentKeys
            
            // for (let i = 0; i < e.currentKeys.count; i++){
            //    // console.log(e.currentKeys.item(i).value[0] )
            //     tempList.push(e.currentKeys.item(i).value[0].userGroupKey); 
            // }
            
            this.selectedUserGroupChanged(dataItem.userGroupKey)
      
        } 
    }

    toggleModalUser = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modalUser: !this.state.modalUser})
    }

    
    toggleModalUserGroup = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modalUserGroup: !this.state.modalUserGroup})
    }

    toggleModalApplicationSecurity = () =>{
        // console.log("Modal: " + this.state.modal)
        this.setState({modalApplicationSecurity: !this.state.modalApplicationSecurity})
    }

    toggleModalFacility = () =>{
        // console.log("Modal: " + this.state.modal)
        this.setState({modalFacility: !this.state.modalFacility})
    }


    toggleModal = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
    }

    getUserGroupData = () =>{
        const {user}=this.state;

        let myURI = apiURL + 'SecAdmin/UserGroup' 
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                userGroup: json
            })
        })
    }

    // updateEmployeeGroup = () => {   
    //     console.log("Save user in user details")
    //     const updateEmployeeGroupDTO= this.createUserGroupEmployeeLinkForm()
    //     console.log(updateEmployeeGroupDTO)
      
    
    //       let myURI = apiURL + 'SecAdmin/UserGroup/Employee'
    //       fetch(myURI , apiPOSTHeader(updateEmployeeGroupDTO) )
    //         .then(async response => {
    //           const rspData = response.text();
    //           if (!response.ok){
    //             console.log("Error return but you dont see")
    //             const error = (rspData && rspData.message) || response.status;
    //             return Promise.reject(error);
                
    //           }
      
    //           console.log("No error here: " + rspData)
    //         })
    //         .catch(error =>{
    //           console.log("PUT Error: " + error);
    //         })       
    // }
    
    handleResize = (e) => {
   
        if (document.getElementById('mainDiv') !== null){
            this.setState({ windowHeight: window.innerHeight,
                windowWidth: document.getElementById('mainDiv').offsetWidth });

        }
    };

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getUserGroupData();
        
        //this.selectedFacilityChanged(this.state.facilityKeys)
    }


    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    // deleteUserGroup = () =>{

    // } 

    editUserGroup = () => {
        this.toggleModal()
        
    //    try{
    //        this.toggleModal()
    //    }
    //    catch(error){
    //        this.setState({error})
    //    }
    }

    
    createUserGroupForm(){
        const {user} = this.props
        const {grpDetails} = this.state 
        const userGroupCreate ={
            "userGroupKey": -1,
            "userGroupName": "",
            "userGroupDesc": "",
            "active": 0,
            "createdBy": getUserADAccount(user.account.userName) 
        }
        return userGroupCreate
    }


    
    addUserGroup = () => {
        let newGroup = this.createUserGroupForm()
        this.setState({groupDetails:newGroup})
        this.toggleModal();
    }


    // createUserGroupEmployeeLinkForm(){
    //     const {grpDetails} = this.state 
    //     const {employeeDetails} = this.state
    //     const userGroupCreate ={
    //         "userGroupEmployeeLinkKey": null,
    //         "userGroupKey": 6,
    //         "employeeKey": 36,
    //         "linkBy": "test"
    //     }
    //     return userGroupCreate
    // }
    addUserGroupEmployeeLink=()=>{
        // let newGroup = this.createUserGroupEmployeeLinkForm()
        // this.setState({groupDetails:newGroup})
        
        this.toggleModalUser();
    }

    render() {
        const {isLoaded, hideAssociateDetails,userGroup, 
               windowHeight,userGroupKey,activeTab, groupDetails, employeeDetails, applicationSecurityDetails, facilityDetails, error} = this.state
        const gridHeight=(windowHeight * (hideAssociateDetails ? 0.8: 0.3)) + "px"
        const searchText = window.$searchText.toLowerCase()
        // console.log("LOADIN.......") 
        
        // if (this.state.error) {
        //     return <h1>Caught an error.</h1>
        //   }
         
        if (!isLoaded){
            return(<div>
                <h3>Loading UserGroups ...... </h3>
            </div>)
        }
        return (  
            <div>
                <FormGroup>
                    <Row form>
                        <h2> User Group Management |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getUserGroupData}>
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height: "25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.addUserGroup}>
                            <img 
                            src={PlusIcon}
                            alt="excel"
                            style={{width:"30px", height:"25px", margin:"0 0 0 0"}}/>
                            Add
                        </Button>{' '}

                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.editUserGroup}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Edit
                        </Button>{' '}

                        {/* <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.deleteUserGroup}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Delete
                        </Button>{' '} */}


                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={ExcelIcon}
                            alt="excel"
                            style={{width:"30px", height:"40px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                       
                            
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>
                <div>
                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height= "300px"
                        //width="Calc(100% - 5px)"
                        //width="100%"
                        dataSource={userGroup.filter(rec => ((rec.userGroupName || '').toLowerCase().includes(searchText)) ||
                                                (rec.userGroupDesc || '').toLowerCase().includes(searchText) ||
                                                (rec.createdBy || '').toLowerCase().includes(searchText)  )}
                        autoGenerateColumns="false"                                   
                        selectionMode="singlerow"
                        selectedKeysChanged={this.onGridRowSelected}>         

                        <IgrTextColumn field="userGroupName" headerText="Group Name" width="*>140" isEditable = "false"/>                              
                        
                        <IgrTextColumn field="userGroupDesc" headerText="Description" width="*>250"  isEditable = "false" />

                        <IgrTemplateColumn field="active" headerText="Active" width="100" 
                        cellUpdating={this.onActiveUpdating}
                        isEditable = "false"
                        />                                

                        <IgrTextColumn field="createdBy" headerText="Created By" width="*>150"  isEditable = "false"/>    

                        <IgrTemplateColumn field="createdDatetime" headerText="Created Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}
                                            isEditable = "false"
                                            />                               
                        
                        <IgrTextColumn field="modifiedBy" headerText="Modified By" width="*>150"  isEditable = "false" />                                   
                        
                        <IgrTextColumn field="modifiedDateTime" headerText="Modified Date" width="*>140"                                           
                                            //cellUpdating={this.onReportDateUpdating}
                                            isEditable = "false"
                                            />
                       
                        <IgrNumericColumn field="userGroupKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>
                </div>

                <FormGroup hidden={hideAssociateDetails}> 
                <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '1'})}
                                      onClick={() => {this.toggle('1')}}>
                               <h5>Users</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({active: activeTab === '2'})}
                                      onClick={() => {this.toggle('2')}}>
                                <h5>Application Security</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({active: activeTab === '3'})}
                                      onClick={() => {this.toggle('3')}}>
                                <h5>Facility</h5>
                            </NavLink>
                        </NavItem>
                                      
                    </Nav>
                    {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getUserGroupData}>
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button> */}

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.addUserGroupEmployeeLink}>
                                    <img
                                    src={PlusIcon}
                                    alt="Link"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Link
                                </Button> */}

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.deleteEmployeeFromGroupLink}>
                                    <img
                                    src={TrashIcon}
                                    alt="Unlink"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Unlink
                                </Button> */}

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getUserGroupData}>
                                    <img
                                    src={ExcelIcon}
                                    alt="Export To Excel"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Export To Excel
                                </Button>  */}

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">                                                        
                            <UserGroupUsers UserGroupKey={userGroupKey} toggleModalUser = {this.toggleModalUserGroup} userInput ={this.props.user} employeeDetails={employeeDetails} userGroupKey = {userGroupKey}  >
                                    
                            </UserGroupUsers>
                            
                        </TabPane>

                        <TabPane tabId="2">
                           <UserGroupSecurity UserGroupKey={userGroupKey} toggleModalApplicationSecurity = {this.toggleModalApplicationSecurity} userInput ={this.props.user} >

                           </UserGroupSecurity>
                        </TabPane>

                        <TabPane tabId="3">
                           <UserGroupFacility UserGroupKey={userGroupKey} toggleModalFacility = {this.toggleModalFacility} userInput ={this.props.user} >

                           </UserGroupFacility>
                        </TabPane>
                        

                    </TabContent>
                </FormGroup>
                

                <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} 
                       contentClassName="userGroupDetail-modal-style">
                    <ModalHeader toggle={() => this.toggleModal()}> User Group Details </ModalHeader>
                    <ModalBody>
                    <UserGroupDetails    
                                     user={this.props.user}
                                     toggle={this.toggleModal}
                                     groupDetails={groupDetails}
                                    refreshData={this.getUserGroupData}
                                    userInput ={this.props.user}
                                     >

                    </UserGroupDetails>
                    </ModalBody>
                </Modal>


                {/* <Modal isOpen={this.state.modalUserGroup} toggle={() => this.toggleModalUserGroup()} 
                       contentClassName="safetyObsDetail-modal-style">
                    <ModalHeader toggle={() => this.toggleModalUserGroup()}> User Group Details Link </ModalHeader>
                    <ModalBody>
                        <UserList  
                                        toggle={this.toggleModalUserGroup}
                                        employeeDetails={employeeDetails}
                                        userGroupKey = {userGroupKey}
                                        toggleModalUser = {this.toggleModalUserGroup}
                                        userInput={this.props.user}
                                        >
                         
                        </UserList>
                       
                    </ModalBody>
                </Modal>

                 */}
{/* 
                <Modal isOpen={this.state.modalApplicationSecurity} toggle={() => this.toggleModalApplicationSecurity()} 
                       contentClassName="safetyObsDetail-modal-style"
                       onClosed={()=> this.selectedUserGroupChanged(this.state.userGroupKey)}>
                    <ModalHeader toggle={() => this.toggleModalApplicationSecurity()}> Application Security User Group Details</ModalHeader>
                    <ModalBody>
                        <ApplicationSecurityList  
                                        toggle={this.toggleModalApplicationSecurity}
                                        applicationSecurityDetails={applicationSecurityDetails}
                                        userGroupKey = {userGroupKey}
                                        toggleModalApplicationSecurity = {this.toggleModalApplicationSecurity}
                                        userInput={this.props.user}
                                        >
                         
                        </ApplicationSecurityList>
                       
                    </ModalBody>
                </Modal> */}
{/* 
                <Modal isOpen={this.state.modalFacility} toggle={() => this.toggleModalFacility()}  
                       contentClassName="oilTicketDetail-modal-style">
                    <ModalHeader toggle={() => this.toggleModalFacility()}> Facility User Group Details</ModalHeader>
                    <ModalBody>
                        <FacilityList  
                                        toggle={this.toggleModalFacility}
                                        facilityDetails={facilityDetails}
                                        userGroupKey = {userGroupKey}
                                        toggleModalFacility = {this.toggleModalFacility}
                                        userInput={this.props.user}
                                        >
                         
                        </FacilityList>
                       
                    </ModalBody>
                </Modal> */}



             </div>
        );
    }

    

    onActiveUpdating(s,e){
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        if (content.childElementCount === 0) {
            //link = document.createElement("INPUT");
            var chkActive = document.createElement("INPUT");
            chkActive.setAttribute("type", "checkbox");           
       
            content.style.display = "inline-block";
            //content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(chkActive);

            chkActive.removeAttribute("checked")
            if (item.active != 0){
                chkActive.setAttribute("checked", "checked");     
            }
        }

       
        
        
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        // console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "FacilityCost");
    }

}