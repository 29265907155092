import React, { Component } from 'react';
import { Col, Row, Button,CustomInput, FormGroup, Label } from 'reactstrap';
import { apiURL } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class TankVolume extends Component {
    static displayName = TankVolume.name;

    constructor(props) {
        super(props);

        this.onSortChanged = () => {
            if (this.pager) {
                this.pager.applySorts(this.grid.sortDescriptions);
            }
        };
        this.onGroupChanged = () => {
            if (this.pager) {
                this.pager.applyGroups(this.grid.groupDescriptions);
            }
        };
        this.onFilterChanged = () => {
            if (this.pager) {
                this.pager.applyFilters(this.grid.filterExpressions);
            }
        };
        this.onPageChanged = (pageNumber, data) => {
            this.grid.dataSource = data;
            this.grid.flush();
            this.grid.scrollToRowByIndex(0);
        };


        this.onGridRef = this.onGridRef.bind(this);
        this.onPagerRef = this.onPagerRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        console.log("Props Start:" + props.StartDate)
        const urlPara=this.getURLParameters(props.parameters)

        this.state = {
            tankVol: [],
            isLoad: false,
            startDate: (urlPara.startDate === null ? ((props.StartDate === "" || typeof(props.StartDate) ==='undefined' || props.StartDate===null) ? getFirstDayofMonth().toISOString() : props.StartDate) : urlPara.startDate),
            endDate:  (urlPara.endDate === null ? ((props.EndDate === "" || typeof(props.EndDate) ==='undefined' || props.EndDate===null) ? new Date().toISOString() : props.EndDate) : urlPara.endDate), // new Date().toISOString(),
            tankKey: props.TankKey,
            tankVolHeader: ((props.VoldHeaderLabel === "" || typeof(props.VoldHeaderLabel) ==='undefined' || props.VoldHeaderLabel===null) ? "Tank Volume | " : props.VoldHeaderLabel + " | "), // new Date().toISOString(),            
            windowHeight:window.innerHeight,
            modal:false,
            gridFilter:((typeof(props.GridFilter) ==='undefined' || props.GridFilter===null) ? true : props.GridFilter),
            facilityKeys:urlPara.facilityKeys,
            showAllFac:(urlPara.facilityKeys === null ? false : true),
            hideAllFacSwitch: (props.hideAllFacilitySwitch === null ? false : props.hideAllFacilitySwitch ) 
        }
    }

    getURLParameters = (inputPara) => {
        var paraMeters={
            startDate: null ,
            endDate:null ,
            facilityKeys: null }

            
        if ( !this.props.location==='undefined' || inputPara!=='undefined'){   
            var query
            if (!this.props.location==='undefined')
            {
                 query = new URLSearchParams(this.props.location.search);
            
            }else{
                 query = new URLSearchParams(inputPara);            
            }
            paraMeters={
                startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
                endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
                facilityKeys:query.get('Facility') 
        }
        
        }
     
        return paraMeters
    }

    onShowAllFac = () =>{
        this.getTankVolume(!this.state.showAllFac)
        this.setState({showAllFac:!this.state.showAllFac})
       
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.tankVol;

    }
    onPagerRef(pager) {
        if (!pager) {
            return;
        }
        this.pager = pager;
        if (this.grid) {
            if (this.grid.sortDescriptions.count > 0) {
                this.onSortChanged();
            }
            if (this.grid.groupDescriptions.count > 0) {
                this.onGroupChanged();
            }
            if (this.grid.filterExpressions.size() > 0) {
                this.onFilterChanged();
            }
        }
    }

    refreshTankVolume = () =>{
        const {showAllFac} = this.state
        this.getTankVolume(showAllFac)
    }

    getTankVolume = (showAllFacility=false) => {
        
        const {facilityKeys,tankKey} = this.state

        let myURI
        if (showAllFacility || tankKey>0){
            myURI = apiURL + 'FacilityInspTankLevel/startdate/' + 
                            this.state.startDate.toString().substring(0, 10) + 
                            '/enddate/' + this.state.endDate.toString().substring(0, 10)+
                            '/AzureAD/' + this.props.user.account.userName;
        }else{
            myURI = apiURL + 'FacilityInspTankLevel/startdate/' + 
                            this.state.startDate.toString().substring(0, 10) + 
                            '/enddate/' + this.state.endDate.toString().substring(0, 10)+
                            '/MyFacility/AzureAD/' + this.props.user.account.userName;
        }


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (!isNaN(this.state.tankKey)) {
                    console.log("TankKey: " + this.state.tankKey)
                    this.setState({
                        tankVol: json.filter(vols => vols.tankKey == this.state.tankKey),
                        isLoad: true
                    })
                } else {
                    this.setState({
                        isLoad: true,
                        tankVol: (facilityKeys === null ? json:
                            json.filter(facList => (facilityKeys.includes(facList.facilityKey))))
                        
                    })
                }
            });
    }

    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.refreshTankVolume();
    }

    componentWillReceiveProps(props) {
        const { refresh, id } = this.props;

        //console.log("In tank volume reresh")
        this.setState({
            tankKey:props.TankKey,
            tankVolHeader: (( typeof(props.VoldHeaderLabel) ==='undefined' || props.VoldHeaderLabel===null) ? "Tank Volumes | " : ((props.VoldHeaderLabel !== "") ? props.VoldHeaderLabel + " | " : "")), // new Date().toISOString(),
          
        })
        this.refreshTankVolume()
      }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue
        }
        );
    }

    handleStartDateChange(value, formattedValue) {
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        }
        );
    }

    render() {
        var { tankVol, isLoad,tankVolHeader,tankKey,hideAllFacSwitch,
             showAllFac,windowHeight,gridFilter } = this.state;
        const gridHeight=(windowHeight * 0.7) + "px"  ;      
        var searchText = window.$searchText.toLowerCase() ;
        
        if (!gridFilter){
            searchText = "";
        }

        if (!isLoad){
            return(<div>
                <h3>Loading tank volumes......</h3>
            </div>)
        }else
        return (
            <div>
                <FormGroup>
                    <Row form>
                        <h2> {tankVolHeader}</h2>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.refreshTankVolume}>
                            <img
                                src={MenuIcon}
                                alt="refresh"
                                style={{ width: "30px", margin: "0 0 0 0" }}
                            />
                            Refresh
                        </Button>

                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img src={MenuIcon}
                                alt="Export"
                                style={{ width: "30px", margin: "0 0 0 0" }}
                            />
                            Export To Excel
                        </Button>

                        <FormGroup  style={{margin:"10px 0 0 100px"}} >
                        <h6>
                        <CustomInput className="float-right" 
                                    type="switch" 
                                    id="allFac" 
                                    name="allFac" 
                                    hidden={(hideAllFacSwitch)}
                                    label="Show All Facilities"                                   
                                    checked={showAllFac} 
                                    onClick={(e) => this.onShowAllFac()} />
                                    </h6>
                                    </FormGroup>
                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                onChange={(v, f) => this.handleStartDateChange(v, f)} />
                        </Col>
                        <Col md={3}>
                            <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate}
                                onChange={(v, f) => this.handleEndDateChange(v, f)} />
                        </Col>
                    </Row>
                </FormGroup>

                <IgrDataGrid
                    ref={this.onGridRef}
                    height={gridHeight}
                    width="100%"
                    dataSource={tankVol.filter(rec => ((rec.lineCreatedBy || '').toLowerCase().includes(searchText)) ||
                                                (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                (rec.tankID || '').toLowerCase().includes(searchText) ||
                                                (rec.tankName || '').toLowerCase().includes(searchText) ||
                                                (rec.areaName || '').toLowerCase().includes(searchText) ||
                                                (rec.companyName || '').toLowerCase().includes(searchText) ||                                                            
                                                (rec.lineComments || '').toLowerCase().includes(searchText) )}
                    autoGenerateColumns="false"
                    sortDescriptionsChanged={this.onSortChanged}
                    groupDescriptionsChanged={this.onGroupChanged}
                    filterExpressionsChanged={this.onFilterChanged}
                    defaultColumnMinWidth="100"
                    summaryScope="Root"
                    isColumnOptionsEnabled="true"
                    columnMovingSeparatorWidth="2">
                   
                    <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>180" />                   
                    <IgrTextColumn field="tankName" headerText="Tank Name" width="*>190" />
                    <IgrNumericColumn field="tankHeight" headerText="Height" width="*>120" />
                    <IgrNumericColumn field="tankWidth" headerText="Width" width="*>120" />
                    <IgrNumericColumn field="tankSize" headerText="Size" width="*>120" />
                    <IgrNumericColumn field="readValueInBbl" headerText="Value In Bbl" width="*>150" />
                    <IgrNumericColumn field="readValue" headerText="Value In Inches" width="*>150" />
                    <IgrTextColumn field="lineCreatedBy" headerText="Created By" width="*>150" />                    
                    <IgrDateTimeColumn field="lineReportDate" headerText="Report Date" width="*>150" />
                    <IgrTextColumn field="facilityID" headerText="Facility ID" width="*>120" />
                    <IgrTextColumn field="tankID" headerText="Tank ID" width="*>120" />
                    <IgrTextColumn field="locationName" headerText="Basin" width="*>150" />
                        <IgrTextColumn field="areaName" headerText="Area Name" width="*>150" />
                        <IgrTextColumn field="subAreaName" headerText="Sub Area" width="*>150" />
                    <IgrTextColumn field="lineComments" headerText="Comments" width="*>250" />
                </IgrDataGrid>

            </div>

        );
    }

    saveWorkbook() {
        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }
        ExcelUtility.save(wb, "TankVolume");
    }
}