import React, { Component, useEffect, useState } from 'react';
import { FormGroup, Row, Button } from 'reactstrap';
import Filter from '../../resources/filter.png';
import {
    apiURL, apiHeader, apiPUTHeader,
    apiPOSTHeader, apiDELETEHeader
} from '../../resources/apiURL';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";
import { FilterExpression } from 'igniteui-react-core';
import { FilterFactory } from 'igniteui-react-core';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();
IgrDataGridToolbarModule.register();


export default class AssetMapListView extends Component {
    static displayName = AssetMapListView.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        //this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onToolbarRef = this.onToolbarRef.bind(this);
        //this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onItemCellUpdating = this.onItemCellUpdating.bind(this);
        this.state = {
            assetData: props.assetData,
            isGridLoaded: false,
            user: props.user
        }
    }

    componentDidUpdate() {


    }

    onToolbarRef(toolbar) {
        this.toolbar = toolbar;
        if (this.toolbar) {
            this.toolbar.targetGrid = this.grid;
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.assetData;

        if (this.toolbar !== null) {
            this.toolbar.targetGrid = this.grid

        }

    }

    applyFilter = () => {
        this.saveGridLayout()
        this.props.toggle()
        this.getSelectedAsset().then(data =>{
            this.props.mapListApplyFiler(data)
           
        })
    }

    getSelectedAsset = () =>{
        const {assetData} = this.state
        return new Promise((res,rej) => {
           const selAsset = assetData.filter(a => a.sel === 1)
            res(selAsset)
        })
    }

    saveGridLayout = () => {
        const { companyID, assetTypeID } = this.state
        if (this.grid !== undefined) {
            //this.state({saveLayout:this.grid.saveLayout()})
            const { user } = this.state

            const addLayoutDTO = {
                "applicationKey": 21,
                "userName": user.account.userName,
                "layoutName": "Asset_" + companyID + '_MapList_' + user.account.userName,
                "layoutData": this.grid.saveLayout(),
                "createdBy": user.account.userName
            }
            let myURI = apiURL + 'AppLayout/AddLayout'
            fetch(myURI, apiPOSTHeader(addLayoutDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;

                        return Promise.reject(error)
                    } else {
                        //console.log("i am here ")
                        //this.closeForm(true)
                    }
                }).catch(error => {
                    console.log("PUT error: " + error)
                })


        }
    }

    loadGridLayout = () => {
        const { companyID, assetTypeID } = this.state

        //console.log('---->loading grid layout------')
        if (this.grid !== undefined) {

            const { user } = this.state
            var myURI = apiURL + 'AppLayout/Grid/21/' + user.account.userName + '/'
            myURI = myURI + "Asset_" + companyID + '_MapList_' + user.account.userName
            //console.log(myURI)
            //myURI = apiURL + 'WOTracker/WO'
            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    // console.log(json)
                    if (json.length > 0) {
                        //console.log("json")
                        this.grid.loadLayout(json[0].layoutData)
                    }
                })
            this.setState({ isGridLoaded: true })
        }
    }

    componentDidMount() {
        const { isGridLoaded } = this.state

        if (!isGridLoaded) {
            console.log("hello hello")
            this.loadGridLayout()

        }

    }

    render() {
        const { assetData } = this.state
        return (<div>
            <FormGroup>
                <Row form>
                    <Button outline
                        style={{ margin: "0", padding: "0 0 0 0" }}
                        color="secondary"

                        className="btn-no-border float-right"
                        onClick={this.applyFilter}>
                        <img src={Filter}
                            alt="Reset"
                            style={{ width: "25px", margin: "0 0 0 0" }} />
                        Apply Filter
                    </Button>
                </Row>
            </FormGroup>
            < FormGroup >
                <Row form style={{ zIndex: 0, position: 'relative' }}>
                    <IgrDataGridToolbar
                        width="100%"
                        ref={this.onToolbarRef}
                        toolbarTitle=""
                        columnChooser="true"
                        columnPinning="true"

                    />
                    <IgrDataGrid

                        ref={this.onGridRef}

                        dataSource={assetData}
                        isGroupExpandedDefault={false}
                        width="100%"
                        height={"40vh"}
                        selectionMode="MultipleRow"
                        isColumnOptionsEnabled="true"
                        isGroupCollapsable="true"
                        filterUIType="FilterRow"
                        useAccessibility="true"
                        //selectedKeysChanged={this.onGridRowSelected}
                        groupHeaderDisplayMode={"split"}
                        scrollable={true}
                        editMode={0}
                        autoGenerateColumns="false"
                        defaultColumnWidth="120"
                        groupSummaryDisplayMode={"RowTop"}
                        summaryScope={"Both"}

                    //shouldComponentUpdate={this.state.shouldComponentUpdate}

                    >

                        <IgrTemplateColumn field="sel" headerText="" width="50"
                            cellUpdating={this.onItemCellUpdating}
                            isEditable="false"
                        />
                      
                        <IgrTextColumn field="asset_name" headerText="Asset name" width="*>200" />                  
                        <IgrTextColumn field="status_id" headerText="Status" width="*>200" />
                        <IgrTextColumn field="basin_name" headerText="Basin" width="*>200" />
                        <IgrTextColumn field="subsystem_name" headerText="Sub System" width="*>200" />                        
                        <IgrTextColumn field="asset_type_desc" headerText="Asset Type" width="150" />
                        
                        {/* <IgrTextColumn field="created_at" headerText="Created At" width="*>200" />
                        <IgrTextColumn field="created_by" headerText="Created By" width="*>200" />
                        <IgrTextColumn field="modified_at" headerText="Modified At" width="*>200" />
                        <IgrTextColumn field="modified_by" headerText="Modified By" width="*>200" /> */}


                        <IgrTextColumn field="assetkey" isHidden={true}

                        />

                        <IgrTextColumn field="asset_type_key" isHidden={true}

                        />
                        <IgrTextColumn field="parent_assetkey" isHidden={true} />


                    </IgrDataGrid>
                </Row>
            </FormGroup >
        </div>)
    }

    lineItemChange = (key, value) => {    
        const {assetData} = this.state
        console.log("-----------------")
        console.log(value)
        //this.updateLineValue(key, 'uomKey', value)
        assetData.map(e => {
            if (e.assetkey === key){                
                e.sel = (value ? 1 : 0)
                return;
            }
            
        })
    }

    onItemCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        var bCreateSel = false

        if (content.childElementCount === 0) {
            //bCreateSel = true
        } else {
            const temp = content.children[0]
            // console.log("temp.id")
            // console.log(temp)
            // console.log(item)

            if (Number(temp.id) !== Number(item.assetkey)) {
                //bCreateSel = true
                temp.remove()
                // console.log("remove")

            }

        }


        if (content.childElementCount === 0) {
            if (s.field === "sel") {
                //link = document.createElement("INPUT");
                var chkActive = document.createElement("INPUT");
                chkActive.setAttribute("type", "checkbox");
                chkActive.setAttribute("id", item.assetkey)
                content.style.display = "inline-block";
                //content.style.display = "inline-grid";
                content.style.fontFamily = "Verdana";
                content.style.fontSize = "12px";
                // content.style.color = "#4286f4";
                content.style.width = "50%";

                content.appendChild(chkActive);

                //chkActive.removeAttribute("checked")
                chkActive.checked = false
                if (item.sel === 1) {
                    //chkActive.setAttribute("checked", "true");
                    chkActive.checked = true
                }
                
                document.getElementById(item.assetkey).addEventListener("change", () =>{
                    this.lineItemChange(item.assetkey, chkActive.checked)
                })
            }
        }




    }

 

}