
import React, { useState } from 'react';
import { Col, Row, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText, FormFeedback } from 'reactstrap';
// import { apiURL, apiHeader } from '../../resources/apiURL';
// 
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import { Form, Card, CardHeader, CardBody, CustomInput, Container } from 'reactstrap';



const SafetyObservationCreateIncident = (props) => {

  const {hidden, id} = props

  //default is First Report
  const [ddval, setddval] = useState(2);
 

  const [modal, setModal] = useState(false);
  const [invalid, setInvalid] = useState(false)
  const [desc, setDesc] = useState("");

  const toggle = () => 
  {
    setDesc("")
    setInvalid(false)
    setModal(!modal);
  }

  const toggleOK = () => {
if (desc==="" || desc === undefined) {setInvalid(true)}
else {

    setModal(!modal)
    // props.onClickOK(ddKey.value)
    // if (ddval === undefined) 
    // {ddval=2}

    props.onClickOK(ddval, desc);


  }
}


  const onTextChange = (fieldName,fieldValue, e) => {
switch (fieldName){
    case "ddkey":
        //setDesc(fieldValue)
        //ddval = fieldValue;
        setddval(fieldValue)
        break;
    case "description":
        setDesc(fieldValue)
        //desc = fieldValue;
        setInvalid(fieldValue === "")
        break;
    default:




}

   

  }




  return (
    <div>
      {/* <Button 
                    // outline = {facilityEventKey > 0 ? false : true} 
                    // color={buttonColor}
                    onClick={toggle} style={{ margin: "0 5px 0 5px" }} >
                Add New Report
            </Button> */}
      <Button 
      // outline color="secondary"
        hidden={hidden}
        id={id}
        disabled={props.disabled}
        className="float-right"
        style={{margin:"0px 10px 0px 0px"}}
        onClick={toggle}>
        {/* <img
          src={Plus}
          alt="new"
          style={{ width: "25px", margin: "0 5px 0 0" }} /> */}
        Create SI
      </Button>
      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}> Create Incident from this Observation</ModalHeader>
        <ModalBody>

          <div>

            <FormGroup row>
              <Col md={12}>
                
                
                    {/* 1 row */}
                    <FormGroup row>

                      <Col md={12}>
                        {/* <Label for="otherInfoSupervisorKey">Supervisor</Label> */}

                        <CustomInput
                          type="select"
                          id="ddKey"
                          name="ddKey"
                          defaultValue={2}
                          //  value={2}
                          //disabled={true}        //{isReadOnly || (safetyObservationKey<0)}
                          onChange={(e) => { onTextChange("ddkey", e.target.value, e) }}
                        >
                          {/* <option value=""></option> */}

                          {props.ddropdown.map(v => <option value={v.typeKey}>{v.typeDesc}</option>)}
                        </CustomInput>




                      </Col>
                      <Col md={12}>
                      </Col>
                      <Col md={12}>
                      <Label for="description">Description</Label>
                      </Col>

                      <Col md={12}>

                                        <Input
                                            invalid={invalid}
                                            type="textarea" name="description" rows={5}
                                            id="description"
                                            placeholder="please enter something here"
                                            // value={data[0].comments}
                                            // onChange={(e) => this.onTextChange("description", e.target.value, e)}
                                            onChange={(e) => { onTextChange("description", e.target.value, e) }}
                                        // defaultValue = {observation[0].hazardDesc}                               
                                        // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                                        // disabled={isReadOnly} 

                                        />





                                    </Col>
                    </FormGroup>









                 
              </Col>


            </FormGroup>

           


          </div>


        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleOK}>OK</Button>
          <Button outline color="primary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SafetyObservationCreateIncident;