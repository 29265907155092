//Created By BN 11/22/21


import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { apiURL, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';


const ModalAddRecord = (props) => {

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        disabled,
        buttonLabel,
        style,
        subform

        //invalid,
        // defaultValue,
        // value
    } = props

    const [modal, setModal] = useState(false);

    const [modalMsg, setModalMsg] = useState(false);
    const [msgBody, setMsgBody] = useState("");
    const [msgHeader, setMsgHeader] = useState("");






    const [invalidField1, setInvalidField1] = useState(false)
    const [invalidField2, setInvalidField2] = useState(false)
    const [invalidField3, setInvalidField3] = useState(false)
    const [invalidField4, setInvalidField4] = useState(false)


    const [Field1, setField1] = useState("")
    const [Field2, setField2] = useState("")
    const [Field3, setField3] = useState("")
    const [Field4, setField4] = useState("")

    const [Field1title] = useState(subform==="customer"?"WD Customer ID":subform==="revenue"?"WD Revenue Category":subform==="site"?"WD Site":"--")
    const [Field2title] = useState(subform==="customer"?"EnergyLink Cust Name":subform==="revenue"?"WD Revenue Category Desc":subform==="site"?"Energy Prop Desc":"--")
    const [Field3title] = useState(subform==="customer"?"WD Customer Name":subform==="revenue"?"WD Reference ID":subform==="site"?"WD Site Name":"--")
    const [Field4title] = useState(subform==="customer"?"customer":subform==="revenue"?"WD Unit of Measure":subform==="site"?"RRC Lease Number":"--")
  

    const toggle = () => {

        setModal(!modal);


        // props.onClickNo()

    }

    const toggleMsg = () => {
        // this.setState({ modalMsg: !this.state.modalMsg })
        setModalMsg(!modalMsg)
    }

    // const toggleNo = () => {
    //     // setModal(! modal);
    //     props.onClickNo()
    // }

    // const toggleYes = (e) => {

    //     if ((props.invalid === false) && (!props.value === ""))
    //     {
    //     setModal(!modal)
    //     props.onClickYes();
    //     }
    //     else 
    //     {
    //         props.onChangeTest(e);
    //     }
    // }


    const toggleYes = (e) => {
        var cont = true
        if (Field1 === "") {
            setInvalidField1(true)
            cont = false
        }

        if (Field2 === "") {
            setInvalidField2(true)
            cont = false
        }

        if (Field3 === "") {
            setInvalidField3(true)
            cont = false
        }

        if (Field4 === "" && subform != "customer" ) {
            setInvalidField4(true)
            cont = false
        }



        if (cont) {
            // props.onClickYes(WDCustomerName, EnergyLinkCustName, WDCusomterID);
            insertRecord(Field1, Field2, Field3, Field4)

            // if (insertOK) {
            //     props.onClickYes();
            //     setModal(!modal)
            // } 


        }



        //    Dragan supreme code is here 
        // if ((props.invalid === false) && (!props.value === ""))
        // {
        // setModal(!modal)
        // props.onClickYes();
        // }
        // else 
        // {
        //     props.onChangeTest(e);
        // }


    }

    // useEffect( () => {
    //     setComment("")
    //     setInvalid(false)
    // },props.dataSelected)


    const onClosed = () => {
        setField1("")
        setField2("")
        setField3("")
        setField4("")

        setInvalidField1(false)
        setInvalidField2(false)
        setInvalidField3(false)
        setInvalidField4(false)

        // if (props.onClosed !== undefined) {
        //     props.onClosed();
        // }
    }





    const onTextChange = (fieldName, fieldValue, e) => {
        switch (fieldName) {




            case "Field1":
                // desc = fieldValue;
                setField1(fieldValue)
                setInvalidField1(fieldValue === "")
                break;
            case "Field2":
                // desc = fieldValue;
                setField2(fieldValue)
                setInvalidField2(fieldValue === "")
                break;
            case "Field3":
                // desc = fieldValue;
                setField3(fieldValue)
                setInvalidField3(fieldValue === "")
                break;
            case "Field4":
                // desc = fieldValue;
                setField4(fieldValue)
                setInvalidField4(fieldValue === "")
                break;




            default:
        }


    }


    const insertRecord = (a, b, c, d) => {
        const itemDTO = createforInsertDTO(a, b, c, d);
        console.log(itemDTO);

        // const putReq = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(itemDTO)
        // }
        var myURI
        if (subform === "customer") {
            myURI = apiURL + 'AP/insertEnergyWDCustMap'
        }

        if (subform === "revenue") {
            myURI = apiURL + 'AP/insertWDRevenueCategory'
        }

        if (subform === "site") {
            myURI = apiURL + 'AP/insertEnergyWDSiteMap'
        }


        fetch(myURI, apiPUTHeader(itemDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        setModalMsg(!modalMsg)
                        setMsgBody(t)
                        setMsgHeader("Error")

                        // this.setState({
                        //     msgBody: t,
                        //     msgHeader: "Error",
                        //     modalMsg: !this.state.modalMsg

                        // })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                props.onClickYes();
                setModal(!modal)
                // setInsertOK(true)
                // this.setState({ txtnewWDCusomterID: '', txtnewEnergyLinkCustName: '', txtnewWDCustomerName: '', showAddNew: false  })
                // this.getData()
                //this.props.refreshData()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    const createforInsertDTO = (a, b, c, d) => {
        // const { txtnewWDCusomterID, txtnewEnergyLinkCustName, txtnewWDCustomerName } = this.state
        // const { user } = this.props
        var dataForInsertDTO
        if (subform === "customer") {
            dataForInsertDTO = {
                "WDCusomterID": a,
                "EnergyLinkCustName": b,
                "WDCustomerName": c
            }
        }

        if (subform === "revenue") {
            dataForInsertDTO = {
                "WDRevenueCategory": a,
                "WDRevenueCategoryDesc": b,
                "WDReferenceID": c,
                "WDUnitofMeasure": d
            }
        }

        if (subform === "site") {
            dataForInsertDTO = {
                "WDSite": a,
                "EnergyPropDesc": b,
                "WDSiteName": c,
                "RRCLeaseNumber": d
            }
        }

        return dataForInsertDTO;
    }



    return (
        <div>
            <Button outline={outline}
                style={style}
                color={color}
                className={className}
                hidden={hidden}
                disabled={disabled}
                onClick={toggle}
            // style={{ margin: "0 5px 0 5px" }} 
            >
                {image}
                {buttonLabel}

            </Button>

            <Modal isOpen={modal}
                toggle={toggle}
                onClosed={onClosed}
            >
                <ModalHeader toggle={toggle}> {formName} {subform}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        {message}

                        <Label style={{ margin: "5px 0 0 0" }}>{Field1title}:</Label>
                        <Input type="text" name="Field1" id="Field1"
                            // defaultValue={props.txtnewWDCusomterID}
                            value={Field1}

                            // rows={3}
                            // defaultValue={meterValDetails.reviewComment}
                            onChange={(e) => { onTextChange("Field1", e.target.value, e) }}
                            invalid={invalidField1}
                        >

                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label style={{ margin: "5px 0 0 0" }}>{Field2title}:</Label>
                        <Input type="text" name="Field2" id="Field2"
                            // defaultValue={EnergyLinkCustName}
                            value={Field2}

                            // rows={3}
                            // defaultValue={meterValDetails.reviewComment}
                            onChange={(e) => { onTextChange("Field2", e.target.value, e) }}
                            invalid={invalidField2}
                        >

                        </Input>

                    </FormGroup>

                    <FormGroup>
                        <Label style={{ margin: "5px 0 0 0" }}>{Field3title}:</Label>
                        <Input type="text" name="Field3" id="Field3"
                            defaultValue={Field3}
                            value={Field3}

                            // rows={3}
                            // defaultValue={meterValDetails.reviewComment}
                            onChange={(e) => { onTextChange("Field3", e.target.value, e) }}
                            invalid={invalidField3}
                        >

                        </Input>

                    </FormGroup>

                    <FormGroup hidden={subform==="customer"}>
                        <Label style={{ margin: "5px 0 0 0" }}>{Field4title}:</Label>
                        <Input type="text" name="Field4" id="Field4"
                            defaultValue={Field4}
                            value={Field4}

                            // rows={3}
                            // defaultValue={meterValDetails.reviewComment}
                            onChange={(e) => { onTextChange("Field4", e.target.value, e) }}
                            invalid={invalidField4}
                        >

                        </Input>

                    </FormGroup>


                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={toggleYes}>Yes</Button>
                    <Button color="primary" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
            <div>
                <Modal isOpen={modalMsg}
                    toggle={toggleMsg}
                    size="lg">
                    <ModalHeader toggle={toggleMsg}>{msgHeader}
                    </ModalHeader>
                    <ModalBody>
                        {msgBody}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={toggleMsg}
                        >
                            OK
                        </Button>
                        {' '}

                    </ModalFooter>
                </Modal>
            </div>
        </div>

    )

}

export default ModalAddRecord;