import React,{Component} from 'react';
import {FormGroup, TabContent, TabPane,Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import '../../custom.css';
import WODashboard from './WODashboard'
import WOSpotfireActiveAndClosed from './WOSpotfireActiveAndClosed';
import WOSpotfireSpend from './WOSpotfireSpend';
import WOSpotfireMTTF from './WOSpotfireMTTF';
import WOSpotfireLabor from './WOSpotfireLabor';
// import spade from '../../resources/spade.png'
export default class WOSpotfireHome extends Component {

    constructor(props){
        super(props);

        this.state ={
            activeTab:'1',
        }

        
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState(() => ({
                activeTab: tab
            }))
        }
    }
    render(){
        const {activeTab} = this.state;

        return (
            <div>

                <FormGroup>

                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '1'})}
                                      onClick={() => {this.toggle('1')}}>
                                <h5><img
                                alt=""
                                style={{ width: "20px", marginBottom:"0px", marginRight:'5px' }} />Summary</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '2'})}
                                      onClick={() => {this.toggle('2')}}>
                                <h5>Active & Closed Detail</h5>
                            </NavLink>
                        </NavItem> 
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '3'})}
                                      onClick={() => {this.toggle('3')}}>
                                <h5>Spend</h5>
                            </NavLink>
                        </NavItem>        
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '4'})}
                                      onClick={() => {this.toggle('4')}}>
                                <h5>MTTF</h5>
                            </NavLink>
                        </NavItem>   

                         <NavItem>
                            <NavLink className={classnames({active: activeTab === '5'})}
                                      onClick={() => {this.toggle('5')}}>
                                <h5>Labor</h5>
                            </NavLink>
                        </NavItem>                        
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">                     
                                <WODashboard user={this.props.user}/>                                                                                  
                        </TabPane>

                        <TabPane tabId="2">
                             <WOSpotfireActiveAndClosed              
                             user={this.props.user}/> 
                                                            
                        </TabPane>
                        
                        <TabPane tabId="3">
                             <WOSpotfireSpend                         
                             user={this.props.user}
                                              />                                                           
                        </TabPane>

                        <TabPane tabId="4">
                             <WOSpotfireMTTF                         
                             user={this.props.user}
                                              />                                                           
                        </TabPane>

                        <TabPane tabId="5">
                             <WOSpotfireLabor                        
                             user={this.props.user}
                                              />                                                           
                        </TabPane>
                    </TabContent>
                </FormGroup>
                
            </div>
        );
    }
}