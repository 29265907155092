
import React,{Component} from 'react';
import {Col, Row, Button, CustomInput,FormGroup, Label} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {apiURL} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'

import '../custom.css';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import EventDetail from './EventDetail'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class FacilityEvent extends Component {

   
    constructor(props){
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);

        const urlPara=this.getURLParameters(props.parameters)

        this.state={
            facEvent:[],
            isLoaded: false,
            startDate: (urlPara.startDate === null? getFirstDayofMonth().toISOString() : urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            endDate: (urlPara.endDate === null ? new Date().toISOString():urlPara.endDate),
            facilityEventKey: 0,
            eventID:'',
            windowHeight:window.innerHeight,
            modal:false,
            facilityKeys: urlPara.facilityKeys,
            priority:urlPara.priority,
            status:urlPara.status,
            showAllFacility:(urlPara.facilityKeys === null ? false : true)
        }
    }
    getURLParameters = (inputPara) => {
        var paraMeters={
            startDate: null ,
            endDate:null ,
            facilityKeys: null ,
            priority:null,
            status:null}

            
        if ( !this.props.location==='undefined' || inputPara!=='undefined'){   
            var query
            if (!this.props.location==='undefined')
            {
                 query = new URLSearchParams(this.props.location.search);
            
            }else{
                 query = new URLSearchParams(inputPara);            
            }
            paraMeters={
                startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
                endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
                facilityKeys:query.get('Facility') ,
                priority:query.get('priority'),
                status:query.get('status')
        }
        
        }
     
        return paraMeters
    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
                
    }

    onLoadStatusChange = (value,e) =>{
        this.getFacEvent(!this.state.showAllFacility)
        this.setState({showAllFacility:!this.state.showAllFacility})
       
    }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.facEvent;
    }

    handleChange=(v,f) => {
        this.setState({
            startDate:v
        })
    }

    handleEndDateChange=(v,f) => {
        this.setState({
            endDate:v
        })
    }

    refreshFacEvent=()=>{
        const {showAllFacility} = this.state
        this.getFacEvent(showAllFacility)    
    }

    getFacEvent=(showAllFacility=false)=>{
        const {facilityKeys, priority,status} = this.state
        let myURI

        if (showAllFacility){
            myURI = apiURL + 'FacilityEvent/startdate/' + 
            this.state.startDate.toString().substring(0, 10) + 
            '/enddate/' + this.state.endDate.toString().substring(0, 10) 
            + '/AzureAD/' + this.props.user.account.userName;
            
        }else{
            myURI = apiURL + 'FacilityEvent/startdate/' + 
            this.state.startDate.toString().substring(0, 10) + 
            '/enddate/' + this.state.endDate.toString().substring(0, 10) 
            + '/MyFacility/AzureAD/' + this.props.user.account.userName;
        }

        console.log(myURI);
        
        fetch(myURI, {modes: 'no-cors'})
        .then(res => res.json())
        .then(json => {
            this.setState({
                isLoaded: true,
                facEvent: (facilityKeys=== null ? json : 
                            json.filter(facList =>(facilityKeys.includes(facList.facilityKey)))
                           ).filter(rec => (priority===null ? true : rec.priorityKey==priority) &&
                                            (status === null ? true : rec.statusKey==status))
                
            })
            //console.log(json)
        });

        //this.grid.dataSource = this.state.facEvent;
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            console.log(dataItem)
            
            this.setState({facilityEventKey: dataItem.facilityEventKey,
                            eventID: dataItem.eventID})       
            this.toggle()
            //this.onVolRefresh()    
        } 
    }

    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.refreshFacEvent();
    }

    render(){
        var {facEvent, isLoaded, windowHeight,facilityKeys,
            facilityEventKey,showAllFacility,eventID} = this.state
        const gridHeight=(windowHeight * 0.7) + "px"
        const searchText = window.$searchText.toLowerCase()
       
        if (!isLoaded){
            return(<div>
                <h3>Loading facility Events ......</h3>
            </div>)
        }else
        return(
            <div>
                <FormGroup>
                    <Row form>
                        <h2> Facility Events | </h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.refreshFacEvent}>
                            <img 
                            src={Refresh}
                            alt="Refresh"
                            style={{width:"30px", margin:"0 5px 0 0"}}/>
                            Refresh
                        </Button>

                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.saveWorkbook}>
                                    
                            <img 
                                src={Excel}
                                alt="Excel"
                                style={{width:"50px", margin:"0 0 0 0"}}
                            />
                            Export To Excel
                        </Button>

                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.newEvent}>
                                    
                            <img 
                                src={Plus}
                                alt="newEvent"
                                style={{width:"25px", margin:"0 5px 0 0"}}
                            />
                            New Event
                        </Button>

                        <FormGroup  style={{margin:"10px 0 0 100px"}} >
                        <h6>
                        <CustomInput className="float-right" 
                                    hidden={(facilityKeys === null ? false : true)}                                   
                                    type="switch" 
                                    id="accept" 
                                    name="accept" 
                                    label="Show All Facilities"                                   
                                    checked={showAllFacility} 
                                    onClick={(e) => this.onLoadStatusChange(1,e)} />
                                    </h6>
                                    </FormGroup>

                    </Row>
                    <ColoredLine color="grey"/>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <Label style={{ margin:"0 0 0 0", padding:"0"}} >
                                Start Date
                            </Label>
                            <DatePicker value={this.state.startDate}
                                        onChange={(v,f) => this.handleChange(v,f)} />
                        </Col>

                        <Col md={3}>
                            <Label style={{ margin:"0 0 0 0", padding:"0"}}>
                                End Date
                            </Label>
                            <DatePicker value={this.state.endDate} 
                                        onChange={(v,f) => this.handleEndDateChange(v,f)}/>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}
                        width="100%"
                        dataSource={facEvent.filter(rec => ((rec.createdByName || '').toLowerCase().includes(searchText)) ||
                                                            (rec.facilityName || '').toLowerCase().includes(searchText) ||
                                                            rec.eventID.toLowerCase().includes(searchText) ||
                                                            (rec.eventPriorityDesc || '').toLowerCase().includes(searchText) ||
                                                            (rec.areaName || '').toLowerCase().includes(searchText) ||
                                                            (rec.facEventCategoryDesc || '').toLowerCase().includes(searchText) ||    
                                                            (rec.eventSubTypeDesc || '').toLowerCase().includes(searchText) ||  
                                                            (rec.eventDesc || '').toLowerCase().includes(searchText) ||                                                          
                                                            (rec.comments || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}>                      
                        
                        <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>180" />
                        <IgrTextColumn field="eventID" headerText="Event ID" width="*>140" />

                        <IgrTemplateColumn field="eventDate" headerText="Event Date" width="*>130"                                           
                                            cellUpdating={this.onReportDateUpdating}/>
                        <IgrTextColumn field="eventStatusDesc" headerText="Status" width="*>130" />
                        
                         <IgrNumericColumn field="eventCost" headerText="Event Cost" width="*>130"/>

                        <IgrTextColumn field="eventPriorityDesc" headerText="Priority" width="*>120" />
                        <IgrTextColumn field="facEventCategoryDesc" headerText="Category" width="*>180" />
                        <IgrTextColumn field="eventTypeDesc" headerText="Type" width="*>140" />
                        <IgrTextColumn field="eventSubTypeDesc" headerText="Sub Type" width="*>140" />
                       
                        <IgrTextColumn field="eventDesc" headerText="Description" width="*>210" />
                       
                        <IgrTextColumn field="createdByName" headerText="Created By" width="*>150" />                                   
                        <IgrTextColumn field="comments" headerText="Comments" width="*>450"/>
                        <IgrTextColumn field="areaName" headerText="Area" width="*>130" />
                        <IgrTextColumn field="locationName" headerText="Basin" width="*>130" />
                        
                        <IgrNumericColumn field="facilityEventKey" isHidden/>

                    </IgrDataGrid>
                </FormGroup>
                
                <div>
                <Modal  isOpen={this.state.modal} toggle={() => this.toggle()} 
                    //contentClassName="eventDetail-modal-style"
                    size="lg"
                    >
                    <ModalHeader toggle={() => this.toggle()}>  {"Event Details - " + eventID} </ModalHeader>
                    <ModalBody>
                        <EventDetail FacilityEventKey={facilityEventKey}
                                     user={this.props.user}
                                     toggle={this.toggle}                                
                                     refreshData={this.getFacEvent}>
                            
                        </EventDetail>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="primary" className="mr-auto">Attachments</Button>
                        
                        <Button color="primary" onClick={() => this.toggle()}>OK</Button>
                        <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>
                    </ModalFooter> */}
                </Modal>
                </div>

            </div>
        );
    }

    newEvent=()=>{
        this.setState({facilityEventKey: -1})       
        this.toggle()
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "FacilityEvents");
    }



    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.eventDate).toLocaleDateString("es-PA");
    }
 

}