import React, { Component } from 'react';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputGroup, InputGroupAddon, InputGroupText, Input,CustomInput } from 'reactstrap';
import MenuIcon from '../resources/menu.png';
import '../resources/DockManagerStyles.css'
import {apiURL,apiHeader} from '../resources/apiURL';

import { IgcDockManagerComponent, IgcContentPane } from '@infragistics/igniteui-dockmanager';
import { IgcDockManagerPaneType, IgcSplitPaneOrientation } from '@infragistics/igniteui-dockmanager';
import { defineCustomElements } from '@infragistics/igniteui-dockmanager/loader';
import OperatorSummary from './OperatorSummary'
import MeterVolumeChart from './MeterVolumeChart';
import OperationChart from './OperationChart';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrInputModule } from 'igniteui-react-inputs';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';

import { FilterFactory } from 'igniteui-react-core';
import { ConsolidatedItemsPosition_$type } from 'igniteui-react-charts';
import InspectionDashBoardComment from './InspectionDashBoardComment';
import InspectionDashBoardLink from './InspectionDashBoardLink';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();

defineCustomElements();

var DatePicker = require("reactstrap-date-picker");
var filterFac=[];
    
export default class OperatorDashboard extends Component {
  

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        //this.onMyFacilityUpdating = this.onMyFacilityUpdating.bind(this);
        this.onLoad = this.onLoad.bind(this);
        //this.onLoad = this.onLoad.bind(this);

        this.state = {
            facility:[],    
            myFacility:[],                  
            windowHeight: window.innerHeight,
            startDate: getFirstDayofMonth().toISOString() ,
            endDate: new Date().toISOString(),
            facilityKeys:[],
            isLoaded:false,
            facilitySummary:[],
            selectedFacility:0,
            dayCounts:0,
            faciltyViewList:'my',
            onFormLoad:false,
            selectedValue:{"keys":[],"level":-1,"values":[],"key":-1},
            isSelected:false     
        }
    }

   
    
    handleResize = (e) => {
        console.log("resize: ")
        this.setState({ windowHeight: window.innerHeight });
    };

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    handleChange=(v,f) => {
        this.setState({
            startDate:v
        })
    }

    handleEndDateChange = (v,f) => {
        this.setState({
            endDate:v
        })
    }

   
    removeMyFacility = (fKey) => {

    }

    addMyFacility = (fKey) => {

    }


    getOpDashboard = () => {
        const {selectedValue,startDate,endDate} = this.state

        selectedValue.startDate = startDate
        selectedValue.endDate= endDate

        console.log("selectedValue------")
        console.log(selectedValue)
        
        this.setState({selectedValue:selectedValue})

    }


    // componentDidMount(){
    //     window.addEventListener("resize", this.handleResize);
    //     this.getOpDashboard();
    // }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.facility;
        this.onLoad()
    }

    onLoad() {
        console.log("here on load --------------------------")
        if (this.grid !== undefined){
        const location = new IgrColumnGroupDescription();
        location.field = "locationName";
        location.displayName = "Basin";

        const areaName = new IgrColumnGroupDescription();
        areaName.field = "areaName";
        areaName.displayName = "Area";
        const subArea = new IgrColumnGroupDescription();
        subArea.field = "subAreaName";
        subArea.displayName = "";
        
        this.grid.groupDescriptions.add(location);
        this.grid.groupDescriptions.add(areaName);
        this.grid.groupDescriptions.add(subArea);
        
        //this.grid.isGroupExpandedDefault="false"
        
        }
    }


    onGridRowSelected(s, e) {
        const {selectedValue}=this.state
        console.log(" On CLick")
        const content = e.content;
        
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            console.log("dataItem")
            console.log(dataItem)
            
            if (dataItem.level != null){
                // console.log("dataItem.level")
                // console.log(dataItem.level)
                // console.log(dataItem.values[dataItem.level])
                var reload = false, key = -1,areaKey = -1
               

                key = this.getSelectedKey(dataItem)
                reload = (selectedValue.key != key)

                if (key === null){
                    areaKey = this.getSelectedAreaKey(dataItem)
                }

                selectedValue.level = dataItem.level
                selectedValue.keys = dataItem.keys
                selectedValue.values  = dataItem.values
                selectedValue.key = key // this.getSelectedKey(selectedValue)
                selectedValue.reloadData=reload
                selectedValue.startDate=this.state.startDate
                selectedValue.endDate = this.state.endDate
                selectedValue.faciltyViewList=this.state.faciltyViewList
                selectedValue.areaKey = areaKey
                selectedValue.hasSubArea = this.hasSubArea(dataItem)
                
                console.log("isReload")
                console.log(reload)

                this.setState({selectedValue: selectedValue,
                                isSelected:true})
                
            }else{
                var reload = false
                reload = (selectedValue.key != dataItem.facilityKey)

                selectedValue.level = 3
                selectedValue.keys = ["locationName","areaName","subAreaName"]
                selectedValue.values  = [dataItem.locationName,dataItem.areaName,dataItem.subAreaName]
                selectedValue.key = dataItem.facilityKey // this.getSelectedKey(selectedValue)
                selectedValue.reloadData=reload
                selectedValue.startDate=this.state.startDate
                selectedValue.endDate = this.state.endDate
                selectedValue.faciltyViewList=this.state.faciltyViewList
                selectedValue.areaKey = dataItem.facilityAreaKey
                selectedValue.hasSubArea = false
                
                console.log("dataItem.facilityName")
                console.log(selectedValue)

                this.setState({selectedValue: selectedValue,
                    isSelected:true})
            }
            
        
        }else{
            // const tempList=[]
            // this.selectedFacilityChanged(tempList)
            // console.log("----------it was here----------------")
        } 
    }

    hasSubArea = (v) => {
        const {facility} = this.state;

        const filterData = facility.filter(rec => 
            (rec.locationName || '').includes(v.values[0]) 
             && (rec.areaName || '').includes(v.values[1])
             && (rec.subAreaName || '') != ''  
        )
     
        console.log("filterData")
        console.log(filterData)
        
        if (filterData.length >0 )
        {
           return true           
        }
        
        return false
       
    }
    
    getSelectedAreaKey = (v) => {
        
        const {facility} = this.state;

        const filterData = facility.filter(rec => 
            (rec.locationName || '').includes(v.values[0]) 
             && (rec.areaName || '').includes(v.values[1])  
        )
     
        console.log("filterData")
        console.log(filterData)
        
        if (filterData.length >0 )
        {
           return filterData[0].facilityAreaKey           
        }
        
        return -1
       
    }

    getSelectedKey = (v) => {
        
        const {facility} = this.state;

        const filterData = facility.filter(rec => 
            (rec.locationName || '').includes(v.values[0]) 
             && (rec.areaName || '').includes(v.values[1])  
             && (rec.subAreaName || '').includes(v.values[2] || ''
             )  
        )
     
        console.log("filterData")
        console.log(filterData)
        
        if (filterData.length >0 )
        {
            if (v.level == 0){
                return filterData[0].locationKey
            }else if (v.level == 1){
                return filterData[0].facilityAreaKey
            }else {
                return filterData[0].facilitySubAreaKey
            }
        }
        
        return -1
       
    }

    getFacilityData = () =>{
        this.setState({
            onFormLoad:true
        })

        if (this.state.faciltyViewList === 'my')
        {
            console.log("getMyFacilityData")  
            this.getMyFacilityData();
        }
        else {         
            console.log("come here and should not")   
           this.getAssignFacility();
        }    
        
    }

    getAssignFacility = () => {

        let myURI = apiURL + 'EmployeeDetails/Facility/AzureAD/' + this.props.user.account.userName 

        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                facility: json
            })
        })

    }

    getMyFacilityData = () => {
        let myURI = apiURL + 'EmployeeDetails/MyFacility/AzureAD/' + this.props.user.account.userName 
        
        fetch(myURI, apiHeader('GET'),{modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                facility: json,
                myFacility: json
            })
        })        
        this.onLoad();
    }

    selectedFacilityChanged = (facilityKeys) => {
        //this.setState({facilityKeys:facilityKeys});
    }

    onFacilityRadioChanged= (e) => {        
        //console.log("Facilitytype: " + e.target.value)
        const {selectedValue} = this.state
        selectedValue.faciltyViewList=e.target.value

        this.setState({faciltyViewList:e.target.value,
                      selectedValue:selectedValue})
        if (e.target.value === "my"){
           this.getMyFacilityData();
        }else {
            this.getAssignFacility();
        }
    }

    resetFilter = () =>{
        for (var i=0; i<this.grid.actualColumns.count; i++){
            this.grid.actualColumns.item(i).filter=null
        }
        this.grid.selectedItems.clear()
        filterFac = []
    }
  

    render() {
        var { windowHeight,facilitySummary,facility } = this.state;
        const {isLoaded,  selectedFacility, dayCounts,
                onFormLoad,selectedValue,
            startDate,endDate,isReload } = this.state ;

        const frameHeight = (windowHeight * 0.83) + "px";
  
        const gridHeight=(windowHeight *  0.73) + "px"
        var searchText = window.$searchText.toLowerCase();
        
        console.log("facility")
        console.log(facility)
   
        if (!isLoaded){
            return(<div>Loading
                 <igc-dockmanager id="dockManager">

                 </igc-dockmanager>
            </div>)
        }else
        return (<div style={{ height: frameHeight }}>
            <FormGroup>
                <Row form>
                    <h3> Inspection Dashboard | </h3>
                    <Col md={3}>
                        <FormGroup row>
                            <Label for="startDate" sm={4} style={{padding:"5px 0 0 20px"}}>Start Date</Label>
                            <Col sm={8}>
                            <DatePicker value={this.state.startDate}
                            onChange={(v, f) => this.handleChange(v, f)} />
                            </Col>
                        </FormGroup>

                    </Col>

                    <Col md={3}>
                        <FormGroup row>
                            <Label for="endDate" sm={4} style={{padding:"5px 0 0 20px"}}>End Date</Label>
                            <Col sm={8}>
                                <DatePicker value={this.state.endDate}
                                    onChange={(v, f) => this.handleEndDateChange(v, f)} />
                            </Col>
                        </FormGroup>

                    </Col>

                    <Button outline
                        style={{margin: "0 0 20px 20px", padding:"0 0 0 0" }}
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.getOpDashboard}>
                        <img src={MenuIcon}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                            Refresh
                    </Button>

                    <Button outline
                        style={{margin: "0 0 20px 20px", padding:"0 0 0 0" }}
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.resetFilter}>
                        <img src={MenuIcon}
                            alt="Reset"
                            style={{ width: "30px", margin: "0 0 0 0" }} />
                            Reset Filter
                    </Button>

                    

                </Row>

                {/* <h5>{selectedFacility} {(selectedFacility>1 ? 'facilites' : 'facility' )} selected for {dayCounts} days</h5>
                 */}
                <ColoredLine color="grey"/>
                
            </FormGroup>

            <igc-dockmanager id="dockManager">

                <div className="dockManagerContent"
                    slot="facility"
                    id="facilityListContainer" 
                    style={{padding:"5px 0px 5px 5px"}}>                    
                    <FormGroup>                        
                        
                            <div>
                            <CustomInput type="radio" 
                                        id="allFacility" 
                                        name='facility' 
                                        label='All Facilities' 
                                        value='all'
                                        checked = {this.state.faciltyViewList=='all'}
                                        onChange={this.onFacilityRadioChanged} 
                                        inline />                 
                            <CustomInput type="radio" id="myFAcility" 
                                         name='facility' label='My Facilities' 
                                         value='my' 
                                         checked = {this.state.faciltyViewList=='my'}
                                         onChange={this.onFacilityRadioChanged} 
                                         inline/>
                           
                            </div>
                            
                    </FormGroup>

                    <FormGroup>
                        <IgrDataGrid                        
                            ref={this.onGridRef}
                            height={gridHeight}                     
                            dataSource={facility}
                            isColumnOptionsEnabled="true"
                            autoGenerateColumns="false"                                   
                            selectionMode="SingleRow"
                            isGroupCollapsable="true"
                            isGroupExpandedDefault="true"
                            isHeaderSeparatorVisible="false"
                            groupHeaderDisplayMode = "split"
                            selectedKeysChanged={this.onGridRowSelected}                          
                            >         
 
                            <IgrTextColumn field="facilityName" headerText="" width="*>190"/>                        
                            <IgrTextColumn field="subAreaName" headerText="" width="*>130" />
                            <IgrTextColumn field="areaName" headerText="" width="*>130"/>
                            
                                                               
                            <IgrNumericColumn field="facilityKey" isHidden/>

                        </IgrDataGrid>
                </FormGroup>

                </div>


                <div className="dockManagerContent"
                    slot="comment"
                    id="productivityChartContainer">
                    
                    <InspectionDashBoardComment user={this.props.user}
                                                selectedValue={selectedValue}
                                                startDate={startDate}
                                                endDate={endDate}
                                                linkDataLevel={0}/>

                    
                    {/* <OperatorSummary FacilitySummary={facilitySummary} user={this.props.user}/> */}

                </div>

                <div className="dockManagerContent"
                    slot="link"
                    id="oprationChartConainer" 
                    >
                  
                  <InspectionDashBoardLink user={this.props.user}
                                           selectValue={selectedValue}
                                           startDate={startDate}
                                           endDate={endDate}/>

                </div>

                <div className="dockManagerContent"
                    slot="volumeChart"
                    id="volumeChartContainer"
                    hidden>
                    <MeterVolumeChart />
                </div>

            </igc-dockmanager>
        
        
        </div>
        )
    }

    isMyFacility = (k) => {
        if (this.state != null){
        const {myFacility} = this.state;
        const myFac = myFacility.filter(f => f.facilityKey == k)
        // console.log("myFac.length")
        // console.log(myFac.length > 0)
        return (myFac.length > 0)
        }else return false;
    }

    saveMyFacility(e) {
        console.log("fKey-----------------------")
        console.log(e.target.id + " is "+ e.target.checked)
    }


    componentDidMount() {
      
        this.getFacilityData();
        this.onLoad();

        window.addEventListener("resize", this.handleResize);
        window.addEventListener('load', this.onLoad);
    

        

        var { dayCounts,selectedFacility } = this.state;
        console.log('daycount:' + dayCounts)
        const {isLoaded } = this.state ;

        const facilityPane = this.createContentPane('facility', 'Facility', 200)
        facilityPane.allowClose = false
        facilityPane.allowPinning = false
        facilityPane.allowDocking = false
        facilityPane.allowFloating = false
        facilityPane.allowMaximize = false
       // facilityPane.maximizeButton = false

        const linkPane = this.createContentPane('link', '', 100)
        linkPane.allowClose = false
        linkPane.allowPinning = false
        linkPane.allowDocking = false
        linkPane.allowFloating = false
        linkPane.maximizeButton = false
        const summaryPane = this.createContentPane('comment', 'Comment', 200)
        summaryPane.allowClose = false
        summaryPane.allowPinning = false
        summaryPane.allowDocking = false
        summaryPane.allowFloating = false
        summaryPane.maximizeButton = false

        const graphVolumePane = this.createContentPane('volumeChart', 'Meter Volume Chart', 100)
     

        var dockManager = document.getElementById("dockManager");
       

        if (dockManager!==null){
            dockManager.addEventListener('splitterResizeEnd', ev => console.log(ev.detail));
        dockManager.layout = {
            rootPane: {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                panes: [
                    {
                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 80,
                        panes: [facilityPane]
                    },
                    {                        
                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.horizontal,
                        size: 300,
                        panes: [{
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.vertical,
                            size: 50,
                            panes: [summaryPane]
                        },
                        {
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.horizontal,
                            size: 80,
                            panes: [linkPane]
                            //panes: [graphPane, graphVolumePane]
                        }
                        ]
                    }
                ]
            }

            
        };
    }


    }

    createContentPane(contentID, paneHeader, size) {
        const pane = {
            size: size,
            header: paneHeader,
            type: IgcDockManagerPaneType.contentPane,
            contentId: contentID
        };
        return pane;
    }

    createSplitPane(orientation, contentPanes, size) {
        const pane = {
            type: IgcDockManagerPaneType.splitPane,
            orientation: orientation,
            panes: contentPanes,
            size: size
        };
        return pane;
    }
    createTabPane(orientation, contentPanes, size) {
        const pane = {
            type: IgcDockManagerPaneType.documentHost,
            size: size,
            rootPane: {
                type: IgcDockManagerPaneType.splitPane,
                orientation: orientation,
                panes: [
                    {
                        type: IgcDockManagerPaneType.tabGroupPane,
                        panes: contentPanes
                    }
                ]
            }
        };
        return pane;
    }
}