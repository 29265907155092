import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import BarChart from '../resources/barChart.svg'
import EditIcon from '../resources/edit.png';
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'
import ConstructionReportingDetails from './ConstructionReportingDetails'
import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
// //IgrInputModule.register();
//IgrDatePickerModule.register();


var DatePicker = require("reactstrap-date-picker");

export default class ConstructionReporting extends Component {
    //static displayName = AFE.name;


    constructor(props) {
        super(props);
        document.title = "WaterBridge Portal - AFE Manager"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);
        
        const urlPara = this.getURLParameters(props.location)

        this.state = {
            constructionReportKey: (urlPara.constructionReportKey === null ? 0 : urlPara.constructionReportKey),
            afeStatusDesc: "",
            afeNo: "",
            afeName: "",
            isDataLoaded: false,
            windowHeight: window.innerHeight,
            AFEData: [],
            fullAFEData: [],
            empList: [],
            isEmpListLoaded: false,
            budgetList: [],
            isBudgetLoaded: false,
            facilityList: [],
            isFacilityLoaded: false,
            costTypeList: [],
            isCostTypeLoaded: false,
            afeTypeList: [],
            isAFETypeLoaded: false,
            budgetClassList: [],
            isBudgetClassLoaded: false,
            uomList: [],
            isUOMListLoaded: false,
            linkAFEList: [],
            isLinkAFELoaded: false,
            basinList: [],
            isBasinLoaded: false,
            countyList: [],
            isCountyLoaded: false,
            afeStatusList: [],
            selConstructionReportStatus: [],
            selBasin: [],
            selManager: [],
            selCostTypeList: [],
            gridSearch: '',
            isAFEStatusList: false,
            dropdownOpen: false,
            dropdownOpenManage: false,
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            modalProjectDetails: false,
            searchText: "",
            openAFEDetails: !(urlPara.afeHeaderKey === null),
            isAFEAdmin: false,
            isSecurityLoaded: false,
            afeBudgetListURL: [],
            projectStatusList: [],
            isProjectStatusListLoaded: false,
            areaList: [],
            isAreaListLoaded: false,
            dupAFEList:[],
            isDupAFEListLoaded:false,
            afeQueryList: [{
                "label": "My AFEs",
                "value": 1
            }, {
                "label": "All AFEs",
                "value": 2
            }],
            selAFEQuery: [], 
            closeoutStatusKey: 0,
            modalReport: false,
            reportName: "",
            statusKey: 0,
            managerList: [],
            constructionReports: [],
            constructionReportsFiltered: [],
            consturctionReportStatusList: [],
            afeList: [],
            isSecurityAdminLogin: false,
            user: this.props.user,
        }
    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }
    

    getURLParameters = (inputPara) => {

        var paraMeters = {
            afeHeaderKey: null
        }
        var query
        if (inputPara !== undefined) {
            query = new URLSearchParams(inputPara.search);
            console.log("query")
            console.log(query.get('key'))

            paraMeters = {
                constructionReportKey: +query.get('key')
            }

            console.log("paraMeters")
            console.log(paraMeters.constructionReportKey)
        }

        return paraMeters

    }

    toggleAFEDetails = () => {
        this.setState({
            modalProjectDetails: !this.state.modalProjectDetails
        })
    }

    closeDetailsForm = async(edited) => { 

       if (edited != undefined && edited != null && edited === true)
       {
            console.log("edited: " + edited);
            await this.getFitlerAFE2(this.state.constructionReports, this.state.selManager, this.state.selBasin, this.state.selConstructionReportStatus,this.state.selCostTypeList,this.state.gridSearch);
       }
        
        this.setState({ modalProjectDetails: !this.state.modalProjectDetails, });
    }

    setAFEDetailsFormHeader = (afeNo, afeStatusDesc, afeName, afeHeaderKey, closeoutStatusDesc) => {
        this.setState({
            afeNo: afeNo,
            afeStatusDesc: afeStatusDesc,
            afeName: afeName,
            afeHeaderKey: afeHeaderKey,
            closeoutStatusDesc: closeoutStatusDesc
        })
    }

    onGridRowSelected(s, e) {
        //  console.log(" On CLick")
        const content = e.content;
        
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                constructionReportKey: dataItem.constructionReportKey
                // afeStatusDesc: dataItem.afeStatusDesc,
                // afeNo: dataItem.afeNo,
                // //modalAFEDetails: !this.state.modalAFEDetails,
                // afeHeaderKeyReport: dataItem.afeHeaderKey,
                // closeoutStatusKey: dataItem.closeoutStatusKey,
                // statusKey: dataItem.statusKey
            }) 
        } 
        
    }

    onGridRowSelected2(s,e) {
        if (e.isDoubleClick){
            this.setState({ modalProjectDetails: !this.state.modalProjectDetails  });
        }
      }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        //this.grid.dataSource = this.state.AFEData;
        this.grid.dataSource = this.state.constructionReportsFiltered;
    }


    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
        });

    }

    getCounty = () => {

        let myURI = apiURL + 'AFE/County'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    countyList: this.createCountyList(json),
                    isCountyLoaded: true
                })
            });
    }
    createCountyList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.countyDesc
            e.label = e.countyState
            tempList.push(e)
        })

        return tempList
    }

    getAFEStatus = () => {
        let myURI = apiURL + 'AFE/StatusFilter'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeStatusList: this.createAFEStatusList(json),
                    isAFEStatusLoaded: true
                })
            });
    }

    createConstructionReportStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.consturctionReportStatusKey
            e.label = e.consturctionReportStatusDesc
            tempList.push(e)
        })

        return tempList
    }


    getBasin = () => {

        //let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName
        let myURI = apiURL + 'AFE/Location' // + this.props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }


    getUOMList = () => {
        let myURI = apiURL + 'AFE/UOM'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    uomList: this.createUOMList(json),
                    isUOMListLoaded: true
                })
            });
    }



    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }

    getAFEBudgetListURL = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/AFEBudgetListURL'

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    afeBudgetListURL: json
                })
            });
    }



    createUOMList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.uomKey
            e.label = e.uomDesc
            tempList.push(e)
        })

        return tempList
    }

    getAssoAFE = () => {
        let myURI = apiURL + 'ConstructionReporting/AFE'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    linkAFEList: this.createLinkAFEList(json),
                    isLinkAFELoaded: true
                })
            });
    }

    createAFEList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeHeaderKey
            e.label = e.afeNo + ' - ' + e.afeName
            tempList.push(e)
        })

        return tempList
    }


    getBudgeClass = () => {
        let myURI = apiURL + 'AFE/BudgetClass'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetClassList: this.createBudgetClassList(json),
                    isBudgetClassLoaded: true
                })
            });
    }



    createBudgetClassList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetClassKey
            e.label = e.afeBudgetClassDesc
            tempList.push(e)
        })

        return tempList
    }

    getAFEType = () => {
        let myURI = apiURL + 'AFE/Type'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeTypeList: this.createAFETypeList(json),
                    isAFETypeLoaded: true
                })
            });
    }

    createAFETypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeTypeKey
            e.label = e.afeTypeDesc
            tempList.push(e)
        })
        return tempList
    }

    getAFECostType = () => {
        let myURI = apiURL + 'AFE/LineType'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    costTypeList: this.createCostTypeList(json),
                    isCostTypeLoaded: true
                })
            });
    }

    

    createCostTypeList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeLineTypeKey
            e.label = e.afeLineTypeDesc
            tempList.push(e)
        })

        return tempList
    }

    getFacility = () => {
        const { user } = this.props
        let myURI = apiURL + 'EmployeeDetails/myfacility/AzureAD/' + user.account.userName

        //console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    facilityList: this.createFacilityList(json),
                    isFacilityLoaded: true
                })
            });
    }

    createFacilityList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.facilityKey
            e.label = e.facilityName
            tempList.push(e)
        })

        return tempList
    }

    getBudget = () => {

        let myURI = apiURL + 'AFE/Budget'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    budgetList: this.createBudgetList(json),
                    isBudgetLoaded: true
                })
            });
    }

    createBudgetList = (v) => {
        const tempList = []
        v.map(e => {
            e.value = e.afeBudgetDesc
            e.label = e.afeBudgetDesc
            tempList.push(e)
        })

        return tempList
    }

    getConstructionReports = async() => { 
        
        this.setState({ 
            constructionReports: [],
            constructionReportsFiltered: [],
            //selBasin: [],
            //selConstructionReportStatus: [],
            //selManager: [],
            //selCostTypeList: [],
            //gridSearch: '' 
        });

        //await this.getAFEConstructionReporting();
        await this.getFitlerAFE2(this.state.constructionReports, this.state.selManager, this.state.selBasin, this.state.selConstructionReportStatus,this.state.selCostTypeList,this.state.gridSearch) 
    }


    async loadView(){
        let myURI = apiURL + 'ConstructionReporting/load'

        console.log("myURI");
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    //basinList: this.createBasinList(json.basinInformation),
                    //costTypeList: this.createCostTypeList(json.projectType),
                    managerList: this.createEmpList(json.employeesDetails),
                    //consturctionReportStatusList: this.createConstructionReportStatusList(json.consturctionReportStatus),
                    afeList: this.createAFEList(json.afeList), 
                    searchText: "",
                    //isDataLoaded: true,
                }) 
            });
    }

    async getConstructionReportStatusList(){
        let myURI = apiURL + 'ConstructionReporting/ConstructionReportStatusList' 

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                     consturctionReportStatusList: this.createConstructionReportStatusList(json)  
                }) 
            });
    }

    async getConstructionReportProjectTypeList(){
        let myURI = apiURL + 'ConstructionReporting/ConstructionReportProjectTypeList' 

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    costTypeList: this.createCostTypeList(json),
                }) 
            });
    }

    async getConstructionReportBasinInformation(){
        let myURI = apiURL + 'ConstructionReporting/ConstructionReportBasinInformation' 

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json)
                }) 
            });
    }


    getAFEConstructionReporting = async()=>{
        let myURI = apiURL + 'ConstructionReporting'

        console.log("myURI");
        console.log(myURI);
        let constructionReports = [];
        let selConstructionReportStatus = [];
        
        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({ 
                    constructionReports: json,
                    constructionReportsFiltered: json,
                    searchText: ""
                })
            });
    }
    
    getAFEConstructionReportingDefault = async()=>{
        let constructionReports = [];
        let selConstructionReportStatus = [];
        let selManager = [];
        let selCostTypeList = [];
        let {consturctionReportStatusList,managerList} = this.state;
        selConstructionReportStatus = consturctionReportStatusList.filter(x => x.value == 1 || x.value == 2);
        
        this.setState({ selConstructionReportStatus: selConstructionReportStatus  })
        

        let myURI = apiURL + 'ConstructionReporting/ByUser/' + 
                            this.state.user.account.userName + "/ / / / /1"  
                            //this.state.user.account.userName + "/" + null + "/" + null + "/" + null +  "/" + null + "/1"  
        console.log("myURI");
        console.log(myURI);
        
        
        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => { 
                
                this.setState({
                    constructionReports: json,
                    constructionReportsFiltered: json,
                    selConstructionReportStatus: selConstructionReportStatus
                }) 
            });  
    
    }


    async getConstructionReportDetails() {
        
        let {constructionReportKey,constructionReports,constructionReportsFiltered} = this.state;

        if (constructionReportKey <= 0){
            return;
        }

        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey

        let constructionReportDTO = null;

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                 constructionReportDTO = json  
            });

        if (constructionReportDTO != null){
            let obj = constructionReportsFiltered.filter(x => x.constructionReportKey == constructionReportKey);
            if (obj !== null && obj.length > 0){
                obj[0].expectedCompletionDate =  constructionReportDTO.expectedCompletionDate;
            
                //return;
            } 
        } 
        
        this.setState({constructionReportsFiltered: constructionReportsFiltered});
    }

    getDefaultAFEList = (e) => {
        console.log("this.state.selBasin")
        console.log(this.state.selBasin)
        console.log("this.state.selAFEStatus")
        console.log(this.state.selAFEStatus)

        this.getFitlerAFE(e, this.state.selAFEQuery, this.state.selBasin, this.state.selAFEStatus)
    }

    getProjectStatus = () => {
        let myURI = apiURL + 'AFE/ProjectStatus'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    projectStatusList: this.createProjectStatusList(json),
                    isProjectStatusListLoaded: true
                })
            });
    }

    createProjectStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.projectStatusKey
            e.label = e.projectStatusDesc
            tempList.push(e)
        })

        return tempList
    }

    getArea = () => {
        let myURI = apiURL + 'AFE/Area'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    areaList: this.createAreaList(json),
                    isAreaListLoaded: true
                })
            });
    }
    createAreaList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.areaKey
            e.label = e.areaName
            tempList.push(e)
        })

        return tempList
    }

    getEmpList = () => {

        let myURI = apiURL + 'EmployeeDetails'

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    empList: this.createEmpList(json),
                    isEmpListLoaded: true
                })
            });
    }

    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.employeeKey
            e.label = e.empName
            tempList.push(e)
        })

        return tempList
    }

    getDupAFE = () => {
      
        const { user } = this.props

        let myURI = apiURL + 'AFE/Header/AFEDuplicate/AzureAD/' + user.account.userName

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    dupAFEList: this.createDupFEList(json),
                    isDupAFEListLoaded:true
                })
            });
    }

    createDupFEList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.afeNo
            e.label = e.afeNo + ' - ' + e.afeName
            tempList.push(e)
        })

        return tempList
    }


    // getUserCheckPoint = () => {
    //     let myURI = apiURL + 'AFE/AFECheckPoints/' + this.props.user.account.userName;

    //     console.log("myURI");
    //     console.log(myURI);

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 isUserCheckPointLoaded: true,
    //                 userCheckPoint: json
    //             })
    //         });
    // }

    setDefaultAFEQuery = () => {
        //this.setState({ selAFEQuery: this.state.afeQueryList[0] })
    }
    componentDidMount = async () => {
        const {constructionReportKey} = this.state;
        this.getPermission(); 
        this.loadView();
        await this.getConstructionReportStatusList();
        await this.getConstructionReportProjectTypeList();
        await this.getConstructionReportBasinInformation(); 
         
        await this.getAFEConstructionReportingDefault();

        if (constructionReportKey !== undefined && constructionReportKey !== null && constructionReportKey > 0){
            this.openUpDetails();
        }
         
        this.setState({  isDataLoaded: true});
        
    }

    openUpDetails(){
        const {constructionReportKey} = this.state;
        this.setState({ modalProjectDetails: !this.state.modalProjectDetails  });

    }

    getPermission=async()=>{
        let {user} = this.state; 
        let myURI = apiURL + 'ConstructionReporting/ConstructionReportUserPermission/'+ user.account.userName
          
        await fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{ 
            this.setState({isSecurityAdminLogin:  json.isAdmin});  
        }); 
    }

    openAFEDetailsFromKey = (afeList) => {
        const { openAFEDetails } = this.state
        if (openAFEDetails) {
            const selAfe = afeList.filter(a => a.afeHeaderKey === this.state.afeHeaderKey)
            console.log("openAFEDetails")
            console.log(selAfe)

            if (selAfe.length > 0) {
                this.setState({
                    afeNo: selAfe[0].afeNo,
                })
            }
            this.toggleAFEDetails()
        }
    }

    newProject = () => {
        this.setState({
            constructionReportKey: -1
        })
        this.toggleAFEDetails()
    }

    editProject =()=>{
        this.toggleAFEDetails() 
    }


    // getSelAFEStatus = (value) => {
    //     const { afeStatusList } = this.state

    //     if (value !== null && value !== "") {
    //         return costTypeList.filter(e => value.includes(e.afeLineTypeKey))
    //     }
    //     return ""
    // }

    openAFEBudgetList = () => {
        const { afeBudgetListURL } = this.state

        console.log("afeBudgetListURL")
        console.log(afeBudgetListURL)
        if (afeBudgetListURL.length > 0) {
            const newWindow = window.open(afeBudgetListURL[0].afeBudgetListURL, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null

        }

    }

    onTextChange = async(fieldName, fieldValue, e) => {
        const { constructionReports } = this.state
        // console.log(fieldName + ' ------ ' + fieldValue)
        // console.log(e)

        switch (fieldName) { 
            case "status":
                await this.getFitlerAFE2(constructionReports, this.state.selManager, this.state.selBasin, e,this.state.selCostTypeList,this.state.gridSearch)
                //this.getFitlerAFE(constructionReports, this.state.selManager, this.state.selBasin, e,this.state.selCostTypeList,this.state.gridSearch)
                this.setState({ selConstructionReportStatus: e })
                break;
            case "basin":
                //this.getFitlerAFE(constructionReports, this.state.selManager, e, this.state.selConstructionReportStatus,this.state.selCostTypeList,this.state.gridSearch)
                await this.getFitlerAFE2(constructionReports, this.state.selManager, e, this.state.selConstructionReportStatus,this.state.selCostTypeList,this.state.gridSearch)
                this.setState({ selBasin: e })
                break;
            case "manager":
                await this.getFitlerAFE2(constructionReports, e, this.state.selBasin, this.state.selConstructionReportStatus,this.state.selCostTypeList,this.state.gridSearch)
                this.setState({ selManager: e })
                break;
            case 'costTypeList':
                await this.getFitlerAFE2(constructionReports, this.state.selManager, this.state.selBasin, this.state.selConstructionReportStatus,e,this.state.gridSearch)
                this.setState({ selCostTypeList: e })
                break;  
            case 'gridSearch':
                this.getFitlerAFE(constructionReports, this.state.selManager, this.state.selBasin, this.state.selConstructionReportStatus,this.state.selCostTypeList,fieldValue)    
                this.setState({gridSearch: fieldValue});
                break;  
            default:
                break;
        }
    }

    getFitlerAFE = (e, m, b, s, c,searchText) => {
        //const {fullAFEData} = this.state
        const { user } = this.props

        const basins = b.map(e => e.locationName).join(",")
        const status = s.map(e => e.consturctionReportStatusDesc).join(",")
        //const status = s.map(e => e.value).join(",")
        const manager = m.map(e => e.employeeKey).join(",")
        const costTypeList = c.map(e => e.value).join(",")

        const userName = user.account.userName.toLowerCase()

        var tempConstructionReports = e
 
        if (basins.length > 0) {
            tempConstructionReports = tempConstructionReports.filter(e => basins.includes(e.basin));
        }

        if (status.length > 0) {
            tempConstructionReports = tempConstructionReports.filter(e => status.includes(e.statusDescription)); 
           //tempConstructionReports = tempConstructionReports.filter(e => status.includes(e.statusKey));           
        }

        if (manager.length > 0){
            tempConstructionReports = tempConstructionReports.filter(e => manager.includes(e.projectManagerKey)); 
        }

        if (costTypeList.length > 0){
            tempConstructionReports = tempConstructionReports.filter(e => costTypeList.includes(e.projectTypeKey)); 
        }

        if (searchText != undefined && searchText !== null && searchText !== ''){
            tempConstructionReports = tempConstructionReports.filter(rec => (rec.afeNo || '').toLowerCase().includes(searchText.toLowerCase()) || 
                                                                        (rec.projectName || '').toLowerCase().includes(searchText.toLowerCase()) ||
                                                                        (rec.projectType || '').toLowerCase().includes(searchText.toLowerCase()) ||
                                                                        (rec.basin || '').toLowerCase().includes(searchText.toLowerCase())  ||
                                                                        (rec.projectManager || '').toLowerCase().includes(searchText.toLowerCase()) ||
                                                                        (rec.statusDescription || '').toLowerCase().includes(searchText.toLowerCase())
                                                                        );
        }
          
        this.setState({ constructionReportsFiltered: tempConstructionReports})
 
    }

    getFitlerAFE2 = async(e, m, b, s, c,searchText) => {
        this.setState({
            constructionReports: [],
            constructionReportsFiltered: [],
            //selConstructionReportStatus: selConstructionReportStatus
        }) 
        const { user } = this.props 
        const userName = user.account.userName.toLowerCase()

        const locationKeys = b.map(e => e.locationKey).join(",")
        const statusKeys = s.map(e => e.value).join(",")
        const managerKeys = m.map(e => e.employeeKey).join(",")
        const projectTypes = c.map(e => e.value).join(",") 

        //let myURI = apiURL + 'ConstructionReporting/ByUser/' +  this.state.user.account.userName + "/ / / / /1"  
        let myURI = apiURL + 'ConstructionReporting/ByUser/' + 
        this.state.user.account.userName + 
        "/" + (locationKeys.length  > 0 ? locationKeys : " ") +
        "/" + (statusKeys.length > 0 ? statusKeys : " ") +
        "/" + (managerKeys.length > 0 ? managerKeys : " ") +
        "/" + (projectTypes.length > 0 ? projectTypes : " ") +
         "/0"
         
         console.log(myURI);

         await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => { 
                
                this.setState({
                    constructionReports: json,
                    constructionReportsFiltered: json,
                    //selConstructionReportStatus: selConstructionReportStatus
                }) 
            });  
    }

    getAFEdataByKey(k){

        let myURI = apiURL + 'AFE/Header/startDate/' +
            this.state.startDate.toString().substring(0, 10) +
            '/enddate/' + this.state.endDate.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName + "/StatusKey/" + k;

        console.log("myURI");
        console.log(myURI);

        return(fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json()));

    }

    getAFEHeaderColor = (closeoutStatusDesc) => {

        if (closeoutStatusDesc !== null) {

            if (closeoutStatusDesc === "Rejected" || closeoutStatusDesc === "Cancelled" || closeoutStatusDesc === "Revised") {
                return "modal-header reject"
            } else if (closeoutStatusDesc === "Pending") {
                return "modal-header submit"
            } else if (closeoutStatusDesc !== "") {
                return "modal-header primary"
            }

        } else { return "" }
    }

    onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    }

    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            let obj = budgetList.filter(e => e.afeBudgetDesc === v) 
            if (obj === null || obj.length <=0) {
                return '';
            }
            return obj[0].afeBudgetDesc;
        }
        return ''
    }

    async getAFEReport(reportName){
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKeyReport } = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKeyReport }]; 
        return reportPara
    } 

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }
    render() {

        const { isDataLoaded, 
            consturctionReportStatusList, basinList, isSecurityAdminLogin, selBasin, selConstructionReportStatus, selManager,
            selCostTypeList,windowHeight, constructionReportKey, afeList, gridSearch, selAFEStatus, closeoutStatusDesc,
            empList, budgetList, facilityList, afeStatusDesc, afeNo, afeStatusList, isAFEStatusLoaded,
            costTypeList, afeTypeList, budgetClassList, uomList, countyList,
            isEmpListLoaded, isBudgetLoaded, isFacilityLoaded, isCostTypeLoaded,
            isAFETypeLoaded, isBudgetClassLoaded, isUOMListLoaded, projectStatusList,
            isProjectStatusListLoaded, areaList, isAreaListLoaded,
            searchText, isAFEAdmin,isDupAFEListLoaded,dupAFEList,reportName,managerList,constructionReports,
            constructionReportsFiltered} = this.state
 
        const gridHeight = (windowHeight * 0.75) + "px" 
         

        

        if (!isDataLoaded ) {
            return (<div>
                <h3>Loading Construction Reporting Data.....</h3>
            </div>)
        } 
        else
            console.log('constructionReports: ' + constructionReports);

            let banner = 'New Construction Report Project'
            if (this.state.modalProjectDetails && constructionReportKey > 0){
                let objReport = constructionReports.filter (x => x.constructionReportKey == constructionReportKey);
                if (objReport !== null && objReport.length > 0){
                    banner = objReport[0].afeNo + ': ' + objReport[0].projectName + ' - ' +  objReport[0].statusDescription;
                }
            }

            let tempConstructionReports = [];
            let basinListFiltered = [];
            let managerListFiltered = [];
            let costTypeListFiltered = []; 
            
            if (constructionReportsFiltered != undefined && constructionReportsFiltered !== null && constructionReportsFiltered.length > 0){
                tempConstructionReports = constructionReportsFiltered.map(e => e.locationKey);

                if (tempConstructionReports != undefined && tempConstructionReports != null && tempConstructionReports.length > 0){
                    basinListFiltered = basinList.filter(x => tempConstructionReports.includes(x.locationKey));
                }

                tempConstructionReports = []; 
                if(managerList != undefined && managerList != null && managerList.length > 0 ){
                    tempConstructionReports = constructionReportsFiltered.map(e => e.projectManagerKey);

                    if (tempConstructionReports != undefined && tempConstructionReports != null && tempConstructionReports.length > 0){
                        managerListFiltered = managerList.filter(x => tempConstructionReports.includes(x.employeeKey));
                    }
                }
  
            }
             

            return (<div>
                <Form onSubmit={e => e.preventDefault()}>  
                    <FormGroup>
                        <Row form>
                            <Col sm={12}>
                                <Label><h2> Construction Reporting |</h2></Label>
                                <Button outline
                                    color="secondary"
                                    className="btn-no-border"
                                    onClick={this.getConstructionReports}>
                                    <img
                                        src={Refresh}
                                        alt="Refresh"
                                        style={{ width: "30px", margin: "0 5px 0 0" }} />
                                    Refresh
                                </Button>
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.saveWorkbook}>
                                    <img
                                        src={Excel}
                                        alt="excel"
                                        style={{ width: "50px", margin: "0 0 0 0" }} />
                                    Export To Excel
                                </Button>{' '}

                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.newProject}>
                                    <img
                                        src={Plus}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    Add Project
                                </Button>{' '}
                                <Button 
                                    outline color="secondary"
                                    className="btn-no-border"
                                    disabled={constructionReportKey <=0}
                                    onClick={this.editProject}>
                                    <img
                                        src={EditIcon}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    Edit Project
                                </Button>{' '}  
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.newProject}
                                    disabled={true}
                                    hidden={true}
                                    >
                                    <img
                                        src={BarChart}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    Summary Page
                                </Button>{' '}  
                            </Col>
                        </Row>
                        <ColoredLine color="grey" />
                    </FormGroup> 
                    <FormGroup>
                        <Row form>
                            <Col md={2}>
                                <Row form>
                                    <Col md={12}>
                                        <Label for="typeKey">Basin</Label>
                                        {/* <Select options={basinList}  */}
                                        <Select options={basinListFiltered}
                                            value={selBasin}
                                            defaultValue={selBasin}
                                            onChange={(e) => this.onTextChange("basin", e, e)}
                                            isClearable={true} 
                                            isMulti
                                        >
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <Row form>
                                    <Col md={12}>
                                        <Label for="typeKey">Status</Label>
                                        <Select options={consturctionReportStatusList} 
                                            value={selConstructionReportStatus}
                                            defaultValue={selConstructionReportStatus}
                                            onChange={(e) => this.onTextChange("status", e, e)}
                                            isClearable={true} 
                                            isMulti
                                        >
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <Col md={12}>
                                    <Label for="typeKey">Manager</Label> 
                                    {/* <Select options={managerList}  */}
                                    <Select options={managerListFiltered}
                                        value={selManager}
                                        defaultValue={selManager}
                                        onChange={(e) => this.onTextChange("manager", e, e)}
                                        isClearable={true}
                                        isMulti 
                                    >
                                    </Select>
                                </Col>
                            </Col>
                            <Col md={2}>
                                <Col md={12}>
                                    <Label for="typeKey">Project Type</Label> 
                                    <Select options={costTypeList}  
                                        value={selCostTypeList}
                                        defaultValue={selCostTypeList}
                                        onChange={(e) => this.onTextChange("costTypeList", e, e)}
                                        isClearable={true}
                                        isMulti 
                                    >
                                    </Select>
                                </Col>
                            </Col>
                            <Col md={4}>

                                <Label for="typeKey"> </Label>
                                <Input width="75%" className='floatRight'
                                    placeholder="Type here to search......."
                                    onChange={(e) => this.onTextChange("gridSearch", e.target.value, e)}
                                    value={gridSearch}
                                >

                                </Input>


                            </Col>
                            
                        </Row>
                    </FormGroup>


                    <FormGroup>
                        <Row form style={{ zIndex: 0, position: 'relative' }}>
                            <IgrDataGrid
                                ref={this.onGridRef}
                                height={gridHeight}
                                width={"100%"} 
                                isColumnOptionsEnabled="true" 
                                editMode={0} 
                                //dataSource={constructionReports}
                                dataSource={constructionReportsFiltered}
                                autoGenerateColumns="false"
                                selectionMode="SingleRow" 
                                selectedKeysChanged={this.onGridRowSelected}
                                cellClicked={this.onGridRowSelected2}
                                cellTextStyle="14px Calibri" headerTextStyle="14px Calibri" rowHeight={"25"}
                            >
                                <IgrNumericColumn
                                    field="constructionReportKey"
                                    headerText={"ConstructionReportKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                <IgrTextColumn
                                    field="constructionReportID"
                                    headerText={"Report ID"}
                                    width="*>160"
                                />
 
                                <IgrTextColumn 
                                    field="afeNo" 
                                    headerText="AFE No"
                                    width="*>110" 
                                />
                                <IgrTextColumn 
                                    field="projectName"
                                    headerText="AFE Name"
                                    width="*>170" 
                                />
                                <IgrTemplateColumn
                                    field="totalCompletion" 
                                    headerText="% Complete"
                                    width="*>150"
                                    cellUpdating={this.onStatusCellUpdating}
                                /> 

                                <IgrTemplateColumn 
                                    field="expectedCompletionDate" 
                                    headerText="Expected Completion Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                  
                                
                                <IgrTextColumn 
                                    field="projectType" 
                                    headerText="Project Type"
                                    width="*>140"
                                />
                                <IgrNumericColumn
                                    field="projectTypeKey"
                                    headerText={"projectTypeKey"}
                                    width="*>160"
                                    isHidden={true}
                                />

                                <IgrTextColumn 
                                    field="basin" 
                                    headerText="Basin"
                                    width="*>140"
                                />


                                <IgrTextColumn 
                                    field="projectManager" 
                                    headerText="Manager"
                                    width="*>150"
                                />  

                                <IgrTemplateColumn 
                                    field="dateOfLastReport" 
                                    headerText="Date of Last Report"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrTextColumn 
                                    field="statusDescription" 
                                    headerText="Status"
                                    width="*>150"
                                />
                                <IgrTextColumn 
                                    field="createdBy" 
                                    headerText="Created By"
                                    width="*>150"
                                />
                                <IgrTemplateColumn 
                                    field="createdDate" 
                                    headerText="Created Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrTextColumn 
                                    field="lastModifiedBy" 
                                    headerText="Last Modified By"
                                    width="*>150"
                                />
                                <IgrTemplateColumn 
                                    field="lastModifiedDate" 
                                    headerText="Last Modified Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrTextColumn 
                                    field="blob_id" 
                                    headerText="blob_id"
                                    width="*>150"
                                    isHidden={true}
                                /> 
                            </IgrDataGrid>
                        </Row>
                    </FormGroup>

                    <div>
                        <Modal isOpen={this.state.modalProjectDetails}
                            toggle={() => this.closeDetailsForm()}
                            size="xl"
                            backdrop={"static"}
                            // onClosed={() => this.getAFEdata()}
                            >
                            <ModalHeader toggle={() => this.closeDetailsForm()}
                                className={this.getAFEHeaderColor(closeoutStatusDesc)}>
                                {/* {"New Construction Report Project"} */}
                                {banner}
                            </ModalHeader>
                            <ModalBody>
                                <ConstructionReportingDetails 
                                    closeDetail={this.closeDetailsForm}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={this.props.user}
                                    constructionReportKey={constructionReportKey}
                                    isSecurityAdminLogin={isSecurityAdminLogin}
                                    constructionReports={constructionReports}
                                    empList={empList}
                                    budgetList={budgetList}
                                    facilityList={facilityList}
                                    costTypeList={costTypeList}
                                    afeTypeList={afeTypeList}
                                    budgetClassList={budgetClassList}
                                    uomList={uomList}
                                    afeList={afeList}
                                    countyList={countyList}
                                    areaList={areaList}
                                    projectStatusList={projectStatusList}
                                    basinList={basinList}
                                    dupAFEList={dupAFEList}>
                                </ConstructionReportingDetails>
                            </ModalBody>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            size={"xl"}
                            centered
                            >
                            <ModalHeader toggle={() => this.toggleReport()}> {reportName == 'AFECloseout' ? 'AFE Closeout Report' : 'AFE Approval Report' } </ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName = {reportName} 
                                    reportParameters = {this.createReportParamter()}
                                                        
                                    toggle={this.toggleReport}
                                
                                >

                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div>
                </Form>
            </div >)

    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.statusKey === 3 || item.closeoutStatusKey === 3) {
                content.style.background = "#f0f5b0";
            } else if (item.statusKey === 1 || item.statusKey === 5 ||
                item.closeoutStatusKey === 1 || item.closeoutStatusKey === 10) {
                content.style.background = "#F3B5A2";
            } else if (item.statusKey === 6) {
                content.style.background = "#b8f2b1";
            }
            else {
                content.style.background = "transparent";
            }

            if (s.field === "actualvsBudget") {
                if (item.actualvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.actualvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.actualvsBudget > 104.44 && item.actualvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }
            if (s.field === "projectedvsBudget") {
                if (item.projectedvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.projectedvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.projectedvsBudget > 104.44 && item.projectedvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }


        }

        switch (s.field) {
            case 'afeStatusDesc':
                //link.textContent = item.afeStatusDesc;
                link.textContent = "60%";
                break;
            case 'afeName':
                link.textContent = item.afeName;
                break;
            case 'afeSignedDate':
                link.textContent = ""
                if (item.afeSignedDate !== null) {
                    var date = new Date(item.afeSignedDate);
                    link.textContent = date.toLocaleDateString();

                }
                content.style.background = "transparent";
                break;
            case 'afeNo':
                link.textContent = item.afeNo;
                break;

            case 'afeApprovedDate':
                // console.log("afeApprovedDate")
                // console.log(item.afeApprovedDate)
                link.textContent = ""

                if (item.afeApprovedDate !== null) {
                    var date = new Date(item.afeApprovedDate);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'createdDatetime':
                link.textContent = ""
                if (item.createdDatetime !== null) {
                    var date = new Date(item.createdDatetime);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'estStartDate':
                link.textContent = ""
                if (item.estStartDate !== null) {
                    var date = new Date(item.estStartDate);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'expectedCompletionDate':
                link.textContent = ""
                if (item.expectedCompletionDate !== null) {
                    var date = new Date(item.expectedCompletionDate);
                    link.textContent = date.toLocaleDateString(); 

                }
                content.style.background = "transparent";
                break;
            case 'dateOfLastReport':
                link.textContent = ""
                if (item.dateOfLastReport !== null) {
                    var date = new Date(item.dateOfLastReport);
                    link.textContent = date.toLocaleDateString(); 
                }
                content.style.background = "transparent";
                break;
            case 'createdDate':
                link.textContent = ""
                if (item.createdDate !== null) {
                    var date = new Date(item.createdDate);
                    link.textContent = date.toLocaleDateString(); 
                }
                content.style.background = "transparent";
                break;
            case 'lastModifiedDate':
                link.textContent = ""
                if (item.lastModifiedDate !== null) {
                    var date = new Date(item.lastModifiedDate);
                    link.textContent = date.toLocaleDateString(); 
                }
                content.style.background = "transparent";
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'managerName':
                link.textContent = item.managerName;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            case 'actualvsBudget':
                link.textContent = (item.actualvsBudget >= 1 ? Math.round(item.actualvsBudget) : item.actualvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'projectedvsBudget':
                link.textContent = (item.projectedvsBudget >= 1 ? Math.round(item.projectedvsBudget) : item.projectedvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'budgetID':
                link.textContent = item.budgetID === undefined || item.budgetID === null ? '' : this.getSelBudget(item.budgetID);
                content.style.background = "transparent";
                break;
            case 'totalCompletion':
                link.textContent = item.totalCompletion + '%';
                
                if (item.statusKey === 0 || item.statusKey === 3){
                    content.style.background = "transparent";
                }
                else if (item.totalCompletion >= 100){
                    content.style.background = "#b8f2b1"; 
                }
                else if (item.totalCompletion >= 64 && item.totalCompletion < 97){
                    content.style.background = "#28A745";  
                    content.style.background = 'linear-gradient(to right, #28A745 ' + item.totalCompletion + '%, white ' + item.totalCompletion + '%)';
                }
                else if (item.totalCompletion >= 50 && item.totalCompletion <= 63){
                    content.style.background = "#28A745";  
                    content.style.background = 'linear-gradient(to right, #ffaf7a ' + item.totalCompletion + '%, white ' + item.totalCompletion + '%)';
                }
                else if (item.totalCompletion >= 0 && item.totalCompletion <= 49){
                    content.style.background = "#28A745";  
                    //content.style.background = 'linear-gradient(to right, red 50%, white 50%)';
                    if (item.totalCompletion <= 0){
                        content.style.background = 'linear-gradient(to right, red 5%, white 5%)';  
                    }
                    else {
                        content.style.background = 'linear-gradient(to right, red '+ + item.totalCompletion + '%, white ' + item.totalCompletion + '%)';
                    }

                }
                else if (item.totalCompletion > 30 && item.totalCompletion < 100){
                    content.style.background = "#ffaf7a";
                }
                else if (item.totalCompletion <= 30){
                    //content.style.background = "#f8858b";
                    content.style.background = 'linear-gradient(to right, red 50%, white 50%)';
                }
                
                break;

            default:

        }

    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "ConstructionReports");
    }

}