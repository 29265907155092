//Created by BN 11/1/21


import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InputGroupAddon, CustomInput, Col, Row, Button, FormGroup, Label, Input, ListGroup, ListGroupItem, InputGroup, ListGroupItemText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ImageViewer from '../resources/ImageViewer';

import EventDetailModal from './EventDetailModal';
import { apiURL } from '../resources/apiURL';

import '../custom.css';
import { useState } from "react";




const showItemText = (metValHeader,
    asFoundValue,
    itemFieldId,
    asLeftValue,
    itemFieldId2,
    comment,
    itemFieldId3,
    meterValLineDetails,
    allowUpdate,
    onTextChange,
    isHeader,
    isResult,
    addCheckTemplateID,
    isReadOnly,
    meterTotal,
    auxEquip
) => {

    if (isHeader) {


        return <div style={{ margin: "0px 0 0 0" }}>

            <InputGroup>
                <Col sm={2}>
                    <InputGroupAddon addonType="prepend">
                        <h6> <Label width="100px" style={{ margin: "0px 0px 0 0", fontWeight: "bold" }} >{metValHeader} : </Label> </h6>

                    </InputGroupAddon>
                </Col>



                <Col sm={2}>
                    <Label width="100px" style={{ margin: "0px 0px 0 0px" }} > {asFoundValue} </Label>

                </Col>
                <Col sm={2}>
                    <Label width="100px" style={{ margin: "0px 0px 0 0px" }} > {asLeftValue} </Label>

                </Col>

                <Col sm={2}>
                    <Label width="100px" style={{ margin: "0px 0px 0 0px" }} > {comment} </Label>

                </Col>




            </InputGroup>

        </div>


    } else if (isResult) {



        return <div style={{ margin: "0px 0 0 0" }}>

            <InputGroup>
                <Col sm={2}>
                    <InputGroupAddon addonType="prepend">
                        <Label width="100px" style={{ margin: "0px 0px 0 0" }} >{metValHeader} : </Label>
                    </InputGroupAddon>
                </Col>

                <Col sm={2}>

                    <Input style={{ margin: "0px 0 0 0", width: "100px" }}
                        //   type="text"
                        type={(addCheckTemplateID ? "text" : "number")}
                        class="greenColor"
                        defaultValue={asFoundValue}
                        value={(addCheckTemplateID ? ((asFoundValue * 100).toFixed(2) + "%") : asFoundValue)}
                        disabled={meterTotal || isReadOnly || (allowUpdate === false) || addCheckTemplateID}
                        className={(addCheckTemplateID ? (asFoundValue < .15 && asFoundValue != "") ? "bg-success" : (asFoundValue >= .15 && asFoundValue != "") ? "bg-danger" : "defaultColor" : "defaultColor")}
                        onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                            itemFieldId,
                            e.target.value,
                            e)}
                    >

                    </Input>
                </Col>

                <Col sm={2}>
                    <Input style={{ margin: "0px 0 0 0", width: "100px" }}
                        type={(addCheckTemplateID ? "text" : "number")}
                        defaultValue={asLeftValue}
                        value={(addCheckTemplateID ? ((asLeftValue * 100).toFixed(2) + "%") : asLeftValue)}
                        id="mySelect"
                        disabled={meterTotal || isReadOnly || allowUpdate == false || addCheckTemplateID}
                        // disabled={isReadOnly}
                        className={(addCheckTemplateID ? (asLeftValue < .15 && asLeftValue != "") ? "bg-success" : (asLeftValue >= .15 && asLeftValue != "") ? "bg-danger" : "defaultColor" : "defaultColor")}
                        // readOnly={isReadOnly}
                        onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                            itemFieldId2,
                            e.target.value,
                            e)}
                    >
                    </Input>
                </Col>

                <Col sm={5}>

                    <InputGroupAddon style={{ margin: "0px 0 0 0" }} addonType="append">





                        <FormGroup>

                            <Input type="textarea"

                                style={{ margin: "0px 0px 0 0px", height: "40px", width: "480px" }}
                                defaultValue={comment}
                                value={comment}
                                disabled={isReadOnly || allowUpdate == false || allowUpdate == false}
                                onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                                    itemFieldId3,
                                    e.target.value,
                                    e)}
                            />

                        </FormGroup>
                    </InputGroupAddon>

                </Col>



            </InputGroup>

        </div>



    } else if (metValHeader) {

        if (allowUpdate && !isResult) {



            return <div style={{ margin: "0px 0 0 0" }}>
                <InputGroup>
                    <Col sm={2}>
                        <InputGroupAddon addonType="prepend">
                            <Label width="100px" style={{ margin: "0px 0px 0 0" }} >{metValHeader} : </Label>

                        </InputGroupAddon>
                    </Col>


                    <Col sm={2}>

                        <CustomInput style={{ margin: "0px 0 0 0", width: "100px" }}
                            type="select"
                            id="mySelect"
                            disabled={isReadOnly || (allowUpdate === false)}
                            defaultValue={asFoundValue}
                            className={asFoundValue == "Pass" || asFoundValue == "Passed" ? "bg-success" : asFoundValue == "Failed" || asFoundValue == "Fail" ? "bg-danger" : "defaultColor"}


                            onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                                itemFieldId,
                                e.target.value,
                                e)}
                        >

                            <option className="defaultColor" value=""></option>
                            <option className="defaultColor" value="Pass">Pass</option>
                            <option className="defaultColor" value="Fail">Fail</option>
                        </CustomInput>
                    </Col>



                    <Col sm={2}>
                        <CustomInput style={{ margin: "0px 0 0 0", width: "100px" }}
                            type="select"
                            defaultValue={asLeftValue}
                            id="mySelect"
                            className={asLeftValue == "Pass" || asLeftValue == "Passed" ? "bg-success" : asLeftValue == "Failed" || asLeftValue == "Fail" ? "bg-danger" : "defaultColor"}
                            onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                                itemFieldId2,
                                e.target.value,
                                e)}
                        >
                            <option className="defaultColor" value=""></option>
                            <option className="defaultColor" value="Pass">Pass</option>
                            <option className="defaultColor" value="Fail">Fail</option>
                        </CustomInput>
                    </Col>

                    <Col sm={6}>

                        <InputGroupAddon style={{ margin: "0px 0 0 0" }} addonType="append">



                            <Input type="textarea"

                                style={{ margin: "0px 0px 0px 0px", height: "40px", width: "500px" }}
                                defaultValue={comment}
                                disabled={isReadOnly || allowUpdate == false}
                                onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                                    itemFieldId3,
                                    e.target.value,
                                    e)}
                            />
                        </InputGroupAddon>

                    </Col>

                </InputGroup>




            </div>
        } else {
            return (<div style={{ margin: "0px 0 0 0" }}>
                <InputGroup>
                    <Col sm={2}>
                        <InputGroupAddon addonType="prepend">
                            <Label width="100px" style={{ margin: "0px 5px 0 0" }} >{metValHeader} : </Label>

                        </InputGroupAddon>
                    </Col>


                    <Col sm={2}>

                        <CustomInput style={{ margin: "0px 0 0 0", width: "100px" }}
                            type="select"
                            id="mySelect"
                            disabled={isReadOnly || allowUpdate == false}
                            // disabled={isReadOnly} 
                            defaultValue={asFoundValue}
                            className={asFoundValue == "Pass" || asFoundValue == "Passed" ? "bg-success" : asFoundValue == "Failed" || asFoundValue == "Fail" ? "bg-danger" : "defaultColorDisable"}
                            onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                                itemFieldId,
                                e.target.value,
                                e)}

                        >

                            <option className="defaultColor" value=""></option>
                            <option className="defaultColor" value="Pass">Pass</option>
                            <option className="defaultColor" value="Fail">Fail</option>
                        </CustomInput>
                    </Col>


                    <Col sm={2}>
                        <CustomInput style={{ margin: "0px 0 0 0", width: "100px" }}
                            type="select"
                            defaultValue={asLeftValue}
                            disabled={isReadOnly || allowUpdate == false}

                            id="mySelect"
                            className={asLeftValue == "Pass" || asLeftValue == "Passed" ? "bg-success" : asLeftValue == "Failed" || asLeftValue == "Fail" ? "bg-danger" : "defaultColorDisable"}
                            onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                                itemFieldId2,
                                e.target.value,
                                e)}

                        >
                            <option className="defaultColor" value=""></option>
                            <option className="defaultColor" value="Pass">Pass</option>
                            <option className="defaultColor" value="Fail">Fail</option>
                        </CustomInput>
                    </Col>

                    <Col sm={5}>

                        <InputGroupAddon style={{ margin: "0px 0 0 0" }} addonType="append">


                            <Input type="textarea"

                                style={{ margin: "0px 0px 0px 0px", height: "40px", width: "400px" }}
                                defaultValue={comment}
                                disabled={isReadOnly || allowUpdate == false}
                                onChange={(e) => onTextChange(meterValLineDetails.meterValidationAddCheckItemKey,
                                    itemFieldId3,
                                    e.target.value,
                                    e)}
                            />
                        </InputGroupAddon>

                    </Col>

                </InputGroup>




            </div>);
        }

    }
    else {
        return "";
    }


}


const MeterValidationItem = (props) => {

    const sortMetVal = props.data.sort((a, b) => a.sortOrder - b.sortOrder);
    var hdrOrder = [];
    var i = 0;

    hdrOrder[0] = sortMetVal[0];

    const isAllowEdit = props.isAllowEdit;


    const isAbleToValidate = props.isAbleToValidate;

    const isReadOnly = props.isReadOnly

    const statusKey = props.statusKey

    sortMetVal.map(function (item) {

        if (hdrOrder[i].parentKey !== item.parentKey) {

            i = i + 1;
            hdrOrder[i] = item;

        }
    }
    )

    const onHandleMetValLineValueChange = (key, valueChange, value) => {
        props.onHandleMetValLineValueChange(key, valueChange, value)
    }


    return (
        <div>
            {hdrOrder.map(function (item) {

                if (item !== undefined) {


                    if (!isAbleToValidate && item.parentKey == 14) {

                    }

                    else if (item.parentDesc == "AUXILLARY EQUIPMENT") {
                        return <ListGroup style={{ margin: "5px 0 0 0" }}>
                            <ListGroupItem disabled>
                                {showItemText("Aux Equip", "As Found", "", "As Left", "", "Comment", "",
                                    i, isAllowEdit, null, true, true, true, isReadOnly, true, true)}
                            </ListGroupItem>

                            {sortMetVal.map(function (i) {

                                if (item.parentKey === i.parentKey) {
                                    return <ListGroupItem>



                                        {showItemText(i.checkItemDesc, i.asFoundValue, "asFoundValue", i.asLeftValue, "asLeftValue",
                                            i.comment, "comment", i, isAllowEdit, onHandleMetValLineValueChange, false, (i.parentKey == 14 && isAbleToValidate),
                                            (i.addCheckTemplateID == "MTRRSL5"), isReadOnly, (i.addCheckTemplateID == "MTRRSL3"), (i.parentDesc == "Axuillary Equipment")
                                        )}


                                    </ListGroupItem>
                                }

                            })

                            }
                        </ListGroup>
                    }


                    else
                        return <ListGroup style={{ margin: "5px 0 0 0" }}>
                            <ListGroupItem disabled>
                                {showItemText(item.parentDesc, "As Found", "", "As Left", "", "Comment", "",
                                    i, isAllowEdit, null, true, true, true, isReadOnly, true, true)}
                            </ListGroupItem>

                            {sortMetVal.map(function (i) {

                                if (item.parentKey === i.parentKey) {
                                    return <ListGroupItem>


                                        {showItemText(i.checkItemDesc, i.asFoundValue, "asFoundValue", i.asLeftValue, "asLeftValue",
                                            i.comment, "comment", i, isAllowEdit, onHandleMetValLineValueChange, false, (i.parentKey == 14 && isAbleToValidate),
                                            (i.addCheckTemplateID == "MTRRSL5"), isReadOnly, (i.addCheckTemplateID == "MTRRSL3"), (i.parentDesc == "Axuillary Equipment")
                                        )}


                                    </ListGroupItem>
                                }

                            })

                            }
                        </ListGroup>

                }

            }
            )
            }



        </div>


    );

}

export default MeterValidationItem;