import React,{Component} from 'react';
import {apiURL, apiDELETEHeader} from '../resources/apiURL';
//import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import MenuIcon from '../resources/menu.png'
import TrashIcon from '../resources/trash.svg'
import '../custom.css';
import {ExcelUtility} from '../operation/ExcelUtility';
import { getUserADAccount } from '../resources/utility'
import { Col, Row, Button, FormGroup } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';

import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import ConfirmModal from '../operation/ConfirmModal'
import { IgrTemplateColumn } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrTemplateCellInfo } from 'igniteui-react-grids';
// import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine } from '../resources/utility';
import WarehouseDetail from './WarehouseDetail'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

export default class Warehouse extends Component {
    
    constructor(props){
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);

        this.state ={
            modalMsg:false,
            warehouseData: [],
            isWHLoaded:false,
            WHData:[],
            windowHeight:window.innerHeight,
            warehouseKey:-1,
            warehouseStatus:'',
            statusKey:1,
            modal:false,
            msgBody: "",
            msgHeader: "",
            alertMessage: "",
            showAlert: false
        }

    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
                
    }
    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.WHData;
      
    }

    getWHData=() =>{
        let myURI = apiURL + 'SupplyChain/WH'

        console.log(myURI);

        fetch(myURI , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isWHLoaded: true,
                    WHData:  json
                })
            });
            

    }

    newWH = () =>{
        this.setState({warehouseKey: -1})       
        this.toggle()
    }
   

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            console.log(dataItem)
            
            this.setState({warehouseKey: dataItem.warehouseKey,
                          warehouseStatus: dataItem.statusDesc,
                          statusKey:dataItem.statusKey,
                            warehouseData: dataItem})       
           
            //this.onVolRefresh()    
        } 
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getWHData()
    }

    deleteData = () => {
        
        const dataForDeleteDTO = this.createDeleteData()

        let myURI = apiURL + 'SupplyChain/WH'

        fetch(myURI, apiDELETEHeader(dataForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                } else {
                }
            
                this.setState({warehouseKey: -1})
                this.getWHData()
            })
            .catch(error => {
          
                // this.getReceiverLine()
                this.getWHData()

            })

    }

    createDeleteData = () => {
        const {warehouseData } = this.state
        const { user } = this.props

        const lineDataForDeleteDTO = {
            "WarehouseKey": warehouseData.warehouseKey,
            "DeletedBy": getUserADAccount(user.account.userName),
        }

        return lineDataForDeleteDTO;
    }

    
    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    render(){
        var {isWHLoaded,WHData, windowHeight,warehouseKey,warehouseStatus,statusKey} = this.state;
        const {msgBody, msgHeader} = this.state
        const gridHeight=(windowHeight * .8) + "px"
        const searchText = window.$searchText.toLowerCase()

        if (!isWHLoaded) {
            return(<div>
                <h3>Loading Warehouse/Yard Data.....</h3>
            </div>)
        }else
        return(<div> 
                 <FormGroup>
                    <Row form>
                        <h2> Warehouse/Yard |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getWHData}>
                                    <img
                                    src={Refresh}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 5px 0 0"}} />
                                    Refresh
                                </Button>
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={Excel}
                            alt="excel"
                            style={{width:"50px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.newWH}>
                            <img 
                            src={Plus}
                            alt="new"
                            style={{width:"25px", margin:"0 5px 0 0"}}/>
                            New
                        </Button>{' '}

                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.toggle}
                                >
                            <img 
                            src={MenuIcon}
                            alt="new"
                            style={{width:"25px", margin:"0 5px 0 0"}}/>
                            Edit
                        </Button>{' '}

                        <Col>
                        <ConfirmModal 
                        // outline={true}
                        
                                    color="danger"
                                    // className="btn-no-border:focus"
                                    className="float-right"
                                    // className="btn-no-border"
                                    buttonLabel="Delete"
                                    // hidden={isFormReadOnly}                         
                                    formName="Delete"
                                    id={warehouseKey}
                                    message="Are you sure you want to delete selected item?"
                                    onClickYes={(e) => this.deleteData()}
                                    image={<img
                                        
                                        src={TrashIcon}
                                        id={warehouseKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>
                                </Col>

                    <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader} 
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>
                        

                            
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>
            


                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight} 
                        filterUIType="FilterRow"       
                        isColumnOptionsEnabled="true"               
                        //dataSource={safetyObs}
                        dataSource={WHData.filter( rec =>  ((rec.itemID || '').toLowerCase().includes(searchText)) ||
                                                            (rec.itemNumber || '').toLowerCase().includes(searchText) ||
                                                            (rec.warehouseName || '').toLowerCase().includes(searchText) ||
                                                            (rec.itemStatus || '').toLowerCase().includes(searchText) ||
                                                            (rec.itemDesc || '').toLowerCase().includes(searchText) ||
                                                            (rec.itemTypeDesc || '').toLowerCase().includes(searchText) ||
                                                            (rec.inventoryCode || '').toLowerCase().includes(searchText) ||       
                                                            (rec.createdByName || '').toLowerCase().includes(searchText) ||                                                      
                                                            (rec.purchUOMDesc || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                        scrollable={true}
                        //cellBackground={"#ffbfbf"}
                        >                      
                        
                        
                        <IgrTemplateColumn field="locationName" headerText="Location" width="*>190" 
                        cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="warehouseID" headerText="ID" 
                            width="*>140"  cellUpdating={this.onStatusCellUpdating}
                         />


                        <IgrTemplateColumn field="warehouseName" headerText="Name" 
                            width="*>150" 
                            cellUpdating={this.onStatusCellUpdating}
                            />
                                                  
                        <IgrTemplateColumn field="statusDesc" headerText="Status" 
                            width="*>140" 
                            cellUpdating={this.onStatusCellUpdating}/>

                        <IgrTextColumn field="comments" headerText="Comments" 
                            width="*>250" />
                       
                       <IgrTextColumn field="addressName" headerText="Address" 
                            width="*>150" />                  
                            
                        
                        <IgrTextColumn field="contactName" headerText="Contact Name" 
                            width="*>150" />  

                        <IgrTextColumn field="contactEMail" headerText="Contact Email" 
                            width="*>150" />                  

                        <IgrTextColumn field="contactPhone" headerText="Contact Phone" 
                            width="*>150" />                  


                        <IgrTemplateColumn field="createdBy" headerText="Created By" 
                            width="*>150" cellUpdating={this.onStatusCellUpdating}
                            />           

                        <IgrTemplateColumn field="createdDatetime" headerText="Created Date" 
                            width="*>140" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onReportDateUpdating} />
                        
                        
                        <IgrNumericColumn field="warehouseKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>

            
                <div>
                <Modal isOpen={this.state.modal} 
                      toggle={() => this.toggle()}                  
                  size="lg"
                centered>
                    <ModalHeader className={(statusKey===0?"modal-header reject":"")}  
                        toggle={() => this.toggle()}>{ warehouseKey< 0 ? "New Warehouse" : "Warehouse Details - " + warehouseStatus} 
                    </ModalHeader>
                    <ModalBody>
                     <WarehouseDetail 
                        warehouseKey={warehouseKey}
                        user={this.props.user}
                        toggle={this.toggle}
                        refreshData={this.getWHData}>

                        </WarehouseDetail>
                      
                    </ModalBody>                    
                </Modal>
                </div>

        </div>)
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
       // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            //let cell = document.createElement("div");
            
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";          
            
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
           
            // if (item.statusKey===4){
            //     content.style.background = "#b6a065";
            // }else  if (item.statusKey===6){
            //     content.style.background = "#61a066";
            // }else if (item.statusKey===0 || item.statusKey===3 || item.statusKey===5){
            //     content.style.background = "#c69089";
               
            // }else {
            //     content.style.background = "transparent";
            // }
        }


        switch(s.field){
            case 'statusDesc':
                link.textContent = item.statusDesc;
            break;
            case 'warehouseID':
                link.textContent = item.warehouseID;
                break;
            case 'warehouseName':
                link.textContent = item.warehouseName;

                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            // case 'hazardDesc':
            //     link.textContent = item.hazardDesc;
            //     break;      
            // case 'solutionDesc':
            //     link.textContent = item.solutionDesc;
            //     break;       
            case 'createdBy':
                link.textContent = item.createdBy.replace(/\./g, " ");
                break;          
            // case 'managerName':
            //     link.textContent = item.managerName;
            //     break;
            // case 'locationName':
            //     link.textContent = item.locationName;
            //     break;    
            // case 'facilityName':
            //     link.textContent = item.facilityName;
            //     break;                                     
            default:

        }        
      
    }


    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
           // let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]===actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]===actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "warehouse");
    }
}