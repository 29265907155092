//Modified by BN232208 

import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Email from '../../resources/email.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ContainerState, ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
// import AFEApprovalHistory from './AFEApprovalHistory';
 import MOCApprovalAdd from './MOCApprovalAdd'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

export default class MOCApproval extends Component {
    static displayName = MOCApproval.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this)
        this.onGridRowSelected = this.onGridRowSelected.bind(this)
        this.state = {
            mocKey: props.mocKey,
            isLoaded: false,
            afeApproval: [],
            isFormReadOnly: props.isFormReadOnly,
            modalAppHist: false,
            modalAppAdd: false,
            empList: props.empList,
            selLineActionKey: 0,
            addPosition: 0,
            userGroupKey: -1,
            isAFEManager: props.isAFEManager,
            isAFEAdmin: props.isAFEAdmin,
            afeStatusKey: props.afeStatusKey,
            closeoutStatusKey : props.closeoutStatusKey,
            waitingOnApprovers : props.waitingOnApprovers,
            statusKey: props.statusKey,
            mocData: props.mocData,
            isSecurityAdminLogin: props.isSecurityAdminLogin

        }
    }

    createDeleteAppDTO = () => {
        const { user } = this.props
        const deleteData = {
            "ActionLineKey": this.state.selLineActionKey,
            "DeletedBy": user.account.name
        }
        return deleteData
    }
    deleteRec = () => {
        let myURI = apiURL + 'MOC/ApprovalEmail'

        const deleteData = this.createDeleteAppDTO()

        fetch(myURI, apiDELETEHeader(deleteData))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }
                this.getMOCApproval()
            })
            .catch(error => {
            })
    }

    onGridRowSelected(s, e) {
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                selLineActionKey: dataItem.actionLineKey,
                userGroupKey: dataItem.userGroupKey
            })

        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.afeApproval;
    }
    getMOCApproval = () => {
        const { mocKey } = this.state

        let myURI = apiURL + 'MOC/Approval/' + mocKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeApproval: json,
                    isLoaded: true
                })
            });
    }

    toggleModalAppHist = () => {
        this.setState({ modalAppHist: !this.state.modalAppHist })
    }

    toggleModalAppAdd = () => {
        this.setState({ modalAppAdd: !this.state.modalAppAdd })
    }

    AddAfter = () => {
        this.setState({ addPosition: 1 })
        this.toggleModalAppAdd()
    }

    AddBefore = () => {
        this.setState({ addPosition: -1 })
        this.toggleModalAppAdd()
    }

    emailMOCDTO = () => {
        const { mocKey, selLineActionKey } = this.state
        const newAFEEmailDTO = {
            "mocKey": mocKey,
            "ActionLineKeys": selLineActionKey
        }

        return newAFEEmailDTO
    }

    sendRemiderEmail = () =>{
       
        const newAFEEmailDTO = this.emailMOCDTO()

        let myURI = apiURL + 'MOC/ResendApprovalEmail'
        fetch(myURI, apiPUTHeader(newAFEEmailDTO))
        .then(async response => {
            const rspData = response.text();
            if (!response.ok) {
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);

            }         
        })
        .catch(error => {
        })
    }

    componentDidMount() {
        this.getMOCApproval()
    }

    render() {
        const { isLoaded, afeApproval, isFormReadOnly, mocKey,waitingOnApprovers, afeHeaderKey,
            modalAppHist, selLineActionKey, isAFEAdmin, isAFEManager,
            userGroupKey, addPosition, afeStatusKey ,closeoutStatusKey , statusKey, mocData, isSecurityAdminLogin} = this.state

        if (!isLoaded) {
            return (<div>Loading AFE Approval ......</div>)
        } else
            return (<div>
                {/* <ColoredLine color="grey" /> */}
                <Row form style={{ zIndex: 0, position: 'relative' }}>
                    {/* <FormGroup> */}

                    <Col sm={8}>
                        {/* <Button color="secondary" outline className={"float-left"}
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            hidden={!(afeStatusKey === 0)}
                            onClick={() => this.toggleModalAppHist()}>Preview Approval Routing</Button> */}
                        {/* <Button color="success" outline className="float-left"
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            hidden={(!isAFEAdmin && !isAFEManager) || (afeStatusKey !== 3 && afeStatusKey !== 2 && closeoutStatusKey !== 3)}
                            onClick={() => this.SendRemiderEmail()}>
                            <img
                                src={Email}
                                alt="email"
                                style={{ width: "20px", marginRight: "5px" }} />

                            Send Reminder Email</Button> */}

                        <ConfirmModal outline={true}
                            color="success"
                            className="float-left"
                            buttonLabel="Send Reminder Email"
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            // hidden={(!isAFEAdmin && !isAFEManager) || (afeStatusKey !== 3 && afeStatusKey !== 2 && closeoutStatusKey !== 3)}
                            hidden= {(!isSecurityAdminLogin && !(mocData[0].createdBy === getUserADAccount(this.props.user.account.userName))) || (statusKey != 8 && statusKey != 3)}
                            // disabled={userGroupKey >= 0 || statusKey === 5}
                            disabled={selLineActionKey == 0}
                            formName="Confirm"
                            id={afeHeaderKey} 
                            //  message={"Are you sure you want to send reminder email to " + waitingOnApprovers + " ?"}
                             message={"Are you sure you want to send reminder email ?"}
                            onClickYes={(e) => this.sendRemiderEmail()}
                            image={<img
                                src={Email}
                                id={afeHeaderKey}
                                alt="Edit"
                                style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                        </ConfirmModal>

                        {/* <Button color="secondary" outline className={"float-left"}
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            disabled = {statusKey === 5}
                            // hidden={(afeStatusKey === 0)}
                            // onClick={() => this.toggleModalAppHist()}
                            >View Approval History</Button> */}

                        <Button color="primary" outline className="float-left"
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            // hidden={(!isAFEAdmin && !isAFEManager) || (afeStatusKey !== 0 && afeStatusKey !== 3)}
                            hidden= {(!isSecurityAdminLogin && !(mocData[0].createdBy === getUserADAccount(this.props.user.account.userName))) || (statusKey != 8 && statusKey != 3)}
                            disabled= {statusKey === 5}
                             onClick={() => this.toggleModalAppAdd()}
                            >Add</Button>
                        {/* <Button color="secondary" outline className="float-left"
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            // hidden={(!isAFEAdmin && !isAFEManager) || (afeStatusKey !== 0 && afeStatusKey !== 3)}
                            // onClick={() => this.AddBefore()}
                            >Add Before</Button>

                        <Button color="secondary" outline className="float-left"
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            // hidden={(!isAFEAdmin && !isAFEManager) || (afeStatusKey !== 0 && afeStatusKey !== 3)}
                            // onClick={() => this.AddAfter()}
                            >Add After</Button> */}

                        

                    </Col>
                    <Col sm={4}>
                        {/* <ConfirmModal outline={true}
                            color="danger"
                            className="float-right"
                            buttonLabel="Delete"
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            // hidden={(!isAFEAdmin && !isAFEManager) || (afeStatusKey !== 0 && afeStatusKey !== 3)}
                            // disabled={userGroupKey >= 0}
                            disabled
                            formName="Confirm"
                            id={afeHeaderKey}
                            message="Are you sure you want to delete selected email notification record?"
                            // onClickYes={(e) => this.deleteRec()}
                            image={<img
                                src={TrashBin}
                                id={afeHeaderKey}
                                alt="Edit"
                                style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                        </ConfirmModal> */}
                    </Col>



                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="100%"
                        width='100%'
                        dataSource={afeApproval}
                        isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}
                    // cellValueChanging={this.onCellValueChanging}

                    //cellBackground={"#ffbfbf"}
                    >

                        {/* IgrTemplateColumn */}

                        <IgrTemplateColumn
                            field="actionID"
                            headerText="ID"
                            width="70"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="actionDesc"
                            headerText="Approval Desc"
                            width="400"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="lineStatusDesc"
                            headerText="Status"
                            width="100"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="isRequired"
                            headerText="Required"
                            width="120"
                            cellUpdating={this.onCellUpdating}
                        />


                        <IgrTemplateColumn
                            field="actionDate"
                            headerText="Approval Date"
                            width="140"
                            cellUpdating={this.onCellUpdating}
                        />
                        <IgrTemplateColumn
                            field="modifiedByName"
                            headerText="Approval By"
                            width="140"
                            cellUpdating={this.onCellUpdating}
                        />

                        {/* <IgrTextColumn
                            field="displayMaxAmount"
                            headerText="Max Approval"
                            width="120"
                        //  cellUpdating={this.onCellUpdating}
                        /> */}


                        <IgrNumericColumn
                            field="minAmount"
                            headerText="Min"
                            width="100"
                            positivePrefix="$"
                            showGroupingSeparator="true"
                            isHidden
                        //cellUpdating={this.onCellUpdating}

                        />

                        {/* <IgrNumericColumn
                            field="maxAmount"
                            headerText="Max"
                            width="*>120"
                            positivePrefix="$"
                            showGroupingSeparator="true"
                        // cellUpdating={this.onCellUpdating}
                        /> */}
                        <IgrTextColumn
                            field="comments"
                            headerText="Approval Comments"
                            width="*>350"
                        //cellUpdating={this.onStatusCellUpdating}
                        />
                        {/* <IgrTextColumn field="codeDefinitionKey" headerText="Item Number"
                                                        /> */}

                        <IgrNumericColumn field="userGroupKey" isHidden />
                        <IgrNumericColumn field="actionLineKey" isHidden />
                    </IgrDataGrid>

                    {/* </FormGroup> */}
                </Row>

                <div>
                    <Modal isOpen={this.state.modalAppHist}
                        toggle={() => this.toggleModalAppHist()}
                        size="xl">
                        <ModalHeader toggle={() => this.toggleModalAppHist()}>
                            Approval History Log
                        </ModalHeader>
                        <ModalBody>
                            {/* <AFEApprovalHistory afeHeaderKey={afeHeaderKey}
                                toggleModalAppHist={this.toggleModalAppHist}>

                            </AFEApprovalHistory> */}
                        </ModalBody>

                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalAppAdd}
                        toggle={() => this.toggleModalAppAdd()}
                        size="md">
                        <ModalHeader toggle={() => this.toggleModalAppAdd()}>
                            Additional Email & Approval Users
                        </ModalHeader>
                        <ModalBody>
                             <MOCApprovalAdd mocKey={mocKey}
                                refreshData={this.getMOCApproval}
                                empList={this.props.empList}
                                user={this.props.user}
                                selLineActionKey={selLineActionKey}
                                addPosition={addPosition}
                                toggleModalAppAdd={this.toggleModalAppAdd}>

                            </MOCApprovalAdd> 
                        </ModalBody>

                    </Modal>
                </div>


            </div>)
    }


    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.lineStatusKey === 3) {
                //content.style.background = "#f0f5b0";
                content.style.background = "transparent";
            } else if (item.lineStatusKey === 6) {
                content.style.background = "#b8f2b1";
            } else if (item.lineStatusKey === 0) {
                content.style.background = "#fc4e42";
            } else {
                content.style.background = "transparent";
            }
        }

        switch (s.field) {
            case 'actionDesc':
                link.textContent = item.actionDesc;
                break;
            case 'actionID':
                link.textContent = item.actionID;
                break;
            case 'actionDate':
                if (item.lineStatusKey === 6 || item.lineStatusKey === 1) {

                    link.textContent = ""
                    if (item.actionDate !== null) {
                        var date = new Date(item.actionDate);
                        link.textContent = date.toLocaleDateString();
                    }

                }
                break;
            case 'lineStatusDesc':
                link.textContent = item.lineStatusDesc;
                break;
            case 'isRequired':
                link.textContent = item.isRequired;
                break;
            case 'modifiedByName':
                link.textContent = item.modifiedByName;
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'managerName':
                link.textContent = item.managerName;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            default:

        }

    }
}