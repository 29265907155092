//Modified by BN232208 
import React, { Component } from "react";
import {
  apiURL,
  apiHeader,
  apiPUTHeader,
  apiPOSTHeader,
  apiDELETEHeader,
} from "../../resources/apiURL";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import MenuIcon from "../../resources/menu.png";
import Plus from "../../resources/plus.svg";
import Refresh from "../../resources/refresh.svg";
import Revise from "../../resources/revise.png";
import Excel from "../../resources/excel.svg";
import TrashBin from "../../resources/trash.svg";
import "../../custom.css";
import { ExcelUtility } from "../../operation/ExcelUtility";
import Select from "react-select";
import { getUserADAccount, getRandomInt } from "../../resources/utility";
import { IgrTextColumn } from "igniteui-react-grids";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Alert,
  FormFeedback,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Input, CustomInput, InputGroupText } from "reactstrap";
import DocumentBrowserDragDrop from "../../resources/DocumentBrowserDragDrop";
import { IgrDataGrid } from "igniteui-react-grids";
import Datetime from "react-datetime";
import { IgrDataGridModule } from "igniteui-react-grids";
import { IgrNumericColumn } from "igniteui-react-grids";
import { IgrGridColumnOptionsModule } from "igniteui-react-grids";
import { Workbook } from "igniteui-react-excel";
import { WorkbookFormat } from "igniteui-react-excel";
import moment from "moment";
import { IgrTemplateColumn } from "igniteui-react-grids";
import { ColoredLine, getFirstDayofMonth } from "../../resources/utility";
import ConfirmModal from "../../operation/ConfirmModal";
import MOCComments from "./MOCComments";
import MOCTask from "./MOCTask";
import MOCApproval from "./MOCApproval";
import ConfirmMeterValModal from "../ConfirmMeterValModal";
import ConfirmWithMsgModal from "../../resources/ConfirmWithMsgModal";
import MOCRejectModal from "./MOCRejectModal";
import { TreemapValueMappingMode } from "igniteui-react-charts";
import { TimeAxisBreakDescriptionMetadata } from "igniteui-react-core";
export default class MOCDetails extends Component {
  static displayName = MOCDetails.name;
  constructor(props) {
    super(props);
    const urlPara = this.getURLParameters(props.location);
    this.state = {
      readOnly: true,
      mocData: [],
      isMOCLoaded: false,
      searchText: "",
      windowHeight: window.innerHeight,
      mocPriorityList: props.mocPriorityList,
      mocKey: urlPara.mocKey === null ? props.mocKey : urlPara.mocKey,
      user: props.user,
      isTemp: false,
      mocSafetyAndHealthList: props.mocSafetyAndHealthList,
      mocEnvironmentList: props.mocEnvironmentList,
      offLineLinkKey: getRandomInt(10000) * -1,
      mocFinancialList: props.mocFinancialList,
      basinList: props.basinList,
      mocStatus: props.mocStatus,
      statusKey: props.statusKey,
      empList: props.empList,
      isApprover: false,
      facilityList: props.facilityList,
      facilityListDefault: props.facilityList,
      assetList: props.assetList,
      assetListDefault: props.assetList,
      isTempOther: false,
      isTempHazardsOther: false,
      isSecurityAdminLogin: props.isSecurityAdminLogin,
      defaultStatusKey: null,
      isMOCApprover: props.isMOCApprover,
      mocCommentStatusList: props.mocCommentStatusList,
      mocUserList: props.mocUserList,
      modalMsg: false,
      msgHeader: "",
      msgBody: "",
      alertMessage: "",
      showAlert: false,
      validationGroup1: false,
      validationGroup2: false,
      MOCDataParent : props.MOCDataParent,
      mocQuestionsResult: props.mocQuestionsResult,
      // accordionKey: 1,
      validDataItem: {
        summaryOfRequestedChange: true,
        listOfAffectedEquipment: true,
        listOfAffectedJobsRoles: true,
        listOfAffectedMisc: true,
        facilityKey: true,
        assetID: true,
        locationKey: true,
        mocPriorityDesc: true,
        mocPriorityKey: true,
        typeOfChangeProposedKey: true,
        typeOfChangeProposedOther: true,
        safetyAndHealthType: true,
        environmentType: true,
        financialType: true,
        hazardsKey: true,
        hazardsOther: true,
        safetyAndHealthDesc: true,
        environmentDesc: true,
        financialDesc: true,
        proposedChangeTempDate: true,
      },
    };
  }

  getURLParameters = (inputPara) => {
    var paraMeters = {
      mocKey: null,
    };
    var query;
    if (inputPara !== undefined) {
      query = new URLSearchParams(inputPara.search);

      paraMeters = {
        mocKey: query.get("key"),
      };
    }

    return paraMeters;
  };

  // dataValidation = () => {
  //   return new Promise((resolve, reject) => {
  //     const {mocData, validDataItem, isTempOther, isTempHazardsOther} = this.state
  //       const returnVal = { "ok": true, "message": "", "headerValidation": [] }

  //   if (isTempHazardsOther === true && mocData[0].hazardsOther === "") {
  //     validDataItem.hazardsOther = false
  //     returnVal.ok = false
  //     returnVal.message = "Please enter missing data in red"
  // }

  //       if(mocData[0].hazardsKey === null || mocData[0].hazardsKey === ""  || mocData[0].hazardsKey === 0)
  //       {
  //              validDataItem.hazardsKey = false
  //               returnVal.ok = false
  //               returnVal.message = "Please select hazard(s)"
  //       }

  //     if(mocData[0].financialDesc === "" || mocData[0].financialDesc === null)
  //     {
  //           validDataItem.financialDesc = false
  //           returnVal.ok = false
  //           returnVal.message = "Please enter Financial Risk Description"
  //     }
  //       if(mocData[0].financialType === null || mocData[0].financialType === "")
  //       {
  //              validDataItem.financialType = false
  //               returnVal.ok = false
  //               returnVal.message = "Please enter Financial Risk"
  //       }
  //       if(mocData[0].environmentDesc === "" || mocData[0].environmentDesc === null)
  //       {
  //             validDataItem.environmentDesc = false
  //             returnVal.ok = false
  //             returnVal.message = "Please enter Environment Risk Desciption"
  //       }
  //       if(mocData[0].environmentType === null || mocData[0].environmentType === "")
  //       {
  //              validDataItem.environmentType = false
  //               returnVal.ok = false
  //               returnVal.message = "Please enter Environment Risk"
  //       }

  //       if(mocData[0].safetyAndHealthDesc === "" || mocData[0].safetyAndHealthDesc === null)
  //       {
  //             validDataItem.safetyAndHealthDesc = false
  //             returnVal.ok = false
  //             returnVal.message = "Please enter Safety & Health Risk Description"
  //       }
  //       if(mocData[0].safetyAndHealthType === null || mocData[0].safetyAndHealthType === "")
  //       {
  //              validDataItem.safetyAndHealthType = false
  //               returnVal.ok = false
  //               returnVal.message = "Please enter Safety & Health Risk"
  //       }

  //       /*  ----------------------------------------------------------------------------------------------      */
  //       if (mocData[0].lengthOfProposedChangeKey === 1 && (mocData[0].proposedChangeTempDate === null || mocData[0].proposedChangeTempDate === "")) {
  //         validDataItem.proposedChangeTempDate = false
  //         returnVal.ok = false
  //         returnVal.message = "Please enter a date range for Temporary date"

  //           }

  //       if(mocData[0].listOfAffectedMisc === "")
  //       {
  //              validDataItem.listOfAffectedMisc = false
  //               returnVal.ok = false
  //               returnVal.message = "Please enter affected documents, procedures, tests, and/or inspections"
  //       }
  //       if(mocData[0].listOfAffectedJobsRoles === "")
  //       {
  //              validDataItem.listOfAffectedJobsRoles = false
  //               returnVal.ok = false
  //               returnVal.message = "Please enter Affected Jobs/Roles"
  //       }
  //       if(mocData[0].listOfAffectedEquipment === "")
  //       {
  //              validDataItem.listOfAffectedEquipment = false
  //               returnVal.ok = false
  //               returnVal.message = "Please enter Affected Equipment"
  //       }
  //       if (isTempOther === true && mocData[0].typeOfChangeProposedOther === "") {
  //         validDataItem.typeOfChangeProposedOther = false
  //         returnVal.ok = false
  //         returnVal.message = "You have selected 'Other' for type of changed proposed. Please enter a description"
  //     }

  //       if (mocData[0].typeOfChangeProposedKey === null || mocData[0].typeOfChangeProposedKey === "" || mocData[0].typeOfChangeProposedKey === 0 ) {
  //         validDataItem.typeOfChangeProposedKey = false
  //         returnVal.ok = false
  //         returnVal.message = "Please select type of change proposed"
  //     }
  //     if(mocData[0].mocPriorityDesc === "")
  //     {
  //            validDataItem.mocPriorityDesc = false
  //             returnVal.ok = false
  //             returnVal.message = "Please enter Priority"
  //     }

  //     if(mocData[0].mocPriorityKey === null)
  //     {
  //       validDataItem.mocPriorityKey = false
  //        returnVal.ok = false
  //        returnVal.message = "Please enter Priority"
  //     }
  //       if ((mocData[0].assetID === null || mocData[0].assetID === "") && ((mocData[0].locationKey === null && mocData[0].facilityKey === null) ||  (mocData[0].locationKey === "" && mocData[0].facilityKey === ""))) {
  //         validDataItem.assetID = false
  //         returnVal.ok = false
  //         returnVal.message = "Please select a basin, facility, and/or asset"
  //     }

  //       if ((mocData[0].facilityKey === null && mocData[0].locationKey === null) || (mocData[0].facilityKey === "" && mocData[0].locationKey === "") || (mocData[0].facilityKey === undefined && mocData[0].locationKey === undefined)) {

  //         validDataItem.facilityKey = false
  //         validDataItem.locationKey = false
  //         returnVal.ok = false
  //         returnVal.message = "Please select a basin, facility, and/or asset"
  //       }

  //       if (mocData[0].locationKey === null || mocData[0].locationKey === "") {
  //         validDataItem.locationKey = false
  //         returnVal.ok = false
  //         returnVal.message = "Please select a basin, facility, and/or asset"
  //       }
  //         if(mocData[0].summaryOfRequestedChange === "")
  //         {
  //                validDataItem.summaryOfRequestedChange = false
  //                 returnVal.ok = false
  //                 returnVal.message = "Please enter 'Summary of Requested Change'"
  //         }

  //       returnVal.headerValidation = validDataItem

  //       resolve(returnVal)

  //     })
  //   }

  dataValidation = () => {
    return new Promise((resolve, reject) => {
      const { mocData, validDataItem, isTempOther, isTempHazardsOther } =
        this.state;
      const returnVal = { ok: true, message: "", headerValidation: [] };

      /*  ----------------------------------------------------------------------------------------------      */
      if (
        mocData[0].lengthOfProposedChangeKey === 1 &&
        (mocData[0].proposedChangeTempDate === null ||
          mocData[0].proposedChangeTempDate === "")
      ) {
        validDataItem.proposedChangeTempDate = false;
        returnVal.ok = false;
        // returnVal.message = "Please enter a date range for Temporary date"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";

      }

      if (mocData[0].listOfAffectedMisc === "") {
        validDataItem.listOfAffectedMisc = false;
        returnVal.ok = false;
        // returnVal.message = "Please enter affected documents, procedures, tests, and/or inspections"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";

      }
      if (mocData[0].listOfAffectedJobsRoles === "") {
        validDataItem.listOfAffectedJobsRoles = false;
        returnVal.ok = false;
        // returnVal.message = "Please enter Affected Jobs/Roles"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";

      }
      if (mocData[0].listOfAffectedEquipment === "") {
        validDataItem.listOfAffectedEquipment = false;
        returnVal.ok = false;
        // returnVal.message = "Please enter Affected Equipment"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";

      }
      if (isTempOther === true && mocData[0].typeOfChangeProposedOther === "") {
        validDataItem.typeOfChangeProposedOther = false;
        returnVal.ok = false;
        // returnVal.message = "You have selected 'Other' for type of changed proposed. Please enter a description"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";

      }

      if (isTempHazardsOther === true && mocData[0].hazardsOther === "") {
        validDataItem.hazardsOther = false;
        returnVal.ok = false;
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }

      if (
        mocData[0].typeOfChangeProposedKey === null ||
        mocData[0].typeOfChangeProposedKey === "" ||
        mocData[0].typeOfChangeProposedKey === 0
      ) {
        validDataItem.typeOfChangeProposedKey = false;
        returnVal.ok = false;
        // returnVal.message = "Please select type of change proposed"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }
      if (mocData[0].mocPriorityDesc === "") {
        validDataItem.mocPriorityDesc = false;
        returnVal.ok = false;
        // returnVal.message = "Please enter Priority"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }

      if (mocData[0].mocPriorityKey === null) {
        validDataItem.mocPriorityKey = false;
        returnVal.ok = false;
        //  returnVal.message = "Please enter Priority"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }
      if (
        (mocData[0].assetID === null || mocData[0].assetID === "") &&
        ((mocData[0].locationKey === null && mocData[0].facilityKey === null) ||
          (mocData[0].locationKey === "" && mocData[0].facilityKey === ""))
      ) {
        validDataItem.assetID = false;
        returnVal.ok = false;
        // returnVal.message = "Please select a basin, facility, and/or asset"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }

      if (
        (mocData[0].facilityKey === null && mocData[0].locationKey === null) ||
        (mocData[0].facilityKey === "" && mocData[0].locationKey === "") ||
        (mocData[0].facilityKey === undefined &&
          mocData[0].locationKey === undefined)
      ) {
        validDataItem.facilityKey = false;
        validDataItem.locationKey = false;
        returnVal.ok = false;
        // returnVal.message = "Please select a basin, facility, and/or asset"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }

      if (mocData[0].locationKey === null || mocData[0].locationKey === "") {
        validDataItem.locationKey = false;
        returnVal.ok = false;
        // returnVal.message = "Please select a basin, facility, and/or asset"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }
      if (mocData[0].summaryOfRequestedChange === "") {
        validDataItem.summaryOfRequestedChange = false;
        returnVal.ok = false;
        // returnVal.message = "Please enter 'Summary of Requested Change' on Change Request"
        returnVal.message =
          "Please enter missing data in red in the 'Change Request' tab";
      }

      if (returnVal.ok) {
        this.setState({ validationGroup1: true });

        if (
          mocData[0].hazardsKey === null ||
          mocData[0].hazardsKey === "" ||
          mocData[0].hazardsKey === 0
        ) {
          validDataItem.hazardsKey = false;
          returnVal.ok = false;
          // returnVal.message = "Please select hazard(s)"
          returnVal.message =
            "Please enter missing data in red in the 'Risk Analysis' tab";
          // this.setState({ validationGroup1: true });
        }

        if (
          mocData[0].financialDesc === "" ||
          mocData[0].financialDesc === null
        ) {
          validDataItem.financialDesc = false;
          returnVal.ok = false;
          // returnVal.message = "Please enter Financial Risk Description"
          returnVal.message =
            "Please enter missing data in red in the 'Risk Analysis' tab";
          // this.setState({ validationGroup1: true });
        }
        if (
          mocData[0].financialType === null ||
          mocData[0].financialType === ""
        ) {
          validDataItem.financialType = false;
          returnVal.ok = false;
          // returnVal.message = "Please enter Financial Risk"
          returnVal.message =
            "Please enter missing data in red in the 'Risk Analysis' tab";
          // this.setState({ validationGroup1: true });
        }
        if (
          mocData[0].environmentDesc === "" ||
          mocData[0].environmentDesc === null
        ) {
          validDataItem.environmentDesc = false;
          returnVal.ok = false;
          // returnVal.message = "Please enter Environment Risk Desciption"
          returnVal.message =
            "Please enter missing data in red in the 'Risk Analysis' tab";
          // this.setState({ validationGroup1: true });
        }
        if (
          mocData[0].environmentType === null ||
          mocData[0].environmentType === ""
        ) {
          validDataItem.environmentType = false;
          returnVal.ok = false;
          // returnVal.message = "Please enter Environment Risk"
          returnVal.message =
            "Please enter missing data in red in the 'Risk Analysis' tab";
          // this.setState({ validationGroup1: true });
        }

        if (
          mocData[0].safetyAndHealthType === null ||
          mocData[0].safetyAndHealthType === ""
        ) {
          validDataItem.safetyAndHealthType = false;
          returnVal.ok = false;
          // returnVal.message = "Please enter Safety & Health Risk"
          returnVal.message =
            "Please enter missing data in red in the 'Risk Analysis' tab";
          // this.setState({ validationGroup1: true });
        }
        if (
          mocData[0].safetyAndHealthDesc === "" ||
          mocData[0].safetyAndHealthDesc === null
        ) {
          validDataItem.safetyAndHealthDesc = false;
          returnVal.ok = false;
          // returnVal.message = "Please enter Safety & Health Risk Description"
          returnVal.message =
            "Please enter missing data in red in the 'Risk Analysis' tab";
          // this.setState({ validationGroup1: true });
        }
      }
      else{
          this.setState({validationGroup1: false, validationGroup2: true})
      }

      // console.log(this.state.validationGroup1)
      // console.log(this.state.validationGroup2)
      returnVal.headerValidation = validDataItem;

      resolve(returnVal);
    });
  };

  // dataValidation = () => {
  //   return new Promise((resolve, reject) => {
  //     const { mocData, validDataItem, isTempOther, isTempHazardsOther } = this.state;
  //     const returnVal = { ok: true, message: "", headerValidation: [] };

  //     /* Group 1 Validation */
  //     if (mocData[0].lengthOfProposedChangeKey === 1 && (mocData[0].proposedChangeTempDate === null || mocData[0].proposedChangeTempDate === "")) {
  //       validDataItem.proposedChangeTempDate = false;
  //       returnVal.ok = false;
  //       returnVal.message = "Please enter a date range for Temporary date";
  //     }
  //     if (mocData[0].listOfAffectedMisc === "") {
  //       validDataItem.listOfAffectedMisc = false;
  //       returnVal.ok = false;
  //       returnVal.message = "Please enter affected documents, procedures, tests, and/or inspections";
  //     }
  //     // ...rest of Group 1 validations

  //     if (!returnVal.ok) {
  //       // Group 1 validation failed
  //       this.setState(
  //         {
  //           groupOneExpanded: true, // Open changeRequest accordion
  //           groupTwoExpanded: false, // Close riskAnalysis accordion
  //           validDataItem: validDataItem,
  //         },
  //         () => {
  //           console.log("Group 1 Expanded:", this.state.groupOneExpanded);
  //           console.log("Group 2 Expanded:", this.state.groupTwoExpanded);

  //           returnVal.headerValidation = validDataItem;
  //           resolve(returnVal);
  //         }
  //       );
  //       return;
  //     }

  //     /* Group 2 Validation */
  //     if (mocData[0].hazardsKey === null || mocData[0].hazardsKey === "" || mocData[0].hazardsKey === 0) {
  //       validDataItem.hazardsKey = false;
  //       returnVal.ok = false;
  //       returnVal.message = "Please select hazard(s)";
  //     }
  //     if (mocData[0].financialDesc === "" || mocData[0].financialDesc === null) {
  //       validDataItem.financialDesc = false;
  //       returnVal.ok = false;
  //       returnVal.message = "Please enter Financial Risk Description";
  //     }
  //     // ...rest of Group 2 validations

  //     if (!returnVal.ok) {
  //       // Group 1 validation passed, but Group 2 validation failed
  //       this.setState(
  //         {
  //           groupOneExpanded: false, // Close changeRequest accordion
  //           groupTwoExpanded: true, // Open riskAnalysis accordion
  //           validDataItem: validDataItem,
  //         },
  //         () => {
  //           console.log("Group 1 Expanded:", this.state.groupOneExpanded);
  //           console.log("Group 2 Expanded:", this.state.groupTwoExpanded);

  //           returnVal.headerValidation = validDataItem;
  //           resolve(returnVal);
  //         }
  //       );
  //       return;
  //     }

  //     // Both Group 1 and Group 2 validations passed
  //     this.setState(
  //       {
  //         groupOneExpanded: false, // Close changeRequest accordion
  //         groupTwoExpanded: false, // Close riskAnalysis accordion
  //         validDataItem: validDataItem,
  //       },
  //       () => {
  //         console.log("Group 1 Expanded:", this.state.groupOneExpanded);
  //         console.log("Group 2 Expanded:", this.state.groupTwoExpanded);

  //         returnVal.headerValidation = validDataItem;
  //         resolve(returnVal);
  //       }
  //     );
  //   });
  // };

  getSelMOCPriority = (value) => {
    const { mocPriorityList } = this.state;

    if (value !== null && value !== "") {
      return mocPriorityList.filter((e) => e.mocPriorityDesc === value);
    }
    return "";
  };

  getSelMOCSafetyAndHealth = (value) => {
    const { mocSafetyAndHealthList } = this.state;

    if (value !== null && value !== "") {
      return mocSafetyAndHealthList.filter(
        (e) => e.mocSafetyAndHealthDesc === value
      );
    }
    return "";
  };

  getSelMOCEnvironment = (value) => {
    const { mocEnvironmentList } = this.state;

    if (value !== null && value !== "") {
      return mocEnvironmentList.filter((e) => e.mocEnvironmentDesc === value);
    }
    return "";
  };

  getSelMOCFinancial = (value) => {
    const { mocFinancialList } = this.state;

    if (value !== null && value !== "") {
      return mocFinancialList.filter((e) => e.mocFinancialDesc === value);
    }
    return "";
  };


  // getMOC = () => {
  //   const { mocKey, isTemp, isApprover } = this.state;
  //   if (mocKey > 0) {
  //     let myURI = apiURL + "MOC/MOCKey/" + mocKey;
  //     fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
  //       .then((res) => res.json())
  //       .then((json) => {
  //         // console.log(json); // Log the response JSON
  //         this.setState({
  //           isMOCLoaded: true,
  //           mocData: json,
  //           isTemp:
  //             json[0].lengthOfProposedChangeKey === 0
  //               ? false
  //               : json[0].lengthOfProposedChangeKey === null
  //               ? json[0].lengthOfProposedChangeKey
  //               : true,
  //           isApprover: this.isMOCApprover(
  //             json[0].statusKey,
  //             json[0].approvers
  //           ),
  //           defaultStatusKey: json[0].statusKey,
  //         });
  //       });
  //   } else {
  //     const newMOC = this.createMOC();
  //     // console.log(newMOC); // Log the newMOC variable
  //     this.setState({
  //       isMOCLoaded: true,
  //       mocData: newMOC,
  //       readOnly: false,
  //       statusKey: -1,
  //       defaultStatusKey: null,
  //     });
  //   }
  // };

  
  getMOC = (callback) => {
    const { mocKey, isTemp, isApprover } = this.state;
    if (mocKey > 0) {
      let myURI = apiURL + "MOC/MOCKey/" + mocKey;
      fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
        .then((res) => res.json())
        .then((json) => {
          this.setState(
            {
              isMOCLoaded: true,
              mocData: json,
              isTemp:
                json[0].lengthOfProposedChangeKey === 0
                  ? false
                  : json[0].lengthOfProposedChangeKey === null
                  ? json[0].lengthOfProposedChangeKey
                  : true,
              isApprover: this.isMOCApprover(
                json[0].statusKey,
                json[0].approvers
              ),
              defaultStatusKey: json[0].statusKey,
            },
            () => {
              if (typeof callback === "function") {
                callback(json); // Pass the updated mocData back to the callback function
              }
            }
          );
        });
    } else {
      const newMOC = this.createMOC();
      this.setState(
        {
          isMOCLoaded: true,
          mocData: newMOC,
          readOnly: false,
          statusKey: -1,
          defaultStatusKey: null,
        },
        () => {
          if (typeof callback === "function") {
            callback(newMOC); // Pass the newMOC back to the callback function
          }
        }
      );
    }
  };





  isMOCApprover = (statusKey, approver) => {
    const { user } = this.props;
    if (approver !== "" && statusKey === 3) {
      return approver.includes(user.account.userName.toLowerCase());
    } else {
      return false;
    }
  };

  createMOC = () => {
    const { user } = this.props;
    const newWORecord = {
      mocKey: -1,
      mocNumber: "MOCXXXXX",
      statusKey: 0,
      requestedBy: user.account.name,
      summaryOfRequestedChange: "",
      mocPriorityKey: null,
      mocPriorityDesc: "",
      typeOfChangeProposedKey: null,
      typeOfChangeProposedOther: "",
      listOfAffectedEquipment: "",
      listOfAffectedJobsRoles: "",
      listOfAffectedMisc: "",
      lengthOfProposedChangeKey: 0,
      proposedChangeTempDate: null,
      safetyAndHealthKey: null,
      safetyAndHealthType: null,
      safetyAndHealthDesc: "",
      environmentKey: null,
      environmentType: null,
      environmentDesc: "",
      financialKey: null,
      financialType: null,
      financialDesc: "",
      hazardsKey: null,
      hazardsOther: "",
      lat: null,
      long: null,
      createdBy: user.account.name,
      offLineLinkKey: -1,
      locationKey: null,
      assetID: null,
      facilityKey: null,
      //"reqestDateTime": new Date().toISOString(),
    };
    return [newWORecord];
  };

  addMOC = () => {
    const mocCreateDTO = this.createMOCDTO();

    let myURI = apiURL + "MOC/ManagementOfChange";
    fetch(myURI, apiPOSTHeader(mocCreateDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          rspData.then((t) => {
            this.setState({
              msgBody: t,
              msgHeader: "Error",
              modalMsg: !this.state.modalMsg,
            });
          });
          return Promise.reject(error);
        }
        this.props.refreshData();
      })
      .catch((error) => {});
  };

  createMOCDTO = () => {
    const { mocData, user, offLineLinkKey, MOCDataParent, mocQuestionsResult } = this.state;
    const mocCreateDTO = {
      statusKey: mocData[0].statusKey,
      requestedBy: getUserADAccount(user.account.userName),
      summaryOfRequestedChange: mocData[0].summaryOfRequestedChange,
      mocPriorityKey: mocData[0].mocPriorityKey,
      typeOfChangeProposedKey: mocData[0].typeOfChangeProposedKey,
      typeOfChangeProposedOther: mocData[0].typeOfChangeProposedOther,
      listOfAffectedEquipment: mocData[0].listOfAffectedEquipment,
      listOfAffectedJobsRoles: mocData[0].listOfAffectedJobsRoles,
      listOfAffectedMisc: mocData[0].listOfAffectedMisc,
      lengthOfProposedChangeKey: mocData[0].lengthOfProposedChangeKey,
      proposedChangeTempDate: mocData[0].proposedChangeTempDate,
      safetyAndHealthKey: mocData[0].safetyAndHealthKey,
      safetyAndHealthDesc: mocData[0].safetyAndHealthDesc,
      environmentKey: mocData[0].environmentKey,
      environmentDesc: mocData[0].environmentDesc,
      financialKey: mocData[0].financialKey,
      financialDesc: mocData[0].financialDesc,
      hazardsKey: mocData[0].hazardsKey,
      hazardsOther: mocData[0].hazardsOther,
      lat: null,
      long: null,
      createdBy: getUserADAccount(user.account.userName),
      offlineLinkKey: offLineLinkKey,
      locationKey: mocData[0].locationKey,
      facilityKey: mocData[0].facilityKey,
      assetID: mocData[0].assetID,
      mocQuestions: mocQuestionsResult
      // modifiedBy: mocData[0].modifiedBy,
      // "availStartDate": woData[0].availStartDate == null || woData[0].availStartDate === "" ? null : moment(woData[0].availStartDate).format('YYYY-MM-DD HH:mm:ss'),
      // "availEndDate": woData[0].availEndDate == null || woData[0].availEndDate === "" ? null : moment(woData[0].availEndDate).format('YYYY-MM-DD HH:mm:ss'),
    };
    return mocCreateDTO;
  };

  updateMOC = () => {
    const updateMOCDTO = this.updateMOCDTO();

    let myURI = apiURL + "MOC/ManagementOfChange";
    fetch(myURI, apiPUTHeader(updateMOCDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          rspData.then((t) => {
            this.setState({
              msgBody: t,
              msgHeader: "Error",
              modalMsg: !this.state.modalMsg,
            });
          });
          return Promise.reject(error);
        }

        this.props.refreshData();
        // this.getMaterialRequestDetails()
      })
      .catch((error) => {
        // this.getMaterialRequestDetails()
      });
  };

  updateMOCDTO = () => {
    const { mocData } = this.state;
    const { user } = this.props;
    const mocUpdateDTO = {
      mocKey: mocData[0].mocKey,
      statusKey: mocData[0].statusKey,
      requestedBy: mocData[0].requestedBy,
      summaryOfRequestedChange: mocData[0].summaryOfRequestedChange,
      mocPriorityKey: mocData[0].mocPriorityKey,
      typeOfChangeProposedKey: mocData[0].typeOfChangeProposedKey,
      typeOfChangeProposedOther: mocData[0].typeOfChangeProposedOther,
      listOfAffectedEquipment: mocData[0].listOfAffectedEquipment,
      listOfAffectedJobsRoles: mocData[0].listOfAffectedJobsRoles,
      listOfAffectedMisc: mocData[0].listOfAffectedMisc,
      lengthOfProposedChangeKey: mocData[0].lengthOfProposedChangeKey,
      proposedChangeTempDate: mocData[0].proposedChangeTempDate,
      safetyAndHealthKey: mocData[0].safetyAndHealthKey,
      safetyAndHealthDesc: mocData[0].safetyAndHealthDesc,
      environmentKey: mocData[0].environmentKey,
      environmentDesc: mocData[0].environmentDesc,
      financialKey: mocData[0].financialKey,
      financialDesc: mocData[0].financialDesc,
      hazardsKey: mocData[0].hazardsKey,
      hazardsOther: mocData[0].hazardsOther,
      lat: null,
      long: null,
      modifiedBy: getUserADAccount(user.account.userName),
      locationKey: mocData[0].locationKey,
      facilityKey: mocData[0].facilityKey,
      assetID: mocData[0].assetID,
      //userGroupKey: 71,
    };
    return mocUpdateDTO;
  };

  deleteMOC = () => {
    const meterValActionDeleteDTO = this.createMOCDeleteDTO();

    let myURI = apiURL + "moc/ManagementOfChange";
    fetch(myURI, apiDELETEHeader(meterValActionDeleteDTO))
      .then(async (response) => {
        const rspData = response.text();
        if (!response.ok) {
          const error = (rspData && rspData.message) || response.status;
          return Promise.reject(error);
        }
        this.props.refreshData();
        this.closeForm();
      })
      .catch((error) => {});
  };

  createMOCDeleteDTO = () => {
    const { mocKey } = this.state;

    const meterValActionDeleteDTO = {
      mocKey: mocKey,
      deletedBy: "",
    };
    return meterValActionDeleteDTO;
  };

  closeForm = () => {
    this.props.toggle();
  };

  refreshMOCData=()=>{
    this.props.refreshData();
    // this.getMOC()
  }

  

  saveData = () => {
    const { mocData } = this.state;
    this.dataValidation().then((data) => {
      if (data.ok) {
        if (mocData[0].mocKey > 0) {
          this.updateMOC();
          this.closeForm();
        } else {
          this.addMOC();
          this.closeForm();
        }
      } else {
        mocData[0].statusKey = 0;
        this.setState({
            alertMessage: data.message,
            validDataItem: data.headerValidation,
            // validDataItem: data.validDataItem,
            showAlert: true,
            mocData: mocData,
          });
      }
    });
  };

  saveDataOnly = () => {
    const { mocData, defaultStatusKey } = this.state;
    if (defaultStatusKey == null) {
      mocData[0].statusKey = 0;
    } else {
      mocData[0].statusKey = defaultStatusKey;
    }
    this.setState({ mocData: mocData }, () => {
      if (mocData[0].mocKey > 0) {
        this.updateMOC();
        this.closeForm();
      } else {
        this.addMOC();
        this.closeForm();
      }
    });
  };

  componentDidMount = () => {
    this.getMOC();
  };



  onTextChange = (fieldName, fieldValue, e) => {
    // console.log("onTextChange called")

    const {
      mocData,
      validDataItem,
      isTempOther,
      facilityList,
      facilityListDefault,
      assetListDefault,
    } = this.state;
    var { showAlert } = this.state;

    var tempChecked = null;

    switch (fieldName) {
      case "mocPriorityDesc":
        mocData[0].mocPriorityKey = e === null ? "" : e.mocPriorityKey;
        mocData[0].mocPriorityDesc = e === null ? "" : e.mocPriorityDesc;
        validDataItem.mocPriorityDesc = true;
        validDataItem.mocPriorityKey = true;
        break;
      case "typeOfChangeProposedKey":
        if (this.bMultiField(mocData[0].typeOfChangeProposedKey, fieldValue)) {
          mocData[0].typeOfChangeProposedKey =
            mocData[0].typeOfChangeProposedKey - fieldValue;
        } else {
          mocData[0].typeOfChangeProposedKey =
            mocData[0].typeOfChangeProposedKey + fieldValue;
        }
        if (this.bMultiField(mocData[0].typeOfChangeProposedKey, 16) === true) {
          // mocData[0].typeOfChangeProposedOther = "";
          this.setState({ isTempOther: true });
        } else {
          this.setState({ isTempOther: false });
          mocData[0].typeOfChangeProposedOther = "";
          validDataItem.typeOfChangeProposedOther = true;
        }
        // if( this.bMultiField(mocData[0].typeOfChangeProposedKey, 16) === true)
        // {
        //   this.setState({isTempOther: true})
        // }

        validDataItem.typeOfChangeProposedKey = true;
        break;

      case "summaryOfRequestedChange":
        mocData[0].summaryOfRequestedChange = fieldValue;
        validDataItem.summaryOfRequestedChange = true;
        showAlert = false;
        break;

      case "typeOfChangeProposedOther":
        mocData[0].typeOfChangeProposedOther = fieldValue;
        validDataItem.typeOfChangeProposedOther = true;

        break;

      case "listOfAffectedEquipment":
        mocData[0].listOfAffectedEquipment = fieldValue;
        validDataItem.listOfAffectedEquipment = true;
        break;

      case "listOfAffectedJobsRoles":
        mocData[0].listOfAffectedJobsRoles = fieldValue;
        validDataItem.listOfAffectedJobsRoles = true;
        break;

      case "listOfAffectedMisc":
        mocData[0].listOfAffectedMisc = fieldValue;
        validDataItem.listOfAffectedMisc = true;
        break;

      case "lengthOfProposedChangeKey":
        mocData[0].lengthOfProposedChangeKey = fieldValue;
        //tempChecked = false
        this.setState({ isTemp: fieldValue != 0 });
        if (fieldValue === 0) {
          mocData[0].proposedChangeTempDate = null;
          // tempChecked = true
        }
        break;

      case "proposedChangeTempDate":
        if (fieldValue != null || fieldValue != "") {
          mocData[0].proposedChangeTempDate = moment(fieldValue).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          mocData[0].proposedChangeTempDate = null;
        }

        validDataItem.proposedChangeTempDate = true;
        break;

      case "hazardsKey":
        if (this.bMultiField(mocData[0].hazardsKey, fieldValue)) {
          mocData[0].hazardsKey = mocData[0].hazardsKey - fieldValue;
        } else {
          mocData[0].hazardsKey = mocData[0].hazardsKey + fieldValue;
        }

        /*  -------------------------------                  */

        if (this.bMultiField(mocData[0].hazardsKey, 256) === true) {
          this.setState({ isTempHazardsOther: true });
        } else {
          mocData[0].hazardsOther = "";
          this.setState({ isTempHazardsOther: false });
          validDataItem.hazardsOther = true;
        }

        /*  -------------------------------                  */
        validDataItem.hazardsKey = true;
        break;

      case "hazardsOther":
        mocData[0].hazardsOther = fieldValue;
        validDataItem.hazardsOther = true;

        break;

      case "safetyAndHealth":
        mocData[0].safetyAndHealthKey =
          e === null ? "" : e.mocSafetyAndHealthKey;
        mocData[0].safetyAndHealthType =
          e === null ? "" : e.mocSafetyAndHealthDesc;
        validDataItem.safetyAndHealthType = true;
        // validDataItem.safetyAndHealthDesc = true
        break;

      case "safetyAndHealthDesc":
        mocData[0].safetyAndHealthDesc = fieldValue;
        validDataItem.safetyAndHealthDesc = true;
        break;

      case "environment":
        mocData[0].environmentKey = e === null ? "" : e.mocEnvironmentKey;
        mocData[0].environmentType = e === null ? "" : e.mocEnvironmentDesc;
        validDataItem.environmentType = true;

        break;

      case "environmentDesc":
        mocData[0].environmentDesc = fieldValue;
        validDataItem.environmentDesc = true;
        break;

      case "financial":
        mocData[0].financialKey = e === null ? "" : e.mocFinancialKey;
        mocData[0].financialType = e === null ? "" : e.mocFinancialDesc;
        validDataItem.financialType = true;
        break;

      case "financialDesc":
        mocData[0].financialDesc = fieldValue;
        validDataItem.financialDesc = true;
        break;

      case "facility":
        mocData[0].facilityKey = e === null ? "" : e.facilityKey;
        mocData[0].locationKey = e === null ? "" : e.locationKey;
        mocData[0].assetID = null;
        if (e !== null) {
          this.setState({
            assetList: assetListDefault.filter(
              (a) => Number(a.facilityKey) === e.value
            ),
          });
        } else {
          this.setState({
            assetList: assetListDefault,
            facilityList: facilityListDefault,
          });
        }
        validDataItem.facilityKey = true;
        validDataItem.assetID = true;
        validDataItem.locationKey = true;
        break;

      case "basin":
        mocData[0].locationKey = e === null ? "" : e.locationKey;
        mocData[0].facilityKey = e === null ? "" : e.facilityKey;
        mocData[0].assetID = null;
        // facilityList.filter(a=> Number(a.locationKey) === e.value )
        if (e !== null) {
          if (
            mocData[0].facilityKey === "" ||
            mocData[0].facilityKey !== null
          ) {
            this.setState({
              assetList: assetListDefault.filter(
                (a) => Number(a.locationKey) === e.value
              ),
            });
          }
          // else
          // {
          //   this.setState({assetList: assetListDefault})
          // }
          this.setState({
            facilityList: facilityListDefault.filter(
              (a) => Number(a.locationKey) === e.value
            ),
          });
        } else {
          this.setState({
            facilityList: facilityListDefault,
            assetList: assetListDefault,
          });
        }

        // console.log(e.value)
        validDataItem.facilityKey = true;
        validDataItem.assetID = true;
        validDataItem.locationKey = true;
        break;

      case "assetID":
        mocData[0].assetID = e === null ? "" : e.assetID;
        mocData[0].facilityKey = e === null ? "" : e.facilityKey;
        mocData[0].locationKey = e === null ? "" : e.locationKey;

        if (mocData[0].locationKey === null || mocData[0].locationKey === "") {
          this.setState({ assetList: assetListDefault });
        }
        // if(mocData[0].facilityKey === null || mocData[0].facilityKey === "")
        // {
        //   this.setState({assetList: assetListDefault})
        // }

        validDataItem.facilityKey = true;
        validDataItem.assetID = true;
        validDataItem.locationKey = true;
        break;

      default:
    }

    this.setState({
      mocData: mocData,
      validDataItem: validDataItem,
      showAlert: showAlert,
    });
  };

  bMultiField = (value, checkValue) => {
    return (value & checkValue) === checkValue;
  };
  // bMultiField2 = (value, checkValue) => {
  //   return (value & checkValue) === checkValue;
  // };

  getSelBasin = (value) => {
    const { basinList } = this.state;

    if (value !== null && value !== "") {
      return basinList.filter((e) => e.locationKey === value);
    }
    return "";
  };

  getSelFacility = (value) => {
    const { facilityList } = this.state;

    if (value !== null && value !== "") {
      return facilityList.filter((e) => e.facilityKey === value);
    }
    return "";
  };
  getSelAsset = (value) => {
    const { assetList, woData, qrValue } = this.state;

    if (value !== null && value !== "") {
      return assetList.filter((e) => e.assetID === value);
    }
    return "";
  };

  issueMOC = () => {
    const { mocData } = this.state;
    mocData[0].statusKey = 8;
    this.setState({ mocData: mocData });

    this.saveData();
    this.props.refreshData();
  };

  confirmMOC = () => {
    const { mocData, statusKey } = this.state;
    mocData[0].statusKey = 3;
    this.setState({ mocData: mocData });

    this.saveData();
    this.props.refreshData();
  };

  implementMOC = () => {
    const { mocData, statusKey } = this.state;
    mocData[0].statusKey = 9;
    this.setState({ mocData: mocData });

    this.saveData();
    this.props.refreshData();
  };

  reviseMOC = () => {
    const { mocData, statusKey } = this.state;
    mocData[0].statusKey = 10;
    this.setState({ mocData: mocData });

    this.saveData();
    this.props.refreshData();
  };

  acceptMOC = () => {
    const { mocData } = this.state;

    mocData[0].statusKey = 4;
    this.setState({ mocData: mocData }, () => {
      this.saveData();
    });
    // this.props.refreshData()
    // this.closeForm();
  };

  completeMOC = () => {
    const { mocData } = this.state;

    mocData[0].statusKey = 6;
    this.setState({ mocData: mocData }, () => {
      this.saveData();
    });
    // this.props.refreshData()
    // this.closeForm();
  };

  cancelMOC = () => {
    const { mocData } = this.state;

    mocData[0].statusKey = 5;
    this.setState({ mocData: mocData }, () => {
      this.saveData();
    });
  };

  rejectMOC = (comment, key) => {
    this.approvalMOC(0, comment, key);
  };

  approvalMOC = (statusKey, comment, key) => {
    const approvalMOCDTO = this.approvalMOCDTO(statusKey, comment, key);
    let myURI = apiURL + "MOC/Approval";

    fetch(myURI, apiPUTHeader(approvalMOCDTO))
      .then(async (response) => {
        if (!response.ok) {
          const rspData = response.text();
          var error = (rspData && rspData.message) || response.status;

          rspData.then((t) => {
            this.setState({
              msgBody: t,
              msgHeader: "Error",
              modalMsg: !this.state.modalMsg,
            });
          });

          return Promise.reject(error);
        }
        this.props.refreshData();
        this.closeForm();
      })
      .catch((error) => {});
  };

  approvalMOCDTO = (statusKey, comment, actionLineKey) => {
    const { user } = this.props;
    const { mocData } = this.state;
    const mocApprovalDTO = {
      MOCKey: mocData[0].mocKey,
      ApprovalUser: getUserADAccount(user.account.userName),
      ApprovalStatusKey: statusKey,
      ApprovalComment: comment,
      ActionLineKey: actionLineKey,
    };

    return mocApprovalDTO;
  };

  statusFieldReadOnly = (value) => {
    const { mocData, isSecurityAdminLogin, user, validDataItem, statusKey } =
      this.state;
    var returnValue = false;

    if (mocData[0].mocKey < 0) {
      returnValue = false;
    } else if (
      statusKey === 4 &&
      mocData[0].createdBy === getUserADAccount(user.account.userName)
    )
     {
      returnValue = false;
    } 
    else if (
      statusKey === 10 &&
      mocData[0].createdBy === getUserADAccount(user.account.userName)
    ) {
      returnValue = false;
    }
    else if (
      statusKey === 0 &&
      mocData[0].createdBy !== getUserADAccount(user.account.userName)
    ) {
      returnValue = true;
    } else if (statusKey === 0) {
      returnValue = false;
    } else if (isSecurityAdminLogin && statusKey === 0) {
      returnValue = false;
    } else if (statusKey === 8) {
      if (!isSecurityAdminLogin && statusKey === 8) {
        returnValue = true;
      } else if (
        (mocData[0].statusKey === 8 || mocData[0].statusKey === 3) &&
        (validDataItem.summaryOfRequestedChange === false ||
          validDataItem.listOfAffectedEquipment === false ||
          validDataItem.listOfAffectedJobsRoles === false ||
          validDataItem.listOfAffectedMisc === false ||
          validDataItem.facilityKey === false ||
          validDataItem.assetID === false ||
          validDataItem.locationKey === false ||
          validDataItem.mocPriorityDesc === false ||
          validDataItem.mocPriorityKey === false ||
          validDataItem.typeOfChangeProposedKey === false ||
          validDataItem.typeOfChangeProposedOther === false ||
          validDataItem.safetyAndHealthType === false ||
          validDataItem.environmentType === false ||
          validDataItem.financialType === false ||
          validDataItem.hazardsKey === false ||
          validDataItem.safetyAndHealthDesc === false ||
          validDataItem.environmentDesc === false ||
          validDataItem.financialDesc === false ||
          validDataItem.proposedChangeTempDate === false)
      ) {
        returnValue = false;
      }
    } else if (mocData[0].statusKey === 8 && isSecurityAdminLogin) {
      returnValue = false;
    } else if (
      mocData[0].statusKey === 0 &&
      mocData[0].createdBy === getUserADAccount(user.account.userName)
    ) {
      returnValue = false;
    } else if (
      mocData[0].statusKey === 1 &&
      mocData[0].createdBy === getUserADAccount(user.account.userName)
    ) {
      returnValue = false;
    } else if (
      mocData[0].statusKey === 3 ||
      mocData[0].statusKey === 4 ||
      mocData[0].statusKey === 5 ||
      mocData[0].statusKey === 6 ||
      mocData[0].statusKey === 8
    ) {
      returnValue = true;
    } else {
      returnValue = true;
    }

    return returnValue;
  };
  statusButtonReadOnly = (value) => {
    const {
      isSecurityAdminLogin,
      mocData,
      user,
      isApprover,
      validDataItem,
      statusKey,
    } = this.state;
    var returnValue = false;
    if (value === "save") {
      if (mocData[0].mocKey < 0) {
        returnValue = false;
      } else if (
        statusKey === 4 &&
        mocData[0].createdBy === getUserADAccount(user.account.userName)
      ) {
        returnValue = false;
      } else if (!isSecurityAdminLogin && statusKey === 8) {
        returnValue = true;
      } else if (isSecurityAdminLogin && statusKey === 0) {
        returnValue = false;
      } else if (
        statusKey === 0 &&
        mocData[0].createdBy !== getUserADAccount(user.account.userName)
      ) {
        returnValue = true;
      } else if (statusKey === 0 || statusKey === 8) {
        if (
          (mocData[0].statusKey === 8 || mocData[0].statusKey === 3) &&
          (validDataItem.summaryOfRequestedChange === false ||
            validDataItem.listOfAffectedEquipment === false ||
            validDataItem.listOfAffectedJobsRoles === false ||
            validDataItem.listOfAffectedMisc === false ||
            validDataItem.facilityKey === false ||
            validDataItem.assetID === false ||
            validDataItem.locationKey === false ||
            validDataItem.mocPriorityDesc === false ||
            validDataItem.mocPriorityKey === false ||
            validDataItem.typeOfChangeProposedKey === false ||
            validDataItem.typeOfChangeProposedOther === false ||
            validDataItem.safetyAndHealthType === false ||
            validDataItem.environmentType === false ||
            validDataItem.financialType === false ||
            validDataItem.hazardsKey === false ||
            validDataItem.safetyAndHealthDesc === false ||
            validDataItem.environmentDesc === false ||
            validDataItem.financialDesc === false ||
            validDataItem.proposedChangeTempDate === false)
        ) {
          returnValue = false;
        }
      } else if (
        mocData[0].statusKey === 0 &&
        mocData[0].createdBy === getUserADAccount(user.account.userName)
      ) {
        returnValue = false;
      } else if (isSecurityAdminLogin && mocData[0].statusKey === 8) {
        returnValue = false;
      } else if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 1
      ) {
        returnValue = false;
      } else {
        returnValue = true;
      }
      return returnValue;
    }

    if (value === "submit") {
      if (mocData[0].mocKey < 0 && isSecurityAdminLogin) {
        returnValue = true;
      }
      else if (statusKey === 10 && mocData[0].createdBy === getUserADAccount(user.account.userName))
      {
        returnValue = false;
      }  
      else if (statusKey === 0 && isSecurityAdminLogin) {
        returnValue = true;
      } else if (
        statusKey === 0 &&
        mocData[0].createdBy !== getUserADAccount(user.account.userName)
      ) {
        returnValue = true;
      } 
   
      
      else if (statusKey === 0) {
        if (
          (mocData[0].statusKey === 8 || mocData[0].statusKey === 3) &&
          (validDataItem.summaryOfRequestedChange === false ||
            validDataItem.listOfAffectedEquipment === false ||
            validDataItem.listOfAffectedJobsRoles === false ||
            validDataItem.listOfAffectedMisc === false ||
            validDataItem.facilityKey === false ||
            validDataItem.assetID === false ||
            validDataItem.locationKey === false ||
            validDataItem.mocPriorityDesc === false ||
            validDataItem.mocPriorityKey === false ||
            validDataItem.typeOfChangeProposedKey === false ||
            validDataItem.typeOfChangeProposedOther === false ||
            validDataItem.safetyAndHealthType === false ||
            validDataItem.environmentType === false ||
            validDataItem.financialType === false ||
            validDataItem.hazardsKey === false ||
            validDataItem.safetyAndHealthDesc === false ||
            validDataItem.environmentDesc === false ||
            validDataItem.financialDesc === false ||
            validDataItem.proposedChangeTempDate === false)
        ) {
          returnValue = false;
        }
      } else if (mocData[0].mocKey < 0) {
        returnValue = false;
      } else if (
        isSecurityAdminLogin &&
        mocData[0].statusKey === 0 &&
        mocData[0].createdBy === getUserADAccount(user.account.userName)
      ) {
        returnValue = true;
      } else if (
        mocData[0].statusKey === 0 &&
        mocData[0].createdBy === getUserADAccount(user.account.userName)
      ) {
        returnValue = false;
      } else if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 1 &&
        !isSecurityAdminLogin
      ) {
        returnValue = false;
      } else {
        returnValue = true;
      }
      return returnValue;
    }

    if (value === "confirm") {
      if (isSecurityAdminLogin && mocData[0].mocKey < 0) {
        returnValue = false;
      } else if (!isSecurityAdminLogin && statusKey === 8) {
        returnValue = true;
      } else if (statusKey === 0 && isSecurityAdminLogin) {
        returnValue = false;
      } else if (
        isSecurityAdminLogin &&
        mocData[0].statusKey === 0 &&
        mocData[0].createdBy === getUserADAccount(user.account.userName)
      ) {
        returnValue = false;
      } else if (
        (isSecurityAdminLogin && mocData[0].statusKey === 8) ||
        statusKey === 8
      ) {
        returnValue = false;
      } else if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 1 &&
        isSecurityAdminLogin
      ) {
        returnValue = false;
      } else {
        returnValue = true;
      }
      return returnValue;
    }


    if (value === "implemented") {
    if (statusKey === 6 && isSecurityAdminLogin)
    {
      return false
    }
      else {
        returnValue = true;
      }
      return returnValue;
    }

    if (value === "revise") {
      if (statusKey === 3 && mocData[0].createdBy === getUserADAccount(user.account.userName))
      {
        return false
      }
        else {
          returnValue = true;
        }
        return returnValue;
      }

    if (value === "reject") {
      if (isApprover && mocData[0].statusKey === 3) {
        returnValue = false;
      }
      else if(isSecurityAdminLogin && (mocData[0].statusKey === 8))
      {
        returnValue = false
      }
      else {
        returnValue = true;
      }
      return returnValue;
    }

    if (value === "cancel") {
      if (statusKey === 0) {
        returnValue = true;
      
      }
      else if (statusKey === 10 && mocData[0].createdBy === getUserADAccount(user.account.userName))
      {
        returnValue = false;
      }  
      else if (
        statusKey === 4 &&
        mocData[0].createdBy === getUserADAccount(user.account.userName)
      ) {
        returnValue = false;
      } else if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 3
      ) {
        returnValue = false;
      } else if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 1
      ) {
        returnValue = false;
      } else if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 8
      ) {
        returnValue = false;
      } else {
        returnValue = true;
      }
      return returnValue;
    }

    if (value === "complete") {
      if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 4
      ) {
        returnValue = false;
      } else {
        returnValue = true;
      }
      return returnValue;
    }

    if (value === "delete") {
      if (
        mocData[0].createdBy === getUserADAccount(user.account.userName) &&
        mocData[0].statusKey === 0
      ) {
        returnValue = false;
      } else if (isSecurityAdminLogin && statusKey === 0) {
        returnValue = false;
      } else if (
        statusKey === 0 &&
        mocData[0].createdBy !== getUserADAccount(user.account.userName)
      ) {
        returnValue = true;
      } else if (statusKey === 0) {
        if (
          (mocData[0].statusKey === 8 || mocData[0].statusKey === 3) &&
          (validDataItem.summaryOfRequestedChange === false ||
            validDataItem.listOfAffectedEquipment === false ||
            validDataItem.listOfAffectedJobsRoles === false ||
            validDataItem.listOfAffectedMisc === false ||
            validDataItem.facilityKey === false ||
            validDataItem.assetID === false ||
            validDataItem.locationKey === false ||
            validDataItem.mocPriorityDesc === false ||
            validDataItem.mocPriorityKey === false ||
            validDataItem.typeOfChangeProposedKey === false ||
            validDataItem.typeOfChangeProposedOther === false ||
            validDataItem.safetyAndHealthType === false ||
            validDataItem.environmentType === false ||
            validDataItem.financialType === false ||
            validDataItem.hazardsKey === false ||
            validDataItem.safetyAndHealthDesc === false ||
            validDataItem.environmentDesc === false ||
            validDataItem.financialDesc === false ||
            validDataItem.proposedChangeTempDate === false)
        ) {
          returnValue = false;
        }
      } else {
        returnValue = true;
      }

      return returnValue;
    }
  };

  approveMOC = (comment) => {
    this.approvalMOC(6, comment, null);
  };

  toggleAlert = () => {
    this.resetValidDataItem();
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  // toggleAlert = () => {
  //   console.log("toggleAlert called");
  //   const { showAlert } = this.state;
  //   this.resetValidDataItem();
  //   this.setState(
  //     {
  //       showAlert: !showAlert
  //     },
  //     () => {
  //       console.log("xxxx" + showAlert);
  //     }
  //   );
  // };

  resetValidDataItem = () => {
    const { validDataItem } = this.state;

    validDataItem.summaryOfRequestedChange = true;
    validDataItem.listOfAffectedEquipment = true;
    validDataItem.listOfAffectedJobsRoles = true;
    validDataItem.listOfAffectedMisc = true;
    validDataItem.facilityKey = true;
    validDataItem.assetID = true;
    validDataItem.locationKey = true;
    validDataItem.mocPriorityDesc = true;
    validDataItem.mocPriorityKey = true;
    validDataItem.typeOfChangeProposedKey = true;
    validDataItem.typeOfChangeProposedOther = true;
    validDataItem.safetyAndHealthType = true;
    validDataItem.environmentType = true;
    validDataItem.financialType = true;
    validDataItem.hazardsKey = true;
    validDataItem.hazardsOther = true;
    validDataItem.safetyAndHealthDesc = true;
    validDataItem.environmentDesc = true;
    validDataItem.financialDesc = true;
    validDataItem.proposedChangeTempDate = true;

    this.setState({ validDataItem: validDataItem });
  };


  
  toggleMsg = () => {
    this.setState({ modalMsg: !this.state.modalMsg })
}


  render() {
    const {
      mocData,
      isMOCLoaded,
      mocPriorityList,
      isTemp,
      mocSafetyAndHealthList,
      mocEnvironmentList,
      mocFinancialList,
      mocKey,
      offLineLinkKey,
      basinList,
      empList,
      isApprover,
      facilityList,
      assetList,
      validDataItem,
      isSecurityAdminLogin,
      facilityListDefault,
      assetListDefault,
      isMOCApprover,
      mocCommentStatusList,
      mocUserList,
      alertMessage,
      showAlert,
      statusKey,
      validationGroup1,
      validationGroup2,
      msgBody,
      msgHeader
    } = this.state;
    if (!isMOCLoaded) {
      return <div>Loading MOC data.....</div>;
    } else
      return (
        <div>
          <Row>
            <Col md={6} style={{ position: "relative" }}>
              <div className="float-left">
                <Button
                  style={{ margin: "0px 10px 0px 0px" }}
                  color="secondary"
                  className="float-left"
                  onClick={() => this.closeForm()}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  style={{ margin: "0px 10px 0px 0px" }}
                  className="float-left"
                  hidden={this.statusButtonReadOnly("save")}
                  onClick={() => this.saveDataOnly()}
                >
                  Save
                </Button>
              </div>

              <ConfirmModal
                color="success"
                className="float-left"
                buttonLabel="Submit"
                style={{ margin: "0px 10px 0px 0px" }}
                hidden={this.statusButtonReadOnly("submit")}
                formName="Success"
                // id={receiverKey}
                message="Are you sure you want to submit MOC?"
                // onClickYes={(e) => this.issueMOC()} //Commented out by BN 2/14/24 per Ryan's request
                onClickYes={(e) => this.confirmMOC()}
              ></ConfirmModal>

              <ConfirmModal
                color="success"
                className="float-left"
                buttonLabel="Confirm"
                hidden={this.statusButtonReadOnly("confirm")}
                formName="Success"
                message="Are you sure you want to confirm MOC?"
                onClickYes={(e) => this.confirmMOC()}
              ></ConfirmModal>

              <ConfirmModal
                color="warning"
                className="float-left"
                buttonLabel="Revise"
                hidden={this.statusButtonReadOnly("revise")}
                formName="Revise"
                message="Are you sure you want to revise MOC?"
                onClickYes={(e) => this.reviseMOC()}
              ></ConfirmModal>
              

              <ConfirmModal
                color="success"
                className="float-left"
                buttonLabel="Implement"
                hidden={this.statusButtonReadOnly("implemented")}
                formName="Success"
                message="Are you sure you want to confirm MOC?"
                onClickYes={(e) => this.implementMOC()}
              ></ConfirmModal>

              <ConfirmWithMsgModal
                color="success"
                buttonLabel={"Approve"}
                style={{ marginLeft: "10px" }}
                hidden={!isApprover}
                formName="Confirm"
                id={mocKey}
                message=""
                inputComment="Approved."
                isCommentRequired={false}
                commentPrompMsg="Please Enter Approval Comment:"
                onClickYes={(e) => this.approveMOC(e)}
              ></ConfirmWithMsgModal>



              <ConfirmModal
                color="success"
                className="float-left"
                buttonLabel="Complete"
                style={{ marginLeft: "5px" }}
                hidden={this.statusButtonReadOnly("complete")}
                formName="Complete"
                message="Are you sure you want to complete the selected MOC?"
                onClickYes={(e) => this.completeMOC()}
              ></ConfirmModal>
            </Col>

            <Col md={6}>
              <ConfirmModal
                outline={true}
                color="danger"
                className="float-right"
                buttonLabel="Delete"
                hidden={this.statusButtonReadOnly("delete")}
                formName="Delete"
                message="Are you sure you want to delete selected moc?"
                onClickYes={(e) => this.deleteMOC()}
                image={
                  <img
                    src={TrashBin}
                    id={mocKey}
                    alt="Delete"
                    style={{ width: "20px", margin: "0 5px 0 0" }}
                  />
                }
              ></ConfirmModal>

              <ConfirmMeterValModal
                outline={false}
                color="danger"
                className="float-right"
                buttonLabel="Cancel"
                style={{ marginRight: "5px" }}
                hidden={this.statusButtonReadOnly("cancel")}
                formName="Cancel"
                message="Are you sure you want to cancel selected issue/request?"
                onChangeTest={(e) =>
                  this.onTextChange("test", e.target.value, e)
                }
                onClickYes={(e) => this.cancelMOC()}
              />


              <MOCRejectModal
                color="danger"
                buttonLabel="Reject"
                className="float-right"
                hidden={this.statusButtonReadOnly("reject")}
                formName="Reject MOC"
                id={mocKey}
                mocKey={mocKey}
                message=""
                commentPrompMsg="Please Enter Reject Comment:"
                onClickYes={(e, key) => this.rejectMOC(e, key)}
              ></MOCRejectModal>
            </Col>

            {/* <Col style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "-15px",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Alert
                  color="danger"
                  toggle={() => this.toggleAlert()}
                  // className="float-right"
                  isOpen={showAlert}
                  style={{ fontSize: "14px" }}
                >
                  {alertMessage}
                </Alert>
              </div>
            </Col> */}

          </Row>

          <Accordion
            // allowMultipleExpanded={true}
            allowZeroExpanded={true}
            // allowZeroExpanded = {"validationGroup1: " + validationGroup1 + "validationGroup2: " + validationGroup2}
            preExpanded={validationGroup1 ? ["riskAnalysis"] : ["changeRequest"]}
            style={{ marginTop: "10px" }}
            // key={(validationGroup1 ? Math.random():1)}
            // key={(validationGroup1 || validationGroup2 ? 1:0)}
            key={validationGroup1.toString() + validationGroup2.toString()}
            // key={(console.log(validationGroup1 === true) || console.log(validationGroup2 === true))}
            // key={accordionKey}
          >
            <AccordionItem uuid="changeRequest">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <strong>Change Request</strong>
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="afeNo">Requested By</Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          type="text"
                          name="afeNo"
                          id="afeNo"
                          placeholder=""
                          disabled
                          value={mocData[0].requestedBy.replace(".", " ")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="mocSummary">
                          Summary of Requested Change
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          type="textarea"
                          name="mocSummary"
                          rows={4}
                          id="mocSummary"
                          invalid={!validDataItem.summaryOfRequestedChange}
                          placeholder=""
                          // className={(!validDataItem.summaryOfRequestedChange ? "form-error" : "")}
                          value={mocData[0].summaryOfRequestedChange}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange(
                              "summaryOfRequestedChange",
                              e.target.value,
                              e
                            )
                          }
                        />
                        <FormFeedback invalid>
                          Please enter a description
                        </FormFeedback>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* 
                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="mocPriority">Basin</Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          options={basinList}
                          isDisabled={this.statusFieldReadOnly()}
                          //  className={(!validDataItem.locationKey ? "alway-on-top-more-more form-error" : "alway-on-top-more-more")}
                          className={"alway-on-top-more-more"}
                          // invalid={!validDataItem.locationKey}
                          invalid={true}
                          value={this.getSelBasin(mocData[0].locationKey)}
                          onChange={(e) => this.onTextChange("basin", e, e)}
                          placeholder={""}
                          isClearable={true}
                        ></Select>
                         <FormFeedback invalid>Please select a Basin</FormFeedback>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}

                {/* <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
  <Col md={12}>
    <FormGroup>
      <Row form>
        <Col md={4}>
          <Label for="mocPriority">Basin</Label>
        </Col>
        <Col md={8}>
          <Select
            options={basinList}
            isDisabled={this.statusFieldReadOnly()}
            className={"alway-on-top-more-more"}
            invalid={!validDataItem.locationKey}
            value={this.getSelBasin(mocData[0].locationKey)}
            onChange={(e) => this.onTextChange("basin", e, e)}
            placeholder={""}
            isClearable={true}
          ></Select>
          <FormFeedback>Please select a Basin</FormFeedback>
        </Col>
      </Row>
    </FormGroup>
  </Col>
</Row> */}

                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <FormGroup>
                      <Row form>
                        <Col md={4}>
                          <Label for="mocPriority">Basin</Label>
                        </Col>
                        <Col md={8}>
                          <Select
                            options={basinList}
                            isDisabled={this.statusFieldReadOnly()}
                            className={
                              !validDataItem.locationKey
                                ? "alway-on-top-more-more form-error"
                                : "alway-on-top-more-more "
                            }
                            value={this.getSelBasin(mocData[0].locationKey)}
                            onChange={(selectedOption) =>
                              this.onTextChange(
                                "basin",
                                selectedOption,
                                selectedOption
                              )
                            }
                            placeholder=""
                            isClearable={true}
                          />
                          {!validDataItem.locationKey && (
                            <div className="text-danger form-control-feedback">
                              Please select a Basin, Facility, and/or Asset
                            </div>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="mocPriority">Facility</Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          options={
                            mocData[0].locationKey !== null &&
                            mocData[0].locationKey !== "" &&
                            mocData[0].locationKey !== undefined
                              ? facilityList.filter(
                                  (a) =>
                                    Number(a.locationKey) ===
                                    mocData[0].locationKey
                                )
                              : facilityList
                          }
                          isDisabled={this.statusFieldReadOnly()}
                          //  className={(!validDataItem.facilityID ? "alway-on-top-more form-error" : "")}
                          className={
                            !validDataItem.facilityKey
                              ? "alway-on-top-more form-error"
                              : "alway-on-top-more"
                          }
                          // className={"alway-on-top-more"}
                          // invalid={!validDataItem.facilityKey }
                          value={this.getSelFacility(mocData[0].facilityKey)}
                          onChange={(e) => this.onTextChange("facility", e, e)}
                          placeholder={""}
                          isClearable={true}
                        ></Select>
                        {!validDataItem.facilityKey && (
                          <div className="text-danger form-control-feedback">
                            Please select a Basin, Facility, and/or Asset
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="mocPriority">Asset</Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          options={
                            mocData[0].facilityKey !== null &&
                            mocData[0].facilityKey !== "" &&
                            mocData[0].facilityKey !== undefined
                              ? assetList.filter(
                                  (a) =>
                                    Number(a.facilityKey) ===
                                    mocData[0].facilityKey
                                )
                              : mocData[0].locationKey !== null &&
                                mocData[0].locationKey !== "" &&
                                mocData[0].locationKey !== undefined
                              ? assetList.filter(
                                  (a) =>
                                    Number(a.locationKey) ===
                                    mocData[0].locationKey
                                )
                              : assetList
                          }
                          // disabled={console.log(mocData[0].locationKey !== null && mocData[0].locationKey !== "" + "yyyyy")}

                          // isDisabled={readOnly}
                          // isDisabled={ (isSecurityAdminLogin &&(readOnly &&(mocData[0].statusKey != 0 || mocData[0].statusKey === 6 || mocData[0].statusKey === 5) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // isDisabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          isDisabled={this.statusFieldReadOnly()}
                          className={
                            !validDataItem.assetID
                              ? "alway-on-top form-error"
                              : "alway-on-top"
                          }
                          // className={"alway-on-top"}
                          // invalid={!validDataItem.assetID}
                          value={this.getSelAsset(mocData[0].assetID)}
                          onChange={(e) => this.onTextChange("assetID", e, e)}
                          placeholder={""}
                          isClearable={true}
                        ></Select>
                        {!validDataItem.assetID && (
                          <div className="text-danger form-control-feedback">
                            Please select a Basin, Facility, and/or Asset
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="mocPriority">Priority</Label>
                      </Col>
                      <Col md={8}>
                        <Select
                          options={mocPriorityList}
                          // isDisabled={readOnly}
                          // isDisabled={(isSecurityAdminLogin &&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          className={
                            !validDataItem.mocPriorityKey ||
                            !validDataItem.mocPriorityDesc
                              ? "alway-on-top-fiix form-error"
                              : "alway-on-top-fiix"
                          }
                          // className={"alway-on-top-fiix"}
                          // invalid={!validDataItem.mocPriorityKey || !validDataItem.mocPriorityDesc }
                          isDisabled={this.statusFieldReadOnly()}
                          //className={"alway-on-top-fiix"}
                          value={this.getSelMOCPriority(
                            mocData[0].mocPriorityDesc
                          )}
                          onChange={(e) =>
                            this.onTextChange("mocPriorityDesc", e, e)
                          }
                          placeholder={""}
                          isClearable={true}
                        ></Select>
                        {(!validDataItem.mocPriorityKey ||
                          !validDataItem.mocPriorityDesc) && (
                          <div className="text-danger form-control-feedback">
                            Please select the priority
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={4}>
                    <Row form>
                      <Col md={10}>
                        <Label for="mocProposed">Type of change proposed</Label>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={14}>
                    <Row form>
                      <Col md={14}>
                        <Col md={14}>
                          <CustomInput
                            type="checkbox"
                            id="mechanical"
                            //className={"alway-in-between"}
                            label="Process/Procedure Change"
                            checked={this.bMultiField(
                              mocData[0].typeOfChangeProposedKey,
                              1
                            )}
                            // className={(!validDataItem.typeOfChangeProposedKey ? "form-error" : "")}
                            invalid={!validDataItem.typeOfChangeProposedKey}
                            inline
                            // disabled={isReadOnly}
                            // disabled={(isSecurityAdminLogin &&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                            // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                            //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                            //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                            disabled={this.statusFieldReadOnly()}
                            onChange={(e) =>
                              this.onTextChange("typeOfChangeProposedKey", 1, e)
                            }
                          />
                        </Col>
                        <Col md={14}>
                          <CustomInput
                            type="checkbox"
                            id="mechanical2"
                            label="Equipment Change or Modification"
                            checked={this.bMultiField(
                              mocData[0].typeOfChangeProposedKey,
                              2
                            )}
                            // className={(!validDataItem.typeOfChangeProposedKey ? "form-error" : "")}
                            invalid={!validDataItem.typeOfChangeProposedKey}
                            inline
                            // disabled={isReadOnly}
                            // disabled={(isSecurityAdminLogin &&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                            // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                            //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                            //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                            disabled={this.statusFieldReadOnly()}
                            onChange={(e) =>
                              this.onTextChange("typeOfChangeProposedKey", 2, e)
                            }
                          />
                        </Col>
                        <Col md={14}>
                          <CustomInput
                            type="checkbox"
                            id="mechanical3"
                            label="Software or Application Change or Modification"
                            // className={(!validDataItem.typeOfChangeProposedKey ? "form-error" : "")}
                            invalid={!validDataItem.typeOfChangeProposedKey}
                            checked={this.bMultiField(
                              mocData[0].typeOfChangeProposedKey,
                              4
                            )}
                            inline
                            // disabled={isReadOnly}
                            // disabled={(isSecurityAdminLogin &&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                            // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                            //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                            //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                            disabled={this.statusFieldReadOnly()}
                            onChange={(e) =>
                              this.onTextChange("typeOfChangeProposedKey", 4, e)
                            }
                          />
                        </Col>
                        <Col md={14}>
                          <CustomInput
                            type="checkbox"
                            id="mechanical4"
                            label="Setpoint Change"
                            // className={(!validDataItem.typeOfChangeProposedKey ? "form-error" : "")}
                            invalid={!validDataItem.typeOfChangeProposedKey}
                            checked={this.bMultiField(
                              mocData[0].typeOfChangeProposedKey,
                              8
                            )}
                            inline
                            // disabled={isReadOnly}
                            // disabled={(isSecurityAdminLogin &&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                            // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                            //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                            //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                            disabled={this.statusFieldReadOnly()}
                            onChange={(e) =>
                              this.onTextChange("typeOfChangeProposedKey", 8, e)
                            }
                          />
                        </Col>
                        <Col md={14}>
                          <CustomInput
                            type="checkbox"
                            id="mechanical5"
                            label="Other"
                            checked={this.bMultiField(
                              mocData[0].typeOfChangeProposedKey,
                              16
                            )}
                            inline
                            // className={(!validDataItem.typeOfChangeProposedKey ? "form-error" : "")}
                            invalid={!validDataItem.typeOfChangeProposedKey}
                            // disabled={isReadOnly}
                            // disabled={(isSecurityAdminLogin &&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                            // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                            //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                            //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                            disabled={this.statusFieldReadOnly()}
                            onChange={(e) =>
                              this.onTextChange(
                                "typeOfChangeProposedKey",
                                16,
                                e
                              )
                            }
                          />
                        </Col>
                        <Col md={14}>
                          <Input
                            type="textarea"
                            name="typeOfChangeProposedOther"
                            rows={1}
                            id="mocJobs"
                            placeholder=""
                            // className={(!validDataItem.typeOfChangeProposedOther ? "form-error" : "")}
                            invalid={!validDataItem.typeOfChangeProposedOther}
                            value={
                              !this.bMultiField(
                                mocData[0].typeOfChangeProposedKey,
                                16
                              )
                                ? ""
                                : mocData[0].typeOfChangeProposedOther
                            }
                            //Need to fix disabled when submitted
                            // disabled={
                            //    (mocData[0].statusKey === 6 ||
                            //   !this.bMultiField(
                            //     mocData[0].typeOfChangeProposedKey,
                            //     16
                            //   )
                            //   || (mocData[0].statusKey != 0 && readOnly &&isSecurityAdminLogin)) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))
                            // }
                            // disabled={(
                            //   !this.bMultiField(
                            //       mocData[0].typeOfChangeProposedKey,
                            //       16
                            //     )) ? true: isSecurityAdminLogin ? false : true
                            //     }

                            // disabled={mocData[0].statusKey ===0 ?

                            //          !this.bMultiField(
                            //       mocData[0].typeOfChangeProposedKey,
                            //       16
                            //     ): statusKey === 8 ?(

                            //      isSecurityAdminLogin ?     !this.bMultiField(
                            //       mocData[0].typeOfChangeProposedKey,
                            //       16
                            //     ) : true ) : true

                            // }

                            disabled={
                              this.statusFieldReadOnly() ||
                              !this.bMultiField(
                                mocData[0].typeOfChangeProposedKey,
                                16
                              )
                            }
                            // disabled={!(mocData[0].statusKey === 0 ?
                            //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                            //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false))}
                            onChange={(e) =>
                              this.onTextChange(
                                "typeOfChangeProposedOther",
                                e.target.value,
                                e
                              )
                            }
                          />
                        </Col>
                        {!validDataItem.typeOfChangeProposedKey && (
                          <div className="text-danger form-control-feedback">
                            Please select at least one option
                          </div>
                        )}
                        {!validDataItem.typeOfChangeProposedOther && (
                          <div className="text-danger form-control-feedback">
                            Please enter a description
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="afeNo">List of affected equipment</Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          type="textarea"
                          name="mocEqipment"
                          rows={4}
                          id="afeNo"
                          placeholder=""
                          // className={(!validDataItem.listOfAffectedEquipment ? "form-error" : "")}
                          invalid={!validDataItem.listOfAffectedEquipment}
                          // disabled={(isSecurityAdminLogin&&(readOnly &(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          value={mocData[0].listOfAffectedEquipment}
                          onChange={(e) =>
                            this.onTextChange(
                              "listOfAffectedEquipment",
                              e.target.value,
                              e
                            )
                          }
                        />
                        <FormFeedback invalid>
                          Please enter affected equipment(s)
                        </FormFeedback>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="mocJobs">List of affected jobs/roles</Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          type="textarea"
                          name="afeNo"
                          rows={4}
                          id="mocJobs"
                          placeholder=""
                          // className={(!validDataItem.listOfAffectedJobsRoles ? "form-error" : "")}
                          invalid={!validDataItem.listOfAffectedJobsRoles}
                          value={mocData[0].listOfAffectedJobsRoles}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange(
                              "listOfAffectedJobsRoles",
                              e.target.value,
                              e
                            )
                          }
                        />
                        <FormFeedback invalid>
                          Please enter affected jobs/roles
                        </FormFeedback>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="mocMisc">
                          List of affected documents, procedures, tests, and/or
                          inspections
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          type="textarea"
                          name="afeNo"
                          rows={4}
                          id="mocMisc"
                          placeholder=""
                          // className={(!validDataItem.listOfAffectedMisc ? "form-error" : "")}
                          invalid={!validDataItem.listOfAffectedMisc}
                          value={mocData[0].listOfAffectedMisc}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange(
                              "listOfAffectedMisc",
                              e.target.value,
                              e
                            )
                          }
                        />
                        <FormFeedback invalid>
                          Please enter affected documents, procedures, tests,
                          and/or inspections
                        </FormFeedback>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row form>
                  <Col md={4}>
                    <Label for="mocJobs">Length of Proposed Change</Label>
                  </Col>

                  <Col md={14}>
                    <CustomInput
                      type="checkbox"
                      id="permenant"
                      label="Permanent"
                      checked={isTemp === null ? false : !isTemp}
                      inline
                      // disabled={isReadOnly}
                      // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                      // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                      //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                      //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                      disabled={this.statusFieldReadOnly()}
                      onChange={(e) =>
                        this.onTextChange("lengthOfProposedChangeKey", 0, e)
                      }
                    />
                  </Col>
                  <Col md={14}>
                    <CustomInput
                      type="checkbox"
                      id="temporary"
                      label="Temporary"
                      checked={isTemp === null ? false : isTemp}
                      inline
                      // disabled={isReadOnly}
                      // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5|| mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                      // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                      //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                      //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                      disabled={this.statusFieldReadOnly()}
                      onChange={(e) =>
                        this.onTextChange("lengthOfProposedChangeKey", 1, e)
                      }
                    />
                  </Col>

                  <Col md={14}>
                    <Datetime
                      inputProps={{
                        placeholder: "MM/DD/YYYY hh:mm",
                        hidden: !isTemp,
                        // disabled: (mocData[0].statusKey === 6) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))
                        disabled: this.statusFieldReadOnly(),
                      }}
                      className={
                        !validDataItem.proposedChangeTempDate
                          ? "form-error"
                          : ""
                      }
                      // value={moment(data[0].incDateTime).format("MM/DD/YYYY hh:mm")}
                      value={moment(mocData[0].proposedChangeTempDate)}
                      onChange={(v, f) =>
                        this.onTextChange("proposedChangeTempDate", v, f)
                      }
                    />
                    {!validDataItem.proposedChangeTempDate && (
                      <div className="text-danger form-control-feedback">
                        Please enter a date
                      </div>
                    )}
                  </Col>
                </Row>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="riskAnalysis">
              <AccordionItemHeading>
                <AccordionItemButton >
                  <strong>Risk Analysis</strong>

                 
                  {/* <AccordionItemState >
                  {(expanded) => (validationGroup1 ? "expanded" : "collapsed")}
                  </AccordionItemState> */}
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="afeNo">Safety & Health</Label>
                      </Col>
                      <Col md={4}>
                        <Select
                          options={mocSafetyAndHealthList}
                          // isDisabled={readOnly}

                          // isDisabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // isDisabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          isDisabled={this.statusFieldReadOnly()}
                          // className={"alway-on-top-more-more"}
                          className={
                            !validDataItem.safetyAndHealthType
                              ? "alway-on-top-more-more form-error"
                              : "alway-on-top-more-more"
                          }
                          value={this.getSelMOCSafetyAndHealth(
                            mocData[0].safetyAndHealthType
                          )}
                          onChange={(e) =>
                            this.onTextChange("safetyAndHealth", e, e)
                          }
                          placeholder={""}
                          isClearable={true}
                        ></Select>
                        {!validDataItem.safetyAndHealthType && (
                          <div className="text-danger form-control-feedback">
                            Please select safety & health hazard
                          </div>
                        )}
                      </Col>

                      <Col md={4}>
                        <Input
                          type="textarea"
                          name="safetyAndHealthDesc"
                          rows={3}
                          id="safetyAndHealthDesc"
                          // placeholder="Description"
                          // className = {(!validDataItem.safetyAndHealthDesc ? "form-error" : "")}
                          invalid={!validDataItem.safetyAndHealthDesc}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          value={mocData[0].safetyAndHealthDesc}
                          onChange={(e) =>
                            this.onTextChange(
                              "safetyAndHealthDesc",
                              e.target.value,
                              e
                            )
                          }
                        />
                        {!validDataItem.safetyAndHealthDesc && (
                          <div className="text-danger form-control-feedback">
                            Please select a description
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="afeNo">Environment</Label>
                      </Col>
                      <Col md={4}>
                        <Select
                          options={mocEnvironmentList}
                          // isDisabled={readOnly}
                          // isDisabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // isDisabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          isDisabled={this.statusFieldReadOnly()}
                          //className={"alway-on-top-more"}
                          className={
                            !validDataItem.environmentType
                              ? "alway-on-top-more form-error"
                              : "alway-on-top-more"
                          }
                          value={this.getSelMOCEnvironment(
                            mocData[0].environmentType
                          )}
                          onChange={(e) =>
                            this.onTextChange("environment", e, e)
                          }
                          placeholder={""}
                          isClearable={true}
                        ></Select>
                        {!validDataItem.environmentType && (
                          <div className="text-danger form-control-feedback">
                            Please select an environment hazard
                          </div>
                        )}
                      </Col>
                      <Col md={4}>
                        <Input
                          type="textarea"
                          name="environmentDesc"
                          rows={3}
                          id="environmentDesc"
                          // placeholder="Description"
                          value={mocData[0].environmentDesc}
                          // className = {(!validDataItem.environmentDesc ? "form-error" : "")}
                          invalid={!validDataItem.environmentDesc}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange(
                              "environmentDesc",
                              e.target.value,
                              e
                            )
                          }
                        />
                        {!validDataItem.environmentDesc && (
                          <div className="text-danger form-control-feedback">
                            Please enter a description
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={12}>
                    <Row form>
                      <Col md={4}>
                        <Label for="afeNo">Financial</Label>
                      </Col>
                      <Col md={4}>
                        <Select
                          options={mocFinancialList}
                          // isDisabled={readOnly}
                          // isDisabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // isDisabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          isDisabled={this.statusFieldReadOnly()}
                          //className={"alway-on-top"}
                          className={
                            !validDataItem.financialType
                              ? "alway-on-top form-error"
                              : "alway-on-top"
                          }
                          value={this.getSelMOCFinancial(
                            mocData[0].financialType
                          )}
                          onChange={(e) => this.onTextChange("financial", e, e)}
                          placeholder={""}
                          isClearable={true}
                        ></Select>
                        {!validDataItem.financialType && (
                          <div className="text-danger form-control-feedback">
                            Please select an financial hazard
                          </div>
                        )}
                      </Col>
                      <Col md={4}>
                        <Input
                          type="textarea"
                          name="financialDesc"
                          rows={3}
                          id="financialDesc"
                          // placeholder="Description"
                          value={mocData[0].financialDesc}
                          // className = {(!validDataItem.financialDesc ? "form-error" : "")}
                          invalid={!validDataItem.financialDesc}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange(
                              "financialDesc",
                              e.target.value,
                              e
                            )
                          }
                        />
                        {!validDataItem.financialDesc && (
                          <div className="text-danger form-control-feedback">
                            Please enter a description
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row form style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col md={4}>
                    <Row form>
                      <Col md={10}>
                        <Label for="mocProposed">Hazards</Label>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row form>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="heights"
                          label="Working at heights"
                          checked={this.bMultiField(mocData[0].hazardsKey, 1)}
                          inline
                          // disabled={isReadOnly}
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 1, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="load"
                          label="Heavy or suspended load"
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          checked={this.bMultiField(mocData[0].hazardsKey, 2)}
                          inline
                          // disabled={isReadOnly}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 2, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="energy"
                          label="Trapped energy (LOTO Required)"
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          checked={this.bMultiField(mocData[0].hazardsKey, 4)}
                          inline
                          // disabled={isReadOnly}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 4, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="electrical"
                          label="Electrical hazard"
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          checked={this.bMultiField(mocData[0].hazardsKey, 8)}
                          inline
                          // disabled={isReadOnly}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 8, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="space"
                          label="Confined space"
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          checked={this.bMultiField(mocData[0].hazardsKey, 16)}
                          inline
                          // disabled={isReadOnly}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 16, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="h2s"
                          label="H2s (SCBA or Active Monitoring Required)"
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          checked={this.bMultiField(mocData[0].hazardsKey, 32)}
                          inline
                          // disabled={isReadOnly}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 32, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="pinch"
                          label="Pinch Points"
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          checked={this.bMultiField(mocData[0].hazardsKey, 64)}
                          inline
                          // disabled={isReadOnly}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 64, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <CustomInput
                          type="checkbox"
                          id="class"
                          label="Class I Div I Environment (Combustible Environment)"
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          checked={this.bMultiField(mocData[0].hazardsKey, 128)}
                          inline
                          // disabled={isReadOnly}
                          // disabled={(isSecurityAdminLogin&&(readOnly &&(mocData[0].statusKey === 6 || mocData[0].statusKey === 5 || mocData[0].statusKey != 0) && !(mocData[0].statusKey ===1 && mocData[0].createdBy === getUserADAccount(user.account.userName))))}
                          // disabled={!(mocData[0].mocKey < 0 ? true: (mocData[0].statusKey === 0 ?
                          //   isSecurityAdminLogin ? true : (mocData[0].createdBy === getUserADAccount(user.account.userName) ? true : false)
                          //    : (mocData[0].statusKey === 8 ? isSecurityAdminLogin : false)))}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 128, e)
                          }
                        />
                      </Col>
                      <Col md={14}>
                        <CustomInput
                          type="checkbox"
                          id="hazardsOther"
                          label="Other"
                          checked={this.bMultiField(mocData[0].hazardsKey, 256)}
                          inline
                          // className={(!validDataItem.hazardsKey ? "form-error" : "")}
                          invalid={!validDataItem.hazardsKey}
                          disabled={this.statusFieldReadOnly()}
                          onChange={(e) =>
                            this.onTextChange("hazardsKey", 256, e)
                          }
                        />
                      </Col>
                      <Col md={12}>
                        <Input
                          type="textarea"
                          name="hazardsOther"
                          rows={1}
                          id="mocJobs"
                          placeholder=""
                          className={
                            !validDataItem.hazardsOther ? "form-error" : ""
                          }
                          value={
                            !this.bMultiField(mocData[0].hazardsKey, 256)
                              ? ""
                              : mocData[0].hazardsOther
                          }
                          disabled={
                            this.statusFieldReadOnly() ||
                            !this.bMultiField(mocData[0].hazardsKey, 256)
                          }
                          onChange={(e) =>
                            this.onTextChange("hazardsOther", e.target.value, e)
                          }
                        />

                        {!validDataItem.hazardsKey && (
                          <div className="text-danger form-control-feedback">
                            Please select at least one option
                          </div>
                        )}
                        {!validDataItem.hazardsOther && (
                          <div className="text-danger form-control-feedback">
                            Please enter a description
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="mocTask" hidden={mocData[0].statusKey === 0}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <strong>Task</strong>
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <MOCTask
                  user={this.props.user}
                  mocKey={mocKey}
                  isMOCApprover={isMOCApprover}
                  mocCommentStatusList={mocCommentStatusList}
                  mocUserList={mocUserList}
                  mocStatusKey={statusKey}
                  mocData={mocData}
                  toggle={this.closeForm}
                  refreshData={this.refreshMOCData}
                  refreshDataMOCKey={this.getMOC}
                  handleShowAlert={this.props.handleShowAlert}
                  // readOnly={readOnly}
                  // fiixWOId={fiixWOId}
                ></MOCTask>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem
              uuid="mocApproval"
              hidden={mocData[0].statusKey === 0}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <strong>Approval</strong>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <MOCApproval
                  mocKey={mocKey}
                  user={this.props.user}
                  empList={empList}
                  statusKey={mocData[0].statusKey}
                  mocData={mocData}
                  isSecurityAdminLogin={isSecurityAdminLogin}
                  // isAFEManager={isAFEManager}
                  // isAFEAdmin={isAFEAdmin}
                  // isFormReadOnly={isFormReadOnly}
                  // key={Math.random()} //{afeData[0].afeHeaderKey}//
                  // afeStatusKey={statusKey}
                  // closeoutStatusKey={closeoutStatusKey}
                  waitingOnApprovers={mocData[0].approvers}
                ></MOCApproval>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="mocDocuments">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <strong>Documents</strong>
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <Row>
                  <Col md={12}>
                    <DocumentBrowserDragDrop
                      buttonLabel="View Image"
                      buttonColor="primary"
                      formName="MOC Images"
                      linkTableName={"topMOC"}
                      linkPrimaryKey={mocKey < 0 ? offLineLinkKey : mocKey}
                      apiURL={
                        apiURL +
                        "MOC/Documents/" +
                        (mocKey < 0 ? offLineLinkKey : mocKey)
                      }
                      // woData = {woData}
                    />
                  </Col>
                </Row>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid="mocComments">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <strong>Comments</strong>
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <MOCComments
                  user={this.props.user}
                  mocKey={mocKey}
                  offLineLinkKey={offLineLinkKey}
                  // readOnly={readOnly}
                  // fiixWOId={fiixWOId}
                ></MOCComments>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>



          <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
          </Modal>
        </div>
      );
  }
}
