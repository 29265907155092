import React, { useState, useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from './apiURL';

// Root Component to demonstrate usage of wrapper component
const PowerBIReportViewer = (props) => {


    const [isLoaded, setIsLoaded] = useState(false)
    const [rtnEmbedReportObj, setRtnEmbedReportObj] = useState(null);

    const [report, setReport] = useState(null);        
    const [reportParameters, setReportParameters] = useState(props.reportParameters)


    const getEmbReportInfo = (reportName) => {
        let myURI = apiURL + 'Documents/EmbeddedReportInfo/' + reportName
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //props.reportParameters
                console.log(json)
                json.embedURL = json.embedURL + "?&rdl:parameterPanel=collapsed" 
                props.reportParameters.map(e => {
                    json.embedURL  = json.embedURL + '&rp:' + e.name + "=" + e.value 
                })

                console.log("json.embedURL")
                console.log(json.embedURL)
                setReport(json)
                setIsLoaded(true)
                //return json
            });

    }

    // PowerBI Report object (to be received via callback)


    //?&rdl:parameterPanel=collapsed"

    // Report config useState hook
    // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below


    const reportConfig = (rptInfo) => {

        
        const rptConfig = {
            type: 'report',
            tokenType: models.TokenType.Aad,
            id: rptInfo.reportid,
            embedUrl: rptInfo.embedURL,
            accessToken: rptInfo.access_token,
            //parameterValues: [rptInfo.parameters[0]],
            // filterPaneEnabled: false,
            // navContentPaneEnabled: false,
            // ,settings: {
            //     layoutType: models.LayoutType.Custom,
            //     customLayout: {
            //         displayOption: models.DisplayOption.FitToPage,
            //     },
            //     bars: {
            //         actionBar: {
            //            visible: true
            //         }
            //      }
            // },
            // parameterValues: [
            //     { name: "InvoiceKey", value: 2 }
            // ]
        }

        console.log("rptConfig")
        console.log(rptConfig)
        return rptConfig
    }


    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
        }],
        ['rendered', function () {
            console.log('Report has rendered');

            // Update display message
            setMessage('The report is rendered')
        }],
        ['error', function (event) {
            if (event) {
                console.error(event.detail);
            }
        }]
    ]);




    const getActivePage = async (powerbiReport) => {
        const pages = await powerbiReport.getPages()

        // Get the active page
        const activePage = pages.filter(function (page) {
            return page.isActive
        })[0];

        return activePage;
    }

    const [displayMessage, setMessage] = useState(`The report is bootstrapped. Click the Embed Report button to set the access token`);

    // const controlButtons =
    //     <div className="controls">
    //         {/* <button onClick={mockSignIn}>
    //             Embed Report</button> */}

    //         {/* <button onClick={changeSettings}>
    //             Hide filter pane</button> */}

    //         <button onClick={deleteVisual}>
    //             Delete a Visual</button>
    //     </div>;

    const header =
        <div className="header">
            <div className="title">Power BI React component</div>
        </div>;

    const footer =
        <div className="footer">
            <div className="footer-text">
                GitHub: &nbsp;
                <a href="https://github.com/microsoft/PowerBI-client-react">https://github.com/microsoft/PowerBI-client-react</a>
            </div>
        </div>;

    useEffect(() => {
        console.log("props.reportParameters")
        console.log(props.reportParameters)
        getEmbReportInfo(props.reportName);
    }, [])



    if (report === null) {
        return (<div>Loading report data....</div>)
    }

    // console.log("report")
    // console.log(report)

    return (

        <div>
            {/* {header} */}
            <PowerBIEmbed
                embedConfig={reportConfig(report)}
                eventHandlers={eventHandlersMap}
                cssClassName={"report-style-class"}
                getEmbeddedComponent={(embedObject) => {
                console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                setRtnEmbedReportObj(embedObject);
            }}                
            />

            {/* <div className = "hr"></div>

			<div className = "displayMessage">
				{ displayMessage }
			</div>

			{ controlButtons }

			{ footer } */}
        </div>
    );
}

export default PowerBIReportViewer;