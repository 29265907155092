import React, { Component } from 'react';
import Select from 'react-select';

import {
    Form, Col, Row, Button, FormGroup, Label, Input, CustomInput, Card, CardTitle, CardBody,
    FormFeedback, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';


import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';

import TrashBin from '../resources/trash.svg'
import '../custom.css';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from '../operation/ConfirmModal'
import { ColoredLine } from '../resources/utility';

export default class InventoryLocDetails extends Component {
    static displayName = InventoryLocDetails.name;

    constructor(props) {
        super(props);
        this.state = {
            locationLink:false,
            msgBody:"",
            modalMsg:false,
            msgHeader:"",
            isLoaded: false,
            isAFELoaded:false,
            isSiteLoaded:false,
            invLocDetails: [],
            isWHLoaded: false,
            WHList: [],
            invLocGrp: [],
            afeList:[],
            afeListFilter:[],
            afeListDefault:[],
            siteList:[],
            locationKey:props.locationKey,
            setStateOfParent:props.setStateOfParent,
            duplicateBool: props.duplicateBool,
            invLocationKey: props.invLocationKey,
            // validateDataItem: {
            //     warehouseKey: true,
            //     invLocationID: true,
            //     invLocationDesc: true
            // }
            invalidDataItem:{
                warehouseKey: true,
                invLocationDesc: true
            }
        }

    }

    validateData = ()=>{
        const {invalidDataItem, invLocDetails} = this.state
        var bVal = true 
        var valItem = invalidDataItem
        
        if (invLocDetails[0].warehouseKey == null){
            bVal = false
            valItem.warhouseKey= false
        }

        if (invLocDetails[0].invLocationDesc == "" || invLocDetails[0].invLocationDesc == null){
            bVal = false
            valItem.invLocationDesc= false
        }
        
       

        this.setState({invalidDataItem:valItem})
        
        return bVal
    }

    closeForm = (refreshData) => {
        if (refreshData) {
            this.props.refreshData();
            this.props.setStateOfParent(false)
        }

        this.props.toggle()
        this.props.setStateOfParent(false)
    }

    getWHList = () => {
        let myURI = apiURL + 'SupplyChain/WH'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isWHLoaded: true,
                    WHList: this.createWHList(json)
                })
            });
    }

    createWHList = (value) => {
        var tempList = []
        value.map(item => {               
            item.value=item.warehouseKey
            item.label=item.locationWarehouse
            tempList.push(item)         
        })
        // console.log("tempList")
        // console.log(tempList)
        return tempList;
    }

    saveData = () => {
        const { invLocationKey , duplicateBool} = this.state


    if(this.validateData() ){   
        if (duplicateBool){
            this.duplicateItem()
        }
        else if (invLocationKey >= 0) {
            this.updateInvLoc()
            console.log("This hit")
        } else {
            this.addInvLoc()
        }
    }
        // this.closeForm()
    }

    duplicateItem = () => {
        const createItemDuplicateDTO = this.createInvLocCreateDuplicateDTO()

        let myURI = apiURL + 'SupplyChain/InvLoc'
        fetch(myURI, apiPOSTHeader(createItemDuplicateDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    const error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    this.props.refreshData()
                    return Promise.reject(error)
                } else{
                    console.log("Does this hit222?")
                    this.props.refreshData()
                    this.closeForm()
                }
             
                    return response.json()
                    
            }).catch(error => {
                console.log("POST error: " + error)
            })
           this.props.setStateOfParent(false)
    }

    // setStateOfParent = (newTitle) => {
    //     this.setState({duplicateBool: newTitle}, ()=>{
    //     });
    //   } 

    createInvLocCreateDuplicateDTO = () => {
        const { invLocDetails } = this.state
        const { user } = this.props

        const invLocData = {
            "warehouseKey": invLocDetails[0].warehouseKey,
            "invLocGroupKey": invLocDetails[0].invLocGroupKey,
            "invLocationID": "XXXXXX",
            "invLocationDesc": invLocDetails[0].invLocationDesc,
            "physcialLoc": invLocDetails[0].physcialLoc,
            "controlledLoc": invLocDetails[0].controlledLoc,
            "locAisle": invLocDetails[0].locAisle,
            "locRow": invLocDetails[0].locRow,
            "bin": invLocDetails[0].bin,
            "latitude": invLocDetails[0].latitude,
            "longtitude": invLocDetails[0].longtitude,
            "createdBy": getUserADAccount(user.account.userName),
            "afe":invLocDetails[0].afe,
            "siteID":invLocDetails[0].siteID,
            "rack": invLocDetails[0].rack
        }
        console.log(invLocData)
        return invLocData
    }

    updateInvLoc = () => {
        const updateLocDTO = this.createInvLocUpdateDTO()
        // console.log("updateLocDTO")
        // console.log(updateLocDTO)

        let myURI = apiURL + 'SupplyChain/InvLoc'
        fetch(myURI, apiPUTHeader(updateLocDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                this.props.refreshData()
                this.closeForm()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    updateInvLocStatus = (StatusKey) => {
        const { invLocDetails } = this.state
        invLocDetails[0].statusKey = StatusKey
        this.setState({ invLocDetails: invLocDetails })
        this.updateInvLoc()
        this.closeForm()
    }


    createInvLocUpdateDTO = () => {
        const { invLocDetails } = this.state
        const { user } = this.props

        const invLocData = {
            "InvLocationKey": invLocDetails[0].invLocationKey,
            "warehouseKey": invLocDetails[0].warehouseKey,
            "statusKey": invLocDetails[0].statusKey,
            "invLocGroupKey": invLocDetails[0].invLocGroupKey,
            "invLocationID": invLocDetails[0].invLocationID,
            "invLocationDesc": invLocDetails[0].invLocationDesc,
            "physcialLoc": invLocDetails[0].physcialLoc,
            "controlledLoc": invLocDetails[0].controlledLoc,
            "locAisle": invLocDetails[0].locAisle,
            "locRow": invLocDetails[0].locRow,
            "bin": invLocDetails[0].bin,
            "latitude": invLocDetails[0].latitude,
            "longtitude": invLocDetails[0].longtitude,
            "modifiedBy": getUserADAccount(user.account.userName),
            "afe":invLocDetails[0].afe,
            "siteID":invLocDetails[0].siteID,
            "rack": invLocDetails[0].rack
        }
        console.log(invLocData)
        return invLocData
    }

    addInvLoc = () => {
        const updateLocDTO = this.createInvLocCreateDTO()
        let myURI = apiURL + 'SupplyChain/InvLoc'
        fetch(myURI, apiPOSTHeader(updateLocDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

                console.log("No error here: " + rspData)
                this.props.refreshData()
                this.closeForm()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    createInvLocCreateDTO = () => {
        const { invLocDetails } = this.state
        const { user } = this.props

        const invLocData = {
            "warehouseKey": invLocDetails[0].warehouseKey,
            "invLocGroupKey": invLocDetails[0].invLocGroupKey,
            "invLocationID": invLocDetails[0].invLocationID,
            "invLocationDesc": invLocDetails[0].invLocationDesc,
            "physcialLoc": invLocDetails[0].physcialLoc,
            "controlledLoc": invLocDetails[0].controlledLoc,
            "locAisle": invLocDetails[0].locAisle,
            "locRow": invLocDetails[0].locRow,
            "bin": invLocDetails[0].bin,
            "latitude": invLocDetails[0].latitude,
            "longtitude": invLocDetails[0].longtitude,
            "createdBy": getUserADAccount(user.account.userName),
            "afe":invLocDetails[0].afe,
            "siteID":invLocDetails[0].siteID,
            "rack": invLocDetails[0].rack
        }
        console.log(invLocData)
        return invLocData
    }

    getInvLocGroup = () => {

        let myURI = apiURL + 'SupplyChain/InvLocGrp'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    invLocGrp: this.createInvLocGroupList(json)
                })
            });

    }

    createInvLocGroupList = (value) =>{
        var tempList=[]
        value.map(e=> {
            e.value = e.invLocGroupKey
            e.label = e.invGroupID
            tempList.push(e)
        })

        return tempList
    }

    getInvLocDetail = () => {
        const { invLocationKey } = this.state
        if (invLocationKey >= 0) {
            let myURI = apiURL + 'SupplyChain/InvLoc/' + invLocationKey

            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    this.setState({
                        isLoaded: true,
                        invLocDetails: json
                    })
                });
        } else {
            const InvLocDetails = this.createNewInvLoc()
            this.setState({
                invLocDetails: InvLocDetails,
                isLoaded: true
            })
        }

    }

    getSelectedSite = (siteID)=>{
        const {siteList}=this.state    
        return siteList.filter(e => e.siteID === siteID)
    }

    
    getSelectedAFE = (afeID)=>{
        const {afeList, invLocationKey}=this.state
        // var test = afeList.filter(e => e.project_ID === afeID)    
     
        return afeList.filter(e => e.project_ID === afeID)
       

        
    }

 
    createNewInvLoc = () => {
        const { user } = this.props

        const invLocData = {
            "invLocationID": "XXXXXX",
            "invLocationDesc": "",
            "statusDesc": "Active",
            "physcialLoc": 1,
            "controlledLoc":1,
            "locAisle": "",
            "locRow": "",
            "bin": "",
            "afe":"",
            "siteID":"",
            "latitude": null,
            "longtitude": null,
            "createdDateTime": new Date().toISOString(),
            "createdBy": user.account.name,
            "addressName": "",
            "addressLine": "",
            "city": "",
            "stateID": "",
            "zipCode": "",
            "contactName": "",
            "contactEmail": "",
            "contactPhone": "",
            "invLocGroupKey": "",
            "warehouseKey": -1,
            "statusKey": 1,
            "rack": ""
        }
        return [invLocData]
    }
     getSelWarehouseKey = (value) => {
        return value.warehouseKey
    }

    onTextChange = (fieldName, fieldValue, e) => {
        // console.log("props")
        const { invLocDetails, validateDataItem, afeListFilter, afeListDefault, afeList, invalidDataItem} = this.state;

        switch (fieldName) {
            case "warehouseKey":
               
                    // invLocDetails[0].warehouseKey = e.warehouseKey
                  


                    

                    if( e !== null){


                                var afeListDefault2 = afeListFilter.filter(a=> a.locationKey == e.locationKey)
                         
                                this.setState({afeList: afeListDefault2},()=>{
                                  
                               
                                })

                                

                           
                            this.setState({locationLink: false})
                        invLocDetails[0].warehouseKey = (e===null ? "" : this.getSelWarehouseKey(e))
                        // validateDataItem.warehouseKey = true
                        invalidDataItem.warehouseKey = (fieldValue != null)
                    }
                    else{
                        invLocDetails[0].warehouseKey = null
                        
                        this.setState({locationLink: true})
                        invLocDetails[0].afe = null
                        invalidDataItem.warehouseKey = (fieldValue != null)
                    }

              
                
                break;
            case "invLocationID":
                invLocDetails[0].invLocationID = fieldValue
                // validateDataItem.invLocationID = true
                break;
            case "invLocationDesc":
                invLocDetails[0].invLocationDesc = fieldValue
                invalidDataItem.invLocationDesc =  (fieldValue != "" || fieldValue != null )
                // validateDataItem.warehouseName = true
                break;
            case "physcialLoc":
                invLocDetails[0].physcialLoc = (fieldValue ? 1 : 0)

                break;
                
            case "controlledLoc":
                    invLocDetails[0].controlledLoc = (fieldValue ? 1 : 0)
                break;
            case "locAisle":
                invLocDetails[0].locAisle = fieldValue

                break;
            case "locRow":
                invLocDetails[0].locRow = fieldValue

                break;
            case "bin":
                invLocDetails[0].bin = fieldValue
                break;
                case "rack":
                    invLocDetails[0].rack = fieldValue
                    break;
            case "invLocGroupKey":

                invLocDetails[0].invLocGroupKey = (e===null ? "" : this.getInvLocGroupKeyString(e))              
                break;
            case "siteID":
           
                invLocDetails[0].siteID = (e===null ? "" : e.siteID)
                break;
            case "afe":
                
                invLocDetails[0].afe = (e===null ? "" : e.project_ID)
                // console.log(e.value)
                // invLocDetails[0].invLocationID = this.getSelectedAFE()
                break;
            default:

        }

        this.setState({ invLocDetails: invLocDetails, invalidDataItem: invalidDataItem})
    }

    getSelInvLocGroup = (value) =>{
        const {invLocGrp} = this.state

        if (value!==null && value !== ""){ 
        return invLocGrp.filter(e => value.includes(e.invLocGroupKey))
        }
        return ""
    }

    getInvLocGroupKeyString =(value)=>{
        var grpKey = ''
        value.map(e =>{
            if (grpKey !== "") {
                grpKey = grpKey + ','
            } 

            grpKey = grpKey + e.invLocGroupKey
        })

        return grpKey;
    }

    getSite = () => {

        let myURI = apiURL + 'SupplyChain/Site'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isSiteLoaded:true,
                    siteList: this.createSiteList(json)
                })
            });

    }

    createSiteList = (value) =>{
        var tempList=[]
        value.map(e=> {
            e.value = e.siteID
            e.label = e.siteDesc
            tempList.push(e)
        })

        return tempList
    }

    getAFE = () => {
        const {locationKey} = this.state
        let myURI = apiURL + 'SupplyChain/AFE'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isAFELoaded:true,
                    afeListDefault: this.createAFEListDefault(json),
                    afeList: this.createAFEList(json.filter(e => e.locationKey === locationKey)),
                    afeListFilter: this.createAFEListFilter(json)
                })
            });

    }

    createAFEList = (value) =>{
        var tempList=[]
        value.map(e=> {
            e.value = e.project_ID
            e.label = e.projectDesc
            tempList.push(e)
        })

        return tempList
    }

    
createAFEListDefault = (value) =>{
    var tempList=[]
    value.map(e=> {
        e.value = e.project_ID
        e.label = e.projectDesc
        tempList.push(e)
    })

    return tempList
}
    
    createAFEListFilter = (value) =>{
        var tempList=[]
        value.map(e=> {
            e.value = e.project_ID
            e.label = e.projectDesc
            tempList.push(e)
        })

        return tempList
    }


    componentDidMount() {
        this.getSite()
        this.getAFE()
        this.getInvLocGroup()
        this.getWHList()
        this.getInvLocDetail()
    }


    getSelWHItem = (whKey) => {
        const {WHList} = this.state
        return WHList.filter(e => e.warehouseKey === whKey);
    }
    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    render() {
        const { isLoaded, WHList, invLocDetails,isAFELoaded,isSiteLoaded,
            isWHLoaded, invLocationKey, invLocGrp,siteList,afeList, duplicateBool,
        msgHeader, modalMsg, msgBody, locationKey, locationLink, invalidDataItem} = this.state

        

        if (!isSiteLoaded) {
            return (<div>Loading Site list .....</div>)
        } else 
        if (!isAFELoaded) {
            return (<div>Loading AFE list .....</div>)
        } else if (!isWHLoaded) {
            return (<div>Loading Warehouse list .....</div>)
        } else if (!isLoaded) {
            return (<div>Loading Inventory Location Details .....</div>)
        } else
            return (<div>
                <Form>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="warehouse" > Warehouse & Yard </Label>


                                <Select options={WHList}
                                className={!invalidDataItem.warehouseKey ? "form-error" : ""} 
                                name='warehouse'
                                id='warehouse'
                                defaultValue={this.getSelWHItem(invLocDetails[0].warehouseKey)}
                                value={this.getSelWHItem(invLocDetails[0].warehouseKey)}
                                onChange={(e)=>this.onTextChange("warehouseKey", e, e)}
                                isClearable={true}></Select>
                            </FormGroup>
                        </Col>
                
                    </Row>

                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="invLocationID">ID</Label>
                                <Input style={{ margin: "-5px 0 0 0" }} type="text" name="invLocationID" id="invLocationID"
                                    placeholder="" 
                                    value={duplicateBool ? "XXXXXX" : invLocDetails[0].invLocationID}
                                    disabled
                                    onChange={(e) => this.onTextChange("invLocationID", e.target.value, e)}
                                />
                            </FormGroup>
                        </Col>
                      
                    </Row>


                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="invLocationDesc">Description</Label>
                                <Input style={{ margin: "-5px 0 0 0" }} type="text" name="invLocationDesc" id="invLocationID"
                                    placeholder="" value={invLocDetails[0].invLocationDesc}
                                    invalid={!invalidDataItem.invLocationDesc}
                                    onChange={(e) => this.onTextChange("invLocationDesc", e.target.value, e)}
                                />
                            </FormGroup>
                        </Col>

                    </Row>                   

                    <Row form style={{zIndex: 0, position: 'relative'}}>
                    <Col md={6}>
                            <FormGroup check inline>
                                <CustomInput   
                                    type="checkbox" name="physcialLoc" id="physcialLoc"
                                    placeholder=""
                                    checked={invLocDetails[0].physcialLoc !== 0}
                                    onChange={(e) => this.onTextChange("physcialLoc", e.target.checked, e)}
                                />
                                <Label check for="physcialLoc">Physical Location</Label>
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup check inline>                              
                                <CustomInput   
                                    type="checkbox" name="controlledLoc" id="controlledLoc"
                                    placeholder=""
                                    checked={invLocDetails[0].controlledLoc !== 0}
                                    onChange={(e) => this.onTextChange("controlledLoc", e.target.checked, e)}
                                />
                                <Label check for="controlledLoc">Controlled Location</Label>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="aisle">Aisle</Label>
                                <Input style={{ margin: "-5px 0 0 0" }} type="text" name="aisle" id="aisle" placeholder=""
                                    value={invLocDetails[0].locAisle}
                                    onChange={(e) => this.onTextChange("locAisle", e.target.value, e)}
                                />

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="aisle">Rack</Label>
                                <Input style={{ margin: "-5px 0 0 0" }} type="text" name="rack" id="rack" placeholder=""
                                    value={invLocDetails[0].rack}
                                    onChange={(e) => this.onTextChange("rack", e.target.value, e)}
                                />

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="locRow">Row</Label>
                                <Input style={{ margin: "-5px 0 0 0" }} type="text" name="locRow" id="locRow" placeholder=""
                                    value={invLocDetails[0].locRow}
                                    onChange={(e) => this.onTextChange("locRow", e.target.value, e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="bin">Bin</Label>
                                <Input style={{ margin: "-5px 0 0 0" }} type="text" name="bin" id="bin" placeholder=""
                                    value={invLocDetails[0].bin}
                                    onChange={(e) => this.onTextChange("bin", e.target.value, e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <ColoredLine color="grey" />      
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="invLocationDesc">Location Group</Label>

                                {/* <Multiselect style={{ margin: "-5px 0 0 0" }}
                                    options={invLocGrp} // Options to display in the dropdown
                                    selectedValues={selectedInvGroupKey} // Preselected value to persist in dropdown
                                    onSelect={this.onSelect} // Function will trigger on select event
                                    onRemove={this.onRemove} // Function will trigger on remove event
                                    displayValue="invGroupID" // Property name to display in the dropdown options
                                    avoidHighlightFirstOption={true}
                                /> */}

                                
                                <Select options={invLocGrp} 
                                isMulti
                                value = {this.getSelInvLocGroup(invLocDetails[0].invLocGroupKey)}
                                defaultValue ={this.getSelInvLocGroup(invLocDetails[0].invLocGroupKey)}
                                onChange={(e)=>this.onTextChange("invLocGroupKey", e, e)}
                                isClearable={true}></Select>

                            </FormGroup>
                        </Col>

                    </Row>

                    {/* <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="wdSite" > Site </Label>

                                <Select options={siteList} 
                                onChange={(e)=>this.onTextChange("siteID", e, e)}
                                defaultValue={this.getSelectedSite(invLocDetails[0].siteID)}
                                value = {this.getSelectedSite(invLocDetails[0].siteID)}
                                isClearable={true}></Select>

                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row form>
                        <Col md={12}>

                            <FormGroup>
                                <Label for="locAFE" > AFE/OPEX </Label>
                                
                                <Select
                                // class={console.log(test)}
                                 options={afeList} 
                                 isDisabled={locationLink}
                                onChange={(e)=>this.onTextChange("afe", e, e)}
                                defaultValue={this.getSelectedAFE(invLocDetails[0].afe)}
                                value = {this.getSelectedAFE(invLocDetails[0].afe)}
                                isClearable={true}></Select>

                            </FormGroup>

                        </Col>

                    </Row>
                    <ColoredLine color="grey" />      
                    <FormGroup>
                        <Row style={{ margin: "15px 0 0 0" }}>
                            <Col sm={12}>
                                <Button color="warning" className="float-right"
                                    onClick={() => this.closeForm(false)}>Close</Button>


                                <Button color="primary" className="float-right"
                                    style={{ margin: "0px 10px 0px 0px" }}
                                    // hidden={this.setIsReadOnly(3)}
                                    onClick={() => this.saveData()}

                                >{duplicateBool ? "Duplicate" : "Save"}
                                </Button>


                                <ConfirmModal outline={true}
                                    color={(invLocDetails[0].statusKey != 0 ? "danger" : "primary")}
                                    className="float-left"
                                    buttonLabel={(invLocDetails[0].statusKey != 0 ? "Deactivate" : "Activate")}
                                 
                                    //hidden ={(!((statusKey==0 && safetyObservationKey > 0) || ((isQHSEAdmin|| isQHSEApprover) && statusKey!=6 &&  statusKey!=4 && safetyObservationKey > 0))) }
                                    hidden={(invLocationKey < 0 || duplicateBool )}
                                    formName="Confirm"
                                    id={invLocationKey}
                                    message={"Are you sure you want to " + (invLocDetails[0].statusKey !== 0 ? "deactivate" : "activate") + " this inventory location?"}
                                    onClickYes={(e) => this.updateInvLocStatus((invLocDetails[0].statusKey !== 0 ? 0 : 1))}
                                    image={<img
                                        src={TrashBin}
                                        id={invLocationKey}
                                        alt="Edit"
                                        style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                </ConfirmModal>


                            </Col>


                        </Row>
                        <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader} 
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>



                    </FormGroup>
            
                


                </Form>
              
               
            </div>)

    }
}