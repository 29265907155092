import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'

import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, USDCurrencyFormat } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';



import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';
import { FilterFactory, MarketFacilitationIndexIndicatorDescriptionModule, isNaN_ } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import FileManager from '../../resources/FileManager';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ConsolidatedItemsPosition } from 'igniteui-react-charts';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
var DatePicker = require("reactstrap-date-picker");

export default class AssetMetaLog extends Component {
    static displayName = AssetMetaLog.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.state = {
            assetkey: props.assetkey,
            selAssetMeta: [],
            isLoaded: false,
            asset_metas: [],
            asset_metas_filter: [],
            isFormReadOnly: true,
            modal: false,
            asset_type_id: props.asset_type_id,
            companyID: props.companyID,
            assetConfig: [],
            assetConfigMetas: [],
            searchText: '',
            selConfigId: [],
            selConfigMeta: [],
            asset_meta_value: "",
            assetDetais: [],
            asset_config_meta_key: -1,
            asset_meta_change_log_key: -1

        }

    }
   

    onGridRowSelected(s, e) {
        const { assetConfigMetas } = this.state
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]

            console.log("dataItem")
            console.log(dataItem)

            this.setState({
                selAssetMeta: dataItem,
                asset_config_meta_key: dataItem.asset_config_meta_key,
                asset_meta_change_log_key: dataItem.asset_meta_change_log_key

            }, () => {
                //this.loadAssetConfig(dataItem.asset_type_id)
            })
        }
    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.asset_metas_filter;
    }

   

    editAssetMeta = () => {
        const { selAssetMeta, assetConfigMetas, assetConfig } = this.state
        const tempFilter = assetConfig.filter(e => e.value === selAssetMeta.asset_config_key)
        const tempFilterMeta = assetConfigMetas.filter(e => e.asset_config_meta_key === selAssetMeta.asset_config_meta_key)

        this.setState({
            asset_meta_value: selAssetMeta.meta_text,
            selConfigId: (tempFilter.length > 0 ? tempFilter[0] : []),
            selConfigMeta: (tempFilterMeta.length > 0 ? tempFilterMeta[0] : [])
        })
        this.toggleModal()
    }

    getAssetLogs = () => {
        const { assetkey } = this.state

        let myURI = apiURL + 'Asset/AssetLog/ByAssetKey/' + assetkey
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    asset_meta: json,
                    asset_metas_filter: json,
                    isLoaded: true,
                })
            });
    }

    
    onTextChange = (fieldName, fieldValue, e) => {
        var { searchText,selAssetMeta } = this.state
        console.log(fieldValue)


        switch (fieldName) {
            case "searchBox":
                let searchText = (fieldValue || "").toLowerCase()
                if (this.grid.activeCell !== null) {
                    this.grid.activeCell = null
                }

                this.grid.filterExpressions.clear()
                if (searchText === "") {
                    return
                }

                this.filterFactory = new FilterFactory()
                const col_meta_text = this.filterFactory.property("filter_tag").toLower();
                let filter_meta_text = col_meta_text.contains(searchText)
                this.grid.filterExpressions.add(filter_meta_text);

                this.setState({ searchText: searchText })

                break;
            case "comments":
                selAssetMeta.comments = fieldValue
                this.setState({ selAssetMeta: selAssetMeta })
                break;
         

        }

        this.setState({ searchText: searchText })
    }

  
    createUpdateAssetDTO = () => {
        const { assetkey, selAssetMeta } = this.state
        const updateDTO = {
            "asset_meta_change_log_key": selAssetMeta.asset_meta_change_log_key,
            "update_by": getUserADAccount(this.props.user.account.userName),
            "comments": selAssetMeta.comments
        }

        return updateDTO
    }

    updateAssetMeta = () => {
        //let myURI = apiURL + 'Asset/MetaAdd'      
        let myURI = apiURL + 'Asset/AssetLogUpdate'

        const u_meta_datas = this.createUpdateAssetDTO()

        console.log("assetDetails[0]")
        console.log(u_meta_datas)


        fetch(myURI, apiPOSTHeader(u_meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error)
                } else{
                    this.getAssetLogs()
                    this.toggleModal();
                }

            }).catch(error => {
                // setMsgBody("PUT error: " + error)
                // setMsgHeader("Error")
                // setModalMsg(!modalMsg)
            })
    }

  

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,

        })
    }

    componentDidMount() {
    
        this.getAssetLogs()
        // this.getAssetConfig()
    }


  
    render() {
        const { asset_meta, asset_metas_filter, asset_meta_change_log_key, asset_type_id, assetkey,
            isLoaded, isFormReadOnly, selAssetMeta, selConfigMeta } = this.state

        console.log("this.state.selConfigId")
        console.log(this.state.selConfigId)

        if (!isLoaded) {
            return (<div>Loadding Asset Metas.....</div>)
        }
        return (<div>
            <Row form style={{ marginTop: "-10px", marginBottom: "10px" }}>
                <Col sm={6}>
                    {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}
                    <Button color="primary" outline className="float-left"
                        // disabled={isFormReadOnly}
                        // hidden={isFormReadOnly}
                        style={{ height: "40px", marginRight: "5px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.getAssetLogs()}
                    >Refresh</Button>

                    <Button color="primary" outline className="float-left"
                        // disabled={isFormReadOnly}
                        hidden={true}
                        style={{ height: "40px", marginRight: "5px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.addAssetMeta()}
                    >Add</Button>
                    <Button color="primary" outline className="float-left"
                        disabled={asset_meta_change_log_key < 0}
                        // hidden={isFormReadOnly}
                        style={{ height: "40px" }}
                        //hidden={this.setIsReadOnly(statusKey)}
                        onClick={() => this.editAssetMeta()}
                    >Edit</Button>
                </Col>

                {/* </Row>
            <Row form style={{ marginTop: "5px", marginBottom: "2px" }}> */}
                <Col sm={6}>
                    <Input type="text" placeholder='Type here to search .....'
                        autoFocus
                        onChange={(e) => this.onTextChange("searchBox", e.target.value, e)}
                        className="float-left"
                    />
                </Col>
            </Row>

            <ColoredLine color="grey" />

            <Row form style={{ zIndex: 0, position: 'relative' }}>
                {/* <FormGroup> */}

                <IgrDataGrid
                    ref={this.onGridRef}
                    height="400px"
                    width='100%'
                    //autoFocus={true}
                    dataSource={asset_metas_filter}
                    isColumnOptionsEnabled="true"
                    autoGenerateColumns="false"
                    //headerClickAction="none"
                    selectionMode={"SingleRow"}
                    editModeClickAction="DoubleClick"
                    editMode={(isFormReadOnly ? 0 : 1)}
                    editOnKeyPress={"false"}
                    selectedKeysChanged={this.onGridRowSelected}
                    cellValueChanging={this.onCellValueChanging}
                >

                    <IgrTextColumn //IgrTemplateColumn
                        field="action_type"
                        headerText="Action"
                        width="*>100"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="action_by"
                        headerText="By"
                        width="*>150"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="action_at"
                        headerText="At"
                        width="*>150"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="field_name"
                        headerText="Field Name"
                        width="*>200"
                        cellUpdating={this.onCellUpdating}
                    />

                    <IgrTextColumn //IgrTemplateColumn
                        field="field_value"
                        headerText="Value"
                        width="*>250"
                        cellUpdating={this.onCellUpdating}
                    />


                    <IgrTextColumn //IgrTemplateColumn
                        field="comments"
                        headerText="Comment"
                        width="*>400"
                        cellUpdating={this.onCellUpdating}
                    />



                    {/* <IgrTextColumn field="codeDefinitionKey" headerText="Item Number"
                                                        /> */}

                    <IgrNumericColumn field="assetkey" isHidden />
                    <IgrTextColumn field="asset_config_meta_key" isHidden />
                    <IgrNumericColumn field="asset_meta_change_log_key" isHidden />

                </IgrDataGrid>

                {/* </FormGroup> */}
            </Row>

            <div>
                <Modal isOpen={this.state.modal}
                    className="alway-on-top"
                    toggle={() => this.toggleModal()}
                    size="lg"
                    //onClosed={() => this.getRiser()}
                    backdrop={"static"}
                >
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Changed Log Details
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <FormGroup>
                                <Row form style={{ marginBottom: "10px" }}>
                                    <Col md="2">
                                        <Label >Action </Label>
                                    </Col>
                                    <Col md="4">
                                        <Input value={selAssetMeta.action_type}
                                            disabled={true}>
                                        </Input>
                                    </Col>
                                    <Col md="2">
                                        <Label >Action By </Label>
                                    </Col>
                                    <Col md="4">
                                        <Input value={selAssetMeta.action_by}
                                            disabled={true}>
                                        </Input>
                                    </Col>
                                </Row>

                                <Row form style={{ marginBottom: "10px" }}>
                                    <Col md="2">
                                        <Label >At </Label>
                                    </Col>
                                    <Col md="4">
                                        <Input value={selAssetMeta.action_at}
                                            disabled={true}>
                                        </Input>
                                    </Col>
                                    <Col md="2">
                                        <Label >Field Name </Label>
                                    </Col>
                                    <Col md="4">
                                        <Input value={selAssetMeta.field_name}
                                            disabled={true}>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row form style={{ marginBottom: "10px" }}>
                                    <Col md="2">
                                        <Label >Comment </Label>
                                    </Col>
                                    <Col md="10">
                                        <Input type='textarea'
                                               value={selAssetMeta.comments + ''}
                                               onChange={(e) => this.onTextChange("comments", e.target.value, e)}>
                                        </Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <FileManager
                                    controlid={asset_type_id + assetkey
                                        + selAssetMeta.asset_config_meta_key
                                        + selAssetMeta.asset_meta_change_log_key}
                                    folderPath={"tasset/ChangeLog/" + asset_type_id
                                        + '/' + assetkey + '/' + selAssetMeta.field_id
                                        + '/' + selAssetMeta.asset_meta_change_log_key + '/'
                                        //+ '/' + config_meta.meta_id
                                    }

                                //folderPath={"tasset/FAC_INSP_LI/INSPXXXX/fgr_3"}
                                >

                                </FileManager>
                            </FormGroup>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            outline
                            onClick={() => this.updateAssetMeta()}
                        >
                            OK
                        </Button>
                        {' '}
                        <Button
                            color="danger"
                            outline
                            onClick={() => this.toggleModal()}
                        >
                            Cancel
                        </Button>
                        {' '}

                    </ModalFooter>

                </Modal>
            </div>

        </div>)


    }
}