//Created By BN 11/1/2021



import React, { Component } from 'react';

import { Form, Col, Row, Button, TabContent, TabPane, NavItem, Nav, NavLink, FormGroup, Label, Input, CustomInput, ListGroupItem, ListInlineItem, InputGroup, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiGETHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import TrashBin from '../resources/trash.svg'
import { USDCurrencyFormat, NumberFormat } from '../resources/utility'
import '../custom.css';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import { getUserADAccount } from '../resources/utility'
import ConfirmModal from './ConfirmModal'
import ConfirmMeterValModal from './ConfirmMeterValModal'
import MeterValidationItem from './MeterValidationItem';
import DocumentBrowser from '../resources/DocumentBrowser';
import classnames from 'classnames';


import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';

import MeterValidationAction from './MeterValidationAction'
import MeterValidationActionPerformed from './MeterValidationActionPerformed'
import ReviewComment from './ReviewComment'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


export default class MeterValidationDetail extends Component {



    static displayName = MeterValidationDetail.name;

    constructor(props) {
        super(props)
        const urlPara = this.getURLParameters(props.location)
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state = {
            modalComment: false,
            childValue: '',
            actionType1: 1,
            actionType2: 2,
            data: [],
            isLoadedActionRequired: false,
            isLoadedActionPerformed: false,
            isAbleToValidate: false,
            security: [],
            performAction: [],
            requireAction: [],
            meterValAction: [],
            meterValidationAction: [],
            meterVal: props.meterValidation,
            isValTypeLoaded: false,
            isValActionLoaded: false,
            meterVal: [],
            meterValidation: null,
            meterValTypeDesc: [],
            validationDate: null,
            isSecurityLoaded: false,
            isMeterValLoaded: false,
            isReadOnly: false,
            meterValidationActionKey: -1,
            statusKey: 0,
            windowHeight: window.innerHeight,
            isDataLoaded: false,
            isSecurityAdminLogin: false,
            isSecurityMeasurementTech: false,
            isOtherUser: false,
            activeTab: '1',
            invalidDataItem: {
                reviewComment: true
            },
            // meterValidationKey:urlPara.meterValidationKey,
            meterValidationKey: (urlPara.meterValidationKey === null ? props.meterValidationKey : urlPara.meterValidationKey),
            isUrlPara: (urlPara.meterValidationKey === null ? true : false),
            hideAssociateDetails: ((typeof (props.HideAssociateDetails) === 'undefined' || props.HideAssociateDetails === null) ? false : props.HideAssociateDetails)
        }
    }

    //     getURLParameters = (inputPara) => {

    //         var paraMeters={
    //             meterValidationKey:null,
    //         security:null,
    // }
    //         var query
    //         if (inputPara !== undefined){
    //             query = new URLSearchParams(inputPara.search);

    //             paraMeters={
    //                 meterValidationKey:query.get('key') 
    //             }

    //         }

    //         return paraMeters

    //     }



    getURLParameters = (inputPara) => {
        var paraMeters = {
            startDate: null,
            endDate: null,
            facilityKeys: null,
            statusDesc: null,
            meterValidationKey: null,
            meterVal: null,
        }

        //     var query
        //     query = new URLSearchParams(inputPara)
        //     paraMeters={
        //         startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
        //         endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null) ,
        //         statusDesc: query.get('statusDesc'),
        //         meterValidationKey:query.get('key') 
        // }

        if (inputPara !== undefined) {
            var query

            query = new URLSearchParams(inputPara.search);


            paraMeters = {
                startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
                endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
                statusDesc: query.get('statusDesc'),
                meterValidationKey: query.get('key')
            }

        }
        // console.log("inputPara")
        // console.log(inputPara)

        // console.log( "paraMeters.meterValidationKey" )
        // console.log( paraMeters )
        // console.log("paraMeters")
        // console.log(paraMeters)

        return paraMeters

    }



    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }


    toggleComment = () => {
        this.setState({ modalComment: !this.state.modalComment })
    }


    toggleModalAction = () => {

        this.setState({ modalAction: !this.state.modalAction })
    }

    toggleModalActionPerformed = () => {

        this.setState({ modalActionPerformed: !this.state.modalActionPerformed })
    }

    toggleModalReviewComment = () => {

        this.setState({ modalReviewComment: !this.state.modalReviewComment })
    }




    addActionModal = () => {


        this.toggleModalAction()

    }

    addActionModalPerformed = () => {


        this.toggleModalActionPerformed()

    }

    addReviewCommentModal = () => {
        this.toggleModalReviewComment()
    }




    editComment = () => {


        this.toggleComment()

    }



    getType = () => {

        let myURI = apiURL + 'MeterValidation/Type'


        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    meterValTypeDesc: json
                })
            })

    }


    getMeterValAction = () => {
        const { meterValidationKey } = this.state
        let myURI = apiURL + 'MeterValidation/MeterValAction/' + meterValidationKey

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoadedActionRequired: true,
                    meterValAction: json
                })
            })

    }





    getPerformAction = () => {
        let myURI = apiURL + 'MeterValidation/PerformAction'
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoadedActionPerformed: true,
                    performAction: json
                })
            })

    }

    getRequireAction = () => {
        let myURI = apiURL + 'MeterValidation/Action'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    requireAction: json
                })
            })

    }




    getMeterValidation = () => {
        const { meterValidationKey } = this.state

        if (meterValidationKey > 0) {

            let myURI = apiURL + 'MeterValidation/' + meterValidationKey

            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    this.setState({
                        isValTypeLoaded: true,
                        meterVal: json
                    })

                    if (json.length > 0) {
                        this.setState({
                            isAbleToValidate: (json[0].ableToValidate === 0 ? false : true),
                            isSampleCollected: (json[0].sampleCollected === 0 ? false : true),
                            statusKey: json[0].statusKey,
                            isReadOnly: this.setIsReadOnly(json[0].statusKey)
                        })
                    }
                });

        }

    }


    getSecurity = () => {
        let myURI = apiURL + 'EmployeeDetails/Checkpoint/' + this.props.user.account.userName
        const { security, appSecurityNo } = this.state
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({

                    isSecurityLoaded: true,
                    security: json,

                    isSecurityAdminLogin: (json.filter((list) => list.appSecurityNo === 14).length > 0 ? false : true),
                    isSecurityMeasurementTech: (json.filter((list) => list.appSecurityNo === 15).length > 0 ? false : true),
                    isOtherUser: (json.filter((list) => list.appSecurityNo === 15 || list.appSecurityNo === 14).length == 0 ? true : false)
                })

            })

    }

    setIsReadOnly = (statusKey) => {

        return (statusKey == 6)
    }

    getLineDetails = () => {
        const { meterValidationKey } = this.state
        let myURI = apiURL + 'MeterValidation/Line/' + meterValidationKey


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    data: json
                    , isDataLoaded: true

                })
            });
    }







    updateMeterValidation = () => {
        const { requiredKey } = this.state
        const meterValidationUpdateDTO = this.createMeterValUpdateDTO()

        let myURI = apiURL + 'MeterValidation'

        fetch(myURI, apiPUTHeader(meterValidationUpdateDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {

                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }

            })
            .catch(error => {

            })

    }



    createMeterValUpdateDTO = () => {
        const { meterVal, security } = this.state
        const { meterValidationAction } = this.state
        const { user } = this.props
        const meterValUpdateDTO = {
            "meterValidationKey": meterVal[0].meterValidationKey,
            "validationType": meterVal[0].validationType,
            "validationDate": meterVal[0].validationDate,

            "statusKey": meterVal[0].statusKey,
            "latitude": meterVal[0].latitude,
            "longtitude": meterVal[0].longtitude,
            "comment": meterVal[0].comment,
            "modifiedBy": getUserADAccount(user.account.userName),
            "meterValidationResult": meterVal[0].meterValidationResult,
            "actionRequiredKey": meterVal[0].actionRequiredKey,
            "reviewComment": meterVal[0].reviewComment,
            "actionRequired2Key": meterVal[0].actionRequired2Key,
            "ableToValidate": meterVal[0].ableToValidate,
            "sampleCollected": meterVal[0].sampleCollected,

        }

        return meterValUpdateDTO

    }

    deleteData = () => {
        const meterValData = this.MeterValidationForDeleteDto()

        let myURI = apiURL + 'MeterValidation'

        fetch(myURI, apiDELETEHeader(meterValData))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }


            })
            .catch(error => {

            })

        this.closeForm(true)
    }

    MeterValidationForDeleteDto = () => {
        const { meterVal } = this.state
        const { user } = this.props

        const meterValForDeleteDTO = {
            "meterValidationKey": meterVal[0].meterValidationKey,
            "deletedBy": getUserADAccount(user.account.userName),
            "logLatitude": meterVal[0].logLatitude,
            "logLongtitude": meterVal[0].logLongtitude
        }
        return meterValForDeleteDTO
    }




    createMeterValAction() {


        const meterValActionCreateDTO = this.createMeterValActionCreateDTO()


        let myURI = apiURL + 'MeterValidation/MeterValAction'
        fetch(myURI, apiPOSTHeader(meterValActionCreateDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }
                this.refreshAction()
            })
            .catch(error => {

            })
    }





    createMeterValActionCreateDTO = () => {
        const { meterValidationAction, meterValidationKey } = this.state
        const { meterVal } = this.state
        const { user } = this.props
        const meterValActionCreateDTO = {
            "meterValidationKey": meterValidationKey,
            "actionType": meterValidationAction.actionType,
            "actionKey": meterValidationAction.actionKey,
            "comments": meterValidationAction.comments,
            "createdBy": getUserADAccount(user.account.userName)
        }
        return meterValActionCreateDTO
    }



    deleteMeterValAction = () => {

        const meterValActionDeleteDTO = this.createMeterValActionDeleteDTO()



        let myURI = apiURL + 'MeterValidation/MeterValAction'
        fetch(myURI, apiDELETEHeader(meterValActionDeleteDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {

                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }
                this.refreshAction()

            })
            .catch(error => {

            })
    }

    createMeterValActionDeleteDTO = () => {
        const { meterValidationAction, meterValidationActionKey } = this.state

        const meterValActionDeleteDTO = {
            "meterValidationActionKey": meterValidationActionKey,
            "deletedBy": ""

        }
        return meterValActionDeleteDTO
    }



    onGridRowSelected(s, e) {

        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]

            this.setState({
                meterValidationKey: dataItem.meterValidationKey,
                meterValidationActionKey: dataItem.meterValidationActionKey,
                meterValidationAction: dataItem
            })

        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.meterValAction;

    }


    refreshAction = () => {
        this.getMeterValAction()
    }




    componentDidMount() {

        this.getMeterValidation()
        this.getType()
        this.getLineDetails()
        this.getPerformAction()
        this.getRequireAction()
        this.getMeterValAction()
        this.refreshAction()
        this.getSecurity()


    }

    closeForm = (refreshData) => {
        const { isUrlPara } = this.state
        console.log("isUrlPara")
        console.log(isUrlPara)

        if (isUrlPara !== true) {
            console.log("Form was not closed due to Modal Key")
        }

        else if (isUrlPara !== false && refreshData) {
            this.props.toggle()
            this.props.refreshData(true)

        }
        else if (isUrlPara !== false) {
            this.props.toggle()
        }


    }

    //  closeFormTest = (refreshData) => {
    //     if(refreshData) {        
    //         this.props.refreshData()
    //     }

    // }



    saveData = () => {
        const { meterVal } = this.state

        if (meterVal[0].meterValidationKey > 0) {


            this.updateMeterValidation()
            this.saveDataDtl()

        }
        else {
            console.log("This form is saved")
        }
        this.closeForm(true);
    }

    approveData = () => {
        const { meterVal, security } = this.state
        const { user } = this.props


        //this.setState({ meterVal: meterVal })

        //Comment out by Hau Nguyen 03/18/2022
        //User do not have to enter comment when approve
        //if(this.validateData()){ 
        meterVal[0].statusKey = 6
        this.setState({ meterVal: meterVal })
        this.saveData()
        //this.props.toggle()
        //}
    }


    rejectData = () => {
        const { meterVal, security } = this.state
        const { user } = this.props
        if (this.validateData()) {
            this.setState({ meterVal: meterVal })
            meterVal[0].statusKey = 2
            this.saveData()
        }

        //

    }

    submitData = () => {
        const { meterVal, statusKey } = this.state
        const { user } = this.props

        if (statusKey == 2) {
            meterVal[0].reviewComment = ""
        }
        meterVal[0].statusKey = 4

        this.setState({ meterVal: meterVal })
        this.saveData()
    }


    validateData = () => {
        const { meterVal, invalidDataItem } = this.state
        var bVal = true
        var valItem = invalidDataItem

        if (meterVal[0].reviewComment == "") {
            bVal = false
            valItem.reviewComment = false
        }

        this.setState({ invalidDataItem: valItem })

        return bVal
    }


    onTextChange = (fieldName, fieldValue, e) => {

        const { meterVal, meterValidationAction, invalidDataItem } = this.state;

        var { actionType1, actionType2 } = this.state


        switch (fieldName) {
            case "meterValidationID":
                meterVal[0].meterValidationID = fieldValue

                break;
            case "statusKey":
                meterVal[0].statusKey = fieldValue
            case "createdBy":
                meterVal[0].createdBy = fieldValue
                break;
            case "validationType":
                meterVal[0].validationType = fieldValue
                break;
            case "ableToValidate":
                meterVal[0].ableToValidate = fieldValue
                this.setState({ isAbleToValidate: (fieldValue != 0) })
                break;
            case "sampleCollected":
                meterVal[0].sampleCollected = fieldValue
                this.setState({ isSampleCollected: (fieldValue != 0) })
                break;
            case "statusDesc":
                meterVal[0].statusDesc = fieldValue
                break;
            case "validationDate":
                meterVal[0].validationDate = fieldValue
                this.setState({ validationDate: fieldValue })
                break;
            case "qrCodeValue":
                meterVal[0].qrCodeValue = fieldValue
                break;
            case "meterName":
                meterVal[0].meterName = fieldValue
                break;
            case "comment":
                meterVal[0].comment = fieldValue
                break;
            case "reviewComment":
                meterVal[0].reviewComment = fieldValue
                invalidDataItem.reviewComment = (fieldValue != "")
                break;
            case "requiredKey":
                meterValidationAction.actionKey = fieldValue
                meterValidationAction.actionType = 1
                break;
            case "performedKey":
                meterValidationAction.actionKey = fieldValue
                meterValidationAction.actionType = 2
                break;
            case "meterValidationKey":
                meterVal[0].meterValidationKey = fieldValue
                break;

            default:

        }

        this.setState({
            meterVal: meterVal,
            invalidDataItem: invalidDataItem,
            meterValidationAction: meterValidationAction,
            actionType1: actionType1,
            actionType2: actionType2

        })
    }

    handleValidationDate = (v, f) => {
        this.setState({
            validationDate: v
        })
    }

    isAllowEdit = (meterVal) => {
        const { isOtherUser, isSecurityMeasurementTech } = this.state
        if (meterVal.statusKey === 6 || meterVal.statusKey === 5 || isOtherUser === true) {
            return false
        }

        else if (isSecurityMeasurementTech == false && meterVal.statusKey == 4) {
            return false
        }
        else { return true }

    }

    isAbleToValidate = () => {
        if (this.onTextChange("ableToValidate", 1)) {
            return false
        } else { return true }
    }




    createMetValLineItemForUpdateDTO = () => {
        const { data, inspComments, facInspComments, hdrData } = this.state;
        const { user } = this.props
        var tempDTO = [];
        var i = 0;
        data.map(function (line) {
            const uLine = {
                "meterValidationAddCheckItemKey": line.meterValidationAddCheckItemKey,
                "asFoundValue": line.asFoundValue,
                "asLeftValue": line.asLeftValue,
                "comment": line.comment,
                "modifiedBy": getUserADAccount(user.account.userName)

            }

            tempDTO[i] = uLine;
            i = i + 1;
        });

        return tempDTO;
    }


    saveDataDtl = () => {
        const itemDTO = this.createMetValLineItemForUpdateDTO();


        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        let myURI = apiURL + 'MeterValidation/Line'
        fetch(myURI, putReq)
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {

                    const error = (rspData && rspData.message) || response.status;
                    return Promise.reject(error);

                }


            })
            .catch(error => {

            })

    }

    intResultCal = () => {
        var { isLoaded, data } = this.state
        var finalValue
        var finalValueAsLeft
        var initialValue
        var initialValueAsLeft
        var meterTotal
        var meterTotalAsLeft
        var UTMeterTotal
        var variance
        var varianceAsLeft
        var UTMeterTotalAsLeft


        data.map(function (line) {
            if (line.addCheckTemplateID === 'MTRRSL2') {
                finalValue = line.asFoundValue
                finalValueAsLeft = line.asLeftValue

            }
        })


        data.map(function (line) {
            if (line.addCheckTemplateID === 'MTRRSL1') {
                initialValue = line.asFoundValue
                initialValueAsLeft = line.asLeftValue


            }
        })


        data.map(function (line) {
            if (line.addCheckTemplateID === 'MTRRSL3') {

                meterTotal = line.asFoundValue = finalValue - initialValue


                meterTotalAsLeft = line.asLeftValue = finalValueAsLeft - initialValueAsLeft


            }
        })




        data.map(function (line) {
            if (line.addCheckTemplateID === 'MTRRSL4') {
                UTMeterTotal = line.asFoundValue
                UTMeterTotalAsLeft = line.asLeftValue
            }
        })



        data.map(function (line) {
            if (line.addCheckTemplateID === 'MTRRSL5') {

                if (UTMeterTotal > 0) {
                    variance = line.asFoundValue = ((Math.abs((meterTotal - UTMeterTotal)) / UTMeterTotal))

                }
                else {
                    line.asFoundValue = 0.0
                }

                if (UTMeterTotalAsLeft > 0) {
                    varianceAsLeft = line.asLeftValue = ((Math.abs((meterTotalAsLeft - UTMeterTotalAsLeft)) / UTMeterTotalAsLeft))
                }
                else {
                    line.asLeftValue = 0.0
                }


            }
        })



        this.setState({ data: data })


    }





    onHandleMetValLineValueChange = (key, valueChange, value) => {

        this.setState({ childValue: value });

        var { isLoaded, data } = this.state

        if (isLoaded) {


            data.map(function (line) {


                if (line.meterValidationAddCheckItemKey === key) {


                    switch (valueChange) {

                        case "asFoundValue":
                            line.asFoundValue = value

                            return
                        case "asLeftValue":
                            line.asLeftValue = value;

                            return;

                        case "comment":
                            line.comment = value;

                            return;

                        default:
                            return

                    }

                    return;

                }
            })

            this.intResultCal()
            this.setState({ isDataLoaded: false })
            this.setState({ isDataLoaded: true })


        }
    }




    getMeterValidationHeaderColor = (meterValidation) => {


        if (meterValidation != null) {

            const statusKey = meterValidation.statusKey


            if (statusKey === 4) {
                return "modal-header approve"
            } else if (statusKey === 3) {
                return "modal-header reject"
            }
        } else { return "" }
    }


    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        link.textContent = new Date(item.createdDatetime).toLocaleDateString("es-PA");
    }


    render() {
        const { isValTypeLoaded, windowHeight, hideAssociateDetails,
            isValActionLoaded, meterValidationActionKey, activeTab,
            meterVal, security, isMeterValLoaded, isReadOnly, meterValDetails, meterValidation, meterValidationAction, meterValTypeDesc, action, meterValAction, performAction, requireAction, meterValidationKey, validationDate, statusKey, invalidDataItem } = this.state;
        var { isAbleToValidate, isSampleCollected, data, isDataLoaded, isSecurityLoaded, isOtherUser, isSecurityAdminLogin, isSecurityMeasurementTech, isLoaded, isLoadedActionRequired, isLoadedActionPerformed } = this.state;



        if (!isDataLoaded || !isSecurityLoaded || !isLoaded || !meterValAction || !isValTypeLoaded || !isLoadedActionRequired || !isLoadedActionPerformed) {
            return (<div>Data is loading...</div>)
        } else

            return (<div>

                <FormGroup>
                    <Row>
                        <Col sm={12}>
                            <Button color="warning" className="float-left"
                                style={{ marginRight: "10px" }}                                
                                onClick={() => this.closeForm(false)}>Close</Button>


                            <Button color="primary" className="float-left"
                                style={{ marginRight: "10px" }}
                                hidden={this.setIsReadOnly(statusKey) || (isOtherUser) || (isSecurityAdminLogin && statusKey == 4) || statusKey == 5}

                                onClick={() => this.saveData()}>Save</Button>

                            <ConfirmModal outline={false}
                                color="success"
                                className="float-left"
                                buttonLabel="Submit"
                                hidden={!(statusKey == 0 || statusKey == 1 || statusKey == 2) || (isOtherUser)}

                                formName="Confirm Meter Validation"
                                id={meterValidationKey}
                                message="Are you sure you want to submit selected meter validation"
                                onClickYes={(e) => this.submitData()}
                            />



                            <ConfirmMeterValModal outline={false}
                                color="success"
                                className="float-left"
                                buttonLabel="Approve"
                                hidden={(!(statusKey == 4)) || (isSecurityAdminLogin)}
                                invalid={false} //{!invalidDataItem.reviewComment} // Comment out by hau Nguyen 03/18/2022 requested by Staton user do not have have enter comment when approve
                                value={meterVal[0].reviewComment}
                                formName="Confirm"
                                id={meterValidationKey}
                                message="Are you sure you want to approve selected meter validation?"
                                meterValidation={meterValidation}
                                onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                                onClickYes={(e) => this.approveData()
                                }
                                style={{ marginRight: "10px" }}
                            //   onClickNo={(e) => this.closeFormTest(true)} 

                            />


                            <ConfirmMeterValModal outline={false}
                                color="danger"
                                className="float-right"
                                buttonLabel="Reject"
                                hidden={(!(statusKey == 4)) || (isSecurityAdminLogin)}
                                invalid={!invalidDataItem.reviewComment}
                                defaultValue={meterVal[0].reviewComment}
                                formName="Confirm"
                                id={meterValidationKey}
                                message="Are you sure you want to reject selected meter validation?"
                                meterValidation={meterValidation}
                                onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                                onClickYes={(e) => this.rejectData()} />


                            <ConfirmModal outline={true}
                                color="danger"
                                className="float-right"
                                buttonLabel="Delete"
                                hidden={(!((statusKey == 0 && meterValidationKey > 0) || (statusKey != 6 && statusKey != 4 && meterValidationKey > 0))) || (isSecurityAdminLogin) || statusKey == 2 || statusKey == 5}
                                formName="Confirm"
                                id={meterValidationKey}
                                message="Are you sure you want to delete selected meter validation?"
                                onClickYes={(e) => this.deleteData()}
                                image={<img
                                    src={TrashBin}
                                    id={meterValidationKey}
                                    alt="Edit"
                                    style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                            </ConfirmModal>


                        </Col>
                    </Row>

                </FormGroup>
                <ColoredLine color={'grey'}></ColoredLine>
                <Form height="50%">
                    <Form Group>
                        <Row Form>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="meterValidationID">Meter Validation No</Label>
                                    <Input type="text" name="meterValidationID"
                                        id="meterValidationID" disabled

                                        defaultValue={meterVal[0].meterValidationID}

                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4}>



                                <Label for="statusDesc">Status</Label>
                                <Row>

                                    <Col md={statusKey == 2 ? 10 : 12}>
                                        <Input disabled type="text" name="statusDesc" id="statusDesc"
                                            defaultValue={meterVal[0].statusDesc}
                                            onChange={(e) => this.onTextChange("statusDesc", e.target.value, e)}

                                        />
                                    </Col>

                                    <Col md={2}>
                                        <Button color="primary"
                                            hidden={!(statusKey == 2)}
                                            onClick={() => this.toggleModalReviewComment()}>!</Button>

                                    </Col>

                                </Row>

                            </Col>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="createdBy">Modified By</Label>
                                    <Input disabled type="text" name="modifiedByName" id="modifiedByName"

                                        defaultValue={meterVal[0].modifiedByName}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>
                    </Form>



                    <Form Group>
                        <Row Form>

                            <Col md={4}>
                                <FormGroup>
                                    <Label for="facilityName">Facility Name</Label>
                                    <Input type="text" name="facilityName" id="facilityName"
                                        defaultValue={meterVal[0].facilityName}
                                        // onChange={(e) => this.onTextChange("meterName",e.target.value,e)}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>



                            <Col md={4}>
                                <FormGroup>
                                    <Label for="meterName">Meter Name</Label>
                                    <Input type="text" name="meterName" id="createdBy"
                                        defaultValue={meterVal[0].meterName}
                                        onChange={(e) => this.onTextChange("meterName", e.target.value, e)}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>


                            <Col md={4}>
                                <FormGroup>
                                    <Label for="qrCodeValue">QR Code</Label>
                                    <Input type="text" name="qrCodeValue"
                                        id="qrCodeValue"
                                        disabled
                                        placeholder=""
                                        defaultValue={meterVal[0].qrCodeValue}
                                        value={meterVal[0].qrCodeValue}
                                        onChange={(e) => this.onTextChange("qrCodeValue", e.target.value, e)}
                                    />
                                </FormGroup>
                            </Col>





                        </Row>

                    </Form>



                    <Row form>
                        <Col md={6}>


                            <FormGroup>
                                <Label for="validationType">Type</Label>
                                <CustomInput type="select"
                                    id="validationType"
                                    name="validationType"
                                    defaultValue={meterVal[0].validationType}
                                    value={meterVal[0].validationType}
                                    placeholder=""
                                    onChange={(e) => this.onTextChange("validationType", e.target.value, e)}
                                    disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                >

                                    {meterValTypeDesc.map(v => <option value={v.meterValTypeKey}>{v.meterValTypeDesc}</option>)}
                                </CustomInput>

                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <Row form>
                                <Label for="validationDate">Date</Label>
                                <DatePicker id="validationDate"
                                    value={new Date(meterVal[0].validationDate).toISOString()}
                                    onChange={(v, f) => this.onTextChange("validationDate", v, f)}
                                    disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                />
                            </Row>
                        </Col>



                    </Row>

                    <Row form>
                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label>Able To Validate?</Label>
                                </Col>
                                <Col md={4}>
                                    <CustomInput type="checkbox"
                                        id="ableToValidateYes"
                                        label="Yes"
                                        checked={isAbleToValidate}
                                        inline
                                        disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                        onChange={(e) => this.onTextChange("ableToValidate", 1, e)}
                                    />
                                </Col>
                                <Col md={4}>
                                    <CustomInput type="checkbox"
                                        id="ableToValidateNo"
                                        label="No" inline
                                        checked={!isAbleToValidate}
                                        onChange={(e) => this.onTextChange("ableToValidate", 0, e)}
                                        disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                    />
                                </Col>

                            </Row>
                        </Col>

                        <Col md={6}>
                            <Row form>
                                <Col md={4}>
                                    <Label>Is Sample Collected?</Label>
                                </Col>
                                <Col md={4}>
                                    <CustomInput type="checkbox"
                                        id="sampleCollectedYes"
                                        label="Yes"
                                        checked={isSampleCollected}
                                        inline
                                        disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                        onChange={(e) => this.onTextChange("sampleCollected", 1, e)}
                                    />
                                </Col>


                                <Col md={4}>
                                    <CustomInput type="checkbox"
                                        id="sampleCollectedNo"
                                        label="No"
                                        checked={!isSampleCollected}
                                        inline
                                        onChange={(e) => this.onTextChange("sampleCollected", 0, e)}
                                        disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                    />
                                </Col>
                            </Row>

                        </Col>



                    </Row>


                    <Nav tabs>
                        <NavItem >
                            <NavLink className={classnames({ active: activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }} >
                                Comments
                            </NavLink>

                        </NavItem>

                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })}
                                onClick={() => { this.toggleTab('2'); }} >

                                Documents
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '3' })}
                                onClick={() => { this.toggleTab('3'); }} >
                                Actions
                            </NavLink>

                        </NavItem>

                    </Nav>


                    <TabContent activeTab={activeTab} >

                        <TabPane tabId="1" >
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input style={{ height: "100px", width: "1090px", margin: "20px 0 0 0" }}
                                            type="textarea"
                                            disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                            defaultValue={meterVal[0].comment}
                                            value={meterVal[0].comment}

                                            onChange={(e) => this.onTextChange("comment", e.target.value, e)}
                                            placeholder=""
                                        />
                                    </FormGroup>

                                </Col>
                            </Row>
                        </TabPane>



                        <TabPane tabId="2" >
                            <Row>
                                <Col md={6} >
                                    <DocumentBrowser
                                        style={{ margin: "20px 0 0 0" }}
                                        buttonLabel="View Image"
                                        buttonColor="primary"
                                        formName="Meter Validation Images"
                                        disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                                        linkTableName={"topMeterValidation"}
                                        linkPrimaryKey={(meterValidationKey)}
                                        apiURL={apiURL + 'MeterValidation/Documents/' + meterValidationKey}
                                    />

                                </Col>
                            </Row>
                        </TabPane>



                        <TabPane tabId="3">
                            <Row>

                                <Col md={12}>
                                    <FormGroup>

                                        {/* <Button color="primary"
                            disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)}
                             style={{margin:"20px 20px 10px 0px"}}
                             onClick={()=> this.refreshAction()}>Refresh</Button> */}

                                        <Button style={{ margin: "20px 20px 10px 0px" }} disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)} color="primary" onClick={() => { this.addActionModal() }} >Add Action Required</Button>

                                        <Button style={{ margin: "20px 20px 10px 0px" }} disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)} color="primary" onClick={() => { this.addActionModalPerformed() }} >Add Action Performed</Button>

                                        <Button style={{ margin: "20px 20px 10px 0px" }} disabled={isReadOnly || isOtherUser || statusKey == 5 || (isSecurityMeasurementTech == false && statusKey == 4)} color="danger"
                                            onClick={() => this.deleteMeterValAction()}>Delete Action</Button>


                                        <IgrDataGrid
                                            //    height={gridHeight}
                                            disabled={isReadOnly}
                                            height="200px"
                                            width="1080px"
                                            autoGenerateColumns="false"
                                            dataSource={meterValAction}
                                            editMode={0}
                                            selectionMode="SingleRow"
                                            selectedKeysChanged={this.onGridRowSelected}>



                                            <IgrTextColumn field="actionTypeDesc" headerText="Action_Type" width="*>100" />
                                            <IgrTextColumn field="actionDesc" headerText="Action_Description" width="*>100" />
                                            <IgrTemplateColumn field="createdDatetime" headerText="Date" width="*>100"
                                                cellUpdating={this.onReportDateUpdating}
                                                isEditable="false" />

                                        </IgrDataGrid>



                                    </FormGroup>
                                </Col>
                            </Row>
                        </TabPane>



                    </TabContent>


                    <Row form style={{ margin: "0px 5px 0 0" }}>


                        <Modal isOpen={this.state.modalAction} toggle={() => this.toggleModalAction()} size="sm">
                            <ModalHeader
                                toggle={() => this.toggleModalAction()}>Action Required </ModalHeader>
                            <ModalBody>

                                <MeterValidationAction
                                    toggle={this.toggleModalAction}
                                    // user={this.props.user}
                                    refreshData={this.getMeterValAction}
                                    meterValidationKey={meterValidationKey}
                                    meterValidationActionKey={meterValidationActionKey}
                                    meterValidation={meterValidation}
                                    isReadOnly={isReadOnly}
                                >

                                </MeterValidationAction>


                            </ModalBody>

                        </Modal>





                        <Modal isOpen={this.state.modalActionPerformed} toggle={() => this.toggleModalActionPerformed()} size="sm">
                            <ModalHeader
                                toggle={() => this.toggleModalActionPerformed()}>Action Performed </ModalHeader>
                            <ModalBody>

                                <MeterValidationActionPerformed
                                    toggle={this.toggleModalActionPerformed}
                                    // user={this.props.user}
                                    refreshData={this.getMeterValAction}
                                    meterValidationKey={meterValidationKey}
                                    meterValidationActionKey={meterValidationActionKey}
                                    meterValidation={meterValidation}
                                    isReadOnly={isReadOnly}
                                >

                                </MeterValidationActionPerformed>


                            </ModalBody>

                        </Modal>


                        <Modal isOpen={this.state.modalReviewComment} toggle={() => this.toggleModalReviewComment()} size="sm">
                            <ModalHeader
                                toggle={() => this.toggleModalReviewComment()}>Review Comment</ModalHeader>
                            <ModalBody>

                                <ReviewComment
                                    toggle={this.toggleModalReviewComment}
                                    // user={this.props.user}
                                    refreshData={this.getMeterValAction}
                                    meterValidationKey={meterValidationKey}
                                    meterValidationActionKey={meterValidationActionKey}
                                    meterValidation={meterValidation}
                                    isReadOnly={isReadOnly}
                                >

                                </ReviewComment>


                            </ModalBody>

                        </Modal>





                    </Row>


                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <MeterValidationItem data={data} onHandleMetValLineValueChange={this.onHandleMetValLineValueChange} style={{ margin: "0,0,0,0" }}
                                    isAllowEdit={this.isAllowEdit(meterVal[0])} isReadOnly={isReadOnly} isAbleToValidate={isAbleToValidate} statusKey={statusKey}
                                />


                            </Col>
                        </Row>
                    </FormGroup>

                </Form>

            </div>)
    }
}

