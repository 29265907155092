import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';

import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label, Form } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';
import NumberFormat from 'react-number-format';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ReceiverDetail from './ReceiverDetail'
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'
import { data } from 'jquery';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


const MaterialMoveLineCRUD = (props) =>{
    const {itemMasterData, mtlRequestKey, itemMasterKey} = props

    const [isInvLocLoaded, setInvLocLoaded] = useState(false)
    const [invLocList, setInvLocList] = useState([])
    const[itemDataList, setItemDataList] = useState([])
    const[isItemDataLoaded, setIsItemDataLoaded] = useState(false)
    const [moveData, setMoveData] = useState([])
    
    const refreshDataOnSubmit = (refreshData) => {
        // if (bFreshData) this.props.refreshOilTicket();
        if(refreshData){
          props.refreshData()
            // props.calInvtotal()
        }
        props.toggle()
    
     }
    

  const  getItemMaster = ()=>{

        let myURI = apiURL + 'SupplyChain/MaterialRequestItem'
        

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
            //    console.log(json[0].locationKey)
        //   setItemDataArray(createSCList2(json))
    
            setIsItemDataLoaded(true)
            // setItemDataList(createItemDataList(json.filter(a=> a.itemMasterKey === itemMasterData.itemMasterKey)))
            setItemDataList(createItemDataList(json))

   
        
                // setItemData(createItemDataList(selBasinKey !== -1 ? json.filter(a=> a.locationKey === selBasinKey) : json.filter(a=> a.locationKey === locationKey)))
                
        
            });

    }

    const createTransferLineAndClose = () =>{
        const transferLineCreateDTO= createTransferLineCreateDTO()

          
    
        let myURI = apiURL + 'SupplyChain/MaterialRequestLine'
      
        fetch(myURI , apiPOSTHeader(transferLineCreateDTO) )
          .then(async response => {
            const rspData = response.text();
            if (!response.ok){
     
              const error = (rspData && rspData.message) || response.status;
              rspData.then(t => {
                //   setMsgBody(t)
                //   setMsgHeader("Error")
                //   setModalMsg(!modalMsg)
              })
              return Promise.reject(error);
             
            }
          
            // props.setStateOfParentValidation(false)
            refreshDataOnSubmit(true)
         
   
          //   closeForm(true)
          
       
          })
          .catch(error =>{
      
              // refreshDataOnSubmit(true)

  
          })



    }

    const createTransferLineCreateDTO=()=>{
        // const {grpDetails} = this.state 
      
        const transferLineCreateDTO = {
    
           "MtlRequestKey": mtlRequestKey,
           "ItemMasterKey": itemMasterKey,
        //    "ItemNumber":  lineData.itemNumber,
        //    "ItemDescription": lineData.itemNumber,
        //    "RequestQty": lineData.requestQty,
        //    "RequestUOMKey": lineData.requestUOMKey,
        //    "IssueQty": lineData.issueQty,
        //    "IssueUOMKey": lineData.issueUOMKey,
           "FromInvLocKey": moveData.fromInvLocKey,
           "ToInvLocKey": moveData.toInvLocKey,
        //    "FromAFE": lineData.fromAFE,
        //    "ToAFE": "",
        //    "LineComment": lineData.lineComment,
        //    "CreatedBy": getUserADAccount(user.account.userName),
        //    "SpendCategoryID": lineData.spendCategoryID,
        //    "RtnUnitCost": lineData.unitCost
         }
         console.log(transferLineCreateDTO + "xxx")
         return transferLineCreateDTO
    }

   const createItemDataList = (value) =>{
        var tempList = []
        value.map(e => {
            e.value = e.onHandByLocKey
            e.label = e.itemNumberWHSLoc
            tempList.push(e)
        })
        
        return tempList
    } 


  const  getInventoryLoc = () => {
        // const { selBasinKey } = this.state

        let myURI = apiURL + 'SupplyChain/InvLoc'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                  
                        setInvLocLoaded(true)
                        setInvLocList(createInvLocList(json))
                 
                
                    // setInvLocList(createInvLocList(json.filter(e => e.locationKey === selBasinKey)))
                    // setIsInvLocLoaded(true) 
                   
                
            });

    }

        
   const createInvLocList = (value) => {
    var tempList = []
    value.map(e => {
        e.value = e.invLocationKey
        e.label = e.warehouseInvLocation
        tempList.push(e)
    })

    return tempList
}



    useEffect(()=>{
        getItemMaster()
        getInventoryLoc()
        
    },[props.mtlRequestKey])

  const  getSelInvLocKey = (value) => {

        return value.invLocationKey
    }
    
  const   getSelToLocKey = (value) => {
        return value.invLocationKey
    }
    


  const  onTextChange = (fieldName, fieldValue, e) => { 
    
        var temp = null
        console.log(fieldName + '=' + fieldValue )
    
    
        switch (fieldName) {
         
            case "fromInvLocationKey":
                console.log(itemMasterKey)
             console.log( e.value)
             moveData.fromInvLocKey = (e === null ? null : getSelInvLocKey(e))
                // moveData[0].fromInvLocationKey = fieldValue
                
           break;
    
           case "toInvLocationKey": 
    
           moveData.toInvLocKey = (e === null ? null : getSelToLocKey(e))
        //    validDataItem.spendCategoryID = true
            //    setSpendCategoryIDVal(fieldValue != null)
            break;
    
    
             case "transQty": 
    
             moveData.transQty =  Number(fieldValue)
          //    validDataItem.spendCategoryID = true
              //    setSpendCategoryIDVal(fieldValue != null)
              break;
    
              case "unitCost": 
    
             moveData.unitCost =  Number(fieldValue)
          //    validDataItem.spendCategoryID = true
              //    setSpendCategoryIDVal(fieldValue != null)
              break;
    
              case "transMemo": 
    
              moveData.transMemo =  fieldValue
           //    validDataItem.spendCategoryID = true
               //    setSpendCategoryIDVal(fieldValue != null)
               break;
    
             
    
    
    
            default:
    
        }
    
        setMoveData(moveData)
    
    }



    if(!isInvLocLoaded, !isItemDataLoaded){
        return(<div>
            <h4>Loading Transfer Data .... </h4>
        </div>)
    }else

    return(<div>
         <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                From Inv Location
            </Label>
            <Col sm={9}>
                <Select 
                    options={itemDataList}

                    // value={itemDataList.filter(a=> a.itemMasterKey === itemMasterData.itemMasterKey).length > 1 ? this.getSelFromInvLoc(moveData.fromInvLocationKey) :  this.getSelFromInvLoc2(itemMasterData.itemMasterKey)}

                    onChange={(e) => onTextChange("fromInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                To Inv Location
            </Label>
            <Col sm={9}>
                <Select 
                    options={invLocList}

                    onChange={(e) => onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>
        {/* <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                Trans Qty
            </Label>
            <Col sm={9}>
                <NumberFormat 

                    defaultValue={0.00}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    className={"form-control text-md-right"}
                    onValueChange={(e) => onTextChange("transQty", e.value, e)}

                    >

                </NumberFormat>
            </Col>
            
        </FormGroup>
        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                Unit Cost
            </Label>
            <Col sm={9}>
            <NumberFormat 

                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    className={"form-control text-md-right"}
                    // defaultValue={itemMasterData.stdPurchCost}
                 
                    onValueChange={(e) => onTextChange("unitCost", e.value, e)}

                    >

                </NumberFormat>
            </Col>
            
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                Memo
            </Label>
            <Col sm={9}>
            <Input type="textarea" name="memo"
                        rows={4}
                        // value={lineData.lineComment}
                        // defaultValue={lineData.lineComment}
                        //disabled={isFormReadOnly}
                        id="transMemo" 
                       
                        onChange={(e) => onTextChange("transMemo", e.target.value, e)}
                    />
            </Col>
            
        </FormGroup> */}

        <Form style={{ marginTop: "5px" }}>
            <Row>
                <Col md={12}>
                    <div className="float-right">
                        <Button color="warning" className="float-right"
                            onClick={() => props.toggle()}
                            >Close</Button>
                              <Button color="primary" className="float-right"
                            //   hidden = {mtlRequestLineKey < 0}
                            style={{ marginRight: "10px" }}
                            onClick={() => createTransferLineAndClose()}
                            >Add</Button>

                        
                          
                    </div>
                </Col>
            </Row>
        </Form>
    </div>)
}

export default class BulkMove extends Component {
    static displayName = BulkMove.name;

    constructor(props){
      super(props)
      this.onGridRef = this.onGridRef.bind(this);
    //   this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);

        this.state={
            modalLineCRUD: false,
            itemMasterKey:props.itemMasterKey,
            lineData:[],
            mtlRequestLineKey:-1,
            WHList:[],
            isWHLoaded: false,
            basinList:[],
            isBasinLoaded: false,
            mtlRequestKey: props.mtlRequestKey,
            itemMasterData: props.itemMasterData,
            mtlReqLine:[],
            isMtlReqLineLoaded:false,
            startDate: getFirstDayofMonth().toISOString(),
            windowHeight: window.innerHeight,
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.mtlReqLine;
    }


    // getMaterialRequestLine = ()=>{
    //     // const {itemMasterData} = this.state

    //     let myURI = apiURL + 'SupplyChain/MaterialRequestLine/MtlReqKey/' + mtlRequestKey
        

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {

    //     this.setState({
    //         isLoaded: true,
    //         materialRequestLineData: json
    //     })
        
  
    //         });

    // }

    
    getMaterialRequestLine = () => {
        const { mtlRequestKey, mtlReqLine } = this.state

        let myURI = apiURL + 'SupplyChain/MaterialRequestLine/MtlReqKey/' + mtlRequestKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                var mtlReqLine = []
                json.map(e => {
                    e.isUpdated = 0 /* Add this field to keep track of updated receiver line */
                    mtlReqLine.push(e)
                })
                this.setState({
                    mtlReqLine: mtlReqLine,
                    isMtlReqLineLoaded: true
                },()=>{console.log(mtlReqLine)})
                // console.log("Test:" + rcvrLine.ItemMasterKey)
                console.log("Testing")
                
            });
    
    }
    getBasin = () =>{
                    
        let myURI = apiURL + 'EmployeeDetails/FacilityLocation/AzureAD/' + this.props.user.account.userName

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    getWHList = () => {
        let myURI = apiURL + 'SupplyChain/WH'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isWHLoaded: true,
                    WHList: this.createWHList(json)
                })
            });
    }

    createWHList = (value) => {
        var tempList = []
        value.map(item => {               
            item.value=item.warehouseKey
            item.label=item.locationWarehouse
            tempList.push(item)         
        })
        // console.log("tempList")
        // console.log(tempList)
        return tempList;
    }
    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.locationName
            tempList.push(e)
        })

        return tempList
    }


  getSelBasin = (v) => {
    const {basinList} = this.state
        if (v !== null && v !== "") {
            return basinList.filter(e => e.locationKey === v)
        }
        return ""
    }


    onGridRowSelected(s, e) {
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({ 
                            mtlRequestLineKey: dataItem.mtlRequestLineKey,
                            lineData : dataItem,
                            // spendCategoryID: dataItem.spendCategoryID,
                            // itemMasterKey: dataItem.itemMasterKey                                          
                            })

             console.log(dataItem)
             console.log("MtlRequestLineKey: " + dataItem.mtlRequestLineKey)
        }
     
    }

    toggleLineCRUD = () => {
        let newItemLine = this.createItemLineForm()
        this.setState({ 
            lineData : newItemLine,
            mtlRequestLineKey: -1
         
        })
        this.toggleModalCrud()
       
    }

    toggleModalCrud=()=>{
        this.setState({
            modalLineCRUD: !this.state.modalLineCRUD
        })
    }

    createItemLineForm(){
        const {mtlRequestKey, itemMasterKey} = this.props
        const lineItemCreate = {
            "ItemMasterKey": itemMasterKey,
            "TransBy": getUserADAccount(this.props.user.account.userName),
            "TransQty": 0,
            "UnitCost": 0,
            "FromInvLocationKey": null,
            "ToInvLocationKey": null,
            "TransMemo": "",
            
 
        }
        console.log(lineItemCreate)
        return lineItemCreate 
    } 


    
    componentDidMount = () =>{
        this.getMaterialRequestLine()
        this.getBasin()
        this.getWHList()
      
    }



    render(){
        const {mtlReqLine, isMtlReqLineLoaded, windowHeight, basinList, isBasinLoaded, isWHLoaded, WHList, itemMasterKey, lineData, mtlRequestLineKey, mtlRequestKey, itemMasterData} = this.state
        const gridHeight = (windowHeight * 0.60) + "px"
        if (!isMtlReqLineLoaded || !isBasinLoaded ||!isWHLoaded) {
            return (<div>
                <h3>Loading transfer data.....</h3>
            </div>)
        } else
        return(<div>
            <Row form style={{ marginTop: "-10px", marginBottom: "10px" }}>
                <Col sm={4}>

                       <Button color="secondary" className="float-left"
                        onClick={() => this.props.toggle()}>Close</Button>

                        <ConfirmModal
                                    style={{ marginLeft: "5px" }}
                                    color="success"
                                    className="float-left"
                                    buttonLabel="Move"
                                    // hidden={!isFormReadOnly || cancelBool}
                                    formName="Bulk Move"
                                    // id={receiverKey}
                                    message="Are you sure you want to move all selected items?"
                                    // onClickYes={(e) => this.reviseReceiver()}
                                    >
                                </ConfirmModal>
                 </Col>
                 </Row>


                <ColoredLine color="grey" />
                
                

                
              <FormGroup row style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Label
                for="basin"
                sm={3}
            >
                From Basin
            </Label>
            <Col sm={9}>
                <Select 
                    className="alway-on-top-more"
                    options={basinList}
                    // value={itemDataList.filter(a=> a.itemMasterKey === itemMasterData.itemMasterKey).length > 1 ? this.getSelFromInvLoc(moveData.fromInvLocationKey) :  this.getSelFromInvLoc2(itemMasterData.itemMasterKey)}
          
                    // onChange={(e) => this.onTextChange("fromInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                   From Warehouse
            </Label>
            <Col sm={9}>
                <Select 
                    className="alway-on-top"
                    options={WHList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    // onChange={(e) => this.onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

            
        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                   To Basin
            </Label>
            <Col sm={9} className="alway-in-between-more">
                <Select 
                    
                    options={basinList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    // onChange={(e) => this.onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>

        
        <FormGroup row>
            <Label
                for="basin"
                sm={3}
            >
                   To Warehouse
            </Label>
            <Col sm={9}>
                <Select 
                className="alway-in-between"
                    options={WHList}
                    // value={getSelBasin(selBasin)}
                    // className={!basinVal ? "form-error" : ""}
                    // onChange={(e) => this.onTextChange("toInvLocationKey", e, e)}
                    isClearable={true}
                    >

                </Select>
            </Col>
        </FormGroup>


<Row form>
    <Col sm={12}>
    <Button color="success" outline className="float-left"
                style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}   
                  onClick={() => this.getMaterialRequestLine()}
                   ><img
                     src={Plus}
                      alt="new"       
                     style={{ width: "20px", margin: "0 5px 0 0" }} />Refresh</Button>
    <Button color="success" outline className="float-left"
                style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}   
                  onClick={() => this.toggleLineCRUD()}
                   ><img
                     src={Plus}
                      alt="new"       
                     style={{ width: "20px", margin: "0 5px 0 0" }} />New Line</Button>


    </Col>
</Row>
<div>
        <FormGroup >
                    <IgrDataGrid   
                  
                        ref={this.onGridRef}
                        height={gridHeight}
                        // dataSource={receiverData}
                        isColumnOptionsEnabled="true"
                        filterUIType="FilterRow"
                        // filterUIType="FilterRow"
                        // isGroupByAreaVisible="true"
                        // dataSource={receiverData.filter(rec => ((rec.rcvrTypeDesc || '').toLowerCase().includes(searchText)) ||
                        //     (rec.receiverNo || '').toLowerCase().includes(searchText) ||
                        //     (rec.rcvrStausDesc || '').toLowerCase().includes(searchText) ||
                        //     (rec.invoiceNo || '').toLowerCase().includes(searchText) ||
                        //     (rec.poNo || '').toLowerCase().includes(searchText) ||
                        //     (rec.receivedDate || '').toLowerCase().includes(searchText) ||
                        //     (rec.invLocationID|| '').toLowerCase().includes(searchText) ||
                        //     (rec.createdByName|| '').toLowerCase().includes(searchText) ||
                        //     (rec.createdDate || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplierID || '').toLowerCase().includes(searchText) ||
                        //     (rec.comment || '').toLowerCase().includes(searchText) ||
                        //     (rec.supplier_Name|| '').toLowerCase().includes(searchText) 
                        //     )}
                        dataSource={mtlReqLine}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        // selectedKeysChanged={this.onGridRowSelected}
                    //cellBackground={"#ffbfbf"}
                    >
                       
                       <IgrTextColumn field="longItemNumber"
                                    headerText="Item Number"
                                    width="350"
                                   
                                    //disabled
                                    // cellUpdating={this.onCellUpdating}
                                />

                                 <IgrTextColumn field="spendCategoryDesc"
                                    headerText="Spend Category"
                                    width="270"
                                    
                                    //disabled
                                    // cellUpdating={this.onCellUpdating}
                                />
                                {/* <IgrTextColumn
                                    field="itemDescription"
                                    headerText="Item Description"
                                    width="300"
                                    // cellUpdating={this.onCellUpdating}
                                /> */}

                                   <IgrNumericColumn
                                    field="requestQty"
                                    headerText="Request Qty"
                                    width="170"
                                    minFractionDigits={2}
                                    // cellUpdating={this.onCellUpdating}
                                />
                                 <IgrTextColumn
                                    field="requestUOM"
                                    headerText="Request UOM"
                                    width="150"
                                    // cellUpdating={this.onCellUpdating}
                                />
                                   <IgrNumericColumn
                                    field="issueQty"
                                    headerText="Issue Qty"
                                    width="130"
                                    minFractionDigits={2}
                                    // cellUpdating={this.onCellUpdating}
                                />

                                
                                   <IgrTextColumn
                                    field="issueUOM"
                                    headerText="Issue UOM"
                                    width="120"
                                    // cellUpdating={this.onCellUpdating}
                                />
                                   <IgrTextColumn
                                    field="lineComment"
                                    headerText="Comments"
                                    width="300"
                                    // cellUpdating={this.onCellUpdating}
                                />
                                  

                            
                    </IgrDataGrid>
                </FormGroup>
                </div>



                <div>
                        <Modal isOpen={this.state.modalLineCRUD}
                            toggle={() => this.toggleLineCRUD()}
                            size="lg" centered
                            onClosed={() => this.getMaterialRequestLine()}
                            backdrop={"static"}
                            >
                            <ModalHeader toggle={() => this.toggleLineCRUD()}>
                                {/* {msgHeader}  */}
                                {mtlRequestLineKey < 0 ? "Create New Line" : "Edit Line"}
                            </ModalHeader> 
                            <ModalBody>
                                <MaterialMoveLineCRUD 
                                    mtlReqLine = {mtlReqLine}
                                    mtlRequestLineKey = {mtlRequestLineKey}
                                    mtlRequestKey = {mtlRequestKey}
                                    toggle={this.toggleLineCRUD}
                                    refreshData={this.getMaterialRequestLine}
                                    user ={this.props.user}
                                    // resetLineDataOnAdd={this.resetLineDataOnAdd}
                                    itemMasterData={itemMasterData}
                                    itemMasterKey = {itemMasterKey}
                                  >

                                </MaterialMoveLineCRUD>
                            </ModalBody>
                        </Modal>
                    </div>


        </div>)
    }
     
}