import React, { Component, createRef, useState, forwardRef, useRef, useuserImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import RevisionPng from '../resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../resources/DocumentBrowser';
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal';
import DocumentBrowserDragDrop from  '../resources/DocumentBrowserDragDrop'; // '../../resources/DocumentBrowserDragDrop';

import NumberFormat from 'react-number-format';
 

import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../resources/utility';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
import ConstructionReportingChangeOrders from './ConstructionReportingChangeOrders';
import FileManager from '../resources/FileManager';

var DatePicker = require("reactstrap-date-picker");
  
class DailyFacilityReport extends React.Component {
    static displayName =  'Alex testing' //AFEDetail.name

    constructor(props) {
        super(props)

        this.state = {
            user: props.user,
            constructionReportKey: props.constructionReportKey,
            constructionReportFacilityKey: props.constructionReportFacilityKey === undefined || 
                                         props.constructionReportFacilityKey === null ? 0 : 
                                         props.constructionReportFacilityKey,
            constructionReports: props.constructionReports,
            isSecurityAdminLogin:props.isSecurityAdminLogin,
            facilityReportDTO: null,
            statusKey: null,
            closeoutStatusKey: -1,
            alertMessage: "",
            showAlert: false,
            showSaveAlert: false,
            isFormReadOnly: false,
            isApprover: false,
            isAFEManager: false,
            isAFEAdmin: false,
            showCostTracking: false,
            isLoaded: false,
            constructionReportDTO: props.constructionReportDTO,
            empList: props.empList,
            isEmpListLoaded: false,
            budgetClassList: props.budgetClassList,
            isBudgetClass: false,
            afeTypeList: props.afeTypeList,
            isAFETypeLoaded: false,
            costTypeList: props.costTypeList,
            isCostTypeLoaded: false,
            facilityList: props.facilityList,
            isFacilityLoaded: false,
            budgetList: props.budgetList,
            dupAFEList: props.dupAFEList,
            isBudgetLoaded: false,
            isUserAdmin: false,
            isSecurityLoaded: false,
            uomList: props.uomList,
            afeList: props.afeList,
            basinList: props.basinList,
            countyList: props.countyList,
            projectStatusList: props.projectStatusList,
            areaList: props.areaList,
            modalMsg: false,
            msgHeader: "",
            msgBody: "",
            alertMessage: "",
            alertColor: "danger",
            isForceBudgeLineUpdate: false,
            budgetLineRefreshKey: 0,
            activeTab: '1',
            hasDCCost: false,
            isDataChange: false,
            //isApprovalRevision:false,
            yesNoList: [{
                "label": "Yes",
                "value": 1
            }, {
                "label": "No",
                "value": 0
            }],
            validateDataItem: {
                afeName: true,
                afeManagerKey: true,
                afeSponsor: true,
                budgetClassKey: true,
                budgetID: true,
                typeKey: true,
                afeCostType: true,
                locationKey: true,
                county: true,
                linkAFE: true,
                estStartDate: true,
                estComplete: true,
                afeDescription: true,
                wbOwnedPct: true,
                area:true, 
                padConstruction: true,
                expectedCompletionDate: true,
                concreteContainment: true,
                tanksInstalled: true,
                polyInstalled: true,
                stainLessInstalled: true,
                pumpsInstalled: true,
                iAndEComplete: true,
                catWalkInstalled: true,
                miscellaneousWork: true,
                automationElectrical: true,
                inspectorName: true,
                reportDate: true,
                workSummary: true,
                issues: true,
                integrityTestingComplete: true 
            },
            siteTransfer: null ,
            afesRevSupBuget: [],
            reimbursable: [{label: '',value: -1}, {label: 'No',value: 0},{label:'Yes', value: 1}],
            modalReport: false,
            reportName: "",
            dropdownOpenManage: false,
            dailyReports: [],
            edited: false,
        }

        //this.childRef = createRef()
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    dataValidation = () => {
        return new Promise((resolve, reject) => {
            let { facilityReportDTO, validateDataItem} = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }

 

            if (!IsDate(facilityReportDTO.reportDate)) {
                validateDataItem.reportDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " A Report Date was not provided."
            }

            let objReportDate = this.isReportDateExistOnDifferentDailyReport(facilityReportDTO.reportDate);
            if (objReportDate){ 
                validateDataItem.reportDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + ' Report Date ' + facilityReportDTO.reportDate + ' is being used by another daily report.'
            }

            if (!IsDate(facilityReportDTO.expectedCompletionDate)) {
                validateDataItem.expectedCompletionDate = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " A ExpectedCompletion Date was not provided."
            }

            if (facilityReportDTO.workSummary === null || facilityReportDTO.workSummary.trim() === '') {
                validateDataItem.workSummary = false
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Work  Summary is required."
            }
 
            if (facilityReportDTO.padConstruction === null || facilityReportDTO.padConstruction < 0) {
                validateDataItem.padConstruction = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Pad Construction needs to be greater than -1%."
            }

            if (facilityReportDTO.concreteContainment === null || facilityReportDTO.concreteContainment < 0) {
                validateDataItem.concreteContainment = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Concrete & Containment needs to be greater than -1%."
            }

            if (facilityReportDTO.tanksInstalled === null || facilityReportDTO.tanksInstalled < 0) {
                validateDataItem.tanksInstalled = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Tanks Installed needs to be greater than -1%."
            }

            if (facilityReportDTO.polyInstalled === null || facilityReportDTO.polyInstalled < 0) {
                validateDataItem.polyInstalled = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Poly installed needs to be greater than -1%."
            }
  
            if (facilityReportDTO.stainLessInstalled === null || facilityReportDTO.stainLessInstalled < 0) {
                validateDataItem.stainLessInstalled = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Stainless installed needs to be greater than -1%."
            }

            if (facilityReportDTO.pumpsInstalled === null || facilityReportDTO.pumpsInstalled < 0) {
                validateDataItem.pumpsInstalled = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Pumps installed needs to be greater than -1%."
            }

            if (facilityReportDTO.iAndEComplete === null || facilityReportDTO.iAndEComplete < 0) {
                validateDataItem.iAndEComplete = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " I&E Complete needs to be greater than -1%."
            }

            if (facilityReportDTO.catWalkInstalled === null || facilityReportDTO.catWalkInstalled < 0) {
                validateDataItem.catWalkInstalled = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Cat Walk Installed needs to be greater than -1%."
            }

            if (facilityReportDTO.miscellaneousWork === null || facilityReportDTO.miscellaneousWork < 0) {
                validateDataItem.miscellaneousWork = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Miscellaneous Work needs to be greater than -1%."
            }
            
            if (facilityReportDTO.automationElectrical === null || facilityReportDTO.automationElectrical < 0) {
                validateDataItem.automationElectrical = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Automation/Electrical needs to be greater than -1%."
            }
             
            if (facilityReportDTO.issues === null) {
                validateDataItem.issues = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Issues needs to be selected."
            }

            if (facilityReportDTO.integrityTestingComplete === null) {
                validateDataItem.integrityTestingComplete = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Integrity Testing Complete needs to be selected."
            }

            if (facilityReportDTO.inspectorName === null || facilityReportDTO.inspectorName.trim() === '') {
                validateDataItem.inspectorName = false;
                returnVal.ok = false;
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Inspectors Name needs to be filled out."
            }
 

            returnVal.validateDataItem = validateDataItem
            if (returnVal.message !== "") {
                returnVal.message = "Please enter missing data: " + returnVal.message
            }
            resolve(returnVal)
        })
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    toggleAlert = () => {

        this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggleSaveAlsert = () => {
        this.setState({ showSaveAlert: !this.state.showSaveAlert },
            () => { window.setTimeout(() => { this.setState({ showSaveAlert: false }) }, 3000) })
    }

    resetValidDataItem = () => {
        // const { validDataItem } = this.state

        // validDataItem.toLocationKey = true
        // validDataItem.invoiceDate = true
        // validDataItem.invoiceNo = true

        // this.setState({ validDataItem: validDataItem })
    }

    closeForm = () => {
        this.setState({isLoaded: false});
        this.props.toggle(this.state.edited);
    }

    saveData = (statusKey) => {
        const { constructionReportFacilityKey } = this.state

        this.setState({ showAlert: false, alertMessage: '' });
        try {
            this.dataValidation().then((data) => {
                if (data.ok) {

                    //this.childRef.current.saveLineData()

                    if (constructionReportFacilityKey > 0) {
                        this.updateReport(statusKey)
                    } else {
                        this.addReport(statusKey)
                    }

                    this.toggleSaveAlsert()
                } else {
                    this.setState({
                        alertMessage: data.message,
                        validateDataItem: data.validateDataItem,
                        showAlert: true
                    })
                }
            })
        } catch (error) {

        }


    }

    updateReport = async(statusKey) => {
        let {facilityReportDTO,user} = this.state;
        let reload = false;

        facilityReportDTO.statusKey = statusKey;
        facilityReportDTO.modifiedBy = user.account.userName;
        this.setState({isLoaded: false});

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/FacilityDailyReport/Edit/' + facilityReportDTO.expectedCompletionDate;
        fetch(myURI, apiPOSTHeader(facilityReportDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg,
                            isLoaded: true,
                        })
                    })

                    return Promise.reject(error)
                } else {
                    this.setState({edited: true});
                    this.closeForm(); 
                }
                    
            }).then(json => { 
                //this.loadReportData();
                // this.componentDidMount(); 
                reload = true;
            }).catch(error => {
                console.log("PUT error: " + error)
            })

            if (reload){
                //await this.componentDidMount(); 
            }
    }

    addReport = (statusKey) => {
      
        let {facilityReportDTO} = this.state;
    
            let myURI = apiURL + 'ConstructionReporting/ConstructionProject/FacilityDailyReport/Add/' + facilityReportDTO.expectedCompletionDate
            facilityReportDTO.statusKey = statusKey;
    
            this.setState({isLoaded: false});

            fetch(myURI, apiPOSTHeader(facilityReportDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;
    
                        rspData.then(t => {
                            this.setState({
                                msgBody: t,
                                msgHeader: "Error",
                                modalMsg: !this.state.modalMsg,
                                isLoaded: true,
                            })
                        })
    
                        return Promise.reject(error)
                    } else{
                        this.setState({edited: true});
                        return response.json();
                    }

                         
                }).then(json => {
                    this.closeForm();
                    // console.log(json);
                    // this.setState({facilityReportDTO: json,constructionReportFacilityKey: json.constructionReportFacilityKey})    
                }).catch(error => {
                    console.log("PUT error: " + error)
                })
    
        }
     
    deleteAFE = () => {
        const deleteAFEDTO = this.deleteAFEDTO()
        let myURI = apiURL + 'AFE/Header'
        fetch(myURI, apiDELETEHeader(deleteAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    this.props.toggle()
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    deleteAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeDeleteDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "DeletedBy": getUserADAccount(user.account.userName)
        }

        return afeDeleteDTO
    }

    dupAFEDTO = (afeNo) => {
        const { user } = this.props
        const { afeData } = this.state
        const afeDeleteDTO = {
            "DupAFENo": afeNo,
            "RequestBy": getUserADAccount(user.account.userName)
        }

        return afeDeleteDTO
    }


    dupAFE = (afeNo) =>{
        const newDupAFEDTO = this.dupAFEDTO(afeNo)
        //console.log(newAFEDTO)

        let myURI = apiURL + 'AFE/Header/Duplicate'
        fetch(myURI, apiPOSTHeader(newDupAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                if (json[0].afeHeaderKey > 0) {
                    console.log("New AFE Return")
                    console.log(json)


                    this.setState({
                        isLoaded:false,
                        afeHeaderKey: json[0].afeHeaderKey,
                        isDataChange: false
                    })
                    this.getAFEDetails()
                }
            }).catch(error => {
                console.log("PUT error: " + error)
            })
    }

    addAFE = () => {
        const newAFEDTO = this.createAFEDTO()
        //console.log(newAFEDTO)

        let myURI = apiURL + 'AFE/Header'
        fetch(myURI, apiPOSTHeader(newAFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                if (json[0].afeHeaderKey > 0) {
                    console.log("New AFE Return")
                    console.log(json)


                    this.setState({
                        isLoaded:false,
                        afeHeaderKey: json[0].afeHeaderKey,
                        isDataChange: false
                    })
                    this.getAFEDetails()
                }
            }).catch(error => {
                console.log("PUT error: " + error)
            })

    }

    createAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const afeCreateDTO = {
            "AFENo": afeData[0].afeNo,
            "AFEName": afeData[0].afeName,
            "AFEManagerKey": afeData[0].afeManagerKey,
            "AFESponsor": afeData[0].afeSponsor,
            "AFEDescription": afeData[0].afeDescription,
            "Comments": afeData[0].comments,
            "TimingChange": afeData[0].timingChange,
            "TypeKey": afeData[0].typeKey,
            "BudgetClasskey": afeData[0].budgetClassKey,
            "EstStartDate": afeData[0].estStartDate,
            "EstComplete": afeData[0].estComplete,
            "BudgetID": afeData[0].budgetID,
            "WBOwnedPct": afeData[0].wbOwnedPct,
            "FacilityKey": afeData[0].facilityKey,
            "WellName": afeData[0].wellName,
            "Lease": afeData[0].lease,
            "APINumber": afeData[0].apiNumber,
            "County": afeData[0].county,
            "Basin": afeData[0].basin,
            "State": afeData[0].state,
            "SurfaceLegal": afeData[0].surfaceLegal,
            "BottomHoleLegal": afeData[0].bottomHoleLegal,
            "Area": afeData[0].area,
            "CreatedBy": getUserADAccount(user.account.userName),
            "AFECostType": afeData[0].afeCostType,
            "LinkAFE": afeData[0].linkAFE,
            "LocationKey": afeData[0].locationKey,
            "AreaKey": afeData[0].areaKey,
            "SiteTransfer": afeData[0].siteTransfer,
            "ReimbursableProject": afeData[0].reimbursableProject
        }

        return afeCreateDTO;
    }

    reviseAFE = (statusKey) => {
        const { afeData } = this.state
        afeData[0].statusKey = statusKey
        this.setState({ afeData: afeData })
        this.saveData(true)
    }


    reviseCloseoutAFE = (statusKey) => {
        const { afeData } = this.state
        afeData[0].closeoutStatusKey = statusKey
        this.setState({ afeData: afeData })
        this.saveData(true)
    }

    issueAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 4
        this.setState({ afeData: afeData })
        //this.updateAFE()
        this.saveData(true)
    }


    completeAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 6
        this.setState({ afeData: afeData })
        //this.updateAFE()
        this.saveData(true)
    }



    startedAFE = () => {
        const { afeData } = this.state
        afeData[0].statusKey = 11
        this.setState({ afeData: afeData })
        console.log("here here")
        //this.updateAFE()
        this.saveData(true)
    }


    startAFE = () => {
        const { afeData } = this.state
        if (afeData[0].actualStartDate === null) {
            this.setState({
                alertMessage: "Missing Actual Start Date. Please enter Actual Start Date in Additional Detail.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].statusKey = 11 //Project Start
            this.setState({ afeData: afeData })
            //this.updateAFE()
            this.saveData(true)
        }
    }

    completeAFE = () => {
        const { afeData } = this.state
        if (afeData[0].actualEndDate === null) {
            this.setState({
                alertMessage: "Missing Actual End Date. Please enter Actual End Date in Additional Detail.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].statusKey = 6 //Project Start
            this.setState({ afeData: afeData })
            //this.updateAFE()
            this.saveData(true)
        }
    }

    approveAFE = (comment) => {
        this.approvalAFE(6, comment, null)
    }

    sendBackAFE = (comment) => {
        this.approvalAFE(10, comment, null)
    }

    duplidateFromAFE = (afeNo) => {
        this.dupAFE(afeNo)
    }

    fixAssetApproveAFE = (comment) => {
        this.saveData(false)
        this.approvalAFE(6, comment, null)
    }

    approveCloseoutAFE = (comment) => {
        this.approvalAFE(6, comment, null)
    }

    closeOutAFE = (e) => {
        const { afeData } = this.state

        if (afeData[0].actualStartDate === null || afeData[0].actualEndDate === null) {
            var sErr = ""
            if (afeData[0].actualStartDate === null) {
                sErr = "Actual Start Date"
            }
            if (afeData[0].actualEndDate === null) {
                if (sErr !== '') { sErr = sErr + ', ' }
                sErr = sErr + "Actual End Date"
            }
            this.setState({
                alertMessage: "Missing " + sErr + ". Please enter missing date in Additional Details.",
                showAlert: true,
                activeTab: '4'
            })
        } else {
            afeData[0].closeoutStatusKey = 0
            this.setState({ afeData: afeData },
                this.saveData(false))
        }

    }

    submitCloseoutValidation = () => {
        const { afeHeaderKey } = this.state
        var bPass = true
        let myURI = apiURL + 'AFE/closeout/Validate/' + afeHeaderKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    if (json[0].hardStop != 0) {
                        bPass = false
                        this.setState({
                            msgBody: json[0].validationErrorMsg,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    }
                }

                if (bPass) {
                    this.submitCloseoutAFE()
                }
            });
    }




    submitCloseoutAFE = () => {

        const closeoutDTO = this.closeoutAFEDTO()
        console.log(closeoutDTO)
        let myURI = apiURL + 'AFE/Header/Closeout'

        fetch(myURI, apiPUTHeader(closeoutDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })

    }

    closeoutAFEDTO = () => {
        const { user } = this.props
        const { afeData } = this.state
        const closeoutAFEDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "CloseoutBy": getUserADAccount(user.account.userName)
        }

        return closeoutAFEDTO
    }


    rejectAFE = (comment, key) => {
        // console.log("reject Comment")
        // console.log(key)
        this.approvalAFE(0, comment, key)

    }

    rejectCloseoutAFE = (comment, key) => {
        this.approvalAFE(0, comment, key)
    }

    
    approvalAFE = (statusKey, comment, key) => {
        const approvalFEDTO = this.approvalAFEDTO(statusKey, comment, key)
        console.log(approvalFEDTO)
        let myURI = apiURL + 'AFE/Approval'

        fetch(myURI, apiPUTHeader(approvalFEDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                this.closeForm()
            }).catch(error => {
                console.log(error)
            })
    }

    approvalAFEDTO = (statusKey, comment, actionLineKey) => {
        const { user } = this.props
        const { afeData } = this.state
        const afeApprovalDTO = {
            "AFEHeaderKey": afeData[0].afeHeaderKey,
            "ApprovalUser": getUserADAccount(user.account.userName),
            "ApprovalStatusKey": statusKey,
            "ApprovalComment": comment,
            "ActionLineKey": actionLineKey
        }

        return afeApprovalDTO
    }

    getSelEmp = (v) => {
        const { empList } = this.state
        if (v !== null && v !== "" && !isNaN(v)) {
            return empList.filter(e => e.employeeKey === Number(v));
        }
        return ""

    }


    getSelLinkAFE = (v) => {
        const { linkAFEList } = this.state
        if (v !== null && v !== "") {
            return linkAFEList.filter(e => e.project_ID === v);
        }
        return ""

    }



    getEmpKey = () => {
        const { empList } = this.state
        const { user } = this.props
        const adAccount = getUserADAccount(user.account.userName)

        const selEmp = empList.filter(e => (e.adAccount || '').toLowerCase() === adAccount.toLowerCase());
        if (selEmp.length > 0) {
            return selEmp[0].employeeKey
        }
        return -1
    }




    getSelFacility = (v) => {
        const { facilityList } = this.state
        if (v !== null && v !== "") {
            return facilityList.filter(e => e.facilityKey === v);
        }
        return ""
    }



    getSelBudget = (v) => {
        const { budgetList } = this.state
        if (v !== null && v !== "") {
            return budgetList.filter(e => e.afeBudgetDesc === v);
        }
        return ""
    }


    getSelBudgetClass = (v) => {
        const { budgetClassList } = this.state
        if (v !== null && v !== "") {
            return budgetClassList.filter(e => e.afeBudgetClassKey === v);
        }
        return ""
    }


    getSelAFECostType = (value) => {
        const { costTypeList } = this.state

        if (value !== null && value !== "") {
            return costTypeList.filter(e => value.includes(e.afeLineTypeKey))
        }
        return ""
    }

    getSelArea = (v) => {
        const { areaList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return areaList.filter(e => e.areaKey === v)
        }
        return ""
    }

    getReimbursable =(v) =>{
        const { reimbursable } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            return reimbursable.filter(e => e.value === v)
        }
        return ""
    }
    
    getSelCounty = (v) => {
        const { countyList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && v !== "") {
            // console.log("v")
            // console.log(v)
            return countyList.filter(e => e.countyDesc === v)
        }
        return ""
    }


    getSelBasin = (v) => {
        const { basinList } = this.state

        // console.log("v")
        // console.log(v)

        if (v !== null && !isNaN(v)) {
            // console.log("v")
            // console.log(v)
            return basinList.filter(e => e.locationKey === Number(v))
        }
        return ""
    }



    getAFETypeCostString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.afeLineTypeKey
        })

        return grpKey;
    }



    getSelAFEType = (v) => {
        const { afeTypeList } = this.state
        if (v !== null && v !== "") {
            return afeTypeList.filter(e => e.afeTypeKey === v);
        }
        return ""
    }

    getSelYesNo = (v) => {
        const { yesNoList } = this.state
        if (v !== null && v !== "") {
            return yesNoList.filter(e => e.value === v);
        }
        return ""
    }


    onTextChange = (fieldName, fieldValue, e) => {
        let {validateDataItem,facilityReportDTO,alertMessage,showAlert} = this.state
         
        showAlert = false;

        switch (fieldName) {
            case 'reportDate': 
                let objReportDate = this.isReportDateExistOnDifferentDailyReport(fieldValue);
                if (objReportDate){
                    alertMessage = 'Report Date ' + fieldValue.toString().substr(0,10) + ' is being used by another daily report.';
                    showAlert = true;
                    validateDataItem.reportDate = false; 
                }
                else{
                    validateDataItem.reportDate = true;
                    facilityReportDTO.reportDate = fieldValue;
                }
                break;
            case "expectedCompletionDate":
                validateDataItem.expectedCompletionDate = true;
                facilityReportDTO.expectedCompletionDate = fieldValue;
                break;    
            case 'workSummary':
                validateDataItem.workSummary = true;
                facilityReportDTO.workSummary = fieldValue;
                break; 
            case 'padConstruction':
                validateDataItem.padConstruction = true;
                facilityReportDTO.padConstruction = this.inputValueCheck(Number(fieldValue)) ;
                
                // let totalCompletion =  facilityReportDTO.padConstruction  +  
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion/10;                       
                
                break;
            case "concreteContainment":
                validateDataItem.concreteContainment = true;
                facilityReportDTO.concreteContainment = this.inputValueCheck(Number(fieldValue)) ;
                
                // let totalCompletion2 =  facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion2/10;      
                
                break; 
            case "tanksInstalled":
                validateDataItem.tanksInstalled = true;
                facilityReportDTO.tanksInstalled = this.inputValueCheck(Number(fieldValue));
                
                // let totalCompletion3 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion3/10;      
                
                break;
            case "polyInstalled":
                validateDataItem.polyInstalled = true;
                facilityReportDTO.polyInstalled = this.inputValueCheck(Number(fieldValue));
                
                // let totalCompletion4 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion4/10;      
                
                break;
            case "stainLessInstalled":
                validateDataItem.stainLessInstalled = true;
                facilityReportDTO.stainLessInstalled = this.inputValueCheck(Number(fieldValue));
                
                // let totalCompletion5 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion5/10;      
                
                break;
            case "pumpsInstalled":
                validateDataItem.pumpsInstalled = true;
                facilityReportDTO.pumpsInstalled = this.inputValueCheck(Number(fieldValue));
                
                // let totalCompletion6 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion6/10;      
                
                break;
            case "iAndEComplete":
                validateDataItem.iAndEComplete = true;
                facilityReportDTO.iAndEComplete = this.inputValueCheck(Number(fieldValue));
                
                // let totalCompletion7 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion7/10;      
                
                break;
            case "catWalkInstalled":
                validateDataItem.catWalkInstalled = true;
                facilityReportDTO.catWalkInstalled = this.inputValueCheck(Number(fieldValue));
                
                // let totalCompletion8 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion8/10;      
                
                break;
            case "miscellaneousWork":
                validateDataItem.miscellaneousWork = true;
                facilityReportDTO.miscellaneousWork = this.inputValueCheck(Number(fieldValue));
                
                // let totalCompletion9 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                //                         facilityReportDTO.totalCompletion = totalCompletion9/10;      
                
                break;
            case 'automationElectrical':
                validateDataItem.automationElectrical = true;
                facilityReportDTO.automationElectrical = this.inputValueCheck(Number(fieldValue));

                // let totalCompletion10 = facilityReportDTO.padConstruction +    
                //                         facilityReportDTO.concreteContainment + 
                //                         facilityReportDTO.tanksInstalled + 
                //                         facilityReportDTO.polyInstalled +
                //                         facilityReportDTO.stainLessInstalled +
                //                         facilityReportDTO.pumpsInstalled + 
                //                         facilityReportDTO.iAndEComplete +
                //                         facilityReportDTO.catWalkInstalled + 
                //                         facilityReportDTO.miscellaneousWork + 
                //                         facilityReportDTO.automationElectrical
                // facilityReportDTO.totalCompletion = totalCompletion10/10;      

                break;  
            case 'issues':
                facilityReportDTO.issues = fieldValue ? 1 : 0;
                validateDataItem.issues = true;
                break;
            case 'integrityTestingComplete':
                facilityReportDTO.integrityTestingComplete = fieldValue ? 1 : 0;
                validateDataItem.integrityTestingComplete = true;
                break;
            case 'inspectorName':
                validateDataItem.inspectorName = true;
                facilityReportDTO.inspectorName = fieldValue;
                break;
            default:
                break;
        }

        this.setState({
            facilityReportDTO: facilityReportDTO,
            validateDataItem: validateDataItem,
            showAlert: showAlert,
            alertMessage: alertMessage, 
        }) 
    }

    isReportDateExistOnDifferentDailyReport(reportDate){
        const {constructionReports,constructionReportFacilityKey} = this.state;

        if(reportDate === undefined || reportDate === null ||  reportDate == ''){
            return false;
        }

        reportDate = reportDate.substr(0,10);

        if (constructionReports !== undefined && constructionReports !== null){
            let obj = constructionReports.filter(x => x.constructionReportFacilityKey !== constructionReportFacilityKey &&
                                                      x.reportDate.substr(0,10) == reportDate);
            if (obj != null && obj.length > 0){
                return true;
            }
        }
        return false;
    }

    inputValueCheck(input){
        if (input === 0){
            return 0;
        }
        else if (input < 0){
            return 0;
        }
        else if (input > 100){
            return 100;
        }
        else{
            return input;
        }
    }

    onSiteToSiteChange = (fieldValue) => {
        const { afeData  } = this.state
        afeData[0].siteTransfer = fieldValue;
        this.setState({ afeData: afeData })
    }

    getAFEDetailsAPI = () => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }

    getAFERevSup = async() => {
        const { afeHeaderKey } = this.state
        
        if (afeHeaderKey === undefined || afeHeaderKey === null || afeHeaderKey <= 1){
            return; 
        }
        
        let myURI = apiURL + 'AFE/Header/' + afeHeaderKey + '/RevisionSupplement';

        console.log(myURI);

        await fetch (myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afesRevSupBuget: json 
                }) 
            });

        console.log('getAFERevSup');    
    }

    setIsShowCostTracking = (statusKey) => {
        return !(statusKey === 0 || statusKey === 1 || statusKey === 2 || statusKey === 3)
    }

    setIsApprovalRevision = (statusKey, approver, isApprovalRevision, closeoutStatusKey) => {
        if (this.isAFEApprover(statusKey, approver, closeoutStatusKey) && isApprovalRevision !== 0) {
            this.setState({
                showAlert: true,
                alertColor: "info",
                alertMessage: "This AFE have been revised and resubmitted for your approval. Please view change log for update history."
            })
        }
    }

    isAFEApprover = (statusKey, approver, closeOutStatusKey) => {
        const { user } = this.props
        if (approver !== "" && (statusKey === 3 || closeOutStatusKey === 3)) {
            return approver.includes(user.account.userName.toLowerCase())
        } else {
            return false
        }
    }

    getDetails = (constructionReportKey) => {
        const { afeHeaderKey } = this.state
        let myURI = apiURL + 'AFE/Header/' + constructionReportKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    afeData: json,
                    isLoaded: true,
                    isFormReadOnly: this.setIsFormReadonly(json[0].statusKey,
                        json[0].afeManagerAzureAD),
                    statusKey: json[0].statusKey,
                    closeoutStatusKey: json[0].closeoutStatusKey,
                    showCostTracking: this.setIsShowCostTracking(json[0].statusKey),
                    hasDCCost: json[0].afeCostType.includes("1"),
                    isApprover: this.isAFEApprover(json[0].statusKey,
                        json[0].approvers,
                        json[0].closeoutStatusKey),
                    isAFEManager: (json[0].afeManagerAzureAD.toLowerCase() === this.props.user.account.userName.toLowerCase())
                })

                // console.log("InGetAFEDEtails")
                // console.log(json)

                this.setIsApprovalRevision(json[0].statusKey,
                    json[0].approvers,
                    json[0].isApprovalRevision,
                    json[0].closeoutStatusKey)

                this.props.setFormHeader(json[0].afeNo,
                    json[0].afeStatusDesc,
                    json[0].afeName,
                    json[0].afeHeaderKey,
                    json[0].closeoutStatusDesc)
            });
    }



    setIsFormReadonly = (statusKey, afeManagerAzureAD) => {
        const { user } = this.props
        const { isAFEAdmin } = this.state


        if (statusKey === 1 || statusKey === 10) { //Rejected //10=Revise
            return !((afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase()) || isAFEAdmin)
        } if (statusKey === 6 || statusKey === 5) {
            return true
        } else if (statusKey === 3) {
            return true
        } else if (statusKey === 8) { // 8 - Closed
            return true
        } else if (statusKey === 11) {// 11=Project Started
            return true
        } else if (statusKey === 2) {
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } if (statusKey === 4) { //Approved 
            //return true
            return !(afeManagerAzureAD.toLowerCase() === user.account.userName.toLowerCase())
        } else {
            return false
        }

    }

    createConstructionReport = () => {
        const { user } = this.props;

        var date = new Date();
        let todaysDate = date.toLocaleDateString();

        const constructionReportDTO = {
            "constructionReportKey": 0,
            "afeNo": null,
            // "afeName": "",
            "constructionReportStatusDesc": "Initiated",
            "statusKey": 1,
            "afeHeaderKey": 0,
            "projectName": "",
            "createdBy": user.account.name,
            "createdDate": todaysDate, 
            "modifiedBy": user.account.name,
            "modifiedDate": todaysDate,  
            "projectManager": "",
            "projectType": null,
            "requiredEndDate": null,
            "pipeSize": null,
            "uncasedBoreLength": null,
            "projectLength": null,
            "casedBoreLength": null,
            "lat": null,
            "long": null,
            "comments": "", 
        }

        return [constructionReportDTO]
    }

    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }


    async componentDidMount() {
        const { constructionReportKey,constructionReportFacilityKey } = this.state 
        await this.getConstructionReportDetails(constructionReportKey);
        await this.loadReportData();
        this.setState({  isLoaded: true });
    }

    async loadReportData(){
        const {constructionReportDTO,constructionReportKey,constructionReportFacilityKey,user} = this.state;
        if (constructionReportFacilityKey == undefined || constructionReportFacilityKey == null || constructionReportFacilityKey <=0 ){

            await this.getAllDailyReports();
            const {dailyReports} = this.state;
            let objDailyReport = [];

            if(dailyReports.length > 0){
                objDailyReport = dailyReports[0];
            }

            var date = new Date();
            let todaysDate = this.formatDate(date);
             
            let facilityReportDTO = {
                constructionReportFacilityKey: 0,
                constructionReportKey: constructionReportKey,
                reportDate: todaysDate,
                workSummary: null, 
                padConstruction: null,
                padConstructionTotalCompleted: objDailyReport != null && objDailyReport.padConstructionTotalCompleted != null ? objDailyReport.padConstructionTotalCompleted : 0,
                concreteContainment: null,
                concreteContainmentTotalCompleted: objDailyReport != null && objDailyReport.concreteContainmentTotalCompleted != null ? objDailyReport.concreteContainmentTotalCompleted : 0,
                tanksInstalled: null,
                tanksInstalledTotalCompleted: objDailyReport != null && objDailyReport.tanksInstalledTotalCompleted != null ? objDailyReport.tanksInstalledTotalCompleted : 0,
                polyInstalled: null,
                polyInstalledTotalCompleted: objDailyReport != null && objDailyReport.polyInstalledTotalCompleted != null ? objDailyReport.polyInstalledTotalCompleted : 0,
                stainLessInstalled: null,
                stainLessInstalledTotalCompleted: objDailyReport != null && objDailyReport.stainLessInstalledTotalCompleted != null ? objDailyReport.stainLessInstalledTotalCompleted : 0,
                pumpsInstalled: null,
                pumpsInstalledTotalCompleted: objDailyReport != null && objDailyReport.pumpsInstalledTotalCompleted != null ? objDailyReport.pumpsInstalledTotalCompleted : 0,
                iAndEComplete: null,
                iAndECompleteTotalCompleted: objDailyReport != null && objDailyReport.iAndECompleteTotalCompleted != null ? objDailyReport.iAndECompleteTotalCompleted : 0,
                catWalkInstalled: null,
                catWalkInstalledTotalCompleted: objDailyReport != null && objDailyReport.catWalkInstalledTotalCompleted != null ? objDailyReport.catWalkInstalledTotalCompleted : 0,
                miscellaneousWork: null,
                miscellaneousWorkTotalCompleted: objDailyReport != null && objDailyReport.miscellaneousWorkTotalCompleted != null ? objDailyReport.miscellaneousWorkTotalCompleted : 0,
                automationElectrical: null,
                automationElectricalTotalCompleted: objDailyReport != null && objDailyReport.automationElectricalTotalCompleted != null ? objDailyReport.automationElectricalTotalCompleted : 0,
                totalCompletion: objDailyReport != null && objDailyReport.totalCompletion != null ? objDailyReport.totalCompletion : 0,
                issues: null,
                accident: 0,
                integrityTestingComplete: null,
                inspectorName: user.account.name,
                createdBy: user.account.userName,
                createdByKey: 0,
                createdDate: todaysDate,
                statusKey: 0,
                status: "Draft",
                afeNo: constructionReportDTO.afeNo,
                afeHeaderKey: constructionReportDTO.afeHeaderKey,
                projectName: constructionReportDTO.projectName,
                startDate: constructionReportDTO.startDate,
                endDate: constructionReportDTO.estComplete,
                expectedCompletionDate: constructionReportDTO.expectedCompletionDate,
                locationKey: constructionReportDTO.locationKey,
                basin: constructionReportDTO.basin,
                afeStatusKey: 0,
                afeStatusDesc: '',
                blob_id: "-" + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString() + date.getMilliseconds().toString() 
            }

            this.setState({facilityReportDTO: facilityReportDTO})
        }
        else if (constructionReportFacilityKey > 0){
            //Call API;
            await this.getReportData();
        }
    }

    async getConstructionReportDetails(constructionReportKey) {
        //const { constructionReportKey } = this.state
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({ 
                    constructionReportDTO: json, 
                }) 
            });
    }
 
    async getReportData(){
        
        const { constructionReportKey,constructionReportFacilityKey} = this.state;

        let myURI = apiURL + 'ConstructionReporting/ConstructionProject/' + constructionReportKey + '/DailyReport/' + constructionReportFacilityKey

        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({ 
                    facilityReportDTO: json, 
                }) 
            });
    }
  
    async getAllDailyReports(){
        
        const { constructionReportKey} = this.state;

        let myURI = apiURL + 'ConstructionReporting/' + constructionReportKey + '/DailyReports'; 
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                 
                this.setState({ 
                    dailyReports: json, 
                }) 
            });
    }

    isFormReadOnly = () => {
        const {  constructionReportDTO} = this.state

        if (constructionReportDTO !== undefined){
            return constructionReportDTO.statusKey === 3 || constructionReportDTO.statusKey === 4 || constructionReportDTO.statusKey === 5 ? true : false;
        }
        return false;
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKey} = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKey }]; 
        return reportPara
    }
    
    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }

    async getAFEReport(reportName){
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }

    formatDate = (value) => {

        if (IsDate(value)) {
            const months = {
                0: '01',
                1: '02',
                2: '03',
                3: '04',
                4: '05',
                5: '06',
                6: '07',
                7: '08',
                8: '09',
                9: '10',
                10: '11',
                11: '12',
            }
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            const d = new Date(value)
            const year = d.getFullYear()
            const date = (d.getDate() < 10 ? '0' : '') + d.getDate()
            const monthIndex = d.getMonth()
            const monthName = months[d.getMonth()]
            const dayName = days[d.getDay()] // Thu
    
            //const formatted = `${monthName}/${date}/${year}`
            const formatted = `${year}-${monthName}-${date}`
            return formatted.toString()
        } else {
            return ""
        }
    
    }

    issues = (fieldName) => {
        let {facilityReportDTO} = this.state;
        let result = false;

        if (facilityReportDTO.issues == null){
            result = false;
        }
        else if (facilityReportDTO.issues && fieldName == 'YES'){
            result = true;
        }
        else if (facilityReportDTO.issues == false && fieldName == 'YES'){
            result = false;
        }
        else if (facilityReportDTO.issues && fieldName == 'NO'){
            result = false;
        }
        else if (facilityReportDTO.issues == false && fieldName == 'NO'){
            result = true;
        }

        return result; 
    }

    integrityTestingCompleted = (fieldName) => {
        let {facilityReportDTO} = this.state;
        let result = false;

        if (facilityReportDTO.integrityTestingComplete == null){
            result = false;
        }
        else if (facilityReportDTO.integrityTestingComplete && fieldName == 'YES'){
            result = true;
        }
        else if (facilityReportDTO.integrityTestingComplete == false && fieldName == 'YES'){
            result = false;
        }
        else if (facilityReportDTO.integrityTestingComplete && fieldName == 'NO'){
            result = false;
        }
        else if (facilityReportDTO.integrityTestingComplete == false && fieldName == 'NO'){
            result = true;
        }

        return result; 
    }

    render() {
        const { facilityReportDTO, 
            alertMessage, constructionReportDTO, showAlert , isLoaded  , showSaveAlert , 
            alertColor, isDataChange ,afesRevSupBuget,isSecurityAdminLogin,validateDataItem} = this.state
          
        let objAFEsRevSupBuget = 0;
        let objAFEsRevSupBugetDetails = '' 
        if (afesRevSupBuget != undefined && afesRevSupBuget !== null && afesRevSupBuget.length > 0){ 
            for (let i = 0; i < afesRevSupBuget.length; i++) {
                let index = afesRevSupBuget[i].afeNo.indexOf('-') + 1;
                let objLength = afesRevSupBuget[i].afeNo.length - index;
                objAFEsRevSupBuget  =  objAFEsRevSupBuget + afesRevSupBuget[i].afeTotalAmt;
                objAFEsRevSupBugetDetails += (i == 0 ? ' ' : ' + ')   + afesRevSupBuget[i].afeNo.substr(index, objLength) + ': ' +  USDCurrencyFormat(afesRevSupBuget[i].afeTotalAmt,0) ;
            }
            console.log(objAFEsRevSupBuget); 
        } 
 
        let index = 0;

        if (!isLoaded) {
            return (<div>Loading Construction Report Data .....</div>)
        } 
        else if (facilityReportDTO === undefined || facilityReportDTO === null){
            return (<div>Loading Construction Report Data .....</div>)
        }
        else
            return (<div>

                <Form onSubmit={e => e.preventDefault()}>
                    <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <Col sm={5}>

                            <Button color="secondary" className="float-left"
                                hidden={isDataChange}
                                onClick={() => this.closeForm()}>Close</Button>  
                            <Button color="primary" className="float-left"
                                style={{ marginLeft: "5px" }}
                                //ref={ref}
                                hidden={facilityReportDTO.statusKey == 0}
                                onClick={() => this.saveData(2)}>Save</Button>
                            <Button color="success" className="float-left"
                                style={{ marginLeft: "5px" }}
                                //ref={ref}
                                hidden={facilityReportDTO.statusKey > 0 }
                                onClick={() => this.saveData(2)}>Submit</Button>     
                        </Col> 
                        <Col sm={1}> 
                            {/* <Alert color="success" isOpen={showSaveAlert}
                                //toggle={this.toggleSaveAlsert}
                                style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                                {"Data Save Successfuly !!!!"}
                            </Alert>  */}
                        </Col>  
                    </Row>
                    <ColoredLine color="grey" />
                    <Row form>
                        <Alert color="success" isOpen={showSaveAlert}
                            toggle={this.toggleSaveAlsert}
                            style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                            {"Data Save Successfuly !!!!"}
                        </Alert>
                        <Alert color={alertColor} toggle={() => this.toggleAlert()}
                            isOpen={showAlert} style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {alertMessage}
                        </Alert >
                    </Row>
                    <Accordion allowMultipleExpanded={true}
                        allowZeroExpanded={true}
                        preExpanded={(['general','workSummary'])}>

                        <AccordionItem uuid='general'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>General Information</strong>
                                    {/* <strong style={{ textAlign: 'right', paddingLeft: '45rem' }}>Status - {facilityReportDTO.status}</strong>    */}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>

                                <Row form style={{ marginTop: "-10px", marginBottom: "5px" }}>
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeNo">AFE No</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input disabled type="text" name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={facilityReportDTO == undefined || facilityReportDTO == null ||
                                                        facilityReportDTO.afeNo == undefined || facilityReportDTO.afeNo==null ? '' : facilityReportDTO.afeNo}
                                                />
                                            </Col> 
                                        </Row>
                                    </Col>

                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="createdBy">Project Name</Label>
                                            </Col>
                                            <Col md={8}>
                                                 <Input disabled type="text" name="createdBy"
                                                    id="createdBy" placeholder=""
                                                    value={facilityReportDTO == undefined || facilityReportDTO == null ||
                                                        facilityReportDTO.projectName == undefined || facilityReportDTO.projectName==null ? '' : facilityReportDTO.projectName}
                                                />
                                            </Col>

                                        </Row>
                                    </Col> 
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Start Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input type="text" name="afeName"
                                                    //className={(!validateDataItem.afeName ? "form-error" : "")}
                                                    id="afeName" placeholder=""
                                                    disabled 
                                                    value={FormatDate(facilityReportDTO.startDate)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                    <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">End Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="afeName"
                                                    //className={(!validateDataItem.afeName ? "form-error" : "")}
                                                    id="afeName" placeholder=""
                                                    disabled
                                                    value={FormatDate(facilityReportDTO.endDate)}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Report Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <DatePicker
                                                    className={(!validateDataItem.reportDate ? "form-error" : "")}
                                                    value={facilityReportDTO.reportDate}
                                                    onChange={(v, f) => this.onTextChange("reportDate", v, f)}
                                                    disabled={this.isFormReadOnly()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                    <Col md={6}>
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="basin">Basin</Label>
                                            </Col>
                                            <Col md={8}>
                                                <Input 
                                                    type="text" name="basin"
                                                    id="basin" placeholder=""
                                                    disabled 
                                                    value={facilityReportDTO.basin}
                                                />
                                            </Col>
                                        </Row> 
                                    </Col> 
                                </Row>
                                <Row form style={{ marginBottom: "5px" }}>
                                    <Col md={6}> 
                                        <Row form>
                                            <Col md={4}>
                                                <Label for="afeSponsor">Expected Completion Date</Label>
                                            </Col>
                                            <Col md={8}>
                                                <DatePicker 
                                                    value={facilityReportDTO.expectedCompletionDate}
                                                    className={(!validateDataItem.expectedCompletionDate ? "form-error" : "")}
                                                    onChange={(v, f) => this.onTextChange("expectedCompletionDate", v, f)}
                                                    disabled={this.isFormReadOnly() && !isSecurityAdminLogin}
                                                />
                                            </Col>
                                        </Row>
                                    </Col> 
                                </Row> 
                                <Row form style={{ marginBottom: "5px" }}> 
                                    <Col md={12}> 
                                        <Row form>
                                            <Col md={2}>
                                                <Label for="afeSponsor">Work Summary</Label>
                                            </Col>
                                            <Col md={10}>
                                                <Input type="textarea" name="afeDescription"
                                                    rows={8}
                                                    className={(!validateDataItem.workSummary ? "form-error" : "")}
                                                    disabled={this.isFormReadOnly()}
                                                    id="summary" placeholder=""
                                                    value={facilityReportDTO.workSummary}
                                                    //defaultValue={afeData[0].afeDescription}
                                                    onChange={(e) => this.onTextChange("workSummary", e.target.value, e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>  
                                </Row> 
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem uuid='workSummary'>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <strong>Details</strong>  
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <Row form style={{   marginBottom: "5px" }}>
                                <Col md={12}>
                                    <Row form>
                                        <Col md={5}>
                                                
                                        </Col>
                                        <Col md={2} style={{paddingLeft: "8px"}}>
                                            <Label for="afeNo"><strong>Completed Today</strong></Label>
                                        </Col>
                                        <Col md={2} style={{paddingLeft: "17px"}}>
                                            <Label for="afeNo"><strong>Total Completed</strong></Label> 
                                        </Col> 
                                    </Row>
                                </Col>
                            </Row>
                            <Row form style={{   marginBottom: "5px" }}> 
                                <Col md={5}>
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="padConstruction">Pad Construction</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.padConstruction ? "form-error" : "")} 
                                                value={facilityReportDTO.padConstruction}
                                                onValueChange={(e) => this.onTextChange("padConstruction", e.value, e)}
                                                fixedDecimalScale={true}  
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                placeholder={"%"}
                                                thousandSeparator={true}
                                                suffix={'%'} /> 
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px",marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.padConstructionTotalCompleted ? "form-error" : "")} 
                                                value={facilityReportDTO.padConstructionTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("padConstruction", e.value, e)}
                                                fixedDecimalScale={true}  
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder={"%"}
                                                thousandSeparator={true}
                                                suffix={'%'} /> 
                                        </Col> 
                                    </Row>
                                </Col> 
                            </Row> 
                            <Row form style={{   marginBottom: "5px" }}>
                                <Col md={5}>
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeNo">Concrete & Containment</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.concreteContainment ? "form-error" : "")}  
                                                value={facilityReportDTO.concreteContainment}
                                                onValueChange={(e) => this.onTextChange("concreteContainment", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                placeholder={"%"}
                                                thousandSeparator={true}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.concreteContainmentTotalCompleted ? "form-error" : "")}  
                                                value={facilityReportDTO.concreteContainmentTotalCompleted}
                                                onValueChange={(e) => this.onTextChange("concreteContainment", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                placeholder={"%"}
                                                thousandSeparator={true}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col> 
                            </Row> 
                            <Row form style={{marginBottom: "5px"}}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Tanks Installed</Label>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.tanksInstalled ? "form-error" : "")}   
                                                value={facilityReportDTO.tanksInstalled}
                                                onValueChange={(e) => this.onTextChange("tanksInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.tanksInstalled ? "form-error" : "")}   
                                                value={facilityReportDTO.tanksInstalledTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("tanksInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>   
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="afeSponsor">Poly Installed</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.polyInstalled ? "form-error" : "")}   
                                                value={facilityReportDTO.polyInstalled}
                                                onValueChange={(e) => this.onTextChange("polyInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.polyInstalled ? "form-error" : "")}   
                                                value={facilityReportDTO.polyInstalledTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("polyInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Stainless Installed</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.stainLessInstalled ? "form-error" : "")} 
                                                value={facilityReportDTO.stainLessInstalled}
                                                onValueChange={(e) => this.onTextChange("stainLessInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.stainLessInstalled ? "form-error" : "")} 
                                                value={facilityReportDTO.stainLessInstalledTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("stainLessInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Pumps Installed</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.pumpsInstalled ? "form-error" : "")} 
                                                value={facilityReportDTO.pumpsInstalled}
                                                onValueChange={(e) => this.onTextChange("pumpsInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.pumpsInstalled ? "form-error" : "")} 
                                                value={facilityReportDTO.pumpsInstalledTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("pumpsInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">I&E Complete</Label>
                                        </Col>
                                        
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.iAndEComplete ? "form-error" : "")} 
                                                value={facilityReportDTO.iAndEComplete}
                                                onValueChange={(e) => this.onTextChange("iAndEComplete", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.iAndEComplete ? "form-error" : "")} 
                                                value={facilityReportDTO.iAndECompleteTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("iAndEComplete", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Cat Walk Installed</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.catWalkInstalled ? "form-error" : "")}  
                                                value={facilityReportDTO.catWalkInstalled}
                                                onValueChange={(e) => this.onTextChange("catWalkInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.catWalkInstalled ? "form-error" : "")}  
                                                value={facilityReportDTO.catWalkInstalledTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("catWalkInstalled", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Miscellaneous Work</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4} >
                                            <NumberFormat
                                                className={(!validateDataItem.miscellaneousWork ? "form-error" : "")}  
                                                value={facilityReportDTO.miscellaneousWork}
                                                onValueChange={(e) => this.onTextChange("miscellaneousWork", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.miscellaneousWork ? "form-error" : "")}  
                                                value={facilityReportDTO.miscellaneousWorkTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("miscellaneousWork", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Automation/Electrical</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            <NumberFormat
                                                className={(!validateDataItem.automationElectrical ? "form-error" : "")}  
                                                value={facilityReportDTO.automationElectrical}
                                                onValueChange={(e) => this.onTextChange("automationElectrical", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={this.isFormReadOnly()}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat
                                                //className={(!validateDataItem.automationElectrical ? "form-error" : "")}  
                                                value={facilityReportDTO.automationElectricalTotalCompleted}
                                                //onValueChange={(e) => this.onTextChange("automationElectrical", e.value, e)}
                                                fixedDecimalScale={true}
                                                decimalScale={0}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor"><strong>Total Completion</strong></Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form style={{marginRight: "42px"}}> 
                                        <Col md={4}>
                                            {/* <NumberFormat 
                                                value={facilityReportDTO.totalCompletion}
                                                //onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                fixedDecimalScale={true}
                                                //decimalScale={1}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'}
                                                /> */}
                                        </Col>
                                        <Col md={4} style={{marginLeft: "24px", marginRight: "5px"}}>
                                            <NumberFormat 
                                                value={facilityReportDTO.totalCompletion}
                                                //onValueChange={(e) => this.onTextChange("wbOwnedPct", e.value, e)}
                                                fixedDecimalScale={true}
                                                //decimalScale={1}
                                                disabled={true}
                                                thousandSeparator={true}
                                                placeholder={"%"}
                                                suffix={'%'} />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}>
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Issues: If yes, describe in work summary above</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form> 
                                        <Col md={3}> 
                                            <CustomInput
                                                className={(!validateDataItem.issues? "form-error" : "")}  
                                                disabled={this.isFormReadOnly()}
                                                type="checkbox"
                                                id={`q${index + 1}Yes`}
                                                label={"Yes"}
                                                checked={this.issues('YES')}
                                                onChange={(e) => this.onTextChange("issues", true, e)
                                                }
                                            /> 
                                        </Col>
                                        <Col md={3}> 
                                            <CustomInput
                                                className={(!validateDataItem.issues ? "form-error" : "")}  
                                                disabled={this.isFormReadOnly()}
                                                type="checkbox"
                                                id={`q${index + 1}No`}
                                                label={"No"} 
                                                checked={this.issues('NO')}
                                                onChange={(e) => this.onTextChange("issues", false, e)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>  
                            </Row>
                            <Row form style={{ marginBottom: "5px" }}>
                                <Col md={5}> 
                                    <Row form>
                                        <Col md={12}>
                                            <Label for="afeSponsor">Integrity Testing Completed</Label>
                                        </Col> 
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row form> 
                                        <Col md={3}> 
                                            <CustomInput
                                                className={(!validateDataItem.integrityTestingComplete ? "form-error" : "")}  
                                                disabled={this.isFormReadOnly()}
                                                type="checkbox"
                                                id={`q${index + 1}Yes2`}
                                                label={"Yes"}
                                                checked={this.integrityTestingCompleted('YES')}
                                                onChange={(e) => this.onTextChange("integrityTestingComplete",  true, e)
                                                }
                                            />
                                        </Col>
                                        <Col md={3}> 
                                            <CustomInput
                                                className={(!validateDataItem.integrityTestingComplete ? "form-error" : "")} 
                                                disabled={this.isFormReadOnly()}
                                                type="checkbox"
                                                id={`q${index + 1}No2`}
                                                label={"No"}
                                                checked={this.integrityTestingCompleted('NO')}
                                                onChange={(e) => this.onTextChange("integrityTestingComplete", false, e)
                                                }
                                            />
                                        </Col> 
                                    </Row>
                                </Col>  
                            </Row> 
                            <Row form style={{ marginBottom: "5px" }}> 
                                <Col md={12}> 
                                    <Row form>
                                        <Col md={5}>
                                            <Label for="afeSponsor">Inspectors Name</Label>
                                        </Col>
                                        <Col md={6}>
                                            <Input
                                                className={(!validateDataItem.inspectorName ? "form-error" : "")}  
                                                type="text" 
                                                name="createdBy"
                                                id="createdBy" 
                                                placeholder={"Enter Inspectors Name"}
                                                value={facilityReportDTO.inspectorName}
                                                onChange={(e) => this.onTextChange("inspectorName", e.target.value, e)}
                                                disabled={this.isFormReadOnly()}
                                            />
                                        </Col>
                                    </Row>
                                </Col>  
                            </Row> 
                        </AccordionItemPanel>
                        </AccordionItem> 
                        <AccordionItem hidden={facilityReportDTO == undefined || facilityReportDTO == null ||
                                                facilityReportDTO.constructionReportKey == undefined || facilityReportDTO.constructionReportKey == null  
                                                }> 
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Attachments"}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel> 
                                    <FileManager
                                            assetkey={facilityReportDTO.constructionReportFacilityKey > 0 ? facilityReportDTO.constructionReportFacilityKey : facilityReportDTO.blob_id}
                                            controlid={getRandomInt}
                                            folderPath={'constructionReport/' + constructionReportDTO.blob_id + '/DailyReportsDocuments/' + facilityReportDTO.blob_id} 
                                            linkTableName={'topConstructionReportFacility'}
                                            blobContainter={constructionReportDTO.blob_id}
                                        >
                                    </FileManager>
                                </AccordionItemPanel>
                        </AccordionItem>
                                
                        <AccordionItem hidden={facilityReportDTO.constructionReportKey == undefined || facilityReportDTO.constructionReportKey == null || 
                                        facilityReportDTO.constructionReportKey <=0 || facilityReportDTO.statusKey <= 0 ||
                                        facilityReportDTO.constructionReportFacilityKey == undefined ||  facilityReportDTO.constructionReportFacilityKey <= 0}>        
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <strong>{"Change Orders "}</strong>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ConstructionReportingChangeOrders
                                        constructionReportKey={facilityReportDTO.constructionReportKey}
                                        projectType={facilityReportDTO.projectType}
                                        user={this.props.user}
                                        constructionReportDTO={constructionReportDTO}
                                        reportKey={facilityReportDTO.constructionReportGeneralKey}
                                        dailyReportKey={this.props.constructionReportFacilityKey} 
                                        reportDate={facilityReportDTO.reportDate} 
                                    >  
                                    </ConstructionReportingChangeOrders> 
                                </AccordionItemPanel>
                        </AccordionItem>   
                    </Accordion>

                    
                     
                </Form>

            </div>)
    }

}
// return <AFEDetail props={props}/>
// })

export default DailyFacilityReport
