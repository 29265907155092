import React, { Component, useEffect, useReducer, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, UncontrolledTooltip, Alert } from 'reactstrap';
import classnames from 'classnames';

import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import TrashBin from '../resources/trash.svg'
import Excel from '../resources/excel.svg'
import Revise from '../resources/revise.png'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../resources/utility'
import ImageViewer from '../resources/ImageViewer';
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop';

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ComponentRenderer, ListSortDirection, ResponsivePhaseDescription } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import ConfirmModal from '../operation/ConfirmModal'
import ConfirmWithMsgModal from '../resources/ConfirmWithMsgModal'

import { EaseOfMovementIndicator } from 'igniteui-react-charts';
import { useSyncExternalStore } from 'react';
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



const SupplierRequestCRUD = (props) => {

    const { user, supplierCat, serviceTypes, entity } = props

    const [activeTab, setActiveTab] = useState('1')
    const [invalid, setInvalid] = useState(false)
    const supplierRequestKey = props.supplierRequestKey
    const selSupReqRec = props.selSupReqRec
    // const isSuplierRequestUser = props.isSuplierRequestUser
    const isSuplierRequestAdmin = props.isSuplierRequestAdmin

    const [offLineLinksupplierRequestKey, setOffLineKey] = useState((getRandomInt(10000) * -1))

    const iniReqData = {
        supplierRequestKey: props.supplierRequestKey,
        entity: (selSupReqRec === null ? '' : selSupReqRec.entity),
        supplierCategory: (selSupReqRec === null ? '' : selSupReqRec.supplierCategory),
        supplierName: (selSupReqRec === null ? '' : selSupReqRec.supplierName),
        contactName: (selSupReqRec === null ? '' : selSupReqRec.contactName),
        emailAddress: (selSupReqRec === null ? '' : selSupReqRec.emailAddress),
        phoneNo: (selSupReqRec === null ? '' : selSupReqRec.phoneNo),
        serviceType: (selSupReqRec === null ? null : selSupReqRec.serviceType),
        services: (selSupReqRec === null ? null : selSupReqRec.services),
        isOffSite: (selSupReqRec === null ? null : selSupReqRec.isOffSite),
        scopOfWork: (selSupReqRec === null ? '' : selSupReqRec.scopOfWork),
        comments: (selSupReqRec === null ? '' : selSupReqRec.comments),
        workdaySupplierID: (selSupReqRec === null ? '' : selSupReqRec.workdaySupplierID),
        statusKey: (selSupReqRec === null ? 0 : selSupReqRec.statusKey),
        serviceInfo: (selSupReqRec === null ? '' : selSupReqRec.serviceInfo)
    }

    const [reqData, setReqData] = useReducer((state, updates) => ({ ...state, ...updates }), iniReqData)

    const validDataItem = {
        entity: true,
        supplierCategory: true,
        supplierName: true,
        contactName: true,
        supplierName: true,
        phoneNo: true,
        emailAddress: true,
        scopOfWork: true,
        serviceType: true,
        services: true,
        isOffSite: true,
        serviceInfo: true,
        comments: true,
        workdaySupplierID: true
    }

    const [valItem, setValItem] = useReducer((state, updates) => ({ ...state, ...updates }), validDataItem)

    const closeForm = () => {
        props.toggle()
    }

    const getEntityString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.value
        })

        return grpKey;
    }

    const onTextChange = (fieldName, fieldValue, e) => {
        var updateReqData = reqData

        console.log(e)

        switch (fieldName) {
            case "supplierName":
                setReqData({ supplierName: fieldValue })
                setValItem({ supplierName: true })
                break;
            case "contactName":
                setReqData({ contactName: fieldValue })
                setValItem({ contactName: true })
                break;
            case "supplierCategory":
                if (e !== null && e !== undefined) {
                    setReqData({ supplierCategory: e.value })

                } else {
                    setReqData({ supplierCategory: "" })

                }
                setValItem({ supplierCategory: true })
                break;

            case "serviceTypes":
                if (e !== null && e !== undefined) {
                    setReqData({ serviceType: e.value })

                } else {
                    setReqData({ serviceType: null })

                }
                setValItem({ serviceType: true })
                break;

            // case "afeCostType":
            //     afeData[0].afeCostType = this.getAFETypeCostString(e)
            //     validateDataItem.afeCostType = true
            //     isForceBudgeLineUpdate = true
            //     budgetLineRefreshKey = Math.random()
            //     showAlert = false
            //     break;

            case "entity":
                if (e !== null && e !== undefined) {

                    setReqData({ entity: getEntityString(e) })
                } else {
                    setReqData({ entity: "" })
                }
                setValItem({ entity: true })
                break;
            case "phoneNo":
                setReqData({ phoneNo: fieldValue })
                setValItem({ phoneNo: true })
                break;
            case "emailAddress":
                setReqData({ emailAddress: fieldValue })
                setValItem({ emailAddress: true })
                break;
            case "scopOfWork":
                setReqData({ scopOfWork: fieldValue })
                setValItem({ scopOfWork: true })
                break;
            case "comments":
                setReqData({ comments: fieldValue })
                setValItem({ comments: true })
                break;
            case "workdaySupplierID":
                setReqData({ workdaySupplierID: fieldValue })
                setValItem({ workdaySupplierID: true })
                break;
            case "services":
                setReqData({ services: e.target.checked ? 1 : 0 })
                setValItem({ services: true })
                break;
            // case "materialGoods":
            //     setReqData({ serviceType: getServiceType(fieldValue, e.target.checked) })
            //     setValItem({ serviceType: true })
            //     break;
            case "isOffSite":
                // setReqData({ isOffSite: (e.target.checked ? 1 : 0) })
                setReqData({ isOffSite: (fieldValue ? 1 : 0) })
                setValItem({ isOffSite: true, serviceType: true })

                break;
            case "serviceInfo":
                setReqData({ serviceInfo: fieldValue })
                setValItem({ serviceInfo: true })
                break;
            default:
                break;
        }
        setInvalid(false)

    }

    // const getServiceType = (chkValue, isChecked) => {


    //     var serviceType = reqData.serviceType

    //     if (chkValue !== undefined && chkValue !== null) {
    //         // console.log("serviceType & chkValue")
    //         // console.log(serviceType & chkValue)

    //         if ((serviceType & chkValue) === chkValue) {
    //             if (!isChecked) {
    //                 serviceType = serviceType - chkValue
    //             }
    //         } else {
    //             if (isChecked) {
    //                 serviceType = serviceType + chkValue
    //             }
    //         }
    //     }
    //     return serviceType
    // }

    const getSelSupplierCat = (v) => {
        if (v !== null) {
            return supplierCat.filter(e => e.supplierReqCatDesc === v)
        }
        return ""
    }

    const getSelServiceTypes = (v) => {
        if (v !== null) {
            return serviceTypes.filter(e => e.serviceKey === v)
        }
        return ""
    }

    const getSelEntity = (v) => {
        if (v !== null) {
            //return entity.filter(e => e.supplierReqEntityDesc === v)
            return entity.filter(e => v.includes(e.supplierReqEntityDesc))


        }
        return ""
    }

    const getisReadOnly = () => {
        var bReturn = false
        // 0	Draft
        // 3	Requested
        // 4	In Progress
        // 5	Canceled
        // 6	Completed
        // 7	Rejected
        // 8	Pending Acct App
        // 9	Active in WD
        if (selSupReqRec) {

            //"In Progress"
            if (selSupReqRec.statusKey === 4 && !isSuplierRequestAdmin) {
                bReturn = true
            }
            //Canceled
            else if (selSupReqRec.statusKey === 5) {
                bReturn = true
            }
            //"Completed"
            else if (selSupReqRec.statusKey === 6) {
                bReturn = true
            }
            //"Pending Acct App"
            else if (selSupReqRec.statusKey === 8 && !isSuplierRequestAdmin) {
                bReturn = true
            }
            //"Active in WD"
            else if (selSupReqRec.statusKey === 9  && !isSuplierRequestAdmin) {
                bReturn = true
            }

            else bReturn = false




        }


        return bReturn
    }

    const dataValidationInProgress = () => {
        return new Promise((resolve, reject) => {
            const returnVal = {
                "ok": true,
                "message": "",
                "validDataItem": validDataItem
            }

            if (reqData.entity === null || reqData.entity === "") {
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Supplier Entity"
                returnVal.validDataItem.entity = false
            }

            if (reqData.supplierCategory === null || reqData.supplierCategory === "") {
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Supplier Category"

                returnVal.validDataItem.supplierCategory = false
            }

            if (reqData.supplierName === null || reqData.supplierName === "") {
                returnVal.ok = false
                returnVal.validDataItem.supplierName = false
            }

            if (reqData.contactName === null || reqData.contactName === "") {
                returnVal.ok = false
                returnVal.validDataItem.contactName = false
            }


            if (reqData.phoneNo === null || reqData.phoneNo === "") {
                returnVal.ok = false
                returnVal.validDataItem.phoneNo = false
            }

            if (reqData.emailAddress === null || reqData.emailAddress === "") {
                returnVal.ok = false
                returnVal.validDataItem.emailAddress = false
            }
            if (reqData.scopOfWork === null || reqData.scopOfWork === "") {
                returnVal.ok = false
                returnVal.validDataItem.scopOfWork = false
            }
            if (reqData.serviceType === null && (reqData.services === 0 || reqData.services === null)) {
                returnVal.ok = false
                returnVal.validDataItem.serviceType = false
            }
            if (reqData.isOffSite === null && (reqData.services === 1)) {
                returnVal.ok = false
                returnVal.validDataItem.isOffSite = false
            }
            if ((reqData.serviceInfo === null || reqData.serviceInfo === "") && (reqData.services === 1)) {
                returnVal.ok = false
                returnVal.validDataItem.serviceInfo = false
            }

            // if (reqData.comments === null || reqData.comments === "") {
            //     returnVal.ok = false
            //     returnVal.validDataItem.comments = false
            // }

            resolve(returnVal)
        })
    }

    const dataValidationSave = () => {
        return new Promise((resolve, reject) => {
            const returnVal = {
                "ok": true,
                "message": "",
                "validDataItem": validDataItem
            }

            if (reqData.supplierName === null || reqData.supplierName === "") {
                returnVal.ok = false
                returnVal.validDataItem.supplierName = false
            }



            resolve(returnVal)
        })
    }

    const dataValidationPendingAcctApp = () => {
        return new Promise((resolve, reject) => {
            const returnVal = {
                "ok": true,
                "message": "",
                "validDataItem": validDataItem
            }

            if (reqData.entity === null || reqData.entity === "") {
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Supplier Entity"
                returnVal.validDataItem.entity = false
            }

            if (reqData.supplierCategory === null || reqData.supplierCategory === "") {
                returnVal.ok = false
                if (returnVal.message !== "") {
                    returnVal.message = returnVal.message + ","
                }
                returnVal.message = returnVal.message + " Supplier Category"

                returnVal.validDataItem.supplierCategory = false
            }

            if (reqData.supplierName === null || reqData.supplierName === "") {
                returnVal.ok = false
                returnVal.validDataItem.supplierName = false
            }

            if (reqData.contactName === null || reqData.contactName === "") {
                returnVal.ok = false
                returnVal.validDataItem.contactName = false
            }


            if (reqData.phoneNo === null || reqData.phoneNo === "") {
                returnVal.ok = false
                returnVal.validDataItem.phoneNo = false
            }

            if (reqData.emailAddress === null || reqData.emailAddress === "") {
                returnVal.ok = false
                returnVal.validDataItem.emailAddress = false
            }
            if (reqData.scopOfWork === null || reqData.scopOfWork === "") {
                returnVal.ok = false
                returnVal.validDataItem.scopOfWork = false
            }
            if (reqData.serviceType === null && (reqData.services === 0 || reqData.services === null)) {
                returnVal.ok = false
                returnVal.validDataItem.serviceType = false
            }
            if (reqData.isOffSite === null && (reqData.services === 1)) {
                returnVal.ok = false
                returnVal.validDataItem.isOffSite = false
            }
            if ((reqData.serviceInfo === null || reqData.serviceInfo === "") && (reqData.services === 1)) {
                returnVal.ok = false
                returnVal.validDataItem.serviceInfo = false
            }

            if (reqData.comments === null || reqData.comments === "") {
                returnVal.ok = false
                returnVal.validDataItem.comments = false
            }

            if (reqData.workdaySupplierID === null || reqData.workdaySupplierID === "") {
                returnVal.ok = false
                returnVal.validDataItem.workdaySupplierID = false
            }


            resolve(returnVal)
        })
    }

    const dataValidationRejCancel = () => {
        return new Promise((resolve, reject) => {
            const returnVal = {
                "ok": true,
                "message": "",
                "validDataItem": validDataItem
            }



            if (reqData.comments === null || reqData.comments === "") {
                returnVal.ok = false
                returnVal.validDataItem.comments = false
            }




            resolve(returnVal)
        })
    }

    const supReqDTO = (status) => {
        const tempDTO = {
            "StatusKey": status,
            "Entity": reqData.entity,
            "SupplierName": reqData.supplierName,
            "SupplierCategory": reqData.supplierCategory,
            "ContactName": reqData.contactName,
            "PhoneNo": reqData.phoneNo,
            "EmailAddress": reqData.emailAddress,
            "ServiceType": reqData.serviceType,
            "Services": reqData.services,
            "IsOffSite": reqData.services === 1 ? reqData.isOffSite : null,
            "ScopOfWork": reqData.scopOfWork,
            "CreatedBy": getUserADAccount(user.account.userName),
            "OffLineLinkKey": offLineLinksupplierRequestKey,
            "ServiceInfo": reqData.services === 1 ? reqData.serviceInfo : '',
        }
        return tempDTO
    }

    const addSupplierReq = (status = 0) => {
        const addSupReqDTO = supReqDTO(status)
        console.log(addSupReqDTO)
        let myURI = apiURL + 'AP/SupplierRequest/Add'

        fetch(myURI, apiPOSTHeader(addSupReqDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    // rspData.then(t => {
                    //     this.setState({
                    //         msgBody: t,
                    //         msgHeader: "Error",
                    //         modalMsg: !this.state.modalMsg
                    //     })
                    // })

                    return Promise.reject(error)
                } else
                    closeForm()
            }).catch(error => {
                console.log(error)
            })
    }

    const uSupReqDTO = (status) => {
        const tempDTO = {
            "SupplierRequestKey": supplierRequestKey,
            "StatusKey": (status === null ? reqData.statusKey : status),
            "Entity": reqData.entity,
            "SupplierName": reqData.supplierName,
            "SupplierCategory": reqData.supplierCategory,
            "ContactName": reqData.contactName,
            "PhoneNo": reqData.phoneNo,
            "EmailAddress": reqData.emailAddress,
            "ServiceType": reqData.serviceType,
            "Services": reqData.services,
            "IsOffSite": reqData.services === 1 ? reqData.isOffSite : null,
            "ScopOfWork": reqData.scopOfWork,
            "modifiedBy": getUserADAccount(user.account.userName),
            "workdaySupplierID": reqData.workdaySupplierID,
            "comments": reqData.comments,
            "ServiceInfo": reqData.services === 1 ? reqData.serviceInfo : '',
        }
        return tempDTO
    }

    const updateSupplierReq = (status) => {
        const updateSupReqDTO = uSupReqDTO(status)
        console.log(updateSupReqDTO)
        let myURI = apiURL + 'AP/SupplierRequest/Update'

        fetch(myURI, apiPOSTHeader(updateSupReqDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    // rspData.then(t => {
                    //     this.setState({
                    //         msgBody: t,
                    //         msgHeader: "Error",
                    //         modalMsg: !this.state.modalMsg
                    //     })
                    // })

                    return Promise.reject(error)
                } else
                    closeForm()
            }).catch(error => {
                console.log(error)
            })
    }

    // const dSupReqDTO = (status) => {
    //     const tempDTO = {
    //         "SupplierRequestKey": supplierRequestKey,           
    //         "modifiedBy": getUserADAccount(user.account.userName)
    //     }
    //     return tempDTO
    // }

    // const deleteSupplierReq = () => {
    //     const deleteSupReqDTO = dSupReqDTO()
    //     console.log(deleteSupReqDTO)
    //     let myURI = apiURL + 'AP/SupplierRequest/Delete'

    //     fetch(myURI, apiPOSTHeader(deleteSupReqDTO))
    //         .then(async response => {
    //             if (!response.ok) {
    //                 const rspData = response.text();
    //                 var error = (rspData && rspData.message) || response.status;

    //                 // rspData.then(t => {
    //                 //     this.setState({
    //                 //         msgBody: t,
    //                 //         msgHeader: "Error",
    //                 //         modalMsg: !this.state.modalMsg
    //                 //     })
    //                 // })

    //                 return Promise.reject(error)
    //             } else
    //                 closeForm()
    //         }).catch(error => {
    //             console.log(error)
    //         })
    // }

    const deleteSuReq = () => {
        props.deleteSupReq(true)
    }







    const saveData = (status = -1) => {
        console.log("reqData")
        console.log(reqData)
        try {
            // 0	Draft
            // 3	Requested
            // 4	In Progress
            // 5	Canceled
            // 6	Completed
            // 7	Rejected
            // 8	Pending Acct App
            // 9	Active in WD
            setInvalid(false)

            //new request - Save for Later (only Supplier Name reqd)
            if (status === -1) {
                dataValidationSave().then((data) => {
                    if (data.ok) {
                        if (supplierRequestKey < 0) {
                            addSupplierReq((status < 0 ? 0 : status))
                        } else {
                            //Update Supplier Request
                            updateSupplierReq((status < 0 ? reqData.statusKey : status))
                        }

                    } else {
                        setValItem(data.validDataItem)
                        setInvalid(true)
                    }
                })
            }

            //Draft - Submit - In Progress (everything except Vendor #)
            if (status === 4) {
                dataValidationInProgress().then((data) => {
                    if (data.ok) {
                        if (supplierRequestKey < 0) {
                            addSupplierReq((status < 0 ? 0 : status))
                        } else {
                            //Update Supplier Request
                            updateSupplierReq((status < 0 ? reqData.statusKey : status))
                        }

                    } else {
                        setValItem(data.validDataItem)
                        setInvalid(true)
                    }
                })
            }

            //In Progress - Cancel
            //In Progress - Reject
            if (status === 5 || status === 7) {
                dataValidationRejCancel().then((data) => {
                    if (data.ok) {
                        updateSupplierReq((status < 0 ? reqData.statusKey : status))
                    } else {
                        setValItem(data.validDataItem)
                        setInvalid(true)
                    }
                })
            }


            //In Progress - Submit to Acct - Pending Acct App (everything)
            //Pending Acct App - Complete
            if (status === 8 || status === 6) {
                dataValidationPendingAcctApp().then((data) => {
                    if (data.ok) {
                        updateSupplierReq((status < 0 ? reqData.statusKey : status))
                    } else {
                        setValItem(data.validDataItem)
                        setInvalid(true)
                    }
                })
            }




        } catch (error) {

        }
    }


    return (<div>
        <FormGroup>
            <FormGroup>
                <Row>
                    <Col sm={12}>
                        <Alert color="danger"
                            //toggle={() => this.toggleAlert()}
                            isOpen={invalid}
                        // style={{ marginBottom: "5px", marginTop: "5px" }}
                        >
                            Please fill all the requied fields that are marked red
                        </Alert >
                    </Col>
                </Row>

            </FormGroup>
            <FormGroup>
                <Row form>
                    <Col>


                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="typeKey">WaterBridge Entity</Label>
                            </Col>

                            <Col md={8} className={"alway-in-between-more"}>
                                <Select
                                    options={entity}
                                    className={(!valItem.entity ? "form-error" : "")}
                                    //isDisabled={selSupReqRec ? selSupReqRec.statusDesc === "Completed" : false}
                                    isDisabled={getisReadOnly()}


                                    // className={(!validateDataItem.typeKey ? "form-error" : "")}
                                    //value={getSelEntity(reqData.entity)}
                                    defaultValue={getSelEntity(reqData.entity)}
                                    onChange={(e) => onTextChange("entity", e, e)}
                                    isClearable={true}
                                    isMulti={true}

                                >
                                </Select>

                            </Col>

                        </Row>

                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="typeKey">Supplier Category</Label>
                            </Col>

                            <Col md={8} className={"alway-in-between2"}>
                                <Select
                                    options={supplierCat}
                                    // isDisabled={this.isFormReadOnly() || statusKey === 4}
                                    //isDisabled={selSupReqRec ? selSupReqRec.statusDesc === "Completed" : false}
                                    isDisabled={getisReadOnly()}
                                    className={(!valItem.supplierCategory ? "form-error" : "")}
                                    // value={this.getSelAFEType(afeData[0].typeKey)}
                                    defaultValue={getSelSupplierCat(reqData.supplierCategory)}
                                    onChange={(e) => onTextChange("supplierCategory", e, e)}
                                    isClearable={true}

                                >
                                </Select>
                            </Col>

                        </Row>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="supplierName">Supplier Name</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="supplierName"
                                    rows={8}
                                    invalid={!valItem.supplierName}
                                    //disabled={selSupReqRec ? selSupReqRec.statusDesc === "Completed" : false}
                                    disabled={getisReadOnly()}
                                    //disabled={this.isFormReadOnly()}
                                    id="supplierName" placeholder="Supplier Name"
                                    value={reqData.supplierName}
                                    //defaultValue={afeData[0].afeDescription}
                                    onChange={(e) => onTextChange("supplierName", e.target.value, e)}
                                />
                                {/* <FormFeedback>Please select supplier name</FormFeedback> */}
                            </Col>

                        </Row>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="contactName">Supplier Contact Name</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="contactName"
                                    rows={8}
                                    invalid={!valItem.contactName}
                                    //disabled={this.isFormReadOnly()}

                                    disabled={getisReadOnly()}
                                    id="contactName" placeholder="Contact Name"
                                    value={reqData.contactName}
                                    //defaultValue={afeData[0].afeDescription}
                                    onChange={(e) => onTextChange("contactName", e.target.value, e)}
                                />
                            </Col>

                        </Row>

                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="phoneNo">Supplier Phone Number</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="phoneNo"
                                    rows={8}
                                    invalid={!valItem.phoneNo}
                                    //disabled={this.isFormReadOnly()}
                                    disabled={getisReadOnly()}
                                    id="phoneNo" placeholder="Phone Number"
                                    value={reqData.phoneNo}
                                    //defaultValue={afeData[0].afeDescription}
                                    onChange={(e) => onTextChange("phoneNo", e.target.value, e)}
                                />
                            </Col>

                        </Row>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="emailAddress">Supplier Email</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="emailAddress"
                                    rows={8}
                                    invalid={!valItem.emailAddress}
                                  
                                    disabled={getisReadOnly()}
                                    id="emailAddress" placeholder="Email Address"
                                    value={reqData.emailAddress}
                                    //defaultValue={afeData[0].afeDescription}
                                    onChange={(e) => onTextChange("emailAddress", e.target.value, e)}
                                />
                            </Col>

                        </Row>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="serviceKey">Service Type</Label>
                            </Col>

                            <Col md={4} className={"alway-in-between"}>
                                <Select
                                    options={serviceTypes}
                                    // isDisabled={this.isFormReadOnly() || statusKey === 4}
                                  
                                    isDisabled={getisReadOnly()}
                                    className={(!valItem.serviceType ? "form-error" : "")}
                                    // value={this.getSelAFEType(afeData[0].typeKey)}
                                    defaultValue={getSelServiceTypes(reqData.serviceType)}
                                    onChange={(e) => onTextChange("serviceTypes", e, e)}
                                    isClearable={true}

                                >
                                </Select>
                            </Col>
                            <Col md={4}>
                                <Label
                                    style={{ marginLeft: "20px" }}
                                    className={(!valItem.services ? "form-error" : "")}>
                                    <Input type="checkbox" name="serviceType"
                                        disabled={getisReadOnly()}
                                        id="serviceType"
                                        placeholder="Services"
                                        checked={reqData.services === 1}
                                        onChange={(e) => onTextChange("services", 1, e)}
                                    />{''}
                                    Services
                                </Label>

                            </Col>

                        </Row>

                        {/* <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="serviceProvide">Service Type</Label>
                            </Col>

                           <Col md={3}>
                                <Label
                                    style={{ marginLeft: "20px" }}
                                    className={(!valItem.serviceType ? "form-error" : "")}>
                                    <Input type="checkbox" name="serviceType"
                                        //disabled={this.isFormReadOnly()}
                                        disabled={getisReadOnly()}
                                        id="serviceType" placeholder="Material Goods"
                                        checked={(reqData.serviceType & 1) === 1}
                                        onChange={(e) => onTextChange("materialGoods", 1, e)}
                                    />{''}
                                    Material/Goods
                                </Label>
                            </Col>
                            <Col md={3}>
                                <Label
                                    style={{ marginLeft: "20px" }}
                                    className={(!valItem.serviceType ? "form-error" : "")}>
                                    <Input type="checkbox" name="serviceType"
                                        disabled={getisReadOnly()}
                                        id="serviceType"
                                        placeholder="Services"
                                        checked={(reqData.serviceType & 2) === 2}
                                        onChange={(e) => onTextChange("services", 2, e)}
                                    />{''}
                                    Services
                                </Label>
                            </Col>
                        </Row> */}



                        <Row form style={{ marginBottom: "5px" }} hidden={(reqData.services!= 1)}>
                            {/* <Row form style={{ marginBottom: "5px" }} > */}
                            <Col md={4}>

                            </Col>


                            <Col md={4}>
                                <Label
                                    className={(!valItem.isOffSite ? "form-error" : "")}
                                    for="isOffSite">Location Of Services</Label>
                            </Col>

                            <Col md={2}>
                                <CustomInput
                                    type="checkbox"
                                    
                                    disabled={getisReadOnly()}
                                    id="isOffSiteY"
                                    label="On Site"
                                    // checked={reqData.isOffSite === 1}
                                    //checked={(reqData.serviceType & 2) === 2 ? reqData.isOffSite === 1 : false}
                                    checked={reqData.isOffSite === 1}
                                    inline
                                    // disabled={isReadOnly}
                                    // onChange={(e) => this.onTextChange("isContractor", 1, e)}
                                    onChange={(e) => onTextChange("isOffSite", 1, e)}
                                />
                            </Col>
                            <Col md={2}>
                                <CustomInput type="checkbox"
                                    id="isOffSiteN"
                                    label="Off Site" inline
                                    
                                    disabled={getisReadOnly()}
                                    //checked={(reqData.serviceType & 2) === 2 ? reqData.isOffSite === 0 : false}
                                    checked={reqData.isOffSite === 0}
                                    // onChange={(e) => this.onTextChange("isContractor", 0, e)}
                                    onChange={(e) => onTextChange("isOffSite", 0, e)}
                                // disabled={isReadOnly}

                                />
                            </Col>

                        </Row>

                        <Row form style={{ marginBottom: "5px" }} hidden={reqData.services != 1}>
                            {/* <Row form style={{ marginBottom: "5px" }}> */}
                            <Col md={4}>

                            </Col>

                            <Col md={3}>

                                <Label
                                    for="serviceInfo">Project Start Time</Label>
                            </Col>
                            <Col md={5}>
                                <Input type="text" name="serviceInfo"
                                    //rows={8}
                                    invalid={!valItem.serviceInfo}


                                    disabled={getisReadOnly()}
                                    id="serviceInfo" placeholder="Project Start Time"
                                    value={reqData.serviceInfo}
                                    //defaultValue={afeData[0].afeDescription}
                                    onChange={(e) => onTextChange("serviceInfo", e.target.value, e)}
                                />
                            </Col>




                        </Row>


                        {/* <Row form style={{ marginBottom: "5px" }}>
                <Col md={4}>
                    <Label for="scopOfWork">Scope Of Work</Label>
                </Col>

                <Col md={8}>
                    <Input type="textarea" name="scopOfWork"
                        rows={8}
                        //className={(!validateDataItem.afeDescription ? "form-error" : "")}
                        //disabled={this.isFormReadOnly()}
                        id="scopOfWork" placeholder="Scope Of Work"
                        //value={afeData[0].afeDescription}
                        //defaultValue={afeData[0].afeDescription}
                        onChange={(e) => this.onTextChange("scopOfWork", e.target.value, e)}
                    />
                </Col>

            </Row> */}



                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { setActiveTab('1'); }}
                                    >
                                        Scope of Work
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { setActiveTab('2'); }}
                                    >
                                        Documents
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row form style={{ marginBottom: "5px" }}>
                                        <Col md={12}>
                                            <Input type="textarea" name="scopOfWork"
                                                rows={5}
                                                disabled={getisReadOnly()}
                                                //className={(!validateDataItem.afeDescription ? "form-error" : "")}
                                                invalid={!valItem.scopOfWork}
                                                id="scopOfWork" placeholder="Scope Of Work"
                                                value={reqData.scopOfWork}
                                                //defaultValue={afeData[0].afeDescription}
                                                onChange={(e) => onTextChange("scopOfWork", e.target.value, e)}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row style={{ height: "140px" }}>
                                        <Col md={12}>
                                            <DocumentBrowserDragDrop buttonLabel="View Image"
                                                buttonColor="primary"
                                                formName="Supplier Request Documents"
                                                linkTableName={"tpoSupplierRequest"}
                                                linkPrimaryKey={(supplierRequestKey < 0 ? offLineLinksupplierRequestKey : supplierRequestKey)}
                                                apiURL={apiURL + 'AP/SupplierRequest/Documents/' + (supplierRequestKey < 0 ? offLineLinksupplierRequestKey : supplierRequestKey)}
                                            />

                                        </Col>
                                    </Row>
                                </TabPane>

                            </TabContent>
                        </div>
                    </Col>
                    <Col md={5} hidden={(supplierRequestKey < 0)}>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="requestNo">Request No</Label>
                            </Col>

                            <Col md={4}>
                                <Input type="text" name="requestNo"
                                    rows={8}
                                    //invalid={!valItem.phoneNo}
                                    disabled
                                    id="requestNo" placeholder="Request No"
                                    value={(selSupReqRec === null ? '' : selSupReqRec.supplierReqID)}
                                //defaultValue={afeData[0].afeDescription}
                                //onChange={(e) => onTextChange("phoneNo", e.target.value, e)}
                                />
                            </Col>
                            <Col md={4}>
                                <Input type="text" name="status"
                                    //invalid={!valItem.phoneNo}
                                    disabled
                                    id="requestNo" placeholder="Request No"
                                    value={(selSupReqRec === null ? '' : selSupReqRec.statusDesc)}
                                //defaultValue={afeData[0].afeDescription}
                                //onChange={(e) => onTextChange("phoneNo", e.target.value, e)}
                                />
                            </Col>

                        </Row>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="requestedBy">Requested By</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="requestedBy"
                                    rows={8}
                                    //invalid={!valItem.emailAddress}
                                    disabled
                                    id="requestedBy" placeholder="Requested By"
                                    value={(selSupReqRec === null ? '' : selSupReqRec.createdByName)}
                                />
                            </Col>

                        </Row>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="requestedDate">Requested Date</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="requestedDate"
                                    rows={8}
                                    //invalid={!valItem.emailAddress}
                                    disabled
                                    id="requestedDate" placeholder="Requested By"
                                    //new Date(dateStr).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
                                    //value={(selSupReqRec === null ? '' : selSupReqRec.createdDatetime)}
                                    value={(selSupReqRec === null ? '' : new Date(selSupReqRec.createdDatetime).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'medium' }))}
                                />
                            </Col>

                        </Row>


                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="modifiedBy">Modified By</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="modifiedBy"
                                    rows={8}
                                    //invalid={!valItem.emailAddress}
                                    disabled
                                    id="modifiedBy" placeholder="Modified By"
                                    value={(selSupReqRec === null ? '' : selSupReqRec.modifiedByName)}
                                />
                            </Col>

                        </Row>
                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="modifiedDate">Modified Date</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="modifiedDate"
                                    rows={8}
                                    //invalid={!valItem.emailAddress}
                                    disabled
                                    id="modifiedDate" placeholder="Modified Date"
                                    value={(selSupReqRec === null ? '' : 
                                           selSupReqRec.modifiedDateTime === null ? '' :  /*Added by HN 04-11/2023 to show correct date when modified date is null */
                                           new Date(selSupReqRec.modifiedDateTime).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'medium' }))}
                                />
                            </Col>

                        </Row>

                        <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="wdSuplierID">WD SupplierID</Label>
                            </Col>

                            <Col md={8}>
                                <Input type="text" name="wdSuplierID"
                                    rows={8}
                                    invalid={!valItem.workdaySupplierID}
                                    disabled={getisReadOnly() || !isSuplierRequestAdmin}
                                    //disabled={this.isFormReadOnly()}
                                    id="emailAddress" placeholder="Workday Supplier ID"
                                    value={reqData.workdaySupplierID}
                                    //defaultValue={afeData[0].afeDescription}
                                    onChange={(e) => onTextChange("workdaySupplierID", e.target.value, e)}
                                />
                            </Col>

                        </Row>


                        {/* <Row form style={{ marginBottom: "5px" }}>
                            <Col md={4}>
                                <Label for="wdSuplierID"> </Label>
                            </Col>

                            <Col md={8}>

                            </Col>

                        </Row> */}

                        <Row form style={{ marginBottom: "10px" }}>
                            <Col md={4}>
                                <Label for="comments">Comments</Label>
                            </Col>

                            {/* <Col md={8}>
                                
                            </Col> */}

                            {/* </Row>
                        <Row form style={{ marginBottom: "5px" }}> */}
                            <Col md={8}>
                                <Input type="textarea" name="comment"
                                    rows={8}
                                    disabled={getisReadOnly() || !isSuplierRequestAdmin}
                                    invalid={!valItem.comments}

                                    id="comments" placeholder="Comments"
                                    value={reqData.comments}
                                    //defaultValue={afeData[0].afeDescription}
                                    onChange={(e) => onTextChange("comments", e.target.value, e)}
                                />
                            </Col>

                        </Row>

                    </Col>

                </Row>
            </FormGroup>


            <FormGroup>
                <Row>

                    <Col sm={3}>

                        <ConfirmModal outline={true}
                            color="danger"
                            className="float-left"
                            buttonLabel="Delete"
                            hidden={(supplierRequestKey <= -1 || !(reqData.statusKey === 0))}
                            formName="Confirm"
                            id={supplierRequestKey}
                            message="Are you sure you want to delete selected supplier request?"
                            onClickYes={(e) => deleteSuReq()}
                            image={<img
                                src={TrashBin}
                                id={supplierRequestKey}
                                alt="Edit"
                                style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                        </ConfirmModal>
                        <ConfirmModal outline={true}
                            color="danger"
                            className="float-left"
                            buttonLabel={"Cancel"}
                            hidden={(supplierRequestKey <= -1 || (reqData.statusKey !== 4 && reqData.statusKey !== 7 && reqData.statusKey !== 8))}
                            formName="Confirm"
                            id={supplierRequestKey}
                            message={"Are you sure you want to cancel this Supplier Request?"}
                            onClickYes={(e) => saveData(5)}
                        >

                        </ConfirmModal>



                    </Col>


                    {/* <Col md={2}>
                        


                    </Col> */}

                    <Col sm={9}>
                        <Button color="primary" className="float-right"
                            onClick={() => closeForm(false)}>Close</Button>


                        <Button color="primary" className="float-right"
                            
                            hidden={getisReadOnly()}
                            style={{ margin: "0px 10px 0px 0px" }}
                            onClick={() => saveData()}>Save for Later</Button>

                        {/* <ConfirmModal outline={true}
                            color="success"
                            className="float-left"
                            buttonLabel={((reqData.statusKey === 0  || reqData.statusKey === 5) ? "Submit" : "Completed")}
                            hidden={(reqData.statusKey !== 0 && reqData.statusKey !== 4 && reqData.statusKey !== 5)}
                            formName="Confirm"
                            id={supplierRequestKey}
                            message={"Are you sure you want to " + ((reqData.statusKey === 0 || reqData.statusKey === 5) ? "Submit" : "Completed") + " supplier request?"}
                            onClickYes={(e) => saveData((reqData.statusKey === 0 ? 4 : 6))}
                        >
                        </ConfirmModal> */}

                        <ConfirmModal outline={true}
                            color="success"
                            className="float-right"
                            buttonLabel="Submit"
                            hidden={(reqData.statusKey !== 0 && reqData.statusKey !== 7)}
                            style={{ margin: "0px 10px 0px 0px" }}
                            formName="Confirm"
                            id={supplierRequestKey}
                            message={"Are you sure you want to Submit supplier request?"}
                            onClickYes={(e) => saveData(4)}
                        >
                        </ConfirmModal>

                        <ConfirmModal outline={true}
                            color="success"
                            className="float-right"
                            buttonLabel="Submit to Acct"
                            hidden={(reqData.statusKey !== 4 || !isSuplierRequestAdmin)}
                            style={{ margin: "0px 10px 0px 0px" }}
                            formName="Confirm"
                            id={supplierRequestKey}
                            message={"Are you sure you want to Submit to accounting?"}
                            onClickYes={(e) => saveData(8)}
                        >
                        </ConfirmModal>


                        <ConfirmModal outline={true}
                            color="danger"
                            className="float-right"
                            buttonLabel="Reject"
                            hidden={((reqData.statusKey !== 4 && reqData.statusKey !== 8 )|| !isSuplierRequestAdmin)}
                            style={{ margin: "0px 10px 0px 0px" }}
                            formName="Confirm"
                            id={supplierRequestKey}
                            message={"Are you sure you want to Reject supplier request?"}
                            onClickYes={(e) => saveData(7)}
                        >
                        </ConfirmModal>

                        <ConfirmModal outline={true}
                            color="success"
                            className="float-right"
                            buttonLabel="Complete"
                            hidden={(reqData.statusKey !== 9 || !isSuplierRequestAdmin)}
                            style={{ margin: "0px 10px 0px 0px" }}
                            formName="Confirm"
                            id={supplierRequestKey}
                            message={"Are you sure you want to Complete supplier request?"}
                            onClickYes={(e) => saveData(6)}
                        >
                        </ConfirmModal>


                    </Col>
                </Row>
            </FormGroup>


        </FormGroup>

    </div>)
}
export default SupplierRequestCRUD;


