
import React, { Component } from 'react';

import { Form, Card, CardHeader, CardBody, CustomInput, Col, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import { apiURL, apiHeader } from '../../resources/apiURL';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';


import '../../custom.css';

import { getUserADAccount } from '../../resources/utility'
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";

import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import classnames from 'classnames';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// import iconplus from '../resources/plus.svg'
// import icontrash from '../resources/trash.svg'

// var DatePicker = require("reactstrap-date-picker");

//import ConfirmModal from './ConfirmModal'



export default class FormSpillReport extends Component {
  static displayName = FormSpillReport.name;


  constructor(props) {
    super(props);
    // this.onGridRef = this.onGridRef.bind(this);
    // this.getAlert=this.getAlert.bind(this);


    // const urlPara = this.getURLParameters(props.location)
    console.log("loc search")
    // console.log(this.props.location.search)

    this.state = {
      childValue: '',
      data: [],
      hdrData: [],
      isLoaded: false,
      isHdrLoaded: false,
      // facilityInspLineItemForUpdateDTO:[],
      // facilityComments:'',
      // inspComments:'',
      reloadForm: false,
      safIncHeaderKey: 0,
      // safIncLineKey: urlPara.safIncLineKey,
      safIncLineKey: props.safIncLineKey,
      ddEmployees: [],
      activeTab: '1',
      isddEmployeesLoaded: false,
      isReadOnly: props.isReadOnly,

      isddCountyLoaded: false,
      ddCounty: [],
      docCount:[],
      modalMsg: false,
            msgHeader: "",
            msgBody: "",



      // user:props.user
    }
  }

  // getURLParameters = (inputPara) => {
  //   var paraMeters = {
  //     safIncLineKey: null
  //   }

  //   var query
  //   query = new URLSearchParams(this.props.location.search);
  //   console.log("query")
  //   // console.log(query.get('safIncLineKey'))

  //   paraMeters = {
  //     safIncLineKey: query.get('safIncLineKey')
  //   }
  //   return paraMeters
  // }

  toggleMsg = () => {
    this.setState({ modalMsg: !this.state.modalMsg })
}


  getddCounty = () => {
    const { user } = this.props

    //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

    let myURI = apiURL + 'AFE/County'

    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isddCountyLoaded: true,
          ddCounty: json
        })
      });
  }

  createCountyList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.countyKey
      e.label = e.countyDesc
      tempList.push(e)
    })

    return tempList
  }

  getSelCounty = (v) => {
    const { ddCounty } = this.state
    if (v !== null && v !== "" && !isNaN(v)) {
      return ddCounty.filter(e => e.countyKey === Number(v));
    }
    return ""

  }




  bMultiField = (value, checkValue) => {
    return (value & checkValue) === checkValue
  }


  getInspHdrData = (intKey) => {
    const { isLoaded } = this.state

    console.log("safIncHeaderKey")
    console.log(intKey)

    let myURI = apiURL + 'SafetyIncident/SafIncHeaderKey/' + intKey
    fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        this.setState({
          hdrData: json,
          isHdrLoaded: true
        });
        console.log(this.state.hdrData);
      });

  }

  getInpsData = () => {
    const { safIncLineKey, isLoaded } = this.state
    let myURI = apiURL + 'SafetyIncident/FormSpillReportKey/' + safIncLineKey

    fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        if (json.length > 0) {
          this.getInspHdrData(json[0].safIncHeaderKey)

        }

        this.setState({
          data: json,
          isLoaded: true,


          //safIncHeaderKey:json[0].safIncHeaderKey
          // inspComments:json[0].inspComments,
          // facInspComments:json[0].facInspComments
        }
        );


        console.log("---------")
        console.log(this.state.data)

        // this.getInspHdrData(json[0].safIncHeaderKey)


      });
  }


  getddEmployees = () => {
    const { user } = this.props

    //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

    let myURI = apiURL + 'SafetyObservation/SubmitBy'

    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isddEmployeesLoaded: true,
          ddEmployees: json
        })
      });
  }





  // onGridRef(grid) {
  //   if (!grid) {
  //     return;
  //   }
  //   this.grid = grid;
  //   this.grid.dataSource = this.state.tblWitness;

  //   //this.grid.cells[0]
  //   console.log(this.grid.cellBackground)

  // }
  getSpillDocCount = () => {
    const {safIncLineKey} = this.state

    let myURI = apiURL + 'SafetyIncident/Documents/SpillDocCount/' + safIncLineKey

    console.log(myURI);

    fetch(myURI, { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
            this.setState({
                docCount: json
                //,isDataLoaded:true
            })

            // console.log("json")
            // console.log(json)
        });
}



  componentDidMount() {
    this.props.saveSpill(this.saveData);
    this.getInpsData()
    this.getddEmployees()
    this.getddCounty()
    this.getSpillDocCount()
    //this.getInspHdrData()

  }


  onStatusCellUpdating(s, e) {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
    // content.style.backgroundColor = "#4286f4";

    // console.log("item.statusKey")
    // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
    let link;
    if (content.childElementCount === 0) {
      link = document.createElement("label");
      let cell = document.createElement("div");

      content.style.fontFamily = "Verdana";
      content.style.fontSize = "13px";

      content.style.margin = "0px -20px -10px -12px";
      content.style.padding = "0px 10px 10px 10px";
      //content.style.width = "100%";

      content.appendChild(link);
    }
    else {
      link = content.children[0];

      if (item.lineStatusKey === 4) {
        content.style.background = "#b6a065";
      } else if (item.lineStatusKey === 6) {
        content.style.background = "#61a066";
      } else if (item.lineStatusKey === 3 || item.lineStatusKey === 5) {
        content.style.background = "#ad5f55";
      } else {
        content.style.background = "transparent";
      }
    }


    switch (s.field) {
      case 'name':
        link.textContent = item.name;
        break;
      case 'position':
        link.textContent = item.position;
        break;


      default:

    }

  }







  onTextChange = (fieldName, fieldValue, e) => {
    console.log("props")
    console.log(fieldName + '=' + fieldValue)
    const { data } = this.state;

    // /cbincEquipmentDamage
    //incClassif

    switch (fieldName) {




      case "division":
        data[0].division = fieldValue
        break;

      case "county":
        // data[0].countyKey = fieldValue.value
        // data[0].state = fieldValue.stateID

        if (fieldValue === null) {
          // this.setState({ employee: -1, employeeRec: "" });
          data[0].countyKey = null
          data[0].state = ""

        }
        else {
          data[0].countyKey = fieldValue.value
          data[0].state = fieldValue.stateID

        }


        break;

      // case "state":
      //   data[0].state = fieldValue
      //   break;

      case "generalLocation":
        data[0].generalLocation = fieldValue
        break;
      case "latitude":
        data[0].latitude = fieldValue
        break;
      case "longitude":
        data[0].longitude = fieldValue
        break;

      case "section":
        data[0].section = fieldValue
        break;

      case "twp":
        data[0].twp = fieldValue
        break;

      case "range":
        data[0].range = fieldValue
        break;
      case "block":
        data[0].block = fieldValue
        break;

      case "survey":
        data[0].survey = fieldValue
        break;

      case "other":
        data[0].other = fieldValue
        break;

      case "pipelineFacility":
        data[0].pipelineFacility = fieldValue
        break;

      case "discoveredbyName":
        data[0].discoveredbyName = fieldValue
        break;

      case "discoveredDateTime":
        data[0].discoveredDateTime = fieldValue;
        break;

      case "reportedTo":
        data[0].reportedTo = fieldValue
        break;

      case "reportedToDateTime":
        data[0].reportedToDateTime = fieldValue;
        break;

      case "reportedBy":
        data[0].reportedBy = fieldValue
        break;

      case "reportedByDateTime":
        data[0].reportedByDateTime = fieldValue;
        break;

      case "personMakingReport":
        data[0].personMakingReport = fieldValue
        break;

        case "containedDateTime":
          data[0].containedDateTime = fieldValue;
          break;

          case "leaseName":
            data[0].leaseName = fieldValue;
            break;

      case "reporttoHSEDateTime":
        data[0].reporttoHSEDateTime = fieldValue;
        break;

      case "drivingDirections":
        data[0].drivingDirections = fieldValue
        break;

      case "landowner":
        data[0].landowner = fieldValue
        break;

      case "telephoneNumber":
        data[0].telephoneNumber = fieldValue
        break;

      case "quantityReleasedBarrels":
        data[0].quantityReleasedBarrels = fieldValue
        break;

      case "quantityRecoveredBarrels":
        data[0].quantityRecoveredBarrels = fieldValue
        break;

      case "fireExplosionorInjury":
        data[0].fireExplosionorInjury = fieldValue
        break;

      case "didSpillReachWater":
        if (fieldValue === data[0].didSpillReachWater) {
          data[0].didSpillReachWater = null
        }
        else { data[0].didSpillReachWater = fieldValue }
      break;

      case "isSpillReportable":
        if (fieldValue === data[0].isSpillReportable) {
          data[0].isSpillReportable = null
        }
        else { data[0].isSpillReportable = fieldValue }
      break;

      case "spilltoLand":
        if (fieldValue === data[0].spilltoLand) {
          data[0].spilltoLand = null
        }
        else { data[0].spilltoLand = fieldValue }
      break;

      case "fluidType":
        data[0].fluidType = fieldValue
        break;
      
      case "commonName":
          data[0].commonName = fieldValue
       break;
       case "agencyNotified":
         data[0].agencyNotified = fieldValue
      break;
      case "directionofSpillPath":
         data[0].directionofSpillPath = fieldValue
      break;
      case "spilltoLandDescription":
         data[0].spilltoLandDescription = fieldValue
      break;
      case "sourceofSpill":
             data[0].sourceofSpill = fieldValue
      break;
      case "actionsTaken":
            data[0].actionsTaken = fieldValue
      break;




      case "identifyWaterImpacted":
        data[0].identifyWaterImpacted = fieldValue
        break;

      case "dimensionsofSpillSite":
        data[0].dimensionsofSpillSite = fieldValue
        break;

      case "depthofContamination":
        data[0].depthofContamination = fieldValue
        break;

      case "causeofSpill":
        if (this.bMultiField(data[0].causeofSpill, fieldValue)) {
          data[0].causeofSpill = data[0].causeofSpill - fieldValue
        } else {
          data[0].causeofSpill = data[0].causeofSpill + fieldValue
        }
        //validateDataItem.riskInvolvePeopleValue=true
        break;


      case "causeofSpillOther":
        data[0].causeofSpillOther = fieldValue
        break;

      case "causeofSpillTank":
        data[0].causeofSpillTank = fieldValue
        break;

      case "causeofSpillTankNumber":
        data[0].causeofSpillTankNumber = fieldValue
        break;

      case "briefDescriptionofIncident":
        data[0].briefDescriptionofIncident = fieldValue
        break;


      case "weatherConditions":
        if (this.bMultiField(data[0].weatherConditions, fieldValue)) {
          data[0].weatherConditions = data[0].weatherConditions - fieldValue
        } else {
          data[0].weatherConditions = data[0].weatherConditions + fieldValue
        }
        //validateDataItem.riskInvolvePeopleValue=true
        break;

      case "weatherConditionsTemperature":
        data[0].weatherConditionsTemperature = fieldValue
        break;

      case "weatherConditionsWindSpeed":
        data[0].weatherConditionsWindSpeed = fieldValue
        break;

      case "windDirection":
        data[0].windDirection = fieldValue
        break;

      case "comments":
        data[0].comments = fieldValue
        break;


      default:

    }

    this.setState({ data: data })
  }



  createDataForUpdateDTO = () => {
    const { data } = this.state
    const { user } = this.props

    const dataForUpdateDTO = {
      "FormSpillReportKey": data[0].formSpillReportKey,
      //"ModifiedByKey": 580, /*4=Submitted, 6=Reviewed*/
      // "StatusKey": data[0].lineStatusKey,
      "ModifiedByAD": getUserADAccount(user.account.userName),
      "Division": data[0].division,
      "CountyKey": data[0].countyKey,
      "State": data[0].state,
      "GeneralLocation": data[0].generalLocation,
      "Latitude": data[0].latitude,
      "Longitude": data[0].longitude,
      "Section": data[0].section,
      "TWP": data[0].twp,
      "Range": data[0].range,
      "Block": data[0].block,
      "Survey": data[0].survey,
      "Other": data[0].other,
      "PipelineFacility": data[0].pipelineFacility,
      "DiscoveredDateTime": data[0].discoveredDateTime ? moment(data[0].discoveredDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
      "DiscoveredbyName": data[0].discoveredbyName,
      "ReportedTo": data[0].reportedTo,
      "ReportedToDateTime": data[0].reportedToDateTime ? moment(data[0].reportedToDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
      "ReportedBy": data[0].reportedBy,
      "ReportedByDateTime": data[0].reportedByDateTime ? moment(data[0].reportedByDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
      "PersonMakingReport": data[0].personMakingReport,
      "ReporttoHSEDateTime": data[0].reporttoHSEDateTime ? moment(data[0].reporttoHSEDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
      "LeaseName": data[0].leaseName,
      "ContainedDateTime": data[0].containedDateTime ? moment(data[0].containedDateTime).format("YYYY-MM-DD HH:mm:ss") : null,
      "DrivingDirections": data[0].drivingDirections,
      "Landowner": data[0].landowner,
      "TelephoneNumber": data[0].telephoneNumber,
      "QuantityReleasedBarrels": data[0].quantityReleasedBarrels,
      "QuantityRecoveredBarrels": data[0].quantityRecoveredBarrels,
      "FireExplosionorInjury": data[0].fireExplosionorInjury,
      "DidSpillReachWater": data[0].didSpillReachWater,
      "IdentifyWaterImpacted": data[0].identifyWaterImpacted,
      "DimensionsofSpillSite": data[0].dimensionsofSpillSite,
      "DepthofContamination": data[0].depthofContamination,
      "CauseofSpill": data[0].causeofSpill,
      "CauseofSpillOther": data[0].causeofSpillOther,
      "CauseofSpillTank": data[0].causeofSpillTank,
      "CauseofSpillTankNumber": data[0].causeofSpillTankNumber,
      "BriefDescriptionofIncident": data[0].briefDescriptionofIncident,
      "WeatherConditions": data[0].weatherConditions,
      "WeatherConditionsTemperature": data[0].weatherConditionsTemperature,
      "WeatherConditionsWindSpeed": data[0].weatherConditionsWindSpeed,
      "WindDirection": data[0].windDirection,
      "Comments": data[0].comments,
      "FluidType": data[0].fluidType,
      "CommonName": data[0].commonName,
      "IsSpillReportable": data[0].isSpillReportable,
      "AgencyNotified": data[0].agencyNotified,
      "DirectionofSpillPath": data[0].directionofSpillPath,
      "SpilltoLand": data[0].spilltoLand,
      "SpilltoLandDescription": data[0].spilltoLandDescription,
      "SourceofSpill": data[0].sourceofSpill,
      "ActionsTaken": data[0].actionsTaken




    }

    return dataForUpdateDTO;
  }


  saveData = () => {
    const itemDTO = this.createDataForUpdateDTO();
    console.log(itemDTO);
    // alert("spill Report");

    const putReq = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(itemDTO)
    }

    let myURI = apiURL + 'SafetyIncident/updateSafIncSpillForm'
    fetch(myURI, putReq)
    .then(async response => {
      if (!response.ok) {
          const rspData = response.text();
          //console.log("Error return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          rspData.then(t => {
              this.setState({
                  msgBody: t,
                  msgHeader: "Error",
                  modalMsg: !this.state.modalMsg

              })
          })
          return Promise.reject(error);
      }

        console.log("No error here: " )

        this.getInpsData()
      })
      .catch(error => {
        console.log("PUT Error: " + error);
      })


  }


  toggleTab = tab => {
    const { activeTab } = this.state

    if (activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }


  render() {

    // var {isLoaded, data, isHdrLoaded, hdrData} = this.state
    var { isLoaded, isHdrLoaded,
      data, hdrData, docCount,
      ddEmployees, isddEmployeesLoaded, isReadOnly, activeTab, safIncLineKey, ddCounty, isddCountyLoaded, msgHeader, msgBody

    } = this.state

    if (!isLoaded) return <h1> loading... </h1>
    if (!isHdrLoaded) return <h1> loading hdr... </h1>

    if (!isddEmployeesLoaded) return <h1> loading Employees... </h1>
    if (!isddCountyLoaded) return <h1> loading Counties... </h1>



    //if (!isLoaded) return <h1> loading </h1> 

    return (
      <div>
        {/* <Form>
                <FormGroup>
                  <Row form>
                    <h3> First Report </h3>
                  </Row>
                  <h5>
                  <label>{hdrData[0].safIncID}</label>  <span>&nbsp;&nbsp;</span>
                  <label>| Line: {data[0].sLineNo} </label>  <span>&nbsp;&nbsp;</span>                    
                  <label>| Status: {data[0].lineStatusDesc} </label>  <span>&nbsp;&nbsp;</span>                    
                    <label>| By: {data[0].createdByAD} </label>  <span>&nbsp;&nbsp;</span>
                    <label>| Created: {new Date(data[0].createdDateTime).toLocaleDateString()}</label>  <span>&nbsp;&nbsp;</span>
                   
                  </h5>

                  <ColoredLine color="grey" />
                </FormGroup>

            </Form> */}
        <Form>
          {/* <FormGroup row>
            <Col md={2}>
              <h3> Spill Report </h3>
            </Col>
            <Col md={2}>

              <Button color="primary"
                // className="float-right"
                style={{ margin: "0px 10px 0px 0px" }}

              //  hidden={this.setIsReadOnly(statusKey)}
                onClick={() => this.saveData()}
                disabled={isReadOnly}
              >Save</Button>



            </Col>

          </FormGroup> */}

          <FormGroup row>

            <Col md={12}>


              <FormGroup>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { this.toggleTab('1'); }}
                      >
                        Comments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                      //   className={classnames({ active: activeTab === '2' })}
                      //   onClick={() => { this.toggleTab('2'); }}
                      // >
                      //   Documents
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { this.toggleTab('2'); }}
                  >
                      {"Docs/Photos of Spill Location (" + (docCount.length >0 ? docCount[0].spillLocCount : 0) + ")"}

                      

                      </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { this.toggleTab('3'); }}
                  >
                      {"Docs/Photos of Remediated Location (" + (docCount.length >0 ? docCount[0].remediatedLocCount : 0) + ")"}

                    

                      </NavLink>
                    </NavItem>


                    {/* <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { this.toggleTab('3'); }}
                      >
                        Additional Details
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col md={12}>
                          {/* <Label for="youRObserve">WHAT DID YOU OBSERVE?</Label>       */}
                          <Input
                            // invalid={!validateDataItem.hazardDesc}
                            type="textarea" name="comments" rows={5}
                            id="comments"
                            value={data[0].comments}
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("comments", e.target.value, e)}
                          // defaultValue = {observation[0].hazardDesc}                               
                          // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                          // disabled={isReadOnly} 

                          />


                        </Col>


                      </Row>
                    </TabPane>


                    <TabPane tabId="2">
                      <Row>
                        <Col md={12}>
                          {/* <DocumentBrowser  buttonLabel="View Image" 
                                        buttonColor="primary" 
                                        formName="Safety Observation Images"     
                                        linkTableName={"thsSafetyObservation"}
                                        linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}                                   
                                        apiURL={apiURL + 'SafetyObservation/Documents/' + (safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey) } /> */}
                          <DocumentBrowserDragDrop buttonLabel="View Image"
                            buttonColor="primary"
                            formName="Spill Location Images"
                            linkTableName={"thseSafetyIncident_SpillReport_SpillLoc"}
                            // linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}   
                                                           
                            linkPrimaryKey={safIncLineKey}
                            apiURL={apiURL + 'SafetyIncident/Documents/' + safIncLineKey + '/bucket/thseSafetyIncident_SpillReport_SpillLoc'}

                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col md={12}>
                        <DocumentBrowserDragDrop buttonLabel="View Image"
                            buttonColor="primary"
                            formName="Remediated Location Images"
                            linkTableName={"thseSafetyIncident_SpillReport_RemediatedLoc"}
                            // linkPrimaryKey={(safetyObservationKey<0 ? offLineLinkSafetyObservationKey : safetyObservationKey)}   
                                                           
                            linkPrimaryKey={safIncLineKey}
                            apiURL={apiURL + 'SafetyIncident/Documents/' + safIncLineKey + '/bucket/thseSafetyIncident_SpillReport_RemediatedLoc'}

                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>

                </div>
              </FormGroup>


              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={4}>
                      <Label for="division">Division</Label>
                      <Input type="text" name="division"
                        id="division"
                        disabled={isReadOnly}
                        value={data[0].division}
                        onChange={(e) => this.onTextChange("division", e.target.value, e)}
                      />

                    </Col>



                    {/* <Col md={4}>
                      <Label for="county">County</Label>
                      <Input type="text" name="county"
                        id="county"
                        disabled={isReadOnly}
                        value={data[0].county}
                        onChange={(e) => this.onTextChange("county", e.target.value, e)}
                      />
                    </Col> */}

                    {/* <Col md={6}   className="alway-in-between"> */}

                    <Col md={4}  >
                      <Label for="county">County</Label>


                      <Select
                        options={this.createCountyList(ddCounty)}
                        //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                        // styles={customStyles}

                        disabled={isReadOnly}
                        id="county"
                        name="county"
                        value={this.getSelCounty(data[0].countyKey)}
                        // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                        onChange={(e) => this.onTextChange("county", e, e)}
                        isClearable={true}

                      >
                      </Select>

                    </Col>




                    <Col md={4}>
                      <Label for="state">State</Label>
                      <Input type="text" name="state"
                        id="state"
                        disabled={true}
                        value={data[0].state}
                        onChange={(e) => this.onTextChange("state", e.target.value, e)}
                      />


                    </Col>


                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={8}>
                      <Label
                        for="generalLocation">General Location: (include name of nearest town)</Label>
                      <Input
                        // invalid={!validateDataItem.hazardDesc}
                        type="textarea" name="generalLocation" rows={4}
                        id="generalLocation"
                        value={data[0].generalLocation}
                        onChange={(e) => this.onTextChange("generalLocation", e.target.value, e)}
                        // defaultValue = {observation[0].hazardDesc}                               
                        // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                        disabled={isReadOnly}

                      />

                    </Col>




                    <Col md={4}>


                      <Label for="latitude">Latitude</Label>
                      <Input type="number" name="latitude"
                        id="latitude"
                        disabled={isReadOnly}
                        value={data[0].latitude}
                        onChange={(e) => this.onTextChange("latitude", e.target.value, e)}
                      />



                      <Label for="longitude">Longitude</Label>
                      <Input type="number" name="longitude"
                        id="longitude"
                        disabled={isReadOnly}
                        value={data[0].longitude}
                        onChange={(e) => this.onTextChange("longitude", e.target.value, e)}
                      />






                    </Col>




                  </Row>

                </Col>

              </FormGroup>


              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={4}>
                      <Label for="section">Section</Label>
                      <Input type="text" name="section"
                        id="section"
                        disabled={isReadOnly}
                        value={data[0].section}
                        onChange={(e) => this.onTextChange("section", e.target.value, e)}
                      />

                    </Col>



                    <Col md={4}>
                      <Label for="twp">TWP</Label>
                      <Input type="text" name="twp"
                        id="twp"
                        disabled={isReadOnly}
                        value={data[0].twp}
                        onChange={(e) => this.onTextChange("twp", e.target.value, e)}
                      />


                    </Col>

                    <Col md={4}>
                      <Label for="range">Range</Label>
                      <Input type="text" name="range"
                        id="range"
                        disabled={isReadOnly}
                        value={data[0].range}
                        onChange={(e) => this.onTextChange("range", e.target.value, e)}
                      />


                    </Col>


                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={4}>
                      <Label for="block">Block</Label>
                      <Input type="text" name="block"
                        id="block"
                        disabled={isReadOnly}
                        value={data[0].block}
                        onChange={(e) => this.onTextChange("block", e.target.value, e)}
                      />

                    </Col>



                    <Col md={4}>
                      <Label for="survey">Survey</Label>
                      <Input type="text" name="survey"
                        id="survey"
                        disabled={isReadOnly}
                        value={data[0].survey}
                        onChange={(e) => this.onTextChange("survey", e.target.value, e)}
                      />


                    </Col>

                    <Col md={4}>
                      <Label for="other">Other</Label>
                      <Input type="text" name="other"
                        id="other"
                        disabled={isReadOnly}
                        value={data[0].other}
                        onChange={(e) => this.onTextChange("other", e.target.value, e)}
                      />


                    </Col>


                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={12}>
                      <Label for="pipelineFacility">Pipeline (Sub System)/Facility</Label>
                      <Input type="text" name="pipelineFacility"
                        id="pipelineFacility"
                        disabled={isReadOnly}
                        value={data[0].pipelineFacility}
                        onChange={(e) => this.onTextChange("pipelineFacility", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={8}>
                      <Label for="discoveredbyName">Discovered by (Name, Company)</Label>
                      <Input type="text" name="discoveredbyName"
                        id="discoveredbyName"
                        disabled={isReadOnly}
                        value={data[0].discoveredbyName}
                        onChange={(e) => this.onTextChange("discoveredbyName", e.target.value, e)}
                      />

                    </Col>


                    <Col md={4}>
                      <Label for="discoveredDateTime">Discovered Datetime</Label>
                      {/* <DatePicker id="oshaDate"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("oSHADateTime", v, f)}
                            value={data[0].oSHADateTime} /> */}
                      <Datetime
                        inputProps={{
                          placeholder: "MM/DD/YYYY hh:mm",
                          disabled: isReadOnly
                          // disabled: data[0].oshaContacted === 1 ? false : true
                        }}
                        // value={data[0].discoveredDateTime === null ? null : new Date(data[0].discoveredDateTime)}
                        value={moment(data[0].discoveredDateTime)}

                        onChange={(v, f) => this.onTextChange("discoveredDateTime", v, f)}
                      />


                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={8}>
                      <Label for="reportedTo">Reported To</Label>
                      <Input type="text" name="reportedTo"
                        id="reportedTo"
                        disabled={isReadOnly}
                        value={data[0].reportedTo}
                        onChange={(e) => this.onTextChange("reportedTo", e.target.value, e)}
                      />

                    </Col>


                    <Col md={4}>
                      <Label for="reportedToDateTime">Reported To Datetime</Label>
                      {/* <DatePicker id="oshaDate"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("oSHADateTime", v, f)}
                            value={data[0].oSHADateTime} /> */}
                      <Datetime
                        inputProps={{
                          placeholder: "MM/DD/YYYY hh:mm",
                          disabled: isReadOnly

                          // disabled: data[0].oshaContacted === 1 ? false : true
                        }}
                        // value={data[0].reportedToDateTime === null ? null : new Date(data[0].reportedToDateTime)}
                        value={moment(data[0].reportedToDateTime)}

                        onChange={(v, f) => this.onTextChange("reportedToDateTime", v, f)}
                      />


                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={8}>
                      <Label for="reportedBy">Reported By</Label>
                      <Input type="text" name="reportedBy"
                        id="reportedBy"
                        disabled={isReadOnly}
                        value={data[0].reportedBy}
                        onChange={(e) => this.onTextChange("reportedBy", e.target.value, e)}
                      />

                    </Col>


                    <Col md={4}>
                      <Label for="reportedByDateTime">Reported By Datetime</Label>
                      {/* <DatePicker id="oshaDate"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("oSHADateTime", v, f)}
                            value={data[0].oSHADateTime} /> */}
                      <Datetime
                        inputProps={{
                          placeholder: "MM/DD/YYYY hh:mm",
                          disabled: isReadOnly
                          //disabled: false
                          // disabled: data[0].oshaContacted === 1 ? false : true
                        }}
                        // value={data[0].reportedByDateTime === null ? null : new Date(data[0].reportedByDateTime)}
                        value={moment(data[0].reportedByDateTime)}



                        onChange={(v, f) => this.onTextChange("reportedByDateTime", v, f)}
                      />


                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={8}>
                      <Label for="personMakingReport">Person Making Report</Label>
                      <Input type="text" name="reportedBy"
                        id="personMakingReport"
                        disabled={isReadOnly}
                        value={data[0].personMakingReport}
                        onChange={(e) => this.onTextChange("personMakingReport", e.target.value, e)}
                      />

                    </Col>


                    <Col md={4}>
                      <Label for="reporttoHSEDateTime">Report to HS&E Datetime</Label>
                      {/* <DatePicker id="oshaDate"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("oSHADateTime", v, f)}
                            value={data[0].oSHADateTime} /> */}
                      <Datetime
                        inputProps={{
                          placeholder: "MM/DD/YYYY hh:mm",
                          disabled: isReadOnly
                          //disabled: false
                          // disabled: data[0].oshaContacted === 1 ? false : true
                        }}
                        // value={data[0].reporttoHSEDateTime === null ? null : new Date(data[0].reporttoHSEDateTime)}
                        value={moment(data[0].reporttoHSEDateTime)}

                        onChange={(v, f) => this.onTextChange("reporttoHSEDateTime", v, f)}
                      />


                    </Col>




                  </Row>

                </Col>

              </FormGroup>


              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={8}>
                      <Label for="leaseName">Lease Name</Label>
                      <Input type="text" name="basinName"
                        id="basinName"
                        disabled={isReadOnly}
                        value={data[0].leaseName}
                        onChange={(e) => this.onTextChange("leaseName", e.target.value, e)}
                      />

                    </Col>


                    <Col md={4}>
                      <Label for="containedDateTime">Contained DateTime</Label>
                      {/* <DatePicker id="oshaDate"
                            //disabled={isReadOnly}
                            onChange={(v, f) => this.onTextChange("oSHADateTime", v, f)}
                            value={data[0].oSHADateTime} /> */}
                      <Datetime
                        inputProps={{
                          placeholder: "MM/DD/YYYY hh:mm",
                          disabled: isReadOnly
                          //disabled: false
                          // disabled: data[0].oshaContacted === 1 ? false : true
                        }}
                        // value={data[0].reporttoHSEDateTime === null ? null : new Date(data[0].reporttoHSEDateTime)}
                        value={moment(data[0].containedDateTime)}

                        onChange={(v, f) => this.onTextChange("containedDateTime", v, f)}
                      />


                    </Col>




                  </Row>

                </Col>

              </FormGroup>


              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={12}>
                      <Label for="drivingDirections">Driving Directions (include name of nearest town or Highway intersection)</Label>
                      <Input
                        // invalid={!validateDataItem.hazardDesc}
                        type="textarea" name="drivingDirections" rows={4}
                        id="drivingDirections"
                        disabled={isReadOnly}
                        value={data[0].drivingDirections}
                        onChange={(e) => this.onTextChange("drivingDirections", e.target.value, e)}
                      // defaultValue = {observation[0].hazardDesc}                               
                      // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                      // disabled={isReadOnly} 

                      />

                    </Col>







                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={8}>
                      <Label for="landowner">Landowner</Label>
                      <Input type="text" name="landowner"
                        id="landowner"
                        disabled={isReadOnly}
                        value={data[0].landowner}
                        onChange={(e) => this.onTextChange("landowner", e.target.value, e)}
                      />

                    </Col>

                    <Col md={4}>
                      <Label for="telephoneNumber">Telephone Number</Label>
                      <Input type="text" name="telephoneNumber"
                        id="telephoneNumber"
                        disabled={isReadOnly}
                        value={data[0].telephoneNumber}
                        onChange={(e) => this.onTextChange("telephoneNumber", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={4}>
                      <Label for="quantityReleasedBarrels">Quantity Released (Barrels)</Label>
                      <Input type="number" name="quantityReleasedBarrels"
                        id="quantityReleasedBarrels"
                        disabled={isReadOnly}
                        value={data[0].quantityReleasedBarrels}
                        onChange={(e) => this.onTextChange("quantityReleasedBarrels", e.target.value, e)}
                      />

                    </Col>

                    <Col md={4}>
                      <Label for="quantityRecoveredBarrels">Quantity Recovered (Barrels)</Label>
                      <Input type="number" name="quantityRecoveredBarrels"
                        id="quantityRecoveredBarrels"
                        disabled={isReadOnly}
                        value={data[0].quantityRecoveredBarrels}
                        onChange={(e) => this.onTextChange("quantityRecoveredBarrels", e.target.value, e)}
                      />

                    </Col>

                    <Col md={4}>
                      <Label for="fireExplosionorInjury">Fire, Explosion, or Injury?</Label>
                      <Input type="text" name="fireExplosionorInjury"
                        id="fireExplosionorInjury"
                        disabled={isReadOnly}
                        value={data[0].fireExplosionorInjury}
                        onChange={(e) => this.onTextChange("fireExplosionorInjury", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>





              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={6}>
                      <Label for="dimensionsofSpillSite">Dimensions of Spill Site</Label>
                      <Input type="text" name="dimensionsofSpillSite"
                        id="dimensionsofSpillSite"
                        disabled={isReadOnly}
                        value={data[0].dimensionsofSpillSite}
                        onChange={(e) => this.onTextChange("dimensionsofSpillSite", e.target.value, e)}
                      />

                    </Col>

                    <Col md={6}>
                      <Label for="depthofContamination">Depth of Contamination</Label>
                      <Input type="text" name="depthofContamination"
                        id="depthofContamination"
                        disabled={isReadOnly}
                        value={data[0].depthofContamination}
                        onChange={(e) => this.onTextChange("depthofContamination", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={6}>
                      <Label for="fluidType">Fluid Type (Prod. Water, Brine, Oil, Condensate)</Label>
                      <Input type="text" name="fluidType"
                        id="fluidType"
                        disabled={isReadOnly}
                        value={data[0].fluidType}
                        onChange={(e) => this.onTextChange("fluidType", e.target.value, e)}
                      />

                    </Col>

                    <Col md={6}>
                      <Label for="commonName">Common Name of Spilled Substance</Label>
                      <Input type="text" name="commonName"
                        id="commonName"
                        disabled={isReadOnly}
                        value={data[0].commonName}
                        onChange={(e) => this.onTextChange("commonName", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={2}>
                      <Label for="isSpillReportable">Is Spill Reportable?</Label>{"\n"}
                      <CustomInput type="checkbox"
                        id="isSpillReportableY"
                        label="Yes"
                        checked={data[0].isSpillReportable === 1}
                        inline
                        disabled={isReadOnly}
                        onChange={(e) => this.onTextChange("isSpillReportable", 1, e)}
                      />
                      <CustomInput type="checkbox"
                        id="isSpillReportableN"
                        label="No" inline
                        checked={data[0].isSpillReportable === 0}
                        onChange={(e) => this.onTextChange("isSpillReportable", 0, e)}
                        disabled={isReadOnly}

                      />

                    </Col>



                    <Col md={10}>
                      <Label for="agencyNotified">Agency Notified (Name/Date/Agency)</Label>
                      <Input type="text" name="agencyNotified"
                        id="agencyNotified"
                        value={data[0].agencyNotified}
                        disabled={isReadOnly}
                        onChange={(e) => this.onTextChange("agencyNotified", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>


              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={2}>
                      <Label for="didSpillReachWater">Did spill reach water?</Label>{"\n"}
                      <CustomInput type="checkbox"
                        id="didSpillReachWaterY"
                        label="Yes"
                        checked={data[0].didSpillReachWater === 1}
                        inline
                        disabled={isReadOnly}
                        onChange={(e) => this.onTextChange("didSpillReachWater", 1, e)}
                      />
                      <CustomInput type="checkbox"
                        id="didSpillReachWaterN"
                        label="No" inline
                        checked={data[0].didSpillReachWater === 0}
                        onChange={(e) => this.onTextChange("didSpillReachWater", 0, e)}
                        disabled={isReadOnly}

                      />

                    </Col>



                    <Col md={10}>
                      <Label for="identifyWaterImpacted">If yes, identify water impacted</Label>
                      <Input type="text" name="identifyWaterImpacted"
                        id="identifyWaterImpacted"
                        value={data[0].didSpillReachWater === 1 ? data[0].identifyWaterImpacted : ""}
                        disabled={data[0].didSpillReachWater === 1 ? isReadOnly : true}
                        onChange={(e) => this.onTextChange("identifyWaterImpacted", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>
            

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={2}>
                      <Label for="spilltoLand">Spill to Land?</Label>{"\n"}
                      <CustomInput type="checkbox"
                        id="spilltoLandY"
                        label="Yes"
                        checked={data[0].spilltoLand === 1}
                        inline
                        disabled={isReadOnly}
                        onChange={(e) => this.onTextChange("spilltoLand", 1, e)}
                      />
                      <CustomInput type="checkbox"
                        id="spilltoLandN"
                        label="No" inline
                        checked={data[0].spilltoLand === 0}
                        onChange={(e) => this.onTextChange("spilltoLand", 0, e)}
                        disabled={isReadOnly}

                      />

                    </Col>



                    <Col md={10}>
                      <Label for="spilltoLandDescription">If yes, add Description/Name</Label>
                      <Input type="text" name="spilltoLandDescription"
                        id="spilltoLandDescription"
                        value={data[0].spilltoLand === 1 ? data[0].spilltoLandDescription : ""}
                        disabled={data[0].spilltoLand === 1 ? isReadOnly : true}
                        onChange={(e) => this.onTextChange("spilltoLandDescription", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={12}>
                   
                    <p class="font-weight-light">NM: 19.15.29.7-NMAC. Major release 25 barrels or more. Minor release is greater than 5 barrels but less than 25 barrels.</p>
                    <p class="font-weight-light">OK: OAC 165:10-7-5 Any spill to land must be reported to the OCC if it amounts to ten or more barrels of any substance used or produced in petroleum exploration or production. Also, a spill of any quantity of these substances that comes in contact with water must be reported.</p>
                    <p class="font-weight-light">TX: SWR 20(a)(1) Notice of leak, Spill-Notify RRC when over 250 bbl of produced water or over 5 bbl of crude oil</p>
                    
                           

                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={6}>
                      <Label for="sourceofSpill">Source of the Release or Spill</Label>
                      <Input type="text" name="sourceofSpill"
                        id="sourceofSpill"
                        disabled={isReadOnly}
                        value={data[0].sourceofSpill}
                        onChange={(e) => this.onTextChange("sourceofSpill", e.target.value, e)}
                      />

                    </Col>

                    <Col md={6}>
                    <Label for="directionofSpillPath">Direction of Spill Path</Label>
                      <Input type="text" name="directionofSpillPath"
                        id="directionofSpillPath"
                        disabled={isReadOnly}
                        value={data[0].directionofSpillPath}
                        onChange={(e) => this.onTextChange("directionofSpillPath", e.target.value, e)}
                      />


                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Cause of Spill (Check All That Apply)"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbcauseAdverseWeather"
                            disabled={isReadOnly}
                            label="Adverse Weather"
                            checked={this.bMultiField(data[0].causeofSpill, 1)}
                            onChange={(e) => this.onTextChange("causeofSpill", 1, e)}
                            inline
                          // disabled={isReadOnly}


                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbcauseExternalCorrosion" label="External Corrosion" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 2)}
                            onChange={(e) => this.onTextChange("causeofSpill", 2, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbcauseOperatorError" label="Operator Error" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 4)}
                            onChange={(e) => this.onTextChange("causeofSpill", 4, e)}
                          />
                        </Col>

                      </Row>
                      {/* 2 row */}
                      <Row>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbcauseUndetermined"
                            label="Undetermined"
                            checked={this.bMultiField(data[0].causeofSpill, 8)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("causeofSpill", 8, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbcauseControlCenterError" label="Control Center Error" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 16)}
                            onChange={(e) => this.onTextChange("causeofSpill", 16, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbcauseInternalCorrosion" label="Internal Corrosion" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 32)}
                            onChange={(e) => this.onTextChange("causeofSpill", 32, e)}
                          />
                        </Col>

                      </Row>
                      {/* 3 row */}

                      <Row>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbcausePipeSeamFailure"
                            label="Pipe Seam Failure"
                            checked={this.bMultiField(data[0].causeofSpill, 64)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("causeofSpill", 64, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbcauseWeldFailure" label="Weld Failure" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 128)}
                            onChange={(e) => this.onTextChange("causeofSpill", 128, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbcauseEquipmentFailure" label="Equipment Failure" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 256)}
                            onChange={(e) => this.onTextChange("causeofSpill", 256, e)}
                          />
                        </Col>

                      </Row>
                      {/* 4 row */}
                      <Row>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbcauseMaintenance"
                            label="Maintenance"
                            checked={this.bMultiField(data[0].causeofSpill, 512)}
                            inline
                            disabled={isReadOnly}
                            onChange={(e) => this.onTextChange("causeofSpill", 512, e)}

                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox" id="cbcauseThirdParty" label="Third Party (fill out damage report)" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 1024)}
                            onChange={(e) => this.onTextChange("causeofSpill", 1024, e)}
                          />

                        </Col>


                      </Row>
                      <Row>
                        <Col md={2}>
                          <CustomInput type="checkbox" id="cbcauseOther" label="Other (explain)" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].causeofSpill, 2048)}
                            onChange={(e) => this.onTextChange("causeofSpill", 2048, e)}
                          />
                        </Col>
                        <Col md={10}>
                          {/* <Label for="causeofSpillOther">Depth of Contamination</Label> */}
                          <Input type="text" name="causeofSpillOther"
                            id="causeofSpillOther"
                            // value={data[0].causeofSpillOther}
                            value={this.bMultiField(data[0].causeofSpill, 2048) ? data[0].causeofSpillOther : ""}
                            disabled={this.bMultiField(data[0].causeofSpill, 2048) ? isReadOnly : true}

                            onChange={(e) => this.onTextChange("causeofSpillOther", e.target.value, e)}
                          />

                        </Col>



                      </Row>


                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={12}>
                      <Label for="actionsTaken">Actions Taken to Contain Spill or Impact of the Incident</Label>
                      <Input
                        // invalid={!validateDataItem.hazardDesc}
                        type="textarea" name="actionsTaken" rows={3}
                        id="actionsTaken"
                        value={data[0].actionsTaken}
                        onChange={(e) => this.onTextChange("actionsTaken", e.target.value, e)}
                        // defaultValue = {observation[0].hazardDesc}                               
                        // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                        disabled={isReadOnly}

                      />

                    </Col>







                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={3}>
                      <Label for="causeofSpillTank">Tank</Label>
                      <Input type="text" name="causeofSpillTank"
                        id="causeofSpillTank"
                        disabled={isReadOnly}
                        value={data[0].causeofSpillTank}
                        onChange={(e) => this.onTextChange("causeofSpillTank", e.target.value, e)}
                      />

                    </Col>

                    <Col md={9}>
                      <Label for="causeofSpillTankNumber">Tank Number</Label>
                      <Input type="text" name="causeofSpillTankNumber"
                        id="causeofSpillTankNumber"
                        disabled={isReadOnly}
                        value={data[0].causeofSpillTankNumber}
                        onChange={(e) => this.onTextChange("causeofSpillTankNumber", e.target.value, e)}
                      />

                    </Col>




                  </Row>

                </Col>

              </FormGroup>

              <FormGroup row>
                <Col md={12}>


                  <Row>

                    <Col md={12}>
                      <Label for="briefDescriptionofIncident">Brief description of incident (indicate failure mode and source of release, pipeline, tank, truck, etc.)</Label>
                      <Input
                        // invalid={!validateDataItem.hazardDesc}
                        type="textarea" name="briefDescriptionofIncident" rows={5}
                        id="briefDescriptionofIncident"
                        value={data[0].briefDescriptionofIncident}
                        onChange={(e) => this.onTextChange("briefDescriptionofIncident", e.target.value, e)}
                        // defaultValue = {observation[0].hazardDesc}                               
                        // onChange = {(e) => this.onTextChange("hazardDesc",e.target.value,e)}
                        disabled={isReadOnly}

                      />

                    </Col>







                  </Row>

                </Col>

              </FormGroup>
              
              <FormGroup row>
                <Col md={12}>
                  <Card>
                    <CardHeader style={{ padding: "0", margin: "0" }}>
                      <Input
                        //invalid={!validateDataItem.hazardAndRiskValue}                                                    
                        invalid={false}
                        disabled
                        className="bg-transparent"
                        value="Weather Conditions"
                        style={{ border: "0px", padding: "0", margin: "0" }}>
                      </Input>
                      {/* <FormFeedback tooltip>Please select one "HAZARD AND RISK"</FormFeedback> */}

                    </CardHeader>
                    <CardBody>
                      {/* 1 row */}
                      <Row>
                        <Col md={4}>
                          <Label for="weatherConditionsTemperature">Temperature</Label>
                          <Input type="text" name="weatherConditionsTemperature"
                            id="weatherConditionsTemperature"
                            disabled={isReadOnly}
                            value={data[0].weatherConditionsTemperature}
                            onChange={(e) => this.onTextChange("weatherConditionsTemperature", e.target.value, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <Label for="weatherConditionsWindSpeed">WindSpeed</Label>
                          <Input type="text" name="weatherConditionsWindSpeed"
                            id="weatherConditionsWindSpeed"
                            disabled={isReadOnly}
                            value={data[0].weatherConditionsWindSpeed}
                            onChange={(e) => this.onTextChange("weatherConditionsWindSpeed", e.target.value, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <Label for="windDirection">Direction</Label>
                          <Input type="text" name="windDirection"
                            id="windDirection"
                            disabled={isReadOnly}
                            value={data[0].windDirection}
                            onChange={(e) => this.onTextChange("windDirection", e.target.value, e)}
                          />

                        </Col>
                     

                      </Row>
                      {/* 2 row */}
                      <Row
                       style={{ marginTop: "20px" }}
                      >
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbweathSunny"
                            label="Sunny"
                            checked={this.bMultiField(data[0].weatherConditions, 1)}
                            onChange={(e) => this.onTextChange("weatherConditions", 1, e)}
                            inline
                            disabled={isReadOnly}


                          />
                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbweathCloudy"
                            label="Cloudy" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].weatherConditions, 2)}
                            onChange={(e) => this.onTextChange("weatherConditions", 2, e)}
                          />

                        </Col>
                        <Col md={4}>
                          <CustomInput type="checkbox"
                            id="cbweathRaining" label="Raining" inline
                            disabled={isReadOnly}
                            checked={this.bMultiField(data[0].weatherConditions, 4)}
                            onChange={(e) => this.onTextChange("weatherConditions", 4, e)}
                          />
                        </Col>

                      </Row>
                      
                      {/* 3 row */}






                    </CardBody>


                  </Card>
                </Col>
                {/* <Col md={6}>
                          <Row form>
                            <Col md={4}>
                              33
                            </Col>
                            <Col md={8}>
                              44
                            </Col>
                          </Row>
                        </Col> */}
              </FormGroup>

            </Col>

          </FormGroup>

        </Form>


        <div>
                        <Modal isOpen={this.state.modalMsg}
                            toggle={() => this.toggleMsg()}
                            size="lg">
                            <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => this.toggleMsg()}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div>



      </div>

    );



    //       }
    //     }
    //   </FetchData>
    // );
  }
}