import React, { Component } from 'react';

import { Alert, Form, Button } from 'reactstrap';

import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';


import { USDCurrencyFormat,ColoredLine,getUserADAccount } from '../../resources/utility';


import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();


export default class LineCostForcast extends Component {
    static displayName = LineCostForcast.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onCellValueChanging = this.onCellValueChanging.bind(this);

        this.state = {
            costForcastData: [],
            isDataLoaded: false,
            afeLineKey: props.afeLineKey,
            selAFELine: props.selAFELine,
            showAlert: false,
            alertMsg: '',
            isFormReadOnly:props.isFormReadOnly
        }
    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.costForcastData;
        this.onLoad()
    }

    getCostLineForcast = () => {
        const { afeLineKey } = this.state

        let myURI = apiURL + 'AFE/LineDetail/CostForcast/' + afeLineKey

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    costForcastData: json
                })
            });
    }

    componentDidMount() {

        window.addEventListener('load', this.onLoad);
        this.getCostLineForcast()


    }

    onLoad() {
        if (this.grid !== undefined) {
            this.grid.summaryDescriptions.clear()

            const forcastSum = new IgrColumnSummaryDescription();
            forcastSum.field = "amount";
            forcastSum.operand = SummaryOperand.Sum;
            forcastSum.formatOverride = new Intl.NumberFormat('en-EN', {
                style: 'currency', currency: 'USD',
                minimumFractionDigits: 0, maximumFractionDigits: 2
            });
            this.grid.summaryDescriptions.add(forcastSum);

            const pctSum = new IgrColumnSummaryDescription();
            pctSum.field = "pctValue";
            pctSum.operand = SummaryOperand.Sum;
            pctSum.formatOverride = new Intl.NumberFormat('en-EN', { minimumFractionDigits: 2, maximumFractionDigits: 4 });

            this.grid.summaryDescriptions.add(pctSum);

            const dayCountSum = new IgrColumnSummaryDescription();
            dayCountSum.field = "dayCount";
            dayCountSum.operand = SummaryOperand.Sum;
            dayCountSum.formatOverride = new Intl.NumberFormat('en-EN', { minimumFractionDigits: 0, maximumFractionDigits: 4 });

            this.grid.summaryDescriptions.add(dayCountSum);
        }
    }
    toggleAlert = () => {

        // this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    foreCastOKClick = () => {
        this.saveForeCastLine()        
    }

    saveForeCastLine = () => {
        const updateForecastDTO = this.createForecastLineUpdateDTO()
        console.log("updateForecastDTO")
        console.log(updateForecastDTO)
        let myURI = apiURL + 'AFE/Line/Forecast'
        fetch(myURI, apiPUTHeader(updateForecastDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            showAlert: true,
                            alertMsg: t
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.props.toggleModalCostForcast()
            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })
    }

    createForecastLineUpdateDTO = () => {
        const {costForcastData} = this.state
        const {user} = this.props
        const afeForCastCostLine = []

        costForcastData.map(e=> {
            if (e.isUpdated !== undefined){
                if (e.isUpdated !==0){
                    const updateDTO = {
                        "afeLineCostForcastKey": e.afeLineCostForcastKey,
                        "pctValue": e.pctValue / 100.00,
                        "amount": e.amount,
                        "updatedBy": getUserADAccount(user.account.userName),
                        "manualOverride": e.manualOverride,
                    }

                    afeForCastCostLine.push(updateDTO)
                }
            }
        })

        return afeForCastCostLine

    }

    render() {
        const { costForcastData, isDataLoaded,isFormReadOnly,
            selAFELine, showAlert, alertMsg } = this.state

        console.log("updated")

        if (!isDataLoaded) {
            return (<div>Loading Costing Data .......</div>)
        } else
            return (<div>
                <Form>
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height="250px"
                        width='100%'
                        dataSource={costForcastData}
                        isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        groupSummaryDisplayMode={"RowTop"}
                        groupHeaderDisplayMode="combined"
                        summaryScope="Both"
                        editMode={(isFormReadOnly ? 0 : 1)}
                        // editMode={(isFormReadOnly ? 0 : 1)}
                        // selectedKeysChanged={this.onGridRowSelected}
                        cellValueChanging={this.onCellValueChanging}
                    >

                        <IgrTemplateColumn
                            field="yrMonth"
                            headerText="Year Month"
                            width="150"
                            isHidden="true"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrTemplateColumn
                            field="monthYrLabel"
                            headerText="Year Month"
                            width="150"
                            cellUpdating={this.onCellUpdating}
                        />



                        <IgrTemplateColumn
                            field="dayCount"
                            headerText="Day Count"
                            width="150"
                            cellUpdating={this.onCellUpdating}
                        />

                        <IgrNumericColumn
                            field="pctValue"
                            headerText="Percent"
                            width="150"
                            showGroupingSeparator="true"
                            positiveSuffix="%"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                        //cellUpdating={this.onCellUpdating}

                        />

                        <IgrNumericColumn
                            field="amount"
                            headerText="Amount"                           
                            showGroupingSeparator="true"
                            positivePrefix="$"
                            maxFractionDigits={2}
                            width="*>120"                           
                            //cellUpdating={this.onCellUpdating}
                        />
                        <IgrNumericColumn field="afeLineKey" isHidden />
                        <IgrNumericColumn field="afeLineCostForcastKey" isHidden />
                    </IgrDataGrid>


                    <div>
                        <Alert color="danger" isOpen={showAlert}
                            //toggle={() => this.toggleAlert()}
                            style={{ marginTop: "5px",marginBottom:"10px" }}
                        >
                            {alertMsg}
                        </Alert>
                    </div>


                    <ColoredLine color="grey" />
                    
                    <div>
                        <Form>

                        <Button
                            color="danger"
                            className='float-right'
                            onClick={() => this.props.toggleModalCostForcast()}
                            
                            style={{marginTop:"5px"}}
                        >
                            Cancel
                        </Button>

                        <Button
                            color="primary"
                            className='float-right'
                            disabled={showAlert}
                            onClick={() => this.foreCastOKClick() }
                            style={{marginTop:"5px", marginRight:"20px"}}
                        >
                            OK
                        </Button>                    
                        {' '}
                        </Form>
                    </div>
                </Form>
            </div>)
    }

    onCellValueChanging(s, e) {
        this.updateDailyForcastLine(e.item.afeLineCostForcastKey, e.column.field, e.newValue)
    }

    updateDailyForcastLine = (key, field, newValue) => {
        const { costForcastData, selAFELine } = this.state
        var dayCount = 0, dailyRate = 0.0,
            newAmt = 0.0, newTotalAmt = 0.0,
            alertMsg = '', showAlert = false

        costForcastData.map(e => {
            if (e.afeLineCostForcastKey === key) {
                if (field === 'amount') {
                    newAmt = newValue
                    e.amount = newValue
                    e.pctValue = (newValue / selAFELine.extLineAmt) * 100.0
                } else if (field === 'pctValue') {
                    e.pctValue = newValue
                    e.amount = selAFELine.extLineAmt * (newValue / 100.0)
                    newAmt = selAFELine.extLineAmt * (newValue / 100.0)
                }
                e.manualOverride = 1
                e.isUpdated = 1
            } else {
                dayCount = dayCount + e.dayCount
                newTotalAmt = newTotalAmt + e.amount
            }
        })

        newTotalAmt = newTotalAmt + newAmt
        // dailyRate = (selAFELine.extLineAmt - newAmt) / dayCount

        // costForcastData.map(e => {
        //     if (e.afeLineCostForcastKey !== key){
        //         e.amount = dailyRate * e.dayCount
        //     }
        // })

        console.log("newTotalAmt")
        console.log(Math.round(newTotalAmt))

        console.log("selAFELine.extLineAmt")
        console.log(Math.round(selAFELine.extLineAmt))

        if (Math.round(newTotalAmt) !== Math.round(selAFELine.extLineAmt)) {
            showAlert = true
            alertMsg = "Line and Total Forecast are not equal: " + USDCurrencyFormat(selAFELine.extLineAmt - newTotalAmt) + " different."
        }

        this.setState({
            costForcastData: costForcastData,
            showAlert: showAlert,
            alertMsg: alertMsg
        })
        //this.setState({})
        this.onLoad()
    }
    onCellUpdating(s, e) {

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;

        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            link.setAttribute("class", "form-control selectBorderLess");
            //link.style.fontFamily = "Verdana";
            link.style.fontSize = "13px";
            link.style.verticalAlign = "center";

            // content.style.margin = "0px -20px -10px -12px";
            link.style.padding = "8px 0px 0px 0px";
            // //content.style.width = "100%";

            content.appendChild(link);
        } else {
            link = content.children[0]
        }

        if (link != undefined) {

            switch (s.field) {
                case 'monthYrLabel':
                    link.textContent = item.monthYrLabel;
                    link.setAttribute("class", "form-control selectBorderLess");
                    break;
                case 'yrMonth':
                    link.textContent = item.yrMonth;
                    link.setAttribute("class", "form-control selectBorderLess");
                    break;
                case 'dayCount':
                    link.textContent = item.dayCount;
                    link.setAttribute("class", "form-control selectBorderLess");
                    break;
                default:
            }

        }


    }
}