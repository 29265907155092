import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, FormGroup, ModalBody, ModalFooter, Label, Row } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import TaskAndInstructions from './TaskAndInstructions';
const WOWIPModal = (props) => {
  const {
    color,
    className,
    formName,
    message,
    hidden,
    buttonLabel,
    style,
    commentPrompMsg,
    isOpen,
    toggle,
    onClickYes,
    partsAndCostDataGrid,
    fiixWOStatus,
    fiixWOId,
    workOrderKey,
    partsAndCostData,
    partsAndCostStatus,
    empList,
    user
  } = props;
  const [cachedTasks, setCachedTasks] = useState([]);

  useEffect(() => {
    // Additional logic can be added here if needed
  }, [isOpen]);

  const toggleYes = () => {
    if (onClickYes) {
      onClickYes(); // Call the onClickYes function passed from props
    }
    toggle();
  };

  const onClosed = () => {
    if (props.onClosed !== undefined) {
      props.onClosed();
    }
  };

  const onAddTasksToCache = (newTask) => {
    setCachedTasks((prevTasks) => [...prevTasks, newTask]);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} onClosed={onClosed} size="lg">
        <ModalHeader toggle={toggle}>{formName}</ModalHeader>
        <ModalBody>
          <Row form style={{ marginBottom: "5px" }}>
          </Row>
          {message}
          <FormGroup style={{ marginBottom: "-10px" }}>
            <Label for="inputComment">
              {commentPrompMsg}
            </Label>
            {/*Commented out by BN 10/10/24. This is not for v1 release. */}
            {/* <TaskAndInstructions
                    statusChange={"Work In Progress"}
                    fiixWOStatus={fiixWOStatus}
                    user={props.user}
                    workOrderKey={workOrderKey}
                    // readOnly={readOnly}
                    fiixWOId={fiixWOId}
                    partsAndCostData={partsAndCostData}
                    partsAndCostDataGrid={partsAndCostDataGrid}
                    partsAndCostStatus={partsAndCostStatus}
                    empList={empList}
                    onAddTasksToCache={onAddTasksToCache}                                                              
                                >

            </TaskAndInstructions> */}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleYes}>OK</Button>
          <Button color="primary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WOWIPModal;
