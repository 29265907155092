// This comment put in by Hau Nguyen on 06/06/2021

import React, { Component } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import { getUserADAccount, getRandomInt } from '../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
// import { IgrDateTimeColumn } from 'igniteui-react-grids';
// import { IgrColumnGroupDescription } from 'igniteui-react-grids';
// import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrTemplateCellInfo } from 'igniteui-react-grids';
// import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import SupplierRequestCRUD from './SupplierRequestCRUD'
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import PowerBIReportViewer from '../resources/PowerBIReportViewer'
import Revise from '../resources/revise.png'
import Report from '../resources/report.png'
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
IgrDatePickerModule.register();
IgrDataGridToolbarModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class SupplierRequest extends Component {
    static displayName = SupplierRequest.name;

    constructor(props) {
        super(props);
        document.title = "WaterBridge Portal - Supplier Request"
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onGridfilterExpressionsChanged = this.onGridfilterExpressionsChanged.bind(this);
        this.onToolbarRef = this.onToolbarRef.bind(this);

        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        const urlPara = this.getURLParameters(props.location)

        this.state = {
            supplierReq: [],
            supplierRequestKey: (urlPara.supplierRequestKey === null ? -1 : urlPara.supplierRequestKey),
            openDetails: !(urlPara.supplierRequestKey === null),
            modalDetails: false,
            isLoaded: false,
            startDate: (urlPara.startDate === null ? getFirstDayofMonth().toISOString() : urlPara.startDate), //(new Date((new Date()).getFullYear, (new Date()).getMonth, 1)).toISOString,
            //endDate: (urlPara.endDate === null ? new (Date().getdate() + 1).toISOString():urlPara.endDate),
            endDate: (urlPara.endDate === null ? this.addDays(Date(), 1).toISOString() : urlPara.endDate),
            selSupReqRec: null,
            windowHeight: window.innerHeight,

            // safetyType : urlPara.safetyType,
            // isQHSEAdmin:false,
            // isQHSEApprover: false,
            // isMVAUser: false,
            fromParamStartDate: urlPara.startDate,
            fromParamEndDate: urlPara.endDate,
            supplierCat: [],
            isSupplierCatLoaded: false,
            serviceTypes: [],
            isServiceTypesLoaded: false,

            entity: [],
            isEntityLoaded: false,
            statusKey: -1,
            isSuplierRequestAdmin: false,
            isSuplierRequestUser: false,
            user: props.user,
            stupidgridloaded: false
            // modalReport:false,
        }
    }

    // toggleReport = () => {
    //     this.setState({modalReport: !this.state.modalReport})
    // }

    // createReportParamter = () => {
    //     const {supplierRequestKey} = this.state
    //     // const reportPara = [{
    //     //     "name": "InvoiceKey",
    //     //     "value": invoiceKey  
    //     // }]
    //     const reportPara = [{
    //         "name": "supplierRequestKey",
    //         "value": supplierRequestKey
    //     }]

    //     // console.log(reportPara)
    //     return reportPara

    // }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    getURLParameters = (inputPara) => {
        var paraMeters = {
            startDate: null,
            endDate: null,
            // facilityKeys: null ,
            // safetyType: null,
            supplierRequestKey: null
        }


        // if ( !this.props.location==='undefined' || inputPara!=='undefined'){   
        //     var query
        //     if (!this.props.location==='undefined')
        //     {
        //          query = new URLSearchParams(this.props.location.search);

        //     }else{
        //          query = new URLSearchParams(inputPara);            
        //     }

        //     paraMeters={
        //         startDate: (query.get('startDate')!==null ? new Date(query.get('startDate')).toISOString():null) ,
        //         endDate:(query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString():null),
        //         supplierReq: query.get('key')
        //     }        
        // }

        var query
        if (inputPara !== undefined) {
            query = new URLSearchParams(inputPara.search);
            // console.log("query")
            // console.log(query.get('key'))

            paraMeters = {
                startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
                endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
                supplierRequestKey: query.get('key')
            }

            // console.log("paraMeters")
            // console.log(paraMeters.afeHeaderKey)
        }





        console.log(paraMeters)
        return paraMeters
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.supplierReq;

        if (this.toolbar !== null) {
            this.toolbar.targetGrid = this.grid

        }

    }

    onToolbarRef(toolbar) {
        this.toolbar = toolbar;
        if (this.toolbar) {
            this.toolbar.targetGrid = this.grid;
        }
    }

    saveGridLayout = () => {
        if (this.grid !== undefined) {
            //this.state({saveLayout:this.grid.saveLayout()})
            const { user } = this.state
            console.log("user")
            console.log(user)
            const addLayoutDTO = {
                "applicationKey": 20,
                "userName": user.account.userName,
                "layoutName": "SupplierRequest",
                "layoutData": this.grid.saveLayout(),
                "createdBy": user.account.userName
            }
            let myURI = apiURL + 'AppLayout/AddLayout'
            fetch(myURI, apiPOSTHeader(addLayoutDTO))
                .then(async response => {
                    if (!response.ok) {
                        const rspData = response.text();
                        var error = (rspData && rspData.message) || response.status;

                        return Promise.reject(error)
                    } else {
                        console.log("i am here ")
                        //this.closeForm(true)
                    }
                }).catch(error => {
                    console.log("PUT error: " + error)
                })


        }
    }

    loadGridLayout = () => {
        if (this.grid !== undefined) {

            const { user } = this.state
            var myURI = apiURL + 'AppLayout/Grid/20/' + user.account.userName + '/SupplierRequest'
            //var myURI = apiURL + 'api/AppLayout/Grid'
            console.log(myURI)
            //myURI = apiURL + 'WOTracker/WO'
            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    console.log(json)
                    if (json.length > 0) {
                        console.log("json")
                        this.grid.loadLayout(json[0].layoutData)

                    }
                    //if (json.length() > 0) {

                    //}

                })

            this.setState({ stupidgridloaded: true })
            this.openDetailsFromKey()
        }
    }





    toggleDetails = () => {
        // console.log("Modal: " + this.state.modal)
        this.setState({ modalDetails: !this.state.modalDetails })

    }

    onClosedModal() {
        this.setState({
            supplierRequestKey: -1,
            selSupReqRec: null,
            statusKey: -1,
            openDetails: false

        }
            ,

            this.getSupplierReqData()
        )

    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)

            this.setState({
                supplierRequestKey: dataItem.supplierRequestKey,
                selSupReqRec: dataItem,
                statusKey: dataItem.statusKey
            })
        }


    }

    onGridfilterExpressionsChanged(s, e) {
        const { supplierRequestKey } = this.state
        const searchText = window.$searchText.toLowerCase()
        if (supplierRequestKey > 0 && searchText.length === 0) {
            this.setState({ supplierRequestKey: -1 })
        }


    }


    refreshData = () => {
        this.saveGridLayout()
        this.getSupplierReqData()
    }

    getSupplierReqData = () => {

        //console.log(this.state.startDate);
        // const { facilityKeys,
        //     fromParamStartDate, fromParamEndDate, openDetails, supplierRequestKey } = this.state
        // console.log("safetyType");
        // console.log(safetyType);
        let startD = this.state.startDate === null ? '2019-01-01T06:00:00.000Z' : this.state.startDate.toString()
        let endD = this.state.endDate === null ? '2099-01-01T06:00:00.000Z' : this.state.endDate.toString()


        // let myURI = apiURL + 'SafetyObservation/startdate/' + 
        //                      startD.toString().substring(0, 10) + 
        //                       '/enddate/' + endD.toString().substring(0, 10) 
        //                       + '/AzureAD/' + this.props.user.account.userName;

        //let myURI = apiURL + 'AP/SupplierRequest/AzureAD/' + this.props.user.account.userName;

        let myURI = apiURL + 'AP/SupplierRequestByDatebyUser/startdate/' +
            startD.toString().substring(0, 10) +
            '/enddate/' + endD.toString().substring(0, 10)
            + '/AzureAD/' + this.props.user.account.userName;


        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    supplierReq: json,
                    // safetyObs:  json.filter(rec => ((safetyType===null ? true : 
                    //                                (rec.safetyTypeKey === safetyType))) &&
                    //                                ((fromParamStartDate===null && fromParamStartDate===null) ? true : 
                    //                                 (rec.createdDatetime >= fromParamStartDate && rec.createdDatetime <= fromParamEndDate)))
                    //                 .sort((a, b) => ((a.statusKey===5 ? 3 : a.statusKey) > (b.statusKey===5 ? 3 : b.statusKey)) ? 1:-1),
                    //supplierRequestKey: openDetails?supplierRequestKey:-1
                })

            }); console.log(myURI)


    }
    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        // this.getIsQHSEadmin()
        // this.getIsQHSEApprover()
        // this.getIsMVAUser()

        this.getSupplierCat()
        this.getServiceTypes()
        this.getSupplierEntity()
        this.getIsSuplierRequestadmin()
        this.getIsSuplierRequestUser()
        this.getSupplierReqData()

    }
    componentDidUpdate() {
        const { stupidgridloaded } = this.state
        if (!stupidgridloaded) {
            this.loadGridLayout()

        }


    }


    openDetailsFromKey = () => {
        const { openDetails } = this.state
        if (openDetails) {
            var aaaa = parseInt(this.state.supplierRequestKey)
            aaaa = aaaa + 1
            aaaa = aaaa - 1

            // selSupReqRec: dataItem,
            // statusKey: dataItem.statusKey

            const selReq = this.state.supplierReq.filter(a => a.supplierRequestKey === parseInt(this.state.supplierRequestKey))
            //const selReq = this.state.supplierReq.filter(a => a.statusKey === 7)

            if (selReq.length > 0) {
                this.setState({
                    selSupReqRec: selReq[0],
                    statusKey: selReq[0].statusKey
                    // afeNo: selAfe[0].afeNo,
                })
            }
            this.toggleDetails()
        }
    }

    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue

        });

    }

    getSupplierEntity = () => {
        let myURI = apiURL + 'AP/SupplierRequest/Entity'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isEntityLoaded: true,
                    entity: this.createSupplierEntityList(json)
                })
            });
    }

    createSupplierEntityList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.supplierReqEntityDesc
            e.label = e.supplierReqEntityDesc
            tempList.push(e)
        })
        return tempList
    }

    getSupplierCat = () => {
        let myURI = apiURL + 'AP/SupplierRequest/SupplierCategory'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isSupplierCatLoaded: true,
                    supplierCat: this.createSupplierCatList(json)
                })
            });

    }

    getServiceTypes = () => {
        let myURI = apiURL + 'AP/SupplierRequest/ServiceTypes'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isServiceTypesLoaded: true,
                    serviceTypes: this.createServiceTypesList(json)
                })
            });
    }

    createSupplierCatList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.supplierReqCatDesc
            e.label = e.supplierReqCatDesc
            tempList.push(e)
        })

        return tempList
    }

    createServiceTypesList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.serviceKey
            e.label = e.serviceDesc
            tempList.push(e)
        })

        return tempList
    }



    dSupReqDTO = (status) => {
        const { supplierRequestKey, user } = this.state

        const tempDTO = {
            "SupplierRequestKey": supplierRequestKey,
            "modifiedBy": getUserADAccount(user.account.userName)
        }
        return tempDTO
    }





    deleteSupplierReq = (bCloseForm = false) => {
        const deleteSupReqDTO = this.dSupReqDTO()
        let myURI = apiURL + 'AP/SupplierRequest/Delete'

        fetch(myURI, apiPOSTHeader(deleteSupReqDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    return Promise.reject(error)
                } else
                    if (bCloseForm) {
                        this.toggleDetails()
                    }
                this.setState({
                    supplierRequestKey: -1,
                    selSupReqRec: null,
                    statusKey: -1,
                })
                this.getSupplierReq()
            }).catch(error => {
                console.log(error)
            })
    }





    getIsSuplierRequestadmin = () => {

        let myURI = apiURL + 'AP/IsSuplierRequestAdmin/' + this.props.user.account.userName;

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isSuplierRequestAdmin: (json.isSuplierRequestAdmin != 0)
                })
            });
    }

    getIsSuplierRequestUser = () => {

        let myURI = apiURL + 'AP/IsSuplierRequestUser/' + this.props.user.account.userName;

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isSuplierRequestUser: (json.isSuplierRequestUser != 0)
                })
            });
    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.statusKey === 4) {
                content.style.background = "#f0f5b0";
            } else if (item.statusKey === 5 || item.statusKey === 7) {
                content.style.background = "#F3B5A2";
            } else if (item.statusKey === 6) {
                content.style.background = "#b8f2b1";
            }
            else {
                content.style.background = "transparent";
            }
        }

        switch (s.field) {
            case 'entity':
                link.textContent = item.entity;
                break;
            case 'supplierReqID':
                link.textContent = item.supplierReqID;
                break;
            case 'supplierName':
                link.textContent = item.supplierName;
                break;
            case 'statusDesc':
                link.textContent = item.statusDesc;
                break;
            case 'supplierCategory':
                link.textContent = item.supplierCategory;
                break;
            case 'contactName':
                link.textContent = item.contactName;
                break;
            case 'phoneNo':
                link.textContent = item.phoneNo;
                break;
            case 'emailAddress':
                link.textContent = item.emailAddress;
                break;
            case 'serviceTypeDesc':
                link.textContent = item.serviceTypeDesc;
                break;
            case 'isOffSiteDesc':
                link.textContent = item.isOffSiteDesc;
                break;
            case 'scopOfWork':
                link.textContent = item.scopOfWork;
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;

            case 'createdDatetime':
                link.textContent = ""
                if (item.createdDatetime !== null) {
                    var date = new Date(item.createdDatetime);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;

            default:

        }

    }



    newSupReq = () => {
        this.setState({
            supplierRequestKey: -1,
            selSupReqRec: null,
            statusKey: -1,
            modalDetails: !this.state.modalDetails
        }
            // this.toggle()
        )
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "SupplierRequest");
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");


            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
            content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.reportDate).toLocaleDateString("es-PA");
    }







    render() {
        var { isLoaded, supplierReq, windowHeight } = this.state;
        const { supplierRequestKey, selSupReqRec, statusKey, isServiceTypesLoaded, serviceTypes,
            isSupplierCatLoaded, supplierCat, isEntityLoaded, entity, isSuplierRequestAdmin, isSuplierRequestUser } = this.state;
        const gridHeight = "62vh" // (windowHeight * 0.5) + "px" // update by HN 04-11-2023 - used vh instead of gridHeight
        const searchText = window.$searchText.toLowerCase()

        // console.log("isQHSEAdmin")
        // console.log(isQHSEAdmin)
        //if (isMVAUser) return <h1> yes </h1> 

        if (!isSupplierCatLoaded) {
            return (<div> <h4>Loading Supplier Request Category .....</h4></div>)
        } else
            if (!isEntityLoaded) {
                return (<div> <h4>Loading Supplier Request Entity.....</h4></div>)
            } else
                if (!isLoaded) {
                    return (<div> <h4>Loading Supplier Request Data .....</h4></div>)
                } else
                    if (!isServiceTypesLoaded) {
                        return (<div> <h4>Loading Service Types .....</h4></div>)
                    }



        return (<div>
            <FormGroup>
                <Row form>
                    <h2> Supplier Request | </h2>
                    <Button outline
                        color="secondary"
                        className="btn-no-border"
                        onClick={this.refreshData}>
                        <img
                            src={Refresh}
                            alt="Refresh"
                            style={{ width: "30px", margin: "0 5px 0 0" }} />
                        Refresh
                    </Button>



                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.saveWorkbook}>
                        <img
                            src={Excel}
                            alt="excel"
                            style={{ width: "50px", margin: "0 0 0 0" }} />
                        Export To Excel
                    </Button>{' '}





                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={this.toggleDetails}
                        disabled={supplierRequestKey < 0}
                    >
                        <img
                            src={Revise}
                            alt="details"
                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                        View Details
                    </Button>{' '}

                    {/* <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.toggleReport}
                                   disabled={supplierRequestKey < 0}
                                >
                                    <img
                                        src={Report}
                                        alt="Report"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Report
                                </Button>{' '} */}

                    <Button outline color="secondary"
                        className="btn-no-border"
                        onClick={(e) => this.newSupReq()}>
                        <img
                            src={Plus}
                            alt="new"
                            style={{ width: "25px", margin: "0 5px 0 0" }} />
                        New
                    </Button>{' '}


                </Row>
                <ColoredLine color="grey" />
            </FormGroup>

            <FormGroup>
                <Row >
                    <Col md={3}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                            Start Date
                        </Label>
                        <DatePicker value={this.state.startDate}
                            onChange={(v, f) => this.handleChange(v, f)} />
                    </Col>
                    <Col md={3}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                            End Date
                        </Label>
                        <DatePicker value={this.state.endDate}
                            onChange={(v, f) => this.handleEndDateChange(v, f)} />

                    </Col>

                </Row>
            </FormGroup>

            <FormGroup>
                <IgrDataGridToolbar
                    ref={this.onToolbarRef}
                    toolbarTitle=""
                    columnChooser="true"


                />
                <IgrDataGrid
                    ref={this.onGridRef}
                    height={gridHeight}
                    // dataSource={safetyObs}
                    dataSource={supplierReq.filter(rec => ((rec.entity || '').toLowerCase().includes(searchText)) ||
                        (rec.supplierReqID || '').toLowerCase().includes(searchText) ||
                        (rec.supplierName || '').toLowerCase().includes(searchText) ||
                        (rec.statusDesc || '').toLowerCase().includes(searchText) ||
                        (rec.supplierCategory || '').toLowerCase().includes(searchText) ||
                        (rec.contactName || '').toLowerCase().includes(searchText) ||
                        (rec.phoneNo || '').toLowerCase().includes(searchText) ||
                        (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                        (rec.createdByName || '').toLowerCase().includes(searchText) ||
                        (rec.comments || '').toLowerCase().includes(searchText) ||
                        (rec.scopOfWork || '').toLowerCase().includes(searchText))}

                    autoGenerateColumns="false"
                    selectionMode="SingleRow"
                    selectedKeysChanged={this.onGridRowSelected}
                // reactsToFilterChanges="true"
                // filterExpressionsChanged={this.onGridfilterExpressionsChanged}
                //cellBackground={"#ffbfbf"}
                >


                    <IgrTemplateColumn field="entity" headerText="Entity " width="*>180"
                        cellUpdating={this.onStatusCellUpdating}
                    />
                    <IgrTemplateColumn field="supplierReqID" headerText="Supplier Request ID" width="*>180"
                        cellUpdating={this.onStatusCellUpdating}
                    />
                    <IgrTemplateColumn field="supplierName" headerText="Supplier Name"
                        width="*>240"
                        cellUpdating={this.onStatusCellUpdating}
                    />
                    <IgrTemplateColumn field="statusDesc" headerText="Request Status"
                        width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="supplierCategory" headerText="Category"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="contactName" headerText="Contact Name"
                        width="*>250"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="phoneNo" headerText="Phone No"
                        width="*>250"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="emailAddress" headerText="Email Address"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTemplateColumn field="serviceTypeDesc" headerText="Service Type"
                        width="*>140"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTemplateColumn field="isOffSiteDesc" headerText="Is Off Site"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTemplateColumn field="scopOfWork" headerText="Scop Of Work"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTemplateColumn field="createdByName" headerText="Requested By"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTemplateColumn field="createdDatetime" headerText="Created Date"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />
                    <IgrTextColumn field="comments" headerText="Comments"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrNumericColumn field="supplierRequestKey" isHidden />
                </IgrDataGrid>
            </FormGroup>

            <div>
                <Modal isOpen={this.state.modalDetails}
                    toggle={() => this.toggleDetails()}

                    onClosed={() => this.onClosedModal()}
                    size={supplierRequestKey < 0 ? "lg" : "xl"}
                    centered>
                    <ModalHeader toggle={() => this.toggleDetails()}> {(supplierRequestKey < 0 ? "New Supplier Request" :
                        "Edit Supplier Request - " + (selSupReqRec === null ? "" : selSupReqRec.supplierReqID))}</ModalHeader>
                    <ModalBody>
                        <SupplierRequestCRUD
                            supplierRequestKey={supplierRequestKey}
                            selSupReqRec={selSupReqRec}
                            user={this.props.user}
                            toggle={this.toggleDetails}
                            deleteSupReq={(e) => this.deleteSupplierReq(e)}
                            supplierCat={supplierCat}
                            serviceTypes={serviceTypes}
                            entity={entity}
                            isSuplierRequestAdmin={isSuplierRequestAdmin}
                            isSuplierRequestUser={isSuplierRequestUser}
                        // refreshData={this.getSupplierReqData}
                        >

                        </SupplierRequestCRUD>
                    </ModalBody>
                </Modal>
            </div>

            {/* <div>
                <Modal isOpen={this.state.modalReport}
                    toggle={() => this.toggleReport()}
                    //style={{ margin: 0, flex: 1 }}
                    //onClosed={() => this.getSupplierReq()}
                    size={"xl"}//{supplierRequestKey < 0 ? "lg" : "xl"}
                    //contentClassName="full-screen-modal"
                    centered
                    >
                    <ModalHeader toggle={() => this.toggleReport()}> Safety Observation Report </ModalHeader>
                    <ModalBody>
                        <PowerBIReportViewer
                            reportName = {"SafetyObservation"} //{"R9Invoice"}
                            reportParameters = {this.createReportParamter()}
                            //invoiceKey={invoiceKey}                            
                            toggle={this.toggleReport}
                            //refreshData={this.getInvovice}
                        >

                        </PowerBIReportViewer>
                    </ModalBody>
                </Modal>
            </div> */}

        </div>)
    }




}