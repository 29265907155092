import React, { Component, useEffect, useState } from 'react';

import { Col, Row, Button, FormGroup, Label, Form } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import PlusIcon from '../../resources/plus.svg'
import {
    apiURL, apiHeader, apiPUTHeader,
    REACT_APP_MAPS_API_KEY, apiPOSTHeader, apiDELETEHeader
} from '../../resources/apiURL';

import '../../resources/mapAssetShape.css'

import { Loader } from "@googlemaps/js-api-loader"

var map = ''
var dataLayer = ''
const additionalOptions = {};

const loader = new Loader({
    apiKey: REACT_APP_MAPS_API_KEY,
    version: "weekly",
    ...additionalOptions,
});

export default class AssetMapViewSimple extends Component {
    static displayName = AssetMapViewSimple.name;

    constructor(props) {
        super(props)

        this.state = {
            assetCoor: props.assetCoor,
            showLabel: (props.showPipeline === undefined ? false : props.showLabel),
            showPipeline: (props.showPipeline === undefined ? true : props.showPipeline),
            kml_URLs: props.basinKMLFile,
            showSubsystem: props.showSubsystem,
            subSystemKMLFile: props.subSystemKMLFile
            // ,
            // kml_URLs: [{
            //     basin_name: 'ARK',
            //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRARKRoutes.kml'
            // },
            // {
            //     basin_name: 'NDB',
            //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRNDBRoutes.kml'
            // },
            // {
            //     basin_name: 'EVX',
            //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBREVXRoutes.kml'
            // },
            // {
            //     basin_name: 'SDB',
            //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRSDBRoutes4.kml'
            // },
            // {
            //     basin_name: 'SDB1',
            //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRSDBRoutes5.kml'
            // }]
            // ,
        }
    }

    saveMapPosition = () => {

        if (map !== undefined && map !== '') {
            var mapzoom = map.getZoom();

            var mapcenter = map.getCenter();

            var maplat = mapcenter.lat();

            var maplng = mapcenter.lng();

            var maptypeid = map.getMapTypeId();

            var cookiestring = maplat + "_" + maplng + "_" + mapzoom + "_" + maptypeid;

            var exp = new Date();     //set new date object

            exp.setTime(exp.getTime() + (1000 * 60 * 60 * 24 * 30));     //set it 30 days ahead

            this.setCookie("WBRMapviewLocationSimple", cookiestring, exp);

        }

    }

    loadLastMapPosition = () => {

        if (map !== undefined && map !== '') {


            var loadedstring = this.getCookie("WBRMapviewLocationSimple");

            if (loadedstring !== undefined) {
                console.log("loadedstring")
                console.log(loadedstring)


                var splitstr = loadedstring.split("_");

                if (!isNaN(splitstr[0]) && !isNaN(splitstr[1])) {
                    var latlng = new window.google.maps.LatLng(parseFloat(splitstr[0]), parseFloat(splitstr[1]));

                    map.setCenter(latlng);
                }

                if (!isNaN(splitstr[2])) {
                    map.setZoom(parseFloat(splitstr[2]));
                }


                if (splitstr[3] !== "NaN" && splitstr[3] !== "undefined") {
                    map.setMapTypeId(splitstr[3])
                }

            }
        }
        //mapDiv.innerHTML = 'Loaded';

    }



    setCookie = (name, value, expires) => {

        document.cookie = name + "=" + escape(value) + "; path=/" + ((expires == null) ? "" : "; expires=" + expires.toGMTString());

    }

    getCookie = (c_name) => {

        if (document.cookie.length > 0) {

            var c_start = document.cookie.indexOf(c_name + "=");

            if (c_start != -1) {

                c_start = c_start + c_name.length + 1;

                var c_end = document.cookie.indexOf(";", c_start);

                if (c_end == -1) c_end = document.cookie.length;

                return unescape(document.cookie.substring(c_start, c_end));

            }

        }

        return "";

    }
    async initMap(data) {
        const { showLabel, showPipeline, showSubsystem } = this.state
        // The location of Uluru
        //const position = { lat: 29.749907, lng: -95.358421 };
        console.log("Mapp ---- Data")
        console.log(data)
        // Request needed libraries.
        //@ts-ignore
        loader.importLibrary('maps').then(async () => {
            const { Map, InfoWindow, Point, KmlLayer } = await window.google.maps.importLibrary("maps");
            const { AdvancedMarkerElement, PinElement, Marker } = await window.google.maps.importLibrary("marker");
            //const {KmlLayer } =  await window.google.maps.importLibrary("KmlLayer");

            // The map, centered at Uluru
            //if (map.data === undefined) {
                map = new Map(document.getElementById("mapsimple"), {
                    zoom: 8,
                    center: {
                        lat: (data.length > 0 ? data[0].lat : 29.740700),
                        lng: (data.length > 0 ? data[0].lng : -95.463600)
                    },
                    mapTypeId: 'hybrid',
                    mapId: "WBR_Maps",
                });
            //}
            // if (showSubsystem) {
            //     this.state.subSystemKMLFile.map(e => {
            //         // console.log("sub system")
            //         // console.log(e.url)
            //         const kmlLayer = new KmlLayer(e.url, {
            //             suppressInfoWindows: false,
            //             preserveViewport: true,
            //             map: map,
            //         })
            //     })
            // }

            // /** load kml files */
            // if (showPipeline) {
            //     this.state.kml_URLs.map(e => {
            //         const kmlLayer = new KmlLayer(e.url, {
            //             suppressInfoWindows: false,
            //             preserveViewport: true,
            //             map: map,
            //         })
            //     })
            // }

            const infoWindow = new InfoWindow({
                content: "",
                disableAutoPan: true,
            });

            // map.addListener("center_changed", () => {
            //     // 3 seconds after the center of the map has changed, pan back to the
            //     // marker.
            //     //console.log("center_changed")
            //     this.saveMapPosition()
            // });

            // map.addListener("zoom_changed", () => {
            //     //console.log("Zoom change")
            //     this.saveMapPosition()
            // });

            map.addListener("resize", () => {
                window.google.maps.event.trigger(map, "resize");
            })

            data.map((e, i) => {
                const title = e.asset_name;
                const pos = { lat: e.lat, lng: e.lng }

                const pin = new PinElement({
                    glyphColor: "white",
                    background: "#006994",
                    scale: 0.8,
                });

                const assetShape = document.createElement("div");
                assetShape.className = "rectangle-blue"; //(e.html_class_name)
                assetShape.textContent = "";

                //  Make first div    
                const div_form_group = document.createElement('div');
                div_form_group.setAttribute("class", "form-group");

                if (showLabel) {
                    const label = document.createElement('label');
                    label.setAttribute('class', 'asset-title');
                    label.innerHTML = title
                    div_form_group.appendChild(label);
                }

                //div_form_group.appendChild(assetShape);

                const marker = new AdvancedMarkerElement({
                    position: pos,
                    map,
                    content: div_form_group, //assetShape, //pin.element,
                    title: title, //  `${i + 1}. ${title}`
                    //collisionBehavior: window.google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
                });

                console.log("marker")
                console.log(marker)
                // markers can only be keyboard focusable when they have click listeners
                // open info window when marker is clicked
                marker.addListener("click", () => {
                    infoWindow.setContent(this.getAssetTitle(title, e.html_asset_detail));
                    infoWindow.open(map, marker);

                });

            });

            //this.loadLastMapPosition()
           

        
        })


    }

    getAssetTitle = (title, asset_details) => {
        return (
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h4 id="firstHeading" class="firstHeading">' + title + '</h4>' +
            '<div id="bodyContent">' +
            asset_details +
            "</div>" +
            "</div>"
        )
    }

    loadAssetMap = () => {
        this.initMap(this.state.assetCoor)
    }
    componentDidMount() {
        console.log("Asset MapviewSimple Form reload")
        this.loadAssetMap() 

        //window.google.maps.event.trigger(map, "resize");
        
    }

    render() {
        return (
            <div>
                <FormGroup>
                    <div id='mapContainers'  >
                        <div style={{
                            width: '300px',
                            height: '300px',
                            padding: ("0px"),
                            margin: "0px"
                        }} id='mapsimple' />
                    </div>
                </FormGroup>
            </div>
        )
    }

    
}
