import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';


const ConfirmModal = (props) => {
   

    const {
        outline,
        color,
        className,
        formName,
        message,
        image,
        hidden,
        buttonLabel,
        id,
        style,
        disabled
    } = props

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const toggleYes = () => {
        setModal(!modal)
        props.onClickYes();
    }

    const onClosed = () => {

        if (props.onClosed!==undefined)
        {
            props.onClosed();
        }        
    }

    const toggleNo = () => {
        toggle()
        
        if (props.onClickNo!==undefined)
        {
            props.onClickNo()
        }
    }

    return(
        <div>
            <Button outline = {outline} 
                    style={style}
                    color={color}
                    className={className}
                    hidden={hidden}
                    disabled={disabled}
                    onClick={toggle} >
                {image}
                {buttonLabel}
                
            </Button>

            <Modal isOpen={modal} 
                   toggle={toggle}
                   onClosed={onClosed} >
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>

                   {message}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleYes}>Yes</Button>
                    <Button color="primary" onClick={toggleNo}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default ConfirmModal;