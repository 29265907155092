import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap'; 
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
// import ConfirmModal from '../../operation/ConfirmModal'
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import Plus from '../../resources/plus.svg'
// import Refresh from '../../resources/refresh.svg'
// import TrashBin from '../../resources/trash.svg'
// import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import PowerBIReportViewer from '../../resources/PowerBIReportViewer'; //'../resources/PowerBIReportViewer'
//import DocumentBrowser from '../../resources/DocumentBrowser';

// import NumberFormat from 'react-number-format';

// import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';


export default class AFEAttachment extends Component {
    static displayName = AFEAttachment.name;

    constructor(props) {
        super(props)

        this.state = {
            activeTab: '1',
            afeHeaderKey: props.afeHeaderKey,
            user: props.user,
            isFormReadOnly: false,
            isDataLoaded:false,
            isAFEAdmin:props.isAFEAdmin,
            isAFEManager: props.isAFEManager,
            docCount:[],
            closeoutStatusKey: props.closeoutStatusKey,
            statusKey: props.statusKey,
            dropdownOpenManage: false,
            modalReport: false,
        }

    }
    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    getAFEDocCount = () => {
        const {afeHeaderKey} = this.state

        let myURI = apiURL + 'AFE/Documents/Count/' + afeHeaderKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    docCount: json,
                    isDataLoaded:true
                })

                console.log("json")
                console.log(json)
            });
    }

    componentDidMount(){
        this.getAFEDocCount()
    }

    dropdownOpenManage = () => {
        this.setState({ dropdownOpenManage: !this.state.dropdownOpenManage })
    }

    async getAFEReport(reportName){
        this.setState({modalReport: !this.state.modalReport,reportName: reportName})
    }

    toggleReport = () => {
        this.setState({modalReport: !this.state.modalReport})
    }

    createReportParamter = () => {
        const { reportName, afeHeaderKey} = this.state
        let reportPara = [{ "name": "AFEHeaderKey", "value": afeHeaderKey }]; 
        return reportPara
    }

    render() {
        const { activeTab, afeHeaderKey, isFormReadOnly,docCount,isDataLoaded,
            isAFEAdmin,isAFEManager ,closeoutStatusKey,statusKey,reportName} = this.state

        if (!isDataLoaded){
            return (<div>
                <h3>Loading Document data.....</h3>
            </div>)
        }else
        return (<div>
            <Row form > 
                <Col sm={8}>  
                    <Button color="secondary" outline className={"float-left"}
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            hidden={(afeHeaderKey <= 0 || statusKey <= 2)}
                            onClick={() => this.getAFEReport('AFEApp')}>AFE Approval Report</Button>
                    <Button color="secondary" outline className={"float-left"}
                            style={{ marginTop: "-10px", marginBottom: "5px", marginLeft: "5px" }}
                            hidden={(afeHeaderKey <= 0 || closeoutStatusKey <= 2)}
                            onClick={() => this.getAFEReport('AFECloseout')}>AFE Closeout Report</Button>
                </Col>
            </Row>
            <FormGroup style={{ marginTop: "10px", marginLeft: "-15px" }}>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                {"Economics (" + (docCount.length >0 ? docCount[0].ecoDocCount : 0) + ")"}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { this.toggleTab('2'); }}
                            >
                                {"Flowback Plan/Hydraulics (" + (docCount.length >0 ? docCount[0].flowBackDocCount : 0) + ")"}

                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { this.toggleTab('3'); }}
                            >
                                {"Cost Justification/Quotes (" + (docCount.length >0 ? docCount[0].costDocCount : 0) + ")"}

                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => { this.toggleTab('4'); }}
                            >
                                {"Maps (" + (docCount.length >0 ? docCount[0].mapDocCount : 0) + ")"}
                            </NavLink>
                        </NavItem>


                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '5' })}
                                onClick={() => { this.toggleTab('5'); }}
                            >
                                {"Engineering Drawings (" + (docCount.length >0 ? docCount[0].engDocCount : 0) + ")"}

                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '6' })}
                                onClick={() => { this.toggleTab('6'); }}
                            >
                                {"Other (" + (docCount.length >0 ? docCount[0].otherDocCount : 0) + ")"}

                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div><Row>
                                <Col md={12}>
                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                        disabled={isFormReadOnly}
                                        buttonColor="primary"
                                        formName="AFE Economics Documents"
                                        linkTableName={"topAFEHeader_Eco"}
                                        linkPrimaryKey={afeHeaderKey}
                                        //allowDelete={isAFEManager || isAFEAdmin}
                                        apiURL={apiURL + 'AFE/Documents/' + afeHeaderKey + '/Bucket/Eco'} />

                                </Col>
                            </Row></div>
                        </TabPane>

                        <TabPane tabId="2">
                            <div><Row>
                                <Col md={12}>
                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                        disabled={isFormReadOnly}
                                        buttonColor="primary"
                                        formName="AFE Flowback Plan/Hydraulics Documents"
                                        linkTableName={"topAFEHeader_Flowback"}
                                        linkPrimaryKey={afeHeaderKey}
                                        apiURL={apiURL + 'AFE/Documents/' + afeHeaderKey + '/Bucket/Flowback'} />

                                </Col>
                            </Row></div>
                        </TabPane>

                        <TabPane tabId="3">
                            <div><Row>
                                <Col md={12}>
                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                        disabled={isFormReadOnly}
                                        buttonColor="primary"
                                        formName="AFE Cost Justification/Quotes Documents"
                                        linkTableName={"topAFEHeader_Cost"}
                                        linkPrimaryKey={afeHeaderKey}
                                        apiURL={apiURL + 'AFE/Documents/' + afeHeaderKey + '/Bucket/Cost'} />

                                </Col>
                            </Row></div>
                        </TabPane>
                        <TabPane tabId="4">
                        <div><Row>
                                <Col md={12}>
                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                        disabled={isFormReadOnly}
                                        buttonColor="primary"
                                        formName="AFE Maps Documents"
                                        linkTableName={"topAFEHeader_Map"}
                                        linkPrimaryKey={afeHeaderKey}
                                        apiURL={apiURL + 'AFE/Documents/' + afeHeaderKey + '/Bucket/Map'} />

                                </Col>
                            </Row></div>
                        </TabPane>
                        <TabPane tabId="5">
                        <div><Row>
                                <Col md={12}>
                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                        disabled={isFormReadOnly}
                                        buttonColor="primary"
                                        formName="AFE Engineering Drawings Documents"
                                        linkTableName={"topAFEHeader_Engineering"}
                                        linkPrimaryKey={afeHeaderKey}
                                        apiURL={apiURL + 'AFE/Documents/' + afeHeaderKey + '/Bucket/Engineering'} />

                                </Col>
                            </Row></div>
                        </TabPane>
                        <TabPane tabId="6">
                        <div><Row>
                                <Col md={12}>
                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                        disabled={isFormReadOnly}
                                        buttonColor="primary"
                                        formName="AFE Other Documents"
                                        linkTableName={"topAFEHeader_Other"}
                                        linkPrimaryKey={afeHeaderKey}
                                        apiURL={apiURL + 'AFE/Documents/' + afeHeaderKey + '/Bucket/Other'} />

                                </Col>
                            </Row></div>
                        </TabPane>
                    </TabContent>

                </div>
                <div>
                        <Modal isOpen={this.state.modalReport}
                            toggle={() => this.toggleReport()}
                            size={"xl"}
                            centered
                            >
                            <ModalHeader toggle={() => this.toggleReport()}> {reportName == 'AFECloseout' ? 'AFE Closeout Report': "AFE Approval Report"} </ModalHeader>
                            <ModalBody>
                                <PowerBIReportViewer
                                    reportName = {reportName} 
                                    reportParameters = {this.createReportParamter()}
                                                        
                                    toggle={this.toggleReport}
                                
                                >

                                </PowerBIReportViewer>
                            </ModalBody>
                        </Modal>
                    </div>
            </FormGroup>
        </div>)
    }
}