import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import ModalYNName from './ModalYNName';
import FileManager from '../../resources/FileManager';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");





export default class FormJSAJobTask extends Component {
    static displayName = FormJSAJobTask.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state = {
            formJSAKey: props.formJSAKey,
            Data: [],
            isLoaded: false,
            isFormReadOnly: props.isFormReadOnly,
            modal: false,
            user: props.user,
            isAdmin: props.isAdmin,
            objectLinkKey: -1,
            linkType: -1,
            templateStep: null,
            invalidStep: false,
            //offlinekey: this.generateUniqueKey(),


            templateSequence: ' ',
            templateHazard: ' ',
            templateAction: ' ',
            cmtDate: new Date().toISOString(),
            //hidden: props.hidden,

            modalMsg: false,
            msgHeader: "",
            msgBody: "",

            validateDataItem: {
                sequence: true,
                toValidate: false
                // empShift:true
            }





        }

    }





    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.Data;
    }

    toggleOK = () => {
        if (this.saveData()) {
            this.setState({
                modal: !this.state.modal,
                templateStep: null,
                invalidStep: false,
                templateHazard: " ",
                templateAction: " ",

            })

        }
    }

    isNumeric = (str) => {
        // if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }



    validateData = () => {
        var { templateStep, templateSequence, templateHazard, templateAction, invalidStep } = this.state


        // if (!isNaN(templateStep) || !isNaN(parseFloat(templateStep))) {
        //     // templateStep = templateStep.trim()

        // }

        // if (!isNaN(templateStep) ) {
        //     // templateStep = templateStep.trim()
        //     i=i+1

        // }

        // if (!isNaN(parseFloat(templateStep))) {
        //     i=i+1

        // }

        if (!isFinite(String(templateStep)) || templateStep === '' || templateStep === null) {
            invalidStep = true
        }
        else {
            invalidStep = false
        }



        templateSequence = templateSequence.trim()
        templateHazard = templateHazard.trim()
        templateAction = templateAction.trim()


        if (invalidStep != true && templateSequence != "" && templateHazard != "" && templateAction != "") {
            return true
        } else {
            this.setState({ templateStep: templateStep, invalidStep: invalidStep, templateSequence: templateSequence, templateHazard: templateHazard, templateAction: templateAction })
        }
    }

    createDataForUpdateDTO = () => {
        const { objectLinkKey, templateStep, templateSequence, templateHazard, templateAction, user } = this.state
        const opsCmtDTO = {
            "objectLinkKey": objectLinkKey,
            "templateStep": templateStep,
            "templateSequence": templateSequence,
            "templateHazard": templateHazard,
            "templateAction": templateAction,

            // "modifiedBy": getUserADAccount(user.account.userName),
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return opsCmtDTO
    }

    updateData = () => {
        const uptDTO = this.createDataForUpdateDTO()
        // let myURI = apiURL + 'SafetyIncident/updateSafIncJSAJobTaske'

        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(uptDTO)
        }

        let myURI = apiURL + 'SafetyIncident/updateSafIncJSAJobTask'
        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    // return Promise.reject(error);
                    return false;
                }

                // console.log("No error here: " + rspData)

                // this.getInpsData()
                return true

            })
            .catch(error => {
                console.log("PUT Error: " + error);
                return false;
            })

        // fetch(myURI, apiPUTHeader(uptDTO))
        //     .then(async response => {
        //         const rspData = response.text();
        //         if (!response.ok) {
        //             console.log("Error return but you dont see")
        //             const error = (rspData && rspData.message) || response.status;
        //             //return Promise.reject(error);
        //             return false
        //         }

        //         console.log("No error here: " + rspData)
        //     })
        //     .catch(error => {
        //         console.log("PUT Error: " + error);
        //         return false
        //     })

        // return true
    }
    createDataForCreateDTO = () => {
        const { formJSAKey, templateStep, templateHazard, templateSequence, templateAction, user, linkType } = this.state

        const linetoAdd = {
            "formJSAKey": formJSAKey, //selectedValue.key,
            "templateStep": templateStep,
            "templateSequence": templateSequence,
            "templateHazard": templateHazard,
            "templateAction": templateAction,
            "CreatedBy": getUserADAccount(user.account.userName),
            "linkType": linkType

            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }

    saveData = () => {
        const { objectLinkKey } = this.state
        if (this.validateData()) {

            if (objectLinkKey > 0) {
                if (this.updateData()) {
                    this.setState({ objectLinkKey: -1 })
                }
            } else {
                const addDTO = this.createDataForCreateDTO()
                let myURI = apiURL + 'SafetyIncident/addSafIncJSAJobTask'

                const putReq = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(addDTO)
                }

                fetch(myURI, putReq)
                    .then(async response => {
                        if (!response.ok) {
                            const rspData = response.text();
                            //console.log("Error return but you dont see")
                            const error = (rspData && rspData.message) || response.status;
                            rspData.then(t => {
                                this.setState({
                                    msgBody: t,
                                    msgHeader: "Error",
                                    modalMsg: !this.state.modalMsg

                                })
                            })
                            // return Promise.reject(error);
                            return false;
                        }

                        // console.log("No error here: " + rspData)
                        this.getData()

                        // this.getInpsData()
                        return true

                    })
                    .catch(error => {
                        console.log("PUT Error: " + error);
                        return false;
                    })

                // fetch(myURI, apiPOSTHeader(addDTO))
                //     .then(async response => {
                //         const rspData = response.text();
                //         if (!response.ok) {
                //             console.log("Error return but you dont see")
                //             const error = (rspData && rspData.message) || response.status;
                //             //return Promise.reject(error);
                //             return false
                //         }

                //         console.log("No error here: " + rspData)
                //     })
                //     .catch(error => {
                //         console.log("PUT Error: " + error);
                //         return false
                //     })
            }
            return true
        }

        return false
    }


    createNewTemplateDTO = (templateName) => {
        const { formJSAKey, user } = this.state

        const linetoAdd = {
            "FormJSAKey": formJSAKey, //selectedValue.key,
            "TemplateName": templateName,
            "CreatedBy": getUserADAccount(user.account.userName),

            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }


    insertTemplate = (templateName) => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetJSATemplate'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.insertTemplateTF(templateName, json.filter(rec => rec.templateName === templateName.trim()).length > 0 ? true : false)
                // return json.filter(rec => rec.templateName === templateName).length > 0 ? true : false
                // this.setState({
                //     // isddTemplatesLoaded: true,
                //     ddTemplates: json
                // })
            });
    }



    insertTemplateTF = (templateName, exists) => {
        var cont = true

        if (exists) {
            if (!window.confirm('Job/task with that name already exists. Do you want to overwrite?')) {
                cont = false
            }

        }

        if (cont) {
            this.insertTemplateSQL(templateName)

        }




    }

    insertTemplateSQL = (templateName) => {
        const itemDTO = this.createNewTemplateDTO(templateName);
        console.log(itemDTO);
        let myURI = apiURL + 'SafetyIncident/addSafIncJSATemplatefromForm'



        const putReq = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemDTO)
        }

        fetch(myURI, putReq)
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg

                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                // this.getData()
                this.props.onClose();

                // this.setShowingAlert(true)


            })
            .catch(error => {
                console.log("PUT Error: " + error);
            })


    }




    onTextChange = (fieldName, fieldValue, e) => {
        switch (fieldName) {
            case "templateStep":
                var invalidStep
                if (!isFinite(String(fieldValue)) || fieldValue === '' || fieldValue === null) {
                    invalidStep = true
                }
                else {
                    invalidStep = false
                }


                this.setState({ templateStep: fieldValue, invalidStep: invalidStep });
                break;

            case "templateSequence":
                this.setState({ templateSequence: fieldValue });
                break;


            case "templateHazard":
                this.setState({ templateHazard: fieldValue });
                break;

            case "templateAction":
                this.setState({ templateAction: fieldValue });
                break;

            default:
        }





    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({ modal: !this.state.modal })
    }

    newData = () => {
        this.setState({
            templateStep: null,
            invalidStep: false,
            templateSequence: ' ',
            templateHazard: ' ',
            templateAction: ' ',
            linkType: this.generateUniqueKey(),
            objectLinkKey: -1
        })
        this.toggle()
    }

    generateUniqueKey() {

        const now = new Date();

        const year = now.getFullYear().toString().substr(-2); // Get last 2 digits of the year
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed; add 1
        const day = now.getDate().toString().padStart(2, '0');
        const hour = now.getHours().toString().padStart(2, '0');
        const minute = now.getMinutes().toString().padStart(2, '0');
        const second = now.getSeconds().toString().padStart(2, '0');
      
        return `${year}${month}${day}${hour}${minute}${second}`;
  
    }

    onGridRowSelected(s, e) {
        //console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            // this.setState({ selAFELine: dataItem })           
            this.setState({
                templateStep: dataItem.templateStep,
                invalidStep: false,
                templateSequence: dataItem.templateSequence,
                templateHazard: dataItem.templateHazard,
                templateAction: dataItem.templateAction,
                objectLinkKey: dataItem.objectLinkKey,
                linkType: dataItem.linkType
            })
        } else {
            this.setState({
                // templateStep: dataItem.templateStep,

                objectLinkKey: -1
            })
        }
    }

    editData = (key, date, text) => {

        this.toggle()
    }

    refreshData = () => {
        this.getData()
    }

    getData = () => {
        const { formJSAKey } = this.state
        let myURI = apiURL + 'SafetyIncident/getJSAJobTask/' + formJSAKey

        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    Data: json,
                    isLoaded: true
                }
                )
                this.validateDataForSubmit(false);

            });
    }

    validateDataForSubmit = (bSubmit) => {

        const { validateDataItem, Data } = this.state
        var bVal = true
        var valItem = validateDataItem
        // if (data[0].iunderstand === null || data[0].iunderstand == 0) {
        //     valItem.iunderstand = false
        //     bVal = false
        // }


        if (Data.length === 0) {
            valItem.sequence = false
            bVal = false

        }
        else {
            valItem.sequence = true
            bVal = true


        }
if (bSubmit) valItem.toValidate = true

        this.setState({ validateDataItem: valItem })

        return bVal

    }

    validateDataForSubmitMain = () => {
        var bVal = this.validateDataForSubmit(true)
       
        return bVal

    }





    componentDidMount() {
        this.props.refreshJobTask(this.getData);
        this.props.validateJSAJobTaskForSubmit(this.validateDataForSubmitMain);

        // this.props.validateJobTask();
        this.getData();
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

        }

        // switch (s.field) {
        //     case 'datetime':

        //         var date = new Date(item.datetime);
        //         link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;

        //     default:

        // }

    }
    createDataForDeleteLine = () => {
        const { objectLinkKey } = this.state


        const dataForDeleteDTO = {
            "ObjectLinkKey": objectLinkKey,
            // "DeletedByAD": getUserADAccount(user.account.userName)
            // ,
            // "StatusKey": data[0].statusKey
        }

        return dataForDeleteDTO;
    }


    deleteLine = () => {

        const createDataforDelete = this.createDataForDeleteLine()


        let myURI = apiURL + 'SafetyIncident/deleteSafIncJSAJobTask'
        fetch(myURI, apiDELETEHeader(createDataforDelete))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    //console.log("Error return but you dont see")
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

                console.log("No error here: ")
                this.getData()

                this.setState({ objectLinkKey: -1 })
                // this.setShowingAlert(true)
                //this.props.refreshData()
            })

            .catch(error => {
                console.log("PUT Error: " + error);
            })

    }




    render() {
        const { user, isAdmin, formJSAKey, Data, isLoaded, objectLinkKey, templateStep, invalidStep, 
            templateSequence, templateHazard, templateAction, msgHeader, msgBody, validateDataItem, isFormReadOnly, linkType} = this.state
            //const linkTypetoIns = linkType<0 ?this.generateUniqueKey() : linkType;

           
            
        if (!isLoaded) {
            return (<div>
                Loading Data ......
            </div>)
        } else 
            return (<div>
                {/* <FormGroup row>
                    <Label>
                        {validateDataItem.sequence ? "true" : "false"}
                        ddddd {data[0].statusKey} {user} isReadOnly:<b>{isReadOnly ? "true" : "false"}</b>   isUser:<b>{isUser ? "true" : "false"}</b>   isAdmin:<b>{isAdmin ? "true" : "false"}</b>    isApprover:<b>{isApprover ? "true" : "false"}</b>  isEditApprover:<b>{isEditApprover ? "true" : "false"}</b>  
                        ddddtl {objectLinkKey} templateStep:{templateStep}-- cmtDate:{cmtDate}
                    </Label>
                </FormGroup> */}

                <Row form style={{ marginLeft: "-18px", marginTop: "-4px" }}
                    className={(!validateDataItem.sequence && validateDataItem.toValidate ? "form-error" : "")}
                >
                    <Col md={8} >
                        {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}

                        <Button color="primary" outline className="float-left"
                            style={{ height: "35px", marginLeft: "0px" }}
                            disabled={objectLinkKey < 0}
                            onClick={() => this.toggle()}
                        >Details</Button>

                        <Button color="primary" outline className="float-left"
                            style={{ height: "35px", marginLeft: "10px" }}

                            hidden={isFormReadOnly}
                            onClick={() => this.newData()}
                        >Add</Button>

                       

                        <ConfirmModal outline={true}
                            color="danger"
                            className="float-left"
                            style={{ height: "35px", marginLeft: "10px" }}
                            buttonLabel="Delete"
                            // hidden={!(data[0].statusKey == 0 && isAdmin)}
                            hidden={isFormReadOnly}
                            disabled={objectLinkKey < 0}
                            formName="Confirm"
                            // id={safetyObservationKey}
                            message="Are you sure you want to delete? (cannot be undone)"
                            // onClickYes={(e) => this.deleteLine()}
                            onClickYes={this.deleteLine}
                        />
                        

                        <ModalYNName outline={false}
                            color="success"
                            className="float-left"
                            style={{ height: "35px", marginLeft: "20px" }}
                            buttonLabel="Save as Job/Task"
                            // hidden={!((data[0].statusKey == 3) && (isApprover || isEditApprover))}
                            hidden={!isAdmin || isFormReadOnly}
                            disabled={Data.length===0}

                            // invalid={!invalidDataItem.reviewCommentCont}
                            // value={invalidDataItem.reviewCommentText}
                            // defaultValue = {invalidDataItem.reviewCommentText}
                            // disabled= {dataSelected.length>0 ? "false" : "true"}
                            // disabled="false"
                            //inputKey={-1}
                            // dataSelected={dataSelected}
                            formName="Confirm"
                            //   id={meterValidationKey}
                            message="Please enter Job/Task:                 "
                            //   meterValidation={meterValidation}
                            // onClosed={(e) => this.removeErrorZero()}
                            // onChangeTest={(e) => this.onTextChange("reviewComment", e.target.value, e)}
                            onClickYes={(e) => this.insertTemplate(e)}
                        // image={<img
                        //     src={RemoveIcon}
                        //     style={{ width: "25px", height: "25px", margin: "0 0 0 0" }} />}

                        />
                        {/* <Label>   *Please add some Job Steps </Label> */}



                        {/* <div class= {!validateDataItem.sequence ? "text-danger": "d-none"}>     */}

                    </Col>
                    <Col md={8}   >
                        <div
                            // class= {!validateDataItem.sequence ? "d-none": "text-danger"} 
                            class={(!validateDataItem.sequence && validateDataItem.toValidate ? "text-danger" : "d-none")}>
                            *Please add some Job Steps
                        </div>

                    </Col>

                </Row>

                {/* <ColoredLine color="grey" /> */}
                {/* <FormGroup row>
                    <Col md={12}>
                        <div>
                            <Label>

                                dddd5 {formJSAKey}
                            </Label>


                        </div>
                    </Col>

                </FormGroup> */}

                <Row style={{ paddingTop: "5px", paddingRight: "20px" }}
                // className={(!validateDataItem.afeCostType ? "form-error" : "")}
                // className={"form-error"}
                >
                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={(Data.length > 10 ? "300px" : "100%")}
                        width='100%'
                        dataSource={Data}
                        //isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}
                    >

                        <IgrNumericColumn field="templateStep"
                            headerText="Step"
                            width="120"
                        />

                        <IgrTextColumn
                            field="templateSequence"
                            headerText="Sequence of Job Steps"
                            width="*>150"
                        />

                        <IgrTextColumn
                            field="templateHazard"
                            headerText="Potential Hazard(s)"
                            width="*>150"
                        />

                        <IgrTextColumn
                            field="templateAction"
                            headerText="Recommendation Action/Procedure"
                            width="*>150"
                        />



                        <IgrNumericColumn field="objectLinkKey" isHidden />
                    </IgrDataGrid>

                </Row>


                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                        onClosed={() => this.refreshData()}
                        //contentClassName="oilTicketDetail-modal-style"
                        size='lg'
                    >
                        <ModalHeader toggle={() => this.toggle()}
                        > Job/Steps 
                         {/* {linkType} */}
                        </ModalHeader>
                        <ModalBody>


                            <FormGroup row>
                                <Label for="templateStep" sm={2}>Step</Label>
                                <Col sm={10}>
                                    <Input type="number"
                                        name="templateStep"
                                        id="templateStep"
                                        defaultValue={templateStep}
                                        readOnly={isFormReadOnly}
                                        value={templateStep}
                                        invalid={invalidStep}
                                        onChange={(e) => this.onTextChange("templateStep", e.target.value, e)} />
                                    <FormFeedback tooltip>Step can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="templateSequence" sm={2}>Sequence of Job Steps</Label>
                                <Col sm={10}>
                                    <Input type="textarea"
                                        name="text"
                                        id="templateSequence"
                                        rows="3"
                                        defaultValue={templateSequence}
                                        readOnly={isFormReadOnly}
                                        value={templateSequence}
                                        invalid={templateSequence === ""}
                                        onChange={(e) => this.onTextChange("templateSequence", e.target.value, e)} />
                                    <FormFeedback tooltip>Sequence can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="templateHazard" sm={2}>Potential Hazard(s)</Label>
                                <Col sm={10}>
                                    <Input type="textarea"
                                        name="text"
                                        id="templateHazard"
                                        rows="3"
                                        defaultValue={templateHazard}
                                        readOnly={isFormReadOnly}
                                        value={templateHazard}
                                        invalid={templateHazard === ""}
                                        onChange={(e) => this.onTextChange("templateHazard", e.target.value, e)} />
                                    <FormFeedback tooltip>Hazard can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="templateAction" sm={2}>Recommendation Action/Procedure</Label>
                                <Col sm={10}>
                                    <Input type="textarea"
                                        name="text"
                                        id="templateAction"
                                        rows="3"
                                        defaultValue={templateAction}
                                        readOnly={isFormReadOnly}
                                        value={templateAction}
                                        invalid={templateAction === ""}
                                        onChange={(e) => this.onTextChange("templateAction", e.target.value, e)} />
                                    <FormFeedback tooltip>Action can not be blank</FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="pics" sm={2}>Pics/docs</Label>
                                <Col sm={10}>
                                <FileManager
                        assetkey={formJSAKey}
                        controlid={'qhsejsa'+formJSAKey+'key_'+linkType}
                        // folderPath={"tasset/" + 'FAC_INSP_LI' + '/'+formJSAKey+'/lps_1'}
                        folderPath={'qhse/jsa' + '/'+formJSAKey+'/key_'+linkType}
               
                    >

                    </FileManager>
                                  
                                </Col>
                            </FormGroup>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

            </div>)
    }
}