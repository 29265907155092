import React, { Component, ReactDOM, useState, useEffect, useCallback } from 'react';
import { Col, Row, Button, InputGroup, FormGroup, Label, Input, Form, InputGroupText } from 'reactstrap';
import MenuIcon from '../../resources/menu.png';
import PlusIcon from '../../resources/plus.svg'
import RefreshIcon from '../../resources/refresh.svg'
import TrashBin from '../../resources/trash.svg'
import ExcelIcon from '../../resources/excel.svg'
import GlobeBW from '../../resources/globebw.svg'
import Floppy from '../../resources/floppy.png';
import Filter from '../../resources/filter.png'
import Action from '../../resources/action.png'
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import { apiURL, apiDELETEHeader, apiHeader, apiPOSTHeader } from '../../resources/apiURL';
import { getUserADAccount, USDCurrencyFormat, bitwise, addDays } from '../../resources/utility'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { getValueList } from '../../operation/Asset/AssetDetails'
import {
    DiagramComponent,
    DiagramContextMenu,
    Inject, DiagramConstraints,
    SymbolPaletteComponent, DataBinding,
    PortVisibility, ConnectorConstraints, UndoRedo,
    DiagramTools
} from "@syncfusion/ej2-react-diagrams";

import {
    ItemDirective,
    ItemsDirective,
    ToolbarComponent,
} from '@syncfusion/ej2-react-navigations';
import Select from 'react-select';
const getFlowShapes = (props) => {
    let flowShapes = [
        {
            id: "process",
            shape: {
                type: "Flow",
                shape: "Process",
            },
        },
        {
            id: "terminator",
            shape: {
                type: "Flow",
                shape: "Terminator",
            },
        },
        {
            id: "decision",
            shape: {
                type: "Flow",
                shape: "Decision",
            },
            ports: [{
                id: 'left',
                offset: {
                    x: 0,
                    y: 0.5
                },
                visibility: PortVisibility.Hidden
            },
            {
                id: 'top',
                offset: {
                    x: 0.5,
                    y: 0
                },
                visibility: PortVisibility.Hidden
            },
            {
                id: 'right',
                offset: {
                    x: 1,
                    y: 0.5
                },
                visibility: PortVisibility.Hidden
            },
            {
                id: 'bottom',
                offset: {
                    x: 0.5,
                    y: 1
                },
                visibility: PortVisibility.Hidden
            }
            ]
        },
        {
            id: "Link1",
            type: "Orthogonal",
            sourcePoint: {
                x: 0,
                y: 0,
            },
            targetPoint: {
                x: 40,
                y: 40,
            },
            targetDecorator: {
                shape: "Arrow",
            },

        },
        {
            id: "Link21",
            type: "Straight",
            sourcePoint: {
                x: 0,
                y: 0,
            },
            targetPoint: {
                x: 40,
                y: 40,
            },
            targetDecorator: {
                shape: "Arrow",
            },
        }
    ];
    return flowShapes;
}



const LossOfUtilityPowerProcedureDetails = (props) => {
    let diagramInstance
    let toolbarEditor

    const { assetConfigList, assetKey, assetTypeID, companyID,
        parentAssetKey, assetStatus, assetCatID } = props

    const [assetType, setAssetType] = useState(props.assetType)
    const [isDisscusionFormStyle, setIsDisscusionFormStyle] = useState((props.assetType === undefined || props.assetType.length <= 0) ? false :
        bitwise(((props.assetType[0].form_options === null) ? 0 : props.assetType[0].form_options), 64) === 64)
    const [isAssetTypeLoaded, setIsAssetTypeLoaded] = useState((props.assetType === undefined || props.assetType.length <= 0) ? false : true)
    const [isReadOnly, setIsReadOnly] = useState(props.isReadOnly)
    const [isAssetLoaded, setIsAssetLoaded] = useState(false);
    const [assetDetails, setAssetDetails] = useState([])

    const [msgBody, setMsgBody] = useState('')
    const [msgHeader, setMsgHeader] = useState('')
    const [modalMsg, setModalMsg] = useState(false)

    const [assetConfig, setAssetConfig] = useState([])

    const [selAssetStatus, setSelAssetStatus] = useState([])
    const [isAssetConfigLoaded, setIsAssetConfigLoaded] = useState(false)
    const [reloadForm, setFormReload] = useState(0)

    const [showValidateAlert, setShowValidateAlert] = useState(false)
    const [validateMsg, setValidateMsg] = useState('')

    const [scenario, setScenario] = useState([])
    const [windowHeight, setWindowHeight] = useState("675px")


    const onDiagramTextChange = e => {
        // console.log("on Diagram Text Change")
        // console.log(e)
    }

    const refreshData = () => {
        // console.log("diagramInstance.saveDiagram")
        // console.log(diagramInstance.saveDiagram())
    }

    const saveData = () => {

        try {
            //dataValidation().then((data) => {
            //if (data.ok) {
            //OK save or add data



            if (assetDetails[0].assetkey > 0) {
                let diagramJson = diagramInstance.saveDiagram()
                onTextChange(get_asset_config_meta("lpp_flowchartjson"), "lpp_flowchartjson", diagramJson, diagramJson)
                updateAsset()
            } else {
                //addAsset()
            }
            // } else {
            //     //console.log("Missing data: " + data.message)
            //     setValidateMsg('Missing Data - Please complete highlight red items below')
            //     setShowValidateAlert(true)
            // }
            //})
        } catch (error) {

        }
    }

    const updateAsset = (action = "") => {
        //let myURI = apiURL + 'Asset/MetaAdd'      
        let myURI = apiURL + 'Asset/AssetUpdate'

        const u_meta_datas = createUpdateAssetDTO()

        fetch(myURI, apiPOSTHeader(u_meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        setMsgBody(t)
                        setMsgHeader("Error")
                        setModalMsg(!modalMsg)

                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                if (action === "") {
                    props.refreshData();
                    props.toggle();
                } else {
                    spasset_action(action)
                }

            }).catch(error => {
                setMsgBody("PUT error: " + error)
                setMsgHeader("Error")
                setModalMsg(!modalMsg)
            })
    }

    const createAssetActionDTO = (action) => {
        const updateDTO = {
            "assetkey": assetDetails[0].assetkey,
            "action_by": getUserADAccount(props.user.account.userName),
            "action": action
        }

        return updateDTO
    }

    const spasset_action = (action) => {
        let myURI = apiURL + 'Asset/AssetAction'
        const u_meta_datas = createAssetActionDTO(action)
        // console.log("assetDetails[0]")
        // console.log(u_meta_datas)

        fetch(myURI, apiPOSTHeader(u_meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        setMsgBody(t)
                        setMsgHeader("Error")
                        setModalMsg(!modalMsg)
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                props.refreshData();
                props.toggle();

            }).catch(error => {
                setMsgBody("PUT error: " + error)
                setMsgHeader("Error")
                setModalMsg(!modalMsg)
            })
    }


    const createUpdateAssetDTO = () => {
        const updateDTO = {
            "company_id": companyID,
            "assetkey": assetDetails[0].assetkey,
            "modified_by": getUserADAccount(props.user.account.userName),
            "parent_assetkey": assetDetails[0].parent_assetkey,
            "asset_name": assetDetails[0].asset_name,
            "status_id": assetDetails[0].status_id,
            "meta_datas": createUpdateAssetMetaDTO()
        }

        return updateDTO
    }

    const createUpdateAssetMetaDTO = () => {

        var temp_metas = []

        if (assetDetails[0].meta_datas !== undefined) {
            assetDetails[0].meta_datas
                .filter(a => a.asset_meta_key < 0)
                .map(b => {
                    const meta = {
                        "asset_meta_key": -1,
                        "meta_id": b.meta_id,
                        "meta_value": b.meta_value,
                        "meta_text": b.meta_text,
                        "meta_name": b.meta_name,
                        "asset_config_meta_key": b.asset_config_meta_key,
                        "assetkey": b.assetkey,
                        "created_by": getUserADAccount(props.user.account.userName)
                    }

                    temp_metas.push(meta)
                })
        }

        return temp_metas;
    }

    const handleResize = () => {
        let tempHeight = (window.innerHeight * 0.72) + "px"
        // console.log("tempHeight")
        // console.log(tempHeight)
        setWindowHeight(tempHeight);
    }

    useEffect(() => {
        // console.log("--------->useEffect ---> assetConfig")
        // console.log(assetConfig)

        window.addEventListener('resize', handleResize);

        if (assetConfig === undefined || assetConfig === null) {
            console.log("--------->useEffect ---> undefined")
            getAssetConfig()
        } else if (assetConfig.length > 0) {
            //console.log("--------->useEffect ---> config.legth > 0")
            getAssetDetails()
        } else {
            // console.log("--------->useEffect ---> else")
            getAssetConfig()
        }


        // console.log( '-------------hdhdhd-----------')
        // console.log( assetType)
        if (assetType === undefined || assetType.length <= 0) {
            // console.log('-------------hdhdhd-----------')
            getAssetType()
        }

    }, [assetConfig])

    const getAssetDetails = () => {

        // console.log("assetKey")
        // console.log(assetKey)

        if (assetKey > 0) {
            let myURI = apiURL + 'Asset/' + companyID + '/Detail/ByAssetKey/' + assetKey
            //console.log(myURI)

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    // console.log("getAssetDetails")
                    // console.log(json)

                    setAssetDetails(json)
                    setIsAssetLoaded(true)
                    // setConfigForm(json)
                    setSelAssetStatus(assetStatus.filter(e => e.label === json[0].status_id))
                    setIsReadOnly(json[0].allow_actions === "Readonly" || isReadOnly)
                });
        } else {
            const temp = newAsset()
            setAssetDetails(temp)
            setIsAssetLoaded(true)
            //setConfigForm(temp)
            //setIsDisscusionFormStyle(false)
        }
    }
    const isAllow = (e, value) => {

        var rntVal
        value.split(',').some(a => {

            const temp = Number(a)
            rntVal = ((e & temp) === temp)
            if (rntVal) {
                return true
            }
        })


        return rntVal

    }

    const newAsset = () => {
        // console.log("assetConfig")
        // console.log(assetConfig)

        const tempDTO = {
            "company_id": companyID,
            "asset_name": "",
            "assetkey": assetKey,
            "created_by": props.user.account.name,
            "created_at": new Date().toISOString(),
            "status_id": "ACT",
            "parent_assetkey": (parentAssetKey > 0 ? parentAssetKey : null),
            "asset_id": "XXXXXX",
            "asset_type_key": assetConfig[0].asset_type_key,
            "meta_datas": null,
            "asset_type_id": assetConfig[0].asset_type_char,
            "allow_actions": (isAllow(assetType[0].form_options, "32") ? "Submit" : "")
        }

        return [tempDTO]
    }

    const getAssetConfig = () => {
        return new Promise((res, rej) => {


            var bLoadAsset = 0

            // console.log("->getAssetConfig  - " + assetTypeID)
            // console.log(assetConfigList)


            if (assetConfigList === undefined || assetConfigList === null) {
                bLoadAsset = 1
            } else if (assetConfigList.length === 0) {
                bLoadAsset = 1
            } else {

                assetConfigList.map(a => {
                    if (a.asset_type_id === assetTypeID) {

                        // console.log(` -> AssetDetails - found asset config ` + assetTypeID)
                        // console.log(a.assetConfig)

                        /* need to reset validation error */

                        //a.assetConfig.map(b => b.assetConfig.map(c => c.asset_config.map(d => d.meta_datas.map(e => e.validation_error = 0))))
                        a.assetConfig.map(b => b.asset_config.map(c => c.meta_datas.map(d => d.validation_error = 0)))

                        setAssetConfig(a.assetConfig)
                        setIsAssetConfigLoaded(true)
                        // getAssetDetails()
                        bLoadAsset = 2;
                    }
                })

                if (bLoadAsset = 0) {
                    // console.log(` --> not found asset config ` + assetTypeID)
                    // console.log(assetConfigList)
                    bLoadAsset = 1
                }

            }

            if (bLoadAsset === 1) {
                //                let myURI = apiURL + 'Asset/' + companyID + '/AssetConfig/ByAssetType/' + assetTypeID
                let myURI = apiURL + 'Asset/' + companyID + '/OP/1/AssetConfig/ByAssetType/' + assetTypeID

                // console.log(`--> AssetDetails - load asset config ` + assetTypeID)
                // console.log(assetDetails[0])

                fetch(myURI, { modes: 'no-cors' })
                    .then(res => res.json())
                    .then(json => {
                        //console.log(json)
                        getValueList(companyID, json, isReadOnly).then(data => {

                            setAssetConfig(data)
                            setIsAssetConfigLoaded(true)
                            // getAssetDetails()
                            // console.log(`AssetDetails ------RELOADDDDDDDDDD------>Load assetConfig`)

                            //updateAssetConfigList(assetTypeID, data)
                        })

                    });
            }

            res(true)
        })

    }


    const getAssetType = () => {

        // console.log('getAssetType')
        //assetTypeID, companyID

        if (assetTypeID !== "") {
            let myURI = apiURL + 'Asset/' + companyID + '/' + assetCatID
                + '/AssetType/ByAssetType/' + assetTypeID

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    // console.log("assettype")
                    // console.log(json)
                    setAssetType(json)
                    setIsAssetTypeLoaded(true)

                });
        }
    }

    const get_selected_multiple_value = (meta_id) => {
        var meta_data = null

        let value_list = get_asset_config_meta(meta_id).value_list_data

        if (value_list !== undefined || value_list !== null) {
            let meta_value = get_meta_value(meta_id)
            console.log("meta_value ")
            console.log(meta_value)
            if (meta_value !== null) {
                meta_data = value_list.filter(e => meta_value.includes(e.value))
            }
        }
        return meta_data
    }

    const get_asset_config_meta = (meta_id) => {
        var meta_data = null
        // console.log("get_asset_config_meta - assetconfig")
        // console.log(assetConfig)

        assetConfig[0].asset_config.map(f => {
            f.meta_datas.map(e => {
                if (e.meta_id === meta_id) {
                    meta_data = e
                    return meta_data
                }
            })
            if (meta_data !== null) {
                return meta_data
            }
        })

        // console.log("get_asset_config_meta")
        // console.log(meta_data)
        return meta_data
    }

    const get_meta_value = (meta_id) => {

        // console.log("assetDetails")
        // console.log(assetDetails)


        let asset_detail = assetDetails[0]

        if (asset_detail.meta_datas !== null) {
            let meta_data = asset_detail.meta_datas.filter(e => e.meta_id === meta_id);
            if (meta_data !== undefined && meta_data !== null) {
                if (meta_data.length > 0) {

                    if ((meta_data[0].data_type === 'date') &&
                        meta_data[0].meta_value !== null &&
                        meta_data[0].meta_value !== '') {

                        return new Date(meta_data[0].meta_value)

                    } else if (meta_data[0].data_type === "datetime") {
                        //return new Date(meta_data[0].meta_value)
                    }
                    return meta_data[0].meta_value
                }
            }
        }
        return null
    }

    const create_meta_data = (fieldType, meta_id, meta_value, config_meta) => {
        const { user } = props

        let asset_detail = assetDetails[0]

        const meta_data = {
            "asset_meta_key": -1,
            "meta_id": meta_id,
            "meta_value": (fieldType === "value_list" ? meta_value.value : meta_value),
            //"meta_text": (fieldType === "value_list" ? meta_value.label : null),
            "meta_text": (fieldType === "value_list" ? meta_value.label : null),
            "meta_name": config_meta.meta_name,
            "asset_config_meta_key": config_meta.asset_config_meta_key,
            "assetkey": asset_detail.assetkey,
            "created_by": getUserADAccount(user.account.userName)
        }

        return meta_data
    }

    const onTextChange = (config_meta, fieldName, fieldValue, v) => {

        // console.log("AssetControl--->fieldName")
        // console.log(fieldName)
        // console.log("fieldValue, v")
        // console.log(fieldValue, v)
        // console.log("config_meta")
        // console.log(config_meta)
        // console.log("assetDetails")
        // console.log(assetDetails)


        let asset_detail = assetDetails[0]

        if (fieldName != "" && fieldValue !== null) {
            var bMetaExist = false
            let fieldType = config_meta.data_type


            if (asset_detail.meta_datas === null) {
                asset_detail.meta_datas = []
            }

            asset_detail.meta_datas.map(e => {
                if (e.meta_id === fieldName) {
                    e.asset_meta_key = -1
                    e.meta_value = (fieldType === "value_list" ? fieldValue.value : fieldValue)
                    e.meta_text = (fieldType === "value_list" ? (fieldValue.label !== undefined ? fieldValue.label : fieldValue.text) :
                        null)

                    bMetaExist = true

                    // if (fieldType === "value_list") {
                    //     props.onValueListChange(config_meta.asset_config_key, fieldName, fieldValue,config_meta)

                    // }
                    return
                }
            })

            if (!bMetaExist) {
                // console.log("AssetControl--->fieldName ----  here")

                let new_meta = create_meta_data(fieldType, fieldName, fieldValue, config_meta)

                asset_detail.meta_datas.push(new_meta)
                if (fieldType === "value_list") {
                    asset_detail.meta_datas.map(e => {
                        if (e.meta_id === fieldName) {
                            e.asset_meta_key = -1
                            e.meta_value = (fieldType === "value_list" ? fieldValue.value : fieldValue)
                            e.meta_text = (fieldType === "value_list" ? (fieldValue.label !== undefined ? fieldValue.label : fieldValue.text) :
                                null)
                            bMetaExist = true

                            // if (fieldType === "value_list") {
                            //     props.onValueListChange(config_meta.asset_config_key, fieldName, fieldValue)

                            // }
                        }
                    })
                }
            }

            /* added by HN filter other related fields */
            if (config_meta.auto_fill_meta_ids !== "") {
                if (fieldValue !== null) {
                    if (fieldValue.additional_values !== "") {
                        asset_detail.auto_fill_meta_ids = config_meta.auto_fill_meta_ids
                        asset_detail.additional_values = fieldValue.additional_values
                    }
                }
            }

        }

    }
    //create class Property
    const createProperty = (name, type) => {
        return { name: name, type: type };
    }
    const get_flowchart = (section) => {


        // let node = [{
        //     id: 'Enumeration',
        //     style: {
        //         fill: '#26A0DA',
        //     }, borderColor: 'white',
        //     shape: {
        //         type: 'UmlClassifier',
        //         enumerationShape: {
        //             name: 'AccountType',
        //             members: [
        //                 {
        //                     name: 'Checking Account', style: {}
        //                 },
        //             ]
        //         },
        //         classifier: 'Enumeration'
        //     }
        // }];

        // return node


        let lpp_flowchartjson = get_meta_value("lpp_flowchartjson")
        // console.log("get_flowchart")
        // console.log(JSON.parse(lpp_flowchartjson))
        let jsonFlowChart = JSON.parse(lpp_flowchartjson)
        if (jsonFlowChart !== undefined && jsonFlowChart !== null) {
            if (section === "nodes") {
                console.log("jsonFlowChart.nodes")
                console.log(jsonFlowChart.nodes)
                let nodes = jsonFlowChart.nodes
                nodes.map(e => {
                    e.annotations.map(f => {
                        f.margin.right = 10
                        f.margin.left = 10
                        f.margin.top = 10
                        f.margin.bottom = 10
                    })
                })
                return jsonFlowChart.nodes
            } else if (section === "connectors") {
                console.log("jsonFlowChart.connectors")
                console.log(jsonFlowChart.connectors)
                let connectors = jsonFlowChart.connectors
                connectors.map(e => {
                    e.annotations.map(f => {
                        f.style.fill = "white"
                    })
                })
                return connectors
            }
        }
    }

    const tooledit = (args) => {
        switch (args.item.tooltipText) {
            case 'Zoom In':
                let zoomin = { type: 'ZoomIn', zoomFactor: 0.2 };
                diagramInstance.zoomTo(zoomin);
                break;
            case 'Zoom Out':
                let zoomout = { type: 'ZoomOut', zoomFactor: 0.2 };
                diagramInstance.zoomTo(zoomout);
                break;
            case 'Select':
                diagramInstance.clearSelection();
                diagramInstance.drawingObject = {};
                diagramInstance.tool =
                    DiagramTools.SingleSelect | DiagramTools.MultipleSelect;
                break;
            case 'Pan Tool':
                diagramInstance.tool = DiagramTools.ZoomPan;
                break;
            case 'Reset':
                diagramInstance.reset();
                break;
            case 'Fit To Page':
                diagramInstance.fitToPage();
                break;
            case 'Bring Into View':
                if (diagramInstance.selectedItems.nodes.length > 0) {
                    let bound = diagramInstance.selectedItems.nodes[0].wrapper.bounds;
                    diagramInstance.bringIntoView(bound);
                }
                break;
            case 'Bring Into Center':
                if (diagramInstance.selectedItems.nodes.length > 0) {
                    let bounds = diagramInstance.selectedItems.nodes[0].wrapper.bounds;
                    diagramInstance.bringToCenter(bounds);
                }
                break;
        }
    }

    const create_selValue = data => {
        let rntValue = {
            value: '',
            label: ''
        }

        data.map(e => {
            if (rntValue.value !== "") {
                rntValue.value = rntValue.value + ','
                rntValue.label = rntValue.label + ','
            }
            rntValue.value = rntValue.value + e.field_value
            rntValue.label = rntValue.label + e.field_label
        })

        return rntValue
    }

    if (!isAssetConfigLoaded) {
        return (<div>
            Loading asset configs.....
        </div>)
    } else if (!isAssetLoaded) {
        return (<div>
            Loading asset details.....
        </div>)
    }
    return (
        <div id="id1">
            <Form onSubmit={e => e.preventDefault()}>
                <FormGroup>
                    <Row form style={{ marginTop: "-15px" }}>

                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={refreshData}
                        >
                            <img
                                src={RefreshIcon}
                                alt="Refresh"
                                style={{ width: "25px", margin: "0 0 0 0" }} />
                            Refresh
                        </Button>
                        <Button outline
                            color="secondary"
                            className="btn-no-border"
                            onClick={saveData}
                        >
                            <img
                                src={Floppy}
                                alt="Floppy"
                                style={{ width: "25px", margin: "0 0 0 0" }} />
                            Save
                        </Button>
                    </Row>
                    <ColoredLine color="grey" />
                </FormGroup>
                <FormGroup>
                    <Row form style={{ marginTop: "-10px", marginBottom: "-10px" }} >
                        {/* <Col md="auto">
                            <Label>
                                Scenario:
                            </Label>

                        </Col> */}
                        <Col md="auto">
                            <InputGroup>
                                <InputGroupText style={{ border: "0px", backgroundColor: "transparent" }}>
                                    Scenario :
                                </InputGroupText>
                                <DropDownListComponent
                                    dataSource={get_asset_config_meta('lpp_scenario').value_list_data}
                                    fields={{ text: 'label', value: 'value' }}
                                    allowFiltering={true}
                                    width={"500px"}
                                    // isDisabled={(config_meta.is_readonly === 1 || isReadOnly)}
                                    // className={(validation_error ? "e-input-group e-error" : "e-input")}
                                    value={get_meta_value("lpp_scenario")}
                                    // filtering={(e) => onFiltering(e, config_meta.value_list_data)}
                                    change={(e) => onTextChange(get_asset_config_meta('lpp_scenario'),
                                        "lpp_scenario", e.itemData, e)}
                                >

                                </DropDownListComponent>
                            </InputGroup>
                        </Col>
                        <Col md="auto">
                            <InputGroup>
                                <InputGroupText style={{ border: "0px", backgroundColor: "transparent" }}>
                                    Status:
                                </InputGroupText>

                                <DropDownListComponent
                                    dataSource={get_asset_config_meta('lpp_status_id').value_list_data}
                                    fields={{ text: 'label', value: 'value' }}
                                    allowFiltering={true}
                                    width={"110px"}
                                    // isDisabled={(config_meta.is_readonly === 1 || isReadOnly)}
                                    // className={(validation_error ? "e-input-group e-error" : "e-input")}
                                    value={get_meta_value("lpp_status_id")}
                                    // filtering={(e) => onFiltering(e, config_meta.value_list_data)}
                                    change={(e) => onTextChange(get_asset_config_meta('lpp_status_id'),
                                        "lpp_status_id", e.itemData, e)}
                                ></DropDownListComponent>
                              
                            </InputGroup>
                        </Col>
                        <Col md="auto">
                            <InputGroup>
                                <InputGroupText style={{ border: "0px", backgroundColor: "transparent" }}>
                                    Revision:
                                </InputGroupText>
                                <Input name={"lpp_rev"}
                                    id={"lpp_rev"}
                                    width={"80px"}
                                    //readOnly={(config_meta.is_readonly === 1 || isReadOnly)}
                                    //value={get_meta_value(config_meta.meta_id)}
                                    defaultValue={get_meta_value('lpp_rev')}
                                    onChange={(e) => onTextChange(get_asset_config_meta('lpp_rev'),
                                        "lpp_rev", e.target.value, e)} />
                            </InputGroup>
                        </Col>

                    </Row>
                </FormGroup>

                {/* <FormGroup>
                    <Row form style={{ marginTop: "-10px", marginBottom: "-10px" }} >
                        <Col md="3">

                            <DropDownListComponent
                                dataSource={get_asset_config_meta('lpp_scenario').value_list_data}
                                fields={{ text: 'label', value: 'value' }}
                                allowFiltering={true}
                                // isDisabled={(config_meta.is_readonly === 1 || isReadOnly)}
                                // className={(validation_error ? "e-input-group e-error" : "e-input")}
                                value={get_meta_value("lpp_scenario")}
                                // filtering={(e) => onFiltering(e, config_meta.value_list_data)}
                                change={(e) => onTextChange(get_asset_config_meta('lpp_scenario'),
                                    "lpp_scenario", e.itemData, e)}
                            >

                            </DropDownListComponent>




                        </Col>
                        <Col md="4">
                            <Select
                                //className="e-input-group btn-no-border"                                
                                options={get_asset_config_meta('lpp_facility').value_list_data}
                                isMulti
                                //value = {get_selected_multiple_value('lpp_facility')}
                                defaultValue={get_selected_multiple_value('lpp_facility')}
                                onChange={(e) => onTextChange(get_asset_config_meta('lpp_facility'), "lpp_facility", create_selValue(e), e)}
                                isClearable={true}>

                            </Select>
                        </Col>
                    </Row>
                </FormGroup> */}


                <FormGroup>
                    <ToolbarComponent
                        ref={(toolbar) => (toolbarEditor = toolbar)}
                        id="toolbar_diagram"
                        clicked={tooledit}
                    >
                        <ItemsDirective>
                            <ItemDirective
                                prefixIcon="e-icons e-reset"
                                text="Refresh"
                                tooltipText="Refresh"
                            />
                            <ItemDirective
                                prefixIcon="e-icons e-save"
                                text="Save"
                                tooltipText="Save"
                            />
                            <ItemDirective type="Separator" />
                            <ItemDirective
                                prefixIcon="e-icons e-zoom-in"
                                tooltipText="Zoom In"
                            />
                            <ItemDirective
                                prefixIcon="e-icons e-zoom-out"
                                tooltipText="Zoom Out"
                            />
                            <ItemDirective type="Separator" />
                            <ItemDirective
                                prefixIcon="e-icons e-mouse-pointer"
                                tooltipText="Select"
                            />
                            <ItemDirective
                                prefixIcon="e-icons e-pan"
                                tooltipText="Pan Tool"
                            />
                            <ItemDirective type="Separator" />
                            <ItemDirective prefixIcon="e-icons e-reset" tooltipText="Reset" />
                            <ItemDirective
                                prefixIcon="e-icons e-zoom-to-fit"
                                tooltipText="Fit To Page"
                            />
                            <ItemDirective type="Separator" />
                            <ItemDirective
                                prefixIcon="e-icons e-bring-to-view"
                                tooltipText="Bring Into View"
                            //disabled={true}
                            />
                            <ItemDirective
                                prefixIcon="e-icons e-bring-to-center"
                                tooltipText="Bring Into Center"
                            // disabled={true}
                            />
                        </ItemsDirective>
                    </ToolbarComponent>
                    <Row form>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ width: "100px", verticalAlign: "top" }}>
                                    <SymbolPaletteComponent id="containerSymbol"
                                        width={"100px"}
                                        symbolHeight={60}
                                        symbolWidth={60}
                                        palettes={[
                                            {
                                                //Sets the id of the palette
                                                id: "flow",
                                                //Sets whether the palette expands/collapse its children
                                                expanded: true,
                                                //Adds the palette items to palette
                                                symbols: getFlowShapes(),
                                                //Sets the header text of the palette
                                                title: "Flow Shapes",
                                                iconCss: "e-ddb-icons e-flow",
                                            }]}
                                    />

                                </td>

                                <td style={{ width: "100%" }}>
                                    <DiagramComponent
                                        id="diagram"
                                        width={"100%"}
                                        height={windowHeight} //{'680px'}
                                        ref={(diagram) => (diagramInstance = diagram)}

                                        nodes={get_flowchart("nodes")}
                                        connectors={get_flowchart("connectors")} //{connectors}
                                        getNodeDefaults={(node) => {
                                            // node.height = 50;
                                            // node.width = 140;
                                            return node;
                                        }}
                                        getConnectorDefaults={(obj) => {
                                            // obj.type = "Orthogonal";
                                            // obj.targetDecorator = {
                                            //     shape: 'Arrow', width: 10, height: 10
                                            // };
                                            return obj;
                                        }}
                                        contextMenuSettings={{
                                            show: true
                                        }}

                                        historyChange={(args) => {
                                            /** create diagram lpp_flowchartjson */
                                            let diagramJson = diagramInstance.saveDiagram()
                                            onTextChange(get_asset_config_meta("lpp_flowchartjson"), "lpp_flowchartjson", diagramJson, args)
                                            /** end */
                                        }}
                                    >
                                        <Inject services={[DiagramContextMenu, UndoRedo]} />
                                    </DiagramComponent>
                                </td>
                            </tr>
                        </table>
                    </Row>
                </FormGroup>

            </Form >
        </div >
    );
}

export default LossOfUtilityPowerProcedureDetails;

