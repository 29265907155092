

import React, { Component } from 'react';

import { apiURL, apiURLSvc, apiHeader, apiDELETEHeader } from '../resources/apiURL';

import Refresh from '../resources/refresh.svg'
import Plus from '../resources/plus.svg'

import Excel from '../resources/excel.svg'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';

import { Col, Row, Button, FormGroup, Label, Input, CustomInput, InputGroup, Alert } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
// import { IgrDateTimeColumn } from 'igniteui-react-grids';
// import { IgrColumnGroupDescription } from 'igniteui-react-grids';
// import { JsonDictionaryArray, ListSortDirection, tryParseUInt16_1 } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule, IgrInputModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
// import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
// import { IgrTemplateCellInfo } from 'igniteui-react-grids';
// import { IIgrCellTemplateProps } from 'igniteui-react-grids';
//import SafetyIncidentNew from './safetyIncident/SafetyIncidentNew'

import CustomConfirmModal from '../resources/CustomConfirmModal'
import { ColoredLine, getFirstDayofMonth, getFirstDayOfJanuaryYearsAgo } from '../resources/utility';

import { getUserADAccount } from '../resources/utility'
import icontrash from '../resources/trash.svg'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';

//import PowerBIReportViewer from '../resources/PowerBIReportViewer'
//import Report from '../resources/report.png'
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";
import Revise from '../resources/revise.png'
import clearfilter from '../components/img/clearfilter.png'

import JibDetail from './JibDetail';
import TrashBin from '../resources/trash.svg'



IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
//IgrInputModule.register();
//IgrDatePickerModule.register();
IgrDataGridToolbarModule.register();


var DatePicker = require("reactstrap-date-picker");

export default class jibHeader extends Component {
    static displayName = jibHeader.name;

    constructor(props) {
        super(props);
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        // this.onGridfilterExpressionsChanged = this.onGridfilterExpressionsChanged.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        //this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onToolbarRef = this.onToolbarRef.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);

        this.toggleCustomConfirmModal = this.toggleCustomConfirmModal.bind(this);
        this.handleCustomConfirmYes = this.handleCustomConfirmYes.bind(this);



        const urlPara = this.getURLParameters(props.parameters)
        this.searchInput = React.createRef();

        this.state = {
            jibHeader: [],
            jibHeaderDefault: [],
            isLoaded: false,
            startDate: getFirstDayofMonth().toISOString(),
            //startDate: "2024-06-25T05:00:00.000Z" ,

            endDate: new Date().toISOString(),
            //modalOpen: true,
            jibHeaderKey: -1,
            selJibNo: null,
            jibno: '',
            windowHeight: window.innerHeight,
            //modal: false,
            //modalReport: false,
            //fromParamStartDate: urlPara.startDate,
            //fromParamEndDate: urlPara.endDate,
            searchText: '',

            apiResponse: '',
            apiError: '',
            showBadAlert: false,
            showGoodAlert: false,
            isLoading: false,

            dropdownActionOpen: false,

            isSharepointLocLoaded: false,
            sharepointLoc: '',

            modalDetails: false,

            isJIBUser: false,
            isJIBEdit: false,
            isJIBWorkday: false,
            JIBUserStatusLoaded: false,
            isCustomConfirmModalOpen: false,
            confirmModalMessage: '',
            confirmModalAction: null

        }
    }

    toggleCustomConfirmModal(message = '', action = null) {
        // if (!window.confirm(action)) {
        //     return;

        // }

        this.setState({
            isCustomConfirmModalOpen: !this.state.isCustomConfirmModalOpen,
            confirmModalMessage: message,
            confirmModalAction: action
        });
    }

    handleCustomConfirmYes() {
        if (this.state.confirmModalAction) {
            this.state.confirmModalAction();
        }
        this.toggleCustomConfirmModal();
    }

    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
            formattedValue: formattedValue
        });
        console.log(this.state.startDate)
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
            formattedValue: formattedValue

        });

    }




    GetJIBUserStatus = () => {


        let myURI = apiURL + 'AR/GetJIBUserStatus/' + this.props.user.account.userName;

        console.log(myURI);

        fetch(myURI, apiHeader('GET'))
            .then(res => {
                console.log("Response status: ", res.status);
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then(json => {
                console.log("Response JSON: ", json);
                this.setState({
                    isJIBUser: json.isJIBUser !== 0,
                    isJIBEdit: json.isJIBEdit !== 0,
                    isJIBWorkday: json.isJIBWorkday !== 0,
                    JIBUserStatusLoaded: true,
                });
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }




    onCellClicked(s, e) {
        if (e.isDoubleClick) {

            this.toggleDetails();
            //let column_id = s.activeCell.columnUniqueKey


            // if (column_id === 'comments') {
            //     let commentContent = e.cellInfo.textValue;

            //     if (commentContent) {
            //         alert(commentContent);
            //     } else {
            //         alert("No comments");
            //     }

            // }
            // this.toggleEdit()
            // console.log("in OnCellClick")
            // console.log("s", s)
            // console.log("e", e)

            //let config_meta = this.getAssetConfigMeta(column_id)
            var bPopUp = true

            // if (config_meta !== undefined && config_meta !== null) {
            //     if (config_meta.meta_options !== undefined  && config_meta.meta_options !== null) {
            //         console.log("config_meta.meta_options & 32")
            //         console.log(config_meta.meta_options & 32)
            //         console.log(config_meta)
            //         if (column_id === 'wtc_projectname' || (Number(config_meta.meta_options) & 32) !== 32) {
            //             bPopUp = false
            //             this.toggleEdit()
            //         } else if (config_meta.data_type === "daterange") {
            //             //this.toggleModalDateRange()
            //             this.setState({
            //                 selectedDateRange: e.cellInfo.textValue,
            //                 modalDateRange: !this.state.modalDateRange
            //             })
            //         }
            //     }

            // }

            // if  (bPopUp)
            // {
            //     this.toggleEdit()
            // }
        }
    }


    toggleDetails = () => {
        this.setState({
            modalDetails: !this.state.modalDetails
        })
    }
    closeDetailsForm = () => {
        this.setState({
            modalDetails: !this.state.modalDetails,
            jibHeaderKey: -1,
            selJibNo: null
            //,
            //mmStatusDesc: '',

        })
        this.getJibHeader();
    }


    openSharepoint = () => {
        const { sharepointLoc } = this.state
        const linky = document.createElement("a");
        //link.href = `/#/SafetyIncidentHeader?safIncHeaderKey=${safIncHeaderKey}`;
        //linky.href = `{sharepointLoc}`;
        //const encodedUrl = 'https://h20bridge.sharepoint.com/sites/AccountingGroup%2FShared%20Documents%2F04.%20Accounts%20Payable%2FAP%20ticket%20attachments%2FAP000002';

        const decodedUrl = decodeURIComponent(sharepointLoc);
        //linky.href = 'https://h20bridge.sharepoint.com/sites/AccountingGroup\Shared Documents/04. Accounts Payable/AP ticket attachments/AP000002';
        console.log(decodedUrl);
        linky.href = decodedUrl;
        linky.target = "_blank";

        // link.textContent = "Open Safety Incident Header"; // 

        document.body.appendChild(linky);
        linky.click();
        document.body.removeChild(linky);
    }








    // onTextChange = (fieldName, fieldValue, e) => {
    //     const { jibHeaderDefault } = this.state;


    //     if (fieldName === "searchText") {
    //         let searchText = fieldValue.toLowerCase();
    //         if (this.state.isTyping) {
    //             this.searchInput.current.focus();
    //         }
    //         if (searchText !== this.state.searchText) {
    //             this.setState({ searchText: searchText });

    //         }


    //          let filteredData = jibHeaderDefault.filter(item => {
    //             return this.isItemMatchingSearchText(item, searchText);
    //         });


    //         //   this.setState({ facInvCollector: filteredData, searchText, facilityInvCollectorKey: null });
    //         this.setState({ jibHeader: filteredData, searchText });

    //     }
    // };

    toggleDropdownAction = () => {
        this.setState({ dropdownActionOpen: !this.state.dropdownActionOpen })
    }





    isItemMatchingSearchText = (item, searchText) => {
        const { currentView } = this.state
        // Define columns to search based on the current view
        let columnsToSearch =
            ["jibno", "status", "filename", "sharepointLoc", "comments", "result"];
        // ["safetyObservationNo", "statusDesc"];


        return columnsToSearch.some(column => {
            if (item[column] && item[column].toString().toLowerCase().includes(searchText)) {
                return true;
            }
            return false;
        });
    };

    resetFilter = () => {
        const { jibHeaderDefault } = this.state;

        for (var i = 0; i < this.grid.actualColumns.count; i++) {
            this.grid.actualColumns.item(i).filter = null;

        }
        this.grid.selectedItems.clear();


        this.grid.filterExpressions.clear();

        if (this.searchInput.current) {
            // console.log("Ref found, current element:", this.searchInput.current);
            this.searchInput.current.value = '';
        } else {
            // console.log("Ref not found");
        }

        //this.setState({ basinsel: { value: "", label: "Please Select" } });

        this.getJibHeader();

        // this.setState({ searchText: '', safetyInc: safetyIncDefault });
    }







    getURLParameters = (inputPara) => {
        var paraMeters = {
            //startDate: null,
            //endDate: null,
            //safetyType: null,
            jibHeader: null
        }


        if (!this.props.location === 'undefined' || inputPara !== 'undefined') {
            var query
            if (!this.props.location === 'undefined') {
                query = new URLSearchParams(this.props.location.search);

            } else {
                query = new URLSearchParams(inputPara);
            }


            paraMeters = {
                //startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
                //endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
                //safetyType:query.get('SafetyType'),
                //facilityKeys: query.get('Facility')
            }

        }





        console.log(paraMeters)
        return paraMeters
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.jibHeader;

        if (this.toolbar !== null) {
            this.toolbar.targetGrid = this.grid

        }

    }

    onToolbarRef(toolbar) {
        this.toolbar = toolbar;
        if (this.toolbar) {
            this.toolbar.targetGrid = this.grid;
        }
    }


    // toggle = () => {
    //     console.log("Modal: " + this.state.modal)
    //     this.setState({ modal: !this.state.modal })

    // }


    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            console.log(dataItem)

            this.setState({ jibHeaderKey: dataItem.jibHeaderKey })
            this.setState({ jibno: dataItem.jibno })

            this.setState({ selJibNo: dataItem })
            //this.toggle()
            //this.onVolRefresh()    
        }
        else {
            this.setState({ jibHeaderKey: -1 })
            this.setState({ selJibNo: null })
            this.setState({ jibNo: '' })

        }
    }












    getJibHeader = () => {
        const { startDate, endDate } = this.state;
        const defaultPastDate = new Date('2000-01-01');
        const defaultFutureDate = new Date('2100-01-01');

        const startDt = startDate ? new Date(startDate) : defaultPastDate;
        const endDt = endDate ? new Date(endDate) : defaultFutureDate;
        endDt.setHours(23, 59, 59, 999);
        

        let myURI = apiURL + 'AR/GetJibHeader';

        // console.log('Fetching data from:', myURI);
        // console.log('Start Date:', startDt);
        // console.log('End Date:', endDt);

        fetch(myURI, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            modes: 'no-cors'
        })
            .then(res => res.json())
            .then(json => {
                // console.log('Data fetched:', json);

                const filteredData = json.filter(item => {
                    const createdDateTime = new Date(item.createdDateTime);
                    return createdDateTime >= startDt && createdDateTime <= endDt;
                });

                console.log('Filtered Data:', filteredData);

                this.setState({
                    isLoaded: true,
                    jibHeader: filteredData,
                    jibHeaderDefault: filteredData,
                    jibHeaderKey: -1,
                    searchText: ''
                }, () => {
                    // Verify state update
                    // console.log('State updated with new jibHeader:', this.state.jibHeader);
                });

                if (this.searchInput.current) {
                    this.searchInput.current.value = '';
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.getJibHeader();
        this.getSharepoint();
        this.GetJIBUserStatus();





    }

    createDeleteHdrDTO = () => {
        const { jibHeaderKey } = this.state

        const { user } = this.props

        const hdrForDeleteDTO = {
            "JIBHeaderKey": jibHeaderKey,
            "DeletedBy": getUserADAccount(user.account.userName), /*4=Submitted, 6=Reviewed*/
        }

        return hdrForDeleteDTO;
    }

    deleteHeader = () => {
        this.toggleCustomConfirmModal(
            'Do you want to delete the selected JIB NO?',
            this.executeDeleteHeader
        );
    }

    executeDeleteHeader = () => {

        // if (!window.confirm('Do you want to delete the selected JIB NO?')) {
        //     return;

        // }
        const hdrForDeleteDTO = this.createDeleteHdrDTO()

        let myURI = apiURL + 'AR/JIBHeaderDel'

        fetch(myURI, apiDELETEHeader(hdrForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = await response.text();

                    const error = rspData || response.statusText;

                    this.setState({
                        apiError: error,
                        showBadAlert: true
                    })

                    return Promise.reject(new Error(error));
                } else {
                    this.getJibHeader();
                }


            })
            .catch(error => {

            })

    }

    //     getSharepoint = () => {

    //         let myURI = apiURL + 'AR/GetJIBSharepoint'

    //         fetch(myURI, { modes: 'no-cors' })
    //             .then(res => res.json())
    //             .then(json => {
    //                 this.setState({
    //                     sharepointLoc: 'gg'),
    //                     isSharepointLocLoaded: true
    //                 })
    //             });
    //    }

    getSharepoint = () => {
        let myURI = apiURL + 'AR/GetJIBSharepoint';

        fetch(myURI)
            .then(res => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.text();
            })
            .then(text => {
                this.setState({
                    sharepointLoc: text,
                    isSharepointLocLoaded: true
                });
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);

            });
    }


    // openDetails = () => {
    //     const { jibHeaderKey } = this.state;
    //     const link = document.createElement("a");
    //     link.href = `/#/SafetyIncidentHeader?safIncHeaderKey=${jibHeaderKey}`;
    //     link.target = "_blank";

    //     // link.textContent = "Open Safety Incident Header"; // 

    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }



    onDismissAlert = () => {
        this.setState({ showBadAlert: false, showGoodAlert: false });
    }

    callApi = () => {
 
        this.toggleCustomConfirmModal(
            'Do you want to process the JIB excel file from the Sharepoint loc?',
            this.executeApiCall
        );
    }
  

    executeApiCall = () => {
        // if (!window.confirm('Do you want to process the JIB excel file from the Sharepoint loc?')) {
        //     return;

        // }
        //const myURI = 'http://localhost:5266/AP/JIBReadFolder';

        this.setState({endDate: new Date().toISOString()})

        let myURI = apiURLSvc + 'AP/JIBReadFolder' //Remove "/" per dragan request on 


        this.onDismissAlert();
        this.setState({ isLoading: true });
        fetch(myURI)
            .then(response => {
                if (!response.ok) {

                    return response.text().then(text => {
                        throw new Error(text);
                    });
                }

                return response.text();;
            })
            .then(text => {
                // Assuming the 'ok' string indicates success
                if (text === "ok") {
                    this.setState({
                        apiResponse: text,
                        apiError: null,
                        showGoodAlert: true,
                        showBadAlert: false,
                        isLoading: false,
                        endDate: new Date().toISOString()
                    })
                    this.getJibHeader();
                    ;
                } else {
                    // Handle any other text that is not 'ok' as an unexpected response
                    throw new Error('Unexpected response: ' + text);
                }
            })
            .catch(error => {

                this.setState({
                    apiError: error.message,
                    apiResponse: null,
                    showBadAlert: true,
                    isLoading: false
                });
                this.getJibHeader();
            });
    }




    render() {

        const { isLoaded, jibHeader, windowHeight } = this.state;

        const { jibHeaderKey, jibno, apiResponse, apiError, showBadAlert, showGoodAlert, isLoading, isSharepointLocLoaded, selJibNo, modalDetails } = this.state;
        const { isJIBUser, isJIBEdit, isJIBWorkday, JIBUserStatusLoaded, startDate, endDate } = this.state;

        //const gridHeight = (windowHeight * 0.7) + "px"
        const gridHeight = "72vh"
        console.log(jibHeader);

        const status = selJibNo ? selJibNo.status : 'Unknown Status';
        const isSpecialStatus = selJibNo && (selJibNo.status === 'Failed' || selJibNo.status.toLowerCase().startsWith('eib ready'));




        if (!isLoaded || !isSharepointLocLoaded || !JIBUserStatusLoaded) {
            return (<div>
                <h3>Loading JIB.....</h3>
            </div>)
        }
        //if(!isJIBUser||!isJIBEdit)
        if (!isJIBUser && !isJIBEdit && !isJIBWorkday)
            return (
                <div>
                    <div>
                        <h2>Access Denied</h2>
                        <p>You do not have the necessary permissions to view this content.</p>
                    </div>

                    {/* <p>Is JIB User: {isJIBUser ? 'Yes' : 'No'}</p>
        <p>Is JIB Edit: {isJIBEdit ? 'Yes' : 'No'}</p>
        <p>Is JIB Workday: {isJIBWorkday ? 'Yes' : 'No'}</p>
        <p>{startDate}</p>
        <p>{endDate}</p> */}
                </div>
            )



        console.log("selJibNo")
        console.log(selJibNo)
        console.log("end selJibNo")
        console.log({ isJIBWorkday })

        return (<div>

            {/* <div>
    <p>Is JIB User: {isJIBUser ? 'Yes' : 'No'}</p>
        <p>Is JIB Edit: {isJIBEdit ? 'Yes' : 'No'}</p>
        <p>Is JIB Workday: {isJIBWorkday ? 'Yes' : 'No'}</p>
        <p>{startDate}</p>
        <p>{endDate}</p>

      </div> */}
            <FormGroup style={{ marginTop: "-10px" }}>
                <Row form>
                    <Col md={4}>
                        <Label><h3> JIB |</h3></Label>

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={(e) => this.getJibHeader()}
                        >
                            <img
                                src={Refresh}
                                alt="refresh"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Refresh
                        </Button>{' '}
                        {/* <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.saveWorkbook}>
                            <img
                                src={Excel}
                                alt="excel"
                                style={{ width: "50px", margin: "0 0 0 0" }} />
                            Export To Excel
                        </Button> */}

                        <Button outline color="secondary"
                            className="btn-no-border"
                            onClick={this.toggleDetails}
                            disabled={jibHeaderKey < 0 || isLoading}
                        >
                            <img
                                src={Revise}
                                alt="details"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            View EIB Details
                        </Button>{' '}

                        <Button outline color="danger"
                            className="btn-no-border"
                            onClick={this.deleteHeader}
                            disabled={jibHeaderKey < 0 || isLoading || !isSpecialStatus || (!isJIBEdit && !isJIBWorkday)}
                        >
                            <img
                                src={TrashBin}
                                alt="delete"
                                style={{ width: "25px", margin: "0 5px 0 0" }} />
                            Delete
                        </Button>{' '}
                    </Col>
                    <Col>
                        <FormGroup style={{marginTop:"-2px", marginBottom:"-15px", padding:"0px"}}>
                            <row form >
                                <div>
                                    {isLoading &&
                                        <Alert color="info" isOpen={isLoading}>
                                            The services are running...Please wait...
                                        </Alert>
                                    }
                                    {showBadAlert &&
                                        <Alert
                                                color="danger" 
                                                isOpen={showBadAlert} 
                                                toggle={this.onDismissAlert}>
                                            {apiError}
                                        </Alert>
                                    }
                                    {showGoodAlert &&
                                        <Alert color="success" isOpen={showGoodAlert} toggle={this.onDismissAlert}>
                                            Services finished running
                                            {/* : {JSON.stringify(apiResponse)} */}
                                        </Alert>
                                    }

                                </div>

                            </row>
                        </FormGroup>
                    </Col>
                    <Col md={1} className="text-right">

                        <Dropdown isOpen={this.state.dropdownActionOpen} nav={false}
                            direction="left"
                            className="no-border float-right"
                            color="primary"
                            outline={true}
                            toggle={this.toggleDropdownAction}>
                            <DropdownToggle caret>
                                Action
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem
                                     onClick={() => this.openSharepoint()}>
                                    1 - Open SharePoint

                                </DropdownItem>
                                <DropdownItem
                                   

                                    disabled={!isJIBEdit && !isJIBWorkday}

                                    onClick={this.callApi}
                               >
                                   
                                   2 - Start the Service
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </Col>


                    {/* {' '} */}


                    {/* <button outline className="btn-no-border"
                        >

                            <SafetyIncidentNew
                                ddropdown={ddTypes}
                                isSafIncAdmin={false}

                                onClickOK={(ddtyp, ddloc, desc) => this.newIncident(ddtyp, ddloc, desc)}
                            />
                        </button> */}


                </Row>
                <ColoredLine color="grey" />
            </FormGroup>

            <FormGroup style={{ marginBottom: "-5px", marginTop: "-10px" }}>
                <Row >
                    <Col md={3}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                            Start Date
                        </Label>
                        <DatePicker value={this.state.startDate}
                            onChange={(v, f) => this.handleChange(v, f)} />
                    </Col>
                    <Col md={3}>
                        <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                            End Date
                        </Label>
                        <DatePicker value={this.state.endDate}
                            onChange={(v, f) => this.handleEndDateChange(v, f)} />

                    </Col>

                </Row>
            </FormGroup>


            {/* 
            <FormGroup>
                <row form>
                    <div>
                        {isLoading &&
                            <Alert color="info" isOpen={isLoading}>
                                The services are running...Please wait...
                            </Alert>
                        }
                        {showBadAlert &&
                            <Alert color="danger" isOpen={showBadAlert} toggle={this.onDismissAlert}>
                                {apiError}
                            </Alert>
                        }
                        {showGoodAlert &&
                            <Alert color="success" isOpen={showGoodAlert} toggle={this.onDismissAlert}>
                                Services finished running                            
                            </Alert>
                        }


                    </div>

                </row>
            </FormGroup> */}

            {/* <FormGroup row>
                    <Col md={12}>
                        <div>
                            <Label>

                                ddddd   isSafIncAdmin:<b>{isSafIncAdmin ? "true" : "false"}</b>      </Label>

                        </div>
                    </Col>

                </FormGroup> */}



            <FormGroup>


                <Row form >



                    <Col md={9}>

                    </Col>


                    {/* <Col md={3}>

                            <InputGroup>
                                <Button outline color="secondary"
                                    className="btn-no-border float-right"
                                    style={{ float: 'right' }}
                                    onClick={this.resetFilter}
                                    disabled={isLoading}

                                >
                                    <img
                                        src={clearfilter}
                                        alt="clear"
                                        style={{ width: "25px", height: "25px", margin: "0 0 0 10" }} />
                                    {"Clear Filter"}
                                </Button>
                                <Input
                                    disabled={isLoading}
                                    innerRef={this.searchInput}
                                    placeholder="Begin search..."
                                    onChange={(e) => this.onTextChange("searchText", e.target.value, e)}
                                />
                            </InputGroup>

                        </Col> */}



                </Row>
            </FormGroup>

            <FormGroup>
                <IgrDataGridToolbar
                    ref={this.onToolbarRef}
                    toolbarTitle=""
                    columnChooser="true"
                />
                <IgrDataGrid
                    ref={this.onGridRef}
                    cellTextStyle="14px Calibri"
                    headerTextStyle="14px Calibri"
                    rowHeight={"25"}
                    height={gridHeight}
                    dataSource={jibHeader}

                    cellClicked={this.onCellClicked}

                    autoGenerateColumns="false"
                    selectionMode="SingleRow"
                    // reactsToFilterChanges="true"
                    selectedKeysChanged={this.onGridRowSelected}
                // filterExpressionsChanged={this.onGridfilterExpressionsChanged}

                //cellBackground={"#ffbfbf"}
                >
                    <IgrTemplateColumn field="jibno" headerText="JIBNO " width="100"
                        cellUpdating={this.onStatusCellUpdating}
                    />

                    <IgrTemplateColumn field="createdDateTime" headerText="Started"
                        width="180"
                        //dateTimeFormat={"Date"}
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="finishedDateTime" headerText="Finished"
                        width="180"
                        //dateTimeFormat={"DateTimeShort"}
                        cellUpdating={this.onStatusCellUpdating}
                    />

                    <IgrTemplateColumn field="status" headerText="Status"
                        width="180"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="filename" headerText="Filename"
                        width="210"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="sharepointLoc" headerText="Sharepoint Loc"
                        width="160"
                        cellUpdating={this.onIncIDCellUpdating} />


                    <IgrTextColumn field="comments" headerText="Comments"
                        width="320"
                        cellUpdating={this.onStatusCellUpdating} />

                    <IgrTemplateColumn field="result" headerText="Result"
                        width="140"
                        cellUpdating={this.onStatusCellUpdating}

                    />
                    <IgrTemplateColumn field="sendDateTime" headerText="SendDate"
                        width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdating} />

<IgrTemplateColumn field="printDateTime" headerText="PrintDate"
                        width="*>140" dateTimeFormat={"DateShort"}
                        cellUpdating={this.onStatusCellUpdating} />


                    <IgrNumericColumn field="jibHeaderKey" isHidden />
                </IgrDataGrid>
                <CustomConfirmModal 
                    isOpen={this.state.isCustomConfirmModalOpen}
                    toggle={this.toggleCustomConfirmModal}
                    formName="Confirm"
                    message={this.state.confirmModalMessage}
                    onClickYes={this.handleCustomConfirmYes}
                />
               

                <div>
                    <Modal isOpen={modalDetails}
                        contentClassName="full-screen-modal"
                        toggle={() => this.toggleDetails()}
                        style={{ margin: 0, flex: 1 }}
                        backdrop={"static"}
                    //onClosed={() => this.getMtlMovement()}
                    >
                        <ModalHeader toggle={() => this.closeDetailsForm()}>
                            {jibno}
                        </ModalHeader>
                        <ModalBody>
                            <JibDetail toggle={this.closeDetailsForm}
                                user={this.props.user}
                                //selJibNo={selJibNo}
                                //statusKey={statusKey}
                                isJIBUser={isJIBUser}
                                isJIBEdit={isJIBEdit}
                                isJIBWorkday={isJIBWorkday}
                                jibHeaderKey={jibHeaderKey}
                            >
                            </JibDetail>
                        </ModalBody>
                    </Modal>
                </div>

            </FormGroup>





        </div>)
    }

    // onIncIDCellUpdating(s, e) {
    //     const content = e.content;
    //     const info = e.cellInfo;
    //     const item = info.rowItem;
    //     let link;
    //     if (content.childElementCount === 0) {
    //         link = document.createElement("a");
    //         let cell = document.createElement("div");


    //         content.style.fontFamily = "Calibri";
    //         content.style.fontSize = "14px";
    //         content.style.color = "#4286f4";
    //         // content.style.width = "100%";
    //         content.style.margin = "0px -20px -10px -12px";
    //         content.style.padding = "0px 10px 10px 10px";

    //         content.appendChild(link);
    //     }
    //     else {
    //         link = content.children[0];
    //         if (item.statusKey === 3) {
    //             content.style.background = "#f0f5b0";
    //         } else if (item.statusKey === 6) {
    //             content.style.background = "#b8f2b1";
    //         } else if (item.statusKey === 1 || item.statusKey === 5) {
    //             content.style.background = "#fc4e42";
    //         } else {
    //             content.style.background = "transparent";
    //         }


    //     }

    //     //link.href = "tel:" + item.inspectionNo;
    //     link.href = "/#/SafetyIncidentHeader?safIncHeaderKey=" + item.safIncHeaderKey;
    //     link.target = "_blank"
    //     link.textContent = item.safIncID;
    // }

    onIncIDCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

            // content.style.verticalAlign = "center";
            // content.style.marginTop = "15px";
            // content.style.lineHeight = "normal";
            // content.style.display = "inline-block";
            // content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";
            content.style.color = "#4286f4";
            // content.style.width = "100%";
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
            if (item.statusKey === 3) {
                content.style.background = "#f0f5b0";
            } else if (item.statusKey === 6) {
                content.style.background = "#b8f2b1";
            } else if (item.statusKey === 1 || item.statusKey === 5) {
                content.style.background = "#fc4e42";
            } else {
                content.style.background = "transparent";
            }


        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/#/SafetyIncidentHeader?safIncHeaderKey=" + item.safIncHeaderKey;
        if (!item.sharepointLoc) {
            link.removeAttribute('href');  // Removes the href attribute if sharepointLoc is not valid
            link.textContent = 'no';         // Clear the text content
        } else {
            link.href = item.sharepointLoc;
            link.target = "_blank";
            link.textContent = item.jibno;
        }
    }


    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;


        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Calibri";
            content.style.fontSize = "14px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            var cont = true

            if (cont) {

                if (item.statusKey === 3) {
                    content.style.background = "#f0f5b0";
                } else if (item.statusKey === 6) {
                    content.style.background = "#b8f2b1";
                } else if (item.statusKey === 1 || item.statusKey === 5) {
                    content.style.background = "#fc4e42";
                } else {
                    content.style.background = "transparent";
                }

            }
        }

        switch (s.field) {
            case 'jibno':
                link.textContent = item.jibno;
                break;
            case 'createdDateTime':
                var date = new Date(item.createdDateTime);
                var dateString = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                var timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                var fullDateTimeString = dateString + ' ' + timeString;
                link.textContent = fullDateTimeString;

                break;
            case 'finishedDateTime':
                var date = new Date(item.finishedDateTime);
                if (isNaN(date.getTime()) || date.getFullYear() === 1969) {
                    link.textContent = ''; // Set to blank space
                } else {
                    var dateString = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    var timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                    var fullDateTimeString = dateString + ' ' + timeString;
                    link.textContent = fullDateTimeString;
                }

                break;
            case 'status':
                link.textContent = item.status;
                break;
            case 'filename':
                link.textContent = item.filename;
                break;

            case 'sharepointLoc':
                link.textContent = item.sharepointLoc;
                break;
            case 'comments':
                link.textContent = item.comments;
                break;

            case 'result':
                link.textContent = item.result;
                break;

            case 'sendDateTime':

                if (item.sendDateTime === null) {
                    link.textContent = "";

                } else {
                    var date = new Date(item.sendDateTime);
                    link.textContent = date.toLocaleDateString();
                }


                break;

                case 'printDateTime':

                if (item.printDateTime === null) {
                    link.textContent = "";

                } else {
                    var date = new Date(item.printDateTime);
                    link.textContent = date.toLocaleDateString();
                }


                break;

            default:

        }

    }








    // openHeaderForm = (dd) => {


    //     const link = document.createElement("a");
    //     link.href = `/#/SafetyIncidentHeader?safIncHeaderKey=${dd}`;
    //     link.target = "_blank";

    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);

    // }







    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] === actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] === actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "SafetyIncident");
    }





}


