
import React, {useState} from 'react';
import {Button} from 'reactstrap';

const inspState = (a) => {
    //console.log("print State: " + a)
    if (a === 1) {
        return "success";
    } else if (a === 2) {
        return "danger";
    } else {
        return "secondary";
    }
}


const InspItemState = (props) => {
    // console.log(props);
    const [inspLine,setInspLine] = useState(props.InspLine);
    const [itemState,setItemState]= useState(props.InspLine.inspLineValue);
    

    const onHandleInspState = (key) => {
       
        //console.log("item State: " + itemState)
        const iState =  ((itemState + 1) > 2 ? 0 : itemState + 1 );
        setItemState(iState) ;      
        props.onHandleInspState(key, iState);
        //console.log("After State: " + this.State.itemState)
    }
    

    return (
        <Button color={inspState(itemState)}
                style={{margin: "0 5px 0 0"}}
                onClick={(e) => onHandleInspState(inspLine.facilityInspLineItemKey,e)}
                ><span>&nbsp;&nbsp;</span> </Button>
    );
}

export default InspItemState;


// export default class InspItemState extends Component {
//     constructor(props){
//         super(props);
//         this.handleClick = this.handleClick.bind(this);      

//         this.state={
//             inspLine:props.InspLine,
//             itemState:props.InspLine.inspLineValue
//         }

        
//     }

//     inspState = (a) => {
//         if (a === 1) {
//             return "success";
//         } else if (a === 2) {
//             return "danger";
//         } else {
//             return "secondary";
//         }
//     }

//     handleClick(){
//         //console.log(this.state.inspLine)
//         //this.setState({itemState: (this.state.itemState + 1 > 2 ? 0 : this.state.itemState + 1)})
//         this.setState((state) => ({
//             itemState: ((state.itemState + 1) > 2 ? 0 : state.itemState + 1 )
//         }))
//     }

  

//     render(){
//         return (
//             <Button color={this.inspState(this.state.itemState)}
//                     style={{margin: "0 5px 0 0"}}
//                     onClick={(e) => this.onHandleInspState(this.state.itemState.facilityInspLineItemKey,e)}
//                     ><span>&nbsp;&nbsp;</span> </Button>
//         );
//     }
// }