import React, { Component } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback } from 'reactstrap';
import { IgrDataGrid, IgrTemplateColumn, IgrTextColumn, IgrNumericColumn } from 'igniteui-react-grids';
import DatePicker from 'reactstrap-date-picker';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiHeader } from '../../resources/apiURL';
import { getUserADAccount } from '../../resources/utility';
import { IgrDataGridModule } from 'igniteui-react-grids'; 
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

export default class WOComment extends Component {
    static displayName = WOComment.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.toggleCommentModal = this.toggleCommentModal.bind(this); // Bind the method
        
        this.state = {
            user: props.user,
            readOnly: props.readOnly,
            workOrderKey: props.workOrderKey,
            woComment: [],
            isWOCommentLoaded: false,
            modalComment: false,
            cmtDate: new Date().toISOString(),
            operationCommentKey: -1,
            comments: ' ',
            fiixWOId: props.fiixWOId,
            createdBy: "",
            expandedComment: "", // Add this
            isCommentModalOpen: false, // Add this
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.woComment;
    }

    componentDidMount() {
        this.getWOComment();
    }

    getWOComment = () => {
        const { workOrderKey } = this.state;
        let myURI = apiURL + 'Fiix/Comment/' + workOrderKey;
    
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    woComment: json,
                    isWOCommentLoaded: true
                });
            });
    }

    toggleComments = () => {
        this.setState({ modalComment: !this.state.modalComment });
    }

    toggleCommentsNew = () => {
        this.setState({
            comments: " ",
            createdBy: "",
            cmtDate: new Date().toISOString(),
            operationCommentKey: -1,
            modalComment: !this.state.modalComment
        });
    }

    toggleCommentOk = () => {
        if (this.saveWOComment()) {
            this.getWOComment();
            this.setState({
                modalComment: !this.state.modalComment,
                comments: " "
            });
            this.syncWOCommentTOFiix();
        }
    }

    saveWOComment = () => {
        const { operationCommentKey } = this.state;
        if (this.validateData()) {
            if (operationCommentKey > 0) {
                if (this.updateComment()) {
                    this.setState({ operationCommentKey: -1 });
                }
            } else {
                const addDTO = this.createWOCommentForCreateDTO();
                let myURI = apiURL + 'Fiix/Comment';
                fetch(myURI, apiPOSTHeader(addDTO))
                    .then(async response => {
                        const rspData = response.text();
                        if (!response.ok) {
                            const error = (rspData && rspData.message) || response.status;
                            return false;
                        }
                        this.getWOComment();
                    })
                    .catch(error => {
                        this.getWOComment();
                        return false;
                    });
                this.getWOComment();
            }
            return true;
        }
        return false;
    }

    syncWOCommentTOFiix = () => {     
        const { workOrderKey, fiixWOId } = this.state;
        if (workOrderKey > 0) {
            let myURI = apiURL + 'Fiix/Sync/WBCommentsToFiix/' + fiixWOId;
            fetch(myURI, apiHeader('GET'), { modes: 'no-cors' });
        } 
    }

    updateComment = () => {
        const uptDTO = this.createWOCommentForUpdateDTO();
        let myURI = apiURL + 'Fiix/Comment';

        fetch(myURI, apiPUTHeader(uptDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    return false;
                }
                this.getWOComment();
            })
            .catch(error => {
                this.getWOComment();
                return false;
            });
        this.getWOComment();
        return true;
    }

    validateData = () => {
        var { comments } = this.state;
        comments = comments.trim();
        if (comments !== "") {
            return true;
        } else {
            this.setState({ comments: comments });
        }
    }

    createWOCommentForCreateDTO = () => {
        const { cmtDate, comments, user, workOrderKey } = this.state;
        const afeCmtDTO = {
            "commentTypeKey": 5, //AFE
            "compObjectKey": workOrderKey, //selectedValue.key,
            "workOrderKey": null,
            "commentDate": cmtDate,
            "comments": comments,
            "createdBy": getUserADAccount(user.account.userName)
        };
        return afeCmtDTO;
    }

    createWOCommentForUpdateDTO = () => {
        const { operationCommentKey, comments, user } = this.state;
        const opsCmtDTO = {
            "operationCommentKey": operationCommentKey,
            "comments": comments,
            "modifiedBy": getUserADAccount(user.account.userName)
        };
        return opsCmtDTO;
    }

    onGridRowSelected = (s, e) => {
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0);
            let dataItem = clickedRow.value[0];
            this.setState({
                createdBy: dataItem.createdBy,
                comments: dataItem.comments,
                cmtDate: dataItem.commentDate,
                operationCommentKey: dataItem.operationCommentKey,
                expandedComment: dataItem.comments,
            });
        }
    }

    toggleCommentModal = () => {
        this.setState({ isCommentModalOpen: !this.state.isCommentModalOpen });
    }

    onTextChange = (fieldName, fieldValue, e) => {
        this.setState({ comments: fieldValue });
    }

    handleChange = (v, f) => {
        this.setState({
            cmtDate: v
        });
    }

    // Added by BN 6/4/24 Function to get date central time
    getDate = () => {
        const { cmtDate } = this.state;
        const options = {
            timeZone: 'America/Chicago',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const centralTimeString = cmtDate.toLocaleString('en-US', options);         
        return centralTimeString;
    }

    render() {
        const { user, isWOCommentLoaded, readOnly, operationCommentKey, comments, woComment, fiixWOId, createdBy, expandedComment, isCommentModalOpen } = this.state;
    
        if (!isWOCommentLoaded) {
            return (<div>Loading Comment ......</div>);
        } else {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Button color="primary" outline className="float-left" style={{ height: "35px", marginTop: "10px" }} onClick={this.toggleCommentsNew}>Add</Button>
                            <Button color="primary" outline className="float-left" style={{ height: "35px", marginLeft: "10px", marginTop: "10px", marginBottom: "10px" }} hidden={!readOnly} disabled={createdBy !== getUserADAccount(user.account.userName)} onClick={this.toggleComments}>Edit</Button>
                            <Button color="primary" outline className="float-left" style={{ height: "35px", marginLeft: "10px", marginTop: "10px", marginBottom: "10px" }} disabled = {operationCommentKey < 0}onClick={this.toggleCommentModal}>View Comment</Button>
                            <FormGroup>
                                <IgrDataGrid ref={this.onGridRef} height={'200px'} width='100%' dataSource={woComment} autoGenerateColumns="false" selectionMode="SingleRow" editModeClickAction="SingleClick" editMode={0} selectedKeysChanged={this.onGridRowSelected}>
                                    <IgrTemplateColumn field="commentDate" headerText="Date" width="170" cellUpdating={this.onCellUpdating} />
                                    <IgrTemplateColumn field="createdBy" headerText="Created By" width="200" cellUpdating={this.onCellUpdating} />
                                    <IgrTextColumn field="comments" headerText="Comments" width="*>350" />
                                    <IgrNumericColumn field="operationCommentKey" isHidden />
                                </IgrDataGrid>
                            </FormGroup>
                        </Col>
                    </Row>
    
                    <div>
                        <Modal isOpen={this.state.modalComment} toggle={this.toggleComments} size='lg'>
                            <ModalHeader toggle={this.toggleComments}> New Comments </ModalHeader>
                            <ModalBody>
                                <FormGroup row>
                                    <Label for="commentDate" sm={2}>Date</Label>
                                    <Col sm={10}>
                                        <DatePicker value={this.getDate()} id="commentDate" onChange={this.handleChange} disabled={readOnly} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="exampleText" sm={2}>Text Area</Label>
                                    <Col sm={10}>
                                        <Input type="textarea" name="text" id="exampleText" rows="6" defaultValue={comments} value={comments} invalid={comments === ""} onChange={(e) => this.onTextChange("comments", e.target.value, e)} />
                                        <FormFeedback tooltip> Comment can not be blank</FormFeedback>
                                    </Col>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.toggleCommentOk}>OK</Button>
                                <Button color="primary" onClick={this.toggleComments}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
    
                    {/* Comment Modal */}
                    <Modal isOpen={isCommentModalOpen} toggle={this.toggleCommentModal} size="lg">
                        <ModalHeader toggle={this.toggleCommentModal}>View Comment</ModalHeader>
                        <ModalBody>
                            <p>{expandedComment}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleCommentModal}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            );
        }
    }

    onCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");
    
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";
    
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            content.appendChild(link);
        } else {
            link = content.children[0];
        }
    
        switch (s.field) {
            case 'commentDate':
                var date = new Date(item.commentDate);
                link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
                break;
    
            case 'createdBy':
                link.textContent = item.createdBy.replace('.', ' ');
                break;
    
            case 'comments':
                link.textContent = item.comments;
                link.id = `comment-${item.operationCommentKey}`; // Ensure ID is correctly set
                break;
    
            default:
        }
    }
}