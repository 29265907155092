//Ready for to Push to Master from local Master 10/14/21

import React, { Component, useEffect, useState} from 'react';
import {Col, Row, Button, FormGroup, Label} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';

import {apiURL, apiHeader, apiPOSTHeader, apiDELETEHeader} from '../resources/apiURL';

import '../custom.css';

import MenuIcon from '../resources/menu.png';
import RefreshIcon from '../resources/refresh.svg';
import PlusIcon from '../resources/plus.svg';
import TrashIcon from '../resources/trash.svg';
import ExcelIcon from '../resources/excel.svg';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { ListSortDirection } from 'igniteui-react-core';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import { getUserADAccount,getRandomInt } from '../resources/utility'
import { ExcelUtility } from '../operation/ExcelUtility';
import SecurityCheckpointAdminDetails from './SecurityCheckpointAdminDetails'
import ConfirmModal from '../operation/ConfirmModal'
import { ColoredLine, getFirstDayofMonth,useWindowDimensions } from '../resources/utility';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();


const SecCheckUserGroup=(props)=>{
    const [isLoaded,setIsLoaded] = useState(false)
    const [users, setUsers] = useState([]) 
    const [applicationSecurityUserGroupLinkKey, setapplicationSecurityUserGroupLinkKey] = useState([])
    const [user, setUser]= useState(props.userInput)
    const [modalUserGroup, setModalUserGroup] = useState(false)
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]

     
   function toggleModalUserGroup() {  
    // console.log("Modal: " + this.state.modal)
    // this.setState({modalUser: !this.state.modalUser})
    // setModalUser(!modalUser);
    // props.toggleModalUserGroup()
    setModalUserGroup(!modalUserGroup)

}

function addSecCheckUserGroupLink(){
    // let newGroup = this.createUserGroupEmployeeLinkForm()
    // this.setState({groupDetails:newGroup})
    
    toggleModalUserGroup();
}

const deleteApplicationSecurityFromGroup = () => {   
    // console.log("Save user in user details")
    const deleteApplicationSecurityFromGroupDTO= deleteApplicationSecurityFromGroupSelect()
    // console.log(deleteApplicationSecurityFromGroupDTO)
  

      let myURI = apiURL + 'SecAdmin/UserGroup/ApplicationSecurity'
      fetch(myURI , apiDELETEHeader(deleteApplicationSecurityFromGroupDTO) )
        .then(async response => {
          const rspData = response.text();
          if (!response.ok){
            // console.log("Error return but you dont see")
            const error = (rspData && rspData.message) || response.status;
            return Promise.reject(error);
            
          }
          refreshUserData()
        //   console.log("No error here: " + rspData)
        })
        .catch(error =>{
            refreshUserData()
        //   console.log("PUT Error: " + error);
        })       
}

const deleteApplicationSecurityFromGroupSelect=()=>{
    var tempDTO=[];
    var i=0;
    applicationSecurityUserGroupLinkKey.map(function (line) {
       const applicationSecurityFromGroupDelete = {
           "applicationSecurityUserGroupLinkKey": line,
           "deletedBy": getUserADAccount(user.account.userName)

       }
       tempDTO[i] = applicationSecurityFromGroupDelete ;
       i = i + 1
    });
   
   return tempDTO;
}


function onGridRowSelected(s, e) {
// console.log(" On CLick")
//const content = e.content;
// if (e.addedKeys.count > 0) {
    // let clickedRow = e.addedKeys.item(0) 
    // let dataItem = clickedRow.value[0] 
    // console.log(dataItem)
    
    // if (!this.state.hideAssociateDetails){
        // this.setState({userGroupKey: dataItem.userGroupKey,
        //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
    // }

    const tempList=[] 
    let clickedRows = e.currentKeys
    
    for (let i = 0; i < e.currentKeys.count; i++){
    //    console.log(e.currentKeys.item(i).value[0] ) 
        tempList.push(e.currentKeys.item(i).value[0].applicationSecurityUserGroupLinkKey) ; 
    }
    
    // selectedUserGroupChanged(dataItem.employeeKey)
    // console.log(tempList)
    setapplicationSecurityUserGroupLinkKey(tempList)
    // setEmployeeKey(tempList);
   
    // console.log("hello")

// }    
}


function refreshUserData(){
    const myURL = apiURL + 'SecAdmin/CheckPoint/' + props.UserGroupKey + '/UserGroup'
    //  console.log("UserSecCheckPoint Test ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setUsers(json);
         })
     }

    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/CheckPoint/' + props.UserGroupKey + '/UserGroup'
    //  console.log("UserSecCheckPoint Test ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setUsers(json);
         })
         .catch(error => {
            console.log("GET Error: " + error);
        })
     },[props.UserGroupKey])
 
     if (!isLoaded){
         return(<div>
             <h4> loading user group list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>

                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={refreshUserData}
                                >
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>

                                <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={addSecCheckUserGroupLink}
                                >
                                    <img
                                    src={PlusIcon}
                                    alt="Link"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Link
                                </Button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={deleteApplicationSecurityFromGroup}
                                >
                                    <img
                                    src={TrashIcon}
                                    alt="Unlink"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Unlink
                                </Button> */}

                                <button className="btn-no-border">           
                        <ConfirmModal outline={true}
                                //   color="danger"
                                  className="btn-no-border"
                                  buttonLabel="Unlink"
                                 // hidden ={!(user.account.userName.toLowerCase().includes('hau.nguyen@h2obridge.com') && facilityOilTicketKey>0 )}
                                  formName="Confirm"
                                 // id={facilityOilTicketKey}
                                  message="Are you sure you want to delete selected employee?"
                                  onClickYes={deleteApplicationSecurityFromGroup}
                                  image={<img
                                    src={TrashIcon}
                                   // id={facilityOilTicketKey}
                                    
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}}/>}
                                    >
                        </ConfirmModal>    
                    </button>

                                {/* <Button outline
                                color="secondary"
                                className="btn-no-border"
                                // onClick={this.getUserGroupData}
                                >
                                    <img
                                    src={ExcelIcon}
                                    alt="Export To Excel"
                                    style={{width: "30px", margin:"0 0 0 0"}} />
                                    Export To Excel
                                </Button>  */}

                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="400px"
                         //width="Calc(100% - 5px)"
                         //width="100%"
                         dataSource={users}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         selectedKeysChanged={onGridRowSelected}
                         >  
                            <IgrTextColumn field="userGroupKey" isHidden/>  

                            <IgrTextColumn field="userGroupName" headerText="User Group Name" width="*>140" isEditable = "false"  />
                           
                            <IgrTextColumn field="userGroupDescription" headerText="User Group Description" width="*>140" isEditable = "false" />                

                            <IgrTextColumn field="appSecurityNo" headerText="Application Security No" width="*>140" isEditable = "false" />         
                            
                            <IgrTextColumn field="appSecurityName" headerText="Application Security Name" width="*>140" isEditable = "false" />                              
                            
                            <IgrTextColumn field="appSecurityDesc" headerText="Description" width="*>100" isEditable = "false" />

                            <IgrTextColumn field="applicationName" headerText="Application Name" width="*>200" isEditable = "false" />                                                            

                            <IgrTextColumn field="applicationDesc" headerText="Application Description" width="*>150" isEditable = "false"/>    

                            <IgrTextColumn field="applicationKey" isHidden/> 

                            <IgrTextColumn field="applicationSecurityUserGroupLinkKey" isHidden/> 

                            <IgrTextColumn field="applicationKeySecurityKey" isHidden />                              

                            <IgrTextColumn field="createdDateTime" headerText="Created Date"  width="*>140" isEditable = "false"/>

                            <IgrTextColumn field="createdBy" headerText="Created By"  width="*>150" isEditable = "false"/>                             
                     
                     </IgrDataGrid>
                 </FormGroup>

                 
                <Modal isOpen={modalUserGroup} toggle={() => toggleModalUserGroup()} 
                       contentClassName="safetyObsDetail-modal-style">
                    <ModalHeader toggle={() => toggleModalUserGroup()}> User Group Details Link </ModalHeader>
                    <ModalBody>
                        <UserGroupList  
                                        // toggle={this.toggleModalUserGroup}
                                        user={user}
                                        securityDetails={props.securityDetails}
                                        applicationSecurityKey = {props.applicationSecurityKey}
                                        // userGroupKey = {userGroupKey}
                                        toggleModalUserGroup = {toggleModalUserGroup}
                                        refreshData={refreshUserData}
                                        userInput ={props.userInput}
                                        >
                         
                        </UserGroupList>
                       
                    </ModalBody>
                </Modal>


     </div>)

}

const SecCheckUsers=(props)=>{
    const [isLoaded,setIsLoaded] = useState(false)
    const [users, setUsers] = useState([]) 
    //const [userGroupKey,setUserGroupKey] = useState(props.UserGroupKey) 
     //var users=[]
    useEffect(() =>{
     
     const myURL = apiURL + 'SecAdmin/SecCheckPoint/' + props.UserGroupKey + '/User'
    //  console.log("UserSecCheckPoint Test ")       
    //  console.log(myURL)
 
     fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
         .then(res => res.json())
         .then(json=>{
             setIsLoaded(true);
             setUsers(json);
         })
         .catch(error => {         
            console.log("GET Error: " + error);
        })
     },[props.UserGroupKey])
 
     if (!isLoaded){
         return(<div>
             <h4> loading users list ....</h4>
         </div>)
     }else
     return(<div>
        <FormGroup>

                 <IgrDataGrid                        
                        // ref={this.onGridRef}
                         height="420px"
                         //width="Calc(100% - 5px)"
                         //width="100%"
                         dataSource={users}
                         autoGenerateColumns="false"                                   
                         selectionMode="MultipleRow"
                         //selectedKeysChanged={this.onGridRowSelected}
                         >
                        <IgrTextColumn field="applicationSecurityKey" isHidden/>  

                         <IgrTextColumn field="userGroupKey" isHidden/> 

                         <IgrTextColumn field="userGroupName" headerText="User Group Name" width="*>140" isEditable = "false" />         
                          
                         {/* <IgrTextColumn field="userGroupDesc" headerText="User Group Description" width="*>140" isEditable = "false" />   */}


                         {/* <IgrTextColumn field="appSecurityNo" headerText="Application Security No" width="*>140" isEditable = "false" />         
 
                         <IgrTextColumn field="appSecurityName" headerText="Application Security Name" width="*>140" isEditable = "false" />  

                         <IgrTextColumn field="appSecurityDesc" headerText="Description" width="*>100" isEditable = "false" />

                         <IgrTextColumn field="applicationName" headerText="Application Name" width="*>200" isEditable = "false" />                             
                         
                        
                         <IgrTextColumn field="applicationDesc" headerText="Application Description" width="*>150" isEditable = "false"/>    
 

                         <IgrTextColumn field="applicationKey" isHidden/>  */}

                        
                         <IgrTextColumn field="empName" headerText="Employee Name" width="*>140" isEditable = "false"/>    
                                                            
                         <IgrTextColumn field="emailAddress" headerText="Email Address" width="*>140" isEditable = "false"/>    
                        
                        
                         <IgrTextColumn field="adAccount" headerText="AD Account" width="*>140" isEditable = "false"/>  

                         <IgrTextColumn field="employeeKey"  isHidden/>     

                        
                         <IgrTextColumn field="azureAD" headerText="Azure AD" width="*>140" isEditable = "false"/>
{/* 
                         <IgrTextColumn field="appModuleID" headerText="App Module ID" width="*>140" isEditable = "false"/>    

                         <IgrTextColumn field="appModuleName" headerText="App Module Name" width="*>140" isEditable = "false"/>    

                         <IgrTextColumn field="appModuleKey" isHidden/>        

                         <IgrTextColumn field="applicationSecurityUserGroupLinkKey" isHidden/>  */}

                                                             
                
                     </IgrDataGrid>
                 </FormGroup>
     </div>)
}

const UserGroupList =(props)=>{
    const [isLoaded,setIsLoaded] = useState(false)
    const [userGroupList, setUserGroupList] = useState([]) 
    const [userGroupKey, setUserGroupKey] = useState([])
    const [applicationSecurityKey, setApplicationSecurityKey] = useState(props.applicationSecurityKey)
    const [user, setUser]= useState(props.userInput)



    const closeForm=(refreshData)=>{
        if(refreshData){
           props.refreshData()
        }
        props.toggleModalUserGroup();
       
    }


    const updateUserGroup = () => {   
        // console.log("Save application security in user group details")
        const updateApplicationSecurityGroupDTO= createApplicationSecurityUserGroupLinkForm()
        // console.log(updateApplicationSecurityGroupDTO)
      
    
          let myURI = apiURL + 'SecAdmin/UserGroup/ApplicationSecurity'
          fetch(myURI , apiPOSTHeader(updateApplicationSecurityGroupDTO) )
            .then(async response => {
              const rspData = response.text();
              if (!response.ok){
                // console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                return Promise.reject(error);
                
              }
              
            //   console.log("No error here: " + rspData)
            //   test()
              closeForm(true)
            })
            .catch(error =>{
                closeForm(true)
            //   console.log("PUT Error: " + error);
            })       
    }



    const createApplicationSecurityUserGroupLinkForm=()=>{
        var tempDTO=[];
        var i=0;
        userGroupKey.map(function (line) {
           const applicationSecurityCreate = {
                "userGroupKey": line,
               "applicationSecurityKey": applicationSecurityKey,              
               "createdBy": getUserADAccount(user.account.userName)
   
           }
           tempDTO[i] = applicationSecurityCreate;
           i = i + 1
        });
       
       return tempDTO;
   }
    



    function onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        // if (e.addedKeys.count > 0) {
            // let clickedRow = e.addedKeys.item(0) 
            // let dataItem = clickedRow.value[0] 
            // console.log(dataItem)
            
            // if (!this.state.hideAssociateDetails){
                // this.setState({userGroupKey: dataItem.userGroupKey,
                //     userGroupName: dataItem.userGroupName, groupDetails: dataItem})       
            // }
    
            const tempList=[] 
            let clickedRows = e.currentKeys
            
            for (let i = 0; i < e.currentKeys.count; i++){
            //    console.log(e.currentKeys.item(i).value[0] )
                tempList.push(e.currentKeys.item(i).value[0].userGroupKey) ; 
            }
            
            // selectedUserGroupChanged(dataItem.employeeKey)
            // console.log(tempList)
            setUserGroupKey(tempList)
            // setApplicationSecurityKey(tempList)
            // setEmployeeKey(tempList);
           
            // console.log("hello")
        // }
           
    }


    useEffect(() =>{
     
        const myURL = apiURL + 'SecAdmin/UserGroup'
        // console.log("UserGroupUsers ")       
        // console.log(myURL)
    
        fetch(myURL, apiHeader('GET'), {modes: 'no-cors'})
            .then(res => res.json())
            .then(json=>{
                setIsLoaded(true);
                setUserGroupList(json);
            })
            .catch(error => {
                console.log("GET Error: " + error);
            })
        },[props.applicationSecurityKey])

        if (!isLoaded){
            return(<div>
                <h4> loading UserList list ....</h4>
            </div>)
        }else
        return(<div>
            <FormGroup>
    
                             <Button outline
                                    color="secondary"
                                    className="btn-no-border"
                                    // onClick={refreshUserData()}
                                    >
                                        <img
                                        src={RefreshIcon}
                                        alt="Refresh"
                                        style={{width: "30px", margin:"0 0 0 0"}} />
                                        Refresh
                                </Button>
     
                     <IgrDataGrid                        
                            // ref={this.onGridRef}
                             height="300px"
                             //width="Calc(100% - 5px)"
                            //  width="100%"
                             dataSource={userGroupList}
                             autoGenerateColumns="false"                                   
                             selectionMode="MultipleRow"
                             selectedKeysChanged={onGridRowSelected}
                             >         
     
                                <IgrTextColumn field="userGroupName" headerText="Group Name" width="*>140" isEditable = "false"/>                              
                                
                                <IgrTextColumn field="userGroupDesc" headerText="Description" width="*>250"  isEditable = "false" />

                                <IgrTemplateColumn field="active" headerText="Active" width="100" 
                                // cellUpdating={this.onActiveUpdating}
                                isEditable = "false"
                                />                                

                                <IgrTextColumn field="createdBy" headerText="Created By" width="*>150"  isEditable = "false"/>    

                                <IgrTemplateColumn field="createdDatetime" headerText="Created Date" width="*>140"                                           
                                                    // cellUpdating={this.onReportDateUpdating}
                                                    isEditable = "false"
                                                    />                               
                                
                                <IgrTextColumn field="modifiedBy" headerText="Modified By" width="*>150"  isEditable = "false" />                                   
                                
                                <IgrTextColumn field="modifiedDateTime" headerText="Modified Date" width="*>140"                                           
                                                    //cellUpdating={this.onReportDateUpdating}
                                                    isEditable = "false"
                                                    />
                            
                                <IgrNumericColumn field="userGroupKey" isHidden/>
                         </IgrDataGrid>
                            <div>
                             <Button color="primary" className="floatButtonCancel" 
                                 onClick={() => closeForm()}
                                 >Cancel</Button>
                            </div>
                            <Button color="primary"className="floatButtonAdd"
                                //  style={{margin:"0px 10px 0px 0px"}}
                                 onClick={()=> updateUserGroup()}
                                 >Add</Button>
                     </FormGroup>
         </div>)

}

export default class UserAdmin extends Component {
    constructor (props){
        super(props);

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.state = {
            chkPoint:[],
            isLoaded:false,
            applicationSecurityKey:-1,
            appSecurityKey: -1,
            applicationKey:-1,
            securityDetails:null,
            windowHeight:window.innerHeight,
            windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
            activeTab:'1',
            modal:false,
            user:this.props.user,
            appSecurityName:'',
            appSecurityNo:null,
            hideAssociateDetails:((typeof(props.HideAssociateDetails) ==='undefined' || props.HideAssociateDetails===null) ? false : props.HideAssociateDetails),
         
        }
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab})
        }
    }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.chkPoint;
    }

    // selectedUserGroupChanged = (userGroupKey) => {
    //     // if ((typeof(this.props.SelectedUserGroupChanged) !=='undefined' && this.props.SelectedUserGroupChanged!==null)){
    //     //     this.props.SelectedUserGroupChanged(userGroupKey)
    //     // }
                    
    // } 

    selectedUserGroupChanged = (applicationSecurityKey) => {
        // if ((typeof(this.props.SelectedUserGroupChanged) !=='undefined' && this.props.SelectedUserGroupChanged!==null)){
        //     this.props.SelectedUserGroupChanged(userGroupKey)
        // }

        this.setState({applicationSecurityKey:applicationSecurityKey})
                    
    } 

    selectedUserChanged = (appSecurityNo) =>{
        this.setState({appSecurityNo: appSecurityNo})
    }
    toggleModalUser = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modalUser: !this.state.modalUser})
    }

    onGridRowSelected(s, e) {
        // console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            // console.log(dataItem)
            
            if (!this.state.hideAssociateDetails){
                this.setState({userGroupKey: dataItem.userGroupKey,
                    userGroupName: dataItem.userGroupName,
                    applicationSecurityKey: dataItem.applicationSecurityKey,
                    appSecurityNo:dataItem.appSecurityNo,
                    securityDetails: dataItem})       
            }
         

            // const tempList=[]
            // //let clickedRows = e.currentKeys
            
            // for (let i = 0; i < e.currentKeys.count; i++){
            //    // console.log(e.currentKeys.item(i).value[0] )
            //     tempList.push(e.currentKeys.item(i).value[0].applicationKey); 
            // }
            
            // this.selectedUserGroupChanged(tempList)
            this.selectedUserGroupChanged(dataItem.applicationSecurityKey)
            // console.log(dataItem.applicationSecurityKey)

            this.selectedUserChanged(dataItem.appSecurityNo)
            // console.log(dataItem.appSecurityNo)
      
        } 
    }
    
    
    
    toggleModal = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
    }

    toggleModalUserGroup = () => {  
        // console.log("Modal: " + this.state.modal)
        this.setState({modalUserGroup: !this.state.modalUserGroup})
    }


    getUserData = () =>{
        const {user}=this.state;

        let myURI = apiURL + 'SecAdmin/SecCheckPoint' 
        // /' + user.account.userName

        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                isLoaded: true,
                chkPoint: json
            })
        })
    }

    handleResize = (e) => {
   
        if (document.getElementById('mainDiv') !== null){
            this.setState({ windowHeight: window.innerHeight,
                windowWidth: document.getElementById('mainDiv').offsetWidth });

        }
    };

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getUserData();
        this.refreshUserData();
        //this.selectedFacilityChanged(this.state.facilityKeys)
    }


    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    deleteUser = () =>{

    } 

    editUser = () => {
        // console.log("In edit  log")
        //this.getUserData()
        this.toggleModal()
    }

    createSecurityForm(){
        const {chkPoint} = this.props
        const {secDetails} = this.state
        const securityCreate = {
            "applicationKey": 0,
            "applicationName": "",
            "appSecurityName": "",
            "appSecurityDesc": "",
            "createdBy": "",
            "applicationSecurityKey": -1
        
            }
            return securityCreate
    }

    addSecurity = () => {
        let newSecurity=this.createSecurityForm()
        this.setState({securityDetails:newSecurity})
        this.toggleModal()

    }

    refreshUserData =()=>{
        this.getUserData();
        // console.log("this is a test");
    }

    


    render(){
        const {isLoaded, hideAssociateDetails,chkPoint, 
            windowHeight,applicationSecurityKey,activeTab, securityDetails, applicationKey, appSecurityNo} = this.state
     const gridHeight=(windowHeight * (hideAssociateDetails ? 0.8: 0.4)) + "px"
     const searchText = window.$searchText.toLowerCase()

     if (!isLoaded){
        return(<div>
            <h3>Loading Security Checkpoint ...... </h3>
        </div>)
    }
        return (
            <div>
                 <FormGroup>
                    <Row form>
                        <h2> Security CheckPoint |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.refreshUserData}>
                                    <img
                                    src={RefreshIcon}
                                    alt="Refresh"
                                    style={{width: "25px", height:"25px", margin:"0 0 0 0"}} />
                                    Refresh
                                </Button>
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.addSecurity}>
                            <img 
                            src={PlusIcon}
                            alt="excel"
                            style={{width:"25px", height:"25px", margin:"0 0 0 0"}}/>
                            Add
                        </Button>{' '}

                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.editUser}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"25px", height:"25px", margin:"0 0 0 0"}}/>
                            Edit
                        </Button>{' '}

                        {/* <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.deleteUser}>
                            <img 
                            src={MenuIcon}
                            alt="excel"
                            style={{width:"30px", margin:"0 0 0 0"}}/>
                            Delete
                        </Button>{' '} */}


                         <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={ExcelIcon}
                            alt="excel"
                            style={{width:"25px", height:"25px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '} 

                       
                            
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>
                <div>
                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height="300px"
                        //width="Calc(100% - 5px)"
                        //width="100%"
                        dataSource={chkPoint.filter(rec => ((rec.wbrEmpID || '').toLowerCase().includes(searchText)) ||
                                                (rec.empName || '').toLowerCase().includes(searchText) ||
                                                (rec.empStatusDesc || '').toLowerCase().includes(searchText) ||
                                                (rec.emailAddress || '').toLowerCase().includes(searchText) ||
                                                (rec.azureAD || '').toLowerCase().includes(searchText) ||
                                                (rec.locationName || '').toLowerCase().includes(searchText) ||                                                            
                                                (rec.managerNameLvl1 || '').toLowerCase().includes(searchText) )}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}>         


                        <IgrTextColumn field="appSecurityNo" headerText="ID" width="100"  isEditable = "false" />   
                        <IgrTextColumn field="appSecurityName" headerText="Application Security Name" width="*>140" />                              
                        
                        <IgrTextColumn field="appSecurityDesc" headerText="Description" width="*>100"  isEditable = "false" />

                                         
                         <IgrTemplateColumn field="createdDateTime" headerText="Created Date" width="*>140"                                           
                                            cellUpdating={this.onReportDateUpdating}
                                            isEditable = "false"
                                            />
                        <IgrTextColumn field="applicationName" headerText="Application Name" width="*>120"  isEditable = "false" />       
                        <IgrTextColumn field="applicationDesc" headerText="Appliction Desc" width="*>230"  isEditable = "false" />       
                        <IgrTextColumn field="appModuleName" headerText="Module Name" width="*>150"  isEditable = "false" />       
                        <IgrTextColumn field="appModuleID" headerText="Module ID" width="*>150"  isEditable = "false" />       
                        
                        <IgrNumericColumn field="applicationSecurityKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>

                <FormGroup hidden={hideAssociateDetails}> 
                <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: activeTab === '1'})}
                                      onClick={() => {this.toggle('1')}}>
                                <h5>UserGroup</h5>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({active: activeTab === '2'})}
                                      onClick={() => {this.toggle('2')}}>
                                <h5>Users</h5>
                            </NavLink>
                        </NavItem>
                                  
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">                                                        
                                <SecCheckUserGroup securityDetails={securityDetails} UserGroupKey= {applicationSecurityKey} toggleModalUserGroup = {this.toggleModalUserGroup} userInput ={this.props.user}  applicationSecurityKey = {applicationSecurityKey} >

                                </SecCheckUserGroup>
                        </TabPane>

                        <TabPane tabId="2">
                                <SecCheckUsers UserGroupKey = {appSecurityNo} userInput ={this.props.user}>

                                </SecCheckUsers>
                            
                        </TabPane>

                      
                        
                    </TabContent> 

                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} 
                       contentClassName="oilTicketDetail-modal-style">
                    <ModalHeader toggle={() => this.toggleModal()}> Application Security Details </ModalHeader>
                    <ModalBody>
                    <SecurityCheckpointAdminDetails    
                                     user={this.props.user}
                                     toggle={this.toggleModal}
                                     securityDetails={securityDetails}
                                     refreshUserData={this.getUserData}
                                     userInput ={this.props.user}
                                     >

                    </SecurityCheckpointAdminDetails>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="primary" className="mr-auto">View Ticket Image</Button>
                     
                        <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                        <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                    </ModalFooter> */}
                </Modal>

{/* 
                <Modal isOpen={this.state.modalUserGroup} toggle={() => this.toggleModalUserGroup()} 
                       contentClassName="safetyObsDetail-modal-style">
                    <ModalHeader toggle={() => this.toggleModalUserGroup()}> User Group Details Link </ModalHeader>
                    <ModalBody>
                        <UserGroupList  
                                        // toggle={this.toggleModalUserGroup}
                                        user={this.props.user}
                                        securityDetails={securityDetails}
                                        applicationSecurityKey = {applicationSecurityKey}
                                        // userGroupKey = {userGroupKey}
                                        toggleModalUserGroup = {this.toggleModalUserGroup}
                                        refreshUserData={this.getUserData}
                                        userInput ={this.props.user}
                                        >
                         
                        </UserGroupList>
                       
                    </ModalBody>
                </Modal>
 */}





                </FormGroup>
                
            </div>
            </div>
        );
    }
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDateTime).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        // console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]==actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]==actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "SecurityCheckPoint");
    }
}