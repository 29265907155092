import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardBody, CardText, CardHeader, CardFooter, CardDeck, CardColumns, UncontrolledTooltip } from 'reactstrap';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPUTHeader, apiDELETEHeader, apiHeader } from '../../resources/apiURL';


import ConfirmModal from '../../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import MenuIcon from '../../resources/menu.png';
import Trash from '../../resources/trash.svg'
import TrashBin from '../../resources/trash.svg'
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowser from '../../resources/DocumentBrowser';
import NumberFormat from 'react-number-format';

import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

import { IgrDataGrid } from 'igniteui-react-grids';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'

import { IgrTemplateColumn } from 'igniteui-react-grids';
import Datetime from 'react-datetime';
import moment from "moment";
import "react-datetime/css/react-datetime.css";

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();

var DatePicker = require("reactstrap-date-picker");





export default class FormRCAMethods extends Component {
    static displayName = FormRCAMethods.name;

    constructor(props) {
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);

        this.state = {
            formRCAKey: props.formRCAKey,
            Data: [],
            isLoaded: false,
            isFormReadOnly: false,
            modal: false,
            user: props.user,
            tableKey: -1,
            methodKey: -1,
            methodRec: "",

            modalMsg: false,
            msgHeader: "",
            msgBody: "",

            ddMethods: [],
            hidden: props.hidden
        }

    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }


    getddMethods = () => {
        const { user } = this.props

        //let myURI = apiURL + 'SafetyObservation/SubmitBy/' + user.account.userName

        let myURI = apiURL + 'SafetyIncident/GetMethods'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    ddMethods: json
                })
            });
    }

    createEmpList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.methodKey
            e.label = e.methodID
            tempList.push(e)
        })

        return tempList
    }



    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.Data;
    }

    toggleOK = () => {
        if (this.saveData()) {
            this.setState({
                modal: !this.state.modal,
                methodKey: -1

            })

        }


    }

    validateData = () => {
        var { methodKey } = this.state

        if (methodKey > 0) {
            return true
        } else {
            this.setState({ methodKey: 0 })
        }
    }




    createDataForCreateDTO = () => {
        const { formRCAKey, methodKey, user } = this.state

        const linetoAdd = {
            "FormRCAKey": formRCAKey, //selectedValue.key,
            "MethodKey": methodKey,

            "CreatedBy": getUserADAccount(user.account.userName),

            // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
            // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
        }
        return linetoAdd
    }

    saveData = () => {
        const { tableKey } = this.state
        if (this.validateData()) {

            if (tableKey > 0) {
                if (this.updateData()) {
                    this.setState({ tableKey: -1 })
                }
            } else {
                const addDTO = this.createDataForCreateDTO()
                let myURI = apiURL + 'SafetyIncident/AddMethod'

                fetch(myURI, apiPUTHeader(addDTO))
                    .then(async response => {
                        if (!response.ok) {
                            const rspData = response.text();
                            //console.log("Error return but you dont see")
                            const error = (rspData && rspData.message) || response.status;
                            rspData.then(t => {

                                this.setState({
                                    msgBody: t,
                                    msgHeader: "Error",
                                    modalMsg: !this.state.modalMsg

                                })
                            })
                            return Promise.reject(error);
                        }

                        console.log("No error here: ")
                        // props.onClickYes();
                        // setModal(!modal)
                        // setInsertOK(true)
                        // this.setState({ txtnewWDCusomterID: '', txtnewEnergyLinkCustName: '', txtnewWDCustomerName: '', showAddNew: false  })
                        // this.getData()
                        //this.props.refreshData()
                    })
                    .catch(error => {
                        console.log("PUT Error: " + error);
                        return false
                    })
            


            // fetch(myURI, apiPOSTHeader(addDTO))
            //     .then(async response => {
            //         const rspData = response.text();
            //         if (!response.ok) {
            //             console.log("Error return but you dont see")
            //             const error = (rspData && rspData.message) || response.status;
            //             return false
            //         }

            //         console.log("No error here: " + rspData)
            //     })
            //     .catch(error => {
            //         console.log("PUT Error: " + error);
            //         return false
            //     })




        }
        return true
    }

        return false
    }

onTextChange = (fieldName, fieldValue, e) => {
    
    if (fieldValue === null) {
        this.setState({ methodKey: -1, methodRec: "" });
    }
    else {
        this.setState({ methodKey: fieldValue.value, methodRec: fieldValue });

    }

}

toggle = () => {
    console.log("Modal: " + this.state.modal)
    this.setState({ modal: !this.state.modal })
}

newData = () => {
    this.setState({
        methodKey: -1,

        tableKey: -1,
        methodRec: ""

    })
    this.toggle()
}

onGridRowSelected(s, e) {
    //console.log(" On CLick")
    //const content = e.content;
    if (e.addedKeys.count > 0) {
        let clickedRow = e.addedKeys.item(0)
        let dataItem = clickedRow.value[0]
        // this.setState({ selAFELine: dataItem })           
        this.setState({
            // sequence: dataItem.sequence,

            tableKey: dataItem.objectLinkKey
        })
    } else {
        this.setState({
            // sequence: dataItem.sequence,

            tableKey: -1
        })
    }


}



refreshData = () => {
    this.getData()
}

getData = () => {
    const { formRCAKey } = this.state
    let myURI = apiURL + 'SafetyIncident/getMethodsUsed/' + formRCAKey

    console.log(myURI);

    fetch(myURI, { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
            this.setState({
                Data: json,
                isLoaded: true
            })
        });
}
handleChange = (v, f) => {
    // if (v == '') {
    //     this.setState({ invalidDate: true })
    // }

    this.setState({
        // data[0].incDateTime = moment(fieldValue).format();


        // cmtDate: v
    })
}

componentDidMount() {
    this.getData();
    this.getddMethods()
}

onCellUpdating(s, e) {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
    let link;
    if (content.childElementCount === 0) {
        link = document.createElement("label");
        let cell = document.createElement("div");

        content.style.fontFamily = "Verdana";
        content.style.fontSize = "13px";

        content.style.margin = "0px -20px -10px -12px";
        content.style.padding = "0px 10px 10px 10px";
        //content.style.width = "100%";

        content.appendChild(link);
    }
    else {
        link = content.children[0];

    }

    switch (s.field) {
        case 'datetime':

            var date = new Date(item.datetime);
            link.textContent = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();;

        default:

    }

}


createDataForDeleteLine = () => {
    const { tableKey } = this.state


    const dataForDeleteDTO = {
        "ObjectLinkKey": tableKey,
        // "DeletedByAD": getUserADAccount(user.account.userName)
        // ,
        // "StatusKey": data[0].statusKey
    }

    return dataForDeleteDTO;
}

deleteLine = () => {

    const createDataforDelete = this.createDataForDeleteLine()


    let myURI = apiURL + 'SafetyIncident/DeleteMethod'
    fetch(myURI, apiDELETEHeader(createDataforDelete))
        .then(async response => {
            if (!response.ok) {
                const rspData = response.text();
                //console.log("Error return but you dont see")
                const error = (rspData && rspData.message) || response.status;
                rspData.then(t => {
                    this.setState({
                        msgBody: t,
                        msgHeader: "Error",
                        modalMsg: !this.state.modalMsg
                    })
                })
                return Promise.reject(error);
            }

            console.log("No error here: ")
            this.getData();
            this.setState({ tableKey: -1 })
            // this.setShowingAlert(true)
            //this.props.refreshData()
        })
        .catch(error => {
            console.log("PUT Error: " + error);
        })

}



render() {
    const { user, formRCAKey, Data,
        isLoaded, isFormReadOnly,
        timelineKey,
        methodKey, ddMethods, tableKey, msgHeader, msgBody, methodRec, hidden} = this.state

    if (!isLoaded) {
        return (<div>
            Loading Data ......
        </div>)
    } else
        return (
            <div>



                {/* <Row>
                    <div >
                        <Label >

                            ddddd {data[0].statusKey} {user} isReadOnly:<b>{isReadOnly ? "true" : "false"}</b>   isUser:<b>{isUser ? "true" : "false"}</b>   isAdmin:<b>{isAdmin ? "true" : "false"}</b>    isApprover:<b>{isApprover ? "true" : "false"}</b>  isEditApprover:<b>{isEditApprover ? "true" : "false"}</b>  
                            ddddtl {timelineKey} tableKey:{tableKey}--
                        </Label>

                    </div>
                </Row> */}


                {/* <Row form style={{ marginLeft: "0px", marginTop: "-15px", marginBottom: "5px" }}> */}
                {/* <Col sm={12}> */}
                {/* <Label className="float-left" style={{marginTop:"5px"}}>Receiver Line -> </Label>                             */}

                {/* <Row form style={{ marginLeft: "-20px", marginTop: "2px", marginBottom: "2px", marginRight: "-20px"}}> */}
                <Row form style={{  marginLeft: "-20px",marginTop: "4px"}}>
                    <Col md={12}>

                        <Button color="primary" outline className="float-left"
                            style={{ height: "35px" }}
                            hidden={hidden}
                            onClick={() => this.newData()}
                        >Add</Button>



                        {/* <Button color="primary" outline className="float-left"
                                style={{ height: "35px", marginLeft: "10px" }}
                                onClick={() => this.toggle()}
                            >Edit</Button> */}

                        <ConfirmModal outline={true}
                            color="danger"
                            className="float-right"
                            buttonLabel="Delete"
                            // hidden={!(data[0].statusKey == 0 && isAdmin)}
                            hidden={hidden}
                            disabled={tableKey < 0}
                            formName="Confirm"
                            // id={safetyObservationKey}
                            message="Are you sure you want to delete? (cannot be undone)"
                            // onClickYes={(e) => this.deleteLine()}
                            onClickYes={this.deleteLine}
                        />



                        {/* <Button color="danger" outline className="float-right"
                                style={{ height: "35px" }}
                            // onClick={() => this.newData()}
                            >Delete</Button> */}
                    </Col>



                </Row>

{/* top  */}
                <Row style={{ paddingTop: "5px", paddingRight: "20px"}}>

                    <IgrDataGrid
                        ref={this.onGridRef}
                        height={(Data.length > 10 ? "300px" : "100%")}
                        width='100%'
                        dataSource={Data}
                        //isColumnOptionsEnabled="false"
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        editModeClickAction="SingleClick"
                        editMode={0}
                        selectedKeysChanged={this.onGridRowSelected}
                    >



                        <IgrTextColumn
                            field="methodID"
                            headerText="Methods Used"
                            width="*>350"
                        // cellUpdating={this.onCellUpdating}
                        />

                        {/* <IgrTextColumn
                            field="createdBy"
                            headerText="By"
                            width="140"
                        /> */}

                        {/* <IgrTextColumn
                            field="methods"
                            headerText="Methods"
                            width="*>350"
                        /> */}

                        <IgrNumericColumn field="investigativeKey" isHidden />
                    </IgrDataGrid>




                </Row>




                <div>
                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()}
                        onClosed={() => this.refreshData()}
                        //contentClassName="oilTicketDetail-modal-style"
                        size='lg'
                    >
                        <ModalHeader toggle={() => this.toggle()}
                        > Add Method </ModalHeader>
                        <ModalBody>
                            <FormGroup row>
                                <Label for="method" sm={2}>Method</Label>
                                <Col sm={10}>


                                    <Select options={this.createEmpList(ddMethods)}
                                        //className={(!validDataItem.toLocationKey ? "form-error" : "")}
                                        // disabled={isReadOnly}
                                        id="method"
                                        name="method"

                                        className={(methodKey === 0 ? "form-error" : "")}
                                        // className={"form-error"}
                                        value={methodRec}
                                        // value={this.getSelEmp(data[0].otherInfoPersonCompleting)}
                                        // defaultValue={this.getSelEmp(afeData[0].afeManagerKey)}
                                        onChange={(e) => this.onTextChange("method", e, e)}
                                        isClearable={true}

                                    >
                                    </Select>
                                    
                                </Col>
                            </FormGroup>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleOK()}>OK</Button>
                            <Button color="primary" onClick={() => this.toggle()}>Cancel</Button>

                        </ModalFooter>
                    </Modal>
                </div>

                <div>
                    <Modal isOpen={this.state.modalMsg}
                        toggle={() => this.toggleMsg()}
                        size="lg">
                        <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                        </ModalHeader>
                        <ModalBody>
                            {msgBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => this.toggleMsg()}
                            >
                                OK
                            </Button>
                            {' '}

                        </ModalFooter>
                    </Modal>
                </div>

            </div>
        )
}
}