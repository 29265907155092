import React,{Component} from 'react';
import {apiURL} from '../resources/apiURL';
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
import Excel from '../resources/excel.svg'
import '../custom.css';
import {ExcelUtility} from '../operation/ExcelUtility';

import {Row, Button, FormGroup} from 'reactstrap';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ColoredLine} from '../resources/utility';


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();

export default class Supplier extends Component{
    static displayName = Supplier.name;

    constructor(props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this)
        this.onReportDateUpdating = this.onReportDateUpdating.bind(this)
        this.state = {
            isSupplierLoaded:false,
            supplierData:[],
            windowHeight:window.innerHeight,
            wdSupplierKey:-1,
            supplierStatus:'',    
            supplierName:'',     
            modal:false
        }
    }

    toggle = () => {
        console.log("Modal: " + this.state.modal)
        this.setState({modal: !this.state.modal})
                
    }
    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.supplierData;
      
    }

    getSupplierData=() =>{
        let myURI = apiURL + 'SupplyChain/Supplier'

        console.log(myURI);

        fetch(myURI , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isSupplierLoaded: true,
                    supplierData:  json
                })
            });
    }

    onGridRowSelected(s, e) {
        console.log(" On CLick")
        //const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0) 
            let dataItem = clickedRow.value[0] 
            console.log(dataItem)
            
            this.setState({wdSupplierKey: dataItem.wdSupplierKey,
                            supplierStatus: dataItem.supplier_Status,
                            supplierName: dataItem.supplier_Name})       
            //this.toggle()
            //this.onVolRefresh()    
        } 
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    } 

    componentDidMount(){
        window.addEventListener("resize", this.handleResize);
        this.getSupplierData()
    }

    render() {
        var {isSupplierLoaded,supplierData, windowHeight,wdSupplierKey,supplierStatus, supplierName} = this.state;
        const gridHeight=(windowHeight * 0.85) + "px"
        const searchText = window.$searchText.toLowerCase()

        if (!isSupplierLoaded){
            return(<div>
                <h3>Loading Supplier Data .....</h3>
            </div>)
        }
        return(<div>
             <FormGroup>
                    <Row form>
                        <h2> Workday Supplier List |</h2>
                        <Button outline
                                color="secondary"
                                className="btn-no-border"
                                onClick={this.getSupplierData}>
                                    <img
                                    src={Refresh}
                                    alt="Refresh"
                                    style={{width: "30px", margin:"0 5px 0 0"}} />
                                    Refresh
                                </Button>
                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.saveWorkbook}>
                            <img 
                            src={Excel}
                            alt="excel"
                            style={{width:"50px", margin:"0 0 0 0"}}/>
                            Export To Excel
                        </Button>{' '}

                        <Button outline color="secondary" 
                                className="btn-no-border" 
                                onClick={this.toggle}>
                            <img 
                            src={Plus}
                            alt="new"
                            style={{width:"25px", margin:"0 5px 0 0"}}/>
                            Edit
                        </Button>{' '}

                            
                    </Row>
                    <ColoredLine color="grey" />      
                </FormGroup>

                <FormGroup>
                <IgrDataGrid                        
                        ref={this.onGridRef}
                        height={gridHeight}                       
                        //dataSource={safetyObs}
                        dataSource={supplierData.filter( rec =>  ((rec.supplier_ID || '').toLowerCase().includes(searchText)) ||
                                                            (rec.supplier_Name || '').toLowerCase().includes(searchText) ||
                                                            (rec.supplier_Status || '').toLowerCase().includes(searchText) ||
                                                            (rec.supplier_Category || '').toLowerCase().includes(searchText) ||
                                                            (rec.remit_To_Address || '').toLowerCase().includes(searchText))}
                        autoGenerateColumns="false"                                   
                        selectionMode="SingleRow"
                        selectedKeysChanged={this.onGridRowSelected}
                        //cellBackground={"#ffbfbf"}
                        >                      
                        
                        
                        <IgrTemplateColumn field="supplier_ID" headerText="Supplier ID" width="*>190" 
                        cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrTemplateColumn field="supplier_Name" headerText="Supplier Name" 
                            width="*>240"  cellUpdating={this.onStatusCellUpdating}
                         />


                        <IgrTemplateColumn field="supplier_Status" headerText="Status" 
                            width="*>150" 
                            cellUpdating={this.onStatusCellUpdating}
                            />
                                                  
                        <IgrTextColumn field="default_Payment_Term_ID" headerText="Payment Term" 
                            width="*>140" />

                        <IgrTextColumn field="supplier_Category" headerText="Category" 
                            width="*>180" />
                       
                       <IgrTextColumn field="supplier_Group" headerText="Supplier Group" 
                            width="*>150" />                  
                            
                        
                        <IgrTextColumn field="supplier_Contacts" headerText="Supplier Contacts" 
                            width="*>150" />  

                        <IgrTextColumn field="remit_To_Address" headerText="Remit To Address" 
                            width="*>150" />                  


<IgrTextColumn field="statementReview" headerText="statementReview" 
                            width="*>150" />           

                            
                        <IgrTextColumn field="monthlyEmail" headerText="monthlyEmail" 
                            width="*>150" />           
                        <IgrTextColumn field="last3MoTotalInvAmt" headerText="last3MoTotalInvAmt" 
                            width="*>150" />                  


                        <IgrTextColumn field="last3MoTotalNoInv" headerText="last3MoTotalNoInv" 
                            width="*>150" />           

                        <IgrTemplateColumn field="createdDateTime" headerText="Created Date" 
                            width="*>140" dateTimeFormat={"DateShort"}
                            cellUpdating={this.onReportDateUpdating} />
                        
                        
                        <IgrNumericColumn field="wdSupplierKey" isHidden/>
                    </IgrDataGrid>
                </FormGroup>

            
                <div>
                <Modal isOpen={this.state.modal} 
                      toggle={() => this.toggle()}                  
                  size="lg"
                centered>
                    <ModalHeader className={(supplierStatus==="Inactive"?"modal-header reject":"")}  
                        toggle={() => this.toggle()}>{ wdSupplierKey< 0 ? "New Supplier" : "Supplier Details - " + supplierName} 
                    </ModalHeader>
                    <ModalBody>
                     {/* <WarehouseDetail 
                        wdSupplierKey={wdSupplierKey}
                        user={this.props.user}
                        toggle={this.toggle}
                        refreshData={this.getSupplierData}>

                        </WarehouseDetail> */}
                      Supplier Details - Comming in 2022
                    </ModalBody>                    
                </Modal>
                </div>


        </div>)
    }
    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
       // content.style.backgroundColor = "#4286f4";

        // console.log("item.statusKey")
        // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            // let cell = document.createElement("div");
            
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";          
            
            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            //content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
           
            if (item.supplier_Status==="Inactive"){
                content.style.background = "#c69089";
            }else {
                content.style.background = "transparent";
            }
        }


        switch(s.field){
            case 'supplier_ID':
                link.textContent = item.supplier_ID;
            break;
            case 'supplier_Name':
                link.textContent = item.supplier_Name;
                break;
            case 'supplier_Status':
                link.textContent = item.supplier_Status;

                break;
            // case 'locationName':
            //     link.textContent = item.locationName;
            //     break;
            // case 'hazardDesc':
            //     link.textContent = item.hazardDesc;
            //     break;      
            // case 'solutionDesc':
            //     link.textContent = item.solutionDesc;
            //     break;       
            // case 'createdByName':
            //     link.textContent = item.createdByName;
            //     break;          
            // case 'managerName':
            //     link.textContent = item.managerName;
            //     break;
            // case 'locationName':
            //     link.textContent = item.locationName;
            //     break;    
            // case 'facilityName':
            //     link.textContent = item.facilityName;
            //     break;                                     
            default:

        }        
      
    }

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            // let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDateTime).toLocaleDateString("es-PA");
    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0) )
        
        let gridDts = this.grid.dataSource // this.state.inspItems
        
        const headers = Object.keys(gridDts[0]);        
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {            
            for (let j=0; j < actCols.count; j++){
                if (headers[i]===actCols.item(j).field){
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value=actCols.item(j).field
                    }
                }
            }
            
        }

        for (let i = 0; i <gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++){

                    if (headers[j]===actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);        
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "SupplierList");
    }
}