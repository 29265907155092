
import React, { Component, useState, useEffect } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert} from 'reactstrap';
import '../custom.css';
import { apiURL, apiPUTHeader, apiDELETEHeader, apiHeader, apiPOSTHeader } from '../resources/apiURL';
import ConfirmModal from '../operation/ConfirmModal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../resources/plus.svg'
import TrashBin from '../resources/trash.svg'
import MenuIcon from '../resources/menu.png'
import Select from 'react-select';
import { getUserADAccount} from '../resources/utility'
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ColoredLine } from '../resources/utility';
import ConfirmMeterValModal from '../operation/ConfirmMeterValModal';
// import { copySync } from 'fs-extra';
import moment from "moment";
import DocumentBrowserDragDrop from '../resources/DocumentBrowserDragDrop'


IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");


const ReceiverLineCRUD = (props) => {

    const { receiverKey, receiverLineKey,
        rcvrLine, lineData, rcvrDetailToInvLocDefault, receiverTypeKey, user, selBasinKey,
        statusKey, statusKeyBool, poNumber } = props

    const [alertMessage, setAlertMessage] = useState("")
    const [showAlert, setShowAlert] = useState(false)

    const [invLocList, setInvLocList] = useState([])

    const [scLoaded, setSCLoaded] = useState(false)

    const [scList, setSCList] = useState([])

    const [scListMain, setSCListMain] = useState([])

    const [scListDefault, setSCListDefault] = useState([])

    const [afe, setAFE] = useState([])
    const [afeMain, setAFEMain] = useState([])
    const [afeDefault, setAFEDefault] = useState([])


    const [itemData, setItemData] = useState([])
    const [itemDataMain, setItemDataMain] = useState([])
    const [itemDefault, setItemDataDefault] = useState([])
    const [wh, setWH] = useState([])

    // const [itemDataArray, setItemDataArray] = useState([])

    const [afeLoaded, setAFELoaded] = useState(false)
    const [spendCategory, setSpendCategory] = useState("")

    const [itemUOMList, setItemUOMList] = useState([])



    const [isItemDataLoaded, setIsItemDataLoaded] = useState(false)

    const [itemNoVal, setItemNoVal] = useState(true)
    const [itemMasterKeyVal, setItemMasterKeyVal] = useState(true)

    const [locVal, setLocVal] = useState(true)
    const [uomVal, setUOMVal] = useState(true)
    const [unitPriceVal, setUnitPriceVal] = useState(true)
    const [receivedQtyVal, setReceivedQtyVal] = useState(true)
    const [spendCategoryIDVal, setSpendCategoryIDVal] = useState(true)
    const [isInvLocLoaded, setIsInvLocLoaded] = useState(false)

    const [msgBody, setMsgBody] = useState("")
    const [msgHeader, setMsgHeader] = useState("")
    const [modalMsg, setModalMsg] = useState(false)

    //  const dataValidation = () => {
    //     return new Promise((resolve, reject) => {
    //         const returnVal = { "ok": true, "message": "", "headerValidation": [] }


    //         //Line Validation
    //         var rcvrLines = rcvrLine.filter((e) => Number(e.qtyReceived) > 0)
    //         console.log(rcvrLines)
    //         if (rcvrLines.length > 0) {
    //             var lineNOs = ""
    //             rcvrLines = rcvrLine.filter((e) => Number(e.qtyReceived) > 0 && (e.itemMasterKey === null || e.itemMasterKey === 'null'))

    //             if (rcvrLines.length > 0) {
    //                 rcvrLines.map(a => {
    //                     lineNOs = (lineNOs !== "" ? "," : "") + a.rcrvLineNo
    //                 })

    //                 returnVal.ok = false
    //                 returnVal.message = "Missing receive line item number. Please select item for line(s):" + lineNOs

    //             }
    //         } else {
    //             returnVal.ok = false
    //             returnVal.message = "Missing line receive quantity. Please enter received quantity for line(s) you want to received then try again."
    //             //reject(Error())
    //         }

    //         resolve(returnVal)
    //     })
    // }




    const getSelUOM = (value) => {

        if (value !== null && value !== "") {
            return itemUOMList.filter(e => e.uomKey === value)

        }
        return null
    }

    const getSelAFE = (value) => {

        if (value !== null && !isNaN(value)) {
            // console.log(value)
            // console.log(afe.filter(e => e.wdProjectKey === value))
            return afe.filter(e => e.wdProjectKey === value)

        }
        else {
            console.log("It is not a number or null")
        }
        return null
    }





    const getSelInvLoc = (value) => {
        // const { invLocation } = this.state
        if (value !== null && value !== "") {
            return invLocList.filter(e => e.invLocationKey === value)

        }
        return null
    }

    const getSelToLocKey = (value) => {
        return value.invLocationKey
    }

    const getSelUOMKey = (value) => {
        return value.uomKey
    }

    const getSelAFEKey = (value) => {
        // return 996
        return value.wdProjectKey
    }



    const getSelItemNumber = (value) => {
        return value.longItemNumber
    }

    const getSelItemMasterKey = (value) => {
        return value.itemMasterKey
    }


    const getSelSCKey = (value) => {
        return value.spendCategoryID
    }

    const getSelSC = (value) => {

        if (value !== null && value !== "") {
            return scList.filter(e => e.spendCategoryID === value)

        }
        return null
    }





    const closeForm = (refreshData) => {
        var cont = true;
        // if (bFreshData) this.props.refreshOilTicket();
        if (lineData.uomKey == null) {
            setUOMVal(false)
            cont = false

        }
        if (statusKeyBool) {
            console.log("Form closed successful")
        }
        else if (statusKey !== 2 && lineData.qtyReceived <= 0.00) {
            setReceivedQtyVal(false)
            cont = false
        }
        if (lineData.unitPrice <= 0.00) {
            setUnitPriceVal(false)
            cont = false
        }
        if (lineData.toLocationKey == null && rcvrDetailToInvLocDefault == null) {
            setLocVal(false)
            cont = false
        }

        if (lineData.spendCategoryID == null) {
            setSpendCategoryIDVal(false)
            cont = false
        }
        if (lineData.itemMasterKey == null) {
            setItemMasterKeyVal(false)
            cont = false;
        }
        // else{
        if (cont) {
            if (refreshData) {
                props.refreshData()


            }
            props.toggleTest()

        }

        return cont
        // }

    }


    const closeForm2 = (refreshData) => {
        // if (bFreshData) this.props.refreshOilTicket();

        if (refreshData) {
            props.refreshData()

        }
        props.toggleTest()



    }

    const refreshDataOnSubmit = (refreshData) => {
        // if (bFreshData) this.props.refreshOilTicket();
        if (refreshData) {
            props.refreshData()
            // props.calInvtotal()
        }


    }




    const updateLineDetail = () => {
        // const {statusKey} = this.state
        var cont = true;
        // if(validateData()){


        if (lineData.uomKey == null) {
            setUOMVal(false)
            cont = false

        }
        if (statusKeyBool) {
            console.log("Hit 1")
        }
        else if (statusKey !== 2 && (lineData.qtyReceived <= 0.00 || (receiverTypeKey === 1 && lineData.qtyReceived > lineData.qtyOrdered))) {
            console.log("Hit 2")
            console.log("Message pop")
            setAlertMessage("Received Quantity can not be 0 or greater than Order Quantity")
            setShowAlert(true)
            setReceivedQtyVal(false)
            cont = false
        }
        if (lineData.unitPrice <= 0.00) {
            setUnitPriceVal(false)
            cont = false
        }
        if (lineData.toLocationKey == null && rcvrDetailToInvLocDefault == null) {
            setLocVal(false)
            cont = false
        }

        if (lineData.spendCategoryID == null) {
            setSpendCategoryIDVal(false)
            cont = false
        }
        if (lineData.itemMasterKey == null) {
            setItemMasterKeyVal(false)
            cont = false;
        }
        // else{

        // dataValidation().then((data)=>{
        //     if(data.ok){
        console.log(cont)
        if (cont) {
            console.log(cont)
            const createupdateLineDetailDTO = updateLineDetailDTO()

            let myURI = apiURL + 'SupplyChain/ReceiverLine'
            fetch(myURI, apiPUTHeader(createupdateLineDetailDTO))
                .then(async response => {
                    const rspData = response.text();
                    if (!response.ok) {

                        const error = (rspData && rspData.message) || response.status;
                        rspData.then(t => {
                            setMsgBody(t)
                            setMsgHeader("Error")
                            setModalMsg(!modalMsg)
                        })
                        return Promise.reject(error);

                    }
                    console.log("This hit the form")

                    closeForm(true)

                    //this.props.refreshData()
                })
                .catch(error => {

                    // closeForm(true)
                })
        }





        //     }
        //     else{
        //         setAlertMessage(data.message)
        //         setShowAlert(true)
        //     }


        // })  




    }




    const updateLineDetailDTO = () => {
        // const {grpDetails} = this.state 
        // const {user} = this.props
        const rcvrLineDTO = []
        rcvrLine.map(e => {
            // if (e.isUpdated !== 0) {
            const itemDTO = {
                "ReceiverLineKey": e.receiverLineKey,
                "POLineKey": e.poLineKey,
                "ItemMasterKey": e.itemMasterKey,
                "ItemNumber": e.itemNumber,
                "QtyReceived": e.qtyReceived,
                "UnitPrice": e.unitPrice,
                "UOMKey": e.uomKey,
                "ToLocationKey": e.toLocationKey,
                "LineComment": e.lineComment,
                "ModifiedBy": getUserADAccount(user.account.userName),
                "SpendCategoryID": e.spendCategoryID,
                "AFE": e.afe

            }

            rcvrLineDTO.push(itemDTO)
            // }


        })
        // console.log(rcvrLineDTO)
        return rcvrLineDTO
    }



    const createReceiverLine = () => {


        var cont = true;
        // if(validateData()){


        if (lineData.uomKey == null) {
            setUOMVal(false)
            cont = false

        }
        if (lineData.qtyReceived <= 0.00) {
            setReceivedQtyVal(false)
            cont = false
        }
        if (lineData.unitPrice <= 0.00) {
            setUnitPriceVal(false)
            cont = false
        }
        if (lineData.toLocationKey == null && rcvrDetailToInvLocDefault == null) {
            setLocVal(false)
            cont = false
        }

        if (lineData.spendCategoryID == null) {
            setSpendCategoryIDVal(false)
            cont = false
        }
        if (lineData.itemMasterKey == null) {
            setItemMasterKeyVal(false)
            cont = false;
        }

        if (cont) {

            const receiverLineCreateDTO = createReceiverLineCreateDTO()


            let myURI = apiURL + 'SupplyChain/ReceiverLine'

            fetch(myURI, apiPOSTHeader(receiverLineCreateDTO))
                .then(async response => {
                    const rspData = response.text();
                    if (!response.ok) {

                        const error = (rspData && rspData.message) || response.status;

                        return Promise.reject(error);

                    }

                    setUnitPriceVal(true)
                    setReceivedQtyVal(true)

                    refreshDataOnSubmit(true)


                })
                .catch(error => {
                    console.log(error)
                    if (lineData.uomKey == null) {
                        setUOMVal(false)
                    }

                    refreshDataOnSubmit(true)


                })



        }
    }


    const createReceiverLineAndClose = () => {
        var cont = true;



        if (lineData.uomKey == null) {
            setUOMVal(false)
            cont = false

        }
        if (lineData.qtyReceived <= 0.00) {
            setReceivedQtyVal(false)
            cont = false
        }
        if (lineData.unitPrice <= 0.00) {
            setUnitPriceVal(false)
            cont = false
        }
        if (lineData.toLocationKey == null) {
            setLocVal(false)
            cont = false
        }

        if (lineData.spendCategoryID == null) {
            setSpendCategoryIDVal(false)
            cont = false
        }
        if (lineData.itemMasterKey == null) {
            setItemMasterKeyVal(false)
            cont = false;
        }



        if (cont) {
            const receiverLineCreateDTO = createReceiverLineCreateDTO()



            let myURI = apiURL + 'SupplyChain/ReceiverLine'

            fetch(myURI, apiPOSTHeader(receiverLineCreateDTO))
                .then(async response => {
                    const rspData = response.text();
                    if (!response.ok) {

                        const error = (rspData && rspData.message) || response.status;
                        rspData.then(t => {
                            setMsgBody(t)
                            setMsgHeader("Error")
                            setModalMsg(!modalMsg)
                        })
                        return Promise.reject(error);

                    }

                    refreshDataOnSubmit(true)


                    closeForm(true)


                })
                .catch(error => {

                    refreshDataOnSubmit(true)




                })





        }



    }




    const createReceiverLineCreateDTO = () => {
        // const {grpDetails} = this.state 
        // const {user} = this.props
        const receiverLineCreateDTO = {

            "ReceiverKey": receiverKey,
            "POLineKey": null,
            "ItemMasterKey": lineData.itemMasterKey,
            "ItemNumber": lineData.itemNumber,
            "QtyReceived": lineData.qtyReceived,
            "UnitPrice": lineData.unitPrice,
            "UOMKey": lineData.uomKey,
            "ToLocationKey": lineData.toLocationKey,
            "LineComment": lineData.lineComment,
            "CreatedBy": getUserADAccount(user.account.userName),
            "SpendCategoryID": lineData.spendCategoryID,
            "AFE": lineData.afe
        }
        console.log(receiverLineCreateDTO)
        return receiverLineCreateDTO
    }



    // const returnToLocationKey = ()=>{
    //     if (lineData.toLocationKey == null)
    //     {
    //         return rcvrDetailToInvLocDefault
    //     }
    //     else{
    //         return lineData.toLocationKey 
    //     }
    // }



    const createWHList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.warehouseKey
            e.label = e.warehouseName
            tempList.push(e)
        })

        return tempList
    }

    const getWH = () => {


        let myURI = apiURL + 'SupplyChain/WH'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                setWH(createWHList(json.filter(e => e.locationKey === selBasinKey)))

            });



    }


    const createInvLocList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.invLocationKey
            e.label = e.warehouseInvLocation
            tempList.push(e)
        })

        return tempList
    }


    const createItemUOMList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.uomKey
            e.label = e.uomDesc
            tempList.push(e)
        })

        return tempList
    }


    const getInventoryLoc = () => {
        // const { selBasinKey } = this.state

        let myURI = apiURL + 'SupplyChain/InvLoc'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {


                setInvLocList(createInvLocList(json.filter(e => e.locationKey === selBasinKey)))
                setIsInvLocLoaded(true)


            });

    }


    const getItemUOM = () => {
        // const { selBasinKey } = this.state

        let myURI = apiURL + 'ItemMaster/ItemUOM'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                setItemUOMList(createItemUOMList(json))

            });



    }




    function createItemDataList(value) {
        var tempList = []
        value.map(e => {
            e.value = e.itemMasterKey
            e.label = e.longItemNumber
            tempList.push(e)
        })

        return tempList
    }

    function createAFEProjectList(value) {
        var tempList = []
        value.map(e => {
            e.value = e.wdProjectKey
            e.label = e.projectDesc
            tempList.push(e)
        })

        return tempList
    }




    const getSC = () => {
        let myURI = apiURL + 'ItemMaster/SC'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                setSCList(createSCList(json))
                setSCListMain(createSCList(json))
                setSCListDefault(createSCList(json))
                setSCLoaded(true)
            });


    }

    const createSCList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.spendCategoryID

            e.label = e.spendCategoryID

            tempList.push(e)
        })

        return tempList
    }



    // const createSCList2 = (value) => {
    //     var tempList = []
    //     value.map(e => {
    //         e.value = e.spendCategory
    //         e.label = e.spendCategory
    //         tempList.push(e)
    //     })

    //     return tempList
    // }


    const getItemMaster = () => {
        let myURI = apiURL + 'ItemMaster/ItemBasinDetails'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                //   setItemDataArray(createSCList2(json))

                setItemData(createItemDataList(receiverLineKey < 0 ? json.filter(a => a.locationKey === selBasinKey) : json.filter(a => a.spendCategory.includes(lineData.spendCategoryID)).filter(a => a.locationKey === selBasinKey)))
                setItemDataMain(createItemDataList(json.filter(a => a.locationKey === selBasinKey)))
                setItemDataDefault(createItemDataList(json.filter(a => a.locationKey === selBasinKey)))
                setIsItemDataLoaded(true)
                // console.log(json)
                //    console.log(json.filter(a=> a.spendCategory.includes(lineData.spendCategoryID)))
            });




    }





    const getProjectAFE = () => {

        let myURI = apiURL + 'SupplyChain/AFE'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                setAFE(createAFEProjectList(json.filter(e => e.locationKey === selBasinKey)))
                setAFEMain(createAFEProjectList(json.filter(e => e.locationKey === selBasinKey)))
                setAFEDefault(createAFEProjectList(json.filter(e => e.locationKey === selBasinKey)))
                setAFELoaded(true)

            });

    }




    useEffect(() => {
        getProjectAFE()
        getItemMaster()
        getSC()
        getInventoryLoc()
        getWH()
        getItemUOM()



    }, [props.receiverKey])
    const saveData = () => {
        var cont = true;


        if (receiverLineKey > 0) {

            updateLineDetail()
        }
        else {
            if (lineData.uomKey == null) {
                setUOMVal(false)
                cont = false

            }
            // if (statusKeyBool){

            // }
            if (statusKey !== 2 && lineData.qtyReceived <= 0.00) {
                console.log("This hit")
                console.log("StatusKey for required: " + statusKey)
                setReceivedQtyVal(false)
                cont = false
            }

            if (lineData.unitPrice <= 0.00) {
                setUnitPriceVal(false)
                cont = false
            }
            if (lineData.toLocationKey == null && rcvrDetailToInvLocDefault == null) {
                setLocVal(false)
                cont = false
            }

            if (lineData.spendCategoryID == null) {
                setSpendCategoryIDVal(false)
                cont = false
            }
            if (lineData.itemMasterKey == null) {
                setItemMasterKeyVal(false)
                cont = false;
            }
            // else{


            if (cont) {
                console.log("Form was saved anways")
                createReceiverLine()
                props.toggleLineCRUDOnSubmitTest(true)

                setSpendCategory("")
                setUnitPriceVal(true)
                setReceivedQtyVal(true)
            }


        }

    }

    const getSelItemNo = (v) => {

        if (v !== null && v !== "") {
            var test = itemData.filter(e => e.itemMasterKey === v)
            return test
            // return lineData.filter(e => e.masterItemKey === v)

        }
        return null
    }




    const onTextChange = (fieldName, fieldValue, e) => {

        console.log(fieldName + "-" + fieldValue + "-" + e)

        switch (fieldName) {
            case "itemNumber":

                if (e !== null) {


                    if (scListMain.filter(a => e.spendCategory.includes(a.spendCategoryID)) === "") {
                        setSCList(scListDefault)
                    }
                    else {
                        var scListFilter = scListMain.filter(a => e.spendCategory.includes(a.spendCategoryID))

                        // console.log(e.spendCategory)
                        setSCList(scListFilter)

                    }

                    lineData.itemNumber = (e === null ? null : getSelItemNumber(e))

                    console.log(e.itemMasterKey)

                    lineData.itemMasterKey = (e === null ? null : getSelItemMasterKey(e))

                    setItemNoVal(fieldValue != null)
                    setItemMasterKeyVal(fieldValue != null)

                }

                else {
                    setSCList(scListDefault)
                    lineData.longItemNumber = null
                    lineData.itemMasterKey = null

                    // setSCList("")

                    setItemNoVal(fieldValue !== "")
                    setItemMasterKeyVal(fieldValue !== null)
                    setSpendCategory("")
                }

                break;


            case "spendCategoryID":

                lineData.spendCategoryID = (e === null ? null : getSelSCKey(e))
                setSpendCategoryIDVal(fieldValue != null)
                break;






            case "uomKey":

                lineData.uomKey = (e === null ? null : getSelUOMKey(e))

                setUOMVal(fieldValue != null)

                break;

            case "afe":
                // console.log(e)
                // console.log(e.value + "xxx")
                // console.log(e.wdProjectKey)
                lineData.afe = (e === null ? null : getSelAFEKey(e))

                if (e === null) {
                    console.log("This hit")
                    //  setAFE(afeDefault)
                }
                //  setUOMVal(fieldValue != null)

                break;





            //   if( scListMain.filter(a=> e.spendCategory.includes(a.spendCategoryID)) == ""){
            //     setSCList(scListDefault)
            // }
            // else{
            //   var scListFilter = scListMain.filter(a=> e.spendCategory.includes(a.spendCategoryID))

            //   // console.log(e.spendCategory)
            //   setSCList(scListFilter)

            // }

            // case "toLocationKey": //Commented out by BN 10/2/23


            //     if (e !== null) {

            //         if (afeMain.filter(a => e.afe.includes(a.afeNo)) === "") {
            //             lineData.afe = null
            //             setAFE(afeDefault)
            //             console.log("hit1")
            //         }
            //         else {

            //             var afeFilter = afeMain.filter(a => e.afe.includes(a.afeNo))

            //             lineData.afe = afeFilter[0].wdProjectKey


            //             setAFE(afeFilter)

            //         }
            //         lineData.toLocationKey = (e === null ? null : getSelToLocKey(e))
            //     }
            //     else {

            //         lineData.toLocationKey = null
            //         setAFE(afeDefault)
            //     }


            //     // console.log(e.value)


            //     // rcvrDetailToInvLocDefault  = (e === null ? null : getSelToLocKey2(e))
            //     // console.log(e.afe)
            //     if (e === null) {

            //     }
            //     setLocVal(fieldValue != null)

            //     break;

            case "toLocationKey":

    if (e !== null) {
        const afeFilter = afeMain.filter(a => e.afe.includes(a.afeNo));

        if (afeFilter.length === 0) {
            lineData.afe = null;
            setAFE(afeDefault);
            console.log("hit1");
        } else {
            lineData.afe = afeFilter[0].wdProjectKey;
            setAFE(afeFilter);
        }

        lineData.toLocationKey = getSelToLocKey(e);
    } else {
        lineData.toLocationKey = null;
        setAFE(afeDefault);
    }

    setLocVal(fieldValue != null);
    break;


            case "qtyReceived":
                console.log("StatusKey: " + statusKey)
                lineData.qtyReceived = fieldValue
                setReceivedQtyVal((Number(fieldValue) !== 0.00))


                if (statusKey === 4 && fieldValue <= lineData.qtyOrdered) {
                    console.log("hitting correctly")
                    setReceivedQtyVal(true)
                    setShowAlert(false)

                }
                if (statusKey === 2) {
                    setReceivedQtyVal(true)
                    setShowAlert(false)

                }

                else if (receiverTypeKey === 1 && fieldValue > lineData.qtyOrdered) {
                    // console.log("This is hitting bull shit")
                    setReceivedQtyVal(false)
                    setAlertMessage("Received Quantity can not be greater than Order Quantity")
                    setShowAlert(true)
                }
                else {
                    setShowAlert(false)
                }






                break;


            case "unitPrice":
                // rcvrDetail[0].subTotalAmount = fieldValue * lineData.qtyReceived

                //  rcvrDetail[0].unitPrice = fieldValue 
                lineData.unitPrice = fieldValue
                //    lineData.unitPrice = Number(fieldValue)

                setUnitPriceVal(Number(fieldValue) !== 0.00)

                break;

            case "lineComment":

                lineData.lineComment = fieldValue

                break;
            default:
        }

        props.setStateOfParent(lineData)



    }

    const toggleAlert = () => {

        //    resetValidDataItem()

        setShowAlert(true)
    }

    const toggleMsg = () => {
        setModalMsg(!modalMsg)
    }


    // const resetValidDataItem = () => {

    //     if(lineData.qtyReceived < lineData.qtyOrdered){
    //         setReceivedQtyVal(true)
    //         setShowAlert(false)
    //     }

    //     //    if(lineData.qtyReceived <= 0.00 || (receiverTypeKey == 1 && lineData.qtyReceived > lineData.qtyOrdered)){
    //     //         setReceivedQtyVal(false)
    //     //         cont = false
    //     //     }
    // }



    if (!isItemDataLoaded, !scLoaded, !isInvLocLoaded, !afeLoaded) {
        return (<div>
            <h4> loading Item Data ....</h4>
        </div>)
    } else
        return (<div>

            <Form>
                <Row form>
                    <Col sm={12}>
                        <Alert color="danger" toggle={() => toggleAlert()}
                            isOpen={showAlert} style={{ marginBottom: "-5px" }}>
                            {alertMessage}
                        </Alert >
                    </Col>
                </Row>
            </Form>

            <Form>
                <Row form>
                    <Col md={6}>
                        <Label for="itemNumber">
                            Item Number
                        </Label>
                        <Select
                            className={!itemMasterKeyVal || !itemNoVal ? "form-error" : ""}

                            defaultValue={getSelItemNo(lineData.itemMasterKey)}
                            value={getSelItemNo(lineData.itemMasterKey)}
                            options={itemData}
                            onChange={(e) => onTextChange("itemNumber", e, e)}
                            isClearable={true}>

                        </Select>
                    </Col>
                    <Col md={6}>
                        <Label
                            for="spendCategory"

                        >
                            Spend Category
                        </Label>

                        <Select
                            options={scList}
                            isClearable={true}
                            className={!spendCategoryIDVal ? "form-error" : ""}
                            defaultValue={getSelSC(lineData.spendCategoryID)}
                            value={getSelSC(lineData.spendCategoryID)}
                            isDisabled={receiverTypeKey === 1}
                            onChange={(e) => onTextChange("spendCategoryID", e, e)}
                        />
                    </Col>
                </Row>
            </Form>

            <Form>
                <Row form>
                    <Col md={receiverTypeKey === 2 ? 12 : 6}>
                        <Label invalid for="rcvrLoc">Received To Inv Loc</Label>




                        <Select options={invLocList}
                            className={!locVal ? "form-error" : ""}

                            defaultValue={getSelInvLoc(lineData.tolocationKey)}
                            value={getSelInvLoc(lineData.toLocationKey)}
                            //  defaultValue={getSelInvLoc(rcvrDetailToInvLocDefault)}
                            // value={getSelInvLoc(rcvrDetailToInvLocDefault)}   

                            // defaultValue={getSelInvLoc(receiverLineKey > 0 ? lineData.toLocationKey : rcvrDetailToInvLocDefault)}               
                            // value={getSelInvLoc(receiverLineKey > 0 ? lineData.toLocationKey : rcvrDetailToInvLocDefault)}         

                            onChange={(e) => onTextChange("toLocationKey", e, e)}
                            isClearable={true}
                        // isMulti
                        >
                        </Select>
                    </Col>

                    <Col md={6} hidden={receiverTypeKey === 2}>
                        <Label hidden={receiverTypeKey === 2}>
                            Order Qty
                        </Label>

                        <NumberFormat
                            // value={0}
                            // className={"form-control text-md-right" && !receivedQtyVal ? "form-error" : "" }
                            // className={!receivedQtyVal ? "form-error form-control text-md-right " : "form-control text-md-right"}

                            className={"form-control text-md-right"}
                            disabled
                            hidden={receiverTypeKey === 2}
                            value={lineData.qtyOrdered}
                            defaultValue={lineData.qtyOrdered}
                            // onValueChange={(e) => onTextChange("qtyReceived", e.value, e)}
                            // className='form-control text-md-right'
                            fixedDecimalScale={true}
                            decimalScale={2}
                            //displayType={'text'} 
                            //disabled={isFormReadOnly}
                            thousandSeparator={true}
                        />
                    </Col>



                </Row>
            </Form>



            <Form>
                <Row form>

                    <Col md={6}>
                        <Label>
                            Received Qty
                        </Label>

                        <NumberFormat
                            // value={0}
                            // className={"form-control text-md-right" && !receivedQtyVal ? "form-error" : "" }
                            className={!receivedQtyVal ? "form-error form-control text-md-right " : "form-control text-md-right"}

                            // class={"form-control text-md-right"}

                            value={lineData.qtyReceived}
                            defaultValue={lineData.qtyReceived}
                            onValueChange={(e) => onTextChange("qtyReceived", e.value, e)}
                            // className='form-control text-md-right'
                            fixedDecimalScale={true}
                            decimalScale={2}
                            //displayType={'text'} 
                            //disabled={isFormReadOnly}
                            thousandSeparator={true}
                        />
                    </Col>

                    <Col md={3}>
                        <Label>
                            Unit Price
                        </Label>

                        <NumberFormat
                            //  value={0}
                            defaultValue={lineData.unitPrice}
                            value={lineData.unitPrice}
                            // invalid={unitPriceVal}
                            disabled={poNumber !== "" && poNumber !== null ? true : false}
                            // disabled={console.log(poNumber)}
                            onValueChange={(e) => onTextChange("unitPrice", e.value, e)}
                            className={!unitPriceVal ? "form-error form-control text-md-right " : "form-control text-md-right"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            //displayType={'text'} 
                            //disabled={isFormReadOnly}
                            // onChange={(e) => onTextChange("unitPrice", e, e)}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </Col>


                    <Col md={3}>
                        <Label invalid for="rcvrLoc">UOM</Label>



                        <Select
                            className={!uomVal ? "form-error" : ""}


                            options={itemUOMList}

                            defaultValue={getSelUOM(lineData.uomKey)}
                            value={getSelUOM(lineData.uomKey)}
                            onChange={(e) => onTextChange("uomKey", e, e)}
                            isClearable={true}
                        // isMulti
                        >
                        </Select>
                    </Col>

                </Row>
            </Form>



            <Form>
                <Row form>


                    <Col md={12}>
                        <Label invalid for="afe">AFE/Project</Label>

                        <Select
                            // className={!uomVal ? "form-error" : ""}


                            options={afe}

                            // defaultValue={getSelAFE(~~lineData.afe)}
                            value={getSelAFE(~~lineData.afe)}
                            // value={console.log(getSelAFE(~~lineData.afe))}
                            onChange={(e) => onTextChange("afe", e, e)}
                            isClearable={true}
                        // isMulti
                        >
                        </Select>
                    </Col>



                </Row>
            </Form>


            <Form>
                <Row form>

                    <Col md={12} hidden={poNumber === "" || poNumber === null ? true : false}>
                        <Label hidden={poNumber === "" || poNumber === null ? true : false}>
                            WD Line Item Description
                        </Label>
                        <Input type="textarea" name="wdLineDesc"
                            rows={4}
                            value={lineData.wdLineDesc}
                            disabled
                            hidden={poNumber === "" || poNumber === null ? true : false}
                            id="wdLineDesc"
                        // placeholder="wdLineDesc"
                        />
                    </Col>


                </Row>
            </Form>

            <Form>
                <Row form>
                    <Col md={12}>
                        <Label>
                            Comment
                        </Label>

                        <Input type="textarea" name="lineComment"
                            rows={4}
                            value={lineData.lineComment}
                            // defaultValue={lineData.lineComment}
                            //disabled={isFormReadOnly}
                            id="lineComment"
                            placeholder="Comment"

                            onChange={(e) => onTextChange("lineComment", e.target.value, e)}
                        />
                    </Col>

                </Row>
            </Form>




            <Form style={{ marginTop: "5px" }}>
                <Row>
                    <Col md={12}>
                        <div className="float-right">
                            <Button color="warning" className="float-right"
                                onClick={() => closeForm2(true)}>Close</Button>
                            <Button color="primary" className="float-right"
                                hidden={receiverLineKey < 0}
                                style={{ marginRight: "10px" }}
                                onClick={() => updateLineDetail()}>Save</Button>

                            <Button outline={true}
                                hidden={receiverLineKey > 0}
                                color="primary" className="float-right"
                                style={{ marginRight: "10px" }}
                                onClick={() => createReceiverLineAndClose()}

                            >Add and Close</Button>


                        </div>
                        <Button color="primary" className="float-left"
                            style={{ marginRight: "10px" }}
                            hidden={receiverLineKey > 0}
                            onClick={() => saveData()}>Add</Button>
                    </Col>
                </Row>
            </Form>
            <Modal isOpen={modalMsg}
                toggle={() => toggleMsg()}
                size="lg">
                <ModalHeader toggle={() => toggleMsg()}>{msgHeader}
                </ModalHeader>
                <ModalBody>
                    {msgBody}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => toggleMsg()}
                    >
                        OK
                    </Button>
                    {' '}

                </ModalFooter>
            </Modal>
        </div>
        )
}



export default class ReceiverDetail extends Component {
    static displayName = ReceiverDetail.name;

    constructor(props) {
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onCellUpdating = this.onCellUpdating.bind(this);
        this.onCellValueChanging = this.onCellValueChanging.bind(this);
        this.onItemCellUpdating = this.onItemCellUpdating.bind(this);
        // this.saveWorkbook = this.saveWorkbook.bind(this);

        this.state = {
            receiverCommentBool: false,
            completeBool: false,
            reviseBool: false,
            incompleteBool: false,
            statusKeyBool: false,
            testBoolean: false,
            statusKey: props.statusKey,
            temp: "",
            receiverStatusData: props.receiverStatusData,
            toLocationKeyTemp: null,
            rcvrTypeID: props.rcvrTypeID,
            toLocationKey: props.toLocationKey,
            bruh: false,
            cancelBool: false,
            poNumber: props.poNumber,
            poLineKey: -1,
            receiverLineKey: -1,
            itemMasterKey: -1,
            lineData: [],
            isReadOnly: false,
            isInvLocLoaded: false,
            invLocation: [],
            filterInvLocation: [],
            projectAFE: [],
            receiverKey: props.receiverKey,
            selBasinKey: props.selBasinKey,
            receiverTypeKey: 0,
            isFormReadOnly: false,
            isRcvrDetailLoaded: false,
            activeTab: '1',
            rcvrDetail: [],
            rcvrDetailToInvLocDefault: null,
            isRcvrLineLoaded: false,
            isProjectAFELoaded: false,
            rcvrLine: [],
            isItemMasterLoaded: false,
            itemMaster: [],
            scItemList: [],
            modalMsg: false,
            modalLineCRUD: false,
            msgHeader: "",
            msgBody: "",
            alertMessage: "",
            showAlert: false,
            basinWH: [],
            isBasinWHLoaded: false,
            validDataItem: {
                toLocationKey: true,
                invoiceDate: true,
                receivedDate: true,
                invoiceNo: true
            },
            invalidDataItem: {
                comment: true
            }
        }
    }

    toggleMsg = () => {
        this.setState({ modalMsg: !this.state.modalMsg })
    }


    locationKey = () => {
        const { rcvrDetail } = this.state
        const test = rcvrDetail[0].toLocationKey
        if (test !== null) {
            return test
        }
    }

    createItemLineForm() {
        const { receiverKey, itemMasterKey } = this.props
        const lineItemCreate = {
            "ReceiverLineKey": -1,
            "ReceiverKey": receiverKey,
            "POLineKey": null,
            "ItemMasterKey": itemMasterKey,
            "ItemNumber": "",
            "qtyReceived": 0.00,
            "unitPrice": 0.00,
            "UOMKey": null,
            "ToLocationKey": this.locationKey(),
            "toLocationKey": this.locationKey(),
            "lineComment": "",
            "SpendCategoryID": ""


        }


        return lineItemCreate
    }




    toggleLineCRUD = () => {
        let newItemLine = this.createItemLineForm()
        this.setState({
            lineData: newItemLine,
            receiverLineKey: -1

        })
        this.toggleModalCrud()

    }

    toggleModalCrud = () => {
        this.setState({
            modalLineCRUD: !this.state.modalLineCRUD
        })
    }

    toggleLineCRUDOnSubmitTest = (refreshDataOnSubmit) => {

        let newItemLine = this.createItemLineForm()
        if (refreshDataOnSubmit) {

            this.setState({
                lineData: newItemLine,
                receiverLineKey: -1,

            })
        }


    }

    toggleLineCRUDEdit = () => {
        const { receiverLineKey } = this.state
        if (receiverLineKey < 0) {
        }
        else {
            this.setState({ modalLineCRUD: !this.state.modalLineCRUD })

        }


    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    onCellValueChanging(s, e) {
        if (this.state.showAlert) {
            this.setState({
                alertMessage: "",
                showAlert: false
            })
        }

        this.updateRcvrLineValue(e.item.receiverLineKey, e.column.field, e.newValue)
    }



    updateRcvrLineValue = (key, fieldName, value) => {
        const { rcvrLine, rcvrTypeID } = this.state
        var temp = []
        rcvrLine.map(e => {
            if (e.receiverLineKey === key) {
                e.isUpdated = 1
                switch (fieldName) {

                    case "qtyReceived":
                        if (rcvrTypeID !== "MISC") {

                            if (value > e.qtyOrdered) {
                                this.setState({
                                    alertMessage: "Received Quantity can not be greater than Order Quantity",
                                    showAlert: true
                                })


                                break
                            }
                        }

                        e.qtyReceived = value
                        break
                    case "lineComment":
                        e.lineComment = value
                        break
                    case "itemNumber":

                        e.itemMasterKey = value
                        break;
                    default:
                        break
                }
                temp.push(e)
            } else {
                temp.push(e)
            }
        })

        this.setState({ rcvrLine: temp })

        if (fieldName === "qtyReceived") {
            this.calInvtotal()
        }

    }


    deleteLineData = () => {

        const lineDataForDeleteDTO = this.createDeleteLineData()

        let myURI = apiURL + 'SupplyChain/ReceiverLine'

        fetch(myURI, apiDELETEHeader(lineDataForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                } else {
                }

                this.getReceiverLine()
                this.calInvtotal()
                this.setState({ receiverLineKey: -1 })

            })
            .catch(error => {

                this.getReceiverLine()

            })


    }





    createDeleteLineData = () => {
        const { lineData } = this.state
        const { user } = this.props

        const lineDataForDeleteDTO = {
            "ReceiverLineKey": lineData.receiverLineKey,
            "DeletedBy": getUserADAccount(user.account.userName),
        }

        return lineDataForDeleteDTO;
    }




    onGridRowSelected(s, e) {
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                receiverKey: dataItem.receiverKey,
                itemMasterKey: dataItem.itemMasterKey,
                receiverLineKey: dataItem.receiverLineKey,
                poLineKey: dataItem.poLineKey,
                lineData: dataItem,
                toLocationKey: dataItem.toLocationKey
            })
            console.log(dataItem.itemMasterKey)
            console.log(dataItem)
        }
    }


    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.rcvrLine;
    }



    getReceiver = () => {
        const { receiverKey } = this.state

        let myURI = apiURL + 'SupplyChain/Receiver/' + receiverKey



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    rcvrDetail: json,
                    rcvrDetailToInvLocDefault: json[0].toLocationKey,
                    isRcvrDetailLoaded: true,
                    poNumber: json[0].poNo, //Added by Hau Nguyen 03/29/2022 = poNumber was empty if from props
                    isFormReadOnly: (json[0].statusKey !== 2 && json[0].statusKey !== 0),
                    receiverTypeKey: json[0].receiverTypeKey,
                    cancelBool: (json[0].statusKey === 5 || json[0].statusKey === 2 ? true : false),
                    incompleteBool: (json[0].statusKey === 5 || json[0].statusKey === 0 ? true : false),
                    reviseBool: (json[0].statusKey === 2 ? true : false),
                    completeBool: (json[0].statusKey === 4 ? true : false)
                })

            });

    }



    getReceiverLine = () => {
        const { receiverKey } = this.state

        let myURI = apiURL + 'SupplyChain/ReceiverLine/RcvrKey/' + receiverKey


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                var rcvrLine = []
                json.map(e => {
                    e.isUpdated = 0 /* Add this field to keep track of updated receiver line */
                    rcvrLine.push(e)
                })
                this.setState({
                    rcvrLine: rcvrLine,
                    isRcvrLineLoaded: true
                }, () => {
                    this.calInvtotal()
                })

            });

    }

    getBasinWH = () => {
        const { selBasinKey } = this.state

        let myURI = apiURL + 'SupplyChain/WH'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinWH: this.createWHList(json.filter(e => e.locationKey === selBasinKey)),
                    isBasinWHLoaded: true
                })
                console.log("selBasinKey: " + selBasinKey)
            });

    }

    createWHList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.warehouseKey
            e.label = e.warehouseName
            tempList.push(e)
        })

        return tempList
    }




    getInventoryLoc = () => {
        const { selBasinKey } = this.state

        let myURI = apiURL + 'SupplyChain/InvLoc'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    invLocation: this.createInvLocList(json.filter(e => e.locationKey === selBasinKey)),
                    isInvLocLoaded: true
                })
            });

    }

    getProjectAFE = () => {

        let myURI = apiURL + 'SupplyChain/AFE/Projects'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    projectAFE: this.createAFEProjectList(json),
                    isProjectAFELoaded: true
                })
            });

    }

    getItemMaster = () => {

        let myURI = apiURL + 'ITemMaster/ByCompany/WBR'



        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    itemMaster: json,
                    isItemMasterLoaded: true
                })
            });
    }

    //  getItemMaster = ()=>{
    //         let myURI = apiURL + 'ItemMaster/byCompany/WBR'


    //         fetch(myURI, { modes: 'no-cors' })
    //             .then(res => res.json())
    //             .then(json => {

    //         //   setItemDataArray(createSCList2(json))

    //                 setItemData(createItemDataList(json))

    //                 setIsItemDataLoaded(true)


    //             });



    //     }

    createInvLocList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.invLocationKey
            e.label = e.warehouseInvLocation
            tempList.push(e)
        })

        return tempList
    }

    createAFEProjectList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.wdProjectKey
            e.label = e.projectDesc
            tempList.push(e)
        })

        return tempList
    }
    validateData = () => {
        const { invalidDataItem, temp } = this.state
        var bVal = true
        var valItem = invalidDataItem

        if (temp === "") {
            bVal = false
            valItem.comment = false
        }
        if (temp === null) {
            bVal = false
            valItem.comment = false
        }
        // if (temp == ""){
        //     bVal = false
        //     valItem.comment= false
        // }

        this.setState({ invalidDataItem: valItem })
        // console.log(bVal)
        return bVal
    }



    onTextChange = (fieldName, fieldValue, e) => {
        const { rcvrDetail, validDataItem, invLocation, invalidDataItem } = this.state
        var filterInvLocation = []
        var toLocationKeyTemp2 = null

        var temp = ""

        console.log(fieldName + '=' + fieldValue)
        switch (fieldName) {
            case "supplier":

                break;
            case "projectNo":

                rcvrDetail[0].projectNo = (e === null ? null : this.getSelProjectAFEKey(e))
                console.log(rcvrDetail[0].projectNo)
                break;
            case "warehouseKey":
                rcvrDetail[0].warehouseKey = (e === null ? null : e.value)
                filterInvLocation = invLocation.filter(e => (e.warehouseKey === fieldValue.warehouseKey))

                break;
            case 'toLocationKey':
                rcvrDetail[0].toLocationKey = (e === null ? null : this.getSelToLocKey(e))
                validDataItem.toLocationKey = true

                toLocationKeyTemp2 = (e === null ? null : this.getSelToLocKeyTemp(e))
                break;
            case "receivedDate":
                rcvrDetail[0].receivedDate = fieldValue
                validDataItem.receivedDate = true
                break;
            case "invoiceDate":
                rcvrDetail[0].invoiceDate = fieldValue
                validDataItem.invoiceDate = true
                break;
            case "invoiceNo":
                rcvrDetail[0].invoiceNo = fieldValue
                validDataItem.invoiceNo = true
                break;
            case "test":
                if (fieldValue !== "" && fieldValue !== null) {
                    temp = rcvrDetail[0].comment + " " + fieldValue
                    // invalidDataItem.comment = (fieldValue != "")
                    invalidDataItem.comment = (fieldValue !== "" || fieldValue !== null)
                }
                else {
                    console.log("didn't work")
                }
                break;

            case "comment":

                rcvrDetail[0].comment = fieldValue

                break;
            case "subTotalAmount":
                rcvrDetail[0].subTotalAmount = fieldValue

                break;
            case "taxAmount":

                rcvrDetail[0].taxAmount = fieldValue


                break;
            case "freightAmount":
                rcvrDetail[0].freightAmount = fieldValue
                break;
            case "invoiceAmount":
                rcvrDetail[0].invoiceAmount = fieldValue
                break;


            default:
        }

        this.setState({
            rcvrDetail: rcvrDetail,
            validDataItem: validDataItem,
            showAlert: false,
            filterInvLocation: filterInvLocation,
            toLocationKeyTemp: toLocationKeyTemp2,
            temp: temp,
            invalidDataItem: invalidDataItem
        })

        this.calInvtotal()

    }


    calInvtotal = () => {


        const { rcvrDetail, rcvrLine} = this.state
        const calLines = rcvrLine.filter(e => e.qtyReceived > 0)
        var lineTotal = 0

        if (rcvrDetail.length > 0) {
            calLines.map(e => {

                lineTotal = lineTotal + (Number(e.qtyReceived) * e.unitPrice)

            })

            // rcvrDetail[0].taxAmountInDollars = Number((rcvrDetail[0].taxAmount * rcvrDetail[0].subTotalAmount))
            rcvrDetail[0].subTotalAmount = lineTotal
            rcvrDetail[0].invoiceAmount = lineTotal + Number(rcvrDetail[0].freightAmount) + Number((rcvrDetail[0].taxAmount * rcvrDetail[0].subTotalAmount) / 100)

            // this.setState({ rcvrDetail, rcvrDetail })
            this.setState({ rcvrDetail: rcvrDetail })
        }
        else {
        }

    }




    getSelWHKey = (value) => {
        return value.warehouseKey
    }

    getSelWH = (value) => {
        const { basinWH } = this.state
        if (value !== null && value !== "") {
            return basinWH.filter(e => e.warehouseKey === value)
        }
        return null
    }

    getSelToLocKeyTemp = (value) => {
        return value.invLocationKey
    }
    getSelToLocKey = (value) => {
        return value.invLocationKey
    }

    getSelProjectAFEKey = (value) => {
        return value.wdProjectKey
    }

    getSelInvLoc = (value) => {
        const { invLocation } = this.state
        if (value !== null && value !== "") {
            return invLocation.filter(e => e.invLocationKey === value)
        }
        return null
    }

    getSelProjectAFE = (value) => {
        const { projectAFE } = this.state
        if (value !== null && value !== "") {
            return projectAFE.filter(e => e.wdProjectKey === value)
        }
        return null
    }

    componentDidMount = () => {
        this.getBasinWH()
        this.getItemMaster()
        this.getInventoryLoc()
        this.getReceiver()
        this.getReceiverLine()
        this.getProjectAFE()
        this.calInvtotal()

    }



    refreshFormForSubmit = (refreshForm) => {
        if (refreshForm) {
            this.saveRcvrLine();
            this.saveRcvrHdr()
            this.getReceiver()
        }
    }

    // refreshFormForSubmit=(refreshForm)=>{
    //     if (refreshForm){
    //         this.saveRcvrLine();
    //         this.saveRcvrHdr()
    //         this.getReceiver()
    //     }
    // }


    reviseReceiver = () => {
        try {

            const { rcvrDetail} = this.state

            rcvrDetail[0].statusKey = 2
            this.setState({ rcvrDetail: rcvrDetail }, () => {
                if (rcvrDetail[0].statusKey = 2) {
                    this.refreshFormForSubmit(true)
                    this.closeForm2()
                    this.setState({ testBoolean: true })
                    this.setState({ statusKeyBool: true })
                    // this.props.setStateOfStatus(2)
                    // this.setState({statusKey: 2})
                    this.props.setStateofParentHeaderColor(true)

                    console.log("This hit")


                }
                else {
                }

            })

            this.closeForm2()



        }

        catch (error) {
        }


    }

    issueReceiver = () => {

        try {
            this.dataValidation().then((data) => {

                if (data.ok) {
                    const { rcvrDetail } = this.state
                    this.saveRcvrLine(); // save receiver Line first


                    rcvrDetail[0].statusKey = 4
                    this.setState({ rcvrDetail: rcvrDetail }, () => {
                        if (rcvrDetail[0].statusKey = 4) {
                            this.refreshFormForSubmit(true)
                            this.props.setStateofParentHeaderColor(false)
                        }
                        else {
                        }

                    })


                    this.props.refreshData()
                    this.closeForm2();

                } else {
                    this.setState({
                        alertMessage: data.message,
                        validDataItem: data.headerValidation,
                        showAlert: true
                    })
                }
            })
        } catch (error) {

        }

    }

    refreshCancelReceiver = (refreshForm) => {
        if (refreshForm) {
            this.saveRcvrLine();
            this.saveRcvrHdr()
        }
    }

    cancelReceiver = () => {
        const { rcvrDetail, temp, receiverCommentBool } = this.state

        if (this.validateData()) {
            console.log("Does this hit")
            rcvrDetail[0].statusKey = 5
            // rcvrDetail[0].comment = " " + temp
            if (receiverCommentBool !== true) {
                rcvrDetail[0].comment = " " + temp
            }
            this.setState({ rcvrDetail: rcvrDetail }, () => {
                if (rcvrDetail[0].statusKey = 5) {

                    this.refreshCancelReceiver(true)
                    this.props.setStateOfParent(0)
                    this.props.setStateofParentHeaderColor(false)
                    this.props.refreshData()
                    this.closeForm2();
                }
                else {
                    console.log("testing")
                }



            })
            this.props.refreshData()
            // this.closeForm2();


        }

    }



    saveData = () => {
        this.saveRcvrLine();
        this.saveRcvrHdr()
        this.getReceiver();
    }



    dataValidation = () => {
        return new Promise((resolve, reject) => {
            const returnVal = { "ok": true, "message": "", "headerValidation": [] }
            const { rcvrDetail, rcvrLine, validDataItem, statusKey, statusKeyBool } = this.state


            // if (rcvrDetail[0].toLocationKey === null) {
            //     validDataItem.toLocationKey = false
            //     returnVal.ok = false
            //     returnVal.message = "Please enter missing data in red"
            // }

            if ((rcvrDetail[0].invoiceNo || "") === "") {
                validDataItem.invoiceNo = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data in red"
            }

            if ((rcvrDetail[0].receivedDate || "") === "") {
                validDataItem.receivedDate = false
                returnVal.ok = false
                returnVal.message = "Please enter missing data"
            }


            returnVal.headerValidation = validDataItem
            //Header Validation
            //Line Validation

            var rcvrLines = rcvrLine.filter((e) => Number(e.qtyReceived) > 0)
            if (rcvrLines.length > 0) {
                var lineNOs = ""
                rcvrLines = rcvrLine.filter((e) => Number(e.qtyReceived) > 0 && (e.itemMasterKey === null || e.itemMasterKey === 'null'))

                if (rcvrLines.length > 0) {
                    rcvrLines.map(a => {
                        lineNOs = (lineNOs !== "" ? "," : "") + a.rcrvLineNo
                    })

                    returnVal.ok = false
                    returnVal.message = "Missing receive line item number. Please select item for line(s):" + lineNOs

                }
            }
            else if (statusKey === 2) {
                console.log("Status key for revise went through")
            }
            else if (statusKeyBool) {
                console.log("This should work")
            }
            else {
                returnVal.ok = false
                returnVal.message = "Missing line receive quantity. Please enter received quantity for line(s) you want to received then try again."
                //reject(Error())
            }

            resolve(returnVal)
        })
    }

    saveRcvrHdr = () => {
        const updateRcvrDTO = this.createRcvrUpdateDTO()
        console.log("Does save hit")
        let myURI = apiURL + 'SupplyChain/Receiver'
        fetch(myURI, apiPUTHeader(updateRcvrDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {
                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg,
                            receiverCommentBool: true
                        })
                    })
                    return Promise.reject(error);

                }


                this.getReceiver()
            })
            .catch(error => {
                // this.getReceiver()
            })
    }

    saveRcvrLine = () => {
        const updateRcvrLineDTO = this.createRcvrLineUpdateDTO()

        let myURI = apiURL + 'SupplyChain/ReceiverLine'
        fetch(myURI, apiPUTHeader(updateRcvrLineDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();

                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                }

            })
            .catch(error => {

            })
    }

    createRcvrLineUpdateDTO = () => {
        const { rcvrLine, user } = this.state
        const rcvrLineDTO = []

        rcvrLine.map(e => {
            if (e.isUpdated !== 0) {
                const itemDTO = {
                    "ReceiverLineKey": e.receiverLineKey,
                    "POLineKey": e.poLineKey,
                    "ItemMasterKey": e.itemMasterKey,
                    "ItemNumber": e.itemNumber,
                    "QtyReceived": e.qtyReceived,
                    "UnitPrice": e.unitPrice,
                    "UOMKey": e.uomKey,
                    "ToLocationKey": e.toLocationKey,
                    "LineComment": e.lineComment,
                    "ModifiedBy": getUserADAccount(user.account.userName),
                    "SpendCategoryID": e.spendCategoryID
                }

                rcvrLineDTO.push(itemDTO)
            }

        })

        return rcvrLineDTO

    }



    closeForm = () => {
        this.props.toggle()
    }

    closeForm2 = (refreshFormNow) => {
        if (refreshFormNow) {
            this.getReceiver()

        }

        this.props.toggle()

    }

    createRcvrUpdateDTO = () => {
        const { user } = this.props
        const { rcvrDetail } = this.state

        const rcvrCreateDTO = {
            "ReceiverKey": rcvrDetail[0].receiverKey,
            "StatusKey": rcvrDetail[0].statusKey,
            "SupplierID": rcvrDetail[0].supplierID,
            "PONo": rcvrDetail[0].poNo,
            "ToLocationKey": rcvrDetail[0].toLocationKey,
            "ProjectNo": rcvrDetail[0].projectNo,
            "InvoiceNo": rcvrDetail[0].invoiceNo,
            "InvoiceDate": rcvrDetail[0].invoiceDate,
            "OrderDate": rcvrDetail[0].orderDate,
            "SubTotalAmount": rcvrDetail[0].subTotalAmount,
            "TaxAmount": rcvrDetail[0].taxAmount,
            "FreightAmount": rcvrDetail[0].freightAmount,
            "OtherAmount": rcvrDetail[0].otherAmount,
            "InvoiceAmount": rcvrDetail[0].invoiceAmount,
            "Comment": rcvrDetail[0].comment,
            "ModifiedBy": getUserADAccount(user.account.userName),
            "ReceivedDate": rcvrDetail[0].receivedDate
        }

        return rcvrCreateDTO;
    }

    deleteRcvr = () => {
        const rcvrForDeleteDTO = this.createDeleteRcvrDTO()

        let myURI = apiURL + 'SupplyChain/Receiver'

        fetch(myURI, apiDELETEHeader(rcvrForDeleteDTO))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();

                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })
                    return Promise.reject(error);
                } else {
                    this.closeForm()
                }


            })
            .catch(error => {

            })

    }

    createDeleteRcvrDTO = () => {
        const { rcvrDetail } = this.state
        const { user } = this.props

        const rcvrForDeleteDTO = {
            "ReceiverKey": rcvrDetail[0].receiverKey,
            "deletedBy": getUserADAccount(user.account.userName), /*4=Submitted, 6=Reviewed*/
        }

        return rcvrForDeleteDTO;
    }

    toggleAlert = () => {

        this.resetValidDataItem()
        this.setState({
            showAlert: !this.state.showAlert
        })
    }
    resetValidDataItem = () => {
        const { validDataItem } = this.state

        validDataItem.toLocationKey = true
        validDataItem.invoiceDate = true
        validDataItem.receivedDate = true
        validDataItem.invoiceNo = true

        this.setState({ validDataItem: validDataItem })
    }


    convertDate = () => {
        const { rcvrDetail } = this.state
        var test = moment(rcvrDetail[0].createdDate).format('MM-DD-YYYY')

        return test

    }
    render() {
        const { isInvLocLoaded, invLocation, receiverTypeKey,
            rcvrDetail, receiverKey, rcvrLine, isRcvrLineLoaded,
            isRcvrDetailLoaded, activeTab, isFormReadOnly,
            msgHeader, isItemMasterLoaded, validDataItem,
            msgBody, alertMessage, showAlert, lineData, receiverLineKey,
            itemMasterKey, poNumber, cancelBool, rcvrDetailToInvLocDefault,
            isProjectAFELoaded, invalidDataItem,
            selBasinKey, statusKey, testBoolean, statusKeyBool, incompleteBool,
            reviseBool, completeBool } = this.state


        if (!isInvLocLoaded) {
            return <div> Loading Inventory location .....</div>
        }
        if (!isProjectAFELoaded) {
            return <div> Loading Project/AFE List</div>
        } else
            if (!isItemMasterLoaded) {
                return <div> Loading item master list</div>
            } else
                if (!isRcvrLineLoaded) {
                    return <div> Loading receiver header</div>
                } else if (!isRcvrDetailLoaded) {
                    return <div> Loading receiver line</div>
                } else
                    return (<div>
                        <Form>

                            <Row form style={{ marginTop: "-10px", marginBottom: "10px" }}>
                                <Col sm={4}>
                                    <Button color="secondary" className="float-left"

                                        onClick={() => this.closeForm()}>Close</Button>

                                    <ConfirmModal
                                        style={{ marginLeft: "5px" }}
                                        color="success"
                                        className="float-left"
                                        buttonLabel="Revise"
                                        hidden={!isFormReadOnly || cancelBool}
                                        formName="Revise"
                                        id={receiverKey}
                                        message="Are you sure you want to revise selected receiver?"
                                        onClickYes={(e) => this.reviseReceiver()}>
                                    </ConfirmModal>



                                    <Button color="primary" className="float-left"
                                        style={{ marginLeft: "5px" }}
                                        hidden={isFormReadOnly}
                                        onClick={() => this.saveData()}>Save</Button>

                                    <ConfirmModal
                                        style={{ marginLeft: "5px" }}
                                        color="success"
                                        className="float-left"
                                        buttonLabel="Submit"
                                        hidden={isFormReadOnly}
                                        formName="Confirm"
                                        id={receiverKey}
                                        message="Are you sure you want to submit selected receiver?"
                                        onClickYes={(e) => this.issueReceiver()}>
                                    </ConfirmModal>


                                </Col>


                                <Col sm={6}>
                                    <Alert color="danger" toggle={() => this.toggleAlert()}
                                        isOpen={showAlert} style={{ marginBottom: "-5px" }}>
                                        {alertMessage}
                                    </Alert >
                                </Col>

                                <Col sm={2}>

                                    <ConfirmModal outline={true}
                                        color="danger"
                                        className="float-right"
                                        buttonLabel="Delete"
                                        hidden={isFormReadOnly || statusKey === 2 || testBoolean}
                                        formName="Delete"
                                        id={receiverKey}
                                        message="Are you sure you want to delete selected receiver?"
                                        onClickYes={(e) => this.deleteRcvr()}
                                        image={<img

                                            src={TrashBin}
                                            id={receiverKey}
                                            alt="delete"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                    </ConfirmModal>


                                    <ConfirmMeterValModal outline={false}
                                        color="danger"
                                        className="float-right"
                                        buttonLabel="Cancel"
                                        hidden={((incompleteBool || cancelBool || testBoolean) && !reviseBool && !completeBool)}
                                        //   value={console.log(cancelBool)}
                                        invalid={!invalidDataItem.comment}
                                        formName="Cancel"
                                        id={receiverKey}
                                        message="Are you sure you want to cancel selected receiver?"
                                        onChangeTest={(e) => this.onTextChange("test", e.target.value, e)}
                                        onClickYes={(e) => this.cancelReceiver()}

                                    />

                                    {/* <ConfirmModal
                                    style={{ marginright: "5px" }}
                                    color="success"
                                    className="float-right"
                                    buttonLabel="Cancel"
                                    hidden={isFormReadOnly}
                                    formName="Cancel"
                                    id={receiverKey}
                                    message="Are you sure you want to cancel selected receiver?"
                                    onClickYes={(e) => this.cancelReceiver()}>
                                </ConfirmModal> */}

                                    {/* <Button color="success" outline className="float-right"
                                    //   style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}
                                  
                                    onClick={() => this.cancelReceiver()}
                                ><img
                                src={Plus}
                                alt="new"
                                style={{ width: "20px", margin: "0 5px 0 0" }} />Cancel</Button> */}

                                </Col>
                            </Row>
                            <ColoredLine color="grey" />
                            <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="rcvrNo">Receiver No.</Label>
                                        </Col>
                                        <Col md={4}>
                                            <Input disabled type="text" name="rcvrNo"
                                                id="rcvrNo" placeholder=""
                                                value={rcvrDetail[0].receiverNo}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input disabled type="text" name="rcvrStatus"
                                                id="rcvrStatus" placeholder=""
                                                value={rcvrDetail[0].rcvrStausDesc}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="createdBy">Created By</Label>
                                        </Col>
                                        <Col md={4}>
                                            <Input disabled type="text" name="createdBy"
                                                id="createdBy" placeholder=""

                                                defaultValue={this.props.user.account.name}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Input type="text" disabled name="createdDate"
                                                id="createdDate" placeholder=""
                                                value={this.convertDate()}

                                            />
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>
                            <Row form style={{ marginBottom: "5px" }}>

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="invDate">Received Date</Label>
                                        </Col>
                                        <Col md={8}>

                                            <DatePicker id="invDate" className={(!validDataItem.receivedDate ? "form-error" : "")}
                                                disabled={isFormReadOnly}


                                                onChange={(v, f) => this.onTextChange("receivedDate", v, f)}
                                                value={rcvrDetail[0].receivedDate} />
                                        </Col>

                                    </Row>
                                </Col>


                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="basin">Basin </Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="basin"
                                                id="rcvrType" placeholder="Basin"
                                                value={rcvrDetail[0].locationName}
                                            />
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>
                            <Row form style={{ marginBottom: "5px" }}>

                                {/* <Col md={6}>
                            <Row form>
                                    <Col md={4}>
                                        <Label for="invDate">Received Date</Label>
                                    </Col>
                                    <Col md={8}>
                                      
                                        <DatePicker id="invDate" className={(!validDataItem.receivedDate ? "form-error" : "")}
                                            disabled={isFormReadOnly}

                                   
                                            onChange={(v, f) => this.onTextChange("receivedDate", v, f)}
                                            value={rcvrDetail[0].receivedDate} />
                                    </Col>

                                </Row>
                            </Col> */}


                                <Col md={6}>

                                    <Row form>
                                        <Col md={4}>
                                            <Label for="invNo">Packing Slip </Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="text" name="invNo"
                                                className={(!validDataItem.invoiceNo ? "form-error" : "")}
                                                disabled={isFormReadOnly}
                                                id="invNo" placeholder="Packing Slip"
                                                value={rcvrDetail[0].invoiceNo}
                                                onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                                            />

                                        </Col>
                                    </Row>


                                </Col>

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="rcvrType">Receiver Type </Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="rcvrType"
                                                id="rcvrType" placeholder="Receiver Type"
                                                value={rcvrDetail[0].rcvrTypeDesc}
                                            />
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>

                            <Row form style={{ marginBottom: "5px" }}>


                                {/* <Col md={6}>

                            <Row form>
                                    <Col md={4}>
                                        <Label for="invNo">Packing Slip </Label>
                                    </Col>
                                    <Col md={8}>
                                        <Input type="text" name="invNo"
                                            className={(!validDataItem.invoiceNo ? "form-error" : "")}
                                            disabled={isFormReadOnly}
                                            id="invNo" placeholder="Packing Slip"
                                            value={rcvrDetail[0].invoiceNo}
                                            onChange={(e) => this.onTextChange("invoiceNo", e.target.value, e)}
                                        />

                                    </Col>
                                </Row>
                               

                            </Col> */}

                                <Col md={6}>

                                </Col>

                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="supplier">Supplier </Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="supplier"
                                                id="supplier" placeholder=""
                                                value={rcvrDetail[0].supplier_Name}
                                            />
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                            <Row form style={{ marginBottom: "5px" }}>

                                {/* <Col md={6}>
                            <Row form>
                                    <Col md={4}>
                                        <Label for="invNo">Project / AFE </Label>
                                    </Col>
                                    <Col md={8}>
                                        <Select
                                                options = {projectAFE}
                                        
                                         isDisabled={isFormReadOnly}
                                            isClearable={true}

                                            defaultValue={this.getSelProjectAFE(rcvrDetail[0].projectNo)}
                                           
                                            onChange={(e) => this.onTextChange("projectNo", e, e)}
                                        />

                                    </Col>
                                </Row>
                            </Col> */}
                                <Col md={6}>

                                </Col>


                                <Col md={6}>
                                    <Row form>
                                        <Col md={4}>
                                            <Label for="PONo">Purchase Order No.</Label>
                                        </Col>
                                        <Col md={8}>
                                            <Input disabled type="text" name="PONo"

                                                id="PONo" placeholder=""
                                                value={rcvrDetail[0].poNo}

                                            />
                                        </Col>

                                    </Row>
                                </Col>


                            </Row>

                            <Row form hidden={receiverTypeKey !== 2 || isFormReadOnly}>

                                <Col sm={12}>
                                    <Button color="primary" outline className="float-left"
                                        style={{ height: "35px" }}

                                        hidden
                                        onClick={() => this.calInvtotal()}
                                    >Refresh</Button>

                                    <Button color="success" outline className="float-left"
                                        style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}

                                        onClick={() => this.toggleLineCRUD()}
                                    ><img
                                            src={Plus}
                                            alt="new"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />New Line</Button>

                                    <Button color="primary" outline className="float-left"
                                        style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}

                                        onClick={() => this.toggleLineCRUDEdit()}

                                    ><img
                                            src={MenuIcon}
                                            alt="new"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />Edit</Button>

                                    <ConfirmModal outline={true}

                                        color="danger"
                                        className="float-right"
                                        buttonLabel="Delete Line"

                                        disabled={receiverLineKey < 0}

                                        hidden={!incompleteBool}
                                        formName="Confirm"
                                        id={receiverKey}
                                        message="Are you sure you want to delete selected receiver?"
                                        onClickYes={(e) => this.deleteLineData()}
                                        image={<img
                                            src={TrashBin}
                                            id={receiverKey}
                                            alt="Edit"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                    </ConfirmModal>
                                </Col>
                            </Row>
                            <Row form hidden={(isFormReadOnly || receiverTypeKey !== 1)}>

                                <Col sm={12}>

                                    <Button color="primary" outline className="float-left"
                                        style={{ height: "35px", marginBottom: "5px", marginLeft: "10px" }}
                                        disabled={receiverLineKey < 0}
                                        onClick={() => this.toggleLineCRUDEdit()}
                                    ><img
                                            src={MenuIcon}
                                            alt="new"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />Edit</Button>

                                </Col>
                            </Row>




                            <Row form style={{ zIndex: 0, position: 'relative', margin: "5px 0 0 0" }}>


                                <IgrDataGrid
                                    ref={this.onGridRef}
                                    height="300px"
                                    width='100%'
                                    dataSource={rcvrLine}
                                    isColumnOptionsEnabled="false"
                                    autoGenerateColumns="false"
                                    selectionMode="SingleRow"
                                    editModeClickAction="SingleClick"
                                    // editMode={(isFormReadOnly  || receiverTypeKey === 2 ? 0 : 1)}
                                    editMode={0}
                                    selectedKeysChanged={this.onGridRowSelected}
                                    cellValueChanging={this.onCellValueChanging}


                                >

                                    <IgrTemplateColumn field="rcrvLineNo"
                                        headerText="No"
                                        width="65"
                                        //disabled
                                        cellUpdating={this.onCellUpdating}
                                    />
                                    <IgrTextColumn
                                        field="spendCategoryID"
                                        headerText="Spend Category"
                                        width="145"

                                    />

                                    {/* For MISC */}
                                    {/* <IgrTextColumn
                                    field={poNumber == "" || poNumber== null ? "itemNumber" : "itemNumberMisc"}
                                    headerText="Item Number"
                                    width="300"
                                    isHidden={poNumber == "" || poNumber== null ? false : true}
                                   
                                  
                                />

                                <IgrTemplateColumn
                                    field={poNumber === "" || poNumber === null ? "itemNumberMisc2" : "itemNumber"}
                                    
                                    headerText="Item Number"
                                    width="300"
                                    isHidden={poNumber === "" || poNumber === null ? true: false}
                                    cellUpdating={this.onItemCellUpdating}
                                /> */}

                                    {/* <IgrTextColumn
                                    field="itemNumber"
                                    headerText="Item Number"
                                    width="300"
                                    // isHidden={poNumber == "" || poNumber== null ? false : true}
                                   
                                  
                                /> */}

                                    <IgrTextColumn
                                        field="longItemNumber"
                                        headerText="Item Number"
                                        width="*>280"

                                    />




                                    <IgrTemplateColumn
                                        field="qtyOrdered"
                                        //isHidden={rcvrTypeID== "MISC" ? true : false} //comment out by HN by by HN 03/29/2022 is not working
                                        isHidden={receiverTypeKey === 2} //added by HN 03/29/2022
                                        headerText="Order Qty"
                                        width="150"

                                        minFractionDigits={2}
                                        cellUpdating={this.onCellUpdating}

                                    />

                                    <IgrNumericColumn
                                        field="unitPrice"
                                        headerText="Unit Price"
                                        width="110"
                                        positivePrefix="$"
                                        showGroupingSeparator="true"
                                        horizontalAlignment="right"
                                        cellUpdating={this.onCellUpdating}
                                    />

                                    <IgrNumericColumn
                                        field="qtyReceived"
                                        headerText="Receive Qty"
                                        minFractionDigits={2}
                                        width="150"

                                    />



                                    <IgrTextColumn
                                        field="warehouseInvLocation"
                                        headerText="Location"
                                        width="180"

                                    />


                                    <IgrTextColumn
                                        field="projectDesc"
                                        headerText="Project/AFE"
                                        width="200"

                                    />
                                    <IgrTemplateColumn
                                        field="uomDesc"
                                        headerText="UOM"
                                        width="100"
                                        cellUpdating={this.onCellUpdating}
                                    />

                                    {/* <IgrTextColumn
                                    field="wdLineDesc"
                                    headerText="WD Line"
                                    width="250"
                                    isHidden={poNumber == "" || poNumber== null ? true : false}
                                   
                                  
                                />
                */}
                                    <IgrTextColumn
                                        field="lineComment"
                                        headerText="Line Comment"
                                        width="*>280"

                                    />


                                    <IgrNumericColumn field="receiverLineKey" isHidden />
                                </IgrDataGrid>


                            </Row>

                            <FormGroup row>
                                <Col md={8}>

                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { this.toggleTab('1'); }}
                                            >
                                                Comments
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { this.toggleTab('2'); }}
                                            >
                                                Documents
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row form>
                                                <Input type="textarea" name="rcvrCmnt"
                                                    rows={4}
                                                    disabled={isFormReadOnly}
                                                    id="rcvrCmnt" placeholder="Receiver Comment"
                                                    value={rcvrDetail[0].comment}
                                                    onChange={(e) => this.onTextChange("comment", e.target.value, e)}
                                                />
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                {/* <Col md={12}>
                                                <DocumentBrowser buttonLabel="View Image"
                                                    disabled={isFormReadOnly}
                                                    buttonColor="primary"
                                                    formName="Receiver Document"
                                                    linkTableName={"tpoReceiver"}
                                                    linkPrimaryKey={receiverKey}
                                                    apiURL={apiURL + 'SupplyChain/Receiver/Documents/' + receiverKey} />

                                            </Col> */}

                                                <Col md={12}>
                                                    <DocumentBrowserDragDrop buttonLabel="View Image"
                                                        disabled={isFormReadOnly}
                                                        buttonColor="primary"
                                                        formName="Receiver Documents"
                                                        linkTableName={"tpoReceiver"}
                                                        linkPrimaryKey={receiverKey}
                                                        hidden={console.log(receiverKey)}
                                                        // allowDelete={isAFEManager || isAFEAdmin}
                                                        apiURL={apiURL + 'SupplyChain/Receiver/Documents/' + receiverKey} />

                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </Col>

                                <Col md={4}>
                                    <Row form style={{ margin: "5px 0 0 0" }}>
                                        <Col md={4}>
                                            <Label for="subTotal">Sub Total</Label>
                                        </Col>
                                        <Col md={8}>
                                            <NumberFormat value={rcvrDetail[0].subTotalAmount}
                                                onValueChange={(e) => this.onTextChange("subTotalAmount", e.value, e)}
                                                className='form-control text-md-right'
                                                fixedDecimalScale={true}
                                                decimalScale={2}

                                                disabled
                                                thousandSeparator={true}

                                                prefix={'$'} />

                                        </Col>

                                    </Row>
                                    <Row form style={{ margin: "5px 0 0 0" }}>
                                        <Col md={4}>
                                            <Label for="saleTax">Sales Tax %</Label>
                                        </Col>
                                        <Col md={4}>
                                            <NumberFormat
                                                value={rcvrDetail[0].taxAmount}
                                                onValueChange={(e) => this.onTextChange("taxAmount", e.value, e)}
                                                className='form-control text-md-right'
                                                fixedDecimalScale={true}
                                                // decimalScale={2}

                                                disabled={isFormReadOnly}
                                                thousandSeparator={true}
                                                suffix={'%'}


                                            />

                                        </Col>

                                        <Col md={4}>

                                            <NumberFormat
                                                value={(rcvrDetail[0].taxAmount * rcvrDetail[0].subTotalAmount) / 100}
                                                // onValueChange={(e) => this.onTextChange("taxAmount", e.value, e)}
                                                className='form-control text-md-right'
                                                fixedDecimalScale={true}
                                                decimalScale={2}

                                                disabled
                                                thousandSeparator={true}
                                                prefix={'$'}


                                            />

                                        </Col>

                                    </Row>
                                    <Row form style={{ margin: "5px 0 0 0" }} >
                                        <Col md={4}>
                                            <Label for="freight">Freight</Label>
                                        </Col>
                                        <Col md={8}>

                                            <NumberFormat value={rcvrDetail[0].freightAmount}
                                                onValueChange={(e) => this.onTextChange("freightAmount", e.value, e)}
                                                className='form-control text-md-right'
                                                fixedDecimalScale={true}
                                                decimalScale={2}

                                                disabled={isFormReadOnly}
                                                thousandSeparator={true}
                                                prefix={'$'} />


                                        </Col>
                                    </Row>
                                    <Row form style={{ margin: "5px 0 0 0" }}>
                                        <Col md={4}>
                                            <Label for="totalAmt">Total</Label>
                                        </Col>
                                        <Col md={8}>

                                            <NumberFormat value={rcvrDetail[0].invoiceAmount}
                                                onValueChange={(e) => this.onTextChange("invoiceAmount", e.value, e)}
                                                className='form-control text-md-right'
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                disabled
                                                thousandSeparator={true}
                                                prefix={'$'} />

                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>


                        </Form>

                        <div>
                            <Modal isOpen={this.state.modalMsg}
                                toggle={() => this.toggleMsg()}
                                size="lg">
                                <ModalHeader toggle={() => this.toggleMsg()}>{msgHeader}
                                </ModalHeader>
                                <ModalBody>
                                    {msgBody}
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="primary"
                                        onClick={() => this.toggleMsg()}
                                    >
                                        OK
                                    </Button>
                                    {' '}

                                </ModalFooter>
                            </Modal>
                        </div>

                        <div>
                            <Modal isOpen={this.state.modalLineCRUD}
                                toggle={() => this.toggleLineCRUD()}
                                onClosed={() => this.getReceiverLine()}
                                size="lg" centered
                                backdrop={"static"}
                            >
                                <ModalHeader toggle={() => this.toggleLineCRUD()}>{msgHeader} {receiverLineKey < 0 ? "Create New Line" : "Edit Line"}
                                </ModalHeader>
                                <ModalBody>
                                    <ReceiverLineCRUD toggleTest={this.toggleLineCRUD} receiverKey={receiverKey} rcvrLine={rcvrLine} refreshData={this.getReceiverLine} invLocation={invLocation} rcvrDetail={rcvrDetail} lineData={lineData} receiverLineKey={receiverLineKey}
                                        setStateOfParent={this.setStateOfParent} setStateOfParent2={this.setStateOfParent2}
                                        toggleLineCRUDOnSubmitTest={this.toggleLineCRUDOnSubmitTest} itemMasterKey={itemMasterKey} rcvrDetailToInvLocDefault={rcvrDetailToInvLocDefault} calInvtotal={this.calInvtotal}
                                        setStateOfParent3={this.setStateOfParent3} receiverTypeKey={receiverTypeKey} user={this.props.user} selBasinKey={selBasinKey}
                                        statusKey={statusKey} statusKeyBool={statusKeyBool} poNumber={poNumber}   >

                                    </ReceiverLineCRUD>
                                </ModalBody>
                            </Modal>
                        </div>




                    </div>)
    }

    setStateOfParent = (newTitle) => {
        this.setState({ lineData: newTitle }, () => {
        });
    }

    setStateOfParent2 = (newTitle) => {
        this.setState({ rcvrDetailToInvLocDefault: newTitle }, () => {

        })

    }

    setStateOfParent3 = (newTitle) => {
        this.setState({ rcvrDetail: newTitle }, () => {

        })

    }



    onCellUpdating(s, e) {

        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;

        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            // let cell = document.createElement("div");

            link.setAttribute("class", "form-control selectBorderLess");

            link.style.fontSize = "13px";
            link.style.verticalAlign = "center";


            link.style.padding = "8px 0px 0px 0px";


            content.appendChild(link);
        }


        if (link !== undefined) {


            switch (s.field) {
                case 'rcrvLineNo':
                    link.textContent = item.rcrvLineNo;
                    break;
                case 'spendCategoryID':
                    link.textContent = item.spendCategoryID;
                    break;
                case 'qtyOrdered':

                    link.textContent = item.qtyOrdered;
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");
                    break;
                case 'unitPrice':
                    link.textContent = '$' + item.unitPrice;
                    link.setAttribute("class", "text-md-right form-control selectBorderLess");

                    break;
                case 'uomDesc':
                    link.textContent = item.uomDesc;
                    break;
                case 'solutionDesc':
                    link.textContent = item.solutionDesc;
                    break;
                case 'createdByName':
                    link.textContent = item.createdByName;
                    break;
                case 'managerName':
                    link.textContent = item.managerName;
                    break;
                case 'locationName':
                    link.textContent = item.locationName;
                    break;
                case 'facilityName':
                    link.textContent = item.facilityName;
                    break;
                default:
            }
        }
    }





    onItemCellUpdating(s, e) {



        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;



        let select;

        if (content.childElementCount === 0) {
            const { itemMaster, isFormReadOnly } = this.state
            if (s.field === "itemNumber") {
                select = document.createElement("select");

                select.addEventListener("change", () => {
                    this.rcvrLineItemChange(item.receiverLineKey, select.value)
                })




                select.style.margin = "0px 0px 0px -15px";
                select.style.width = "105%";


                const itemMS = itemMaster.filter(e => (e.spendCategory || '').includes(item.spendCategoryID))

                //First Empty option
                var option = document.createElement("option");
                option.setAttribute("value", -1);
                option.text = "";
                select.appendChild(option);

                itemMS.map(e => {
                    var option = document.createElement("option");
                    option.setAttribute("value", e.itemMasterKey);
                    option.text = e.longItemNumber;
                    select.appendChild(option);
                })



                select.value = (item.itemMasterKey === null ? -1 : item.itemMasterKey)
                if (isFormReadOnly) {
                    select.setAttribute("class", "selectBorderLess");
                    select.disabled = isFormReadOnly
                } else {
                    select.setAttribute("class", "form-control selectBorderLess");
                }



                content.appendChild(select);

            }


        }
        else {
            select = content.children[0];
        }
        //}
    }

    rcvrLineItemChange = (key, value) => {
        this.updateRcvrLineValue(key, 'itemNumber', value)

    }

}