import React, { Component, useEffect, useState } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../resources/apiURL';
import { Dropdown, Form, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
 
import Plus from '../resources/plus.svg'
import Refresh from '../resources/refresh.svg'
 
import Excel from '../resources/excel.svg'
 
import Revise from '../resources/revise.png'
import '../custom.css';
import { ExcelUtility } from '../operation/ExcelUtility';
import Select from 'react-select';
 

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
 
import { IgrDataGrid } from 'igniteui-react-grids';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
 
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
//import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
 
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
  
import ChangeOrder from './ChangeOrder';
//import { prop } from 'types-ramda';

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register(); 

var DatePicker = require("reactstrap-date-picker");

class ConstructionReportingChangeOrders extends React.Component {
    //static displayName = AFE.name;


    constructor(props) {
        super(props)
        //document.title = "Report According"

        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);
        
        //const urlPara = this.getURLParameters(props.location)

        this.state = { 
            constructionReportKey: props.constructionReportKey,
            constructionReportChangeOrderKey: 0, 
            changeOrders: [],
            projectType: props.projectType,
            constructionReportDTO: props.constructionReportDTO,
            user: props.user,
            dailyReportKey: props.dailyReportKey,  
            isSecurityAdminLogin: props.isSecurityAdminLogin,
            viewChangeOrder: false,  
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            modalProjectDetails: false,
            searchText: "",
            isAFEAdmin: false,
            isSecurityLoaded: false,
            afeBudgetListURL: [],
            projectStatusList: [],
            isProjectStatusListLoaded: false, 
            constructionReportKey: 0, 
            modalReport: false,
            reportName: "",
            statusKey: 0,
            managerList: [],
            constructionReports: [],
            consturctionReportStatusList: [],
            afeList: [], 
            modalChangeOrder: false,
            reportName: "",
            reportDate: props.reportDate,
            dailyReports: [],
        } 
    }
  
    onGridRowSelected(s, e) { 
        const content = e.content; 
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                constructionReportKey: dataItem.constructionReportKey,
                constructionReportChangeOrderKey: dataItem.constructionReportChangeOrderKey,
                dailyReportKey:  dataItem.dailyReportKey,
                viewChangeOrder: true
            }) 
        } 
        
    }

    onGridRowSelected2(s,e) {
        if (e.isDoubleClick){
            this.setState({ modalChangeOrder: !this.state.modalChangeOrder });
        }
    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.AFEData;
    }
 
    getUserSecurity = () => {
        const { user } = this.props
        let myURI = apiURL + 'AFE/IsAFEAdmin/' + user.account.userName

        // console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log("json")
                // console.log(json)
                this.setState({
                    isAFEAdmin: (json.isAFEAdmin !== 0),
                    isSecurityLoaded: true
                })
            });
    }
  
  

    async loadView(){
        let myURI = apiURL + 'ConstructionReporting/load'

        console.log("myURI");
        console.log(myURI);

        await fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json.basinInformation),
                    costTypeList: this.createCostTypeList(json.projectType),
                    managerList: this.createEmpList(json.employeesDetails),
                    consturctionReportStatusList: this.createConstructionReportStatusList(json.consturctionReportStatus),
                    afeList: this.createAFEList(json.afeList),
                    //constructionReports: json.constructionReports,
                    searchText: "",
                    //isDataLoaded: true,
                }) 
            });
    }

    getChangeOrders = async()=>{ 
        let myURI = apiURL + 'ConstructionReporting/ChangeOrders/' + this.props.constructionReportKey;
  
        if (this.props.dailyReportKey > 0){
            myURI = apiURL + 'ConstructionReporting/ChangeOrders/' + 
                                this.props.constructionReportKey + '/' +
                                this.props.dailyReportKey;
        }
        else{
            myURI = apiURL + 'ConstructionReporting/ChangeOrders/' + this.props.constructionReportKey;
        }
  
        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({ 
                    changeOrders: json,
                    searchText: ""
                }) 
            });
        return;    
    }
    
    refresh=()=>{
        // this.setState({  isDataLoaded: false });
        this.getChangeOrders();
        // this.setState({  isDataLoaded: true });   
    }
 
    componentDidMount = async () => {  
        //await this.loadView();
        this.setState({constructionReportKey: this.props.constructionReportKey})
        await this.getDailyReports();
        await this.getChangeOrders();
        this.setState({  isDataLoaded: true });
    }
   
    getDailyReports = async()=>{

        const{constructionReportDTO,constructionReportKey} = this.state;
        let myURI = apiURL + 'ConstructionReporting/' + constructionReportDTO.constructionReportKey + '/DailyReports';  
        console.log("myURI");
        console.log(myURI);

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({dailyReports: json }) 
            });
    }

    onTextChange = (fieldName, fieldValue, e) => {
        const { fullAFEData } = this.state 

        switch (fieldName) {
            case "afeSearch":
                this.setState({ searchText: fieldValue.toLowerCase() })
                break;
            case "afeStatus":
                this.getFitlerAFE(fullAFEData, this.state.selAFEQuery, this.state.selBasin, e)
                this.setState({ selAFEStatus: e })
                break;
            case "basin":
                this.getFitlerAFE(fullAFEData, this.state.selAFEQuery, e, this.state.selAFEStatus)
                this.setState({ selBasin: e })
                break;
            case "afeQuery":
                this.getFitlerAFE(fullAFEData, e, this.state.selBasin, this.state.selAFEStatus)
                this.setState({ selAFEQuery: e })
                break;
            default:
                break;
        }
    }
      
    toggleNewChangeOrder =() => {
        this.setState({
            modalChangeOrder: !this.state.modalChangeOrder,constructionReportChangeOrderKey: 0
        })
    }

    viewChangeOrder =() => {
        this.setState({ modalChangeOrder: !this.state.modalChangeOrder });
    }

    closeChangeOrder = async() => {
        this.setState({ modalChangeOrder: false,viewChangeOrder: false })
        await this.getDailyReports();
        this.getChangeOrders();
    }
    
    render() {

        const {dailyReportKey , constructionReportKey,isDataLoaded, changeOrders,
            constructionReportChangeOrderKey, constructionReportDTO, viewChangeOrder, user,
            windowHeight ,reportDate,dailyReports,isSecurityAdminLogin} = this.state

     
        const gridHeight = (windowHeight * 0.75) + "px"
 
 
        if (!isDataLoaded ) {
            return (<div>
                <h2>Loading Report Data.....</h2>
            </div>)
        } 
        else{ 
            return (<div>
                <Form onSubmit={e => e.preventDefault()}> 
                    <FormGroup>
                        <Row form>
                            <Col sm={12}> 
                                <Button outline
                                    color="secondary"
                                    className="btn-no-border"
                                    onClick={this.refresh}>
                                    <img
                                        src={Refresh}
                                        alt="Refresh"
                                        style={{ width: "30px", margin: "0 5px 0 0" }} />
                                    Refresh
                                </Button>
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    onClick={this.saveWorkbook}>
                                    <img
                                        src={Excel}
                                        alt="excel"
                                        style={{ width: "50px", margin: "0 0 0 0" }} />
                                    Export To Excel
                                </Button>{' '}

                                <Button outline color="secondary"
                                    className="btn-no-border" 
                                    //hidden={dailyReports === undefined || dailyReports === null || dailyReports.length <= 0}
                                    disabled={dailyReports === undefined || dailyReports === null || dailyReports.length <= 0 ||
                                        constructionReportDTO == undefined || constructionReportDTO == null ||
                                        (!isSecurityAdminLogin && (constructionReportDTO.statusKey == 0 || constructionReportDTO.statusKey == 3 ||
                                        constructionReportDTO.statusKey == 4 || constructionReportDTO.statusKey == 5))}
                                    onClick={ ()=> this.toggleNewChangeOrder()}  
                                    >
                                    <img
                                        src={Plus}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    Add Change Order
                                </Button>{' '}
                                <Button outline color="secondary"
                                    className="btn-no-border"
                                    disabled={!viewChangeOrder} 
                                    onClick={() => this.viewChangeOrder()}> 
                                    <img
                                        src={Revise}
                                        alt="new"
                                        style={{ width: "25px", margin: "0 5px 0 0" }} />
                                    View Change Order
                                </Button>{' '} 
                            </Col>
                        </Row>
                        <ColoredLine color="grey" />
                    </FormGroup>  
                    <FormGroup>
                        <Row form style={{ zIndex: 0, position: 'relative' }}> 
                            <IgrDataGrid
                                ref={this.onGridRef}
                                height={gridHeight}
                                width={"100%"} 
                                isColumnOptionsEnabled="true" 
                                editMode={0}
                                dataSource={changeOrders}
                                autoGenerateColumns="false"
                                selectionMode="SingleRow" 
                                selectedKeysChanged={this.onGridRowSelected}
                                cellClicked={this.onGridRowSelected2}
                                cellTextStyle="14px Calibri" headerTextStyle="14px Calibri" rowHeight={"25"}
                            >
                                <IgrNumericColumn
                                    field="constructionReportChangeOrderKey"
                                    headerText={"ConstructionReportChangeOrderKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                <IgrNumericColumn
                                    field="constructionReportKey"
                                    headerText={"ConstructionReportKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                <IgrNumericColumn
                                    field="dailyReportKey"
                                    headerText={"DailyReportKey"}
                                    width="*>160"
                                    isHidden={true}
                                />
                                
                                <IgrTemplateColumn 
                                    field="changeOrderDate" 
                                    headerText="Change Order Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />

                                <IgrTextColumn 
                                    field="contractor" 
                                    headerText="Contractor"
                                    width="*>110" 
                                />
                                
                                <IgrTextColumn
                                    field="description" 
                                    headerText="Description"
                                    width="*>150"
                                /> 

                                
                                <IgrTemplateColumn 
                                    field="amount" 
                                    headerText="Amount"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />
                                <IgrTextColumn 
                                    field="createdBy" 
                                    headerText="CreatedBy"
                                    width="*>110" 
                                />
                                <IgrTemplateColumn 
                                    field="createdDate" 
                                    headerText="Created Date"
                                    width="*>160" 
                                    cellUpdating={this.onStatusCellUpdating}
                                />    
                            </IgrDataGrid>
                        </Row>
                        
                    </FormGroup>

                    <div>
                        <Modal isOpen={this.state.modalChangeOrder}
                            toggle={() => this.toggleNewChangeOrder()}
                            size="md"
                            backdrop={"static"}
                            onClosed={() => this.closeChangeOrder()}
                            >
                            <ModalHeader toggle={() => this.closeChangeOrder()}
                                //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                                >
                                {constructionReportChangeOrderKey == undefined || constructionReportChangeOrderKey == null ||
                                constructionReportChangeOrderKey <=0 ? "New Change Order" : "Change Order"} 
                            </ModalHeader>
                            <ModalBody>
                                <ChangeOrder 
                                    toggle={this.closeChangeOrder}
                                    setFormHeader={this.setAFEDetailsFormHeader}
                                    user={user}
                                    constructionReportChangeOrderKey={constructionReportChangeOrderKey}
                                    constructionReportKey={constructionReportKey}
                                    dailyReportKey={dailyReportKey}//{reportKey}
                                    reportDate={reportDate}
                                    dailyReports={dailyReports === undefined || dailyReports === null || dailyReports.length <= 0 ? null : dailyReports}
                                    constructionReportDTO={constructionReportDTO}
                                    isSecurityAdminLogin={isSecurityAdminLogin}
                                    >
                                </ChangeOrder>
                            </ModalBody>
                        </Modal>
                    </div>
                     
                </Form>
            </div >)
        }
            

    }

    onStatusCellUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("label");
            let cell = document.createElement("div");

            content.style.fontFamily = "Verdana";
            content.style.fontSize = "13px";

            content.style.margin = "0px -20px -10px -12px";
            content.style.padding = "0px 10px 10px 10px";
            

            content.appendChild(link);
        }
        else {
            link = content.children[0];

            if (item.statusKey === 3 || item.closeoutStatusKey === 3) {
                content.style.background = "#f0f5b0";
            } else if (item.statusKey === 1 || item.statusKey === 5 ||
                item.closeoutStatusKey === 1 || item.closeoutStatusKey === 10) {
                content.style.background = "#F3B5A2";
            } else if (item.statusKey === 6) {
                content.style.background = "#b8f2b1";
            }
            else {
                content.style.background = "transparent";
            }

            if (s.field === "actualvsBudget") {
                if (item.actualvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.actualvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.actualvsBudget > 104.44 && item.actualvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }
            if (s.field === "projectedvsBudget") {
                if (item.projectedvsBudget <= 104.44) {
                    content.style.background = "#b8f2b1";
                } else if (item.projectedvsBudget >= 114.44) {
                    content.style.background = "#f8858b";
                } else if (item.projectedvsBudget > 104.44 && item.projectedvsBudget < 114.44) {
                    content.style.background = "#ffaf7a";
                } else {
                    content.style.background = "#transparent";
                }
                link.style.fontSize = "13px";
                link.style.verticalAlign = "center";
            }


        }

        switch (s.field) {
            case 'afeStatusDesc': 
                link.textContent = "60%";
                break;
            case 'afeName':
                link.textContent = item.afeName;
                break;
            case 'changeOrderDate':
                link.textContent = ""
                if (item.changeOrderDate !== null) {
                    var date = new Date(item.changeOrderDate);
                    link.textContent = date.toLocaleDateString();

                }
                content.style.background = "transparent";
                break;
            case 'amount':
                link.textContent = '$' + item.amount;
                break;

            case 'afeApprovedDate': 
                link.textContent = ""

                if (item.afeApprovedDate !== null) {
                    var date = new Date(item.afeApprovedDate);
                    link.textContent = date.toLocaleDateString(); 

                }
                content.style.background = "transparent";
                break;
            case 'createdDate':
                link.textContent = ""
                if (item.createdDate !== null) {
                    var date = new Date(item.createdDate);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'estStartDate':
                link.textContent = ""
                if (item.estStartDate !== null) {
                    var date = new Date(item.estStartDate);
                    link.textContent = date.toLocaleDateString();
                    //content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'requiredEndDate':
                link.textContent = ""
                if (item.requiredEndDate !== null) {
                    var date = new Date(item.requiredEndDate);
                    link.textContent = date.toLocaleDateString(); 

                }
                content.style.background = "transparent";
                break;
            case 'estComplete':
                link.textContent = ""
                if (item.estComplete !== null) {
                    var date = new Date(item.estComplete);
                    link.textContent = date.toLocaleDateString();
                    // content.style.background = "transparent";

                }
                content.style.background = "transparent";
                break;
            case 'createdByName':
                link.textContent = item.createdByName;
                break;
            case 'managerName':
                link.textContent = item.managerName;
                break;
            case 'locationName':
                link.textContent = item.locationName;
                break;
            case 'facilityName':
                link.textContent = item.facilityName;
                break;
            case 'actualvsBudget':
                link.textContent = (item.actualvsBudget >= 1 ? Math.round(item.actualvsBudget) : item.actualvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'projectedvsBudget':
                link.textContent = (item.projectedvsBudget >= 1 ? Math.round(item.projectedvsBudget) : item.projectedvsBudget) + '%';
                link.setAttribute("class", "text-md-right form-control selectBorderLess");

                break;
            case 'budgetID':
                link.textContent = item.budgetID === undefined || item.budgetID === null ? '' : this.getSelBudget(item.budgetID);
                content.style.background = "transparent";
                break;

            default:

        }

    }

    saveWorkbook() {

        var actCols = this.grid.actualColumns
        console.log(actCols.item(0))

        let gridDts = this.grid.dataSource // this.state.inspItems

        const headers = Object.keys(gridDts[0]);
        headers.pop();
        const wb = new Workbook(WorkbookFormat.Excel2007);
        const ws = wb.worksheets().add("Sheet1");

        for (let i = 0; i < headers.length; i++) {
            for (let j = 0; j < actCols.count; j++) {
                if (headers[i] == actCols.item(j).field) {
                    ws.rows(0).cells(j).value = actCols.item(j).headerText //headers[i];
                    if ((actCols.item(j).headerText || "") === "") {
                        ws.rows(0).cells(j).value = actCols.item(j).field
                    }
                }
            }

        }

        for (let i = 0; i < gridDts.length; i++) {
            const dataRow = gridDts[i];
            const xlRow = ws.rows(i + 1);
            for (let j = 0; j < headers.length; j++) {
                for (let k = 0; k < actCols.count; k++) {

                    if (headers[j] == actCols.item(k).field) {
                        xlRow.setCellValue(k, dataRow[headers[j]]);
                    }

                }

                //xlRow.setCellValue(j, dataRow[headers[j]]);                
            }
        }

        ExcelUtility.save(wb, "Change Orders");
    }

}

export default ConstructionReportingChangeOrders